import React from "react";
import {IconButton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {findIndex, propEq, remove} from "ramda";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {generateID} from "@core/helpers";
import {STATUSES} from "@core/constants/test";
import {observer} from "mobx-react";
import useStores from "../../../useStores";
import styles from "./styles";

const AddRemoveNewTestType = observer(({classes, numberSelected, selected, type, setSelected}) => {
  const {UserStore} = useStores();

  const companyId = UserStore.user.data.company._id;

  const newTest = {
    assignee: "",
    witnesses: [],
    type,
    company: companyId,
    status: STATUSES.EMPTY,
    properties: {},
    _id: generateID(),
  };

  const addType = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSelected([...selected, newTest]);
  };

  const removeType = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const indexToRemove = findIndex(propEq(type, "type"))(selected);
    setSelected(remove(indexToRemove, 1, selected));
  };

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.minusIcon}
        onClick={removeType}
      >
        <RemoveIcon/>
      </IconButton>
      {numberSelected}
      <IconButton
        className={classes.plusIcon}
        onClick={addType}
      >
        <AddIcon/>
      </IconButton>
    </div>
  );
});

export default withStyles(AddRemoveNewTestType, styles);