import React, {useState} from "react";
import {pick} from "ramda";
import {MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ActionCell from "@core/components/ActionCell";
import ProductForm from "../ProductForm";
import styles from "./styles";

const Actions = ({classes, onDelete, product}) => {
  const [editFormOpen, setEditFormOpen] = useState(false);

  const formattedProduct = {
    properties: {
      ...product.properties,
      location: product.properties.location?._id,
    },
    campaigns: product.campaigns.map((campaign) => ({
      ...campaign,
      campaignId: campaign.campaignId._id,
    })),
    ...pick(["status", "securedBy", "_id"], product),
  };

  return (
    <>
      <ActionCell>
        <MenuItem
          onClick={() => setEditFormOpen(true)}
        >
        View
        </MenuItem>
        <MenuItem
          className={classes.deleteButton}
          onClick={onDelete}
        >
        Delete
        </MenuItem>
      </ActionCell>
      <ProductForm
        product={formattedProduct}
        open={editFormOpen}
        close={() => setEditFormOpen(false)}
      />
    </>
  );
};

export default withStyles(Actions, styles);