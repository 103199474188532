import {sort as rSort, is} from "ramda";
import {ORDERS} from "@core/constants/sort";

const SORT_OPTIONS = {numeric: true, sensitivity: "base"};

const formatItem = ((item = "") => {
  if (is(Array, item)) return item.join();

  return item;
});

export const ascend = (itemA, itemB) => {
  return itemA.localeCompare(itemB, undefined, SORT_OPTIONS);
};

export const descend = (itemA, itemB) => {
  return itemB.localeCompare(itemA, undefined, SORT_OPTIONS);
};

export const sort = (order, orderBy, items) => {
  const handler = order === ORDERS.ASC ? ascend : descend;

  return rSort((a, b) => {
    const itemA = formatItem(a[orderBy]);
    const itemB = formatItem(b[orderBy]);

    return handler(itemA, itemB);
  }, items);
};

export const sortStrings = (order, items) => {
  const handler = order === ORDERS.ASC ? ascend : descend;

  return rSort((a, b) => {
    return handler(a, b);
  }, items);
};