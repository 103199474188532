import React from "react";
import {FormHelperText, IconButton, InputAdornment, TextField} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import styles from "./styles";
const PasswordField = ({classes, autoFocus, onSubmit, showPassword, handleChange, setShowPassword, value, error, touched, name, label}) => (
  <>
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name={name}
      label={label}
      type={showPassword ? "text" : "password"}
      onChange={handleChange}
      value={value}
      autoFocus={autoFocus}
      error={Boolean(error) && touched}
      errorMessage={error}
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <IconButton edge="end" onClick={() => setShowPassword(!showPassword)} size="large">
              {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      inputProps={{
        autocomplete: "off"
      }}
      InputLabelProps={{
        shrink: true
      }}
      onKeyDown={(e) => {
        if (e.key !== "Enter" || !onSubmit) return;

        onSubmit();
      }}
    />
    {(Boolean(error) && touched) && (
      <FormHelperText className={classes.error}>{error}</FormHelperText>
    )}
  </>
);

export default withStyles(PasswordField, styles);