import React from "react";
import {Table, TableBody, TableCell, TableRow, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {generateID} from "@core/helpers";
import styles from "./styles";

const RadioGraphicTestTable = (props) => {

  const {table, classes} = props;
  let tableArr = [];

  if(table){
    tableArr = Object.entries(table).map((entry) => ({[entry[0]]: entry[1]}));
  }

  const countColumns = 8;
  const tables = Array(Math.ceil(tableArr.length / countColumns)).fill();

  return (
    <div className={classes.table}>
      {tables.map((nil, tableIndex) => {
        const from = tableIndex * countColumns;
        const to = tableIndex * countColumns + countColumns;
        const tableData = tableArr.slice(from, to);

        return <Table style={{width: `${((tableData.length + 1) / (countColumns + 1)) * 100}%`}} key={generateID()}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.bold}>Loc</TableCell>
              {tableData.map((el) => {
                return <TableCell key={generateID()}>{Object.keys(el)[0]}</TableCell>;
              })}
            </TableRow>
            <TableRow>
              <TableCell className={classes.bold}>Final Judg.</TableCell>
              {tableData.map((el) => {
                return <TableCell key={generateID()} className={classes.green}>
                  <Tooltip
                    title="Satisfactory"
                    placement="bottom-end"
                    enterDelay={300}
                  ><a>
                      {el[Object.keys(el)[0]]}
                    </a>
                  </Tooltip>
                </TableCell>;
              })}
            </TableRow>
            <TableRow>
              <TableCell className={classes.bold}>Ind.</TableCell>
              {tableData.map(() => {
                return <TableCell key={generateID()}></TableCell>;
              })}
            </TableRow>
          </TableBody>
        </Table>;
      })}
    </div>
  );
};

export default withStyles(RadioGraphicTestTable, styles);
