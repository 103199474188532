import {getPoItemNumber} from "@core/helpers";
import React, {useEffect, useState} from "react";
import {Formik, FieldArray} from "formik";
import * as yup from "yup";
import {values, keys, filter} from "ramda";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Tooltip
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {withStyles} from "tss-react/mui";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import NormAutocomplete from "@core/components/NormAutocomplete";
import ImpactCurve from "@core/components/ImpactCurve";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestFooter from "../../LabTestFooter";
import {TEST_RESULTS} from "@core/constants/testResults";
import {ORIENTATIONS, POSITIONS} from "@core/constants/test";
import useFetchTestNorms from "@core/hooks/useFetchTestNorms";
import {config as norms, locations} from "../ImpactTest/data";
import {WIDTH, NOTCH_TYPES, TEST_STANDARDS, FRACTURE_TYPES, ELEMENT, EXCEPTIONS} from "./data";
import {getTestAcceptable} from "./services";
import styles from "./styles";
import MuiSelect from "@core/components/MuiSelect";
import Uploader from "@core/components/Uploaders/Uploader";

const DropWeightTear = ({
  classes,
  test,
  user,
  saveTest,
  client,
  certificate,
  formRef,
}) => {
  const [grades, setGrades] = useState([]);

  const initialValues = {
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name || "",
    testStandard: "",
    norm: test.properties.norm || certificate?.properties?.norm || "",
    grade: test.properties.grade || certificate?.properties?.grade || "",
    temperature: "",
    transitionCurve: false,
    width: WIDTH,
    thickness: "",
    notchType: "",
    finalProductThickness: "",
    acceptance: "",
    notes: "",
    file: "",
    elements: [ELEMENT],
    requirements: {
      singleShearArea: "",
      averageShearArea: "",
    },
  };

  const validationSchema = yup.object().shape({
    client: yup.string().required("Field is required"),
    lab: yup.string().required("Field is required"),
    testStandard: yup.string().required("Field is required"),
    norm: yup.string().required("Field is required"),
    grade: yup.string().required("Field is required"),
    temperature: yup.number().when("transitionCurve", {
      is: (transitionCurve) => transitionCurve,
      then: yup.number(),
      otherwise: yup.number().required("Field is required")
    }),
    transitionCurve: yup.bool(),
    width: yup.number().required("Field is required"),
    thickness: yup.number().required("Field is required"),
    notchType: yup.string().required("Field is required"),
    finalProductThickness: yup.number(),
    acceptance: yup.string(),
    notes: yup.string(),
    file: yup.string(),
    elements: yup.array().of(yup.object().shape({
      specimen: yup.string().required("Field is required"),
      location: yup.string().required("Field is required"),
      position: yup.string().when("transitionCurve", {
        is: true,
        then: yup.string(),
      }),
      orientation: yup.string().when("transitionCurve", {
        is: true,
        then: yup.string(),
      }),
      fractureType: yup.string().required("Field is required"),
      temperature: yup.number().required("Field is required"),
      shearArea: yup.string().when("fractureType", {
        is: "Normal",
        then: yup.string().required("Field is required")
      }),
    })),
    requirements: yup.object().shape({
      singleShearArea: yup.string(),
      averageShearArea: yup.string(),
    }),
  });

  useFetchTestNorms("certificate", initialValues.norm, (gradesByNorm) => setGrades(values(gradesByNorm)));

  const onSubmit = (values) => {
    const isAcceptable = getTestAcceptable(values);
    const result = isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
    saveTest({...values, result});
  };

  const filteredAcceptances = filter((ec) => !ec.company || ec.company.includes(user.company.name), EXCEPTIONS);

  return (
    <Formik
      onSubmit={onSubmit}
      innerRef={formRef}
      initialValues={{...initialValues, ...test.properties}}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(props) => {
        useEffect(() => {
          if(!props.touched.norm) return;

          props.setFieldValue("grade", "");
        }, [props.values.norm]);

        useEffect(() => {
          if(!props.touched.grade) return;

          props.setFieldValue("temperature", "");
        }, [props.values.grade]);

        useEffect(() => {
          if(props.values.transitionCurve) return;

          const elements = props.values.elements.map((element) => ({
            ...element,
            temperature: props.values.temperature
          }));

          props.setFieldValue("elements", elements);
        }, [props.values.temperature]);

        useEffect(() => {
          if(!props.touched.transitionCurve) return;

          const [element] = props.values.elements;

          const elements = [{...element, temperature: props.values.temperature}];

          props.setFieldValue("elements", elements);
        }, [props.values.transitionCurve]);

        useEffect(() => {
          if(!props.touched.acceptance && !props.touched.location) return;

          onRequirements();
        }, [props.values.acceptance, props.values.location]);

        const getRequirements = (data) => {
          if(data.locationDependantValues) {
            return data.locationDependantValues.find((ldv) => ldv.location === props.values.location) || {};
          }

          return data;
        };

        const getTransitionCurveElements = (config) => {
          const poItemNumber = getPoItemNumber(certificate.lineItem);
          const temperatures = config.temperaturesByPoItem && poItemNumber ? config.temperaturesByPoItem[poItemNumber] :  config.temperatures;

          const elements = temperatures.map(({value: temperature}) => ({
            ...ELEMENT,
            temperature,
          }));

          props.setFieldValue("elements", elements);
        };

        const onRequirements = () => {
          const poItemNumber = getPoItemNumber(certificate.lineItem);

          const {temperature: config = {}} = EXCEPTIONS[props.values.acceptance] || {};

          const temperature = config.temperatureByPoItem && poItemNumber ? config.temperatureByPoItem[poItemNumber] :  config.value;
          props.setFieldValue("temperature", temperature || "");

          const {singleShearArea, averageShearArea} = getRequirements(config.data || {});

          props.setFieldValue("requirements.singleShearArea", singleShearArea || "");
          props.setFieldValue("requirements.averageShearArea", averageShearArea || "");
          props.setFieldValue("notes", config.notes || "");

          if(config.transitionCurve) {
            getTransitionCurveElements(config);
            props.setFieldValue("transitionCurve", true);
          }
        };

        const normConfig = norms.find((norm) => norm.value === props.values.norm) || {};
        const gradeConfig = normConfig.grade?.find((grade) => grade.value === props.values.grade) || {};
        const temperatureConfig = gradeConfig.temperature?.find((temperature) => temperature.value === props.values.temperature) || {};

        const isAcceptable = getTestAcceptable(props.values);
        const result = isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

        return (
          <>
            <Grid container spacing={5} alignItems="flex-end">
              <Grid item xs={3}>
                <ClientField
                  isFromProducer={!!client.name}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  name="lab"
                  label="Laboratory"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={TEST_STANDARDS}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end">
              <Grid item xs={3}>
                {certificate._id ? (
                  <TextField
                    label="Material Specification"
                    name="norm"
                    required
                    disabled
                  />
                ) : (
                  <NormAutocomplete
                    label="Material Specification"
                    name="norm"
                    testType="certificate"
                    onChange={({norm, value: grades}) => {
                      props.setFieldValue("norm", norm || "");
                      setGrades(grades || []);
                    }}
                    required
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                {certificate._id ? (
                  <TextField
                    label="Grade / UNS"
                    name="grade"
                    required
                    disabled
                  />
                ) : (
                  <SelectField
                    label="Grade / UNS"
                    name="grade"
                    required
                  >
                    {grades.map((grade) => (
                      <MenuItem key={grade.Material} value={grade.Material}>{grade.Material}</MenuItem>
                    ))}
                  </SelectField>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end">
              {!props.values.transitionCurve && (
                <Grid item xs={3}>
                  {gradeConfig.temperature ? (
                    <SelectField
                      required
                      label="Test Temperature"
                      name="temperature"
                    >
                      {gradeConfig.temperature.map((temperature) => (
                        <MenuItem key={temperature.value} value={temperature.value}>{temperature.value}</MenuItem>
                      ))}
                    </SelectField>
                  ) : (
                    <TextField
                      required
                      label="Test Temperature"
                      name="temperature"
                      type="number"
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.values.transitionCurve}
                      onChange={(e) => props.setFieldValue("transitionCurve", e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Transition curve"
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end">
              <Grid item xs={3}>
                <TextField
                  label="Specimen Dimension"
                  name="width"
                  required
                  disabled
                  endAdornment="MM"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Thickness"
                  name="thickness"
                  required
                  endAdornment="MM"
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  required
                  label="Notch Type"
                  name="notchType"
                >
                  {NOTCH_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Final Product Thickness"
                  name="finalProductThickness"
                  required={temperatureConfig.data?.thicknessDependantValues}
                  endAdornment="MM"
                  type="number"
                />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <SelectField
                  label="Load requirements"
                  name="acceptance"
                >
                  <MenuItem key="N/A" value={undefined}>N/A</MenuItem>
                  {keys(filteredAcceptances).map((name) => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Specimen ID</TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Position</TableCell>
                      <TableCell align="center">Orientation</TableCell>
                      <TableCell align="center">Temperature [&#8451;]</TableCell>
                      <TableCell align="center">Fracture type</TableCell>
                      <TableCell align="center">Shear area [%]</TableCell>
                      {props.values.requirements.averageShearArea && (
                        <TableCell>
                          <div className={classes.shearAreaAverage}>
                            Shear area average [%]&nbsp;
                            <Tooltip title="Shear area average across all test specimens">
                              <InfoIcon className={classes.info}/>
                            </Tooltip>
                          </div>
                        </TableCell>
                      )}
                      {props.values.transitionCurve && (
                        <TableCell />
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="elements">
                      {({remove}) => props.values.elements.map((element, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            <TextField
                              name={`elements.${index}.specimen`}
                              required
                            />
                          </TableCell>
                          <TableCell align="center">
                            <SelectField
                              required
                              name={`elements.${index}.location`}
                            >
                              {locations.map((location) => <MenuItem key={location} value={location}>{location}</MenuItem>)}
                            </SelectField>
                          </TableCell>
                          <TableCell align="center">
                            <SelectField
                              required={props.values.transitionCurve}
                              name={`elements.${index}.position`}
                            >
                              {POSITIONS.map((position) => (<MenuItem key={position} value={position}>{position}</MenuItem>))}
                              <MenuItem value={undefined}>N/A</MenuItem>
                            </SelectField>
                          </TableCell>
                          <TableCell align="center">
                            <SelectField
                              required={props.values.transitionCurve}
                              name={`elements.${index}.orientation`}
                            >
                              {ORIENTATIONS.map((orientation) => <MenuItem key={orientation} value={orientation}>{orientation}</MenuItem>)}
                              <MenuItem value={undefined}>N/A</MenuItem>
                            </SelectField>
                          </TableCell>
                          <TableCell align="center">
                            {props.values.transitionCurve ? (
                              <TextField
                                type="number"
                                name={`elements.${index}.temperature`}
                                required
                              />
                            ) : (
                              element.temperature
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <SelectField
                              required
                              name={`elements.${index}.fractureType`}
                            >
                              {FRACTURE_TYPES.map((fractureType) => <MenuItem key={fractureType} value={fractureType}>{fractureType}</MenuItem>)}
                            </SelectField>
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              name={`elements.${index}.shearArea`}
                              required={element.fractureType === "Normal"}
                              type="number"
                            />
                          </TableCell>
                          {props.values.requirements.averageShearArea && (
                            <TableCell>
                              {props.values.requirements.averageShearArea}
                            </TableCell>
                          )}
                          {props.values.elements.length > 1 && (
                            <TableCell align="center">
                              <IconButton
                                className={classes.removeButton}
                                onClick={() => remove(index)}
                              >
                                <CancelSharpIcon />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </FieldArray>
                    <TableRow>
                      <TableCell className={classes.hideBorder} colSpan={6} />
                      <TableCell className={classes.hideBorder} align="center" colSpan={1}>
                        {props.values.requirements.singleShearArea || null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item container justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={() => props.setFieldValue("elements", [...props.values.elements, {...ELEMENT, temperature: props.values.temperature}])}
                    color="primary"
                    variant="contained"
                  >
                      Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  rows={4}
                  multiline
                  name="notes"
                  label="Notes"
                />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <p>Test sketch</p>
                <Uploader
                  fileType={"image/*, application/pdf, .csv"}
                  file={props.values.file}
                  buttonText={"Choose file"}
                  handleUploadedFile={(file) => props.setFieldValue("file", file.file.dir + file.file.name)}
                  onRemove={() => props.setFieldValue("file", "")}
                />
              </Grid>
            </Grid>
            {props.values.transitionCurve && (
              <ImpactCurve
                elements={props.values.elements.map((element) => ({
                  ...element,
                  values: [element.shearArea]
                }))}
                valuesLabel="Total shear area [%]"
              />
            )}
            <TestFooter
              onSubmit={onSubmit}
              result={result}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default withStyles(DropWeightTear, styles);