export default (theme) => ({
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#cccccc",
    margin: "20px 0"
  },
  satisfactory : {
    color: "#4caf50",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },
  unsatisfactory: {
    color: "#d02d52",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },
  helpIcon: {
    display: "inline-flex",
    alignItems: "center",
  },
});
