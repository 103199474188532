export default () => ({
  productType: {
    color: "#616161",
    marginBottom: 15
  },
  item: {
    paddingBottom: 40
  },
  remainingQuantity: {
    marginBottom: 13
  },
  propertiesList: {
    marginBottom: 10,
  },
  checkbox: {
    margin: "0 -14px",
  },
  certificatesContainer: {
    overflowY: "auto",
    maxHeight: 510,
  }
});