import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Box, Divider, Grid} from "@mui/material";
import LegendWrapper from "../../../Footer/components/LegendWrapper";
import {
  AS_BUILD_TIE_IN_WELD_CONFIG,
  PIPE_CONFIG,
  REPAIRED_WELD_CONFIG,
  TIE_IN_WELD_CONFIG,
  WELD_CONFIG
} from "../../constants";
import {STATE, PIPE_WITH_TYPE_CONFIG} from "../../../../constants";
import useStores from "../../../../../../../../../../useStores";

const PIPE_LEGEND_CONFIG = {
  BORDER: `1px solid ${PIPE_CONFIG.BORDER_COLOR}`,
  HEIGHT: 10,
  WIDTH: 25,
};

const WELD_LEGEND_CONFIG = {
  BORDER: `1px solid ${WELD_CONFIG.BORDER_COLOR}`,
  HEIGHT: 10,
  WIDTH: 8,
};

const TIE_IN_WELD_LEGEND_CONFIG = {
  BORDER: `1px solid ${TIE_IN_WELD_CONFIG.BORDER_COLOR}`,
  HEIGHT: 10,
  WIDTH: 20,
};

const Legend = ({settings, state, isWrapped}) => {
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;
  const baseMaterialsById = CampaignStore.campaignProductsById;

  const baseMaterialsTypes = useMemo(() => {
    return CampaignStore.baseMaterialsTypes.slice(0, PIPE_WITH_TYPE_CONFIG.BACKGROUND_COLORS.length);
  }, [CampaignStore.baseMaterialsTypes.length]);

  const isEveryPipeWithType = useMemo(() => {
    return campaign.welds.every((weld) => baseMaterialsById[weld.pipeA]?.type &&  baseMaterialsById[weld.pipeB]?.type);
  }, [campaign.welds.length, campaign.products.length]);

  return (
    <LegendWrapper isWrapped={isWrapped}>
      {!isEveryPipeWithType && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  border: PIPE_LEGEND_CONFIG.BORDER,
                  backgroundColor: PIPE_CONFIG.COLOR,
                  height: PIPE_LEGEND_CONFIG.HEIGHT,
                  width: PIPE_LEGEND_CONFIG.WIDTH,
                }}
              />
            </Grid>
            <Grid item>
              Pipe
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {Boolean(settings.type && baseMaterialsTypes.length) && (
        baseMaterialsTypes.map((type, index) => (
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: PIPE_WITH_TYPE_CONFIG.BACKGROUND_COLORS[index],
                    border: PIPE_LEGEND_CONFIG.BORDER,
                    height: PIPE_LEGEND_CONFIG.HEIGHT,
                    width: PIPE_LEGEND_CONFIG.WIDTH
                  }}
                />
              </Grid>
              <Grid item>
                {type}
              </Grid>
              <Grid item>
                <Divider orientation="vertical" sx={{height: 20}} />
              </Grid>
            </Grid>
          </Grid>
        ))
      )}
      {settings.welds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  border: WELD_LEGEND_CONFIG.BORDER,
                  backgroundColor: WELD_CONFIG.COLOR,
                  width: WELD_LEGEND_CONFIG.WIDTH,
                  height: WELD_LEGEND_CONFIG.HEIGHT
                }}
              />
            </Grid>
            <Grid item>
              Weld
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {settings.highlightRepairedWelds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  border: WELD_LEGEND_CONFIG.BORDER,
                  backgroundColor: REPAIRED_WELD_CONFIG.COLOR,
                  width: WELD_LEGEND_CONFIG.WIDTH,
                  height: WELD_LEGEND_CONFIG.HEIGHT
                }}
              />
            </Grid>
            <Grid item>
              Repaired weld
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {settings.tieInWelds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              {state === STATE.AS_BUILT ? (
                <Box
                  sx={{
                    border: WELD_LEGEND_CONFIG.BORDER,
                    backgroundColor: AS_BUILD_TIE_IN_WELD_CONFIG.COLOR,
                    width: WELD_LEGEND_CONFIG.WIDTH,
                    height: WELD_LEGEND_CONFIG.HEIGHT
                  }}
                />
              ) : (
                <Box
                  sx={{
                    border: TIE_IN_WELD_LEGEND_CONFIG.BORDER,
                    height: TIE_IN_WELD_LEGEND_CONFIG.HEIGHT,
                    width: TIE_IN_WELD_LEGEND_CONFIG.WIDTH,
                    borderRadius: "50%",
                  }}
                />
              )}
            </Grid>
            <Grid item>
              Tie-in weld
            </Grid>
          </Grid>
        </Grid>
      )}
    </LegendWrapper>
  );
};

export default observer(Legend);