export default (theme) => ({
  newCertificate: {
    color: "#616161",
    border: "1px solid #ced4da",
    borderRadius: 10
  },
  newCertificateHeader: {
    padding: 20
  },
  newItem: {
    padding: "20px 23px 20px 0",
  },
  closeIcon: {
    fill: "#616161",
    cursor: "pointer"
  },
  value: {
    fontSize: 18,
    marginTop: 10
  },
  uploader: {
    "& .avatar": {
      width: "100%",
    }
  },
  lotId: {
    marginLeft: 17,
    marginTop: 20,
  },
  button: {
    padding: 0,
    paddingRight: 4,
    paddingLeft: 4,
    marginTop: 5,
    display: "block",
  },
  property: {
    fontWeight: theme.typography.fontWeightMedium,
  }
});