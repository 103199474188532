import React, {useState} from "react";
import {head, drop, indexBy, prop, omit, values} from "ramda";
import {List, ListItem, Collapse} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {capitalizeFirstLetter} from "@core/helpers";
import styles from "./styles";

function fromCamelcase(str) {
  const result = str.replace( /([A-Z])/g, " $1" );

  return capitalizeFirstLetter(result);
}

const Item = ({item}) => {
  const valueByField = indexBy(prop("field"), item);

  return (
    <div>
      {`Q: ${valueByField?.quantity?.value || "Not found"}`}
      {values(omit(["quantity"], valueByField)).map(({field, value}) => {
        return (
          <React.Fragment key={field}>
            &nbsp;/&nbsp;
            {`${fromCamelcase(field)}: ${value}`}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const DesiredItems = ({classes, items}) => {
  const [open, setOpen] = useState(false);

  const first = head(items) || {};
  const rest = drop(1, items) || [];

  return (
    <List>
      <ListItem classes={{root: classes.listItem}} disableGutters dense={true} onClick={() => setOpen(!open)}>
        <Item
          item={first}
        />
        {rest.length ? (open ? <ExpandLess /> : <ExpandMore />) : null}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {rest.map((item) => (
          <ListItem classes={{root: classes.listItem}} disableGutters dense={true}>
            <Item
              item={item}
              key={item._id}
            />
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
};

export default withStyles(DesiredItems, styles);
