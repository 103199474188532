import React, {useState, useMemo, useCallback, useRef, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {map, omit, keys, flatten, indexBy, prop, isNil, reject} from "ramda";
import {useTheme} from "@mui/material/styles";
import {
  Grid,
  Divider,
  Box,
  Tab,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Tabs,
} from "@mui/material";
import TestsList from "../TestsList";
import TestDrawerNavigation from "../TestDrawerNavigation";
import RawMaterialsTable from "../RawMaterialsTable";
import DefectLog from "../DefectLog";
import useStores from "../../../../../../useStores";
import {FILTERS} from "@core/constants/filters";
import {ENTITIES} from "../../../../constants";
import {CAMPAIGN_SPECIMEN_LABELS, CAMPAIGN_TYPES} from "@core/constants/campaign";
import qs from "qs";
import {useHistory, useLocation} from "react-router";
import {filterTests, getPipeDefects, getPipeProductionTests} from "../../../../helpers";

const TEST_TYPES = {
  BASE_MATERIAL: "Base Material",
  COATING: "Coating",
  WELDING: "Welding",
};

const PipeTestsDrawer = observer(({close, open, index, welds = [], pipes = [], navigate}) => {
  const [filteredTestIds, setFilteredTestIds] = useState([]);
  const [testsByType, setTestsByType] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  const testsRefs = useRef({});

  const {CampaignStore} = useStores();

  const theme = useTheme();

  const campaign = CampaignStore.campaign;
  const transfers = CampaignStore.transfers;

  const {type, ...search} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const baseMaterialsById = useMemo(() => indexBy(prop("_id"), campaign.products), [campaign.products]);
  const weldsByPosition = useMemo(() => indexBy(prop("position"), welds), [welds]);

  const joint = useMemo(() => {
    const pipe = pipes[index] || {};

    return pipe;
  }, [index, pipes]);

  const pipe = useMemo(() => {
    if(!pipes.length || isNil(index)) return {};

    const id = joint._id || "";

    return baseMaterialsById[id] || {};
  }, [index, pipes]);

  const prevPipe = useMemo(() => {
    const prevPipe = pipes[index - 1] || {};

    return prevPipe;
  }, [index]);

  const nextPipe = useMemo(() => {
    const nextPipe = pipes[index + 1] || {};

    return nextPipe;
  }, [index]);

  const prevWeld = useMemo(() => {
    const prevWeld = weldsByPosition[index - 1] || welds[index - 1] || {};

    return prevWeld;
  }, [index]);

  const nextWeld = useMemo(() => {
    const nextWeld = weldsByPosition[index] || welds[index] ||{};

    return nextWeld;
  }, [index]);

  const setType = useCallback((type) => {
    const newSearch = type ? {...search, type} : omit(["type"], search);
    
    history.push({
      pathname: history.pathname,
      search: qs.stringify(newSearch),
    });
  }, [history.pathname, search]);

  useEffect(() => {
    if(open && !type) {
      setType(joint.weldNumber ? TEST_TYPES.WELDING: TEST_TYPES.BASE_MATERIAL);
    }

    if(!open) {
      setType(null);
      setTestsByType({});
    }
  }, [open]);

  const onTestClick = useCallback((_, testId) => {
    testsRefs.current[testId].scrollIntoView({behavior: "smooth", block: "start"});
  }, []);

  const productionTests = useMemo(() => {
    return transfers.reduce((tests, transfer) => {
      const transferTests = flatten(transfer.certificates.map((certificate) => certificate.tests));

      return tests.concat(transferTests);
    }, []);
  }, [transfers.length]);

  const campaignsById = useMemo(() => {
    const campaignsById = transfers.reduce((campaignsById, transfer) => {
      const coatingCampaigns = transfer.campaigns.filter((campaign) => campaign.type === CAMPAIGN_TYPES.COATING);

      return {...campaignsById, ...indexBy(prop("_id"), coatingCampaigns)};
    }, {});

    delete campaignsById[campaign._id];

    return campaignsById;
  }, [transfers.length]);

  const coatingTestsByCampaignId = useMemo(() => map((campaign) => campaign.tests, campaignsById), [campaignsById]);

  const coatingsIds = useMemo(() => keys(campaignsById), [campaignsById]);

  useEffect(() => {
    const pipeProductionTests = getPipeProductionTests(pipe, productionTests);
    const weldingTests = filterTests({
      [FILTERS.SPECIMEN_ID]: [pipe.pipeNumber],
    }, campaign.tests, campaign.rawMaterials, {welds: [prevWeld.weldNumber, nextWeld.weldNumber]});

    setTestsByType({
      [TEST_TYPES.BASE_MATERIAL]: pipeProductionTests,
      [TEST_TYPES.WELDING]: weldingTests,
      ...coatingTestsByCampaignId,
    });

    setIsLoading(false);
  }, [pipe._id, transfers.length]);

  const tests = testsByType[type] || [];

  const testsById = useMemo(() => indexBy(prop("_id"), tests), [type, tests]);
  const filteredTests = useMemo(() => filteredTestIds.length ? reject(isNil, filteredTestIds.map((id) => testsById[id])) : tests,  [filteredTestIds, tests]);

  return (
    <>
      <TestDrawerNavigation
        close={close}
        text={`${joint.pipeNumber}: Tests Reports (${tests.length})`}
        secondPrevItem={joint.weldNumber ? prevPipe : null}
        prevItem={joint.weldNumber ? prevWeld : prevPipe}
        nextItem={joint.weldNumber ? nextWeld : nextPipe}
        secondNextItem={joint.weldNumber ? nextPipe : null}
        index={index}
        welds={welds}
        pipes={pipes}
        entity={ENTITIES.PIPE}
        navigate={navigate}
      />
      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: theme.palette.divider,
          marginLeft: theme.spacing(),
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={type}
          onChange={(e, value) => setType(value)}
        >
          <Tab
            disabled={!testsByType[TEST_TYPES.BASE_MATERIAL]}
            label={TEST_TYPES.BASE_MATERIAL}
            value={TEST_TYPES.BASE_MATERIAL}
          />
          {keys(coatingTestsByCampaignId).map((id, index) => (
            <Tab
              key={id}
              label={campaignsById[id].coatingType || `${TEST_TYPES.COATING}  ${index + 1}`}
              value={id}
            />
          ))}
          <Tab
            disabled={!testsByType[TEST_TYPES.WELDING]}
            label={campaign.coatingType || TEST_TYPES.WELDING}
            value={TEST_TYPES.WELDING}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <Box sx={{marginBottom: theme.spacing()}}>
          <h3>{type === TEST_TYPES.WELDING ? "Joint Information" : "Base Material Information"}</h3>
        </Box>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Product Type</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Heat</TableCell>
              <TableCell>Pipe ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{pipe.productType || "-"}</TableCell>
              <TableCell>{pipe.manufacturer}</TableCell>
              <TableCell>{pipe.description || "-"}</TableCell>
              <TableCell>{pipe.heat}</TableCell>
              <TableCell>{pipe.pipeNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          padding: theme.spacing(2),
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {(pipe.quarantine || pipe.quarantineReason) && (
            <>
              <Grid item xs={12}>
                <Box sx={{marginBottom: theme.spacing()}}>
                  <h3>Quarantine Information</h3>
                </Box>
                <Table className="styled-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pipe ID</TableCell>
                      <TableCell>Quarantine</TableCell>
                      <TableCell>Quarantine reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{pipe.pipeNumber}</TableCell>
                      <TableCell>{pipe.quarantine ? "Yes" : "No"}</TableCell>
                      <TableCell>{pipe.quarantineReason || "-"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          <DefectLog
            getDefects={getPipeDefects}
            welds={[prevWeld.weldNumber, nextWeld.weldNumber]}
          />
          {coatingsIds.includes(type) && (
            <RawMaterialsTable
              id={pipe.pipeNumber}
              rawMaterials={campaignsById[type].rawMaterials}
              label="Coating Raw material(s)"
              type={CAMPAIGN_SPECIMEN_LABELS.PIPE}
            />
          )}
          <Grid item xs={12}>
            <TestsList
              tests={filteredTests}
              productId={pipe.pipeNumber}
              isLoading={isLoading}
              testsRefs={testsRefs}
              setFilteredTestIds={setFilteredTestIds}
              onTestClick={onTestClick}
              allTests={tests}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default PipeTestsDrawer;