import {includes, intersection, is, keys} from "ramda";

export const filterElements = (filter, elements) => {
  return elements.filter((element) => keys(filter).every((name) => {
    const value = element[name]?.length ? element[name] : "";
    const filterValue = filter[name] || [];

    const condition = is(Array, value) ? intersection(value, filterValue).length : includes(value, filterValue);

    return !filterValue.length || condition;
  }));
};