import {getColumns as getDefaultColumns} from "@core/columns/campaign";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";

export const getColumns = (module) => {
  const defaultColumns = getDefaultColumns(module.name);

  return [
    ...defaultColumns,
    {
      isSorted: "action",
      dataIndex: "action",
      title: "Action",
      render: (campaign) => (
        <Button
          component={Link}
          color="primary"
          to={`/campaigns/${campaign.type.toLowerCase()}/${campaign._id}`}
          target="_blank"
        >
          View
        </Button>
      ),
    }
  ];
};