import LoginPage from "../components/LoginContainer";
import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import LoginForm from "./components/LoginForm";
import VerificationForm from "../components/VerificationForm";

const Login = () => {
  const [user, setUser] = useState(null);
  const refreshToken = localStorage.getItem("refreshToken");

  if (refreshToken && refreshToken.length !== 0) {
    return <Redirect to={"/"} />;
  }

  return (
    <LoginPage>
      {!user ? (
        <LoginForm setUser={setUser} />
      ) : (
        <VerificationForm user={user} />
      )}
    </LoginPage>
  );
};

export default Login;
