import React from "react";
import {Button, Grid, IconButton, TableCell, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Uploader} from "@core/components/Uploaders";
import File from "@core/components/File";

import styles from "./styles";
import {GrDocumentUpload} from "react-icons/gr";
import {IoMdCloseCircleOutline} from "react-icons/io";
import CheckIcon from "@mui/icons-material/Check";
import {MdEditNote} from "react-icons/md";
import EditableCell from "../EditableCell";
import {isEmpty} from "ramda";

export default withStyles(
  (props) => {
    const {values, index, actions, editable, classes, disabled} = props;
    const {name, url, image} = values;

    const errors = props.errors[index] || {};
    const touched = props.touched[index] || {};

    return (
      <TableRow>
        <TableCell>
          <EditableCell
            id={name.path}
            disabled={disabled}
            placeholder={"Name"}
            classes={classes}
            value={name.value}
            required = {true}
            editable={editable}
            error={errors.name}
            touched={touched.name}
            onChange={(e) => actions.change(name.path, e)}
            onBlur={() => actions.blur(name.path, "name")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            id={url.path}
            disabled={disabled}
            placeholder={"URL"}
            classes={classes}
            value={url.value}
            editable={editable}
            error={errors.url}
            touched={touched.url}
            onChange={(e) => actions.change(url.path, e)}
            onBlur={() => actions.blur(url.path, "url")}
          />
        </TableCell>
        <TableCell width="250" className={classes.fileCell}>
          {editable ? (
            <Uploader
              disabled={disabled}
              className={classes.avatarUpload}
              file={image.value}
              buttonText="Upload image"
              handleUploadedFile={(e) => actions.uploadImage(image.path, e)}
              onRemove={() => actions.resetPreviewImage(image.path)}
              uploadButton={(
                <Button
                  className={classes.uploadButton}
                  component="span"
                >
                  <GrDocumentUpload size="20px" />
                </Button>
              )}

              removeButton={(
                <IconButton
                  className={classes.removeButton}
                  onClick={() => actions.resetPreviewImage(image.path)}
                  size="large">
                  <IoMdCloseCircleOutline />
                </IconButton>
              )}
            />
          ) : (
            (image.value) ? (
              <File
                preview
                file={image.value}
              />) :
              "-"
          )}
        </TableCell>
        <TableCell width="130" className={classes.actionCell}>
          <Grid container justifyContent='start' spacing={1}>
            <Grid item>
              <IconButton
                onClick={() => actions.save()}
                disabled={(editable && !isEmpty(errors)) || disabled}
                size="large">
                {editable?(<CheckIcon/>):(<MdEditNote/>)}
              </IconButton>
            </Grid>
            {actions.remove &&<Grid item>
              <IconButton
                className={classes.removeButton}
                onClick={() => actions.remove()}
                size="large">
                <IoMdCloseCircleOutline />
              </IconButton>
            </Grid>}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }, styles);
