import React, {useState} from "react";
import {FormControlLabel, Grid, Radio, RadioGroup} from "@mui/material";
import {prop, uniqBy} from "ramda";
import PositionTable from "./PositionTable";

const sortElements = (elements, elementsHistory) => {
  const elementsSortedByQuadrants = elementsHistory.reduce((acc, history) => {
    const positions = history.position.split(", ");
    const quadrantElements = elements.filter((element) => positions.includes(element.position.toString()));
    const quadrantsElementsWithMetal = quadrantElements.map((element) => ({...element, metal: history.metal}));
    acc.push(...quadrantsElementsWithMetal);

    return acc;
  }, []);

  const positionsWithQuadrant = elementsSortedByQuadrants.map((el) => el.position);
  const elementsWithoutQuadrant = elements.filter((el) => !positionsWithQuadrant.includes(el.position));

  return elementsSortedByQuadrants.concat(elementsWithoutQuadrant);
};

const PositionView = ({test}) => {
  const {elements, elementsHistory} = test.properties;

  const [value, setValue] = useState("all");
  const [tableData, setTableData] = useState(sortElements(elements, elementsHistory));

  const handleChange = (test) => {
    const {value} = test.target;

    if (value === "all") {
      const tableData = sortElements(elements, elementsHistory);
      setTableData(tableData);
    } else {
      const history = elementsHistory.find((history) => history.metal === value);
      const positions = history.position.split(", ");
      const quadrantElements = elements.filter((element) => positions.includes(element.position.toString()));
      setTableData(quadrantElements);
    }

    setValue(value);
  };

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <RadioGroup
            aria-label="position"
            name="position"
            color="primary"
            value={value}
            onChange={handleChange}
            row
          >
            <FormControlLabel value="all" control={<Radio color="primary" />} label="All" />

            {uniqBy(prop("metal"), elements).map((el) => {
              const elementHistory = elementsHistory.find((e) => {
                const positions = e.position.split(", ");

                return positions.includes((el.position.toString()).toString());
              });

              const label = elementHistory ? elementHistory.metal : el.metal;

              if (label.length > 0) {
                return (
                  <FormControlLabel
                    key={label}
                    value={label}
                    control={<Radio color="primary" />}
                    label={label.toUpperCase()}
                  />
                );
              }
            })}

          </RadioGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <PositionTable
            value={value}
            table={tableData}
            elementsHistory={elementsHistory}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PositionView;