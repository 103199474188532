import {action, makeObservable, observable} from "mobx";
import axios from "axios";
import baseStore from "@core/helpers/baseStore";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class ConditionStore extends baseStore {
  constructor(props) {
    super(props);
    makeObservable(this, {
      conditions: observable,
      condition: observable,
      conditionsPresets: observable,
      getByCertificateId: action,
      getPresetsByCompany: action,
      getConditionByTestId: action,
      create: action,
      createPreset: action,
      update: action,
      deleteTest: action,
      deleteConditionPreset: action,
    });
  }
  conditions = {
    data: [],
    isLoaded: false
  };

  condition = {
    data: {},
    isLoaded: false
  };

  conditionsPresets = {
    data: [],
    isLoaded: false
  };

  async getByCertificateId(id) {
    this.conditions.isLoaded = false;

    const response = await axios.get(ROUTES.CONDITION[ACTIONS.SINGLE_BY_CERTIFICATE_ID](id))
      .catch((error) => error);

    this.conditions.data = response.data;
    this.conditions.isLoaded = true;
  }

  async getPresetsByCompany() {
    this.conditions.isLoaded = false;

    const response = await axios.get(ROUTES.CONDITION_PRESET[ACTIONS.ALL])
      .catch((error) => error);

    this.conditionsPresets.data = response.data;
    this.conditionsPresets.isLoaded = true;
  }

  async getConditionByTestId(testId) {
    this.condition.isLoaded = false;

    const res = await axios.get(ROUTES.CONDITION[ACTIONS.SINGLE_BY_TEST_ID](testId), {
      withCredentials: true
    });

    this.condition.isLoaded = true;
    this.condition.data = res.data;
  }

  async create(data) {
    this.condition.isLoaded = false;

    const response = await axios.post(ROUTES.CONDITION[ACTIONS.CREATE], data, {
      withCredentials: true,
    }).catch((error) => error);

    this.condition.data = response.data;
    this.condition.isLoaded = true;
  }

  async createPreset(data) {
    this.condition.isLoaded = false;

    const response = await axios.post(ROUTES.CONDITION_PRESET[ACTIONS.CREATE], data, {
      withCredentials: true,
    }).catch((error) => error);

    this.condition.data = response.data;
    this.condition.isLoaded = true;
  }

  async update(changes, id) {
    const response = await axios.patch(ROUTES.CONDITION[ACTIONS.UPDATE](id), changes);

    this.condition.data = response.data;
  }

  async deleteTest(testId) {
    await axios.delete(ROUTES.CONDITION[ACTIONS.REMOVE_TEST](testId), {
      withCredentials: true,
      successMessage: "The test has been removed"
    });
  }

  async deleteConditionPreset(id) {
    await axios.delete(ROUTES.CONDITION_PRESET[ACTIONS.REMOVE](id), {
      withCredentials: true,
      successMessage: "The condition preset has been removed"
    });
  }
}

export default new ConditionStore();
