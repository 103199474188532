import React, {useEffect, useState} from "react";
import {Box, Skeleton} from "@mui/material";
const Label = ({subTab}) => {
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 500);
  }, []);
  
  return (
    <Box
      sx={{
        position: "relative"
      }}
    >
      {isLoading && (
        <Skeleton
          width="100%"
          sx={{
            visibility: "visible",
            position: "absolute"
          }}
        />
      )}
      <Box
        sx={{
          color: isLoading ? "transparent" : "inherit"
        }}
      >
        {subTab}
      </Box>
    </Box>
  );
};

export default Label;