import React, {useContext, useEffect} from "react";
import Loader from "@core/components/Loader";
import {filter} from "ramda";
import userService from "@core/api/user-service";
import {observer} from "mobx-react-lite";
import functionalCompany from "../../Companies/stores/functionalCompany";
import CompanyForm from "../../core/components/CompanyForm";

const Admin = observer(() => {
  const companyStore = useContext(functionalCompany);

  // TODO Need change mobx-react to mobx-react-light
  // const Notifications = useContext(functionalNotification)
  const company = companyStore.company.data;

  // TODO Need to be able to do like this
  // const currentUser =  useContext(functionalUser)

  useEffect(() => {
    userService.getUserInfo()
      .then(async (currentUser) => {
        await companyStore.getCompanyById(currentUser.company._id);
      });
  }, []);

  if (!companyStore.company.isLoaded) {
    return <Loader />;
  }

  const initialValues = {
    name: company.name,
    image: company.image,
    country: company.mainLocation ? company.mainLocation.country : "",
    city: company.mainLocation ? company.mainLocation.city : "",
    street: company.mainLocation ? company.mainLocation.street : "",
    house: company.mainLocation ? company.mainLocation.house : "",
    postalCode: company.mainLocation ? company.mainLocation.postalCode : "",
    plantDescription: company.mainLocation ? company.mainLocation.plantDescription : "",
    phone: company.phone,
    fax: company.fax,
    logo: company.logo,
    email: company.email,
    website: company.website,
    partners: company.partners,
    approvals: company.approvals,
    modules: company.modules,
    features: company.features,
    locations: filter((location) => location._id !== company.mainLocation?._id, company.locations),
  };

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      image: values.image,
      locations: values.locations,
      mainLocation: {
        country: values.country,
        city: values.city,
        street: values.street,
        house: values.house,
        postalCode: values.postalCode,
        plantDescription: values.plantDescription
      },
      logo: values.logo,
      phone: values.phone,
      fax: values.fax,
      email: values.email,
      website: values.website,
      partners: values.partners,
      approvals: values.approvals,
      features: values.features
    };

    await companyStore.update(data, company._id);
  };

  return (
    <CompanyForm
      header="Admin"
      company={initialValues}
      onSubmit={onSubmit}
    />
  );
});

export default Admin;
