import TestResultTableCell from "@core/components/TestResultTableCell";
import React, {useMemo} from "react";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import File from "@core/components/File";

const CrackTipOpeningDisplacement = ({classes, test}) => {
  const specimenLength = useMemo(() => test.properties.elements.some((element) => element.specimenLength), []);
  const specimenWidth = useMemo(() => test.properties.elements.some((element) => element.specimenWidth), []);
  const specimenThickness = useMemo(() => test.properties.elements.some((element) => element.specimenThickness), []);
  const appliedLoad = useMemo(() => test.properties.elements.some((element) => element.appliedLoad), []);
  const loadRate = useMemo(() => test.properties.elements.some((element) => element.loadRate), []);
  const a0 = useMemo(() => test.properties.elements.some((element) => element.a0), []);
  const clipGageDisplacement = useMemo(() => test.properties.elements.some((element) => element.clipGageDisplacement), []);
  const crackTipOpeningDisplacement = useMemo(() => test.properties.elements.some((element) => element.crackTipOpeningDisplacement), []);

  return (
    <Grid container spacing={5}>
      <Grid item container alignContent="space-between" spacing={1}>
        {(test.company?.name || test.properties.client) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Client</h4>
            <p className={classes.value}>{test.company?.name || test.properties.client}</p>
          </Grid>
        )}
        {(test.assignee?.name || test.properties.lab) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Laboratory</h4>
            <p className={classes.value}>{test.assignee?.name || test.properties.lab}</p>
          </Grid>
        )}
      </Grid>
      <Grid item container alignContent="space-between" spacing={1}>
        {test.properties.testStandard && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Test standard</h4>
            <p className={classes.value}>{test.properties.testStandard}</p>
          </Grid>
        )}
        {test.properties.acceptance && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Acceptance criteria</h4>
            <p className={classes.value}>{test.properties.acceptance}</p>
          </Grid>
        )}
        <Grid item xs={3}>
          <h4 className={classes.name}>Sample ID</h4>
          <p className={classes.value}>{test.properties.sampleID}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Test temperature</h4>
          <p className={classes.value}>{test.properties.temperature} °C</p>
        </Grid>
        {test.properties.position && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Position</h4>
            <p className={classes.value}>{test.properties.position}</p>
          </Grid>
        )}
        {test.properties.orientation && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Orientation</h4>
            <p className={classes.value}>{test.properties.orientation}</p>
          </Grid>
        )}
      </Grid>
      <Grid item container alignContent="space-between" spacing={1}>
        {test.properties.yeildStrengthValue && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Yield strength value</h4>
            <p className={classes.value}>{test.properties.yeildStrengthValue} MPa</p>
          </Grid>
        )}
        {test.properties.yeildStrengthAtTestTemperature && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Yield strength at test temperature</h4>
            <p className={classes.value}>{test.properties.yeildStrengthAtTestTemperature} MPa</p>
          </Grid>
        )}
        {test.properties.tensileStrengthValue && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Tensile Strength Value</h4>
            <p className={classes.value}>{test.properties.tensileStrengthValue} MPa</p>
          </Grid>
        )}
        {test.properties.youngModulus && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Young modulus</h4>
            <p className={classes.value}>{test.properties.youngModulus} MPa</p>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={8}>
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Spec. ID</TableCell>
                {specimenLength && (
                  <TableCell>Specimen Length [mm]</TableCell>
                )}
                {specimenWidth && (
                  <TableCell>Specimen Width [mm]</TableCell>
                )}
                {specimenThickness && (
                  <TableCell>Specimen Thickness [mm]</TableCell>
                )}
                {appliedLoad && (
                  <TableCell>Applied Load [N]</TableCell>
                )}
                {loadRate && (
                  <TableCell>Load (K) Rate [MPa*m/s]</TableCell>
                )}
                {a0 && (
                  <TableCell>Final Pre-crack Length [mm]</TableCell>
                )}
                {clipGageDisplacement && (
                  <TableCell>Clip Gage (Vp) Displacement [mm]</TableCell>
                )}
                {crackTipOpeningDisplacement && (
                  <TableCell>Crack Tip Opening Displacement [mm]</TableCell>
                )}
              </TableRow>
              {test.properties.requirements.crackTipOpeningDisplacementMin && (
                <TableRow>
                  <TableCell>Min</TableCell>
                  {specimenLength && <TableCell>-</TableCell>}
                  {specimenWidth && <TableCell>-</TableCell>}
                  {specimenThickness && <TableCell>-</TableCell>}
                  {appliedLoad && <TableCell>-</TableCell>}
                  {loadRate && <TableCell>-</TableCell>}
                  {a0 && <TableCell>-</TableCell>}
                  {clipGageDisplacement && <TableCell>-</TableCell>}
                  {crackTipOpeningDisplacement && (
                    <TableCell>
                      {test.properties.requirements.crackTipOpeningDisplacementMin}
                    </TableCell>
                  )}
                </TableRow>
              )}
              {test.properties.requirements.crackTipOpeningDisplacementMax && (
                <TableRow>
                  <TableCell>Max</TableCell>
                  {specimenLength && <TableCell>-</TableCell>}
                  {specimenWidth && <TableCell>-</TableCell>}
                  {specimenThickness && <TableCell>-</TableCell>}
                  {appliedLoad && <TableCell>-</TableCell>}
                  {loadRate && <TableCell>-</TableCell>}
                  {a0 && <TableCell>-</TableCell>}
                  {clipGageDisplacement && <TableCell>-</TableCell>}
                  {crackTipOpeningDisplacement && (
                    <TableCell>
                      {test.properties.requirements.crackTipOpeningDisplacementMax}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {test.properties.elements.map((element) => {
                return (
                  <TableRow>
                    <TableCell>{element.specimenId}</TableCell>
                    {specimenLength && (
                      <TableCell>{element.specimenLength}</TableCell>
                    )}
                    {specimenWidth && (
                      <TableCell>{element.specimenWidth}</TableCell>
                    )}
                    {specimenThickness && (
                      <TableCell>{element.specimenThickness}</TableCell>
                    )}
                    {appliedLoad && (
                      <TableCell>{element.appliedLoad}</TableCell>
                    )}
                    {loadRate && (
                      <TableCell>{element.loadRate}</TableCell>
                    )}
                    {a0 && (
                      <TableCell>{element.a0}</TableCell>
                    )}
                    {clipGageDisplacement && (
                      <TableCell>{element.clipGageDisplacement}</TableCell>
                    )}
                    {crackTipOpeningDisplacement && (
                      <TableCell>
                        <TestResultTableCell
                          value={element.crackTipOpeningDisplacement}
                          max={test.properties.requirements.crackTipOpeningDisplacementMax}
                          min={test.properties.requirements.crackTipOpeningDisplacementMin}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {test.properties.notes && (
        <Grid item xs={12}>
          <p
            className={classes.notes}
            dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
          />
        </Grid>
      )}
      {!!test.properties.files?.length && (
        <Grid item container spacing={8}>
          {test.properties.files.map((image) =>
            <Grid item xs={4} container alignItems="center" key={image}>
              <File file={image} preview />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(CrackTipOpeningDisplacement, styles);
