import {Chip} from "@mui/material";
import React from "react";
import {getTestResult} from "@core/helpers";

const Result = ({test}) => {
  const {theme: resultTheme, text} = getTestResult(test);

  return (
    <Chip
      size="small"
      label={text}
      sx={{
        color: resultTheme.text,
        backgroundColor: resultTheme.backgroundColor
      }}
    />
  );
};

export default Result;