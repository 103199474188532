import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import FilterType from "./components/FilterType";
import {FILTERS} from "@core/constants/filters";
import {flatten, values, keys} from "ramda";
import {Button, Divider, List, ListItem, ListItemText, Popover} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {Input} from "@core/components/Form";
import DateCreatedFilter from "@core/components/DateCreatedFilter";
import {FcClearFilters} from "react-icons/fc";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import useStores from "../../../useStores";

const FilterButton = observer(({
  classes,
  optionsByType,
  children,
  ...props
}) => {
  const {FilterStore} = useStores();

  const {filters, setFilters} = FilterStore;
  const options = values(FILTERS);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterBy, setFilterBy] = useState("");

  const onFilterBy = (e) => {
    const value = e.target.value;
    setFilterBy(value);
  };

  const isFilled = flatten(values(filters)).length;

  const availableOptions = options.filter((option) => optionsByType[option]);
  const noOptions = availableOptions.every((o) => !optionsByType[o].filter((o) => o.toLowerCase().includes(filterBy.toLowerCase())).length);

  const displayOrder = props.displayOrder || keys(optionsByType);

  return (
    <>
      <div
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {children || (
          <Button
            size="small"
            color={isFilled ? "primary" : "inherit"}
          >
            <FilterListIcon fontSize="small" />&nbsp;
            <span>Filter</span>
          </Button>
        )}
      </div>
      <Popover
        classes={{paper: classes.popover}}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        TransitionProps={{
          onExited: () => setFilterBy("")
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={classes.input}>
          <Input
            variant="outlined"
            value={filterBy}
            placeholder="Filter by"
            onChange={onFilterBy}
          />
        </div>
        {!noOptions ? (
          <>
            <List component="nav">
              {availableOptions.map((option) => (
                <FilterType
                  key={option}
                  name={option}
                  options={optionsByType[option]}
                  classes={classes}
                  filters={filters}
                  setFilters={setFilters}
                  filterBy={filterBy}
                />
              ))}
            </List>
            {displayOrder.includes(FILTERS.DATE_CREATED) && (
              <DateCreatedFilter
                setFilters={setFilters}
                filters={filters}
              />
            )}
            <Divider />
            <List component="nav">
              <ListItem
                button
                onClick={() => setFilters({})}
              >
                <FcClearFilters />&nbsp;
                <ListItemText primary="Reset filter" />
              </ListItem>
            </List>
          </>
        ) : (
          <List component="nav">
            <ListItem>
              <ListItemText primary="No data." />
            </ListItem>
          </List>
        )}
      </Popover>
    </>
  );
});

export default withStyles(FilterButton, styles);
