export default (theme) => ({

  companyInfo:{
    overflow:"hidden"
  },

  imageContainer:{
    marginTop: theme.spacing(2),
    width: "inherit",
    height: "inherit",
  },
  field: {
    marginLeft: 10,
    color: "#575757"
  },

  companyLogo: {
    maxHeight: "100%",
    maxWidth: "100%"
  },

  infoH1: {
    textTransform: "uppercase",
    borderBottom:"2px solid gray"
  },

  partnersAvatar: {
    display: "block",
    height: 80,
    marginRight: 15,

    "& img": {
      maxHeight: "100%",
      maxWidth: "100%"
    }
  },
  // download: {
  //   display: "flex",
  //   alignItems: "center",
  //   color: "#2196f3",
  //   textDecoration: "none",
  //   textTransform: "uppercase",
  //   font: "500 14px/14px"
  // }
});
