import React, {useState} from "react";
import {useFormikContext} from "formik";
import CampaignCreate from "@core/components/CampaignCreate";
import {MuiAutocomplete} from "@core/components/Form";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const CampaignAutocomplete = ({name, required, usedCampaigns, isCreatable}) => {
  const [createNewCampaignOpen, setCreateNewCampaignOpen] = useState(false);

  const {setFieldValue} = useFormikContext();

  const filterOptions = (options) => {
    return options?.filter(({value}) => !usedCampaigns.includes(value));
  };

  return (
    <>
      <MuiAutocomplete
        url={ROUTES.CAMPAIGN[ACTIONS.ALL]}
        inputProps={{
          name,
          required,
        }}
        formatOptions={(data) => data.items.map((item) => ({label: item.name, value: item._id}))}
        filterOptions={filterOptions}
        onCreateNew={isCreatable && (() => setCreateNewCampaignOpen(true))}
      />
      <CampaignCreate
        open={createNewCampaignOpen}
        close={() => setCreateNewCampaignOpen(false)}
        onCreated={(campaign) => setFieldValue(name, campaign._id)}
      />
    </>
  );
};

export default CampaignAutocomplete;
