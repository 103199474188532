import React, {useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {observer} from "mobx-react-lite";
import {any, propEq} from "ramda";
import {MultipleSelect} from "@core/components/Form";
import modules from "@core/constants/modules";
import useStores from "../../../../useStores";

const AddWitnessesPopup = observer(({open, close, onSubmit, ...props}) => {
  const [witnesses, setWitnesses] = useState(props.witnesses.map((witness) => witness.company) || []);

  const {CompanyStore} = useStores();
    
  const witnessCompanies = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.WITNESS, "name"), company.modules);
  });

  const witnessCompaniesNames = witnessCompanies.map((c) => c.name);
  const values = witnesses.map((witness) => witness?.name);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {props.witnesses.length ? "Change witnesses" : "Add witnesses"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MultipleSelect
              label="Witnesses"
              value={values}
              elements={witnessCompaniesNames}
              onChange={(values) => {
                const witnesses = witnessCompanies.filter((c) => values.includes(c.name));
                setWitnesses(witnesses);
              }}
            />
          </Grid>
          <Grid item xs>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={async () => {
                await onSubmit(witnesses);
                close();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

export default AddWitnessesPopup;