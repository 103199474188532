export default (theme) => ({
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  metal: {
    borderRight: "1px solid #e0e0e0",
  },
  leftBorder: {
    borderLeft: "2px solid #9f9f9f"
  }
});

