export default (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  adminName: {
    paddingLeft: 0,
  },
  table: {
    minWidth: 700,
  },
  pagination: {
    flex: "initial",
    padding: 0
  },
  padding40: {
    padding: theme.spacing(4)
  },
  headerAndButton: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(3),
    "& h4": {
      marginRight: theme.spacing(3),
    },
  },
  paddingRight40: {
    paddingRight: "40px !important "
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 3,
  },
  tableFooter: {
    "& div": {
      paddingLeft: theme.spacing(),
    },
    "& td": {
      borderBottom: "none"
    }
  }
});

