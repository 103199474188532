import React from "react";
import {Grid, MenuItem} from "@mui/material";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import {OPERATOR_DESCRIPTION, OPERATORS} from "@core/constants/specifications";

const ComparisonForm = ({item, name, xs, allowedOperators, allowedValues}) => {
  const typeNumberOperators = [OPERATORS.LTE, OPERATORS.LT, OPERATORS.GTE, OPERATORS.GT, OPERATORS.BETWEEN];

  return (
    <>
      <Grid item xs={xs}>
        <SelectField
          name={`${name}.operator`}
          label='Operator'
          required
        >
          {allowedOperators.map((operator) => (
            <MenuItem value={operator}>{OPERATOR_DESCRIPTION[operator]}</MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={xs}>
        {allowedValues ? (
          <SelectField
            name={`${name}.value`}
            label='Value'
            required
          >
            {allowedValues.map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </SelectField>
        ) : (
          <TextField
            type={typeNumberOperators.includes(item.operator) ? "number" : "string"}
            name={`${name}.value`}
            label="Value"
            required
          />
        )}
      </Grid>
      {item.operator === OPERATORS.BETWEEN && (
        <Grid item xs={xs}>
          <TextField
            type="number"
            name={`${name}.secondValue`}
            label="Value 2"
            required
          />
        </Grid>
      )}
    </>
  );
};

ComparisonForm.defaultProps = {
  xs: 2,
  allowedOperators: Object.keys(OPERATOR_DESCRIPTION),
};

export default ComparisonForm;
