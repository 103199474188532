export default () => ({
  inputCell: {
    "&:first-child": {
      padding: 4,
    },
    "& input": {
      textAlign: "center",
    }
  },
  tableContainer: {
    marginTop: 20,
    marginBottom: 20,
    overflowX: "auto",
  },
  table: {
    whiteSpace: "nowrap",
    "& td": {
      padding: "0px 5px 0px 5px",
      borderRight: "1px solid #e0e0e0"
    },
    "& th": {
      padding: "5px",
      borderRight: "1px solid #e0e0e0"
    },
    "& td:last-child": {
      borderRight: 0,
    },
    "& th:last-child": {
      padding: "5px",
      borderRight: 0,
    },
  },
  stickyColumn: {
    position: "sticky",
    background: "white",
    left: 0,
    zIndex: 1,
  },
  saveButton: {
    marginLeft: "auto",
  },
  acceptable: {
    backgroundColor: "#b3e5b8",
  },
  notAcceptable: {
    backgroundColor: "#f8e0e9",
  },
});
