export default () => ({
  input: {
    padding: 5,
  },
  actionCell: {
    padding: 0,
    "& svg": {
      cursor: "pointer",
      fontSize: 20,
    }
  },
  inputRoot: {
    width: "100%",
  },
  requiredInput: {
    position: "relative",
    "&:after" : {
      position: "absolute",
      left: 3,
      top: 0,
      content: "' *'",
      color: "#e91e63"
    },
    "& input": {
      paddingLeft: 10,
    }
  },
});

