import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import React from "react";

export default withStyles(({classes, customClasses}) => <div className={classNames([classes.stripe, customClasses])}/>, (theme) => ({
  stripe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: theme.spacing(1),
    height: "100%",
  },
}));