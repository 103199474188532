export default () => ({
  mt3: {
    marginTop: "3px !important"
  },

  mt8: {
    marginTop: "8px !important"
  },

  mt20: {
    marginTop: "20px !important",
  },

  pt0: {
    paddingTop: "0px !important"
  },

  title: {
    marginTop: "40px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  },

  secondaryButton: {
    marginTop: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#cccccc",
    margin: "20px 0"
  },

  saveButton: {
    marginLeft: "auto",
  }
});

