import {Box, useTheme} from "@mui/material";
import React, {useMemo} from "react";
import {values} from "ramda";
import {PO_ITEMS} from "@core/constants/poItems";
import AcceptanceTable from "./components/AcceptanceTable";

const HydrostaticTest = ({qcpConfig}) => {
  const poItems = useMemo(() => values(PO_ITEMS).filter((poItem) => qcpConfig.getProperties && qcpConfig.getProperties(poItem.toString()).pressure), []);
  const theme = useTheme();

  if(poItems.length) {
    return poItems.map((poItem) => (
      <Box key={poItem} sx={{marginTop: theme.spacing()}}>
        <h3>QCP Item {poItem}</h3>
        <AcceptanceTable
          config={qcpConfig.getProperties(poItem.toString())}
        />
      </Box>
    ));
  }

  return (
    <>
      <h3>Local Requirements</h3>
      <AcceptanceTable
        config={qcpConfig.properties}
      />
    </>
  );
};

export default HydrostaticTest;