import Avatar from "@core/components/Avatar";
import {NotificationStore} from "@core/components/Modal/stores";
import {debounce} from "@core/helpers";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import Cropper from "react-easy-crop";
import {withStyles} from "tss-react/mui";
import {MAX_FILE_SIZE} from "../constants";
import {DEFAULTS} from "./constants";
import {dataUrlToFile, eventPreventDefault, getBase64ImageCircularCropped} from "./service";
import styles from "./styles";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const MemoizedCropper = memo(Cropper);

function AvatarUploader({
  file,
  fileId,
  classes,
  preloadText,
  buttonText,
  className,
  disabled,
  onRemove,
  justifyContent,
  handleUploadedFile,
  handleResetPreview,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [over, setOver] = useState();
  const fileInputId = useRef(Math.random().toString(36).substring(2));
  const checkboxId = useRef(Math.random().toString(36).substring(2));
  const inputElement = useRef(null);
  const imageConvertEl = useRef();
  const imageCanvasEl = useRef();
  const [sourceImage, setSourceImage] = useState("");
  const [sourceImageLoaded, setSourceImageLoaded] = useState(false);
  const [crop, setCrop] = useState(DEFAULTS.crop);
  const [zoom, setZoom] = useState(DEFAULTS.zoom);
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [checkbox, setCheckbox] = useState(DEFAULTS.cropCheckbox);
  const selectedFile = useRef(null);

  // reset state after dialog closing
  useEffect(() => {
    if (!sourceImageLoaded) {
      setCheckbox(DEFAULTS.cropCheckbox);
      setSourceImage("");
      setCrop(DEFAULTS.crop);
      setZoom(DEFAULTS.zoom);
      setCroppedImageFile(null);
    }
  }, [sourceImageLoaded]);

  const onImageLoad = () => setSourceImageLoaded(true);

  const onCropSubmit = async () => {
    const controller = new AbortController();
    const data = new FormData();
    data.append("file", checkbox ? croppedImageFile : selectedFile.current);
    setLoading(true);
    setSuccess(true);
    try {
      const response = await axios.post(ROUTES.UPLOAD[ACTIONS.CREATE], data, {withCredentials: true, signal: controller.signal});
      typeof handleUploadedFile === "function" && handleUploadedFile(response.data);
    } catch (error) {
      NotificationStore.showError("Error. Something went wrong...");
    }
    setLoading(false);
    setSourceImageLoaded(false);
  };

  const removeFile = async () => {
    if(!fileId) return;

    setLoading(true);

    try {
      await axios.delete(ROUTES.UPLOAD[ACTIONS.REMOVE](fileId));
      setLoading(false);
      onRemove();
    } catch (error) {
      NotificationStore.showError("Error. Something went wrong...");
      setLoading(false);
    }
  };

  const onDragEnter = (event) => {
    setOver(true);
    event.preventDefault();
  };

  const onDragLeave = (event) => {
    setOver(false);
    event.preventDefault();
  };

  const onDrop = (event) => {
    event.preventDefault();
    inputElement.current.click();
  };

  const onFileInputChange = (event) => {
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    selectedFile.current = null;

    if (!file) {
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setSuccess(false);
      typeof handleResetPreview === "function" && handleResetPreview();

      return;
    }

    selectedFile.current = file;
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const regex = /data:.*base64,/;
      const imageData = reader.result.replace(regex, "");
      setSourceImage(`data:;base64,${imageData}`);
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onCropChange = useCallback((newCrop) => setCrop(newCrop), []);
  const onZoomChange = useCallback((newZoom) => setZoom(newZoom), []);
  const onCropComplete = useCallback(debounce((croppedArea, croppedAreaPixels) => {
    const croppedBase64 = getBase64ImageCircularCropped(imageCanvasEl.current, imageConvertEl.current,
      croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height
    );
    const randomFileName = Math.random().toString(36).substring(2);
    const file = dataUrlToFile(croppedBase64, `avatar-${randomFileName}`);
    setCroppedImageFile(file);
  }), []);

  return(
    <div className={className}>
      {file && <Avatar user={{avatar: {file}}} />}

      {
        !file && preloadText ?
          <div
            onClick={() => !disabled && inputElement.current.click()}
            onDrop={onDrop}
            onDragOver={eventPreventDefault}
            onDragLeave={onDragLeave}
            onDragEnter={onDragEnter}
            onDragStart={eventPreventDefault}
            onDragEnd={eventPreventDefault}
            onDrag={eventPreventDefault}
            className={classNames(["preloadImg", classes.preloadAvatar, over ? classes.enter : classes.leave])}
          >
            {preloadText}
          </div> :
          null
      }
      <input
        ref={inputElement}
        accept="image/*"
        className={classes.input}
        id={fileInputId.current}
        disabled={disabled}
        type="file"
        onClick={(event) => event.target.value = ""}
        onChange={onFileInputChange}
      />
      {!success && <p className={classes.errorMessage}>Uploaded file is too big. <br /> Max size: 50mb</p>}
      <Grid container spacing={2} justifyContent={justifyContent} className={file && classes.buttonsContainer}>
        <Grid item>
          <label htmlFor={fileInputId.current}>
            <Button
              variant="contained"
              component="span"
              className={classNames(["button", classes.button])}
              disabled={loading || disabled}
              color="primary"
            >
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              {file ? <span>Change</span> : <span>{buttonText || "Upload"}</span>}
            </Button>
          </label>
        </Grid>
        {(onRemove && file) && (
          <Grid item onClick={removeFile}>
            <Button
              variant="contained"
              component="span"
              className={classNames(["button", classes.button])}
              color="secondary"
            >
                      Remove
            </Button>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={sourceImageLoaded}
        maxWidth="lg"
        aria-labelledby="responsive-dialog-title">
        <DialogTitle>Crop your image. Tip: You can scroll to zoom in or out.</DialogTitle>
        <DialogContent>
          {checkbox &&
              <div className={classes.cropperContainer}>
                <MemoizedCropper
                  restrictPosition={false}
                  image={sourceImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  cropShape="round"
                  showGrid={false}
                  onZoomChange={onZoomChange}
                  onCropComplete={onCropComplete}
                  onCropChange={onCropChange}
                  minZoom={0.5}
                />
              </div>}
          {!checkbox &&
            <div className={classes.noCropContent}>
              <img src={sourceImage} alt="selected-image"/>
            </div>
          }
        </DialogContent>
        <DialogActions className={classes.dialogButtonsContainer}>
          <div className={classes.dialogCheckBoxWrap}>
            <Checkbox
              id={checkboxId.current}
              color="primary"
              checked={checkbox}
              onChange={() => setCheckbox((prevState) => !prevState)}/>
            <label htmlFor={checkboxId.current}>Crop image</label>
          </div>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onCropSubmit}>
            Ok
          </Button>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            className={classes.dialogCancel}
            onClick={() => setSourceImageLoaded(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <img
        ref={imageConvertEl}
        src={sourceImage}
        alt="image-converter"
        className={classes.converterImage}
        onLoad={onImageLoad}/>
      <canvas ref={imageCanvasEl} className={classes.converterCanvas}/>
    </div>
  );
}

export default withStyles(AvatarUploader, styles);