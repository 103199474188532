import React, {Component} from "react";
import {Grid, Table, TableHead, TableBody, TableRow, TableCell} from "@mui/material";

import {withStyles} from "tss-react/mui";

import {inject, observer} from "mobx-react";
import styles from "./styles";
import {compose} from "ramda";

class AusteniteSpacing extends Component {
  render() {
    const {
      test: {properties},
      classes
    } = this.props;

    return (
      <>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <h4 className={classes.name}>Test standard</h4>
            <p className={classes.value}>{properties.norm}</p>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.name}>Acceptance criteria</h4>
            <p className={classes.value}>{properties.acceptance}</p>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <h4 className={classes.name}>Etching standard</h4>
            <p className={classes.value}>{properties.etchingStandard}</p>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.name}>Etching solution</h4>
            <p className={classes.value}>{properties.etchingSolution}{properties.electrolytic && " (Electrolytic)"}</p>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={3}>
            <h4 className={classes.name}>Specimen ID</h4>
            <p className={classes.value}>{properties.specimenId}</p>
          </Grid>
          <Grid item xs={3}>
            <h4 className={classes.name}>Position</h4>
            <p className={classes.value}>{properties.position || "-"}</p>
          </Grid>
          <Grid item xs={3}>
            <h4 className={classes.name}>Orientation</h4>
            <p className={classes.value}>{properties.orientation || "-"}</p>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={3}>
            <h4 className={classes.name}>Magnification</h4>
            <p className={classes.value}>{properties.magnification}</p>
          </Grid>
          <Grid item xs={3}>
            <h4 className={classes.name}>Max austenite spacing</h4>
            <p className={classes.value}>{properties.maxAusteniteSpacing} µm</p>
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" className={classes.marginBottom}>
          <Grid item xs={6}>
            <Table className={classes.borderTable}>
              <TableHead>
                <TableRow>
                  <TableCell size='small'><b>Field</b></TableCell>
                  <TableCell><b>Austenite spacing [µm]</b></TableCell>
                </TableRow>
              </TableHead>
              {properties.elements && properties.elements.length > 0 ? (
                <TableBody>
                  {properties.elements.map((element, index) => (
                    <TableRow key={element.spacing}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{element.spacing}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ): null}
            </Table>
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom}>
          <Grid item xs={12}>
            <Table className={classes.borderTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Mean spacing [µm]</TableCell>
                  <TableCell>Standard deviation[%]</TableCell>
                  <TableCell>CI 95%</TableCell>
                  <TableCell>Relative accuracy [%]</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell><b>{properties.meanAustenite}</b></TableCell>
                  <TableCell><b>{properties.deviation}</b></TableCell>
                  <TableCell><b>{properties.ci}</b></TableCell>
                  <TableCell><b>{properties.accuracy}</b></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        {properties.notes && (
          <Grid container>
            <Grid item xs={12}>
              <p
                className={classes.value}
                dangerouslySetInnerHTML={{__html: properties.notes.split("\n").join("</br>")}}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }

}

export default compose(
  inject("TestStore", "SigningStore", "CertificateStore"),
)(withStyles(observer(AusteniteSpacing), styles));
