import React from "react";
import Actions from "./components/Actions";

const getColumns = (setProjectToDelete) => [
  {
    dataIndex: "name",
    title: "Project Name",
  },
  {
    dataIndex: "transfers",
    title: "Orders",
    render: (project) => project.transfers.length,
  },
  {
    dataIndex: "action",
    permanent: true,
    title: "Action",
    render: (project) => (
      <Actions
        project={project}
        onDelete={() => setProjectToDelete(project._id)}
      />
    )
  },
];

export {getColumns};
