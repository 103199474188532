import React, {useState} from "react";
import {QRCodeCanvas} from "qrcode.react";
import {observer} from "mobx-react-lite";
import {Dialog, DialogTitle, Typography, DialogContent, Button, Grid, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {Input} from "@core/components/Form";
import userService from "@core/api/user-service";
import {copyToClipboard} from "@core/helpers/copyToClipboard";
import useStores from "../../../useStores";
import styles from "./styles";

const DEFAULT_ISSUER = "SteelTrace";

const SetupTwoFactor = ({tempSecret, onVerified, buttonProps, classes}) =>{
  const [open, setOpen] = useState(false);
  const [issuer, setIssuer] = useState(DEFAULT_ISSUER);
  const [codeScanned, setCodeScanned] = useState(false);
  const [totp, setTotp] = useState("");

  const {NotificationStore} = useStores();

  const onClose = () => {
    setOpen(false);
    setTotp("");
    setCodeScanned(false);
    setIssuer(DEFAULT_ISSUER);
  };

  const onSubmit = async () => {
    if (!codeScanned) {
      setCodeScanned(true);

      return;
    }

    const {data} = await userService.verifyTotp({totp});

    NotificationStore.showSuccess("Two-Factor Authentication was configured successfully!");
    onVerified(data);
    onClose();
  };

  return <>
    <Button
      {...buttonProps}
      onClick={() => setOpen(true)}
    >
      {buttonProps.title || "Setup Two-Factor Authentication"}
    </Button>
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Two-Factor Authentication
      </DialogTitle>
      <DialogContent>
        <Typography component="h6" variant="subtitle1" className={classes.title}>
          Please use your authentication app (such as <a className={classes.link} target="_blank" href="https://support.google.com/accounts/answer/1066447?hl=en">Google Authenticator</a> or <a className={classes.link} target="_blank" href="https://www.microsoft.com/en-us/account/authenticator">Microsoft Authenticator</a>) to scan this QR code.
        </Typography>
        <Input
          placeholder="It's strongly recommended to not leave this field empty"
          className={classes.issuer}
          label={(
            <Tooltip title="Type in a name to identify this account">
              <span className={classes.issuerLabel}>Issuer&nbsp;<HelpOutlineIcon fontSize="small" /></span>
            </Tooltip>
          )}
          value={issuer}
          onChange={(e) => setIssuer(e.target.value)}
        />
        <Grid container>
          <Grid item xs={6}>
            <QRCodeCanvas
              value={`otpauth://totp/${issuer}?secret=${tempSecret}`}
              imageSettings={{
                src: "/images/logo-symbol-only-black.png",
                height: 50,
                width: 50,
              }}
              size={250}
            />
          </Grid>
          <Grid item xs={6} className={classes.codeBox}>
            <Typography component="h6" variant="subtitle2" align="center">
              Or enter this code into your authentication app
            </Typography>
            <Tooltip title="Click to copy" placement="top">
              <div className={classes.codeContainer} onClick={() => copyToClipboard(tempSecret)}>
                {tempSecret.match(/.{1,4}/g).map((segment) => (
                  <Typography component="span" variant="h6" align="center" className={classes.code}>{segment}</Typography>
                ))}
              </div>
            </Tooltip>
          </Grid>
        </Grid>
        {codeScanned && (
          <Input
            placeholder="Type authentication code here"
            className={classes.authCode}
            onKeyDown={(e) => {
              if (e.key !== "Enter") return;

              onSubmit();
            }}
            label="Authentication code"
            type="number"
            value={totp}
            onChange={(e) => setTotp(e.target.value)}
          />
        )}
        <Grid container spacing={2} justifyContent="flex-end" className={classes.actions}>
          <Grid xs={3} item container justifyContent="flex-end">
            <Button
              disabled={codeScanned && !totp}
              onClick={onSubmit}
              color="primary"
              variant="contained"
              autoFocus
            >
              {!codeScanned ? "Next" : "Verify"}
            </Button>
          </Grid>
          <Grid xs={2} item>
            <Button onClick={onClose} color="secondary" variant="contained">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  </>;
};

export default withStyles(observer(SetupTwoFactor), styles);
