import {action, observable, makeObservable} from "mobx";

class TimelineStore {
  constructor() {
    const open = JSON.parse(localStorage.getItem("timelineOpen")) === true;

    this.open = open;

    makeObservable(this, {
      open: observable,
      toggle: action,
      setOpen: action,
    });
  }

  open = false;

  toggle() {
    this.setOpen(!this.open);
  }

  setOpen(open) {
    localStorage.setItem("timelineOpen", open);
    this.open = open;
  }
}

export default new TimelineStore();