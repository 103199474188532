import {lastIndexOf, splitAt} from "ramda";
import axios from "axios";
import {api} from "Config";

const signatures = {
  JVBERi0: "application/pdf",
  R0lGODdh: "image/gif",
  R0lGODlh: "image/gif",
  iVBORw0KGgo: "image/png",
  "/9j/": "image/jpg",
};

const getMimeType = (base64)=>{
  for(const sign in signatures)if(base64.startsWith(sign))return signatures[sign];
};

export const downloadBlob = (data, fileName, type) => {
  const blob = new Blob([data], {type});
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const getFileData = (fileName) => {
  const name = fileName.split(/_(.*)/s)[1];
  const dotIndex = lastIndexOf(".", name);
  const [filename, extension] = splitAt(dotIndex, name);

  return {name: filename, extension: extension.toLowerCase()};
};

export const downloadFile = async (file) => {
  const {data} = await axios.get(`${api}/uploads/download${file.dir}${file.name}`);

  const {name, extension} = getFileData(file.name);
  downloadBlob(base64ToBlob(data), name + extension, data.type);
};

export const base64ToBlob = (base64) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], {type: `${getMimeType(base64)};base64`});
};