export default (theme) => ({
  inputContainer: {
    minWidth: "1000px",
    width: "100%",
    height: "500px",
    border: "1px solid #575757",
    background: "white",
    overflow: "scroll",
  },
  outputContainer: {
    minWidth: "700px",
    width: "100%",
    height: "200px",
    border: "1px solid #575757",
    background: "white",
    overflow: "scroll",
  },
  outputContainer__textArea: {
    height: "100%",
    width: "100%",
    resize: "none",
    border: "none"
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#cccccc",
    margin: "20px 0"
  },
  table: {
    width: "100%",
    fontSize: "16px",
    paddingBottom: "15px",
    "& td ": {
      border: "1px solid #575757",
      padding: 10,
      textAlign: "center",
    },
    "& th": {
      border: "none",
      padding: "10px 0"
    },
    "& tr": {
      height: "initial",
    }
  },
  tableHead: {
    padding: "10px 24px",
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    color: "#616161",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f5f5f5"
    }
  },
  success: {
    color: "green"
  },
  fail: {
    color: "red"
  },
  certificateId: {
    color: theme.palette.primary.main
  }
});