import React, {Component} from "react";
import {Dialog, DialogContent, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {inject, observer} from "mobx-react";
import RadioGraphicReportForm from "@core/components/TestAddDataForms/RadioGraphicReport";
import styles from "./styles";
import RadioGraphicTable from "./table";
import {compose} from "ramda";

class RadioGraphic extends Component {
  state = {
    dataDialogOpen: false,
  };

  handleChange = (test) => {
    const {value} = test.target;
    const table = this.state.tableData;
    const filteredData = value === "all" ? table : table.filter((D) => D.type === value);
    this.setState({value, tableData: filteredData});
  };

  render() {
    const {test, classes} = this.props;
  
    return <div className={classes.radioGraphic}>
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item xs={3}>
          <h4>Workstage</h4>
          <span className={classes.values}>{test.properties.workStage}</span>
        </Grid>
        <Grid item xs={3}>
          <h4>Radioscopic Tec.</h4>
          <span className={classes.values}>{test.properties.radioscopic}</span>
        </Grid>
        <Grid item xs={3}>
          <h4>Test norms</h4>
          <span className={classes.values}>{test.properties.norm}</span>
        </Grid>
        <Grid item xs={3}>
          <h4>Acceptance criteria</h4>
          <span className={classes.values}>{test.properties.acceptance}</span>
        </Grid>
      </Grid>
      <h2>Single exposure and viewing</h2>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <div className={classes.source}>
            <h4>Source</h4>
            <span>{test.properties.source}</span>
            <p>Size: <span className={classes.values}>{`${test.properties.sourceSize} MM`}</span></p>
            <p>Distance: <span className={classes.values}>{`${test.properties.sourceDistance} MM`}</span></p>
          </div>
          <div className={classes.source}>
            <h4>Screen</h4>
            <p>Type: <span className={classes.values}>{test.properties.screen}</span></p>
            <p>Thickness Source Side: <span
              className={classes.values}>{`${test.properties.thicknessSourceSide} MM`}</span></p>
            <p>Thickness Film Side: <span
              className={classes.values}>{`${test.properties.thicknessFilmSide} MM`}</span></p>
          </div>
        </Grid>
        <Grid item xs={7} className={classes.additional}>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <h4>Position</h4>
              <span className={classes.values}>{test.properties.position}</span>
              <h4>Density</h4>
              <span className={classes.values}>{test.properties.density}</span>
              <h4>Film Type</h4>
              <span className={classes.values}>{test.properties.filmType}</span>
            </Grid>
            <Grid item xs={6}>
              <h4>X-ray Apparatus</h4>
              <span className={classes.values}>{test.properties.xRayApparatus}</span>
              <h4>I.Q.I Type</h4>
              <span className={classes.values}>{test.properties.iqiType}</span>
              <h4>Sales order №</h4>
              <span className={classes.values}>{test.properties.salesOrderNumber}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {test.properties.description && (
        <Grid item xs={10} className={classes.description}>
          <h4>Description</h4>
          <p
            className={classes.values}
            dangerouslySetInnerHTML={{__html: test.properties.description.split("\n").join("</br>")}}
          />
        </Grid>
      )}
      <RadioGraphicTable table={test.properties.sections} />

      <Dialog
        open={this.props.addDataOpen}
        onClose={this.props.dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent className={classes.dialogContent}>
          <RadioGraphicReportForm
            formRef={this.props.formRef}
            saveTest={this.props.updateTest}
            test={test}
          />
        </DialogContent>
      </Dialog>
    </div>;
  }
}

export default compose(
  inject("TestStore", "SigningStore", "CertificateStore")
)(withStyles(observer(RadioGraphic), styles));