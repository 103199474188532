export default (theme) => ({
  value: {
    color: "#616161",
    fontWeight: theme.typography.fontWeightMedium,
  },
  tableContainer: {
    maxHeight: 500,
    overflow:"auto",
    padding:"0px !important",
    borderStyle:"hidden",
    borderRadius: 5,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  dimensionTable: {
    borderCollapse: "separate",
    whiteSpace: "nowrap",
  },
  stickyHeader: {
    zIndex: 1,
    position: "sticky",
    top: 0,
    "& th":{
      borderRadius:"0 !important"
    }
  },
  stickyColumn: {
    position: "sticky",
    background: "white",
    left: 0,
    zIndex: 0,
  },
  firstStickyColumn: {
    zIndex: 2,
  },
  error: {
    color: theme.palette.error.main
  },
  success: {
    color: theme.palette.success.main
  },
  info: {
    color: theme.palette.primary.main
  }
});
