export default () => ({
  gridRoot: {
    marginTop: 20,
    marginBottom: 20
  },

  unsigned: {
    minHeight: 100,
    display: "flex",
    color: "#575757",
    justifyContent: "center",
    alignItems: "center"
  },

  dateContainer: {
    display: "flex",
    flexDirection: "column"
  },

  dateLabel: {
    width: "100%",
    position: "relative",
    whiteSpace: "nowrap",
    color: "#00000085",
  },

  dateField: {
    display: "flex",
    width: "100%",
    overflow: "auto",
    minHeight: 49,
    padding: "5px 12px",
    marginTop: 7,
    border: "1px solid #ced4da",
    borderRadius: 5,
    backgroundColor: "#fff",
  },

  header: {
    marginBottom: 30,
  },

  emptyForm: {
    padding: 20,
    minHeight: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #e0e0e0",
  }
});

