export default (theme) => ({
  content: {
    padding: `0 ${theme.spacing(4)}`,
  },
  transferred: {
    "& td": {
      backgroundColor: theme.palette.success.backgroundColor,
    }
  },
  title: {
    margin: `${theme.spacing(3)} 0 ${theme.spacing()} 0`
  },
  actionsColumn: {
    display: "flex",
    justifyContent: "space-around",
  },
  tableAction: {
    padding: 0,
  },
  headerWithPadding: {
    "& th": {
      padding: 10,
    }
  },
  allCheckbox: {
    marginLeft: 3,
  }
});