import {Grid, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {MdAdd} from "react-icons/md";
import {TABS, TABS_TITLES} from "../../constants";
import SelectBaseMaterialsForm from "../../../singleMaterialComponents/SelectBaseMaterialsForm";

const BaseMaterialsPage = () => {
  const [baseMaterialFormOpen, setBaseMaterialFormOpen] = useState(false);
  const [baseMaterialsIds, setBaseMaterialsIds] = useState([]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6">
                {TABS_TITLES[TABS.BASE_MATERIALS]}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setBaseMaterialFormOpen(true)}
              >
                <MdAdd size={18} />&nbsp;Modify
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Product ID</TableCell>
                <TableCell>Product Type</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Norm</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Heat</TableCell>
                <TableCell>Production Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1001</TableCell>
                <TableCell>Seamless pipe</TableCell>
                <TableCell>Fox pipe</TableCell>
                <TableCell>API 5L</TableCell>
                <TableCell>X65</TableCell>
                <TableCell>Ten-DL-SMLS</TableCell>
                <TableCell>913905-1</TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={"/certificates/"}
                  >
                    View certificate or product
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>1002</TableCell>
                <TableCell>Seamless pipe</TableCell>
                <TableCell>Fox pipe</TableCell>
                <TableCell>API 5L</TableCell>
                <TableCell>X65</TableCell>
                <TableCell>Ten-DL-SMLS</TableCell>
                <TableCell>913905-2</TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={"/certificates/"}
                  >
                    View certificate or product
                  </Button>
                </TableCell>
              </TableRow>
              {baseMaterialsIds.map((id) => (
                <TableRow>
                  <TableCell>{id}</TableCell>
                  <TableCell>Seamless pipe</TableCell>
                  <TableCell>Fox pipe</TableCell>
                  <TableCell>API 5L</TableCell>
                  <TableCell>X65</TableCell>
                  <TableCell>Ten-DL-SMLS</TableCell>
                  <TableCell>913905-2</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/certificates/"}
                    >
                      View certificate or product
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <SelectBaseMaterialsForm
        selectedIds={baseMaterialsIds}
        baseMaterialFormOpen={baseMaterialFormOpen}
        setBaseMaterialFormOpen={setBaseMaterialFormOpen}
        setBaseMaterials={(ids) => setBaseMaterialsIds(ids)}
      />
    </>
  );
};

export default BaseMaterialsPage;