import React, {useState} from "react";
import {DialogTitle, Button, Dialog, DialogContent, Grid} from "@mui/material";
import {useHistory, useLocation} from "react-router";
import {observer} from "mobx-react";
import {difference, map, prop, values} from "ramda";
import {TestService} from "@core/services";
import Table from "@core/components/Table";
import TableNavigation from "@core/components/TableNavigation";
import useStores from "../../../useStores";
import {columns, TABS, TABS_TITLES} from "./data";
import {STATUSES, TYPES} from "@core/constants/test";

const MultipleSigningModal = ({tests, entity, link, updateTest, disabled, triggerButton: TriggerButton}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = useState(false);
  
  const location = useLocation();
  const history = useHistory();

  const {UserStore, TestStore, NotificationStore} = useStores();

  const currentTab = new URLSearchParams(location.search).get("view") || TABS.AVAILABLE_TO_SIGN;

  const selectedIds = map(prop("_id"), selectedItems);
  
  const onSelect = (ids) => {
    const newTestsIds = difference(ids, selectedIds);
    const newTests = newTestsIds.map((id) => tests.find((test) => test._id === id));
    const oldTests = selectedItems.filter((test) => ids.includes(test._id));
    setSelectedItems(oldTests.concat(newTests));
  };

  const onApprove = () => {
    const testsToApprove = selectedItems.filter((test) => test.status !== STATUSES.INSPECTED || test.assignee);
    const testsToAccept = selectedItems.filter((test) => test.status === STATUSES.INSPECTED && !test.assignee);

    if (testsToApprove.length) {
      TestService.approve(testsToApprove, entity, UserStore.user.data._id);
    }

    if (testsToAccept.length) {
      testsToAccept.forEach((test) => {
        TestStore.update({status: STATUSES.APPROVED}, test._id);
        updateTest(test, {status: STATUSES.APPROVED});
      });
      NotificationStore.showSuccess("Successfully accepted!");
    }

    setSelectedItems([]);
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedItems([]);
    history.push(location.pathname);
  };

  const onOpen = () => {
    if(disabled || !availableToSign.length) return;

    setOpen(true);
  };

  const {availableToSign, notAvailableToSign} = tests.reduce((acc, test) => {
    const isFilledByManufacturerWithNoWitness = test.status === STATUSES.FILLED && !test.witnesses.length && !test.assignee;
    const isDone = [STATUSES.SUBMITTED, STATUSES.INSPECTED].includes(test.status);
    const isSplitTest = test.type === TYPES.SPLIT && test.status !== STATUSES.APPROVED;

    if (isFilledByManufacturerWithNoWitness || isDone || isSplitTest) {
      acc.availableToSign.push(test);
    } else {
      acc.notAvailableToSign.push(test);
    }

    return acc;
  }, {availableToSign: [], notAvailableToSign: []});

  const items = currentTab === TABS.AVAILABLE_TO_SIGN ? availableToSign : notAvailableToSign;

  return (
    <>
      <span onClick={onOpen}>
        <TriggerButton
          availableToSign={availableToSign}
          notAvailableToSign={notAvailableToSign}
        />
      </span>
      <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Approve multiple tests</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item xs={12}>
              <TableNavigation
                titles={TABS_TITLES}
                views={values(TABS)}
                currentView={currentTab}
                link={link}
                query="view"
              />
              <Table
                setSelectedItems={onSelect}
                selectedItems={selectedIds}
                allowRowSelection={currentTab === TABS.AVAILABLE_TO_SIGN}
                items={items}
                columns={columns}
                isLoaded
                total={items.length}
              />
            </Grid>
            {currentTab === TABS.AVAILABLE_TO_SIGN && (
              <Grid item>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  disabled={!selectedItems.length}
                  onClick={onApprove}
                >
                Next
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default observer(MultipleSigningModal);
