export default () => ({
  applyButton: {
    marginTop: 20,
  },
  paperWidthMd: {
    width: 900,
    maxWidth: 900,
  },
  expandButton: {
    display: "flex",
    cursor: "pointer",
  },
  collapse: {
    width: "100%",
  }
});
