import React from "react";
import {withStyles} from "tss-react/mui";
import ResizableDrawer from "@core/components/ResizableDrawer";
import styles from "./styles";
import WeldTestsDrawer from "./components/WeldTestsDrawer";
import PipeTestsDrawer from "./components/PipeTestsDrawer";
import {ENTITIES} from "../../constants";

const COMPONENTS_BY_ENTITY_TYPE = {
  [ENTITIES.PIPE]: PipeTestsDrawer,
  [ENTITIES.WELD]: WeldTestsDrawer,
}; 

const TestsDrawer = ({classes, type, ...props}) => {
  const Component = COMPONENTS_BY_ENTITY_TYPE[type];

  if(!type) return null;

  return (
    <ResizableDrawer
      className={classes.drawer}
      paperClasses={{root: classes.container}}
      open={props.open}
      close={props.close}
      keepMounted={false}
    >
      <Component
        {...props}
      />
    </ResizableDrawer>
  );
};

export default withStyles(TestsDrawer, styles);