import {action, makeObservable, observable} from "mobx";
import axios from "axios";
import {ACTIONS} from "@core/constants/api";
import {ROUTES} from "@core/api/routes";

class StatisticStore {
  constructor() {
    makeObservable(this, {
      statistic: observable,
      getCompanyStatistics: action,
      getAdminStatistics: action,
    });
  }
  statistic = {
    data: {},
    isLoaded: false,
  };

  async getCompanyStatistics(id) {
    this.statistic.isLoaded = false;

    const res = await axios.get(ROUTES.STATISTIC[ACTIONS.SINGLE](id), {
      withCredentials: true
    });

    this.statistic.data = res.data;
    this.statistic.isLoaded = true;
  }

  async getAdminStatistics() {
    this.statistic.isLoaded = false;

    const res = await axios.get(ROUTES.STATISTIC[ACTIONS.ALL]);

    this.statistic.data = res.data;
    this.statistic.isLoaded = true;
  }

}

export default new StatisticStore();
