import {is, keys} from "ramda";

const getSign = (condition) => {
  if(condition.indexOf("<=") !== 1) return "<=";

  if(condition.indexOf(">") !== 1) return ">";

  if(condition.indexOf("<") !== 1) return "<";

  return ">=";
};

export const getConfigFromCondition = (values, value) => {
  if(!is(Object, values) || !value) return null;

  const condition = keys(values).find((condition) => {
    if(Number(condition)) return Number(value) === Number(condition);

    const sign = getSign(condition);
    const correctedCondition = `${value}${condition.split(sign).join(`&&${value}${sign}`)}`;

    return eval(correctedCondition);
  });

  return values[condition] || {};
};