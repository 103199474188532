export const openFullScreen = (container) => {
  if(container.requestFullscreen) {
    container.requestFullscreen();
  } else if(container.webkitrequestFullscreen) {
    container.webkitRequestFullscreen();
  } else if(container.mozRequestFullscreen) {
    container.mozRequestFullScreen();
  }
};

export const closeFullScreen = () => {
  if(document.exitFullscreen) {
    document.exitFullscreen();
  } else if(document.webkitExitFullscreen ) {
    document.webkitExitFullscreen();
  } else if(document.mozExitFullscreen) {
    document.mozExitFullScreen();
  }
};