export default () => ({
  advancedSettingsButton: {
    display: "flex",
    cursor: "pointer",
  },
  toleranceUnits: {
    paddingRight: 12,
  },
  holdingTimeUnits: {
    paddingRight: 0,
  },
  everyText: {
    marginBottom: 15,
  },
  slash: {
    marginLeft: 10,
    marginBottom: 10,
    fontSize: 25,
    "& h3": {
      marginLeft: -7,
    }
  },
  saveButton: {
    marginLeft: "auto",
  }
});