export default () => ({
  elements: {
    margin: "30px 0"
  },
  fileContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  fileUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    wordBreak: "break-all",
    "& p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 150,
    }
  },
  alignEnd: {
    display: "flex",
    alignItems: "flex-end",
    "& button": {
      margin: "8px 0"
    }
  },
  saveButton: {
    marginLeft: "auto",
  }
});