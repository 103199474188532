export const ACTION_LABELS = {
  create: () => "Create",
  assign: (transactions) => {
    const hasInspect = transactions
      .find((transaction) => transaction.action === TRANSACTION_ACTIONS.inspect);

    if (hasInspect) return "Assign with witness";

    return "Assign";
  },
  submit: () => "Lab submit",
  witness: () => "Inspector endorsement",
  approve: () => "QA Approval",
  split: () => "Split",
  witnessRequest: () => "QA Approval & Witness Request",
  inspect: () => "Witness request"
};

export const TRANSACTION_ACTIONS = {
  assign: "assign",
  witnessRequest: "witnessRequest",
  inspect: "inspect"
};

export const TRANSACTION_DECLINED = "DECLINED";
