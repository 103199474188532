import React, {useState} from "react";
import {Formik} from "formik";
import {Grid, Dialog, DialogContent, DialogTitle, Button, InputAdornment, IconButton} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import * as yup from "yup";
import {MdVisibility, MdVisibilityOff} from "react-icons/md/";
import {validatePassword} from "@core/helpers";
import useStores from "../../../useStores";

const initialValues = {
  password: "",
  passwordConfirmation: ""
};

const validationSchema = yup.object().shape({
  password: yup.string()
    .required("New password is required!")
    .test("password", validatePassword),
  passwordConfirmation: yup.string()
    .required("Please confirm your password")
    .oneOf([yup.ref("password"), null], "Passwords don't match")
});

const PasswordField = ({label, name}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      label={label}
      name={name}
      required
      type={showPassword ? "text" : "password"}
      inputProps={{
        autocomplete: "new-password"
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton edge="end" onClick={() => setShowPassword(!showPassword)} size="large">
            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const PasswordChangeForm = ({userId, open, changePassword, onClose}) => {
  const {NotificationStore} = useStores();

  const onSubmit = async (values) => {
    await changePassword(userId, values.password);
    NotificationStore.showSuccess("Successfully changed!");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Password changing</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {isValid, handleSubmit} = props;

            return (
              <Grid container spacing={4} direction="column">
                <Grid item>
                  <PasswordField
                    label="New password"
                    name="password"
                  />
                </Grid>
                <Grid item>
                  <PasswordField
                    label="Confirm new password"
                    name="passwordConfirmation"
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={!isValid}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordChangeForm;
