import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Grid, MenuItem, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Input, MultipleSelect, Select} from "@core/components/Form";
import {any, propEq} from "ramda";
import modules from "@core/constants/modules";
import testConfig from "@core/configs/test";
import useStores from "../../../../../useStores";
import styles from "./styles";

const InviteLabForm = ({classes, setFieldValue, tests}) => {
  const {CompanyStore} = useStores();

  const [laboratory, setLaboratory] = useState("");
  const [witnesses, setWitnesses] = useState([]);
  const [notes, setNotes] = useState("");

  const laboratories = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.LAB_INTERNAL, "name"), company.modules);
  });

  const witnessCompanies = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.WITNESS, "name"), company.modules);
  });

  const isInviteLab = tests.some((test) => testConfig[test.type].responsible.includes(modules.LAB_INTERNAL));

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5" className={classes.title}>
          Default
        </Typography>
      </Grid>
      <Grid container spacing={4}>
        {isInviteLab && (
          <Grid item xs={3}>
            <Select
              label='Laboratory'
              value={laboratory}
              onChange={(e) => {
                setLaboratory(e.target.value);
                const testsWithAssignee = tests.map((t) => ({...t, assignee: e.target.value}));
                setFieldValue("tests", testsWithAssignee);
              }}
            >
              {laboratories.map((lab) => (
                <MenuItem key={lab._id} value={lab}>{lab.name}</MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item xs={3}>
          <div>
            <MultipleSelect
              label="Witnesses"
              value={witnesses.map((c) => c.name)}
              elements={witnessCompanies.map((c) => c.name)}
              onChange={(values) => {
                const witnesses = witnessCompanies.filter((c) => values.includes(c.name));
                setWitnesses(witnesses);
                const testsWithWitnesses = tests.map((t) => ({...t, witnesses}));
                setFieldValue("tests", testsWithWitnesses);
              }}
            />
          </div>
        </Grid>
        {isInviteLab && (
          <Grid item xs={6}>
            <Input
              rows={2}
              multiline
              label="Notes"
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
                const testsWithNotes = tests.map((t) => ({...t, notes: e.target.value}));
                setFieldValue("tests", testsWithNotes);
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(observer(InviteLabForm), styles);