import WS from "@core/api/socketConnection";
import userService from "@core/api/user-service.js";
import EntityNotFound from "@core/components/EntityNotFound";
import {Input, Select} from "@core/components/Form";
import Loader from "@core/components/Loader";
import PasswordChangeForm from "@core/components/PasswordChangeForm";
import TableFooter from "@core/components/TableFooter";
import TableSkeleton from "@core/components/TableSkeleton";
import {Uploader} from "@core/components/Uploaders";
import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import modules from "@core/constants/modules";
import roles from "@core/constants/roles";
import USER_STATUSES from "@core/constants/userStatuses";
import {getBCPermissions} from "@core/helpers";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography,
} from "@mui/material";
import CompanyBCService from "Blockchain/companies-service";
import axios from "axios";
import classNames from "classnames";
import {Formik} from "formik";
import {inject, observer} from "mobx-react";
import moment from "moment";
import {compose, equals, omit, pick} from "ramda";
import React, {Component} from "react";
import {withStyles} from "tss-react/mui";
import * as yup from "yup";
import PersonalInfoService from "../../Account/components/EditPersonalInfo/components/PersonalInfoService";
import AcknowledgementPopup from "./components/AcknowledgementPopup";
import styles from "./styles";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import referenceService from "@core/api/reference-service";

const MAX_SYMBOLS_IN_LOG = 100;

class UserSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      preview: false,
      preview_path: "",
      user: {},
      logs: {
        data: [],
        isLoaded: false
      },
      isActivated: false,
      isEmpty: false,
      acknowledgementPopupOpen: false,
      isChangePasswordOpen: false,
      currentUser: {},
      limit: 10,
      offset: 0,
      isSendingEmail: false,
    };
  }

  addBCUser = (user) => {
    this.setState({acknowledgementPopupOpen: false});

    const {SigningStore, NotificationStore} = this.props;
    const permissions = getBCPermissions(user.company.modules[0].name, user.role);

    userService.getUserInfo()
      .then((currentUser) => referenceService.create({
        creator: currentUser._id,
        transactions: [
          {
            transactionObject: CompanyBCService.addUserTx(user.company._id, user.bc_address, permissions),
            payload: {
              type: "user",
              action: "create",
              historyData: {
                newUser: user.username,
              },
            },
          },
        ],
        date_created: new Date,
      }))
      .then(
        (reference) => {
          SigningStore.openSigner({id: reference._id}, {
            title: "User creation",
            createdFor: "the admin of the company"
          });
        },
        () => NotificationStore.showError("Something went wrong!")
      );
  };

  updateUserInfo = async (changes) => {
    const {NotificationStore} = this.props;
    const userId = this.props.match.params.id;
    await PersonalInfoService.update(changes,  userId);
    this.setState({user: {...this.state.user, ...changes}});
    NotificationStore.showSuccess("Your changes have been applied!");
  };

  changePermissions = (user, changes) => {
    const {SigningStore} = this.props;
    const permissions = changes.status === USER_STATUSES.DISABLED ? 0 : getBCPermissions(user.company.modules[0].name, changes.role);

    userService.getUserInfo()
      .then(async (currentUser) => {
        try {
          const transactionObject = CompanyBCService.changeUserPermissionsTX(this.state.user.company._id, this.state.user.bc_address, permissions);
          const reference = await referenceService.create({
            creator: currentUser._id,
            transactions: [
              {
                transactionObject: transactionObject,
                payload: {
                  type: "user",
                  action: "change",

                  historyData: {
                    editedUser: user.username
                  },

                  changes,
                  userId: user._id
                }
              },
            ]
          });

          SigningStore.openSigner({id: reference._id}, {
            title: "Change user info",
            createdFor: "the admin of the company"
          });

          WS.listen("transaction:user:change", () => {
            SigningStore.closeSigner();
            this.updateUserInfo(changes);
          });
        } catch (e) {
          console.log(e);
        }
      });
  };

  onSubmit = (values) => {
    const changes = pick(["status", "role"], values);

    this.updateUserInfo(changes);
  };

  getAdditionalData = (log) => {
    if (log && log.additional) {
      const text =  Object.entries(log.additional)
        .reduce((acc, curr, index) => {
          return index === 0 ? `${curr[0]}: ${curr[1]}` : `${acc}, ${curr[0]}: ${curr[1]}`;
        }, "").split(",").join(", ");

      return text.length > MAX_SYMBOLS_IN_LOG ? `${text.substring(0, MAX_SYMBOLS_IN_LOG)}...` : text;
    } else {
      return " - ";
    }
  };

  resetPassword = async () => {
    const {NotificationStore} = this.props;
    const {user, isSendingEmail} = this.state;

    if(isSendingEmail) return;

    this.setState({isSendingEmail: true});

    await userService.getRecoveryToken({username: user.username});

    NotificationStore.showSuccess("Password reset email sent!");

    this.setState({isSendingEmail: false});
  };

  content() {
    const {user, currentUser, preview, preview_path, isActivated, acknowledgementPopupOpen, isChangePasswordOpen, isSendingEmail, offset, limit} = this.state;
    const {classes, UserStore} = this.props;
    const isInitialAdmin = user.company.admin === user._id;

    const logsPaginated = UserStore.logs.data.slice(offset, offset + limit);

    const actionNames = {
      create: "Create",
      delete: "Delete",
      bulkDelete: "Delete",
      login: "Log in",
      logout: "Log out",
      createBalk: "Create"
    };

    const initialValues = {
      username: user.username || "",
      firstname: user.firstname || "",
      lastname: user.lastname || "",
      email: user.email || "",
      role: user.role || "",
      status: user.status || "",
      avatar: user.avatar?._id || null,
    };

    const validationSchema = yup.object().shape({
      username: yup.string().required("The field is required!"),
      firstname: yup.string().required("The field is required!"),
      lastname: yup.string().required("The field is required!"),
      email: yup.string().email("Email has to be of type name@email.com").required("The field is required!"),
      role: yup.string().required("The field is required!"),
      status: yup.string().required("The field is required!"),
    });

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={this.onSubmit}
        render={(props) => {
          const {
            values: {username, firstname, lastname, email, role, status},
            errors,
            isValid,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            setFieldTouched
          } = props;

          const change = (name, e) => {
            e?.persist && e?.persist();
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          const uploadImage = (name, e) => {
            this.setState({
              preview: true,
              preview_path: e.file.dir + e.file.name,
            });
            setFieldValue("avatar", e._id);
            setFieldTouched(name, true, false);
          };

          const handleResetPreview = () => {
            this.setState({preview_path: ""});
            setFieldValue("avatar", "");
          };

          let avatar_path;

          if (user.avatar && !preview) {
            avatar_path = user.avatar.file.dir + user.avatar.file.name;
          } else {
            avatar_path = "";
          }

          const [module] = currentUser.company.modules;
          const isSuperAdmin = module.name === modules.SUPER_ADMIN;

          const isAdmin = currentUser.role === roles.ADMIN;

          const noChangesApplied = equals(initialValues, props.values);

          return (
            <Grid container className={classes.userComponent}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h4" fontSize="1.8rem">
                  User
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.section}>
                <form className={classes.form} noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Input
                        label="Username"
                        name='username'
                        value={username}
                        disabled={true}
                        error={Boolean(errors.username) && touched.username}
                        errorMessage={errors.username}
                        className={classes.textField}
                        onChange={(e) => change("username", e)}
                        onBlur={() => setFieldValue("username", username.trim())}
                      />
                      <Input
                        label="First name"
                        name='firstname'
                        value={firstname}
                        disabled={true}
                        error={Boolean(errors.firstname) && touched.firstname}
                        errorMessage={errors.firstname}
                        className={classes.textField}
                        onChange={(e) => change("firstname", e)}
                        onBlur={() => setFieldValue("firstname", firstname.trim())}
                      />
                      {user.role !== roles.SUPER_ADMIN && !isInitialAdmin && (
                        <Select
                          label="Role"
                          value={role}
                          name="role"
                          disabled={user._id === currentUser._id}
                          error={Boolean(errors.role) && touched.role}
                          errorMessage={errors.role}
                          className={classes.selectField}
                          onChange={(e) => change("role", e)}
                        >
                          {
                            Object.values(roles).filter((el) => el !== "SUPER_ADMIN").map((role) => (
                              <MenuItem value={role}>{role}</MenuItem>
                            ))
                          }
                        </Select>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Input
                        label="Email"
                        name='email'
                        value={email}
                        disabled={true}
                        error={Boolean(errors.email) && touched.email}
                        errorMessage={errors.email}
                        className={classes.textField}
                        onChange={(e) => change("email", e)}
                      />

                      <Input
                        label="Last name"
                        name='lastname'
                        value={lastname}
                        disabled={true}
                        error={Boolean(errors.lastname) && touched.lastname}
                        errorMessage={errors.lastname}
                        className={classes.textField}
                        onChange={(e) => change("lastname", e)}
                        onBlur={() => setFieldValue("lastname", lastname.trim())}
                      />

                      <Select
                        label="Status"
                        value={status}
                        name="status"
                        disabled={user._id === currentUser._id}
                        error={Boolean(errors.status) && touched.status}
                        errorMessage={errors.status}
                        className={classes.selectField}
                        onChange={(e) => change("status", e)}
                      >
                        {
                          Object.values(omit([USER_STATUSES.PENDING], USER_STATUSES)).map((status) => (
                            <MenuItem value={status}>{status}</MenuItem>
                          ))
                        }
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Uploader
                        justifyContent="center"
                        isDrop
                        disabled={true}
                        className={classes.avatarUpload}
                        preloadText="drop avatar file here"
                        file={preview ? preview_path : avatar_path}
                        handleUploadedFile={(e) => uploadImage("avatar", e)}
                        handleResetPreview={() => handleResetPreview()}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    {(!isActivated && !isInitialAdmin) &&
                          <Grid item className={classes.buttonContainer}>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              size="large"
                              disabled={!this.state.user.bc_address || !noChangesApplied}
                              onClick={() => this.setState({acknowledgementPopupOpen: true})}
                              className={classes.button}
                            >
                              Activate
                            </Button>
                          </Grid>
                    }
                    {!isActivated && (
                      <Grid item className={classes.buttonContainer}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={!isValid}
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </Grid>
                    )}

                    {(isActivated && (
                      <Grid item className={classes.buttonContainer}>
                        <Button
                          disabled={noChangesApplied}
                          fullWidth
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={() => this.changePermissions(user, {role, status})}
                        >
                          Sign
                        </Button>
                      </Grid>
                    ))}

                    {isSuperAdmin && (
                      <Grid item xs={4}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => this.setState(({isChangePasswordOpen: true}))}
                        >
                          Change password
                        </Button>
                      </Grid>
                    )}

                    {isAdmin && (
                      <Grid item>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={this.resetPassword}
                        >
                          {isSendingEmail && <CircularProgress size={20} />}
                          Reset password
                        </Button>
                      </Grid>
                    )}

                    {!isSuperAdmin && !user.bc_address && (
                      <>
                        {user.status === USER_STATUSES.PENDING ? (
                          <Grid item xs={12}>
                            <p className={classes.activationTooltip}>
                              User has received an invitation mail and needs to follow the procedure mentioned in the email.
                            </p>
                          </Grid>
                        ):(
                          <Grid item xs={12}>
                            <p className={classes.activationTooltip}>
                              User needs to follow the app process.
                            </p>
                          </Grid>)}
                      </>
                    )}
                  </Grid>
                </form>
              </Grid>

              <Grid item xs={12} container >
                <Grid xs={12} className={classes.title}>
                  <Typography variant="h4" fontSize="1.8rem">
                    Log
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Table className={classNames("styled-table", classes.table)}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>Information</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {UserStore.logs.isLoaded ? (
                        logsPaginated.length ? logsPaginated.map((log) => (
                          <TableRow>
                            <TableCell className={classes.action}>{log.type} {actionNames[log.action] || log.action}</TableCell>
                            <TableCell>{this.getAdditionalData(log)}</TableCell>
                            <TableCell>{moment(log.date_created).format(DATE_TIME_FORMAT)}</TableCell>
                          </TableRow>
                        )) : (
                          <TableRow>
                            <TableCell colSpan={3}>No data.</TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableSkeleton columns={3} />
                      )}
                    </TableBody>
                    <TableFooter
                      isLoaded={UserStore.logs.isLoaded}
                      items={UserStore.logs.data}
                      total={UserStore.logs.data.length}
                      limit={limit}
                      offset={offset}
                      onOffsetChange={(offset) => this.setState({offset})}
                      onLimitChange={(limit) => this.setState({limit})}
                    />
                  </Table>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <AcknowledgementPopup
                  open={acknowledgementPopupOpen}
                  onCancel={() => this.setState({acknowledgementPopupOpen: false})}
                  onAccept={() => this.addBCUser(user)}
                />
                <PasswordChangeForm
                  changePassword={(userId, password) => {
                    userService.resetPassword({newPassword: password, userId});
                  }}
                  userId={this.props.match.params.id}
                  open={isChangePasswordOpen}
                  onClose={() => this.setState({isChangePasswordOpen: false})}
                />
              </Grid>
            </Grid>
          );
        }}
      />
    );
  }

  render() {
    const {isLoaded, isEmpty} = this.state;

    if (!isLoaded) {
      return <Loader/>;
    }

    if (isEmpty) {
      return <EntityNotFound entity='User'/>;
    }

    return this.content();
  }

  componentWillUnmount() {
    WS.remove("transaction:user:create");
    WS.remove("transaction:user:change");
  }

  componentDidMount() {
    WS.listen("transaction:user:create", () => {
      this.props.SigningStore.closeSigner();
      getUser();
    });

    userService.getUserFromCache().then((user) =>{
      this.setState({currentUser: user});
    });

    this.props.UserStore.getUserLogs(this.props.match.params.id);
    // Cancel any in-progress requests
    // Load new data and update profileOrError
    const getUser = () => {
      userService.getUserInfoByid(this.props.match.params.id)
        .then((user) => {
          if (!user || user.hasOwnProperty("error")) {
            this.setState({
              isLoaded: true,
              isEmpty: true
            });

            return;
          }

          axios.get(ROUTES.BLOCKCHAIN_COMPANY[ACTIONS.SINGLE_USERS](user.company._id))
            .then((res) => {
              if (res.data.companies.find((el) => el.address === user.bc_address) || user.company.modules[0].name === "SUPER_ADMIN") {
                this.setState({
                  isActivated: true
                });
              }
            })
            .then(() => {
              this.setState({
                isLoaded: true,
                user
              });
            });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            isEmpty: true,
            error
          });
        });
    };
    getUser();
  }
}

export default compose(
  inject("SigningStore", "NotificationStore", "UserStore"),
)(withStyles(observer(UserSingle), styles));
