import {action, makeObservable, observable} from "mobx";
import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class GlobalStatisticsStore {
  constructor() {
    makeObservable(this, {
      globalStatistic: observable,
      getAllStatistics: action,
    });
  }
  globalStatistic = {
    data: {},
    isLoaded: false,
  };

  async getAllStatistics() {
    this.globalStatistic.isLoaded = false;

    const res = await axios.get(ROUTES.STATISTIC[ACTIONS.ALL], {
      params: {companies: true, tests: true, certificates: true, transactions: true},
      withCredentials: true
    });

    this.globalStatistic.data = res.data;
    this.globalStatistic.isLoaded = true;
  }

}

export default new GlobalStatisticsStore();
