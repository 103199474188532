import React, {useState} from "react";
import {Grid, Collapse, TableHead, TableRow, TableCell, Table, TableBody} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ImpactCurve from "@core/components/ImpactCurve";
import {openFileInNewTab} from "@core/helpers";
import ImpactTestTable from "./ImpactTestTable";
import styles from "./styles";
import classNames from "classnames";

const ImpactTest = ({setTest, addDataOpen: editable, classes, ...props}) => {
  const test = editable ? props.testUpdates : props.test;

  const [curveExpanded, setExpanded] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h3>Test Information:</h3>
      </Grid>
      <Grid item xs={12}>
        <Table className={classNames("styled-table", classes.table)}>
          <TableHead>
            <TableRow>
              {(test.company?.name || test.properties.client) && (
                <TableCell>Client</TableCell>
              )}
              {(test.assignee?.name || test.properties.lab) && (
                <TableCell>Laboratory</TableCell>
              )}
              <TableCell>Material specification</TableCell>
              <TableCell>Grade / UNS</TableCell>
              <TableCell>Test Standard</TableCell>
              {test.properties.acceptance && <TableCell>QCP/ITP</TableCell>}
              {test.properties.acceptanceItem && <TableCell>QCP Item</TableCell>}
              <TableCell>Specimen dimensions</TableCell>
              <TableCell>Notch type</TableCell>
              <TableCell>Striker radius</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {(test.company?.name || test.properties.client) && (
                <TableCell>{test.company?.name || test.properties.client}</TableCell>
              )}
              {(test.assignee?.name || test.properties.lab) && (
                <TableCell>{test.assignee?.name || test.properties.lab}</TableCell>
              )}
              <TableCell>{test.norm}</TableCell>
              <TableCell>{test.grade}</TableCell>
              <TableCell>{test.properties.testStandard || "-"}</TableCell>
              {test.properties.acceptance && <TableCell>{test.properties.acceptance}</TableCell>}
              {test.properties.acceptanceItem && <TableCell>{test.properties.acceptanceItem}</TableCell>}
              <TableCell>{test.properties.width || "- "} x {test.properties.height || "- "} x 55 mm</TableCell>
              <TableCell>{test.properties.notch || "-"}</TableCell>
              <TableCell>{test.properties.radius || "-"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}><h3>Test Result:</h3></Grid>
      <Grid item xs={12}>
        <ImpactTestTable
          test={test}
          editable={editable}
          setTest={setTest}
        />
      </Grid>
      {test.properties.impactTestCurve  && (
        <Grid item xs={12}>
          <h3
            className={classes.advancedSettingsButton}
            onClick={() => setExpanded(!curveExpanded)}
          >
                See impact graph{curveExpanded ? <ExpandLess /> : <ExpandMore />}
          </h3>
        </Grid>
      )}
      {test.properties.impactTestCurve  && (
        <Grid item xs={12}>
          <Collapse in={curveExpanded} timeout="auto" unmountOnExit>
            <div>
              <ImpactCurve
                elements={test.properties.elements.map((element) => ({
                  ...element,
                  values: [element.energyJoule1, element.energyJoule2, element.energyJoule3],
                }))}
                valuesLabel="Absorbed energy [J]"
              />
            </div>
          </Collapse>
        </Grid>
      )}
      {test.properties.file  && (
        <Grid item xs={12}>
          <a
            className={classes.link}
            onClick={() => openFileInNewTab(test.properties.file)}
            target={"_blank"}
          >
                Test sketch
          </a>
        </Grid>
      )}
      {test.properties.notes  && (
        <Grid item xs={12}>
          <p
            className={classes.notes}
            dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(ImpactTest, styles);

