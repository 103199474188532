import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const CustomTest = ({qcpConfig}) => (
  <>
    <h3>Local Requirements</h3>
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>Activity</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Min</TableCell>
          <TableCell>Max</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {qcpConfig.properties.activities.map((element) => {
          const {min, max, unit} = element.acceptanceCriteria?.value || {};

          return (
            <TableRow key={element.activity}>
              <TableCell>{element.activity}</TableCell>
              <TableCell>{element.description}</TableCell>
              <TableCell>{element.acceptanceCriteria?.label || "-"}</TableCell>
              <TableCell>{min ? min + unit : "-"}</TableCell>
              <TableCell>{max ? max + unit : "-"}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </>
);

export default CustomTest;