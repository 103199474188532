import React from "react";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react";
import TestMarker from "@core/components/TestMarker";
import styles from "./styles";

const AssignedTest = observer(({
  classes,
  test,
  deleteTest,
  updateTest,
  disabled,
  shareLink
}) => {

  return (
    <>
      <TestMarker
        test={test}
        deleteTest={deleteTest}
        updateTest={updateTest}
        disabled={disabled}
        shareLink={shareLink}
      />
      <div className={classes.emptyForm}>
        <h2>
          Waiting for test results from lab
        </h2>
      </div>
    </>
  );
});

export default withStyles(AssignedTest, styles);
