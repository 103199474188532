import React from "react";
import TextField from "@core/components/FormikTextField";
import {useFormikContext} from "formik";
import {Grid} from "@mui/material";

const PorousCalculationForm = ({fieldsLabelsByFieldName}) => {
  const {values, setFieldValue} = useFormikContext();

  return (
    <Grid item container spacing={2}>
      {fieldsLabelsByFieldName.massOfItemInAir && (
        <Grid item xs={3}>
          <TextField
            type='number'
            required
            name="massOfItemInAir"
            label={fieldsLabelsByFieldName.massOfItemInAir}
            endAdornment="g"
          />
        </Grid>
      )}
      <Grid item xs={3}>
        <TextField
          type='number'
          name="massOfOilImpregnatedPart"
          label={fieldsLabelsByFieldName.massOfOilImpregnatedPart}
          endAdornment="g"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type='number'
          name="massOfOilImpregnatedSupportPart"
          label={fieldsLabelsByFieldName.massOfOilImpregnatedSupportPart}
          endAdornment="g"
          disabled={values.massOfOilImpregnatedPartInWater}
          onChange={() => setFieldValue("massOfOilImpregnatedPartInWater", "")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type='number'
          name="massOfOilImpregnatedSupportInWater"
          label={fieldsLabelsByFieldName.massOfOilImpregnatedSupportInWater}
          endAdornment="g"
          disabled={values.massOfOilImpregnatedPartInWater}
          onChange={() => setFieldValue("massOfOilImpregnatedPartInWater", "")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type='number'
          name="massOfOilImpregnatedPartInWater"
          label={fieldsLabelsByFieldName.massOfOilImpregnatedPartInWater}
          endAdornment="g"
          disabled={values.massOfOilImpregnatedSupportPart || values.massOfOilImpregnatedSupportInWater}
          onChange={() => {
            setFieldValue("massOfOilImpregnatedSupportPart", "");
            setFieldValue("massOfOilImpregnatedSupportInWater", "");
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          type="number"
          name="densityOfWater"
          label="Density of water"
          endAdornment="g/m^3"
          required
        />
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              type="number"
              name="density"
              label={fieldsLabelsByFieldName.density}
              endAdornment="g/m^3"
              disabled
              required
            />
          </Grid>
          {values.acceptanceCriteria && (
            <Grid item sx={{paddingBottom: 1.5}} alignSelf="flex-end">
              {`>= ${values.acceptanceCriteria}`}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PorousCalculationForm;