import * as yup from "yup";
import {validatePassword} from "@core/helpers";

export const validationSchema = yup.object().shape({
  username: yup.string().required("Username is required!"),
  firstname: yup.string().required("Name is required!"),
  lastname: yup.string().required("Last name is required!"),
  password: yup.string()
    .required("Password is required!")
    .test("password", validatePassword),
  passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], "Passwords don't match")
    .required("Password confirmation is required!"),
  role: yup.string().required("Role name is required!"),
  email: yup.string().email("Email has to be of type name@email.com").required("Email name is required!")
});