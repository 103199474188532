import openSocket from "socket.io-client";
import {api} from "Config";

class WS {
  constructor(url) {
    this.url = url;
    this.connection = {};
  }

  connect(user) {
    this.connection = openSocket(this.url, {query: {userId: user._id}});
  }

  listen(type, callback) {
    if (this.connection.on) {
      this.connection.on(type, callback);
    }
  }

  remove(type) {
    this.connection.off(type);
  }
}

export default new WS(api);

