import React from "react";
import EmptyLabTest from "../EmptyLabTest";
import EmptyProducerTest from "../EmptyProducerTest";
import testsConfig from "@core/configs/test";
import modules from "@core/constants/modules";

const EmptyTest = ({test, ...props}) => {
  if(testsConfig[test.type].responsible.includes(modules.PRODUCER)) {
    return (
      <EmptyProducerTest
        test={test}
        {...props}
      />
    );
  }

  return (
    <EmptyLabTest
      test={test}
      {...props}
    />
  );
};

export default EmptyTest;