import React from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {Formik} from "formik";
import MuiSelect from "@core/components/MuiSelect";
import Input from "@core/components/Form/Input";
import TestResults from "./TestResults";
import {debounce, getDeviation, getMean} from "@core/helpers";
import {TEST_STANDARDS, TEST_METHODS, STRESS_AT_BREAK_DEFAULTS} from "./constants";
import {FIELD_NAMES, DISPLAY_NAME_BY_FIELD} from "@core/constants/transverseRuptureStrength";
import {TEST_RESULTS} from "@core/constants/testResults";

const TransverseRuptureStrength = ({test, closeNewTest, saveTest, formRef}) => {

  const {acceptanceCriteria, testStandard, method, stressAtBreak} = test?.properties ?? {};

  const initialValues = {
    acceptanceCriteria: acceptanceCriteria ?? undefined,
    testStandard: testStandard ?? TEST_STANDARDS[0],
    method: method ?? TEST_METHODS[0],
    stressAtBreak: stressAtBreak ?? STRESS_AT_BREAK_DEFAULTS,
  };

  const onSubmit = (values, actions) => {
    const acceptanceCriteria = values.acceptanceCriteria;
    const stressAtBreak = values.stressAtBreak;
    const isAccepted = stressAtBreak.every(({x, y, z}) => x >= acceptanceCriteria && y >= acceptanceCriteria && z >= acceptanceCriteria);
    const result = isAccepted ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

    const standardDeviation ={
      x: Math.round(getDeviation(stressAtBreak?.map(({x}) => x))),
      y: Math.round(getDeviation(stressAtBreak?.map(({y}) => y))),
      z: Math.round(getDeviation(stressAtBreak?.map(({z}) => z))),
    };

    const meanValues = {
      x: Math.round(getMean(stressAtBreak?.map(({x}) => x))),
      y: Math.round(getMean(stressAtBreak?.map(({y}) => y))),
      z: Math.round(getMean(stressAtBreak?.map(({z}) => z))),
    };

    const formData = {...values, result, standardDeviation, meanValues};

    saveTest(formData, {witnesses: []});
    actions.setSubmitting(false);

    closeNewTest && closeNewTest();
  };

  return (
    <Box>
      <Typography marginBottom={4} variant="h5">Transverse Rupture Strength</Typography>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}>
        {({handleSubmit, handleChange}) => {

          return (
            <>
              <Grid container gap={4}>
                <Grid xs={3} item>
                  <MuiSelect
                    required
                    name={FIELD_NAMES.testStandard}
                    defaultOptions={TEST_STANDARDS}
                    label={DISPLAY_NAME_BY_FIELD[FIELD_NAMES.testStandard]}
                  />
                </Grid>
                <Grid xs={4} item>
                  <MuiSelect
                    required
                    name={FIELD_NAMES.method}
                    defaultOptions={TEST_METHODS}
                    label={DISPLAY_NAME_BY_FIELD[FIELD_NAMES.method]}
                  />
                </Grid>
              </Grid>
              <Grid marginTop={4} container>
                <Grid xs={2}>
                  <Input
                    required
                    defaultValue={initialValues.acceptanceCriteria}
                    endAdornment={<span>≥&nbsp;ksi</span>}
                    type="number"
                    name={FIELD_NAMES.acceptanceCriteria}
                    label={DISPLAY_NAME_BY_FIELD[FIELD_NAMES.acceptanceCriteria]}
                    onChange={debounce(handleChange)}
                  />
                </Grid>
              </Grid>

              <Box marginTop={3}>
                <TestResults/>
              </Box>

              <Grid container justifyContent="flex-end">
                <Grid item><Button variant="contained" onClick={handleSubmit}>Submit</Button></Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default TransverseRuptureStrength;