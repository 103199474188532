export default (theme) => ({
  name: {
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },

  value: {
    color: "#616161",
    fontWeight: theme.typography.fontWeightMedium,
  },

  tableColumn: {
    border: "1px solid #e0e0e0",
  }
});

