import React, {memo} from "react";
import Pipe from "../AnimatedPipe";
import {DOUBLE_RED_BAND_PIPE_CONFIG} from "../../constants";

const DoubleRedBandPipe = ({...props}) => {
  return (
    <Pipe
      {...props}
      fillLinearGradientStartPoint={{x: 10, y: 0}}
      fillLinearGradientEndPoint={{x: 10, y: props.pipeConfig.HEIGHT}}
      fillLinearGradientColorStops={[0.5, props.pipeConfig.BACKGROUND_COLOR, 0.5, DOUBLE_RED_BAND_PIPE_CONFIG.BACKGROUND_COLOR]}
    />
  );
};

export default memo(DoubleRedBandPipe);