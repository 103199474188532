export default () => ({
  acceptedButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    width: 164,
  },
  notAcceptedButton: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  editor: {
    "& .rdw-editor-main": {
      backgroundColor: "#ffffff",
    }
  }
});