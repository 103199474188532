import {ACTIONS} from "@core/constants/api";
import {api} from "Config";

const ACCOUNT = {
  [ACTIONS.SINGLE]:
    `${api}/account/users/me`,// logged in user
  [ACTIONS.ALL]:
    `${api}/account/users/getUsersInfo`,// user's accounts
  [ACTIONS.RESET_BC_ADDRESS]:
    `${api}/account/users/me/reset-bc-address`,
  [ACTIONS.LOGIN]:
    `${api}/account/login`,
  [ACTIONS.LOGOUT]:
  `${api}/account/logout`,
  [ACTIONS.GENERATE_TOTP]:
    `${api}/account/generate-totp`,
  [ACTIONS.VALIDATE_TOTP]:
    `${api}/account/validate-totp`,
  [ACTIONS.VERIFY_TOTP]:
    `${api}/account/verify-totp`,
  [ACTIONS.GET_RECOVERY_TOKEN]:
    `${api}/account/get-recovery-token`,
  [ACTIONS.RECOVER_PASSWORD]: (id) =>
    `${api}/account/recover-password/${id}`,
  [ACTIONS.RESET_PASSWORD]:
  `${api}/account/resetPassword`,
  [ACTIONS.SELECT_PROVIDER]: (provider) =>
    `${api}/auth/${provider}/sign-in`,
  [ACTIONS.CHANGE_PASSWORD]:
    `${api}/account/password`,
  [ACTIONS.REFRESH_TOKEN]:
    `${api}/account/refreshToken`
};

const USER = {
  [ACTIONS.ALL]:
    `${api}/account/users`,
  [ACTIONS.CREATE]:
    `${api}/account/users`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/account/users/${id}`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/account/users/${id}`,
  [ACTIONS.VALIDATE]:
    `${api}/account/users/invite-validate`,
  [ACTIONS.REMOVE]:
    `${api}/account/users/pending`,
  [ACTIONS.INVITE_USERS]:
    `${api}/account/users/invite`,
  [ACTIONS.CREATE_FROM_INVITE]:
    `${api}/account/users/create-from-invite`,
  [ACTIONS.RESEND_INVITE]:
    `${api}/account/users/invite-again`,
  [ACTIONS.VALIDATE_TOKEN]:
    `${api}/account/users/create-from-invite-validate`,
  [ACTIONS.PRODUCERS]:
    `${api}/account/producers`,
  [ACTIONS.REGISTER]:
    `${api}/account/register`,
};

const EXTERNAL_USER = {
  [ACTIONS.ALL]:
    `${api}/viewers`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/viewers/${id}`,
  [ACTIONS.CREATE]:
    `${api}/viewers`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/viewers/${id}`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/viewers/${id}`,
  [ACTIONS.CHANGE_PASSWORD]: (id) =>
    `${api}/viewers/changePassword/${id}`,
  [ACTIONS.TRANSFER]:
    `${api}/viewers/batchassigncertificate`,
};

const LOG = {
  [ACTIONS.ALL]:
    `${api}/logs`,
};

const AUTH = {
  [ACTIONS.CREATE]: (provider) =>
    `${api}/auth/${provider}/link`,
  [ACTIONS.REMOVE]: (provider) =>
    `${api}/auth/${provider}/unlink`,
  [ACTIONS.LOGIN]: (id) =>
    `${api}/auth/sign-in/${id}`
};

const CAMPAIGN = {
  [ACTIONS.ALL]:
    `${api}/campaigns`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/campaigns/${id}`,
  [ACTIONS.CREATE]:
    `${api}/campaigns`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/campaigns/${id}`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/campaigns/${id}`,
  [ACTIONS.IMPORT]: (id) =>
    `${api}/campaigns/uploads/${id}`,
  [ACTIONS.PARSE]: (id) =>
    `${api}/campaigns/tests/${id}`,
  [ACTIONS.REMOVE_TEST]: (id, testId) =>
    `${api}/campaigns/${id}/tests/${testId}`,
};

const RAW_MATERIAL = {
  [ACTIONS.CREATE]:
    `${api}/raw-materials`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/raw-materials/${id}`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/raw-materials/${id}`,
};

const WELD = {
  [ACTIONS.REMOVE]: (id) =>
    `${api}/campaigns/${id}/welds`,
};

const TRANSFER_CAMPAIGN_PRODUCT = {
  [ACTIONS.ALL]:
    `${api}/transfers/campaign-products`
};

const ARCHIVE = {
  [ACTIONS.ALL]:
    `${api}/archives/certificates`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/archives/certificates/${id}`,
};

const CERTIFICATE = {
  [ACTIONS.ALL]:
    `${api}/certificates`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/certificates/${id}`,
  [ACTIONS.UPDATE_WITH_ORDER]: (id) =>
    `${api}/certificates/${id}/order`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/certificates/${id}`,
  [ACTIONS.CREATE]:
    `${api}/certificates`,
  [ACTIONS.CREATE_BULK]:
    `${api}/certificates/bulk`,
  [ACTIONS.REMOVE]:
    `${api}/certificates`,
  [ACTIONS.CONVERT]: (id) =>
    `${api}/certificates/${id}/productType`,
  [ACTIONS.CONVERT_MULTIPLE]:
    `${api}/certificates/productType`,
  [ACTIONS.SPLIT]: (id) =>
    `${api}/certificates/${id}/split`,
  [ACTIONS.SINGLE_BY_TEST_ID]: (id) =>
    `${api}/certificates/test/${id}`,
  [ACTIONS.REMOVE_TEST]: (id, testId) =>
    `${api}/certificates/${id}/test/${testId}`,
  [ACTIONS.REMOVE_CONDITION]: (id, conditionId) =>
    `${api}/certificates/${id}/condition/${conditionId}`,
  [ACTIONS.TRANSFER]:
    `${api}/certificates/transfer`,
  [ACTIONS.ALL_BY_QUERY]: (id) =>
    `${api}/certificates/all/test/${id}`,
  [ACTIONS.PARSE]:
    `${api}/certificates/parseFile`,
  [ACTIONS.IMPORT]:
    `${api}/certificates/uploadParsed`,
};

const TRANSFER = {
  [ACTIONS.ALL]:
    `${api}/transfers`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/transfers/${id}`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/transfers/${id}`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/transfers/${id}`,
  [ACTIONS.OPEN]: (id) =>
    `${api}/transfers/reopen/${id}`,
  [ACTIONS.CLOSE]: (id) =>
    `${api}/transfers/approve/${id}`,
  [ACTIONS.SINGLE_CERTIFICATES]: (id, certificateId) =>
    `${api}/transfers/${id}/certificate/${certificateId}`,
  [ACTIONS.DOWNLOAD]: (id) =>
    `${api}/transfers/download/${id}`
};

const DRAFT_TRANSFER = {
  [ACTIONS.CREATE]:
    `${api}/transfers/draft`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/transfers/draft/${id}`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/transfers/draft/${id}`
};

const PORTAL_ORDER = {
  [ACTIONS.SINGLE]: (id, lineItem) =>
    `${api}/portalOrders/feedback/${id}/${lineItem}`,
  [ACTIONS.CREATE]: (id, lineItem) =>
    `${api}/portalOrders/feedback/${id}/${lineItem}`,
  [ACTIONS.UPDATE]: (id, lineItem, feedbackNumber) =>
    `${api}/portalOrders/feedback/${id}/${lineItem}/${feedbackNumber}`
};

const PORTAL_ORDER_MESSAGE = {
  [ACTIONS.SINGLE]: (id) =>
    `${api}/portalOrders/feedback/${id}`,
  [ACTIONS.ALL]:
    `${api}/portalOrders/messages`
};

const PORTAL_CERTIFICATE = {
  [ACTIONS.SINGLE]: (id, lineItem) =>
    `${api}/portalOrders/status/${id}/${lineItem}`
};

const PRODUCT_TYPE = {
  [ACTIONS.ALL]:
    `${api}/productTypes`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/productTypes/${id}`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/productTypes/${id}`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/productTypes/${id}`,
  [ACTIONS.CREATE]:
    `${api}/productTypes`
};

const TRANSACTION_HASH = {
  [ACTIONS.UPDATE]: (id, type) =>
    `${api}/${type}/updateHash/${id}`
};

const CONDITION = {
  [ACTIONS.CREATE]:
  `${api}/conditions`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/conditions/presets/${id}`,
  [ACTIONS.SINGLE_BY_TEST_ID]: (id) =>
    `${api}/conditions/tests/${id}`,
  [ACTIONS.REMOVE_TEST]: (id) =>
    `${api}/tests/${id}`,
  [ACTIONS.SINGLE_BY_CERTIFICATE_ID]: (id) =>
    `${api}/conditions/certificates/${id}`,
};

const CONDITION_PRESET = {
  [ACTIONS.ALL]:
    `${api}/conditions/presets`,
  [ACTIONS.CREATE]:
    `${api}/conditions/presets`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/conditions/presets/${id}`,
};

const COMPANY = {
  [ACTIONS.CREATE]:
    `${api}/companies`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/companies/${id}`,
  [ACTIONS.ALL]:
    `${api}/companies`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/companies/${id}`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/companies/${id}`,
  [ACTIONS.META]:
    `${api}/companies/meta`,
  [ACTIONS.SINGLE_USERS]: (id) =>
    `${api}/companies/${id}/users`,
  [ACTIONS.SINGLE_TESTS]: (id) =>
    `${api}/companies/${id}/tests`,
};

const BLOCKCHAIN_COMPANY = {
  [ACTIONS.CREATE]:
    `${api}/blockchain/companies`,
  [ACTIONS.SINGLE_USERS]: (id) =>
    `${api}/blockchain/companies/${id}/users`
};

const MODULE = {
  [ACTIONS.ALL]:
    `${api}/modules`,
  [ACTIONS.SINGLE_BY_TYPE]:
  `${api}/companies/modules`,
};

const FEATURE = {
  [ACTIONS.ALL]:
    `${api}/features`,
};

const STATISTIC = {
  [ACTIONS.ALL]:
    `${api}/statistics`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/statistics/companies/${id}`,
};

const TEST = {
  [ACTIONS.ALL]:
    `${api}/tests`,
  [ACTIONS.TYPES]:
    `${api}/tests/types`,
  [ACTIONS.CREATE]:
    `${api}/tests/bulk`,
  [ACTIONS.UPLOAD]:
    `${api}/tests/parseFile`,
  [ACTIONS.IMPORT]:
  `${api}/tests/import/jsonData`,
  [ACTIONS.CREATE_BULK]:
    `${api}/tests/bulkCertificates`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/tests/${id}`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/tests/${id}`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/tests/${id}`,
  [ACTIONS.ALL_BY_QUERY]:
    `${api}/tests/exist`,
};

const WITNESS_REVIEW = {
  [ACTIONS.UPDATE]: (id, witnessCompanyId) =>
    `${api}/tests/${id}/review/witness/${witnessCompanyId}`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/tests/${id}/download/witnessFiles`,
};

const INSTRUMENT = {
  [ACTIONS.ALL]:
    `${api}/instruments`,
  [ACTIONS.CREATE]:
    `${api}/instruments`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/instruments/${id}`,
  [ACTIONS.REMOVE]:
    `${api}/instruments/bulk`
};

const STATEMENT_PRESET = {
  [ACTIONS.ALL]:
    `${api}/presets`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/presets/${id}`,
  [ACTIONS.UPLOAD]:
    `${api}/presets/file`,
  [ACTIONS.CREATE]:
    `${api}/presets`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/presets/${id}`,
};

const PRODUCT = {
  [ACTIONS.ALL]:
    `${api}/products`,
  [ACTIONS.CREATE]:
    `${api}/products`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/products/${id}`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/products/${id}`,
};

const PROJECT = {
  [ACTIONS.ALL]:
    `${api}/projects`,
  [ACTIONS.REMOVE]: (id) =>
    `${api}/projects/${id}`,
  [ACTIONS.CREATE]:
    `${api}/projects`,
  [ACTIONS.SINGLE]: (id) =>
    `${api}/projects/${id}`,
  [ACTIONS.LINK]: (id) =>
    `${api}/projects/${id}/link`,
  [ACTIONS.UNLINK]: (id) =>
    `${api}/projects/${id}/unlink`,
};

const SPECIFICATION = {
  [ACTIONS.ALL]:
    `${api}/specifications`,
  [ACTIONS.CREATE]:
    `${api}/specifications`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/specifications/${id}`,
  [ACTIONS.REMOVE]:
    `${api}/specifications/bulk`,
};

const BASE_PRODUCT = {
  [ACTIONS.CREATE]:
    `${api}/coatings/base-products`,
};

const UPLOAD = {
  [ACTIONS.REMOVE]: (id) =>
    `${api}/uploads/${id}`,
  [ACTIONS.CREATE]:
    `${api}/uploads`,
  [ACTIONS.UPDATE]: (id) =>
    `${api}/uploads/${id}`,
};

const TEST_NORM = {
  [ACTIONS.ALL_BY_QUERY]: (type) =>
    `${api}/tests/norm/${type}`
};

const TEST_CHART = {
  [ACTIONS.SINGLE]:
    `${api}/tests/charts`,
  [ACTIONS.TYPES]:
    `${api}/tests/charts/param`
};

const REFERENCE = {
  [ACTIONS.CREATE]:
    `${api}/reference`,
};

const QCP = {
  [ACTIONS.ALL]:
    `${api}/qcps`,
};

const METRICS = {
  [ACTIONS.ALL]:
    `${api}/metrics`,
};

const OPEN_AI = {
  [ACTIONS.SINGLE]: 
  `${api}/openai-call/code-explanation`
};

export const ROUTES = {
  ACCOUNT,
  EXTERNAL_USER,
  USER,
  LOG,
  AUTH,
  CAMPAIGN,
  RAW_MATERIAL,
  WELD,
  TRANSFER_CAMPAIGN_PRODUCT,
  ARCHIVE,
  CERTIFICATE,
  TRANSFER,
  PORTAL_ORDER,
  PRODUCT_TYPE,
  DRAFT_TRANSFER,
  TRANSACTION_HASH,
  CONDITION,
  CONDITION_PRESET,
  COMPANY,
  BLOCKCHAIN_COMPANY,
  MODULE,
  FEATURE,
  STATISTIC,
  TEST,
  INSTRUMENT,
  STATEMENT_PRESET,
  PRODUCT,
  PROJECT,
  SPECIFICATION,
  WITNESS_REVIEW,
  BASE_PRODUCT,
  UPLOAD,
  TEST_NORM,
  TEST_CHART,
  PORTAL_ORDER_MESSAGE,
  PORTAL_CERTIFICATE,
  REFERENCE,
  QCP,
  METRICS,
  OPEN_AI
};