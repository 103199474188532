import {downloadBlob} from "@core/helpers";
import {TableStore} from "@core/stores";
import axios from "axios";
import {action, computed, makeObservable, observable} from "mobx";
import {isEmpty} from "ramda";
import {ACTIONS} from "@core/constants/api";
import {ROUTES} from "@core/api/routes";

class TestStore {
  constructor() {
    makeObservable(this, {
      tests: observable,
      selectedTests: computed,
      test: observable,
      create: action,
      parseFile: action,
      importJsonData: action,
      createMultipleTestsForMultipleCertificates: action,
      update: action,
      delete: action,
      changeTest: action,
      getTestById: action,
      updateHash: action,
      witnessReview: action,
      downloadWitnessFiles: action,
    });
  }
  
  tests = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.tests.fetch, ...queryParams};
      const {
        offset, limit, search, sort,
        statuses = [], witnessStatuses = [], certificateIds = [],
        hideUnsignedCertificates = false
      } = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value: search},
        statuses,
        select: "-stringified",
        witnessStatuses,
        certificateIds,
        hideUnsignedCertificates,
      };

      this.tests.setFetch(newQueryParams);

      return axios.get(
        ROUTES.TEST[ACTIONS.ALL],
        {params}
      );
    }
  });
  
  get selectedTests() {
    return this.tests.data.filter((c) => this.tests.selected.includes(c._id)) || [];
  }

  test = {
    data: {},
    isLoaded: false,
  };

  async create(data) {
    this.test.isLoaded = false;

    const res = await axios.post(ROUTES.TEST[ACTIONS.CREATE],{tests: data}, {
      withCredentials: true,
    });

    this.test.isLoaded = true;

    return res.data;
  }

  async parseFile(data, testType, qcp, poItem, extensionFeatureType, companyName) {
    const formData = new FormData();

    formData.append("file", data);
    formData.append("testType", testType);

    if(qcp) {
      formData.append("qcp", qcp);
    }

    if(poItem) {
      formData.append("poItem", poItem);
    }

    if(extensionFeatureType && companyName) {
      formData.append("extensionFeatureType", extensionFeatureType);
      formData.append("companyName", companyName);
    }

    const response = await axios.post(ROUTES.TEST[ACTIONS.UPLOAD], formData, {
      withCredentials: true,
    });

    return response.data;
  }

  async importJsonData({testsData, shouldCheckJson = false}) {
    const response = await axios.post(
      ROUTES.TEST[ACTIONS.IMPORT],
      {data: {testsData, shouldCheckJson}},
      {
        withCredentials: true,
        successMessage: "The data was imported."
      },
    );

    return response.data;
  }

  async createMultipleTestsForMultipleCertificates(certificatesIds, tests) {
    const response = await axios.post(ROUTES.TEST[ACTIONS.CREATE_BULK], {certificates: certificatesIds, tests}, {
      withCredentials: true,
    });

    return response.data;
  }

  async update(data, id) {
    this.test.isLoaded = false;

    const res = await axios.patch(ROUTES.TEST[ACTIONS.UPDATE](id), data,{
      withCredentials: true,
    });

    this.test = {
      data: res.data,
      isLoaded: true
    };

  }

  async delete(id) {
    await axios.delete(ROUTES.TEST[ACTIONS.REMOVE](id), {
      withCredentials: true,
      successMessage: "The test has been removed"
    });

    this.tests.data = this.tests.data.filter((test) => test._id !== id);
    this.tests.total = this.tests.total - 1;
  }

  changeTest(newTest) {
    this.test.data = {...this.test.data, ...newTest};
  }
  async getTestById(id) {
    this.test.isLoaded = false;

    const res = await axios.get(ROUTES.TEST[ACTIONS.SINGLE](id),{
      withCredentials: true
    });

    this.test = {
      data: res.data,
      isLoaded: true,
      isEmpty: res.data.hasOwnProperty("error"),
    };
  }

  async updateHash(id, testHash, data) {
    await axios.patch(ROUTES.TRANSACTION_HASH[ACTIONS.UPDATE](id, "tests"), {testHash, stringified: JSON.stringify(data)});
  }

  async witnessReview(id, data, witnessCompanyId) {
    const response = await axios.patch(ROUTES.WITNESS_REVIEW[ACTIONS.UPDATE](id, witnessCompanyId), data);

    this.test.data = response.data;
    this.tests.data = this.tests.data.map((test) => test._id === id ? response.data : test);
  }

  async downloadWitnessFiles(id) {
    const response = await axios.post(ROUTES.WITNESS_REVIEW[ACTIONS.SINGLE](id), {}, {
      responseType: "arraybuffer",
    });

    downloadBlob(response.data, `Inspection_files_${id}`, "application/zip");
  }
}

export default new TestStore();
