import React, {useCallback, memo, useMemo, useRef} from "react";
import {Rect} from "react-konva";
import Defect from "../Defect";

const AnimatedPipe = ({settings, id, tooltipData, index, pipes, welds = {}, animateShape, pipeCoordinates, weldCoordinates = {}, setOpenPipe, pipeConfig, weldConfig = {}, defects, ...props}) => {
  const pipe = useRef();

  const maxScalePipeY = useMemo(() => -(pipeConfig.HEIGHT * pipeConfig.MAX_SCALE - pipeConfig.HEIGHT) / 2, []);
  const midScalePipeY = useMemo(() => -(pipeConfig.HEIGHT * pipeConfig.MEDIUM_SCALE - pipeConfig.HEIGHT) / 2, []);
  const midScaleWeldY =  useMemo(() => (pipeConfig.HEIGHT - weldConfig.HEIGHT * weldConfig.MEDIUM_SCALE) / 2, []);

  const scaleNeighboringWelds = useCallback((index) => {
    const prevWeld = welds.current[index - 1];
    const nextWeld = welds.current[index];

    animateShape(prevWeld, {
      scaleX: weldConfig.MEDIUM_SCALE,
      scaleY: weldConfig.MEDIUM_SCALE,
      y: midScaleWeldY,
      offsetX: 1
    });

    animateShape(nextWeld, {
      scaleX: weldConfig.MEDIUM_SCALE,
      scaleY: weldConfig.MEDIUM_SCALE,
      y: midScaleWeldY,
      offsetX: 1
    });
  }, []);

  const scaleNeighboringPipes = useCallback(() => {
    const prevPipe = pipes.current[index - 1];
    const nextPipe = pipes.current[index + 1];

    animateShape(prevPipe, {
      scaleX: pipeConfig.MEDIUM_SCALE,
      scaleY: pipeConfig.MEDIUM_SCALE,
      y: midScalePipeY,
    });

    animateShape(nextPipe, {
      scaleX: pipeConfig.MEDIUM_SCALE,
      scaleY: pipeConfig.MEDIUM_SCALE,
      y: midScalePipeY,
      offsetX: 1,
    });
  }, [index]);

  const onMouseEnter = useCallback((e) => {
    document.body.style.cursor = "pointer";

    pipe.current.setAttr("data", tooltipData);

    e.cancelBubble = true;

    if(!animateShape) return;

    const currentPipe = pipes.current[index];

    animateShape(currentPipe, {
      scaleX: pipeConfig.MAX_SCALE,
      scaleY: pipeConfig.MAX_SCALE,
      y: maxScalePipeY,
      offsetX: 1.5,
    });

    const scaleNeighboringShapes = settings.welds ? scaleNeighboringWelds : scaleNeighboringPipes;
    scaleNeighboringShapes(index);
  }, [settings.welds, index]);

  const resetNeighboringWelds = useCallback((index) => {
    const prevWeld = welds.current[index - 1];
    const nextWeld = welds.current[index];

    animateShape(prevWeld, {
      y: weldCoordinates.y,
      scaleX: 1,
      scaleY: 1,
      offsetX: 0,
    });

    animateShape(nextWeld, {
      y: weldCoordinates.y,
      scaleX: 1,
      scaleY: 1,
      offsetX: 0,
    });
  }, [weldCoordinates.y]);

  const resetNeighboringPipes = useCallback((index) => {
    const prevPipe = pipes.current[index - 1];
    const nextPipe = pipes.current[index + 1];

    animateShape(prevPipe, {
      y: pipeCoordinates.y,
      scaleX: 1,
      scaleY: 1,
      offsetX: 0,
    });

    animateShape(nextPipe, {
      y: pipeCoordinates.y,
      scaleX: 1,
      scaleY: 1,
      offsetX: 0,
    });
  }, [pipeCoordinates.y]);

  const onMouseLeave = useCallback((e) => {
    document.body.style.cursor = "default";

    e.cancelBubble = true;

    if(!animateShape) return;

    const pipe = pipes.current[index];

    animateShape(pipe, {
      x: pipeCoordinates.x,
      y: pipeCoordinates.y,
      scaleX: 1,
      scaleY: 1,
      offsetX: 0,
    });

    const resetNeighboringShapes = settings.welds ? resetNeighboringWelds : resetNeighboringPipes;
    resetNeighboringShapes(index);
  }, [pipeCoordinates.x, settings.welds, index]);

  return (
    <>
      <Rect
        id={id}
        perfectDrawEnabled={false}
        ref={(element) => {
          if(pipes.current[index] && element) {
            pipes.current[index].parent?.destroy();
          }

          pipes.current[index] = element;
          pipe.current = element;
        }}
        x={pipeCoordinates.x}
        y={pipeCoordinates.y}
        width={pipeConfig.WIDTH}
        height={pipeConfig.HEIGHT}
        cornerRadius={pipeConfig.RADIUS}
        shadowForStrokeEnabled={false}
        hitStrokeWidth={pipeConfig.HIT_STROKE_WIDTH}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => setOpenPipe(id)}
        {...props}
      />
      {settings.highlightCoatingDefects && (
        <Defect
          settings={settings}
          defects={defects}
          config={pipeConfig}
          coordinates={pipeCoordinates}
        />
      )}
    </>
  );
};

export default memo(AnimatedPipe);