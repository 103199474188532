import {action, makeObservable, observable} from "mobx";
import {equals, reject} from "ramda";

class FilterStore {
  constructor() {
    makeObservable(this, {
      filters: observable,
      setFilters: action,
    });
  }
  filters = {};

  setFilters = (filters) => {
    this.filters = reject(equals([]))(filters);
  };
}

export default new FilterStore();