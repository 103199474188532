const ACCEPTABLE_TEST_RESULTS = {
  ACCEPTABLE: "Acceptable",
  NOT_DEFECTIVE: "Not defective",
  NO_RECORDABLE_INDICATIONS: "No recordable indications",
  NO_DEFECTS: "No defects",
  ACCEPTABLE_DEFECTS: "Acceptable defects",
  NO_INDICATION: "No indication",
  ACCEPTABLE_INDICATIONS: "Acceptable indications",
  ABSENCE_OF_CRACKS: "Absence of cracks and fissures after bending",
  ABSENCE_OF_CRACKS_AFTER_FIRST_STEP: "Absence of cracks on the specimen after first step"
};

const NOT_ACCEPTABLE_TEST_RESULTS = {
  NOT_ACCEPTABLE: "Not Acceptable",
  PRESENCE_OF_CRACKS: "Presence of cracks and fissures after bending",
  NOT_ACCEPTABLE_INDICATIONS: "Not acceptable indications",
  NOT_ACCEPTABLE_DEFECTS: "Not acceptable defects"
};

const PARTIALLY_UNACCEPTABLE_TEST_RESULTS = {
  PARTIALLY_UNACCEPTABLE: "Partially Unacceptable"
};

const TEST_RESULTS = {
  ...ACCEPTABLE_TEST_RESULTS,
  ...NOT_ACCEPTABLE_TEST_RESULTS,
  ...PARTIALLY_UNACCEPTABLE_TEST_RESULTS,
};

export {ACCEPTABLE_TEST_RESULTS, NOT_ACCEPTABLE_TEST_RESULTS, PARTIALLY_UNACCEPTABLE_TEST_RESULTS, TEST_RESULTS};