import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Box, Grid, Typography} from "@mui/material";
import DashboardTable from "../Table";
import StatisticsSection from "../../../StatisticsSection";
import useStores from "../../../../../useStores";
import {columns} from "./columns";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

const BasicDashboard = observer(({userCompanyId}) => {
  const {StatisticStore} = useStores();

  useEffect(() => {
    StatisticStore.getCompanyStatistics(userCompanyId);
  }, []);

  const statistic = StatisticStore.statistic;

  return (
    <Box sx={{padding: 4}}>
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <StatisticsSection
              isLoading={!statistic.isLoaded}
              data={[{
                number: statistic.data.owned || 0,
                description: "Certificates owned",
                link: "/certificates",
                icon: ICONISTS_ICONS.sketchbook
              }, {
                number: statistic.data.transferred || 0,
                description: "Certificates transferred",
                link: "/certificates/archive",
                icon: ICONISTS_ICONS.archive
              }]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DashboardTable
            title="Certificates ready for transfer"
            noFoundText="No certificates found."
            type="certificates"
            columns={columns}
            onRowClick={(certificate) => window.open(`/certificates/${certificate._id}`, "_self")}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default BasicDashboard;
