import React from "react";
import {Button, Grid, IconButton, TableCell, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Input} from "@core/components/Form";
import {Uploader} from "@core/components/Uploaders";
import File from "@core/components/File";
import moment from "moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import styles from "./styles";
import classNames from "classnames";
import CheckIcon from "@mui/icons-material/Check";
import {MdEditNote} from "react-icons/md";
import {IoMdCloseCircleOutline} from "react-icons/io";
import {GrDocumentUpload} from "react-icons/gr";
import EditableCell from "../EditableCell";
import {isEmpty} from "ramda";
export default withStyles(
  (props) => {
    const {values, index, actions, editable, classes, disabled} = props;
    const {standard, issuingBody, description, from, to, link} = values;

    const errors = props.errors[index] || {};
    const touched = props.touched[index] || {};

    return (
      <TableRow>
        <TableCell>
          <EditableCell
            id={standard.path}
            disabled={disabled}
            placeholder={"Standard"}
            classes={classes}
            value={standard.value}
            required = {true}
            editable={editable}
            error={errors.standard}
            touched={touched.standard}
            onChange={(e) => actions.change(standard.path, e)}
            onBlur={() => actions.blur(standard.path, "standard")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            id={issuingBody.path}
            disabled={disabled}
            placeholder={"Issue Body"}
            classes={classes}
            value={issuingBody.value}
            required = {true}
            editable={editable}
            error={errors.issuingBody}
            touched={touched.issuingBody}
            onChange={(e) => actions.change(issuingBody.path, e)}
            onBlur={() => actions.blur(issuingBody.path, "issuingBody")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            id={description.path}
            disabled={disabled}
            placeholder={"Description"}
            classes={classes}
            value={description.value}
            required = {true}
            editable={editable}
            error={errors.description}
            touched={touched.description}
            onChange={(e) => actions.change(description.path, e)}
            onBlur={() => actions.blur(description.path, "description")}
          />
        </TableCell>
        <TableCell>
          {editable ? (
            <Input
              classes={{
                input: classes.input,
                root: classNames({
                  [classes.inputRoot]: true,
                })
              }}
              id={from.path}
              disabled={disabled}
              placeholder="From"
              type="date"
              className={classes.underlineInput}
              value={moment(from.value).format(DATE_FORMAT)}
              name={from.path}
              error={errors.from}
              touched={touched.from}
              onClick={() => actions.setFieldTouched(from.path, true)}
              onChange={(e) => actions.change(from.path, e)}
            />
          ) : (
            <p className={classes.value}>
              {from.value ? moment(from.value).format(DATE_FORMAT) : "-"}
            </p>
          )}
        </TableCell>
        <TableCell>
          {editable ? (
            <Input
              classes={{
                input: classes.input,
                root: classNames({
                  [classes.inputRoot]: true,
                })
              }}
              id={to.path}
              disabled={disabled}
              placeholder="To"
              type="date"
              className={classes.underlineInput}
              value={moment(to.value).format(DATE_FORMAT)}
              name={to.path}
              error={errors.to}
              touched={touched.to}
              onClick={() => actions.setFieldTouched(to.path, true)}
              onChange={(e) => actions.change(to.path, e)}
            />
          ): (
            <p className={classes.value}>
              {to.value ? moment(to.value).format(DATE_FORMAT) : "-"}
            </p>
          )}
        </TableCell>
        <TableCell className={classNames(classes.fileCell,(editable)?classes.requiredInput:"")}>
          {editable ? (
            <Uploader
              file={link.value}
              fileType="application/pdf"
              buttonText="upload PDF file"
              required
              handleUploadedFile={(e) => actions.uploadImage(link.path, e)}
              onRemove={() => actions.resetPreviewImage(link.path)}
              uploadButton={(
                <Button
                  className={classes.uploadButton}
                  component="span"
                >
                  <GrDocumentUpload size="20px" />
                </Button>
              )}
              removeButton={(
                <IconButton
                  className={classes.removeButton}
                  onClick={() => actions.resetPreviewImage(link.path)}
                  size="large">
                  <IoMdCloseCircleOutline />
                </IconButton>
              )}
            />):( (link.value) ? (
            <File
              preview
              file={link.value}
            />) :
            "-")}
        </TableCell>
        <TableCell width="130" className={classes.actionCell}>
          <Grid container justifyContent='start' spacing={1}>
            <Grid item>
              <IconButton
                onClick={() => actions.save()}
                disabled={(editable && !isEmpty(errors)) || disabled}
                size="large">
                {editable?(<CheckIcon/>):(<MdEditNote/>)}
              </IconButton>
            </Grid>
            {actions.remove &&<Grid item>
              <IconButton
                className={classes.removeButton}
                onClick={() => actions.remove()}
                size="large">
                <IoMdCloseCircleOutline />
              </IconButton>
            </Grid>}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }, styles);
