export default () => ({
  btnAddCell: {
    textAlign: "center"
  },

  btnAdd: {
    margin: "0 auto",
    width: "50%"
  },

  gridRoot: {
    marginTop: 40,
    marginBottom: 20
  },

  paddingCheckbox: {
    padding: "5px 12px",
  },

  saveButton: {
    marginLeft: "auto",
  }
});

