import {getLocationAddress} from "@core/helpers";
import React, {useMemo} from "react";
import moment from "moment";
import {flatten, uniq} from "ramda";
import {observer} from "mobx-react-lite";
import {Divider, Grid, Skeleton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import FilterButton from "@core/components/FilterButton";
import AppliedFiltersList from "@core/components/AppliedFiltersList";
import OrderNumber from "../OrderNumber";
import {FILTERS} from "@core/constants/filters";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import useStores from "../../../../useStores";
import styles from "./styles";
import {useParams} from "react-router";
import classNames from "classnames";

const FILTERS_LIST = [FILTERS.PO_ITEM_NO, FILTERS.HEAT, FILTERS.HEAT_LOT, FILTERS.LOCATION];

const HeaderBottomLine = observer(({classes, interfaceType}) => {
  const {TransferStore, MenuStore} = useStores();

  const {id: transferId} = useParams();

  const transfer = TransferStore.transfer;

  const isLoaded = transfer._id === transferId;
  const {receiver, sender, viewer, date_created: dateCreated, certificates = [], campaigns = [], isDraft} = transfer;

  const isMenuCollapsed = MenuStore.collapsed;

  const optionsByType = useMemo(() => {
    const certificateHeats = certificates.map((certificate) => certificate.heat);
    const certificateLotIds = certificates.filter((certificate) => certificate.properties.lotId).map((certificate) => certificate.properties.lotId + certificate.heat);
    const certificatePoItems = certificates.filter((certificate) => certificate.lineItem).map((certificate) => certificate.lineItem);
    const certificateLocations = certificates.map((certificate) => getLocationAddress(certificate.location));

    const campaignHeats = flatten(campaigns.map((campaign) => campaign.products.map((product) => product.heat)));
    const campaignPoItems = campaigns.reduce((acc, campaign) => {
      if(campaign.purchaseOrderItem) acc.push(campaign.purchaseOrderItem);

      return acc;
    }, []);
    const campaignLocations = campaigns.map((campaign) => getLocationAddress(campaign.manufacturer.mainLocation));

    const heats = uniq(certificateHeats.concat(campaignHeats));
    const lotIds = uniq(certificateLotIds.concat(campaignHeats));
    const poItems = uniq(certificatePoItems.concat(campaignPoItems));
    const locations = uniq(certificateLocations.concat(campaignLocations));

    return  {
      [FILTERS.PO_ITEM_NO]: poItems,
      [FILTERS.HEAT]: heats,
      [FILTERS.HEAT_LOT]: lotIds,
      [FILTERS.LOCATION]: locations,
    };
  }, [certificates.length, campaigns.length]);

  return (
    <Grid container justifyContent="space-between" alignContent="center">
      <Grid item>
        <Grid container spacing={3} alignItems="center">
          <Grid item marginRight={3}>
            {isLoaded ? (
              <h3>
                {interfaceType === INTERFACE_TYPE.SENDER ? "Customer: " : "Manufacturer: "}
                {interfaceType === INTERFACE_TYPE.SENDER ? receiver.name : sender.name}
                {viewer && `${viewer.lastname} - ${viewer.company}`}
              </h3>
            ) : (
              <Skeleton width={200} />
            )}
          </Grid>
          <Grid item style={{paddingLeft: 0, paddingRight: 0}}>
            <Divider
              orientation="vertical"
              className={classes.verticalDivider}
            />
          </Grid>
          <Grid item marginRight={3}>
            <OrderNumber
              interfaceType={interfaceType}
            />
          </Grid>
          <Grid item style={{paddingLeft: 0, paddingRight: 0}}>
            <Divider
              orientation="vertical"
              className={classes.verticalDivider}
            />
          </Grid>
          {isLoaded ? (
            <Grid item>
              Status:
              <div
                className={classNames(classes.status, {
                  [classes.statusOpen]: isDraft,
                  [classes.statusClosed]: !isDraft,
                })}
              >
                {isDraft ? "Open" : "Closed"}
              </div>
            </Grid>
          ) : (
            <Grid item>
              <Skeleton width={100} />
            </Grid>
          )}
          <Grid
            item
            style={{paddingLeft: 0, paddingRight: 0}}
            classes={{item: classes.filterDivider}}
          >
            <div className={classes.verticalDivider} />
          </Grid>
          <Grid item xs={12} lg={isMenuCollapsed ? "auto" : 12} xl="auto">
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <FilterButton
                  optionsByType={optionsByType}
                  displayOrder={FILTERS_LIST}
                />
              </Grid>
              <Grid item>
                <AppliedFiltersList
                  optionsByType={optionsByType}
                  displayOrder={FILTERS_LIST}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.date}>
        {isLoaded ? (
          <h3>
            Creation date:&nbsp;{moment(dateCreated).format(DATE_FORMAT)}
          </h3>
        ) : (
          <Skeleton width={200} />
        )}
      </Grid>
    </Grid>
  );
});

export default withStyles(HeaderBottomLine, styles);