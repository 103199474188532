import React, {useMemo, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {intersection, isEmpty, uniq} from "ramda";
import {Button, FormControlLabel, Grid, Switch, useTheme} from "@mui/material";
import {IoMdDownload} from "react-icons/io";
import CompactView from "../CompactView";
import StandardView from "../StandardView";
import SpecimensList from "../SpecimensList";
import {DETAILED_VIEW_SPECIMENS_LENGTH} from "../../constants";
import {CUSTOM_TEST_TYPES} from "@core/constants/test";
import {CAMPAIGN_SPECIMEN_LABELS} from "@core/constants/campaign";
import {FILTERS} from "@core/constants/filters";
import useStores from "../../../../../../useStores";
import {downloadFile} from "@core/helpers";

const AUT_VIEWER = "AUT Viewer";

const TestResults = ({activities, disabled, updateTest, test}) => {
  const [isCompactView, setIsCompactView] = useState(true);

  const theme = useTheme();
  const {FilterStore, CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;
  
  const isNdtTest = test.displayName === CUSTOM_TEST_TYPES.AUTOMATED_ULTRASONIC_TESTING;

  const TableComponent = isCompactView ? CompactView : isNdtTest ? SpecimensList : StandardView;

  const filterBySpecimens = !test.specimenLabel || test.specimenLabel === CAMPAIGN_SPECIMEN_LABELS.PIPE;
  const specimens = filterBySpecimens ? FilterStore.filters[FILTERS.SPECIMEN_ID] || [] : [];

  const filterByWelds = test.specimenLabel === CAMPAIGN_SPECIMEN_LABELS.WELD;
  const welds = filterByWelds ? FilterStore.filters[FILTERS.WELD_ID] || [] : [];

  const specimensString = specimens.join();
  const weldsString = welds.join();

  const activitiesSpecimens = useMemo(() => uniq(activities.map((activity) => activity.specimen)), [activities]);

  const autViewer = test.displayName === CUSTOM_TEST_TYPES.AUTOMATED_ULTRASONIC_TESTING &&
    campaign.attachments?.find((upload) => upload.label?.toLowerCase() === AUT_VIEWER.toLowerCase());

  useEffect(() => {
    const filters = specimens.concat(welds);

    setIsCompactView(
      specimens.length > DETAILED_VIEW_SPECIMENS_LENGTH ||
        welds.length > DETAILED_VIEW_SPECIMENS_LENGTH ||
      (activitiesSpecimens.length !== DETAILED_VIEW_SPECIMENS_LENGTH &&
      !intersection(activitiesSpecimens, filters).length)
    );
  }, [activitiesSpecimens.length, specimensString, weldsString]);

  return (
    <>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: autViewer ? theme.spacing() : 0,
              marginTop: autViewer ? theme.spacing() : 0
            }}
          >
            <h3>Test Result(s)</h3>
            {autViewer && (
              <Button
                sx={{
                  marginLeft: theme.spacing(),
                }}
                color="primary"
                variant="containedGray"
                size="small"
                onClick={() => downloadFile(autViewer.file)}
              >
                <span>Download {AUT_VIEWER}</span>
                &nbsp;
                <IoMdDownload size={18} />
              </Button>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              control={(
                <Switch
                  color="primary"
                  checked={isCompactView}
                  onChange={() => setIsCompactView(!isCompactView)}
                />
              )}
              label="Compact View"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableComponent
          disabled={disabled || !isEmpty(FilterStore.filters)}
          activities={activities}
          test={test}
          updateTest={updateTest}
        />
      </Grid>
    </>
  );
};

export default observer(TestResults);