import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

let user = {};

const getUserInfo = () => {
  return axios.get(ROUTES.ACCOUNT[ACTIONS.SINGLE], {
    withCredentials: true,
  })
    .then(
      (response) => response.data,
      (rejectResponse) => Promise.reject(rejectResponse?.response?.data?.message)
    );
};

const clearCache = () => {
  user = {};
};

const getUserInfoByid = (id) => {
  const getUser = new Promise((resolve, reject) => {

    axios.get(ROUTES.USER[ACTIONS.SINGLE](id), {
      withCredentials: true
    }).then( (response) => {
      resolve(response.data);
    }).catch((error)=>{
      reject(error);
    });
  });

  return getUser;
};

const getSelectUsersInfo = () => axios.get(ROUTES.ACCOUNT[ACTIONS.ALL])
  .then((res) => res.data);

const signInWithSelectedUser = (userId) => axios.post(ROUTES.AUTH[ACTIONS.LOGIN](userId))
  .then((res) => res.data);

const resetBCAddress = () => axios.post(ROUTES.ACCOUNT[ACTIONS.RESET_BC_ADDRESS])
  .then((res) => res.data);

const login = (username, password) => {

  // eslint-disable-next-line no-shadow
  const login = new Promise((resolve, reject) => {
    axios.post(ROUTES.ACCOUNT[ACTIONS.LOGIN], {username, password},{
      withCredentials: true,
      responseType: "json",
    })
      .then( (response) => {
        resolve(response);
        user = response.data.user;
      })
      .catch((error)=> reject(error.response));
  });

  return login;
};

const signInWith = (identityProvider) => {
  window.location.href = ROUTES.ACCOUNT[ACTIONS.SELECT_PROVIDER](identityProvider);
};

const generateTotp = () => {
  return new Promise((resolve, reject) => {
    try {
      const response = axios.get(ROUTES.ACCOUNT[ACTIONS.GENERATE_TOTP]);
      resolve(response);
    }
    catch (error) {
      reject(error);
    }
  });
};

const validateTotp = async (data) => {
  const response = await axios.post(ROUTES.ACCOUNT[ACTIONS.VALIDATE_TOTP], data, {
    responseType: "json",
  });

  return response;
};

const verifyTotp = async (data) => {
  const response = await axios.post(ROUTES.ACCOUNT[ACTIONS.VERIFY_TOTP], data, {
    responseType: "json",
  });

  return response;
};

const getRecoveryToken = async (data) => {
  await axios.post(ROUTES.ACCOUNT[ACTIONS.GET_RECOVERY_TOKEN], data);
};

const setNewPassword = async (data, id) => {
  const response = await axios.post(ROUTES.ACCOUNT[ACTIONS.RECOVER_PASSWORD](id), data, {
    responseType: "json",
  });

  return response.data;
};

const resetPassword = async (data) => {
  const response = await axios.patch(ROUTES.ACCOUNT[ACTIONS.RESET_PASSWORD], data, {
    responseType: "json",
  });

  return response;
};

const getUserFromCache = () => {
  const getUser = new Promise((resolve, reject) => {
    if(user.username){
      resolve(user);
    }else{
      axios.get(ROUTES.ACCOUNT[ACTIONS.SINGLE], {
        withCredentials: true
      }).then( (response) => {
        user = response.data;
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    }
  });

  return getUser;
};

const getFromGlobal = () => {
  return user;
};

const listAll = (params) => {

  const getUsers = new Promise((resolve, reject) => {

    axios.get(ROUTES.USER[ACTIONS.ALL], {
      params,
      withCredentials: true
    }).then( (response) => {

      resolve(response.data);
    }).catch(() => {
      reject();
    });

  });

  return getUsers;
};

const addNew = (data, companyId) => {
  const saveUser = new Promise((resolve) => {
    axios.post(ROUTES.USER[ACTIONS.CREATE], data,{
      responseType: "json",
      params: companyId ? {companyId} : {}
    }).then( (response) => {
      resolve(response.data);
    }).catch(() => {});
  });

  return saveUser;
};
const logout = () => {
  // eslint-disable-next-line no-shadow
  const logout = new Promise((resolve, reject) => {
    axios.post(ROUTES.ACCOUNT[ACTIONS.LOGOUT], {}, {
      responseType: "json",
    }).then( (response) => {
      resolve(response.data);
    }).catch(() => {
      reject();
    });
  });

  return logout;
};

const deleteUsers = (userIds) => {
  const deleteUser = new Promise((resolve, reject) => {
    axios.delete(ROUTES.USER[ACTIONS.REMOVE], {
      withCredentials: true,
      data: {users: userIds},
      responseType: "json",
    }).then( (response) => {
      resolve(response.data);
    }).catch(() => {
      reject();
    });
  });

  return deleteUser;
};

const inviteUsers = async (users) => {
  const response = await axios.post(ROUTES.USER[ACTIONS.INVITE_USERS], {data: users}, {
    responseType: "json",
  });

  return response.data;
};

const validateInvitationToken = async (id, token) => {
  const response = await axios.post(ROUTES.USER[ACTIONS.VALIDATE_TOKEN], {},{
    params: {id, token},
  });

  return response.data;
};

const createFromInvite =  async (data, id, token) => {
  const response = await axios.patch(ROUTES.USER[ACTIONS.CREATE_FROM_INVITE], data, {
    params: {id, token},
    responseType: "json",
  });

  return response.data;
};

const inviteAgain = async (id) => {
  const response = await axios.post(ROUTES.USER[ACTIONS.RESEND_INVITE], {id}, {
    responseType: "json",
  });

  return response.data;
};

const setUser = (u) => {
  user = u;
};

export default {
  setUser,
  getUserInfo,
  getSelectUsersInfo,
  signInWithSelectedUser,
  signInWith,
  login,
  logout,
  getUserFromCache,
  listAll,
  addNew,
  getUserInfoByid,
  getFromGlobal,
  delete: deleteUsers,
  clearCache,
  generateTotp,
  validateTotp,
  verifyTotp,
  resetPassword,
  getRecoveryToken,
  setNewPassword,
  resetBCAddress,
  inviteUsers,
  createFromInvite,
  validateInvitationToken,
  inviteAgain
};
