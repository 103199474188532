export default (theme) => ({
  tableCell: {
    whiteSpace: "nowrap"
  },
  paper: {
    overflowX: "auto",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      height: 7,
      cursor: "pointer",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c6c5c3",
      borderRadius: 10,
    }
  },
  table: {
    width: "max-content"
  },
  selectableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.backgroundColor.main
    }
  },
});