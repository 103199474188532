import React from "react";
import {Box, Typography} from "@mui/material";
import themeSettings from "../../../../../../theme-settings";

const STATUS_BG_COLOR = {
  success: themeSettings.palette.success.backgroundColor,
  error: themeSettings.palette.error.backgroundColor,
  warning: themeSettings.palette.warning.backgroundColor,
};

const STATUS_TEXT_COLOR = {
  success: themeSettings.palette.success.text,
  error: themeSettings.palette.error.text,
  warning: themeSettings.palette.warning.text,
};

function StatusChipItem({text, status}) {
  return (
    <Box
      sx={{
        borderRadius: 3,
        paddingY: "1px",
        paddingX: "10px",
        display: "inline-block",
        backgroundColor: STATUS_BG_COLOR[status]
      }}
    >
      <Typography display="inline-block" color={STATUS_TEXT_COLOR[status]}>{text}</Typography>
    </Box>
  );
}

export default StatusChipItem;