import {Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {withStyles} from "tss-react/mui";
import useStores from "../../../../../useStores";
import {isZendeskAdded} from "./helpers";
import styles from "./styles";
import {ZE_SNIPPET_ID} from "./constants";
import {zendeskEnabled as zendeskEnabledENV} from "Config";

// eslint-disable-next-line no-undef
const getZendesk = () => zE;
let onWidgetClose;

function ZendeskButton({classes, isZendeskFeatureEnabled}) {

  const {NotificationStore} = useStores();
  const [showButton, setShowButton] = useState(true);
  const zendeskEnabled = zendeskEnabledENV && isZendeskFeatureEnabled;

  const openWidget = () => {
    getZendesk()("webWidget", "show");
    getZendesk()("webWidget", "open");
    setShowButton(false);
  };

  useEffect(() => {
    // We need this one because there is no way to unsubscribe the zendesk event listener
    onWidgetClose = () => {
      getZendesk()("webWidget", "hide");
      setShowButton(true);
    };
  }, []);

  useEffect(() => {
    if (zendeskEnabled && !isZendeskAdded()) {
      const script = document.createElement("script");
      script.src = "https://static.zdassets.com/ekr/snippet.js?key=d2a50dae-8b97-4db5-a2c0-8e6b4df56ab6";
      script.id = ZE_SNIPPET_ID;
      window.zESettings = {webWidget: {zIndex: 1300}};

      script.onload = () => {
        const notLoadedZendesk = getZendesk();
        const halfMinuteInMs = 30000;
        const intervalRepeat = 150;
        let intervalTimePassed = 0;
        const interval = setInterval(() => {
          if (notLoadedZendesk !== getZendesk()) { // zE function changes after some time and only after that, ze("messenger:set") method is available
            try {
              getZendesk()("webWidget", "hide");
              getZendesk()("webWidget:on", "close", () => onWidgetClose());
              clearInterval(interval);
            } catch (e) {
              NotificationStore.showError("Support chat unavailable");
            }
          }

          intervalTimePassed = intervalTimePassed + intervalRepeat;

          if (intervalTimePassed >= halfMinuteInMs) {
            clearInterval(interval);
          }
        }, intervalRepeat);
      };

      document.body.appendChild(script);
    }

  }, [zendeskEnabled]);

  if (!(zendeskEnabled && showButton)) {
    return null;
  }

  return (
    <Button classes={{root: classes.container}} variant="contained" onClick={openWidget}>Support</Button>
  );
}

export default withStyles(ZendeskButton, styles);