import React from "react";
import {Grid, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import File from "@core/components/File";

const Macrographic = ({classes, test}) => {
  return (
    <Grid container spacing={1}>
      <Grid item container spacing={3}>
        {test.properties.client && (
          <Grid item xs={3} className={classes.section}>
            <p>Client</p>
            <h6>{test.properties.client}</h6>
          </Grid>
        )}
        {test.properties.lab && (
          <Grid item xs={3} className={classes.section}>
            <p>Lab</p>
            <h6>{test.properties.lab}</h6>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={3} className={classes.section}>
          <p>Test standard</p>
          <h6>{test.properties.norm}</h6>
        </Grid>
        <Grid item xs={3} className={classes.section}>
          <p>Acceptance criteria</p>
          <h6>{test.properties.acceptanceCriteria}</h6>
        </Grid>
      </Grid>
      {test.properties.elements && (
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell padding="none" width="70">Spec. ID</TableCell>
                <TableCell padding="none">Etching standard</TableCell>
                <TableCell padding="none">Etching solution</TableCell>
                <TableCell padding="none">Magnification</TableCell>
                <TableCell padding="none" width="70">File(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {test.properties.elements.map((element) => (
                <TableRow key={element.specimenId}>
                  <TableCell padding="none" width="70">{element.specimenId}</TableCell>
                  <TableCell padding="none">{element.etchingStandard}</TableCell>
                  <TableCell padding="none" className={classes.etchingSolution}>
                    {element.etchingSolution}{element.electrolytic && " (Electrolytic)"}
                  </TableCell>
                  <TableCell padding="none">{element.magnification}</TableCell>
                  <TableCell padding="none" width="70">
                    {element.files && (
                      <Grid container xs={12} spacing={5}>
                        {element.files.map((image) =>
                          <Grid item xs={4} container alignItems="center" key={image}>
                            <File file={image} />
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
      {test.properties.notes && (
        <Grid item xs={12} className={classes.section}>
          <h6
            dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(Macrographic, styles);
