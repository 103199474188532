import React, {useCallback} from "react";
import {Tooltip, IconButton, useTheme} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {openFullScreen, closeFullScreen} from "@core/helpers";
import styles from "./styles";
import {STAGE_CONFIG} from "../../../../constants";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

const ScaleHandlers = ({classes, setDefaultStageStatus, container, zoomBy, isFullScreen, scale}) => {
  const theme = useTheme();

  const toggleFullscreen = useCallback(() => {
    if(isFullScreen) closeFullScreen();
    else openFullScreen(container.current);
  }, [container.current, isFullScreen]);

  return (
    <div
      className={classes.container}
    >
      <div className={classes.buttonsContainer}>
        <Tooltip
          PopperProps={{
            container: container.current,
          }}
          title="Reset zoom to default"
        >
          <IconButton
            size="small"
            color="primary"
            onClick={setDefaultStageStatus}
          >
            <IconistsIcon icon={ICONISTS_ICONS.focusRenew} color={theme.palette.primary.main} />
          </IconButton>
        </Tooltip>
        <Tooltip
          PopperProps={{
            container: container.current,
          }}
          title="Zoom in"
        >
          <IconButton
            disabled={scale === STAGE_CONFIG.MAX_SCALE}
            size="small"
            color="primary"
            onClick={() => zoomBy(-1, STAGE_CONFIG.SCALE_BY)}
          >
            <IconistsIcon icon={ICONISTS_ICONS.magnifyingGlassPlus} color={theme.palette.primary.main} />
          </IconButton>
        </Tooltip>
        <Tooltip
          PopperProps={{
            container: container.current,
          }}
          title="Zoom out"
        >
          <IconButton
            disabled={scale === STAGE_CONFIG.MIN_SCALE}
            size="small"
            color="primary"
            onClick={() => zoomBy(+1, STAGE_CONFIG.SCALE_BY)}
          >
            <IconistsIcon icon={ICONISTS_ICONS.magnifyingGlassMinus} color={theme.palette.primary.main} />
          </IconButton>
        </Tooltip>
        <Tooltip
          PopperProps={{
            container: container.current,
          }}
          title={isFullScreen ? "Exit full screen" : "Enter full screen"}
        >
          <IconButton
            size="small"
            color="primary"
            onClick={toggleFullscreen}
          >
            <IconistsIcon icon={isFullScreen ? ICONISTS_ICONS.focusZoomIn : ICONISTS_ICONS.focusZoomOut} color={theme.palette.primary.main} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default withStyles(ScaleHandlers, styles);