export default (theme) => ({
  container: {
    zIndex: 1000,
    overflow: "hidden",
  },
  content: {
    padding: "20px 15px 10px 20px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  graphContainer: {
    marginLeft: theme.spacing(2),
    borderLeft: "10px solid",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  }
});