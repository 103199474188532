import React from "react";
import {observer} from "mobx-react";
import modules from "@core/constants/modules";
import LabTestList from "./components/LabTestList";
import ProducerTestList from "./components/ProducerTestList";
import useStores from "../../useStores";

const List = () => {
  const {UserStore} = useStores();

  const [module] = UserStore.user.data.company.modules;

  const isLab = module.name === modules.LAB_INTERNAL;
  const isProducer = [modules.PRODUCER, modules.END_OWNER].includes(module.name);

  if (isLab) {
    return (
      <LabTestList />
    );
  }

  if (isProducer) {
    return (
      <ProducerTestList />
    );
  }

  return null;
};

export default observer(List);
