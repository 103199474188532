import {action, makeObservable, observable} from "mobx";
import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class ExternalUsersStore {
  constructor() {
    makeObservable(this, {
      externalUsers: observable,
      externalUser: observable,
      getExternalUsers: action,
      createExternalUser: action,
      removeExternalUser: action,
      getExternalUser: action,
      updateExternalUser: action,
      changePassword: action,
    });
  }
  externalUsers = {
    data: [],
    isLoaded: false,
  };

  externalUser = {};

  async getExternalUsers(label) {
    const response = await axios.get(ROUTES.EXTERNAL_USER[ACTIONS.ALL], {params: {label}});

    this.externalUsers = {
      data: response.data.items,
      isLoaded: true,
    };
  }

  async createExternalUser(data) {
    const response = await axios.post(ROUTES.EXTERNAL_USER[ACTIONS.CREATE], data);

    this.externalUsers.data = [
      ...this.externalUsers.data,
      response.data,
    ];
  }

  async removeExternalUser(id) {
    await axios.delete(ROUTES.EXTERNAL_USER[ACTIONS.REMOVE](id));
    this.externalUsers.data = this.externalUsers.data.filter((u) => u._id !== id);
  }

  async getExternalUser(id) {
    const response = await axios.get(ROUTES.EXTERNAL_USER[ACTIONS.SINGLE](id));

    this.externalUser = response.data;
  }

  async updateExternalUser(id, data) {
    const response = await axios.patch(ROUTES.EXTERNAL_USER[ACTIONS.UPDATE](id), data);

    this.externalUser = response.data;
    this.externalUsers.data = this.externalUsers.data.map((u) => u._id === id ? response.data : u);
  }

  async changePassword(id, password) {
    await axios.patch(ROUTES.EXTERNAL_USER[ACTIONS.CHANGE_PASSWORD](id), {password});
  }
}

export default new ExternalUsersStore();
