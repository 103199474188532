export default () => ({
  emptyForm: {
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #e0e0e0",
    flexGrow: 1
  }
});

