import React, {useMemo} from "react";
import AppWrapperNewDesign from "@core/components/AppWrapper/components/AppWrapperNewDesign";
import AppWrapperOldDesign from "@core/components/AppWrapper/components/AppWrapperOldDesign";
import isExperimentalDesignEnabled from "@core/helpers/isExperimentalDesignEnabled";
import isSafeEnvironment from "@core/helpers/isSafeEnvironment";

const AppWrapper = (props) => {
  const experimentalDesignEnabled = useMemo(() => isExperimentalDesignEnabled(), []);
  const isEnvSafe = useMemo(() => isSafeEnvironment(), []);

  if (isEnvSafe && experimentalDesignEnabled) {
    return <AppWrapperNewDesign {...props} />;
  } else {
    return <AppWrapperOldDesign {...props} />;
  }
};

export default AppWrapper;