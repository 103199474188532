import {indexBy, prop, keys, times, groupBy} from "ramda";
import {TYPES, TEST_TEMPERATURE_UNITS, ORIENTATIONS} from "@core/constants/test";

export class Qcp {
  qcpName = "";
  qcpItem = "";
  testsByTestType = {};

  constructor(qcp) {
    this.qcpName = qcp.name;
    this.qcpItem = qcp.qcpItem;
    this.testsByTestType = this.buildTests(qcp.tests);
  }

  buildTests(tests) {
    return indexBy(prop("type"), tests) || {};
  }
  
  getTest(testType) {
    return this.testsByTestType[testType] || {};
  }
  
  getTestProperties(testType) {
    switch (testType) {
    case TYPES.CHEMICAL:
      return this.getChemicalCompositionProperties();
    case TYPES.HARDNESS:
      return this.getHardnessTestProperties();
    case TYPES.IMPACT:
      return this.getImpactTestProperties();
    case TYPES.TENSILE:
      return this.getTensileTestProperties();
    default:
      return {};
    }
  }
  
  getChemicalCompositionProperties() {
    const {acceptanceCriteria, properties} = this.getTest(TYPES.CHEMICAL);

    return {
      ...properties,
      acceptance: this.qcpName,
      acceptanceItem: this.qcpItem,
      elements: acceptanceCriteria.elements,
    };
  }

  getHardnessTestProperties() {
    const {acceptanceCriteria, properties} = this.getTest(TYPES.HARDNESS);

    const [testType] = keys(acceptanceCriteria.TestType);
    const {Dmin: min, Dmax: max, AvgMax: avgMin, AvgMin: avgMax} = acceptanceCriteria.TestType[testType];

    const elements = acceptanceCriteria.TestZones.reduce((elements, testZone) => {
      const zoneElements = times((index) => {
        const elementIndex = elements.length + index;

        return {
          id: elementIndex,
          position: elementIndex + 1,
          metal: testZone.name,
          value: "",
          checked: false,
          active: false,
          max,
          min: min || 0
        };
      }, testZone.values);
      
      elements.push(...zoneElements);
      
      return elements;
    }, []);

    const elementsByMetal = groupBy(prop("metal"), elements);

    const elementsHistory = acceptanceCriteria.TestZones.map((testZone) => ({
      metal: testZone.name,
      position: elementsByMetal[testZone.name].map((element) => element.position).join(", "),
      differentMax: "",
      differentMin: ""
    }));

    return {
      ...properties,
      acceptance: this.qcpName,
      acceptanceItem: this.qcpItem,
      hardnessTest: testType,
      max,
      min,
      avgMin,
      avgMax,
      elements,
      elementsHistory,
      amount: acceptanceCriteria.Amount,
    };
  }

  getImpactTestProperties() {
    const {acceptanceCriteria, properties} = this.getTest(TYPES.IMPACT);

    const {data: requirements, value} = acceptanceCriteria.temperature;

    const element = {
      laboratory: "",
      orientation: "",
      temperature: value || "",
      position: "",
      energyJoule1: "",
      energyJoule2: "",
      energyJoule3: "",
      shearArea1: "",
      shearArea2: "",
      shearArea3: "",
      lateralExpansion1: "",
      lateralExpansion2: "",
      lateralExpansion3: "",
      location: ""
    };

    return {
      acceptance: this.qcpName,
      acceptanceItem: this.qcpItem,
      ...properties,
      unit: TEST_TEMPERATURE_UNITS.CELSIUS,
      width: 10,
      requirements: ORIENTATIONS.reduce((acc, orientation) => {
        acc[orientation] = requirements;

        return acc;
      }, {}),
      elements: [element],
    };
  }

  getTensileTestProperties() {
    const {acceptanceCriteria, properties} = this.getTest(TYPES.TENSILE);

    const {testStandard, specimen, ...elementProperties} = properties;

    const elements = acceptanceCriteria.elements.map((element) => ({
      specimenId: "",
      dimensionX: "",
      dimensionY: "",
      temperature: "",
      ...elementProperties,
      ...element,
      rp: "",
      rm: "",
      a: "",
      reductionOfArea: "",
      uniformElongation: "",
    }));

    return {
      acceptance: this.qcpName,
      acceptanceItem: this.qcpItem,
      testStandard: testStandard || "",
      specimen: specimen || "",
      elements,
    };
  }
}