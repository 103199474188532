export default () => ({
  container: {
    padding: "10px 20px 0 20px",
    backgroundColor: "#fff",
    transition: "left 0.3s",
    width: "100",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 10,
    "& h3": {
      fontSize: 16,
    }
  },
  tabs: {
    marginTop: 10,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    left: 260,
  },
  fullWidthHeader: {
    left: 60,
  },
  divider: {
    height: 20,
  },
  filterContainer: {
    display: "flex",
    alignItems: "center"
  }
});
