import CollapsibleTable from "../../../../CollapsibleTable";
import React from "react";

const TestConditions = ({editable}) => {
  return (
    <CollapsibleTable
      editable={editable}
      title="Test Conditions"
      requiredFields={[{
        name: "impingementAngle",
        label: "Impingement Angle",
        type: "number",
        endAdornment: "°",
        inputProps: {min: 0},
      }, {
        name: "testDuration",
        label: "Test Duration",
        type: "number",
        endAdornment: "s",
        inputProps: {min: 0},
      }]}
      optionalFields={[{
        name: "averageParticleVelocity",
        label: "Particle velocity (average)",
        type: "number",
        endAdornment: "m/s",
        inputProps: {min: 0},
      }, {
        name: "methodOfVelocityDetermination",
        label: "Method Of Velocity Determination",
      }, {
        name: "particleFlow",
        label: "Particle Flow",
      }, {
        name: "particleFlux",
        label: "Particle Flux",
      }, {
        name: "temperatureOfSpecimen",
        label: "Temperature Of Specimen",
        type: "number",
        endAdornment: "°C",
        inputProps: {min: 0},
      }, {
        name: "temperatureOfParticles",
        label: "Temperature Of Particles",
        type: "number",
        endAdornment: "°C",
        inputProps: {min: 0},
      }, {
        name: "temperatureOfCarrierGas",
        label: "Temperature Of Carrier Gas",
        type: "number",
        endAdornment: "°C",
        inputProps: {min: 0},
      }, {
        name: "methodOfDeterminingSteadyStateErosionCondition",
        label: "Method Of Determining Steady-state Erosion Condition",
      }, {
        name: "carrierGasComposition",
        label: "Carrier Gas Composition",
      }, {
        name: "pressure",
        label: "Pressure",
        type: "number",
        endAdornment: "bar",
        inputProps: {min: 0},
      }, {
        name: "measurementMethod",
        label: "Measurement Method",
      }, {
        name: "methodOfDeterminingTheMassOfAbrasiveUsed",
        label: "Method Of Determining The Mass Of Abrasive Used",
      }]}
    />
  );
};

export default TestConditions;