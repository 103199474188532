import {Field, Form, Formik} from "formik";
import CompanyForm from "@core/components/CompanyForm";
import UserForm from "@core/components/NewUser";
import {Button} from "@mui/material";
import React, {useContext, useEffect} from "react";
import styles from "./styles";
import {withStyles} from "tss-react/mui";
import * as yup from "yup";
import {validationSchema as companySchema} from "@core/validationSchemas/company";
import {validationSchema as userSchema} from "@core/validationSchemas/user";
import {omit, pick} from "ramda";
import useStores from "../../useStores";
import {useHistory} from "react-router";
import {observer} from "mobx-react";
import useDetachedExecutionAfterSubmit from "@core/hooks/useDetachedExecutionAfterSubmit";
import {ConfirmationOnUrlChangeContext} from "@core/components/ConfirmationOnUrlChange/context";
import {SHOULD_BLOCK_DEFAULT} from "@core/constants/shouldBlockDefault";

const validationSchema = yup.object().shape({
  company: companySchema,
  user: userSchema,
});

function CreateNewNew({classes}) {
  const {AccountStore, CompanyStore} = useStores();
  const history = useHistory();
  
  const {setShouldBlock} = useContext(ConfirmationOnUrlChangeContext);
  const {navigateAway} = useDetachedExecutionAfterSubmit();
  
  useEffect(() => {
    CompanyStore.getModules();
  }, []);
  
  const handleSubmit = async (data, actions) => {
    actions.setSubmitting(true);
    const {company, user} = data;
    
    const locationFields = ["country", "city", "house", "postalCode", "street", "plantDescription"];
    await AccountStore.register({
      user: user,
      company: {
        ...omit(locationFields, company),
        mainLocation: pick(locationFields, company),
      },
    });
    
    setShouldBlock(() => SHOULD_BLOCK_DEFAULT);
    navigateAway(() => {
      actions.setSubmitting(false);
      history.push(`/companies/${AccountStore.account.data._id}`);
    });
  };
  
  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={{company: {}, user: {}}}
        onSubmit={handleSubmit}
      >
        {({isValid, handleSubmit, ...rest}) => {
          return (
            <Form>
              <Field name="company">
                {({field}) => (
                  <CompanyForm
                    header="Create New Company"
                    isNew
                    modulesArr={CompanyStore.modules.data}
                    allowSubmit={false}
                    formik={{...rest, name: field.name}}
                  />)
                }
              </Field>
              <Field name="user">
                {({field}) => (
                  <UserForm
                    header="Admin"
                    user={{role: "ADMIN"}}
                    formik={{...rest, name: field.name}}
                    allowRoleSelection={false}
                    allowSubmit={false}
                  />)
                }
              </Field>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                color="primary"
                disabled={!isValid}
                onClick={handleSubmit}
              >
                Create
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default withStyles(observer(CreateNewNew), styles);