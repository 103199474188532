import React, {useMemo, useEffect} from "react";
import {FIELDS_LABELS_BY_FIELD_NAME} from "./constants";
import PorousCalculationForm from "../PorousCalculationForm";
import {getDensity} from "../../../../helpers";
import {useFormikContext} from "formik";

const ImpregnatedDensityForm = () => {
  const {values, setFieldValue} = useFormikContext();

  const c = useMemo(() => {
    if(values.massOfOilImpregnatedPart && values.massOfOilImpregnatedSupportPart && values.massOfOilImpregnatedSupportInWater) {
      return Number(values.massOfOilImpregnatedPart) - (Number(values.massOfOilImpregnatedSupportPart) - Number(values.massOfOilImpregnatedSupportInWater));
    }

    return Number(values.massOfOilImpregnatedPart) - Number(values.massOfOilImpregnatedPartInWater);
  }, [values.massOfOilImpregnatedPart, values.massOfOilImpregnatedSupportPart, values.massOfOilImpregnatedSupportInWater, values.massOfOilImpregnatedPart, values.massOfOilImpregnatedPartInWater, values.massOfOilImpregnatedSupportPart, values.massOfOilImpregnatedSupportInWater]);

  const density = useMemo(() => {
    return getDensity(values.massOfOilImpregnatedPart, values.densityOfWater, c);
  }, [values.massOfOilImpregnatedPart, values.densityOfWater, c]);

  useEffect(() => {
    setFieldValue("density", density);
  }, [density]);

  return (
    <PorousCalculationForm
      fieldsLabelsByFieldName={FIELDS_LABELS_BY_FIELD_NAME}
    />
  );
};

export default ImpregnatedDensityForm;