import React, {useState} from "react";
import classNames from "classnames";
import {IconButton, TableCell, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import {IoMdCloseCircleOutline} from "react-icons/io";
import styles from "./styles";

const SpecimenCell = ({classes, children, required, additionalAnalysis, setAdditionalAnalysis}) => {
  const [visible, setVisible] = useState(false);

  return (
    <TableCell
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {(!additionalAnalysis || !required) && (
        <div className={classes.specimenToolbar}>
          <Tooltip title={additionalAnalysis ? "Remove specimen" : "Add specimen"}>
            <IconButton
              className={classNames({
                [classes.visible]: true,
                [classes.hidden]: !visible,
              })}
              onClick={() => setAdditionalAnalysis(!additionalAnalysis)}
            >
              {additionalAnalysis ? <IoMdCloseCircleOutline /> : <AddIcon />}
            </IconButton>
          </Tooltip>
        </div>
      )}
      {children}
    </TableCell>
  );
};

export default withStyles(SpecimenCell, styles);