export default (theme) => ({
  input: {
    marginTop: 30,
  },
  submit: {
    marginTop: 30,
    background: theme.palette.primary.main,
  },
  description: {
    fontSize: 15,
    padding: "0 10px",
  },
  loader: {
    color: "#ffffff",
  }
});