import React, {Component} from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogContent,
  Dialog,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import File from "@core/components/File";
import OtherTestForm from "@core/components/TestForms/OtherTest";
import {inject, observer} from "mobx-react";
import styles from "./styles";
import {compose} from "ramda";

class OtherTest extends Component {
  render() {
    const {test, classes, addDataOpen, dataDialogClose, updateTest, formRef} = this.props;

    return (
      <>
        <Grid container alignContent="space-between" spacing={4}>
          <Grid item xs={12}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell size='small'>Test</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Result</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {test.properties.elements.map((element, index) => (
                  <TableRow key={`prop${index}`}>
                    <TableCell>{element.test}</TableCell>
                    <TableCell>{element.description}</TableCell>
                    <TableCell>
                      <span
                        className={element.result === "Unsatisfactory" ? classes.redValidate : classes.greenValidate}>{element.result}</span>
                    </TableCell>
                    <TableCell>{element.notes || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          {test.properties.file && (
            <Grid item xs={12}>
              <File file={test.properties.file} />
            </Grid>
          )}

          {test.properties.files && (
            test.properties.files.map((file) => (
              <Grid item>
                <File file={file} />
              </Grid>
            ))
          )}
        </Grid>
        <Dialog
          open={addDataOpen}
          onClose={dataDialogClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <OtherTestForm
              formRef={formRef}
              test={test}
              isProducerTest={true}
              saveTest={updateTest}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default compose(
  inject("TestStore", "SigningStore", "CertificateStore"),
)(withStyles(observer(OtherTest), styles));
