import React from "react";
import {ClickAwayListener} from "@mui/material";
import {SelectContext} from "../../context";

const ListItemsWrapper = React.forwardRef(({children}, ref) => {
  const {setOpen} = React.useContext(SelectContext);

  return (
    <div ref={ref}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>{children}</div>
      </ClickAwayListener>
    </div>
  );
});

export default ListItemsWrapper;