import React, {memo} from "react";
import Pipe from "../AnimatedPipe";
import {BLACK_BANDED_ANODE_PIPE_CONFIG} from "../../constants";

const BlackBandedAnodePipe = ({...props}) => {
  return (
    <Pipe
      {...props}
      fill={BLACK_BANDED_ANODE_PIPE_CONFIG.BACKGROUND_COLOR}
    />
  );
};

export default memo(BlackBandedAnodePipe);