import React from "react";
import {append} from "ramda";
import {FieldArray} from "formik";
import {Button, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Location from "./Location";
import {MdAdd} from "react-icons/md/";
import styles from "../styles";

const Locations = ({classes, disabled, onSubmit}) => (
  <>
    <h4 className={classes.label}>Other locations</h4>
    <FieldArray
      name="locations"
    >
      {({remove, push, replace, form}) => {
        const {initialValues, setFieldValue, values, setFieldTouched, touched, errors, handleChange} = form;

        const onSave = async (index, location) => {
          if(location.editable && initialValues.name) {
            const locations = initialValues.locations[index] ?
              initialValues.locations.map((l, idx) => idx === index ? location : l) :
              append(location,  initialValues.locations);
            await onSubmit({...initialValues, locations});
          }

          replace(index, {...location, editable: !location.editable});
        };

        return (
          <Grid container spacing={2} className={classes.mb25}>
            <Grid item xs={12}>
              <Table className="styled-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Street</TableCell>
                    <TableCell>House Number</TableCell>
                    <TableCell>Postal Code</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Plant description</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.locations.map((location,index) => (
                    <Location
                      index={index}
                      disabled={disabled}
                      values={{
                        country: {value: location.country, path: `locations.${index}.country`},
                        plantDescription: {value: location.plantDescription, path: `locations.${index}.plantDescription`},
                        city: {value: location.city, path: `locations.${index}.city`},
                        street: {value: location.street, path: `locations.${index}.street`},
                        house: {value: location.house, path: `locations.${index}.house`},
                        postalCode: {value: location.postalCode, path: `locations.${index}.postalCode`},
                        phone: {value: location.phone, path: `locations.${index}.phone`}
                      }}
                      actions={{
                        change: (fieldName, e) => {
                          handleChange(e);
                          setFieldTouched(fieldName, true, false);
                        },
                        blur: (path, field) => setFieldValue(path, values.locations[index][field].trim()),
                        save: () => onSave(index, location),
                        remove: () => remove(index)
                      }}
                      editable={location.editable}
                      touched={touched.locations || {}}
                      errors={errors.locations || {}}
                    />
                  ))}
                  <TableRow>
                    <TableCell colSpan={8}>
                      <div className={classes.loaderContainer}>
                        <Button
                          onClick={() => {
                            push({
                              editable: true,
                              city: "",
                              street: "",
                              house: "",
                              postalCode: "",
                              phone: "",
                              country: "",
                              plantDescription: ""
                            });
                          }}
                          disabled={disabled}
                        >
                          <MdAdd size={80} /> Add new
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  </>
);

export default withStyles(Locations, styles);