const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=(.*\d){2})(?=.*[/\\^:@$!%*?&)(+#.])[A-Za-z\d/\\^:@$!%*?&)(+#.]{8,}$/;
const PASSWORD_ENCODED = "Must contain at least 8 characters, 1 uppercase and 1 lowercase letter, 2 numbers and 1 special character";
const NOT_ALLOWED_CHARACTERS_REGEX = /[{}\\"=[\]|£=°§\-_,;<>'~`/]/;
const ALLOWED_CHARACTERS_TEXT = "Allowed special characters are ^:@$!%*?&)(+#.";

export function validatePassword (value) {
  if(NOT_ALLOWED_CHARACTERS_REGEX.test(value)) return this.createError({message: ALLOWED_CHARACTERS_TEXT, path: this.path});

  return !PASSWORD_REGEX.test(value) ? this.createError({message: PASSWORD_ENCODED, path: this.path}) : true;
}
