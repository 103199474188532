import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Grid, circularProgressClasses} from "@mui/material";
import {Input} from "@core/components/Form";
import {debounce} from "@core/helpers";
import {getFilteredDataFromApiAsync, getValuesFromApiAsync} from "./helpers";
import EditableTable from "./components/EditableTable";
import DisplayTable from "./components/DisplayTable";
import Loader from "@core/components/Loader";
import {TbFileUpload} from "react-icons/tb";

const TraceabilityPage = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [filterInProgress, setFilterInProgress] = useState(false);
  const [editState, setEditState] = useState(false);
  const [data, setData] = useState([]);
  const [handleFormikSubmit, setHandleFormikSubmit] = useState(() => () => {});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onInputChange = useCallback(async (event) => {
    setFilterInProgress(true);
    const newData = await getFilteredDataFromApiAsync(event.target.value);
    setData(newData);
    setFilterInProgress(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const initialData = await getValuesFromApiAsync();
      setData(initialData);
      setInitialLoad(false);
    };
    fetchData();
  }, []);

  const onSubmit = async (values, actions) => {
    const newData = await getValuesFromApiAsync(values?.traceabilityTable);
    setData(newData);
    setEditState(false);
    actions.setSubmitting(false);
  };

  return (
    <>
      <Box sx={{maxWidth: "1024px"}}>
        <Box sx={{
          [`.${circularProgressClasses.root}`]: {
            margin: (theme) => `0 ${theme.spacing(1)} 0 0`
          }
        }}>
          <Input
            disabled={editState || filterInProgress}
            endAdornment={filterInProgress && <Loader size={15} />}
            label="Search for material(s)"
            onChange={debounce(onInputChange, 700)}
          />
        </Box>

        <Box marginTop={2}>
          <Grid container marginBottom={2} gap={3}>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                disabled={editState || initialLoad}
                onClick={() => setEditState(true)}
              >
                Edit data
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                size="small"
                disabled={editState || initialLoad}
              >
                <TbFileUpload size={19} />&nbsp;
                Import Data
              </Button>
            </Grid>

            {
              editState ?
                <>
                  <Grid item marginLeft="auto">
                    <Button variant="outlined" size="small" disabled={isSubmitting} onClick={() => setEditState(false)}>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" size="small" disabled={isSubmitting} onClick={handleFormikSubmit}>Submit</Button>
                  </Grid>
                </> :
                <></>
            }
          </Grid>
          {
            initialLoad ?
              <Loader size={40}/> :
              editState ?
                <EditableTable
                  tableData={data}
                  onSubmit={onSubmit}
                  setHandleFormikSubmit={setHandleFormikSubmit}
                  setIsSubmitting={setIsSubmitting}
                /> :
                <DisplayTable values={data}/>
          }
        </Box>
      </Box>
    </>
  );
};

export default TraceabilityPage;