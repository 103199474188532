export default (theme) => ({
  instruments: {
    color: "#616161",
    display: "flex",
    fontWeight: theme.typography.fontWeightMedium,
    "& h4": {
      flexShrink: 0,
      marginRight: 10,
    },
    "& a": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    }
  },
});