import React, {useMemo, useEffect} from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {partition} from "ramda";
import TestSummaryTableRow from "./components/TestSummaryTableRow";
import DrawerHeader from "@core/components/DrawerHeader";
import ResizableDrawer from "@core/components/ResizableDrawer";
import {QCPS} from "@core/constants/qcps";
import {ORDERS} from "@core/constants/sort";
import useStores from "../../../useStores";
import {sort} from "@core/helpers";
import styles from "./styles";

const QcpSideBar = ({classes, qcp, open, close, poItem}) => {
  const {CompanyStore} = useStores();

  useEffect(() => {
    CompanyStore.getCompaniesMetaData();
  }, []);

  const [testsWithActivityNumber, testsWithoutActivityNumber] = useMemo(() => {
    const producerTests = QCPS[qcp]?.producerTests || [];
    const labTests = QCPS[qcp]?.labTests || [];

    const tests = [...producerTests, ...labTests];

    return partition((test) => test.activityNumber, tests);
  }, [qcp]);

  const sortedTests = useMemo(() => {
    return sort(ORDERS.ASC, "activityNumber", testsWithActivityNumber)
      .concat(testsWithoutActivityNumber);
  }, [qcp]);

  return (
    <ResizableDrawer
      open={open}
      close={close}
      paperClasses={{root: classes.container}}
    >
      <DrawerHeader close={close} text={qcp}/>
      <div className={classes.content}>
        <h3>Test Summary</h3>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell padding="none" width="45" />
              {!!testsWithActivityNumber.length && <TableCell>Activity Number</TableCell>}
              <TableCell>Test Type</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Laboratory</TableCell>
              <TableCell>Witnesses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedTests.map((test, index) => {
              const isLabTest = !test.properties;

              if(test.poItems && !test.poItems.includes(poItem)) {
                return null;
              }

              return (
                <TestSummaryTableRow
                  key={test.type + index}
                  qcp={qcp}
                  quantity={test.quantity}
                  witnesses={QCPS[qcp].witnesses}
                  labs={isLabTest ? QCPS[qcp].labs : []}
                  test={test}
                  baseQcp={QCPS[qcp].base}
                  activityNumber={test.activityNumber}
                  withActivityNumber={!!testsWithActivityNumber.length}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
    </ResizableDrawer>
  );
};

export default withStyles(QcpSideBar, styles);