export default (theme) => ({
  root: {
    display: "flex"
  },
  form: {
    marginTop: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    padding: "5px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  group: {
    margin: `${theme.spacing(1)} 0`

  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  selectField: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  input: {
    display: "none"
  },
  frmRow: {
    marginBottom: "15px"
  },
  avatarUpload: {
    textAlign: "center",
    "& .avatar": {
      width: 150,
      height: 150,
      margin: "auto"
    },
    "& label": {
      width: 225,
      display: "block",
      margin: "0 auto",

      "& > span": {
        width: "100%",
        lineHeight: 1.2,
        marginTop: 26
      }
    }
  },
  paddingRight: {
    paddingRight: "30px !important"
  },
  paddingLeft: {
    paddingLeft: "30px !important"
  },
  mt40: {
    marginTop: 40,
  },
  mb25: {
    marginBottom: 25
  },
  mb30: {
    marginBottom: 30
  },
});
