export default (theme) => ({

  bgColor: {
    backgroundColor: "#f5f5f5",
  },

  mainContainer: {
    padding: theme.spacing(4),
    color: "#616161",
  },
  
  chooseTable: {
    border: "1px solid #d8d8d8"
  },
  
  title: {
    marginBottom: 30,
  },

  testView: {
    padding: 20,
    backgroundColor: "#fff",
    borderBottom: "1px solid #e0e0e0",
    position: "relative",
    height: "100%"
  },
  
  testTitle: {
    color: "#575757",
    fontSize: 32,
    fontWeight: theme.typography.fontWeightMedium,
  },

  result: {
    marginTop: theme.spacing(3),
  },

  mt25: {
    marginTop: 25
  },

  ml20: {
    marginLeft: 60
  },

  inspectorNotes: {
    "& h4": {
      color: "#575757",
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 10,
      marginRight: 10,
      marginBottom: 10,
    },
    "& p": {
      color: "#575757",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  values: {
    display: "flex",
    marginBottom: 12,
    "& h4": {
      flexShrink: 0,
      marginRight: 10,
    },
    "& p": {
      color: "#575757",
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  instruments: {
    marginBottom: 12,
  },
  container: {
    position: "relative",
    borderRadius: 4,
    overflow: "hidden",
    display: "flex",
    justifyContent: "space-between"
  },
  content: {
    transition: "margin-right 0.3s ease-in-out",
    borderRight: "1px solid #e0e0e0",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  contentBig: {
    marginRight: "-20vw",
  },
  contentSmall: {
    marginRight: "initial",
  }
});