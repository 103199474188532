import {STATUSES} from "@core/constants/test";
import {ACTION_LABELS} from "@core/components/TimelineBlock/const";

function prodWithWitnessFlowLabels({labExists, witnessesExists, testStatus}) {
  if (!labExists && witnessesExists && testStatus === STATUSES.INSPECTING) {
    return [ACTION_LABELS.witness()];
  }

  if (!labExists && testStatus === STATUSES.APPROVED) {
    return [];
  }

  return null;
}

function prodWithLabNoWitnessLabels({labExists, witnessesExists, testStatus}) {
  if (labExists && !witnessesExists && testStatus === STATUSES.ASSIGNED) {
    return [ACTION_LABELS.submit(), ACTION_LABELS.approve()];
  }

  if (labExists && !witnessesExists && testStatus === STATUSES.SUBMITTED) {
    return [ACTION_LABELS.approve()];
  }

  if (labExists && !witnessesExists && testStatus === STATUSES.APPROVED) {
    return [];
  }

  return null;
}

function getProdWithLabWithWitnessFlowLabels({labExists, witnessesExists, testStatus}) {
  if (labExists && witnessesExists && (testStatus === STATUSES.ASSIGNED || testStatus === STATUSES.FILLED)) {
    return [ACTION_LABELS.submit(), ACTION_LABELS.witness(), ACTION_LABELS.approve()];
  }

  if (labExists && witnessesExists && (testStatus === STATUSES.SUBMITTED || testStatus === STATUSES.INSPECTING)) {
    return [ACTION_LABELS.witness(), ACTION_LABELS.approve()];
  }

  if (labExists && witnessesExists && testStatus === STATUSES.INSPECTED) {
    return [ACTION_LABELS.approve()];
  }

  return null;
}

export function getLabelsToRender ({labExists, witnessesExists, testStatus}) {
  let pendingLabels;

  pendingLabels = prodWithWitnessFlowLabels({labExists, witnessesExists, testStatus});

  if (pendingLabels) {
    return pendingLabels;
  }

  pendingLabels = prodWithLabNoWitnessLabels({labExists, witnessesExists, testStatus});

  if (pendingLabels) {
    return pendingLabels;
  }

  pendingLabels = getProdWithLabWithWitnessFlowLabels({labExists, witnessesExists, testStatus});

  if (pendingLabels) {
    return pendingLabels;
  }

  return [];
}