import {getMean} from "@core/helpers";

export const getRangeValue = (elements, property) => {
  const values = elements.map((element) => Number(element[property]));

  const min = Math.min(...values);
  const max = Math.max(...values);

  return max - min;
};

export const getAverage = (elements, property) => {
  const values = elements.map((element) => Number(element[property]));

  return getMean(values);
};