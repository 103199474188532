import React, {useEffect} from "react";
import TestParameters from "./TestParameters";
import {FieldArray, useFormikContext} from "formik";
import {splitEvery} from "ramda";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {getMean, getDeviation, getCi, getAccuracy} from "@core/helpers";
import TextField from "@core/components/FormikTextField";

const ELEMENTS_IN_COLUMN = 10;

const ManualCount = ({firstUpdate, test}) => {
  const {values, setFieldValue} = useFormikContext();

  const columns = splitEvery(ELEMENTS_IN_COLUMN, values.elements);
  const columnsNumber = Math.max(Math.floor(12 / columns.length), 2);
  const columnWidth = Math.min(columnsNumber, 4);

  const elementsString = values.elements.join("");

  useEffect(() => {
    const mean = getMean(values.elements);
    const deviation = getDeviation(values.elements);

    const meanFerrite = values.pointsPerField ? mean / Number(values.pointsPerField) * 100 || 0 : 0;
    const deviationFerrite = values.pointsPerField ? deviation / Number(values.pointsPerField) * 100 || 0 : 0;
    const ci = getCi(values.elements, deviationFerrite);
    const accuracy = getAccuracy(values.elements, deviationFerrite, meanFerrite);

    setFieldValue("meanFerrite", meanFerrite.toFixed(3));
    setFieldValue("deviation", deviationFerrite.toFixed(3));
    setFieldValue("ci", ci.toFixed(3));
    setFieldValue("accuracy", accuracy.toFixed(3));

  }, [elementsString, values.pointsPerField]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TestParameters
          firstUpdate={firstUpdate}
          test={test}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={9} container>
            {columns.map((bunch, bunchIndex) => (
              <Grid item xs={columnWidth}>
                <Table className="styled-table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none" width="70">Field</TableCell>
                      <TableCell padding="none">Points falling in ferrite phase</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="elements">
                      {() => bunch.map((element, elementIndex) => {
                        const actualIndex = bunchIndex * ELEMENTS_IN_COLUMN + (elementIndex + 1);

                        return (
                          <TableRow key={bunchIndex + elementIndex}>
                            <TableCell padding="none">{actualIndex}</TableCell>
                            <TableCell
                              padding="normal"
                            >
                              <TextField
                                inputProps={{min: 0, max: Number(values.pointsPerField)}}
                                type="number"
                                name={`elements.${actualIndex - 1}`}
                                required
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </FieldArray>
                  </TableBody>
                </Table>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={9}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell padding="none">Mean ferrite [%]</TableCell>
                  <TableCell padding="none">Standard deviation [%]</TableCell>
                  <TableCell padding="none">CI 95%</TableCell>
                  <TableCell padding="none">Relative accuracy [%]</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell padding="none">{values.meanFerrite}</TableCell>
                  <TableCell padding="none">{values.deviation}</TableCell>
                  <TableCell padding="none">{values.ci}</TableCell>
                  <TableCell padding="none">{values.accuracy}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManualCount;