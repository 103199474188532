import axios from "axios";
import {action, makeObservable} from "mobx";
import {ACTIONS} from "@core/constants/api";
import {ROUTES} from "@core/api/routes";

class FeatureStore {
  constructor() {
    makeObservable(this, {
      getFeatures: action,
    });
  }

  async getFeatures() {
    const response = await axios.get(ROUTES.FEATURE[ACTIONS.ALL], {
      withCredentials: true,
    });

    return response.data.items;
  }
}

export default new FeatureStore();
