import React from "react";
import {Grid} from "@mui/material";
import GlobalViewLegend from "../../../GlobalView/components/Legend";
import DetailedViewLegend from "../../../DetailedView/components/Legend";
import CoatingViewLegend from "../../../CoatingView/components/Legend";
import {VIEWS} from "../../../../constants";

const COMPONENT_BY_VIEW = {
  [VIEWS.GLOBAL]: GlobalViewLegend,
  [VIEWS.DETAILED]: DetailedViewLegend,
  [VIEWS.COATING]: CoatingViewLegend,
};
const Legend = ({settings, view, state}) => {
  const Component = COMPONENT_BY_VIEW[view];
  
  if(!settings.legend) return null;

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Component
          state={state}
          settings={settings}
        />
      </Grid>
    </Grid>
  );
};

export default Legend;