export default (theme) => ({
  content: {
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(2),
  },
  tabs: {
    borderBottom: "1px solid #e0e0e0",
    marginLeft: theme.spacing(),
  },
});
