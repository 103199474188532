import Field from "@core/components/CollapsibleTable/components/Field";
import AddRowButton from "@core/components/DynamicTestResultsTable/AddRowButton";
import RemoveButtonCell from "@core/components/DynamicTestResultsTable/RemoveButtonCell";
import {DIRECTIONS} from "@core/components/TestForms/PalmqvistTest/constants";
import {ORIENTATIONS, POSITIONS, ZONES} from "@core/constants/test";
import React from "react";
import {FieldArray, useFormikContext} from "formik";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {TEST_RESULT} from "../../constants";

const TestResults = ({editable}) => {

  const {values} = useFormikContext();

  return (
    <FieldArray name="testResults">
      {({push, remove}) => {
        return (
          <>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Specimen ID</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Orientation</TableCell>
                  <TableCell>Direction</TableCell>
                  <TableCell>
                    {values.palmqvist ? (
                      <span
                        dangerouslySetInnerHTML={{__html: values.palmqvist}}
                      />
                    ) : "Palmqvist"}
                  </TableCell>
                  <TableCell>
                    K<sub>Ic</sub>
                    {values.acceptanceCriteria && (
                      <span>&nbsp;&#8804;&nbsp;{values.acceptanceCriteria}&nbsp;{values.stressIntensityFactorUnit}</span>
                    )}
                  </TableCell>
                  {editable && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.testResults.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.specimen`}
                        errorMessage={null}
                        editable={editable}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        name={`testResults.${index}.location`}
                        errorMessage={null}
                        editable={editable}
                        options={[ZONES.BASE_METAL, ZONES.HAZ, ZONES.WELD_METAL]}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        name={`testResults.${index}.position`}
                        errorMessage={null}
                        editable={editable}
                        options={POSITIONS}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        name={`testResults.${index}.orientation`}
                        errorMessage={null}
                        editable={editable}
                        options={ORIENTATIONS}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.direction`}
                        errorMessage={null}
                        editable={editable}
                        options={DIRECTIONS}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.palmqvist`}
                        errorMessage={null}
                        editable={editable}
                        type="number"
                        inputProps={{min: 0}}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.stressIntensityFactor`}
                        errorMessage={null}
                        editable={editable}
                        type="number"
                        inputProps={{min: 0}}
                        endAdornment={values.stressIntensityFactorUnit}
                      />
                    </TableCell>
                    {editable && (
                      <RemoveButtonCell
                        onClick={() => remove(index)}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {editable && (
              <AddRowButton
                push={push}
                rowCellsValue={TEST_RESULT}
              />
            )}
          </>
        );
      }}
    </FieldArray>
  );
};

export default TestResults;