import React from "react";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const Welds = ({classes, id, children}) => {
  return (
    <>
      <h3
        className={classes.header}
        id={id}
      >
        {children}
      </h3>
    </>
  );
};

export default withStyles(Welds, styles);