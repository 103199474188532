import {useFormikContext} from "formik";
import React from "react";
import {keys} from "ramda";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import {Grid, MenuItem} from "@mui/material";
import {DENSITY_TYPES, TEST_FORM_BY_DENSITY_TYPE} from "./constants";

const PorousTestForm = () => {
  const {values} = useFormikContext();

  return (
    <Grid item container spacing={2}>
      <Grid item xs={3}>
        <SelectField
          name="densityType"
          label="Type of density"
          required
        >
          {keys(DENSITY_TYPES).map((type) => (
            <MenuItem key={type} value={DENSITY_TYPES[type]}>{DENSITY_TYPES[type]}</MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          type="number"
          name="oilViscosity"
          label="Oil viscosity"
          endAdornment="m^2/s"
        />
      </Grid>
      {TEST_FORM_BY_DENSITY_TYPE[values.densityType]}
    </Grid>
  );
};

export default PorousTestForm;