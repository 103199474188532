import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const get = () => {
  const getUser = new Promise((resolve, reject) => {
    axios.get(ROUTES.ACCOUNT[ACTIONS.SINGLE], {
      withCredentials: true,
    })
      .then( (response) => {
        resolve(response.data);
      }).catch((error)=>{
        reject(error.response);
      });
  });

  return getUser;
};

const create = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(ROUTES.REFERENCE[ACTIONS.CREATE], data, {
      withCredentials: true,
      responseType: "json",
    })
      .then( (response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export default {
  get,
  create,
};
