import themeSettings from "../../../theme-settings";
import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
  ...themeSettings,
  components: {
    ...themeSettings.components,
    MuiCssBaseline: {
      ...themeSettings.components.MuiCssBaseline,
      styleOverrides: {
        ...themeSettings.components.MuiCssBaseline.styleOverrides,
        body: {
          ...themeSettings.components.MuiCssBaseline.styleOverrides.body,
          backgroundColor: "#F7F8FB"
        }
      }
    }
  }
});
