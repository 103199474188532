export default () => ({
  pagination: {
    flex: "initial",
    padding: 0,
  },
  footer: {
    "& div": {
      minHeight: "auto",
      padding: 0,
    },
    position: "relative",
    "& td": {
      borderTop: "1px solid #e0e0e0"
    }
  },
  selected: {
    position: "absolute",
    height: "100%",
    display: "flex",
    alignItems: "center",
    left: 380,
    fontSize: "0.85rem"
  }
});