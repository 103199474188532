import React from "react";
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import AdvancedSettings from "../AdvancedSettings";
import {Input} from "@core/components/Form";
import usePlotFilter from "../../hooks/usePlotFilter";

const NUMBER_REGEX = /[^0-9.]/g;
const MAX = 100;

const formatValue = (value) => {
  if(!value) return "";

  if(value > MAX) return MAX;

  return value.replace(NUMBER_REGEX, "");
};

const HistogramSettings = ({isFullScreen, plot}) => {
  const {onHistogramOptions, histogramOptions} = usePlotFilter();

  return (
    <AdvancedSettings
      isFullScreen={isFullScreen}
      plot={plot}
    >
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                defaultChecked
                color="primary"
                checked={histogramOptions.autoBinning}
                onClick={() => onHistogramOptions({
                  autoBinning: !histogramOptions.autoBinning,
                  binCountWanted: "",
                  binWidthWanted: ""
                })}
              />
            )}
            label="Auto binning"
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Bin amount"
            type="number"
            placeholder={histogramOptions.autoBinning && "Most optimal number"}
            inputProps={{min: 1, max: MAX}}
            disabled={histogramOptions.autoBinning}
            value={!histogramOptions.autoBinning && histogramOptions.binCountWanted}
            onChange={(e) => onHistogramOptions({binCountWanted: formatValue(e.target.value)})}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Bin width"
            type="number"
            placeholder={histogramOptions.autoBinning && "Most optimal number"}
            inputProps={{min: 1, max: MAX}}
            disabled={histogramOptions.autoBinning}
            value={!histogramOptions.autoBinning && histogramOptions.binWidthWanted}
            onChange={(e) => onHistogramOptions({binWidthWanted: formatValue(e.target.value)})}
          />
        </Grid>
      </Grid>
    </AdvancedSettings>
  );
};

export default HistogramSettings;