export const STORE_DATA = {
  certificates: "certificates",
  campaigns: "campaigns",
  archives: "archives",
  tests: "tests",
  transfers: "transfers",
  productTypes: "productTypes",
  products: "products",
  projects: "projects",
  metrics: "metrics",
  qcps: "qcps"
};