export default () => ({
  container: {
    padding: "10px 20px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #e0e0e0",
    transition: "left 0.3s",
    width: "100",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 10,
    "& h3": {
      fontSize: 16,
    }
  },
  tabs: {
    minHeight: 48,
    borderBottom: "1px solid #e0e0e0",
    marginBottom: 10,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    left: 260,
  },
  fullWidthHeader: {
    left: 60,
  },
  divider: {
    height: 20,
  }
});
