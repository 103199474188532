import React, {useCallback, useState} from "react";
import {IconButton, Tooltip, OutlinedInput} from "@mui/material";
import {withStyles} from "tss-react/mui";
import CheckIcon from "@mui/icons-material/Check";
import {BiEdit} from "react-icons/bi";
import styles from "./styles";

const ENTER_KEY_CODE = 13;

const EditableCell = ({classes, required, disabled, placeholder, emptyText, onSave, addDataText, type, ...props}) => {
  const [value, setValue] = useState(props.value);
  const [isEditable, setIsEditable] = useState(addDataText ? false : !props.value);

  const onSaveChanges = useCallback(async () => {
    if(!value && required) return;

    if(value !== props.value) await onSave(value);

    setIsEditable(false);
  }, [value]);

  const onKeyPress = useCallback((e) => {
    if(e.keyCode !== ENTER_KEY_CODE) return;

    onSaveChanges();
  }, [value]);

  if (disabled) {
    return props.value || emptyText || "-";
  }

  return (
    <div className={classes.inputContainer}>
      {isEditable ? (
        <>
          <OutlinedInput
            fullWidth
            type={type}
            classes={{input: classes.input, root: classes.inputRoot}}
            margin="none"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={onKeyPress}
            placeholder={placeholder}
          />
          {value && (
            <Tooltip title="Save changes">
              <IconButton
                classes={{root: classes.icon}}
                onClick={onSaveChanges}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <div
          className={classes.editContainer}
          onClick={() => setIsEditable(true)}
        >
          {props.value || addDataText}
          <Tooltip title="Edit">
            <IconButton
              classes={{root: classes.icon}}
            >
              <BiEdit />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
export default withStyles(EditableCell, styles);