import React, {useState, useMemo, useEffect, useRef, useCallback} from "react";
import {observer} from "mobx-react-lite";
import {trim} from "ramda";
import {Button, Menu, MenuItem, OutlinedInput, TableCell, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import {getAllowedTestTypes} from "../../services";
import useStores from "../../../../../useStores";
import classNames from "classnames";
import {MdAdd} from "react-icons/md";

const getFilteredByExactMatch = (options, value) => {
  const key = trim(value.toLowerCase());

  return options
    .filter((item) => {
      const testTitle = item.title.toLowerCase();

      return key && testTitle.includes(key);
    })
    .sort((a, b) => {
      const isGoodMatchA = a.title.toLowerCase().startsWith(key);
      const isGoodMatchB = b.title.toLowerCase().startsWith(key);

      if (isGoodMatchA ^ isGoodMatchB) { // XOR
        return isGoodMatchA ? -1 : 1;
      }

      return a.title.localeCompare(b.title);
    });
};

const AddNewRow = observer(({classes, addTest}) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState("");
  const [match, setMatch] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const inputRef = useRef(null);
  const menuRef = useRef(null);

  const {UserStore} = useStores();
  const [module] = UserStore.user.data.company.modules;
  
  const testTypes = useMemo(() => getAllowedTestTypes(module.name), []);

  const addTestType = (type) => {
    addTest(type);
    setMatch(null);
    setValue("");
    inputRef.current.focus();
  };

  const findOptions = useCallback(() => {
    const options = getFilteredByExactMatch(testTypes, value);

    setOptions(options);
    setOpen(Boolean(options.length));
  }, [value]);

  const findMatch = useCallback(() => {
    if(!value.length) {
      setMatch(null);

      return;
    }

    const match = testTypes.find((test) => {
      const firstPartOfTestName = test.title.slice(0, value.length).toLowerCase();
      const inputValue = trim(value.toLowerCase());

      return firstPartOfTestName === inputValue;
    });

    setMatch(match);
  }, [value]);

  useEffect(() => {
    findMatch();
    findOptions();
  }, [value]);

  const onKeyDown = (e) => {
    if(["ArrowUp", "ArrowDown"].includes(e.key)) {
      menuRef.current.focus();

      return;
    }

    if(!["ArrowRight", "Enter", "Tab"].includes(e.key) || !match) return;

    if(value === match.title) addTestType(match.type);
    else setValue(match.title);
  };

  if(!editable){
    return (
      <TableRow colSpan={4}>
        <TableCell>
          <Button
            onClick={() => setEditable(true)}
          >
            <MdAdd size={80} />Add new
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow hover>
      <TableCell width="30%">
        <div className={classes.inputContainer}>
          <OutlinedInput
            classes={{
              input: classes.input,
              root: classNames(classes.inputRoot, classes.hiddenInput)
            }}
            margin="none"
            value={match ? value + match.title.slice(value.length, match.title.length) : ""}
          />
          <OutlinedInput
            inputRef={inputRef}
            autoFocus
            classes={{
              input: classes.input,
              root: classes.inputRoot
            }}
            margin="none"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={onKeyDown}
            placeholder="Test name"
          />
          <Menu
            innerRef={menuRef}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            transformOrigin={{vertical: "top", horizontal: "left"}}
            anchorEl={inputRef.current}
            onClose={() => setOpen(false)}
            open={open}
            classes={{paper: classes.dropdown}}
            getContentAnchorEl={null}
            disableAutoFocus
            disableAutoFocusItem
            onKeyDown={(e) => {
              if(["ArrowUp", "ArrowDown"].includes(e.key)) return;

              setOpen(false);
              inputRef.current.focus();
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option.type}
                onClick={() => addTestType(option.type)}
              >
                {option.title}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
});

export default withStyles(AddNewRow, styles);
