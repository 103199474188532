export default (theme) => ({
  minusIcon: {
    marginRight: theme.spacing(),
  },
  plusIcon: {
    marginLeft: theme.spacing(),
  },
  container: {
    display: "flex",
    flexWrap: "nowrap"
  }
});
