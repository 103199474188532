import React, {useCallback, useEffect, useState} from "react";
import {Zoom, Fab, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from "./styles";

const ScrollToTop = ({classes}) => {
  const [showButton, setShowButton] = useState(false);

  const listenScroll = useCallback(() => {
    if (window.pageYOffset > 300) setShowButton(true);
    else setShowButton(false);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScroll);

    return () => window.removeEventListener("scroll", listenScroll);
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <Zoom in={showButton} timeout={300}>
      <Tooltip title="Scroll to top" placement="left">
        <div onClick={scrollToTop} role="presentation" className={classes.root} data-html2canvas-ignore="true">
          <Fab color="primary" size="small">
            <KeyboardArrowUpIcon />
          </Fab>
        </div>
      </Tooltip>
    </Zoom>
  );
};

export default withStyles(ScrollToTop, styles);