import {CssBaseline} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {createTheme, ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import {BoxAndWiskers, BoxPlotController, Violin, ViolinChart, ViolinController} from "@sgratzl/chartjs-chart-boxplot";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {
  Chart as ChartJS, BarElement, RadarController, CategoryScale, Legend, LinearScale, LineController, LineElement, PointElement, ScatterController, Title,
  Tooltip, RadialLinearScale
} from "chart.js";
import AnnotationPlugin from "chartjs-plugin-annotation";
import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import Modules from "@core/constants/modules";
import Roles from "@core/constants/roles";

import NotFoundPage from "@core/components/404";
import AppWrapper from "@core/components/AppWrapper";
import ProtectedRoute from "@core/components/ProtectedRoute";

// Account
import Account from "./Account";

// Admin
import ExternalUser from "./Admin/ExternalUser";
import ExternalUsers from "./Admin/ExternalUsers";
import InviteUsers from "./Admin/InviteUsers";
import Logs from "./Admin/Logs";
import NewUser from "./Admin/NewUser";
import Admin from "./Admin/Overview";
import User from "./Admin/User";
import Users from "./Admin/Users";

// Campaigns
import Campaign from "./Campaigns/Campaign";
// import Campaign from "./Campaigns/SingleMaterialCampaign";
import Campaigns from "./Campaigns/Overview";
import NewCampaign from "./Campaigns/CreateNew";
import SingleMaterialCampaign from "./Campaigns/SingleMaterialCampaign";

// Certificates
import Archive from "./Certificates/Archive";
import ArchivedCertificate from "./Certificates/ArchivedCertificate";
import Certificate from "./Certificates/Certificate";
import NewCertificate from "./Certificates/CreateNew";
import Certificates from "./Certificates/Overview";

// Companies
import DashboardCompany from "@core/components/DashboardCompany";
import Company from "./Companies/Company";
import NewCompany from "./Companies/CreateNew";
import Companies from "./Companies/Overview";

// Dashboard
import Dashboard from "./Dashboard";

// DataAnalytics
import DataAnalytics from "./DataAnalytics";

// ErrorPage
import ErrorPage from "./ErrorPage";

// Instruments
import Instruments from "./Instruments";

// Login
import Login from "./Login/LoginPage";
import PasswordRecovery from "./Login/PasswordRecovery";
import ProviderLoginHandler from "./Login/ProviderLoginHandler";
import Registration from "./Login/Registration";
import UserSelect from "./Login/UserSelect";
import MobilePasscodeRecovery from "./Login/MobilePasscodeRecovery";

// Logout
import Logout from "./Logout";

// Preferences
import Conditions from "./Preferences/Conditions";
import ProductTypes from "./Preferences/ProductTypes";
import Statements from "./Preferences/Statements";

// Products
import Products from "./Products";

// Projects
import Projects from "./Projects/Overview";
import Project from "./Projects/Project";

// Specifications
import NewSpecification from "./Specifications/CreateNew";
import Specifications from "./Specifications/Overview";
import Specification from "./Specifications/Specification";

// Qcps
import Qcps from "./Qcps";

// Tests
import ImportFromJSON from "./Tests/ImportFromJSON";
import Tests from "./Tests/Overview";
import Test from "./Tests/Test";

// CustomerOrders
import OutgoingCertificate from "./CustomerOrders/Certificate";
import CustomerOrder from "./CustomerOrders/CustomerOrder";
import CustomerOrders from "./CustomerOrders/Overview";

// OrdersToSupplier
import OrderToSupplier from "./OrdersToSupplier/OrderToSupplier";
import OrdersToSupplier from "./OrdersToSupplier/Overview";

// Reports
import ReportCertificate from "./Reports/Certificate";
import Reports from "./Reports/Overview";
import Report from "./Reports/Report";

// MultipleTestSigning
import MultipleTestSigning from "@core/components/MultipleTestSigning";

// ParseFile
import ParseFile from "@core/components/ParseFile";

// Metrics
import Metrics from "./Metrics";

import WS from "@core/api/socketConnection";
import userService from "@core/api/user-service";
import themeSettings from "./theme-settings";
import {compose} from "ramda";

const queryClient = new QueryClient();
const theme = createTheme(themeSettings);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BoxPlotController,
  BoxAndWiskers,
  Violin,
  ViolinChart,
  ViolinController,
  Title,
  Tooltip,
  Legend,
  RadarController,
  AnnotationPlugin,
  PointElement,
  LineElement,
  BarElement,
  ScatterController,
  LineController,
  RadialLinearScale
);

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      redirectToTest: ""
    };

  }

  redirectToForm = (type) => {
    if (type) {
      this.setState({redirectToTest: <Redirect to={`/tests/${type}`} />});
    }
  };

  content() {
    return (
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              {this.state.redirectToTest}
              <CssBaseline />
              <Switch>

                {/* --- No restriction routes ---*/}
                <Route exact path='/error' component={ErrorPage} />
                <Route exact path='/login' component={Login}/>
                <Route exact path='/login/provider' component={ProviderLoginHandler} />
                <Route exact path='/login/selectUser' component={UserSelect} />
                <ProtectedRoute exact path='/logout'><Logout logout={this.logout.bind(this)} /></ProtectedRoute>
                <Route exact path='/passwordRecovery' component={PasswordRecovery} />
                <Route exact path="/invite" component={Registration} />
                <Route exact path="/mobilePasscodeRecovery" component={MobilePasscodeRecovery}/>

                {/* --- All modules routes ---*/}
                <ProtectedRoute exact path='/' component={Dashboard} />
                <ProtectedRoute exact path='/account' component={Account} />

                {/* --- Producer's and Basic routes ---*/}
                <ProtectedRoute exact path='/transfers' component={CustomerOrders} modules={[Modules.PRODUCER, Modules.BASIC]} />
                <ProtectedRoute exact path='/transfers/:id' component={CustomerOrder} modules={[Modules.PRODUCER, Modules.BASIC]} />

                {/* --- Producer's, Basic and End Owner's routes ---*/}
                <ProtectedRoute exact path='/certificates' component={Certificates} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/archive' component={Archive} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/archive/:id' component={ArchivedCertificate} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/create' component={NewCertificate} modules={[Modules.PRODUCER, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/testParsing' component={ParseFile} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/:id' component={Certificate} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/preferences' component={ProductTypes} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path="/preferences/conditions" component={Conditions} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path="/preferences/statements" component={Statements} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/specifications' component={Specifications} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/specifications/create' component={NewSpecification} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/specifications/:id' component={Specification} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/qcps' component={Qcps} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/transfers/:id/certificate/:certificateId' component={OutgoingCertificate} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/orders' component={OrdersToSupplier} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/orders/:id' component={OrderToSupplier} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/projects/:id' component={Project} modules={[Modules.END_OWNER, Modules.BASIC, Modules.PRODUCER]} />
                <ProtectedRoute exact path='/projects' component={Projects} modules={[Modules.END_OWNER, Modules.BASIC, Modules.PRODUCER]} />

                {/* --- Producer's and Lab's routes ---*/}
                <ProtectedRoute exact path='/tests/multiSigning' component={MultipleTestSigning} modules={[Modules.PRODUCER, Modules.BASIC, Modules.LAB_INTERNAL, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/tests' component={Tests} modules={[Modules.PRODUCER, Modules.BASIC, Modules.LAB_INTERNAL, Modules.END_OWNER]} />

                {/* --- Lab's routes ---*/}
                <ProtectedRoute exact path='/tests/testParsing' component={ParseFile} modules={[Modules.LAB_INTERNAL]} />
                <ProtectedRoute exact path='/tests/importFromJSON' component={ImportFromJSON} modules={[Modules.LAB_INTERNAL]} />
                <ProtectedRoute exact path='/tests/:id' component={Test} modules={[Modules.LAB_INTERNAL]} />
                <ProtectedRoute exact path='/instruments/' component={Instruments} modules={[Modules.LAB_INTERNAL]} />

                {/* --- End owner's routes ---*/}
                <ProtectedRoute exact path='/dataAnalytics' component={DataAnalytics} modules={[Modules.END_OWNER]}/>

                {/* --- Witness's routes ---*/}
                <ProtectedRoute exact path='/witness/multiSigning' component={MultipleTestSigning} modules={[Modules.WITNESS]} />
                <ProtectedRoute exact path='/witness/:id/certificate' component={ReportCertificate} modules={[Modules.WITNESS]} />
                <ProtectedRoute exact path='/witness/:id' component={Report} modules={[Modules.WITNESS]} />
                <ProtectedRoute exact path='/witness' component={Reports} modules={[Modules.WITNESS]} />

                {/* --- Producer's, End owner's and Witness's routes ---*/}
                <ProtectedRoute exact path='/campaigns' component={Campaigns} modules={[Modules.PRODUCER, Modules.END_OWNER, Modules.WITNESS]} />
                <ProtectedRoute exact path="/campaigns/create" component={NewCampaign} modules={[Modules.PRODUCER, Modules.END_OWNER]} />
                <ProtectedRoute exact path="/campaigns/edit/:id" component={NewCampaign} modules={[Modules.PRODUCER, Modules.END_OWNER]} />
                <ProtectedRoute exact path={["/campaigns/:id", "/campaigns/:type/:id"]} component={Campaign} modules={[Modules.PRODUCER, Modules.END_OWNER, Modules.WITNESS]} />
                <ProtectedRoute exact path="/single-material-campaigns/:id" component={SingleMaterialCampaign} modules={[Modules.PRODUCER, Modules.END_OWNER, Modules.WITNESS]} />

                {/* --- Producer's and End owner's routes ---*/}
                <ProtectedRoute exact path='/products' component={Products} modules={[Modules.PRODUCER, Modules.END_OWNER]} />

                {/* --- Company's admin routes ---*/}
                <ProtectedRoute exact path='/admin' component={Admin} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/users' component={Users} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]}/>
                <ProtectedRoute exact path='/admin/users/new' component={NewUser} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/users/invite' component={InviteUsers} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/users/:id' component={User} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/external-users' component={ExternalUsers} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/external-users/:id' component={ExternalUser} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />

                {/* --- Super admin routes ---*/}
                <ProtectedRoute exact path="/metrics" component={Metrics} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/logs' component={Logs} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/companies' component={Companies} modules={[Modules.SUPER_ADMIN]} />
                <ProtectedRoute exact path='/companies/create' component={NewCompany} modules={[Modules.SUPER_ADMIN]} />
                <ProtectedRoute exact path='/companies/:id' component={Company} modules={[Modules.SUPER_ADMIN]} />
                <ProtectedRoute exact path='/companies/:id/users/create' component={NewUser} modules={[Modules.SUPER_ADMIN]} />
                <ProtectedRoute exact path='/companies/:id/dashboard' component={DashboardCompany} />
                <ProtectedRoute exact path='/users' component={Users} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/users/new' component={NewUser} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/users/:id' component={User} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />

                {/* --- ??? routes ---*/}
                <ProtectedRoute exact path='/users' component={Users} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/users/create' component={NewUser} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />

                {/* --- Not existing routes ---*/}
                <Route
                  exact
                  path='*'
                  render={(props) => <AppWrapper  {...props} ><NotFoundPage {...props} /></AppWrapper>}
                />
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    );
  }

  render() {

    if (this.state.loaded) {
      return (this.content());
    }

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: "100vh"}}
      >

        <Grid item xs={3}>
          <CircularProgress />
        </Grid>

      </Grid>
    );

  }

  componentWillUnmount() {
    WS.remove("test:invite");
    window.removeEventListener("storage", this.listenLogout);
  }

  componentDidUpdate() {
    window.addEventListener("storage", this.listenLogout);
  }

  componentDidMount() {
    const {UserStore} = this.props;
    const token = localStorage.getItem("accessToken");

    if (!token) {
      this.setState({loaded: true});

      return;
    }

    userService.getUserInfo()
      .then(function (user) {
        // Connect WS

        if (user._id) {
          WS.connect(user);
          UserStore.setUser(user);
        }

        WS.listen("test:invite", (data) => {
          this.props.NotificationStore.showInfo(`${data.name} has requested you to perform a ${data.type}!`, {
            button: {
              icon: EditIcon
            },
            callback: this.redirectToForm.bind(null, data.testID)
          });

        });
        this.setState({loaded: true});
      }.bind(this))
      .catch((error) => {

        if (error) {
          this.setState({loaded: true});
        }
      });
  }

  listenLogout(e) {
    const wasRemoved = e.oldValue && !e.newValue;

    if(e.key !== "accessToken" || !wasRemoved) return;

    window.location.reload();
  }
  logout() {
    document.title = "SteelTrace - Certificate Manager";
    this.setState({loaded: true});
  }
}

export default compose(
  inject("NotificationStore", "UserStore"),
  observer
)(App);
