import {TEST_RESULTS} from "@core/constants/testResults";
import React, {useState} from "react";
import {
  FormControlLabel,
  Grid,
  MenuItem, Radio, RadioGroup
} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import {indexBy, prop, keys, isEmpty} from "ramda";
import useFetchTestNorms from "@core/hooks/useFetchTestNorms";
import SelectField from "@core/components/FormikSelect";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TextField from "@core/components/FormikTextField";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {TEST_UNITS} from "@core/constants/test";
import {PROOFLOAD_TYPES, PROOFLOAD_TYPES_TITLES} from "./data";
import TestFooter from "../../LabTestFooter";

const validationSchema = yup.object().shape({
  client: yup.string().required("Client is required"),
  lab: yup.string().required("Laboratory is required"),
  norm: yup.string().required("Norm is required"),
  grade: yup.string().required("Grade is required!"),
  type: yup.string().required("Type is required!"),
  nominalSize: yup.string().required("Nominal size is required!"),
  threadsPitch: yup.string().required(),
  stressArea: yup.string().required(),
  proofLoad: yup.string().required(),
  result: yup.string().required("Result is required!")
});

const ProofloadTest = ({
  test,
  saveTest,
  user,
  client,
  formRef
}) => {
  const [grades, setGrades] = useState({});

  useFetchTestNorms(test.type, test.properties.norm, setGrades);

  const initialValues = {
    client: client.name || "",
    lab: user.company.name || "",
    norm: "",
    grade: "",
    type: "",
    nominalSize: "",
    threadsPitch: "",
    stressArea: "",
    proofLoad: "",
    result: "",
    units: TEST_UNITS.METRIC
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{...initialValues, ...test.properties}}
      validationSchema={validationSchema}
      onSubmit={saveTest}
    >
      {(props) => {
        const {setFieldValue, values} = props;

        const grade = values.grade && grades[values.grade] ? grades[values.grade] : {};
        const nominalSizes = grade.NominalSize ? grade.NominalSize[values.units] : {};

        const onUnits = (event) => {
          setFieldValue("units", event.target.value);
          setFieldValue("nominalSize", "");
          setFieldValue("threadsPitch", "");
          setFieldValue("stressArea", "");
          setFieldValue("proofLoad", "");
          setFieldValue("type", "");
          setFieldValue("result", "");
        };

        const changeLimits = (nominalSize, type) => {
          if (!nominalSizes[nominalSize]) return;

          const {ThreadsPitch: threadsPitch, StressArea: stressArea, HeavyHex: heavyHex, Hex: hex} = nominalSizes[nominalSize];

          setFieldValue("threadsPitch", threadsPitch, false);
          setFieldValue("stressArea", stressArea, false);

          if (!type) return;

          const proofLoad = type === PROOFLOAD_TYPES.HEAVY_HEX ? heavyHex : hex;

          setFieldValue("proofLoad", proofLoad, false);
        };

        return (
          <>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <ClientField isFromProducer={!!client.name} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  name="lab"
                  label="Laboratory"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end">
              <Grid item xs={3}>
                <NormAutocomplete
                  label="Material Specification"
                  name="norm"
                  testType={test.type}
                  onChange={({norm, value}) => {
                    setFieldValue("norm", norm);
                    setGrades(value ? indexBy(prop("Material"), value) : {});
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  disabled={!values.norm || isEmpty(grades)}
                  label="Grade / UNS"
                  name="grade"
                  placeholder={isEmpty(grades) && "Material specification not supported"}
                  required
                  onChange={() => changeLimits(values.nominalSize, values.type)}
                >
                  {keys(grades).map((grade) => (
                    <MenuItem key={grade} value={grade}>
                      {grade}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end">
              <Grid item xs={3}>
                <RadioGroup
                  row
                  value={values.units || TEST_UNITS.METRIC}
                  onChange={onUnits}
                >
                  <FormControlLabel value={TEST_UNITS.METRIC} control={<Radio color="primary" />}
                    label={TEST_UNITS.METRIC} />
                  <FormControlLabel value={TEST_UNITS.IMPERIAL} control={<Radio color="primary" />}
                    label={TEST_UNITS.IMPERIAL} />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <SelectField
                  disabled={!values.grade || isEmpty(grades)}
                  label="Nominal size"
                  name="nominalSize"
                  required
                  placeholder={isEmpty(grades) && "Material specification not supported"}
                  onChange={(value) => changeLimits(value, values.type)}
                >
                  {keys(nominalSizes).map((nominalSize) => (
                    <MenuItem key={nominalSize} value={nominalSize}>
                      {nominalSize}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Threads pitch"
                  name="threadsPitch"
                  placeholder='0'
                  type="number"
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Stress area"
                  name="stressArea"
                  placeholder='0'
                  type="number"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <SelectField
                  label="Type"
                  name="type"
                  required
                  onChange={(value) => changeLimits(values.nominalSize, value)}
                >
                  {keys(PROOFLOAD_TYPES).map((type) => (
                    <MenuItem key={PROOFLOAD_TYPES[type]} value={PROOFLOAD_TYPES[type]}>
                      {PROOFLOAD_TYPES_TITLES[PROOFLOAD_TYPES[type]]}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Proof load"
                  name="proofLoad"
                  placeholder='0'
                  type="number"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <SelectField
                  disabled={!values.proofLoad}
                  label="Result"
                  name="result"
                  required
                >
                  <MenuItem value={TEST_RESULTS.ACCEPTABLE}>
                    The nut resisted the proof load without stripping or rupture, and is removable from the test mandrel
                    by the fingers after the load is released
                  </MenuItem>
                  <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE}>
                    The nut didn't resist the proof load
                  </MenuItem>
                </SelectField>
              </Grid>
            </Grid>
            <TestFooter
              onSubmit={saveTest}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ProofloadTest;
