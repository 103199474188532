import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import * as yup from "yup";

export const END_PRODUCT = {
  quantity: "",
};

export const SPECIFICATION = {
  norm: "",
  grades: [],
};

export const initialValuesDefault = {
  name: "",
  type: CAMPAIGN_TYPES.SINGLE_MATERIAL,
  certificationType: "",
  connectToPO: false,
  existingMaterial: false,

  // required if attach to PO
  customer: "", // omit from result
  purchaseOrder: {}, // format to id

  manufacturingLocation: "",
  properties: {
    statistics: {
      estimatedHeats: "",
      estimatedHeatLots: ""
    },
    product: {
      qcp: "",
      qcpName: "", // omit from result
      productType: {
        _id: "", // omit from result
        name: "",
        internalName: "", // to be renamed to internalId
        properties: [],
      },
      materialSpecifications: [SPECIFICATION],
      nominalSizes: []
    }
  },

  products: [],
};

export const validationSchema = yup.object().shape({
  name: yup.string().required("Campaign name is required")
});

export const initialValuesEdit = {
  "name": "New steel",
  "type": "Single material",
  "certificationType": "3.2",
  "connectToPO": true,
  "existingMaterial": true,
  "customer": "",// id
  "purchaseOrder": "",// id
  "manufacturingLocation": "",// id
  "properties": {
    statistics: {
      estimatedHeats: "1231",
      estimatedHeatLots: "111"
    },
    product: {
      qcp: "",// id
      productType: {
        _id: "",
        "name": "pipe",
        "internalId": "pipe",
        properties: [{
          label: "Outside diameter",
          measurements: "MM",
          name: "outsideDiameter"
        }, {
          label: "Weight",
          measurements: "kg",
          name: "weight"
        }, {
          label: "Height",
          measurements: "m",
          name: "height"
        }]
      },
      materialSpecifications: [{
        "norm": "API 5L (2018) PSL2",
        "grades": ["X42MO", "BR"]
      },
      {
        "norm": "ASTM A182 (2021)",
        "grades": ["F73"]
      }],
      nominalSizes: [{
        "quantity": 10,
        "outerDiameter": 10,
        "weight": 20,
        "height": 100,
        "qcpItem": "2323"
      },
      {
        "quantity": 1000,
        "outerDiameter": 210,
        "weight": 3210,
        "height": 12220,
        "qcpItem": "231"
      }]
    }
  },
};