export default () => ({
  fileUploader: {
    display: "none",
  },
  asterisk: {
    position: "relative",
    "&:after" : {
      position: "absolute",
      content: "' *'",
      color: "#ff0000"
    }
  },
  downloadButton: {
    display: "flex",
    alignItems: "center"
  }
});