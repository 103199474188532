import React, {useState, useEffect} from "react";
import {IconButton, Zoom} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import {FaFileSignature} from "react-icons/fa";
import KeyboardArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeftOutlined";
import {observer} from "mobx-react-lite";
import useStores from "../../../useStores";

const TimelineToggle = observer(({classes, refTest, alwaysInViewPort}) => {
  const [isExpand, setIsExpand] = useState(false);

  const {TimelineStore} = useStores();
  const useIntersection = (element,rootMargin)=>{
    const [isVisible,setIsVisible]= useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
        }, {rootMargin}
      );

      element.current && observer.observe(element.current);

      return () => element.current && observer.unobserve(element.current);
    },[]);

    return isVisible;
  };

  const inViewport = alwaysInViewPort || useIntersection(refTest, "0px");

  return (
    <Zoom in={inViewport} timeout={300}>
      <IconButton onClick={() => TimelineStore.toggle()}
        role="presentation"
        data-html2canvas-ignore="true"
        className={classes.root}
        onMouseEnter={()=>setIsExpand(true)}
        onMouseLeave={()=>setIsExpand(false)}
      >
        <div className={classes.mainTest}>
          <KeyboardArrowLeftOutlined className={TimelineStore.open ? classes.rotateArrow : ""}/>
          <div
            className={!isExpand?classes.signature:classes.signatureHover}>
            <FaFileSignature/>
          </div>
        </div>
      </IconButton>
    </Zoom>
  );
});

export default withStyles(TimelineToggle, styles);
