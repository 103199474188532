export default (theme) => ({
  testView: {
    backgroundColor: "#fff",
    marginTop: 20,
    borderRadius: 4,
    borderRight: "1px solid #e0e0e0",
  },
  testResults: {
    padding: 20,
    borderBottom: "1px solid #e0e0e0",
    position: "relative",
  },
  result: {
    marginTop: theme.spacing(3),
  },
  reviewSummary: {
    margin: "20px 0",
    paddingRight: 8,
  }
});