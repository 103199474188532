import React from "react";
import {Grid, DialogContent, Dialog} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ProofloadForm from "@core/components/TestAddDataForms/ProofloadTest";
import styles from "./styles";

const ProofloadTest = ({classes, test, addDataOpen, dataDialogClose, updateTest, formRef}) => {
  return (
    <>
      <Grid container spacing={5} className={classes.container}>
        {test.properties.client && (
          <Grid item xs={3}>
            <p>Client</p>
            <h6>{test.properties.client}</h6>
          </Grid>
        )}
        {test.properties.lab && (
          <Grid item xs={3}>
            <p>Laboratory</p>
            <h6>{test.properties.lab}</h6>
          </Grid>
        )}
        <Grid item xs={3}>
          <p>Material specification</p>
          <h6>{test.properties.norm}</h6>
        </Grid>
        <Grid item xs={3}>
          <p>Grade / UNS</p>
          <h6>{test.properties.grade}</h6>
        </Grid>
        <Grid item xs={3}>
          <p>Nominal size</p>
          <h6>{test.properties.nominalSize}</h6>
        </Grid>
        <Grid item xs={3}>
          <p>Threads pitch</p>
          <h6>{test.properties.threadsPitch}</h6>
        </Grid>
        <Grid item xs={3}>
          <p>Stress area</p>
          <h6>{test.properties.stressArea}</h6>
        </Grid>
        <Grid item xs={3}>
          <p>Type</p>
          <h6>{test.properties.type}</h6>
        </Grid>
        <Grid item xs={3}>
          <p>Proof load</p>
          <h6>{test.properties.proofLoad}</h6>
        </Grid>
        <Dialog
          open={addDataOpen}
          onClose={dataDialogClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <ProofloadForm
              formRef={formRef}
              saveTest={updateTest}
              test={test}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export default withStyles(ProofloadTest, styles);
