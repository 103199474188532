export default () => ({
  pending: {
    fontStyle: "italic"
  },
  stripe: {
    backgroundColor: "#e6cc00", // TODO change to smth from theme
  },
  expansionPanelRoot: {
    backgroundColor: "initial",
    "&&": {
      cursor: "not-allowed"
    }
  },
  summaryContentRoot: {
    cursor: "not-allowed",
    "&&": {
      opacity: "initial"
    }
  },
  summaryContent: {
    flexDirection: "column",
    justifyContent: "center",
    cursor: "not-allowed"
  },
});