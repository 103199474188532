import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Box, Grid, Divider} from "@mui/material";
import LegendWrapper from "../../../Footer/components/LegendWrapper";
import {flatten, uniq} from "ramda";
import useStores from "../../../../../../../../../../useStores";
import {PIPE_CONFIG, WELD_CONFIG} from "../../constants";
import {QUARANTINE_PIPE_CONFIG, REPAIRED_WELD_CONFIG, TIE_IN_WELD_CONFIG, PIPE_WITH_COATING_CONFIG, DEFECT_CONFIG} from "../../../Stalk/constants";

const PIPE_LEGEND_CONFIG = {
  HEIGHT: 15,
  WIDTH: 30,
  RADIUS: "2px",
};

const WELD_LEGEND_CONFIG = {
  HEIGHT: 15,
  WIDTH: 10,
  RADIUS: "2px",
};

const DEFECT_LEGEND_CONFIG = {
  HEIGHT: 15,
  WIDTH: 5,
  RADIUS: "2px",
};

const Legend = ({settings, isWrapped}) => {
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;
  const coatingTypesByPipeNumber = CampaignStore.coatingTypesByPipeNumber;
  const coatingCombinations = CampaignStore.coatingCombinations;
  const longestCoatingCombination = CampaignStore.longestCoatingCombination;
  const baseMaterialsById = CampaignStore.campaignProductsById;

  const pipeNumbers = useMemo(() => {
    const pipeIds = uniq(flatten(campaign.welds.map((weld) => [weld.pipeA, weld.pipeB])));

    return pipeIds.map((id) => baseMaterialsById[id]?.pipeNumber);
  }, [campaign.welds, campaign.products]);

  const isEveryPipeCoated = useMemo(() => {
    return pipeNumbers.every((pipeNumber) => coatingTypesByPipeNumber[pipeNumber]);
  }, []);

  return (
    <LegendWrapper
      isWrapped={isWrapped}
    >
      {!isEveryPipeCoated && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  background: PIPE_CONFIG.BACKGROUND_COLOR,
                  borderRadius: PIPE_LEGEND_CONFIG.RADIUS,
                  height: PIPE_LEGEND_CONFIG.HEIGHT,
                  width: PIPE_LEGEND_CONFIG.WIDTH,
                }}
              />
            </Grid>
            <Grid item>
              Pipe
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {settings.quarantine && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: QUARANTINE_PIPE_CONFIG.BACKGROUND_COLOR,
                  borderRadius: PIPE_LEGEND_CONFIG.RADIUS,
                  height: PIPE_LEGEND_CONFIG.HEIGHT,
                  width: PIPE_LEGEND_CONFIG.WIDTH,
                }}
              />
            </Grid>
            <Grid item>
              Quarantined pipe
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {Boolean(!!coatingCombinations.length && settings.coatingType) && (
        coatingCombinations.map((coatingTypes) => {
          const startIndex = longestCoatingCombination.indexOf(coatingTypes[0]);
          const endIndex = longestCoatingCombination.indexOf(coatingTypes[coatingTypes.length - 1]);

          const coatingColors = PIPE_WITH_COATING_CONFIG.BACKGROUND_COLORS.slice(startIndex, endIndex + 1);
          
          return (
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Box
                    sx={{
                      background: coatingTypes.length > 1 ?
                        `linear-gradient(to bottom, ${coatingColors.map((color) => `${color} ${100 / coatingColors.length}%`).join(",")})` :
                        coatingColors[0],
                      borderRadius: PIPE_LEGEND_CONFIG.RADIUS,
                      height: PIPE_LEGEND_CONFIG.HEIGHT,
                      width: PIPE_LEGEND_CONFIG.WIDTH,
                    }}
                  />
                </Grid>
                <Grid item>
                  {coatingTypes.join("/")}
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" sx={{height: 20}} />
                </Grid>
              </Grid>
            </Grid>  
          );
        })
      )}
      {settings.welds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  background: WELD_CONFIG.BACKGROUND_COLOR,
                  borderRadius: WELD_LEGEND_CONFIG.RADIUS,
                  height: WELD_LEGEND_CONFIG.HEIGHT,
                  width: WELD_LEGEND_CONFIG.WIDTH,
                }}
              />
            </Grid>
            <Grid item>
              Weld
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {settings.highlightRepairedWelds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: REPAIRED_WELD_CONFIG.BACKGROUND_COLOR,
                  borderRadius: WELD_LEGEND_CONFIG.RADIUS,
                  height: WELD_LEGEND_CONFIG.HEIGHT,
                  width: WELD_LEGEND_CONFIG.WIDTH,
                }}
              />
            </Grid>
            <Grid item>
              Repaired weld
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {settings.tieInWelds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: TIE_IN_WELD_CONFIG.BACKGROUND_COLOR,
                  borderRadius: WELD_LEGEND_CONFIG.RADIUS,
                  height: WELD_LEGEND_CONFIG.HEIGHT,
                  width: WELD_LEGEND_CONFIG.WIDTH,
                }}
              />
            </Grid>
            <Grid item>
              Tie-in weld
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {settings.highlightCoatingDefects && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: DEFECT_CONFIG.BACKGROUND_COLOR,
                  borderRadius: DEFECT_LEGEND_CONFIG.RADIUS,
                  height: DEFECT_LEGEND_CONFIG.HEIGHT,
                  width: DEFECT_LEGEND_CONFIG.WIDTH,
                }}
              />
            </Grid>
            <Grid item>
              Coating defect
            </Grid>
          </Grid>
        </Grid>
      )}
    </LegendWrapper>
  );
};

export default observer(Legend);