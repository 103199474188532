import React, {memo, useCallback} from "react";
import {Group} from "react-konva";
import {MARGIN} from "../../../../../Stalk/constants";
import {PIPE_CONFIG} from "../../../../constants";
import SequenceNumber from "../../../../../SequenceNumber";
import {toLower} from "ramda";
import qs from "qs";
import {PAGE_TABS} from "../../../../../../../../constants";
import {ENTITIES} from "../../../../../../../../../../constants";
import {useHistory} from "react-router";

const PipesRow = ({chunk, chunkIndex, splitBy, pipes, animateShape, scale, settings, pipesRef, pipesByIdRef, PipeComponent}) => {
  const history = useHistory();

  const onClick = useCallback((id) => {
    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        tab: toLower(PAGE_TABS.PIPELINE),
        [ENTITIES.PIPE]: id,
      }),
    });
  }, []);

  return chunk.map((pipe, pipeIndexInChunk) => {
    const index = chunkIndex * splitBy + pipeIndexInChunk;

    const pipeCoordinates = {x: (PIPE_CONFIG.WIDTH + MARGIN) * pipeIndexInChunk, y: 0};

    return (
      <Group
        key={pipe._id}
        ref={(element) => {
          pipesByIdRef.current[index] = element;
        }}
      >
        <SequenceNumber
          pipeConfig={PIPE_CONFIG}
          stalkLength={pipes.length}
          scale={scale}
          pipeCoordinates={pipeCoordinates}
          index={index}
        />
        <PipeComponent
          index={index}
          pipes={pipesRef}
          animateShape={animateShape}
          settings={{...settings, welds: false}}
          id={pipe.pipeNumber}
          tooltipData={{pipe: pipe.pipeNumber}}
          pipeCoordinates={pipeCoordinates}
          setOpenPipe={onClick}
          pipeConfig={PIPE_CONFIG}
        />
      </Group>
    );
  });
};

export default memo(PipesRow);