import React, {useEffect, useState} from "react";
import {sort} from "ramda";
import {Checkbox, Popover, List, ListItem, ListItemText} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Input} from "@core/components/Form";
import DateCreatedFilter from "@core/components/DateCreatedFilter";
import styles from "./styles";
import {FILTERS} from "@core/constants/filters";

const OptionsFilter = ({
  classes,
  anchorEl,
  type,
  filters,
  setFilters,
  onClose,
  ...props
}) => {
  const allOptions = props.options;

  const [options, setOptions] = useState([]);
  const [filterBy, setFilterBy] = useState("");

  const optionsString = props.options.join("");
  const selectedOptions = filters[type] || [];
  const sortedOptions = sort((itemA, itemB) => itemA.localeCompare(itemB, undefined, {numeric: true}), options);

  useEffect(() => {
    setOptions(allOptions);
  }, [optionsString]);

  const onFilterBy = (e) => {
    const value = e.target.value;
    setFilterBy(value);
    setOptions(allOptions.filter((option) => option.toLowerCase().includes(value.toLowerCase())));
  };

  const onOption =  (option) => {
    let newFilters = {};

    if(selectedOptions.includes(option)) {
      newFilters = selectedOptions.filter((o) => o !== option);
    } else {
      newFilters = [...selectedOptions, option];
    }

    setFilters({...filters, [type]: newFilters});
  };

  if(!allOptions.length) return null;

  return (
    <Popover
      classes={{paper: classes.popover}}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      TransitionProps={{
        onExited: () => {
          setFilterBy("");
          setOptions(allOptions);
        }
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className={classes.input}>
        <Input
          variant="outlined"
          value={filterBy}
          placeholder="Filter by"
          onChange={onFilterBy}
        />
      </div>
      <List
        component="div"
        className={classes.optionsList}
      >
        {sortedOptions.map((option) => (
          <ListItem
            button
            key={option}
            classes={{root: classes.listItem}}
            onClick={() => onOption(option)}
          >
            <Checkbox
              checked={selectedOptions.includes(option)}
              value={option}
              color="primary"
            />
            <ListItemText primary={option} />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

const DateFilter = ({
  classes,
  anchorEl,
  filters,
  setFilters,
  onClose,
}) => {
  return (
    <Popover
      classes={{paper: classes.popover}}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <DateCreatedFilter
        filters={filters}
        setFilters={setFilters}
      />
    </Popover>
  );
};

const ChipPopover = (props) => {
  if(props.type === FILTERS.DATE_CREATED) {
    return (
      <DateFilter {...props} />
    );
  }

  return (
    <OptionsFilter {...props} />
  );
};

export default withStyles(ChipPopover, styles);