export default (theme) => ({
  notes: {
    fontWeight: theme.typography.fontWeightMedium,
    color: "#616161"
  },
  name: {
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },
  value: {
    color: "#616161",
    fontWeight: theme.typography.fontWeightMedium,
  },
  table: {
    "& th:last-child, td:last-child": {
      paddingRight: 10
    }
  },
  advancedSettingsButton: {
    display: "flex",
    cursor: "pointer",
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  info: {
    color: theme.palette.info.main,
  },
  shearAreaAverage: {
    display: "flex",
    alignItems: "center"
  },
});

