import React, {useState} from "react";
import {filter, keys} from "ramda";
import {Formik} from "formik";
import TextField from "@core/components/FormikTextField";
import {Dialog, DialogTitle, DialogContent, Button, Grid} from "@mui/material";

const initialValues = {
  crackLengthRatioMax: "",
  crackThicknessRatioMax: "",
  crackSensitivityRatioMax: "",
};

const CustomException = ({requirements, onChange}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Set exceptions
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          Set custom requirements
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
              const filledValues = filter((value) => !!value, values);
              onChange(filledValues);
              setOpen(false);
            }}
          >
            {(props) => (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    name="crackLengthRatioMax"
                    placeholder={requirements.crackLengthRatioMax && requirements.crackLengthRatioMax}
                    type="number"
                    label="Crack length ratio max"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="crackThicknessRatioMax"
                    placeholder={requirements.crackThicknessRatioMax && requirements.crackThicknessRatioMax}
                    type="number"
                    label="Crack thickness ratio max"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="crackSensitivityRatioMax"
                    placeholder={requirements.crackSensitivityRatioMax && requirements.crackSensitivityRatioMax}
                    type="number"
                    label="Crack sensitivity ratio max"
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      disabled={!keys(filter((value) => !!value, props.values)).length}
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.handleSubmit}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomException;
