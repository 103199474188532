import React, {useMemo} from "react";
import {indexBy, prop} from "ramda";
import {observer} from "mobx-react-lite";
import {TableCell, Tooltip, Box, useTheme} from "@mui/material";
import useStores from "../../../../../../useStores";
import {PREV_PIPE_CELL, WELD_NUMBER_CELL} from "../../constants";
import {AiOutlineWarning} from "react-icons/ai";

const PipeNumberCell = ({data, cell}) => {
  const {CampaignStore} = useStores();
  
  const theme = useTheme();

  const {welds, products} = CampaignStore.campaign;

  const weldsByWeldNumber = useMemo(() => indexBy(prop("weldNumber"), welds), [welds.length]);
  const baseMaterialsById = useMemo(() => indexBy(prop("_id"), products), [products.length]);

  const weld = weldsByWeldNumber[data[WELD_NUMBER_CELL]] || {};

  const pipeId = cell === PREV_PIPE_CELL ? weld.pipeB : weld.pipeA;
  const pipeNumber = baseMaterialsById[pipeId]?.pipeNumber.toString();

  const pipesMatch = data[cell]?.toString() === pipeNumber;

  return (
    <TableCell
      sx={{
        color: pipesMatch ? "inherit" : theme.palette.error.text
      }}
    >
      <Box sx={{display: "flex", alignItems: "center"}}>
        {data[cell]}&nbsp;
        {!pipesMatch && (
          <Tooltip title="Pipe ID does not match as-built record">
            <Box sx={{display: "flex", alignItems: "center", cursor: "pointer"}}>
              <AiOutlineWarning size={15} />
            </Box>
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
};

export default observer(PipeNumberCell);