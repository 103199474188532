import axios from "axios";
import {action, makeObservable, observable} from "mobx";
import {createContext} from "react";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class Company {
  constructor() {
    makeObservable(this, {
      companies: observable,
      company: observable,
      tests: observable,
      employees: observable,
      modules: observable,
      create: action,
      update: action,
      getCompanies: action,
      activate: action,
      getTests: action,
      getCompanyById: action,
      getUsers: action,
      getModules: action,
    });
  }
  companies = {
    data: [],
    isLoaded: false
  };

  company = {
    data: {},
    isLoaded: false
  };

  tests = {
    data: [],
    isLoaded: false
  };

  employees = {
    data: [],
    isLoaded: false
  };

  modules = {
    data: [],
    isLoaded: false
  };

  async create(data) {
    this.company.isLoaded = false;

    const res = await axios.post(ROUTES.COMPANY[ACTIONS.CREATE], data, {
      withCredentials: true,
    }).catch((e) => e);

    this.company.data = res.data;
    this.company.isLoaded = true;
  }

  async update(data, id) {
    this.company.isLoaded = false;

    const res = await axios.patch(ROUTES.COMPANY[ACTIONS.UPDATE](id), data, {
      withCredentials: true,
    }).catch((e) => e);

    this.company.data = res.data;
    this.company.isLoaded = true;
  }

  async getCompanies() {
    const res = await axios.get(ROUTES.COMPANY[ACTIONS.ALL], {
      withCredentials: true
    });

    this.companies = {
      data: res.data,
      isLoaded: true
    };
  }

  async activate(data) {
    const res = await axios.post(ROUTES.BLOCKCHAIN_COMPANY[ACTIONS.CREATE], data, {
      withCredentials: true,
    }).catch((e) => e);

    if(!res.data.error) {
      this.company.data.activate = true;
    }
  }

  async getTests(id, params) {
    const res = await axios.get(ROUTES.COMPANY[ACTIONS.SINGLE_TESTS](id), {
      params,
      withCredentials: true
    });

    this.tests = {
      data: res.data,
      isLoaded: true
    };
  }

  async getCompanyById(id) {
    const res = await axios.get(ROUTES.COMPANY[ACTIONS.SINGLE](id), {
      withCredentials: true
    });

    this.company = {
      data: res.data,
      isLoaded: true
    };
  }

  async getUsers(id) {
    const res = await axios.get(ROUTES.COMPANY[ACTIONS.SINGLE_USERS](id), {
      withCredentials: true
    });

    this.employees = {
      data: res.data,
      isLoaded: true
    };
  }

  async getModules() {
    const res = await axios.get(ROUTES.MODULE[ACTIONS.ALL], {
      withCredentials: true
    });

    this.modules = {
      data: res.data,
      isLoaded: true
    };
  }
}

export default createContext(new Company());
