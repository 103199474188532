import React, {useMemo} from "react";
import styles from "./styles";
import RouterLink from "@core/components/RouterLink";
import {Button, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import useQuery from "../core/hooks/useQuery";

const ErrorPage = ({classes}) => {

  const query = useQuery();
  const message = useMemo(() => query.get("message"), [query]);

  return (
    <div className={classes.container}>
      <img src="/images/logo-symbol-blue.svg" alt="Steeltrace logo" className={classes.logoImage} />
      <Typography variant="h4" color="primary" className={classes.message}>
        {message || "Sorry, something went wrong"}
      </Typography>
      <Button color="primary" component={RouterLink} to="/">Go to Home</Button>
    </div>
  );
};

export default withStyles(ErrorPage, styles);
