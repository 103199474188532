import React from "react";
import MuiAutocompleteInput from "@core/components/MuiAutocompleteInput";
import {StyledAutocomplete} from "./styles";
import {useField} from "formik";
import {filter, isEmpty, uniq, prepend} from "ramda";
import {getDynamicNewOption} from "./helpers";
import {DYNAMIC_INPUT} from "./constants";
import MuiAutocompleteOption from "@core/components/MuiAutocompleteOption";
import MuiAutocompletePaper from "@core/components/MuiAutocompletePaper";

function MuiSelect({required, label, name, defaultOptions, creatable = true}) {
  const [field, meta, helpers] = useField(name);
  const showError = Boolean(meta.error) && meta.touched;
  const {setValue: setFormikValue, setTouched: setFormikTouched} = helpers;

  const fieldValue = field?.value || "";

  const withCustomOption = uniq(filter((ts) => !isEmpty(ts), [...defaultOptions, fieldValue]));
  const [options, setOptions] = React.useState(withCustomOption.map((ts) => ({value: ts, label: ts})));

  const dynamicNewOption = React.useRef();

  const filterOptions = (options, {inputValue}) => {
    if (!inputValue) return options;
    
    dynamicNewOption.current = {label: inputValue, value: inputValue};
    const filteredOptions = options.filter((option) => option?.label?.toLowerCase().includes(inputValue.toLowerCase()));
    const newOptions = creatable ? prepend(getDynamicNewOption(inputValue), filteredOptions) : filteredOptions;

    return newOptions;
  };

  const onAutoCompleteChange = (event, selectedOption) => {
    if (!selectedOption) {
      setFormikTouched(true, false);
      setFormikValue("");

      return;
    }
    
    const {value} = selectedOption;

    if (value === DYNAMIC_INPUT) {
      setOptions([...options, dynamicNewOption.current]);
      setFormikValue(dynamicNewOption.current?.value);
      setFormikTouched(true, false);

      return;
    }

    setFormikValue(value);
    setFormikTouched(true, false);
  };

  return (
    <StyledAutocomplete
      value={{label: fieldValue, value: fieldValue}}
      fullWidth={true}
      PaperComponent={MuiAutocompletePaper}
      renderOption={(props, option) => <MuiAutocompleteOption {...props} option={option}/>}
      renderInput={(params) => {
        return (
          <MuiAutocompleteInput
            inputLabel={label}
            inputProps={params.inputProps}
            InputProps={params.InputProps}
            required={required}
            showError={showError}
            errorMessage={showError ? meta.error : null}/>
        );
      }}
      options={options}
      filterOptions={filterOptions}
      onChange={onAutoCompleteChange}
      freeSolo
      forcePopupIcon
    />
  );
}

export default MuiSelect;