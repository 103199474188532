import React from "react";
import {Box, IconButton, TableCell} from "@mui/material";
import {IoMdCloseCircleOutline} from "react-icons/io";

const RemoveButtonCell = ({disabled, onClick}) => (
  <TableCell>
    <Box sx={{display: "flex", justifyContent: "center"}}>
      <IconButton
        onClick={onClick}
        disabled={disabled}
        sx={{
          "&:hover": {
            color: (theme) => theme.palette.error.main
          }
        }}
        size="large"
      >
        <IoMdCloseCircleOutline/>
      </IconButton>
    </Box>
  </TableCell>
);

export default RemoveButtonCell;