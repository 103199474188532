export default () => ({
  labelBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "initial",
    color: "#575757",
    fontSize: 16,
    
    "& .itemIcon": {
      display: "flex",
      paddingLeft: 10,
      fontSize: 20,
    }
  },

  toolBar: {
    backgroundColor: "white",
    padding: "0 12px",
    "& img": {
      marginRight: 5,
      height: 20,
      width: 24,
    }
  }
});
