export default (theme) => ({
  main: {
    padding: theme.spacing(4),
  },
  topSection: {
    marginBottom: theme.spacing(3),
  },
  fullHeight: {
    height: "100%"
  },
  tests: {
    marginTop: 20,
  },
  test: {
    marginTop: 20,
  }
});