export const ICONS = {
  "zip": "/images/files/ppt/zip",
  "pptx": "/images/files/ppt.svg",
  "ppt": "/images/files/ppt.svg",
  "jpeg": "/images/files/jpg.svg",
  "jpg": "/images/files/jpg.svg",
  "png": "/images/files/png.svg",
  "pdf": "/images/files/pdf.svg",
  "xls": "/images/files/xls.svg",
  "xlsx": "/images/files/xls.svg",
  "doc": "/images/files/doc.svg",
  "docx": "/images/files/doc.svg",
  "txt": "/images/files/txt.svg"
};