import * as yup from "yup";
import convert  from "convert-units";
import {ZONES} from "@core/constants/test";
import ManualForm, {validationSchema as manualFormValidationSchema} from "./components/ManualForm";
import AutomaticWithPitsForm, {validationSchema as automaticWithPitsFormValidationSchema} from "./components/AutomaticWithPitsForm";
import AutomaticForm, {validationSchema as automaticFormValidationSchema} from "./components/AutomaticForm";

export const commonValidationSchema = {
  client: yup.string().required("This field is required"),
  laboratory: yup.string().required("This field is required"),
  testStandard: yup.string().required("This field is required"),
  method: yup.string().required("This field is required"),
  acceptance: yup.string().required("This field is required"),
  specimenId: yup.string().required("This field is required"),
  position: yup.string().required("This field is required"),
  orientation: yup.string().required("This field is required"),
  diameter: yup.number().positive("Should be > 0").required("This field is required"),
  weight: yup.number().positive("Should be > 0").required("This field is required"),
  size: yup.number().positive("Should be > 0").required("This field is required"),
  exposedSurface: yup.number().positive("Should be > 0").required("This field is required"),
  preparation: yup.string().required("This field is required"),
  heatTreatment: yup.string(),
  testStart: yup.string(),
  testingTime: yup.number().min(1, "Should be more than 1").required("This field is required"),
  testEnd: yup.string(),
  testTemperature: yup.number().required("This field is required"),
  zone: yup.string(),
  result: yup.string().required("This field is required"),
};

export const TEST_STANDARDS = [{
  value: "ASTM A262 (2015)",
  component: ManualForm,
  validationSchema: manualFormValidationSchema,
  zones: [ZONES.BASE_METAL, ZONES.HAZ, ZONES.WELD_METAL, ZONES.TRANSITION_AREA_LINER, ZONES.WELD_OVERLAY],
  methods: {
    E: {
      testReactant: "100 g CuSO4 x 5H2O (in 700 ml H2O) + 100 ml H2SO4 + H2O (to 1000 ml)",
      bendingAngle: 180,
    }
  }
}, {
  value: "ASTM G48-11 (2020)e1",
  component: AutomaticWithPitsForm,
  zones: [ZONES.BASE_METAL, ZONES.WELD_METAL, ZONES.WELD_OVERLAY],
  validationSchema: automaticWithPitsFormValidationSchema,
  methods: {
    A: {
      testReactant: "100 g FeCl3 x 6H2O + 900 ml H2O",
      maxPits: 0,
      numberOfPits: {
        sideA: 0,
        sideB: 0,
      },
      pitsDepth: {
        avg: 0,
        max: 0,
      }
    }
  },
}, {
  value: "ASTM G28-02 (2015)",
  component: AutomaticForm,
  zones: [ZONES.BASE_METAL, ZONES.WELD_METAL, ZONES.WELD_OVERLAY],
  validationSchema: automaticFormValidationSchema,
  methods: {
    A: {
      testReactant: "25 g Fe2(SO4)3 + 236 ml H2SO4 + 400 ml H2O",
    }
  },
}];

export const CORROSION_RATE_K_BY_UNIT = {
  "mpy": () => 3450000,
  "ipy": () => 3450,
  "ipm": () => 287,
  "mm/y": () => 87600,
  "mm/month": () => 87600/12,
  "micron/y": () => 87600000,
  "pm/s": () => 2780000,
  "g/m^2-h": (materialDensity) => 10000 * materialDensity,
  "mdd": (materialDensity) => 2400000 * materialDensity,
  "microgram/m^2-s": (materialDensity) => 2780000 * materialDensity
};

export const WEIGHT_UNIT = {
  "g/m^2": (value) => value,
  "microgram/m^2": (value) => convert(value).from("mcg").to("g")
};

export const SPECIMEN_PREPARATIONS = ["Grinding with 180 grit abrasive paper on specimen surfaces. Degreasing with alcohol"];