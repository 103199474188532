import React, {useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle, Grid, TextField as DateField} from "@mui/material";
import moment from "moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";

const SetInspectionDatePopup = ({open, close, inspectionDate, onSubmit}) => {
  const [value, setValue] = useState(inspectionDate || "");

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
                Set inspection Date:
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DateField
              variant="standard"
              label="Inspection Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={value ? moment(value).format(DATE_FORMAT) : ""}
              onChange={(event) => setValue(event.target.value)} />
          </Grid>
          <Grid item xs>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={async () => {
                await onSubmit(value);
                close();
              }}
            >
                            Save
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SetInspectionDatePopup;