export default (theme) => ({
  values: {
    color: "#616161"
  },

  greenValidate: {
    color: theme.palette.success.main,
  },

  redValidate: {
    color: theme.palette.error.main,
  },

  file: {
    cursor: "pointer",
    color: "#2d88d1",
    textDecoration: "underline",
  },
});

