import React from "react";
import {groupBy, map, prop, sort, uniq, values, keys} from "ramda";
import {Chart} from "react-chartjs-2";

const colors = [
  "#2d88d1",
  "#ec7e35",
  "#78d237",
  "#ffd246",
  "#ff6358",
  "#aa46be"
];

const ImpactCurve = ({elements, valuesLabel}) => {
  const elementsWithData = elements.filter((element) => element.temperature && element.position && element.orientation);

  const sortedByTemperatures = sort((a, b) => a.temperature - b.temperature, elementsWithData);

  const elementsWithLabel = sortedByTemperatures.map((element) => ({
    ...element,
    label: `${element.position} - ${element.orientation}`
  }));

  const groupedByPosition = groupBy(prop("label"), elementsWithLabel);
  const datasets = values(groupedByPosition);
  const positions = keys(groupedByPosition);

  const scatter = datasets.map((dataset, index) => {
    const points = dataset.reduce((points, element) => {
      const elementPoints = element.values.map((value) => ({x: element.temperature, y: value}));

      return points.concat(elementPoints);
    }, []);

    return {
      data: points,
      fill: false,
      borderColor: colors[index],
      label: positions[index],
      type: "scatter"
    };
  });

  const avgTemperatures = sortedByTemperatures.map((element) => element.values.reduce((acc, value) => acc + Number(value), 0) / element.values.length);

  const regressionLine = {
    type: "line",
    pointRadius: 0,
    label: "Regression line for the moving average of the absorbed energy",
    borderColor: "rgb(255, 99, 132)",
    fill: false,
    data: avgTemperatures,
  };

  const labels = uniq(map(prop("temperature"), sortedByTemperatures));

  const data = {
    labels,
    datasets: [regressionLine, ...scatter]
  };

  const options = {
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Test temperature [\u00b0C]"
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: valuesLabel
        }
      }
    },
  };

  return (
    <Chart
      type="scatter"
      data={data}
      options={options}
    />
  );
};

export default ImpactCurve;