import React, {useRef, memo} from "react";
import {keys} from "ramda";
import {Rect, Layer, Group, RegularPolygon} from "react-konva";
import LengthProgressBar from "../LengthProgressBar";
import {isElementOnScreen} from "../../helpers";

const TOOLTIP_POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",    
  RIGHT: "right"
};

const TOOLTIP_POSITIONS_PRIORITY = [TOOLTIP_POSITIONS.TOP, TOOLTIP_POSITIONS.BOTTOM, TOOLTIP_POSITIONS.RIGHT, TOOLTIP_POSITIONS.LEFT];

const TooltipContainer = ({node = {}, config, scale, stage, children}) => {
  const layer = useRef(null);
  const tooltips = useRef({});

  const pointerHeight = config.POINTER_RADIUS / 2;
  const paddingInBetweenPointerAndTooltip = 2 * config.PADDING;

  const contentHeight = node.data.length && node.data.totalLength ? config.HEIGHT : config.HEIGHT_WITHOUT_AS_BUILT_LENGTH;
  
  const tooltipCoordinatesByPosition = {
    [TOOLTIP_POSITIONS.TOP]: {
      y: node.y / scale - contentHeight - paddingInBetweenPointerAndTooltip,
      x: node.x / scale - config.WIDTH / 2,
    },
    [TOOLTIP_POSITIONS.BOTTOM]: {
      y : node.y / scale + paddingInBetweenPointerAndTooltip,
      x: node.x / scale - config.WIDTH / 2,
    },
    [TOOLTIP_POSITIONS.LEFT]: {
      y: node.y / scale - contentHeight / 2,
      x: node.x / scale - config.WIDTH - paddingInBetweenPointerAndTooltip,
    },
    [TOOLTIP_POSITIONS.RIGHT]: {
      y: node.y / scale - contentHeight / 2,
      x: node.x / scale + paddingInBetweenPointerAndTooltip,
    },
  };
  
  const pointerCoordinatesByPosition = {
    [TOOLTIP_POSITIONS.TOP]: {
      y: contentHeight + pointerHeight,
      rotation: 180,
      x: config.WIDTH / 2
    },
    [TOOLTIP_POSITIONS.BOTTOM]: {
      y: -pointerHeight,
      rotation: 0,
      x: config.WIDTH / 2
    },
    [TOOLTIP_POSITIONS.LEFT]: {
      y: contentHeight / 2,
      rotation: 90,
      x: config.WIDTH + pointerHeight
    },
    [TOOLTIP_POSITIONS.RIGHT]: {
      y: contentHeight / 2,
      rotation: 270,
      x: 0 - pointerHeight
    },
  };

  const visibleTooltip = TOOLTIP_POSITIONS_PRIORITY.find((position) => {
    if(!tooltips.current[position]) return false;

    return isElementOnScreen(tooltips.current[position], stage.current);
  });

  return (
    <Layer
      listening={false}
      ref={layer}
      scale={{x: scale, y: scale}}
    >
      {keys(TOOLTIP_POSITIONS).map((position) => {
        const pointerCoordinates = pointerCoordinatesByPosition[TOOLTIP_POSITIONS[position]];
        const tooltipCoordinates = tooltipCoordinatesByPosition[TOOLTIP_POSITIONS[position]];

        return (
          <Group
            visible={visibleTooltip === TOOLTIP_POSITIONS[position]}
            key={position}
            x={tooltipCoordinates.x}
            y={tooltipCoordinates.y}
          >
            <RegularPolygon
              x={pointerCoordinates.x}
              y={pointerCoordinates.y}
              rotation={pointerCoordinates.rotation}
              sides={3}
              radius={config.POINTER_RADIUS}
              fill={config.BACKGROUND_COLOR}
              strokeWidth={config.STROKE_WIDTH}
              stroke={config.STROKE_COLOR}
            />
            <Rect
              ref={(ref) => tooltips.current[TOOLTIP_POSITIONS[position]] = ref}
              fill={config.BACKGROUND_COLOR}
              width={config.WIDTH}
              height={contentHeight}
              strokeWidth={config.STROKE_WIDTH}
              stroke={config.STROKE_COLOR}
              cornerRadius={config.RADIUS}
            />
            <Group
              x={config.PADDING}
            >
              <Group
                y={config.PADDING}
              >
                {children}
              </Group>
              <LengthProgressBar
                config={config}
                data={node.data}
              />
            </Group>
          </Group>
        );
      })}
    </Layer>
  );
};

export default memo(TooltipContainer);