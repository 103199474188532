import React from "react";
import {Grid, Typography} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import testConfig from "@core/configs/test";

const Test = ({test, index, types}) => {
  const amount = types[test.type];

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Typography component="h5" variant="h5">
            {testConfig[test.type].title}
            {amount > 1 && <span>: {amount}</span>}
          </Typography>
        </Grid>
      </Grid>
      <>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              label="Heat"
              name={`tests.${index}.item_heat`}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Lot Id"
              name={`tests.${index}.lotId`}
            />
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default Test;
