import React from "react";
import {Switch} from "@mui/material";

const PositionTitle = ({tableIndex, positionNumberingActual, onChange}) => {
  if (tableIndex) return positionNumberingActual ? "Pos ID" : "N";
  else {
    return (
      <div>
        N
        <Switch
          size="small"
          color="primary"
          checked={positionNumberingActual}
          onChange={onChange}
        />
        Pos ID
      </div>
    );
  }
};

export default PositionTitle;