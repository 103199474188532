export default () => ({
  icon: {
    cursor: "pointer",
  },
  container: {
    color: "#575757",
    display: "flex",
    alignItems: "center",
    "& p": {
      maxWidth: 100,
      textOverflow: "ellipsis",
      marginLeft: 10,
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  }
});