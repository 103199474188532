import {useCallback, useState} from "react";
import {usePlotOptions} from "../plotContext";

const usePlotFilter = () => {
  const {setPlotOptions} = usePlotOptions();

  const [splitByZones, setSplitByZones] = useState(false);
  const toggleSplitByZones = useCallback(() => {
    setSplitByZones((prev) => !prev);
    setPlotOptions((prev) => ({...prev, splitByZones: !prev.splitByZones}));
  }, []);

  const [showAcceptanceCriteria, setShowAcceptanceCriteria] = useState(false);
  const toggleShowAcceptanceCriteria = useCallback(() => {
    setShowAcceptanceCriteria((prev) => !prev);
    setPlotOptions((prev) => ({...prev, options: {...prev.options, showAcceptanceCriteria: !prev.options.showAcceptanceCriteria}}));
  }, []);

  const [paramType, setParamType] = useState("");
  const onNewParamType = useCallback((paramType) => {
    setParamType(paramType);
    setPlotOptions((prev) => ({...prev, paramType}));
  }, []);

  const [histogramOptions, setHistogramOptions] = useState({autoBinning: true});
  const onHistogramOptions = useCallback((changes) => {
    setHistogramOptions((prev) => ({...prev, ...changes}));
    setPlotOptions((prev) => ({...prev, options: {...prev.options, ...changes}}));
  }, []);

  return {
    splitByZones, toggleSplitByZones,
    showAcceptanceCriteria, toggleShowAcceptanceCriteria,
    paramType, onNewParamType,
    histogramOptions, onHistogramOptions
  };
};

export default usePlotFilter;