import {getIsWithinRequirements, getMean} from "@core/helpers";
import {TestResult} from "./index";

export class HardnessResult extends TestResult {
  avgMin = 0;
  avgMax = 0;
  elements = [];
    
  constructor(avgMin, avgMax, elements) {
    super();
    this.avgMax = avgMax;
    this.avgMin = avgMin;
    this.elements = elements;
    this.buildResult();
  }

  buildResult() {
    const elementsAcceptable = this.elements.every((element) => {
      return getIsWithinRequirements(element.value, element.min, element.max);
    });

    const average = getMean(this.elements.map((element) => Number(element.value)));
    const averageAcceptable = getIsWithinRequirements(average, this.avgMin, this.avgMax);

    this.isAcceptable = elementsAcceptable && averageAcceptable;
  }
}