import {COLUMNS} from "@core/components/TestSummaryTable/constants";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import {Button, Grid, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {equals, indexBy, prop} from "ramda";
import React, {useEffect, useRef, useState} from "react";
import {withStyles} from "tss-react/mui";
import Certificates from "./components/Certificates";
import Tests from "./components/Tests";
import ParsedTable from "./components/ParsedTable";
import ImportForm from "./components/ImportForm";
import RawMaterialsTable from "@core/components/RawMaterialsTable";
import TestSummaryTable from "@core/components/TestSummaryTable";
import styles from "./styles";

const SUMMARY_COLUMNS = [
  COLUMNS.ACTIVITY_NUMBER,
  COLUMNS.TITLE,
  COLUMNS.WITNESS_EDITABLE,
  COLUMNS.STATUS,
  COLUMNS.RESULT,
  COLUMNS.CONFIDENTIAL_EDITABLE,
];

const formatWitnessesForImport = (tests) => {
  return tests.map((test) => ({
    ...test,
    witnesses: test.witnesses.map((witness) => ({company: witness._id, status: WITNESS_STATUSES.UNSET})),
  }));
};

const ImportData = ({classes, defaultData = {}, close, children, formData, setFormData, parseFile, disabled, ...props}) => {
  const [data, setData] = useState(defaultData);
  const [filteredTestIds, setFilteredTestIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const testsRefs = useRef({});

  const {tests = [], rawMaterials = [], welds = [], certificates = []} = data;

  const testsById = indexBy(prop("_id"), tests);
  const filteredTests = filteredTestIds.length ? filteredTestIds.map((id) => testsById[id]) : tests;

  const performedImport = !equals(data, defaultData);

  useEffect(() => {
    if(!performedImport) return;

    setData(defaultData);
  }, [formData]);

  const importAllowed = !!(tests.length || rawMaterials.length || welds.length || certificates.length);

  const onNewFile = (event) => {
    const file = event.target.files[0];

    if(!file) return;

    const newFormData = performedImport ? {file} : {...formData, file};
    setFormData(newFormData);
  };

  const uploadFile = async () => {
    setIsLoading(true);

    const values = new FormData();
    values.append("file", formData.file);

    const {data} = await parseFile(values);

    setData(data);
    setIsLoading(false);
  };

  const importData = async () => {
    setIsLoading(true);

    const testsWithFormattedWitness = formatWitnessesForImport(tests);
    const certificatesWithFormattedWitness = certificates.map((certificate) => ({...certificate, tests: formatWitnessesForImport(certificate.tests)}));

    await props.importData({
      tests: testsWithFormattedWitness,
      rawMaterials,
      welds,
      certificates: certificatesWithFormattedWitness,
    });

    setIsLoading(false);
    setFormData({});
    setData(defaultData);
    close();
  };

  const deleteRawMaterial = (materialToDeleteId) => {
    const newRawMaterials = rawMaterials.filter((material) => material._id !== materialToDeleteId);
    setData({...data, rawMaterials: newRawMaterials});
  };

  const updateRawMaterial = (materialId, data) => {
    const newRawMaterials = rawMaterials.map((material) => material._id === materialId ? data : material);
    setData((data) => ({...data, rawMaterials: newRawMaterials}));
  };

  const deleteTest = (testId) => {
    const newTests = tests.filter((test) => test._id !== testId);
    setData({...data, tests: newTests});
  };

  const updateTest = (changes, testId) => {
    const newTests = tests.map((test) => test._id === testId ? {...test, ...changes} : test);
    setData({...data, tests: newTests});
  };

  const updateCertificate = (changes, updateIndex) => {
    const newCertificates = certificates.map((certificate, index) => updateIndex === index ? {...certificate, ...changes} : certificate);
    setData({...data, certificates: newCertificates});
  };

  const onTestClick = (_, testId) => {
    testsRefs.current[testId].scrollIntoView({behavior: "smooth", block: "start"});
  };

  return (
    <>
      <div className={classes.block}>
        <Typography variant="h6" marginBottom>Overview</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ImportForm
              file={formData.file}
              isLoading={isLoading}
              onNewFile={onNewFile}
            >
              {children}
            </ImportForm>
          </Grid>
          {defaultData.certificates && (
            <Grid item xs={12}>
              <Certificates
                isLoading={isLoading}
                certificates={certificates}
                updateCertificate={updateCertificate}
              />
            </Grid>
          )}
          {defaultData.tests && (
            <Grid item xs={12}>
              <TestSummaryTable
                tests={filteredTests}
                setFilteredTestIds={setFilteredTestIds}
                onTestClick={onTestClick}
                updateTests={(changes, [testId]) => updateTest(changes, testId)}
                columns={SUMMARY_COLUMNS}
                withPlots={false}
                isLoaded={!isLoading}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              {!performedImport && (
                <Grid item>
                  <Button
                    color='primary'
                    variant="contained"
                    component="span"
                    disabled={isLoading || !formData.file || disabled}
                    onClick={uploadFile}
                  >
                    Parse{isLoading && <>&nbsp;<CircularProgress size={20} /></>}
                  </Button>
                </Grid>
              )}
              {importAllowed && (
                <Grid item>
                  <Button
                    color='primary'
                    variant="contained"
                    component="span"
                    onClick={importData}
                    disabled={isLoading}
                  >
                    Import&nbsp;{isLoading && <>&nbsp;<CircularProgress size={20} /></>}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      {!!rawMaterials.length && (
        <ParsedTable title="Raw Materials">
          <RawMaterialsTable
            savedRawMaterials={rawMaterials}
            deleteRawMaterial={deleteRawMaterial}
            updateRawMaterial={updateRawMaterial}
            disabled
          />
        </ParsedTable>
      )}
      {!!tests.length && (
        <ParsedTable title="Test Reports">
          <Tests
            testsRefs={testsRefs}
            tests={tests}
            deleteTest={deleteTest}
            updateTest={updateTest}
          />
        </ParsedTable>
      )}
    </>
  );
};

export default withStyles(ImportData, styles);