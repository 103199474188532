import React, {memo, useEffect, useRef} from "react";
import {Group} from "react-konva";
import {STALK_NUMBER_WIDTH} from "../../../../../Stalk/constants";
import {PAGE_PADDING, SPACING_BETWEEN_STALKS} from "../../../../../../constants";
import {PIPE_CONFIG} from "../../../../constants";
import PipesRow from "../PipesRow";
import {keys, splitAt} from "ramda";

const PipesRows = ({chunks, splitBy, pipes, animateShape, scale, settings, PipeComponent}) => {
  const pipesByIdRef = useRef({});
  const pipesRef = useRef({});

  useEffect(() => {
    const oldPipesIndexes = keys(pipesByIdRef.current);

    const oldPipesLength = oldPipesIndexes.length;
    const newPipesLength = pipes.length;

    if(oldPipesLength === newPipesLength) return;

    const [, pipesToRemove] = splitAt(newPipesLength - oldPipesLength, oldPipesIndexes);

    pipesToRemove.forEach((index) => pipesByIdRef.current[index].destroy());
  }, [pipes.length]);

  return chunks.map((chunk, chunkIndex) => (
    <Group
      key={chunkIndex}
      x={STALK_NUMBER_WIDTH + PAGE_PADDING}
      y={(PIPE_CONFIG.HEIGHT + SPACING_BETWEEN_STALKS) * chunkIndex}
    >
      <PipesRow
        chunk={chunk}
        chunkIndex={chunkIndex}
        splitBy={splitBy}
        pipes={pipes}
        animateShape={animateShape}
        scale={scale}
        settings={settings}
        pipesRef={pipesRef}
        pipesByIdRef={pipesByIdRef}
        PipeComponent={PipeComponent}
      />
    </Group>
  ));
};

export default memo(PipesRows);