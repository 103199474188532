import {STATUSES} from "@core/constants/test";

const CERTIFICATE_VIEWS = {
  ALL: "all",
  OPEN: "open",
  ATTEST: "attest",
  COMPLETE: "complete"
};

const CERTIFICATE_STATUSES_BY_VIEW = {
  [CERTIFICATE_VIEWS.OPEN]: [STATUSES.ASSIGNED, STATUSES.FILLED],
  [CERTIFICATE_VIEWS.ATTEST]: [STATUSES.INSPECTED, STATUSES.SUBMITTED],
  [CERTIFICATE_VIEWS.COMPLETE]: [STATUSES.APPROVED]
};

const CERTIFICATE_VIEWS_TITLES = {
  [CERTIFICATE_VIEWS.ALL]: "all",
  [CERTIFICATE_VIEWS.OPEN]: "pending tests",
  [CERTIFICATE_VIEWS.ATTEST]: "pending QA test approval",
  [CERTIFICATE_VIEWS.COMPLETE]: "complete"
};

export {CERTIFICATE_VIEWS, CERTIFICATE_VIEWS_TITLES, CERTIFICATE_STATUSES_BY_VIEW};
