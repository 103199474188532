import React, {Component} from "react";
import moment from "moment";
import {compose, keys, tail} from "ramda";
import qs from "qs";
import {Table, TableBody, TableCell, TableHead, TableRow, Paper, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Link, withRouter} from "react-router-dom";
import ActionCell from "@core/components/ActionCell";
import Avatar from "@core/components/ColoredModuleAvatar";
import Button from "@mui/material/Button";
import TableFooter from "@core/components/TableFooter";
import TableNavigation from "@core/components/TableNavigation";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import TableSkeleton from "@core/components/TableSkeleton";
import UserStatus from "../UserStatus";
import ROLES from "@core/constants/roles";
import MODULES from "@core/constants/modules";
import userService from "@core/api/user-service.js";
import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import userStatuses from "@core/constants/userStatuses";
import styles from "./styles.js";
import {inject, observer} from "mobx-react";
import classNames from "classnames";
import {getRowsPerPage, setRowsPerPage} from "@core/helpers";

const TABS = {
  ALL: "ALL",
  DISABLED: "DISABLED",
  NOT_ACTIVE: "NOT_ACTIVE",
  INVITED: "INVITED"
};

const TABS_TITLES = {
  [TABS.ALL]: "All",
  [TABS.DISABLED]: "Disabled",
  [TABS.NOT_ACTIVE]: "Not Activated",
  [TABS.INVITED]: "Invited"
};

const QUERY_BY_TAB = {
  [TABS.ALL]: {},
  [TABS.DISABLED]: {status: userStatuses.DISABLED},
  [TABS.NOT_ACTIVE]: {isActive: false},
  [TABS.INVITED]: {status: userStatuses.PENDING}
};

class UserList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      users: [],
      total: 0,
      limit: getRowsPerPage(props.match.path),
      offset: 0,
      loggedInUser: null,
      userIdToReinvite: "",
      userIdToCancelInvitation: "",
      isSendingEmail: false,
    };
  }

  componentDidMount() {
    const {limit, offset} = this.state;

    this.getAllUsers({limit, offset});
    this.getLoggedInUser();
  }

  componentDidUpdate(prevProps) {
    const location = this.props.location;
    const prevLocation = prevProps.location;
    const {limit} = this.state;

    if(prevLocation.search === location.search) return;

    const params = {limit, offset: 0};

    this.getAllUsers(params);
  }

  componentWillUnmount() {
    this.setState({
      isLoaded: false,
      users: [],
      total: 0,
      offset: 0,
      loggedInUser: null
    });
  }

  // Cancel any in-progress requests
  // Load new data and update profileOrError
  getLoggedInUser = () => {
    userService.getUserInfo().then( (loggedInUser) => this.setState({loggedInUser}));
  };

  getAllUsers = (params) => {
    this.setState({isLoaded: false});

    const location = this.props.location;
    const currentTab = qs.parse(tail(location.search)).status || TABS.ALL;

    userService.listAll({...params, ...QUERY_BY_TAB[currentTab]})
      .then((data) => {
        const users = data.items.map((user) => {
          return {...user, checked: false};
        });
        this.setState({isLoaded: true, users, offset: params.offset, limit: params.limit, total: data.total});
      },
      (error) => {
        this.setState({isLoaded: true, error});
      });
  };

  handleChangePage = (offset) => {
    const {limit} = this.state;

    this.getAllUsers({limit, offset});
  };

  handleChangeRowsPerPage = (limit) => {
    setRowsPerPage(this.props.match.path, limit);
    const {offset} = this.state;

    this.getAllUsers({limit, offset});
  };

  reinviteUser = async () => {
    await userService.inviteAgain(this.state.userIdToReinvite);
    this.props.NotificationStore.showSuccess("Invitation was sent! It will be valid for another 2 days.");
    this.setState({userIdToReinvite: ""});
  };

  cancelInvitation = async () => {
    await userService.delete([this.state.userIdToCancelInvitation]);
    this.props.NotificationStore.showSuccess("Invitation was canceled!");
    this.setState({userIdToCancelInvitation: ""});
    const {offset, limit} = this.state;
    this.getAllUsers({limit, offset});
  };

  render() {
    return this.content();
  }

  getRedirectRoute(userId) {
    const {UserStore} = this.props;

    return UserStore.user.data._id === userId ? "/account/" : `/admin/users/${userId}`;
  }
  
  async resetPassword(e, username) {
    const {NotificationStore} = this.props;

    e.stopPropagation();
    this.setState({isSendingEmail: true});
    await userService.getRecoveryToken({username});

    NotificationStore.showSuccess("Password reset email sent!");
    this.setState({isSendingEmail: false});
  }

  content() {
    const {classes, location, UserStore} = this.props;
    const {users, total, offset, limit, isLoaded, userIdToReinvite, userIdToCancelInvitation, isSendingEmail} = this.state;

    const currentTab = qs.parse(tail(location.search)).status || TABS.ALL;

    const currentUser = UserStore.user.data;

    const isAdmin = currentUser.role === ROLES.ADMIN;

    return (
      <>
        <TableNavigation
          titles={TABS_TITLES}
          views={keys(TABS)}
          currentView={currentTab}
          link="/admin/users"
          query="status"
        />
        <Paper className={classes.root}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                {this.state.loggedInUser && this.state.loggedInUser.company.modules[0].name === "Super Admin" ? <TableCell>Company</TableCell> : null}
                <TableCell>Logins</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last login</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {isLoaded ? (
              <TableBody>
                {users.map((user) => {
                  const registered = user.status !== userStatuses.PENDING;

                  const redirect = this.getRedirectRoute(user._id);

                  return (
                    <TableRow key={`userlist${user._id}`}>
                      <TableCell>
                        <Button
                          size="small"
                          component={registered ? Link : "span"}
                          to={redirect}
                          className={classNames(
                            classes.avatarAndName, {
                              [classes.disabledLink]: !registered,
                            })
                          }
                        >
                          <Avatar user={user} className={classes.avatar} module={MODULES.PRODUCER} />
                          <span>
                            {user.username}
                            <span data-sentry-mask>
                              {registered ? ` | ${user.firstname} ${user.lastname}` : null}
                            </span>
                          </span>
                        </Button>
                      </TableCell>
                      {this.state.loggedInUser && this.state.loggedInUser.company.modules[0].name === "Super Admin" ? <TableCell>
                        <Button size="small" component={Link} to={`/companies/${user.company}`}>
                          {user.company}
                        </Button>
                      </TableCell> :
                        null}
                      <TableCell>{user.loginCount || 0}</TableCell>
                      <TableCell>{moment(user.createdAt).format(DATE_TIME_FORMAT)}</TableCell>
                      <TableCell>{user.lastLogin ? moment(user.lastLogin).format(DATE_TIME_FORMAT) : "Never logged in before"}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>
                        <UserStatus status={user.status} />
                      </TableCell>
                      <TableCell>
                        <ActionCell>
                          {!registered ? (
                            <>
                              <MenuItem
                                onClick={() => this.setState({userIdToReinvite: user._id})}
                              >
                                Re-send invite
                              </MenuItem>
                              <MenuItem
                                className={classes.cancelButton}
                                onClick={() => this.setState({userIdToCancelInvitation: user._id})}
                              >
                                Cancel invite
                              </MenuItem>
                            </>
                          ) : (
                            <>
                              <MenuItem
                                onClick={() => window.open(redirect, "_self")}
                              >
                              View
                              </MenuItem>
                              {(isAdmin && user._id !== currentUser._id) && (
                                <MenuItem
                                  disabled={isSendingEmail}
                                  onClick={(e) => this.resetPassword(e, user.username)}
                                >
                                  Reset password
                                </MenuItem>
                              )}
                            </>
                          )}
                        </ActionCell>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!users.length && (
                  <TableRow>
                    <TableCell colSpan={8}>No data.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableSkeleton columns={7}/>
              </TableBody>
            )}
            <TableFooter
              isLoaded={isLoaded}
              items={users}
              total={total}
              limit={limit}
              offset={offset}
              onOffsetChange={this.handleChangePage}
              onLimitChange={this.handleChangeRowsPerPage}
            />
          </Table>
        </Paper>
        <Confirmation
          open={userIdToReinvite}
          alertText="Are you sure you want to re-send the invite? It will be valid for another 2 days."
          onCancel={() => this.setState({userIdToReinvite: ""})}
          onConfirm={this.reinviteUser}
        />
        <Confirmation
          open={userIdToCancelInvitation}
          alertText="Are you sure you want to cancel the invite?"
          onCancel={() => this.setState({userIdToCancelInvitation: ""})}
          onConfirm={this.cancelInvitation}
        />
      </>
    );
  }
}

export default compose(
  inject("NotificationStore", "UserStore"),
  withRouter
)(withStyles(observer(UserList), styles));
