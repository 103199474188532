import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Grid, Button, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {inject, observer} from "mobx-react";
import UserList from "./components/UserList";
import styles from "./components/UserList/styles.js";
import {compose} from "ramda";

class UserOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      users: [],
    };

  }

  handleRowClick = (event, id) => {
    this.setState({
      redirect: true,
      redirect_id: id
    });

  };
  content() {
    return (
      <div className="content padded-container">
        <Grid container alignItems="center" spacing={3}>
          <Grid container item spacing={3}>
            <Grid item>
              <Typography variant="h4" fontSize="1.8rem">
                Users
              </Typography>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to="/admin/users/new"
                variant="contained"
                color="primary"
              >
                Create user
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to="/admin/users/invite"
                variant="contained"
                color="primary"
              >
                Invite User
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <UserList />
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      this.state.isLoaded ? this.content() : null
    );
  }
}

export default compose(
  inject("NotificationStore"),
)(withStyles(observer(UserOverview), styles));
