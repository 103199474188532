export default (theme) => ({
  cardTitle: {
    "& h1": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    marginBottom: 5,
  },
  testViewContainer: {
    position: "relative",
    backgroundColor: "#fff",
    padding: 40,
  },
  testId: {
    cursor: "pointer"
  },
  testAlert: {
    color: "#f44336",
    textAlign: "right",
    font: "500 18px/18px",
    textTransform: "uppercase"
  },
  inspectorFiles: {
    cursor: "pointer",
    color: "#2d88d1",
    textDecoration: "underline",
  },
  inspectorNotes: {
    "& h4": {
      marginRight: 10,
    },
    "& p": {
      color: "#616161",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  testResults: {
    padding: 20,
    height: "100%",
    borderBottom: "1px solid #e0e0e0",
  },
});
