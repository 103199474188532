import React from "react";
import {TableSortLabel, TableCell} from "@mui/material";
import {ORDERS} from "@core/constants/sort";

const SortableCell = ({order, orderBy, id, setOrderBy, setOrder, children}) => {
  return (
    <TableCell
      sortDirection={orderBy === id ? order : false}
    >
      <TableSortLabel
        active={orderBy === id}
        direction={order}
        onClick={() => {
          setOrder((orderBy === id && order === ORDERS.DESC) ? ORDERS.ASC : ORDERS.DESC);

          if(setOrderBy) setOrderBy(id);
        }}
      >
        <span>{children}</span>
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableCell;