import React, {useLayoutEffect, useMemo, useEffect, useRef, useCallback} from "react";
import {partition} from "ramda";
import qs from "qs";
import {observer} from "mobx-react-lite";
import {useWindowVirtualizer} from "@tanstack/react-virtual";
import {Box, Skeleton, useTheme} from "@mui/material";
import {useLocation} from "react-router-dom";
import ScrollToTop from "@core/components/ScrollToTop";
import TimelineToggle from "@core/components/TimelineToggle";
import Test from "@core/components/Test";
import BlockHeader from "../../../BlockHeader";
import WS from "@core/api/socketConnection";
import {CUSTOM_TEST_TYPES, STATUSES, TYPES} from "@core/constants/test";
import {sortTestsByType} from "@core/helpers/tests";
import modules from "@core/constants/modules";
import {TestService} from "@core/services";
import {VIEWS} from "../../../../constants";
import {TRANSACTION_TYPES} from "@core/constants/transactions";
import useStores from "../../../../../../useStores";

const ESTIMATED_TEST_HEIGHT = 700;

function easeInOutQuint(t) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

// base on smooth scroll example from v2 https://github.com/TanStack/virtual/blob/main/examples/smooth-scroll/src/main.jsx
const useSmoothScroll = (virtualizer) => {
  const scrollingRef = React.useRef(0);

  return useCallback(
    (
      index,
      {
        align: initialAlign,
        duration = 1000
      }
    ) => {
      const start = virtualizer.scrollOffset;
      const startTime = (scrollingRef.current = Date.now());
      const [, align] = virtualizer.getOffsetForIndex(index, initialAlign);

      const run = () => {
        if (scrollingRef.current !== startTime) return;

        const now = Date.now();
        const elapsed = now - startTime;
        const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
        const [offset] = virtualizer.getOffsetForIndex(index, align);
        const interpolated = start + (offset - start) * progress;

        if (elapsed < duration) {
          virtualizer.scrollToOffset(interpolated, {align: "start"});
          requestAnimationFrame(run);
        } else {
          virtualizer.scrollToOffset(interpolated, {align: "start"});
        }
      };

      requestAnimationFrame(run);
    },
    [virtualizer]
  );
};

const Tests = observer(({id, label, tests}) => {
  const {TestStore, NotificationStore, SigningStore, CampaignStore, UserStore, TimelineStore} = useStores();
  const location = useLocation();
  const parentRef = useRef(null);
  const parentOffsetRef = useRef(0);

  const theme = useTheme();

  const campaign = CampaignStore.campaign;
  const [module] = UserStore.user.data.company.modules;
  const userId = UserStore.user.data._id;

  const {testId: activeTestId, view = VIEWS.REPORT} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const [newTests, filledTests] = useMemo(() => partition((test) => test.status === STATUSES.EMPTY, tests), [tests]);
  const [testsWithTags, restFilledTests] = useMemo(() => partition((test) => test.properties?.tags?.length, filledTests), [filledTests]);

  const sorted = useMemo(() => [
    ...sortTestsByType(newTests),
    ...sortTestsByType(testsWithTags),
    ...sortTestsByType(restFilledTests),
  ], [newTests, testsWithTags, restFilledTests]);

  const retestsByOriginalTestId = useMemo(() => {
    return sorted.reduce((acc, test) => {
      acc[test._id] = test.retest.map(({testId: test}) => test._id);

      return acc;
    }, {});
  }, [sorted.length]);

  const virtualizer = useWindowVirtualizer({
    count: sorted.length,
    estimateSize: useCallback(() => ESTIMATED_TEST_HEIGHT, []),
    scrollMargin: parentOffsetRef.current,
    overscan: 2,
    scrollPaddingEnd: parseFloat(theme.spacing(2.5)),
    scrollPaddingStart: parseFloat(theme.spacing(2.5)),
  });

  const smoothScrollToIndex = useSmoothScroll(virtualizer);

  useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  useEffect(() => {
    if(!activeTestId) return;

    const testIndex = sorted.findIndex((test) => {
      return test._id === activeTestId ||
        test.displayName === activeTestId ||
        retestsByOriginalTestId[test._id].includes(activeTestId);
    });
    smoothScrollToIndex(testIndex, {align: "start"});
  }, [activeTestId]);

  useEffect(() => {
    if(view === VIEWS.GLOBAL) TimelineStore.setOpen(false);
  }, [view]);

  useEffect(() => {
    WS.listen("transaction:test:assign", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINE") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      CampaignStore.getCampaignById(campaign._id);
      NotificationStore.showSuccess("Successfully assigned!");
    });

    WS.listen("transaction:test:approve", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINE") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      CampaignStore.getCampaignById(campaign._id);
      NotificationStore.showSuccess("Successfully approved!");
    });

    WS.listen("transaction:test:witnessRequest", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINE") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      CampaignStore.getCampaignById(campaign._id);
      NotificationStore.showSuccess("Successfully assigned!");
    });
  }, []);

  useEffect(() => {
    return () => {
      WS.remove("transaction:test:assign");
      WS.remove("transaction:test:approve");
      WS.remove("transaction:test:witnessRequest");
    };
  }, []);

  const onTestUpdate = async (testId, changes) => {
    await TestStore.update(changes, testId);

    const tests = campaign.tests.map((test) => test._id === testId ? {...test, ...changes} : test);

    CampaignStore.updateCampaign({tests});
  };

  const removeWelds = useCallback(async (test) => {
    if(test.type !== TYPES.CUSTOM || test.displayName !== CUSTOM_TEST_TYPES.AS_BUILD_RECORD) return;

    const specimensToRemove = test.properties.activities.map((activity) => activity.specimen);

    if(!specimensToRemove.length) return;

    const [weldsToStay, weldsToRemove] = partition((weld) => !specimensToRemove.includes(weld.weldNumber), campaign.welds);

    await CampaignStore.updateCampaignById(campaign._id, {welds: weldsToStay.map((weld) => weld._id)});
    await CampaignStore.removeWelds(campaign._id, weldsToRemove.map((weld) => weld._id));
  }, [campaign._id, campaign.welds]);

  const deleteTest = useCallback(async (testId) => {
    const test = campaign.tests.find((t) => t._id === testId);

    await CampaignStore.deleteTest(campaign._id, testId);
    await TestStore.delete(testId);

    NotificationStore.showSuccess("Successfully deleted!");

    await removeWelds(test);
  }, [campaign.tests, campaign._id]);

  const items = virtualizer.getVirtualItems();

  return (
    <>
      <BlockHeader
        id={id}
      >
        {label}
      </BlockHeader>
      <div ref={parentRef}>
        <Box
          sx={{
            height: virtualizer.getTotalSize(),
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${
                items[0].start - virtualizer.options.scrollMargin
              }px)`,
            }}
          >
            {items.map((virtualRow) => {
              const test = sorted[virtualRow.index];

              const isLoading = virtualRow.index < virtualizer.range.startIndex ||
                virtualRow.index > virtualizer.range.endIndex;

              return (
                <Box
                  sx={{
                    marginBottom: theme.spacing(2.5),
                  }}
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                >
                  {isLoading ? (
                    <Skeleton
                      sx={{
                        height: virtualizer.itemSizeCache.get(virtualRow.index) || ESTIMATED_TEST_HEIGHT,
                        transform: "unset"
                      }}
                      component="div"
                      width="100%"
                    />
                  ) : (
                    <Test
                      test={test}
                      isLast={virtualRow.index === tests.length - 1}
                      isFirst={!virtualRow.index}
                      isProducer={module.name === modules.PRODUCER}
                      onTestUpdate={(changes) => onTestUpdate(test._id, changes)}
                      approveTest={(test) => TestService.approve([test], campaign, userId, TRANSACTION_TYPES.CAMPAIGN)}
                      assignInspector={() => TestService.assignInspector([test], {[test._id]: campaign}, TRANSACTION_TYPES.CAMPAIGN)}
                      deleteTest={deleteTest}
                      disabled={module.name !== modules.PRODUCER}
                      certificate={campaign}
                      shareLink
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </div>
      {(!!tests.length && view === VIEWS.REPORT) && (
        <TimelineToggle
          refTest={parentRef}
        />
      )}
      <ScrollToTop />
    </>
  );
});

export default Tests;