import React, {useCallback, useEffect, useMemo, useRef} from "react";
import qs from "qs";
import {useLocation, useParams, useHistory} from "react-router";
import {Box} from "@mui/material";
import Test from "@core/components/Test";
import TimelineToggle from "@core/components/TimelineToggle";
import modules from "@core/constants/modules";
import {TRANSACTION_TYPES} from "@core/constants/transactions";
import {TestService} from "@core/services";
import BlockHeader from "../../../../../../../BlockHeader";
import useStores from "../../../../../../../../../../useStores";
const TestReports = ({tests}) => {
  const {id: campaignId} = useParams();
  const location = useLocation();
  const history = useHistory();

  const {CampaignStore, UserStore, TestStore, NotificationStore} = useStores();
  
  const container = useRef();
  const testsRefs = useRef({});

  const campaign = CampaignStore.campaign;
  const [module] = UserStore.user.data.company.modules;
  const userId = UserStore.user.data._id;

  const isLoaded = campaignId === campaign._id;

  const {testId: activeTestId, ...search} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  useEffect(() => {
    if(!activeTestId || !testsRefs[activeTestId]) return;

    testsRefs[activeTestId].scrollIntoView({behavior: "smooth", block: "start"});

    history.push({
      location: location.pathname,
      search: qs.stringify(search),
    });
  }, [activeTestId]);

  const onTestUpdate = async (testId, changes) => {
    await TestStore.update(changes, testId);

    const tests = campaign.tests.map((test) => test._id === testId ? {...test, ...changes} : test);

    CampaignStore.updateCampaign({tests});
  };

  const deleteTest = useCallback(async (testId) => {
    await CampaignStore.deleteTest(campaign._id, testId);
    await TestStore.delete(testId);

    NotificationStore.showSuccess("Successfully deleted!");
  }, [campaign._id]);
  
  if(!isLoaded || !tests.length) return null;

  return (
    <Box
      sx={{
        marginTop: (theme) => theme.spacing(3.5),
      }}
    >
      <BlockHeader>
        Field Joint Coating Test Reports
      </BlockHeader>
      <div ref={container}>
        {tests.map((test, index) => (
          <Box
            sx={{
              marginBottom: (theme) => theme.spacing(2.5),
            }}
            key={test._id}
            ref={(ref) => testsRefs[test._id] = ref}
          >
            <Test
              test={test}
              isLast={index === tests.length - 1}
              isFirst={!index}
              isProducer={module.name === modules.PRODUCER}
              onTestUpdate={(changes) => onTestUpdate(test._id, changes)}
              approveTest={(test) => TestService.approve([test], campaign, userId, TRANSACTION_TYPES.CAMPAIGN)}
              assignInspector={() => TestService.assignInspector([test], {[test._id]: campaign}, TRANSACTION_TYPES.CAMPAIGN)}
              deleteTest={deleteTest}
              disabled={module.name !== modules.PRODUCER}
              certificate={campaign}
              shareLink
            />
          </Box>
        ))}
      </div>
      <TimelineToggle
        refTest={container}
      />
    </Box>
  );
};

export default TestReports;