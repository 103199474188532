import React from "react";
import {FieldArray} from "formik";
import {Table, TableBody, TableCell, TableHead, Grid, TableRow, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {MdAdd} from "react-icons/md/";
import styles from "../styles";
import Approval from "./Approval";
import classNames from "classnames";
import {append} from "ramda";

const Partners = ({classes, disabled, onSubmit}) => (
  <>
    <h4 className={classes.label}>Approvals</h4>
    <FieldArray name="approvals">
      {({remove, push, replace, form}) => {
        const {setFieldValue, values, setFieldTouched, touched, errors, handleChange, initialValues} = form;

        const onSave = async (index, approval) => {
          if(approval.editable && initialValues.name) {
            const approvals = initialValues.approvals[index] ?
              initialValues.approvals.map((l, idx) => idx === index ? approval : l) :
              append(approval,  initialValues.approvals);
            await onSubmit({...initialValues, approvals});
          }

          replace(index, {...approval, editable: !approval.editable});
        };

        return (
          <Grid container spacing={2} className={classes.mb25}>
            <Grid item xs={12}>
              <Table className={classNames("styled-table",classes.table)}>
                <TableHead>
                  <TableCell>Standard</TableCell>
                  <TableCell>Issuing body</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Issuing date</TableCell>
                  <TableCell>Expiration date</TableCell>
                  <TableCell>PDF File</TableCell>
                  <TableCell>Actions</TableCell>
                </TableHead>
                <TableBody>
                  {values.approvals && values.approvals.map((approval, index) =>(
                    <Approval
                      index={index}
                      disabled={disabled}
                      values={{
                        standard: {value: approval.standard, path: `approvals.${index}.standard`},
                        issuingBody: {value: approval.issuingBody, path: `approvals.${index}.issuingBody`},
                        description: {value: approval.description, path: `approvals.${index}.description`},
                        from: {value: approval.from, path: `approvals.${index}.from`},
                        to: {value: approval.to, path: `approvals.${index}.to`},
                        link: {value: approval.link, path: `approvals.${index}.link`}
                      }}
                      actions={{
                        change: (fieldName, e) => {
                          handleChange(e);
                          setFieldTouched(fieldName, true, false);
                        },
                        uploadImage: (fieldName, e) => setFieldValue(fieldName, e.file.dir + e.file.name),
                        resetPreviewImage: (fieldName) => setFieldValue(fieldName, ""),
                        setFieldTouched,
                        blur: (path, field) => setFieldValue(path, values.approvals[index][field].trim()),
                        save: () => onSave(index, approval),
                        remove: () => remove(index)
                      }}
                      editable={approval.editable}
                      touched={touched.approvals || {}}
                      errors={errors.approvals || {}}
                    />
                  ))}
                  <TableRow>
                    <TableCell colSpan={7}>
                      <div className={classes.loaderContainer}>
                        <Button
                          onClick={() => {
                            push({
                              standard:"",
                              issuingBody: "",
                              description: "",
                              from: null,
                              to: null,
                              link: "",
                              editable: true
                            });
                          }}
                          disabled={disabled}
                        >
                          <MdAdd size={80} /> Add New
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  </>
);

export default withStyles(Partners, styles);