import React, {Fragment} from "react";
import {Button, Grid, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {omit} from "ramda";
import {Formik} from "formik";
import * as yup from "yup";
import {TEST_RESULTS} from "@core/constants/testResults";
import {isAcceptable} from "@core/helpers/tests";
import {Input, Select, MultipleSelect} from "@core/components/Form";
import {acceptances, norms, techniques, sensitivities, surfaceConditions} from "./data";
import IdentificationOfInspectedItems from "../../IdentificationOfInspectedItems";
import styles from "./styles";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TextField from "@core/components/FormikTextField";
import MuiSelect from "@core/components/MuiSelect";
import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";

const UltrasonicTest = ({
  classes,
  user,
  test,
  saveTest,
  closeNewTest,
  isProducerTest,
  client,
  inspectedCertificates,
  formRef,
  ...props
}) => {
  const validationSchema = yup.object().shape({
    client: !isProducerTest ? yup.string().required("This field is required!") : yup.string(),
    lab: !isProducerTest ? yup.string().required("This field is required!") : yup.string(),
    testStandard: yup.string().required("Field is required!"),
    acceptance: yup.string().required("Field is required!"),
    surfacePreparation: yup.string(),
    surfaceRoughness: yup.string(),
    result: yup.string().required("Field is required"),
    examination: yup.object().shape({
      speed: yup.string(),
      gel: yup.string(),
      overlap: yup.string(),
    }),
    elements: yup.array().of(yup.object().shape({
      technique: yup.object().shape({
        technique: yup.string(),
        sensitivity: yup.string(),
        volume: yup.array(),
      }),
      probes: yup.object().shape({
        waves: yup.string(),
        angle: yup.string(),
        model: yup.string(),
        size: yup.string(),
        frequency: yup.string(),
        calibration: yup.string(),
      }),
    })),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
  });

  const initialElementValues = {
    technique: {
      technique: "",
      sensitivity: "",
      volume: []
    },
    probes: {
      waves: "",
      angle: "",
      model: "",
      size: "",
      frequency: "",
      calibration: ""
    },
  };

  const initialValues = {
    client: !isProducerTest ? (test?.properties.client || client.name || "") : "",
    lab: !isProducerTest ? (test?.properties.lab || user.company.name || "") : "",
    testStandard: test?.properties.testStandard || "",
    acceptance: test?.properties.acceptance || "",
    surfacePreparation: test?.properties.surface_preparation || test?.properties.surfacePreparation || "",
    surfaceRoughness: test?.properties.surfaceRoughness || "",
    result: test?.properties.result || "",
    notes: test?.properties.notes || "",
    examination: test?.properties.examination || {
      speed: (test?.properties.examination && test.properties.examination.speed) || "100-150",
      gel: (test?.properties.examination && test.properties.examination.gel) || "",
      overlap: (test?.properties.examination && test.properties.examination.overlap) || "15",
    },
    elements: test?.properties.elements || [{
      technique: test?.properties.technique || {
        technique: (test?.properties.technique && test.properties.technique.technique) || "",
        sensitivity: (test?.properties.technique && test.properties.technique.sensitivity) || "",
        volume: (test?.properties.technique && test.properties.technique.volume) || [],
      },
      probes: test?.properties.probes || {
        waves: (test?.properties.probes && test.properties.probes.waves) || "",
        angle: (test?.properties.probes && test.properties.probes.angle) || "",
        model: (test?.properties.probes && test.properties.probes.model) || "",
        size: (test?.properties.probes && test.properties.probes.size) || "",
        frequency: (test?.properties.probes && test.properties.probes.frequency) || "",
        calibration: (test?.properties.probes && test.properties.probes.calibration) || "",
      }
    }],
    witnesses: test?.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || []
  };

  const filteredAcceptances = Object.keys(acceptances).reduce(function (r, e) {
    if (!acceptances[e].hasOwnProperty("company") || acceptances[e].company.includes(user?.company.name)) r[e] = acceptances[e];

    return r;
  }, {});

  const onSubmit = (values) => {
    if (isProducerTest) saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    else saveTest(values);

    if (!closeNewTest) return;

    props.closeNewTest();
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
        render={({
          values: {
            acceptance,
            surfacePreparation,
            surfaceRoughness,
            result,
            examination: {speed, gel, overlap},
            elements,
            notes,
          },
          errors,
          handleChange,
          touched,
          setFieldTouched,
          setFieldValue
        }) => {
          const change = (name, event) => {
            handleChange(event);
            setFieldTouched(name, true, false);
          };

          const changeMultiple = (name, event) => {
            setFieldValue(name, event);
            setFieldTouched(name, true, false);
          };

          const changeWaves = (name, event, index) => {
            handleChange(event);

            if (event.target.value === "Straight beam") setFieldValue(`elements[${index}].probes.angle`, "0");

            setFieldTouched(name, true, false);
          };

          const changeElements = (newElement, index) => {
            if (index) {
              elements.splice(index, 1);
              setFieldValue("elements", elements);
            }
            else {
              elements.push(newElement);
              setFieldValue("elements", elements);
            }
          };

          const acceptable = isAcceptable(result);

          return (
            <>
              {isProducerTest && (
                <Grid container spacing={5} marginBottom={4}>
                  <Grid item xs={12}>
                    <h1>Ultrasonic test</h1>
                  </Grid>
                </Grid>
              )}
              {!isProducerTest && (
                <Grid item container spacing={5} marginBottom={2}>
                  <Grid item xs={3}>
                    <ClientField isFromProducer={!!client.name} />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled
                      name="lab"
                      label="Laboratory"
                      required
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={5} marginBottom={2}>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <MuiSelect
                    required
                    label="Test standard"
                    name="testStandard"
                    defaultOptions={norms}
                  />
                </Grid>

                <Grid item xs={isProducerTest ? 8 : 6}>
                  <Select
                    value={acceptance}
                    name='acceptance'
                    label='Acceptance Criteria'
                    required
                    error={Boolean(errors.acceptance) && touched.acceptance}
                    onChange={(event) => change("acceptance", event)}
                  >
                    {Object.keys(filteredAcceptances).map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                  </Select>
                </Grid>

              </Grid>

              <Grid container spacing={5} marginBottom={2}>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Select
                    name="surfacePreparation"
                    value={surfacePreparation}
                    required
                    error={Boolean(errors.surfacePreparation) && touched.surfacePreparation}
                    label="Surface Condition"
                    onChange={(event) => change("surfacePreparation", event)}
                    onBlur={() => setFieldValue("surfacePreparation", surfacePreparation.trim())}
                  >
                    {surfaceConditions.map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Input
                    name="surfaceRoughness"
                    value={surfaceRoughness}
                    label="Surface Roughness"
                    endAdornment="μm"
                    onChange={(event) => change("surfaceRoughness", event)}
                    onBlur={() => setFieldValue("surfaceRoughness", surfaceRoughness.trim())}
                  />
                </Grid>

              </Grid>

              <IdentificationOfInspectedItems
                isProducerTest={isProducerTest}
                inspectedCertificates={inspectedCertificates}
                test={test}
              />

              <Grid container spacing={5} marginBottom={2}>
                <Grid item xs={12} className={classes.title}>
                  <h3>Examination conditions</h3>
                </Grid>

                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Input
                    name="examination.speed"
                    value={speed}
                    label="Scanning speed"
                    endAdornment="mm/s"
                    onChange={(event) => change("examination.speed", event)}
                    onBlur={() => setFieldValue("examination.speed", speed.trim())}
                  />
                </Grid>

                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Input
                    name="examination.gel"
                    label="Couplant gel"
                    value={gel}
                    onChange={(event) => change("examination.gel", event)}
                    onBlur={() => setFieldValue("examination.gel", gel.trim())}
                  />
                </Grid>

                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Input
                    name="examination.overlap"
                    value={overlap}
                    label="Scanning overlap minimum"
                    endAdornment="%"
                    onChange={(event) => change("examination.overlap", event)}
                    onBlur={() => setFieldValue("examination.overlap", overlap.trim())}
                  />
                </Grid>
              </Grid>

              {elements.map(({technique, probes}, index) => (
                <Fragment key={index}>
                  <Grid container spacing={5} marginBottom={2}>
                    <Grid item xs={12} className={classes.title}>
                      <h3>Examination technique</h3>
                    </Grid>

                    <Grid item xs={isProducerTest ? 4 : 3}>
                      <Select
                        value={technique.technique}
                        name={`elements[${index}].technique.technique`}
                        label="Technique"
                        onChange={(event) => change(`elements[${index}].technique.technique`, event)}
                      >
                        {techniques.map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                      </Select>
                    </Grid>

                    <Grid item xs={isProducerTest ? 4 : 3}>
                      <Select
                        value={technique.sensitivity}
                        name={`elements[${index}].technique.sensitivity`}
                        label="Sensitivity"
                        onChange={(event) => change(`elements[${index}].technique.sensitivity`, event)}
                      >
                        {sensitivities.map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                      </Select>
                    </Grid>

                    <Grid item xs={isProducerTest ? 4 : 3} className={isProducerTest ? classes.mt3 : classes.mt8}>
                      <MultipleSelect
                        value={technique.volume}
                        label="Scanned volume"
                        elements={["100% of volume", "Welds", "Weld seam", "Bevel ends", "Weld overlay", "150 mm from ends"]}
                        onChange={(event) => changeMultiple(`elements[${index}].technique.volume`, event)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} marginBottom={2}>
                    <Grid item xs={12} className={classes.title}>
                      <h3>Probes and Calibration</h3>
                    </Grid>

                    <Grid item xs={isProducerTest ? 3 : 3}>
                      <Select
                        value={probes.waves}
                        name={`elements[${index}].probes.waves`}
                        label="Waves"
                        onChange={(event) => changeWaves(`elements[${index}].probes.waves`, event, index)}
                      >
                        {["Straight beam", "Angle beam"].map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                      </Select>
                    </Grid>

                    <Grid item xs={isProducerTest ? 3 : 3}>
                      <Input
                        name={`elements[${index}].probes.angle`}
                        value={probes.waves === "Straight beam" ? 0 : probes.angle}
                        endAdornment="°"
                        label="Angle"
                        onChange={(event) => change(`elements[${index}].probes.angle`, event)}
                        onBlur={() => setFieldValue(`elements[${index}].probes.angle`, probes.angle.trim())}
                      />
                    </Grid>

                    <Grid item xs={isProducerTest ? 3 : 3}>
                      <Input
                        name={`elements[${index}].probes.model`}
                        value={probes.model}
                        label="Brand and model"
                        onChange={(event) => change(`elements[${index}].probes.model`, event)}
                        onBlur={() => setFieldValue(`elements[${index}].probes.model`, probes.model.trim())}
                      />
                    </Grid>

                    <Grid item xs={isProducerTest ? 3 : 3}>
                      <Input
                        name={`elements[${index}].probes.size`}
                        value={probes.size}
                        endAdornment="mm"
                        label="Size"
                        onChange={(event) => change(`elements[${index}].probes.size`, event)}
                        onBlur={() => setFieldValue(`elements[${index}].probes.size`, probes.size.trim())}
                      />
                    </Grid>

                    <Grid item xs={isProducerTest ? 3 : 3} className={classes.pt0}>
                      <Input
                        name={`elements[${index}].probes.frequency`}
                        value={probes.frequency}
                        label="Frequency"
                        endAdornment="MHz"
                        onChange={(event) => change(`elements[${index}].probes.frequency`, event)}
                        onBlur={() => setFieldValue(`elements[${index}].probes.frequency`, probes.frequency.trim())}
                      />
                    </Grid>

                    <Grid item xs={isProducerTest ? 6 : 6} className={classes.pt0}>
                      <Input
                        name={`elements[${index}].probes.calibration`}
                        value={probes.calibration}
                        label="Calibration"
                        onChange={(event) => change(`elements[${index}].probes.calibration`, event)}
                        onBlur={() => setFieldValue(`elements[${index}].probes.calibration`, probes.calibration.trim())}
                      />
                    </Grid>

                    <Grid item xs={isProducerTest ? 2 : 1} className={classes.secondaryButton}>
                      {index === 0 ? (
                        <Button
                          size="large"
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => changeElements(initialElementValues)}>
                          Add
                        </Button>
                      ) : (
                        <Button
                          size="large"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => changeElements("", index)}>
                            Remove
                        </Button>
                      )}
                    </Grid>

                    <Grid container>
                      <Grid item xs={12}>
                        <div className={classes.divider} />
                      </Grid>
                    </Grid>

                  </Grid>
                </Fragment>
              ))}

              <Grid container spacing={5} marginBottom={2}>
                <Grid item xs={isProducerTest ? 8 : 6}>
                  <Select
                    value={result}
                    name='result'
                    label='Result'
                    required
                    onChange={(event) => change("result", event)}
                  >
                    <MenuItem value={TEST_RESULTS.NO_INDICATION}>{TEST_RESULTS.NO_INDICATION}</MenuItem>
                    <MenuItem value={TEST_RESULTS.ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.ACCEPTABLE_INDICATIONS}</MenuItem>
                    <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.NOT_ACCEPTABLE_INDICATIONS}</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid container spacing={5} marginBottom={2}>
                <Grid item xs={isProducerTest ? 8 : 6}>
                  <Input
                    label="Notes"
                    rows={4}
                    multiline
                    value={notes}
                    name="notes"
                    onChange={(event) => change("notes", event)}
                    onBlur={() => setFieldValue("notes", notes.trim())}
                  />
                </Grid>
              </Grid>

              {isProducerTest ? (
                <ProducerTestFooter />
              ) : (
                <LabTestFooter
                  onSubmit={onSubmit}
                  result={acceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE}
                />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default withStyles(UltrasonicTest, styles);
