import React, {useMemo, useEffect} from "react";
import TextField from "@core/components/FormikTextField";
import PorousCalculationForm from "../PorousCalculationForm";
import {FIELDS_LABELS_BY_FIELD_NAME} from "./constants";
import {getDensity} from "../../../../helpers";
import {useFormikContext} from "formik";
import {Checkbox, FormControlLabel, Grid} from "@mui/material";

const GreenDensityForm = () => {
  const {values, setFieldValue} = useFormikContext();

  const c = useMemo(() => {
    if(values.massOfOilImpregnatedPart && values.massOfOilImpregnatedSupportPart && values.massOfOilImpregnatedSupportInWater) {
      return Number(values.massOfOilImpregnatedPart) - (Number(values.massOfOilImpregnatedSupportPart) - Number(values.massOfOilImpregnatedSupportInWater));
    }

    if(values.massOfOilImpregnatedPart && values.massOfOilImpregnatedPartInWater) {
      return Number(values.massOfOilImpregnatedPart) - Number(values.massOfOilImpregnatedPartInWater);
    }

    return Number(values.massOfOilImpregnatedSupportPart) - Number(values.massOfOilImpregnatedSupportInWater);
  }, [values.massOfOilImpregnatedPart, values.massOfOilImpregnatedSupportPart, values.massOfOilImpregnatedSupportInWater, values.massOfOilImpregnatedPart, values.massOfOilImpregnatedPartInWater, values.massOfOilImpregnatedSupportPart, values.massOfOilImpregnatedSupportInWater]);

  const density = useMemo(() => {
    return getDensity(values.massOfItemInAir, values.densityOfWater, c);
  }, [values.massOfItemInAir, values.densityOfWater, c]);

  useEffect(() => {
    setFieldValue("density", density);
  }, [density]);

  return (
    <>
      <Grid item container spacing={2}>
        <Grid item xs={3} alignSelf="flex-end">
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={values.partImpregnated}
                onClick={(e) => setFieldValue("partImpregnated", e.target.checked)}
              />
            )}
            label="Part impregnated"
          />
        </Grid>
        {values.partImpregnated && (
          <Grid item xs={3}>
            <TextField
              required
              name="impregnationMethod"
              label="Impregnation method"
            />
          </Grid>
        )}
      </Grid>
      <PorousCalculationForm
        fieldsLabelsByFieldName={FIELDS_LABELS_BY_FIELD_NAME}
      />
    </>
  );
};

export default GreenDensityForm;