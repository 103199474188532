import React from "react";
import {Box} from "@mui/material";
import {isEmpty, pick} from "ramda";
import GlobalAcceptance from "../GlobalAcceptance";
import AcceptanceOnLocation from "../AcceptanceOnLocation";
import Acceptance from "../Acceptance";

const AcceptancesContent = ({config}) => {
  const globalConfig = pick(["averageShearAreaAllSpecimens"], config);
  const withGlobalConfig = !isEmpty(globalConfig);
  
  return (
    <>
      {withGlobalConfig && (
        <Box sx={{marginTop: 1, marginBottom: 1}}>
          <h3>Global Requirements</h3>
          <GlobalAcceptance
            {...globalConfig}
          />
        </Box>
      )}
      <Box sx={{marginTop: 1}}>
        {withGlobalConfig && <h3>Local Requirements</h3>}
        {config.locationDependantValues ? (
          <AcceptanceOnLocation
            config={config}
            withGlobalConfig={withGlobalConfig}
          />
        ) : (
          <Acceptance
            config={config}
          />
        )}
      </Box>
    </>
  );
};

export default AcceptancesContent;