import React, {useEffect} from "react";
import * as yup from "yup";
import {times, identity, isEmpty} from "ramda";
import {FieldArray, Formik, useFormikContext} from "formik";
import {Grid, MenuItem, Table, TableHead, TableCell, TableBody, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import styles from "./styles";
import {FilesUploader} from "@core/components/Uploaders";

const PIECE_IDENTIFICATIONS = {
  SINGLE_PIECE: "Single piece",
  HEAT_AND_LOT: "Heat and lot"
};

const MultipleIdentificationOfInspectedItems = ({
  classes,
  certificates
}) => {

  const formik = useFormikContext();

  const validationSchema = yup.object().shape({
    items: yup.array().of(yup.object().shape({
      overallQuantityInspected: yup.number().required(),
      quantityInspected: yup.number().when("overallQuantityInspected", (overallQuantityInspected, schema) => {
        return schema
          .positive("Should to be > 0")
          .max(overallQuantityInspected, `Should be < ${overallQuantityInspected}`)
          .typeError("Should be a number!")
          .required("Field is required");
      }),
      examinationConditions: yup.object().required("Field is required")
    })),
    pieceIdentification: yup.string(),
  });

  const initialValues = {
    items: certificates.map((certificate) => {
      const overallQuantityInspected = certificate.items.reduce((acc, curr) => acc + Number(curr.quantity), 0);

      return {
        overallQuantityInspected,
        quantityInspected: overallQuantityInspected,
        examinationConditions: {},
        files: [],
      };
    }),
    pieceIdentification: ""
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(props) => {

        useEffect(() => {
          formik.setErrors({...formik.errors, ...props.errors});
        }, [formik.isValid, props.isValid]);

        useEffect(() => {
          formik.setValues(() => ({...formik.values, ...props.values}));
          formik.setTouched({...formik.touched, ...props.touched});
        }, [props.values.pieceIdentification, props.values.items]);

        const changeExaminationConditions = (pieceIdentification, item, index) => {
          if (pieceIdentification === PIECE_IDENTIFICATIONS.HEAT_AND_LOT) {
            props.setFieldValue(`items.${index}.examinationConditions`, {});
          } else {
            const examinationConditions = times(identity, item.quantityInspected).reduce((acc, index) => {
              acc[index] = "";

              return acc;
            }, {});
            props.setFieldValue(`items.${index}.examinationConditions`, examinationConditions);
          }
        };

        return (
          <>
            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={12} className={classes.title}>
                <h3>Identification of tested items</h3>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none">Product</TableCell>
                      <TableCell padding="none" align="center">Heat</TableCell>
                      <TableCell padding="none" align="center">Lot ID</TableCell>
                      <TableCell padding="none">Quantity of tested items</TableCell>
                      <TableCell padding="none" align="center">Percentage</TableCell>
                      <TableCell padding="none" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="items">
                      {() => props.values.items.map((item, index) => {
                        const percentage = Math.round(item.quantityInspected / item.overallQuantityInspected * 100);
                        const certificate = certificates[index];

                        return (
                          <TableRow key={item.overallQuantityInspected}>
                            <TableCell padding="none" width="10%">{certificate.properties.productType.name}</TableCell>
                            <TableCell padding="none" width="20%" align="center">{certificate.heat}</TableCell>
                            <TableCell padding="none" width="13%" align="center">{certificate.properties.lotId}</TableCell>
                            <TableCell padding="none" width="20%" classes={{root: classes.row}}>
                              <TextField
                                inputProps={{min: 0, max: item.overallQuantityInspected}}
                                type="number"
                                endAdornment="pcs"
                                required
                                name={`items.${index}.quantityInspected`}
                                onChange={() => changeExaminationConditions(props.values.pieceIdentification, item, index)}
                              />
                            </TableCell>
                            <TableCell padding="none" align="center" width="10%">
                              {percentage >= 1 ? `${percentage}%` : "Less than 1%"}
                            </TableCell>
                            <TableCell padding="none" width="17%">
                              <FilesUploader
                                name={`items.${index}.files`}
                                files={item.files}
                                onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                                changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </FieldArray>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={8}>
                <SelectField
                  name="pieceIdentification"
                  label='Piece identification'
                  required
                  onChange={(value) => {
                    props.values.items.forEach((item, index) => changeExaminationConditions(value, item, index));
                  }}
                >
                  <MenuItem key={PIECE_IDENTIFICATIONS.SINGLE_PIECE} value={PIECE_IDENTIFICATIONS.SINGLE_PIECE}>
                    {PIECE_IDENTIFICATIONS.SINGLE_PIECE}
                  </MenuItem>
                  <MenuItem key={PIECE_IDENTIFICATIONS.HEAT_AND_LOT} value={PIECE_IDENTIFICATIONS.HEAT_AND_LOT}>
                    {PIECE_IDENTIFICATIONS.HEAT_AND_LOT}
                  </MenuItem>
                </SelectField>
              </Grid>
              {props.values.pieceIdentification && (
                <FieldArray name="items">
                  {() => props.values.items.map((item, index) => {
                    if (isEmpty(item.examinationConditions)) return null;

                    const certificate = certificates[index];

                    return (
                      <>
                        <Grid item xs={12}>
                          <h3>Heat {certificate.heat} - Lot ID {certificate.properties.lotId}</h3>
                        </Grid>
                        <Grid item container xs={8} spacing={3}>
                          {Object.keys(item.examinationConditions).map((key) => (
                            <Grid key={index} item xs={4}>
                              <TextField
                                name={`items.${index}.examinationConditions.${key}`}
                                label={`Piece No. ${Number(key) + 1}`}
                                required
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    );
                  })}
                </FieldArray>
              )}
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default withStyles(MultipleIdentificationOfInspectedItems, styles);