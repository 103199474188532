export default (theme) => ({
  table : {
    "& td, td:last-child": {
      padding: 4,
    },
    "& th, th:last-child": {
      padding: 5,
    },
    "& tr": {
      height: "initial"
    },
    "& input": {
      padding: "13px 2px 13px 8px"
    }
  },
  hideBorder: {
    borderBottom: 0,
  },
  removeButton: {
    padding: 0,
    "& svg": {
      transform: "scale(0.8)",
    }
  },
  shearAreaAverage: {
    display: "flex",
    alignItems: "center"
  },
  info: {
    color: theme.palette.info.main,
  },
});