import React from "react";
import {Grid, Box, useTheme} from "@mui/material";
import {flatten, splitAt} from "ramda";

const LegendWrapper = ({children}) => {
  const theme = useTheme();

  const components = flatten(children);
  const middle = Math.ceil(components.length / 2);
  const chunks = splitAt(middle, components);

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        borderRadius: 1,
        display: "flex",
        zIndex: 3,
        flexWrap: "wrap",
        justifyContent: "center"
      }}
    >
      {chunks.map((chunk, index) => (
        <Box
          key={index}
          sx={{display: "inline"}}
        >
          <Grid
            container
            wrap="nowrap"
            sx={{
              "& > div": {
                margin: theme.spacing(),
              },
            }}
          >
            {chunk}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default LegendWrapper;