import {TEST_UNITS} from "@core/constants/test";
import {times} from "ramda";

export const TEST_STANDARDS = ["ISO 28079:2009"];

export const PALMQVIST_TYPES = {
  "W<sub>G</sub>": {
    units: {
      [TEST_UNITS.METRIC]: "N/mm",
      [TEST_UNITS.IMPERIAL]: "lb/in"
    }
  },
  "W<sub>K</sub>": {
    units: {
      [TEST_UNITS.METRIC]: "MNm^(-3/2)",
      [TEST_UNITS.IMPERIAL]: "ksiin^(1/2)"
    }
  },
};
export const STRESS_INTENSITY_FACTOR_UNIT = {
  [TEST_UNITS.METRIC]: "MNm^(-3/2)",
  [TEST_UNITS.IMPERIAL]: "ksiin^(1/2)"
};

export const DIRECTIONS = ["X direction", "Y direction", "Z direction"];

export const TEST_RESULT = {
  specimen: "",
  location: "",
  position: "",
  orientation: "",
  direction: "",
  palmqvist: undefined,
  stressIntensityFactor: undefined,
};

export const TEST_RESULTS_AMOUNT = 1;

export const DEFAULT_TEST_RESULTS = times(() => TEST_RESULT, TEST_RESULTS_AMOUNT);