const SPEC_TYPES = {
  certificate: "Main certificate",
  test: "Test circumstances",
  test_results: "Test results",
  statements: "Statements",
};

const OPERATORS =  {
  LTE: "lte",
  LT: "lt",
  GTE: "gte",
  GT: "gt",
  EQUAL: "equal",
  BETWEEN: "between",
  CONTAINS: "contains",
};

const OPERATOR_DESCRIPTION =  {
  [OPERATORS.LTE]: "Less or equal to",
  [OPERATORS.LT]: "Less than",
  [OPERATORS.GTE]: "Greater or equal to",
  [OPERATORS.GT]: "Greater than",
  [OPERATORS.EQUAL]: "Equal to",
  [OPERATORS.BETWEEN]: "Between",
  [OPERATORS.CONTAINS]: "Contains",
};
export {SPEC_TYPES, OPERATORS, OPERATOR_DESCRIPTION};