import CircularProgress from "@mui/material/CircularProgress";
import React, {useEffect, useState} from "react";
import {isEmpty} from "ramda";
import {observer} from "mobx-react-lite";
import {Box, Tooltip, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {
  PAGE_PADDING,
  STATE,
  VIEWS
} from "../../constants";
import useStores from "../../../../../../../../useStores";

const Header = ({isFullScreen, tieInWeldsByStalkNumber, onChangeView, onChangeState, ...props}) => {
  const [state, setState] = useState(props.state);
  const [view, setView] = useState(props.view);

  const {CampaignStore} = useStores();

  useEffect(() => onChangeState(state), [state]);
  useEffect(() => onChangeView(view), [view]);

  const campaign = CampaignStore.campaign;
  const coatingTypesByPipeNumber = CampaignStore.coatingTypesByPipeNumber;

  if(!campaign.welds.length) return null;

  return (
    <>
      {!isEmpty(tieInWeldsByStalkNumber) && (
        <Box
          sx={{
            position: "absolute",
            left: isFullScreen ? PAGE_PADDING : 0,
            top: isFullScreen ? PAGE_PADDING : 0,
            zIndex: 3,
          }}
        >
          <ToggleButtonGroup
            variant="canvas"
            value={state}
            exclusive
            onChange={(_, state) => {
              if(!state) return;

              setState(state);
            }}
          >
            <ToggleButton value={STATE.AS_FABRICATED}>As-fabricated</ToggleButton>
            <ToggleButton value={STATE.AS_BUILT}>As-built</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          right: isFullScreen ? PAGE_PADDING : 0,
          top: isFullScreen ? PAGE_PADDING : 0,
          zIndex: 3,
        }}
      >
        <ToggleButtonGroup
          variant="canvas"
          value={view}
          exclusive
          onChange={(_, view) => {
            if(!view) return;

            setView(view);
          }}
        >
          <ToggleButton value={VIEWS.GLOBAL}>Global view</ToggleButton>
          <ToggleButton value={VIEWS.DETAILED}>Detailed view</ToggleButton>
          <ToggleButton value={VIEWS.COATING} disabled={isEmpty(coatingTypesByPipeNumber)}>
            <Tooltip
              title="The coating view will be available once coating data have been added to this campaign"
              disableHoverListener={!isEmpty(coatingTypesByPipeNumber)}
            >
              <span>Coating view</span>
            </Tooltip>
            &nbsp;{CampaignStore.transfersLoading && <CircularProgress size={20}/>}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

export default observer(Header);