export default () => ({
  popover: {
    width: 350,
    maxHeight: 400,
    overflow: "visible",
  },
  input: {
    padding: 10,
  },
  listItem: {
    padding: 0,
  },
  optionsList: {
    maxHeight: 300,
    overflow: "scroll"
  }
});