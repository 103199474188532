import * as Sentry from "@sentry/react";
import {host, sentryDsn, environment, sentryEnabled, version} from "Config";

if (sentryEnabled) {
  Sentry.init({
    environment,
    release: `frontend@${version}`,
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [host],
      }),
      new Sentry.Replay({
        maskAllText: false,
        mask: ["[data-sentry-mask]"]
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
