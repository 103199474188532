import React from "react";
import {FieldArray} from "formik";
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Partner from "./Partner";
import styles from "../styles";
import classNames from "classnames";
import {MdAdd} from "react-icons/md";
import Grid from "@mui/material/Grid";
import {append} from "ramda";

const Partners = ({classes, disabled, onSubmit}) => (
  <>
    <h4 className={classes.label}>Partners</h4>
    <FieldArray name="partners">
      {({remove,push, replace, form}) => {
        const {setFieldValue, values, setFieldTouched, touched, errors, handleChange, initialValues} = form;

        const onSave = async (index, partner) => {
          if(partner.editable && initialValues.name) {
            const partners = initialValues.partners[index] ?
              initialValues.partners.map((l, idx) => idx === index ? partner : l) :
              append(partner, initialValues.partners);
            await onSubmit({...initialValues, partners});
          }

          replace(index, {...partner, editable: !partner.editable});
        };
        
        return (
          <Grid container spacing={2} className={classes.mb25}>
            <Grid item xs={12}>
              <Table className={classNames("styled-table", classes.table)}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.partners && values.partners.map((partner, index) => (
                    <Partner
                      index={index}
                      disabled={disabled}
                      values={{
                        name: {value: partner.name, path: `partners.${index}.name`},
                        url: {value: partner.url, path: `partners.${index}.url`},
                        image: {value: partner.image, path: `partners.${index}.image`}
                      }}
                      actions={{
                        change: (fieldName, e) => {
                          handleChange(e);
                          setFieldTouched(fieldName, true, false);
                        },
                        uploadImage: (fieldName, e) => setFieldValue(fieldName, e.file.dir + e.file.name),
                        resetPreviewImage: (fieldName) => setFieldValue(fieldName, ""),
                        blur: (path, field) => setFieldValue(path, values.partners[index][field].trim()),
                        save: () => onSave(index, partner),
                        remove: () => remove(index),
                      }}
                      editable={partner.editable}
                      touched={touched.partners || {}}
                      errors={errors.partners || {}}
                    />
                  ))}
                  <TableRow>
                    <TableCell colSpan={7}>
                      <div className={classes.loaderContainer}>
                        <Button
                          onClick={() => {
                            push({
                              name: "",
                              url: "",
                              image: "",
                              editable: true
                            });
                          }}
                          disabled={disabled}
                        >
                          <MdAdd size={80} />Add New
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  </>
);

export default withStyles(Partners, styles);