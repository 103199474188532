export default (theme) => ({
  name: {
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },

  value: {
    color: "#616161",
    fontWeight: theme.typography.fontWeightMedium,
  },

  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "underline",
  }
});

