export default (theme) => ({
  paddedContainer: {
    padding: 40
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  input: {
    marginTop: 24
  },
  table: {
    "& td th": {
      fontSize: "14"
    }
  },
  tableHead: {
    "& th": {
      color: "#222222"
    }
  },
});