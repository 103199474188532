import {getIsWithinRequirements} from "@core/helpers";
import {Box, useTheme} from "@mui/material";
import {isNil} from "ramda";
import React from "react";

const TestResultTableCell = ({value, min, max, children}) => {
  const theme = useTheme();

  const isAcceptable = getIsWithinRequirements(value, min, max);

  const withRequirements = !isNil(min) || !isNil(max);

  const testColor = withRequirements ?
    isAcceptable ? theme.palette.success.main : theme.palette.error.main :
    theme.palette.primary.main;

  const cellValue = Number(value) || value?.length ? value : "-";

  return (
    <Box
      sx={value ? {color: testColor} : {}}
    >
      {children || cellValue}
    </Box>
  );
};

export default TestResultTableCell;