import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import TableSkeleton from "@core/components/TableSkeleton";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  CircularProgress,
  MenuItem, Typography,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import ProductTypeModal from "@core/components/ProductTypeModal";
import ActionCell from "@core/components/ActionCell";
import {Input} from "@core/components/Form";
import styles from "./styles";
import useStores from "../../useStores";
import TableFooter from "@core/components/TableFooter";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const ProductTypesList = observer(({match, classes}) => {
  const {ProductTypesStore} = useStores();

  const [productToDelete, setProductToDelete] = useState(null);
  const [productTypeModalOpen, setProductTypeModalOpen] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState(null);

  const {status, data: productTypes, total, fetch} = ProductTypesStore.productTypes;

  useEffect(() => {
    return () => ProductTypesStore.productTypes.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => ProductTypesStore,
    dataKey: STORE_DATA.productTypes,
    matchPath: match.path,
  });

  const handleRowsPerPageChange = useCallback((limit) => {
    setRowsPerPage(match.path, limit);
    ProductTypesStore.productTypes.load({limit, offset: 0});
  }, []);

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    ProductTypesStore.productTypes.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);
  
  const onDelete = useCallback(async () => {
    await ProductTypesStore.removeProduct(productToDelete);
    await ProductTypesStore.productTypes.load();
    setProductToDelete(null);
  }, [productToDelete]);

  return (
    <div className="padded-container">
      <Grid container spacing={3} marginBottom={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Product Types
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedProductType(null);
              setProductTypeModalOpen(true);
            }}
          >
            Create new
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={12}>
          <Input
            className={classes.input}
            name='search'
            endAdornment={
              <InputAdornment position="start">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Search Product Types e.g. \"Pipe\""}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Internal name</TableCell>
                <TableCell>Properties</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {status.loaded ? (
              <TableBody>
                {productTypes.length ? productTypes.map((product) => (
                  <TableRow key={product._id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.internalName}</TableCell>
                    <TableCell>
                      {product.properties.map((property) => property.measurements ? `${property.label} (${property.measurements.toLowerCase()})` : property.label).join(" / ")}
                    </TableCell>
                    <TableCell>
                      <ActionCell>
                        <MenuItem
                          onClick={() => {
                            setSelectedProductType(product);
                            setProductTypeModalOpen(true);
                          }}
                        >
                          Edit
                        </MenuItem>
                        {product.company && (
                          <MenuItem
                            className={classes.deleteButton}
                            onClick={() => setProductToDelete(product._id)}
                          >
                            Delete
                          </MenuItem>
                        )}
                      </ActionCell>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={4}>No products found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableSkeleton columns={4}/>
              </TableBody>
            )}
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={productTypes}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              onOffsetChange={(offset) => ProductTypesStore.productTypes.load({offset})}
              onLimitChange={handleRowsPerPageChange}
            />
          </Table>
        </Grid>
      </Grid>
      <ProductTypeModal
        productType={selectedProductType}
        open={productTypeModalOpen}
        close={() => setProductTypeModalOpen(false)}
        onProductTypeCreated={() => ProductTypesStore.productTypes.load()}
      />
      <Confirmation
        open={productToDelete}
        onCancel={() => setProductToDelete(null)}
        onConfirm={onDelete}
        alertText="Are you sure you want to delete this product?"
      />
    </div>
  );
});

export default withStyles(ProductTypesList, styles);
