import React from "react";

export default () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 512 512" xmlSpace="preserve">
    <path d="M41.8,348.9c8.5,8.6,20.1,13.4,32.1,13.4h75.8V438c0,25.1,20.4,45.5,45.5,45.5l0,0H438c25.1,0,45.5-20.4,45.5-45.5V195.3
     c-0.1-12.1-4.9-23.6-13.4-32.1c-8.5-8.6-20.1-13.4-32.1-13.4h-75.8V74c0-25.1-20.4-45.5-45.5-45.5H74c-12.1,0.1-23.6,4.9-32.1,13.4
     C33.2,50.3,28.4,61.9,28.4,74v242.6C28.4,328.7,33.2,340.3,41.8,348.9L41.8,348.9z M424.8,193.5c4.1,0.1,7.9,1.9,10.7,4.8
     c2.8,2.8,4.4,6.7,4.4,10.7v217c0,4-1.6,7.8-4.4,10.7c-2.8,2.8-6.7,4.4-10.7,4.4H211.7c-8.3,0-15.1-6.7-15.2-15.1v-63.7l120.1,0
     c25.1,0,45.5-20.4,45.5-45.5V193.6L424.8,193.5z M66.3,83.7c0-3.8,1.5-7.4,4.1-10.1c2.6-2.7,6.2-4.2,9.9-4.2l231.1,1.8
     c7.8,0,14.1,6.4,14.1,14.4l0,226.6c-0.1,3.8-1.7,7.3-4.4,9.9c-2.6,2.7-13.3,1.8-17,1.8l-223.8,0.5c-7.8,0-14.1-6.4-14.1-14.4
     L66.3,83.7z"/>
  </svg>
);