import React, {useMemo, memo} from "react";
import {reverse} from "ramda";
import {Group, Text} from "react-konva";
import {AS_BUILD_TIE_IN_WELD_CONFIG, PIPE_CONFIG, REPAIRED_WELD_CONFIG, WELD_LINE_CONFIG} from "../../constants";
import {STAGE_CONFIG, PAGE_PADDING} from "../../../../constants";
import {getTextScale} from "../../../../helpers";
import {getIsTieInWeld, getWeldIsRepaired} from "../../../../../../helpers";
import CurvedLine from "../../../CurvedLine";

const WeldLine = ({x, id, settings, scale, onClick}) => {
  const y = useMemo(() => PIPE_CONFIG.HEIGHT / 2, []);

  const nextY = useMemo(() => PIPE_CONFIG.HEIGHT + STAGE_CONFIG.PADDING, []);
  const middlePointY = useMemo(() => nextY / 2, [nextY]);

  const lineOffsets = useMemo(() => [{x: 0, y: 0}, {x: PAGE_PADDING, y: 0}, {x: PAGE_PADDING, y: middlePointY}], [middlePointY]);
  const rightPartOfLine = lineOffsets.reduce((points, {x: xOffset, y: yOffset}) => points.concat([xOffset, yOffset]), []);
  const leftPartOfLine = reverse(lineOffsets).reduce((points, {x: xOffset, y: yOffset}) => points.concat([-x - xOffset, nextY - yOffset]), []);

  const points = useMemo(() => [...rightPartOfLine, ...leftPartOfLine], [rightPartOfLine, leftPartOfLine]);

  const textScale = useMemo(() => {
    return getTextScale(PIPE_CONFIG.TEXT_SCALE_BY, scale, PIPE_CONFIG.TEXT_MAX_SCALE, PIPE_CONFIG.TEXT_MIN_SCALE);
  }, [scale]);

  const textY = useMemo(() => {
    const y = WELD_LINE_CONFIG.TEXT_POSITION / scale;

    return  middlePointY - Math.min(WELD_LINE_CONFIG.TEXT_POSITION_MIN, y);
  }, [scale]);

  const textColor = useMemo(() => {
    const isRepaired = getWeldIsRepaired(id);

    if(isRepaired && settings.highlightRepairedWelds) return REPAIRED_WELD_CONFIG.TEXT_COLOR;

    const isTieInWeld = getIsTieInWeld(id);

    if(isTieInWeld && settings.tieInWelds) return AS_BUILD_TIE_IN_WELD_CONFIG.TEXT_COLOR;

    return WELD_LINE_CONFIG.TEXT_COLOR;
  }, [id, settings.highlightRepairedWelds]);

  if(!settings.welds) return null;

  return (
    <Group
      y={y}
      x={x}
      onClick={onClick}
      onMouseEnter={() => document.body.style.cursor = "pointer"}
      onMouseLeave={() => document.body.style.cursor = "default"}
    >
      <CurvedLine
        points={points}
        fill={WELD_LINE_CONFIG.COLOR}
        stroke={WELD_LINE_CONFIG.COLOR}
      />
      <Text
        perfectDrawEnabled={false}
        x={-x}
        text={id}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        fill={textColor}
        align="center"
        y={textY}
        fontSize={WELD_LINE_CONFIG.FONT_SIZE}
        scale={{x: textScale, y: textScale}}
      />
    </Group>
  );
};

export default memo(WeldLine);