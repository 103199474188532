import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {MenuItem} from "@mui/material";
import {Select} from "@core/components/Form";
import useStores from "../../../useStores";
import Specification from "./components/Specification";

const SpecificationChecker = ({certificate}) => {
  const [openId, setOpenId] = useState(null);
  
  const {SpecificationStore} = useStores();

  useEffect(() => {
    SpecificationStore.getAll();
  }, []);
  
  const specifications = SpecificationStore.specifications.data;
  
  return (
    <>
      <Select
        value="specification"
        name="specification"
        onChange={(e) => setOpenId(e.target.value)}
      >
        <MenuItem value="specification" selected disabled><em>Check specifications</em></MenuItem>
        {specifications.map((specification) =>
          <MenuItem key={specification._id} value={specification._id}>{specification.name}</MenuItem>
        )}
      </Select>
      <Specification
        open={!!openId}
        onClose={() => setOpenId(null)}
        certificate={certificate}
        specifications={specifications}
        chosenSpecificationId={openId}
      />
    </>
  );
};

export default observer(SpecificationChecker);