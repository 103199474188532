export default () => ({
  inputContainer: {
    position: "relative",
    cursor: "pointer",
  },
  input: {
    padding: 5,
  },
  inputRoot: {
    width: "100%",
    backgroundColor: "transparent",
  },
  hiddenInput: {
    color: "gray",
    cursor: "none",
    position: "absolute",
    left: 0,
  },
  dropdown: {
    maxHeight: 200,
    minWidth: 350,
  }
});
