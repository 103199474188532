import React, {useState} from "react";
import {Button, Grid} from "@mui/material";
import {omit, partition} from "ramda";
import TestSummaryTable from "@core/components/TestSummaryTable";
import AddNewRow from "../AddNewRow";
import {COLUMNS} from "@core/components/TestSummaryTable/constants";
import {generateID, sort} from "@core/helpers";
import {QCPS} from "@core/constants/qcps";
import {STATUSES} from "@core/constants/test";
import {ORDERS} from "@core/constants/sort";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import useStores from "../../../../../useStores";

const TESTS_SUMMARY_COLUMNS = [
  COLUMNS.TITLE,
  COLUMNS.QUANTITY_EDITABLE,
  COLUMNS.WITNESS_EDITABLE,
  COLUMNS.LAB_EDITABLE,
  COLUMNS.CONFIDENTIAL_EDITABLE,
  COLUMNS.TAGS
];

const QCP_WITH_STATUS_TESTS_SUMMARY_COLUMNS = [
  COLUMNS.ACTIVITY_NUMBER,
  COLUMNS.TITLE,
  COLUMNS.QUANTITY,
  COLUMNS.WITNESS_EDITABLE,
  COLUMNS.LAB_EDITABLE,
  COLUMNS.STATUS,
  COLUMNS.RESULT,
  COLUMNS.CONFIDENTIAL_EDITABLE,
  COLUMNS.TAGS
];

const Overview = ({qcp, close, assignTests, onTestsCreated, tests, setSelectedTests}) => {
  const {TestStore, UserStore} = useStores();

  const companyId = UserStore.user.data.company._id;

  const [isLoading, setIsLoading] = useState(false);

  const changeTests = (changes, testIds) => {
    const newTests = tests.map((test) => testIds.includes(test._id) ? {...test, ...changes} : test);
    setSelectedTests(newTests);
  };

  const addTest = (type) => {
    const newTest = {
      type,
      assignee: "",
      company: companyId,
      status: STATUSES.EMPTY,
      properties: {
        tags: []
      },
      witnesses: [],
      _id: generateID(),
    };

    setSelectedTests([...tests, newTest]);
  };
  const onCreateTests = async () => {
    setIsLoading(true);

    const [testsToFill, testsToAssign] = partition((test) => !test.assignee, tests);

    const formattedTestsToFill = testsToFill.map((test) => ({
      ...omit(["assignee", "_id"], test),
      witnesses: test.witnesses.map((witness) => ({company: witness._id, status: WITNESS_STATUSES.UNSET})),
    }));
    const formattedTestsToAssign = testsToAssign.map((test) => omit(["witnesses", "assignee", "_id"], test));

    const createdTests = await TestStore.create([...formattedTestsToFill, ...formattedTestsToAssign]);

    if(createdTests.length) await onTestsCreated(createdTests);

    setIsLoading(false);
    close();

    if(testsToAssign.length) await assignTests(testsToAssign, createdTests);
  };

  const columns = qcp && QCPS[qcp].producerTests ? QCP_WITH_STATUS_TESTS_SUMMARY_COLUMNS : TESTS_SUMMARY_COLUMNS;
  const testsToCreate = sort(ORDERS.ASC, "type", tests);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TestSummaryTable
          collapsible
          withGrouping
          groupByProps={!qcp ? ["type"] : null}
          tests={testsToCreate}
          updateTests={changeTests}
          setSelected={setSelectedTests}
          columns={columns}
        >
          {!qcp && <AddNewRow addTest={addTest} />}
        </TestSummaryTable>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Grid item>
          <Button
            color='primary'
            variant="contained"
            component="span"
            disabled={!testsToCreate.length || isLoading}
            onClick={onCreateTests}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Overview;