import React from "react";
import moment from "moment";
import {Box, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Company from "./components/Company";
import Title from "./components/Title";
import WitnessEdit from "./components/WitnessEdit";
import LabEdit from "./components/LabEdit";
import Status from "../Status";
import Result from "./components/Result";
import ConfidentialEdit from "./components/ConfidentialEdit";
import QuantityEdit from "./components/QuantityEdit";
import Tags from "./components/Tags";
import InspectionResult from "./components/InspectionResult";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";

export const COLUMNS = {
  TITLE: "TITLE",
  DATES: "DATES",
  QUANTITY: "QUANTITY",
  QUANTITY_EDITABLE: "QUANTITY_EDITABLE",
  WITNESS: "WITNESS",
  WITNESS_EDITABLE: "WITNESS_EDITABLE",
  LAB_EDITABLE: "LAB_EDITABLE",
  COMPANY: "COMPANY",
  LAB: "LAB",
  STATUS: "STATUS",
  RESULT: "RESULT",
  INSPECTION_RESULT: "INSPECTION_RESULT",
  PRODUCT_TYPE: "PRODUCT_TYPE",
  CONFIDENTIAL_EDITABLE: "CONFIDENTIAL_EDITABLE",
  TAGS: "TAGS",
  HEAT: "HEAT",
  QCP_ITEM: "QCP_ITEM",
  ACTIVITY_NUMBER: "ACTIVITY_NUMBER"
};

export const DEFAULT_COLUMNS = [
  COLUMNS.ACTIVITY_NUMBER,
  COLUMNS.TITLE,
  COLUMNS.WITNESS,
  COLUMNS.COMPANY,
  COLUMNS.LAB,
  COLUMNS.STATUS,
  COLUMNS.RESULT,
  COLUMNS.INSPECTION_RESULT
];

export const COLUMNS_CONFIG = {
  [COLUMNS.TITLE]: {
    permanent: true,
    title: "Tests and Analyses",
    isSorted: true,
    width: "30%",
    render: (props) => <Title {...props} />,
  },
  [COLUMNS.DATES]: {
    title: "Date(s)",
    render: ({test}) => {
      const latestDate = new Date(Math.max(...test.properties.activities.map((activity) => new Date(activity.date))));
      const recentDate = new Date(Math.min(...test.properties.activities.map((activity) => new Date(activity.date))));
      
      const formattedLatestDate = moment(latestDate).format(DATE_FORMAT);
      const formattedRecentDate = moment(recentDate).format(DATE_FORMAT);
      
      return test.properties.activities ? `${formattedRecentDate}/${formattedLatestDate}`: "-";
    },
  },
  [COLUMNS.QUANTITY]: {
    title: "Quantity",
    permanent: true,
    isSorted: true,
    render: ({bunchOfSameTests}) => bunchOfSameTests.length,
  },
  [COLUMNS.QUANTITY_EDITABLE]: {
    title: "Quantity",
    permanent: true,
    render: (props) => <QuantityEdit {...props} />
  },
  [COLUMNS.PRODUCT_TYPE]: {
    title: "Product",
    render: ({test}) => test.productType.name,
  },
  [COLUMNS.WITNESS]: {
    title: "Witness",
    isSorted: true,
    render: ({test}) => <Company name={test[COLUMNS.WITNESS].join(", ")} />,
  },
  [COLUMNS.WITNESS_EDITABLE]: {
    title: "Witness",
    permanent: true,
    width: "25%",
    render: (props) => <WitnessEdit {...props} />,
  },
  [COLUMNS.COMPANY]: {
    title: "Company",
    permanent: true,
    isSorted: true,
    render: ({test}) => <Company name={test[COLUMNS.COMPANY]} />,
  },
  [COLUMNS.LAB]: {
    title: "Lab",
    isSorted: true,
    render: ({test}) => <Company name={test[COLUMNS.LAB]} />,
  },
  [COLUMNS.LAB_EDITABLE]: {
    title: "Lab",
    permanent: true,
    width: "20%",
    render: (props) => <LabEdit {...props} />,
  },
  [COLUMNS.STATUS]: {
    permanent: true,
    title: "Status",
    isSorted: true,
    render: (props) => <Status {...props} />
  },
  [COLUMNS.RESULT]: {
    permanent: true,
    title: "Result",
    isSorted: true,
    render: (props) => <Result {...props} />
  },
  [COLUMNS.INSPECTION_RESULT]: {
    title: "Inspection Result",
    isSorted: true,
    render: (props) => <InspectionResult {...props} />,
  },
  [COLUMNS.CONFIDENTIAL_EDITABLE]: {
    permanent: true,
    title: (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        Confidential&nbsp;
        <Tooltip title="Confidential tests are only visible to you until they've been fully approved">
          <InfoIcon color="info" />
        </Tooltip>
      </Box>
    ),
    render: (props) => <ConfidentialEdit {...props} />,
  },
  [COLUMNS.TAGS]: {
    permanent: true,
    title: "Tags",
    render: (props) => <Tags {...props} />,
  },
  [COLUMNS.HEAT]: {
    title: "Heat",
    render: ({test}) => test.item_heat,
  },
  [COLUMNS.QCP_ITEM]: {
    title: "QCP Item",
    render: ({test}) => test.properties.acceptanceItem,
  },
  [COLUMNS.ACTIVITY_NUMBER]: {
    width: "185px",
    isSorted: true,
    title: "Activity Number",
    render: ({test}) => test[COLUMNS.ACTIVITY_NUMBER] || "-",
  }
};

export const INSPECTION_RESULTS = {
  ACCEPTED: "Accepted",
  UNACCEPTED: "Unaccepted",
  UNSET: "Pending Signature",
};

export const INSPECTION_RESULT_BY_WITNESS_STATUS = {
  [WITNESS_STATUSES.UNSET]: INSPECTION_RESULTS.UNSET,
  [WITNESS_STATUSES.ACCEPTED]: INSPECTION_RESULTS.ACCEPTED,
  [WITNESS_STATUSES.NOT_ACCEPTED]: INSPECTION_RESULTS.UNACCEPTED,
};

export const CELLS_WITH_NA_VALUE = [COLUMNS.WITNESS, COLUMNS.LAB, COLUMNS.INSPECTION_RESULT];