function isSafeEnvironment() {
  // eslint-disable-next-line no-undef
  const env = process.env.ENVIRONMENT;

  if (env === "staging" || env === "development" || env === "production" || env === "demo") {
    return true;
  }

  return false;
}

export default isSafeEnvironment;