import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import RouterLink from "@core/components/RouterLink";
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Loader from "@core/components/Loader";
import TableFooter from "@core/components/TableFooter";
import styles from "./styles";
import {compose} from "ramda";

class List extends Component {
  componentDidMount() {
    const {CompanyStore} = this.props;
    const {offset, limit} = CompanyStore.companies;

    CompanyStore.getCompanies({offset, limit});
  }

  componentWillUnmount() {
    const {CompanyStore} = this.props;

    CompanyStore.resetCompanies();
  }

  handleChangePage = (offset) => {
    const {CompanyStore} = this.props;
    const {limit} = CompanyStore.companies;

    CompanyStore.getCompanies({offset, limit});
  };

  handleChangeRowsPerPage = (limit) => {
    const {CompanyStore} = this.props;
    const {offset} = CompanyStore.companies;

    CompanyStore.getCompanies({offset, limit});
  };

  render() {
    const {classes, dashboard, CompanyStore} = this.props;
    const {total, limit, offset, data: companies, isLoaded} = CompanyStore.companies;

    return (
      <div className={dashboard ? "" : classes.padding40}>
        <div className={classes.headerAndButton}>
          <Typography variant="h4" fontSize="1.8rem">
            {dashboard ? "Companies' Dashboard" : "Companies"}
          </Typography>
          {dashboard ?
            null :
            <Button component={RouterLink} to="/companies/create" variant="contained" size="medium" color="primary">
              Create new
            </Button>}
        </div>
        <Paper className={classes.root}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoaded ? (
              <TableBody>
                {companies.map((company) => <TableRow key={company._id} hover>
                  <TableCell>{company.name}</TableCell>
                  <TableCell data-sentry-mask>
                    {company.admin?._id ? (
                      <Button
                        className={classes.adminName}
                        color="primary"
                        component={RouterLink}
                        to={`/admin/users/${company.admin._id}`}
                      >
                        {`${company.admin.firstname} ${company.admin.lastname}`}
                      </Button>
                    ) : "-"}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      component={RouterLink}
                      to={dashboard ? `/companies/${company._id}/dashboard` : `/companies/${company._id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>)}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <div className={classes.loaderContainer}>
                      <Loader size={30}/>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {(isLoaded && !!companies.length) && (
              <TableFooter
                isLoaded={isLoaded}
                items={companies}
                total={total}
                limit={limit}
                offset={offset}
                onOffsetChange={this.handleChangePage}
                onLimitChange={this.handleChangeRowsPerPage}
                className={classes.tableFooter}
              />
            )}
          </Table>
        </Paper>
      </div>
    );
  }
}

export default compose(
  inject("CompanyStore"),
)(withStyles(observer(List), styles));
