import React from "react";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {AiOutlineLock, AiOutlineUnlock} from "react-icons/ai";
import styles from "./styles";

const LockSwitch = ({classes, acceptance, tableLocked, setTableLocked}) => {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Tooltip title={acceptance ? `You can't change ${acceptance} requirements` :
          tableLocked ? "Unlock min/max" : "Lock min/max"}>
          <IconButton
            className={classes.button}
            disabled={acceptance}
            onClick={() => setTableLocked(!tableLocked)}
            size="large">
            {tableLocked || acceptance ? <AiOutlineLock /> : <AiOutlineUnlock />}
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default withStyles(LockSwitch, styles);