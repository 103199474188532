import React, {useEffect} from "react";
import {Grid, MenuItem} from "@mui/material";
import {useFormikContext} from "formik";
import {isNil, filter, intersection, keys, pick, values as getValues} from "ramda";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import {MEASUREMENTS_CONFIG} from "../../data";
import {CUSTOM_EXCEPTIONS} from "../../exceptions";
import {getCalculatedAcceptanceCriteria} from "../../services";

const Acceptance = ({name, customToleranceUnits, label, units, disabled, measurement, poItemNumber}) => {
  const {values, setFieldValue} = useFormikContext();

  const onNominal = () => {
    if(!values.acceptance) return;

    const acceptanceCriteria = CUSTOM_EXCEPTIONS[values.acceptance].data;
    const calculatedAcceptanceCriteria = getCalculatedAcceptanceCriteria(acceptanceCriteria, poItemNumber, values.acceptanceCriteria);

    const emptyCriteria = filter((value) => {
      return isNil(value.body?.nominal) && isNil(value.ends?.nominal) && isNil(value.nominal);
    }, values.acceptanceCriteria);
    const filledCriteria = pick(keys(emptyCriteria), calculatedAcceptanceCriteria);
    setFieldValue("acceptanceCriteria", {...values.acceptanceCriteria, ...filledCriteria});
  };

  return (
    <>
      <Grid item xs={2}>
        <TextField
          type={disabled ? "text" : "number"}
          name={`${name}.nominal`}
          label={label}
          endAdornment={units}
          required
          onChange={onNominal}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              type={disabled ? "text" : "number"}
              startAdornment="+"
              name={`${name}.tolerancePlus`}
              endAdornment={customToleranceUnits}
            />
          </Grid>
          <Grid item xs>
            <TextField
              type={disabled ? "text" : "number"}
              startAdornment="-"
              name={`${name}.toleranceMinus`}
              endAdornment={customToleranceUnits}
            />
          </Grid>
          {!disabled && (
            <Grid item xs={2}>
              <SelectField
                name={`${name}.toleranceUnits`}
              >
                {getValues(MEASUREMENTS_CONFIG[measurement].units).map((unit) => <MenuItem key={unit} value={unit}>{unit}</MenuItem>)}
                <MenuItem value={"%"}>{"%"}</MenuItem>
              </SelectField>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const AcceptanceRow = ({measurement, poItemNumber}) => {
  const {values, setFieldValue} = useFormikContext();

  const hasNoLocations = !MEASUREMENTS_CONFIG[measurement].locations;
  const hasBody = !hasNoLocations && MEASUREMENTS_CONFIG[measurement].locations.includes("body");
  const hasEnds = !hasNoLocations && intersection(["rightEnd", "leftEnd"], MEASUREMENTS_CONFIG[measurement].locations);

  const customAcceptanceCriteria = CUSTOM_EXCEPTIONS[values.acceptance]?.data[measurement];
  const acceptanceCriteria = values.acceptanceCriteria[measurement] || {};

  useEffect(() => {
    if (!values.acceptance) return;

    const toleranceUnits = values.units[measurement];

    if (hasNoLocations && !acceptanceCriteria.toleranceUnits) {
      setFieldValue(`acceptanceCriteria.${measurement}.toleranceUnits`, toleranceUnits);
    }

    if (hasBody && !acceptanceCriteria.body?.toleranceUnits) {
      setFieldValue(`acceptanceCriteria.${measurement}.body.toleranceUnits`, toleranceUnits);
    }

    if (hasEnds && !acceptanceCriteria.ends?.toleranceUnits) {
      setFieldValue(`acceptanceCriteria.${measurement}.ends.toleranceUnits`, toleranceUnits);
    }
  }, []);

  return (
    <>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <h3>{MEASUREMENTS_CONFIG[measurement].title}</h3>
        </Grid>
      </Grid>
      <Grid item container alignItems="flex-end" spacing={3}>
        {hasNoLocations && (
          <Acceptance
            name={`acceptanceCriteria.${measurement}`}
            label="Nominal"
            units={values.units[measurement]}
            customToleranceUnits={customAcceptanceCriteria && (
              customAcceptanceCriteria.toleranceUnits ||
              acceptanceCriteria.toleranceUnits)}
            disabled={customAcceptanceCriteria}
            measurement={measurement}
            poItemNumber={poItemNumber}
          />
        )}
        {hasBody && (
          <Acceptance
            name={`acceptanceCriteria.${measurement}.body`}
            label="Nominal (Body)"
            units={values.units[measurement]}
            customToleranceUnits={customAcceptanceCriteria && (
              customAcceptanceCriteria.body?.toleranceUnits ||
              acceptanceCriteria.body?.toleranceUnits)}
            disabled={customAcceptanceCriteria}
            measurement={measurement}
            poItemNumber={poItemNumber}
          />
        )}
        {hasEnds && (
          <Acceptance
            name={`acceptanceCriteria.${measurement}.ends`}
            label="Nominal (Ends)"
            units={values.units[measurement]}
            customToleranceUnits={customAcceptanceCriteria && (
              customAcceptanceCriteria.ends?.toleranceUnits ||
              acceptanceCriteria.ends?.toleranceUnits)}
            disabled={customAcceptanceCriteria}
            measurement={measurement}
            poItemNumber={poItemNumber}
          />
        )}
      </Grid>
    </>
  );
};

export default AcceptanceRow;