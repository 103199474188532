import {COLUMNS, DEFAULT_COLUMNS} from "./constants";
import {groupBy, keys, insertAll} from "ramda";

export const getDefaultColumns = (withGrouping, tests) => {
  const columnsToBeInserted = [];

  if(withGrouping) columnsToBeInserted.push(COLUMNS.QUANTITY);

  const groupedByProduct = groupBy((test) => test.productType?._id, tests);
  const productsNumber = keys(groupedByProduct);

  if(productsNumber > 1) columnsToBeInserted.push(COLUMNS.PRODUCT_TYPE);

  return insertAll(1, columnsToBeInserted, DEFAULT_COLUMNS);
};