import React, {useMemo, useState} from "react";
import {observer} from "mobx-react";
import {Divider} from "@mui/material";
import {withStyles} from "tss-react/mui";
import TestSelectionTable from "./components/TestSelectionTable";
import QcpSelectionTable from "./components/QcpSelectionTable";
import styles from "./styles";
import {keys, sortBy, prop, isEmpty, times, any, propEq} from "ramda";
import {QCPS} from "@core/constants/qcps";
import useStores from "../../../useStores";
import {STATUSES, TEST_TYPES} from "@core/constants/test";
import {getAllowedTestTypes} from "./services";
import modules from "@core/constants/modules";
import Overview from "./components/Overview";
import {generateID} from "@core/helpers";

const formatQcpTests = (qcp, companyId, laboratories, witnesses) => {
  const labToPerformTests = laboratories.find((lab) => QCPS[qcp].labs.includes(lab.name)) || {};
  const witnessToInspectTests = witnesses.find((witness) => QCPS[qcp].witnesses.includes(witness.name)) || {};

  const producerTests = QCPS[qcp].producerTests.map((test) => ({
    status: !isEmpty(test.properties) ? STATUSES.FILLED : STATUSES.EMPTY,
    type: test.type,
    properties: {...test.properties || {}, tags: []},
    company: companyId,
    witnesses: test.witnesses ? [witnessToInspectTests] : [],
    _id: generateID()
  }));

  const labTests = QCPS[qcp].labTests.reduce((tests, test) => {
    const data = {
      type: test.type,
      status: STATUSES.EMPTY,
      company: companyId,
      assignee: labToPerformTests,
      witnesses: test.witnesses ? [witnessToInspectTests] : [],
      properties: {...test.properties || {}, tags: []},
    };

    return [...tests, ...times(() => ({...data, _id: generateID()}), test.quantity)];
  }, []);

  return [...producerTests, ...labTests];
};

const CreateTests = observer(({classes, close, assignTests, onTestsCreated, touched}) => {
  const [selectedQcp, setSelectedQcp] = useState("");
  const [selectedTests, setSelectedTests] = useState([]);
  
  const {UserStore, CompanyStore} = useStores();

  touched.current = selectedQcp || !!selectedTests.length;

  const companyName = UserStore.user.data.company.name;
  const companyId = UserStore.user.data.company._id;
  const [module] = UserStore.user.data.company.modules;

  const laboratories = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.LAB_INTERNAL, "name"), company.modules);
  });

  const witnesses = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.WITNESS, "name"), company.modules);
  });

  const companyQcps = useMemo(() => keys(QCPS).filter((qcp) => QCPS[qcp].companies.includes(companyName)), []);

  const testTypes = useMemo(() => getAllowedTestTypes(module.name), []);
  const sortedTestTypes =  useMemo(() => sortBy(prop("title"))(testTypes), []);

  const tests = useMemo(() => sortedTestTypes.filter(({testType}) => testType === TEST_TYPES.TEST), []);
  const declarations = useMemo(() => sortedTestTypes.filter(({testType}) => testType === TEST_TYPES.DECLARATION), []);

  const onSelectQcp = (qcp) => {
    if(selectedQcp === qcp) {
      setSelectedQcp("");
      setSelectedTests([]);
    } else {
      setSelectedQcp(qcp);
      setSelectedTests(formatQcpTests(qcp, companyId, laboratories, witnesses));
    }
  };

  const onClose = () => {
    setSelectedQcp("");
    setSelectedTests([]);
    close();
  };

  return (
    <div>
      <div className={classes.block}>
        <h3 className={classes.title}>Overview</h3>
        <Overview
          tests={selectedTests}
          setSelectedTests={setSelectedTests}
          qcp={selectedQcp}
          close={onClose}
          assignTests={assignTests}
          onTestsCreated={onTestsCreated}
        />
      </div>
      {!!companyQcps.length && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.block}>
            <h3 className={classes.title}>QCPs/ITPs</h3>
            <QcpSelectionTable
              selectedQcp={selectedQcp}
              onSelectQcp={onSelectQcp}
            />
          </div>
        </>
      )}
      <Divider className={classes.divider} />
      <div className={classes.block}>
        <h3 className={classes.title}>Tests and Analyses</h3>
        <TestSelectionTable
          disabled={Boolean(selectedQcp)}
          tests={tests}
          selected={selectedQcp ? [] : selectedTests}
          setSelected={setSelectedTests}
        />
      </div>
      <Divider className={classes.divider} />
      <div className={classes.block}>
        <h3 className={classes.title}>Declarations</h3>
        <TestSelectionTable
          disabled={Boolean(selectedQcp)}
          tests={declarations}
          selected={selectedQcp ? [] : selectedTests}
          setSelected={setSelectedTests}
        />
      </div>
    </div>
  );
});

export default withStyles(CreateTests, styles);