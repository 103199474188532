export default () => ({
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: 24
    }
  },
  proLabel: {
    margin: "-10px 0 0 5px",
    textTransform: "none"
  }
});