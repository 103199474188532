import React from "react";
import {withStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CustomCircularProgress = withStyles(({classes}) => (
  <Box sx={{display: "flex", mr: 2}}>
    <CircularProgress size="1.5rem" color="inherit" classes={{root: classes.circularProgress}}/>
  </Box>
), (theme) => ({
  circularProgress: {
    color: theme.palette.secondary.grey
  }
}));

export default CustomCircularProgress;