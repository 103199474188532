export default (theme) => ({
  detailsBlock: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.success.backgroundColor
  },
  signatureIdLink: {
    display: "inline-flex",
    alignItems: "flex-end",
    gap: theme.spacing(1)
  },
  signatureId: {
    display: "inline-block",
    fontWeight: theme.typography.fontWeightMedium,
  },
  signatureIdIcon: {
    color: theme.palette.text.primary,
    fontSize: "1.4rem"
  },
});