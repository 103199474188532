export default (theme) => ({
  header: {
    width: "100%",
    height: 60,
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "flex-start",
  },

  headerLeftCol: {
    flexGrow: 2,
    display: "flex",
    alignItems: "center",
  },
  headerRightCol: {
    flexGrow: 2,
  },

  logo: {
    display: "inline-block",
    "& img": {
      display: "inline-block",
      width: 150,
      height: "auto",
      margin: "4px 0 0 20px",
    }
  },

  actionButton: {
    display: "flex",
    justifyContent: "flex-end",
    color: "#eeeeee",
    "& a": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      height: "100%",
      padding: "0 20px",
      borderLeft: "1px solid #ffffff80",
    },
    "& img": {
      filter: "invert(1)",
    },
  },

  item: {
    display: "inline-block",
    fontSize: 28,
    padding: "15px 15px 0 0",
    transition: "color 0.4s",
    "&:hover": {
      color: "#70b045",
      cursor: "pointer",
    }
  },
});