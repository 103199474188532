import {useState, useCallback, useEffect} from "react";
const useResize = (myRef) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(() => {
    setWidth(myRef.current ? myRef.current.offsetWidth : 0);
    setHeight(myRef.current ? myRef.current.offsetHeight : 0);
  }, [myRef]);

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    myRef.current?.addEventListener("transitionstart", handleResize);
    myRef.current?.addEventListener("transitionrun", handleResize);
    myRef.current?.addEventListener("transitionend", handleResize);

    return () => {
      myRef.current?.removeEventListener("transitionstart", handleResize);
      myRef.current?.removeEventListener("transitionrun", handleResize);
      myRef.current?.removeEventListener("transitionend", handleResize);
    };
  }, [myRef.current, handleResize]);

  return {width, height};
};

export default useResize;