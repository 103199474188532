import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {keys, values} from "ramda";
import classNames from "classnames";
import {createTheme, ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import {CssBaseline, Skeleton, Grid, ToggleButtonGroup, ToggleButton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import themeSettings from "../../../../theme-settings";
import useStores from "../../../../useStores";
import SubNavigation from "../../../components/SubNavigation";
import styles from "./styles";
import GeneralInfo from "./components/GeneralInfo";
import FilterButton from "@core/components/FilterButton";
import {useParams} from "react-router";

const defaultTheme = createTheme();
const theme = createTheme({
  ...themeSettings,
  breakpoints: {values: {...defaultTheme?.breakpoints.values, lg: 1201, xl: 1601}}
});

const Header = ({
  classes,
  pageTabs,
  pageTabsLabels,
  subtabsByTab = {},
  onTab,
  tab,
  optionsByType,
  displayOrder,
  activeSubTab,
  setActiveSubTab
}) => {
  const [isSticky, setIsSticky] = useState(false);

  const {MenuStore, CampaignStore} = useStores();

  const {id: campaignId} = useParams();
  const campaign = CampaignStore.campaign;

  const isMenuCollapsed = MenuStore.collapsed;

  const isLoaded = campaignId === campaign._id;

  const checkIsSticky = useCallback(() => {
    setIsSticky(window.pageYOffset);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkIsSticky);

    return () => {
      window.removeEventListener("scroll", checkIsSticky);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          className={classNames(
            classes.container, {
              [classes.stickyHeader]: isSticky,
              [classes.fullWidthHeader]: isMenuCollapsed,
            }
          )}
        >
          <GeneralInfo />
          <Grid container className={classes.tabs} justifyContent="space-between">
            <Grid item>
              <ToggleButtonGroup
                variant="header"
                value={tab}
                exclusive
                onChange={(_, value) => onTab(value)}
              >
                {keys(pageTabs).map((tab) => (
                  <ToggleButton
                    key={tab}
                    value={tab}
                  >
                    {isLoaded ? pageTabsLabels[tab] : <Skeleton width={80} />}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item className={classes.filterContainer}>
              <FilterButton
                optionsByType={optionsByType}
                displayOrder={displayOrder}
              />
            </Grid>
          </Grid>
        </div>
        {subtabsByTab[tab] && (
          <SubNavigation
            activeSubTab={activeSubTab}
            setActiveSubTab={setActiveSubTab}
            subTabs={values(subtabsByTab[tab])}
          />
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default withStyles(observer(Header), styles);
