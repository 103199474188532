export default (theme) => ({
  container: {
    backgroundColor: "white",
    marginTop: 20,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    marginBottom: 20,
    "& h6": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "& b": {
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  content: {
    padding: "15px 30px",
    color: "#575757",
  },
  label: {
    marginBottom: 15,
    fontWeight: theme.typography.fontWeightMedium,
  },
  header: {
    padding: "15px 30px",
  },
  viewFullButton: {
    color: "#2196f3",
    marginTop: 10,
    "&:hover": {
      cursor: "pointer"
    }
  },
  markingImage: {
    "& img": {
      height: "100%",
      width: "100%",
    }
  },
  qrCode: {
    display: "flex",
    justifyContent: "flex-end"
  },
});
