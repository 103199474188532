export default (theme) => ({
  dateContainer: {
    display: "flex",
    flexDirection: "column"
  },
  dateLabel: {
    width: "100%",
    position: "relative",
    fontSize: "1.2vw",
    whiteSpace: "nowrap",
    color: "#00000085",
  },
  dateField: {
    marginTop: theme.spacing(1.3),
    "& input": {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5)
    },
  },
});