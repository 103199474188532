import React from "react";
import AddRemoveNewTestType from "@core/components/AddRemoveNewTestType";

const QuantityEdit = ({tests, groupedTests, test, setSelected}) => {
  return (
    <AddRemoveNewTestType
      selected={tests}
      type={test.type}
      setSelected={setSelected}
      numberSelected={groupedTests.length}
    />
  );
};

export default QuantityEdit;