import React, {useCallback, useState} from "react";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router";
import classNames from "classnames";
import {Table, TableHead, TableBody, TableRow, TableCell, Skeleton, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {QCPS} from "@core/constants/qcps";
import modules from "@core/constants/modules";
import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";
import EditableValue from "@core/components/EditableValue";
import QcpSideBar from "@core/components/QcpSideBar";
import TableSkeleton from "@core/components/TableSkeleton";
import styles from "./styles";
import useStores from "../../../../useStores";

const CampaignInfo = observer(({classes, type}) => {
  const {CampaignStore, UserStore} = useStores();

  const [qcpOpen, setQcpOpen] = useState(false);

  const {id: campaignId} = useParams();
  const campaign = CampaignStore.campaign;

  const company = UserStore.user.data.company;
  const [module] = company.modules;

  const isLoaded = campaignId === campaign._id;

  const toggleQcp = useCallback(() => {
    setQcpOpen(!qcpOpen);
  }, [qcpOpen]);

  return (
    <div className={classes.paper}>
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        {isLoaded ? `${type} Campaign` : <Skeleton width={200}/>}
      </Typography>
      <Table className={classNames("styled-table", classes.generalInfoTable)}>
        <TableHead>
          <TableRow>
            <TableCell width="50%">
              {isLoaded ? (
                campaign.creator._id === company._id ? "Manufacturer" : "Customer"
              ) : <Skeleton />}
            </TableCell>
            <TableCell width="50%">
              {isLoaded ? (
                campaign.creator._id === company._id ? campaign.manufacturer.name : campaign.creator.name
              ) : <Skeleton />}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            <>
              <TableRow>
                <TableCell width="50%">Name</TableCell>
                <TableCell width="50%">
                  <EditableValue
                    disabled={module.name === modules.WITNESS}
                    value={campaign.name}
                    onSave={(name) => CampaignStore.updateCampaignById(campaign._id, {name})}
                    placeholder="Campaign Name"
                    emptyText="Pending"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="50%">{campaign.creator.name} PO</TableCell>
                <TableCell width="50%">{campaign.purchaseOrder.orderNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="50%">{campaign.creator.name} PO Item</TableCell>
                <TableCell width="50%">{campaign.purchaseOrderItem}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="50%">{campaign.manufacturer.name} Work Order</TableCell>
                <TableCell width="50%">
                  <EditableValue
                    disabled={module.name === modules.WITNESS}
                    value={campaign.internalWorkOrder}
                    onSave={(internalWorkOrder) => CampaignStore.updateCampaignById(campaign._id, {internalWorkOrder})}
                    placeholder="Work Order"
                    emptyText="Pending"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="50%">QCP/ITP</TableCell>
                <TableCell width="50%">
                  {QCPS[campaign.qcp] ? (
                    <DrawerButtonTrigger
                      onClick={toggleQcp}
                      text={campaign.qcp}
                    />
                  ) : (campaign.qcp)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="50%">QCP/ITP Description</TableCell>
                <TableCell width="50%">
                  <EditableValue
                    disabled={module.name === modules.WITNESS}
                    value={campaign.description}
                    onSave={(description) => CampaignStore.updateCampaignById(campaign._id, {description})}
                    placeholder="QCP/ITP Description"
                    emptyText="-"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="50%">{campaign.type} type</TableCell>
                <TableCell width="50%">
                  <EditableValue
                    disabled={module.name === modules.WITNESS}
                    value={campaign.coatingType}
                    onSave={(coatingType) => CampaignStore.updateCampaignById(campaign._id, {coatingType})}
                    placeholder={`${campaign.type} type`}
                    emptyText="-"
                  />
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableSkeleton
              columns={2}
              rows={6}
            />
          )}
        </TableBody>
      </Table>
      <QcpSideBar
        open={qcpOpen}
        close={toggleQcp}
        qcp={campaign.qcp}
      />
    </div>
  );
});

export default withStyles(CampaignInfo, styles);