export default () => ({
  btnAddCell: {
    textAlign: "center"
  },

  btnAdd: {
    margin: "0 auto",
    width: "50%"
  },

  gridRoot: {
    marginTop: 70,
    marginBottom: 20
  }
});

