import React, {useState, useEffect} from "react";
import {head, drop, keys, omit} from "ramda";
import {observer} from "mobx-react-lite";
import {List, ListItem, Collapse} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useStores from "../../../useStores";
import styles from "./styles";

const Item = ({item, properties}) => (
  <div>
    {`Q: ${item.quantity}`}
    {keys(omit(["quantity", "_id"], item)).map((prop, idx) => {
      const property = properties.find((p) => p.name === prop);

      if (!property) return null;

      const {label, measurements} = property;

      return (
        <React.Fragment key={`${idx}_${property.name}`}>
          &nbsp;/&nbsp;
          {`${label}: ${item[prop]}${measurements ? measurements.toLowerCase() : ""}`}
        </React.Fragment>
      );
    })}
  </div>
);

const Items = observer(({certificate, classes, centered}) => {
  const [open, setOpen] = useState(false);

  const {CertificateStore} = useStores();

  useEffect(() => {
    const {isLoading, data} = CertificateStore.products;

    if (isLoading || data.length) return;

    CertificateStore.getProducts();
  }, []);

  if (!certificate.items) return null;

  const first = head(certificate.items) || {};
  const rest = drop(1, certificate.items) || [];

  const product = CertificateStore.products.data.find((p) => p._id === certificate.properties.productType?._id) || {};
  const productProperties = product.properties || [];

  return (
    <List>
      <ListItem
        classes={{root: centered ? classes.centered : classes.listItem}}
        disableGutters
        onClick={() => setOpen(!open)}
      >
        <Item
          item={first}
          properties={productProperties}
        />
        {rest.length ? (open ? <ExpandLess /> : <ExpandMore />) : null}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {rest.map((item) => (
          <ListItem
            classes={centered ? {root: classes.listItem} : {}}
            disableGutters
            dense
          >
            <Item
              key={item._id}
              item={item}
              properties={productProperties}
            />
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
});

export default withStyles(Items, styles);
