import {Checkbox, FormControlLabel, Grid, MenuItem} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {MuiAutocomplete} from "@core/components/Form";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {INTERFACE_TYPE, ORDER_STATUS} from "@core/constants/transfer";
import React from "react";
import {values as ramdaValues} from "ramda";
import useStores from "../../../../useStores";
import {useFormikContext} from "formik";

function CampaignDetailsFields() {
  const {TransferStore} = useStores();
  const {values, setFieldValue, isSubmitting} = useFormikContext();
  const customers = TransferStore.receivers;
  
  return (
    <Grid container rowGap={4} justifyContent="space-between">
      <Grid item xs={12} container columnGap={4}>
        <Grid item flex={1}>
          <TextField
            required
            disabled={isSubmitting}
            label='Campaign name'
            name='name'
          />
        </Grid>
        <Grid item flex={1}>
          <SelectField
            name='type'
            label="Campaign type"
            disabled={isSubmitting}
            required
          >
            {ramdaValues(CAMPAIGN_TYPES).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={5}>
          <FormControlLabel
            control={(
              <Checkbox
                name="connectToPO"
                checked={values.connectToPO}
                disabled={isSubmitting}
                onClick={() => {
                  setFieldValue("connectToPO", !values.connectToPO);
                  setFieldValue("customer", "");
                  setFieldValue("purchaseOrder", "");
                }}
              />
            )}
            label="Connect to PO"
          />
        </Grid>
      </Grid>
      {values.connectToPO && (
        <Grid item xs={12} container columnGap={4}>
          <Grid item flex={1}>
            <SelectField
              name='customer'
              label="Customer"
              required
              disabled={isSubmitting}
              onChange={() => {
                setFieldValue("purchaseOrder", "");
              }}
            >
              {customers.map((company) => (
                <MenuItem key={company._id} value={company._id}>
                  {company.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item flex={1}>
            <MuiAutocomplete
              url={`${ROUTES.TRANSFER[ACTIONS.ALL]}?status=${ORDER_STATUS.OPEN}&type=${INTERFACE_TYPE.SENDER}`}
              required
              disabled={!values.customer || isSubmitting}
              inputProps={{
                label: "Purchase order",
                name: "purchaseOrder",
                required: true,
                disabled: !values.customer || isSubmitting,
              }}
              onChange={(order) => setFieldValue("purchaseOrder", order)}
              formatOptions={(data) => data.items.map((item) => ({label: item.orderNumber, value: item}))}
              filterOptions={(options) => {
                return options.filter(({value: transfer}) => transfer.receiver?._id === values.customer);
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default CampaignDetailsFields;