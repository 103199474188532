import React, {useMemo} from "react";
import {Group, Text} from "react-konva";
import TooltipContainer from "../TooltipContainer";
import {getIsTieInWeld, getWeldIsRepaired} from "../../../../../../helpers";
import {STAGE_CONFIG} from "../../../../constants";
import {
  REPAIRED_WELD_CONFIG,
  TIE_IN_WELD_CONFIG,
  WELD_TOOLTIP_CONFIG
} from "../../../Stalk/constants";

const CONTENT_WIDTH = WELD_TOOLTIP_CONFIG.WIDTH - 2 * WELD_TOOLTIP_CONFIG.PADDING;

const WeldTooltip = ({node, scale, stage}) => {
  const isRepairedWeld = getWeldIsRepaired(node.data.weld);
  const isTieInWeld = getIsTieInWeld(node.data.weld);

  const scaleBy = useMemo(() => {
    const scaleBy = (STAGE_CONFIG.MAX_SCALE - scale) || 0.1;

    return Math.max(WELD_TOOLTIP_CONFIG.SCALE_MIN, scaleBy);
  }, [scale]);

  const weldTypes = useMemo(() => {
    const types = [];

    if(isTieInWeld) types.push("Tie-in");

    if(isRepairedWeld) types.push("Repair");
    
    return types.join("&");
  }, [node.data.weld]);
  
  const weldNumberColor = useMemo(() => {
    if(isRepairedWeld) return REPAIRED_WELD_CONFIG.BACKGROUND_COLOR;

    if(isTieInWeld) return TIE_IN_WELD_CONFIG.BACKGROUND_COLOR;

    return WELD_TOOLTIP_CONFIG.TEXT_COLOR;
  }, [node.data.weld]);

  return (
    <TooltipContainer
      node={node}
      config={WELD_TOOLTIP_CONFIG}
      scale={scaleBy}
      stage={stage}
    >
      <Group>
        <Text
          width={CONTENT_WIDTH}
          align="left"
          text="WELD"
          fill={WELD_TOOLTIP_CONFIG.LIGHT_TEXT_COLOR}
          fontSize={WELD_TOOLTIP_CONFIG.FONT_SIZE}
          fontFamily={STAGE_CONFIG.FONT_FAMILY}
        />
        <Text
          width={CONTENT_WIDTH}
          align="right"
          text={node.data.weld}
          fill={weldNumberColor}
          fontSize={WELD_TOOLTIP_CONFIG.FONT_SIZE}
          fontFamily={STAGE_CONFIG.FONT_FAMILY}
          fontStyle="bold"
        />
      </Group>
      <Group
        y={WELD_TOOLTIP_CONFIG.FONT_SIZE + WELD_TOOLTIP_CONFIG.MARGIN}
      >
        <Text
          width={CONTENT_WIDTH}
          align="right"
          text={weldTypes}
          fill={WELD_TOOLTIP_CONFIG.LIGHT_TEXT_COLOR}
          fontSize={WELD_TOOLTIP_CONFIG.GENERAL_TEXT_FONT_SIZE}
          fontFamily={STAGE_CONFIG.FONT_FAMILY}
          fontStyle="bold"
        />
      </Group>
    </TooltipContainer>
  );
};

export default WeldTooltip;