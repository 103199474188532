import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const CrackTipOpeningDisplacement = ({qcpConfig}) => (
  <>
    <h3>Local Requirements</h3>
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>CTOD min</TableCell>
          <TableCell>CTOD average min</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{qcpConfig.requirements.crackTipOpeningDisplacementMin || "-"}</TableCell>
          <TableCell>{qcpConfig.requirements.crackTipOpeningDisplacementAverageMin}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </>
);

export default CrackTipOpeningDisplacement;