import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {FormControl, FormHelperText, InputLabel} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Editor} from "react-draft-wysiwyg";
import styles from "./styles";
import classNames from "classnames";

const StyledEditor = ({classes, value, onChange, error}) => (
  <>
    <Editor
      editorClassName={classNames(classes.editorTextArea, {[classes.error]: error})}
      wrapperClassName={classes.editorWrapper}
      toolbarClassName={classes.editorToolbar}
      toolbar={{
        options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "remove"]
      }}
      editorState={value}
      onEditorStateChange={onChange}
    />
    {error && (
      <div className={classes.errorContainer}>
        <FormHelperText className={classes.errorMessage}>{error}</FormHelperText>
      </div>
    )}
  </>
);

const HtmlEditor = ({classes, label, value, onChange, error}) => {
  if (label) {
    return (
      <FormControl variant="standard">
        <InputLabel className={classes.editorLabel}>{label}</InputLabel>
        <StyledEditor
          classes={classes}
          value={value}
          onChange={onChange}
          error={error}
        />
      </FormControl>
    );
  }

  return (
    <StyledEditor
      classes={classes}
      value={value}
      onChange={onChange}
      error={error}
    />
  );
};

export default withStyles(HtmlEditor, styles);