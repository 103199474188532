export default (theme) => ({
  title: {
    fontSize: 28,
    color: "#616161",
    padding: 20,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
  },
  table: {
    border: "1px solid #e0e0e0",
    "& th": {
      padding: "5px 20px",
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
      border: "1px solid #e0e0e0",
    },
    "& td": {
      padding: "5px 20px",
      fontSize: 14,
    },
    "& td:nth-child(1)": {
      borderRight: "1px solid #e0e0e0",
    },
    "& td:nth-child(4)": {
      maxWidth: 500,
    }
  },
  specName: {
    textTransform: "capitalize",
  },
  passed: {
    color: "#4caf50",
  },
  failed: {
    color: "#D02D52",
  },
  paperWidth: {
    maxWidth: 1500,
  }
});