import {TYPES} from "@core/constants/test";

const DECLARATIONS = [
  {
    key: "Heat Treatment",
    value: TYPES.HEATTREATMENT,
  },
  {
    key: "Treatment",
    value: TYPES.TREATMENT
  }
];

const RELEENG_PRESET = {
  name: "Reeling",
  treatments: [{
    type: "treatment_test",
    properties: {
      elements: [
        {treatment: "Treatment", description: "Tension up to 1.7% strain"},
        {treatment: "Treatment", description: "Compress to original length"},
        {treatment: "Treatment", description: "Tension up to 1.7% strain"},
        {treatment: "Treatment", description: "Compress to original length"},
        {treatment: "Treatment", description: "Tension up to 1.7% strain"},
        {treatment: "Treatment", description: "Compress to original length"},
      ]
    },
  },

  {
    type: "heat_treatment",
    properties: {
      coolingMedium: "Still air",
      holdingTime: 1,
      holdingTimeUnits: "hr",
      images: [],
      secondCoolingMedium: "",
      secondHoldingTime: "",
      secondHoldingTimeUnits: "hr",
      secondTemp: "",
      strainHardening: false,
      temp: "250",
      tempUnits: "celsius",
      type: "Annealing",
    }
  }]
};

const PRESET_CONDITIONS = [{name: "None"}, {name: "Reeling"}];

const TESTS = [
  TYPES.CHEMICAL,
  TYPES.TENSILE,
  TYPES.IMPACT,
  TYPES.PROOFLOAD,
  TYPES.BEND,
  TYPES.CORROSION,
  TYPES.HARDNESS,
  TYPES.AUSTENITE,
  TYPES.FERRITE,
  TYPES.MICROGRAPHIC,
  TYPES.MACROGRAPHIC,
  TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
  TYPES.HYDROGEN_INDUCED_CRACKING,
  TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
  TYPES.OTHER,
];

export {
  RELEENG_PRESET,
  PRESET_CONDITIONS,
  TESTS,
  DECLARATIONS
};