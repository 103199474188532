import React from "react";
import {Skeleton} from "@mui/material";
import ProgressBar from "@core/components/ProgressBar";
import {STATUSES, TYPES} from "@core/constants/test";
import {DEFAULT_ALL_NUMBER, PROGRESS_BAR_LABELS_BY_STATUS} from "@core/constants/progressBar";
import themeSettings from "../../../theme-settings";

const TestsProgressBar = ({tests, isLoaded}) => {
  const existingTests = tests.filter((test) => test.status !== STATUSES.DELETED);
  const testsNumber = existingTests.length;

  const doneNumber = existingTests.filter((test) => test.status === STATUSES.APPROVED).length;

  const awaitingQaNumber = existingTests.filter((test) => {
    const isFilledByManufacturerWithNoWitness = test.status === STATUSES.FILLED && !test.witnesses.length && !test.assignee;
    const isDone = [STATUSES.SUBMITTED, STATUSES.INSPECTED].includes(test.status);
    const isUnsignedSplitTest = test.type === TYPES.SPLIT && test.status !== STATUSES.APPROVED;

    return isFilledByManufacturerWithNoWitness || isDone || isUnsignedSplitTest;
  }).length;

  const awaitingInspectionNumber = existingTests.filter((test) => test.status === STATUSES.INSPECTING).length;

  const awaitingDataNumber = existingTests.filter((test) => {
    const isSplitTest = test.type === TYPES.SPLIT;

    if(isSplitTest) return false;

    const isAssigned = test.status === STATUSES.ASSIGNED;
    const isEmpty = test.status === STATUSES.EMPTY;

    return isAssigned || isEmpty;
  }).length;

  const preliminaryNumber = existingTests.filter((test) => {
    const isSplitTest = test.type === TYPES.SPLIT;

    if(isSplitTest) return false;

    const toBeFilledByLab = test.status === STATUSES.FILLED && test.assignee;
    const toBeFilledByProducer = test.status === STATUSES.FILLED && !test.assignee && test.witnesses.length;

    return toBeFilledByLab || toBeFilledByProducer;
  }).length;

  if(!isLoaded) {
    return (
      <Skeleton height={25} />
    );
  }
  
  if(!tests.length) {
    return (
      <ProgressBar
        data={[{
          color: themeSettings.palette.awaitingData.backgroundColor,
          number: DEFAULT_ALL_NUMBER,
          title: PROGRESS_BAR_LABELS_BY_STATUS.AWAITING_DATA
        }]}
      />
    );
  }

  return (
    <ProgressBar
      data={[{
        color: themeSettings.palette.success.main,
        number: doneNumber,
        title: PROGRESS_BAR_LABELS_BY_STATUS.COMPLETE
      },
      {
        color: themeSettings.palette.warning.main,
        number: awaitingQaNumber,
        title:  PROGRESS_BAR_LABELS_BY_STATUS.AWAITING_QA
      },
      {
        color: themeSettings.palette.error.main,
        number: awaitingInspectionNumber,
        title:  PROGRESS_BAR_LABELS_BY_STATUS.AWAITING_INSPECTION
      },
      {
        color: themeSettings.palette.primary.main,
        number: preliminaryNumber,
        title:  PROGRESS_BAR_LABELS_BY_STATUS.PRELIMINARY
      },
      {
        color: themeSettings.palette.awaitingData.backgroundColor,
        number: awaitingDataNumber,
        title: PROGRESS_BAR_LABELS_BY_STATUS.AWAITING_DATA
      }
      ]}
      allNumber={testsNumber}
    />
  );
};

export default TestsProgressBar;