import React from "react";
import {FormHelperText, TextField} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const LoginTextField = ({classes, disabled, endAdornment, name, label, handleChange, value, error, touched}) => {
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        disabled={disabled}
        name={name}
        label={label}
        type="text"
        onChange={handleChange}
        value={value}
        error={Boolean(error) && touched}
        errorMessage={error}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          autocomplete: "off"
        }}
        InputProps={{
          endAdornment
        }}
      />
      {(Boolean(error) && touched) && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </>
  );
};

export default withStyles(LoginTextField, styles);