import React from "react";
import {useTheme} from "@mui/material";
import Line from "../Line";
import {PROGRESS_BAR_LABELS_BY_STATUS} from "@core/constants/progressBar";

const StackedProgressBar = ({data, allNumber, setActiveItem}) => {
  const theme = useTheme();
  
  const rest = allNumber - data.reduce((acc, item) => acc + item.number, 0);

  return (
    <>
      {data.map((item, idx) => (
        <Line
          key={`${idx}_${item.color}_${item.width}`}
          allNumber={allNumber}
          item={item}
          setActiveItem={setActiveItem}
        />
      ))}
      {Boolean(rest) && (
        <Line
          item={{
            title: PROGRESS_BAR_LABELS_BY_STATUS.AWAITING_DATA,
            color: theme.palette.awaitingData.backgroundColor,
            number: rest,
          }}
          allNumber={allNumber}
          setActiveItem={setActiveItem}
        />
      )}
    </>
  );
};

export default StackedProgressBar;
