import {getIsWithinRequirements} from "@core/helpers";
import {TestResult} from "./index";

export class TensileResult extends TestResult {
  elements = [];

  constructor(elements) {
    super();
    this.elements = elements;
    this.buildResult();
  }
  
  checkYieldStrength = (element) => {
    const {yeldMin, yeldMax} = element.requirements;
    
    return getIsWithinRequirements(element.rp, yeldMin, yeldMax);
  };

  checkTensileStrength = (element) => {
    const {tensMin, tensMax} = element.requirements;

    return getIsWithinRequirements(element.rm, tensMin, tensMax);
  };

  checkReductionOfArea = (element) => {
    const {reductionOfArea} = element.requirements;

    return getIsWithinRequirements(element.reductionOfArea, reductionOfArea);
  };

  checkElongation = (element) => {
    const {elongation} = element.requirements;

    return getIsWithinRequirements(element.a, elongation);
  };

  checkYieldTensileRatio = (element) => {
    const {yeildTensileRatio} = element.requirements;

    return getIsWithinRequirements(element.yeildTensileRatio, null, yeildTensileRatio);
  };

  checkUniformElongation = (element) => {
    const {uniformElongationMin} = element.requirements;

    return getIsWithinRequirements(element.uniformElongation, uniformElongationMin);
  };
  
  buildResult() {
    this.isAcceptable = this.elements.every((element) => {
      return this.checkYieldStrength(element) &&
        this.checkTensileStrength(element) &&
        this.checkReductionOfArea(element) &&
        this.checkElongation(element) &&
        this.checkYieldTensileRatio(element) &&
        this.checkUniformElongation(element);
    });
  }
}