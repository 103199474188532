export default (theme) => {
  return ({
    input: {
      padding: 5,
      minWidth: 40,
    },
    inputRoot: {
      width: "100%",
    },
    errorIcon: {
      marginLeft: 5,
      cursor: "pointer",
      color: theme.palette.error.main,
    },
    warningIcon: {
      marginLeft: 5,
      cursor: "pointer",
      color: theme.palette.warning.main,
    },
    container: {
      display: "flex",
      alignItems: "center"
    }
  });
};
