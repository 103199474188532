export default (theme) => ({
  errorIcon: {
    marginLeft: 5,
    cursor: "pointer",
    color: theme.palette.error.main,
  },
  warningIcon: {
    marginLeft: 5,
    cursor: "pointer",
    color: theme.palette.warning.main,
  },
  container: {
    display: "flex",
    alignItems: "center"
  }
});
