import React from "react";
import {Button, FormControl, Grid, InputLabel} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {FieldArray} from "formik";
import styles from "./styles";
import Uploader from "../Uploader";

const FilesUploader = ({classes, maxLength, name, files, onNewFile, changeFile, label}) => {
  if(!files) return null;
  
  return (
    <FormControl variant="standard" classes={{root: classes.uploader}}>
      {label && <InputLabel classes={{root: classes.uploaderLabel}}>{label}</InputLabel>}
      <FieldArray
        name={name}
        render={({remove, push, replace}) => (
          <Grid container alignItems="flex-end" spacing={3}>
            {files.map((image, index) => {
              return (
                <Grid item xs={12} key={image}>
                  <Uploader
                    fileType={"image/*, application/pdf, .csv"}
                    file={image}
                    buttonText={"Choose file"}
                    handleUploadedFile={(file) => changeFile(index, file, replace)}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              );
            })}
            {(!maxLength || files.length < maxLength) && (
              <Grid item xs={12}>
                <Uploader
                  fileType={"image/*, application/pdf, .csv"}
                  file=""
                  buttonText={"Choose file"}
                  handleUploadedFile={(file) => onNewFile(file, push)}
                />
              </Grid>
            )}
          </Grid>
        )}
      />
    </FormControl>
  );
};

export default withStyles(FilesUploader, styles);