import {action, makeObservable, observable} from "mobx";

class Signer {
  constructor() {
    makeObservable(this, {
      open: observable,
      qrData: observable,
      state: observable,
      configure: observable,
      status: observable,
      callback: observable,
      openSigner: action,
      changeState: action,
      changeStatus: action,
      closeSigner: action,
    });
  }
  open = false;

  qrData = "";

  state = "default";

  configure = {
    blocked: false
  };

  status = false;

  callback = null;

  openSigner(qrData = "", config, callback) {
    this.open = true;
    this.qrData = qrData;
    this.configure = {...this.configure, ...config};
    this.callback = callback;
  }

  changeState(state) {
    this.state = state;
  }

  changeStatus(status) {
    this.status = status;
  }

  closeSigner() {
    this.open = false;

    if (!this.status && this.callback) {
      this.callback();
      this.callback = null;
      this.status = false;
    }
  }
}

export default new Signer();
