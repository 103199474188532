import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const Acceptance = ({qcpConfig}) => (
  <>
    <h3>Local Requirements</h3>
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Crack Length Ratio</TableCell>
          <TableCell>Crack Thickness Ratio</TableCell>
          <TableCell>Crack Sensitivity Ratio</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Min</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Max</TableCell>
          <TableCell>{qcpConfig.requirements.crackLengthRatioMax || "-"}</TableCell>
          <TableCell>{qcpConfig.requirements.crackThicknessRatioMax || "-"}</TableCell>
          <TableCell>{qcpConfig.requirements.crackSensitivityRatioMax || "-"}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </>
);

export default Acceptance;