import React from "react";
import {withStyles} from "tss-react/mui";
import Avatar from "@core/components/Avatar";
import MODULES from "@core/constants/modules";
import styles from "./styles";

const ColoredModuleAvatar = ({classes, user, className, module, ...props}) => {
  const AVATAR_COLORS_BY_MODULE = {
    [MODULES.PRODUCER]: classes.yellow,
    [MODULES.BASIC]: classes.yellow,
    [MODULES.WITNESS]: classes.red,
    [MODULES.LAB_INTERNAL]: classes.blue,
  };

  const colorClassName = AVATAR_COLORS_BY_MODULE[module] || classes.default;

  return (
    <Avatar
      user={user}
      colorClassName={colorClassName}
      className={className}
      {...props}
    />
  );
};

export default withStyles(ColoredModuleAvatar, styles);