import React, {useState} from "react";
import * as yup from "yup";
import draftToHtml from "draftjs-to-html";
import {observer} from "mobx-react-lite";
import {EditorState, convertToRaw} from "draft-js";
import {Grid, Button} from "@mui/material";
import Form from "../Form";
import useStores from "../../../../useStores";
import {STATUSES} from "@core/constants/order";

const validationSchema = yup.object().shape({
  message: yup.object().required("This field is required!"),
});

const EMPTY_MESSAGE = EditorState.createEmpty();

const NewMessage = ({lineItem, certificateId, index, lineItemStatus}) => {
  const {CertificateStore} = useStores();

  const [showForm, setShowForm] = useState(false);

  const onSubmit = async (values, {resetForm}) => {
    const message = values.message.getCurrentContent().hasText();

    if(!message) return;

    const data = {
      message: draftToHtml(convertToRaw(values.message.getCurrentContent())),
    };

    await CertificateStore.updateFeedback(certificateId, lineItem, index, data);
    resetForm();
  };

  return (
    <Grid item container xs={12}>
      {!showForm && lineItemStatus !== STATUSES.ACCEPTED && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowForm(true)}
        >
            Reply
        </Button>
      )}
      {showForm && (
        <Form
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialValues={{message: EMPTY_MESSAGE}}
        />
      )}
    </Grid>
  );
};

export default observer(NewMessage);