import React, {useState} from "react";
import {Modal, Paper, Button, Grid, Fade, InputAdornment, IconButton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Input} from "@core/components/Form";
import {Formik} from "formik";
import styles from "./styles";
import {observer} from "mobx-react-lite";
import * as yup from "yup";
import {MdVisibility, MdVisibilityOff} from "react-icons/md/";
import Loader from "@core/components/Loader";
import {validatePassword} from "@core/helpers";

const ChangePasswordModal = observer((props) => {

  const [loading, setLoading] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showNewPassConfirm, setShowNewPassConfirm] = useState(false);
  const {classes} = props;

  const initialValues = {
    oldPass: "",
    newPass: "",
    newPassConfirm: ""
  };

  const validationSchema = yup.object().shape({
    oldPass: yup.string().required("Old password is required!"),
    newPass: yup.string()
      .required("Password is required!")
      .test("password", validatePassword),
    newPassConfirm: yup.string()
      .required("Please confirm your password")
      .oneOf([yup.ref("newPass"), null], "Passwords don't match")
  });

  const handleChangePassword = (values) => {
    setLoading(true);
    const data = {
      oldPassword: values.oldPass,
      newPassword: values.newPass
    };
    
    try {
      props.handleChangePassword(data);
      setLoading(false);
      props.onClose();
    } catch(e) {
      setLoading(false);
      props.onClose();
    }
  };

  return (
    <Modal open={props.opened} onClose={!loading && props.onClose} >
      <Fade in={props.opened} >
        <Paper className={classes.paper}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleChangePassword}
            render={(props) => {
              const {
                values: {
                  oldPass,
                  newPass,
                  newPassConfirm
                },
                errors,
                isValid,
                handleSubmit,
                handleChange,
                touched,
                setFieldTouched
              } = props;
              const change = (name, e) => {
                handleChange(e);
                setFieldTouched(name, true, false);
              };

              return (
                <Grid container spacing={4} direction={"column"}>
                  <Grid item xs={12} className={classes.heading}>
                    Password changing
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label="Old password"
                      name='oldPass'
                      value={oldPass}
                      onChange={(e) => change("oldPass", e)}
                      error={Boolean(errors.oldPass) && touched.oldPass}
                      errorMessage={errors.oldPass}
                      type={showOldPass ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position={"end"}>
                          <IconButton edge="end" onClick={() => setShowOldPass(!showOldPass)} size="large">
                            {showOldPass ? <MdVisibility /> : <MdVisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label="New password"
                      name='newPass'
                      value={newPass}
                      onChange={(e) => change("newPass", e)}
                      error={Boolean(errors.newPass) && touched.newPass}
                      errorMessage={errors.newPass}
                      type={showNewPass ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position={"end"}>
                          <IconButton edge="end" onClick={() => setShowNewPass(!showNewPass)} size="large">
                            {showNewPass ? <MdVisibility /> : <MdVisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label="Confirm new password"
                      name='newPassConfirm'
                      value={newPassConfirm}
                      onChange={(e) => change("newPassConfirm", e)}
                      error={Boolean(errors.newPassConfirm) && touched.newPassConfirm}
                      errorMessage={errors.newPassConfirm}
                      type={showNewPassConfirm ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position={"end"}>
                          <IconButton
                            edge="end"
                            onClick={() => setShowNewPassConfirm(!showNewPassConfirm)}
                            size="large">
                            {showNewPassConfirm ? <MdVisibility /> : <MdVisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.submitContainer}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      size={"large"}
                      disabled={!isValid || loading}
                      onClick={handleSubmit}
                    >
                      {loading ? "processing..." : "submit"}
                    </Button>
                    {loading && <div className={classes.loader}><Loader /></div>}
                  </Grid>
                </Grid>
              );
            }}
          />
        </Paper>
      </Fade>

    </Modal>
  );
});

export default withStyles(ChangePasswordModal, styles);
