import React, {Component} from "react";
import {Grid, Dialog, DialogContent} from "@mui/material";
import {withStyles} from "tss-react/mui";
import userService from "@core/api/user-service";
import {compose} from "ramda";
import {inject, observer} from "mobx-react";
import ExaminationConditions from "@core/components/ExaminationConditions";
import LiquidePenetrantTestForm from "@core/components/TestForms/LiquidePenetrant";
import styles from "./styles";

class LiquidePenetrant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addDataOpen: false
    };
  }

  dataDialogClose = () => {
    this.setState({addDataOpen: false});
  };

  updateTest = (data) => {
    const {TestStore, CertificateStore} = this.props;

    userService.getUserInfo()
      .then(async () => {
        await TestStore.update({properties: data}, this.props.test._id);
        CertificateStore.updateTests({...this.props.test, properties: data});
      });

    this.dataDialogClose();
  };

  render() {
    const {
      test,
      classes
    } = this.props;

    return (
      <>

        <Grid container alignContent="space-between" spacing={2} className={classes.marginBottom}>
          <Grid item xs={6}>
            <h4 className={classes.name}>Test Standard</h4>
            <p className={classes.value}>{test.properties.testStandard}</p>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.name}>Acceptance criteria</h4>
            <p className={classes.value}>{test.properties.acceptance}</p>
          </Grid>
          <Grid item xs={12}>
            <h4 className={classes.name}>Examination conditions</h4>
            <p className={classes.value}>{test.properties.condition}</p>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.name}>Quantity Inspected</h4>
            <p className={classes.value}>{test.properties.quantityInspected}</p>
          </Grid>

          <Grid item xs={6}>
            <h4 className={classes.name}>Piece Identification:</h4>
            <p className={classes.value}>{test.properties.pieceIdentification}</p>
          </Grid>
        </Grid>

        <ExaminationConditions
          test={test}
          file={test.properties.file}
        />

        <Grid container spacing={2}>
          <Grid item>
            <h3>Examination technique</h3>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" spacing={2} className={classes.marginBottom}>
          <Grid item xs={6}>
            <h4 className={classes.name}>Type</h4>
            <p className={classes.value}>{test.properties.type}</p>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.name}>Method</h4>
            <p className={classes.value}>{test.properties.method}</p>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <h3>Liquid penetrant consumables</h3>
          </Grid>
        </Grid>

        <Grid container alignContent="space-between" spacing={2} className={classes.marginBottom}>
          <Grid item xs={6}>
            <h4 className={classes.name}>Manufacturer of consumables</h4>
            <p className={classes.value}>{test.properties.tradeName}</p>
          </Grid>
          <Grid item xs={3}>
            <h4 className={classes.name}>Penetrant</h4>
            <p className={classes.value}>{test.properties.penetrant}</p>
          </Grid>
          <Grid item xs={3}>
            <h4 className={classes.name}>Developer</h4>
            <p className={classes.value}>{test.properties.dev}</p>
          </Grid>
          {
            (test.properties.method === "Method B: Post-emulsifiable, lipophilic" || test.properties.method === "Method D: Post-emulsifiable, hydrophilic") && (
              <Grid item xs={6}>
                <h4 className={classes.name}>Emulsifier</h4>
                <p className={classes.value}>{test.properties.emulsifier}</p>
              </Grid>
            )
          }
          {

            (test.properties.method === "Method B: Post-emulsifiable, lipophilic" || test.properties.method === "Method C: Solvent removable") && (
              <Grid item xs={6}>
                <h4 className={classes.name}>Penetrant remover</h4>
                <p className={classes.value}>{test.properties.cleaner}</p>
              </Grid>
            )
          }
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <h3>Examination methods and parameters</h3>
          </Grid>
        </Grid>

        <Grid container alignContent="space-between" spacing={2} className={classes.marginBottom}>
          <Grid item xs={4}>
            <h4 className={classes.name}>Penetrant application by</h4>
            <p className={classes.value}>{test.properties.penetrantApp}</p>
          </Grid>
          <Grid item xs={4}>
            <h4 className={classes.name}>Dwell time</h4>
            <p className={classes.value}>{test.properties.dwelTime}</p>
          </Grid>
        </Grid>
        {(test.properties.method === "Method B: Post-emulsifiable, lipophilic" || test.properties.method === "Method D: Post-emulsifiable, hydrophilic") && (
          <Grid container alignContent="space-between" spacing={2} className={classes.marginBottom}>
            <Grid item xs={4}>
              <h4 className={classes.name}>Emulsifier application by</h4>
              <p className={classes.value}>{test.properties.emulsifierApp}</p>
            </Grid>
            <Grid item xs={4}>
              <h4 className={classes.name}>Emulsification time</h4>
              <p className={classes.value}>{test.properties.emulsificationTime}</p>
            </Grid>
          </Grid>
        )
        }
        <Grid container alignContent="space-between" spacing={2} className={classes.marginBottom}>
          <Grid item>
            <h4 className={classes.name}>Penetrant removal by</h4>
            <p className={classes.value}>{test.properties.removalBy}</p>
          </Grid>
        </Grid>

        <Grid container alignContent="space-between" spacing={2} className={classes.marginBottom}>
          <Grid item xs={4}>
            <h4 className={classes.name}>Developer application by</h4>
            <p className={classes.value}>{test.properties.devApp}</p>
          </Grid>
          <Grid item xs={4}>
            <h4 className={classes.name}>Developing time</h4>
            <p className={classes.value}>{test.properties.devTime}</p>
          </Grid>
        </Grid>

        {test.properties.additionalRemarks && (
          <>
            <Grid container spacing={2}>
              <Grid item>
                <h3>Notes</h3>
              </Grid>
            </Grid>
            <Grid container>
              <p
                className={classes.value}
                dangerouslySetInnerHTML={{__html: test.properties.additionalRemarks.split("\n").join("</br>")}}
              />
            </Grid>

          </>
        )}

        <Dialog
          open={this.props.addDataOpen}
          onClose={this.props.dataDialogClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent className={classes.dialogContent}>
            <LiquidePenetrantTestForm
              formRef={this.props.formRef}
              isProducerTest
              saveTest={this.props.updateTest}
              test={test}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default compose(
  inject("TestStore", "SigningStore", "CertificateStore", "NotificationStore"),
)(withStyles(observer(LiquidePenetrant), styles));
