import React from "react";
import {Grid, DialogContent, Dialog, List, ListItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import StatementTestForm from "@core/components/TestAddDataForms/StatementTest";

const Statement = (props) => {
  const {classes, test, formRef} = props;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <List>
                {test.properties.elements.map((el, index) =>
                  <ListItem key={index} className={classes.li}>
                    {el.statement}
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {props.addDataOpen && <Dialog
        open={props.addDataOpen}
        onClose={props.dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent className={classes.dialogContent}>
          <StatementTestForm
            formRef={formRef}
            saveTest={props.updateTest}
            test={test}
          />
        </DialogContent>
      </Dialog>}
    </>
  );
};

export default withStyles(Statement, styles);
