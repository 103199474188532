import React, {useEffect, useState} from "react";
import {Dialog, Button, Typography, Grid, DialogContent, MenuItem, DialogTitle} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {Formik, FieldArray} from "formik";
import styles from "./styles";
import * as yup from "yup";
import {keys, omit, map, indexBy, prop, values as getValues, pick, equals, isEmpty} from "ramda";
import draftToHtml from "draftjs-to-html";
import {EditorState, convertToRaw} from "draft-js";
import {FilesUploader} from "@core/components/Uploaders";
import HtmlEditor from "@core/components/HtmlEditor";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import ProductTypesAutocomplete from "@core/components/ProductTypeAutocomplete";
import {getProductTypeFields} from "@core/helpers/getProductTypeFields";
import useStores from "../../../useStores";
import WS from "@core/api/socketConnection";
import {CertificateService} from "@core/services";

const validationSchema = yup.object().shape({
  deliveryCondition: yup.string(),
  certificateType: yup.string().required("The field is required!"),
  items: yup.array().of(yup.lazy((item) =>
    yup.object(
      map(() => yup.string().required("This field is required"), item)
    ))).required(),
  markingText: yup.string(),
  markingFiles: yup.array().of(yup.string()),
  norm: yup.string().required("Material specification is required"),
  grade: yup.string().required("The field is required!"),
  productType: yup.object().required("Product type is required!"),
  notes: yup.object()
});

const MultipleConvertModal = ({
  classes,
  convertOpened,
  onClose,
  certificates,
  fetchCertificates,
  setSelected,
}) => {
  const {CertificateStore, NotificationStore, SigningStore} = useStores();

  const [convertValues, setConvertValues] = useState({});
  const [convertAllowed, setConvertAllowed] = useState(false);

  const defaultValues = {
    deliveryCondition: convertValues.deliveryCondition || certificates[0]?.deliveryCondition,
    certificateType: convertValues.certificateType || certificates[0]?.certificateType,
    items: convertValues.items || certificates[0]?.items,
    markingText: convertValues.markingText || certificates[0]?.markingText,
    markingFiles: convertValues.markingFiles || [],
    norm: convertValues.properties?.norm || certificates[0]?.properties?.norm,
    grade: convertValues.properties?.grade || certificates[0]?.properties?.grade,
    productType: convertValues.properties?.productType || certificates[0]?.properties?.productType,
  };

  isEmpty(convertValues) && certificates.forEach((item) => {
    const currentValues = {
      ...pick([
        "deliveryCondition",
        "certificateType",
        "items",
        "markingText",
      ], item),
      markingFiles: [],
      ...pick([
        "norm",
        "grade",
        "productType",
      ], item.properties),
    };

    for (const current in currentValues) {
      if (!equals(currentValues[current], defaultValues[current])) {
        if (current === "items") defaultValues[current] = [];
        else defaultValues[current] = "";
      }
    }
  });

  const convert = async () => {
    const data = certificates.map((certificate) => ({
      certificateId: certificate._id,
      newProperties: {...certificate.properties, ...convertValues.properties},
      oldProperties: certificate.properties,
      newItems: convertValues.items,
      oldItems: certificate.items,
      certificateType: convertValues.certificateType,
      notes: convertValues.notes,
      oldNotes: certificate.notes,
      markingText: convertValues.markingText,
      markingFiles: convertValues.markingFiles,
      deliveryCondition: convertValues.deliveryCondition,
    }));

    await CertificateStore.convertMultipleProductType(data);

    setSelected([]);
    fetchCertificates();

    onClose();
    setConvertAllowed(false);
    NotificationStore.showSuccess("Product has been converted");
  };

  useEffect(() => {
    WS.listen("transaction:certificate:convert", (data) => {
      SigningStore.closeSigner();

      if (data.status !== "ACCEPTED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      setConvertAllowed(true);
    });
  }, []);

  useEffect(() => {
    if (convertAllowed) convert();
  }, [convertAllowed]);

  const onSubmit = async (values) => {
    const data = {
      ...pick([
        "deliveryCondition",
        "certificateType",
        "items",
        "markingText",
        "markingFiles"
      ], values),
      properties: {
        norm: values.norm,
        grade: values.grade,
        productType: values.productType,
      },
      notes: convertToRaw(values.notes.getCurrentContent()).blocks[0].text ? draftToHtml(convertToRaw(values.notes.getCurrentContent())) : ""
    };

    const updatedCertificates = certificates.map((certificate) => ({
      ...certificate,
      ...data,
      properties: {
        ...certificate.properties,
        ...data.properties
      }
    }));

    setConvertValues(data);
    await CertificateService.convert(updatedCertificates);
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={convertOpened}
      onClose={onClose}
    >
      <DialogTitle>Multiple Convert</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h5" variant="h5">
              Convert to
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                ...defaultValues,
                notes: EditorState.createEmpty()
              }}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {(props) => {
                const {
                  values,
                  isValid,
                  handleSubmit,
                  setFieldValue
                } = props;
                const productProperties = (defaultValues.productType && defaultValues.productType.properties) || [];
                const [properties, setProperties] = useState(productProperties);

                const {norms: allNorms} = useFetchedCertificateNorms();

                const norms = allNorms.filter((n) => n.Norm === values.norm);
                const normsByMaterial = indexBy(prop("Material"), norms) || {};

                const normChange = ({norm, value}) => {
                  setFieldValue("norm", norm);

                  if (!value) {
                    setFieldValue("grade", "");

                    return;
                  }

                  if (value.length === 1) {
                    const [grade] = value;
                    materialGradeChange(grade);

                    return;
                  }

                  setFieldValue("grade", "");
                };

                const materialGradeChange = (value) => {
                  value && setFieldValue("grade", value.Material);
                };

                return (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <NormAutocomplete
                          label="Material Specification"
                          name="norm"
                          testType="certificate"
                          onChange={normChange}
                          required
                        />
                      </Grid>

                      {values.norm && (
                        <Grid item xs={3}>
                          <SelectField
                            value={values.grade}
                            label="Grade / UNS"
                            name="grade"
                            onChange={(value) => materialGradeChange(normsByMaterial[value])}
                            required
                          >
                            {getValues(normsByMaterial).map((item) => (
                              <MenuItem key={item.Material} value={item.Material}>{item.Material}</MenuItem>
                            ))}
                          </SelectField>
                        </Grid>
                      )}

                      {normsByMaterial[props.values.grade]?.DeliveryConditions && (
                        <Grid item xs={3}>
                          <SelectField
                            label="Delivery condition"
                            name="deliveryCondition"
                          >
                            {keys(normsByMaterial[props.values.grade].DeliveryConditions).map((item) => (
                              <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))}
                          </SelectField>
                        </Grid>
                      )}
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <SelectField
                          name="certificateType"
                          label='Certificate type'
                          required
                        >
                          {["3.1", "3.2"].map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item xs={2}>
                        <ProductTypesAutocomplete
                          inputProps={{
                            label: "Product type",
                            name: "productType.internalName",
                            required: true
                          }}
                          onChange={(value) => {
                            if (value.name === values.productType.name) return;

                            if (!value) {
                              setFieldValue("productType", "");
                              setFieldValue("items", []);
                            } else {
                              setProperties(value.properties);
                              setFieldValue("productType", value);
                              setFieldValue("items", [{quantity: "", ...getProductTypeFields(value.properties)}]);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>

                    <FieldArray name="items">
                      {({remove}) => values.items.map((item, index) => (
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <TextField
                              name={`items.${index}.quantity`}
                              label="Quantity"
                              required
                            />
                          </Grid>

                          {keys(omit(["quantity", "_id"], item)).map((name) => {
                            const property = properties && properties.find((p) => p.name === name);

                            if (!property) return null;

                            return (
                              <Grid item xs={2}>
                                <TextField
                                  label={property.label}
                                  name={`items.${index}.${name}`}
                                  required
                                  endAdornment={property.measurements}
                                />
                              </Grid>
                            );
                          })}

                          {values.items.length > 1 && (
                            <Grid item xs={1} container alignItems="flex-end">
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </FieldArray>

                    <Grid container spacing={5}>
                      <Grid item>
                        <Button
                          className={classes.addButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const items = values.items.concat(getProductTypeFields(properties));
                            setFieldValue("items", items);
                          }}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={5}>
                      <Grid item xs={10}>
                        <TextField
                          multiline
                          rows={3}
                          label='Marking'
                          name='markingText'
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={6}>
                        <FilesUploader
                          name="markingFiles"
                          files={values.markingFiles}
                          onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                          changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={5}>
                      <Grid item>
                        <HtmlEditor
                          label="Notes"
                          value={values.notes}
                          onChange={(contentState) => setFieldValue("notes", contentState)}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={5} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          size={"large"}
                          variant={"contained"}
                          color={"primary"}
                          onClick={handleSubmit}
                          disabled={!isValid}
                        >
                          Convert
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(MultipleConvertModal, styles);
