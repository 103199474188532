import React, {useEffect} from "react";
import {keys, indexBy, prop, omit} from "ramda";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import styles from "./styles";
import useStores from "../../../../useStores";

const SplitHistory = observer(({classes, productType, items}) => {
  const {CertificateStore} = useStores();

  useEffect(() => {
    const {isLoading, data} = CertificateStore.products;

    if (isLoading || data.length) return;

    CertificateStore.getProducts();
  }, []);

  const product = CertificateStore.products.data.find((p) => p._id === productType) || {};
  const productProperties = product.properties || [];
  const propertyByKey = indexBy(prop("name"), productProperties);

  return (
    <div className={classes.box}>
      <div>Items</div>
      <div>
        {items.map((item) => (
          <p className={classes.item}>
            <span>Quantity: {item.quantity}</span>
            {keys(omit(["quantity", "_id"], item)).map((prop) => {
              if (!propertyByKey[prop]) return null;

              return (
                <>
                  &nbsp;/&nbsp;
                  <span>{propertyByKey[prop].label}: {`${item[prop]}${propertyByKey[prop].measurements || ""}`}</span>
                </>
              );
            })}
          </p>
        ))}
      </div>
    </div>
  );
});

export default withStyles(SplitHistory, styles);
