import WS from "@core/api/socketConnection";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import SupplierFeedbackBox from "@core/components/SupplierFeedbackBox";
import {STATUSES} from "@core/constants/order";
import {TestService} from "@core/services";
import {Button, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import axios from "axios";
import {observer} from "mobx-react";
import {isEmpty} from "ramda";
import React, {useEffect, useState} from "react";
import useStores from "../../../../useStores";
import styles from "./styles";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const ApprovePortalCertificate = observer(({certificate, user, classes}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [feedbackBoxOpen, setFeedbackBoxOpen] = useState(false);
  const [lineItemStatus, setLineItemStatus] = useState();
  const [feedbackApprovable, setFeedbackApprovable] = useState(false);

  const {SigningStore, NotificationStore, CertificateStore} = useStores();

  const getStatus = async () => {
    const response = await axios.get(ROUTES.PORTAL_CERTIFICATE[ACTIONS.SINGLE](certificate._id, certificate.lineItem));

    setLineItemStatus(response.data.status);
  };

  const getFeedback = () => {
    CertificateStore.getFeedback(certificate._id, certificate.lineItem);
  };

  const listenCertificateApproved = () => {
    WS.listen("transaction:test:approve", (data) => {
      SigningStore.closeSigner();

      if (data.status !== "ACCEPTED") NotificationStore.showError("Something went wrong!");
      else {
        setLineItemStatus(STATUSES.ACCEPTED);
        // OrdersStore.acceptOrder(certificate._id, certificate.lineItem);
        NotificationStore.showSuccess("Successfully approved!");
      }
    });
  };

  useEffect(() => {
    getStatus();
    getFeedback();
    listenCertificateApproved();
  }, []);

  if (!certificate.createdByPortal) return null;

  return (
    <>
      <Confirmation
        open={confirmOpen}
        alertText="Are you sure to decline order?"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          // await OrdersStore.declineOrder(certificate._id, certificate.lineItem);
          setLineItemStatus(STATUSES.DECLINED);
          setConfirmOpen(false);
          setFeedbackBoxOpen(true);
        }}
      />
      <SupplierFeedbackBox
        approvable={feedbackApprovable}
        certificateId={certificate._id}
        lineItem={certificate.lineItem}
        onClose={() => setFeedbackBoxOpen(false)}
        open={feedbackBoxOpen}
        lineItemStatus={lineItemStatus}
      />
      {[STATUSES.DECLINED, STATUSES.PENDING].includes(lineItemStatus) && (
        <Grid item>
          <Button
            className={classes.approveButton}
            variant="contained"
            size="large"
            onClick={() => {
              if (CertificateStore.feedback.data.every((f) => f.isClosed)) {
                TestService.approve(certificate.tests, certificate, user._id);
              } else {
                setFeedbackBoxOpen(true);
                setFeedbackApprovable(true);
              }
            }}
          >
            Approve
          </Button>
        </Grid>)}
      {lineItemStatus === STATUSES.PENDING && (
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => setConfirmOpen(true)}
          >
            Decline
          </Button>
        </Grid>
      )}
      {lineItemStatus === STATUSES.DECLINED ||
        (lineItemStatus === STATUSES.ACCEPTED && !isEmpty(CertificateStore.feedback.data)) ? (
          <Grid item>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => setFeedbackBoxOpen(true)}
            >
              History
            </Button>
          </Grid>
        ) : null}
    </>
  );
});

export default withStyles(ApprovePortalCertificate, styles);
