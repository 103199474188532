import React, {useEffect} from "react";
import {FieldArray} from "formik";
import {observer} from "mobx-react-lite";
import {Grid, MenuItem, Button, Dialog, DialogContent, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {map, prop, uniqBy, flatten} from "ramda";
import SelectField from "@core/components/FormikSelect";
import ComparisonForm from "../ComparisonForm";
import styles from "./styles";
import useStores from "../../../../useStores";

const EMPTY_ITEM = {
  field: "",
  operator: "",
  value: "",
  secondValue: ""
};

const ItemsForm = observer(({classes, name, items, setFieldValue, isOpen, onClose, removeItems}) => {

  const {CertificateStore} = useStores();

  useEffect(() => {
    if (!items.value) setFieldValue(`${name}.value`, [[EMPTY_ITEM]]);

    const {isLoading, data} = CertificateStore.products;

    if(isLoading || data.length) return;

    CertificateStore.getProducts();
  }, []);

  if (!items.value) return null;

  const properties = flatten(map(prop("properties"), CertificateStore.products.data));
  const uniqueProperties = uniqBy(prop("name"), properties);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth='md'
    >
      <DialogContent>
        <FieldArray
          name={`${name}.value`}
        >
          {(itemHelpers) => (
            <Grid container spacing={3}>
              {items.value.map((item, itemIndex) => (
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Typography component="h5" variant="h5" gutterBottom>
                      {`Item: ${itemIndex + 1}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name={`${name}.value.${itemIndex}`}
                    >
                      {(fieldHelpers) => item.map((field, fieldIndex) => (
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                            <SelectField
                              name={`${name}.value.${itemIndex}.${fieldIndex}.field`}
                              label='Field'
                              required
                            >
                              {uniqueProperties.map(({name, label}) => (
                                <MenuItem value={name}>{label}</MenuItem>
                              ))}
                            </SelectField>
                          </Grid>
                          <ComparisonForm
                            xs={3}
                            name={`${name}.value.${itemIndex}.${fieldIndex}`}
                            item={field}
                          />
                          <Grid item xs={1} container alignItems="flex-end">
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                if (items.value.length === 1 && item.length === 1) removeItems();

                                if (item.length === 1) itemHelpers.remove(itemIndex);
                                else fieldHelpers.remove(fieldIndex);
                              }}
                            >
                              Remove
                            </Button>
                          </Grid>
                          {fieldIndex === item.length - 1 && (
                            <Grid item xs={12}>
                              <Button
                                onClick={() => fieldHelpers.push(EMPTY_ITEM)}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                              >
                                Add field
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </FieldArray>
                  </Grid>
                </Grid>
              ))}
              <Grid item container justifyContent="flex-end" spacing={3}>
                <Grid item>
                  <Button
                    onClick={() => itemHelpers.push([EMPTY_ITEM])}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                  >
                    Add item
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </FieldArray>
      </DialogContent>
    </Dialog>
  );
});

export default withStyles(ItemsForm, styles);
