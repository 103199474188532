export default (theme) => ({
  notAcceptable: {
    color: theme.palette.error.main,
  },
  acceptable: {
    color: theme.palette.success.main,
  },
  link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  resultContainer: {
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      whiteSpace: "nowrap",
    },
    alignItems: "center",
  },
  icon: {
    padding: 0,
  },
  input: {
    padding: 5,
  },
  inputRoot: {
    marginRight: 5,
    width: "85%",
  },
});
