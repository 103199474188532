import React, {useCallback, useState, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {
  RadioGroup,
  Radio,
  Grid,
  IconButton,
  Tooltip,
  Popover,
  FormControlLabel,
  Checkbox,
  useTheme
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import {VIEWS, LEGEND_KEY, VIEW_KEY, STATE} from "../../../../constants";
import useStores from "../../../../../../../../../../useStores";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {flatten} from "ramda";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

const Settings = observer(({classes, isFullScreen, container, setSettings, settings, onChangeView, view, state}) => {
  const [defaultView, setDefaultView] = useState(view);
  const [anchorEl, setAnchorEl] = useState(null);

  const {CampaignStore} = useStores();

  const theme = useTheme();

  const campaign = CampaignStore.campaign;
  const transfers = CampaignStore.transfers;

  const coatingCampaigns = useMemo(() => {
    return flatten(transfers.map((transfer) => transfer.campaigns))
      .filter((campaign) => campaign.type === CAMPAIGN_TYPES.COATING);
  }, [transfers.length]);

  const onNewSettings = useCallback((changes) => {
    setSettings({...settings, ...changes});
  }, [settings]);

  const onChangeDefaultView = useCallback((e) => {
    const view = e.target.value;
    localStorage.setItem(VIEW_KEY + campaign._id, view);
    onChangeView(view);
    setDefaultView(view);
  }, []);

  const toggleLegend = useCallback(() => {
    localStorage.setItem(LEGEND_KEY, !settings.legend);
    setSettings({...settings, legend: !settings.legend});
  }, [settings]);

  return (
    <>
      <Popover
        container={isFullScreen ? container.current : document.body}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Grid container direction="column" className={classes.container}>
          <Grid item>
            <h3>Settings</h3>
          </Grid>
          <Grid item>
            <FormControlLabel
              onChange={() => onNewSettings({welds: !settings.welds})}
              checked={settings.welds}
              control={(
                <Checkbox
                  color="primary"
                />
              )}
              label="Show Welds"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              onChange={() => onNewSettings({tieInWelds: !settings.tieInWelds})}
              checked={settings.tieInWelds}
              control={(
                <Checkbox
                  color="primary"
                />
              )}
              label={state === STATE.AS_BUILT ? "Highlight Tie-in welds" : "Show Tie-in welds"}
            />
          </Grid>
          {/* <Grid item> */}
          {/*   <FormControlLabel */}
          {/*     checked={settings.anodes} */}
          {/*     onChange={() => onNewSettings({anodes: !settings.anodes})} */}
          {/*     control={( */}
          {/*       <Checkbox */}
          {/*         color="primary" */}
          {/*       /> */}
          {/*     )} */}
          {/*     label="Show Anodes" */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item>
            <FormControlLabel
              disabled={!settings.welds}
              checked={settings.highlightRepairedWelds}
              onChange={() => onNewSettings({highlightRepairedWelds: !settings.highlightRepairedWelds})}
              control={(
                <Checkbox
                  color="primary"
                />
              )}
              label="Highlight Repaired welds"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              checked={settings.splitStalk}
              disabled={view === VIEWS.DETAILED}
              onChange={() => onNewSettings({splitStalk: !settings.splitStalk})}
              control={(
                <Checkbox
                  color="primary"
                />
              )}
              label="Split across multiple lines"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              checked={settings.showStats}
              onChange={() => setSettings({...settings, showStats: !settings.showStats})}
              control={(
                <Checkbox
                  color="primary"
                />
              )}
              label="Show stats"
            />
          </Grid>
          {state === STATE.AS_FABRICATED && (
            <Grid item>
              <FormControlLabel
                checked={settings.length}
                onChange={() => setSettings({...settings, length: !settings.length})}
                control={(
                  <Checkbox
                    color="primary"
                  />
                )}
                label="Show As-built length"
              />
            </Grid>
          )}
          {(view === VIEWS.GLOBAL && state === STATE.AS_FABRICATED) && (
            <Grid item>
              <FormControlLabel
                checked={settings.baseMaterials}
                onChange={() => onNewSettings({baseMaterials: !settings.baseMaterials})}
                control={(
                  <Checkbox
                    color="primary"
                  />
                )}
                label="Show Base materials"
              />
            </Grid>
          )}
          {state === STATE.AS_FABRICATED && (
            <Grid item>
              <FormControlLabel
                checked={settings.quarantine}
                onChange={() => onNewSettings({quarantine: !settings.quarantine})}
                control={(
                  <Checkbox
                    color="primary"
                  />
                )}
                label="Show Quarantine"
              />
            </Grid>
          )}
          {view === VIEWS.COATING && (
            <Grid item>
              <FormControlLabel
                checked={settings.coatingType}
                onChange={() => onNewSettings({coatingType: !settings.coatingType})}
                control={(
                  <Checkbox
                    color="primary"
                  />
                )}
                label="Show Joint coating type"
              />
            </Grid>
          )}
          {view === VIEWS.COATING && (
            <Grid item>
              <FormControlLabel
                checked={settings.highlightCoatingDefects}
                onChange={() => onNewSettings({highlightCoatingDefects: !settings.highlightCoatingDefects})}
                control={(
                  <Checkbox
                    color="primary"
                  />
                )}
                label="Show Coating defects"
              />
            </Grid>
          )}
          {(view === VIEWS.DETAILED || view === VIEWS.GLOBAL) && (
            <Grid item>
              <FormControlLabel
                checked={settings.type}
                onChange={() => onNewSettings({type: !settings.type})}
                control={(
                  <Checkbox
                    color="primary"
                  />
                )}
                label="Joint Type"
              />
            </Grid>
          )}
          <Grid item>
            <FormControlLabel
              checked={settings.legend}
              onChange={toggleLegend}
              control={(
                <Checkbox
                  color="primary"
                />
              )}
              label="Show Legend"
            />
          </Grid>
          <Grid item>
            <h3>Default View</h3>
          </Grid>
          <Grid item>
            <RadioGroup
              row
              value={defaultView}
              onChange={onChangeDefaultView}
            >
              <FormControlLabel
                value={VIEWS.GLOBAL}
                control={<Radio color="primary" />}
                label="Global"
                labelPlacement="end"
              />
              <FormControlLabel
                disabled={!campaign.welds.length}
                value={VIEWS.DETAILED}
                control={<Radio color="primary" />}
                label={(
                  <span className={classes.detailedLabel}>
                    Detailed
                    {!campaign.welds.length && (
                      <Tooltip title="The detailed view will be available once welds have been added to this campaign">
                        <InfoIcon fontSize="small" color="info" />
                      </Tooltip>
                    )}
                  </span>
                )}
                labelPlacement="end"
              />
              {(!!coatingCampaigns.length && !!campaign.welds.length) && (
                <FormControlLabel
                  value={VIEWS.COATING}
                  control={<Radio color="primary" />}
                  label="Coating"
                  labelPlacement="end"
                />
              )}
            </RadioGroup>
          </Grid>
        </Grid>
      </Popover>
      <Tooltip
        PopperProps={{
          container: container.current,
        }}
        title="Settings"
      >
        <IconButton
          size="small"
          color="primary"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={classes.button}
        >
          <IconistsIcon icon={ICONISTS_ICONS.settingsGear} color={theme.palette.primary.main} />
        </IconButton>
      </Tooltip>
    </>
  );
});

export default withStyles(Settings, styles);