import React, {useMemo, useState} from "react";
import {values, groupBy, isNil} from "ramda";
import classNames from "classnames";
import {Table, TableCell, TableBody, TableHead, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import SortableCell from "../../../../SortableCell";
import DetailedActivity from "../DetailedActivity";
import {mode, getMean, sort} from "@core/helpers";
import {ORDERS} from "@core/constants/sort";
import {ORDER_BY} from "../../constants";
import styles from "./styles";
import {TEST_RESULTS} from "@core/constants/testResults";
import {CUSTOM_TEST_TYPES} from "@core/constants/test";

const CompactView = ({classes, activities, disabled, updateTest, test}) => {
  const [order, setOrder] = useState(ORDERS.ASC);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const withActivity = useMemo(() => activities.some((element) => element.activity), [activities]);
  const withAcceptance = useMemo(() => activities.some((element) => element.acceptanceCriteria), [activities]);

  const sorted = useMemo(() => sort(order, ORDER_BY.ACTIVITY, activities), [activities]);

  const groupedActivities = useMemo(() => values(groupBy((element) => {
    const acceptanceCriteria = element.acceptanceCriteria && element.acceptanceCriteria.map((ac) => ac.label).join(" ,");

    return element.activity + element.description + acceptanceCriteria;
  }, sorted)), [activities]);
  
  const isNdtTest = test.displayName === CUSTOM_TEST_TYPES.AUTOMATED_ULTRASONIC_TESTING;

  return (
    <>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            {withActivity && (
              <SortableCell
                id={ORDER_BY.ACTIVITY}
                setOrder={setOrder}
                order={order}
                orderBy={ORDER_BY.ACTIVITY}
              >
                ITP Activity
              </SortableCell>
            )}
            <TableCell>Description</TableCell>
            {withAcceptance && (
              <TableCell>Acceptance Criteria</TableCell>
            )}
            <TableCell>Result</TableCell>
            {!isNdtTest && (
              <TableCell>Average</TableCell>
            )}
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedActivities.map((bunchOfActivities, index) => {
            const [element] = bunchOfActivities;

            const results = bunchOfActivities.reduce((acc, activity) => {
              if(!isNil(activity.results.indicationCount)) acc.push(activity.results.indicationCount);
              else acc.push(...activity.results.values.map(({value}) => value));

              return acc;
            }, []);
            const isArrayOfNumbers = results.every((value) => Number(value));

            const acceptableNumber = bunchOfActivities.filter((activity) => activity.results.result === TEST_RESULTS.ACCEPTABLE).length;
            const isAcceptable = bunchOfActivities.every((activity) => activity.results.result === TEST_RESULTS.ACCEPTABLE);
            const isWarning = !isAcceptable && acceptableNumber;

            const [value] = element.results.values;

            const average = isArrayOfNumbers ? getMean(results).toFixed(2) : mode(results);

            const {description, activity} = bunchOfActivities[0];

            return (
              <TableRow
                className={classes.clickableRow}
                onClick={() => setSelectedActivity(activity || description)}
                hover
                key={element.specimen + index}
              >
                {withActivity && <TableCell>{element.activity}</TableCell>}
                <TableCell>{element.description || "-"}</TableCell>
                {withAcceptance && (
                  <TableCell>
                    {element.acceptanceCriteria ? element.acceptanceCriteria.map((ac) => ac.label).join(" ,") : "-"}
                  </TableCell>
                )}
                <TableCell
                  className={classNames({
                    [classes.acceptable]: isAcceptable,
                    [classes.notAcceptable]: !isAcceptable,
                    [classes.warning]: isWarning
                  })}
                >
                  {isWarning ? (
                    `${acceptableNumber} acceptable out of ${bunchOfActivities.length}`
                  ) : (
                    isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE
                  )}
                </TableCell>
                {!isNdtTest && (
                  <TableCell>
                    {average}
                    {value.unit ? ` ${value.unit}` : null}
                  </TableCell>
                )}
                <TableCell>{bunchOfActivities.length}</TableCell>
              </TableRow>
            );
          })}
          {!groupedActivities.length && (
            <TableRow>
              <TableCell colSpan={6}>No data.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <DetailedActivity
        open={!!selectedActivity}
        close={() => setSelectedActivity(null)}
        activity={selectedActivity}
        activities={activities}
        disabled={disabled}
        updateTest={updateTest}
        test={test}
      />
    </>
  );
};

export default withStyles(CompactView, styles);