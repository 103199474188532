import React from "react";
import {Button, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {intersection} from "ramda";
import testConfig from "@core/configs/test";
import modules from "@core/constants/modules";
import styles from "./styles";

const ACTION_TITLE = {
  addData: "Add data",
  inviteLab: "Invite lab"
};

const ActionTypeSelect = ({classes, type, action, setAction}) => {
  const isProducerTest = Boolean(intersection([modules.PRODUCER, modules.END_OWNER], testConfig[type].responsible).length) && testConfig[type].addDataForm;
  const isLabTest = testConfig[type].responsible.includes(modules.LAB_INTERNAL);

  if(isProducerTest && !isLabTest) {
    return (
      <Button
        size="small"
        color="primary"
        onClick={() => setAction("addData")}
      >
        {ACTION_TITLE.addData}
      </Button>
    );
  }

  return (
    <RadioGroup
      classes={{
        root: classes.radioGroup
      }}
      value={action}
      onChange={({target}) => setAction(target.value)}
    >
      {isProducerTest && (
        <FormControlLabel value="addData" control={<Radio color="primary" />} label={ACTION_TITLE.addData} />
      )}
      {isLabTest && isProducerTest && (
        <FormControlLabel value="inviteLab" control={<Radio color="primary" />} label={ACTION_TITLE.inviteLab} />
      )}
    </RadioGroup>
  );
};

export default withStyles(ActionTypeSelect, styles);