import React from "react";
import Line from "../Line";
import {PROGRESS_BAR_LABELS_BY_STATUS} from "@core/constants/progressBar";
import {useTheme} from "@mui/material";

const ProgressBar = ({item, allNumber, setActiveItem}) => {
  const theme = useTheme();

  return (
    <>
      <Line
        allNumber={allNumber}
        item={item}
        setActiveItem={setActiveItem}
      />
      <Line
        item={{
          title: PROGRESS_BAR_LABELS_BY_STATUS.AWAITING_DATA,
          color: theme.palette.awaitingData.backgroundColor,
          number: allNumber - item.number,
        }}
        allNumber={allNumber}
        setActiveItem={setActiveItem}
      />
    </>
  );
};

export default ProgressBar;
