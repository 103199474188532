import TimelineSideBar from "@core/components/TimelineBlock/components/TimelineSideBar";
import {getLabelsToRender} from "@core/components/TimelineBlock/utils";
import React, {useMemo} from "react";
import {STATUSES} from "@core/constants/test";
import DisabledExpansionPanel from "@core/components/TimelineBlock/components/DisabledExpansionPanel";
import Stripe from "@core/components/TimelineBlock/components/Stripe";
import TransactionList from "@core/components/TimelineBlock/components/TransactionList";
import styles from "./styles";
import {withStyles} from "tss-react/mui";
import TimelineSidebarContent from "@core/components/TimelineBlock/components/TimelineSidebarContent";

function TimelineBlockMain({isLast, isFirst, test, classes}) {
  const testStatus = test.status;
  const labExists = test.assignee?._id ? test.assignee?._id !== test.company?._id : false;
  const witnessesExists = !!test.witnesses?.length;
  const labelsToRender = useMemo(
    () => getLabelsToRender({labExists, witnessesExists, testStatus}),
    [testStatus, labExists, witnessesExists]
  );

  return (
    <TimelineSideBar>
      <TimelineSidebarContent isLast={isLast} isFirst={isFirst}>
        {
          testStatus === STATUSES.EMPTY || (!labExists && testStatus === STATUSES.FILLED) ?
            <DisabledExpansionPanel
              header="Unsigned"
              stripeComponent={<Stripe customClasses={classes?.disabledStripe}/>}/> :
            <TransactionList test={test} futureLabels={labelsToRender}/>
        }
      </TimelineSidebarContent>
    </TimelineSideBar>
  );
}

export default withStyles(TimelineBlockMain, styles);