import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {keys, uniq, flatten} from "ramda";
import {Box, Table, TableBody, TableHead, TableRow, TableCell} from "@mui/material";
import PipeNumberCell from "./components/PipeNumberCell";
import {NEXT_PIPE_CELL, PREV_PIPE_CELL} from "./constants";

const CustomReport = ({test}) => {
  const cells = useMemo(() => {
    return uniq(flatten(test.properties.data.map((data) => keys(data))));
  }, [test.properties.data]);

  return (
    <Box
      sx={{
        overflow: "scroll",
        maxHeight: 500,
      }}
    >
      <Table
        className="styled-table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {cells.map((cell) => (
              <TableCell key={cell}>
                <Box
                  sx={{
                    maxWidth: "200px",
                    textWrap: "balance",
                    width: "max-content"
                  }}
                >
                  {cell}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {test.properties.data.map((data, index) => (
            <TableRow key={index}>
              {cells.map((cell) => {
                
                if([PREV_PIPE_CELL, NEXT_PIPE_CELL].includes(cell)) {
                  return (
                    <PipeNumberCell
                      key={cell + index}
                      data={data}
                      cell={cell}
                    />
                  );
                } 
                
                return (
                  <TableCell
                    key={cell + index}
                  >
                    {data[cell] || "-"}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default observer(CustomReport);