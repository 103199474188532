import React, {Component} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

class ConfirmationModal extends Component {

  state = {
    open: false,
    loading: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if(!prevState.loading) return;

    this.setState({loading: false});
  }

  confirm = async () => {
    this.setState({loading: true});

    await this.props.onConfirm();

    this.setState({loading: false});
  };

  cancel = () => {
    this.props.onCancel();
  };

  render() {
    const {loading} = this.state;
    const {fullScreen, open, alertText, onBackdropClick, onCancel, onEscapeKeyDown, children} = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open || false}
        onClose={(_, reason) => {
          if (reason === "escapeKeyDown" && onEscapeKeyDown) return;

          if (reason === "backdropClick" && onBackdropClick) return;

          onCancel && onCancel();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Please confirm your action</DialogTitle>
        <DialogContent>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <DialogContentText id="alert-dialog-description">
                {alertText}
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {children || (
            <>
              <Button onClick={this.cancel} color="secondary">
                Cancel
              </Button>
              <Button disabled={loading} onClick={this.confirm} variant="contained" color="primary">
                {loading && <CircularProgress size={24} />}
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationModal;
