import {Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import React from "react";
import styles from "./styles";

function PanelSummaryContent({classes, children, actionLabel}) {
  return (
    <>
      <div className={classes.summaryLeftContainer}>
        <Typography variant="body1">{actionLabel}</Typography>
        <Typography variant="body2" color="textSecondary" classes={{root: classes.signedLabel}}>
          Signed
        </Typography>
      </div>
      {children}
    </>
  );
}

export default withStyles(PanelSummaryContent, styles);