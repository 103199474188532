import React, {useEffect, useState} from "react";
import {
  MenuItem,
  Grid,
  FormControlLabel,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {FieldArray, Formik} from "formik";
import {times} from "ramda";
import * as yup from "yup";
import {getMean, getDeviation, getCi, getAccuracy} from "@core/helpers";
import {Input} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import TestFooter from "../../LabTestFooter";
import ClientField from "../../../../Tests/Test/components/ClientField";
import EtchantAutocomplete from "../../../../Tests/Test/components/EtchantAutocomplete";
import {TEST_RESULTS} from "@core/constants/testResults";
import {MAGNIFICATIONS, ETCHING_STANDARDS, POSITIONS, ORIENTATIONS} from "@core/constants/test";
import {ACCEPTANCE_CRITERIA, TEST_STANDARDS, ELEMENT} from "./data";
import styles from "./styles";
import MuiSelect from "@core/components/MuiSelect";

const validationSchema = yup.object().shape({
  norm: yup.string().required("This field is required!"),
  client: yup.string().required("This field is required!"),
  lab: yup.string().required("This field is required!"),
  acceptance: yup.string().required("This field is required!"),
  etchingStandard: yup.string().required("This field is required!"),
  etchingSolution: yup.string().required("This field is required!"),
  position: yup.string(),
  orientation: yup.string(),
  specimenId: yup.string().required("This field is required!"),
  magnification: yup.string().required("This field is required!"),
  electrolytic: yup.bool(),
  maxAusteniteSpacing: yup.number().min(0, "Should be \u2265 0").required(),
  meanAustenite: yup.string().required(),
  deviation: yup.string().required(),
  ci: yup.string().required(),
  accuracy: yup.string().required(),
  elements: yup.array().of(yup.object().shape({
    spacing: yup.string().required("This field is required!"),
  })).required(),
});

const AusteniteSpacing = ({classes, user, client, test, saveTest, formRef}) => {

  const initialValues = {
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name || "",
    norm: "",
    acceptance: "",
    etchingStandard: "",
    etchingSolution: "",
    position: "",
    orientation: "",
    specimenId: "",
    magnification: "",
    electrolytic: false,
    maxAusteniteSpacing: "",
    meanAustenite: "",
    deviation: "",
    ci: "",
    accuracy: "",
    elements: [ELEMENT],
    notes: "",
  };

  return (
    <Formik
      onSubmit={saveTest}
      innerRef={formRef}
      initialValues={{...initialValues, ...test.properties}}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(props) => {
        const [numberOfFields, setNumberOfFields] = useState(props.values.elements.length);

        const elements = props.values.elements.map((el) => Number(el.spacing) || 0);
        const elementsString = elements.join();

        useEffect(() => {
          const mean = getMean(elements);
          const deviation = getDeviation(elements);
          const ci = getCi(elements, deviation);
          const accuracy = getAccuracy(elements, deviation, mean);

          props.setFieldValue("meanAustenite", mean.toFixed(3));
          props.setFieldValue("deviation", deviation.toFixed(3));
          props.setFieldValue("ci", ci.toFixed(3));
          props.setFieldValue("accuracy", accuracy.toFixed(3));

        }, [elementsString]);

        useEffect(() => {
          const currentNumber = props.values.elements.length;

          const newNumber = Number(numberOfFields) || 1;

          const missingElements = newNumber > currentNumber ? times(() => ELEMENT, newNumber - currentNumber) : [];
          const newElements = props.values.elements.concat(missingElements);

          props.setFieldValue("elements", newElements.slice(0, newNumber));
        }, [numberOfFields]);

        const isAcceptable = Number(props.values.meanAustenite) + Number(props.values.ci) <= Number(props.values.maxAusteniteSpacing);
        const isSatisfactory = isAcceptable && props.isValid;
        const result = isSatisfactory ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

        return (
          <>
            <Grid container spacing={1}>
              <Grid item container spacing={5}>
                <Grid item xs={3}>
                  <ClientField
                    isFromProducer={!!client.name}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    name="lab"
                    label="Laboratory"
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={3}>
                  <MuiSelect
                    required
                    name="norm"
                    defaultOptions={TEST_STANDARDS}
                    label="Test standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiSelect
                    required
                    label="Acceptance criteria"
                    name="acceptance"
                    defaultOptions={ACCEPTANCE_CRITERIA}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5} alignItems="flex-end">
                <Grid item xs={3}>
                  <SelectField
                    name="etchingStandard"
                    label="Etching standard"
                    required
                  >
                    {ETCHING_STANDARDS.map((standard) => (
                      <MenuItem key={standard} value={standard}>{standard}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <EtchantAutocomplete
                    etchingStandard={props.values.etchingStandard}
                    name="etchingSolution"
                    setFieldValue={props.setFieldValue}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={props.values.electrolytic}
                        onClick={() => props.setFieldValue("electrolytic", !props.values.electrolytic)}
                        name="electrolytic"
                        color="primary"
                      />
                    )}
                    label="Electrolytic"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={3}>
                  <SelectField
                    label="Position"
                    name="position"
                  >
                    {POSITIONS.map((position) => (
                      <MenuItem key={position} value={position}>{position}</MenuItem>)
                    )}
                    <MenuItem value={undefined}>N/A</MenuItem>)
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    label="Orientation"
                    name="orientation"
                  >
                    {ORIENTATIONS.map((orientation) => (
                      <MenuItem key={orientation} value={orientation}>{orientation}</MenuItem>)
                    )}
                    <MenuItem value={undefined}>N/A</MenuItem>)
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label='Specimen ID'
                    name="specimenId"
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5} alignItems="flex-end">
                <Grid item xs={3}>
                  <SelectField
                    required
                    label="Magnification"
                    name="magnification"
                  >
                    {MAGNIFICATIONS.map((magnification) => (
                      <MenuItem key={magnification} value={magnification}>{magnification}</MenuItem>)
                    )}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    type="number"
                    label='Max austenite spacing'
                    name="maxAusteniteSpacing"
                    required
                    endAdornment="µm"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    inputProps={{min: 1}}
                    type="number"
                    value={numberOfFields}
                    label='Number of fields'
                    required
                    onChange={(e) => setNumberOfFields(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={9}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="none" width="70" align="center">Field</TableCell>
                        <TableCell padding="none" width="35%" align="center">Austenite spacing [µm]</TableCell>
                        <TableCell padding="none" align="center">Mean spacing [µm]</TableCell>
                        <TableCell padding="none" align="center">Standard deviation [%]</TableCell>
                        <TableCell padding="none" align="center">CI 95%</TableCell>
                        <TableCell padding="none" align="center">Relative accuracy [%]</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray name="elements">
                        {() => props.values.elements.map((element, index) => (
                          <TableRow key={index}>
                            <TableCell padding="none" width="70" align="center">{index + 1}</TableCell>
                            <TableCell
                              align="center"
                              padding="normal"
                              width="35%"
                              classes={{root: classes.inputCell}}
                            >
                              <TextField
                                type="number"
                                name={`elements.${index}.spacing`}
                                required
                              />
                            </TableCell>
                            {!index && (
                              <>
                                <TableCell
                                  rowSpan={props.values.elements.length}
                                  padding="none"
                                  align="center"
                                  classes={{root: classes.resultCell}}
                                >
                                  {props.values.meanAustenite}
                                </TableCell>
                                <TableCell
                                  rowSpan={props.values.elements.length}
                                  padding="none"
                                  align="center"
                                  classes={{root: classes.resultCell}}
                                >
                                  {props.values.deviation}
                                </TableCell>
                                <TableCell
                                  rowSpan={props.values.elements.length}
                                  padding="none"
                                  align="center"
                                  classes={{root: classes.resultCell}}
                                >
                                  {props.values.ci}
                                </TableCell>
                                <TableCell
                                  rowSpan={props.values.elements.length}
                                  padding="none"
                                  align="center"
                                  classes={{root: classes.resultCell}}
                                >
                                  {props.values.accuracy}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        ))}
                      </FieldArray>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={6}>
                  <TextField
                    rows={4}
                    multiline
                    name="notes"
                    label="Additional remarks"
                  />
                </Grid>
              </Grid>
            </Grid>
            <TestFooter
              onSubmit={() => saveTest({...props.values, result})}
              result={result}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default withStyles(AusteniteSpacing, styles);
