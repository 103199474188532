import {HARDNESS_UNITS} from "@core/constants/test";
import CollapsibleTable from "../../../../CollapsibleTable";
import React from "react";

const Specimen = ({editable}) => {
  return (
    <CollapsibleTable
      editable={editable}
      title="Specimen"
      requiredFields={[{
        name: "preparation",
        label: "Preparation",
      }, {
        name: "cleaning",
        label: "Cleaning",
      }, {
        name: "initialSurfaceRoughness",
        label: "Initial Surface Roughness",
        type: "number",
        endAdornment: "rms",
        inputProps: {min: 0},
      }]}
      optionalFields={[{
        name: "hardnessMeasurementUnit",
        label: "Hardness Measurement Unit",
        options: HARDNESS_UNITS,
      }, {
        name: "acceptanceCriteria",
        label: "Acceptance Criteria",
        type: "number",
        endAdornment: "mm^3/kg",
        inputProps: {min: 0},
      }]}
    />
  );
};

export default Specimen;