import React, {useEffect, useState, useCallback} from "react";
import * as yup from "yup";
import {observer} from "mobx-react";
import {identity, times, values, map, omit, pathOr} from "ramda";
import {Formik, useFormikContext} from "formik";
import {Checkbox, FormControlLabel, Grid, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Uploader} from "@core/components/Uploaders";
import {Input, Select} from "@core/components/Form";
import styles from "./styles";
import useStores from "../../../../useStores";

const getExaminationConditions = (isFileForEachItem, examinationConditions) => {
  return map((ec) => !isFileForEachItem ? omit(["file"], ec) : {...ec, file: ""}, examinationConditions);
};

const SingleIdentificationOfInspectedItems = observer(({
  classes,
  isProducerTest,
  test,
  ...props
}) => {

  const {CertificateStore, CampaignStore} = useStores();

  const certificate = props.certificate || CertificateStore.certificate.data;
  const campaign = CampaignStore.campaign;

  const formik = useFormikContext();

  const hasFiles = values(test?.properties.examinationConditions).find((ec) => ec.file);
  const [isFileForEachItem, setIsFileForEachItem] = useState(!!hasFiles || false);
  
  const validationSchema = yup.object().shape({
    overallQuantityInspected: yup.number().required(),
    quantityInspected: yup.number().when("overallQuantityInspected",
      (overallQuantityInspected, schema) => {
        if (!overallQuantityInspected) return schema
          .positive("Should to be > 0")
          .typeError("Should be a number!")
          .required("Field is required");

        return schema
          .positive("Should to be > 0")
          .max(overallQuantityInspected)
          .typeError("Should be a number!")
          .required("Field is required");
      }),
    pieceIdentification: yup.string().required("Field is required"),
    examinationConditions: yup.lazy((item) => yup.object().shape(
      map(() => {
        return yup.object().shape({
          file: yup.string(),
          value: yup.string(),
        });
      }, item)
    ))
  });

  const initialValues = {
    overallQuantityInspected: test?.properties.overallQuantityInspected || 0,
    quantityInspected: test?.properties.quantityInspected || "",
    pieceIdentification: test?.properties.pieceIdentification || "",
    examinationConditions: test?.properties.examinationConditions || {},
    file: test?.properties.file || ""
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      render={({
        values,
        isValid,
        errors,
        touched,
        setFieldValue,
        handleChange,
        setFieldTouched
      }) => {
        
        const {
          overallQuantityInspected,
          quantityInspected,
          pieceIdentification,
          examinationConditions,
          file
        } = values;

        useEffect(() => {
          formik.setErrors({...formik.errors, ...errors});
        }, [formik.isValid, isValid]);

        useEffect(() => {
          formik.setValues(() => ({...formik.values, ...values}));
          formik.setTouched({...formik.touched, ...touched});
        }, [overallQuantityInspected, quantityInspected, pieceIdentification, examinationConditions, file]);

        const calculateCertificateOverallQuantity = useCallback(() => {
          if (!certificate.items) return 0;

          return certificate.items.reduce((acc, curr) => acc + Number(curr.quantity), 0);
        }, [certificate._id]);

        const calculateCampaignOverallQuantity = useCallback(() => {
          if (!campaign.products) return;

          return campaign.products.length;
        }, [campaign._id]);

        useEffect(() => {
          if ((!certificate && !campaign) || (quantityInspected && overallQuantityInspected)) return;

          const quantity = campaign._id ? calculateCampaignOverallQuantity() : calculateCertificateOverallQuantity();

          setFieldValue("overallQuantityInspected", quantity);
          setFieldValue("quantityInspected", quantity);
        }, []);

        useEffect(() => {
          if(!touched.pieceIdentification && !touched.quantityInspected) return;
          
          quantityInspectedHandler();
        }, [pieceIdentification, quantityInspected]);

        const quantityInspectedHandler = useCallback(() => {
          if (pieceIdentification === "Heat and lot") {
            setFieldValue("examinationConditions", {});
          } else {
            const examinationConditions = times(identity, quantityInspected).reduce((acc, index) => {
              acc[index] = {value: ""};

              return acc;
            }, {});
            setFieldValue("examinationConditions", examinationConditions);
          }
        }, [pieceIdentification, quantityInspected]);

        const changeQuantityInspected = (event) => {
          if (!isProducerTest) setFieldValue("overallQuantityInspected", Number(event.target.value));

          handleChange(event);
          setFieldTouched("quantityInspected", true, false);
        };

        const changePieceIdentification = (event) => {
          setIsFileForEachItem(false);
          handleChange(event);
          setFieldTouched("pieceIdentification", true, false);
        };

        return (
          <>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={12}>
                <h3>Identification of tested items</h3>
              </Grid>

              <Grid item xs={isProducerTest ? 4 : 3}>
                <Input
                  inputProps={{min: 0, max: overallQuantityInspected || Infinity}}
                  name="quantityInspected"
                  value={quantityInspected}
                  type="number"
                  endAdornment="pcs"
                  required
                  error={Boolean(errors.quantityInspected) && touched.quantityInspected}
                  label="Quantity tested"
                  onChange={changeQuantityInspected}
                />
              </Grid>

              <Grid item xs={isProducerTest ? 4 : 3}>
                <Input
                  name="percentage"
                  value={quantityInspected && Math.round(quantityInspected / overallQuantityInspected * 100) || "Less than 1%"}
                  disabled
                  endAdornment="%"
                  label="Percentage"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="flex-end" marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Select
                  value={pieceIdentification}
                  name='pieceIdentification'
                  label='Piece identification'
                  required
                  onChange={changePieceIdentification}
                >
                  {["Single piece", "Heat and lot"].map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs>
                {pieceIdentification === "Single piece" && (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        onChange={(e) => {
                          const isFileForEachItem = e.target.checked;
                          setIsFileForEachItem(isFileForEachItem);
                          setFieldValue("examinationConditions", getExaminationConditions(isFileForEachItem, examinationConditions));
                          setFieldValue("file", "");
                        }}
                        checked={isFileForEachItem}
                      />
                    )}
                    label="Upload a file for each item"
                  />
                )}
              </Grid>
            </Grid>

            {pieceIdentification === "Single piece" && (
              <Grid container xs={8} spacing={3} alignItems="flex-end" marginBottom={2}>
                {Object.keys(examinationConditions).map((key, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={isProducerTest ? 4 : 3}>
                      <Input
                        defaultValue={examinationConditions[key].value || examinationConditions[key]}
                        name={`examinationConditions.${key}.value`}
                        value={pathOr(examinationConditions[key].value, [key, "value", "value"], examinationConditions)}
                        label={`Piece No. ${Number(key) + 1}`}
                        onChange={(event) => setFieldValue(`examinationConditions.${key}.value`, event.target.value)}
                      />
                    </Grid>
                    {isFileForEachItem && (
                      <Grid key={index} item xs={isProducerTest ? 8 : 9}>
                        <Uploader
                          buttonClass={classes.uploadButton}
                          fileType="image/*, application/pdf, .csv"
                          file={examinationConditions[key].file || ""}
                          buttonText="Choose file"
                          handleUploadedFile={(file) => {
                            setFieldValue(`examinationConditions.${key}.file`, file.file.dir + file.file.name);
                          }}
                          onRemove={() => setFieldValue(`examinationConditions.${key}.file`, "")}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            )}

            {!isFileForEachItem && (
              <Grid container alignItems="flex-end" spacing={3} marginBottom={2}>
                <Grid item xs={12}>
                  <Uploader
                    fileType="image/*, application/pdf, .csv"
                    file={file}
                    buttonText="Choose file"
                    handleUploadedFile={(file) => {
                      setFieldValue("file", file.file.dir + file.file.name);
                    }}
                    onRemove={() => setFieldValue("file", "")}
                  />
                </Grid>
              </Grid>
            )}
          </>
        );
      }}
    />
  );
});

export default withStyles(SingleIdentificationOfInspectedItems, styles);