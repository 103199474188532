export default (theme) => ({
  container: {
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  logoImage: {
    height: "42px",
    marginBottom: theme.spacing(4)
  },
  listRoot: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(4)
  },
  blueAvatar: {
    margin: "10px 20px 10px 10px",
    color: "#fff",
    backgroundColor: "#2196f3 !important",
  },
});
