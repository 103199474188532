import {Grid, Link, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import LaunchIcon from "@mui/icons-material/Launch";
import React from "react";
import styles from "./styles";

function DetailsContent({classes, name, company, date, signatureId, children}) {
  return (
    <>
      <Grid container direction="column" justifyContent="center" classes={{container: classes.detailsBlock}}>
        <Grid item>
          <Link target="_blank" rel="noreferrer"
            variant="inherit"
            underline="hover"
            // eslint-disable-next-line no-undef
            href={process.env.REACT_APP_BLOCKSCOUT_URL+"/tx/"+signatureId}
            classes={{root: classes.signatureIdLink}}>
            <Typography variant="body1" color="textPrimary" classes={{root: classes.signatureId}}>
              Signature info
            </Typography>
            <LaunchIcon classes={{root: classes.signatureIdIcon}}/>
          </Link>
          <Grid item>
            <Typography variant="body2" color="textSecondary">{name}</Typography>
            <Typography variant="body2" color="textSecondary">{date}</Typography>
            <Typography variant="body2" color="textSecondary">{company}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </>
  );
}

export default withStyles(DetailsContent, styles);