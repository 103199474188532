import {getConfigFromCondition, round} from "@core/helpers";

export const NORMS = [
  {
    id: 0,
    value: "ASTM A240 (2018)",
    grades: [
      {
        id: 0,
        value: "S31254",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            min: 19.5,
            max: 20.5,
            bm: "Cr"
          },
          {
            id: 7,
            min: 17.5,
            max: 18.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 6,
            max: 6.5,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.18,
            max: 0.25,
            bm: "N"
          },
          {
            id: 10,
            min: 0.50,
            max: 1.00,
            bm: "Cu"
          }
        ]
      },
      {
        id: 1,
        value: "S31266",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            min: 2.00,
            max: 4.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 23.0,
            max: 25.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 21.0,
            max: 24.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 5.2,
            max: 6.2,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.35,
            max: 0.60,
            bm: "N"
          },
          {
            id: 10,
            min: 1.00,
            max: 2.50,
            bm: "Cu"
          },
          {
            id: 11,
            min: 1.50,
            max: 2.50,
            bm: "W"
          }
        ]
      },
      {
        id: 2,
        value: "S31803",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 21.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 4.5,
            max: 6.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.5,
            max: 3.5,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.08,
            max: 0.20,
            bm: "N"
          },
          {
            id: 10,
            bm: "Cu"
          }
        ]
      }
    ]
  },
  {
    id: 1,
    value: "API 5L (2018) PSL2",
    grades: [
      {
        id: 0,
        value: "BR",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            formula: "Nb+V",
            bm: "Nb+V"
          }
        ]
      },
      {
        id: 1,
        value: "X42R",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.06,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 2,
        value: "BN",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            formula: "Nb+V",
            bm: "Nb+V"
          }
        ]
      },
      {
        id: 3,
        value: "X42N",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.06,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 4,
        value: "X46N",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.07,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          },
          {
            id: 15,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 16,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 5,
        value: "X52N",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          },
          {
            id: 15,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 16,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 6,
        value: "X56N",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          },
          {
            id: 15,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 16,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 7,
        value: "X60N",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          },
          {
            id: 15,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 16,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 8,
        value: "BQ",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 9,
        value: "X42Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 10,
        value: "X46Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 11,
        value: "X52Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 12,
        value: "X56Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.24,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.07,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          },
          {
            id: 15,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 16,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 13,
        value: "X60Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.18,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.7,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 14,
        value: "X65Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.18,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.7,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 15,
        value: "X70Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.18,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.8,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 16,
        value: "X80Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.18,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.9,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.004,
            bm: "B"
          },
          {
            id: 14,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 17,
        value: "X90Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.9,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.55,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.80,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.004,
            bm: "B"
          },
          {
            id: 14,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 18,
        value: "X100Q",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.9,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.55,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.80,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.004,
            bm: "B"
          },
          {
            id: 14,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 19,
        value: "BM",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.22,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.2,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 20,
        value: "X42M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.22,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.3,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 21,
        value: "X46M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.22,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.3,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 22,
        value: "X52M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.22,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.4,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 23,
        value: "X56M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.22,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.4,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 24,
        value: "X60M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 25,
        value: "X65M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 26,
        value: "X70M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.7,
            bm: "Mn",
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 27,
        value: "X80M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.85,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 28,
        value: "X90M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.55,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 2.10,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.001,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 29,
        value: "X100M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.55,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 2.10,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.004,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 30,
        value: "X120M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.55,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 2.10,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.004,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 30,
        value: "BNS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            maxError: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.36,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          },
          {
            id: 20,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 21,
            min: 0,
            max: 0.06,
            formula: "Nb+V",
            bm: "Nb+V"
          }
        ]
      },
      {
        id: 31,
        value: "X42NS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            maxError: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.36,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          }
        ]
      },
      {
        id: 32,
        value: "X46NS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.07,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            maxError: 0.23,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.38,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 20,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 33,
        value: "X52NS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.1,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 20,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 34,
        value: "BQS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.04,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            maxError: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.34,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          }
        ]
      },
      {
        id: 35,
        value: "X42QS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.04,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            maxError: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.34,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          }
        ]
      },
      {
        id: 36,
        value: "X46QS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.4,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            maxError: 0.23,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.36,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          }
        ]
      },
      {
        id: 37,
        value: "X52QS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.07,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            maxError: 0.23,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.39,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 20,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 38,
        value: "X56QS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.07,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.21,
            maxError: 0.24,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.40,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 20,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 39,
        value: "X60QS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            maxError: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            maxError: 0.45,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            maxError: 0.35,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.41,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 20,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 40,
        value: "X65QS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.09,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            maxError: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            maxError: 0.45,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            maxError: 0.35,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 20,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 41,
        value: "X70QS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            maxError: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            maxError: 0.45,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            maxError: 0.35,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.41,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 20,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 42,
        value: "BMS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.25,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.002,
            maxError: 0.006,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.04,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.006,
            bm: "Ca"
          }
        ]
      },
      {
        id: 43,
        value: "X42MS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.25,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.002,
            maxError: 0.006,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.04,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.006,
            bm: "Ca"
          }
        ]
      },
      {
        id: 44,
        value: "X46MS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.002,
            maxError: 0.006,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.006,
            bm: "Ca"
          }
        ]
      },
      {
        id: 45,
        value: "X52MS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.002,
            maxError: 0.006,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.06,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.006,
            bm: "Ca"
          }
        ]
      },
      {
        id: 46,
        value: "X56MS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.002,
            maxError: 0.006,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.06,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.21,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 47,
        value: "X60MS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.002,
            maxError: 0.006,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            maxError: 0.35,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.21,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 48,
        value: "X65MS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.60,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.002,
            maxError: 0.006,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.1,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            maxError: 0.45,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            maxError: 0.35,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 49,
        value: "X70MS",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.60,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.002,
            maxError: 0.006,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.1,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            maxError: 0.45,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            maxError: 0.35,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 50,
        value: "BNO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            maxError: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.36,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          },
          {
            id: 20,
            min: 0,
            max: 0.06,
            formula: "Nb+V",
            bm: "Nb+V"
          }
        ]
      },
      {
        id: 51,
        value: "X42NO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            maxError: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.36,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          }
        ]
      },
      {
        id: 52,
        value: "X46NO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.4,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.07,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            maxError: 0.23,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.38,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 53,
        value: "X52NO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.1,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.22,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 10,
            min: 0,
            max: 0.43,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 11,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 12,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 13,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 14,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 54,
        value: "BQO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.04,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            maxError: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.34,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          }
        ]
      },
      {
        id: 55,
        value: "X42QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.04,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            maxError: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.34,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          }
        ]
      },
      {
        id: 56,
        value: "X46QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.40,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            maxError: 0.23,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.36,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          }
        ]
      },
      {
        id: 57,
        value: "X52QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.07,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            maxError: 0.23,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.39,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 58,
        value: "X56QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.07,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.21,
            maxError: 0.24,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.40,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 59,
        value: "X60QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.41,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 60,
        value: "X65QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.09,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 61,
        value: "X70QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.17,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.75,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.1,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.23,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 62,
        value: "X80QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.17,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.85,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.06,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 63,
        value: "X90QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.85,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.06,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.60,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.55,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.80,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 64,
        value: "X100QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.14,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.85,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.06,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.60,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.55,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.80,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 65,
        value: "BMO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.25,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.04,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          }
        ]
      },
      {
        id: 66,
        value: "X42MO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.04,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.19,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          }
        ]
      },
      {
        id: 67,
        value: "X46MO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.10,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          }
        ]
      },
      {
        id: 68,
        value: "X52MO (L360MO)",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.05,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.20,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 69,
        value: "X56MO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.06,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.04,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.21,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 70,
        value: "X60MO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.21,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 71,
        value: "X65MO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 72,
        value: "X70MO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.75,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 73,
        value: "X80MO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.85,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.08,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.24,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 16,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 17,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 18,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      },
      {
        id: 74,
        value: "X65QS/X65QO",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.16,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 3,
            min: 0,
            max: 1.65,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.003,
            maxError: 0.008,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.09,
            bm: "V"
          },
          {
            id: 7,
            min: 0,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 8,
            min: 0,
            max: 0.06,
            bm: "Ti"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.30,
            maxError: 0.50,
            bm: "Ni"
          },
          {
            id: 11,
            min: 0,
            max: 0.30,
            maxError: 0.45,
            bm: "Cr"
          },
          {
            id: 12,
            min: 0,
            max: 0.15,
            maxError: 0.35,
            bm: "Mo"
          },
          {
            id: 13,
            min: 0,
            max: 0.0005,
            bm: "B"
          },
          {
            id: 14,
            min: 0,
            max: 0.22,
            maxError: 0.25,
            formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
            bm: "CE_Pcm"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
          {
            id: 16,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 17,
            min: 0,
            max: 0.012,
            bm: "N"
          },
          {
            id: 18,
            min: 2,
            formula: "Al/N",
            bm: "Al/N"
          },
          {
            id: 19,
            min: 0,
            max: 0.006,
            bm: "Ca"
          },
          {
            id: 20,
            min: 0,
            max: 0.15,
            formula: "Nb+V+Ti",
            bm: "Nb+V+Ti"
          }
        ]
      }
    ]
  },
  {
    id: 2,
    value: "ASTM A333 (2018)",
    grades: [
      {
        id: 0,
        value: "1",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            min: 0.40,
            max: 1.06,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            bm: "Si"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Al"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Nb"
          },
          {
            id: 13,
            bm: "Mo"
          },
          {
            id: 14,
            bm: "Co"
          }
        ]
      },
      {
        id: 1,
        value: "3",
        elements: [
          {
            id: 1,
            max: 0.19,
            bm: "C"
          },
          {
            id: 3,
            min: 0.31,
            max: 0.64,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            min: 0.18,
            max: 0.37,
            bm: "Si"
          },
          {
            id: 7,
            min: 3.18,
            max: 3.82,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Al"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Nb"
          },
          {
            id: 13,
            bm: "Mo"
          },
          {
            id: 14,
            bm: "Co"
          }
        ]
      },
      {
        id: 2,
        value: "4",
        elements: [
          {
            id: 1,
            max: 0.12,
            bm: "C"
          },
          {
            id: 3,
            min: 0.50,
            max: 1.05,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            min: 0.08,
            max: 0.37,
            bm: "Si"
          },
          {
            id: 7,
            min: 0.47,
            max: 0.98,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.44,
            max: 1.01,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0.40,
            max: 0.75,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0.04,
            max: 0.30,
            bm: "Al"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Nb"
          },
          {
            id: 13,
            bm: "Mo"
          },
          {
            id: 14,
            bm: "Co"
          }
        ]
      },
      {
        id: 3,
        value: "6",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            min: 0.29,
            max: 1.06,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            min: 0.10,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Al"
          },
          {
            id: 11,
            max: 0.08,
            bm: "V"
          },
          {
            id: 12,
            max: 0.02,
            maxError: 0.06,
            bm: "Nb"
          },
          {
            id: 13,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 14,
            bm: "Co"
          }
        ]
      },
      {
        id: 4,
        value: "7",
        elements: [
          {
            id: 1,
            max: 0.19,
            bm: "C"
          },
          {
            id: 3,
            max: 0.90,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            min: 0.13,
            max: 0.32,
            bm: "Si"
          },
          {
            id: 7,
            min: 2.03,
            max: 2.57,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Al"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Nb"
          },
          {
            id: 13,
            bm: "Mo"
          },
          {
            id: 14,
            bm: "Co"
          }
        ]
      },
      {
        id: 5,
        value: "8",
        elements: [
          {
            id: 1,
            max: 0.13,
            bm: "C"
          },
          {
            id: 3,
            max: 0.90,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            min: 0.13,
            max: 0.32,
            bm: "Si"
          },
          {
            id: 7,
            min: 8.40,
            max: 9.60,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Al"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Nb"
          },
          {
            id: 13,
            bm: "Mo"
          },
          {
            id: 14,
            bm: "Co"
          }
        ]
      },
      {
        id: 6,
        value: "9",
        elements: [
          {
            id: 1,
            max: 0.20,
            bm: "C"
          },
          {
            id: 3,
            min: 0.40,
            max: 1.06,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            bm: "Si"
          },
          {
            id: 7,
            min: 1.60,
            max: 2.24,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0.75,
            max: 1.25,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Al"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Nb"
          },
          {
            id: 13,
            bm: "Mo"
          },
          {
            id: 14,
            bm: "Co"
          }
        ]
      },
      {
        id: 7,
        value: "10",
        elements: [
          {
            id: 1,
            max: 0.20,
            bm: "C"
          },
          {
            id: 3,
            min: 1.15,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.015,
            bm: "S"
          },
          {
            id: 6,
            min: 0.10,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.25,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.15,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.15,
            bm: "Cu"
          },
          {
            id: 10,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 11,
            max: 0.12,
            bm: "V"
          },
          {
            id: 12,
            max: 0.05,
            bm: "Nb"
          },
          {
            id: 13,
            max: 0.05,
            bm: "Mo"
          },
          {
            id: 14,
            bm: "Co"
          }
        ]
      },
      {
        id: 8,
        value: "11",
        elements: [
          {
            id: 1,
            max: 0.10,
            bm: "C"
          },
          {
            id: 3,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 7,
            min: 35.0,
            max: 37.0,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Al"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Nb"
          },
          {
            id: 13,
            max: 0.50,
            bm: "Mo"
          },
          {
            id: 14,
            max: 0.50,
            bm: "Co"
          }
        ]
      },
    ]
  },
  {
    id: 3,
    value: "ASTM A105 (2021)",
    grades: [
      {
        id: 0,
        value: "-",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.35,
            bm: "C"
          },
          {
            id: 3,
            min: 0.6,
            max: 1.05,
            bm: "Mn",
            getMax({elements, defaultElements}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              if (Number(carbon.value) > carbon.max) return manganese.max;

              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (manganese.max + additionToMn) > 1.65 ? 1.65 : round((manganese.max + additionToMn), 2);
            },
          },
          {
            id: 4,
            min: 0,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 2,
            min: 0.1,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 7,
            min: 0,
            max: 0.4,
            bm: "Cu"
          },
          {
            id: 8,
            min: 0,
            max: 0.4,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.3,
            bm: "Cr"
          },
          {
            id: 10,
            min: 0,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 11,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 1,
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          },
          {
            id: 13,
            min: 0,
            max: 0.32,
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          },
          {
            id: 23,
            min: 0,
            max: 0.48,
            getMax({measurement: wallThicknessInInches}) {
              return wallThicknessInInches > 2 ? 0.47 : 0.48;
            },
            bm: "Pren",
            label: "CE",
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15"
          }
        ]
      }
    ]
  },
  {
    id: 4,
    value: "ASTM A694 (2016)",
    grades: [
      {
        id: 0,
        value: "F42",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      },
      {
        id: 1,
        value: "F46",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      },
      {
        id: 2,
        value: "F48",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      },
      {
        id: 3,
        value: "F50",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      },
      {
        id: 4,
        value: "F52",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      },
      {
        id: 5,
        value: "F56",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      },
      {
        id: 6,
        value: "F60",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      },
      {
        id: 7,
        value: "F65",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      },
      {
        id: 8,
        value: "F70",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.6,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Mo"
          },
          {
            id: 10,
            bm: "V"
          },
          {
            id: 11,
            bm: "Nb"
          },
          {
            id: 12,
            bm: "B"
          }
        ]
      }
    ]
  },
  {
    id: 5,
    value: "ASTM A860 (2018)",
    grades: [
      {
        id: 0,
        value: "WPHY42",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            value: 1.0,
            min: 1.0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.03,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.05,
            bm: "Ti"
          },
          {
            id: 11,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 13,
            min: 0,
            max: 0.12,
            bm: "Nb+V",
            formula: "Nb+V"
          },
          {
            id: 14,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            bm: "Pren",
            formula: "C+(Mn/6)+((Cr+Mo+V)/5)+((Ni+Cu)/15)"
          },
          {
            id: 16,
            min: 0,
            max: 1.00,
            bm: "Ni+Cr+Mo+Cu",
            formula: "Ni+Cr+Mo+Cu"
          }
        ]
      },
      {
        id: 0,
        value: "WPHY46",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            value: 1.0,
            min: 1.0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.03,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.05,
            bm: "Ti"
          },
          {
            id: 11,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 13,
            min: 0,
            max: 0.12,
            bm: "Nb+V",
            formula: "Nb+V"
          },
          {
            id: 14,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            bm: "Pren",
            formula: "C+(Mn/6)+((Cr+Mo+V)/5)+((Ni+Cu)/15)"
          },
          {
            id: 16,
            min: 0,
            max: 1.00,
            bm: "Ni+Cr+Mo+Cu",
            formula: "Ni+Cr+Mo+Cu"
          }
        ]
      },
      {
        id: 0,
        value: "WPHY52",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            value: 1.0,
            min: 1.0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.03,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.05,
            bm: "Ti"
          },
          {
            id: 11,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 13,
            min: 0,
            max: 0.12,
            bm: "Nb+V",
            formula: "Nb+V"
          },
          {
            id: 14,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            bm: "Pren",
            formula: "C+(Mn/6)+((Cr+Mo+V)/5)+((Ni+Cu)/15)"
          },
          {
            id: 16,
            min: 0,
            max: 1.00,
            bm: "Ni+Cr+Mo+Cu",
            formula: "Ni+Cr+Mo+Cu"
          }
        ]
      },
      {
        id: 0,
        value: "WPHY60",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            value: 1.0,
            min: 1.0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.03,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.05,
            bm: "Ti"
          },
          {
            id: 11,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 13,
            min: 0,
            max: 0.12,
            bm: "Nb+V",
            formula: "Nb+V"
          },
          {
            id: 14,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            bm: "Pren",
            formula: "C+(Mn/6)+((Cr+Mo+V)/5)+((Ni+Cu)/15)"
          },
          {
            id: 16,
            min: 0,
            max: 1.00,
            bm: "Ni+Cr+Mo+Cu",
            formula: "Ni+Cr+Mo+Cu"
          }
        ]
      },
      {
        id: 0,
        value: "WPHY65",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            value: 1.0,
            min: 1.0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.03,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.05,
            bm: "Ti"
          },
          {
            id: 11,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 13,
            min: 0,
            max: 0.12,
            bm: "Nb+V",
            formula: "Nb+V"
          },
          {
            id: 14,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            bm: "Pren",
            formula: "C+(Mn/6)+((Cr+Mo+V)/5)+((Ni+Cu)/15)"
          },
          {
            id: 16,
            min: 0,
            max: 1.00,
            bm: "Ni+Cr+Mo+Cu",
            formula: "Ni+Cr+Mo+Cu"
          }
        ]
      },
      {
        id: 0,
        value: "WPHY70",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            value: 1.0,
            min: 1.0,
            max: 1.45,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.03,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.01,
            bm: "S"
          },
          {
            id: 5,
            value: 0.15,
            min: 0.15,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0,
            max: 0.05,
            bm: "Ti"
          },
          {
            id: 11,
            min: 0,
            max: 0.10,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 0.04,
            bm: "Nb"
          },
          {
            id: 13,
            min: 0,
            max: 0.12,
            bm: "Nb+V",
            formula: "Nb+V"
          },
          {
            id: 14,
            min: 0,
            max: 0.06,
            bm: "Al"
          },
          {
            id: 15,
            min: 0,
            max: 0.42,
            bm: "Pren",
            formula: "C+(Mn/6)+((Cr+Mo+V)/5)+((Ni+Cu)/15)"
          },
          {
            id: 16,
            min: 0,
            max: 1.00,
            bm: "Ni+Cr+Mo+Cu",
            formula: "Ni+Cr+Mo+Cu"
          }
        ]
      }
    ]
  },
  {
    id: 6,
    value: "API 6A (2018) + Errata 1-2-3 + Addenda 1-2 - PSL 1",
    grades: [
      {
        id: 0,
        value: "Soft Iron",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.45,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.8,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 2.75,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0,
            max: 1.50,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.30,
            bm: "V"
          }
        ]
      },
      {
        id: 1,
        value: "SS316L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.03,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 2.0,
            max: 3.0,
            bm: "Mo"
          },
          {
            id: 8,
            min: 10.0,
            max: 15.0,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.1,
            bm: "N"
          }
        ]
      },
      {
        id: 2,
        value: "SS316",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 2.0,
            max: 3.0,
            bm: "Mo"
          },
          {
            id: 8,
            min: 10.0,
            max: 15.0,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.1,
            bm: "N"
          }
        ]
      },
      {
        id: 3,
        value: "SS304",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      },
      {
        id: 4,
        value: "SS304L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.03,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      }
    ]
  },
  {
    id: 7,
    value: "ASTM A182 (2021)",
    grades: [
      {
        id: 0,
        value: "F316",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 10.00,
            max: 14.00,
            bm: "Ni"
          },
          {
            id: 7,
            min: 16.00,
            max: 18.00,
            bm: "Cr"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      },
      {
        id: 1,
        value: "F316L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 10.00,
            max: 15.00,
            bm: "Ni"
          },
          {
            id: 7,
            min: 16.00,
            max: 18.00,
            bm: "Cr"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      },
      {
        id: 2,
        value: "F1",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.28,
            bm: "C"
          },
          {
            id: 2,
            min: 0.60,
            max: 0.90,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.045,
            bm: "S"
          },
          {
            id: 5,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni"
          },
          {
            id: 7,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0.44,
            max: 0.65,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N"
          }
        ]
      },
      {
        id: 3,
        value: "F2",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.21,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.80,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            min: 0.10,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni"
          },
          {
            id: 7,
            bm: "Cr",
            min: 0.50,
            max: 0.81,
          },
          {
            id: 8,
            min: 0.44,
            max: 0.65,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N"
          }
        ]
      },
      {
        id: 4,
        value: "F5",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50
          },
          {
            id: 7,
            bm: "Cr",
            min: 4.0,
            max: 6.0,
          },
          {
            id: 8,
            min: 0.44,
            max: 0.65,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N"
          }
        ]
      },
      {
        id: 5,
        value: "F5a",
        elements: [
          {
            id: 1,
            max: 0.25,
            bm: "C"
          },
          {
            id: 2,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50
          },
          {
            id: 7,
            bm: "Cr",
            min: 4.0,
            max: 6.0,
          },
          {
            id: 8,
            min: 0.44,
            max: 0.65,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N"
          }
        ]
      },
      {
        id: 6,
        value: "F9",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 0.50,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni"
          },
          {
            id: 7,
            bm: "Cr",
            min: 8.0,
            max: 10.0,
          },
          {
            id: 8,
            min: 0.90,
            max: 1.10,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N"
          }
        ]
      },
      {
        id: 7,
        value: "F10",
        elements: [
          {
            id: 1,
            min: 0.10,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            min: 0.50,
            max: 0.80,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 1.00,
            max: 1.40,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 19.0,
            max: 22.0,
          },
          {
            id: 7,
            bm: "Cr",
            min: 7.0,
            max: 9.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N"
          }
        ]
      },
      {
        id: 8,
        value: "F91 Type 1",
        elements: [
          {
            id: 1,
            min: 0.08,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0.20,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.40,
          },
          {
            id: 7,
            bm: "Cr",
            min: 8.0,
            max: 9.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.85,
            max: 1.05
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.06,
            max: 0.10
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.03,
            max: 0.07
          },
          {
            id: 12,
            bm: "Al",
            max: 0.02
          },
          {
            id: 13,
            bm: "V",
            min: 0.18,
            max: 0.25
          },
          {
            id: 14,
            bm: "Ti",
            max: 0.01
          },
          {
            id: 15,
            bm: "Zr",
            max: 0.01
          }
        ]
      },
      {
        id: 9,
        value: "F91 Type 2",
        elements: [
          {
            id: 1,
            min: 0.08,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.50,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.005,
            bm: "S"
          },
          {
            id: 5,
            min: 0.20,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.20,
          },
          {
            id: 7,
            bm: "Cr",
            min: 8.0,
            max: 9.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.85,
            max: 1.05
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.06,
            max: 0.10
          },
          {
            id: 10,
            bm: "Ti",
            max: 0.01
          },
          {
            id: 11,
            bm: "N",
            min: 0.035,
            max: 0.070
          },
          {
            id: 12,
            bm: "Al",
            max: 0.020
          },
          {
            id: 13,
            bm: "V",
            min: 0.18,
            max: 0.25
          },
          {
            id: 14,
            bm: "Zr",
            max: 0.01
          },
          {
            id: 15,
            bm: "B",
            max: 0.001
          },
          {
            id: 16,
            bm: "Cu",
            max: 0.10
          },
          {
            id: 17,
            bm: "W",
            max: 0.05
          },
          {
            id: 18,
            bm: "Sn",
            max: 0.010
          },
          {
            id: 19,
            bm: "As",
            max: 0.010
          },
          {
            id: 20,
            bm: "Sb",
            max: 0.003
          },
          {
            id: 21,
            min: 4.0,
            formula: "N/Al",
            bm: "N/Al"
          }
        ]
      },
      {
        id: 10,
        value: "F92",
        elements: [
          {
            id: 1,
            min: 0.07,
            max: 0.13,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.40,
          },
          {
            id: 7,
            bm: "Cr",
            min: 8.50,
            max: 9.50
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.30,
            max: 0.60
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.04,
            max: 0.09
          },
          {
            id: 10,
            bm: "Ti",
            max: 0.01
          },
          {
            id: 11,
            bm: "N",
            min: 0.030,
            max: 0.070
          },
          {
            id: 12,
            bm: "Al",
            max: 0.020
          },
          {
            id: 13,
            bm: "V",
            min: 0.15,
            max: 0.25
          },
          {
            id: 14,
            bm: "Zr",
            max: 0.01
          },
          {
            id: 15,
            bm: "B",
            min: 0.001,
            max: 0.006
          },
          {
            id: 16,
            bm: "W",
            min: 1.50,
            max: 2.00
          }
        ]
      },
      {
        id: 11,
        value: "F93",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0.20,
            max: 0.70,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.008,
            bm: "S"
          },
          {
            id: 5,
            min: 0.05,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.20,
          },
          {
            id: 7,
            bm: "Cr",
            min: 8.50,
            max: 9.50
          },
          {
            id: 8,
            bm: "V",
            min: 0.15,
            max: 0.30
          },
          {
            id: 9,
            bm: "B",
            min: 0.007,
            max: 0.015
          },
          {
            id: 10,
            bm: "Al",
            max: 0.030
          },
          {
            id: 11,
            bm: "W",
            min: 2.5,
            max: 3.5
          },
          {
            id: 12,
            bm: "Co",
            min: 2.5,
            max: 3.5
          },
          {
            id: 13,
            bm: "N",
            min: 0.005,
            max: 0.015
          },
          {
            id: 14,
            bm: "Nb",
            min: 0.05,
            max: 0.12
          },
          {
            id: 15,
            bm: "Nd",
            min: 0.010,
            max: 0.06
          },
          {
            id: 16,
            bm: "O",
            max: 0.0050
          }
        ]
      },
      {
        id: 12,
        value: "F115",
        elements: [
          {
            id: 1,
            min: 0.08,
            max: 0.13,
            bm: "C"
          },
          {
            id: 2,
            min: 0.20,
            max: 0.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.005,
            bm: "S"
          },
          {
            id: 5,
            min: 0.15,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.25,
          },
          {
            id: 7,
            bm: "Cr",
            min: 10.0,
            max: 11.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.40,
            max: 0.60
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.02,
            max: 0.06
          },
          {
            id: 10,
            bm: "Ti",
            max: 0.01
          },
          {
            id: 11,
            bm: "V",
            min: 0.18,
            max: 0.25
          },
          {
            id: 12,
            bm: "B",
            max: 0.001
          },
          {
            id: 13,
            bm: "Cu",
            max: 0.10
          },
          {
            id: 14,
            bm: "Al",
            max: 0.02
          },
          {
            id: 15,
            bm: "W",
            max: 0.05
          },
          {
            id: 16,
            bm: "N",
            min: 0.030,
            max: 0.070
          },
          {
            id: 17,
            bm: "Zr",
            max: 0.01
          },
          {
            id: 18,
            bm: "As",
            max: 0.010
          },
          {
            id: 19,
            bm: "Sn",
            max: 0.010
          },
          {
            id: 20,
            bm: "Sb",
            max: 0.003
          },
          {
            id: 21,
            min: 4.0,
            formula: "N/Al",
            bm: "N/Al"
          },
          {
            id: 22,
            max: 10.5,
            formula: "(Cr+6*Si+4*Mo+1.5*W+11*V+5*Nb+9*Ti+12*Al) – (40*C+30*N+4*Ni+2*Mn+Cu)",
            bm: "CNB"
          }
        ]
      },
      {
        id: 13,
        value: "F122",
        elements: [
          {
            id: 1,
            min: 0.07,
            max: 0.13,
            bm: "C"
          },
          {
            id: 2,
            max: 0.70,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50,
          },
          {
            id: 7,
            bm: "Cr",
            min: 10.0,
            max: 11.50
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.25,
            max: 0.60
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.04,
            max: 0.10
          },
          {
            id: 10,
            bm: "Ti",
            max: 0.01
          },
          {
            id: 11,
            bm: "V",
            min: 0.15,
            max: 0.30
          },
          {
            id: 12,
            bm: "B",
            max: 0.005
          },
          {
            id: 13,
            bm: "Cu",
            min: 0.30,
            max: 1.70
          },
          {
            id: 14,
            bm: "Al",
            max: 0.02
          },
          {
            id: 15,
            bm: "W",
            min: 1.50,
            max: 2.50
          },
          {
            id: 16,
            bm: "N",
            min: 0.040,
            max: 0.100
          },
          {
            id: 17,
            bm: "Zr",
            max: 0.01
          }
        ]
      },
      {
        id: 14,
        value: "F911",
        elements: [
          {
            id: 1,
            min: 0.09,
            max: 0.13,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0.10,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.40,
          },
          {
            id: 7,
            bm: "Cr",
            min: 8.5,
            max: 9.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.90,
            max: 1.10
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.060,
            max: 0.10
          },
          {
            id: 10,
            bm: "Ti",
            max: 0.01
          },
          {
            id: 11,
            bm: "V",
            min: 0.18,
            max: 0.25
          },
          {
            id: 12,
            bm: "B",
            min: 0.0003,
            max: 0.006
          },
          {
            id: 14,
            bm: "Al",
            max: 0.02
          },
          {
            id: 15,
            bm: "W",
            min: 0.90,
            max: 1.10
          },
          {
            id: 16,
            bm: "N",
            min: 0.040,
            max: 0.09
          },
          {
            id: 17,
            bm: "Zr",
            max: 0.01
          }
        ]
      },
      {
        id: 15,
        value: "F11 Class 1",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 0.50,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 1.00,
            max: 1.50
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.44,
            max: 0.65
          }
        ]
      },
      {
        id: 16,
        value: "F11 Class 2",
        elements: [
          {
            id: 1,
            min: 0.10,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.80,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            min: 0.50,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 1.00,
            max: 1.50
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.44,
            max: 0.65
          }
        ]
      },
      {
        id: 17,
        value: "F11 Class 3",
        elements: [
          {
            id: 1,
            min: 0.10,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.80,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            min: 0.50,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 1.00,
            max: 1.50
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.44,
            max: 0.65
          }
        ]
      },
      {
        id: 18,
        value: "F12 Class 1",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.045,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 0.80,
            max: 1.25
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.44,
            max: 0.65
          }
        ]
      },
      {
        id: 19,
        value: "F12 Class 2",
        elements: [
          {
            id: 1,
            min: 0.10,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.80,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            min: 0.10,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 0.80,
            max: 1.25
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.44,
            max: 0.65
          }
        ]
      },
      {
        id: 20,
        value: "F21",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 2.7,
            max: 3.3
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.80,
            max: 1.06
          }
        ]
      },
      {
        id: 21,
        value: "F3V",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.18,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.10,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 2.8,
            max: 3.2
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.90,
            max: 1.10
          },
          {
            id: 9,
            bm: "Ti",
            min: 0.015,
            max: 0.035
          },
          {
            id: 10,
            bm: "V",
            min: 0.20,
            max: 0.30
          },
          {
            id: 11,
            bm: "B",
            min: 0.001,
            max: 0.003
          }
        ]
      },
      {
        id: 22,
        value: "F3VCb",
        elements: [
          {
            id: 1,
            min: 0.10,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.10,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.25,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 2.7,
            max: 3.3
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.90,
            max: 1.10
          },
          {
            id: 9,
            bm: "Ti",
            min: 0.015,
          },
          {
            id: 10,
            bm: "V",
            min: 0.20,
            max: 0.30
          },
          {
            id: 11,
            bm: "Cu",
            max: 0.25
          },
          {
            id: 12,
            bm: "Ca",
            min: 0.0005,
            max: 0.0150
          },
          {
            id: 13,
            bm: "Nb",
            min: 0.015,
            max: 0.070
          }
        ]
      },
      {
        id: 23,
        value: "F22 Class 1",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 2.00,
            max: 2.50
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.87,
            max: 1.13
          }
        ]
      },
      {
        id: 24,
        value: "F22 Class 3",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 2.00,
            max: 2.50
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.87,
            max: 1.13
          }
        ]
      },
      {
        id: 25,
        value: "F22V",
        elements: [
          {
            id: 1,
            min: 0.11,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.015,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.10,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.25,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 2.00,
            max: 2.50
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.90,
            max: 1.10
          },
          {
            id: 9,
            bm: "Nb",
            max: 0.07
          },
          {
            id: 10,
            bm: "Ti",
            max: 0.030
          },
          {
            id: 11,
            bm: "Cu",
            max: 0.20
          },
          {
            id: 12,
            bm: "V",
            min: 0.25,
            max: 0.35
          },
          {
            id: 13,
            bm: "B",
            max: 0.002
          },
          {
            id: 14,
            bm: "Ca",
            max: 0.015
          }
        ]
      },
      {
        id: 26,
        value: "F23",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0.10,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.40,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 1.90,
            max: 2.60
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.05,
            max: 0.30
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.02,
            max: 0.08
          },
          {
            id: 10,
            bm: "Ti",
            min: 0.005,
            max: 0.060
          },
          {
            id: 11,
            bm: "V",
            min: 0.20,
            max: 0.30
          },
          {
            id: 12,
            bm: "B",
            min: 0.0010,
            max: 0.006
          },
          {
            id: 13,
            bm: "N",
            max: 0.015
          },
          {
            id: 14,
            bm: "Al",
            max: 0.030
          },
          {
            id: 15,
            bm: "W",
            min: 1.45,
            max: 1.75
          }
        ]
      },
      {
        id: 27,
        value: "F24",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.70,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0.15,
            max: 0.45,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
          },
          {
            id: 7,
            bm: "Cr",
            min: 2.20,
            max: 2.60
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.90,
            max: 1.10
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti",
            min: 0.06,
            max: 0.10
          },
          {
            id: 11,
            bm: "V",
            min: 0.20,
            max: 0.30
          },
          {
            id: 12,
            bm: "B",
            min: 0.0015,
            max: 0.0070
          },
          {
            id: 13,
            bm: "N",
            max: 0.12
          },
          {
            id: 14,
            bm: "Al",
            max: 0.020
          }
        ]
      },
      {
        id: 28,
        value: "FR",
        elements: [
          {
            id: 1,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            min: 0.40,
            max: 1.06,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.050,
            bm: "S"
          },
          {
            id: 5,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 1.60,
            max: 2.24
          },
          {
            id: 7,
            bm: "Cr"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Cu",
            min: 0.75,
            max: 1.25
          }
        ]
      },
      {
        id: 29,
        value: "F36 Class 1",
        elements: [
          {
            id: 1,
            min: 0.10,
            max: 0.17,
            bm: "C"
          },
          {
            id: 2,
            min: 0.80,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            min: 0.25,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 1.00,
            max: 1.30,
          },
          {
            id: 7,
            bm: "Cr",
            max: 0.30
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.25,
            max: 0.50
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.015,
            max: 0.045,
          },
          {
            id: 10,
            bm: "N",
            max: 0.020
          },
          {
            id: 11,
            bm: "Al",
            max: 0.050
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.50,
            max: 0.80
          },
          {
            id: 13,
            bm: "V",
            max: 0.02
          }
        ]
      },
      {
        id: 30,
        value: "F36 Class 2",
        elements: [
          {
            id: 1,
            min: 0.10,
            max: 0.17,
            bm: "C"
          },
          {
            id: 2,
            min: 0.80,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            min: 0.25,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 1.00,
            max: 1.30,
          },
          {
            id: 7,
            bm: "Cr",
            max: 0.30
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.25,
            max: 0.50
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.015,
            max: 0.045,
          },
          {
            id: 10,
            bm: "N",
            max: 0.020
          },
          {
            id: 11,
            bm: "Al",
            max: 0.050
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.50,
            max: 0.80
          },
          {
            id: 13,
            bm: "V",
            max: 0.02
          }
        ]
      },
      {
        id: 31,
        value: "F6a Class 1",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50,
          },
          {
            id: 7,
            bm: "Cr",
            min: 11.5,
            max: 13.5
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 32,
        value: "F6a Class 2",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50,
          },
          {
            id: 7,
            bm: "Cr",
            min: 11.5,
            max: 13.5
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 33,
        value: "F6a Class 3",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50,
          },
          {
            id: 7,
            bm: "Cr",
            min: 11.5,
            max: 13.5
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 34,
        value: "F6a Class 4",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50,
          },
          {
            id: 7,
            bm: "Cr",
            min: 11.5,
            max: 13.5
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 35,
        value: "F6b",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 1.00,
            max: 2.00
          },
          {
            id: 7,
            bm: "Cr",
            min: 11.5,
            max: 13.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.40,
            max: 0.60
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Cu",
            max: 0.50
          }
        ]
      },
      {
        id: 36,
        value: "F6NM",
        elements: [
          {
            id: 1,
            max: 0.05,
            bm: "C"
          },
          {
            id: 2,
            min: 0.50,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 3.5,
            max: 5.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 11.5,
            max: 14.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.50,
            max: 1.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 37,
        value: "FXM-27Cb",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.010
              },
              "product": {
                max: 0.012
              }
            },
            bm: "C"
          },
          {
            id: 2,
            max: 0.40,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50
          },
          {
            id: 7,
            bm: "Cr",
            min: 25.0,
            max: 27.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.75,
            max: 1.50
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.05,
            max: 0.20
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Cu",
            max: 0.20
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 12,
            max: 0.5,
            formula: "Cu+Ni",
            bm: "Cu+Ni"
          }
        ]
      },
      {
        id: 38,
        value: "F429",
        elements: [
          {
            id: 1,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.75,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50
          },
          {
            id: 7,
            bm: "Cr",
            min: 14.0,
            max: 16.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 39,
        value: "F430",
        elements: [
          {
            id: 1,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.75,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            max: 0.50
          },
          {
            id: 7,
            bm: "Cr",
            min: 16.0,
            max: 18.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 40,
        value: "F304",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 8.0,
            max: 11.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 18.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            max: 0.10
          }
        ]
      },
      {
        id: 41,
        value: "F304H",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 8.0,
            max: 11.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 18.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 42,
        value: "F304L",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 8.0,
            max: 13.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 18.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            max: 0.10
          }
        ]
      },
      {
        id: 43,
        value: "F304N",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 8.0,
            max: 10.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 18.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            min: 0.10,
            max: 0.16
          }
        ]
      },
      {
        id: 44,
        value: "F304LN",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 8.0,
            max: 10.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 18.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            min: 0.10,
            max: 0.16
          }
        ]
      },
      {
        id: 45,
        value: "F309H",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 12.0,
            max: 15.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 22.0,
            max: 24.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 46,
        value: "F310",
        elements: [
          {
            id: 1,
            max: 0.25,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 19.0,
            max: 22.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 47,
        value: "F310H",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 19.0,
            max: 22.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 48,
        value: "F310MoLN",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.015,
            bm: "S"
          },
          {
            id: 5,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 21.0,
            max: 23.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.00,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            min: 0.10,
            max: 0.16
          }
        ]
      },
      {
        id: 49,
        value: "F316",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 10.0,
            max: 14.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 16.0,
            max: 18.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.00,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            max: 0.10
          }
        ]
      },
      {
        id: 50,
        value: "F316H",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 10.0,
            max: 14.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 16.0,
            max: 18.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.00,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 51,
        value: "F316L",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 10.0,
            max: 15.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 16.0,
            max: 18.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.00,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            max: 0.10
          }
        ]
      },
      {
        id: 52,
        value: "F316N",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 11.0,
            max: 14.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 16.0,
            max: 18.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.00,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            min: 0.10,
            max: 0.16
          }
        ]
      },
      {
        id: 53,
        value: "F316LN",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 11.0,
            max: 14.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 16.0,
            max: 18.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.00,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            min: 0.10,
            max: 0.16
          }
        ]
      },
      {
        id: 54,
        value: "F316Ti",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 10.0,
            max: 14.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 16.0,
            max: 18.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.00,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            max: 0.70,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const nitrogen = elements.find(({bm}) => bm === "N");

              return 5 * carbon.value + nitrogen.value;
            },
            bm: "Ti"
          },
          {
            id: 10,
            bm: "N",
            max: 0.10
          }
        ]
      },
      {
        id: 55,
        value: "F317",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 11.0,
            max: 15.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 18.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.00,
            max: 4.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 56,
        value: "F317L",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 11.0,
            max: 15.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 18.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.00,
            max: 4.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 57,
        value: "F317LNCb",
        elements: [
          {
            id: 1,
            min: 0.005,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 11.0,
            max: 15.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 19.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.00,
            max: 4.5
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.20,
            max: 0.50,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
            },
          },
          {
            id: 9,
            bm: "Ti"
          }
        ]
      },
      {
        id: 58,
        value: "F72",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 14.5,
            max: 16.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.5,
            max: 19.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.8,
            max: 4.5
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Cu",
            min: 2.8,
            max: 4.0
          },
          {
            id: 12,
            bm: "N",
            min: 0.15,
            max: 0.21
          }
        ]
      },
      {
        id: 59,
        value: "F70",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 15,
            max: 16.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 19.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.0,
            max: 4.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Cu",
            min: 4.0,
            max: 5.0
          },
          {
            id: 12,
            bm: "N",
            max: 0.045
          }
        ]
      },
      {
        id: 60,
        value: "F73",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 24.0,
            max: 28.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 22.0,
            max: 24.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 5.0,
            max: 6.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.17,
            max: 0.22
          }
        ]
      },
      {
        id: 61,
        value: "F321",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 9.0,
            max: 12.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 19.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            max: 0.70,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const nitrogen = elements.find(({bm}) => bm === "N");

              return 5 * Number(carbon.value) + Number(nitrogen.value);
            },
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N"
          }
        ]
      },
      {
        id: 62,
        value: "F321H",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.1,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 9.0,
            max: 12.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 19.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            max: 0.70,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const nitrogen = elements.find(({bm}) => bm === "N");

              return 4 * carbon.value + nitrogen.value;
            },
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N"
          }
        ]
      },
      {
        id: 63,
        value: "F347",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 9.0,
            max: 13.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb",
            max: 1.10,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 10 * carbon.value;
            },
          },
          {
            id: 10,
            bm: "Ti"
          }
        ]
      },
      {
        id: 64,
        value: "F347H",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.1,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 9.0,
            max: 13.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb",
            max: 1.10,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 8 * carbon.value;
            },
          },
          {
            id: 10,
            bm: "Ti"
          }
        ]
      },
      {
        id: 65,
        value: "F347LN",
        elements: [
          {
            id: 1,
            min: 0.005,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 9.0,
            max: 13.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 19.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.20,
            max: 0.50,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
            },
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.06,
            max: 0.10
          }
        ]
      },
      {
        id: 66,
        value: "F347LNCuB",
        elements: [
          {
            id: 1,
            min: 0.005,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 10.0,
            max: 13.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 19.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.20,
            max: 1.20
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.20,
            max: 0.50,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
            },
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.06,
            max: 0.12
          },
          {
            id: 12,
            bm: "Cu",
            min: 2.50,
            max: 3.50
          },
          {
            id: 13,
            bm: "B",
            min: 0.001,
            max: 0.005
          },
        ]
      },
      {
        id: 67,
        value: "F348",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 9.0,
            max: 13.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb",
            max: 1.10,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 10 * carbon.value;
            },
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Co",
            max: 0.20
          },
          {
            id: 12,
            bm: "Ta",
            max: 0.10
          }
        ]
      },
      {
        id: 68,
        value: "F348H",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.1,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 9.0,
            max: 13.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb",
            max: 1.10,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 8 * carbon.value;
            },
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Co",
            max: 0.20
          },
          {
            id: 12,
            bm: "Ta",
            max: 0.10
          }
        ]
      },
      {
        id: 69,
        value: "FXM-11",
        elements: [
          {
            id: 1,
            max: 0.04,
            bm: "C"
          },
          {
            id: 2,
            min: 8.0,
            max: 10.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.060,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 5.5,
            max: 7.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 21.5
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.15,
            max: 0.40
          }
        ]
      },
      {
        id: 70,
        value: "FXM-19",
        elements: [
          {
            id: 1,
            max: 0.06,
            bm: "C"
          },
          {
            id: 2,
            min: 4.0,
            max: 6.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 11.5,
            max: 13.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 20.5,
            max: 23.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 1.50,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.10,
            max: 0.30
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.20,
            max: 0.40
          },
          {
            id: 12,
            bm: "V",
            min: 0.10,
            max: 0.30
          }
        ]
      },
      {
        id: 71,
        value: "F20",
        elements: [
          {
            id: 1,
            max: 0.07,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.035,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 32.0,
            max: 38.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 21.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.00,
            max: 3.00
          },
          {
            id: 9,
            bm: "Nb",
            max: 1.10,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 8 * carbon.value;
            },
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Cu",
            min: 3.0,
            max: 4.0
          }
        ]
      },
      {
        id: 72,
        value: "F44",
        elements: [
          {
            id: 1,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 17.5,
            max: 18.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.5,
            max: 20.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 6.0,
            max: 6.5
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Cu",
            min: 0.50,
            max: 1.00
          },
          {
            id: 12,
            bm: "N",
            min: 0.18,
            max: 0.25
          }
        ]
      },
      {
        id: 73,
        value: "F45",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 0.80,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 1.40,
            max: 2.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 10.0,
            max: 12.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 20.0,
            max: 22.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Ce",
            min: 0.03,
            max: 0.08
          },
          {
            id: 12,
            bm: "N",
            min: 0.14,
            max: 0.20
          }
        ]
      },
      {
        id: 74,
        value: "F46",
        elements: [
          {
            id: 1,
            max: 0.018,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            min: 3.7,
            max: 4.3,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 14.0,
            max: 15.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 18.5
          },
          {
            id: 8,
            bm: "Mo",
            max: 0.20
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Cu",
            max: 0.50
          },
          {
            id: 12,
            bm: "N",
            min: 0.18,
            max: 0.25
          }
        ]
      },
      {
        id: 75,
        value: "F47",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.75,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 13.0,
            max: 17.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 18.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 4.0,
            max: 5.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            max: 0.10
          }
        ]
      },
      {
        id: 76,
        value: "F48",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.75,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 13.5,
            max: 17.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 20.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 4.0,
            max: 5.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.10,
            max: 0.20
          }
        ]
      },
      {
        id: 77,
        value: "F49",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            min: 5.0,
            max: 7.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 16.0,
            max: 18.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 23.0,
            max: 25.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 4.0,
            max: 5.0
          },
          {
            id: 9,
            bm: "Nb",
            max: 0.10
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.40,
            max: 0.60
          }
        ]
      },
      {
        id: 78,
        value: "F56",
        elements: [
          {
            id: 1,
            min: 0.04,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.015,
            bm: "S"
          },
          {
            id: 5,
            max: 0.30,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 31.0,
            max: 33.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 26.0,
            max: 28.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb",
            min: 0.6,
            max: 1.0
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Ce",
            min: 0.05,
            max: 0.10
          },
          {
            id: 12,
            bm: "Al",
            max: 0.025
          }
        ]
      },
      {
        id: 79,
        value: "F58",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            min: 2.0,
            max: 4.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 21.0,
            max: 24.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 23.0,
            max: 25.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 5.2,
            max: 6.2
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.35,
            max: 0.60
          },
          {
            id: 12,
            bm: "Cu",
            min: 1.00,
            max: 2.50
          },
          {
            id: 13,
            bm: "W",
            min: 1.50,
            max: 2.50
          }
        ]
      },
      {
        id: 80,
        value: "F62",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 23.5,
            max: 25.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 20.0,
            max: 22.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 6.0,
            max: 7.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.18,
            max: 0.25
          },
          {
            id: 12,
            bm: "Cu",
            max: 0.75
          }
        ]
      },
      {
        id: 81,
        value: "F63",
        elements: [
          {
            id: 1,
            max: 0.07,
            bm: "C"
          },
          {
            id: 2,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 4.8,
            max: 6.0,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 19.0,
            max: 22.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 16.5,
            max: 19.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.30,
            max: 1.50
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Cu",
            min: 1.50,
            max: 2.50
          }
        ]
      },
      {
        id: 82,
        value: "F64",
        elements: [
          {
            id: 1,
            max: 0.015,
            bm: "C"
          },
          {
            id: 2,
            min: 0.50,
            max: 0.80,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.013,
            bm: "S"
          },
          {
            id: 5,
            min: 5.0,
            max: 5.6,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 17.0,
            max: 18.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 17.0,
            max: 18.0
          },
          {
            id: 8,
            bm: "Mo",
            max: 0.20
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            max: 0.05
          },
          {
            id: 12,
            bm: "Cu",
            max: 0.35
          }
        ]
      },
      {
        id: 83,
        value: "F904L",
        elements: [
          {
            id: 1,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 23.0,
            max: 28.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 23.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 4.0,
            max: 5.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            max: 0.10
          },
          {
            id: 12,
            bm: "Cu",
            min: 1.00,
            max: 2.00
          }
        ]
      },
      {
        id: 84,
        value: "F700",
        elements: [
          {
            id: 1,
            max: 0.04,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 24.0,
            max: 26.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 23.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 4.3,
            max: 5.0
          },
          {
            id: 9,
            bm: "Nb",
            max: 0.40,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 8 * carbon.value;
            },
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Cu",
            max: 0.50
          }
        ]
      },
      {
        id: 85,
        value: "FNIC",
        elements: [
          {
            id: 1,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.015,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 30.0,
            max: 35.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 23.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti",
            min: 0.15,
            max: 0.60
          },
          {
            id: 11,
            bm: "Al",
            min: 0.15,
            max: 0.60
          },
          {
            id: 12,
            bm: "Cu",
            max: 0.75
          },
          {
            id: 13,
            bm: "Fe",
            min: 39.5
          }
        ]
      },
      {
        id: 86,
        value: "FNIC10",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.015,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 30.0,
            max: 35.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 23.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti",
            min: 0.15,
            max: 0.60
          },
          {
            id: 11,
            bm: "Al",
            min: 0.15,
            max: 0.60
          },
          {
            id: 12,
            bm: "Cu",
            max: 0.75
          },
          {
            id: 13,
            bm: "Fe",
            min: 39.5
          }
        ]
      },
      {
        id: 87,
        value: "FNIC11",
        elements: [
          {
            id: 1,
            min: 0.06,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.015,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 30.0,
            max: 35.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 23.0
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti",
            min: 0.25,
            max: 0.60
          },
          {
            id: 11,
            bm: "Al",
            min: 0.25,
            max: 0.60
          },
          {
            id: 12,
            bm: "Cu",
            max: 0.75
          },
          {
            id: 13,
            bm: "Fe",
            min: 39.5
          },
          {
            id: 14,
            min: 0.85,
            max: 1.20,
            formula: "Al+Ti",
            bm: "Al+Ti"
          },
        ]
      },
      {
        id: 88,
        value: "F1925",
        elements: [
          {
            id: 1,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 24.0,
            max: 26.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 21.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 6.0,
            max: 7.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.10,
            max: 0.20
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.80,
            max: 1.50
          }
        ]
      },
      {
        id: 89,
        value: "F1925N",
        elements: [
          {
            id: 1,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 24.0,
            max: 26.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 19.0,
            max: 21.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 6.0,
            max: 7.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.15,
            max: 0.25
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.50,
            max: 1.50
          }
        ]
      },
      {
        id: 90,
        value: "F50",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 5.5,
            max: 6.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 1.20,
            max: 2.00
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.14,
            max: 0.20
          }
        ]
      },
      {
        id: 91,
        value: "F51",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 4.5,
            max: 6.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 21.0,
            max: 23.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.5,
            max: 3.5
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.08,
            max: 0.20
          }
        ]
      },
      {
        id: 92,
        value: "F69",
        elements: [
          {
            id: 1,
            max: 0.040,
            bm: "C"
          },
          {
            id: 2,
            min: 4.00,
            max: 6.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 1.35,
            max: 1.70
          },
          {
            id: 7,
            bm: "Cr",
            min: 21.0,
            max: 22.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.10,
            max: 0.80
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.20,
            max: 0.25
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.10,
            max: 0.80
          }
        ]
      },
      {
        id: 93,
        value: "F52",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 3.5,
            max: 5.2
          },
          {
            id: 7,
            bm: "Cr",
            min: 26.0,
            max: 29.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 1.00,
            max: 2.50
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.15,
            max: 0.35
          }
        ]
      },
      {
        id: 94,
        value: "F53",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 6.0,
            max: 8.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.0,
            max: 5.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.24,
            max: 0.32
          },
          {
            id: 12,
            bm: "Cu",
            max: 0.50
          }
        ]
      },
      {
        id: 95,
        value: "F54",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 6.0,
            max: 8.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.5,
            max: 3.5
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.24,
            max: 0.32
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.20,
            max: 0.80
          },
          {
            id: 13,
            bm: "W",
            min: 1.50,
            max: 2.50
          }
        ]
      },
      {
        id: 96,
        value: "F55",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 6.0,
            max: 8.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.0,
            max: 4.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.20,
            max: 0.30
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.50,
            max: 1.00
          },
          {
            id: 13,
            bm: "W",
            min: 0.50,
            max: 1.00
          }
        ]
      },
      {
        id: 97,
        value: "F57",
        elements: [
          {
            id: 1,
            max: 0.025,
            bm: "C"
          },
          {
            id: 2,
            max: 0.80,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            max: 0.002,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 6.5,
            max: 8.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.0,
            max: 4.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.23,
            max: 0.33
          },
          {
            id: 12,
            bm: "Cu",
            min: 1.20,
            max: 2.00
          },
          {
            id: 13,
            bm: "W",
            min: 0.80,
            max: 1.20
          }
        ]
      },
      {
        id: 98,
        value: "F59",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 5.5,
            max: 8.0
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.0,
            max: 5.0
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.20,
            max: 0.35
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.50,
            max: 3.00
          }
        ]
      },
      {
        id: 99,
        value: "F60",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 4.5,
            max: 6.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 22.0,
            max: 23.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.0,
            max: 3.5
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.14,
            max: 0.20
          }
        ]
      },
      {
        id: 100,
        value: "F61",
        elements: [
          {
            id: 1,
            max: 0.040,
            bm: "C"
          },
          {
            id: 2,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 4.5,
            max: 6.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 27.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 2.9,
            max: 3.9
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.10,
            max: 0.25
          },
          {
            id: 12,
            bm: "Cu",
            min: 1.50,
            max: 2.50
          }
        ]
      },
      {
        id: 101,
        value: "F65",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            min: 0.80,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 5.8,
            max: 7.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 28.0,
            max: 30.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 1.5,
            max: 2.6
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.30,
            max: 0.40
          },
          {
            id: 12,
            bm: "Cu",
            max: 0.80
          }
        ]
      },
      {
        id: 102,
        value: "F66",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 1.00,
            max: 2.80
          },
          {
            id: 7,
            bm: "Cr",
            min: 21.5,
            max: 24.0
          },
          {
            id: 8,
            bm: "Mo",
            max: 0.45
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.18,
            max: 0.26
          }
        ]
      },
      {
        id: 103,
        value: "F67",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.015,
            bm: "S"
          },
          {
            id: 5,
            max: 0.90,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 5.5,
            max: 7.2
          },
          {
            id: 7,
            bm: "Cr",
            min: 24.0,
            max: 26.0
          },
          {
            id: 8,
            bm: "Mo",
            min: 3.0,
            max: 3.5
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.08,
            max: 0.20
          },
          {
            id: 12,
            bm: "W",
            min: 0.05,
            max: 0.30
          }
        ]
      },
      {
        id: 104,
        value: "F68",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 3.0,
            max: 5.5
          },
          {
            id: 7,
            bm: "Cr",
            min: 21.5,
            max: 24.5
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.05,
            max: 0.60
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.05,
            max: 0.20
          },
          {
            id: 12,
            bm: "Cu",
            min: 0.05,
            max: 0.60
          }
        ]
      },
      {
        id: 105,
        value: "F71",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.10,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni",
            min: 7.0,
            max: 8.2
          },
          {
            id: 7,
            bm: "Cr",
            min: 27.0,
            max: 27.9
          },
          {
            id: 8,
            bm: "Mo",
            min: 0.80,
            max: 1.2
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "N",
            min: 0.30,
            max: 0.40
          },
          {
            id: 12,
            bm: "W",
            min: 2.10,
            max: 2.50
          }
        ]
      },
    ]
  },
  {
    id: 8,
    value: "ASTM A193 (2020)",
    grades: [
      {
        id: 0,
        value: "B7",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.38,
                max: 0.48
              },
              "product": {
                min: 0.36,
                max: 0.5
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.75,
                max: 1.0
              },
              "product": {
                min: 0.71,
                max: 1.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.040
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.35
              },
              "product": {
                min: 0.12,
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 0.80,
                max: 1.10
              },
              "product": {
                min: 0.75,
                max: 1.15
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.25
              },
              "product": {
                min: 0.13,
                max: 0.27
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 1,
        value: "B7M",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.38,
                max: 0.48
              },
              "product": {
                min: 0.36,
                max: 0.5
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.75,
                max: 1.0
              },
              "product": {
                min: 0.71,
                max: 1.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.040
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.35
              },
              "product": {
                min: 0.12,
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 0.80,
                max: 1.10
              },
              "product": {
                min: 0.75,
                max: 1.15
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.25
              },
              "product": {
                min: 0.13,
                max: 0.27
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 2,
        value: "B8M Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 3,
        value: "B8MA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 4,
        value: "B8M2 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 5,
        value: "B8M3 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 6,
        value: "B5",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.10,
                max: 1.10
              },
              "product": {
                min: 0.09,
                max: 1.11
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.04
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 4.0,
                max: 6.0
              },
              "product": {
                min: 3.9,
                max: 6.1
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.65
              },
              "product": {
                min: 0.35,
                max: 0.70
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 7,
        value: "B6",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.15
              },
              "product": {
                min: 0.08,
                max: 0.16
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.04
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 11.5,
                max: 13.5
              },
              "product": {
                min: 11.35,
                max: 13.65
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 8,
        value: "B6X",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.15
              },
              "product": {
                min: 0.08,
                max: 0.16
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.04
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 11.5,
                max: 13.5
              },
              "product": {
                min: 11.35,
                max: 13.65
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 9,
        value: "B16",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.36,
                max: 0.47
              },
              "product": {
                min: 0.34,
                max: 0.49
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.45,
                max: 0.70
              },
              "product": {
                min: 0.42,
                max: 0.73
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.05
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.35
              },
              "product": {
                min: 0.13,
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 0.80,
                max: 1.15
              },
              "product": {
                min: 0.75,
                max: 1.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.50,
                max: 0.65
              },
              "product": {
                min: 0.47,
                max: 0.68
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.25,
                max: 0.35
              },
              "product": {
                min: 0.22,
                max: 0.38
              }
            },
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 10,
        value: "B8 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 11,
        value: "B8 Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 12,
        value: "B8 Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 13,
        value: "B8 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 14,
        value: "B8A Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 15,
        value: "B8A Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 16,
        value: "B8A Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 17,
        value: "B8A Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 18,
        value: "B8C Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0,
                max: 1.1
              },
              "product": {
                min: 0,
                max: 1.1
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 19,
        value: "B8C Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0,
                max: 1.1
              },
              "product": {
                min: 0,
                max: 1.1
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 20,
        value: "B8C Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0,
                max: 1.1
              },
              "product": {
                min: 0,
                max: 1.1
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 21,
        value: "B8C Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0,
                max: 1.1
              },
              "product": {
                min: 0,
                max: 1.1
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 22,
        value: "B8CA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0,
                max: 1.1
              },
              "product": {
                min: 0,
                max: 1.1
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 23,
        value: "B8CA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0,
                max: 1.1
              },
              "product": {
                min: 0,
                max: 1.1
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 24,
        value: "B8CA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0,
                max: 1.1
              },
              "product": {
                min: 0,
                max: 1.1
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 25,
        value: "B8CA Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0,
                max: 1.1
              },
              "product": {
                min: 0,
                max: 1.1
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 26,
        value: "B8M Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 27,
        value: "B8M Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 28,
        value: "B8M Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 29,
        value: "B8MA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 30,
        value: "B8MA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 31,
        value: "B8MA Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 32,
        value: "B8M2 Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 33,
        value: "B8M2 Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 34,
        value: "B8M2 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 35,
        value: "B8M3 Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 36,
        value: "B8M3 Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 37,
        value: "B8M3 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 10.0,
                max: 14.0
              },
              "product": {
                min: 9.85,
                max: 14.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 38,
        value: "B8P Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.12
              },
              "product": {
                min: 0,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.0,
                max: 13.0
              },
              "product": {
                min: 10.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 39,
        value: "B8P Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.12
              },
              "product": {
                min: 0,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.0,
                max: 13.0
              },
              "product": {
                min: 10.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 40,
        value: "B8P Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.12
              },
              "product": {
                min: 0,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.0,
                max: 13.0
              },
              "product": {
                min: 10.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 41,
        value: "B8P Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.12
              },
              "product": {
                min: 0,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.0,
                max: 13.0
              },
              "product": {
                min: 10.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 42,
        value: "B8PA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.12
              },
              "product": {
                min: 0,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.0,
                max: 13.0
              },
              "product": {
                min: 10.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 43,
        value: "B8PA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.12
              },
              "product": {
                min: 0,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.0,
                max: 13.0
              },
              "product": {
                min: 10.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 44,
        value: "B8PA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.12
              },
              "product": {
                min: 0,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.0,
                max: 13.0
              },
              "product": {
                min: 10.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 45,
        value: "B8PA Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.12
              },
              "product": {
                min: 0,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.0,
                max: 13.0
              },
              "product": {
                min: 10.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 46,
        value: "B8N Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 47,
        value: "B8N Class 1B",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 48,
        value: "B8N Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                min: 0,
                max: 0.015
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 49,
        value: "B8N Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 50,
        value: "B8NA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 51,
        value: "B8NA Class 1B",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 52,
        value: "B8NA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 53,
        value: "B8NA Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 54,
        value: "B8MN Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 55,
        value: "B8MN Class 1B",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 56,
        value: "B8MN Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 57,
        value: "B8MN Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 58,
        value: "B8MNA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 59,
        value: "B8MNA Class 1B",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 60,
        value: "B8MNA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 61,
        value: "B8MNA Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.8,
                max: 18.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 62,
        value: "B8MLCuN Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.02
              },
              "product": {
                min: 0,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.8
              },
              "product": {
                min: 0,
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 19.5,
                max: 20.5
              },
              "product": {
                min: 19.3,
                max: 20.7
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 17.5,
                max: 18.5
              },
              "product": {
                min: 17.35,
                max: 18.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 6.0,
                max: 6.5
              },
              "product": {
                min: 5.90,
                max: 6.60
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.0
              },
              "product": {
                min: 0.50,
                max: 1.0
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 63,
        value: "B8MLCuN Class 1B",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.02
              },
              "product": {
                min: 0,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.8
              },
              "product": {
                min: 0,
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 19.5,
                max: 20.5
              },
              "product": {
                min: 19.3,
                max: 20.7
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 17.5,
                max: 18.5
              },
              "product": {
                min: 17.35,
                max: 18.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 6.0,
                max: 6.5
              },
              "product": {
                min: 5.90,
                max: 6.60
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.0
              },
              "product": {
                min: 0.50,
                max: 1.0
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 64,
        value: "B8MLCuN Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.02
              },
              "product": {
                min: 0,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.8
              },
              "product": {
                min: 0,
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 19.5,
                max: 20.5
              },
              "product": {
                min: 19.3,
                max: 20.7
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 17.5,
                max: 18.5
              },
              "product": {
                min: 17.35,
                max: 18.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 6.0,
                max: 6.5
              },
              "product": {
                min: 5.90,
                max: 6.60
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.0
              },
              "product": {
                min: 0.50,
                max: 1.0
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 65,
        value: "B8MLCuN Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.02
              },
              "product": {
                min: 0,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.8
              },
              "product": {
                min: 0,
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 19.5,
                max: 20.5
              },
              "product": {
                min: 19.3,
                max: 20.7
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 17.5,
                max: 18.5
              },
              "product": {
                min: 17.35,
                max: 18.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 6.0,
                max: 6.5
              },
              "product": {
                min: 5.90,
                max: 6.60
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.0
              },
              "product": {
                min: 0.50,
                max: 1.0
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 66,
        value: "B8MLCuNA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.02
              },
              "product": {
                min: 0,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.8
              },
              "product": {
                min: 0,
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 19.5,
                max: 20.5
              },
              "product": {
                min: 19.3,
                max: 20.7
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 17.5,
                max: 18.5
              },
              "product": {
                min: 17.35,
                max: 18.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 6.0,
                max: 6.5
              },
              "product": {
                min: 5.90,
                max: 6.60
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.0
              },
              "product": {
                min: 0.50,
                max: 1.0
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 67,
        value: "B8MLCuNA Class 1B",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.02
              },
              "product": {
                min: 0,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.8
              },
              "product": {
                min: 0,
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 19.5,
                max: 20.5
              },
              "product": {
                min: 19.3,
                max: 20.7
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 17.5,
                max: 18.5
              },
              "product": {
                min: 17.35,
                max: 18.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 6.0,
                max: 6.5
              },
              "product": {
                min: 5.90,
                max: 6.60
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.0
              },
              "product": {
                min: 0.50,
                max: 1.0
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 68,
        value: "B8MLCuNA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.02
              },
              "product": {
                min: 0,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.8
              },
              "product": {
                min: 0,
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 19.5,
                max: 20.5
              },
              "product": {
                min: 19.3,
                max: 20.7
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 17.5,
                max: 18.5
              },
              "product": {
                min: 17.35,
                max: 18.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 6.0,
                max: 6.5
              },
              "product": {
                min: 5.90,
                max: 6.60
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.0
              },
              "product": {
                min: 0.50,
                max: 1.0
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 69,
        value: "B8MLCuNA Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.02
              },
              "product": {
                min: 0,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.03
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.8
              },
              "product": {
                min: 0,
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 19.5,
                max: 20.5
              },
              "product": {
                min: 19.3,
                max: 20.7
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 17.5,
                max: 18.5
              },
              "product": {
                min: 17.35,
                max: 18.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 6.0,
                max: 6.5
              },
              "product": {
                min: 5.90,
                max: 6.60
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.0
              },
              "product": {
                min: 0.50,
                max: 1.0
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 70,
        value: "B8T Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0,
                max: 0.70
              },
              "product": {
                min: 0,
                max: 0.70
              }
            },
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0,
                max: 0.10
              },
              "product": {
                min: 0,
                max: 0.10
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 71,
        value: "B8T Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0,
                max: 0.70
              },
              "product": {
                min: 0,
                max: 0.70
              }
            },
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0,
                max: 0.10
              },
              "product": {
                min: 0,
                max: 0.10
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 72,
        value: "B8T Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0,
                max: 0.70
              },
              "product": {
                min: 0,
                max: 0.70
              }
            },
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0,
                max: 0.10
              },
              "product": {
                min: 0,
                max: 0.10
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 73,
        value: "B8TA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0,
                max: 0.70
              },
              "product": {
                min: 0,
                max: 0.70
              }
            },
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0,
                max: 0.10
              },
              "product": {
                min: 0,
                max: 0.10
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 74,
        value: "B8TA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0,
                max: 0.70
              },
              "product": {
                min: 0,
                max: 0.70
              }
            },
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0,
                max: 0.10
              },
              "product": {
                min: 0,
                max: 0.10
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 75,
        value: "B8TA Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.08
              },
              "product": {
                min: 0,
                max: 0.09
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.8,
                max: 19.2
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 12.0
              },
              "product": {
                min: 8.85,
                max: 12.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.0,
                max: 3.0
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0,
                max: 0.70
              },
              "product": {
                min: 0,
                max: 0.70
              }
            },
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0,
                max: 0.10
              },
              "product": {
                min: 0,
                max: 0.10
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 76,
        value: "B8R Class 1C",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.06
              },
              "product": {
                min: 0,
                max: 0.07
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 4.0,
                max: 6.0
              },
              "product": {
                min: 3.95,
                max: 6.05
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.05
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 20.5,
                max: 23.5
              },
              "product": {
                min: 20.25,
                max: 23.75
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.5,
                max: 13.5
              },
              "product": {
                min: 11.35,
                max: 13.75
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 1.5,
                max: 3.0
              },
              "product": {
                min: 1.40,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.3
              },
              "product": {
                min: 0.05,
                max: 0.35
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.3
              },
              "product": {
                min: 0.08,
                max: 0.32
              }
            },
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.2,
                max: 0.40
              },
              "product": {
                min: 0.18,
                max: 0.42
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 77,
        value: "B8R Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.06
              },
              "product": {
                min: 0,
                max: 0.07
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 4.0,
                max: 6.0
              },
              "product": {
                min: 3.95,
                max: 6.05
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.05
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 20.5,
                max: 23.5
              },
              "product": {
                min: 20.25,
                max: 23.75
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.5,
                max: 13.5
              },
              "product": {
                min: 11.35,
                max: 13.75
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 1.5,
                max: 3.0
              },
              "product": {
                min: 1.40,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.3
              },
              "product": {
                min: 0.05,
                max: 0.35
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.3
              },
              "product": {
                min: 0.08,
                max: 0.32
              }
            },
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.2,
                max: 0.40
              },
              "product": {
                min: 0.18,
                max: 0.42
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 78,
        value: "B8S Class 1C",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.1
              },
              "product": {
                min: 0,
                max: 0.11
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 7.0,
                max: 9.0
              },
              "product": {
                min: 6.94,
                max: 9.6
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.06
              },
              "product": {
                min: 0,
                max: 0.065
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 3.5,
                max: 4.5
              },
              "product": {
                min: 3.35,
                max: 4.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 9.0
              },
              "product": {
                min: 7.9,
                max: 9.1
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.18
              },
              "product": {
                min: 0.07,
                max: 0.19
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 79,
        value: "B8S Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.1
              },
              "product": {
                min: 0,
                max: 0.11
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 7.0,
                max: 9.0
              },
              "product": {
                min: 6.94,
                max: 9.6
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.06
              },
              "product": {
                min: 0,
                max: 0.065
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 3.5,
                max: 4.5
              },
              "product": {
                min: 3.35,
                max: 4.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 9.0
              },
              "product": {
                min: 7.9,
                max: 9.1
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.18
              },
              "product": {
                min: 0.07,
                max: 0.19
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 80,
        value: "B8SA Class 1C",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.1
              },
              "product": {
                min: 0,
                max: 0.11
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 7.0,
                max: 9.0
              },
              "product": {
                min: 6.94,
                max: 9.6
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.06
              },
              "product": {
                min: 0,
                max: 0.065
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 3.5,
                max: 4.5
              },
              "product": {
                min: 3.35,
                max: 4.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 9.0
              },
              "product": {
                min: 7.9,
                max: 9.1
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.18
              },
              "product": {
                min: 0.07,
                max: 0.19
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 81,
        value: "B8SA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.1
              },
              "product": {
                min: 0,
                max: 0.11
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 7.0,
                max: 9.0
              },
              "product": {
                min: 6.94,
                max: 9.6
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.06
              },
              "product": {
                min: 0,
                max: 0.065
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 3.5,
                max: 4.5
              },
              "product": {
                min: 3.35,
                max: 4.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 9.0
              },
              "product": {
                min: 7.9,
                max: 9.1
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.18
              },
              "product": {
                min: 0.07,
                max: 0.19
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 82,
        value: "B8LN Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 83,
        value: "B8LN Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 84,
        value: "B8LN Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 85,
        value: "B8LNA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 86,
        value: "B8LNA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 87,
        value: "B8LNA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.03
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.0
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.0
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 18.0,
                max: 20.0
              },
              "product": {
                min: 17.80,
                max: 20.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 8.0,
                max: 11.0
              },
              "product": {
                min: 7.85,
                max: 11.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 88,
        value: "B8MLN Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 89,
        value: "B8MLN Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 90,
        value: "B8MLN Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 91,
        value: "B8MLNA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 92,
        value: "B8MLNA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 93,
        value: "B8MLNA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 16.0,
                max: 18.0
              },
              "product": {
                min: 15.80,
                max: 18.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.16
              },
              "product": {
                min: 0.09,
                max: 0.17
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 94,
        value: "B8CLN Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 13.0
              },
              "product": {
                min: 8.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 95,
        value: "B8CLN Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 13.0
              },
              "product": {
                min: 8.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 96,
        value: "B8CLN Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 13.0
              },
              "product": {
                min: 8.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 97,
        value: "B8CLNA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 13.0
              },
              "product": {
                min: 8.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 98,
        value: "B8CLNA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 13.0
              },
              "product": {
                min: 8.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 99,
        value: "B8CLNA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.055
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 9.0,
                max: 13.0
              },
              "product": {
                min: 8.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 2.00,
                max: 3.00
              },
              "product": {
                min: 1.90,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 100,
        value: "B8CLNCuB Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.60
              },
              "product": {
                min: 0,
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 1.20
              },
              "product": {
                min: 0.20,
                max: 1.20
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 2.50,
                max: 3.50
              },
              "product": {
                min: 2.35,
                max: 3.65
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.12
              },
              "product": {
                min: 0.05,
                max: 0.13
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 101,
        value: "B8CLNCuB Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.60
              },
              "product": {
                min: 0,
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 1.20
              },
              "product": {
                min: 0.20,
                max: 1.20
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 2.50,
                max: 3.50
              },
              "product": {
                min: 2.35,
                max: 3.65
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.12
              },
              "product": {
                min: 0.05,
                max: 0.13
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 102,
        value: "B8CLNCuB Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.60
              },
              "product": {
                min: 0,
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 1.20
              },
              "product": {
                min: 0.20,
                max: 1.20
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 2.50,
                max: 3.50
              },
              "product": {
                min: 2.35,
                max: 3.65
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.12
              },
              "product": {
                min: 0.05,
                max: 0.13
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 103,
        value: "B8CLNCuBA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.60
              },
              "product": {
                min: 0,
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 1.20
              },
              "product": {
                min: 0.20,
                max: 1.20
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 2.50,
                max: 3.50
              },
              "product": {
                min: 2.35,
                max: 3.65
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.12
              },
              "product": {
                min: 0.05,
                max: 0.13
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 104,
        value: "B8CLNCuBA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.60
              },
              "product": {
                min: 0,
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 1.20
              },
              "product": {
                min: 0.20,
                max: 1.20
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 2.50,
                max: 3.50
              },
              "product": {
                min: 2.35,
                max: 3.65
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.12
              },
              "product": {
                min: 0.05,
                max: 0.13
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 105,
        value: "B8CLNCuBA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.005,
                max: 0.020
              },
              "product": {
                min: 0.003,
                max: 0.025
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.035
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.015
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 0.60
              },
              "product": {
                min: 0,
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 10.0,
                max: 13.0
              },
              "product": {
                min: 9.85,
                max: 13.15
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 1.20
              },
              "product": {
                min: 0.20,
                max: 1.20
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 2.50,
                max: 3.50
              },
              "product": {
                min: 2.35,
                max: 3.65
              }
            },
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.50,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.20 ? 15 * carbon.value : 0.20;
                },
              },
              "product": {
                min: 0.15,
                max: 0.55,
                getMin({elements = []}) {
                  const carbon = elements.find(({bm}) => bm === "C");

                  return 15 * carbon.value > 0.15 ? 15 * carbon.value : 0.15;
                },
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.12
              },
              "product": {
                min: 0.05,
                max: 0.13
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 106,
        value: "B8ML4CuN Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 15.0,
                max: 16.5
              },
              "product": {
                min: 14.85,
                max: 16.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 3.0,
                max: 4.0
              },
              "product": {
                min: 2.85,
                max: 4.15
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.046
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 107,
        value: "B8ML4CuN Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 15.0,
                max: 16.5
              },
              "product": {
                min: 14.85,
                max: 16.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 3.0,
                max: 4.0
              },
              "product": {
                min: 2.85,
                max: 4.15
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.046
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 108,
        value: "B8ML4CuN Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 15.0,
                max: 16.5
              },
              "product": {
                min: 14.85,
                max: 16.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 3.0,
                max: 4.0
              },
              "product": {
                min: 2.85,
                max: 4.15
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.046
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 109,
        value: "B8ML4CuNA Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 15.0,
                max: 16.5
              },
              "product": {
                min: 14.85,
                max: 16.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 3.0,
                max: 4.0
              },
              "product": {
                min: 2.85,
                max: 4.15
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.046
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 110,
        value: "B8ML4CuNA Class 1A",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 15.0,
                max: 16.5
              },
              "product": {
                min: 14.85,
                max: 16.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 3.0,
                max: 4.0
              },
              "product": {
                min: 2.85,
                max: 4.15
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.046
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 111,
        value: "B8ML4CuNA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0,
                max: 2.00
              },
              "product": {
                min: 0,
                max: 2.04
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.040
              },
              "product": {
                min: 0,
                max: 0.045
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.010
              },
              "product": {
                min: 0,
                max: 0.012
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 17.0,
                max: 19.0
              },
              "product": {
                min: 16.80,
                max: 19.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            value: 0.00,
            analysis: {
              "heat": {
                min: 15.0,
                max: 16.5
              },
              "product": {
                min: 14.85,
                max: 16.65
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 3.0,
                max: 4.0
              },
              "product": {
                min: 2.85,
                max: 4.15
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.046
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 112,
        value: "B8RA Class 1C",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.06
              },
              "product": {
                min: 0,
                max: 0.07
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 4.0,
                max: 6.0
              },
              "product": {
                min: 3.95,
                max: 6.05
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.05
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 20.5,
                max: 23.5
              },
              "product": {
                min: 20.25,
                max: 23.75
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.5,
                max: 13.5
              },
              "product": {
                min: 11.35,
                max: 13.75
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 1.5,
                max: 3.0
              },
              "product": {
                min: 1.40,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.3
              },
              "product": {
                min: 0.05,
                max: 0.35
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.3
              },
              "product": {
                min: 0.08,
                max: 0.32
              }
            },
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.2,
                max: 0.40
              },
              "product": {
                min: 0.18,
                max: 0.42
              }
            },
            bm: "N"
          }
        ]
      },
      {
        id: 113,
        value: "B8RA Class 1D",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0,
                max: 0.06
              },
              "product": {
                min: 0,
                max: 0.07
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 4.0,
                max: 6.0
              },
              "product": {
                min: 3.95,
                max: 6.05
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0,
                max: 0.045
              },
              "product": {
                min: 0,
                max: 0.05
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                min: 0,
                max: 0.030
              },
              "product": {
                min: 0,
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                min: 0,
                max: 1.00
              },
              "product": {
                min: 0,
                max: 1.05
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 20.5,
                max: 23.5
              },
              "product": {
                min: 20.25,
                max: 23.75
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 11.5,
                max: 13.5
              },
              "product": {
                min: 11.35,
                max: 13.75
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 1.5,
                max: 3.0
              },
              "product": {
                min: 1.40,
                max: 3.10
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.3
              },
              "product": {
                min: 0.05,
                max: 0.35
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.1,
                max: 0.3
              },
              "product": {
                min: 0.08,
                max: 0.32
              }
            },
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                min: 0.2,
                max: 0.40
              },
              "product": {
                min: 0.18,
                max: 0.42
              }
            },
            bm: "N"
          }
        ]
      },
    ]
  },
  {
    id: 9,
    value: "ASTM A194 (2020a)",
    grades: [
      {
        id: 0,
        value: "2",
        elements: [
          {
            id: 1,
            value: 0.40,
            min: 0.40,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.050,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 1,
        value: "2HM",
        elements: [
          {
            id: 1,
            value: 0.40,
            min: 0.40,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.050,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 2,
        value: "2H",
        elements: [
          {
            id: 1,
            value: 0.40,
            min: 0.40,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.050,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 3,
        value: "8M",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.00,
            max: 18.00,
            bm: "Cr"
          },
          {
            id: 7,
            min: 10.00,
            max: 14.00,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 4,
        value: "8MA",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.00,
            max: 18.00,
            bm: "Cr"
          },
          {
            id: 7,
            min: 10.00,
            max: 14.00,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 5,
        value: "1",
        elements: [
          {
            id: 1,
            min: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.050,
            bm: "S"
          },
          {
            id: 5,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 6,
        value: "3",
        elements: [
          {
            id: 1,
            min: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 4.0,
            max: 6.0,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.40,
            max: 0.65,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 7,
        value: "6",
        elements: [
          {
            id: 1,
            min: 0.08,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 11.5,
            max: 13.5,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 8,
        value: "6F",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.25,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.060,
            bm: "P"
          },
          {
            id: 4,
            min: 0.15,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 12.0,
            max: 14.0,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          }
        ]
      },
      {
        id: 9,
        value: "6F - 416Se UNS S41623",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.25,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.060,
            bm: "P"
          },
          {
            id: 4,
            min: 0.15,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 12.0,
            max: 14.0,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
          {
            id: 13,
            bm: "Al"
          },
          {
            id: 14,
            bm: "N"
          },
          {
            id: 15,
            min: 0.15,
            bm: "Se"
          }
        ]
      },
      {
        id: 10,
        value: "7",
        elements: [
          {
            id: 1,
            min: 0.38,
            max: 0.48,
            bm: "C"
          },
          {
            id: 2,
            min: 0.75,
            max: 1.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            min: 0.80,
            max: 1.10,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.15,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 11,
        value: "7M",
        elements: [
          {
            id: 1,
            min: 0.38,
            max: 0.48,
            bm: "C"
          },
          {
            id: 2,
            min: 0.75,
            max: 1.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            min: 0.80,
            max: 1.10,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.15,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 12,
        value: "16",
        elements: [
          {
            id: 1,
            min: 0.36,
            max: 0.47,
            bm: "C"
          },
          {
            id: 2,
            min: 0.45,
            max: 0.70,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            min: 0.15,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            min: 0.80,
            max: 1.15,
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.50,
            max: 0.65,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            min: 0.25,
            max: 0.35,
            bm: "V"
          },
          {
            id: 15,
            max: 0.15,
            bm: "Al"
          }
        ]
      },
      {
        id: 13,
        value: "9C",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 20.0,
            max: 22.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 23.5,
            max: 25.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 6.0,
            max: 7.0,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.18,
            max: 0.25,
            bm: "N"
          },
          {
            id: 12,
            max: 0.75,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 14,
        value: "9CA",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 20.0,
            max: 22.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 23.5,
            max: 25.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 6.0,
            max: 7.0,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.18,
            max: 0.25,
            bm: "N"
          },
          {
            id: 12,
            max: 0.75,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 15,
        value: "8",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 16,
        value: "8A",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 17,
        value: "8C",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 9.0,
            max: 12.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            min: 1.10,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 10 * carbon.value <= 1.10 ? 1.10 : 10 * carbon.value;
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 18,
        value: "8CA",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 9.0,
            max: 12.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            min: 1.10,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 10 * carbon.value <= 1.10 ? 1.10 : 10 * carbon.value;
            },
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 19,
        value: "8CLN",
        elements: [
          {
            id: 1,
            min: 0.005,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 9.0,
            max: 13.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            min: 0.20,
            max: 0.50,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 15 * carbon.value <= 0.20 ? 0.20 : 15 * carbon.value;
            },
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.06,
            max: 0.10,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 20,
        value: "8CLNA",
        elements: [
          {
            id: 1,
            min: 0.005,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 9.0,
            max: 13.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            min: 0.20,
            max: 0.50,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 15 * carbon.value <= 0.20 ? 0.20 : 15 * carbon.value;
            },
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.06,
            max: 0.10,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 21,
        value: "8M",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 10.0,
            max: 14.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 22,
        value: "8MA",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 10.0,
            max: 14.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 23,
        value: "8T",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 9.0,
            max: 12.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            max: 0.70,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const nitrogen = elements.find(({bm}) => bm === "N");

              return 5 * (carbon.value + nitrogen.value);
            },
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            max: 0.10,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 24,
        value: "8TA",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 9.0,
            max: 12.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            max: 0.70,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const nitrogen = elements.find(({bm}) => bm === "N");

              return 5 * (carbon.value + nitrogen.value);
            },
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            max: 0.10,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 25,
        value: "8F",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.20,
            bm: "P"
          },
          {
            id: 4,
            min: 0.15,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 10.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 26,
        value: "8FA",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.20,
            bm: "P"
          },
          {
            id: 4,
            min: 0.15,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 10.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 27,
        value: "8F - 303Se - UNS S30323",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.20,
            bm: "P"
          },
          {
            id: 4,
            max: 0.06,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 10.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            min: 0.15,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 28,
        value: "8FA - 303Se - UNS S30323",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.20,
            bm: "P"
          },
          {
            id: 4,
            max: 0.06,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 10.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            min: 0.15,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 29,
        value: "8P",
        elements: [
          {
            id: 1,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 11.0,
            max: 13.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 30,
        value: "8PA",
        elements: [
          {
            id: 1,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 11.0,
            max: 13.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 31,
        value: "8N",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.10,
            max: 0.16,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 32,
        value: "8NA",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.10,
            max: 0.16,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 33,
        value: "8LN",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.10,
            max: 0.16,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 34,
        value: "8LNA",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.10,
            max: 0.16,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 35,
        value: "8MN",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 10.0,
            max: 13.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.10,
            max: 0.16,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 36,
        value: "8MNA",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 10.0,
            max: 13.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.10,
            max: 0.16,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 37,
        value: "8MLN",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 10.0,
            max: 13.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.10,
            max: 0.16,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 38,
        value: "8MLNA",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 10.0,
            max: 13.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.10,
            max: 0.16,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 39,
        value: "8R",
        elements: [
          {
            id: 1,
            max: 0.06,
            bm: "C"
          },
          {
            id: 2,
            min: 4.0,
            max: 6.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 20.5,
            max: 23.5,
            bm: "Cr"
          },
          {
            id: 7,
            min: 11.5,
            max: 13.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 1.50,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            min: 0.10,
            max: 0.30,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.20,
            max: 0.40,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            min: 0.10,
            max: 0.30,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 40,
        value: "8RA",
        elements: [
          {
            id: 1,
            max: 0.06,
            bm: "C"
          },
          {
            id: 2,
            min: 4.0,
            max: 6.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 20.5,
            max: 23.5,
            bm: "Cr"
          },
          {
            id: 7,
            min: 11.5,
            max: 13.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 1.50,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            min: 0.10,
            max: 0.30,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.20,
            max: 0.40,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            min: 0.10,
            max: 0.30,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 41,
        value: "8S",
        elements: [
          {
            id: 1,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 7.0,
            max: 9.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.060,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 3.5,
            max: 4.5,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 9.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.08,
            max: 0.18,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 42,
        value: "8SA",
        elements: [
          {
            id: 1,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            min: 7.0,
            max: 9.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.060,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            min: 3.5,
            max: 4.5,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 9.0,
            bm: "Ni"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.08,
            max: 0.18,
            bm: "N"
          },
          {
            id: 12,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 43,
        value: "8MLCuN",
        elements: [
          {
            id: 1,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            min: 19.5,
            max: 20.5,
            bm: "Cr"
          },
          {
            id: 7,
            min: 17.5,
            max: 18.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 6.0,
            max: 6.5,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.18,
            max: 0.22,
            bm: "N"
          },
          {
            id: 12,
            min: 0.50,
            max: 1.00,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 44,
        value: "8MLCuNA",
        elements: [
          {
            id: 1,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            min: 19.5,
            max: 20.5,
            bm: "Cr"
          },
          {
            id: 7,
            min: 17.5,
            max: 18.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 6.0,
            max: 6.5,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            min: 0.18,
            max: 0.22,
            bm: "N"
          },
          {
            id: 12,
            min: 0.50,
            max: 1.00,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      },
      {
        id: 45,
        value: "B8MLCuN",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.0,
            max: 19.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 15.0,
            max: 16.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 3.0,
            max: 4.0,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Ti"
          },
          {
            id: 10,
            bm: "Nb"
          },
          {
            id: 11,
            max: 0.045,
            bm: "N"
          },
          {
            id: 12,
            min: 4.0,
            max: 5.0,
            bm: "Cu"
          },
          {
            id: 13,
            bm: "Se"
          },
          {
            id: 14,
            bm: "V"
          },
          {
            id: 15,
            bm: "Al"
          }
        ]
      }
    ]
  },
  {
    id: 10,
    value: "ASTM A516 (2017)",
    grades: [
      {
        id: 0,
        value: "55",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=12.5": {
                min: 0,
                max: 0.18
              },
              ">12.5<=50": {
                min: 0,
                max: 0.20
              },
              ">50<=100": {
                min: 0,
                max: 0.22
              },
              ">100<=200": {
                min: 0,
                max: 0.24
              },
              ">200": {
                min: 0,
                max: 0.26
              }
            }
          },
          {
            id: 2,
            bm: "Mn",
            thickness: {
              "<=12.5": {
                analysis: {
                  "heat": {
                    min: 0.60,
                    max: 0.90
                  },
                  "product": {
                    min: 0.55,
                    max: 0.98
                  }
                }
              },
              ">12.5": {
                analysis: {
                  "heat": {
                    min: 0.60,
                    max: 1.20
                  },
                  "product": {
                    min: 0.55,
                    max: 1.98
                  }
                }
              }
            },
            getMax({elements, defaultElements, measurement: thickness, analysis}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              const thicknessConfig = getConfigFromCondition(manganese.thickness, thickness);
              const defaultMax = thicknessConfig.analysis[analysis].max;

              if (Number(carbon.value) > carbon.max) return defaultMax;

              const maxIncrease = analysis === "heat" ? 1.5 : 1.6;
              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (defaultMax + additionToMn) > maxIncrease ? maxIncrease : round((defaultMax + additionToMn), 2);
            },
          },
          {
            id: 3,
            bm: "P",
            min: 0,
            max: 0.025
          },
          {
            id: 4,
            bm: "S",
            min: 0,
            max: 0.025
          },
          {
            id: 5,
            bm: "Si",
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.40
              },
              "product": {
                min: 0.13,
                max: 0.45
              }
            }
          }
        ]
      },
      {
        id: 1,
        value: "60",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=12.5": {
                min: 0,
                max: 0.21
              },
              ">12.5<=50": {
                min: 0,
                max: 0.23
              },
              ">50<=100": {
                min: 0,
                max: 0.25
              },
              ">100<=200": {
                min: 0,
                max: 0.27
              },
              ">200": {
                min: 0,
                max: 0.27
              }
            }
          },
          {
            id: 2,
            bm: "Mn",
            thickness: {
              "<=12.5": {
                analysis: {
                  "heat": {
                    min: 0.60,
                    max: 0.90
                  },
                  "product": {
                    min: 0.55,
                    max: 0.98
                  }
                }
              },
              ">12.5": {
                analysis: {
                  "heat": {
                    min: 0.85,
                    max: 1.20
                  },
                  "product": {
                    min: 0.79,
                    max: 1.30
                  }
                }
              }
            },
            getMax({elements, defaultElements, measurement: thickness, analysis}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              const thicknessConfig = getConfigFromCondition(manganese.thickness, thickness);
              const defaultMax = thicknessConfig.analysis[analysis].max;

              if (Number(carbon.value) > carbon.max) return defaultMax;

              const maxIncrease = analysis === "heat" ? 1.5 : 1.6;
              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (defaultMax + additionToMn) > maxIncrease ? maxIncrease : round((defaultMax + additionToMn), 2);
            },
          },
          {
            id: 3,
            bm: "P",
            min: 0,
            max: 0.025
          },
          {
            id: 4,
            bm: "S",
            min: 0,
            max: 0.025
          },
          {
            id: 5,
            bm: "Si",
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.40
              },
              "product": {
                min: 0.13,
                max: 0.45
              }
            }
          }
        ]
      },
      {
        id: 2,
        value: "65",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=12.5": {
                min: 0,
                max: 0.24
              },
              ">12.5<=50": {
                min: 0,
                max: 0.26
              },
              ">50<=100": {
                min: 0,
                max: 0.28
              },
              ">100<=200": {
                min: 0,
                max: 0.29
              },
              ">200": {
                min: 0,
                max: 0.29
              }
            }
          },
          {
            id: 2,
            bm: "Mn",
            thickness: {
              "<=12.5": {
                analysis: {
                  "heat": {
                    min: 0.85,
                    max: 1.20
                  },
                  "product": {
                    min: 0.79,
                    max: 1.30
                  }
                }
              },
              ">12.5": {
                analysis: {
                  "heat": {
                    min: 0.85,
                    max: 1.20
                  },
                  "product": {
                    min: 0.79,
                    max: 1.30
                  }
                }
              }
            },
            getMax({elements, defaultElements, measurement: thickness, analysis}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              const thicknessConfig = getConfigFromCondition(manganese.thickness, thickness);
              const defaultMax = thicknessConfig.analysis[analysis].max;

              if (Number(carbon.value) > carbon.max) return defaultMax;

              const maxIncrease = analysis === "heat" ? 1.5 : 1.6;
              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (defaultMax + additionToMn) > maxIncrease ? maxIncrease : round((defaultMax + additionToMn), 2);
            },
          },
          {
            id: 3,
            bm: "P",
            min: 0,
            max: 0.025
          },
          {
            id: 4,
            bm: "S",
            min: 0,
            max: 0.025
          },
          {
            id: 5,
            bm: "Si",
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.40
              },
              "product": {
                min: 0.13,
                max: 0.45
              }
            }
          }
        ]
      },
      {
        id: 3,
        value: "70",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=12.5": {
                min: 0,
                max: 0.27
              },
              ">12.5<=50": {
                min: 0,
                max: 0.28
              },
              ">50<=100": {
                min: 0,
                max: 0.30
              },
              ">100<=200": {
                min: 0,
                max: 0.31
              },
              ">200": {
                min: 0,
                max: 0.31
              }
            }
          },
          {
            id: 2,
            bm: "Mn",
            thickness: {
              "<=12.5": {
                analysis: {
                  "heat": {
                    min: 0.85,
                    max: 1.20
                  },
                  "product": {
                    min: 0.79,
                    max: 1.30
                  }
                }
              },
              ">12.5": {
                analysis: {
                  "heat": {
                    min: 0.85,
                    max: 1.20
                  },
                  "product": {
                    min: 0.79,
                    max: 1.30
                  }
                }
              }
            },
            getMax({elements, defaultElements, measurement: thickness, analysis}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              const thicknessConfig = getConfigFromCondition(manganese.thickness, thickness);
              const defaultMax = thicknessConfig.analysis[analysis].max;

              if (Number(carbon.value) > carbon.max) return defaultMax;

              const maxIncrease = analysis === "heat" ? 1.5 : 1.6;
              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (defaultMax + additionToMn) > maxIncrease ? maxIncrease : round((defaultMax + additionToMn), 2);
            },
          },
          {
            id: 3,
            bm: "P",
            min: 0,
            max: 0.025
          },
          {
            id: 4,
            bm: "S",
            min: 0,
            max: 0.025
          },
          {
            id: 5,
            bm: "Si",
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.40
              },
              "product": {
                min: 0.13,
                max: 0.45
              }
            }
          }
        ]
      }
    ]
  },
  {
    id: 11,
    value: "API 5L (2018) PSL1",
    grades:
      [
        {
          id: 0,
          value: "A25 (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.21,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 0.60,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            }
          ]
        },
        {
          id: 1,
          value: "A25P (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.21,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 0.60,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0.045,
              max: 0.080,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            }
          ]
        },
        {
          id: 2,
          value: "A (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.22,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 0.90,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            }
          ]
        },
        {
          id: 4,
          value: "B (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.20,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.06,
              formula: "Nb+V",
              bm: "Nb+V"
            },
            {
              id: 17,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 5,
          value: "X42 (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 6,
          value: "X46 (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.40,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 7,
          value: "X52 (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 8,
          value: "X56 (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 9,
          value: "X60 (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 10,
          value: "X65 (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 11,
          value: "X70 (Seamless)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 12,
          value: "A25 (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.21,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 0.60,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            }
          ]
        },
        {
          id: 13,
          value: "A25P (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.21,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 0.60,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0.045,
              max: 0.080,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            }
          ]
        },
        {
          id: 14,
          value: "A (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.22,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 0.90,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            }
          ]
        },
        {
          id: 15,
          value: "B (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.26,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.20,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.06,
              formula: "Nb+V",
              bm: "Nb+V"
            },
            {
              id: 17,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 16,
          value: "X42 (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.26,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 17,
          value: "X46 (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.26,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.40,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 18,
          value: "X52 (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.26,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 19,
          value: "X56 (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.26,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 20,
          value: "X60 (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 21,
          value: "X65 (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        },
        {
          id: 22,
          value: "X70 (Welded)",
          elements: [
            {
              id: 1,
              min: 0,
              max: 0.28,
              bm: "C"
            },
            {
              id: 2,
              bm: "Si"
            },
            {
              id: 3,
              min: 0,
              max: 1.30,
              bm: "Mn"
            },
            {
              id: 4,
              min: 0,
              max: 0.030,
              bm: "P"
            },
            {
              id: 5,
              min: 0,
              max: 0.03,
              bm: "S"
            },
            {
              id: 6,
              bm: "V"
            },
            {
              id: 7,
              bm: "Nb"
            },
            {
              id: 8,
              bm: "Ti"
            },
            {
              id: 9,
              min: 0,
              max: 0.50,
              bm: "Cu"
            },
            {
              id: 10,
              min: 0,
              max: 0.50,
              bm: "Ni"
            },
            {
              id: 11,
              min: 0,
              max: 0.50,
              bm: "Cr"
            },
            {
              id: 12,
              min: 0,
              max: 0.15,
              bm: "Mo"
            },
            {
              id: 13,
              min: 0,
              max: 0.001,
              bm: "B"
            },
            {
              id: 14,
              formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
              bm: "CE_Pcm"
            },
            {
              id: 15,
              formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
              bm: "CE_IIW"
            },
            {
              id: 16,
              min: 0,
              max: 0.15,
              formula: "Nb+V+Ti",
              bm: "Nb+V+Ti"
            }
          ]
        }
      ]
  },
  {
    id: 12,
    value: "API 6A (2018) + Errata 1-2-3 + Addenda 1-2 - PSL 2",
    grades: [
      {
        id: 0,
        value: "Soft Iron",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.45,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.8,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 2.75,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0,
            max: 1.50,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.30,
            bm: "V"
          }
        ]
      },
      {
        id: 1,
        value: "SS316L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.03,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 2.0,
            max: 3.0,
            bm: "Mo"
          },
          {
            id: 8,
            min: 10.0,
            max: 15.0,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.1,
            bm: "N"
          }
        ]
      },
      {
        id: 2,
        value: "SS316",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 2.0,
            max: 3.0,
            bm: "Mo"
          },
          {
            id: 8,
            min: 10.0,
            max: 15.0,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.1,
            bm: "N"
          }
        ]
      },
      {
        id: 3,
        value: "SS304",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      },
      {
        id: 4,
        value: "SS304L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.03,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.04,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      }
    ]
  },
  {
    id: 13,
    value: "API 6A (2018) + Errata 1-2-3 + Addenda 1-2 - PSL 3",
    grades: [
      {
        id: 0,
        value: "Soft Iron",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.45,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.8,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 2.75,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0,
            max: 1.50,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.30,
            bm: "V"
          }
        ]
      },
      {
        id: 1,
        value: "SS316L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.03,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 2.0,
            max: 3.0,
            bm: "Mo"
          },
          {
            id: 8,
            min: 10.0,
            max: 15.0,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.1,
            bm: "N"
          }
        ]
      },
      {
        id: 2,
        value: "SS316",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 2.0,
            max: 3.0,
            bm: "Mo"
          },
          {
            id: 8,
            min: 10.0,
            max: 15.0,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.1,
            bm: "N"
          }
        ]
      },
      {
        id: 3,
        value: "SS304",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      },
      {
        id: 4,
        value: "SS304L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.03,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      }
    ]
  },
  {
    id: 14,
    value: "API 6A (2018) + Errata 1-2-3 + Addenda 1-2 - PSL 4",
    grades: [
      {
        id: 0,
        value: "Soft Iron",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.45,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.8,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.015,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 6,
            min: 0,
            max: 1.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 0,
            max: 2.75,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0,
            max: 1.50,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0,
            max: 0.30,
            bm: "V"
          }
        ]
      },
      {
        id: 1,
        value: "SS316L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.03,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.015,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 2.0,
            max: 3.0,
            bm: "Mo"
          },
          {
            id: 8,
            min: 10.0,
            max: 15.0,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.1,
            bm: "N"
          }
        ]
      },
      {
        id: 2,
        value: "SS316",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.015,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 2.0,
            max: 3.0,
            bm: "Mo"
          },
          {
            id: 8,
            min: 10.0,
            max: 15.0,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.1,
            bm: "N"
          }
        ]
      },
      {
        id: 3,
        value: "SS304",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.015,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      },
      {
        id: 4,
        value: "SS304L",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.03,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.015,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            min: 0,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 18.0,
            max: 20.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 8.0,
            max: 11.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0,
            max: 0.10,
            bm: "N"
          }
        ]
      }
    ]
  },
  {
    id: 15,
    value: "EN 10025-2 (2019)",
    grades: [
      {
        id: 0,
        value: "S235JR",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.17
                  },
                  "product": {
                    max: 0.19
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.17
                  },
                  "product": {
                    max: 0.19
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.40
              },
              "product": {
                max: 1.50
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.035,
                maxError: 0.045
              },
              "product": {
                max: 0.045,
                maxError: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.035,
                maxError: 0.05
              },
              "product": {
                max: 0.045,
                maxError: 0.06
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.35,
                maxError: 0.37
              },
              ">30<=40": {
                max: 0.35,
                maxError: 0.37
              },
              ">40<=150": {
                max: 0.38,
                maxError: 0.40
              },
              ">150<=250": {
                max: 0.40,
                maxError: 0.42
              },
              ">250<=400": {
                max: 0.40,
                maxError: 0.42
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 1,
        value: "S235J0",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.17
                  },
                  "product": {
                    max: 0.19
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.17
                  },
                  "product": {
                    max: 0.19
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.17
                  },
                  "product": {
                    max: 0.19
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.40
              },
              "product": {
                max: 1.50
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.035
              },
              "product": {
                max: 0.040,
                maxError: 0.045
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.055
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.35,
                maxError: 0.37
              },
              ">30<=40": {
                max: 0.35,
                maxError: 0.37
              },
              ">40<=150": {
                max: 0.38,
                maxError: 0.40
              },
              ">150<=250": {
                max: 0.40,
                maxError: 0.42
              },
              ">250<=400": {
                max: 0.40,
                maxError: 0.42
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 2,
        value: "S235J2",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.17
                  },
                  "product": {
                    max: 0.19
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.17
                  },
                  "product": {
                    max: 0.19
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.17
                  },
                  "product": {
                    max: 0.19
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.40
              },
              "product": {
                max: 1.50
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.025,
                maxError: 0.04
              },
              "product": {
                max: 0.035,
                maxError: 0.05
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.025,
                maxError: 0.04
              },
              "product": {
                max: 0.035,
                maxError: 0.05
              }
            }
          },
          {
            id: 6,
            bm: "N"
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.35,
                maxError: 0.37
              },
              ">30<=40": {
                max: 0.35,
                maxError: 0.37
              },
              ">40<=150": {
                max: 0.38,
                maxError: 0.40
              },
              ">150<=250": {
                max: 0.40,
                maxError: 0.42
              },
              ">250<=400": {
                max: 0.40,
                maxError: 0.42
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 3,
        value: "S275JR",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.21
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.21
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.22
                  },
                  "product": {
                    max: 0.25
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.035,
                maxError: 0.05
              },
              "product": {
                max: 0.045,
                maxError: 0.06
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.035,
                maxError: 0.05
              },
              "product": {
                max: 0.045,
                maxError: 0.06
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.40,
                maxError: 0.42
              },
              ">30<=40": {
                max: 0.40,
                maxError: 0.42
              },
              ">40<=150": {
                max: 0.42,
                maxError: 0.44
              },
              ">150<=250": {
                max: 0.44,
                maxError: 0.46
              },
              ">250<=400": {
                max: 0.44,
                maxError: 0.46
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 4,
        value: "S275J0",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.18
                  },
                  "product": {
                    max: 0.21
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.18
                  },
                  "product": {
                    max: 0.21
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.18,
                    maxError: 0.20
                  },
                  "product": {
                    max: 0.21,
                    maxError: 0.22
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.40,
                maxError: 0.42
              },
              ">30<=40": {
                max: 0.40,
                maxError: 0.42
              },
              ">40<=150": {
                max: 0.42,
                maxError: 0.44
              },
              ">150<=250": {
                max: 0.44,
                maxError: 0.46
              },
              ">250<=400": {
                max: 0.44,
                maxError: 0.46
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 5,
        value: "S275J2",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.18
                  },
                  "product": {
                    max: 0.21
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.18
                  },
                  "product": {
                    max: 0.21
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.18,
                    maxError: 0.20
                  },
                  "product": {
                    max: 0.21,
                    maxError: 0.22
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.025,
                maxError: 0.04
              },
              "product": {
                max: 0.035,
                maxError: 0.05
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.025,
                maxError: 0.04
              },
              "product": {
                max: 0.035,
                maxError: 0.05
              }
            }
          },
          {
            id: 6,
            bm: "N"
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.40,
                maxError: 0.42
              },
              ">30<=40": {
                max: 0.40,
                maxError: 0.42
              },
              ">40<=150": {
                max: 0.42,
                maxError: 0.44
              },
              ">150<=250": {
                max: 0.44,
                maxError: 0.46
              },
              ">250<=400": {
                max: 0.44,
                maxError: 0.46
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 6,
        value: "S355JR",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.24
                  },
                  "product": {
                    max: 0.27
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.24
                  },
                  "product": {
                    max: 0.27
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.24
                  },
                  "product": {
                    max: 0.27
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.60
              },
              "product": {
                max: 1.70
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.035,
                maxError: 0.05
              },
              "product": {
                max: 0.045,
                maxError: 0.06
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.035,
                maxError: 0.05
              },
              "product": {
                max: 0.045,
                maxError: 0.06
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.45,
                maxError: 0.47
              },
              ">30<=40": {
                max: 0.47,
                maxError: 0.49
              },
              ">40<=150": {
                max: 0.47,
                maxError: 0.49
              },
              ">150<=250": {
                max: 0.49,
                maxError: 0.54
              },
              ">250<=400": {
                max: 0.49,
                maxError: 0.51
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 7,
        value: "S355J0",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.20,
                    maxError: 0.22
                  },
                  "product": {
                    max: 0.23,
                    maxError: 0.24
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.23,
                    maxError: 0.24
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.60
              },
              "product": {
                max: 1.70
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.45,
                maxError: 0.47
              },
              ">30<=40": {
                max: 0.47,
                maxError: 0.49
              },
              ">40<=150": {
                max: 0.47,
                maxError: 0.49
              },
              ">150<=250": {
                max: 0.49,
                maxError: 0.54
              },
              ">250<=400": {
                max: 0.49,
                maxError: 0.51
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 8,
        value: "S355J2",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.20,
                    maxError: 0.22
                  },
                  "product": {
                    max: 0.23,
                    maxError: 0.24
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.22
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.60
              },
              "product": {
                max: 1.70
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.025,
                maxError: 0.040
              },
              "product": {
                max: 0.035,
                maxError: 0.050
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.025,
                maxError: 0.040
              },
              "product": {
                max: 0.035,
                maxError: 0.050
              }
            }
          },
          {
            id: 6,
            bm: "N"
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.45,
                maxError: 0.47
              },
              ">30<=40": {
                max: 0.47,
                maxError: 0.49
              },
              ">40<=150": {
                max: 0.47,
                maxError: 0.49
              },
              ">150<=250": {
                max: 0.49,
                maxError: 0.54
              },
              ">250<=400": {
                max: 0.49,
                maxError: 0.51
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 9,
        value: "S355K2",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.20,
                    maxError: 0.22
                  },
                  "product": {
                    max: 0.23,
                    maxError: 0.24
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.22
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.60
              },
              "product": {
                max: 1.70
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.025,
                maxError: 0.040
              },
              "product": {
                max: 0.035,
                maxError: 0.050
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.025,
                maxError: 0.040
              },
              "product": {
                max: 0.035,
                maxError: 0.050
              }
            }
          },
          {
            id: 6,
            bm: "N"
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            thickness: {
              "<=30": {
                max: 0.45,
                maxError: 0.47
              },
              ">30<=40": {
                max: 0.47,
                maxError: 0.49
              },
              ">40<=150": {
                max: 0.47,
                maxError: 0.49
              },
              ">150<=250": {
                max: 0.49,
                maxError: 0.54
              },
              ">250<=400": {
                max: 0.49,
                maxError: 0.51
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 10,
        value: "S460JR",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.20,
                    maxError: 0.22
                  },
                  "product": {
                    max: 0.23,
                    maxError: 0.24
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.22
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.027
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            bm: "Nb",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 12,
            bm: "V",
            analysis: {
              "heat": {
                max: 0.13
              },
              "product": {
                max: 0.15
              }
            }
          },
          {
            id: 13,
            bm: "Ti",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 14,
            thickness: {
              "<=30": {
                max: 0.47
              },
              ">30<=40": {
                max: 0.49
              },
              ">40<=150": {
                max: 0.49
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 11,
        value: "S460J0",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.20,
                    maxError: 0.22
                  },
                  "product": {
                    max: 0.23,
                    maxError: 0.24
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.22
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.027
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            bm: "Nb",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 12,
            bm: "V",
            analysis: {
              "heat": {
                max: 0.13
              },
              "product": {
                max: 0.15
              }
            }
          },
          {
            id: 13,
            bm: "Ti",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 14,
            thickness: {
              "<=30": {
                max: 0.47
              },
              ">30<=40": {
                max: 0.49
              },
              ">40<=150": {
                max: 0.49
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 12,
        value: "S460J2",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.20,
                    maxError: 0.22
                  },
                  "product": {
                    max: 0.23,
                    maxError: 0.24
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.22
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.027
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            bm: "Nb",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 12,
            bm: "V",
            analysis: {
              "heat": {
                max: 0.13
              },
              "product": {
                max: 0.15
              }
            }
          },
          {
            id: 13,
            bm: "Ti",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 14,
            thickness: {
              "<=30": {
                max: 0.47
              },
              ">30<=40": {
                max: 0.49
              },
              ">40<=150": {
                max: 0.49
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 13,
        value: "S460K2",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.20,
                    maxError: 0.22
                  },
                  "product": {
                    max: 0.23,
                    maxError: 0.24
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.22
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.027
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            bm: "Nb",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 12,
            bm: "V",
            analysis: {
              "heat": {
                max: 0.13
              },
              "product": {
                max: 0.15
              }
            }
          },
          {
            id: 13,
            bm: "Ti",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 14,
            thickness: {
              "<=30": {
                max: 0.47
              },
              ">30<=40": {
                max: 0.49
              },
              ">40<=150": {
                max: 0.49
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 14,
        value: "S500J0",
        elements: [
          {
            id: 1,
            bm: "C",
            thickness: {
              "<=16": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">16<=40": {
                analysis: {
                  "heat": {
                    max: 0.20
                  },
                  "product": {
                    max: 0.23
                  }
                }
              },
              ">40": {
                analysis: {
                  "heat": {
                    max: 0.22
                  },
                  "product": {
                    max: 0.24
                  }
                }
              },
            },
          },
          {
            id: 2,
            bm: "Si",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 3,
            bm: "Mn",
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            }
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.030,
                maxError: 0.045
              },
              "product": {
                max: 0.040,
                maxError: 0.055
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.027
              }
            }
          },
          {
            id: 7,
            bm: "Cu",
            analysis: {
              "heat": {
                max: 0.55
              },
              "product": {
                max: 0.60
              }
            }
          },
          {
            id: 8,
            bm: "Ni",
            analysis: {
              "heat": {
                max: 0.42
              },
              "product": {
                max: 0.47
              }
            }
          },
          {
            id: 9,
            bm: "Cr",
            analysis: {
              "heat": {
                max: 0.29
              },
              "product": {
                max: 0.34
              }
            }
          },
          {
            id: 10,
            bm: "Mo",
            analysis: {
              "heat": {
                max: 0.11
              },
              "product": {
                max: 0.14
              }
            }
          },
          {
            id: 11,
            bm: "Nb",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 12,
            bm: "V",
            analysis: {
              "heat": {
                max: 0.13
              },
              "product": {
                max: 0.15
              }
            }
          },
          {
            id: 13,
            bm: "Ti",
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            }
          },
          {
            id: 14,
            thickness: {
              "<=30": {
                max: 0.49
              },
              ">30<=40": {
                max: 0.49
              },
              ">40<=150": {
                max: 0.49
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          }
        ]
      },
      {
        id: 15,
        value: "S185",
        elements: [
          {
            id: 1,
            bm: "C"
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn"
          },
          {
            id: 4,
            bm: "P"
          },
          {
            id: 5,
            bm: "S"
          },
          {
            id: 6,
            bm: "N"
          },
          {
            id: 7,
            bm: "Cu"
          },
          {
            id: 8,
            bm: "Ni"
          },
          {
            id: 9,
            bm: "Cr"
          },
          {
            id: 10,
            bm: "Mo"
          }
        ]
      },
      {
        id: 16,
        value: "E295",
        elements: [
          {
            id: 1,
            bm: "C"
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn"
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.045,
                maxError: 0.055
              },
              "product": {
                max: 0.055,
                maxError: 0.065
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu"
          },
          {
            id: 8,
            bm: "Ni"
          },
          {
            id: 9,
            bm: "Cr"
          },
          {
            id: 10,
            bm: "Mo"
          }
        ]
      },
      {
        id: 17,
        value: "E335",
        elements: [
          {
            id: 1,
            bm: "C"
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn"
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.045,
                maxError: 0.055
              },
              "product": {
                max: 0.055,
                maxError: 0.065
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu"
          },
          {
            id: 8,
            bm: "Ni"
          },
          {
            id: 9,
            bm: "Cr"
          },
          {
            id: 10,
            bm: "Mo"
          }
        ]
      },
      {
        id: 18,
        value: "E360",
        elements: [
          {
            id: 1,
            bm: "C"
          },
          {
            id: 2,
            bm: "Si"
          },
          {
            id: 3,
            bm: "Mn"
          },
          {
            id: 4,
            bm: "P",
            analysis: {
              "heat": {
                max: 0.045
              },
              "product": {
                max: 0.055
              }
            }
          },
          {
            id: 5,
            bm: "S",
            analysis: {
              "heat": {
                max: 0.045,
                maxError: 0.055
              },
              "product": {
                max: 0.055,
                maxError: 0.065
              }
            }
          },
          {
            id: 6,
            bm: "N",
            analysis: {
              "heat": {
                max: 0.012
              },
              "product": {
                max: 0.014
              }
            }
          },
          {
            id: 7,
            bm: "Cu"
          },
          {
            id: 8,
            bm: "Ni"
          },
          {
            id: 9,
            bm: "Cr"
          },
          {
            id: 10,
            bm: "Mo"
          }
        ]
      }
    ]
  },
  {
    id: 16,
    value: "ASTM A106 (2019a)",
    grades: [
      {
        id: 0,
        value: "A",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.25,
            bm: "C"
          },
          {
            id: 3,
            min: 0.27,
            max: 0.93,
            bm: "Mn",
            getMax({elements, defaultElements}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              if (Number(carbon.value) > carbon.max) return manganese.max;

              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (manganese.max + additionToMn) > 1.35 ? 1.35 : round((manganese.max + additionToMn), 2);
            },
          },
          {
            id: 4,
            min: 0,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.035,
            bm: "S"
          },
          {
            id: 2,
            min: 0.10,
            bm: "Si"
          },
          {
            id: 7,
            min: 0,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 8,
            min: 0,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.40,
            bm: "Cr"
          },
          {
            id: 10,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 11,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 1,
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          }
        ]
      },
      {
        id: 1,
        value: "B",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            min: 0.29,
            max: 1.06,
            bm: "Mn",
            getMax({elements, defaultElements}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              if (Number(carbon.value) > carbon.max) return manganese.max;

              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (manganese.max + additionToMn) > 1.35 ? 1.35 : round((manganese.max + additionToMn), 2);
            },
          },
          {
            id: 4,
            min: 0,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.035,
            bm: "S"
          },
          {
            id: 2,
            min: 0.10,
            bm: "Si"
          },
          {
            id: 7,
            min: 0,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 8,
            min: 0,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.40,
            bm: "Cr"
          },
          {
            id: 10,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 11,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 1,
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          }
        ]
      },
      {
        id: 1,
        value: "C",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.35,
            bm: "C"
          },
          {
            id: 3,
            min: 0.29,
            max: 1.06,
            bm: "Mn",
            getMax({elements, defaultElements}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              if (Number(carbon.value) > carbon.max) return manganese.max;

              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (manganese.max + additionToMn) > 1.35 ? 1.35 : round((manganese.max + additionToMn), 2);
            },
          },
          {
            id: 4,
            min: 0,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.035,
            bm: "S"
          },
          {
            id: 2,
            min: 0.10,
            bm: "Si"
          },
          {
            id: 7,
            min: 0,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 8,
            min: 0,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 9,
            min: 0,
            max: 0.40,
            bm: "Cr"
          },
          {
            id: 10,
            min: 0,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 11,
            min: 0,
            max: 0.08,
            bm: "V"
          },
          {
            id: 12,
            min: 0,
            max: 1,
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          }
        ]
      }
    ]
  },
  {
    id: 17,
    value: "ASTM A216 (2018)",
    grades: [
      {
        id: 0,
        value: "WCA",
        elements: [
          {
            id: 1,
            max: 0.25,
            bm: "C"
          },
          {
            id: 3,
            max: 0.70,
            bm: "Mn",
            getMax({elements, defaultElements}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              if (Number(carbon.value) > carbon.max) return manganese.max;

              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.04;

              return (manganese.max + additionToMn) > 1.10 ? 1.10 : round((manganese.max + additionToMn), 2);
            },
          },
          {
            id: 4,
            max: 0.04,
            bm: "P"
          },
          {
            id: 5,
            max: 0.045,
            bm: "S"
          },
          {
            id: 2,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.30,
            bm: "Cu"
          },
          {
            id: 8,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 9,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 10,
            max: 0.20,
            bm: "Mo"
          },
          {
            id: 11,
            max: 0.03,
            bm: "V"
          },
          {
            id: 12,
            max: 1.00,
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          }
        ]
      },
      {
        id: 1,
        value: "WCB",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            max: 1.00,
            bm: "Mn",
            getMax({elements, defaultElements}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              if (Number(carbon.value) > carbon.max) return manganese.max;

              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.04;

              return (manganese.max + additionToMn) > 1.28 ? 1.28 : round((manganese.max + additionToMn), 2);
            },
          },
          {
            id: 4,
            max: 0.04,
            bm: "P"
          },
          {
            id: 5,
            max: 0.045,
            bm: "S"
          },
          {
            id: 2,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.30,
            bm: "Cu"
          },
          {
            id: 8,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 9,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 10,
            max: 0.20,
            bm: "Mo"
          },
          {
            id: 11,
            max: 0.03,
            bm: "V"
          },
          {
            id: 12,
            max: 1,
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          }
        ]
      },
      {
        id: 2,
        value: "WCC",
        elements: [
          {
            id: 1,
            max: 0.25,
            bm: "C"
          },
          {
            id: 3,
            max: 1.20,
            bm: "Mn",
            getMax({elements, defaultElements}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              if (Number(carbon.value) > carbon.max) return manganese.max;

              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.04;

              return (manganese.max + additionToMn) > 1.40 ? 1.40 : round((manganese.max + additionToMn), 2);
            },
          },
          {
            id: 4,
            max: 0.04,
            bm: "P"
          },
          {
            id: 5,
            max: 0.045,
            bm: "S"
          },
          {
            id: 2,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.30,
            bm: "Cu"
          },
          {
            id: 8,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 9,
            max: 0.50,
            bm: "Cr"
          },
          {
            id: 10,
            max: 0.20,
            bm: "Mo"
          },
          {
            id: 11,
            max: 0.03,
            bm: "V"
          },
          {
            id: 12,
            max: 1,
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          }
        ]
      }
    ]
  },
  {
    id: 18,
    value: "ASTM A234 (2019)",
    grades: [
      {
        id: 0,
        value: "WPB",
        elements: [
          {
            id: 1,
            max: 0.30,
            maxError: 0.35,
            bm: "C"
          },
          {
            id: 3,
            min: 0.29,
            max: 1.06,
            bm: "Mn",
            getMax({elements, defaultElements}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const manganese = defaultElements.find(({bm}) => bm === "Mn");

              if (Number(carbon.value) > carbon.max) return manganese.max;

              const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
              const additionToMn = reductionTimes * 0.06;

              return (manganese.max + additionToMn) > 1.65 ? 1.65 : round((manganese.max + additionToMn), 2);
            },
          },
          {
            id: 4,
            max: 0.050,
            bm: "P"
          },
          {
            id: 5,
            max: 0.058,
            bm: "S"
          },
          {
            id: 2,
            min: 0.10,
            maxError: 0.35,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.40,
            bm: "Cr"
          },
          {
            id: 8,
            max: 0.15,
            bm: "Mo"
          },
          {
            id: 9,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.08,
            bm: "V"
          },
          {
            id: 12,
            max: 1,
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 1,
        value: "WP92",
        elements: [
          {
            id: 1,
            min: 0.07,
            max: 0.13,
            bm: "C"
          },
          {
            id: 3,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            max: 0.010,
            bm: "S"
          },
          {
            id: 2,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 7,
            min: 8.50,
            max: 9.50,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0.30,
            max: 0.60,
            bm: "Mo"
          },
          {
            id: 9,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 10,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Al"
          },
          {
            id: 12,
            min: 0.001,
            max: 0.006,
            bm: "B"
          },
          {
            id: 13,
            min: 0.04,
            max: 0.09,
            bm: "Nb"
          },
          {
            id: 14,
            min: 0.030,
            max: 0.070,
            bm: "N"
          },
          {
            id: 15,
            max: 0.01,
            bm: "Ti"
          },
          {
            id: 16,
            min: 1.50,
            max: 2.00,
            bm: "W"
          },
          {
            id: 17,
            min: 0.15,
            max: 0.25,
            bm: "V"
          },
          {
            id: 18,
            max: 0.01,
            bm: "Zr"
          }
        ]
      }
    ]
  },
  {
    id: 19,
    value: "ASTM A335 (2021a)",
    grades: [
      {
        id: 0,
        value: "P22",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.15,
            bm: "C"
          },
          {
            id: 3,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 7,
            min: 1.90,
            max: 2.60,
            bm: "Cr"
          },
          {
            id: 10,
            min: 0.87,
            max: 1.13,
            bm: "Mo"
          }
        ]
      },
      {
        id: 1,
        value: "P91 Type 1",
        elements: [
          {
            id: 1,
            min: 0.08,
            max: 0.12,
            bm: "C"
          },
          {
            id: 3,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            max: 0.010,
            bm: "S"
          },
          {
            id: 6,
            min: 0.20,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 7,
            min: 8.00,
            max: 9.50,
            bm: "Cr"
          },
          {
            id: 10,
            min: 0.85,
            max: 1.05,
            bm: "Mo"
          },
          {
            id: 11,
            min: 0.18,
            max: 0.25,
            bm: "V"
          },
          {
            id: 12,
            min: 0.030,
            max: 0.070,
            bm: "N"
          },
          {
            id: 13,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 14,
            max: 0.02,
            bm: "Al"
          },
          {
            id: 15,
            min: 0.06,
            max: 0.10,
            bm: "Nb"
          },
          {
            id: 16,
            max: 0.01,
            bm: "Ti"
          },
          {
            id: 17,
            max: 0.01,
            bm: "Zr"
          }
        ]
      },
      {
        id: 2,
        value: "P91 Type 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.12
              },
              "product": {
                min: 0.07,
                max: 0.13
              }
            },
            bm: "C"
          },
          {
            id: 3,
            min: 0.30,
            max: 0.50,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.020,
            bm: "P"
          },
          {
            id: 5,
            max: 0.005,
            bm: "S"
          },
          {
            id: 6,
            min: 0.20,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 7,
            min: 8.00,
            max: 9.50,
            bm: "Cr"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.85,
                max: 1.05
              },
              "product": {
                min: 0.80,
                max: 1.05
              }
            },
            bm: "Mo"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0.18,
                max: 0.25
              },
              "product": {
                min: 0.16,
                max: 0.27
              }
            },
            bm: "V"
          },
          {
            id: 12,
            min: 0.035,
            max: 0.070,
            bm: "N"
          },
          {
            id: 13,
            max: 0.20,
            bm: "Ni"
          },
          {
            id: 14,
            max: 0.020,
            bm: "Al"
          },
          {
            id: 15,
            min: 4.0,
            formula: "N/Al",
            bm: "N/Al"
          },
          {
            id: 16,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "Nb"
          },
          {
            id: 17,
            max: 0.01,
            bm: "Ti"
          },
          {
            id: 18,
            max: 0.01,
            bm: "Zr"
          },
          {
            id: 19,
            max: 0.010,
            bm: "Sn"
          },
          {
            id: 20,
            max: 0.003,
            bm: "Sb"
          },
          {
            id: 21,
            max: 0.010,
            bm: "As"
          },
          {
            id: 22,
            max: 0.001,
            bm: "B"
          },
          {
            id: 23,
            max: 0.05,
            bm: "W"
          },
          {
            id: 24,
            max: 0.10,
            bm: "Cu"
          }
        ]
      }
    ]
  },
  {
    id: 20,
    value: "ASTM A350 (2018)",
    grades: [
      {
        id: 0,
        value: "LF1 Class 1",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            min: 0.60,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.040,
            bm: "S"
          },
          {
            id: 6,
            min: 0.15,
            max: 0.30,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.02,
                maxError: 0.05
              },
              "product": {
                max: 0.02,
                maxError: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.08,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          }
        ]
      },
      {
        id: 1,
        value: "LF2 Class 1",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            min: 0.60,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.040,
            bm: "S"
          },
          {
            id: 6,
            min: 0.15,
            max: 0.30,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.02,
                maxError: 0.05
              },
              "product": {
                max: 0.02,
                maxError: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.08,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          }
        ]
      },
      {
        id: 2,
        value: "LF2 Class 2",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            min: 0.60,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.040,
            bm: "S"
          },
          {
            id: 6,
            min: 0.15,
            max: 0.30,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.02,
                maxError: 0.05
              },
              "product": {
                max: 0.02,
                maxError: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.08,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          }
        ]
      },
      {
        id: 3,
        value: "LF3 Class 1",
        elements: [
          {
            id: 1,
            max: 0.20,
            bm: "C"
          },
          {
            id: 3,
            max: 0.90,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.040,
            bm: "S"
          },
          {
            id: 6,
            min: 0.20,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 7,
            min: 3.3,
            max: 3.7,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.03,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          }
        ]
      },
      {
        id: 4,
        value: "LF3 Class 2",
        elements: [
          {
            id: 1,
            max: 0.20,
            bm: "C"
          },
          {
            id: 3,
            max: 0.90,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.040,
            bm: "S"
          },
          {
            id: 6,
            min: 0.20,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 7,
            min: 3.3,
            max: 3.7,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.03,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          }
        ]
      },
      {
        id: 5,
        value: "LF5 Class 1",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            min: 0.60,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.040,
            bm: "S"
          },
          {
            id: 6,
            min: 0.20,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 7,
            min: 1.0,
            max: 2.0,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.03,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          }
        ]
      },
      {
        id: 6,
        value: "LF5 Class 2",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 3,
            min: 0.60,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.040,
            bm: "S"
          },
          {
            id: 6,
            min: 0.20,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 7,
            min: 1.0,
            max: 2.0,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.03,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          }
        ]
      },
      {
        id: 6,
        value: "LF6 Class 1",
        elements: [
          {
            id: 1,
            max: 0.22,
            bm: "C"
          },
          {
            id: 3,
            min: 1.15,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            min: 0.15,
            max: 0.30,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            min: 0.04,
            max: 0.11,
            bm: "V"
          },
          {
            id: 13,
            min: 0.01,
            max: 0.030,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          },
        ]
      },
      {
        id: 7,
        value: "LF6 Class 2",
        elements: [
          {
            id: 1,
            max: 0.22,
            bm: "C"
          },
          {
            id: 3,
            min: 1.15,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            min: 0.15,
            max: 0.30,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            min: 0.04,
            max: 0.11,
            bm: "V"
          },
          {
            id: 13,
            min: 0.01,
            max: 0.030,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          },
        ]
      },
      {
        id: 8,
        value: "LF6 Class 3",
        elements: [
          {
            id: 1,
            max: 0.22,
            bm: "C"
          },
          {
            id: 3,
            min: 1.15,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            min: 0.15,
            max: 0.30,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            min: 0.04,
            max: 0.11,
            bm: "V"
          },
          {
            id: 13,
            min: 0.01,
            max: 0.030,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo+V",
            formula: "Cu+Ni+Cr+Mo+V"
          },
        ]
      },
      {
        id: 9,
        value: "LF9",
        elements: [
          {
            id: 1,
            max: 0.20,
            bm: "C"
          },
          {
            id: 3,
            min: 0.40,
            max: 1.06,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.035,
            bm: "P"
          },
          {
            id: 5,
            max: 0.040,
            bm: "S"
          },
          {
            id: 6,
            bm: "Si"
          },
          {
            id: 7,
            min: 1.60,
            max: 2.24,
            bm: "Ni"
          },
          {
            id: 8,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 10,
            min: 0.75,
            max: 1.25,
            bm: "Cu"
          },
          {
            id: 11,
            max: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.03,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            bm: "Cr+Mo",
            formula: "Cr+Mo"
          }
        ]
      },
      {
        id: 10,
        value: "LF787 Class 2",
        elements: [
          {
            id: 1,
            max: 0.07,
            bm: "C"
          },
          {
            id: 3,
            min: 0.40,
            max: 0.70,
            bm: "Mn"
          },
          {
            id: 4,
            max: 0.025,
            bm: "P"
          },
          {
            id: 5,
            max: 0.025,
            bm: "S"
          },
          {
            id: 6,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 7,
            min: 0.70,
            max: 1.00,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.60,
            max: 0.90,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0.15,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 10,
            min: 1.00,
            max: 1.30,
            bm: "Cu"
          },
          {
            id: 11,
            min: 0.02,
            bm: "Nb"
          },
          {
            id: 12,
            max: 0.03,
            bm: "V"
          },
          {
            id: 13,
            bm: "N"
          }
        ]
      },
    ]
  },
  {
    id: 20,
    value: "ASTM A707 (2019)",
    grades: [
      {
        id: 0,
        value: "L1 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.23
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.60,
                max: 1.50
              },
              "product": {
                min: 0.55,
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.040
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 1,
        value: "L1 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.23
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.60,
                max: 1.50
              },
              "product": {
                min: 0.55,
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.040
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 2,
        value: "L1 Class 3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.23
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.60,
                max: 1.50
              },
              "product": {
                min: 0.55,
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.040
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 3,
        value: "L1 Class 4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.23
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.60,
                max: 1.50
              },
              "product": {
                min: 0.55,
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.040
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 4,
        value: "L2 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.33
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.60,
                max: 1.35
              },
              "product": {
                min: 0.55,
                max: 1.45
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.040
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 5,
        value: "L2 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.33
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.60,
                max: 1.35
              },
              "product": {
                min: 0.55,
                max: 1.45
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.040
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 6,
        value: "L2 Class 3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.33
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.60,
                max: 1.35
              },
              "product": {
                min: 0.55,
                max: 1.45
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.040
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 7,
        value: "L2 Class 4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.33
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.60,
                max: 1.35
              },
              "product": {
                min: 0.55,
                max: 1.45
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.035
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.030
              },
              "product": {
                max: 0.040
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
              }
            },
            bm: "Cu+Ni+Cr+Mo",
            formula: "Cu+Ni+Cr+Mo"
          }
        ]
      },
      {
        id: 8,
        value: "L3 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.22
              },
              "product": {
                max: 0.25
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 1.15,
                max: 1.50
              },
              "product": {
                min: 1.05,
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.32
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.04,
                max: 0.11
              },
              "product": {
                min: 0.03,
                max: 0.13
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.010,
                max: 0.030
              },
              "product": {
                min: 0.005,
                max: 0.035
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0.20
              },
              "product": {
                min: 0.18
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 9,
        value: "L3 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.22
              },
              "product": {
                max: 0.25
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 1.15,
                max: 1.50
              },
              "product": {
                min: 1.05,
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.32
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.04,
                max: 0.11
              },
              "product": {
                min: 0.03,
                max: 0.13
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.010,
                max: 0.030
              },
              "product": {
                min: 0.005,
                max: 0.035
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0.20
              },
              "product": {
                min: 0.18
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 10,
        value: "L3 Class 3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.22
              },
              "product": {
                max: 0.25
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 1.15,
                max: 1.50
              },
              "product": {
                min: 1.05,
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.32
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.04,
                max: 0.11
              },
              "product": {
                min: 0.03,
                max: 0.13
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.010,
                max: 0.030
              },
              "product": {
                min: 0.005,
                max: 0.035
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0.20
              },
              "product": {
                min: 0.18
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 11,
        value: "L3 Class 4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.22
              },
              "product": {
                max: 0.25
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 1.15,
                max: 1.50
              },
              "product": {
                min: 1.05,
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.32
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.04,
                max: 0.11
              },
              "product": {
                min: 0.03,
                max: 0.13
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.010,
                max: 0.030
              },
              "product": {
                min: 0.005,
                max: 0.035
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 0.20
              },
              "product": {
                min: 0.18
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 12,
        value: "L4 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.45,
                max: 0.65
              },
              "product": {
                min: 0.40,
                max: 0.70
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 1.65,
                max: 2.00
              },
              "product": {
                min: 1.60,
                max: 2.05
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.30
              },
              "product": {
                min: 0.19,
                max: 0.33
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 13,
        value: "L4 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.45,
                max: 0.65
              },
              "product": {
                min: 0.40,
                max: 0.70
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 1.65,
                max: 2.00
              },
              "product": {
                min: 1.60,
                max: 2.05
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.30
              },
              "product": {
                min: 0.19,
                max: 0.33
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 14,
        value: "L4 Class 3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.45,
                max: 0.65
              },
              "product": {
                min: 0.40,
                max: 0.70
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 1.65,
                max: 2.00
              },
              "product": {
                min: 1.60,
                max: 2.05
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.30
              },
              "product": {
                min: 0.19,
                max: 0.33
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 15,
        value: "L4 Class 4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.45,
                max: 0.65
              },
              "product": {
                min: 0.40,
                max: 0.70
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 1.65,
                max: 2.00
              },
              "product": {
                min: 1.60,
                max: 2.05
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.30
              },
              "product": {
                min: 0.19,
                max: 0.33
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 16,
        value: "L5 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.07
              },
              "product": {
                max: 0.09
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.70
              },
              "product": {
                min: 0.35,
                max: 0.75
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 0.60,
                max: 0.90
              },
              "product": {
                min: 0.56,
                max: 0.94
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 0.70,
                max: 1.00
              },
              "product": {
                min: 0.67,
                max: 1.03
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.25
              },
              "product": {
                min: 0.14,
                max: 0.28
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 1.00,
                max: 1.30
              },
              "product": {
                min: 0.95,
                max: 1.35
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.03
              },
              "product": {
                min: 0.02
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 17,
        value: "L5 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.07
              },
              "product": {
                max: 0.09
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.70
              },
              "product": {
                min: 0.35,
                max: 0.75
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 0.60,
                max: 0.90
              },
              "product": {
                min: 0.56,
                max: 0.94
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 0.70,
                max: 1.00
              },
              "product": {
                min: 0.67,
                max: 1.03
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.25
              },
              "product": {
                min: 0.14,
                max: 0.28
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 1.00,
                max: 1.30
              },
              "product": {
                min: 0.95,
                max: 1.35
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.03
              },
              "product": {
                min: 0.02
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 18,
        value: "L5 Class 3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.07
              },
              "product": {
                max: 0.09
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.70
              },
              "product": {
                min: 0.35,
                max: 0.75
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 0.60,
                max: 0.90
              },
              "product": {
                min: 0.56,
                max: 0.94
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 0.70,
                max: 1.00
              },
              "product": {
                min: 0.67,
                max: 1.03
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.25
              },
              "product": {
                min: 0.14,
                max: 0.28
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 1.00,
                max: 1.30
              },
              "product": {
                min: 0.95,
                max: 1.35
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.03
              },
              "product": {
                min: 0.02
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 19,
        value: "L5 Class 4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.07
              },
              "product": {
                max: 0.09
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.70
              },
              "product": {
                min: 0.35,
                max: 0.75
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 0.60,
                max: 0.90
              },
              "product": {
                min: 0.56,
                max: 0.94
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 0.70,
                max: 1.00
              },
              "product": {
                min: 0.67,
                max: 1.03
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.15,
                max: 0.25
              },
              "product": {
                min: 0.14,
                max: 0.28
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                min: 1.00,
                max: 1.30
              },
              "product": {
                min: 0.95,
                max: 1.35
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.03
              },
              "product": {
                min: 0.02
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 20,
        value: "L6 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.07
              },
              "product": {
                max: 0.09
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 1.85,
                max: 2.20
              },
              "product": {
                min: 1.75,
                max: 2.30
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.17
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.25,
                max: 0.35
              },
              "product": {
                min: 0.22,
                max: 0.38
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 21,
        value: "L6 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.07
              },
              "product": {
                max: 0.09
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 1.85,
                max: 2.20
              },
              "product": {
                min: 1.75,
                max: 2.30
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.17
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.25,
                max: 0.35
              },
              "product": {
                min: 0.22,
                max: 0.38
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 22,
        value: "L6 Class 3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.07
              },
              "product": {
                max: 0.09
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 1.85,
                max: 2.20
              },
              "product": {
                min: 1.75,
                max: 2.30
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.17
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.25,
                max: 0.35
              },
              "product": {
                min: 0.22,
                max: 0.38
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 23,
        value: "L6 Class 4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.07
              },
              "product": {
                max: 0.09
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 1.85,
                max: 2.20
              },
              "product": {
                min: 1.75,
                max: 2.30
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.17
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.25,
                max: 0.35
              },
              "product": {
                min: 0.22,
                max: 0.38
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.06,
                max: 0.10
              },
              "product": {
                min: 0.05,
                max: 0.11
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 24,
        value: "L7 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.90
              },
              "product": {
                max: 1.00
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 3.2,
                max: 3.7
              },
              "product": {
                min: 3.18,
                max: 3.82
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            formula: "Cr+Mo+V",
            bm: "Cr+Mo+V"
          }
        ]
      },
      {
        id: 25,
        value: "L7 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.90
              },
              "product": {
                max: 1.00
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 3.2,
                max: 3.7
              },
              "product": {
                min: 3.18,
                max: 3.82
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            formula: "Cr+Mo+V",
            bm: "Cr+Mo+V"
          }
        ]
      },
      {
        id: 26,
        value: "L7 Class 3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.90
              },
              "product": {
                max: 1.00
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 3.2,
                max: 3.7
              },
              "product": {
                min: 3.18,
                max: 3.82
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            formula: "Cr+Mo+V",
            bm: "Cr+Mo+V"
          }
        ]
      },
      {
        id: 27,
        value: "L7 Class 4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.90
              },
              "product": {
                max: 1.00
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.035
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.34
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 3.2,
                max: 3.7
              },
              "product": {
                min: 3.18,
                max: 3.82
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.32
              },
              "product": {
              }
            },
            formula: "Cr+Mo+V",
            bm: "Cr+Mo+V"
          }
        ]
      },
      {
        id: 28,
        value: "L8 Class 1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.40
              },
              "product": {
                min: 0.15,
                max: 0.45
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 1.50,
                max: 2.00
              },
              "product": {
                min: 1.44,
                max: 2.06
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 2.8,
                max: 3.9
              },
              "product": {
                min: 2.68,
                max: 3.97
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.60
              },
              "product": {
                min: 0.35,
                max: 0.65
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 29,
        value: "L8 Class 2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.40
              },
              "product": {
                min: 0.15,
                max: 0.45
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 1.50,
                max: 2.00
              },
              "product": {
                min: 1.44,
                max: 2.06
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 2.8,
                max: 3.9
              },
              "product": {
                min: 2.68,
                max: 3.97
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.60
              },
              "product": {
                min: 0.35,
                max: 0.65
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 30,
        value: "L8 Class 3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.40
              },
              "product": {
                min: 0.15,
                max: 0.45
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 1.50,
                max: 2.00
              },
              "product": {
                min: 1.44,
                max: 2.06
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 2.8,
                max: 3.9
              },
              "product": {
                min: 2.68,
                max: 3.97
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.60
              },
              "product": {
                min: 0.35,
                max: 0.65
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      },
      {
        id: 31,
        value: "L8 Class 4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C",
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.40
              },
              "product": {
                min: 0.15,
                max: 0.45
              }
            },
            bm: "Mn"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "S"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.37
              }
            },
            bm: "Si"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 1.50,
                max: 2.00
              },
              "product": {
                min: 1.44,
                max: 2.06
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 2.8,
                max: 3.9
              },
              "product": {
                min: 2.68,
                max: 3.97
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.60
              },
              "product": {
                min: 0.35,
                max: 0.65
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "V"
          },
          {
            id: 10,
            analysis: {
              "heat": {
              },
              "product": {
              }
            },
            bm: "N"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.43
              }
            },
            bm: "Cu"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Nb"
          }
        ]
      }
    ]
  },
  {
    id: 21,
    value: "ASTM A790 (2020)",
    grades: [
      {
        id: 0,
        value: "S31803",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 4.5,
            max: 6.5,
            bm: "Ni"
          },
          {
            id: 7,
            min: 21.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 2.5,
            max: 3.5,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.08,
            max: 0.20,
            bm: "N"
          },
          {
            id: 10,
            bm: "Cu"
          },
        ]
      },
      {
        id: 1,
        value: "S32205",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 4.5,
            max: 6.5,
            bm: "Ni"
          },
          {
            id: 7,
            min: 22.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 3.0,
            max: 3.5,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.14,
            max: 0.20,
            bm: "N"
          },
          {
            id: 10,
            bm: "Cu"
          },
        ]
      },
      {
        id: 2,
        value: "S32750",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            min: 6.0,
            max: 8.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 24.0,
            max: 26.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 3.0,
            max: 5.0,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.24,
            max: 0.32,
            bm: "N"
          },
          {
            id: 10,
            max: 0.5,
            bm: "Cu"
          },
          {
            id: 11,
            min: 41,
            formula: "Cr+3.3*Mo+16*N",
            bm: "Cr+3.3*Mo+16*N"
          }
        ]
      },
      {
        id: 3,
        value: "S32760",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 6.0,
            max: 8.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 24.0,
            max: 26.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 3.0,
            max: 4.0,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.20,
            max: 0.30,
            bm: "N"
          },
          {
            id: 10,
            min: 0.5,
            max: 1.00,
            bm: "Cu"
          },
          {
            id: 11,
            min: 0.5,
            max: 1.00,
            bm: "W"
          },
          {
            id: 12,
            min: 41,
            formula: "Cr+3.3*(Mo+0.5*W)+16*N",
            bm: "Cr+3.3*(Mo+0.5*W)+16*N"
          }
        ]
      }
    ]
  },
  {
    id: 22,
    value: "ASTM A815 (2021)",
    grades: [
      {
        id: 0,
        value: "S44627",
        elements: [
          {
            id: 1,
            max: 0.010,
            bm: "C"
          },
          {
            id: 2,
            max: 0.75,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.020,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 25.0,
            max: 27.5,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0.75,
            max: 1.50,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
            max: 0.20
          },
          {
            id: 10,
            max: 0.015,
            bm: "N"
          },
          {
            id: 11,
            min: 0.05,
            max: 0.20,
            bm: "Nb"
          },
        ]
      },
      {
        id: 1,
        value: "S44626",
        elements: [
          {
            id: 1,
            max: 0.06,
            bm: "C"
          },
          {
            id: 2,
            max: 0.75,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.75,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 25.0,
            max: 27.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0.75,
            max: 1.50,
            bm: "Mo"
          },
          {
            id: 9,
            max: 0.20,
            bm: "Cu"
          },
          {
            id: 10,
            max: 0.040,
            bm: "N"
          },
          {
            id: 11,
            min: 0.20,
            max: 1.00,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");
              const nitrogen = elements.find(({bm}) => bm === "N");

              return 7 * (carbon.value + nitrogen.value) <= 0.2 ? 0.2 : 7 * (carbon.value + nitrogen.value);
            },
            bm: "Ti"
          }
        ]
      },
      {
        id: 2,
        value: "S42900",
        elements: [
          {
            id: 1,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            max: 1.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.75,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 14.0,
            max: 16.0,
            bm: "Cr"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
          },
          {
            id: 10,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 3,
        value: "S43000",
        elements: [
          {
            id: 1,
            max: 0.12,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 16.0,
            max: 18.0,
            bm: "Cr"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
          },
          {
            id: 10,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 4,
        value: "S43036",
        elements: [
          {
            id: 1,
            max: 0.10,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.75,
            bm: "Ni"
          },
          {
            id: 7,
            min: 16.0,
            max: 19.5,
            bm: "Cr"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "N"
          },
          {
            id: 11,
            max: 0.75,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 5 * carbon.value;
            },
            bm: "Ti"
          }
        ]
      },
      {
        id: 5,
        value: "S44600",
        elements: [
          {
            id: 1,
            max: 0.20,
            bm: "C"
          },
          {
            id: 2,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.75,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 23.0,
            max: 27.0,
            bm: "Cr"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
          },
          {
            id: 10,
            max: 0.25,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 6,
        value: "S31803",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 6,
            min: 4.5,
            max: 6.5,
            bm: "Ni"
          },
          {
            id: 7,
            min: 21.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 2.5,
            max: 3.5,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
          },
          {
            id: 10,
            min: 0.08,
            max: 0.20,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 7,
        value: "S32101",
        elements: [
          {
            id: 1,
            max: 0.040,
            bm: "C"
          },
          {
            id: 2,
            min: 4.0,
            max: 6.0,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 1.35,
            max: 1.70,
            bm: "Ni"
          },
          {
            id: 7,
            min: 21.0,
            max: 22.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0.10,
            max: 0.80,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.10,
            max: 0.80,
            bm: "Cu",
          },
          {
            id: 10,
            min: 0.20,
            max: 0.25,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 8,
        value: "S32202",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 1.00,
            max: 2.80,
            bm: "Ni"
          },
          {
            id: 7,
            min: 21.5,
            max: 24.0,
            bm: "Cr"
          },
          {
            id: 8,
            max: 0.45,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
          },
          {
            id: 10,
            min: 0.18,
            max: 0.26,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 9,
        value: "S32750",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.8,
            bm: "Si"
          },
          {
            id: 6,
            min: 6.0,
            max: 8.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 24.0,
            max: 26.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 3.0,
            max: 5.0,
            bm: "Mo"
          },
          {
            id: 9,
            max: 0.5,
            bm: "Cu",
          },
          {
            id: 10,
            min: 0.24,
            max: 0.32,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 10,
        value: "S32950",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 6,
            min: 3.5,
            max: 5.2,
            bm: "Ni"
          },
          {
            id: 7,
            min: 26.0,
            max: 29.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 1.00,
            max: 2.50,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
          },
          {
            id: 10,
            min: 0.15,
            max: 0.35,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 11,
        value: "S32760",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 6.0,
            max: 8.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 24.0,
            max: 26.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 3.0,
            max: 4.0,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.5,
            max: 1.00,
            bm: "Cu",
          },
          {
            id: 10,
            min: 0.20,
            max: 0.30,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            min: 0.50,
            max: 1.00,
            bm: "W"
          }
        ]
      },
      {
        id: 12,
        value: "S39274",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            min: 6.0,
            max: 8.0,
            bm: "Ni"
          },
          {
            id: 7,
            min: 24.0,
            max: 26.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 2.50,
            max: 3.50,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.20,
            max: 0.80,
            bm: "Cu",
          },
          {
            id: 10,
            min: 0.24,
            max: 0.32,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            min: 1.50,
            max: 2.50,
            bm: "W"
          },
          {
            id: 13,
            min: 41,
            formula: "Cr+3.3*Mo+16*N",
            bm: "Cr+3.3*Mo+16*N"
          }
        ]
      },
      {
        id: 13,
        value: "S32550",
        elements: [
          {
            id: 1,
            max: 0.04,
            bm: "C"
          },
          {
            id: 2,
            max: 1.50,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 4.5,
            max: 6.5,
            bm: "Ni"
          },
          {
            id: 7,
            min: 24.0,
            max: 27.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 2.9,
            max: 3.9,
            bm: "Mo"
          },
          {
            id: 9,
            min: 1.50,
            max: 2.50,
            bm: "Cu",
          },
          {
            id: 10,
            min: 0.10,
            max: 0.25,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 14,
        value: "S32205",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 4.5,
            max: 6.5,
            bm: "Ni"
          },
          {
            id: 7,
            min: 22.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 3.0,
            max: 3.5,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.14,
            max: 0.20,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 15,
        value: "S41000",
        elements: [
          {
            id: 1,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 7,
            min: 11.5,
            max: 13.5,
            bm: "Cr"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
          },
          {
            id: 10,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 16,
        value: "S41008",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.040,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.60,
            bm: "Ni"
          },
          {
            id: 7,
            min: 11.5,
            max: 13.5,
            bm: "Cr"
          },
          {
            id: 8,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu",
          },
          {
            id: 10,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          }
        ]
      },
      {
        id: 17,
        value: "S41500",
        elements: [
          {
            id: 1,
            max: 0.05,
            bm: "C"
          },
          {
            id: 2,
            min: 0.50,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.030,
            bm: "S"
          },
          {
            id: 5,
            max: 0.60,
            bm: "Si"
          },
          {
            id: 6,
            min: 3.5,
            max: 5.5,
            bm: "Ni"
          },
          {
            id: 7,
            min: 11.5,
            max: 14.0,
            bm: "Cr"
          },
          {
            id: 8,
            min: 0.50,
            max: 1.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "N"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            min: 0.50,
            max: 1.00,
            bm: "W"
          }
        ]
      },
    ]
  },
  {
    id: 23,
    value: "ASTM A859 (2014)",
    grades: [
      {
        id: 0,
        value: "A Class 1",
        elements: [
          {
            id: 1,
            max: 0.07,
            bm: "C"
          },
          {
            id: 2,
            min: 0.40,
            max: 0.70,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0.60,
            max: 0.90,
            bm: "Cr"
          },
          {
            id: 7,
            min: 0.70,
            max: 1.00,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.15,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            min: 1.00,
            max: 1.30,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0.02,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Al"
          },
          {
            id: 13,
            bm: "Ti"
          },
          {
            id: 14,
            bm: "Sn"
          },
          {
            id: 15,
            bm: "As"
          },
          {
            id: 16,
            bm: "Sb"
          },
          {
            id: 17,
            bm: "N"
          }
        ]
      },
      {
        id: 1,
        value: "A Class 2",
        elements: [
          {
            id: 1,
            max: 0.07,
            bm: "C"
          },
          {
            id: 2,
            min: 0.40,
            max: 0.70,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0.60,
            max: 0.90,
            bm: "Cr"
          },
          {
            id: 7,
            min: 0.70,
            max: 1.00,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.15,
            max: 0.25,
            bm: "Mo"
          },
          {
            id: 9,
            min: 1.00,
            max: 1.30,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0.02,
            bm: "Nb"
          },
          {
            id: 11,
            bm: "V"
          },
          {
            id: 12,
            bm: "Al"
          },
          {
            id: 13,
            bm: "Ti"
          },
          {
            id: 14,
            bm: "Sn"
          },
          {
            id: 15,
            bm: "As"
          },
          {
            id: 16,
            bm: "Sb"
          },
          {
            id: 17,
            bm: "N"
          }
        ]
      },
      {
        id: 2,
        value: "B",
        elements: [
          {
            id: 1,
            min: 0.02,
            max: 0.04,
            bm: "C"
          },
          {
            id: 2,
            min: 0.75,
            max: 1.05,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.015,
            bm: "P"
          },
          {
            id: 4,
            min: 0,
            max: 0.002,
            bm: "S"
          },
          {
            id: 5,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            min: 0.45,
            max: 0.75,
            bm: "Cr"
          },
          {
            id: 7,
            min: 3.35,
            max: 3.85,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.55,
            max: 0.65,
            bm: "Mo"
          },
          {
            id: 9,
            min: 1.15,
            max: 1.75,
            bm: "Cu"
          },
          {
            id: 10,
            min: 0.02,
            max: 0.06,
            bm: "Nb"
          },
          {
            id: 11,
            max: 0.008,
            bm: "V"
          },
          {
            id: 12,
            max: 0.03,
            bm: "Al"
          },
          {
            id: 13,
            max: 0.003,
            bm: "Ti"
          },
          {
            id: 14,
            max: 0.03,
            bm: "Sn"
          },
          {
            id: 15,
            max: 0.025,
            bm: "As"
          },
          {
            id: 16,
            max: 0.025,
            bm: "Sb"
          },
          {
            id: 17,
            min: 0.0,
            bm: "N"
          }
        ]
      }
    ]
  },
  {
    id: 24,
    value: "ASTM A369 (2018a)",
    grades: [
      {
        id: 0,
        value: "FP22",
        elements: [
          {
            id: 1,
            min: 0.05,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.025,
            bm: "P"
          },
          {
            id: 4,
            max: 0.025,
            bm: "S"
          },
          {
            id: 5,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 6,
            min: 1.90,
            max: 2.60,
            bm: "Cr"
          },
          {
            id: 7,
            min: 0.87,
            max: 1.13,
            bm: "Mo"
          }
        ]
      }
    ]
  },
  {
    id: 25,
    value: "ASTM A403 (2020)",
    grades: [
      {
        id: 0,
        value: "WPS31254",
        elements: [
          {
            id: 1,
            max: 0.020,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            min: 17.5,
            max: 18.5,
            bm: "Ni"
          },
          {
            id: 7,
            min: 19.5,
            max: 20.5,
            bm: "Cr"
          },
          {
            id: 8,
            min: 6.0,
            max: 6.5,
            bm: "Mo"
          },
          {
            id: 9,
            min: 0.18,
            max: 0.25,
            bm: "N"
          },
          {
            id: 10,
            min: 0.50,
            max: 1.00,
            bm: "Cu"
          }
        ]
      }
    ]
  },
  {
    id: 26,
    value: "ASTM A420 (2020)",
    grades: [
      {
        id: 0,
        value: "WPL6",
        elements: [
          {
            id: 1,
            max: 0.30,
            bm: "C"
          },
          {
            id: 2,
            min: 0.50,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.040,
            bm: "S"
          },
          {
            id: 5,
            min: 0.15,
            max: 0.40,
            bm: "Si"
          },
          {
            id: 6,
            max: 0.40,
            bm: "Ni"
          },
          {
            id: 7,
            max: 0.30,
            bm: "Cr"
          },
          {
            id: 8,
            max: 0.12,
            bm: "Mo"
          },
          {
            id: 9,
            max: 0.40,
            bm: "Cu"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.02,
                maxError: 0.05
              },
              "product": {
                max: 0.02,
                maxError: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            max: 0.08,
            bm: "V"
          },
        ]
      }
    ]
  },
  {
    id: 27,
    value: "ASTM A479 (2021)",
    grades: [
      {
        id: 0,
        value: "S31803",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 21.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 4.5,
            max: 6.5,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.08,
            max: 0.20,
            bm: "N"
          },
          {
            id: 9,
            min: 2.5,
            max: 3.5,
            bm: "Mo"
          }
        ]
      },
      {
        id: 1,
        value: "S32750",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.035,
            bm: "P"
          },
          {
            id: 4,
            max: 0.020,
            bm: "S"
          },
          {
            id: 5,
            max: 0.80,
            bm: "Si"
          },
          {
            id: 6,
            min: 24.0,
            max: 26.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 6.0,
            max: 8.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.24,
            max: 0.32,
            bm: "N"
          },
          {
            id: 9,
            min: 3.0,
            max: 5.0,
            bm: "Mo"
          },
          {
            id: 10,
            max: 0.50,
            bm: "Cu"
          },
          {
            id: 11,
            min: 41,
            formula: "Cr+3.3*Mo+16*N",
            bm: "Cr+3.3*Mo+16*N"
          }
        ]
      },
      {
        id: 2,
        value: "S32760",
        elements: [
          {
            id: 1,
            max: 0.030,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.030,
            bm: "P"
          },
          {
            id: 4,
            max: 0.010,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 24.0,
            max: 26.0,
            bm: "Cr"
          },
          {
            id: 7,
            min: 6.0,
            max: 8.0,
            bm: "Ni"
          },
          {
            id: 8,
            min: 0.20,
            max: 0.30,
            bm: "N"
          },
          {
            id: 9,
            min: 3.0,
            max: 4.0,
            bm: "Mo"
          },
          {
            id: 10,
            min: 0.50,
            max: 1.00,
            bm: "Cu"
          },
          {
            id: 11,
            min: 0.50,
            max: 1.00,
            bm: "W"
          },
          {
            id: 11,
            min: 41,
            formula: "Cr+3.3*(Mo+0.5*W)+16*N",
            bm: "Cr+3.3*(Mo+0.5*W)+16*N"
          }
        ]
      }
    ]
  },
  {
    id: 28,
    value: "ASTM B423 (2011)",
    grades: [
      {
        id: 0,
        value: "N08825",
        elements: [
          {
            id: 1,
            min: 38.0,
            max: 46.0,
            bm: "Ni"
          },
          {
            id: 2,
            min: 19.5,
            max: 23.5,
            bm: "Cr"
          },
          {
            id: 3,
            min: 22.0,
            bm: "Fe"
          },
          {
            id: 4,
            max: 1.0,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.05,
            bm: "C"
          },
          {
            id: 6,
            min: 1.5,
            max: 3.0,
            bm: "Cu"
          },
          {
            id: 7,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 8,
            max: 0.03,
            bm: "S"
          },
          {
            id: 9,
            max: 0.2,
            bm: "Al"
          },
          {
            id: 10,
            min: 0.6,
            max: 1.2,
            bm: "Ti"
          },
          {
            id: 11,
            min: 2.5,
            max: 3.5,
            bm: "Mo"
          },
          {
            id: 12,
            bm: "W"
          }
        ]
      }
    ]
  },
  {
    id: 29,
    value: "ASTM B444 (2018)",
    grades: [
      {
        id: 0,
        value: "N06625",
        elements: [
          {
            id: 1,
            min: 58.0,
            bm: "Ni"
          },
          {
            id: 2,
            min: 20.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 3,
            max: 5.0,
            bm: "Fe"
          },
          {
            id: 4,
            max: 0.50,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.10,
            bm: "C"
          },
          {
            id: 6,
            bm: "Cu"
          },
          {
            id: 7,
            max: 0.50,
            bm: "Si"
          },
          {
            id: 8,
            max: 0.015,
            bm: "S"
          },
          {
            id: 9,
            max: 0.40,
            bm: "Al"
          },
          {
            id: 10,
            max: 0.40,
            bm: "Ti"
          },
          {
            id: 11,
            min: 8.0,
            max: 10.0,
            bm: "Mo"
          },
          {
            id: 12,
            max: 1.0,
            bm: "Co"
          },
          {
            id: 13,
            bm: "Nb"
          },
          {
            id: 14,
            bm: "Ta"
          },
          {
            id: 13,
            min: 3.15,
            max: 4.15,
            bm: "Nb+Ta",
            formula: "Nb+Ta"
          }
        ]
      }
    ]
  },
  {
    id: 30,
    value: "ASTM B462 (2018e1)",
    grades: [
      {
        id: 0,
        value: "N08020",
        elements: [
          {
            id: 1,
            max: 0.07,
            bm: "C"
          },
          {
            id: 2,
            max: 2.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.045,
            bm: "P"
          },
          {
            id: 4,
            max: 0.035,
            bm: "S"
          },
          {
            id: 5,
            max: 1.00,
            bm: "Si"
          },
          {
            id: 6,
            min: 32.00,
            max: 38.00,
            bm: "Ni"
          },
          {
            id: 7,
            min: 19.00,
            max: 21.00,
            bm: "Cr"
          },
          {
            id: 8,
            min: 2.00,
            max: 3.00,
            bm: "Mo"
          },
          {
            id: 9,
            min: 3.00,
            max: 4.00,
            bm: "Cu"
          },
          {
            id: 10,
            max: 1.00,
            getMin({elements = []}) {
              const carbon = elements.find(({bm}) => bm === "C");

              return 8 * carbon.value;
            },
            bm: "Nb+Ta"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            bm: "Fe"
          }
        ]
      },
      {
        id: 1,
        value: "N10276",
        elements: [
          {
            id: 1,
            max: 0.010,
            bm: "C"
          },
          {
            id: 2,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.04,
            bm: "P"
          },
          {
            id: 4,
            max: 0.03,
            bm: "S"
          },
          {
            id: 5,
            max: 0.08,
            bm: "Si"
          },
          {
            id: 6,
            bm: "Ni"
          },
          {
            id: 7,
            min: 14.5,
            max: 16.5,
            bm: "Cr"
          },
          {
            id: 8,
            min: 15.00,
            max: 17.00,
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Cu"
          },
          {
            id: 10,
            bm: "Nb+Ta"
          },
          {
            id: 11,
            bm: "N"
          },
          {
            id: 12,
            min: 4.0,
            max: 7.0,
            bm: "Fe"
          },
          {
            id: 13,
            max: 2.5,
            bm: "Co"
          },
          {
            id: 14,
            min: 3.0,
            max: 4.5,
            bm: "W"
          },
          {
            id: 15,
            max: 0.35,
            bm: "V"
          },
          {
            id: 16,
            bm: "Ti"
          },
          {
            id: 17,
            bm: "Zr"
          },
          {
            id: 18,
            bm: "Al"
          },
          {
            id: 19,
            bm: "Ni+Mo"
          },
          {
            id: 20,
            bm: "Nb"
          },
          {
            id: 21,
            bm: "Ta"
          },
        ]
      },
    ]
  },
  {
    id: 31,
    value: "ASTM B564 (2019)",
    grades: [
      {
        id: 0,
        value: "N04400",
        elements: [
          {
            id: 1,
            min: 63.0,
            bm: "Ni"
          },
          {
            id: 2,
            min: 28.0,
            max: 34.0,
            bm: "Cu"
          },
          {
            id: 3,
            max: 2.5,
            bm: "Fe"
          },
          {
            id: 4,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.3,
            bm: "C"
          },
          {
            id: 6,
            max: 0.5,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.024,
            bm: "S"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Al"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            bm: "Mo"
          },
          {
            id: 13,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      },
      {
        id: 1,
        value: "N06600",
        elements: [
          {
            id: 1,
            min: 72.0,
            bm: "Ni"
          },
          {
            id: 2,
            max: 0.5,
            bm: "Cu"
          },
          {
            id: 3,
            min: 6.0,
            max: 10.0,
            bm: "Fe"
          },
          {
            id: 4,
            max: 1.0,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.15,
            bm: "C"
          },
          {
            id: 6,
            max: 0.5,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.015,
            bm: "S"
          },
          {
            id: 8,
            min: 14.0,
            max: 17.0,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Al"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            bm: "Mo"
          },
          {
            id: 13,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      },
      {
        id: 2,
        value: "N06617",
        elements: [
          {
            id: 1,
            min: 44.5,
            bm: "Ni"
          },
          {
            id: 2,
            max: 0.5,
            bm: "Cu"
          },
          {
            id: 3,
            max: 3.0,
            bm: "Fe"
          },
          {
            id: 4,
            max: 1.0,
            bm: "Mn"
          },
          {
            id: 5,
            min: 0.05,
            max: 0.15,
            bm: "C"
          },
          {
            id: 6,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.015,
            bm: "S"
          },
          {
            id: 8,
            min: 20.0,
            max: 24.0,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0.8,
            max: 1.5,
            bm: "Al"
          },
          {
            id: 10,
            max: 0.6,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            min: 8.0,
            max: 10.0,
            bm: "Mo"
          },
          {
            id: 13,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            min: 10.0,
            max: 15.0,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            max: 0.006,
            bm: "B"
          }
        ]
      },
      {
        id: 3,
        value: "N06625",
        elements: [
          {
            id: 1,
            min: 58.0,
            bm: "Ni"
          },
          {
            id: 2,
            bm: "Cu"
          },
          {
            id: 3,
            max: 5.0,
            bm: "Fe"
          },
          {
            id: 4,
            max: 0.5,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.10,
            bm: "C"
          },
          {
            id: 6,
            max: 0.5,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.015,
            bm: "S"
          },
          {
            id: 8,
            min: 20.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.4,
            bm: "Al"
          },
          {
            id: 10,
            max: 0.4,
            bm: "Ti"
          },
          {
            id: 11,
            min: 3.15,
            max: 4.15,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            min: 8.0,
            max: 10.0,
            bm: "Mo"
          },
          {
            id: 13,
            max: 0.015,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      },
      {
        id: 4,
        value: "N08800",
        elements: [
          {
            id: 1,
            min: 30.0,
            max: 35.0,
            bm: "Ni"
          },
          {
            id: 2,
            max: 0.75,
            bm: "Cu"
          },
          {
            id: 3,
            min: 39.5,
            bm: "Fe"
          },
          {
            id: 4,
            max: 1.5,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.10,
            bm: "C"
          },
          {
            id: 6,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.015,
            bm: "S"
          },
          {
            id: 8,
            min: 19.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0.15,
            max: 0.60,
            bm: "Al"
          },
          {
            id: 10,
            min: 0.15,
            max: 0.60,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            bm: "Mo"
          },
          {
            id: 13,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      },
      {
        id: 5,
        value: "N08810",
        elements: [
          {
            id: 1,
            min: 30.0,
            max: 35.0,
            bm: "Ni"
          },
          {
            id: 2,
            max: 0.75,
            bm: "Cu"
          },
          {
            id: 3,
            min: 39.5,
            bm: "Fe"
          },
          {
            id: 4,
            max: 1.5,
            bm: "Mn"
          },
          {
            id: 5,
            min: 0.05,
            max: 0.10,
            bm: "C"
          },
          {
            id: 6,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.015,
            bm: "S"
          },
          {
            id: 8,
            min: 19.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0.15,
            max: 0.60,
            bm: "Al"
          },
          {
            id: 10,
            min: 0.15,
            max: 0.60,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            bm: "Mo"
          },
          {
            id: 13,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      },
      {
        id: 6,
        value: "N08811",
        elements: [
          {
            id: 1,
            min: 30.0,
            max: 35.0,
            bm: "Ni"
          },
          {
            id: 2,
            max: 0.75,
            bm: "Cu"
          },
          {
            id: 3,
            min: 39.5,
            bm: "Fe"
          },
          {
            id: 4,
            max: 1.5,
            bm: "Mn"
          },
          {
            id: 5,
            min: 0.06,
            max: 0.10,
            bm: "C"
          },
          {
            id: 6,
            max: 1.0,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.015,
            bm: "S"
          },
          {
            id: 8,
            min: 19.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 9,
            min: 0.15,
            max: 0.60,
            bm: "Al"
          },
          {
            id: 10,
            min: 0.15,
            max: 0.60,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            bm: "Mo"
          },
          {
            id: 13,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          },
          {
            id: 19,
            min: 0.85,
            maqx: 1.20,
            bm: "Al+Ti",
            formula: "Al+Ti",
          }
        ]
      },
      {
        id: 7,
        value: "N08825",
        elements: [
          {
            id: 1,
            min: 38.0,
            max: 46.0,
            bm: "Ni"
          },
          {
            id: 2,
            min: 1.5,
            max: 3.0,
            bm: "Cu"
          },
          {
            id: 3,
            min: 22.0,
            bm: "Fe"
          },
          {
            id: 4,
            max: 1.0,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.05,
            bm: "C"
          },
          {
            id: 6,
            max: 0.5,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.03,
            bm: "S"
          },
          {
            id: 8,
            min: 19.5,
            max: 23.5,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.2,
            bm: "Al"
          },
          {
            id: 10,
            min: 0.6,
            max: 1.2,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            min: 2.5,
            max: 3.5,
            bm: "Mo"
          },
          {
            id: 13,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      },
      {
        id: 8,
        value: "N08825",
        elements: [
          {
            id: 1,
            bm: "Ni"
          },
          {
            id: 2,
            bm: "Cu"
          },
          {
            id: 3,
            min: 4.0,
            max: 7.0,
            bm: "Fe"
          },
          {
            id: 4,
            max: 1.0,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.010,
            bm: "C"
          },
          {
            id: 6,
            max: 0.08,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.03,
            bm: "S"
          },
          {
            id: 8,
            min: 14.5,
            max: 16.5,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Al"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            min: 15.0,
            max: 17.0,
            bm: "Mo"
          },
          {
            id: 13,
            max: 0.04,
            bm: "P"
          },
          {
            id: 14,
            min: 3.0,
            max: 4.5,
            bm: "W"
          },
          {
            id: 15,
            max: 2.5,
            bm: "Co"
          },
          {
            id: 16,
            max: 0.35,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      },
    ]
  },
  {
    id: 32,
    value: "ASTM B637 (2018)",
    grades: [
      {
        id: 0,
        value: "N07718",
        elements: [
          {
            id: 1,
            max: 0.08,
            bm: "C"
          },
          {
            id: 2,
            max: 0.35,
            bm: "Mn"
          },
          {
            id: 3,
            max: 0.015,
            bm: "P"
          },
          {
            id: 4,
            max: 0.015,
            bm: "S"
          },
          {
            id: 5,
            max: 0.35,
            bm: "Si"
          },
          {
            id: 6,
            min: 50.00,
            max: 55.00,
            bm: "Ni"
          },
          {
            id: 7,
            min: 17.00,
            max: 21.00,
            bm: "Cr"
          },
          {
            id: 8,
            min: 2.80,
            max: 3.30,
            bm: "Mo"
          },
          {
            id: 9,
            max: 0.30,
            bm: "Cu"
          },
          {
            id: 10,
            min: 4.75,
            max: 5.50,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            bm: "Fe"
          },
          {
            id: 13,
            max: 1.0,
            bm: "Co"
          },
          {
            id: 14,
            min: 0.65,
            max: 1.15,
            bm: "Ti"
          },
          {
            id: 15,
            min: 0.20,
            max: 0.80,
            bm: "Al"
          },
          {
            id: 16,
            max: 0.006,
            bm: "Zr"
          }
        ]
      }
    ]
  },
  {
    id: 33,
    value: "ASTM B366 (2019)",
    grades: [
      {
        id: 0,
        value: "N04400",
        elements: [
          {
            id: 1,
            min: 63.0,
            bm: "Ni"
          },
          {
            id: 2,
            min: 28.0,
            max: 34.0,
            bm: "Cu"
          },
          {
            id: 3,
            max: 2.5,
            bm: "Fe"
          },
          {
            id: 4,
            max: 2.0,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.3,
            bm: "C"
          },
          {
            id: 6,
            max: 0.5,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.024,
            bm: "S"
          },
          {
            id: 8,
            bm: "Cr"
          },
          {
            id: 9,
            bm: "Al"
          },
          {
            id: 10,
            bm: "Ti"
          },
          {
            id: 11,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            bm: "Mo"
          },
          {
            id: 13,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      },
      {
        id: 2,
        value: "N06625",
        elements: [
          {
            id: 1,
            min: 58.0,
            bm: "Ni"
          },
          {
            id: 2,
            bm: "Cu"
          },
          {
            id: 3,
            max: 5.0,
            bm: "Fe"
          },
          {
            id: 4,
            max: 0.5,
            bm: "Mn"
          },
          {
            id: 5,
            max: 0.10,
            bm: "C"
          },
          {
            id: 6,
            max: 0.5,
            bm: "Si"
          },
          {
            id: 7,
            max: 0.015,
            bm: "S"
          },
          {
            id: 8,
            min: 20.0,
            max: 23.0,
            bm: "Cr"
          },
          {
            id: 9,
            max: 0.4,
            bm: "Al"
          },
          {
            id: 10,
            max: 0.4,
            bm: "Ti"
          },
          {
            id: 11,
            min: 3.15,
            max: 4.15,
            bm: "Nb+Ta"
          },
          {
            id: 12,
            min: 8.0,
            max: 10.0,
            bm: "Mo"
          },
          {
            id: 13,
            max: 0.015,
            bm: "P"
          },
          {
            id: 14,
            bm: "W"
          },
          {
            id: 15,
            bm: "Co"
          },
          {
            id: 16,
            bm: "V"
          },
          {
            id: 17,
            bm: "N"
          },
          {
            id: 18,
            bm: "B"
          }
        ]
      }
    ]
  },
  {
    id: 34,
    value: "EN 10222-2 (2017)",
    grades: [
      {
        id: 0,
        value: "11CrMo9-10",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.15
              },
              "product": {
                min: 0.08,
                max: 0.17
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.55
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.80
              },
              "product": {
                min: 0.40,
                max: 0.85
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
                maxError: 0.015
              },
              "product": {
                max: 0.013,
                maxError: 0.020
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 2.0,
                max: 2.50
              },
              "product": {
                min: 1.90,
                max: 2.60
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.25
              },
              "product": {
                max: 0.30
              }
            },
            bm: "Cu"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.90,
                max: 1.10
              },
              "product": {
                min: 0.86,
                max: 1.14
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            bm: "Ni"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
        ]
      },
      {
        id: 1,
        value: "13CrMo4-5",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.18
              },
              "product": {
                min: 0.08,
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.40
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0.40,
                max: 1.00
              },
              "product": {
                min: 0.40,
                max: 1.05
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
                maxError: 0.015
              },
              "product": {
                max: 0.013,
                maxError: 0.020
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 0.70,
                max: 1.15
              },
              "product": {
                min: 0.75,
                max: 1.20
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.60
              },
              "product": {
                min: 0.36,
                max: 0.64
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Ni"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
        ]
      },
      {
        id: 2,
        value: "16Mo3",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.12,
                max: 0.20
              },
              "product": {
                min: 0.12,
                max: 0.22
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.35
              },
              "product": {
                max: 0.40
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0.40,
                max: 0.90
              },
              "product": {
                min: 0.40,
                max: 0.95
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
                maxError: 0.015
              },
              "product": {
                max: 0.013,
                maxError: 0.020
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.25,
                max: 0.35
              },
              "product": {
                min: 0.22,
                max: 0.38
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Ni"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            bm: "V"
          },
        ]
      },
      {
        id: 2,
        value: "P245GH",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.20
              },
              "product": {
                min: 0.08,
                max: 0.22
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.45
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0.50,
                max: 1.30
              },
              "product": {
                min: 0.55,
                max: 1.40
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.015,
              },
              "product": {
                max: 0.020,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.11
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.01
              },
              "product": {
                max: 0.015
              }
            },
            bm: "Nb"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Ni"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Ti"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.70
              }
            },
            bm: "Cr+Cu+Mo+Ni",
            formula: "Cr+Cu+Mo+Ni"
          },
        ]
      },
      {
        id: 3,
        value: "P280GH",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.08,
                max: 0.20
              },
              "product": {
                min: 0.08,
                max: 0.22
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.45
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0.90,
                max: 1.30
              },
              "product": {
                min: 0.95,
                max: 1.40
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.015,
              },
              "product": {
                max: 0.020,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.11
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.01
              },
              "product": {
                max: 0.015
              }
            },
            bm: "Nb"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Ni"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.03
              }
            },
            bm: "Ti"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.02
              },
              "product": {
                max: 0.03
              }
            },
            bm: "V"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.70
              }
            },
            bm: "Cr+Cu+Mo+Ni",
            formula: "Cr+Cu+Mo+Ni"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.70
              }
            },
            formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
            bm: "CE_IIW"
          },
        ]
      },
      {
        id: 4,
        value: "X20CrMov11-1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.17,
                max: 0.23
              },
              "product": {
                min: 0.17,
                max: 0.25
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.45
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0.30,
                max: 1.00
              },
              "product": {
                min: 0.30,
                max: 1.05
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.005,
              },
              "product": {
                max: 0.008,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                min: 10.00,
                max: 12.50
              },
              "product": {
                min: 9.85,
                max: 12.65
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            bm: "Cu"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.80,
                max: 1.20
              },
              "product": {
                min: 0.76,
                max: 1.24
              }
            },
            bm: "Mo"
          },
          {
            id: 9,
            bm: "Nb"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.30,
                max: 0.80
              },
              "product": {
                min: 0.30,
                max: 0.85
              }
            },
            bm: "Ni"
          },
          {
            id: 11,
            bm: "Ti"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.20,
                max: 0.35
              },
              "product": {
                min: 0.17,
                max: 0.38
              }
            },
            bm: "V"
          },
        ]
      },
    ]
  },
  {
    id: 34,
    value: "VDTUV 377-3 (2010)",
    grades: [
      {
        id: 0,
        value: "15NiCuMoNb5-6-4",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                min: 0.10,
                max: 0.17
              },
              "product": {
                min: 0.08,
                max: 0.19
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                min: 0.25,
                max: 0.50
              },
              "product": {
                min: 0.20,
                max: 0.56
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                min: 0.80,
                max: 1.20
              },
              "product": {
                min: 0.75,
                max: 1.30
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.020,
              },
              "product": {
                max: 0.025,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cr"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                min: 1.00,
                max: 1.30
              },
              "product": {
                min: 0.95,
                max: 1.35
              }
            },
            bm: "Ni"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                min: 0.50,
                max: 0.80
              },
              "product": {
                min: 0.45,
                max: 0.85
              }
            },
            bm: "Cu"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                min: 0.25,
                max: 0.50
              },
              "product": {
                min: 0.22,
                max: 0.54
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                min: 0.015,
                max: 0.045
              },
              "product": {
                min: 0.010,
                max: 0.050
              }
            },
            bm: "Nb"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.022
              }
            },
            bm: "N"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                min: 0.015,
                max: 0.050
              },
              "product": {
                min: 0.010,
                max: 0.060
              }
            },
            bm: "Al"
          },
        ]
      }
    ]
  },
  {
    id: 35,
    value: "EN 10028-6:2017",
    grades: [
      {
        id: 0,
        value: "P355Q",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.16
              },
              "product": {
                max: 0.18
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.45
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
              },
              "product": {
                max: 0.013,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.40
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.25
              },
              "product": {
                max: 0.29
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.04
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.06
              },
              "product": {
                max: 0.07
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 1,
        value: "P355QH",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.16
              },
              "product": {
                max: 0.18
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.45
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
              },
              "product": {
                max: 0.013,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.40
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.25
              },
              "product": {
                max: 0.29
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.04
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.06
              },
              "product": {
                max: 0.07
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 2,
        value: "P355QL1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.16
              },
              "product": {
                max: 0.18
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.45
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.008,
              },
              "product": {
                max: 0.011,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.40
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.25
              },
              "product": {
                max: 0.29
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.04
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.06
              },
              "product": {
                max: 0.07
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 3,
        value: "P355QL2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.16
              },
              "product": {
                max: 0.18
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.40
              },
              "product": {
                max: 0.45
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.005,
              },
              "product": {
                max: 0.008,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.40
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.25
              },
              "product": {
                max: 0.29
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.04
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.06
              },
              "product": {
                max: 0.07
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 5,
        value: "P460Q",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.55
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
              },
              "product": {
                max: 0.013,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.60
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.54
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
                max: 1.10
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.04
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.09
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 6,
        value: "P460QH",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.55
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
              },
              "product": {
                max: 0.013,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.60
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.54
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
                max: 1.10
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.04
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.09
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 7,
        value: "P460QL1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.55
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.008,
              },
              "product": {
                max: 0.011,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.60
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.54
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
                max: 1.10
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.04
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.09
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 8,
        value: "P460QL2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.55
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.005,
              },
              "product": {
                max: 0.008,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.60
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.50
              },
              "product": {
                max: 0.54
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
                max: 1.10
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.03
              },
              "product": {
                max: 0.04
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.09
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 9,
        value: "P500Q",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.60
              },
              "product": {
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
              },
              "product": {
                max: 0.013,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
                max: 1.10
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.74
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.09
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.16
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 10,
        value: "P500QH",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.60
              },
              "product": {
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
              },
              "product": {
                max: 0.013,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
                max: 1.10
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.74
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.09
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.16
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 11,
        value: "P500QL1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.60
              },
              "product": {
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.008,
              },
              "product": {
                max: 0.011,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
                max: 1.10
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.74
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.09
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.16
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 12,
        value: "P500QL2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.18
              },
              "product": {
                max: 0.20
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.60
              },
              "product": {
                max: 0.65
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.005,
              },
              "product": {
                max: 0.008,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 1.00
              },
              "product": {
                max: 1.10
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.74
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.08
              },
              "product": {
                max: 0.09
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.16
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 13,
        value: "P690Q",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.80
              },
              "product": {
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
              },
              "product": {
                max: 0.013,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.74
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.06
              },
              "product": {
                max: 0.07
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 2.50
              },
              "product": {
                max: 2.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.16
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 14,
        value: "P690QH",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.80
              },
              "product": {
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.025
              },
              "product": {
                max: 0.030
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.010,
              },
              "product": {
                max: 0.013,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.74
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.06
              },
              "product": {
                max: 0.07
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 2.50
              },
              "product": {
                max: 2.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.16
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 15,
        value: "P690QL1",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.80
              },
              "product": {
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.008,
              },
              "product": {
                max: 0.011,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.74
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.06
              },
              "product": {
                max: 0.07
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 2.50
              },
              "product": {
                max: 2.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.16
              }
            },
            bm: "Zr"
          }
        ]
      },
      {
        id: 16,
        value: "P690QL2",
        elements: [
          {
            id: 1,
            analysis: {
              "heat": {
                max: 0.20
              },
              "product": {
                max: 0.22
              }
            },
            bm: "C"
          },
          {
            id: 2,
            analysis: {
              "heat": {
                max: 0.80
              },
              "product": {
                max: 0.85
              }
            },
            bm: "Si"
          },
          {
            id: 3,
            analysis: {
              "heat": {
                max: 1.70
              },
              "product": {
                max: 1.80
              }
            },
            bm: "Mn"
          },
          {
            id: 4,
            analysis: {
              "heat": {
                max: 0.020
              },
              "product": {
                max: 0.025
              }
            },
            bm: "P"
          },
          {
            id: 5,
            analysis: {
              "heat": {
                max: 0.005,
              },
              "product": {
                max: 0.008,
              }
            },
            bm: "S"
          },
          {
            id: 6,
            analysis: {
              "heat": {
                max: 0.015
              },
              "product": {
                max: 0.017
              }
            },
            bm: "N"
          },
          {
            id: 7,
            analysis: {
              "heat": {
                max: 0.005
              },
              "product": {
                max: 0.0055
              }
            },
            bm: "B"
          },
          {
            id: 8,
            analysis: {
              "heat": {
                max: 1.50
              },
              "product": {
                max: 1.60
              }
            },
            bm: "Cr"
          },
          {
            id: 9,
            analysis: {
              "heat": {
                max: 0.70
              },
              "product": {
                max: 0.74
              }
            },
            bm: "Mo"
          },
          {
            id: 10,
            analysis: {
              "heat": {
                max: 0.30
              },
              "product": {
                max: 0.35
              }
            },
            bm: "Cu"
          },
          {
            id: 11,
            analysis: {
              "heat": {
                max: 0.06
              },
              "product": {
                max: 0.07
              }
            },
            bm: "Nb"
          },
          {
            id: 12,
            analysis: {
              "heat": {
                max: 2.50
              },
              "product": {
                max: 2.60
              }
            },
            bm: "Ni"
          },
          {
            id: 13,
            analysis: {
              "heat": {
                max: 0.05
              },
              "product": {
                max: 0.06
              }
            },
            bm: "Ti"
          },
          {
            id: 14,
            analysis: {
              "heat": {
                max: 0.12
              },
              "product": {
                max: 0.13
              }
            },
            bm: "V"
          },
          {
            id: 15,
            analysis: {
              "heat": {
                max: 0.15
              },
              "product": {
                max: 0.16
              }
            },
            bm: "Zr"
          }
        ]
      },
    ]
  },
  {
    id: 36,
    value: "CSA Z245.1 (2018)",
    grades: [
      {
        id: 0,
        value: "359 Category II - SS M18C",
        elements: []
      }
    ]
  },
  {
    id: 37,
    value: "API 5CT (2018, Err.1-2-3 Add. 1)",
    grades: [
      {
        id: 0,
        value: "H40",
        elements: [
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.030,
            bm: "S"
          },
        ]
      },
      {
        id: 1,
        value: "J55",
        elements: [
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.030,
            bm: "S"
          },
        ]
      },
      {
        id: 2,
        value: "K55",
        elements: [
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.030,
            bm: "S"
          },
        ]
      },
      {
        id: 3,
        value: "N80 Type 1",
        elements: [
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.030,
            bm: "S"
          },
        ]
      },
      {
        id: 4,
        value: "N80 Type Q",
        elements: [
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.030,
            bm: "S"
          },
        ]
      },
      {
        id: 5,
        value: "R95",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.45,
            maxError: 0.55,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.90,
            bm: "Mn"
          },
          {
            id: 4,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.45,
            bm: "Si"
          }],
      },
      {
        id: 6,
        value: "L80 Type 1",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.43,
            maxError: 0.50,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.90,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.25,
            bm: "Ni"
          },
          {
            id: 4,
            min: 0,
            max: 0.35,
            bm: "Cu"
          },
          {
            id: 5,
            min: 0,
            max: 0.030,
            bm: "P"
          },
          {
            id: 6,
            min: 0,
            max: 0.030,
            bm: "S"
          },
          {
            id: 6,
            min: 0,
            max: 0.45,
            bm: "Si"
          }],
      },
      {
        id: 7,
        value: "L80 Type 9Cr",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.15,
            bm: "C"
          },
          {
            id: 2,
            min: 0.30,
            max: 0.60,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0.90,
            max: 1.10,
            bm: "Mo"
          },
          {
            id: 4,
            min: 8.00,
            max: 10.0,
            bm: "Cr"
          },
          {
            id: 5,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 6,
            min: 0,
            max: 0.25,
            bm: "Cu"
          },
          {
            id: 7,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 8,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 9,
            min: 0,
            max: 1.00,
            bm: "Si"
          }],
      },
      {
        id: 8,
        value: "L80 Type 13Cr",
        elements: [
          {
            id: 1,
            min: 0.15,
            max: 0.22,
            bm: "C"
          },
          {
            id: 2,
            min: 0.25,
            max: 1.00,
            bm: "Mn"
          },
          {
            id: 4,
            min: 12.00,
            max: 14.0,
            bm: "Cr"
          },
          {
            id: 5,
            min: 0,
            max: 0.50,
            bm: "Ni"
          },
          {
            id: 6,
            min: 0,
            max: 0.25,
            bm: "Cu"
          },
          {
            id: 7,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 8,
            min: 0,
            max: 0.010,
            bm: "S"
          },
          {
            id: 9,
            min: 0,
            max: 1.00,
            bm: "Si"
          }],
      },
      {
        id: 9,
        value: "C90 Type 1",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.35,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0.25,
            getMin({measurement: thickness}) {
              if(thickness && thickness < 17.78) return 0;

              return 0.25;
            },
            max: 0.85,
            bm: "Mo",
          },
          {
            id: 4,
            min: 0,
            max: 1.50,
            bm: "Cr"
          },
          {
            id: 5,
            min: 0,
            max: 0.99,
            bm: "Ni"
          },
          {
            id: 6,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 7,
            min: 0,
            max: 0.010,
            bm: "S"
          }
        ],
      },
      {
        id: 10,
        value: "T95 Type 1",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.35,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0.25,
            getMin({measurement: thickness}) {
              if(thickness && thickness < 17.78) return 0.15;

              return 0.25;
            },
            max: 0.85,
            bm: "Mo"
          },
          {
            id: 4,
            min: 0.40,
            max: 1.50,
            bm: "Cr"
          },
          {
            id: 5,
            min: 0,
            max: 0.99,
            bm: "Ni"
          },
          {
            id: 6,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 7,
            min: 0,
            max: 0.010,
            bm: "S"
          }],
      },
      {
        id: 11,
        value: "C110",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.35,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.20,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0.25,
            max: 1.00,
            bm: "Mo"
          },
          {
            id: 4,
            min: 0.40,
            max: 1.50,
            bm: "Cr"
          },
          {
            id: 5,
            min: 0,
            max: 0.99,
            bm: "Ni"
          },
          {
            id: 6,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 7,
            min: 0,
            max: 0.005,
            bm: "S"
          }],
      },
      {
        id: 12,
        value: "P110",
        elements: [
          {
            id: 4,
            min: 0,
            max: 0.020,
            maxError: 0.030,
            bm: "P"
          },
          {
            id: 5,
            min: 0,
            max: 0.010,
            maxError: 0.030,
            bm: "S"
          },
        ]
      },
      {
        id: 13,
        value: "Q125 Type 1",
        elements: [
          {
            id: 1,
            min: 0,
            max: 0.35,
            bm: "C"
          },
          {
            id: 2,
            min: 0,
            max: 1.35,
            bm: "Mn"
          },
          {
            id: 3,
            min: 0,
            max: 0.85,
            bm: "Mo"
          },
          {
            id: 4,
            min: 0,
            max: 1.50,
            bm: "Cr"
          },
          {
            id: 5,
            min: 0,
            max: 0.020,
            bm: "P"
          },
          {
            id: 6,
            min: 0,
            max: 0.010,
            bm: "S"
          }],
      },
    ]
  }
];