export default (theme) => ({
  page: {
    padding: theme.spacing(4),
  },
  pageHeadline: {
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  pageTable: {
    width: "100%",
    marginTop: 30,
    overflowX: "auto",
  },
  textField: {
    textAlign: "left",
    marginTop: "5px",
    marginBottom: 0,
  },
  selectPadding: {
    padding: 3
  },
});
