const norms = [
  "ASME V Article 4 (2021)",
  "ASME V Article 5 (2021)",
  "API 5L Table E.7 (46 edition)",
  "ASTM A388 (2019)",
  "ASTM A745 (2020)",
  "ASTM B594 (2019e1)",
  "ASTM E213 (2020)",
  "ASTM A435 (2017)",
  "ASTM A578 (2017)",
  "EN 10160 (1999)",
  "EN 10306 (2001)",
  "EN 10308 (2001)",
  "ISO 10893-8 (2011/Amd1:2020)",
  "ISO 10893-9 (2011/Amd1:2020)",
  "ISO 10893-10 (2011/Amd1:2020)",
  "EN 10228-3 (2016)"
];

const acceptances = {
  "QCP 6916 Rev. 3": {
    company: ["Tenaris (Dalmine S.p.A.)", "TenarisLab", "Turcotte Group", "Tremblay Inc."],
  },
  "ASME VIII Division 2 Paragraph 3.3.4 (2021)": {},
  "API 5L Table E.8 (46 edition)": {},
  "EN 10228-3 Class 1 (2016)": {},
  "EN 10228-3 Class 2 (2016)": {},
  "EN 10228-3 Class 3 (2016)": {},
  "EN 10228-3 Class 4 (2016)": {},
  "ISO 10893-8 Level U0 (2011/Amd1:2020)": {},
  "ISO 10893-8 Level U1 (2011/Amd1:2020)": {},
  "ISO 10893-8 Level U2 (2011/Amd1:2020)": {},
  "ISO 10893-8 Level U3 (2011/Amd1:2020)": {},
  "ASTM A745 QL-1 (2020)": {},
  "ASTM A745 QL-2 (2020)": {},
  "ASTM A745 QL-3 (2020)": {},
  "ASTM A745 QL-4 (2020)": {},
  "ASTM A745 QL-5 (2020)": {},
  "ASTM B594 Class AAA (2019e1)": {},
  "ASTM B594 Class AA (2019e1)": {},
  "ASTM B594 Class A (2019e1)": {},
  "ASTM B594 Class B (2019e1)": {},
  "ASTM B594 Class C (2019e1)": {}
};

const surfaceConditions = ["As rolled", "As forged", "As welded", "Sandblasted", "Machined"];

const techniques = ["Impulse echo - Reflection method", "Through transmission method", "Phased Array"];

const sensitivities = ["Back wall echo", "DAC curve", "AVG diagram"];

export {norms, acceptances, techniques, sensitivities, surfaceConditions};
