export default (theme) => ({
  container: {
    display: "flex",
    flexWrap: "nowrap",
  },
  checkbox: {
    marginRight: theme.spacing(),
    padding: 0,
  },
});
