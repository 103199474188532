export default () => ({
  section: {
    color: "#575757",
    "& p": {
      marginBottom: 5,
    },
  },
  etchingSolution: {
    cursor: "pointer",
  }
});