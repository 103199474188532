export default () => ({
  sideBar: {
    width: "20vw",
    position: "relative",
    flexShrink: 0,
    transition: "left 0.3s ease-in-out",
    left: "20vw"
  },
  sideBarClosedTransitionEnd: {
    height: 1
  },
  sideBarOpen: {
    left: 0,
    height: "auto"
  },
});