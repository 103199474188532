export default (theme) => ({
  container: {
    overflow: "hidden",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  reports: {
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  testReport: {
    marginBottom: theme.spacing(2),
  },
  drawer: {
    flexShrink: 0
  },
});