export default (theme) => ({
  box: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center",
    "& div:first-child": {
      backgroundColor: "#f1f1f1",
      padding: "20px 10px",
      fontWeight: theme.typography.fontWeightMedium,
      color: "#616161",
      fontSize: 15,
    },
    "& div:last-child": {
      color: "#0000008a",
      padding: "20px 10px",
      height: 100,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  container: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    "& div:first-child": {
      borderLeft: "unset",
    }
  },
  item: {
    marginBottom: 5,
  },
  items: {
    borderLeft: "unset",
  }
});