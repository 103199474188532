import {action, makeObservable, observable} from "mobx";
import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class StatementPresetsStore {
  constructor() {
    makeObservable(this, {
      presets: observable,
      isLoaded: observable,
      getPresets: action,
      removePreset: action,
      uploadPreset: action,
      appendFromFile: action,
      createPreset: action,
      updatePreset: action,
    });
  }
  presets = [];
  isLoaded = false;

  async getPresets() {
    this.isLoaded = false;
    const response = await axios.get(ROUTES.STATEMENT_PRESET[ACTIONS.ALL]);

    this.presets = response.data.items;
    this.isLoaded = true;
  }

  async removePreset(id) {
    await axios.delete(ROUTES.STATEMENT_PRESET[ACTIONS.REMOVE](id));

    this.presets = this.presets.filter((preset) => preset._id !== id);
  }

  async uploadPreset(data) {
    const formData = new FormData();

    formData.append("file", data.file);
    formData.append("name", data.name);

    const response = await axios.post(ROUTES.STATEMENT_PRESET[ACTIONS.UPLOAD], formData);

    this.presets = this.presets.concat(response.data);
  }

  async appendFromFile(data) {
    const formData = new FormData();

    formData.append("file", data.file);
    formData.append("name", data.name);

    const response = await axios.patch(ROUTES.STATEMENT_PRESET[ACTIONS.UPLOAD], formData);

    this.presets = this.presets.map((preset) => preset._id === response.data._id ? response.data : preset);
  }

  async createPreset(data) {
    const response = await axios.post(ROUTES.STATEMENT_PRESET[ACTIONS.CREATE], data);

    this.presets = this.presets.concat(response.data);
  }

  async updatePreset(id, statements) {
    const response = await axios.patch(ROUTES.STATEMENT_PRESET[ACTIONS.UPDATE](id), {statements});

    this.presets = this.presets.map((preset) => preset._id === id ? response.data : preset);
  }
}

export default new StatementPresetsStore();
