export default (theme) => {
  const resultText = {
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "uppercase"
  };

  return {
    mainContainer: {
      padding: theme.spacing(4),
      position: "relative",
    },
    utilsMenu: {
      alignItems: "flex-end",
      boxShadow: "none",
      background: "#fff",
      fontSize: 14,
      "& > div": {
        minHeight: "auto"
      }
    },
    main: {
      padding: 20
    },

    menuButton: {
      padding: "15px 20px",
    },

    itemIcon: {
      fontSize: 18,
      display: "flex",
      marginLeft: 10
    },
    titleSection: {
      display: "flex",
      marginBottom: theme.spacing(3),
    },
    satisfaction: {
      ...resultText,
      color: "#4caf50",
    },
    unsatisfaction: {
      ...resultText,
      color: "#D02D52",
    },
    awaiting: {
      ...resultText,
      color: "#d4a237",
    },
    instrumentSection: {
      display: "flex",
      alignItems: "flex-end",
      margin: "20px 0",
      "& > div": {
        minWidth: 400,
      },
      "& button": {
        height: 42,
        marginLeft: 15
      }
    }
  };

};