import React from "react";
import {MenuItem, Button, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import SelectField from "@core/components/FormikSelect";
import {SPEC_TYPES} from "@core/constants/specifications";
import Certificate from "../Certificate";
import Statements from "../Statements";
import Tests from "../Tests";
import TestResults from "../TestResults";
import styles from "./styles";

const FORM_BY_ENTITY = {
  certificate: Certificate,
  test_results: TestResults,
  test: Tests,
  statements: Statements,
};

const SpecificationRow = ({classes, item, values, index, remove, setFieldValue}) => {
  const EntityForm = FORM_BY_ENTITY[item.entity];

  return (
    <Grid container spacing={3} marginBottom={2}>
      <Grid item xs={2}>
        <SelectField
          name={`config.${index}.entity`}
          label='Check'
          required
        >
          {Object.keys(SPEC_TYPES).map((check) => (
            <MenuItem value={check} key={check}>{SPEC_TYPES[check]}</MenuItem>
          ))}
        </SelectField>
      </Grid>
      {item.entity && (
        <EntityForm
          values={values}
          index={index}
          item={item}
          setFieldValue={setFieldValue}
          remove={remove}
        >
          {values.config.length > 1 && (
            <Grid item xs={2} container alignItems="flex-end">
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            </Grid>
          )}
        </EntityForm>
      )}
    </Grid>
  );
};

export default withStyles(SpecificationRow, styles);
