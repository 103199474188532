import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import RouterLink from "@core/components/RouterLink";
import styles from "./styles";
import useStores from "../../../useStores";

const OrderNumber = observer(({order, classes}) => {
  const {UserStore} = useStores();

  const user = UserStore.user.data;

  if (!order) return "-";

  if (!order._id) {
    return (
      <Tooltip title="Closed order">
        <div className={classes.deletedOrder}>
          <Button
            disabled={!order._id}
            component={RouterLink}
            target="_blank"
          >
            {order.orderNumber}
          </Button>
        </div>
      </Tooltip>
    );
  }

  const isSender = order.sender === user.company._id;

  return (
    <Button
      component={RouterLink}
      to={isSender ? `/transfers/${order._id}`: `/orders/${order._id}`}
      target="_blank"
    >
      {order.orderNumber}
    </Button>
  );
});

export default withStyles(OrderNumber, styles);