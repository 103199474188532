import React, {useState, useEffect} from "react";
import {Checkbox, FormControlLabel, Grid, MenuItem} from "@mui/material";
import {useFormikContext} from "formik";
import SelectField from "@core/components/FormikSelect";
import {Input} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import {times} from "ramda";
import EtchantAutocomplete from "../../../../Tests/Test/components/EtchantAutocomplete";
import {MAGNIFICATIONS, ETCHING_STANDARDS, ORIENTATIONS, POSITIONS} from "@core/constants/test";
import {FERRITE_TEST_TYPES} from "./data";

const TestParameters = ({firstUpdate, test}) => {
  const {values, setFieldValue} = useFormikContext();
  const [numberOfFields, setNumberOfFields] = useState(values.elements.length || 30);

  useEffect(() => {
    if (firstUpdate.current && test.properties.type) {
      firstUpdate.current = false;

      return;
    }

    setNumberOfFields(30);

    if (values.type === FERRITE_TEST_TYPES.MANUAL_COUNT) {
      setFieldValue("elements", times(() => "", 30));
      setFieldValue("pointsPerField", 16);
    }

    if (values.type === FERRITE_TEST_TYPES.AUTOMATIC_COUNT) {
      setFieldValue("elements", times(() => "", 30));
    }
  }, [values.type]);

  const onNumberOfFields = (e) => {
    const value = Number(e.target.value);

    const currentNumber = numberOfFields;
    const newNumber = value > 5 ? value : 5;

    const missingElements = newNumber > currentNumber ? times(() => "", newNumber - currentNumber) : [];
    const newElements = values.elements.concat(missingElements);

    setFieldValue("elements", newElements.slice(0, newNumber));

    setNumberOfFields(value || "");
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <h3>Test parameters</h3>
        </Grid>
      </Grid>
      <Grid container spacing={5} alignItems="flex-end">
        <Grid item xs={3}>
          <SelectField
            name="etchingStandard"
            label="Etching standard"
            required
          >
            {ETCHING_STANDARDS.map((standard) => (
              <MenuItem key={standard} value={standard}>{standard}</MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={3}>
          <EtchantAutocomplete
            etchingStandard={values.etchingStandard}
            name="etchingSolution"
            setFieldValue={setFieldValue}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={values.electrolytic}
                onClick={() => setFieldValue("electrolytic", !values.electrolytic)}
                name="electrolytic"
                color="primary"
              />
            )}
            label="Electrolytic"
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <SelectField
            label="Position"
            name="position"
          >
            {POSITIONS.map((position) => (
              <MenuItem key={position} value={position}>{position}</MenuItem>)
            )}
            <MenuItem value={undefined}>N/A</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={3}>
          <SelectField
            label="Orientation"
            name="orientation"
          >
            {ORIENTATIONS.map((orientation) => (
              <MenuItem key={orientation} value={orientation}>{orientation}</MenuItem>)
            )}
            <MenuItem value={undefined}>N/A</MenuItem>)
          </SelectField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label='Specimen ID'
            name="specimenId"
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <SelectField
            required
            label="Magnification"
            name="magnification"
          >
            {MAGNIFICATIONS.map((magnification) => (
              <MenuItem key={magnification} value={magnification}>{magnification}</MenuItem>)
            )}
          </SelectField>
        </Grid>
        <Grid item xs={3}>
          <Input
            inputProps={{min: 5}}
            type="number"
            value={numberOfFields}
            label='Number of fields'
            required
            onChange={onNumberOfFields}
          />
        </Grid>
        {values.type === FERRITE_TEST_TYPES.MANUAL_COUNT && (
          <Grid item xs={3}>
            <TextField
              inputProps={{min: 1}}
              type="number"
              name="pointsPerField"
              label="Points per field"
              required
            />
          </Grid>
        )}
        {values.type === FERRITE_TEST_TYPES.AUTOMATIC_COUNT && (
          <Grid item xs={3}>
            <TextField
              inputProps={{min: 1}}
              type="number"
              name="singleFieldArea"
              label="Single field area"
              required
              endAdornment={<span>µm<sup>2</sup></span>}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TestParameters;