import React, {useEffect, useState} from "react";
import {Dialog, DialogContent, DialogTitle, Grid, Button, MenuItem} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import {observer} from "mobx-react";
import ProjectIdAutocomplete from "@core/components/ProjectIdAutocomplete";
import {MuiAutocomplete, Input, Select} from "@core/components/Form";
import useStores from "../../../../useStores";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import {ORDER_TABS} from "@core/components/TransfersList/data";

const Transfer = observer(({handleSend, transferOpen, transferClose, companies}) => {
  const [addNewOrder, setAddNewOrder] = useState(false);

  const {CertificateStore, TransferStore, UserStore} = useStores();

  const getCertificate = () => CertificateStore.certificate.data;
  const [order] = TransferStore.transfers.data;
  const activeProducers = companies.filter((company) => company.active);

  useEffect(() => {
    const orderNumber = getCertificate().orderNumber;

    if (!orderNumber) {
      return;
    }

    TransferStore.transfers.load({
      search: orderNumber,
      status: ORDER_TABS.OPEN, type: INTERFACE_TYPE.SENDER
    });
  }, []);

  useEffect(() => {
    return () => TransferStore.transfers.reset();
  }, []);

  const initialValues = {
    customer: getCertificate().orderNumber && order ? order.receiver : "",
    transferId: getCertificate().orderNumber && order ? order._id : "",
    orderNumber: getCertificate().orderNumber || "",
    lineItem: getCertificate().lineItem || "",
    projectId: getCertificate().projectId?._id || ""
  };

  const validationSchema = yup.object().shape({
    customer: yup.object().required("Customer is required!"),
    orderNumber: yup.string().required("Purchase Order is required!"),
    lineItem: yup.string(),
    projectId: yup.string(),
    transferId: yup.string()
  });

  const addToOrder = async ({transferId, projectId, lineItem}) => {
    const certificate = {
      _id: CertificateStore.certificate.data._id,
      projectId,
      lineItem
    };
    await CertificateStore.addCertificateToOutgoingOrder(transferId, [certificate]);
  };

  const createOrder = async (values) => {
    await TransferStore.createTransfer({
      receiver: values.customer._id,
      sender: UserStore.user.data.company._id,
      orderNumber: values.orderNumber
    });

    await addToOrder({
      transferId: TransferStore.transfer._id,
      projectId: values.projectId,
      lineItem: values.lineItem
    });

    handleSend({...values, transferId: TransferStore.transfer._id});
    setAddNewOrder(false);
  };

  const addToExistingOrder = async (values) => {
    await addToOrder(values);
    handleSend(values);
  };

  const manageOrder = async (values) => {
    if (addNewOrder) await createOrder(values);
    else await addToExistingOrder(values);
  };

  return (
    <Dialog
      open={transferOpen}
      onClose={() => {
        transferClose();
        setAddNewOrder(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Transfer of ownership</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={getCertificate().orderNumber ? handleSend : manageOrder}

          render={
            ({
              values,
              errors,
              handleSubmit,
              handleChange,
              touched,
              isValid,
              setFieldTouched,
              setValues
            }) => {

              const change = (name, e) => {
                e?.persist && e.persist();
                handleChange(e);
                setFieldTouched(name, true, false);
              };

              return (
                <>
                  <Grid container spacing={3}>
                    {getCertificate().orderNumber ? (
                      <Grid item xs={12}>
                        <Input
                          disabled={getCertificate().orderNumber}
                          label="PO Number"
                          name="orderNumber"
                          value={values.orderNumber}
                          required
                          error={Boolean(errors.orderNumber) && touched.orderNumber}
                          errorMessage={errors.orderNumber}
                          onChange={(e) => change("orderNumber", e)}
                        />
                      </Grid>
                    ) : (
                      <>
                        {!addNewOrder && (
                          <Grid xs={12} item>
                            <MuiAutocomplete
                              url={`${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open&type=sender`}
                              inputProps={{
                                label: "Customer order",
                                name: "orderNumber",
                                required: true
                              }}
                              formatOptions={(data) => data.items.map((item) => ({
                                label: item.orderNumber,
                                value: item
                              }))}
                              onCreateNew={() => setAddNewOrder(true)}
                              onChange={({orderNumber, receiver, _id}) => {
                                setValues(() => ({...values, orderNumber, customer: receiver, transferId: _id}));
                              }}
                            />
                          </Grid>
                        )}
                        {addNewOrder && (
                          <>
                            <Grid item xs={6}>
                              <Select
                                value={values.customer}
                                name="customer"
                                label="Customer"
                                required
                                error={Boolean(errors.customer) && touched.customer}
                                onChange={(event) => change("customer", event)}
                              >
                                {activeProducers.map((company) => (
                                  <MenuItem key={company._id} value={company}>{company.name}</MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="PO Number"
                                name="orderNumber"
                                value={values.orderNumber}
                                required
                                error={Boolean(errors.orderNumber) && touched.orderNumber}
                                errorMessage={errors.orderNumber}
                                onChange={(e) => change("orderNumber", e)}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    <Grid item xs={6}>
                      <Input
                        label="PO item"
                        name="lineItem"
                        value={values.lineItem}
                        error={Boolean(errors.lineItem) && touched.lineItem}
                        errorMessage={errors.lineItem}
                        onChange={(e) => change("lineItem", e)} />
                    </Grid>
                    <Grid item xs={6}>
                      <ProjectIdAutocomplete
                        name="projectId"
                        label="Project ID"
                      />
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                      <Grid item>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={!isValid}
                          onClick={handleSubmit}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            }}
        />
      </DialogContent>
    </Dialog>
  );
});

export default Transfer;
