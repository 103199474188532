import React, {useState, useEffect} from "react";
import axios from "axios";
import {withStyles} from "tss-react/mui";
import ImageZoom from "react-medium-image-zoom";
import {api} from "Config";
import "react-medium-image-zoom/dist/styles.css";
import styles from "./styles";

const ImageWithZoom = ({classes, src, alt}) => {
  const [image, setImage] = useState(null);
    
  useEffect(() => {
    axios.get(`${api}/uploads/download${src}`).then((res) => setImage(res.data));
  }, [src]);

  return (
    <ImageZoom>
      <img
        onClick={(e) => e.stopPropagation()}
        alt={alt}
        src={`data:;base64,${image}`}
        className={classes.photo}
      />
    </ImageZoom>
  );
};

export default withStyles(ImageWithZoom, styles);