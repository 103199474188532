import axios from "axios";
import {api} from "Config";
import {base64ToBlob} from "@core/helpers/file";

const openFileInNewTab = (file) => {
  axios.get(`${api}/uploads/download${file}`).then((res) => {
    const blob = base64ToBlob(res.data);
    const fileURL = URL.createObjectURL(blob);
    const fileWindow = window.open();
    fileWindow.location.href = fileURL;
  });
};

export {openFileInNewTab};

