import React, {useState, useEffect} from "react";
import {isEmpty} from "ramda";
import {Button, Dialog, DialogContent, DialogActions, DialogTitle} from "@mui/material";
import {withStyles} from "tss-react/mui";
import TestsListTable from "@core/components/TestsListTable";
import NewTestsForm from "../NewTestsForm";
import styles from "./styles";

const TestsList = ({onSuccess, onClose, types}) => (
  <>
    <DialogTitle>Add test or declaration</DialogTitle>
    <DialogContent>
      <NewTestsForm
        onSuccess={onSuccess}
        onClose={onClose}
        types={types}
      />
    </DialogContent>
  </>
);

const TestsTypesList = ({classes, types, setTypes, setTestsTypesSelected}) => (
  <>
    <DialogContent>
      <TestsListTable
        types={types}
        setTypes={setTypes}
      />
    </DialogContent>
    <DialogActions classes={{root: classes.dialogActions}}>
      <Button
        onClick={() => setTestsTypesSelected(true)}
        color="primary"
        variant="contained"
        disabled={isEmpty(types)}
      >
        Next
      </Button>
    </DialogActions>
  </>
);

const StyledTestTypesList = withStyles(TestsTypesList, styles);

const AddTestsForm = ({onSuccess, classes}) => {
  const [testsTypesSelected, setTestsTypesSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState({});

  useEffect(() => {
    if (open) return;

    setTypes({});
    setTestsTypesSelected(false);
  }, [open]);

  const Component = testsTypesSelected ? TestsList : StyledTestTypesList;

  return (
    <>
      <Button
        color="primary"
        size="medium"
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Add test / Declaration
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTypes({});
        }}
        maxWidth="md"
        fullWidth
        classes={{paperWidthMd: classes.paperWidth}}
      >
        <Component
          types={types}
          setTypes={setTypes}
          setTestsTypesSelected={setTestsTypesSelected}
          onSuccess={onSuccess}
          onClose={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default withStyles(AddTestsForm, styles);
