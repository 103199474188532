import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {isEmpty, filter} from "ramda";
import {Formik} from "formik";
import TextField from "@core/components/FormikTextField";
import {Dialog, DialogTitle, DialogContent, Button, Grid} from "@mui/material";

const CustomException = ({isHotTensileTest, requirements, onChange}) => {
  const [open, setOpen] = useState(false);
  const [defaultRequirements, setDefaultRequirements] = useState({});

  useEffect(() => {
    if (!isEmpty(defaultRequirements)) return;

    setDefaultRequirements(requirements);
  }, [open]);

  const validationSchema = yup.object().shape({
    yeldMin: defaultRequirements.yeldMin ?
      yup.number().min(defaultRequirements.yeldMin, `Should be \u2265 ${defaultRequirements.yeldMin}`).required() :
      yup.number(),
    yeldMax: defaultRequirements.yeldMax ?
      yup.number().max(defaultRequirements.yeldMax, `Should be \u2264 ${defaultRequirements.yeldMax}`).required() :
      yup.number(),
    tensMin: defaultRequirements.tensMin ?
      yup.number().min(defaultRequirements.tensMin, `Should be \u2265 ${defaultRequirements.tensMin}`).required() :
      yup.number(),
    tensMax: defaultRequirements.tensMax ?
      yup.number().max(defaultRequirements.tensMax, `Should be \u2264 ${defaultRequirements.tensMax}`).required() :
      yup.number(),
    elongation: defaultRequirements.elongation ?
      yup.number().min(defaultRequirements.elongation, `Should be \u2265 ${defaultRequirements.elongation}`).required() :
      yup.number(),
    reductionOfArea: defaultRequirements.reductionOfArea ?
      yup.number().min(defaultRequirements.reductionOfArea, `Should be \u2265 ${defaultRequirements.reductionOfArea}`).required() :
      yup.number(),
    yeildTensileRatio: defaultRequirements.yeildTensileRatio ?
      yup.number().max(defaultRequirements.yeildTensileRatio, `Should be \u2264 ${defaultRequirements.yeildTensileRatio}`).required() :
      yup.number(),
    uniformElongationMin: defaultRequirements.uniformElongationMin ?
      yup.number().min(defaultRequirements.uniformElongationMin, `Should be \u2265 ${defaultRequirements.uniformElongationMin}`).required() :
      yup.number(),
  });

  const hotTensileTestValidationSchema = yup.object().shape({
    yeldMin: yup.number().min(0, "Should be \u2265 0"),
    yeldMax: defaultRequirements.yeldMax ?
      yup.number().max(defaultRequirements.yeldMax, `Should be \u2264 ${defaultRequirements.yeldMax}`) :
      yup.number(),
    tensMin: yup.number().min(0, "Should be \u2265 0"),
    tensMax: defaultRequirements.tensMax ?
      yup.number().max(defaultRequirements.tensMax, `Should be \u2264 ${defaultRequirements.tensMax}`) :
      yup.number(),
    elongation: yup.number().min(0, "Should be \u2265 0"),
    reductionOfArea: yup.number().min(0, "Should be \u2265 0"),
    yeildTensileRatio: defaultRequirements.yeildTensileRatio ?
      yup.number().max(defaultRequirements.yeildTensileRatio, `Should be \u2264 ${defaultRequirements.yeildTensileRatio}`) :
      yup.number(),
    uniformElongationMin: yup.number().min(0, "Should be \u2265 0"),
  });

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Set exceptions
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          Set custom requirements
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            validationSchema={isHotTensileTest ? hotTensileTestValidationSchema : validationSchema}
            initialValues={filter((requirement) => Boolean(requirement), requirements)}
            onSubmit={(values) => {
              onChange(values);
              setOpen(false);
            }}
          >
            {(props) => (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    required={!isHotTensileTest && defaultRequirements.yeldMin}
                    name="yeldMin"
                    placeholder={!!requirements.yeldMin && requirements.yeldMin}
                    type="number"
                    label="Min yield strength [MPa]"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required={!!defaultRequirements.yeldMax}
                    name="yeldMax"
                    placeholder={!!requirements.yeldMax && requirements.yeldMax}
                    type="number"
                    label="Max yield strength [MPa]"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required={!isHotTensileTest && defaultRequirements.tensMin}
                    name="tensMin"
                    placeholder={!!requirements.tensMin && requirements.tensMin}
                    type="number"
                    label="Min tensile strength [MPa]"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required={!!defaultRequirements.tensMax}
                    name="tensMax"
                    placeholder={!!requirements.tensMax && requirements.tensMax}
                    type="number"
                    label="Max tensile strength [MPa]"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required={!isHotTensileTest && defaultRequirements.elongation}
                    name="elongation"
                    placeholder={!!requirements.elongation && requirements.elongation}
                    type="number"
                    label="Min elongation"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required={!isHotTensileTest && defaultRequirements.reductionOfArea}
                    name="reductionOfArea"
                    placeholder={!!requirements.reductionOfArea && requirements.reductionOfArea}
                    type="number"
                    label="Min reduction of area"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required={!!defaultRequirements.yeildTensileRatio}
                    name="yeildTensileRatio"
                    placeholder={!!requirements.yeildTensileRatio && requirements.yeildTensileRatio}
                    type="number"
                    label="Max yield/tensile strength ratio"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required={!isHotTensileTest && defaultRequirements.uniformElongationMin}
                    name="uniformElongationMin"
                    placeholder={!!requirements.uniformElongationMin && requirements.uniformElongationMin}
                    type="number"
                    label="Min uniform elongation"
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      disabled={!props.isValid}
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={props.handleSubmit}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomException;
