import React from "react";
import {Grid} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import {Input} from "@core/components/Form";
import ProducerTestFooter from "@core/components/ProducerTestFooter";

const NotesTest = (props) => {

  const initialValues = {
    description: props.test.properties.description || "",
    witnesses: props.test.witnesses.map((witness) => witness.company) || [],
    inspectionDate: props.test.inspectionDate || "",
    inspectorJobNumber: props.test.inspectorJobNumber || "",
    tags: props.test.properties.tags || []
  };

  const validationSchema = yup.object().shape({
    description: yup.string().required("Description required"),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
  });

  const onSubmit = (values, actions) => {
    const data = {
      description: values.description,
      inspectionDate: values.inspectionDate,
      inspectorJobNumber: values.inspectorJobNumber,
      tags: values.tags,
    };
    props.saveTest(data, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    if (props.closeNewTest) {
      props.closeNewTest();
    }
  };

  return (
    <div>
      <Formik
        innerRef={props.formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}

        render={(props) => {
          const {
            values: {description},
            errors,
            handleChange,
            touched,
            setFieldTouched,
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          return (
            <>
              <h1>Notes</h1>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    label='Description'
                    name='description'
                    required
                    value={description}
                    error={Boolean(errors.description) && touched.description}
                    errorMessage={errors.description}
                    onChange={(e) => change("description", e)}
                    multiline
                    rows={5}
                  />
                </Grid>
                <ProducerTestFooter />
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
};

export default NotesTest;
