import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {STATUSES} from "@core/constants/test";
import DashboardTable from "../Table";
import {columns} from "./columns";

const DashboardWitness = () => {
  return (
    <Box sx={{padding: 4}}>
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardTable
            title="Waiting for attestation"
            noFoundText="No tests found."
            type="tests"
            columns={columns}
            params={{statuses: [STATUSES.INSPECTING]}}
            onRowClick={(test) => window.open(`/witness/${test._id}`, "_self")}
          />
        </Grid>
        <Grid item xs={12}>
          <DashboardTable
            title="Attested"
            noFoundText="No tests found."
            type="tests"
            columns={columns}
            params={{statuses: [STATUSES.INSPECTED, STATUSES.APPROVED]}}
            onRowClick={(test) => window.open(`/witness/${test._id}`, "_self")}
          />
        </Grid>
      </Grid>
    </Box>
  );

};

export default DashboardWitness;