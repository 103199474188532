import {times} from "ramda";
export const TEST_STANDARDS = ["ASTM G76 (2018)"];
export const TEST_CONDITIONS = ["As per standard", "Modified"];

export const DEFAULT_TEST_RESULTS_AMOUNT = 4;

export const TEST_RESULT = {
  specimen: "",
  hardness: undefined,
  density: undefined,
  massLoss: undefined,
  volumeLoss: 0,
  erosionValue: undefined,
};

export const DEFAULT_TEST_RESULTS = times(() => TEST_RESULT, DEFAULT_TEST_RESULTS_AMOUNT);
