const testStandards = ["ASTM A275 (2018)", "ASTM E709 (2021)", "ASTM E709 (2015)", "ASME (2021) Section V Article 7", "ISO 10893-5 (2011)", "EN 10228-1 (2016)"];
const acceptances = ["ASME (2021) Section VIII, Division 1 - Appendix 6", "ASME (2017) Section VIII, Division 1 - Appendix 6", "API 5L (46th edition) Annex E.6", "EN 10228-1 (2016) Class 1", "EN 10228-1 (2016) Class 2", "EN 10228-1 (2016) Class 3", "EN 10228-1 (2016) Class 4", "QCP 6916 Rev. 3"];
const conditions = ["Black light intensity at the examined surface > 1000 µW/cm2, and visible light in the darkened area < 20 lux", "Visible light intensity at the examined surface > 1076 lux"];
const currentTypes = ["AC", "DC", "HWRC"];
const particleTypes = ["Dry Powder", "Wet particles"];
const particleVisibilities = ["Visible", "Fluorescent"];
const solutions = ["Water", "Oil"];
const concentrations = ["1.2-2.4 mL per 100 mL of liquid vehicle", "0.2-0.4 mL per 100 mL of liquid vehicle"];
const cleanings = ["Cleaning with solvent", "Pickling", "Machining", "Grinding", "N/A"];
const demagnetizations = ["Not required", "Performed"];
const examinedSurfaces = ["100% of surface", "Bevel ends", "External surface", "Internal surface", "Welds", "Square cut surface"];

export {testStandards, acceptances, conditions, currentTypes, particleTypes, particleVisibilities, solutions, concentrations, cleanings, demagnetizations, examinedSurfaces};
