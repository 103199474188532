import {action, makeObservable, observable} from "mobx";
import {ORDERS} from "@core/constants/sort";

export class TableStore {

  #loader;
  #initialFetch;

  constructor({loader, fetch = {}}) {
    if (loader && typeof loader !== "function") {
      throw new Error("Loader is not a function");
    }

    this.#loader = loader;
    this.#initialFetch = {...TableStore.DEFAULT_FETCH, ...fetch};
    this.setFetch(this.#initialFetch);
    
    makeObservable(this, {
      data: observable,
      selected: observable,
      total: observable,
      status: observable,
      fetch: observable,
      columns: observable,
      setFetch: action,
      setSelected: action,
      setSort: action,
      load: action,
      reset: action,
      setColumns: action,
    });
  }
  
  data = [];

  selected = [];

  total = 0;

  status = {
    loaded: false,
    loading: false
  };
    
  fetch;
    
  columns = [];
    
  setFetch(fetch, merge = true) {
    if (merge) {
      this.fetch = {...this.fetch, ...fetch};
    } else {
      this.fetch = fetch;
    }
  }
  
  setSelected(selected) {
    if (!selected) {
      return;
    }

    if (!Array.isArray(selected)) {
      throw new Error("Selected must be an array");
    }

    this.selected = selected;
  }
  
  setSort(s) {
    this.sort = s;
  }
  
  async load(params = {}) {
    if(this.status.loading) return null;
    
    if (this.#loader) {
      this.status = {loaded: false, loading: true};
      const response = await this.#loader(params);
      this.data = response.data.items || [];
      this.total = response.data.total || 0;
      this.status = {loaded: true, loading: false};

      return response;
    }

    return null;
  }
  
  reset(params = {}) {
    const {fetch, selected, shouldMerge = true} = params;
    this.setFetch(fetch || this.#initialFetch, shouldMerge);
    this.status = {
      loaded: false,
      loading: false
    };
    this.setSelected(selected || []);
  }
  
  setColumns(cols) {
    this.columns = cols;
  }

  static DEFAULT_FETCH = {
    search: "",
    limit: 10,
    offset: 0,
    sort: {orderBy: "_id", order: ORDERS.DESC}
  };
}
