export default () => ({
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between"
  },
  content: {
    borderRadius: 4,
    backgroundColor: "#fff",
    width: "100%",
    transition: "margin-right 0.3s ease-in-out",
    overflow: "auto",
    borderRight: "1px solid #e0e0e0",
    marginRight: "-20vw",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  contentSmall: {
    marginRight: "initial",
  },
  contentBig: {
    marginRight: "-20vw",
  },
});

