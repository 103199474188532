export default () => ({
  btnAddCell: {
    textAlign: "center"
  },

  btnAdd: {
    margin: "0 auto",
    width: "50%"
  },

  gridRoot: {
    marginTop: 20,
    marginBottom: 20
  },

  formControl: {
    display: "block",
    width: "100%"
  },

  tableCell: {
    paddingRight: "0px !important"
  },

  identificationTitle: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  },

  identificationTitle__item: {
    marginTop: "30px !important",
  },

  inspectionTitle: {
    marginTop: "40px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  },

  saveButton: {
    marginLeft: "auto",
  }
});

