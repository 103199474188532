export default (theme) => ({
  approveButton: {
    backgroundColor: theme.palette.success.main,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#388e3c"
    }
  },
  button: {
    marginLeft: 24,
  }
});