import Text from "@core/components/ProgressBar/components/Text";
import React, {useMemo, useState} from "react";
import {sort, descend, prop} from "ramda";
import {withStyles} from "tss-react/mui";
import {DEFAULT_ALL_NUMBER, PROGRESS_BAR_LABELS_BY_STATUS} from "@core/constants/progressBar";
import StackedProgressBar from "./components/StackedProgressBar";
import ProgressBar from "./components/ProgressBar";
import styles from "./styles";

const Progress = ({classes, data, ...props}) => {
  const [activeItem, setActiveItem] = useState(null);

  const allNumber = props.allNumber || DEFAULT_ALL_NUMBER;
  
  const defaultItem = useMemo(() => {
    const sorted = sort(descend(prop("number")), data);

    const [firstItem, secondItem] = sorted;
    const {number, title} = firstItem;

    if(title === PROGRESS_BAR_LABELS_BY_STATUS.COMPLETE) {
      return number === allNumber ? firstItem : secondItem;
    }

    return firstItem;
  }, [allNumber]);

  return (
    <div className={classes.container}>
      <Text
        withPercentage={Boolean(activeItem)}
        activeItem={activeItem || defaultItem}
        allNumber={allNumber}
      />
      <div className={classes.chart}>
        {data.length === 1 ? (
          <ProgressBar
            item={data[0]}
            allNumber={allNumber}
            setActiveItem={setActiveItem}
          />
        ) : (
          <StackedProgressBar
            data={data}
            allNumber={allNumber}
            setActiveItem={setActiveItem}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(Progress, styles);
