import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {
  IconButton,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Grid,
  FormGroup,
  DialogTitle,
  DialogContent,
  Dialog,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {map, prop, head, sort, ascend} from "ramda";
import styles from "./styles";

const ColumnsSelect = ({classes, match, location, columns, ...props}) => {
  const existingColumns = props.selectedColumns.filter((dataIndex) => columns.find((c) => c.dataIndex === dataIndex));
  const [selectedColumns, setSelectedColumns] = useState(existingColumns);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const permanentColumns = columns.filter((column) => column.permanent);
    const notSelectedPermanentColumns = permanentColumns.filter((column) => !selectedColumns.find((dataIndex) => dataIndex === column.dataIndex));

    if (!notSelectedPermanentColumns.length) return;

    const notSelectedDataIndices = notSelectedPermanentColumns.map((column) => column.dataIndex);

    const newSelectedColumns = notSelectedDataIndices.concat(selectedColumns);

    props.setSelectedColumns(newSelectedColumns);
    setSelectedColumns(newSelectedColumns);
    localStorage.setItem(match.path, JSON.stringify(newSelectedColumns));
  }, [location.key]);

  const onAllCheckboxClick = () => {
    if (selectedColumns.length === columns.length) {
      setSelectedColumns([head(columns).dataIndex]);
    } else {
      setSelectedColumns(map(prop("dataIndex"), columns));
    }
  };

  const onCheckboxClick = (dataIndex) => {
    const isColumnSelected = selectedColumns.includes(dataIndex);

    if (isColumnSelected) {
      setSelectedColumns(selectedColumns.filter((column) => column !== dataIndex));
    } else {
      setSelectedColumns(selectedColumns.concat(dataIndex));
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const columns = Array.from(selectedColumns);
    const [removed] = columns.splice(result.source.index, 1);
    columns.splice(result.destination.index, 0, removed);
    setSelectedColumns(columns);
  };

  const withoutPermanent = columns.filter((column) => !column.permanent);
  const sortedColumns = sort(ascend(prop("title")), withoutPermanent);

  const onCancel = () => {
    setOpen(false);
  };

  return <>
    <Tooltip title="Column settings" placement="left">
      <IconButton
        color="primary"
        className={classes.button}
        onClick={() => setOpen(true)}
        size="large">
        <SettingsIcon />
      </IconButton>
    </Tooltip>
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onCancel}
      TransitionProps={{
        onExited: () => setSelectedColumns(props.selectedColumns)
      }}
    >
      <DialogTitle classes={{root: classes.title}}>
        <Grid container>
          <Grid item xs={6} container justifyContent="center">Available columns</Grid>
          <Grid item xs={6} container justifyContent="center">
            <Grid item xs={12} container justifyContent="center">Selected columns</Grid>
            <Grid item xs={12} container justifyContent="center">
              <Typography>(Click and drag to arrange order)</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={onAllCheckboxClick}
                  checked={selectedColumns.length === columns.length}
                  indeterminate={!!selectedColumns.length && selectedColumns.length < columns.length}
                  color="primary"
                />
              )}
              label="Toggle all"
            />
          </Grid>
          <Grid item xs={6} container direction="row">
            <FormGroup>
              {sortedColumns.map((column) => (
                <FormControlLabel
                  key={column.dataIndex}
                  control={(
                    <Checkbox
                      disabled={selectedColumns.length === 1 && selectedColumns.includes(column.dataIndex)}
                      color="primary"
                      onClick={() => onCheckboxClick(column.dataIndex)}
                      checked={selectedColumns.includes(column.dataIndex)}
                    />
                  )}
                  label={column.title}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className={classes.droppableContainer}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {selectedColumns.map((dataIndex, index) => {
                      const column = columns.find((column) => column.dataIndex === dataIndex);

                      if(!column) return null;

                      return (
                        <Draggable key={dataIndex} draggableId={dataIndex} index={index}>
                          {(provided) => (
                            <div
                              className={classes.item}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{...provided.draggableProps.style, cursor: "pointer"}}
                            >
                              {column.title}
                              {!column.permanent && (
                                <IconButton
                                  className={classes.closeIcon}
                                  onClick={() => onCheckboxClick(dataIndex)}
                                  size="large">
                                  <CloseIcon />
                                </IconButton>
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions classes={{root: classes.buttonsContainer}}>
        <Button
          onClick={onCancel}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            localStorage.setItem(match.path, JSON.stringify(selectedColumns));
            props.setSelectedColumns(selectedColumns);
            setOpen(false);
          }}
          color="primary"
          variant="contained"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default withRouter(withStyles(ColumnsSelect, styles));
