import {prop, uniqBy} from "ramda";
import moment from "moment";
import importedColumns from "@core/columns/test";
import {STATUSES} from "@core/constants/test";
import {DATE_FORMAT} from "@core/constants/dateFormats";

const TABS = {
  AVAILABLE_TO_SIGN: "available",
  NOT_AVAILABLE_TO_SIGN: "not_available",
};

const TABS_TITLES = {
  [TABS.AVAILABLE_TO_SIGN]: "available to sign",
  [TABS.NOT_AVAILABLE_TO_SIGN]: "not available to sign"
};

const columns = uniqBy(prop("dataIndex"),[
  {
    dataIndex: "inspectionDate",
    title: "Inspection date",
    render: (test) => {
      const attestTransaction = test.transactions.find((transaction) => transaction.status === STATUSES.INSPECTED);

      if (attestTransaction) return moment(attestTransaction.date_created).format(DATE_FORMAT);

      if (test.inspectionDate) return moment(test.inspectionDate).format(DATE_FORMAT);

      return "-";
    }
  },
  ...importedColumns,
]);

export {columns, TABS, TABS_TITLES};
