import {PO_ITEMS} from "@core/constants/poItems";
import {ZONES} from "@core/constants/test";
const exceptionsConfig = {
  "QCP 7928 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "270",
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 6916 Rev. 3 - Item 1": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "235",
        AvgMin: "",
        AvgMax: "230"
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 6916 Rev. 3 - Item 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "250"
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 6916 Rev. 3 - (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "250",
        AvgMin: "",
        AvgMax: ""
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 5987 Rev. 2": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    TestType: {
      POItem: {
        [PO_ITEMS.PO_ITEM_1]: {
          HV10: {
            Dmin: "",
            Dmax: "235",
            AvgMin: "",
            AvgMax: "230"
          }
        },
        [PO_ITEMS.PO_ITEM_2]: {
          HV10: {
            Dmin: "",
            Dmax: "245",
          }
        },
      },
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 5983 Rev. 5": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "250",
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "HS-D-ITP-22-02-02 Rev. 5": {
    company: ["HusteelLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "200"
      }
    },
    Amount: "33",
    TestZoneAmount: "5",
    TestZones: [{
      name: ZONES.WELD_CENTRAL_LINE,
      values: 3
    }, {
      name: `${ZONES.HAZ} - 1`,
      values: 3
    }, {
      name: `${ZONES.HAZ} - 2`,
      values: 3
    }, {
      name: `${ZONES.BASE_METAL} - 1`,
      values: 12
    }, {
      name: `${ZONES.BASE_METAL} - 2`,
      values: 12
    }]
  },
  "HS-D-ITP-23-01-06 Rev. 3": {
    company: ["HusteelLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "200"
      },
      "HV0.5": {
        Dmin: "",
        Dmax: "200"
      }
    },
    Amount: "33",
    TestZoneAmount: "5",
    TestZones: [{
      name: ZONES.WELD_CENTRAL_LINE,
      values: 3
    }, {
      name: `${ZONES.HAZ} - 1`,
      values: 3
    }, {
      name: `${ZONES.HAZ} - 2`,
      values: 3
    }, {
      name: `${ZONES.BASE_METAL} - 1`,
      values: 12
    }, {
      name: `${ZONES.BASE_METAL} - 2`,
      values: 12
    }]
  },
  "FP-ITP Rev. 0": {
    company: ["Tremblay Inc.", "Mechan Lab"],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "200"
      }
    },
    Amount: "33",
    TestZoneAmount: "5",
    TestZones: [{
      name: ZONES.WELD_CENTRAL_LINE,
      values: 3
    }, {
      name: `${ZONES.HAZ} - 1`,
      values: 3
    }, {
      name: `${ZONES.HAZ} - 2`,
      values: 3
    }, {
      name: `${ZONES.BASE_METAL} - 1`,
      values: 12
    }, {
      name: `${ZONES.BASE_METAL} - 2`,
      values: 12
    }]
  },
  "FX ITP 2537 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "230",
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "MTIP WA0058-4400025974-H06-44004 Rev. 6": {
    company: ["SumitomoLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "240",
        AvgMin: "",
        AvgMax: "230"
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 8263 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "250",
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 8252 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "240",
        AvgMin: "",
        AvgMax: "230"
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 8251 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "240",
        AvgMin: "",
        AvgMax: "230"
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 8262 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    TestType: {
      POItem: {
        [PO_ITEMS.PO_ITEM_1]: {
          HV10: {
            Dmin: "",
            Dmax: "235",
            AvgMin: "",
            AvgMax: "220"
          }  
        },
        [PO_ITEMS.PO_ITEM_2]: {
          HV10: {
            Dmin: "",
            Dmax: "235",
            AvgMin: "",
            AvgMax: "220"
          }
        },
        [PO_ITEMS.PO_ITEM_3]: {
          HV10: {
            Dmin: "",
            Dmax: "250",
          }
        }
      },
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 8252 Rev. 6 - (After reeling)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "270",
        AvgMin: "",
        AvgMax: ""
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 8251 Rev. 6 - (After reeling)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "270",
        AvgMin: "",
        AvgMax: ""
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "ITP-BPM-22.20 Rev.6": {
    company: ["Tremblay Inc.", "Berg Pipe"],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "275",
        AvgMin: "",
        AvgMax: ""
      }
    },
    Amount: "33",
    TestZoneAmount: "12",
    TestZones: [{
      name: ZONES.BASE_METAL,
      values: 1,
      HV10: {
        Dmax: 245,
      }
    }, {
      name: ZONES.HAZ,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.WELD_CENTRAL_LINE,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.HAZ,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.BASE_METAL,
      values: 2,
      HV10: {
        Dmax: 245,
      }
    }, {
      name: ZONES.HAZ,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.WELD_CENTRAL_LINE,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.HAZ,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.BASE_METAL,
      values: 2,
      HV10: {
        Dmax: 245,
      }
    }, {
      name: ZONES.HAZ,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.WELD_CENTRAL_LINE,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.HAZ,
      values: 3,
      HV10: {
        Dmax: 275,
      }
    }, {
      name: ZONES.BASE_METAL,
      values: 1,
      HV10: {
        Dmax: 245,
      }
    }]
  },
  get "ITP-BPM-22.20 Rev.6 MPQT"() {
    return this["ITP-BPM-22.20 Rev.6"];
  },
  "ITP 011/23/124277 Rev. 05": {
    company: ["Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: ""
      }
    },
    Amount: "40",
    TestZones: [{
      name: ZONES.WELD_OVERLAY,
      values: 7,
      HV10: {
        Dmax: 325,
      }
    }, {
      name: ZONES.BASE_METAL,
      values: 30,
      HV10: {
        Dmax: 250,
      }
    }, {
      name: "CRA Liner",
      values: 3,
      HV10: {
        AvgMax: 248
      }
    }]
  },
  get "ITP 011/23/124277 Rev. 05 MPQT"() {
    return this["ITP 011/23/124277 Rev. 05"];
  },
  "QCP 5513 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "235",
        AvgMin: "",
        AvgMax: "230"
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 5513 Rev. 2 (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "250",
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "250",
      }
    },
    Amount: "48",
    TestZoneAmount: "12",
    TestZones: [{
      name: "Q1 - ID",
      values: 4
    }, {
      name: "Q1 - MW",
      values: 4
    }, {
      name: "Q1 - OD",
      values: 4
    }, {
      name: "Q2 - ID",
      values: 4
    }, {
      name: "Q2 - MW",
      values: 4
    }, {
      name: "Q2 - OD",
      values: 4
    }, {
      name: "Q3 - ID",
      values: 4
    }, {
      name: "Q3 - MW",
      values: 4
    }, {
      name: "Q3 - OD",
      values: 4
    }, {
      name: "Q4 - ID",
      values: 4
    }, {
      name: "Q4 - MW",
      values: 4
    }, {
      name: "Q4 - OD",
      values: 4
    }],
  },
  "CPW-ITP-24-23-SAWL Rev. 3": {
    company: ["Tremblay Inc."],
    TestType: {
      HV10: {
        Dmin: "",
        Dmax: "270"
      }
    },
    Amount: "33",
    TestZones: [{
      name: ZONES.BASE_METAL,
      values: 1
    }, {
      name: ZONES.HAZ,
      values: 3
    }, {
      name: ZONES.WELD_METAL,
      values: 3
    }, {
      name: ZONES.HAZ,
      values: 3
    }, {
      name: ZONES.BASE_METAL,
      values: 2
    }, {
      name: ZONES.HAZ,
      values: 3
    }, {
      name: ZONES.WELD_METAL,
      values: 3
    }, {
      name: ZONES.HAZ,
      values: 3
    }, {
      name: ZONES.BASE_METAL,
      values: 2
    }, {
      name: ZONES.HAZ,
      values: 3
    }, {
      name: ZONES.WELD_METAL,
      values: 3
    }, {
      name: ZONES.HAZ,
      values: 3
    }, {
      name: ZONES.BASE_METAL,
      values: 1
    }]
  }
};

export {exceptionsConfig};
