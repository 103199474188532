import React from "react";
import {ThemeProvider} from "@mui/material/styles";
import {Box, CssBaseline, Typography} from "@mui/material";
import {theme} from "./constant";

function UptimeLikeFormLayout({title, formRows = [], formFooter}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Box sx={{
        maxWidth: "1248px",
        paddingX: 3,
        marginX: "auto"
      }}>
        <Typography variant="h4" gutterBottom marginTop={2}>{title}</Typography>
        <Box marginTop={6}>
          {formRows.map(({sectionDescription, sectionForm}) => (
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 6
            }}>
              <Box sx={{
                maxWidth: 500,
                paddingRight: (theme) => theme.spacing(9)
              }}>
                <Typography variant="h5" gutterBottom>{sectionDescription?.title}</Typography>
                <Typography variant="body2" color="textSecondary" marginTop={3} gutterBottom>
                  {sectionDescription?.text}
                </Typography>
              </Box>
              <Box sx={{
                flexGrow: 1,
                maxWidth: 700,
                backgroundColor: "#FFFFFF",
                border: "1px solid #efefef",
                borderRadius: 2,
                paddingX: 3,
                paddingY: 4
              }}>
                {sectionForm}
              </Box>
            </Box>
          ))}
        </Box>
        <Box marginY={6}>
          {formFooter}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default UptimeLikeFormLayout;