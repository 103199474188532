export default (theme) => ({
  input: {
    padding: theme.spacing(0.5),
    minWidth: 100,
  },
  inputRoot: {
    marginRight: theme.spacing(0.5),
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      cursor: "pointer",
    }
  },
  icon: {
    padding: 0,
  },
  editContainer: {
    "& svg": {
      fontSize: 20,
    },
    "& button": {
      marginLeft: theme.spacing(0.5),
    },
    cursor: "pointer",
  }
});
