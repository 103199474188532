import * as yup from "yup";

export function validatePhoneNumber (value) {
  return !/^[0-9-+() ]*$/.test(value) && value ? this.createError({message: "Please use only allowed symbols: +, -, (, ) spaces and digits", path: this.path}) : true;
}

export const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required!"),
  country: yup.string().required("Country is required!"),
  modules: yup.string().required("Modules is required!"),
  city: yup.string().required("City is required!"),
  street: yup.string().required("Street is required!"),
  house: yup.string().required("House Number is required!"),
  postalCode: yup.string().required("Postal code is required!"),
  plantDescription: yup.string(),
  email: yup.string().email("Email has to be of type name@email.com").required("Email name is required!"),
  phone: yup.string().required("Phone is required!").test("phone", validatePhoneNumber),
  locations: yup.array().of(yup.object().shape({
    city: yup.string().required("City is required!"),
    street: yup.string().required("Street is required!"),
    house: yup.string().required("House Number is required!"),
    postalCode: yup.string(),
    phone: yup.string().test("phone", validatePhoneNumber),
    country: yup.string().required("Country is required!"),
    plantDescription: yup.string(),
  })),
  partners: yup.array().of(yup.object().shape({
    name: yup.string().required("Name is required!"),
    url: yup.string(),
    image: yup.string()
  })),
  approvals: yup.array().of(yup.object().shape({
    standard: yup.string().required("Standard it required!"),
    issuingBody: yup.string().required("Issuing body it required!"),
    description: yup.string().required("Description it required!"),
    from: yup.date().typeError("Should be date!").nullable(),
    to: yup.date().typeError("Should be date!").nullable(),
    link: yup.string().required("Link is required!")
  }))
});