import React, {useRef, useState} from "react";
import {Button, Popover} from "@mui/material";
import {withStyles} from "tss-react/mui";
import EditIcon from "@mui/icons-material/Edit";
import {Input} from "@core/components/Form";
import styles from "./styles";

const AddValue = ({classes, value, label, buttonName, propertyName, newItems, indexInNewItems, setNewItems}) => {
  const [inputShown, setInputShown] = useState(false);
  const anchorEl = useRef();

  return (
    <>
      <Popover
        anchorEl={anchorEl.current}
        open={inputShown}
        onClose={() => setInputShown(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
      >
        <Input
          onKeyDown={(e) => {
            if (e.key !== "Enter") return;

            setInputShown(false);
          }}
          placeholder={label}
          value={value}
          onChange={(e) => {
            const items = newItems.map((i, idx) => {
              if (idx !== indexInNewItems) return i;

              return {...i, [propertyName]: e.target.value};
            });
            setNewItems(items);
          }}
        />
      </Popover>
      {value ? (
        <div className={classes.value} ref={anchorEl}>
          <span>{label}:</span>
          &nbsp;
          {value}
          <EditIcon color="primary" onClick={() => setInputShown(true)}/>
        </div>
      ) : (
        <Button
          className={classes.button}
          color="primary"
          size="small"
          onClick={() => setInputShown(true)}
        >
          <span ref={anchorEl}>{buttonName}</span>
        </Button>
      )}
    </>
  );
};

export default withStyles(AddValue, styles);