import React, {useContext} from "react";
import {DialogOpenContext} from "@core/components/TimelineBlock/contexts";
import {Button, SvgIcon} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {NewTabIcon} from "@core/components/images";
import styles from "./styles";

function OpenCompanyDialogButton({classes, transaction}) {
  const {handleOpen} = useContext(DialogOpenContext);

  return (
    <Button
      data-html2canvas-ignore="true"
      variant="containedGray"
      onClick={handleOpen(transaction.signer && transaction.signer.company && transaction.signer.company._id)}>
      <SvgIcon classes={{root: classes.newTabIcon}}><NewTabIcon/></SvgIcon>
      Company overview
    </Button>
  );
}

export default withStyles(OpenCompanyDialogButton, styles);