import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Dialog, DialogTitle, DialogContent, Button, Grid, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import * as yup from "yup";
import {keys, values} from "ramda";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import BaseProductsList from "../../../Campaigns/components/BaseProductsList";
import BaseProductsUpload from "../../../Campaigns/components/BaseProductsUpload";
import {QCPS} from "@core/constants/qcps";
import modules from "@core/constants/modules";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import styles from "./styles";
import useStores from "../../../useStores";
import {MuiAutocomplete} from "@core/components/Form";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const TRANSFER_QUERY_BY_MODULE = {
  [modules.PRODUCER]: `${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open`,
  [modules.END_OWNER]: `${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open&type=${INTERFACE_TYPE.RECEIVER}`,
};

const initialValues = {
  name: "",
  type: "",
  manufacturer: "",
  purchaseOrder: "",
  purchaseOrderItem: "",
  qcp: "",
  products: [],
};

const validationSchema = yup.object().shape({
  name: yup.string().required("This field is required!"),
  manufacturer: yup.string().required("This field is required!"),
  purchaseOrder: yup.string().required("This field is required!"),
  purchaseOrderItem: yup.string().required("This field is required!"),
  qcp: yup.string().required("This field is required!"),
  internalWorkOrder: yup.string(),
  products: yup.array().of(yup.object().shape({
    manufacturer: yup.string().required(),
    heat: yup.string().required(),
    pipeNumber: yup.string().required(),
    description: yup.string(),
  })).required(),
});

const CampaignCreate = observer(({classes, open, close, onCreated}) => {
  const {CampaignStore, TransferStore, UserStore} = useStores();

  const [parsedProducts, setParsedProducts] = useState([]);

  useEffect(() => {
    TransferStore.getTransfersReceivers([modules.PRODUCER]);
  }, []);

  const onSubmit = async (values) => {
    await CampaignStore.createCampaign(values);
    onCreated(CampaignStore.campaign);
  };

  const [module] = UserStore.user.data.company.modules;

  const producers = TransferStore.receivers;
  const companyQcps = keys(QCPS).filter((qcp) => QCPS[qcp].companies.includes(UserStore.user.data.company.name));

  return (
    <Dialog
      open={open}
      onClose={() => {
        close();
        setParsedProducts([]);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Create campaign
      </DialogTitle>
      <DialogContent>
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(props) => {
            return (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    required
                    label='Name'
                    name='name'
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name='type'
                    label="Campaign type"
                    required
                  >
                    {values(CAMPAIGN_TYPES).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <MuiAutocomplete
                    url={TRANSFER_QUERY_BY_MODULE[module.name]}
                    inputProps={{
                      label: "PO Number",
                      name: "purchaseOrder",
                      required: true
                    }}
                    onChange={(orderId, order) => {
                      if (!orderId) {
                        return;
                      }

                      props.setFieldValue("purchaseOrder", orderId);
                      props.setFieldValue("manufacturer", order[INTERFACE_TYPE.SENDER]._id);
                    }}
                    formatOptions={(data) => data.items.map((item) => ({label: item.orderNumber, value: item._id}))}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    disabled={!props.values.purchaseOrder}
                    name='manufacturer'
                    label={props.values.type ? `${props.values.type} company` : "Company"}
                    required
                  >
                    {producers.map((producer) => (
                      <MenuItem key={producer._id} value={producer._id}>
                        {producer.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    label='PO Item'
                    name='purchaseOrderItem'
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    required
                    label='QCP/ITP'
                    name='qcp'
                  >
                    {companyQcps.map((qcp) => (
                      <MenuItem key={qcp} value={qcp}>
                        {qcp}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                {!!parsedProducts.length && (
                  <Grid item xs={12}>
                    <h3 className={classes.productsHeader}>Base Products</h3>
                    <BaseProductsList
                      products={parsedProducts}
                      selectedProducts={props.values.products}
                      setSelectedProducts={(products) => props.setFieldValue("products", products)}
                    />
                  </Grid>
                )}
                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <BaseProductsUpload
                      required
                      products={parsedProducts}
                      selectedProducts={props.values.products}
                      setProducts={setParsedProducts}
                      setSelectedProducts={(products) => props.setFieldValue("products", products)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      color='primary'
                      variant="contained"
                      onClick={props.handleSubmit}
                      disabled={!props.isValid}
                    >
                      Start campaign
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
});

export default withStyles(CampaignCreate, styles);