import React, {memo, useMemo} from "react";
import {SPOOL_LABEL} from "../../constants";
import {last} from "ramda";

const AsBuilt = ({Component, settings, stalks, tieInWeldsByStalkNumber, ...props}) => {
  const spool = useMemo(() => {
    const spool = stalks.reduce((acc, stalk, index) => {
      if(!index) return acc.concat(stalk);

      const lastWeld = last(acc);
      const {position, stalkNumber} = lastWeld;

      if(tieInWeldsByStalkNumber[stalkNumber]) {
        const tieInWeld = {
          ...tieInWeldsByStalkNumber[stalkNumber],
          position: position + 1
        };
        acc.push(tieInWeld);
      }
      
      const updatedStalk = stalk.map((weld) => ({
        ...weld,
        position: weld.position + position + 2
      }));
      
      return acc.concat(updatedStalk);
    }, []);

    return spool;
  }, []);

  return (
    <Component
      stalks={[spool]}
      settings={settings}
      label={SPOOL_LABEL}
      {...props}
    />
  );
};

export default memo(AsBuilt);