import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody, Box} from "@mui/material";

const GlobalAcceptance = ({qcpConfig}) => {
  const {averageCrackLengthRatioMax, averageCrackThicknessRatioMax, averageCrackSensitivityRatioMax} = qcpConfig.requirements;

  if(!averageCrackLengthRatioMax &&
    !averageCrackThicknessRatioMax &&
    !averageCrackSensitivityRatioMax) return null;

  return (
    <Box sx={{marginBottom: 1}}>
      <h3>Global Requirements</h3>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Average Crack Length Ratio</TableCell>
            <TableCell>Average Crack Thickness Ratio</TableCell>
            <TableCell>Average Crack Sensitivity Ratio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Min</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max</TableCell>
            <TableCell>{averageCrackLengthRatioMax || "-"}</TableCell>
            <TableCell>{averageCrackThicknessRatioMax || "-"}</TableCell>
            <TableCell>{averageCrackSensitivityRatioMax || "-"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default GlobalAcceptance;