export default (theme) => ({
  modal: {
    width: "60%",
    padding: " 40px 20px 40px 20px",
    backgroundColor: "#ffffff",
    margin: "5% auto",
  },

  headerSection: {
    display: "flex",
    justifyContent: "space-between"
  },

  searchContainer: {
    marginLeft: "30%",
    float: "right",
    width: "300px",
    height: "50px",
    position: "relative",
  },

  searchIcon: {
    float: "right",
    position: "relative",
    top: "-35px",
    right: "15px",
  },

  tableContainer: {
    padding: "30px 0"
  },

  buttonInsertTests: {
    position: "absolute",
    bottom: "140px",
    right: "280px",
  },

  thUnderline: {
    borderBottom: "2px solid #d2d2d2"
  },

  checkboxTableWidth: {
    maxWidth: "50px"
  },

  satisfactory: {
    color: "#4caf50"
  },

  modalAddTest: {
    padding: "35px !important"
  },

  mainSection: {
    height: "500px",
    display: "flex",
    flexDirection: "column",
  },

  paper: {
    paddingTop: "30px",
    overflow: "scroll",
  },

  insertBtnContainer: {
    textAlign: "right",
    paddingTop: "30px"
  },

  unsatisfactory: {
    color: "#d02d52",
    fontWeight: theme.typography.fontWeightMedium,
  },

  awaitingResult: {
    color: "#d4a237",
    fontWeight: theme.typography.fontWeightMedium,
  }
});