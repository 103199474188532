import React, {useCallback, useEffect, useRef} from "react";
import {Chart as ChartJS} from "chart.js";
import {usePlotOptions} from "../../plotContext";
import {getLabel, getPlugins, getScale} from "../../services";
import {COLORS} from "../../сonstants";

const TITLE = "Box Plot";

const COLORS_PRIORITY = [COLORS.blue, COLORS.orange, COLORS.violet];

const BoxPlot = ({testType, plotData, testConfig, isFullScreen}) => {
  const canvas = useRef(null);
  const plot = useRef(null);

  const {options: plotOptions, paramType} = usePlotOptions();
  const {minY: min = 0, maxY: max = 0} = plotData.minmax ?? {};

  const yAxisLabel = getLabel(plotData.units, testType, paramType, testConfig);

  const prepareData = useCallback((plot) => {
    const prepared = {...plot};

    prepared.datasets = prepared.datasets.map((ds, index) => ({
      ...ds,
      outlierBackgroundColor: COLORS.red.backgroundColor,
      borderColor: COLORS_PRIORITY[index].borderColor,
      backgroundColor: COLORS_PRIORITY[index].backgroundColor,
    }));

    return prepared;
  }, []);

  const dataset = plotData.plot.datasets[0].data[0];
  const {min: minAxisY , max: maxAxisY} = getScale(dataset, plotOptions.showAcceptanceCriteria, plotData.minmax);

  useEffect(() => {
    const additionalPlugins = {
      title: {
        display: true,
        text: TITLE,
      },
      legend: {
        display: plotData.plot.datasets.length > 1
      }
    };

    const plugins = {
      ...additionalPlugins,
      ...getPlugins(plotOptions.showAcceptanceCriteria, {y: {min, max}})
    };

    const chartConfig = {
      data: prepareData(plotData.plot),
      options: {
        ...plotData.options,
        responsive: true,
        maintainAspectRatio: true,
        animation: false,
        plugins,
        scales: {
          y: {
            min: minAxisY,
            max: maxAxisY,
            title: {
              display: true,
              text: yAxisLabel,
            },
          },
        }
      },
    };

    plot.current = new ChartJS(canvas.current, chartConfig);

    return () => {
      plot.current.destroy();
    };
  }, [plotData, prepareData, plotOptions.showAcceptanceCriteria, min, max, isFullScreen]);

  return (
    <canvas ref={canvas} aria-label={TITLE} ></canvas>
  );
};

export default BoxPlot;
