import React from "react";
import {Box} from "@mui/material";

const Company = ({name}) => {
  return (
    <Box>
      {name ? name : "N/A"}
    </Box>
  );
};

export default Company;