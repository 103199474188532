import React, {useState, useEffect} from "react";
import {sort} from "ramda";
import {Checkbox, Collapse, List, ListItem, ListItemText} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styles from "./styles";

const FilterType = ({classes, name, options, filters, setFilters, filterBy}) => {
  const [open, setOpen] = useState(false);

  const selectedOptions = filters[name] || [];
  const filteredOptions = options.filter((o) => o.toLowerCase().includes(filterBy.toLowerCase()));
  const sortedOptions = sort((itemA, itemB) => itemA.localeCompare(itemB, undefined, {numeric: true}), filteredOptions);

  useEffect(() => {
    if(filterBy && filteredOptions.length) setOpen(true);
    else setOpen(false);
  }, [filterBy]);

  const onClick = (option) => {
    let newFilters = {};

    if(selectedOptions.includes(option)) {
      const value = filters[name].filter((o) => o !== option);
      newFilters = {...filters, [name]: value};
    } else {
      newFilters = {...filters, [name]: [...(filters[name] || []), option]};
    }

    setFilters(newFilters);
  };

  if(!filteredOptions.length) return null;

  return (
    <>
      <ListItem
        button
        key={name}
        onClick={() => setOpen(!open)}
      >
        <ListItemText
          primary={name}
          classes={filters[name]?.length ? {primary: classes.selectedListItem} : {}}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={classes.optionsList}
        >
          {sortedOptions.map((option) => (
            <ListItem
              button
              key={option}
              classes={{root: classes.listItemCheckbox}}
              onClick={() => onClick(option)}
            >
              <Checkbox
                checked={selectedOptions.includes(option)}
                value={option}
                color="primary"
              />
              <ListItemText primary={option} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default withStyles(FilterType, styles);