import React from "react";
import {Select} from "@core/components/Form";
import {useField} from "formik";

const SelectField = ({label, children, onChange, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const {error, touched} = meta;
  const {setTouched, setValue} = helpers;

  return (
    <Select
      label={label}
      value={field.value}
      error={Boolean(error) && touched}
      errorMessage={error}
      onChange={({target}) => {
        if (!touched) setTouched(true);

        const value = target.value;
        setValue(value);

        if (!onChange) return;

        onChange(value);
      }}
      {...props}
    >
      {children}
    </Select>
  );
};

export default SelectField;