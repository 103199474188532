import React, {useState} from "react";
import {Button, Toolbar, AppBar} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import CertificateDeleteConfirmationModal from "@core/components/CertificateDeleteConfirmationModal";
import styles from "./styles";
import Grid from "@mui/material/Grid";
import RouterLink from "@core/components/RouterLink";

const UtilsBar = observer((props) => {
  const [confirmationOpened, setConfirmationOpened] = useState(false);

  const openDeleteConfirmation = () => {
    setConfirmationOpened(true);
  };

  const closeConfirmation = () => {
    setConfirmationOpened(false);
  };

  return (
    <>
      <AppBar position="static" color="default" enableColorOnDark data-html2canvas-ignore="true">
        <Toolbar variant="dense" align="right" className={props.classes.toolBar} disableGutters>
          <Grid container>
            <Grid item container xs={6}>
              {props.isProducer && (
                <Button
                  component={RouterLink}
                  classes={{
                    label: props.classes.labelBtn
                  }}
                  to="/certificates/create"
                >
                  <img src="/images/add_sign.svg" alt="new certificate"/>
                  New Certificate
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                classes={{
                  label: props.classes.labelBtn
                }}
                onClick={openDeleteConfirmation}
              >
                <img src="/images/remove_circle.svg" alt="remove certificate"/>
                Remove Certificate
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <CertificateDeleteConfirmationModal
        open={confirmationOpened}
        close={closeConfirmation}
        onConfirm={props.deleteCertificate}
      />
    </>
  );
});

export default withStyles(UtilsBar, styles);
