import React from "react";
import Acceptance from "./components/Acceptance";
import GlobalAcceptance from "./components/GlobalAcceptance";

const HydrogenInducedCrackingTest = ({qcpConfig}) => {
  return (
    <>
      <GlobalAcceptance qcpConfig={qcpConfig} />
      <Acceptance qcpConfig={qcpConfig} />
    </>
  );
};

export default HydrogenInducedCrackingTest;