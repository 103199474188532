import React from "react";
import TransfersList from "@core/components/TransfersList";
import {INTERFACE_TYPE} from "@core/constants/transfer";

const OutgoingTransfersList = () => {
  return (
    <TransfersList interfaceType={INTERFACE_TYPE.SENDER} />
  );
};

export default OutgoingTransfersList;