import React, {useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid, Switch, Skeleton} from "@mui/material";
import AddBaseMaterial from "./components/AddBaseMaterial";
import BlockHeader from "../BlockHeader";
import CompactBaseMaterials from "./components/CompactBaseMaterials";
import DetailedBaseMaterials from "./components/DetailedBaseMaterials";
import {FILTERS} from "@core/constants/filters";
import useStores from "../../../../useStores";
import {indexBy, prop} from "ramda";
import {useParams} from "react-router";

const VIEWS = {
  COMPACT: "COMPACT",
  DETAILED: "DETAILED",
};

const BaseMaterials = ({id, label}) => {
  const {CampaignStore, FilterStore} = useStores();

  const [view, setView] = useState(VIEWS.DETAILED);
  const [addNewOpen, setAddNewOpen] = useState(false);

  const {id: campaignId} = useParams();

  const campaign = CampaignStore.campaign;
  
  const {welds = [], rawMaterials = [], products = []} = campaign;

  const isLoaded = campaignId === campaign._id;

  const specimens = FilterStore.filters[FILTERS.SPECIMEN_ID] || [];
  const batchNumbers = FilterStore.filters[FILTERS.BATCH_NUMBER] || [];

  const specimensOfBatchNumbers = useMemo(() => batchNumbers.reduce((specimens, bn) => {
    const material = rawMaterials.find((material) => material.batchNumber === bn) || {};

    return specimens.concat(material.pipes);
  }, []), [batchNumbers, rawMaterials]);

  const weldByPipeId = useMemo(() => indexBy(prop("pipeA"), welds), [welds]);
  
  const formattedProducts = useMemo(() => {
    return products.map((product) => {
      const weld = weldByPipeId[product._id] || {};

      return {
        ...product,
        quarantine: product.quarantine ? "Yes" : "No",
        stalkNumber: weld.stalkNumber,
        jointNumber: weld.jointNumberA,
        weldNumber: weld.weldNumber,
      };
    });
  }, [products, weldByPipeId]);

  const filteredProducts = useMemo(() => formattedProducts.filter((product) => {
    const isInSpecimensArr = !specimens.length || specimens.includes(product.pipeNumber);
    const isInSpecimensBatchNumberArr = !specimensOfBatchNumbers.length || specimensOfBatchNumbers.includes(product.pipeNumber);

    return isInSpecimensArr && isInSpecimensBatchNumberArr;
  }), [specimens, batchNumbers, formattedProducts]);
  
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <BlockHeader
            id={id}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                {isLoaded ? label : <Skeleton width={200} />}
              </Grid>
              <Grid item>
                <Button
                  color='primary'
                  variant="contained"
                  onClick={() => setAddNewOpen(true)}
                >
                  Add new
                </Button>
              </Grid>
            </Grid>
          </BlockHeader>
        </Grid>
        <Grid item>
          Compact View
          <Switch
            color="primary"
            checked={view === VIEWS.DETAILED}
            onChange={(event) => setView(event.target.checked ? VIEWS.DETAILED : VIEWS.COMPACT)}
          />
          Detailed View
        </Grid>
      </Grid>
      {view === VIEWS.COMPACT ? (
        <CompactBaseMaterials
          products={filteredProducts}
        />
      ) : (
        <DetailedBaseMaterials
          products={filteredProducts}
        />
      )}
      <AddBaseMaterial
        open={addNewOpen}
        close={() => setAddNewOpen(false)}
      />
    </>
  );
};

export default observer(BaseMaterials);