import React, {useEffect, useMemo} from "react";
import classNames from "classnames";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react";
import {MdArrowForward} from "react-icons/md/";
import Menu from "@core/components/Menu";
import Header from "@core/components/Header";
import User from "../User";
import Notification from "@core/components/Modal";
import ErrorBoundary from "../ErrorBoundary";
import NotActivatedMessage from "../NotActivatedMessage";
import {TITLES} from "@core/components/AppWrapper/constants";

import styles from "./styles";
import ZendeskButton from "../ZendeskButton";
import {FEATURES} from "@core/constants/features";
import ConfirmationOnUrlChange from "@core/components/ConfirmationOnUrlChange";
import useStores from "../../../../../useStores";

const AppWrapperOldDesign = observer(({children, classes, history, location}) => {
  const {MenuStore, UserStore} = useStores();
  const {collapsed} = MenuStore;

  const user = useMemo(() => UserStore.user.data, [UserStore.user.data]);
  const zendeskFeatureEnabled = useMemo(() => !!UserStore.features[FEATURES.ZENDESK], [UserStore.features]);
  const [module] = user.company.modules;

  useEffect(()=>{
    document.title = "SteelTrace - " + TITLES[module.name];
  },[]);

  const handleClick = () => {
    MenuStore.toggleCollapsed();
  };

  return (
    <ConfirmationOnUrlChange>
      <div className={classes.container}>
        <div className={classNames(classes.leftColumn, {
          "collapsed": collapsed
        })}>
          <User history={history} />
          <Menu isCollapsed={collapsed} />

          <div className={classNames(classes.toggle, {
            "collapsed": collapsed
          })}
          onClick={handleClick}
          >
            <MdArrowForward />
          </div>
        </div>
        <div
          id="container"
          className={
            classNames(classes.rightColumn, {
              "collapsed": collapsed
            })}
        >
          <Header />
          <NotActivatedMessage />
          <ErrorBoundary key={location.pathname}>
            {children}
          </ErrorBoundary>
        </div>
        <Notification />
        <ZendeskButton isZendeskFeatureEnabled={zendeskFeatureEnabled}/>
      </div>
    </ConfirmationOnUrlChange>
  );

});

export default withStyles(AppWrapperOldDesign,styles);