import React, {useCallback, useState} from "react";
import {IconButton, TableCell, TableRow} from "@mui/material";
import EditableCell from "@core/components/EditableCell";
import {MuiAutocomplete} from "@core/components/Form";
import {useFormikContext} from "formik";
import CreateCustomerOrderModal from "@core/components/CreateCustomerOrderModal";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import {IoMdCloseCircleOutline} from "react-icons/io";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const Certificate = ({remove, withOrder, index, quantity}) => {
  const [addCustomerOrderOpen, setAddCustomerOrderOpen] = useState(false);

  const {setFieldTouched, setFieldValue, values, ...props} = useFormikContext();

  const certificate = values.certificates[index];

  const onChange = useCallback((index, field, value) => {
    setFieldValue(`certificates.${index}.${field}`, value);
    setFieldTouched(`certificates.${index}.${field}`, true);
  }, []);

  const errors = props.errors.certificates ? props.errors.certificates[index] || {} : {};
  const touched = props.touched.certificates ? props.touched.certificates[index] || {} : {};

  return (
    <TableRow hover>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <EditableCell
          editable
          value={certificate.heat}
          required
          setValue={(value) => onChange(index, "heat", value)}
          error={errors.heat && touched.heat}
          errorMessage={errors.heat}
        />
      </TableCell>
      <TableCell>
        <EditableCell
          editable
          value={certificate.properties.lotId}
          required
          setValue={(value) => onChange(index, "properties.lotId", value)}
          error={errors.properties?.lotId && touched.properties?.lotId}
          errorMessage={errors.properties?.lotId}
        />
      </TableCell>
      <TableCell>
        <EditableCell
          editable
          value={certificate.internalWorkOrder}
          setValue={(value) => onChange(index, "internalWorkOrder", value)}
          error={errors.internalWorkOrder && touched.internalWorkOrder}
          errorMessage={errors.internalWorkOrder}
        />
      </TableCell>
      <TableCell>
        <EditableCell
          editable
          value={certificate.internalItemNumber}
          setValue={(value) => onChange(index, "internalItemNumber", value)}
          error={errors.internalItemNumber && touched.internalItemNumber}
          errorMessage={errors.internalItemNumber}
        />
      </TableCell>
      <TableCell>
        <MuiAutocomplete
          url={`${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open&type=sender`}
          inputProps={{
            name: `certificates.${[index]}.transferId`,
          }}
          formatOptions={(data) => data.items.map((item) => ({label: item.orderNumber, value: item._id}))}
          onCreateNew={() => setAddCustomerOrderOpen(true)}
        />
        <CreateCustomerOrderModal
          onTransferCreated={(transferId) => setFieldValue("transferId", transferId)}
          open={addCustomerOrderOpen}
          setOpen={setAddCustomerOrderOpen}
          interfaceType={INTERFACE_TYPE.SENDER}
        />
      </TableCell>
      {withOrder && (
        <TableCell>
          <EditableCell
            editable
            value={certificate.lineItem}
            setValue={(value) => onChange(index, "lineItem", value)}
            error={errors.lineItem && touched.lineItem}
            errorMessage={errors.lineItem}
          />
        </TableCell>
      )}
      <TableCell>{quantity || "-"}</TableCell>
      <TableCell>
        <IconButton
          onClick={() => remove(index)}
        >
          <IoMdCloseCircleOutline />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default Certificate;