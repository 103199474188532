export const TEST_STANDARDS = [
  "NACE TM0177 (2016)",
];

export const ACCEPTANCE_CRITERIA = {
  "API 5L Annex H, clause H.4.5": {},
  "ISO 15156-2 (2015) - NACE MR0175 Table B.1": {},
  "QCP 6916 Rev. 3": {
    company: ["TenarisLab","Tremblay Inc."],
    magnification: 10,
    stressMethod: "Four Point Bend test",
    solution: "Solution A of NACE MR0177",
    duration: 720,
    yeildStrengthType: "Actual",
    yeildStrengthPercentage: 80,
  },
  "QCP 5513 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    magnification: 10,
    yeildStrengthPercentage: 80,
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    magnification: 10,
    yeildStrengthPercentage: 80,
  },
  "QCP 5987 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    duration: 720,
    yeildStrengthType: "Actual",
    yeildStrengthPercentage: 80,
    magnification: 10,
  }
};

export const STRESS_METHODS = [
  "Uniaxial Tensile testing",
  "Bent Beam testing",
  "C-Ring testing",
  "Double Cantilever Beam (DCB) test",
  "Four Point Bend test"
];

export const YEILD_STRENGTH_TYPES = [
  "Actual",
  "Specified"
];

export const EVALUATION_OF_CRAKS = [
  "Absent (PASS)",
  "Present (FAIL)"
];