const testStandards = ["ASTM E165 (2018)", "ASTM E1417 (2016)", "ASME (2021) Section V Article 6", "ISO 10893-4 (2011)", "EN 10228-2 (2016)"];
const acceptances = ["ASME (2021) Section VIII, Division 1 - Appendix 8", "EN 10228-2 (2016) Class 1", "EN 10228-2 (2016) Class 2", "EN 10228-2 (2016) Class 3", "EN 10228-2 (2016) Class 4"];
const conditions = ["Black light intensity at the examined surface > 1000 µW/cm2, and visible light in the darkened area < 20 lux", "Visible light intensity at the examined surface > 1076 lux"];
const examinedSurfaces = ["100% of surface", "Bevel ends", "External surface", "Internal surface", "Welds"];
const cleanings = ["Cleaning with solvent", "Pickling", "Machining", "Grinding"];
const types = ["Type I: Fluorescent", "Type II: Visible"];
const methods = ["Method A: Water-washable", "Method B: Post-emulsifiable, lipophilic", "Method C: Solvent removable", "Method D: Post-emulsifiable, hydrophilic"];
const penetrantApps = ["Brushing", "Dipping", "Flooding", "Spraying"];
const removalBys = ["Spray-rinse water (pressure below 275 kPa, temperature between 10 °C and 38 °C)", "Hydro-air pressure spray gun (pressure below 172 kPa, temperature between 10 °C and 38 °C)", "Immersion in water (temperature between 10 °C and 38 °C)", "Wiping with lint-free cloth and solvent", "Immersion in remover"];
const devApps = ["Flowing", "Immersion", "Spraying"];
const emulsifierApps = ["Immersion", "Spraying"];

export {testStandards, acceptances, conditions, types, methods, examinedSurfaces, cleanings, penetrantApps, removalBys, devApps, emulsifierApps};
