import * as React from "react";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

const LightTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      boxShadow: (theme) => theme.shadows[8],
      color: theme.palette.text.primary
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&:before": {
        filter: "drop-shadow(0px 0px 0.5px rgba(130, 130, 130, 1))",
      }
    }
  };
});

export default LightTooltip;
