import React from "react";
import {Route, Redirect} from "react-router-dom";
import {compose} from "ramda";
import {inject, observer} from "mobx-react";
import AppWrapper from "@core/components/AppWrapper";
import {useLocation} from "react-router";
import {ACCESSED_ROUTE} from "@core/constants/accessedRoute";

const ProtectedRoute = ({component: Component, ...props}) => {
  const {UserStore: {user}, modules, roles} = props;
  const location = useLocation();

  if (!user.isLoaded) {
    const {pathname, search} = location;
    sessionStorage.setItem(ACCESSED_ROUTE, JSON.stringify({pathname, search}));

    return <Redirect to={"/login"}/>;
  }

  const userCompanyModule = user.data.company.modules[0].name;
  const userRole = user.data.role;

  const isAppropriateModule = !modules || modules.includes(userCompanyModule);
  const isAppropriateRole = !roles || roles.includes(userRole);

  const hasAccess = isAppropriateModule && isAppropriateRole;

  return (
    <Route {...props} render={(props) => {
      if (hasAccess){
        return <AppWrapper  {...props} ><Component {...props}/></AppWrapper>;
      } else {
        return <Redirect to={"/"}/>;
      }
    }}/>
  );
};

export default compose(inject("UserStore"), observer)(ProtectedRoute);
