import React from "react";
import {keys} from "ramda";
import {Box} from "@mui/material";
import AcceptanceTable from "./components/AcceptanceTable";

const HardnessTest = ({qcpConfig}) => {

  if(qcpConfig.TestType.POItem) {
    return keys(qcpConfig.TestType.POItem).map((poItem) => (
      <Box key={poItem} sx={{marginTop: 1}}>
        <h3>QCP Item {poItem}</h3>
        <AcceptanceTable
          testZones={qcpConfig.TestZones}
          config={qcpConfig.TestType.POItem[poItem]}
        />
      </Box>
    ));
  }

  return (
    <Box sx={{marginTop:1}}>
      <h3>Local Requirements</h3>
      <AcceptanceTable
        config={qcpConfig.TestType}
        testZones={qcpConfig.TestZones}
      />
    </Box>
  );
};

export default HardnessTest;