export default (theme) => ({
  box: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center",
    "& div:first-child": {
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#f1f1f1",
      padding: "20px 10px",
      color: "#616161",
      fontSize: 15,
    },
    "& div:last-child": {
      color: "#0000008a",
      padding: "20px 10px",
      height: 100,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }
  },
});