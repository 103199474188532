import React from "react";
import {Formik} from "formik";
import Form from "./components/Form";
import {validationSchema} from "@core/validationSchemas/company";

const initialValues = {
  name: "",
  country: "",
  city: "",
  street: "",
  house: "",
  postalCode: "",
  plantDescription: "",
  fax: "",
  logo: "",
  email: "",
  website: "",
  phone: "",
  modules: [],
  features: [],
  locations: [],
  partner: [],
  approvals: [],
  avatar: null
};

const CompanyForm = ({company, onSubmit, ...formProps}) => (
  <Formik
    enableReinitialize
    validateOnMount
    initialValues={company || initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    children={(props) => (
      <Form
        {...props}
        {...formProps}
        onSubmit={onSubmit}
      />
    )}
  />
);

export default CompanyForm;