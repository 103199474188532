import React from "react";
import {observer} from "mobx-react-lite";
import {Box, useTheme} from "@mui/material";
import Table from "@core/components/Table";
import {getColumns} from "./columns";
import {PAGE_TABS} from "../constants";
import useStores from "../../../../useStores";

const Campaigns = ({campaigns}) => {
  const {TransferStore, UserStore} = useStores();

  const theme = useTheme();

  const transfer = TransferStore.transfer;

  const [module] = UserStore.user.data.company.modules;

  const columns = getColumns(module);

  if(!transfer.campaigns?.length) return null;

  return (
    <Box
      sx={{
        padding: `0 ${theme.spacing(4)}`,
      }}
    >
      <Box
        sx={{
          margin: `${theme.spacing(3)} 0 ${theme.spacing()} 0`
        }}
      >
        <h3
          id={PAGE_TABS.CAMPAIGNS}
        >
          Campaigns
        </h3>
      </Box>
      <Table
        noDataText="No campaigns."
        items={campaigns}
        columns={columns}
        isLoaded
        total={campaigns.length}
      />
    </Box>
  );
};

export default observer(Campaigns);