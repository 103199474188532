import {getMean} from "@core/helpers";

const getTestAcceptable = ({elements, requirements}) => {
  const average = Number(requirements.averageShearArea) || 0;
  const single = Number(requirements.singleShearArea) || 0;

  const isSingleAcceptable = !single || elements.every((element) => Number(element.shearArea) >= single);
  const isAverageAcceptable = !average || getMean(elements.map((element) => Number(element.shearArea))) >= average;

  return isAverageAcceptable && isSingleAcceptable;
};

export {getTestAcceptable}; 