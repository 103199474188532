import React from "react";
import classNames from "classnames";
import {CircularProgress} from "@mui/material";

import {withStyles} from "tss-react/mui";

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  wrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

function CircularIndeterminate({classes, size = 40, noMargin = false}) {
  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classNames({[classes.progress]: !noMargin})} size={size} />
    </div>
  );
}

export default withStyles(CircularIndeterminate, styles);
