import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {Grid} from "@mui/material";
import {times} from "ramda";
import {FieldArray, useFormikContext} from "formik";
import TextField from "@core/components/FormikTextField";
import IdentificationOfInspectedItems from "../../IdentificationOfInspectedItems";
import {Input} from "@core/components/Form";

const ByFerriteScope = ({test, inspectedCertificates, isProducerTest, firstUpdate}) => {
  const {values, setFieldValue} = useFormikContext();

  const [numberOfTestLocations, setNumberOfTestLocations] = useState(values.elements.length || "");

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }

    setNumberOfTestLocations("");
    setFieldValue("elements", []);
  }, [values.type]);

  const changeElements = useCallback(() => {
    if (!values.quantityInspected || !numberOfTestLocations) {
      setFieldValue("elements", []);
    } else {
      const element = values.elements[0] || [];

      const missingTestInputs = numberOfTestLocations > element.length ? times(() => "", numberOfTestLocations - element.length) : [];
      const elements = values.elements.map((element) => {
        const newElement = [...element, ...missingTestInputs];

        return newElement.slice(0, Number(numberOfTestLocations));
      });

      const testInputs = times(() => "", numberOfTestLocations);

      const missingPieces = Number(values.quantityInspected) > elements.length ? times(() => testInputs, Number(values.quantityInspected) - elements.length) : [];
      const newElements = elements.concat(missingPieces);

      setFieldValue("elements", newElements.slice(0, Number(values.quantityInspected)));
    }
  }, [values.quantityInspected, numberOfTestLocations]);

  useLayoutEffect(() => {
    changeElements();
  }, [values.quantityInspected, numberOfTestLocations]);

  return (
    <>
      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={12}>
          <h3>Inspection parameters</h3>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={isProducerTest ? 4 : 3}>
          <Input
            inputProps={{min: 1}}
            type="number"
            value={numberOfTestLocations}
            label='Number of test locations'
            required
            onChange={(e) => setNumberOfTestLocations(e.target.value)}
          />
        </Grid>
      </Grid>
      <IdentificationOfInspectedItems
        isProducerTest={isProducerTest}
        inspectedCertificates={inspectedCertificates}
        test={test}
      />
      {!!values.elements.length && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <h3>Values</h3>
          </Grid>
        </Grid>
      )}
      <FieldArray name="elements">
        {() => values.elements.map((testInputs, elementIndex) => (
          <>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <h3> {values.examinationConditions && values.examinationConditions[elementIndex]?.value}</h3>
              </Grid>
            </Grid>
            {Array.isArray(testInputs) && (
              <Grid container spacing={2}>
                {testInputs.map((testInput, testInputIndex) => (
                  <Grid item xs={isProducerTest ? 3 : 2}>
                    <TextField
                      type="number"
                      name={`elements.${elementIndex}.${testInputIndex}`}
                      label={`Test result ${testInputIndex + 1}`}
                      required
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        ))}
      </FieldArray>
    </>
  );
};

export default ByFerriteScope;
