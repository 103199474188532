import React from "react";
import {MenuItem, Grid, FormControl} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Select, Input, MultipleSelect} from "@core/components/Form";
import {Formik} from "formik";
import * as yup from "yup";
import {omit} from "ramda";
import {TEST_RESULTS} from "@core/constants/testResults";
import {isAcceptable} from "@core/helpers/tests";
import IdentificationOfInspectedItems from "../../IdentificationOfInspectedItems";
import styles from "./styles";
import {
  testStandards,
  acceptances,
  conditions,
  types,
  methods,
  cleanings,
  penetrantApps,
  examinedSurfaces,
  removalBys,
  devApps,
  emulsifierApps
} from "./data";
import MuiSelect from "@core/components/MuiSelect";
import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";

const LiquidePenetrantTest = ({
  classes,
  test,
  saveTest,
  inspectedCertificates,
  isProducerTest,
  formRef
}) => {
  const validationSchema = yup.object().shape({
    testStandard: yup.string().required("Test standard is required!"),
    acceptance: yup.string(),
    condition: yup.string().required("Condition is required!"),
    type: yup.string().required("Type is required!"),
    method: yup.string().required("Method is required!"),
    penetrant: yup.string().required("Penetrant is required!"),
    dev: yup.string().required("Developer is required!"),
    tradeName: yup.string().required("Trade Name is required!"),
    penetrantApp: yup.string().required("Penetrant Application is required!"),
    dwelTime: yup.number()
      .positive("Invalid time")
      .typeError("Dwell Time should be a number!")
      .required("Dwell Time is required!"),
    emulsificationTime: yup.number()
      .positive("Invalid time")
      .nullable(true)
      .typeError("Dwel Time should be a number!"),
    devApp: yup.string().required("Developer Application is required!"),
    cleaning: yup.object().shape({
      before: yup.string().required("Cleaning before examination is required!"),
      after: yup.string().required("Cleaning after examination is required!")
    }),
    devTime: yup.number().min(1, "Should be \u2265 1").required("Property is required"),
    result: yup.string().required("Result is required!"),
    additionalRemarks: yup.string(),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
  });

  const initialValues = {
    testStandard: test?.properties.testStandard || "",
    acceptance: test?.properties.acceptance || "",
    condition: test?.properties.condition || "",
    method: test?.properties.method || "",
    type: test?.properties.type || "",
    cleaner: test?.properties.cleaner || "",
    emulsifier: test?.properties.emulsifier || "",
    penetrant: test?.properties.penetrant || "",
    dev: test?.properties.dev || "",
    tradeName: test?.properties.tradeName || "",
    penetrantApp: test?.properties.penetrantApp || "",
    dwelTime: test?.properties.dwelTime || "",
    removalBy: test?.properties.removalBy || "",
    emulsificationTime: test?.properties.emulsificationTime || null,
    emulsifierApp: test?.properties.emulsifierApp || "",
    devApp: test?.properties.devApp || "",
    devTime: test?.properties.devTime || "",
    examinedSurface: test?.properties.examinedSurface || [],
    cleaning: test?.properties.cleaning || {
      before: "",
      after: ""
    },
    result: test?.properties.result || "",
    additionalRemarks: test?.properties.additionalRemarks || "",
    witnesses: test?.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || []
  };

  const onSave = (values) => {
    if (isProducerTest) saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    else saveTest(values);
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        dirty
        onSubmit={onSave}
        render={(props) => {
          const {
            values: {
              acceptance,
              condition,
              type,
              method,
              cleaner,
              emulsifier,
              penetrant,
              dev,
              emulsificationTime,
              emulsifierApp,
              tradeName,
              penetrantApp,
              dwelTime,
              removalBy,
              devApp,
              devTime,
              cleaning: {before, after},
              result,
              examinedSurface,
              additionalRemarks,
            },

            errors,
            handleChange,
            touched,
            setFieldTouched,
            setFieldValue,
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          const changeMultiple = (name, e) => {
            setFieldValue(name, e);
            setFieldTouched(name, true, false);
          };

          const acceptable = isAcceptable(result);

          return <>
            {isProducerTest && (
              <Grid item xs={12}>
                <h1>Liquid Penetrant Examination</h1>
              </Grid>
            )}
            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={isProducerTest ? 4 : 3}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={testStandards}
                />
              </Grid>
              <Grid item xs={isProducerTest ? 4 : 3}>

                <Select
                  value={acceptance}
                  name='acceptance'
                  label='Acceptance Criteria'
                  error={Boolean(errors.acceptance) && touched.acceptance}
                  onChange={(event) => change("acceptance", event)}
                >
                  {acceptances.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                </Select>
              </Grid>
            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <Select
                  value={condition}
                  label="Examination conditions"
                  name="condition"
                  required
                  onChange={(e) => change("condition", e)}
                >
                  {conditions.map((value, index) =>
                    <MenuItem key={index} value={value}>{value}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item>
                <h2>Type and Method</h2>
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={isProducerTest ? 3 : 2}>
                <Select
                  value={type}
                  name='type'
                  label='Type'
                  required
                  error={Boolean(errors.type) && touched.type}
                  onChange={(event) => change("type", event)}
                >
                  {types.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={isProducerTest ? 5 : 4}>
                <Select
                  value={method}
                  name='method'
                  label='Method'
                  disabled={type === ""}
                  placeholder={!type && "Select type first"}
                  required
                  error={Boolean(errors.method) && touched.method}
                  onChange={(event) => change("method", event)}
                >
                  {
                    type === "Type II: Visible" ?
                      methods.filter((el, id) => id === 0 || id === 2).map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>) :
                      methods.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)
                  }
                </Select>
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item>
                <h2>Consumables</h2>
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={isProducerTest ? 4 : 2}>
                <Input
                  label='Penetrant'
                  name='penetrant'
                  value={penetrant}
                  required
                  error={Boolean(errors.penetrant) && touched.penetrant}
                  errorMessage={errors.penetrant}
                  onChange={(e) => change("penetrant", e)}
                  onBlur={() => setFieldValue("penetrant", penetrant.trim())}
                />
              </Grid>
              <Grid item xs={isProducerTest ? 4 : 2}>
                <Input
                  label='Developer'
                  name='dev'
                  value={dev}
                  required
                  error={Boolean(errors.dev) && touched.dev}
                  errorMessage={errors.dev}
                  onChange={(e) => change("dev", e)}
                  onBlur={() => setFieldValue("dev", dev.trim())}
                />
              </Grid>
              {
                (method === "Method B: Post-emulsifiable, lipophilic" || method === "Method D: Post-emulsifiable, hydrophilic") && (
                  <Grid item xs={isProducerTest ? 4 : 2}>
                    <Input
                      label='Emulsifier'
                      name='emulsifier'
                      value={emulsifier}
                      required
                      error={Boolean(errors.emulsifier) && touched.emulsifier}
                      errorMessage={errors.emulsifier}
                      onChange={(e) => change("emulsifier", e)}
                      onBlur={() => setFieldValue("emulsifier", emulsifier.trim())}
                    />
                  </Grid>
                )
              }
              {

                (method === "Method B: Post-emulsifiable, lipophilic" || method === "Method C: Solvent removable") && (
                  <Grid item xs={isProducerTest ? 4 : 2}>
                    <Input
                      label='Penetrant remover'
                      name='cleaner'
                      value={cleaner}
                      required
                      error={Boolean(errors.cleaner) && touched.cleaner}
                      errorMessage={errors.cleaner}
                      onChange={(e) => change("cleaner", e)}
                      onBlur={() => setFieldValue("cleaner", cleaner.trim())}
                    />
                  </Grid>
                )
              }

              <Grid item xs={isProducerTest ? 4 : 2}>
                <Input
                  label='Manufacturer'
                  name='tradeName'
                  value={tradeName}
                  required
                  error={Boolean(errors.tradeName) && touched.tradeName}
                  errorMessage={errors.tradeName}
                  onChange={(e) => change("tradeName", e)}
                  onBlur={() => setFieldValue("tradeName", tradeName.trim())}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item><h2>Test execution</h2></Grid>
            </Grid>

            <IdentificationOfInspectedItems
              isProducerTest={isProducerTest}
              inspectedCertificates={inspectedCertificates}
              test={test}
            />

            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={4}>
                <MultipleSelect
                  label={"Examined surface"}
                  required
                  value={examinedSurface}
                  elements={examinedSurfaces}
                  onChange={(e) => changeMultiple("examinedSurface", e)}
                />
              </Grid>
              <Grid item xs={isProducerTest ? 4 : 2}>
                <Select
                  required
                  value={penetrantApp}
                  name='penetrantApp'
                  label='Penetrant Application By'
                  error={Boolean(errors.penetrantApp) && touched.penetrantApp}
                  errorMessage={errors.penetrantApp}
                  onChange={(e) => change("penetrantApp", e)}
                >
                  {penetrantApps.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={isProducerTest ? 4 : 2}>
                <Input
                  label='Dwell Time'
                  name='dwelTime'
                  value={dwelTime}
                  required
                  type={"number"}
                  error={Boolean(errors.dwelTime) && touched.dwelTime}
                  errorMessage={errors.dwelTime}
                  endAdornment='Min.'
                  onChange={(e) => change("dwelTime", e)}
                />
              </Grid>
              {(method === "Method B: Post-emulsifiable, lipophilic" || method === "Method D: Post-emulsifiable, hydrophilic") && (
                <>
                  <Grid item xs={isProducerTest ? 4 : 2}>
                    <Select
                      value={emulsifierApp}
                      name='emulsifierApp'
                      label='Emulsifier Application By'
                      error={Boolean(errors.emulsifierApp) && touched.emulsifierApp}
                      errorMessage={errors.emulsifierApp}
                      onChange={(e) => change("emulsifierApp", e)}
                    >
                      {emulsifierApps.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                    </Select>
                  </Grid>
                  <Grid item xs={isProducerTest ? 4 : 2}>
                    <Input
                      label='Emulsification Time'
                      name='emulsificationTime'
                      value={emulsificationTime}
                      required
                      type={"number"}
                      error={Boolean(errors.emulsificationTime) && touched.emulsificationTime}
                      errorMessage={errors.emulsificationTime}
                      endAdornment='Min.'
                      onChange={(e) => change("emulsificationTime", e)}
                    />
                  </Grid>
                </>
              )
              }
              <Grid item xs={6}>
                <Select
                  value={removalBy}
                  name='removalBy'
                  label='Penetrant Removal By'
                  error={Boolean(errors.removalBy) && touched.removalBy}
                  errorMessage={errors.removalBy}
                  onChange={(e) => change("removalBy", e)}
                >
                  {removalBys.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                </Select>
              </Grid>
            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={isProducerTest ? 4 : 2}>
                <Select
                  required
                  value={devApp}
                  name='devApp'
                  label='Developer Application By'
                  error={Boolean(errors.devApp) && touched.devApp}
                  errorMessage={errors.devApp}
                  onChange={(e) => change("devApp", e)}
                >
                  {devApps.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={isProducerTest ? 4 : 2}>
                <Input
                  label='Developing Time'
                  name='devTime'
                  value={devTime}
                  required
                  type={"number"}
                  inputProps={{min: 1}}
                  error={Boolean(errors.devTime) && touched.devTime}
                  errorMessage={errors.devTime}
                  endAdornment='Min.'
                  onChange={(e) => change("devTime", e)}
                />
              </Grid>

            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <h3>Surface cleaning</h3>

                <Grid container className={classes.gridRoot} spacing={5}>
                  <Grid item xs={6}>
                    <Select
                      value={before}
                      label="Before examination"
                      name="cleaning.before"
                      required
                      onChange={(e) => change("cleaning.before", e)}
                    >
                      {cleanings.map((value, index) =>
                        <MenuItem key={index} value={value}>{value}</MenuItem>
                      )}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      value={after}
                      label="After examination"
                      name="cleaning.after"
                      required
                      onChange={(e) => change("cleaning.after", e)}
                    >
                      {cleanings.map((value, index) =>
                        <MenuItem key={index} value={value}>{value}</MenuItem>
                      )}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={isProducerTest ? 4 : 3}>
                <Select
                  value={result}
                  name='result'
                  label='Result'
                  required
                  error={Boolean(errors.result) && touched.result}
                  onChange={(event) => change("result", event)}
                >
                  <MenuItem value={TEST_RESULTS.NO_RECORDABLE_INDICATIONS}>{TEST_RESULTS.NO_RECORDABLE_INDICATIONS}</MenuItem>
                  <MenuItem value={TEST_RESULTS.ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.ACCEPTABLE_INDICATIONS}</MenuItem>
                  <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.NOT_ACCEPTABLE_INDICATIONS}</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <FormControl variant="standard" className={classes.formControl}>
                  <Input
                    label='Additional remarks'
                    name='additionalRemarks'
                    value={additionalRemarks}
                    onChange={(e) => change("additionalRemarks", e)}
                    multiline
                    rows={5}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {isProducerTest ? (
              <ProducerTestFooter />
            ) : (
              <LabTestFooter
                onSubmit={saveTest}
                result={acceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE}
              />
            )}
          </>;
        }}
      />
    </div>
  );
};

export default withStyles(LiquidePenetrantTest, styles);
