import React, {useEffect, useState} from "react";
import {Typography, Table, TableBody, TableRow, TableCell, Button, Paper} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Loader from "@core/components/Loader";
import RouterLink from "@core/components/RouterLink";
import {observer} from "mobx-react-lite";
import {isEmpty} from "ramda";
import useStores from "../../../../useStores";
import styles from "./styles";
import {ROLES_TITLES} from "@core/constants/externalUsersRoles";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";

const ExternalUsersTable = observer(({classes}) => {
  const {ExternalUsersStore} = useStores();

  const [externalUserToDelete, setExternalUserToDelete] = useState(null);

  useEffect(() => {
    ExternalUsersStore.getExternalUsers();
  }, []);

  if(!ExternalUsersStore.externalUsers.isLoaded) {
    return (
      <Loader />
    );
  }

  if(isEmpty(ExternalUsersStore.externalUsers.data)) {
    return null;
  }

  return (
    <>
      <Typography
        component="h5"
        variant="h5"
        gutterBottom
        className={classes.header}
      >
        Users
      </Typography>
      <Paper className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>First name</TableCell>
              <TableCell className={classes.tableCell}>Last name</TableCell>
              <TableCell className={classes.tableCell}>Company</TableCell>
              <TableCell className={classes.tableCell}>Role</TableCell>
              <TableCell className={classes.tableCell}>Email</TableCell>
              <TableCell className={classes.tableCell} align="center">Actions</TableCell>
            </TableRow>
            {ExternalUsersStore.externalUsers.data.map((user) => (
              <TableRow>
                <TableCell>{user.firstname}</TableCell>
                <TableCell>{user.lastname}</TableCell>
                <TableCell>{user.company}</TableCell>
                <TableCell>{user.labels.map((label) => ROLES_TITLES[label]).join(" & ")}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell align="center">
                  <Button
                    color="primary"
                    component={RouterLink}
                    to={`/admin/external-users/${user._id}`}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => {
                      setExternalUserToDelete(user._id);
                    }}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Confirmation
        open={externalUserToDelete}
        onCancel={() => setExternalUserToDelete(null)}
        onConfirm={async () => {
          await ExternalUsersStore.removeExternalUser(externalUserToDelete);
          setExternalUserToDelete(null);
        }}
        alertText="Are you sure you want to delete this external user?"
      />
    </>
  );
});

export default withStyles(ExternalUsersTable, styles);
