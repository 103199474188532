import React, {useMemo} from "react";
import {Grid} from "@mui/material";
import {CgChevronLeft, CgChevronRight, CgChevronDoubleLeft, CgChevronDoubleRight} from "react-icons/cg";
import DrawerHeader from "@core/components/DrawerHeader";
import ArrowButton from "../../../Welding/components/WeldsCanvas/components/ArrowButton";
import {ENTITIES} from "../../../../constants";

const TITLE_BY_ENTITY = {
  [ENTITIES.WELD]: "Weld",
  [ENTITIES.PIPE]: "Joint"
};

const TestDrawerNavigation = ({close, text, secondPrevItem, prevItem, nextItem, secondNextItem, index, welds, pipes, entity, navigate}) => {
  const entities = useMemo(() => {
    if(entity === ENTITIES.WELD) return welds;

    return pipes;
  }, [entity, welds, pipes]);

  return (
    <DrawerHeader
      close={close}
      text={text}
    >
      {navigate && (
        <Grid item xs container justifyContent="flex-end" alignItems="center">
          <ArrowButton
            item={secondPrevItem}
            onClick={navigate}
          >
            <CgChevronDoubleLeft size={22}/>
          </ArrowButton>
          <ArrowButton
            item={prevItem}
            onClick={navigate}
          >
            <CgChevronLeft />
          </ArrowButton>
          <Grid item>{TITLE_BY_ENTITY[entity]} {index + 1} out of {entities.length}</Grid>
          <ArrowButton
            item={nextItem}
            onClick={navigate}
          >
            <CgChevronRight />
          </ArrowButton>
          <ArrowButton
            item={secondNextItem}
            onClick={navigate}
          >
            <CgChevronDoubleRight size={22}/>
          </ArrowButton>
        </Grid>
      )}
    </DrawerHeader>
  );
};

export default TestDrawerNavigation;