import React from "react";
import {withStyles} from "tss-react/mui";

import styles from "./styles";

const NotFoundPage = ({classes}) => {
  return (
    <div className={classes.container}>
      <img
        className={classes.notFoundImage}
        src="/images/error-404.gif"
        alt="Page is not found"
      />
    </div>
  );
};

export default withStyles(NotFoundPage, styles);

