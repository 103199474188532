import React from "react";
import {DEFECT_CONFIG} from "../../constants";
import {Rect} from "react-konva";
import {DEFECT_POSITIONS} from "../../../../constants";

const X_OFFSET_BY_POSITION = {
  [DEFECT_POSITIONS.MIDDLE]: (x, width) => x + (width / 2) - (DEFECT_CONFIG.WIDTH / 2),
  [DEFECT_POSITIONS.END]: (x, width) => x + width - DEFECT_CONFIG.WIDTH,
  [DEFECT_POSITIONS.START]: (x) => x,
};

const Defect = ({config, coordinates, defects, settings}) => {

  if(!settings.highlightCoatingDefects || !defects.length) return null;

  return defects.map((position) => (
    <Rect
      key={position}
      x={X_OFFSET_BY_POSITION[position](coordinates.x, config.WIDTH)}
      y={config.y}
      fill={DEFECT_CONFIG.BACKGROUND_COLOR}
      width={DEFECT_CONFIG.WIDTH}
      height={DEFECT_CONFIG.HEIGHT}
      cornerRadius={DEFECT_CONFIG.RADIUS}
    />
  ));
};

export default Defect;