import React, {useMemo} from "react";
import {uniq} from "ramda";
import {observer} from "mobx-react-lite";
import {TableRow, TableCell} from "@mui/material";
import {getDefectReports} from "../../../../../../../../helpers";
import useStores from "../../../../../../../../../../useStores";

const CoatingRow = ({stalk, totalDefects}) => {
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;

  const stalkNumber = stalk[0].stalkNumber;

  const joints = useMemo(() =>
    uniq(stalk.reduce((acc, weld) => acc.concat([weld.jointNumberA, weld.jointNumberB]), []))
  , [stalkNumber]);

  const welds = useMemo(() => stalk.map((weld) => weld.weldNumber), [stalkNumber]);

  const defectReports = useMemo(() => getDefectReports(campaign.tests), [campaign.tests]);

  const defects = useMemo(() => {
    return defectReports.reduce((defects, report) => {
      const filtered = report.properties.data.filter((data) => welds.includes(data["Weld Number"]));

      return defects.concat(filtered);
    }, []);
  }, [welds]);

  return (
    <TableRow>
      <TableCell>{stalkNumber}</TableCell>
      <TableCell>{joints.length}</TableCell>
      <TableCell>{defects.length}</TableCell>
      <TableCell>{(defects.length * 100 / totalDefects.length).toFixed(2)} %</TableCell>
    </TableRow>
  );
};

export default observer(CoatingRow);