import React, {memo, useMemo} from "react";
import {Line, Text, Ellipse, Group} from "react-konva";
import {PIPE_CONFIG, TIE_IN_WELD_CONFIG} from "../../constants";
import {PAGE_PADDING, STAGE_CONFIG} from "../../../../constants";
import {getTextScale} from "../../../../helpers";
import {getWeldIsRepaired} from "../../../../../../helpers";
import {REPAIRED_WELD_CONFIG} from "../../../Stalk/constants";

const TieInWeld = ({tieInWeld = {}, scale, stalkNumber, settings, setOpenWeld}) => {

  const color = useMemo(() => {
    const isRepaired = getWeldIsRepaired(tieInWeld.weldNumber);

    if(isRepaired && settings.highlightRepairedWelds) return REPAIRED_WELD_CONFIG.BACKGROUND_COLOR;

    return TIE_IN_WELD_CONFIG.TEXT_COLOR;
  }, [settings.highlightRepairedWelds, tieInWeld.weldNumber]);

  const textScale = useMemo(() => {
    return getTextScale(PIPE_CONFIG.TEXT_SCALE_BY, scale, PIPE_CONFIG.TEXT_MAX_SCALE, PIPE_CONFIG.TEXT_MIN_SCALE);
  }, [scale]);

  if(!tieInWeld.weldNumber || !settings.tieInWelds) return null;

  return (
    <Group
      x={tieInWeld.stalkNumber !== stalkNumber ? 0 : PIPE_CONFIG.WIDTH}
      y={-2 * PAGE_PADDING}
      onMouseEnter={() => document.body.style.cursor = "pointer"}
      onMouseLeave={() => document.body.style.cursor = "default"}
      onClick={() => setOpenWeld(tieInWeld.weldNumber)}
    >
      <Ellipse
        radius={{x: TIE_IN_WELD_CONFIG.WIDTH, y: TIE_IN_WELD_CONFIG.HEIGHT}}
        stroke={TIE_IN_WELD_CONFIG.BORDER_COLOR}
        shadowForStrokeEnabled={false}
        hitStrokeWidth={0}
        strokeWidth={TIE_IN_WELD_CONFIG.BORDER_WIDTH}
      />
      <Line
        points={[0, TIE_IN_WELD_CONFIG.HEIGHT, 0, TIE_IN_WELD_CONFIG.LINE_HEIGHT]}
        fill={TIE_IN_WELD_CONFIG.BORDER_COLOR}
        stroke={TIE_IN_WELD_CONFIG.BORDER_COLOR}
        shadowForStrokeEnabled={false}
        hitStrokeWidth={0}
        strokeWidth={TIE_IN_WELD_CONFIG.BORDER_WIDTH}
      />
      <Text
        x={-TIE_IN_WELD_CONFIG.WIDTH}
        y={-TIE_IN_WELD_CONFIG.HEIGHT}
        width={2 * TIE_IN_WELD_CONFIG.WIDTH / textScale}
        align="center"
        verticalAlign="middle"
        height={2 * TIE_IN_WELD_CONFIG.HEIGHT / textScale}
        fontSize={TIE_IN_WELD_CONFIG.FONT_SIZE}
        text={tieInWeld.weldNumber}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        scale={{x: textScale, y: textScale}}
        fill={color}
        fontStyle="bold"
      />
    </Group>
  );
};

export default memo(TieInWeld);