import AddDataDrawer from "@core/components/AddDataDrawer";
import CreateTests from "@core/components/CreateTests";
import {Button, ButtonGroup, Tooltip} from "@mui/material";
import React, {useState} from "react";
import {GoChecklist} from "react-icons/go";
import {RiFileAddFill} from "react-icons/ri";
import ImportData from "../../../../../Campaign/components/Header/components/TestActions/components/ImportData";

const ActionsGroupButton = () => {
  const [addTestsOpen, setAddTestsOpen] = useState(false);

  return (
    <>
      <ButtonGroup
        variant="contained"
        size="small"
      >
        <Button
          onClick={() => setAddTestsOpen(true)}
        >
          <RiFileAddFill size={18} />&nbsp;<span>Add tests</span>
        </Button>
        <Tooltip title="Approve multiple tests">
          <Button variant="containedGray">
            <GoChecklist size={24} />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <AddDataDrawer
        open={addTestsOpen}
        close={() => setAddTestsOpen(false)}
        ImportData={ImportData}
        CreateTests={CreateTests}
        assignTests={() => {}}
        onTestsCreated={() => {}}
        importData={() => {}}
      />
    </>
  );
};

export default ActionsGroupButton;