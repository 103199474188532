import React from "react";
import {TableCell, Box} from "@mui/material";

const SplitDiagonallyTableHeaderCell = ({topHeader, bottomHeader, width}) => {
  return (
    <TableCell
      width={width}
      sx={{
        position: "relative",
        backgroundImage: "linear-gradient(to bottom left, #fafafa 49%, #e0e0e0 50%, #fafafa 51%)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: 10,
          top: 5,
        }}
      >
        {topHeader}
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 10,
          bottom: 5,
        }}
      >
        {bottomHeader}
      </Box>
    </TableCell>
  );
};

export default SplitDiagonallyTableHeaderCell;