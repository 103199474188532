import React from "react";
import {withStyles} from "tss-react/mui";
import {Paper} from "@mui/material";

const CustomPaper = withStyles(
  ({classes, ...restProps}) => (<Paper {...restProps} classes={{root: classes.root}}/>),
  () => ({
    root: {
      boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
    }
  }));

export default CustomPaper;