import React from "react";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {isAcceptable} from "@core/helpers";
import classNames from "classnames";
import styles from "./styles";

const BendTest = ({test, classes}) => {
  const elements = test.properties.elements || [test.properties];

  return (
    <Grid container alignContent="space-between">
      <Grid item xs={12}>
        <Grid container alignContent="space-between" className={classes.row} spacing={2}>
          {test.properties.client && (
            <Grid item xs={6}>
              <h4 className={classes.label}>Client</h4>
              <p className={classes.field}>{test.properties.client}</p>
            </Grid>
          )}
          {test.properties.lab && (
            <Grid item xs={6}>
              <h4 className={classes.label}>Laboratory</h4>
              <p className={classes.field}>{test.properties.lab}</p>
            </Grid>
          )}
        </Grid>
        <Grid container alignContent="space-between" className={classes.row} spacing={2}>
          <Grid item xs={6}>
            <h4 className={classes.label}>Test standard</h4>
            <p className={classes.field}>{test.properties.testStandard}</p>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.label}>Acceptance criteria</h4>
            <p className={classes.field}>{test.properties.acceptance}</p>
          </Grid>
        </Grid>
        {elements.map((element, idx) => {
          const acceptable = isAcceptable(element.result);

          return (
            <Grid className={classes.elements} key={idx}>
              <Grid container className={classes.row}><h3 className={classes.field}>Test {idx + 1}</h3></Grid>

              <Grid container alignContent="space-between" className={classes.row} spacing={2}>
                <Grid item xs={4}>
                  <h4 className={classes.label}>Specimen Id</h4>
                  <p className={classes.field}>{element.specimenId}</p>
                </Grid>
                <Grid item xs={4}>
                  <h4 className={classes.label}>Specimen dimensions</h4>
                  <p className={classes.field}>{`${element.width} X ${element.height} mm`}</p>
                </Grid>
                <Grid item xs={4}>
                  <h4 className={classes.label}>Position</h4>
                  <p className={classes.field}>{element.position}</p>
                </Grid>
              </Grid>
              <Grid container alignContent="space-between" className={classes.row} spacing={2}>
                <Grid item xs={4}>
                  <h4 className={classes.label}>Plunger radius</h4>
                  <p className={classes.field}>{`${element.jig} mm`}</p>
                </Grid>
                <Grid item xs={4}>
                  <h4 className={classes.label}>Roll Distance</h4>
                  <p className={classes.field}>{`${element.rollsDistance} mm`}</p>
                </Grid>
                <Grid item xs={4}>
                  <h4 className={classes.label}>Bend angle</h4>
                  <p className={classes.field}>{`${element.angle} °`}</p>
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end" className={classes.row}>
                <h3 className={classNames({
                  [classes.greenField]: acceptable,
                  [classes.redField]: !acceptable
                })}>{element.result}</h3>
              </Grid>
            </Grid>
          );
        })}

        {test.properties.notes && (
          <Grid className={classes.row}>
            <h4 className={classes.label}>Notes</h4>
            <p
              className={classes.field}
              dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
            />
          </Grid>
        )}
      </Grid>

    </Grid>
  );
};

export default withStyles(BendTest, styles);
