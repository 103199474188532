export default (theme) => ({
  expandablePanelParent: {
    "&&": {
      marginBottom: theme.spacing(1),
    }
  },
  expandablePanelParentLast: {
    "&&&": {
      marginBottom: "initial"
    }
  },
  pendingStripe: {
    background: "#e6cc00"
  },
  successStripe: {
    background: theme.palette.success.main,
  },
  signatureAvatar: {
    fontSize: "1rem",
    alignSelf: "center"
  },
  companyNameButtonContainer: {
    marginTop: theme.spacing(2)
  },
});