export default (theme) => ({
  filtrationPopover: {
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column"
  },
  filtrationButton: {
    position: "absolute",
    top: 5,
    right: 5,
    color: "#000000b3",
    "&:hover": {
      color: theme.palette.primary.main,
    }
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  labelRoot: {
    marginRight: 0,
    whiteSpace: "nowrap",
  }
});