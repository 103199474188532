import React, {Component, createRef} from "react";
import {compose, isEmpty, isNil} from "ramda";
import {inject, observer} from "mobx-react";

import TimelineToggle from "@core/components/TimelineToggle";
import Summary from "../components/Summary";
import Loader from "@core/components/Loader";
import EntityNotFound from "@core/components/EntityNotFound";
import Test from "@core/components/Test";
import {Grid, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {STATUSES} from "@core/constants/test";
import styles from "./styles";

class ArchiveCertificateDetails extends Component {
  constructor(props) {
    super(props);

    this.testsContainer = createRef();

    this.state = {
      isLoading: true,
    };

    this.testsRefs = [];
  }

  componentDidMount() {
    this.getCertificate()
      .then(() => this.setState({isLoading: false}));
  }

  scrollTo = (_, id) => {
    this.testsRefs[id].scrollIntoView({behavior: "smooth", block: "start"});
  };

  getCertificate = async () => {
    const {match: {params: {id}}, CertificateStore} = this.props;
    await CertificateStore.getArchivedCertificate(id);
  };

  render() {
    const {isLoading} = this.state;
    const {classes, CertificateStore} = this.props;

    const certificate = CertificateStore.certificate;

    if (isLoading) {
      return <Loader />;
    }

    if (!certificate) {
      return <EntityNotFound entity='Archived certificated' />;
    }

    return (
      <>
        <div className={classes.main}>
          <Grid container spacing={3} className={classes.topSection}>
            <Grid item xs={8}>
              <Typography variant="h4" fontSize="1.8rem">
                Certificate {certificate.data._id}
              </Typography>
            </Grid>
          </Grid>
          <Summary certificate={certificate.data} onTestClick={this.scrollTo} />

          <div
            ref={this.testsContainer}
            className={classes.tests}
          >
            {certificate.data.tests && certificate.data.tests.filter((test) => test.status !== STATUSES.EMPTY).slice().reverse().map((test, idx) => {

              if (!test || isEmpty(test.properties) || isNil(test.properties)) {
                return null;
              }

              const createdTests = certificate.data.tests.filter((test) => test.status !== STATUSES.EMPTY).reverse();
              const isLast = certificate.data.transfers.length === 0 && idx === createdTests.length - 1;

              return (
                <div
                  key={test._id}
                  ref={(ref) => { this.testsRefs[test._id] = ref; }}
                  className={classes.test}
                >
                  <Test
                    certificate={certificate.data}
                    test={test}
                    isLast={isLast}
                    isFirst={!idx}
                    disabled
                  />
                </div>
              );
            })}
          </div>
        </div>
        <TimelineToggle
          refTest={this.testsContainer}
        />
      </>
    );
  }
}

export default compose(
  inject("NotificationStore", "CertificateStore", "ArchiveStore"),
)(withStyles(observer(ArchiveCertificateDetails), styles));
