import Modules from "@core/constants/modules";

export const TITLES = {
  [Modules.PRODUCER]: "Manufacturer",
  [Modules.BASIC]: "Basic",
  [Modules.LAB_INTERNAL]: "Laboratory",
  [Modules.SUPER_ADMIN]: "Admin",
  [Modules.END_OWNER]: "End Owner",
  [Modules.WITNESS]: "Witness",
};
