export default () => ({
  item: {
    backgroundColor: "#ffffff",
    padding: 11,
    marginTop: 3,
    cursor: "pointer",
    textAlign: "center",
    width: "100%",
    border: "1px solid #757575",
    borderRadius: 5,
    color: "#616161",
    fontSize: 14,
    lineHeight: 1.5,
    position: "relative"
  },
  closeIcon: {
    position: "absolute",
    top: 1,
    right: 2,
    "& svg": {
      fontSize: 16,
    }
  },
  droppableContainer: {
    height: "100%"
  },
  checkboxContainer: {
    "& span": {
      fontSize: 14,
    },
    whiteSpace: "nowrap",
  },
  buttonsContainer: {
    margin: 20,
  },
  title: {
    paddingBottom: 0,
  }
});