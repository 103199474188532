import React from "react";
import {Grid, DialogContent, Dialog} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExaminationConditions from "@core/components/ExaminationConditions";
import EditFormMagneticParticle from "@core/components/TestForms/MagneticParticle";
import styles from "./styles";

const MagneticParticle = ({
  classes,
  test,
  addDataOpen,
  dataDialogClose,
  updateTest,
  formRef
}) => {
  const {properties = {}} = test;
  const material = properties.materialObj || properties.material || {};
  const hasCleaning = properties.cleaning?.before || properties.cleaning?.after;

  return (
    <>
      <Grid container alignContent="space-between" spacing={2} className={classes.marginBottom}>
        <Grid item xs={6}>
          <h4 className={classes.name}>Test Standard</h4>
          <p className={classes.value}>{properties.testStandard}</p>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.name}>Acceptance criteria</h4>
          <p className={classes.value}>{properties.acceptance}</p>
        </Grid>
        <Grid item xs={12}>
          <h4 className={classes.name}>Examination conditions</h4>
          <p className={classes.value}>{properties.condition}</p>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.name}>Quantity Inspected</h4>
          <p className={classes.value}>{properties.quantityInspected}</p>
        </Grid>

        <Grid item xs={6}>
          <h4 className={classes.name}>Piece Identification:</h4>
          <p className={classes.value}>{properties.pieceIdentification}</p>
        </Grid>
      </Grid>

      <ExaminationConditions
        test={test}
        file={test.properties.file}
      />

      <Grid container>
        <h3>Magnetic particles</h3>
      </Grid>

      <Grid container justifyContent="space-between">
        {material.particles ?
          <React.Fragment>
            <Grid item xs={4}>
              <h4 className={classes.name}>Trade name</h4>
              <p className={classes.value}>{material.particles}</p>
            </Grid>

            <Grid item xs={4}>
              <h4 className={classes.name}>Form</h4>
              <p className={classes.value}>{material.type}</p>
            </Grid>

            <Grid item xs={4}>
              <h4 className={classes.name}>Type</h4>
              <p className={classes.value}>{material.visibility}</p>
            </Grid>

            <Grid container justifyContent="space-between" className={classes.marginBottom}>
              {material.solution && (
                <Grid item xs={4}>
                  <h4 className={classes.name}>Solution in</h4>
                  <p className={classes.value}>{material.solution}</p>
                </Grid>
              )}
              {material.concentration && (
                <Grid item xs={8}>
                  <h4 className={classes.name}>Concentration</h4>
                  <p className={classes.value}>{material.concentration}</p>
                </Grid>
              )}

            </Grid>
          </React.Fragment> :
          <React.Fragment>
            <Grid container justifyContent="space-between" className={classes.marginBottom}>
              <Grid item xs={6}>
                <h4 className={classes.name}>Form</h4>
                <p className={classes.value}>{material.type}</p>
              </Grid>

              <Grid item xs={6}>
                <h4 className={classes.name}>Type</h4>
                <p className={classes.value}>{material.visibility}</p>
              </Grid>

              {material.solution && (
                <Grid item xs={6}>
                  <h4 className={classes.name}>Solution in</h4>
                  <p className={classes.value}>{material.solution}</p>
                </Grid>
              )}
              {material.concentration && (
                <Grid item xs={6}>
                  <h4 className={classes.name}>Concentration</h4>
                  <p className={classes.value}>{material.concentration}</p>
                </Grid>
              )}

            </Grid>

          </React.Fragment>
        }
      </Grid>

      <Grid container>
        <h3>Examination technique</h3>
      </Grid>

      <Grid container justifyContent="space-between" spacing={2} className={classes.marginBottom}>
        {material.particles && (
          <Grid item xs={4}>
            <h4 className={classes.name}>Current type</h4>
            <p className={classes.value}>{properties.technique.currentType}</p>
          </Grid>
        )}

        {properties.technique?.current && (
          <Grid item xs={4}>
            <h4 className={classes.name}>Current value</h4>
            <p className={classes.value}>{properties.technique.current}</p>
          </Grid>
        )}

        {properties.technique?.examinedSurface && (
          <Grid item xs={6}>
            <h4 className={classes.name}>Examined surface</h4>
            <p className={classes.value}>{properties.technique.examinedSurface.join(", ")}</p>
          </Grid>
        )}

        <Grid item xs={6}>
          <h4 className={classes.name}>Demagnetization</h4>
          <p className={classes.value}>{properties.technique?.demagnetization}</p>
        </Grid>

        {properties.technique?.residualMagnetism && (
          <Grid item xs={8}>
            <h4 className={classes.name}>Residual magnetism</h4>
            <p className={classes.value}>{properties.technique?.residualMagnetism}</p>
          </Grid>
        )}

      </Grid>

      {hasCleaning && (
        <React.Fragment>
          <Grid container>
            <h3>Surface cleaning</h3>
          </Grid>

          <Grid container justifyContent="space-between" spacing={2} className={classes.marginBottom}>

            <Grid item xs={6}>
              <h4 className={classes.name}>Before examination</h4>
              <p className={classes.value}>{properties.cleaning.before}</p>
            </Grid>

            <Grid item xs={6}>
              <h4 className={classes.name}>After examination</h4>
              <p className={classes.value}>{properties.cleaning.after}</p>
            </Grid>

          </Grid>
        </React.Fragment>
      )}

      {properties.additionalRemarks && (
        <React.Fragment>
          <Grid container>
            <h3>Notes</h3>
          </Grid>
          <Grid container>
            <p className={classes.value}>{properties.additionalRemarks}</p>
          </Grid>
        </React.Fragment>
      )}

      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <EditFormMagneticParticle
            formRef={formRef}
            test={test}
            isProducerTest={true}
            saveTest={updateTest}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(MagneticParticle, styles);
