import {TEST_RESULTS} from "@core/constants/testResults";
import {getPoItemNumber} from "@core/helpers";
import React, {useEffect} from "react";
import {Button, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {FieldArray, Formik} from "formik";
import {isEmpty, keys} from "ramda";
import * as yup from "yup";
import TextField from "@core/components/FormikTextField";
import {FilesUploader} from "@core/components/Uploaders";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestFooter from "../../LabTestFooter";
import {
  TEST_STANDARDS,
  ACCEPTANCE_CRITERIA
} from "./data";
import styles from "./styles";
import MuiSelect from "@core/components/MuiSelect";

const validationSchema = yup.object().shape({
  client: yup.string().required("This field is required!"),
  lab: yup.string().required("This field is required!"),
  testStandard: yup.string().required("This field is required!"),
  acceptance: yup.string().required("This field is required!"),
  sampleID: yup.string().required("This field is required!"),
  temperature: yup.string().required("This field is required!"),
  position: yup.string(),
  orientation: yup.string().required("This field is required!"),
  yeildStrengthValue: yup.string().required("This field is required!"),
  tensileStrengthValue: yup.string(),
  yeildStrengthAtTestTemperature: yup.string().required("This field is required!"),
  youngModulus: yup.string(),
  requirements: yup.object().shape({
    crackTipOpeningDisplacementMin: yup.string(),
    crackTipOpeningDisplacementMax: yup.string(),
    crackTipOpeningDisplacementAverageMin: yup.string(),
    crackTipOpeningDisplacementAverageMax: yup.string()
  }),
  elements: yup.array().of(yup.object().shape({
    specimenId: yup.string().required("This field is required!"),
    a0: yup.string(),
    specimenLength: yup.string(),
    specimenWidth: yup.string().required("This field is required!"),
    appliedLoad: yup.string().required("This field is required!"),
    clipGageDisplacement: yup.string().required("This field is required!"),
    specimenThickness: yup.string().required("This field is required!"),
    loadRate: yup.string().required("This field is required!"),
    crackTipOpeningDisplacement: yup.string()
  })),
  notes: yup.string()
});

const ELEMENT = {
  specimenId: "",
  a0: "",
  specimenLength: "",
  specimenWidth: "",
  specimenThickness: "",
  loadRate: "",
  appliedLoad: "",
  clipGageDisplacement: "",
  crackTipOpeningDisplacement: ""
};

const REQUIREMENTS = {
  crackTipOpeningDisplacementMin: "",
  crackTipOpeningDisplacementMax: "",
  crackTipOpeningDisplacementAverageMin: "",
  crackTipOpeningDisplacementAverageMax: ""
};

const getAverageCTODValue = (elements) => {
  const sum = elements.reduce((acc, element) => {
    acc += Number(element.crackTipOpeningDisplacement);

    return acc;
  }, 0);

  return sum / elements.length;
};

const isTestAcceptable = ({elements, requirements}) => {
  const elementsSingleAcceptable = elements.every((element) => {
    const min = Number(requirements.crackTipOpeningDisplacementMin);
    const max = Number(requirements.crackTipOpeningDisplacementMax);

    const value = Number(element.crackTipOpeningDisplacement);

    return value >= min && (!max || value <= max);
  });

  const averageMin = Number(requirements.crackTipOpeningDisplacementAverageMin);
  const averageMax = Number(requirements.crackTipOpeningDisplacementAverageMax);

  const averageValue = getAverageCTODValue(elements);

  const averageValueAcceptable = averageValue >= averageMin && (!averageMax || averageValue <= averageMax);

  return elementsSingleAcceptable && averageValueAcceptable;
};

const getResult = (isAcceptable) => {
  if (isAcceptable) return TEST_RESULTS.ACCEPTABLE;

  return TEST_RESULTS.NOT_ACCEPTABLE;
};

const CrackTipOpeningDisplacement = ({
  test,
  certificate,
  user,
  saveTest,
  client,
  formRef,
  classes
}) => {
  const initialValues = {
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name || "",
    testStandard: "",
    acceptance: "",
    sampleID: "",
    temperature: "",
    position: "",
    orientation: "",
    yeildStrengthValue: "",
    tensileStrengthValue: "",
    yeildStrengthAtTestTemperature: "",
    youngModulus: "",
    requirements: REQUIREMENTS,
    elements: [ELEMENT, ELEMENT, ELEMENT],
    files: [],
    notes: "",
    result: ""
  };

  const filteredAcceptances= Object.keys(ACCEPTANCE_CRITERIA).reduce(function(r, e) {
    if (!ACCEPTANCE_CRITERIA[e].hasOwnProperty("company") || ACCEPTANCE_CRITERIA[e].company.includes(user.company.name)) r[e] = ACCEPTANCE_CRITERIA[e];

    return r;
  }, {});

  const onSubmit = (values) => {
    const isAcceptable = isTestAcceptable(values);
    const result = getResult(isAcceptable);
    saveTest({...values, result});
  };

  return (
    <Formik
      onSubmit={onSubmit}
      innerRef={formRef}
      initialValues={isEmpty(test.properties) ? initialValues : {...initialValues, ...test.properties}}
      validationSchema={validationSchema}
      enableReinitialize
      render={(props) => {
        const isAcceptable = props.isValid && isTestAcceptable(props.values);
        const result = getResult(isAcceptable);

        useEffect(() => {
          if (!props.touched.acceptance) return;

          const poItemNumber = getPoItemNumber(certificate.lineItem);
          const values = ACCEPTANCE_CRITERIA[props.values.acceptance] || {};
          const {requirements, temperatureByPoItem, orientation} = values;

          const temperature = temperatureByPoItem ? temperatureByPoItem[poItemNumber] : values.temperature;

          props.setValues(() => ({
            ...props.values,
            requirements: requirements || REQUIREMENTS,
            temperature: temperature,
            orientation,
          }));
        }, [props.values.acceptance]);

        return (
          <Grid container spacing={2}>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <ClientField isFromProducer={!!client.name} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  name="lab"
                  label="Laboratory"
                  required
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={TEST_STANDARDS}
                />
              </Grid>
              <Grid item xs={6}>
                <MuiSelect
                  required
                  label="Acceptance criteria"
                  name="acceptance"
                  defaultOptions={keys(filteredAcceptances)}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  name="sampleID"
                  label="Sample ID"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="temperature"
                  label="Test temperature"
                  required
                  endAdornment="°C"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="position"
                  label="Position"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="orientation"
                  label="Orientation"
                  required
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="yeildStrengthValue"
                  label="Yield strength value"
                  required
                  endAdornment="MPa"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="yeildStrengthAtTestTemperature"
                  label="Yield strength at test temperature"
                  required
                  endAdornment="MPa"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="tensileStrengthValue"
                  label="Tensile strength value"
                  endAdornment="MPa"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="youngModulus"
                  label="Young modulus"
                  endAdornment="MPa"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item>
                <h3>Acceptance criteria</h3>
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.crackTipOpeningDisplacementMin"
                  label="CTOD min"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.crackTipOpeningDisplacementMax"
                  label="CTOD max"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.crackTipOpeningDisplacementAverageMin"
                  label="CTOD average min"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.crackTipOpeningDisplacementAverageMax"
                  label="CTOD average max"
                />
              </Grid>
              <Grid item xs={12}>
                <FilesUploader
                  label="Displacement curve"
                  name="files"
                  files={props.values.files}
                  onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                  changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                />
              </Grid>
            </Grid>
            <FieldArray name="elements">
              {({remove, push}) => props.values.elements.map((element, index) => (
                <>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenId`}
                        label="Specimen ID"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type="number"
                        name={`elements.${index}.a0`}
                        label="Final pre-crack length"
                        endAdornment="MM"
                      />
                    </Grid>
                    {props.values.elements.length > 1 && (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    )}
                    {props.values.elements.length - 1 === index && (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          onClick={() => push(ELEMENT)}
                        >
                          Add
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenLength`}
                        label="Specimen Length"
                        endAdornment="MM"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenWidth`}
                        label="Specimen Width"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenThickness`}
                        label="Specimen Thickness"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <TextField
                        type="number"
                        name={`elements.${index}.appliedLoad`}
                        label="Applied load"
                        endAdornment="N"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type="number"
                        name={`elements.${index}.clipGageDisplacement`}
                        label="Clip Gage (Vp) Displacement"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type="number"
                        name={`elements.${index}.loadRate`}
                        label="Load (K) rate"
                        required
                        endAdornment={(<span>MPa*m<sup>0.5</sup>/s</span>)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5}>
                    <Grid item>
                      <h3>Test results</h3>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3} container alignItems="flex-end">
                      {props.values.requirements.crackTipOpeningDisplacementMin && (
                        <Grid xs={2} className={classes.requirementMax} item container justifyContent="center">
                          {props.values.requirements.crackTipOpeningDisplacementMin}&nbsp;&nbsp;{"\u2264"}
                        </Grid>
                      )}
                      <Grid item xs>
                        <TextField
                          type="number"
                          name={`elements.${index}.crackTipOpeningDisplacement`}
                          label="CTOD"
                          endAdornment="MM"
                          required={props.values.requirements.crackTipOpeningDisplacementMin || props.values.requirements.crackTipOpeningDisplacementMax}
                        />
                      </Grid>
                      {props.values.requirements.crackTipOpeningDisplacementMax && (
                        <Grid xs={2} className={classes.requirementMax} item container justifyContent="center">
                          {"\u2264"}&nbsp;&nbsp;{props.values.requirements.crackTipOpeningDisplacementMax}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              ))}
            </FieldArray>
            <Grid item container spacing={5}>
              <Grid item>
                <h3>Average CTOD: {getAverageCTODValue(props.values.elements).toFixed(2)}</h3>
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  rows={4}
                  multiline
                  name="notes"
                  label="Additional remarks"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TestFooter
                onSubmit={onSubmit}
                result={result}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default withStyles(CrackTipOpeningDisplacement, styles);
