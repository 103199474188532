import React from "react";
import {Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment";
import importedColumns from "@core/columns/test";
import {STATUSES} from "@core/constants/test";
import modules from "@core/constants/modules";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import {prop, uniqBy} from "ramda";
import {getTestName} from "@core/helpers";

const columns = uniqBy(prop("dataIndex"), [
  {
    isSorted: "true",
    dataIndex: "type",
    title: "Test type",
    render: (test) => {
      const testName = getTestName(test) || "";

      if (!test.certificateId || test.certificateSigned) return testName;

      return (
        <Tooltip title="Сertificate is not yet signed">
          <span>
            <InfoIcon color="secondary" style={{verticalAlign: "middle", fontSize: "18px"}} />&nbsp;
            {testName}
          </span>
        </Tooltip>
      );
    }
  },
  {
    dataIndex: "inspectionDate",
    title: "Inspection date",
    render: (test) => {
      const attestTransaction = test.transactions.find((transaction) => transaction.status === STATUSES.INSPECTED);

      if (attestTransaction) return moment(attestTransaction.date_created).format(DATE_FORMAT);

      if (test.inspectionDate) return moment(test.inspectionDate).format(DATE_FORMAT);

      return "-";
    }
  },
  ...importedColumns,
]);

const TABS = {
  AVAILABLE_TO_SIGN: "available to sign",
  NOT_AVAILABLE_TO_SIGN: "not available to sign"
};

const LINK_BY_MODULE = {
  [modules.LAB_INTERNAL]: "/tests/multiSigning",
  [modules.WITNESS]: "/witness/multiSigning",
  [modules.PRODUCER]: "/tests/multiSigning",
  [modules.END_OWNER]: "/tests/multiSigning"
};

const NAVIGATION_CONFIG = {
  [modules.PRODUCER]: {
    [TABS.AVAILABLE_TO_SIGN]: [{
      title: "Show unsigned certificates",
      value: {showUnsignedCertificates: true},
      defaultChecked: false,
    }]
  },
  [modules.END_OWNER]: {
    [TABS.AVAILABLE_TO_SIGN]: [{
      title: "Show unsigned certificates",
      value: {showUnsignedCertificates: true},
      defaultChecked: false,
    }]
  },
};

const WITNESS_REVIEW_STATUSES_BY_TAB = {
  [TABS.AVAILABLE_TO_SIGN]: [WITNESS_STATUSES.UNSET, WITNESS_STATUSES.NOT_ACCEPTED],
};

const TEST_STATUSES_BY_MODULE = {
  [modules.LAB_INTERNAL]: {
    [TABS.AVAILABLE_TO_SIGN]: [STATUSES.FILLED],
    [TABS.NOT_AVAILABLE_TO_SIGN]: [STATUSES.INSPECTED, STATUSES.INSPECTING, STATUSES.ASSIGNED, STATUSES.SUBMITTED, STATUSES.EMPTY],
  },
  [modules.WITNESS]: {
    [TABS.AVAILABLE_TO_SIGN]: [STATUSES.INSPECTING],
    [TABS.NOT_AVAILABLE_TO_SIGN]: [STATUSES.FILLED, STATUSES.ASSIGNED, STATUSES.SUBMITTED, STATUSES.INSPECTED, STATUSES.EMPTY],
  },
  [modules.PRODUCER]: {
    [TABS.AVAILABLE_TO_SIGN]: [STATUSES.FILLED, STATUSES.SUBMITTED, STATUSES.INSPECTED],
    [TABS.NOT_AVAILABLE_TO_SIGN]: [STATUSES.INSPECTING, STATUSES.ASSIGNED, STATUSES.EMPTY],
  },
  [modules.END_OWNER]: {
    [TABS.AVAILABLE_TO_SIGN]: [STATUSES.FILLED, STATUSES.SUBMITTED, STATUSES.INSPECTED],
    [TABS.NOT_AVAILABLE_TO_SIGN]: [STATUSES.INSPECTING, STATUSES.ASSIGNED, STATUSES.EMPTY],
  },
};

export {columns, TABS, TEST_STATUSES_BY_MODULE, LINK_BY_MODULE, WITNESS_REVIEW_STATUSES_BY_TAB, NAVIGATION_CONFIG};
