import React, {useState, useEffect} from "react";
import {observer} from "mobx-react";
import useStores from "../../../../useStores";
import roles from "../../../constants/roles";
import {Checkbox, FormControl, FormControlLabel, Grid, FormGroup} from "@mui/material";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import styles from "../styles";
import {Field} from "formik";

const Features = observer(({classes}) => {
  const {FeatureStore, UserStore, NotificationStore} = useStores();

  const [features, setFeatures] = useState([]);
  const fetchFeatures = async () => setFeatures(await FeatureStore.getFeatures());

  useEffect(() => {
    fetchFeatures();
  }, []);

  const user = UserStore.user.data;
  const editable = user.role === roles.SUPER_ADMIN;

  return (
    <Grid container className={classes.mb25} spacing={3}>
      <Grid item xs={3}>
        <h4 className={classNames(classes.label)}>
          Features
          <span className={classes.proLabel}> PRO</span>
        </h4>
        <Field name="features">
          {({form, meta}) => {
            const {error, touched, value} = meta;
            const {setFieldTouched, setFieldValue} = form;

            return (
              <FormControl
                name="features"
                component="fieldset"
                error={Boolean(error) && touched}
              >
                <FormGroup>
                  {features.map((feature) => (
                    <FormControlLabel
                      disabled={!editable}
                      key={feature._id}
                      value={feature._id}
                      control={(
                        <Checkbox
                          checked={value.includes(feature._id)}
                          onChange={(event) => {
                            if (event.target.checked) setFieldValue("features", [...value, feature._id]);
                            else setFieldValue("features", value.filter((f) => f !== feature._id));

                            setFieldTouched("features", true, false);
                          }}
                          value={feature._id}
                          color="primary"
                        />
                      )}
                      label={feature.name}
                      onClick={() => {
                        if(editable) return;

                        NotificationStore.showInfo("Please, contact you supervisor to enable this feature!");
                      }}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            );
          }}
        </Field>
      </Grid>
    </Grid>
  );
});

export default withStyles(Features, styles);
