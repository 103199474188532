import React, {Component} from "react";
import ReactDOM from "react-dom";
import {inject, observer} from "mobx-react";
import Notifications from "./Notifications";
import Signing from "./Signing";
import {compose} from "ramda";

const notificationRoot = document.getElementById("notification-root");

class Modal extends Component {
  constructor(props) {
    super(props);

    this.el = document.createElement("div");
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    notificationRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    notificationRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <>
        <Notifications store={this.props.NotificationStore} />
        <Signing store={this.props.SigningStore} />
      </>,
      this.el
    );
  }
}

export default compose(
  inject("NotificationStore", "SigningStore"),
  observer
)(Modal);
