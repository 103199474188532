import themeSettings from "../../../../../../theme-settings";
import {isNil} from "ramda";

export const PAGE_PADDING = 20;

export const SPACING_BETWEEN_STALKS = 6 * PAGE_PADDING;

export const ARROW_BUTTONS_KEY_CODES = {
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
};

export const STAGE_CONFIG = {
  PADDING: PAGE_PADDING * 6,
  FONT_FAMILY: "Inter",
  SCALE_BY: 1.1,
  DRAG_DELTA: 2,
  KEY_DRAG_DELTA: 4,
  MAX_SCALE: 2.5,
  MIN_SCALE: 0.3,
  BACKGROUND_COLOR: themeSettings.palette.backgroundColor.main
};

export const STALK_NUMBER_CONFIG = {
  TEXT_COLOR: themeSettings.palette.text.primary,
  FONT_SIZE: 25,
};

export const PIPE_TYPES = {
  DOUBLE_RED_BAND: "DOUBLE_RED_BAND",
  SINGLE_RED_BAND: "SINGLE_RED_BAND",
  BLACK_BANDED_ANODE_JOINT: "BLACK_BANDED_ANODE_JOINT",
  TRANSITION_JOINT: "TRANSITION_JOINT",
  QUARANTINED: "QUARANTINED",
  BASE_MATERIAL: "BASE_MATERIAL",
  COATED: "COATED",
  WITH_TYPE: "WITH_TYPE",
  WITH_DEFECT: "WITH_DEFECT"
};

export const PIPE_WITH_TYPE_CONFIG = {
  BACKGROUND_COLORS: ["#3E7F8F", "#2db9d9", "#d171df"]
};

export const STATE = {
  AS_FABRICATED: "AS_FABRICATED",
  AS_BUILT: "AS_BUILT",
};

export const VIEWS = {
  GLOBAL: "GLOBAL",
  DETAILED: "DETAILED",
  COATING: "COATING"
};

export const ALL_STALKS = "All Stalks";

export const DEFAULT_SCALE_BY_VIEW = {
  [VIEWS.GLOBAL]: 0.5,
  [VIEWS.DETAILED]: 0.5,
  [VIEWS.COATING]: 0.5,
};

export const DEFAULT_COORDINATES = {
  x: 0,
  y: 0
};

export const SPOOL_LABEL = "SPOOL";
export const STALKS_LABEL = "STALK";
export const BASE_MATERIALS_LABEL = "BASE MATERIALS";
export const QUARANTINE_LABEL = "QUARANTINE";

export const LEGEND_KEY = "campaignLegend";
export const VIEW_KEY = "campaignView_";

const showLegendSavedValue = JSON.parse(localStorage.getItem(LEGEND_KEY));

const DEFAULT_SETTINGS = {
  stalkNumber: null,
  anodes: true,
  welds: true,
  tieInWelds: true,
  highlightRepairedWelds: true,
  splitStalk: false,
  showStats: false,
  baseMaterials: true,
  quarantine: true,
  legend: (isNil(showLegendSavedValue) ? true : showLegendSavedValue),
  length: true,
  coatingType: false,
  highlightCoatingDefects: false,
  type: true,
};

export const SETTINGS_BY_VIEW = {
  [VIEWS.GLOBAL]: (state) => ({
    ...DEFAULT_SETTINGS,
    splitStalk: state === STATE.AS_BUILT,
    quarantine: state === STATE.AS_FABRICATED,
    length: state === STATE.AS_FABRICATED,
  }),
  [VIEWS.DETAILED]: (state) => ({
    ...DEFAULT_SETTINGS,
    splitStalk: true,
    baseMaterials: false,
    quarantine: state === STATE.AS_FABRICATED,
    length: state === STATE.AS_FABRICATED,
  }),
  [VIEWS.COATING]: (state) => ({
    ...DEFAULT_SETTINGS,
    highlightRepairedWelds: false,
    splitStalk: true,
    length: state === STATE.AS_FABRICATED,
    baseMaterials: false,
    quarantine: false,
    coatingType: true,
    highlightCoatingDefects: true,
    type: false,
  }),
};

export const DEFECT_POSITIONS = {
  MIDDLE: "MIDDLE",
  START: "START",
  END: "END"
};