export default (theme) => ({
  select: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    marginBottom: theme.spacing(2),
  }
});

