import React from "react";
import {Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {AiOutlineWarning} from "react-icons/ai";
import ErrorIcon from "@mui/icons-material/Error";
import {Input} from "@core/components/Form";
import styles from "./styles";
const EditableCell = ({classes, componentClasses, Component, editable, value, setValue, warning, error, warningMessage, errorMessage, ...props}) => {
  if(!editable) return Number(value) || value?.length ? value : "-";

  const InputComponent = Component || Input;

  return (
    <div className={classes.container}>
      <InputComponent
        classes={{
          input: classes.input,
          root: classes.inputRoot,
          ...componentClasses,
        }}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        warning={warning}
        error={error}
        {...props}
      />
      {(warningMessage && warning) && (
        <Tooltip title={warningMessage}>
          <AiOutlineWarning className={classes.warningIcon} />
        </Tooltip>
      )}
      {(errorMessage && error) && (
        <Tooltip title={errorMessage}>
          <ErrorIcon className={classes.errorIcon} />
        </Tooltip>
      )}
    </div>
  );
};

export default withStyles(EditableCell, styles);