import React, {useMemo, useState} from "react";
import {ACTION_LABELS, TRANSACTION_ACTIONS, TRANSACTION_DECLINED} from "@core/components/TimelineBlock/const";
import DetailsContent from "../DetailsContent";
import DisabledExpansionPanel from "../DisabledExpansionPanel";
import OpenCompanyInfoButton from "../OpenCompanyDialogButton";
import PanelSummaryContent from "../PanelSummaryContent";
import Stripe from "../Stripe";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "@core/components/TimelineBlock/accordionComponents";
import Avatar from "@core/components/ColoredModuleAvatar";
import moment from "moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {inject, observer} from "mobx-react";
import {compose} from "ramda";
import styles from "./styles";

function TransactionList({test, futureLabels, classes}) {
  const handlePanelExpand = (panelId) => (event, expanded) => {
    setExpandedPanelId(expanded ? panelId : "");
  };

  const transactionsToDisplay = useMemo(() => {
    const {transactions = []} = test;
    let validTransactions = transactions.filter((transaction) => transaction.status !== TRANSACTION_DECLINED);
    const shouldHideInspected = !!validTransactions
      .find((transaction) => [TRANSACTION_ACTIONS.assign, TRANSACTION_ACTIONS.witnessRequest].includes(transaction.action));
    validTransactions = shouldHideInspected ?
      validTransactions.filter((transaction) => transaction.action !== TRANSACTION_ACTIONS.inspect) :
      validTransactions;

    validTransactions = validTransactions
      .map((item) => ({transaction: item, itemKey: item._id, isFuture: false, futureLabel: ""}));

    return [
      ...validTransactions,
      ...futureLabels.map((label) => ({transaction: null, itemKey: `tl-panel-${label}`, isFuture: true, futureLabel: label}))
    ].reverse();
  }, [test, futureLabels]);
  
  const defaultExpandedPanel = useMemo(() => {
    const transactionToExpand = transactionsToDisplay.find((item) => item.isFuture === false) || {};

    return transactionToExpand.itemKey;
  }, [test._id, transactionsToDisplay]);

  const [expandedPanelId, setExpandedPanelId] = useState(defaultExpandedPanel || "");

  if (!transactionsToDisplay?.length) {
    return <></>;
  }

  return (
    <>
      {transactionsToDisplay.map(({transaction, itemKey, isFuture, futureLabel}, index) => {
        if(transaction && !transaction._id) return null;

        const isLastAction = index === transactionsToDisplay?.length - 1;
        const additionalExpansionPanelRootClass = isLastAction ? classes.expandablePanelParentLast : classes.expandablePanelParent;

        if (isFuture) {
          return (
            <DisabledExpansionPanel
              key={futureLabel}
              header={futureLabel}
              additionalExpansionPanelRootClass={additionalExpansionPanelRootClass}
              stripeComponent={<Stripe customClasses={classes.pendingStripe}/>}/>
          );
        } else {
          const getActionLabel = ACTION_LABELS[transaction.action];
          const [module] = transaction.signer.company.modules;

          return (
            <ExpansionPanel
              key={itemKey}
              expanded={expandedPanelId === itemKey}
              onChange={handlePanelExpand(itemKey)}
              additionalClasses={additionalExpansionPanelRootClass && {root: additionalExpansionPanelRootClass}}>
              <ExpansionPanelSummary>
                <Stripe customClasses={classes.successStripe}/>
                <PanelSummaryContent actionLabel={getActionLabel(transactionsToDisplay)}>
                  <Avatar className={classes.signatureAvatar} user={transaction.signer} module={module.name} />
                </PanelSummaryContent>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Stripe customClasses={classes.successStripe}/>
                <DetailsContent
                  signatureId={transaction.signature}
                  name={transaction.signer && `${transaction.signer.firstname} ${transaction.signer.lastname}`}
                  company={transaction.signer.company && transaction.signer.company?.name || ""}
                  date={moment(transaction.date_created).format(DATE_FORMAT)}>
                  {
                    !!transaction.signer &&
                    <Grid container justifyContent="flex-end" classes={{container: classes.companyNameButtonContainer}}>
                      <OpenCompanyInfoButton transaction={transaction}/>
                    </Grid>
                  }
                </DetailsContent>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        }
      })}
    </>
  );
}

TransactionList.defaultProps = {
  futureLabels: []
};

export default compose(
  inject("CompanyStore"),
)(withStyles(observer(TransactionList), styles));