import React from "react";
import classNames from "classnames";
import {withStyles} from "tss-react/mui";
import userStatuses from "@core/constants/userStatuses";
import styles from "./styles";

const UserStatus = ({classes, status}) => {
  return (
    <div
      className={classNames(classes.tag, {
        [classes.enabled]: status === userStatuses.ENABLED,
        [classes.disabled]: status === userStatuses.DISABLED,
        [classes.invited]: status === userStatuses.PENDING,
      })}
    >
      {status}
    </div>
  );
};

export default withStyles(UserStatus, styles);