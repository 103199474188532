import AcceptanceRow from "@core/components/QcpSideBar/components/TensileTest/components/AcceptanceRow";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const Acceptance = ({config}) => {
  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Yield Strength [MPa]</TableCell>
          <TableCell>Tensile Strength [MPa]</TableCell>
          <TableCell>Elongation [%]</TableCell>
          <TableCell>Uniform Elongation [%]</TableCell>
          <TableCell>Yield/Tensile Ratio</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <AcceptanceRow
          {...config}
        />
      </TableBody>
    </Table>
  );
};

export default Acceptance;