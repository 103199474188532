import testsConfig from "@core/configs/test";
import modules from "@core/constants/modules";
import {TYPES} from "@core/constants/test";

export const getAllowedTestTypes = (module) => {
  const allTypes = Object.values(testsConfig).filter((test) => test.type !== TYPES.SPLIT);
  
  if (module === modules.LAB_INTERNAL) {
    return allTypes.filter((config) => config.responsible.includes(module));
  }

  const typesWithForm = allTypes.filter((test) => test.addDataForm || test.testForm);

  return typesWithForm;
};