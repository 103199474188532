import React, {useContext, useEffect} from "react";
import {ConfirmationOnUrlChangeContext} from "@core/components/ConfirmationOnUrlChange/context";
import {SHOULD_BLOCK_DEFAULT} from "@core/constants/shouldBlockDefault";

function NavigationBlockerConsumer({shouldBlock, children}) {
  const {setShouldBlock} = useContext(ConfirmationOnUrlChangeContext);
  
  useEffect(() => {
    setShouldBlock(() => shouldBlock);
    
    return () => {
      setShouldBlock(() => SHOULD_BLOCK_DEFAULT);
    };
  }, []);
  
  return (
    <>{children}</>
  );
}

export default NavigationBlockerConsumer;