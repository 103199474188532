import React from "react";
import RouterLink from "@core/components/RouterLink";
import importedColumns from "@core/columns/test";
import {Button} from "@mui/material";
import {TEST_ID_PARAM} from "@core/constants/test";
import {TABS} from "../../../components/TestsList/data";

const getColumns = (tab) => {
  if(tab === TABS.BIN) return importedColumns;

  return [{
    dataIndex: "action",
    permanent: true,
    title: "Action",
    align: "center",
    render: (test) => (
      <Button
        component={RouterLink}
        to={`/certificates/${test.certificateId}?${TEST_ID_PARAM}=${test._id}`}
      >
        View
      </Button>
    )
  }, ...importedColumns];
};

export {getColumns};
