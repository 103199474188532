import React, {useCallback, useEffect, useState} from "react";
import {Button, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import qs from "qs";
import {isEmpty, map, prop, values, tail, flatten} from "ramda";
import InputAdornment from "@mui/material/InputAdornment";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import WS from "@core/api/socketConnection";
import BasicCompUpgrade from "@core/components/Modal/BasicCompUpgrade";
import CertificateDeleteConfirmationModal from "@core/components/CertificateDeleteConfirmationModal";
import SignCertificateModal from "../components/SignCertificateModal";
import ColumnsSelect from "@core/components/ColumnsSelect";
import TableFooter from "@core/components/TableFooter";
import {Input} from "@core/components/Form";
import Table from "@core/components/Table";
import TableNavigation from "@core/components/TableNavigation";
import {getColumns} from "@core/columns/certificate";
import {STATUSES, TYPES} from "@core/constants/test";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import {TestService} from "@core/services";
import MultipleConvertModal from "../components/MultipleConvertModal";
import AddTests from "../components/AddTests";
import BulkCreateCertificates from "./components/BulkCreateCertificates";
import {CERTIFICATE_VIEWS, CERTIFICATE_VIEWS_TITLES} from "./data";
import styles from "./styles";
import useStores from "../../useStores";
import RouterLink from "@core/components/RouterLink";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import useSetInitialViewQueryParam from "@core/hooks/useSetInitialViewQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const List = observer(({location, match, classes}) => {
  const [convertingModalOpened, setConvertingModalOpened] = useState(false);
  const [isBasicUpgradeOpen, setIsBasicUpgradeOpen] = useState(false);
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const [unsignedCertificates, setUnsignedCertificates] = useState([]);

  const {normsByMaterial} = useFetchedCertificateNorms();
  const columns = getColumns(normsByMaterial);

  const savedSelectedColumns = JSON.parse(localStorage.getItem(match.path));
  const [selectedColumns, setSelectedColumns] = useState(savedSelectedColumns || map(prop("dataIndex"), columns));

  const {TestStore, CertificateStore, UserStore, SigningStore, NotificationStore} = useStores();

  const {status, data: certificates, total, fetch, selected} = CertificateStore.certificates;

  const view = qs.parse(tail(location.search)).view || CERTIFICATE_VIEWS.ALL;

  const isProducer = UserStore.isProducer();

  useSetInitialViewQueryParam(view);

  useEffect(() => {
    WS.listen("transaction:test:assign", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") return;

      NotificationStore.showSuccess("Successfully assigned!");
    });

    WS.listen("transaction:certificate:sign", (data) => {
      if (data.status === "DECLINED") {
        NotificationStore.showError("Signing failed!");

        return;
      }

      if (data.status === "ACCEPTED") {
        NotificationStore.showSuccess("Successfully signed!");
        CertificateStore.closeForceSignCertificate();
        CertificateStore.certificates.setSelected([]);
        CertificateStore.certificates.load();
        SigningStore.closeSigner();
      }
    });

    return () => CertificateStore.certificates.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => CertificateStore,
    dataKey: STORE_DATA.certificates,
    matchPath: match.path,
    defaultParams: {view},
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    CertificateStore.certificates.load({limit, offset: 0});
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    CertificateStore.certificates.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  const onDelete = () => {
    if (!selected.length) return;

    setIsConfirmationOpened(true);
  };

  const handleActionCancel = () => {
    setIsConfirmationOpened(false);
  };

  const handleActionConfirm = (deleteTests) => {
    CertificateStore.deleteCertificates(selected, deleteTests)
      .then(() => {
        setIsConfirmationOpened(false);
        CertificateStore.certificates.setSelected([]);
        CertificateStore.certificates.load();
      });
  };

  const onSelect = useCallback(
    (ids) => CertificateStore.certificates.setSelected(ids),
    [CertificateStore.certificates.setSelected]
  );

  const multipleConvert = () => {
    if (UserStore.isBasic()) {
      setIsBasicUpgradeOpen(true);

      return;
    }

    const unsignedCertificates = CertificateStore.selectedCertificates.filter((item) => item.transactions.length === 0);
    setUnsignedCertificates(unsignedCertificates);

    if (!isEmpty(unsignedCertificates)) CertificateStore.openForceSignCertificate();
    else setConvertingModalOpened(true);
  };

  const createTests = async (testsToCreate, testDataByType) => {
    const certificateIds = map(prop("_id"), CertificateStore.selectedCertificates);
    const response = await TestStore.createMultipleTestsForMultipleCertificates(certificateIds, testsToCreate);
    await TestService.assignTestsToMultipleCertificates(response, testDataByType);
  };

  const getNewTestsByCertificateId = (test, data, certificates) => {
    return certificates.reduce((testsByCertificateId, certificate, index) => {
      const inspectedInfo = certificates.length > 1 && data.items ? data.items[index] : {};

      const properties = {
        ...test.properties,
        ...inspectedInfo,
      };

      const isApproved = certificate.tests.length && certificate.tests.every((test) => test.status === STATUSES.APPROVED);

      if (test.type === TYPES.INSPECTION_RELEASE_NOTE && !isApproved) return testsByCertificateId;

      if (test.type === TYPES.INSPECTION_RELEASE_NOTE) {
        properties.testIds = map(prop("_id"), certificate.tests);
      }

      const newTest = {
        ...test,
        properties,
        productType: certificate.properties.productType._id,
        item_heat: certificate.heat,
        lotId: certificate.properties.lotId,
        internalWorkOrder: certificate.internalWorkOrder,
        internalItemNumber: certificate.internalItemNumber,
        norm: certificate.properties.norm,
        grade: certificate.properties.grade,
        inspectionDate: data.inspectionDate,
        inspectorJobNumber: data.inspectorJobNumber,
      };

      const certificateTests = testsByCertificateId[certificate._id] || [];
      testsByCertificateId[certificate._id] = [...certificateTests, newTest];

      return testsByCertificateId;
    }, {});
  };

  const createTestWithData = async (test, data, allowToSign) => {
    const testsByCertificateId = getNewTestsByCertificateId(test, data, CertificateStore.selectedCertificates);
    let tests = flatten(Object.values(testsByCertificateId));

    if (tests.length) {
      tests = await TestStore.create(tests);
      const promises = Object.entries(testsByCertificateId).reduce((promises, [certificateId], index) => {
        promises.push(CertificateStore.addTest([tests[index]._id], certificateId));

        return promises;
      }, []);

      await Promise.all(promises);
    }

    if (tests.length && allowToSign) {
      const certificateByTestId = tests.reduce((acc, test, index) => {
        acc[test._id] = certificates[index];

        return acc;
      }, {});
      TestService.assignInspector(tests, certificateByTestId);
    }
  };

  return (
    <div className={classes.page}>
      <Grid container className={classes.pageHeadline} spacing={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Certificates
          </Typography>
        </Grid>
        {isProducer && (
          <>
            <Grid item>
              <Button
                component={RouterLink}
                className={classes.createNew}
                to="/certificates/create"
                variant="contained"
                color="primary"
              >
              Create new
              </Button>
            </Grid>
            <Grid item>
              <BulkCreateCertificates />
            </Grid>
          </>
        )}
        {!isEmpty(selected) && (
          <Grid item xs container justifyContent="flex-end" alignItems="center" spacing={3}>
            <Grid item>
              <Button
                color="primary"
                size="medium"
                variant="contained"
                onClick={() => multipleConvert()}
              >
                Multiple convert
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                size="medium"
                variant="contained"
                component={RouterLink}
                to={`/tests/multiSigning?${qs.stringify({certificateIds: selected})}`}
              >
                Multiple sign
              </Button>
            </Grid>
            <Grid item>
              <MultipleConvertModal
                convertOpened={convertingModalOpened}
                fetchCertificates={() => CertificateStore.certificates.load()}
                setSelected={onSelect}
                onClose={() => setConvertingModalOpened(false)}
                certificates={CertificateStore.selectedCertificates}
              />
            </Grid>
            <Grid item>
              <AddTests
                certificates={CertificateStore.selectedCertificates}
                createTests={createTests}
                createTestWithData={createTestWithData}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={onDelete}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Input
            name="search"
            endAdornment={
              <InputAdornment position="end">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Search Certificates"}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item>
          <ColumnsSelect
            columns={columns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Grid>
        <Paper className={classes.pageTable}>
          <TableNavigation
            titles={CERTIFICATE_VIEWS_TITLES}
            views={values(CERTIFICATE_VIEWS)}
            currentView={view}
            link="/certificates"
            query="view"
          />
          <Table
            setSelectedItems={onSelect}
            selectedItems={selected}
            selectedColumns={selectedColumns}
            allowRowSelection
            items={certificates}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => CertificateStore.certificates.load({sort})}
            saveSortToQueryParams
            total={total}
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={certificates}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              selectedItems={selected}
              onOffsetChange={(offset) => {
                CertificateStore.certificates.load({offset});
              }}
              onLimitChange={handleRowsPerPageChange}
            />
          </Table>
        </Paper>
      </Grid>

      <CertificateDeleteConfirmationModal
        open={isConfirmationOpened}
        close={handleActionCancel}
        onConfirm={handleActionConfirm}
      />
      <BasicCompUpgrade
        open={isBasicUpgradeOpen}
        onCancel={() => setIsBasicUpgradeOpen(false)}
      />
      <SignCertificateModal certificates={unsignedCertificates} />
    </div>
  );
});

export default withStyles(List, styles);
