import {action, makeObservable, observable} from "mobx";
import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class SpecificationStore {
  constructor() {
    makeObservable(this, {
      specifications: observable,
      testTypes: observable,
      specification: observable,
      getAll: action,
      create: action,
      update: action,
      delete: action,
      getTestTypes: action,
    });
  }

  specifications = {
    data: [],
    isLoaded: false,
  };

  testTypes = {
    data: [],
    isLoading: false,
  };

  specification = {};

  async getAll(params={}) {
    const res = await axios.get(ROUTES.SPECIFICATION[ACTIONS.ALL], {
      params,
      withCredentials: true,
    });
    this.specifications = {
      data: res.data.items,
      isLoaded: true
    };
  }

  async create(data) {
    const response = await axios.post(ROUTES.SPECIFICATION[ACTIONS.CREATE], data, {
      successMessage: "The digital rule set has been added"
    });
    this.specification = response.data;

    return this.specification;
  }

  async update(data, id) {
    const res = await axios.patch(ROUTES.SPECIFICATION[ACTIONS.UPDATE](id), data, {
      withCredentials: true,
      successMessage: "Successfully edited"
    });
    const updatedSpecifications = this.specifications.data.map((el) => {
      if (el._id === res.data._id) return res.data;

      return el;
    });

    this.specifications = {
      isLoaded: true,
      data: updatedSpecifications
    };

  }

  async delete(ids) {
    await axios.delete(ROUTES.SPECIFICATION[ACTIONS.REMOVE], {
      params: {ids},
      withCredentials: true,
      successMessage: "The digital rule set has been removed"
    });
    const updatedSpecifications = this.specifications.data.filter((el) => !ids.includes(el._id));

    this.specifications = {
      data: updatedSpecifications,
      isLoaded: true
    };
  }

  async getTestTypes() {
    this.testTypes.isLoading = true;

    const response = await axios.get(ROUTES.TEST[ACTIONS.TYPES]);

    this.testTypes = {
      data: response.data,
      isLoading: false,
    };
  }
}

export default new SpecificationStore();
