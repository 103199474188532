import {TableStore} from "@core/stores";
import axios from "axios";
import {makeObservable, observable} from "mobx";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class MetricsStore {
  constructor() {
    makeObservable(this, {
      metrics: observable,
    });
  }

  metrics = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.metrics.fetch, ...queryParams};
      const {offset, limit, sort} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
      };

      this.metrics.setFetch(newQueryParams);

      return axios.get(
        ROUTES.METRICS[ACTIONS.ALL],
        {params}
      );
    }
  });
}

export default new MetricsStore();