import React, {useEffect, useState} from "react";
import {Grid, Button, Dialog, DialogContent, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import axios from "axios";
import useStores from "../../../../useStores";
import {observer} from "mobx-react-lite";
import {isEmpty, map, prop} from "ramda";
import {CertificateService} from "@core/services";
import WS from "@core/api/socketConnection";
import SplitByQuantity from "./SplitByQuantity";
import AddWitnesses from "./AddWitnesses";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const SplitModal = observer(({classes, onClose, open}) => {
  const {CertificateStore, NotificationStore, SigningStore} = useStores();

  const [selectedWitnesses, setSelectedWitnesses] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [groupItems, setGroupItems] = useState(false);

  const split = async () => {
    const splitedCertificateId = CertificateStore.splitCertificateData[0]?._id;

    if (splitedCertificateId) {
      const res = await axios.get(ROUTES.CERTIFICATE[ACTIONS.SINGLE](splitedCertificateId),{
        withCredentials: true
      });

      if (isEmpty(res.data.transactions)) {
        CertificateStore.getCertificateById(CertificateStore.certificate.data._id);
        NotificationStore.showError("The certificate has been split but not signed! You will be requested to sign manually, the next time you open the newly split certificate(s).");
        onClose();
      }
    }
  };

  useEffect(() => {
    WS.listen("transaction:certificate:split", (data) => {
      SigningStore.changeStatus(data?.status);
      SigningStore.closeSigner();

      if (data.status === "ACCEPTED") {
        NotificationStore.showSuccess("Product has been split");
        onClose();
      } else NotificationStore.showError("Something went wrong!");
    });

    return () => {
      WS.remove("transaction:certificate:create");
    };

  }, []);

  const onSubmit = async () => {
    const data = {
      items: newItems,
      witnessIds: map(prop("_id"), selectedWitnesses),
    };

    const certificateId = CertificateStore.certificate.data._id;

    await CertificateStore.splitCertificate(certificateId, data, !groupItems);
    await CertificateService.split(CertificateStore.splitCertificateData, certificateId, split);
  };

  const {items, properties} = CertificateStore.certificate.data;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogContent>
        <Typography component="h5" variant="h5" className={classes.header}>
          Splitting certificate
        </Typography>
        <SplitByQuantity
          items={items}
          productType={properties.productType}
          newItems={newItems}
          setNewItems={setNewItems}
          groupItems={groupItems}
          setGroupItems={setGroupItems}
        />
        <Grid container spacing={5} justifyContent="flex-end">
          <Grid item xs={3}>
            <AddWitnesses
              selectedWitnesses={selectedWitnesses}
              setSelectedWitnesses={setSelectedWitnesses}
            />
          </Grid>
          <Grid item xs={1} container alignItems="flex-end">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={isEmpty(newItems)}
              onClick={onSubmit}
            >
              Sign
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

export default withStyles(SplitModal, styles);
