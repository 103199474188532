import {ROUTES} from "@core/api/routes";
import {Select} from "@core/components/Form";
import {ACTIONS} from "@core/constants/api";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {Grid, MenuItem} from "@mui/material";
import axios from "axios";
import {equals, keys} from "ramda";
import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import ImportWrapper from "@core/components/ImportData";
import useStores from "../../../../../../../../useStores";

const TEST_SOURCES_BY_CAMPAIGN = {
  [CAMPAIGN_TYPES.WELDING]: {
    BevelTech: "bevelTech",
    SpoolFab: "spoolFab",
    ShawcorNDT: "ShawcorNDT"
  },
  [CAMPAIGN_TYPES.COATING]: {
    Bayou: "Bayou",
    Odysea: "Odysea",
  }
};

const TEST_SCHEMAS_BY_SOURCE = {
  [TEST_SOURCES_BY_CAMPAIGN[CAMPAIGN_TYPES.WELDING].SpoolFab]: [
    "FieldJointCoating",
    "AsBuiltRecord",
    "WeldExport",
    "AsLaidRecord"
  ],
  [TEST_SOURCES_BY_CAMPAIGN[CAMPAIGN_TYPES.WELDING].ShawcorNDT]: [
    "iLineWeldAdvisor"
  ]
};

const ImportData = ({touched, ...props}) => {
  const initialFormData = {source: "", schema: "", file: ""};
  const [formData, setFormData] = useState(initialFormData);

  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;
  
  const sources = TEST_SOURCES_BY_CAMPAIGN[campaign.type];

  touched.current = !equals(formData, initialFormData);

  const parseFile = (data) => {
    data.append("source", formData.source);

    if(formData.schema) {
      data.append("schema", formData.schema);
    }

    return axios.post(ROUTES.CAMPAIGN[ACTIONS.PARSE](campaign._id), data);
  };
  
  return (
    <ImportWrapper
      parseFile={parseFile}
      formData={formData}
      setFormData={setFormData}
      disabled={!formData.source || (TEST_SCHEMAS_BY_SOURCE[formData.source] && !formData.schema)}
      defaultData={{tests: []}}
      {...props}
    >
      <Grid item xs={2}>
        <Select
          label="Source"
          required
          value={formData.source}
          onChange={(e) => {
            const source = e.target.value;
            const schemas = TEST_SCHEMAS_BY_SOURCE[source] || [];

            const schema = schemas.length === 1 ? schemas[0] : "";

            setFormData({...formData, source, schema});
          }}
        >
          {keys(sources).map((source) => (
            <MenuItem key={sources[source]} value={sources[source]}>{source}</MenuItem>
          ))}
        </Select>
      </Grid>
      {(formData.source && TEST_SCHEMAS_BY_SOURCE[formData.source]) && (
        <Grid item xs={2}>
          <Select
            label="Schema"
            required
            value={formData.schema}
            onChange={(e) => setFormData({...formData, schema: e.target.value, source: formData.source})}
          >
            {TEST_SCHEMAS_BY_SOURCE[formData.source].map((schema) => (
              <MenuItem key={schema} value={schema}>{schema}</MenuItem>
            ))}
          </Select>
        </Grid>
      )}
    </ImportWrapper>
  );
};

export default observer(ImportData);