import {Typography} from "@mui/material";
import React from "react";

const CompanyDetails = ({company}) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>{company.name}</Typography>
      {company.phone && (
        <>
          Phone:
          <Typography variant="subtitle1" gutterBottom>{company.phone}</Typography>
        </>
      )}
      {company.email && (
        <>
          Email:
          <Typography variant="subtitle1">{company.email}</Typography>
        </>
      )}
    </>
  );
};

export default CompanyDetails;