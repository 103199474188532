import Tags from "@core/components/Tags";
import React, {useState} from "react";
import {Grid, Divider, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {last, isEmpty, indexBy, prop} from "ramda";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import Properties from "@core/components/Properties";
import File from "@core/components/File";
import TestSummaryTable from "@core/components/TestSummaryTable";
import Owner from "./Owner";
import QrCode from "./QrCode";
import ProductInfoHistoryModal from "./ProductInfoHistoryModal";
import OwnersHistoryModal from "./OwnersHistoryModal";
import styles from "./styles";

const Summary = ({classes, onTestClick, certificate}) => {
  const [previousOwnersOpen, showPreviousOwners] = useState(false);
  const [productHistory, showProductHistory] = useState(false);
  const [filteredTestIds, setFilteredTestIds] = useState([]);

  const {normsByMaterial} = useFetchedCertificateNorms(certificate.properties.norm);

  const testsById = indexBy(prop("_id"), certificate.tests || []);
  const filteredTests = filteredTestIds.length ?
    filteredTestIds.reduce((acc, id) => testsById[id] ? acc.concat(testsById[id]) : acc, []) :
    certificate.tests;

  const previousOwner = last(certificate.previous_owners);

  return (
    <div className={classes.container}>
      <Grid container className={classes.header}>
        <Grid item xs container>
          <Typography component="h6" variant="h6">
                        Heat:&nbsp;<b>{certificate.heat}</b>
          </Typography>
        </Grid>
        <Grid item xs container justifyContent="flex-end">
          <Typography component="h6" variant="h6">
            <div className={classes.qrCode}>
              <QrCode certificate={certificate} />
                            Certificate:&nbsp;<b>{certificate._id}</b>
            </div>
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={5} className={classes.content}>
        <Grid item xs={6}>
          <Typography component="h6" variant="h6" className={classes.label}>
            Product description
          </Typography>
          <Grid container>
            {(certificate.internalWorkOrder || certificate.internalItemNumber) && (
              <>
                <Grid container item xs={4}>
                  <Grid item xs={10}>
                    {certificate.internalWorkOrder && "Work Order"}
                    {(certificate.internalWorkOrder && certificate.internalItemNumber) && " - "}
                    {certificate.internalItemNumber && "Item No."}
                  </Grid>
                  <Grid item xs={1}>
                                        :
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <b>
                    {certificate.internalWorkOrder && certificate.internalWorkOrder}
                    {(certificate.internalWorkOrder && certificate.internalItemNumber) && " - "}
                    {certificate.internalItemNumber && certificate.internalItemNumber}
                  </b>
                </Grid>
              </>
            )}
            <Grid container item xs={4}>
              <Grid item xs={10}>
                Product Type
              </Grid>
              <Grid item xs={1}>
                                :
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <b>{certificate.properties.productType?.name}</b>
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={10}>
                Material - Grade/UNS
              </Grid>
              <Grid item xs={1}>
                                :
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <b>
                {certificate.properties.norm}
                                &nbsp;-&nbsp;
                {certificate.properties.grade}
                {certificate.deliveryCondition &&
                                    normsByMaterial[certificate.properties.grade]?.DeliveryConditions &&
                                    normsByMaterial[certificate.properties.grade].DeliveryConditions[certificate.deliveryCondition]}
              </b>
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={10}>
                                Certificate Type
              </Grid>
              <Grid item xs={1}>
                                :
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <b>{certificate.certificateType}</b>
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={10}>
                                Lot ID
              </Grid>
              <Grid item xs={1}>
                                :
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <b>{certificate.properties.lotId || "-"}</b>
            </Grid>
            {!!certificate.tags?.length && (
              <>
                <Grid container item xs={4} alignItems="center">
                  <Grid item xs={10}>
                    Tags
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                </Grid>
                <Grid item xs={8} alignItems="center">
                  <Tags
                    tags={certificate.tags}
                  />
                </Grid>
              </>
            )}
            {certificate.properties.qcp && (
              <>
                <Grid container item xs={4}>
                  <Grid item xs={10}>
                    QCP/ITP
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <b>{certificate.properties.qcp}</b>
                </Grid>
              </>
            )}
            {certificate.properties.qcpItem && (
              <>
                <Grid container item xs={4}>
                  <Grid item xs={10}>
                    QCP/ITP Item
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <b>{certificate.properties.qcpItem}</b>
                </Grid>
              </>
            )}
          </Grid>
          {(!isEmpty(certificate.previousProperties) || !isEmpty(certificate.previousItems)) && (
            <div
              className={classes.viewFullButton}
              onClick={() => showProductHistory(!productHistory)}
            >
                            Previous product type
              <ProductInfoHistoryModal
                opened={productHistory}
                onClose={() => showProductHistory(!productHistory)}
                certificate={certificate}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography component="h6" variant="h6" className={classes.label}>
                        Owner
          </Typography>
          <Owner company={certificate.company} />
          {previousOwner && (
            <div
              className={classes.viewFullButton}
              onClick={() => showPreviousOwners(!previousOwnersOpen)}
            >
                            Previous owners
              <OwnersHistoryModal
                opened={previousOwnersOpen}
                onClose={() => showPreviousOwners(!previousOwnersOpen)}
                certificate={certificate}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={3}>
          {previousOwner && (
            <>
              <Typography component="h6" variant="h6" className={classes.label}>
                                Previous Owner
              </Typography>
              <Owner company={previousOwner} />
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography component="h6" variant="h6" className={classes.label}>
                        Items
          </Typography>
          {certificate.items && certificate.items.map((item) => (
            <Grid key={item._id} container spacing={3} wrap="nowrap">
              <Grid item xs={2}>
                {`Q: ${item.quantity}`}
              </Grid>
              <Properties
                item={item}
                productType={certificate.properties.productType}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={3}>
          <Typography component="h6" variant="h6" className={classes.label}>
                        Notes
          </Typography>
          {certificate.markingNotes}
        </Grid>
        <Grid item xs={3}>
          <Typography component="h6" variant="h6" className={classes.label}>
                        Marking
          </Typography>
          <Grid container spacing={3} alignItems="center">
            {certificate.markingFiles.map((file) => (
              <Grid key={file._id} item>
                <File file={file} />
              </Grid>
            ))}
            <Grid item xs={8}>
              {certificate.markingText}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h6" variant="h6" className={classes.label}>
                        Test Summary
          </Typography>
          {isEmpty(certificate.tests) ? (
            <span className='empty-data'>No Data</span>
          ) : (
            <TestSummaryTable
              setFilteredTestIds={setFilteredTestIds}
              onTestClick={onTestClick}
              tests={filteredTests}
              allTests={certificate.tests}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(Summary, styles);