import React, {memo, useMemo} from "react";
import StalkRow from "../StalkRow";

const StalkRows = ({chunks, numberOfStalkItemsInRow, stalkLength, ...props}) => {
  const pipesInRow = useMemo(() => chunks.length > 1 ? numberOfStalkItemsInRow : stalkLength, [chunks.length, numberOfStalkItemsInRow, stalkLength]);

  return chunks.map((chunk, index) => (
    <StalkRow
      {...props}
      key={index}
      chunk={chunk}
      index={index}
      pipesInRow={pipesInRow}
    />
  ));
};

export default memo(StalkRows);