import React, {useEffect, useCallback, useState} from "react";
import {observer} from "mobx-react-lite";
import qs from "qs";
import {map, prop, values, tail} from "ramda";
import {CircularProgress, Paper, Grid, InputAdornment, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Input} from "@core/components/Form";
import ColumnsSelect from "@core/components/ColumnsSelect";
import TableNavigation from "@core/components/TableNavigation";
import TableFooter from "@core/components/TableFooter";
import styles from "./styles";
import Table from "@core/components/Table";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {NAVIGATION_CONFIG, WITNESS_TABS, getColumns, WITNESS_STATUSES_BY_TAB, WITNESS_TAB_DEFAULT} from "./data";
import useStores from "../../useStores";
import {STATUSES} from "@core/constants/test";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import useSetInitialViewQueryParam from "@core/hooks/useSetInitialViewQueryParam";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const List = observer(({classes, match, location}) => {
  const {UserStore, TestStore} = useStores();
  const {status, data: tests, total, fetch} = TestStore.tests;

  const [filtration, setFiltration] = useState([]);

  const currentTab = qs.parse(tail(location.search)).view || WITNESS_TABS.TO_BE_ATTESTED;
  const companyId = UserStore.user.data.company._id;
  const columns = getColumns(currentTab, companyId);

  const savedSelectedColumns = JSON.parse(localStorage.getItem(match.path));
  const [selectedColumns, setSelectedColumns] = useState(savedSelectedColumns || map(prop("dataIndex"), columns));

  const statuses = WITNESS_STATUSES_BY_TAB[currentTab];

  const isInspectionNotPassed = (test) => {
    const witness = test.witnesses.find((witness) => witness.company._id === companyId) || {};
    const isInspected = [STATUSES.INSPECTED, STATUSES.APPROVED].includes(test.status);

    return witness.status === WITNESS_STATUSES.NOT_ACCEPTED && isInspected;
  };

  useSetInitialViewQueryParam(WITNESS_TAB_DEFAULT);

  useFetchTableDataByQueryParam({
    getStore: () => TestStore,
    dataKey: STORE_DATA.tests,
    matchPath: match.path,
    defaultParams: {statuses, witnessStatuses: filtration}
  });

  useEffect(() => {
    setFiltration([]);
  }, [currentTab]);

  useEffect(() => {
    return () => {
      TestStore.tests.reset();
    };
  }, []);

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    TestStore.tests.load({limit, offset: 0});
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    TestStore.tests.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  const onFilterTableView = (selectedOptions) => {
    const value = selectedOptions.length !== NAVIGATION_CONFIG[currentTab].length ? selectedOptions : [];
    setFiltration(value);
    TestStore.tests.load({witnessStatuses: value});
  };

  return (
    <div className={classes.page}>
      <Grid container className={classes.pageHeadline} spacing={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Tests
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Input
            name="search"
            endAdornment={
              <InputAdornment position="start">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Search Tests"}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item>
          <ColumnsSelect
            columns={columns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Grid>
        <Paper className={classes.pageTable}>
          <TableNavigation
            views={values(WITNESS_TABS)}
            currentView={currentTab}
            link="/witness"
            query="view"
            onFilter={onFilterTableView}
            options={NAVIGATION_CONFIG}
          />
          <Table
            selectedColumns={selectedColumns}
            items={tests}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => TestStore.tests.load({sort})}
            total={total}
            getRowClassName={(test) => isInspectionNotPassed(test) ? classes.inspectionNotPassedRow : null}
          >
            {status.loaded && tests.length > 0 && (
              <TableFooter
                saveTablePageToQueryParams
                isLoaded={status.loaded}
                items={tests}
                total={total}
                limit={fetch.limit}
                offset={fetch.offset}
                onOffsetChange={(offset) => TestStore.tests.load({offset})}
                onLimitChange={handleRowsPerPageChange}
              />
            )}
          </Table>
        </Paper>
      </Grid>
    </div>
  );
});

export default withStyles(List, styles);
