import React from "react";
import {TableCell, TableRow} from "@mui/material";
import {getMean, getDeviation} from "@core/helpers";
import {DIRECTIONS} from "@core/constants/transverseRuptureStrength";
import HeavyToRenderTableCell
  from "@core/components/TestForms/TransverseRuptureStrength/TestResults/CalculatedValuesRows/HeavyToRenderTableCell";
import {equals, pick} from "ramda";

const CalculatedValuesRows = ({stressAtBreak}) => {
  return (
    <>
      <TableRow>
        <TableCell className="vertical-header-col">Standard deviation</TableCell>
        <TableCell></TableCell> {/* For the specimen ID column*/}
        {Object.values(DIRECTIONS).map((direction) => (
          <React.Fragment key={direction}>
            <HeavyToRenderTableCell
              calculateItems={getDeviation}
              items={stressAtBreak.map((item) => item[direction])}
            />
          </React.Fragment>
        ))}
        <TableCell></TableCell> {/* For the action column*/}
      </TableRow>

      <TableRow>
        <TableCell className="vertical-header-col">Mean value</TableCell>
        <TableCell></TableCell> {/* For the specimen ID column*/}
        {Object.values(DIRECTIONS).map((direction) => (
          <React.Fragment key={direction}>
            <HeavyToRenderTableCell
              calculateItems={getMean}
              items={stressAtBreak.map((item) => item[direction])}
            />
          </React.Fragment>
        ))}
        <TableCell></TableCell> {/* For the action column*/}
      </TableRow>
    </>
  );
};

export default React.memo(
  CalculatedValuesRows,
  (prevProps, nextProps) => {
    const prevValues = prevProps?.stressAtBreak.map((item) => pick(Object.values(DIRECTIONS), item));
    const nextValues = nextProps?.stressAtBreak.map((item) => pick(Object.values(DIRECTIONS), item));

    return equals(prevValues, nextValues);
  }
);