const TYPES = {
  ANNEALING: "Annealing",
  NORMALIZED: "Normalization",
  POST_WELD_AND_HEAT_TREATMENT: "Post weld heat treatment",
  QUENCHING: "Quenching",
  SOLUTION_ANNEALING: "Solution annealing",
  TEMPERING: "Tempering",
  QUENCH_AND_TEMPER: "Quench and Temper",
  CARBIDE_SOLUTION: "Carbide solution treatment",
  AGING: "Aging",
  CURING: "Curing"
};

const METHODS = ["Single batch furnace", "Localized", "In-line"];

const coolingMedium = [
  {id: 1, value: "Water"},
  {id: 2, value: "Still air"},
  {id: 3, value: "Forced air"},
  {id: 4, value: "Oil"},
  {id: 5, value: "Furnace"}
];

export default {coolingMedium, TYPES, METHODS};
