import React from "react";
import {isEmpty, keys} from "ramda";
import {Grid, Table, TableBody, TableRow, TableHead, TableCell} from "@mui/material";
import {withStyles} from "tss-react/mui";
import File from "@core/components/File";
import {openFileInNewTab} from "@core/helpers/openFileInNewTab";
import styles from "./styles";

const ExaminationValue = ({classes, value, file}) => {
  if (value && file) {
    return (
      <a
        className={classes.link}
        onClick={() => openFileInNewTab(file)}
        target={"_blank"}
      >
        {value}
      </a>
    );
  }

  if (file) {
    return (
      <File file={file} />
    );
  }

  if(value) return value;

  return null;
};

const ExaminationConditions = ({classes, test, file}) => {
  return (
    <Grid item container spacing={1}>
      {(!isEmpty(test.properties.examinationConditions) && test.properties.examinationConditions) && (
        <>
          <Grid item xs={12}>
            <h3>Identification of tested items:</h3>
          </Grid>
          <Grid item xs={12}  sx={{overflowX: "scroll"}}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  {keys(test.properties.examinationConditions).map((_, index) => (
                    <TableCell key={index}>{`Piece No. ${Number(index) + 1}`}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {keys(test.properties.examinationConditions).map((key) => {
                    const {file, value} = test.properties.examinationConditions[key];

                    return (
                      <TableCell key={value}>
                        <ExaminationValue
                          classes={classes}
                          value={value}
                          file={file}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </>
      )}
      {file && (
        <Grid item xs={12}>
          <File file={file} />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(ExaminationConditions, styles);