import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Box, useTheme} from "@mui/material";
import BlockHeader from "../../../../../BlockHeader";
import useStores from "../../../../../../../../useStores";
import StatisticByStalkNumber from "./components/StatisticByStalkNumber";
import StatisticByDefectType from "./components/StatisticByDefectType";
import {getDefectReports} from "../../../../../../helpers";

const CoatingDefects = () => {
  const {CampaignStore} = useStores();

  const theme = useTheme();

  const campaign = CampaignStore.campaign;

  const {welds = [], tests = []} = campaign;

  const defectReports = useMemo(() => getDefectReports(tests), [tests]);
  
  const weldNumbers = useMemo(() => welds.map((weld) => weld.weldNumber), [welds]);

  const totalDefects = useMemo(() => {
    return defectReports.reduce((defects, report) => {
      const filtered = report.properties.data.filter((data) => weldNumbers.includes(data["Weld Number"]));

      return defects.concat(filtered);
    }, []);
  }, [weldNumbers]);
  
  return (
    <>
      <BlockHeader>Coating Defects</BlockHeader>
      <Box sx={{marginBottom: theme.spacing(3.5)}}>
        <StatisticByStalkNumber
          totalDefects={totalDefects}
        />
      </Box>
      <StatisticByDefectType
        totalDefects={totalDefects}
      />
    </>
  );
};

export default observer(CoatingDefects);