import themeSettings from "../../theme-settings";
// Scenarios:
// Producer, make 'add data' test, without witness. FILLED -> APPROVED
// Producer, add data test, with witness. FILLED -> INSPECTING -> INSPECTED -> APPROVED
// Producer, assign lab, without witness. EMPTY -> ASSIGNED -> FILLED -> SUBMITTED -> APPROVED
// Producer, assign lab, with witness. EMPTY -> ASSIGNED -> FILLED -> SUBMITTED -> INSPECTING -> INSPECTED -> APPROVED
// Lab, create test. EMPTY -> FILLED -> SUBMITTED

const STATUSES = {
  INSPECTED: "INSPECTED", // Witness has signed results
  INSPECTING: "INSPECTING", // Awaiting witness to sign
  ASSIGNED: "ASSIGNED", // Test assigned to lab
  APPROVED: "APPROVED", // Results signed by producer
  SUBMITTED: "SUBMITTED", // Results signed by lab
  FILLED: "FILLED", // Data added - not signed
  EMPTY: "EMPTY", // Test created
  DELETED: "DELETED", // Test deleted
};

const STATUSES_DESCRIPTION = {
  [STATUSES.EMPTY]: "New Test",
  [STATUSES.ASSIGNED]: "Awaiting Results",
  [STATUSES.FILLED]: "Preliminary",
  [STATUSES.SUBMITTED]: "Awaiting QA",
  [STATUSES.INSPECTED]: "Inspected / Awaiting QA",
  [STATUSES.INSPECTING]: "Awaiting Inspection",
  [STATUSES.APPROVED]: "Complete",
};

const THEME_BY_STATUS = {
  [STATUSES.EMPTY]: themeSettings.palette.warning,
  [STATUSES.ASSIGNED]: themeSettings.palette.warning,
  [STATUSES.FILLED]: themeSettings.palette.warning,
  [STATUSES.SUBMITTED]: themeSettings.palette.warning,
  [STATUSES.INSPECTED]: themeSettings.palette.warning,
  [STATUSES.INSPECTING]: themeSettings.palette.warning,
  [STATUSES.APPROVED]: themeSettings.palette.success,
};

const NOT_PLOTTABLE_TEST_STATUSES = [STATUSES.ASSIGNED, STATUSES.EMPTY, STATUSES.DELETED];

const TEST_GROUPS = {
  CHEMICAL_ANALYSES: "Chemical analyses",
  MECHANICAL_TESTS: "Mechanical tests",
  MATERIAL_TREATMENT: "Material treatment",
  "NDE/NDT": "NDE/NDT",
  CORROSION: "Corrosion",
  FACTORY_ACCEPTANCE_TESTS: "Factory acceptance tests (FAT)",
  METALLURGICAL: "Metallurgical",
  STATEMENTS: "Statements",
  OTHER: "Other"
};

const TEST_GROUP_ORDER = [
  [TEST_GROUPS.MATERIAL_TREATMENT],
  [TEST_GROUPS.CHEMICAL_ANALYSES],
  [TEST_GROUPS.MECHANICAL_TESTS],
  [TEST_GROUPS.CORROSION],
  [TEST_GROUPS.METALLURGICAL],
  [TEST_GROUPS["NDE/NDT"]],
  [TEST_GROUPS.FACTORY_ACCEPTANCE_TESTS],
  [TEST_GROUPS.STATEMENTS],
  [TEST_GROUPS.OTHER],
];

const TYPES = {
  CHEMICAL: "chemical_composition",
  IMPACT: "impact_test",
  TENSILE: "tensile_test",
  HOT_TENSILE: "hot_tensile_test",
  HARDNESS: "hardness_test",
  HYDROSTATIC: "hydrostatic_test",
  CORROSION: "corrosion_test",
  RADIOGRAPHIC: "radio_graphic_report",
  LIQUID: "liquid_penetrant_test",
  BEND: "bend_test",
  NOTES: "notes_test",
  TREATMENT: "treatment_test",
  OTHER: "other_test",
  ULTRASONIC: "ultrasonic_test",
  MAGNETIC: "magnetic_particle",
  AUSTENITE: "austenite_spacing",
  FERRITE: "ferrite_content",
  MICROGRAPHIC: "micrographic_test",
  MACROGRAPHIC: "macrographic_test",
  HEATTREATMENT: "heat_treatment",
  SPLIT: "split_certificate",
  SUPPLEMENT: "supplement_test",
  STATEMENT: "statement_test",
  SUPPLIER: "supplier_certificates",
  PROOFLOAD: "proofload_test",
  VISUAL: "visual_test",
  SULPHIDE_STRESS_CORROSIO_CRACKING: "sulphide_stress_corrosio_cracking_test_sscc",
  HYDROGEN_INDUCED_CRACKING: "hydrogen_induced_cracking_test_hic",
  CRACK_TIP_OPENING_DISPLACEMENT: "crack_tip_opening_displacement_ctod",
  ELECTROMAGNETIC_INDUCTION: "electromagnetic_induction_test_emi",
  INSPECTION_RELEASE_NOTE: "inspection_release_note",
  DIMENSIONAL: "dimensional_test",
  FLATTENING: "flattening_test",
  DROP_WEIGHT_TEAR: "drop_weight_tear_test_dwtt",
  CUSTOM: "custom_test",
  CUSTOM_REPORT: "custom_report",
  POROSITY: "porosity_test",
  TRANSVERSE_RUPTURE_STRENGTH: "transverse_rupture_strength_test",
  EROSION_TEST: "erosion_test",
  PALMQVIST_TEST: "palmqvist_test",
  DENSITY_TEST: "density_test",
};

const TEST_ORDER = [
  TYPES.CHEMICAL,
  TYPES.TENSILE,
  TYPES.HOT_TENSILE,
  TYPES.IMPACT,
  TYPES.PROOFLOAD,
  TYPES.BEND,
  TYPES.CORROSION,
  TYPES.HARDNESS,
  TYPES.AUSTENITE,
  TYPES.FERRITE,
  TYPES.HYDROSTATIC,
  TYPES.LIQUID,
  TYPES.MAGNETIC,
  TYPES.MICROGRAPHIC,
  TYPES.MACROGRAPHIC,
  TYPES.RADIOGRAPHIC,
  TYPES.ULTRASONIC,
  TYPES.HEATTREATMENT,
  TYPES.TREATMENT,
  TYPES.VISUAL,
  TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
  TYPES.HYDROGEN_INDUCED_CRACKING,
  TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
  TYPES.ELECTROMAGNETIC_INDUCTION,
  TYPES.DIMENSIONAL,
  TYPES.FLATTENING,
  TYPES.DROP_WEIGHT_TEAR,
  TYPES.OTHER,
  TYPES.CUSTOM,
  TYPES.CUSTOM_REPORT,
  TYPES.STATEMENT,
  TYPES.SUPPLEMENT,
  TYPES.SUPPLIER,
  TYPES.NOTES,
  TYPES.INSPECTION_RELEASE_NOTE,
];

const TEST_WITH_MANDATORY_WITNESS = [
  TYPES.DIMENSIONAL,
  TYPES.HARDNESS,
  TYPES.HYDROSTATIC,
  TYPES.IMPACT,
  TYPES.TENSILE,
  TYPES.VISUAL,
  TYPES.HEATTREATMENT,
  TYPES.INSPECTION_RELEASE_NOTE
];

const CUSTOM_TEST_TYPES = {
  FINAL_INSPECTION: "Final Inspection",
  COUNTERBORING: "Counterboring",
  AS_BUILD_RECORD: "As-Built Record",
  AUTOMATED_ULTRASONIC_TESTING: "Automated Ultrasonic Testing",
  FIELD_JOINT_COATING: "Field Joint Coating",
};

const CUSTOM_REPORT_TYPES = {
  COATING_DEFECT_LOG: "Coating Defect Log",
};

const CHEMICAL_COMPOSITION_SUB_TYPES = {
  PRODUCT: "chemical_composition-product",
  HEAT: "chemical_composition-heat"
};

const TEST_TYPES = {
  TEST: "TEST",
  DECLARATION: "DECLARATION",
};

const TEST_UNITS = {
  METRIC: "Metric",
  IMPERIAL: "Imperial",
};

const TEST_TEMPERATURE_UNITS = {
  CELSIUS: "celsius"
};

const POSITIONS = [
  "1/4 thickness",
  "1/2 thickness",
  "3/4 thickness",
  "2 mm from inside",
  "2 mm from outside",
  "full thickness",
];

const POSITIONS_SHORT_FORM = {
  "1/4 thickness": "1/4T",
  "1/2 thickness": "MW",
  "3/4 thickness": "3/4T",
  "2 mm from inside": "ID",
  "2 mm from outside": "OD",
  "full thickness": "T",
};

const ORIENTATIONS = [
  "Longitudinal",
  "Transversal",
  "Tangential",
  "Radial",
];

const MAGNIFICATIONS = ["100x", "200x", "400x", "500x"];

const ETCHING_STANDARDS = ["ASTM E407-07 (2015e1)"];

const ZONES = {
  BASE_METAL: "Base metal",
  HAZ: "HAZ",
  WELD_METAL: "Weld metal",
  TRANSITION_AREA_LINER: "Transition area liner/weld overlay",
  WELD_OVERLAY: "Weld overlay",
  WELD_CENTRAL_LINE: "Weld center line",
  CRA: "CRA",
};

const TEST_ID_PARAM = "testId";

const HARDNESS_UNITS = ["HBW", "HRC", "HRB", "HV0.5", "HV10"];

export {HARDNESS_UNITS, TEST_TEMPERATURE_UNITS, TEST_GROUP_ORDER, TEST_GROUPS, POSITIONS_SHORT_FORM, ETCHING_STANDARDS, MAGNIFICATIONS, THEME_BY_STATUS, STATUSES, TYPES, TEST_ORDER, TEST_TYPES, TEST_UNITS, STATUSES_DESCRIPTION, NOT_PLOTTABLE_TEST_STATUSES, TEST_ID_PARAM, TEST_WITH_MANDATORY_WITNESS, CUSTOM_TEST_TYPES, CUSTOM_REPORT_TYPES, CHEMICAL_COMPOSITION_SUB_TYPES, POSITIONS, ORIENTATIONS, ZONES};