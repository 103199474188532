import React from "react";
import classNames from "classnames";
import {Grid, Tooltip, IconButton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {CgChevronDoubleRight} from "react-icons/cg";
import styles from "./styles";

const DrawerHeader = ({classes, className, close, text, children}) => {
  return (
    <Grid
      container
      alignItems="center"
      className={
        classNames(classes.toolbar, className, {
          [classes.border]: text
        })
      }
    >
      <Grid item>
        <Tooltip title="Close">
          <IconButton
            onClick={close}
            color="primary"
          >
            <CgChevronDoubleRight />
          </IconButton>
        </Tooltip>
      </Grid>
      {text && (
        <Grid item>
          <h3>{text}</h3>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

export default withStyles(DrawerHeader, styles);