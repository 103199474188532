import React, {useState, useMemo, useContext} from "react";

const PlotContext = React.createContext({});

const OPTIONS = {
  plotType: "",
  paramType: undefined,
  options: {},
};

const PlotContextProvider = ({children}) => {
  const [plotOptions, setPlotOptions] = useState(OPTIONS);

  const contextValue = useMemo(() => ({
    ...plotOptions,
    setPlotOptions
  }), [plotOptions]);

  return (
    <PlotContext.Provider value={contextValue}>
      {children}
    </PlotContext.Provider>
  );
};

const usePlotOptions = () => {
  const context = useContext(PlotContext);

  return context;
};

export {
  usePlotOptions,
  PlotContextProvider
};
