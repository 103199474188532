import {withStyles} from "tss-react/mui";
import Accordion, {accordionClasses} from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import React from "react";
import classNames from "classnames";

const ExpansionPanel = withStyles(({classes, additionalClasses, ...restProps}) => {
  return (<Accordion
    {...restProps}
    classes={{
      root: classNames(classes.root, additionalClasses?.root),
      expanded: classNames(classes.expanded, additionalClasses?.expanded)
    }}/>);
}, (theme) => ({
  root: {
    overflow: "hidden",
    borderRadius: "4px",
    border: "1px solid #e0e0e0",
    "&:before": {
      display: "none",
    },
    zIndex: 1,
  },
  expanded: {
    [`&.${accordionClasses.expanded}`]: {
      margin: "initial",
      marginBottom: theme.spacing(1),
    }
  },
}));

const ExpansionPanelSummary = withStyles(({classes, additionalClasses, ...restProps}) => {
  return <AccordionSummary
    {...restProps}
    classes={{
      root: classNames(classes.root, additionalClasses?.root),
      content: classNames(classes.content, additionalClasses?.content)
    }}/>;
}, (theme) => {
  return {
    root: {
      backgroundColor: theme.palette.common.white,
      padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(3)}`,
    },
    content: {
      margin: 0,
      justifyContent: "space-between",
      minHeight: `${theme.spacing(7)}`,
      "&&": {
        margin: 0,
      },
      "& > :last-child": {
        paddingRight: "initial"
      }
    },
  };});

ExpansionPanelSummary.muiName = "AccordionSummary";

const ExpansionPanelDetails = withStyles(AccordionDetails, (theme) => {
  return {
    root: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)}`,
      display: "flex",
      flexDirection: "column"
    },
  };});

export {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails};