import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {PIPE_CONFIG} from "../../constants";
import {Rect} from "react-konva";
import {PIPE_WITH_TYPE_CONFIG} from "../../../../constants";
import {flatten} from "ramda";
import useStores from "../../../../../../../../../../useStores";

const ColoredPipe = observer(({settings, pipe, pipeRef, ...props}) => {
  const {CampaignStore} = useStores();

  const baseMaterialsTypes = CampaignStore.baseMaterialsTypes;

  const backgroundColors = useMemo(() => {
    if(pipe.type && settings.type) {
      const index = baseMaterialsTypes.findIndex((t) => t === pipe.type);

      return [PIPE_WITH_TYPE_CONFIG.BACKGROUND_COLORS[index]];
    }

    return [PIPE_CONFIG.COLOR];
  }, [baseMaterialsTypes, pipe.type, settings.type]);

  return (
    <Rect
      {...props}
      ref={pipeRef}
      perfectDrawEnabled={false}
      onMouseEnter={() => document.body.style.cursor = "pointer"}
      onMouseLeave={() => document.body.style.cursor = "default"}
      width={PIPE_CONFIG.WIDTH}
      height={PIPE_CONFIG.HEIGHT}
      stroke={PIPE_CONFIG.BORDER_COLOR}
      strokeWidth={PIPE_CONFIG.BORDER_WIDTH}
      shadowForStrokeEnabled={false}
      hitStrokeWidth={0}
      fillLinearGradientStartPoint={{x: 0, y: 0}}
      fillLinearGradientEndPoint={{x: PIPE_CONFIG.WIDTH, y: 0}}
      fillLinearGradientColorStops={
        flatten(backgroundColors.map((color) => [100 / backgroundColors.length / 100, color || PIPE_CONFIG.COLOR]))
      }
    />
  );
});

export default ColoredPipe;
