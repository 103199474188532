import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {TableStore} from "@core/stores";
import axios from "axios";
import {computed, makeObservable, observable} from "mobx";
import {isEmpty} from "ramda";
import {CERTIFICATE_STATUSES_BY_VIEW, CERTIFICATE_VIEWS} from "../../core/components/Transfer/CertificatesList/data";

class OutgoingOrderStore {
  constructor() {
    makeObservable(this, {
      certificates: observable,
      selectedCertificates: computed,
    });
  }

  getQueryParam = (search) => new URLSearchParams(search).get("view") || CERTIFICATE_VIEWS.READY_FOR_TRANSFER;

  certificates = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.certificates.fetch, ...queryParams};
      const {offset, limit, search, sort, orderCertificatesIds} = newQueryParams;
      const view = this.getQueryParam(location.search);
      const params = {
        sort,
        offset,
        limit,
        emptyViewer: true,
        search: isEmpty(search) ? null : JSON.stringify({value: search}),
        statuses: CERTIFICATE_STATUSES_BY_VIEW[view],
        statusesStrict: view === CERTIFICATE_VIEWS.READY_FOR_TRANSFER,
        excludeIds: orderCertificatesIds,
        populate: JSON.stringify([
          {
            path: "company",
            populate: {
              path: "mainLocation",
              select: "street city house postalCode"
            },
            select: "_id name"
          },
          {path: "projectId", select: "_id name"},
          {path: "properties.productType"},
          {path: "previous_owners"},
          {
            path: "tests",
            populate: [
              {path: "company", select: "_id name"},
              {path: "assignee", select: "_id name"},
              {path: "witnesses.company", select: "_id name"},
              {path: "productType", select: "_id name"}
            ],
            select: "_id status type properties witnesses.status item_heat lotId"
          },
        ]),
        select: "_id heat properties orderNumber internalWorkOrder internalItemNumber lineItem deliveryCondition items owner",
      };

      this.certificates.setFetch(newQueryParams);

      return axios.get(
        ROUTES.CERTIFICATE[ACTIONS.ALL],
        {params}
      );
    }
  });
    
  get selectedCertificates() {
    return this.certificates.data.filter((c) => this.certificates.selected.includes(c._id)) || [];
  }
}

export default new OutgoingOrderStore();
