export default (theme) => ({
  content: {
    padding: `0 ${theme.spacing(4)}`,
  },
  title: {
    margin: `${theme.spacing(3)} 0 ${theme.spacing()} 0`
  },
  poItemContainer: {
    display: "flex",
    align: "center",
  },
  warningIcon: {
    fontSize: 17,
    marginLeft: 10,
    color: "#ed6c02",
    cursor: "pointer",
  },
  poItemName: {
    "&:hover" : {
      cursor: "pointer"
    }
  },
  removeIcon: {
    "& svg": {
      fontSize: 18,
    },
    marginLeft: 10,
    padding: 0,
  }
});