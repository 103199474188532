import roles from "./roles";
import modules from "./modules";

export default {
  [modules.LAB_INTERNAL]: {
    [roles.SIGNER]: 16,
    [roles.ADMIN]: 17,
  },

  [modules.PRODUCER]: {
    [roles.SIGNER]: 238,
    [roles.ADMIN]: 239,
  },

  [modules.END_OWNER]: {
    [roles.SIGNER]: 238,
    [roles.ADMIN]: 239,
  },

  [modules.BASIC]: {
    [roles.ADMIN]: 5,
    [roles.SIGNER]: 4,
  },
  
  [modules.WITNESS]: {
    [roles.SIGNER]: 256,
    [roles.ADMIN]: 257,
  }
};
