import React, {useMemo} from "react";
import {times, identity} from "ramda";
import {Checkbox, Skeleton, TableCell, TableRow} from "@mui/material";

const TableSkeleton = ({allowRowSelection, columns, rows = 3}) => {
  const rowsToDisplay = useMemo(() => times(identity, rows), []);
  const columnsToDisplay = useMemo(() => times(identity, columns), []);

  return rowsToDisplay.map((index) => (
    <TableRow key={index}>
      {allowRowSelection && (
        <TableCell>
          <Checkbox
            disabled
            color="primary"
          />
        </TableCell>
      )}
      {columnsToDisplay.map((index) => (
        <TableCell>
          <Skeleton
            key={index}
            variant="text"
            sx={{fontSize: "inherit", lineHeight: "initial", padding: "0.5px 0"}}
          />
        </TableCell>
      ))}
    </TableRow>
  ));
};

export default TableSkeleton;