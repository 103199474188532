import {filter, propEq, pipe, prop} from "ramda";

export const isCertificateTransferred = (certificate, senderId) =>
  certificate.company._id !== senderId || !!certificate.viewer;

/**
 * @example
 * const test = getTestsOfType(type)(certificate);
 * @param type test type to search for
 * @returns array of tests with specified type
 */
export const getTestsOfType = (type) => pipe(prop("tests"), filter(propEq(type, "type")));