export default (theme) => ({
  "@global": {
    body: {
      "@media only screen and (max-width: 1500px) and (-webkit-min-device-pixel-ratio: 1.5)": {
        zoom: 0.8,
      },
      "@media only screen and (max-width: 1500px) and (-moz-min-device-pixel-ratio: 1.5)": {
        zoom: 0.8,
      },
      "@media only screen and (max-width: 1500px) and (-o-min-device-pixel-ratio: 1.5)": {
        zoom: 0.8,
      },
      "@media only screen and (max-width: 1500px) and (min-device-pixel-ratio: 1.5)": {
        zoom: 0.8,
      },
    },
  },
  infoMessage: {
    display: "flex",
    fontSize: 16,
    justifyContent: "center",
    backgroundColor: theme.palette.primary.dark,
  },

  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  text: {
    paddingLeft: 20
  },

  container: {
    display: "flex",
    alignItems: "stretch",
    minHeight: "100vh",
  },

  leftColumn: {
    width: 260,
    backgroundColor: "#252525",
    position: "fixed",
    height: "100%",
    overflow: "hidden",
    transition: "width 0.3s",
    zIndex: 10,

    "&.collapsed": {
      width: 60
    }
  },

  toggle: {
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
    padding: "20px 0 20px 20px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid #454545",
    cursor: "pointer",
    zIndex: 1000,
    backgroundColor: "#252525",

    "& svg": {
      transform: "rotateY(180deg)",
      transition: "transform 0.3s",
      width: 22,
      height: 22
    },

    "&:hover": {
      backgroundColor: "#222222",

      "& svg": {
        fill: "white",
      }
    },

    "&.collapsed": {
      "& svg": {
        transform: "rotateY(0deg)",
      },
    }
  },

  rightColumn: {
    width: "calc(100vw - 260px)",
    marginLeft: 260,
    flexGrow: 8,
    transition: "width 0.3s, margin-left 0.3s",

    "&.collapsed": {
      marginLeft: 60,
      width: "calc(100vw - 60px)",
    }
  }
});
