import React, {useEffect, useState, useRef} from "react";
import {equals} from "ramda";
import {Dialog, Typography, DialogContent} from "@mui/material";
import ConfirmationOnModalClose from "@core/components/ConfirmationOnModalClose";
import Form from "../../../components/Form";
import userService from "@core/api/user-service";
import useStores from "../../../../useStores";

const Edit = ({open, onClose, certificate}) => {
  const {CertificateStore} = useStores();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [user, setUser] = useState({});

  const formRef = useRef();

  const getUser = async () => {
    const user = await userService.getUserFromCache();
    setUser(user);
  };

  useEffect(() => {
    getUser();
  }, []);

  const onSave = async (data) => {
    await CertificateStore.updateCertificateWithOrder(certificate._id, data);

    if(certificate.properties.productType !== data.properties.productType) {
      await CertificateStore.getCertificateProductById(data.properties.productType); 
    }

    setConfirmationOpen(false);
    onClose();
  };

  const values = {
    ...certificate,
    location: certificate.location ? certificate.location._id : "",
    projectId: certificate.projectId ? certificate.projectId._id : "",
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (equals(formRef.current.values, formRef.current.initialValues)) onClose();
          else setConfirmationOpen(true);
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Typography component="h5" variant="h5" gutterBottom>
            Editing main certificate data
          </Typography>
          <Form
            formRef={formRef}
            certificate={values}
            user={user}
            onSave={onSave}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationOnModalClose
        confirmationOpen={confirmationOpen}
        formRef={formRef}
        setConfirmationOpen={setConfirmationOpen}
        onClose={onClose}
        onSave={() => onSave(formRef.current.values)}
      />
    </>
  );
};

export default Edit;
