import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {COLUMN_NAMES, TABLE_FIELDS_NAMES} from "../../constants";

const DisplayTable = ({values}) => {
  return (
    <>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            {COLUMN_NAMES.map((columnName) => (
              <TableCell key={columnName}>{columnName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map(({uniqueId, ...item}) => (
            <TableRow key={uniqueId}>
              {TABLE_FIELDS_NAMES.map((field) => (
                <TableCell key={field}>{item[field]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DisplayTable;