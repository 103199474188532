import WS from "@core/api/socketConnection";
import Input from "@core/components/Form/Input";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {STATUSES} from "@core/constants/test";
import {getTestName, isAcceptable} from "@core/helpers";
import {TestService} from "@core/services";
import {Button, Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {observer} from "mobx-react-lite";
import moment from "moment";
import {default as React, useEffect, useState} from "react";
import {MdSearch} from "react-icons/md";
import {withStyles} from "tss-react/mui";
import useStores from "../../../../useStores";
import styles from "./styles";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const AddExistingTest = observer(({
  classes,
  open,
  onClose,
  certificate,
}) => {
  const {CertificateStore, UserStore} = useStores();

  const [tests, setTests] = useState([]);
  const [chosenTests, setChosenTests] = useState([]);

  const fetchExistingTests = async () => {
    const {data} = await axios.get(ROUTES.TEST[ACTIONS.ALL_BY_QUERY], {
      params: {search: {item_heat: certificate.heat}},
    });

    const tests = getAvailableTests(data);

    setTests(tests);
  };

  const listenTestsSigned = () => {
    WS.listen("transaction:test:approve", async (res) => {
      await CertificateStore.addTest([res.data.referenceId], certificate._id);
    });
  };

  useEffect(() => {
    fetchExistingTests();
    listenTestsSigned();
  }, []);

  const getAvailableTests = (existingTests) => {
    const certificateTests = CertificateStore.certificate.data.tests || [];
    const certificateTestsTypes = {};

    certificateTests.forEach((test) => certificateTestsTypes[test.type] = true);

    return existingTests.filter((test) => (
      !certificateTestsTypes[test.type] && test.status === STATUSES.APPROVED && isTestSatisfactory(test)
    ));
  };

  const isTestSatisfactory = (test) => {
    return isAcceptable(test.properties.result) || !test.properties.result;
  };

  const handleSearch = (event) => {
    const tests = getAvailableTests();

    setTests(tests.filter((test) => {
      const searchedValue = event.target.value.toLowerCase();

      if (test.type.toLowerCase().includes(searchedValue)) return test;
    }));
  };

  const handleCheckboxClick = (event, test) => {
    setChosenTests(event.target.checked ? [...chosenTests, test._id] : chosenTests.filter((T) => T !== test._id));
  };

  const handleSubmit = () => {
    const testsToSign = tests.filter((test) => chosenTests.includes(test._id));
    TestService.addExistingTest(testsToSign, certificate, UserStore.user.data._id);
  };

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose}
      >
        <div className={classes.modal}>
          <div className={classes.headerSection}>
            <Typography variant="h6" id="modal-title">
              Choose existing test
            </Typography>
            <div className={classes.searchContainer}>
              <Input onChange={handleSearch} />
              <MdSearch className={classes.searchIcon} size={25} />
            </div>
          </div>

          <div className={classes.mainSection}>
            <Paper className={classes.paper}>
              <Table className={classes.tableContainer}>
                <TableHead className={classes.thUnderline}>
                  <TableRow>
                    <TableCell />
                    <TableCell>Test Type</TableCell>
                    <TableCell>Test Result</TableCell>
                    <TableCell>Lot ID</TableCell>
                    <TableCell>Requested By</TableCell>
                    <TableCell>Requested On</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tests.map((test, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            name='checkbox'
                            onChange={(event) => handleCheckboxClick(event, test)}
                            color="primary"
                          />
                        </TableCell>

                        <TableCell>{getTestName(test)}</TableCell>
                        <TableCell>
                          <span className={classes.satisfactory}>Satisfactory</span>
                        </TableCell>
                        <TableCell>{test.lotId}</TableCell>
                        <TableCell>{test.company?.name || "Company no longer exists"}</TableCell>
                        <TableCell>{moment(test.date_created).format(DATE_FORMAT)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>

            <div className={classes.insertBtnContainer}>
              <Button
                color='primary'
                variant="contained"
                onClick={handleSubmit}
                disabled={chosenTests.length === 0}
              >
                INSERT
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default withStyles(AddExistingTest, styles);
