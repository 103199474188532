import React, {useMemo} from "react";
import {observer} from "mobx-react";
import {Skeleton, Grid} from "@mui/material";
import {createTheme, ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import Statistics from "./components/Statistics";
import themeSettings from "../../../theme-settings";
import useStores from "../../../useStores";

const defaultTheme = createTheme();

const StatisticsSection = observer(({isLoading, data}) => {
  const {MenuStore} = useStores();
  const {collapsed} = MenuStore;

  const theme = useMemo(() => createTheme({
    ...themeSettings,
    breakpoints: {
      values: {
        ...defaultTheme.breakpoints.values,
        md: 0,
        lg: collapsed ? 1639 : 1818,
      }
    }
  }), [collapsed]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} alignItems="stretch">
          {data.map((statistics) => {
            return (
              <Grid
                item
                xs={Math.round(12 / data.length)}
                key={statistics.description}
              >
                {isLoading ? (
                  <Skeleton variant="rounded" height={170} />
                ) : (
                  <Statistics
                    link={statistics.link}
                    number={statistics.number?.toString()}
                    description={statistics.description}
                    icon={statistics.icon}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default StatisticsSection;
