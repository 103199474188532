import React from "react";
import {Grid, MenuItem, Select, useTheme} from "@mui/material";
import moment from "moment/moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";

const TestVersionSelector = ({testVersions, testVersionIndex, setTestVersionId}) => {
  const theme = useTheme();

  if(testVersions.length <= 1) return null;

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Select
            sx={{
              "& div": {
                padding: theme.spacing(),
                paddingRight: theme.spacing(4),
              }
            }}
            value={testVersionIndex}
            onChange={(e) => setTestVersionId(e.target.value)}
          >
            {testVersions.map((test, index) => (
              <MenuItem key={test._id} value={index}>
                {moment(test.date_created).format(DATE_FORMAT)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestVersionSelector;