import {PO_ITEMS} from "@core/constants/poItems";
import {getPoItemNumber, round} from "@core/helpers";
import {update} from "ramda";

export const getAcceptancesConfig = (makingProcess) => ({
  "QCP 7928 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        max: 0.18,
        bm: "C"
      },
      {
        id: 2,
        max: 0.45,
        bm: "Si"
      },
      {
        id: 3,
        max: 1.70,
        bm: "Mn"
      },
      {
        id: 4,
        max: 0.025,
        bm: "P"
      },
      {
        id: 5,
        max: 0.015,
        bm: "S"
      },
      {
        id: 6,
        max: 0.50,
        bm: "Cr"
      },
      {
        id: 7,
        max: 0.50,
        bm: "Mo"
      },
      {
        id: 8,
        max: 0.50,
        bm: "Ni"
      },
      {
        id: 9,
        max: 0.50,
        bm: "Cu"
      },
      {
        id: 10,
        max: 0.08,
        bm: "V"
      },
      {
        id: 11,
        max: 0.05,
        bm: "Nb"
      },
      {
        id: 12,
        bm: "Ti"
      },
      {
        id: 15,
        max: 0.010,
        bm: "N"
      },
      {
        id: 16,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 18,
        max: 0.25,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 19,
        max: 0.43,
        getMin({elements = []}) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value > 0.12 || !elements.length ? "" : 0.43;
        },
        getMax({elements = []}) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value > 0.12 || !elements.length ? "" : 0.43;
        },
        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
        bm: "CE_IIW"
      },
      {
        id: 20,
        max: 0.15,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      }
    ]
  },
  "Total E&P": {
    company: ["Total E&P"],
    elements: [
      {
        bm: "N",
        min: 0.14,
        max: 0.20
      }
    ]
  },
  "Van Leeuwen": {
    company: ["Van Leeuwen"],
    prenLabel: "CQ_03",
    elements: [
      {
        id: 1,
        min: 0,
        max: 0.2,
        bm: "C"
      },
      {
        id: 1,
        min: 0.29,
        max: 1.35,
        bm: "Mn"
      },
      {
        id: 5,
        min: 0,
        max: 0.01,
        bm: "S"
      }
    ]
  },
  "Dylan Staal": {
    company: ["Dylan Staal"],
    prenLabel: "CQ_03",
    elements: [
      {
        id: 1,
        min: 0,
        max: 0.2,
        bm: "C"
      },
      {
        id: 1,
        min: 0.29,
        max: 1.35,
        bm: "Mn"
      },
      {
        id: 5,
        min: 0,
        max: 0.01,
        bm: "S"
      }
    ]
  },
  "QCP 6916 Rev. 3": {
    company: ["TenarisLab", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        min: 0.06,
        max: 0.1,
        bm: "C"
      },
      {
        id: 2,
        min: 0.20,
        max: 0.35,
        bm: "Si"
      },
      {
        id: 3,
        min: 1.0,
        max: 1.5,
        bm: "Mn"
      },
      {
        id: 4,
        max: 0.015,
        bm: "P"
      },
      {
        id: 5,
        max: 0.003,
        bm: "S"
      },
      {
        id: 6,
        max: 0.25,
        bm: "Cr"
      },
      {
        id: 7,
        max: 0.20,
        bm: "Mo"
      },
      {
        id: 8,
        max: 0.30,
        bm: "Ni"
      },
      {
        id: 9,
        max: 0.20,
        bm: "Cu"
      },
      {
        id: 10,
        max: 0.07,
        bm: "V"
      },
      {
        id: 11,
        max: 0.005,
        bm: "Nb"
      },
      {
        id: 12,
        max: 0.020,
        bm: "Ti"
      },
      {
        id: 13,
        min: 0.020,
        max: 0.050,
        bm: "Al"
      },
      {
        id: 14,
        max: 0.004,
        bm: "Ca"
      },
      {
        id: 15,
        max: 0.012,
        bm: "N"
      },
      {
        id: 16,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 17,
        min: 0.4,
        max: 6.0,
        getMin({elements = []}) {
          const element = elements.find(({bm}) => bm === "S");

          return element?.value <= 0.002 || !elements.length ? "" : 0.4;
        },
        getMax({elements = []}) {
          const element = elements.find(({bm}) => bm === "S");

          return element?.value <= 0.002 || !elements.length ? "" : 6;
        },
        getNote(elements = []) {
          const element = elements.find(({bm}) => bm === "S");

          return element?.value <= 0.002 ? "Ca/S limits do not apply, since S <= 0.002" : "Ca/S limits applied since S > 0.002";
        },
        formula: "Ca/S",
        bm: "Ca/S"
      },
      {
        id: 18,
        min: 0.17,
        max: 0.21,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 19,
        min: 0.39,
        max: 0.44,
        getMin({elements = []}) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value > 0.12 || !elements.length ? "" : 0.39;
        },
        getMax({elements = []}) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value > 0.12 || !elements.length ? "" : 0.44;
        },
        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
        bm: "CE_IIW"
      },
      {
        id: 20,
        max: 0.09,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 21,
        min: 2,
        formula: "Al/N",
        bm: "Al/N",
      },
    ]
  },
  "QCP 5987 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        min: 0.06,
        max: 0.1,
        bm: "C"
      },
      {
        id: 2,
        min: 0.20,
        max: 0.35,
        bm: "Si"
      },
      {
        id: 3,
        min: 1.0,
        max: 1.5,
        bm: "Mn"
      },
      {
        id: 4,
        max: 0.015,
        bm: "P"
      },
      {
        id: 5,
        max: 0.003,
        bm: "S"
      },
      {
        id: 6,
        max: 0.25,
        bm: "Cr"
      },
      {
        id: 7,
        max: 0.20,
        bm: "Mo"
      },
      {
        id: 8,
        max: 0.30,
        bm: "Ni"
      },
      {
        id: 9,
        max: 0.20,
        bm: "Cu"
      },
      {
        id: 10,
        max: 0.07,
        bm: "V"
      },
      {
        id: 11,
        max: 0.005,
        bm: "Nb"
      },
      {
        id: 12,
        max: 0.020,
        bm: "Ti"
      },
      {
        id: 13,
        min: 0.020,
        max: 0.050,
        bm: "Al"
      },
      {
        id: 14,
        max: 0.004,
        bm: "Ca"
      },
      {
        id: 15,
        max: 0.012,
        bm: "N"
      },
      {
        id: 16,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 17,
        min: 0.4,
        max: 6.0,
        getMin({elements = []}) {
          const element = elements.find(({bm}) => bm === "S");

          return element?.value > 0.002 && elements.length ? 0.4 : "";
        },
        getMax({elements = []}) {
          const element = elements.find(({bm}) => bm === "S");

          return element?.value > 0.002 && elements.length ? 6 : "";
        },
        getNote(elements = []) {
          const element = elements.find(({bm}) => bm === "S");

          return element?.value <= 0.002 ? "Ca/S limits do not apply, since S <= 0.002" : "Ca/S limits applied since S > 0.002";
        },
        formula: "Ca/S",
        bm: "Ca/S"
      },
      {
        id: 18,
        min: 0.17,
        max: 0.21,
        getMin({elements = []}) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value <= 0.12 && elements.length ? 0.17 : "";
        },
        getMax({elements = []}) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value <= 0.12 && elements.length ? 0.21 : "";
        },
        getNote(elements = []) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value > 0.12 ? "CE_Pcm limits do not apply, since C > 0.12" : "CE_Pcm limits applied since C <= 0.12";
        },
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 19,
        min: 0.39,
        max: 0.44,
        getMin({elements = []}) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value > 0.12 && elements.length ? 0.39 : "";
        },
        getMax({elements = []}) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value > 0.12 && elements.length ? 0.44 : "";
        },
        getNote(elements = []) {
          const element = elements.find(({bm}) => bm === "C");

          return element?.value <= 0.12 ? "CE_IIW limits do not apply, since C <= 0.12" : "CE_IIW limits applied since C > 0.12";
        },
        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
        bm: "CE_IIW"
      },
      {
        id: 20,
        max: 0.09,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      }
    ]
  },
  "QCP 5983 Rev. 5": {
    company: ["TenarisLab", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        min: 0.07,
        max: 0.12,
        bm: "C"
      },
      {
        id: 2,
        max: 0.45,
        bm: "Si"
      },
      {
        id: 3,
        max: 1.65,
        bm: "Mn"
      },
      {
        id: 4,
        max: 0.020,
        bm: "P"
      },
      {
        id: 5,
        max: 0.003,
        bm: "S"
      },
      {
        id: 6,
        max: 0.30,
        bm: "Cr"
      },
      {
        id: 7,
        max: 0.20,
        bm: "Mo"
      },
      {
        id: 8,
        max: 0.30,
        bm: "Ni"
      },
      {
        id: 9,
        max: 0.35,
        bm: "Cu"
      },
      {
        id: 10,
        max: 0.080,
        bm: "V"
      },
      {
        id: 11,
        max: 0.040,
        bm: "Nb"
      },
      {
        id: 12,
        max: 0.020,
        bm: "Ti"
      },
      {
        id: 13,
        max: 0.060,
        bm: "Al"
      },
      {
        id: 14,
        max: 0.012,
        bm: "N"
      },
      {
        id: 15,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 16,
        max: 0.12,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 17,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      },
      {
        id: 18,
        max: 0.22,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 19,
        min: 0,
        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
        bm: "CE_IIW"
      },
    ]
  },
  "QCP 8262 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        max: 0.12,
        bm: "C",
      },
      {
        id: 2,
        max: 1.65,
        bm: "Mn",
      },
      {
        id: 3,
        max: 0.45,
        bm: "Si",
      },
      {
        id: 4,
        max: 0.020,
        bm: "P",
      },
      {
        id: 5,
        max: 0.010,
        bm: "S",
      },
      {
        id: 6,
        max: 0.09,
        bm: "V",
      },
      {
        id: 7,
        max: 0.05,
        bm: "Nb",
      },
      {
        id: 8,
        max: 0.06,
        bm: "Ti",
      },
      {
        id: 9,
        max: 0.5,
        bm: "Cr",
      },
      {
        id: 10,
        max: 0.5,
        bm: "Mo",
      },
      {
        id: 11,
        max: 0.5,
        bm: "Ni",
      },
      {
        id: 12,
        max: 0.5,
        bm: "Cu",
      },
      {
        id: 13,
        max: 0.06,
        bm: "Al",
      },
      {
        id: 14,
        max: 0.012,
        bm: "N",
      },
      {
        id: 15,
        max: 0.0005,
        bm: "B",
      },
      {
        id: 16,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
            return 0.21;
          case PO_ITEMS.PO_ITEM_2:
            return 0.21;
          case PO_ITEMS.PO_ITEM_3:
            return 0.22;
          default:
            return "";
          }
        },
        bm: "CE_Pcm",
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
      },
      {
        id: 17,
        max: 0.15,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 18,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      },
    ],
  },
  get "QCP 8262 Rev. 6 MPQT/FDPT"() {
    return {
      company: ["Tenaris-Siderca", "Tremblay Inc."],
      elements: update(4, {id: 5, max: 0.003, bm: "S"}, this["QCP 8262 Rev. 6"].elements),
    };
  },
  "QCP 8251 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        max: 0.12,
        bm: "C",
      },
      {
        id: 2,
        max: 1.65,
        bm: "Mn",
      },
      {
        id: 3,
        max: 0.45,
        bm: "Si",
      },
      {
        id: 4,
        max: 0.020,
        bm: "P",
      },
      {
        id: 5,
        max: 0.010,
        bm: "S",
      },
      {
        id: 6,
        max: 0.09,
        bm: "V",
      },
      {
        id: 7,
        max: 0.05,
        bm: "Nb",
      },
      {
        id: 8,
        max: 0.06,
        bm: "Ti",
      },
      {
        id: 9,
        max: 0.5,
        bm: "Cr",
      },
      {
        id: 10,
        max: 0.5,
        bm: "Mo",
      },
      {
        id: 11,
        max: 0.5,
        bm: "Ni",
      },
      {
        id: 12,
        max: 0.5,
        bm: "Cu",
      },
      {
        id: 13,
        max: 0.06,
        bm: "Al",
      },
      {
        id: 14,
        max: 0.012,
        bm: "N",
      },
      {
        id: 15,
        max: 0.0005,
        bm: "B",
      },
      {
        id: 16,
        bm: "CE_Pcm",
        max: 0.21,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
      },
      {
        id: 17,
        max: 0.15,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 18,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      },
    ],
  },
  "QCP 8252 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        max: 0.12,
        bm: "C",
      },
      {
        id: 2,
        max: 1.65,
        bm: "Mn",
      },
      {
        id: 3,
        max: 0.45,
        bm: "Si",
      },
      {
        id: 4,
        max: 0.020,
        bm: "P",
      },
      {
        id: 5,
        max: 0.010,
        bm: "S",
      },
      {
        id: 6,
        max: 0.09,
        bm: "V",
      },
      {
        id: 7,
        max: 0.05,
        bm: "Nb",
      },
      {
        id: 8,
        max: 0.06,
        bm: "Ti",
      },
      {
        id: 9,
        max: 0.5,
        bm: "Cr",
      },
      {
        id: 10,
        max: 0.5,
        bm: "Mo",
      },
      {
        id: 11,
        max: 0.5,
        bm: "Ni",
      },
      {
        id: 12,
        max: 0.5,
        bm: "Cu",
      },
      {
        id: 13,
        max: 0.06,
        bm: "Al",
      },
      {
        id: 14,
        max: 0.012,
        bm: "N",
      },
      {
        id: 15,
        max: 0.0005,
        bm: "B",
      },
      {
        id: 16,
        bm: "CE_Pcm",
        max: 0.21,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
      },
      {
        id: 17,
        max: 0.15,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 18,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      },
    ],
  },
  "QCP 8263 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        max: 0.12,
        bm: "C",
      },
      {
        id: 2,
        max: 1.65,
        bm: "Mn",
      },
      {
        id: 3,
        max: 0.45,
        bm: "Si",
      },
      {
        id: 4,
        max: 0.020,
        bm: "P",
      },
      {
        id: 5,
        max: 0.010,
        bm: "S",
      },
      {
        id: 6,
        max: 0.09,
        bm: "V",
      },
      {
        id: 7,
        max: 0.05,
        bm: "Nb",
      },
      {
        id: 8,
        max: 0.06,
        bm: "Ti",
      },
      {
        id: 9,
        max: 0.5,
        bm: "Cr",
      },
      {
        id: 10,
        max: 0.5,
        bm: "Mo",
      },
      {
        id: 11,
        max: 0.5,
        bm: "Ni",
      },
      {
        id: 12,
        max: 0.5,
        bm: "Cu",
      },
      {
        id: 13,
        max: 0.06,
        bm: "Al",
      },
      {
        id: 14,
        max: 0.012,
        bm: "N",
      },
      {
        id: 15,
        max: 0.0005,
        bm: "B",
      },
      {
        id: 16,
        bm: "H",
      },
      {
        id: 17,
        bm: "CE_Pcm",
        max: 0.21,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
      },
      {
        id: 19,
        max: 0.15,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 19,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      },
    ],
  },
  "HS-D-ITP-22-02-02 Rev. 5": {
    company: ["HusteelLab", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        min: 0.025,
        max: 0.1,
        bm: "C"
      },
      {
        id: 2,
        max: 0.45,
        bm: "Si"
      },
      {
        id: 3,
        max: 1.45,
        bm: "Mn"
      },
      {
        id: 4,
        max: 0.012,
        bm: "P"
      },
      {
        id: 5,
        max: 0.002,
        bm: "S"
      },
      {
        id: 6,
        max: 0.30,
        bm: "Cr"
      },
      {
        id: 7,
        max: 0.15,
        bm: "Mo"
      },
      {
        id: 8,
        max: 0.30,
        bm: "Ni"
      },
      {
        id: 9,
        max: 0.35,
        bm: "Cu"
      },
      {
        id: 10,
        max: 0.05,
        bm: "V"
      },
      {
        id: 11,
        max: 0.04,
        bm: "Nb"
      },
      {
        id: 12,
        max: 0.025,
        bm: "Ti"
      },
      {
        id: 13,
        max: 0.06,
        bm: "Al"
      },
      {
        id: 14,
        max: 0.006,
        bm: "Ca"
      },
      {
        id: 15,
        max: 0.012,
        bm: "N"
      },
      {
        id: 16,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 17,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      },
      {
        id: 18,
        max: 0.115,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 19,
        max: 0.20,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 20,
        bm: "F",
        getValue(elements) {
          const carbon = elements.find(({bm}) => bm === "C") || {};
          const value = parseFloat(carbon.value);

          if (value < 0.06){
            return 0.53;
          }
          else if (value > 0.21){
            return 1;
          }
          else {
            switch(value){
            case 0.06: return 0.54;
            case 0.07: return 0.56;
            case 0.08: return 0.58;
            case 0.09: return 0.62;
            case 0.10: return 0.66;
            case 0.11: return 0.70;
            case 0.12: return 0.75;
            case 0.13: return 0.80;
            case 0.14: return 0.85;
            case 0.15: return 0.88;
            case 0.16: return 0.92;
            case 0.17: return 0.94;
            case 0.18: return 0.96;
            case 0.19: return 0.97;
            case 0.20: return 0.98;
            case 0.21: return 0.99;
            }
          }
        }
      },
      {
        id: 21,
        max: 0.35,
        formula: "C+F*(Mn/6+Si/24+Cu/15+Ni/20+(Cr+Mo+V+Nb)/5+5*B)",
        bm: "CE_CSA"
      },
    ]
  },
  "HS-D-ITP-23-01-06 Rev. 3": {
    company: ["HusteelLab", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        min: 0.025,
        max: 0.1,
        bm: "C"
      },
      {
        id: 2,
        max: 1.45,
        bm: "Mn"
      },
      {
        id: 3,
        max: 0.45,
        bm: "Si"
      },
      {
        id: 4,
        max: 0.002,
        bm: "S"
      },
      {
        id: 5,
        max: 0.012,
        bm: "P"
      },
      {
        id: 6,
        max: 0.35,
        bm: "Cu"
      },
      {
        id: 7,
        max: 0.04,
        bm: "Nb"
      },
      {
        id: 8,
        max: 0.05,
        bm: "V"
      },
      {
        id: 9,
        max: 0.025,
        bm: "Ti"
      },
      {
        id: 10,
        max: 0.06,
        bm: "Al"
      },
      {
        id: 11,
        max: 0.012,
        bm: "N"
      },
      {
        id: 12,
        max: 0.3,
        bm: "Cr"
      },
      {
        id: 13,
        max: 0.3,
        bm: "Ni"
      },
      {
        id: 14,
        max: 0.15,
        bm: "Mo"
      },
      {
        id: 15,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 16,
        max: 0.006,
        bm: "Ca"
      },
      {
        id: 17,
        bm: "F",
        getValue(elements) {
          const carbon = elements.find(({bm}) => bm === "C") || {};
          const value = parseFloat(carbon.value);

          if (value < 0.06){
            return 0.53;
          }
          else if (value > 0.21){
            return 1;
          }
          else {
            switch(value){
            case 0.06: return 0.54;
            case 0.07: return 0.56;
            case 0.08: return 0.58;
            case 0.09: return 0.62;
            case 0.10: return 0.66;
            case 0.11: return 0.70;
            case 0.12: return 0.75;
            case 0.13: return 0.80;
            case 0.14: return 0.85;
            case 0.15: return 0.88;
            case 0.16: return 0.92;
            case 0.17: return 0.94;
            case 0.18: return 0.96;
            case 0.19: return 0.97;
            case 0.20: return 0.98;
            case 0.21: return 0.99;
            }
          }
        }
      },
      {
        id: 18,
        max: 0.115,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 19,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      },
      {
        id: 20,
        max: 0.2,
        formula: "C+Si/30+Mn/20+Cu/20+Ni/60+Cr/20+Mo/15+V/10+5*B",
        bm: "CE_Pcm"
      },
      {
        id: 21,
        max: 0.35,
        formula: "C+F*(Mn/6+Si/24+Cu/15+Ni/20+(Cr+Mo+V+Nb)/5+5*B)",
        bm: "CE_CSA"
      },
    ]
  },
  "ITP-BPM-22.20 Rev.6": {
    company: ["Tremblay Inc.", "Berg Pipe"],
    elements: [{
      id: 1,
      max: 0.12,
      bm: "C",
    }, {
      id: 2,
      max: 1.7,
      bm: "Mn",
      getMax({elements = [], defaultElements = [], grade}) {
        if(!elements.length) return "";

        if(grade !== "X70M") return 1.7;

        const carbon = elements.find(({bm}) => bm === "C") || {};
        const manganese = defaultElements.find(({bm}) => bm === "Mn") || {};

        if (Number(carbon.value) > carbon.max || !carbon.value) return manganese.max;

        const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
        const additionToMn = reductionTimes * 0.05;

        return (manganese.max + additionToMn) > 1.8 ? 1.8 : round((manganese.max + additionToMn), 2);
      },
    }, {
      id: 3,
      max: 0.020,
      bm: "P",
    }, {
      id: 4,
      max: 0.008,
      bm: "S",
    }, {
      id: 5,
      max: 0.35,
      bm: "Si",
    }, {
      id: 6,
      min: 0.010,
      max: 0.050,
      bm: "Al",
    }, {
      id: 7,
      max: 0.1,
      bm: "Nb",
    }, {
      id: 8,
      max: 0.1,
      bm: "V",
    }, {
      id: 9,
      max: 0.020,
      bm: "Ti"
    }, {
      id: 10,
      max: 0.3,
      bm: "Cr"
    }, {
      id: 11,
      max: 0.3,
      bm: "Mo"
    }, {
      id: 12,
      max: 0.3,
      bm: "Ni"
    }, {
      id: 13,
      max: 0.35,
      bm: "Cu"
    }, {
      id: 14,
      max:  0.0005,
      bm: "B",
    }, {
      id: 15,
      max: 0.005,
      bm: "Ca",
      required: false,
    }, {
      id: 16,
      getMax({steelMakingProcess}) {
        if(steelMakingProcess === makingProcess.BOF) return  0.009;

        if(steelMakingProcess === makingProcess.EAF) return  0.010;

        return "";
      },
      bm: "N",
      required: false,
    }, {
      id: 17,
      max: 0.15,
      formula: "Nb+V+Ti",
      bm: "Nb+V+Ti"
    },
    {
      id: 18,
      max: 0.22,
      formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
      bm: "CE_Pcm"
    },],
  },
  get "ITP-BPM-22.20 Rev.6 MPQT"() {
    return this["ITP-BPM-22.20 Rev.6"];
  },
  "FP-ITP Rev. 0": {
    company: ["Tremblay Inc.", "Mechan Lab"],
    elements: [
      {
        id: 1,
        min: 0.025,
        max: 0.1,
        bm: "C"
      },
      {
        id: 2,
        max: 0.45,
        bm: "Si"
      },
      {
        id: 3,
        max: 1.45,
        bm: "Mn"
      },
      {
        id: 4,
        max: 0.012,
        bm: "P"
      },
      {
        id: 5,
        max: 0.002,
        bm: "S"
      },
      {
        id: 6,
        max: 0.30,
        bm: "Cr"
      },
      {
        id: 7,
        max: 0.15,
        bm: "Mo"
      },
      {
        id: 8,
        max: 0.30,
        bm: "Ni"
      },
      {
        id: 9,
        max: 0.35,
        bm: "Cu"
      },
      {
        id: 10,
        max: 0.05,
        bm: "V"
      },
      {
        id: 11,
        max: 0.04,
        bm: "Nb"
      },
      {
        id: 12,
        max: 0.025,
        bm: "Ti"
      },
      {
        id: 13,
        max: 0.06,
        bm: "Al"
      },
      {
        id: 14,
        max: 0.006,
        bm: "Ca"
      },
      {
        id: 15,
        max: 0.012,
        bm: "N"
      },
      {
        id: 16,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 17,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      },
      {
        id: 18,
        max: 0.115,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 19,
        max: 0.20,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 20,
        bm: "F",
        getValue(elements) {
          const carbon = elements.find(({bm}) => bm === "C") || {};
          const value = parseFloat(carbon.value);

          if (value < 0.06){
            return 0.53;
          }
          else if (value > 0.21){
            return 1;
          }
          else {
            switch(value){
            case 0.06: return 0.54;
            case 0.07: return 0.56;
            case 0.08: return 0.58;
            case 0.09: return 0.62;
            case 0.10: return 0.66;
            case 0.11: return 0.70;
            case 0.12: return 0.75;
            case 0.13: return 0.80;
            case 0.14: return 0.85;
            case 0.15: return 0.88;
            case 0.16: return 0.92;
            case 0.17: return 0.94;
            case 0.18: return 0.96;
            case 0.19: return 0.97;
            case 0.20: return 0.98;
            case 0.21: return 0.99;
            }
          }
        }
      },
      {
        id: 21,
        max: 0.35,
        formula: "C+F*(Mn/6+Si/24+Cu/15+Ni/20+(Cr+Mo+V+Nb)/5+5*B)",
        bm: "CE_CSA"
      },
    ]
  },
  "ITP 011/23/124277 Rev. 05": {
    company: ["Tremblay Inc."],
    elements: [
      {
        id: 1,
        max: 15,
        bm: "Fe",
      }
    ]
  },
  "ITP 011/23/124277 Rev. 05 MPQT": {
    company: ["Tremblay Inc."],
    elements: [
      {
        id: 1,
        max: 15,
        bm: "Fe",
      }
    ]
  },
  "MTIP WA0058-4400025974-H06-44004 Rev. 6": {
    company: ["SumitomoLab", "Tremblay Inc.", "subsea7"],
    elements: [
      {
        id: 1,
        max: 0.16,
        bm: "C"
      },
      {
        id: 2,
        max: 0.45,
        bm: "Si"
      },
      {
        id: 3,
        max: 1.65,
        bm: "Mn",
        getMax({elements = [], defaultElements = []}) {
          if(!elements.length) return "";

          const carbon = elements.find(({bm}) => bm === "C") || {};
          const manganese = defaultElements.find(({bm}) => bm === "Mn") || {};

          if (Number(carbon.value) > carbon.max || !carbon.value) return manganese.max;

          const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
          const additionToMn = reductionTimes * 0.05;

          return (manganese.max + additionToMn) > 1.98 ? 1.98 : round((manganese.max + additionToMn), 2);
        },
      },
      {
        id: 4,
        max: 0.015,
        bm: "P"
      },
      {
        id: 5,
        max: 0.003,
        bm: "S"
      },
      {
        id: 6,
        max: 0.50,
        bm: "Cr"
      },
      {
        id: 7,
        max: 0.50,
        bm: "Mo"
      },
      {
        id: 8,
        max: 0.50,
        bm: "Ni"
      },
      {
        id: 9,
        max: 0.50,
        bm: "Cu"
      },
      {
        id: 10,
        max: 0.09,
        bm: "V"
      },
      {
        id: 11,
        max: 0.05,
        bm: "Nb"
      },
      {
        id: 12,
        max: 0.06,
        bm: "Ti",
      },
      {
        id: 13,
        max: 0.06,
        bm: "Al",
      },
      {
        id: 14,
        max: 0.004,
        bm: "Ca",
      },
      {
        id: 15,
        max: 0.012,
        bm: "N"
      },
      {
        id: 16,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 17,
        max: 0.0002,
        bm: "H"
      },
      {
        id: 18,
        max: 0.21,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 20,
        max: 0.15,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      },
      {
        id: 21,
        min: 2,
        formula: "Al/N",
        bm: "Al/N"
      }
    ]
  },
  "QCP 5513 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        min: 0.06,
        max: 0.10,
        bm: "C",
      },
      {
        id: 2,
        min: 0.20,
        max: 0.35,
        bm: "Si",
      },
      {
        id: 3,
        min: 1,
        max: 1.5,
        bm: "Mn",
      },
      {
        id: 4,
        max: 0.015,
        bm: "P",
      },
      {
        id: 5,
        max: 0.003,
        bm: "S",
      },
      {
        id: 6,
        max: 0.22,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
            return 0.22;
          case PO_ITEMS.PO_ITEM_2:
            return 0.2;
          default:
            return "";
          }
        },
        bm: "Cr",
      },
      {
        id: 7,
        max: 0.20,
        bm: "Mo",
      },
      {
        id: 8,
        max: 0.30,
        bm: "Ni",
      },
      {
        id: 9,
        max: 0.20,
        bm: "Cu",
      },
      {
        id: 10,
        max: 0.07,
        bm: "V",
      },
      {
        id: 11,
        max: 0.005,
        bm: "Nb",
      },
      {
        id: 12,
        max: 0.020,
        bm: "Ti",
      },
      {
        id: 13,
        min: 0.02,
        max: 0.05,
        bm: "Al",
      },
      {
        id: 14,
        max: 0.004,
        bm: "Ca",
      },
      {
        id: 15,
        min: 0.4,
        max: 6.0,
        bm: "Ca/S",
        formula: "Ca/S",
        getMin({elements = []}) {
          const element = elements.find(({bm}) => bm === "S");

          return element?.value > 0.002 && elements.length ? 0.4 : "";
        },
        getMax({elements = []}) {
          const element = elements.find(({bm}) => bm === "S");

          return element?.value > 0.002 && elements.length ? 6.0 : "";
        },
      },
      {
        id: 16,
        min: 0.17,
        max: 0.20,
        bm: "CE_Pcm",
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
      },
      {
        id: 17,
        getMin({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
            return 0.38;
          case PO_ITEMS.PO_ITEM_2:
            return 0.39;
          default:
            return "";
          }
        },
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
            return 0.43;
          case PO_ITEMS.PO_ITEM_2:
            return 0.44;
          default:
            return "";
          }
        },
        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
        bm: "CE_IIW"
      },
      {
        id: 18,
        max: 0.012,
        bm: "N"
      },
      {
        id: 19,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 20,
        max: 0.09,
        bm: "Nb+V+Ti",
        formula: "Nb+V+Ti",
      }
    ],
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    elements: [
      {
        id: 1,
        getMin({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.07;
          case PO_ITEMS.PO_ITEM_4:
            return 0.06;
          default:
            return "";
          }
        },
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.12;
          case PO_ITEMS.PO_ITEM_4:
            return 0.10;
          default:
            return "";
          }
        },
        bm: "C",
      },
      {
        id: 2,
        getMin({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_4:
            return 0.20;
          default:
            return "";
          }
        },
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.45;
          case PO_ITEMS.PO_ITEM_4:
            return 0.35;
          default:
            return "";
          }
        },
        bm: "Si",
      },
      {
        id: 3,
        getMin({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_4:
            return 1;
          default:
            return "";
          }
        },
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 1.65;
          case PO_ITEMS.PO_ITEM_4:
            return 1.5;
          default:
            return "";
          }
        },
        bm: "Mn",
      },
      {
        id: 4,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.020;
          case PO_ITEMS.PO_ITEM_4:
            return 0.015;
          default:
            return "";
          }
        },
        bm: "P",
      },
      {
        id: 5,
        max: 0.003,
        bm: "S",
      },
      {
        id: 6,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.30;
          case PO_ITEMS.PO_ITEM_4:
            return 0.25;
          default:
            return "";
          }
        },
        bm: "Cr",
      },
      {
        id: 7,
        max: 0.20,
        bm: "Mo",
      },
      {
        id: 8,
        max: 0.30,
        bm: "Ni",
      },
      {
        id: 9,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.35;
          case PO_ITEMS.PO_ITEM_4:
            return 0.20;
          default:
            return "";
          }
        },
        bm: "Cu",
      },
      {
        id: 10,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.08;
          case PO_ITEMS.PO_ITEM_4:
            return 0.07;
          default:
            return "";
          }
        },
        bm: "V",
      },
      {
        id: 11,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.04;
          case PO_ITEMS.PO_ITEM_4:
            return 0.005;
          default:
            return "";
          }
        },
        bm: "Nb",
      },
      {
        id: 12,
        max: 0.020,
        bm: "Ti",
      },
      {
        id: 13,
        getMin({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_4:
            return 0.02;
          default:
            return "";
          }
        },
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.06;
          case PO_ITEMS.PO_ITEM_4:
            return 0.05;
          default:
            return "";
          }
        },
        bm: "Al",
      },
      {
        id: 14,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_4:
            return 0.004;
          default:
            return "";
          }
        },
        bm: "Ca",
      },
      {
        id: 15,
        max: 0.012,
        bm: "N",
      },
      {
        id: 16,
        max: 0.0005,
        bm: "B",
      },
      {
        id: 17,
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.12;
          case PO_ITEMS.PO_ITEM_4:
            return 0.09;
          default:
            return "";
          }
        },
        bm: "Nb+V+Ti",
        formula: "Nb+V+Ti",
      },
      {
        id: 18,
        getMin({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 2;
          default:
            return "";
          }
        },
        bm: "Al/N",
        formula: "Al/N",
      },
      {
        id: 19,
        getMin({elements = [], poItem = ""}) {
          const element = elements.find(({bm}) => bm === "S");

          return (element?.value > 0.002 && getPoItemNumber(poItem) === PO_ITEMS.PO_ITEM_4) && elements.length ? 0.4 : "";
        },
        getMax({elements = [], poItem = ""}) {
          const element = elements.find(({bm}) => bm === "S");

          return (element?.value > 0.002 && getPoItemNumber(poItem) === PO_ITEMS.PO_ITEM_4) && elements.length ? 6 : "";
        },
        bm: "Ca/S",
        formula: "Ca/S",
      },
      {
        id: 20,
        getMin({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_4:
            return 0.17;
          default:
            return "";
          }
        },
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_1:
          case PO_ITEMS.PO_ITEM_2:
          case PO_ITEMS.PO_ITEM_3:
            return 0.22;
          case PO_ITEMS.PO_ITEM_4:
            return 0.21;
          default:
            return "";
          }
        },
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 21,
        getMin({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_4:
            return 0.39;
          default:
            return "";
          }
        },
        getMax({poItem = ""}) {
          switch (getPoItemNumber(poItem)) {
          case PO_ITEMS.PO_ITEM_4:
            return 0.44;
          default:
            return "";
          }
        },
        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
        bm: "CE_IIW"
      },
    ],
  },
  "CPW-ITP-24-23-SAWL Rev. 3": {
    company: ["Tremblay Inc."],
    elements: [
      {
        id: 1,
        max: 0.12,
        bm: "C"
      },
      {
        id: 2,
        max: 0.45,
        bm: "Si"
      },
      {
        id: 3,
        max: 1.65,
        bm: "Mn",
        getMax({elements = [], defaultElements = []}) {
          const carbon = elements.find(({bm}) => bm === "C") || {};
          const manganese = defaultElements.find(({bm}) => bm === "Mn") || {};

          if (Number(carbon.value) > carbon.max || !carbon.value) return manganese.max;

          const reductionTimes = round((carbon.max - Number(carbon.value)), 3) / 0.01;
          const additionToMn = reductionTimes * 0.05;

          return (manganese.max + additionToMn) > 1.85 ? 1.85 : round((manganese.max + additionToMn), 2);
        },
      },
      {
        id: 4,
        max: 0.020,
        bm: "P"
      },
      {
        id: 5,
        max: 0.010,
        bm: "S"
      },
      {
        id: 6,
        max: 0.05,
        bm: "Nb"
      },
      {
        id: 7,
        max: 0.05,
        bm: "V"
      },
      {
        id: 8,
        max: 0.04,
        bm: "Ti"
      },
      {
        id: 9,
        max: 0.060,
        bm: "Al"
      },
      {
        id: 10,
        max: 0.50,
        bm: "Cr"
      },
      {
        id: 11,
        max: 0.50,
        bm: "Ni"
      },
      {
        id: 12,
        max: 0.50,
        bm: "Cu"
      },
      {
        id: 13,
        max: 0.50,
        bm: "Mo"
      },
      {
        id: 14,
        max: 0.012,
        bm: "N"
      },
      {
        id: 15,
        max: 0.0005,
        bm: "B"
      },
      {
        id: 16,
        min: 2,
        bm: "Al/N",
        formula: "Al/N",
      },
      {
        id: 17,
        max: 0.20,
        formula: "C+Si/30+((Mn+Cu+Cr)/20)+Ni/60+Mo/15+V/10+B*5",
        bm: "CE_Pcm"
      },
      {
        id: 18,
        max: 0.42,
        formula: "C+Mn/6+(Cr+Mo+V)/5+(Ni+Cu)/15",
        bm: "CE_IIW"
      },
      {
        id: 19,
        max: 0.15,
        formula: "Nb+V+Ti",
        bm: "Nb+V+Ti"
      }
    ]
  },
  "PFF": {
    company: ["PFF"],
    prenLabel: "CQ_03",
    elements: [
      {
        id: 1,
        min: 0,
        max: 0.2,
        bm: "C"
      },
      {
        id: 1,
        min: 0.29,
        max: 1.35,
        bm: "Mn"
      },
      {
        id: 5,
        min: 0,
        max: 0.01,
        bm: "S"
      }
    ]
  }
});