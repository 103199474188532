import React, {useMemo} from "react";
import {Select, Input, MenuItem} from "@mui/material";

const ConfidentialEdit = ({test, updateTests, groupedTests}) => {
  const testIds = useMemo(() => groupedTests.map((test) => test._id), [groupedTests]);

  return (
    <Select
      defaultValue={false}
      fullWidth
      value={test.confidential}
      onChange={(event) => updateTests({confidential: event.target.value}, testIds)}
      input={<Input />}
    >
      <MenuItem value={true}>Yes</MenuItem>
      <MenuItem value={false}>No</MenuItem>
    </Select>
  );
};

export default ConfidentialEdit;