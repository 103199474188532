export default (theme) => ({
  notes: {
    fontWeight: theme.typography.fontWeightMedium,
    color: "#616161"
  },
  name: {
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },
  value: {
    color: "#616161",
    fontWeight: theme.typography.fontWeightMedium,
  },
});

