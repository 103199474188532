export default (theme)=> ({
  elementsButtons: {
    marginTop: 50,
    display: "flex",
    flexWrap: "wrap",
    "& button": {
      margin: "0 10px 10px 0",
    }
  },
  mathButtons: {
    display: "flex",
    marginBottom: 50,
    "& > button": {
      marginRight: 5
    }
  },
  customButton: {
    color: "#2196f3",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24,
    textAlign: "center",
    minWidth: 64,
    height: "fit-content",
    padding: "5px 16px",
    borderRadius: 4,
    boxShadow: "none",
    backgroundColor: "transparent",
    border: "1px solid rgba(33, 150, 243, 0.5)",
    lineHeight: "1.75",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#dcefff",
      border: "1px solid #249bff",
    },
    "&:focus": {
      backgroundColor: "#dcefff",
      border: "1px solid #249bff",
      outline: "none",
    },
    "&:active": {
      backgroundColor: "#a3d6ff",
      border: "1px solid #249bff",
      outline: "none",
    },
  },
  result: {
    fontSize: 17,
    marginTop: 32,
  }
});