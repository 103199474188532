import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import UserService from "../../core/api/user-service";
import styles from "./styles";
import Avatar from "@core/components/Avatar";
import {List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";

import {withStyles} from "tss-react/mui";

const UserSelect = ({classes}) => {
  const location = useLocation();
  const history = useHistory();

  const [accessToken, setAccessToken] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("accessToken");
    axios.defaults.headers.common["X-Token-Jwt"] = token;
    setAccessToken(token);
  }, [location]);

  useEffect(() => {
    if (accessToken) {
      UserService.getSelectUsersInfo()
        .then(setUsers)
        .catch(() => {
          history.push("/");
        });
    }
  }, [accessToken]);

  const loginWithSelectedAccount = useCallback((u) => () => {
    UserService.signInWithSelectedUser(u._id)
      .then((result) => history.push(result.to))
      .catch(() => {
        history.push("/");
      });
  }, []);

  return(
    <div className={classes.container}>
      <img src="/images/logo-symbol-blue.svg" alt="Steeltrace logo" className={classes.logoImage} />
      <Typography variant="h5" color="primary">Please select Steeltrace account to login</Typography>
      <List className={classes.listRoot}>
        {users.map((u) => (
          <ListItem alignItems="center" button onClick={loginWithSelectedAccount(u)} key={u._id}>
            <ListItemAvatar>
              <Avatar user={u} className={classes.blueAvatar} />
            </ListItemAvatar>
            <ListItemText
              primary={`${u.firstname} ${u.lastname}`}
              secondary={
                <>
                  <Typography component="span" color="textPrimary">
                    {u.username}
                  </Typography>
                  {u.company.name}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default withStyles(UserSelect, styles);
