export default (theme) => ({
  form: {
    maxWidth: "70%",
    margin: "0 auto",
  },
  loginScreen: {
    minHeight: "100vh",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  loginBox: {
    width: "1400px",
    maxWidth: "90%",
    background: theme.palette.common.white,
  },
  loginForm: {
    position: "relative"
  },
  loginStatistics: {
    height: 927,
    maxHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: theme.palette.primary.main,
  },
  oilwell: {
    width: "auto",
    height: "549px",
    display: "block",
    margin: "100px auto 40px auto",
  },
  logo: {
    display: "block",
    margin: "30px auto",
    width: "308px",
  },
  statistics: {
    maxWidth: 1000,
    width: "90%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  statisticsSection: {
    height: 250,
    width: 250,
    alignContent: "center",
    textAlign: "center",
    padding: 25,
    color: theme.palette.common.white,
    fontSize: 20,
  },
  statisticsNumber: {
    fontSize: 60,
    fontWeight: theme.typography.fontWeightMedium,
  },
});