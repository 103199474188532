import React, {Suspense} from "react";
import {ICONS_SVG} from "./constants";
import {Grid, CircularProgress} from "@mui/material";

const circularProgressStyles = {width: "100%!important", height: "auto!important"};

const IconistsIcon = ({icon, width = "20px", color}) => {
  return (
    <Grid
      container
      aria-label={icon}
      role="img"
      justifyContent="center"
      alignItems="center"
      color={color}
      width={width}
      height="auto"
    >
      <Suspense fallback={<CircularProgress sx={circularProgressStyles}/>}>
        {
          !!icon &&
            React.createElement(ICONS_SVG[icon], {
              style: {width: "100%", height: "auto"},
            })
        }
      </Suspense>
    </Grid>
  );
};

export default React.memo(IconistsIcon);