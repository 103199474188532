export default () => ({
  title: {
    marginRight: 20,
  },
  titleContainer: {
    position: "relative",
    paddingLeft: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterIcon: {
    fontSize: 16,
  },
  upArrow: {
    "& svg": {
      fontSize: 18,
    },
    position: "absolute",
    left: -5,
    top: -5,
  },
  downArrow: {
    "& svg": {
      fontSize: 18,
    },
    position: "absolute",
    left: -5,
    top: 5,
  },
  filterPopup: {
    padding: 10,
    "& input": {
      padding: 10,
    }
  },
  listItemCheckbox: {
    padding: 0,
  },
  listItemTitle: {
    padding: 0,
  }
});