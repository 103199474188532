import React, {useState} from "react";
import {Box, Link, useTheme} from "@mui/material";
import {BsLayoutSidebarInsetReverse} from "react-icons/bs";

const DrawerButtonTrigger = ({onClick, text, label = "Open"}) => {
  const theme = useTheme();

  const [triggerShown, setTriggerShown] = useState(false);

  return (
    <Box
      onClick={onClick}
      sx={{
        position: "relative",
        cursor: "pointer"
      }}
      onMouseEnter={() => setTriggerShown(true)}
      onMouseLeave={() => setTriggerShown(false)}
    >
      <Link underline="hover">
        {text}
      </Link>
      {triggerShown && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: -2.5,
            display: "flex",
            alignItems: "center",
            textTransform: "uppercase",
            cursor: "pointer",
            fontWeight: theme.typography.fontWeightMedium,
            border: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(0.4),
            backgroundColor: "#fff",
            borderRadius: 1,
            fontSize: "0.72rem",
            "&:hover": {
              backgroundColor: theme.palette.backgroundColor.main
            }
          }}
        >
          <BsLayoutSidebarInsetReverse />&nbsp;
          {label}
        </Box>
      )}
    </Box>
  );
};

export default DrawerButtonTrigger;