import React, {useMemo} from "react";
import {Table, TableBody, TableRow} from "@mui/material";
import TypeSelection from "../TypeSelection";
import {isEmpty} from "ramda";
import {splitIntoChunks} from "@core/helpers";

const TestSelectionTable = ({disabled, tests, selected, setSelected}) => {
  const chunks = useMemo(() => splitIntoChunks(2, tests), []);

  return (
    <Table className="styled-table">
      <TableBody>
        {chunks.map(([testA = {}, testB = {}]) => (
          <TableRow
            key={testA.title + testB.title}
            hover
          >
            <TypeSelection
              disabled={disabled}
              selected={selected}
              type={testA.type}
              setSelected={setSelected}
              title={testA.title}
            />
            {!isEmpty(testB) && (
              <TypeSelection
                disabled={disabled}
                selected={selected}
                type={testB.type}
                setSelected={setSelected}
                title={testB.title}
              />
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TestSelectionTable;