import React, {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import qs from "qs";
import {Box, TableBody, Table, TableHead, TableRow, TableCell, useTheme} from "@mui/material";
import NdtDataAnalysisRow from "./components/NdtDataAnalysisRow";
import NdtTotalRow from "./components/NdtTotalRow";
import useStores from "../../../../../../useStores";
import {getWeldsByStalkNumber} from "../../helpers";
import BlockHeader from "../../../BlockHeader";
import {SUB_TABS_LABELS} from "../../constants";
import {ENTITIES} from "../../../../constants";
import {useLocation, useParams} from "react-router";
import TableSkeleton from "@core/components/TableSkeleton";
import TestsDrawer from "../../../TestsDrawer";
import {sort} from "@core/helpers";
import {ORDERS} from "@core/constants/sort";

const StalkInformation = () => {
  const [welds, setWelds] = useState([]);
  const [index, setIndex] = useState(0);
  const [title, setTitle] = useState(null);

  const {CampaignStore} = useStores();

  const theme = useTheme();
  const location = useLocation();
  const {id: campaignId} = useParams();

  const campaign = CampaignStore.campaign;

  const isLoaded = campaignId === campaign._id;

  const stalks = useMemo(() => campaign.welds ? getWeldsByStalkNumber(campaign.welds).stalks : [], [campaign.welds?.length]);

  const {weld} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const sortedWelds = sort(ORDERS.ASC, "weldNumber", welds);

  const onClose = useCallback(() => {
    setWelds([]);
    setIndex(0);
  }, []);

  useEffect(() => {
    if(!weld) return;

    const index = sortedWelds.findIndex(({weldNumber}) => weldNumber === weld);

    if(index === -1) return;

    setIndex(index);
  }, [weld]);

  return (
    <>
      <BlockHeader
        id={SUB_TABS_LABELS.STALK_INFORMATION}
      >
        {SUB_TABS_LABELS.STALK_INFORMATION}
      </BlockHeader>
      <Box sx={{marginBottom: theme.spacing(3.5)}}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Stalk No</TableCell>
              <TableCell>Stated Length</TableCell>
              <TableCell>As-built Length</TableCell>
              <TableCell>No. of Joints</TableCell>
              <TableCell>No. of Welds</TableCell>
              <TableCell>No. of Repaired welds</TableCell>
              <TableCell>Weld NDT Defects</TableCell>
              <TableCell>Weld NDT Indications</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoaded ? (
              <>
                {stalks.map((stalk, index) => (
                  <NdtDataAnalysisRow
                    key={`stalk-${index}`}
                    stalk={stalk}
                    stalkNumber={stalk[0].stalkNumber}
                    setWelds={setWelds}
                    setTitle={setTitle}
                  />
                ))}
                <NdtTotalRow
                  stalks={stalks}
                  setWelds={setWelds}
                  setTitle={setTitle}
                />
              </>
            ) : (
              <TableSkeleton
                columns={8}
              />
            )}
          </TableBody>
        </Table>
      </Box>
      <TestsDrawer
        title={title}
        index={index}
        open={!!welds.length}
        close={onClose}
        pipes={[]}
        welds={sortedWelds}
        type={ENTITIES.WELD}
        withRepairs={false}
      />
    </>
  );
};

export default observer(StalkInformation);