import {TYPES} from "@core/constants/test";
import {acceptancesConfig as chemicalQcps} from "@core/components/TestForms/ChemicalComposition/data";
import {ACCEPTANCE_CRITERIA as hydrogenInducedCrackingQcps} from "@core/components/TestForms/HydrogenInducedCracking/data";
import {exceptionsConfig as impactQcps} from "@core/components/TestForms/ImpactTest/data";
import {EXCEPTIONS as tensileQcps} from "@core/components/TestForms/TensileTest/data";
import {exceptionsConfig as hardnessQcps} from "@core/components/TestForms/HardnessTest/data";
import {CUSTOM_EXCEPTIONS as dimensionalQcps} from "@core/components/TestForms/DimensionalTest/exceptions";
import {ACCEPTANCE_CRITERIA as ctodQcps} from "@core/components/TestForms/CrackTipOpeningDisplacement/data";

export const QCP_CONFIG_BY_TEST_TYPE = {
  [TYPES.HYDROGEN_INDUCED_CRACKING]: (qcp) => hydrogenInducedCrackingQcps[qcp],
  [TYPES.IMPACT]: (qcp) => impactQcps[qcp],
  [TYPES.TENSILE]: (qcp) => tensileQcps[qcp],
  [TYPES.HOT_TENSILE]: (qcp) => tensileQcps[`${qcp} (Hot tensile)`],
  [TYPES.HARDNESS]: (qcp) => hardnessQcps[qcp],
  [TYPES.CHEMICAL]: (qcp) => chemicalQcps[qcp],
  [TYPES.DIMENSIONAL]: (qcp) => dimensionalQcps[qcp],
  [TYPES.CRACK_TIP_OPENING_DISPLACEMENT]: (qcp) => ctodQcps[qcp],
};
