import React from "react";
import {Box, Grid, useTheme} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Notification from "@core/components/Modal";
import styles from "./styles";

const LoginContainer = ({classes, logoAndContentSx, children}) => {
  const theme = useTheme();

  return (
    <div className={classes.loginScreen}>
      <Grid container justifyContent="center" className={classes.loginBox}>
        <Grid item xs={4} container className={classes.loginForm}>
          <Box
            sx={{
              marginTop: 18,
              width: "100%",
              padding: 2,
              textAlign: "center",
              color: theme.palette.text.secondary,
              ...logoAndContentSx
            }}>
            <img src="/images/logo-symbol-blue.svg" className={classes.logo}/>
            <div className={classes.form}>
              {children}
            </div>
          </Box>
        </Grid>
        <Grid item xs={8} className={classes.loginStatistics}>
          <div>
            <img src="/images/oil-well.png" alt="" className={classes.oilwell}></img>
          </div>
          <Notification/>
        </Grid>
      </Grid>
    </div>
  );
};

LoginContainer.defaultProps = {
  logoAndContentSxStyles: {}
};

export default withStyles(LoginContainer, styles);