import FieldRow from "@core/components/CollapsibleTable/components/FieldRow";
import React from "react";

const RequiredFields = ({editable, fields}) => {
  return fields.map((field) => (
    <FieldRow
      key={field.name}
      field={field}
      required
      editable={editable}
    />
  ));
};

export default RequiredFields;