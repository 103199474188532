import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const AcknowledgementPopup = ({open, onCancel, onAccept}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        By activating this user I declare
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <List>
            <ListItem disableGutters>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText
                primary="This user is qualified and certified for the signing actions he or she will perform."
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText
                primary="The user has the necessary non-disclosures in place to protect the privacy of the other SteelTrace users."
              />
            </ListItem>
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" variant="contained">
          Decline
        </Button>
        <Button onClick={onAccept} color="primary" variant="contained" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcknowledgementPopup;
