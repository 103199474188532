export default () => ({
  avatarUpload: {
    "& .preloadImg": {
      width: 100,
      height: 100,
      padding: "17px",
      fontSize: "17px",
    },
    "& .avatar": {
      width: 100,
      height: 100,
      margin: "23px 0 15px 0",
    }
  },
  unitSelect: {
    display: "flex",
    flexDirection: "row",
  },
  addButton: {
    marginTop: 20,
  },
  companyInfo: {
    color: "#616161",
    display: "flex",
    height: "100%",
    alignItems: "center",
    "& h6": {
      marginTop: 30,
    }
  }
});
