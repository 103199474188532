import axios from "axios";
import {NotificationStore} from "configure-store";

const OK_MESSAGE = "OK";

const MOVED_TO_ARCHIVE_STATUS_CODE = 301;

// Add a response interceptor
axios.interceptors.response.use( (response) => {
  const successMessage = response.config.successMessage;
  const infoMessage = response.data.message || "";

  if(infoMessage && infoMessage !== OK_MESSAGE ) {
    NotificationStore.showInfo(response.data.message);
  }

  else if(successMessage){
    NotificationStore.showSuccess(successMessage);
  }

  if(response.data?.hasOwnProperty("data")) {
    response.data = response.data.data;
  }

  return response;
},  (error) => {
  if(error.response.status === MOVED_TO_ARCHIVE_STATUS_CODE) {
    const data = error.response.data.data;
    window.open(`/${data.type}/archive/${data.entityId}`, "_self");
  }

  let message = "Sorry, something went wrong";

  if(error.response && error.response.data) {
    error.message = error.response.data;
    message = error.response.data.message || error.response.data.error;
  }

  if(error.message && typeof error.message === "string"){
    message = error.message;
  }

  NotificationStore.showError(message);

  return Promise.reject(error.response);
});
