import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {isEmpty} from "ramda";
import {Typography, CircularProgress} from "@mui/material";
import Form from "../components/Form";
import useStores from "../../useStores";

const ExternalUser = ({match}) => {
  const {ExternalUsersStore, NotificationStore} = useStores();

  useEffect(() => {
    ExternalUsersStore.getExternalUser(match.params.id);
  }, []);

  const updateExternalUser = async (values) => {
    await ExternalUsersStore.updateExternalUser(match.params.id, values);
    NotificationStore.showSuccess("Successfully changed!");
  };

  return (
    <div className="content padded-container">
      <Typography component="h1" variant="h4" gutterBottom>
        External User
      </Typography>
      {isEmpty(ExternalUsersStore.externalUser) ? (
        <CircularProgress />
      ) : (
        <Form
          externalUser={ExternalUsersStore.externalUser}
          onSubmit={updateExternalUser}
        />
      )}
    </div>
  );
};

export default observer(ExternalUser);
