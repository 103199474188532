export const columns = [{
  dataIndex: "properties.productType",
  isSorted: "true",
  title: "Product type",
  render: (product) =>  product.properties.productType.name,
}, {
  isSorted: "true",
  dataIndex: "creator",
  title: "Manufacturer",
  render: (product) => product.creator.name,
}, {
  isSorted: "true",
  dataIndex: "heat",
  title: "Heat",
  render: (product) => product.properties.heat,
}, {
  dataIndex: "_id",
  title: "Product ID",
}, {
  dataIndex: "properties.notes",
  isSorted: "true",
  title: "Description",
  render: (product) => product.properties.notes || "-",
}];