import {TableStore} from "@core/stores";
import axios from "axios";
import {action, makeObservable, observable} from "mobx";
import {isEmpty} from "ramda";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class ProductStore {
  constructor() {
    makeObservable(this, {
      products: observable,
      create: action,
      deleteById: action,
      updateById: action
    });
  }

  products = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.products.fetch, ...queryParams};
      const {offset, limit, search, sort} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value: search},
      };

      this.products.setFetch(newQueryParams);

      return axios.get(
        ROUTES.PRODUCT[ACTIONS.ALL],
        {params}
      );
    }
  });
  
  async create(data) {
    const response = await axios.post(ROUTES.PRODUCT[ACTIONS.CREATE], data);

    this.products.data = [response.data, ...this.products.data];
  }
  
  async deleteById(id) {
    await axios.delete(ROUTES.PRODUCT[ACTIONS.REMOVE](id));

    this.products.data = this.products.data.filter((product) => product._id !== id);
    this.products.total = this.products.total - 1;
  }
  
  async updateById(id, data) {
    const response = await axios.patch(ROUTES.PRODUCT[ACTIONS.UPDATE](id), data);

    this.products.data = this.products.data.map((product) => product._id === id ? response.data : product);
  }
}

export default new ProductStore();