import React, {useRef, useState} from "react";
import {withStyles} from "tss-react/mui";
import {CircularProgress} from "@mui/material";
import ResizableDrawer from "@core/components/ResizableDrawer";
import Test from "@core/components/Test";
import DrawerHeader from "@core/components/DrawerHeader";
import TimelineToggle from "@core/components/TimelineToggle";
import styles from "./styles";

const TestsDrawer = ({classes, close, open, tests, productId, updateTest, shareLink, timeline}) => {
  const [inView, setInView] = useState(false);

  const container = useRef();

  return (
    <ResizableDrawer
      className={classes.drawer}
      paperClasses={{root: classes.container}}
      open={open}
      close={close}
      keepMounted={false}
      SlideProps={{
        onEntered: () => setInView(true),
        onExited: () => setInView(false),
      }}
    >
      <DrawerHeader
        close={close}
        text={`Tests Reports (${tests.length})`}
      />
      {inView ? (
        <div
          className={classes.reports}
          ref={container}
        >
          {tests.map((test, idx) => {
            if(!test) return;

            return (
              <div className={classes.testReport}>
                <Test
                  test={test}
                  isLast={idx === tests.length - 1}
                  isFirst={!idx}
                  disabled={!updateTest}
                  shareLink={shareLink}
                  hideSign
                  productId={productId}
                  timeline={!!timeline}
                  onTestUpdate={updateTest}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}
      {timeline && (
        <TimelineToggle
          alwaysInViewPort
          refTest={container}
        />
      )}
    </ResizableDrawer>
  );
};

export default withStyles(TestsDrawer, styles);