import classNames from "classnames";
import {ExpansionPanel, ExpansionPanelSummary} from "@core/components/TimelineBlock/accordionComponents";
import {Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import React from "react";
import styles from "./styles";

function DisabledExpansionPanel({classes, stripeComponent, additionalExpansionPanelRootClass, header}) {
  return (
    <ExpansionPanel disabled additionalClasses={{root: classNames(classes.expansionPanelRoot, additionalExpansionPanelRootClass)}}>
      <ExpansionPanelSummary additionalClasses={{root: classes.summaryContentRoot, content: classes.summaryContent}}>
        {stripeComponent}
        <Typography variant="body1">{header}</Typography>
        <Typography variant="body2" color="textSecondary" classes={{root: classes.pending}}>
          pending
        </Typography>
      </ExpansionPanelSummary>
    </ExpansionPanel>
  );
}

export default withStyles(DisabledExpansionPanel, styles);