import React, {useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Typography, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import TableSkeleton from "@core/components/TableSkeleton";
import TableFooter from "@core/components/TableFooter";
import {TableStore} from "../../../../../Dashboard/stores/tableStore";

const DashboardTable = observer(({title, noFoundText, type = "test", columns, params, onRowClick}) => {
  const [Store] = useState(() => new TableStore());

  const {total, status, data: items, fetch} = Store[type];

  useEffect(() => {
    Store[type].load(params);
  }, [title]);

  useEffect(() => {
    return () => Store[type].reset();
  }, []);

  return (
    <>
      <Typography variant="h6" marginBottom={1}>
        {title}
      </Typography>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell key={col.title} align={!index ? "left" : "center"}>
                {col.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {status.loaded ? (
            items.length ? items.map((item) => (
              <TableRow
                key={item._id}
                hover
                sx={{cursor: "pointer"}}
                onClick={() => onRowClick ? onRowClick(item) : {}}
              >
                {columns.map((col) => (
                  <TableCell key={col.title + item._id}>
                    {col.render(item)}
                  </TableCell>
                ))}
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={4}>{noFoundText}</TableCell>
              </TableRow>
            )
          ) : (
            <TableSkeleton
              columns={columns.length}
            />
          )}
        </TableBody>
        <TableFooter
          rowsPerPageOptions={[3, 5, 10, 25]}
          isLoaded={status.loaded}
          items={items}
          total={total}
          limit={fetch.limit}
          offset={fetch.offset}
          onOffsetChange={(offset) => Store[type].load({offset})}
          onLimitChange={(limit) => Store[type].load({limit, offset: 0})}
        />
      </Table>
    </>
  );
});

export default DashboardTable;
