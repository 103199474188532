import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Group, Text} from "react-konva";
import useStores from "../../../../../../../../../../useStores";
import {PIPE_WITH_TYPE_CONFIG, STAGE_CONFIG} from "../../../../constants";
import {PIPE_TOOLTIP_CONFIG} from "../../../Stalk/constants";
import TooltipContainer from "../TooltipContainer";

const CONTENT_WIDTH = PIPE_TOOLTIP_CONFIG.WIDTH - 2 * PIPE_TOOLTIP_CONFIG.PADDING;
const ROW_HEIGHT = PIPE_TOOLTIP_CONFIG.FONT_SIZE + PIPE_TOOLTIP_CONFIG.MARGIN;

const PipeTooltip = ({node, scale, stage}) => {
  const {CampaignStore} = useStores();

  const scaleBy = useMemo(() => {
    const scaleBy = (STAGE_CONFIG.MAX_SCALE - scale) || 0.1;

    return Math.max(PIPE_TOOLTIP_CONFIG.SCALE_MIN, scaleBy);
  }, [scale]);

  const baseMaterialsTypes = CampaignStore.baseMaterialsTypes;
  const coatingTypesByPipeNumber = CampaignStore.coatingTypesByPipeNumber;

  return (
    <TooltipContainer
      node={node}
      config={PIPE_TOOLTIP_CONFIG}
      scale={scaleBy}
      stage={stage}
    >
      <Group>
        <Text
          width={CONTENT_WIDTH}
          align="left"
          text="JOINT"
          fill={PIPE_TOOLTIP_CONFIG.LIGHT_TEXT_COLOR}
          fontSize={PIPE_TOOLTIP_CONFIG.FONT_SIZE}
          fontFamily={STAGE_CONFIG.FONT_FAMILY}
        />
        <Text
          width={CONTENT_WIDTH}
          align="right"
          text={node.data.joint}
          fill={PIPE_TOOLTIP_CONFIG.TEXT_COLOR}
          fontSize={PIPE_TOOLTIP_CONFIG.FONT_SIZE}
          fontFamily={STAGE_CONFIG.FONT_FAMILY}
          fontStyle="bold"
        />
      </Group>
      {node.data.pipe && (
        <Group
          y={ROW_HEIGHT}
        >
          <Text
            width={CONTENT_WIDTH}
            align="right"
            text={`Pipe ID: ${node.data.pipe}`}
            fill={PIPE_TOOLTIP_CONFIG.LIGHT_TEXT_COLOR}
            fontSize={PIPE_TOOLTIP_CONFIG.GENERAL_TEXT_FONT_SIZE}
            fontFamily={STAGE_CONFIG.FONT_FAMILY}
            fontStyle="bold"
          />
        </Group>
      )}
      {node.data.type && (
        <Group
          y={ROW_HEIGHT * 2}
        >
          <Text
            width={CONTENT_WIDTH}
            align="left"
            text={node.data.type}
            fill={PIPE_WITH_TYPE_CONFIG.BACKGROUND_COLORS[baseMaterialsTypes.findIndex((t) => t === node.data.type)]}
            fontSize={PIPE_TOOLTIP_CONFIG.FONT_SIZE}
            fontFamily={STAGE_CONFIG.FONT_FAMILY}
            fontStyle="bold"
          />
        </Group>
      )}
      {!!coatingTypesByPipeNumber[node.data.pipe]?.length && (
        <Group
          y={ROW_HEIGHT * 3}
        >
          <Text
            width={CONTENT_WIDTH}
            align="left"
            text={coatingTypesByPipeNumber[node.data.pipe].join("/")}
            fill={PIPE_TOOLTIP_CONFIG.TEXT_COLOR}
            fontSize={PIPE_TOOLTIP_CONFIG.FONT_SIZE}
            fontFamily={STAGE_CONFIG.FONT_FAMILY}
            fontStyle="bold"
          />
        </Group>
      )}
    </TooltipContainer>
  );
};

export default observer(PipeTooltip);