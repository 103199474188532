import React, {useCallback, useState} from "react";
import {observer} from "mobx-react-lite";
import TestsDrawer from "@core/components/TestsDrawer";
import CampaignInfo from "../../../CampaignInfo";
import BaseProducts from "../../../BaseProducts";
import RawMaterials from "../../../RawMaterials";
import CampaignProgress from "../../../CampaignProgress";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {SUB_TABS_LABELS} from "../../constants";
import useStores from "../../../../../../useStores";

const GeneralInfo = observer(({tests, setFilteredTestIds}) => {
  const [openTests, setOpenTests] = useState([]);

  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;

  const onTestClick = useCallback((tests) => setOpenTests(tests), []);

  return (
    <>
      <CampaignInfo
        type={campaign.coatingType || CAMPAIGN_TYPES.COATING}
      />
      <BaseProducts
        label={SUB_TABS_LABELS.BASE_PRODUCTS}
      />
      <RawMaterials
        label={SUB_TABS_LABELS.RAW_MATERIALS}
      />
      <CampaignProgress
        label={SUB_TABS_LABELS.CAMPAIGN_PROGRESS}
        onTestClick={onTestClick}
        tests={tests}
        setFilteredTestIds={setFilteredTestIds}
      />
      <TestsDrawer
        open={openTests.length}
        tests={openTests}
        close={() => setOpenTests([])}
      />
    </>
  );
});

export default GeneralInfo;