export default (theme) => ({
  paper: {
    marginBottom: theme.spacing(3.5),
  },
  generalInfoTable: {
    "& tr": {
      height: 40,
    },
    "& td": {
      padding: "5px 10px",
    }
  },
});
