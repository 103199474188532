import {PO_ITEMS} from "@core/constants/poItems";

export const TEST_STANDARDS = [
  "BS 7448-1 (1991)",
];

export const ACCEPTANCE_CRITERIA = {
  "ISO 15156-2 (2015) - NACE MR0175 Table B.1": {},
  "QCP 6916 Rev. 3": {
    company: ["TenarisLab", "Tremblay Inc."],
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.50,
    },
    temperature: 0,
  },
  "QCP 8251 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.38,
    },
    temperature: -10,
  },
  "QCP 8251 Rev. 6 (After reeling)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: -10,
  },
  "QCP 8262 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.38,
    },
    temperatureByPoItem: {
      [PO_ITEMS.PO_ITEM_1]: -18,
      [PO_ITEMS.PO_ITEM_2]: -18,
      [PO_ITEMS.PO_ITEM_3]: -10,
    },
  },
  "ITP-BPM-22.20 MPQT": {
    company: ["Berg Pipe", "Tremblay Inc."],
    temperature: -18,
  },
  "ITP 011/23/124277 Rev. 05 MPQT": {
    company: ["Tremblay Inc."],
    orientation: "longitudinal",
    requirements: {
      crackTipOpeningDisplacementMin: 0.2,
    },
    temperature: -18,
  },
  "QCP 5513 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: 2.8,
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.50,
    },
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperatureByPoItem: {
      [PO_ITEMS.PO_ITEM_1]: 3,
      [PO_ITEMS.PO_ITEM_2]: 3,
      [PO_ITEMS.PO_ITEM_3]: 3,
      [PO_ITEMS.PO_ITEM_4]: -3,
    },
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.50,
    },
  },
  "QCP 5987 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: -3,
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.50,
    },
  },
  "QCP 5987 Rev. 2 (For information only)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: -12,
  },
  "QCP 5983 Rev. 5": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: 3,
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.50,
    },
  },
};