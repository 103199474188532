import {STATUSES} from "@core/constants/test";

export const CERTIFICATE_VIEWS = {
  READY_FOR_TRANSFER: "ready",
  NOT_COMPLETE_YET: "not_complete"
};

export const CERTIFICATE_STATUSES_BY_VIEW = {
  [CERTIFICATE_VIEWS.READY_FOR_TRANSFER]: [STATUSES.APPROVED],
  [CERTIFICATE_VIEWS.NOT_COMPLETE_YET]: [STATUSES.ASSIGNED, STATUSES.FILLED, STATUSES.INSPECTED, STATUSES.SUBMITTED, STATUSES.INSPECTING]
};

export const VIEWS_TITLES = {
  [CERTIFICATE_VIEWS.READY_FOR_TRANSFER]: "Ready for transfer",
  [CERTIFICATE_VIEWS.NOT_COMPLETE_YET]: "Not complete yet",
};

