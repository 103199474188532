import React from "react";
import SingleIdentificationOfInspectedItems from "../../../Tests/Test/components/SingleIdentificationOfInspectedItems";
import MultipleIdentificationOfInspectedItems from "../../../Tests/Test/components/MultipleIdentificationOfInspectedItems";

const IdentificationOfInspectedItems = ({inspectedCertificates = [], ...props}) => {
  if (inspectedCertificates.length > 1) {
    return (
      <MultipleIdentificationOfInspectedItems
        certificates={inspectedCertificates}
      />
    );
  }

  const [certificate] = inspectedCertificates;

  return (
    <SingleIdentificationOfInspectedItems
      certificate={certificate}
      {...props}
    />
  );
};

export default IdentificationOfInspectedItems;
