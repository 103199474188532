import React, {useMemo, useState, useCallback} from "react";
import {observer} from "mobx-react-lite";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {indexBy, prop} from "ramda";
import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";
import TableFooter from "@core/components/TableFooter";
import PdfDrawer from "@core/components/PdfDrawer";
import useStores from "../../../../../../useStores";
import TableSkeleton from "@core/components/TableSkeleton";
import FilterableSortableHeaderCell from "@core/components/FilterableSortableHeaderCell";
import {filterElements, sort} from "@core/helpers";
import {ORDERS} from "@core/constants/sort";

const WeldsList = ({welds = [], currentWeld = {}, onWeldClick, onPipeClick, header, isLoaded = true, ...props}) => {
  const [limit, setLimit] = useState(props.limit || 30);
  const [offset, setOffset] = useState(0);

  const [order, setOrder] = useState(ORDERS.ASC);
  const [orderBy, setOrderBy] = useState("weldNumber");
  const [filter, setFilter] = useState({});
  const [openFilePath, setOpenFilePath] = useState("");

  const theme = useTheme();

  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;

  const baseMaterialsById = useMemo(() => indexBy(prop("_id"), campaign.products || []), [campaign.products]);

  const formattedWelds = useMemo(() => welds.map((weld) => ({
    ...weld,
    pipeANumber: baseMaterialsById[weld.pipeA]?.pipeNumber,
    pipeBNumber: baseMaterialsById[weld.pipeB]?.pipeNumber
  })), [campaign.products, welds]);

  const filtered = useMemo(() => filterElements(filter, formattedWelds), [filter, formattedWelds]);
  const sorted = useMemo(() => sort(order, orderBy, filtered), [filtered, order, orderBy, filter]);

  const weldsPaginated = useMemo(() => sorted.slice(offset, offset + limit), [sorted, offset, limit]);

  const withStatus = useMemo(() => !isLoaded || formattedWelds.some((weld) => weld.status), [formattedWelds, isLoaded]);
  const withStatusReason = useMemo(() => !isLoaded || formattedWelds.some((weld) => weld.statusReason), [formattedWelds, isLoaded]);
  const withWps = useMemo(() => !isLoaded || formattedWelds.some((weld) => weld.wps?._id), [formattedWelds, isLoaded]);

  const updateFilter = useCallback((filter) => {
    setFilter(filter);
    setOffset(0);
  }, []);

  return (
    <>
      <Box sx={{marginBottom: theme.spacing()}}>
        <h3>{header}</h3>
      </Box>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            {!currentWeld._id ? (
              <FilterableSortableHeaderCell
                id="weldNumber"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={formattedWelds}
              >
                Weld ID
              </FilterableSortableHeaderCell>
            ) : (
              <TableCell>Weld ID</TableCell>
            )}
            {!currentWeld._id ? (
              <FilterableSortableHeaderCell
                id="stalkNumber"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={formattedWelds}
              >
                Stalk Number
              </FilterableSortableHeaderCell>
            ) : (
              <TableCell>Stalk Number</TableCell>
            )}
            {!currentWeld._id ? (
              <FilterableSortableHeaderCell
                id="jointNumberA"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={formattedWelds}
              >
                Joint Number (Side A)
              </FilterableSortableHeaderCell>
            ) : (
              <TableCell>Joint Number (Side A)</TableCell>
            )}
            {!currentWeld._id ? (
              <FilterableSortableHeaderCell
                id="jointNumberB"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={formattedWelds}
              >
                Joint Number (Side B)
              </FilterableSortableHeaderCell>
            ) : (
              <TableCell>Joint Number (Side B)</TableCell>
            )}
            {!currentWeld._id ? (
              <FilterableSortableHeaderCell
                id="pipeANumber"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={formattedWelds}
              >
                Pipe ID (Side A)
              </FilterableSortableHeaderCell>
            ) : (
              <TableCell>Pipe ID (Side A)</TableCell>
            )}
            {!currentWeld._id ? (
              <FilterableSortableHeaderCell
                id="pipeBNumber"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={formattedWelds}
              >
                Pipe ID (Side B)
              </FilterableSortableHeaderCell>
            ) : (
              <TableCell>Pipe ID (Side B)</TableCell>
            )}
            {withStatus && (
              !currentWeld._id ? (
                <FilterableSortableHeaderCell
                  id="status"
                  setOrder={setOrder}
                  order={order}
                  setOrderBy={setOrderBy}
                  orderBy={orderBy}
                  filter={filter}
                  setFilter={updateFilter}
                  elements={formattedWelds}
                >
                  Status
                </FilterableSortableHeaderCell>
              ) : (
                <TableCell>Status</TableCell>
              )
            )}
            {withStatusReason && (
              !currentWeld._id ? (
                <FilterableSortableHeaderCell
                  id="statusReason"
                  setOrder={setOrder}
                  order={order}
                  setOrderBy={setOrderBy}
                  orderBy={orderBy}
                  filter={filter}
                  setFilter={updateFilter}
                  elements={formattedWelds}
                >
                  Status Reason
                </FilterableSortableHeaderCell>
              ) : (
                <TableCell>Status Reason</TableCell>
              )
            )}
            {withWps && (
              <TableCell>WPS</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            <>
              {weldsPaginated.map((weld) => (
                <TableRow
                  key={weld._id}
                  sx={welds.length > 1 ? {
                    "& td": {
                      backgroundColor: weld._id === currentWeld._id ? "#F1F1F1" : "inherit"
                    },
                  } : {}}
                >
                  <TableCell>
                    {weld._id !== currentWeld._id ? (
                      <DrawerButtonTrigger
                        onClick={() => onWeldClick(weld)}
                        text={weld.weldNumber}
                      />
                    ) : weld.weldNumber}
                  </TableCell>
                  <TableCell>{weld.stalkNumber || "-"}</TableCell>
                  <TableCell>
                    {weld.jointNumberA ? (
                      onPipeClick ? (
                        <DrawerButtonTrigger
                          onClick={() => {
                            onPipeClick({
                              pipeNumber: weld.jointNumberA,
                              _id: weld.pipeA,
                              weldNumber: weld.weldNumber,
                            });
                          }}
                          text={weld.jointNumberA}
                        />
                      ) : weld.jointNumberA
                    ) : "-"}
                  </TableCell>
                  <TableCell>
                    {weld.jointNumberB ? (
                      onPipeClick ? (
                        <DrawerButtonTrigger
                          onClick={() => {
                            onPipeClick({
                              pipeNumber: weld.jointNumberB,
                              _id: weld.pipeB,
                              weldNumber: weld.weldNumber,
                            });
                          }}
                          text={weld.jointNumberB}
                        />
                      ) : weld.jointNumberB
                    ) : "-"}
                  </TableCell>
                  <TableCell>
                    {weld.pipeA ? (
                      onPipeClick ? (
                        <DrawerButtonTrigger
                          onClick={() => {
                            onPipeClick({
                              pipeNumber: weld.pipeANumber,
                              _id: weld.pipeA,
                            });
                          }}
                          text={weld.pipeANumber}
                        />
                      ) : weld.pipeANumber
                    ) : "-"}
                  </TableCell>
                  <TableCell>
                    {weld.pipeB ? (
                      onPipeClick ? (
                        <DrawerButtonTrigger
                          onClick={() => {
                            onPipeClick({
                              pipeNumber: weld.pipeBNumber,
                              _id: weld.pipeB,
                            });
                          }}
                          text={weld.pipeBNumber}
                        />
                      ) : weld.pipeBNumber
                    ) : "-"}
                  </TableCell>
                  {withStatus && (
                    <TableCell>{weld.status}</TableCell>
                  )}
                  {withStatusReason && (
                    <TableCell>{weld.statusReason || "-"}</TableCell>
                  )}
                  {withWps && (
                    <TableCell
                      sx={{minWidth: 110}}
                    >
                      {(weld.wps && !!weld.wps.attachments?.length && weld.wps.name) ? (
                        <DrawerButtonTrigger
                          onClick={() => setOpenFilePath(weld.wps.attachments[0].file.dir + weld.wps.attachments[0].file.name)}
                          text={weld.wps.name}
                        />
                      ) : weld.wps?.name || "-"}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </>
          ) : (
            <TableSkeleton
              columns={9}
            />
          )}
        </TableBody>
        {welds.length > 1 && (
          <TableFooter
            isLoaded
            items={weldsPaginated}
            total={welds.length}
            limit={limit}
            offset={offset}
            onOffsetChange={setOffset}
            onLimitChange={setLimit}
          />
        )}
      </Table>
      <PdfDrawer
        open={!!openFilePath}
        close={() => setOpenFilePath("")}
        file={openFilePath}
      />
    </>
  );
};

export default observer(WeldsList);