const CERTIFICATE_FIELDS = [{
  name: "heat",
  type: "string",
  friendlyName: "Heat",
}, {
  name: "norm",
  type: "string",
  friendlyName: "Norm",
}, {
  name: "grade",
  type: "string",
  friendlyName: "Grade",
}, {
  name: "certificateType",
  type: "string",
  friendlyName: "Certificate type",
}, {
  name: "productType",
  type: "string",
  friendlyName: "Product type",
}, {
  name: "lotId",
  type: "string",
  friendlyName: "Lot ID",
}, {
  name: "uns",
  type: "string",
  friendlyName: "TP UNS",
}, {
  name: "markingText",
  type: "string",
  friendlyName: "Information",
}, {
  name: "markingNotes",
  type: "string",
  friendlyName: "Additional notes",
}, {
  name: "items",
  friendlyName: "Items",
  type: "array",
}];

export {CERTIFICATE_FIELDS};
