import React, {useMemo} from "react";
import {values} from "ramda";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const AcceptanceTable = ({config, testZones}) => {
  const withZoneAcceptanceCriteria = useMemo(() => testZones.some((zone) => zone.HV10), []);
  const withGlobalAcceptanceCriteria = useMemo(() => values(config.HV10).some((value) => value), []);

  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          {withZoneAcceptanceCriteria && <TableCell>Zone</TableCell>}
          <TableCell />
          <TableCell>Single [HV10]</TableCell>
          <TableCell>Average [HV10]</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {withGlobalAcceptanceCriteria && (
          <>
            <TableRow>
              {withZoneAcceptanceCriteria && <TableCell />}
              <TableCell>Min</TableCell>
              <TableCell>{config.HV10.Dmin || "-"}</TableCell>
              <TableCell>{config.HV10.AvgMin || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Max</TableCell>
              <TableCell>{config.HV10.Dmax || "-"}</TableCell>
              <TableCell>{config.HV10.AvgMax || "-"}</TableCell>
            </TableRow>
          </>
        )}
        {withZoneAcceptanceCriteria && (
          testZones.map((zone) => (
            <>
              <TableRow>
                <TableCell rowSpan={2}>{zone.name}</TableCell>
                <TableCell>Min</TableCell>
                <TableCell>{zone.HV10.Dmin || "-"}</TableCell>
                <TableCell>{zone.HV10.AvgMin || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Max</TableCell>
                <TableCell>{zone.HV10.Dmax || "-"}</TableCell>
                <TableCell>{zone.HV10.AvgMax || "-"}</TableCell>
              </TableRow>
            </>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default AcceptanceTable;