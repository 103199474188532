import {keyframes} from "tss-react";
export default () => ({
  container: {
    position: "relative",
    height: 20,
    display: "flex",
    alignItems: "flex-end"
  },
  chart: {
    overflow: "hidden",
    width: 0,
    "animation": `${keyframes`
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    `} 1.5s ease forwards`,
    animationDelay: ".2s",
    borderRadius: 8,
    position: "relative",
  },
});