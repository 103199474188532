export default (theme) => ({
  container: {
    zIndex: 3,
    position: "relative",
    display: "flex",
    alignItems: "center",
    "& div, button": {
      backgroundColor: "#fff",
      borderRadius: 4,
    },
    "& button": {
      "&:hover": {
        backgroundColor: "#fff"
      }
    }
  },
  selectOutlined: {
    paddingLeft: theme.spacing(),
    paddingTop: 6,
    paddingBottom: 6,
  },
  select: {
    "&:focus": {
      backgroundColor: "#fff"
    }
  }
});