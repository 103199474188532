export default (theme) => ({
  container: {
    backgroundColor: theme.palette.backgroundColor.main,
    paddingBottom: theme.spacing(2),
    "& br": {
      display: "none"
    },
    "& table": {
      pageBreakInside: "auto"
    },
    "&tr":   {
      pageBreakInside: "avoid",
      pageBreakAfter: "auto"
    }
  }
});