export default (theme) => ({
  p40: {
    padding: 40
  },

  mb25: {
    marginBottom: theme.spacing(4)
  },

  submitContainer:{
    width:"100%",
    backgroundColor:"#F5F5F5",
    position: "sticky",
    bottom: 0,
    textAlign:"end",

    "& Button": {
      margin:20
    },
  },

  addLocation: {
    height: "100%",
    display: "flex",
    "& div": {
      height: "100%",

      "& button": {
        flex: 1
      }
    },
  },

  label: {
    fontSize: 14.5,
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: 10,
  },

  proLabel: {
    color: "#0288d1"
  },

  avatarUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .avatar": {
      width: 150,
      height: 150,
      margin: "auto",
    }
  },

  underlineInput: {
    marginBottom: 15
  },

  uploader: {
    marginTop: 15,
    width: "100%"
  },

  location: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 230,
    padding: 15
  },

  value: {
    position: "relative",
    marginTop: 5,
    marginBottom: 7,
    fontSize: 16
  },

  asterisk: {
    position: "relative",
    "&:after": {
      position: "absolute",
      content: "' *'",
      color: "#ff0000"
    }
  },
  btnContainer:{
    padding: theme.spacing(1)
  }
});
