import React from "react";
import {ListItem, ListItemText} from "@mui/material";
import {withStyles} from "tss-react/mui";
import DatePicker from "react-multi-date-picker";
import Icon from "react-multi-date-picker/components/icon";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {FILTERS} from "@core/constants/filters";
import styles from "./styles";

const DateCreatedFilter = ({classes, filters, setFilters}) => {
  const [dateA, dateB] = filters[FILTERS.DATE_CREATED] ? filters[FILTERS.DATE_CREATED] : [];

  return (
    <ListItem>
      <ListItemText
        primary={FILTERS.DATE_CREATED}
        classes={filters[FILTERS.DATE_CREATED]?.length ? {primary: classes.selectedListItem} : {}}
      />
      <DatePicker
        range
        render={<Icon width={24} height={24} />}
        className={classes.datePicker}
        inputClass={classes.pickerInput}
        onChange={([dateA, dateB]) => {
          const formatedDateA = dateA ? dateA.format() : null;
          const formatedDateB = dateB ? dateB.format() : null;
          setFilters({...filters, [FILTERS.DATE_CREATED]: [formatedDateA, formatedDateB]});
        }}
        value={[dateA, dateB]}
        format={DATE_FORMAT}
      />
    </ListItem>
  );
};

export default withStyles(DateCreatedFilter, styles);