import React, {useMemo} from "react";
import {
  Grid,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  DialogContent,
  Dialog,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import testsConfig from "@core/configs/test";
import File from "@core/components/File";
import SupplementTestForm from "@core/components/TestAddDataForms/SupplementTest";
import styles from "./styles";
const Supplement = (props) => {
  const {classes, test, formRef} = props;
  
  const hasFiles = useMemo(() => test.properties.elements.some((element) => element.file.preview_path), []);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Creator</TableCell>
                <TableCell>Test type</TableCell>
                <TableCell>Type</TableCell>
                {hasFiles && (
                  <TableCell>Download</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {test.properties.elements.map((el, index) =>
                <TableRow key={index}>
                  <TableCell>{el.creator}</TableCell>
                  <TableCell>{testsConfig[el.testType].title}</TableCell>
                  <TableCell>{el.certificateType}</TableCell>
                  {hasFiles && (
                    <TableCell className={classes.file}>
                      {el.file.preview_path && (
                        <File file={el.file.preview_path} />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {props.addDataOpen && <Dialog
        open={props.addDataOpen}
        onClose={props.dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <SupplementTestForm
            formRef={formRef}
            saveTest={props.updateTest}
            test={test}
          />
        </DialogContent>
      </Dialog>
      }
    </>
  );
};

export default withStyles(Supplement, styles);
