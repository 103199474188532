import React, {useState, useEffect} from "react";
import axios from "axios";
import {api} from "Config";

const Image = ({src, ...props}) => {
  const [image, setImage] = useState(null);
    
  useEffect(() => {
    axios.get(`${api}/uploads/download${src}`).then((res) => setImage(res.data));
  }, [src]);

  return image ? <img src={`data:;base64,${image}`} {...props} /> : null;
};

export default Image;