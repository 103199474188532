import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  DialogContent,
  Dialog,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import HeatTreatmentForm from "@core/components/TestAddDataForms/HeatTreatment";
import File from "@core/components/File";

const TYPES = {
  ANNEALING: "Annealing",
  NORMALIZED: "Normalization",
  POST_WELD_AND_HEAT_TREATMENT: "Post weld heat treatment",
  QUENCHING: "Quenching",
  SOLUTION_ANNEALING: "Solution annealing",
  TEMPERING: "Tempering",
  QUENCH_AND_TEMPER: "Quench and Temper",
  CARBIDE_SOLUTION: "Carbide solution treatment"
};

const getTempUnit = (unit = "celsius") => `\u00b0${unit === "celsius" ? "C" : "F"}`;

const TreatmentRow = ({method, type, temp, holdingTime, coolingMedium, unitOfHoldingTime, holdingThickness}) => (
  <TableRow>
    {method && <TableCell rowSpan={2}>{method}</TableCell>}
    <TableCell>{type}</TableCell>
    <TableCell>{temp}</TableCell>
    {holdingTime && (
      <TableCell>
        {holdingTime} {unitOfHoldingTime === "thickness" && ` every ${holdingThickness} of thickness`}
      </TableCell>
    )}
    <TableCell>{coolingMedium}</TableCell>
  </TableRow>
);

const HeatTreatment = ({classes, test, ...props}) => {

  const isAdvancedSettingsFilled = test.properties.tolerance ||
    test.properties.heatingRateMin ||
    test.properties.heatingRateMax ||
    test.properties.coolingRateMin ||
    test.properties.coolingRateMax;

  return (
    <>
      <Grid item container spacing={3} xs={12}>
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                {test.properties.method && <TableCell>Method</TableCell>}
                <TableCell>Type</TableCell>
                <TableCell>Temperature</TableCell>
                {test.properties.holdingTime && <TableCell>Holding time</TableCell>}
                <TableCell>Cooling medium</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TreatmentRow
                method={test.properties.method}
                type={test.properties.type === TYPES.QUENCH_AND_TEMPER ? "Quench" : test.properties.type}
                temp={(
                  <span>
                    {test.properties.temp}&nbsp;
                    {getTempUnit(test.properties.tempUnits)}
                    {test.properties.tolerancePlus && ` +${test.properties.tolerancePlus}`}
                    {test.properties.toleranceMinus && `/-${test.properties.toleranceMinus}`}
                    {(test.properties.tolerancePlus || test.properties.toleranceMinus) && ` ${test.properties.toleranceUnits}`}
                  </span>
                )}
                holdingTime={test.properties.holdingTime && `${test.properties.holdingTime} ${test.properties.holdingTimeUnits}`}
                unitOfHoldingTime={test.properties.unitOfHoldingTime}
                holdingThickness={`${test.properties.holdingThickness} ${test.properties.holdingThicknessUnits}`}
                coolingMedium={test.properties.coolingMedium}
              />
              {test.properties.type === TYPES.QUENCH_AND_TEMPER && (
                <TreatmentRow
                  type="Temper"
                  temp={(
                    <span>
                      {test.properties.secondTemp}&nbsp;
                      {getTempUnit(test.properties.secondTempUnits)}
                      {test.properties.tolerancePlus && ` +${test.properties.tolerancePlus}`}
                      {test.properties.toleranceMinus && `/-${test.properties.toleranceMinus}`}
                      {(test.properties.tolerancePlus || test.properties.toleranceMinus) && ` ${test.properties.toleranceUnits}`}
                    </span>
                  )}
                  holdingTime={test.properties.secondHoldingTime && `${test.properties.secondHoldingTime} ${test.properties.secondHoldingTimeUnits}`}
                  unitOfHoldingTime={test.properties.secondUnitOfHoldingTime}
                  holdingThickness={`${test.properties.secondHoldingThickness} ${test.properties.secondHoldingThicknessUnits}`}
                  coolingMedium={test.properties.secondCoolingMedium}
                />
              )}
            </TableBody>
          </Table>
        </Grid>

        {isAdvancedSettingsFilled && (
          <Grid item xs={12}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Heating rate min</TableCell>
                  <TableCell>Heating rate max</TableCell>
                  <TableCell>Cooling rate min</TableCell>
                  <TableCell>Cooling rate min</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{test.properties.heatingRateMin} {test.properties.heatingRateUnits}</TableCell>
                  <TableCell>{test.properties.heatingRateMax} {test.properties.heatingRateUnits}</TableCell>
                  <TableCell>{test.properties.coolingRateMin} {test.properties.coolingRateUnits}</TableCell>
                  <TableCell>{test.properties.coolingRateMax} {test.properties.coolingRateUnits}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        )}

        <Grid item xs={12} className={classes.value}>
          {test.properties.strainHardening && "Strain hardening after heat treatment"}
        </Grid>

        <Grid item spacing={3} container>
          {test.properties.images?.map((image) =>
            <Grid item xs={4} container alignItems="center" key={image.preview_path}>
              <File file={image.preview_path} preview />
            </Grid>
          )}
        </Grid>

        {test.properties.notes && (
          <Grid item xs={12} className={classes.value}>
            <p
              className={classes.value}
              dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
            />
          </Grid>
        )}

        <Dialog
          open={props.addDataOpen}
          onClose={props.dataDialogClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent className={classes.dialogContent}>
            <HeatTreatmentForm
              formRef={props.formRef}
              saveTest={props.updateTest}
              test={test}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export default withStyles(HeatTreatment, styles);
