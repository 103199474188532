import React from "react";
import {Grid, DialogContent, Dialog} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExaminationConditions from "@core/components/ExaminationConditions";
import EditFormVisualTest from "@core/components/TestForms/VisualTest";
import styles from "./styles";

const VisualTest = ({
  classes,
  test,
  addDataOpen,
  dataDialogClose,
  updateTest,
  formRef
}) => {
  const {properties} = test;

  return (
    <>
      <Grid container alignContent="space-between" spacing={5} className={classes.marginBottom}>
        <Grid item xs={6}>
          <h4 className={classes.name}>Light Intensity</h4>
          <p className={classes.value}>{properties.lightIntensity} lux</p>
        </Grid>

        <Grid item xs={6}>
          <h4 className={classes.name}>Surface Cleaning</h4>
          <p className={classes.value}>{properties.surface}</p>
        </Grid>

        <Grid item xs={6}>
          <h4 className={classes.name}>Examined Surface</h4>
          <p className={classes.value}>{properties.examinedSurface.join(", ")}</p>
        </Grid>

        <Grid item xs={6}>
          <h4 className={classes.name}>Quantity Inspected</h4>
          <p className={classes.value}>{properties.quantityInspected}</p>
        </Grid>

        <Grid item xs={6}>
          <h4 className={classes.name}>Piece Identification:</h4>
          <p className={classes.value}>{properties.pieceIdentification}</p>
        </Grid>
      </Grid>

      <ExaminationConditions
        test={test}
        file={test.properties.file}
      />

      <Grid container justifyContent="space-between" className={classes.notes}>
        <Grid item xs={12}>
          <p
            className={classes.values}
            dangerouslySetInnerHTML={{__html: properties.notes.split("\n").join("</br>")}}
          />
        </Grid>
      </Grid>

      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <EditFormVisualTest
            formRef={formRef}
            test={test}
            isProducerTest={true}
            saveTest={updateTest}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(VisualTest, styles);
