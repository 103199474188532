import React, {useCallback, useMemo, useState} from "react";
import {Button, Grid, CircularProgress, Divider} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import {map, mergeDeepLeft} from "ramda";
import CommonFieldsForm from "../CommonFieldsForm";
import CertificatesList from "../CertificatesList";

const commonFields = {
  certificateType: "",
  properties: {
    productType: "",
    norm: "",
    grade: "",
  },
  items: [],
  location: "",
  transferId: "",
  projectId: "",
  deliveryCondition: "",
};

const CertificatesForm = ({onSubmit, certificates, formRef}) => {
  const [loading, setLoading] = useState(false);

  const createCertificates = useCallback(async (values) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  }, []);
  
  const preFilledCertificates = useMemo(() => certificates ? certificates.map((certificate) => mergeDeepLeft(certificate, commonFields)) : [], []);
  
  const advancedFormFields = {
    heats: "",
    lots: "",
    heat: "",
    internalItemNumber: "",
    internalWorkOrder: "",
    certificates: [],
    ...commonFields,
  };

  const simpleFormFields = {
    certificates: preFilledCertificates,
    ...commonFields,
  };

  const validationSchema = yup.object().shape({
    heats: !certificates ? yup.string().required("This field is required!") : yup.string(),
    lots: !certificates ? yup.string().required("This field is required!") : yup.string(),
    certificateType: yup.string().required("This field is required!"),
    properties: yup.object().shape({
      productType: yup.object().required("This field is required!"),
      norm: yup.string(),
      grade: yup.string(),
    }),
    heat: yup.string(),
    internalItemNumber: yup.string(),
    internalWorkOrder: yup.string(),
    location: yup.string().required("This field is required!"),
    transferId: yup.string(),
    projectId: yup.string(),
    deliveryCondition: yup.string(),
    items: yup.array().of(yup.lazy((item) =>
      yup.object().shape({
        ...map(() => yup.string().required("This field is required!"), item),
        quantity: yup.number().min(1, "Should be \u2265 1").required("This field is required!"),
      }))).required(),
    certificates: yup.array().of(yup.object().shape({
      heat: yup.string().required("This field is required!"),
      properties: yup.object().shape({
        lotId: yup.string().when("productType", {
          is: (productType) => {
            return productType?.name && (productType.name.toLowerCase() === "heat");
          },
          then: yup.string(),
          otherwise: yup.string().required("This field is required!"),
        }),
        norm: yup.string().required("This field is required!"),
        grade: yup.string().required("This field is required!"),
      }),
      internalItemNumber: yup.string(),
      internalWorkOrder: yup.string(),
      transferId: yup.string(),
      lineItem: yup.string(),
    })),
  });

  return (
    <Formik
      innerRef={formRef}
      validateOnMount
      initialValues={certificates ? simpleFormFields : advancedFormFields}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={createCertificates}
    >
      {(props) => (
        <Grid container spacing={3}>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <h3>Base information:</h3>
            </Grid>
            <Grid item xs={12}>
              <CommonFieldsForm
                isSimpleForm={!!certificates}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <h3>Certificates to be generated:</h3>
            </Grid>
            <Grid item xs={12}>
              <CertificatesList />
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Grid item>
              <Button
                color='primary'
                variant="contained"
                disabled={!props.isValid || loading}
                onClick={props.handleSubmit}
              >
                {loading && <CircularProgress size={24} />}
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default CertificatesForm;