import React from "react";
import {Tooltip, Chip} from "@mui/material";

const ConfidentialLabel = () => {
  return (
    <Tooltip title="Confidential tests are only visible to you until they've been fully approved">
      <Chip
        size="small"
        label="Confidential"
        sx={{
          cursor: "pointer",
          backgroundColor: (theme) => theme.palette.error.text,
          color: "#fff",
          marginLeft: (theme) => theme.spacing(),
        }}
      />
    </Tooltip>
  );
};

export default ConfidentialLabel;