import React, {useEffect, useState} from "react";
import {keys} from "ramda";
import {Grid, Button, MenuItem, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import {Select} from "@core/components/Form";
import {observer} from "mobx-react-lite";
import {environment} from "Config";
import {ENVIRONMENTS} from "@core/constants/environments";
import {TYPES, CHEMICAL_COMPOSITION_SUB_TYPES} from "@core/constants/test";
import {QCPS} from "@core/constants/qcps";
import useStores from "../../../useStores";
import styles from "./styles";

const TYPES_TO_PARSE = [{
  key: TYPES.CHEMICAL,
  value: "Chemical Composition",
},{
  key: CHEMICAL_COMPOSITION_SUB_TYPES.HEAT,
  value: "Chemical Composition Heat"
}, {
  key: CHEMICAL_COMPOSITION_SUB_TYPES.PRODUCT,
  value: "Chemical Composition Product"
}, {
  key: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
  value: "Crack Tip Opening Displacement Test (CTOD)",
}, {
  key: TYPES.HARDNESS,
  value: "Hardness",
}, {
  key: TYPES.HYDROGEN_INDUCED_CRACKING,
  value: "Hydrogen Induced Cracking (HIC)"
}, {
  key: TYPES.IMPACT,
  value: "Impact",
}, {
  key: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
  value: "Sulphide Stress Corrosion Cracking Test (SSCC)",
}, {
  key: TYPES.TENSILE,
  value: "Tensile",
},
{
  key: TYPES.DIMENSIONAL,
  value: "Dimensional",
}];

const COMPANIES_BY_FILE_TYPE = {
  csv: ["tenaris", "husteel",  "mechan"],
  xls: ["husteel", "subsea",  "mechan"],
  xlsx: ["husteel", "subsea",  "mechan"],
};

const ALLOWED_EXT_NAMES = keys(COMPANIES_BY_FILE_TYPE);

const getExtName = (fileName = "") => fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);

const TestParsing = observer(({classes}) => {
  const [file, setFile] = useState(null);
  const [testType, setTestType] = useState(null);
  const [qcp, setQcp] = useState("");
  const [poItem, setPoItem] = useState("");
  const [company, setCompany] = useState("");

  const [convertedFile, setConvertedFile] = useState(null);

  const {TestStore, UserStore} = useStores();

  const companyName = UserStore.user.data.company.name;
  const companyQcpNames = keys(QCPS).filter((name) => QCPS[name].companies.includes(companyName) || QCPS[name].labs?.includes(companyName));
  const poItems = QCPS[qcp] ? QCPS[qcp].poItems : [];

  const extName = getExtName(file?.name);
  const canSelectCompany = [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.STAGING].includes(environment);

  useEffect(() => {
    setPoItem("");
  }, [qcp]);
  
  const convertFile = async () => {
    const type = testType.toLowerCase();

    const newFile = await TestStore.parseFile(file, type, qcp, poItem, extName, company);
    setConvertedFile(JSON.stringify(newFile, null, "\t"));
  };

  const changeFile = (event) => {
    if (!event.target.files[0]) return;

    const fileName = event.target.files[0].name;
    const extname = getExtName(fileName);

    if (ALLOWED_EXT_NAMES.some((ext) => ext === extname)) setFile(event.target.files[0]);
  };

  const copyToBuffer = () => {
    navigator.clipboard.writeText(convertedFile);
  };

  return (
    <div className="padded-container">
      <Grid container spacing={5} marginBottom={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Parse File
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={4}>
          <Select
            required
            value={testType}
            name={"testype"}
            label="Test Type"
            onChange={(event) => {
              setTestType(event.target.value);
              setQcp("");
            }}
          >
            {TYPES_TO_PARSE.map((E) => <MenuItem key={E.key} value={E.key}>{E.value}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            required
            value={qcp}
            label='QCP/ITP'
            onChange={(event) => setQcp(event.target.value)}
          >
            {companyQcpNames.map((qcp) => (
              <MenuItem key={qcp} value={qcp}>
                {qcp}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {!!poItems?.length && (
          <Grid item xs={2}>
            <Select
              value={poItem}
              label='QCP Item'
              onChange={(event) => setPoItem(event.target.value)}
            >
              {poItems.map((poItem) => (
                <MenuItem key={poItem} value={poItem}>
                  {poItem}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        {canSelectCompany && (
          <Grid item xs={2}>
            <Select
              disabled={!extName}
              required
              value={company}
              label='Company'
              onChange={(event) => setCompany(event.target.value)}
            >
              {COMPANIES_BY_FILE_TYPE[extName]?.map((company) => (
                <MenuItem key={company} value={company}>
                  {company}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={2}>
          <label htmlFor="contained-button-file">
            <input
              id="contained-button-file"
              accept=".csv,.xls,.xlsx"
              multiple type="file"
              onChange={changeFile}
              style={{display: "none"}}
            />

            <Button
              fullWidth
              size={"large"}
              variant={"contained"}
              component="span"
            >
              Upload
              <AddIcon />
            </Button>
          </label>
        </Grid>

        <Grid item xs={8} className={classes.buttonContainer}>
          <Typography variant="body1">
            {file ? `Filename: ${file.name}` : "No File Uploaded"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Button
            fullWidth
            size={"large"}
            variant={"contained"}
            component="span"
            onClick={convertFile}
            disabled={!file || !testType || !qcp}
          >
            Convert
          </Button>
        </Grid>

        <Grid item xs={2} className={classes.buttonContainer}>
          <Button
            fullWidth
            size={"large"}
            variant={"contained"}
            component="span"
            onClick={copyToBuffer}
            disabled={!convertedFile}
          >
            Copy To Buffer
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={10} className={classes.buttonContainer}>
          <div className={classes.outputContainer}>
            <textarea
              className={classes.outputContainer__textArea}
              name="converted File"
              resize="none"
              disabled
              value={convertedFile}
            />
          </div>
        </Grid>
      </Grid>
    </div >
  );
});

export default withStyles(TestParsing, styles);
