export default () => ({
  verticalDivider: {
    height: 20,
  },
  info: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "1.2rem"
    }
  }
});
