import React, {memo} from "react";
import Pipe from "../AnimatedPipe";
const DefaultPipe = ({color, pipeConfig, ...props}) => {
  return (
    <Pipe
      {...props}
      pipeConfig={pipeConfig}
      fill={color || pipeConfig.BACKGROUND_COLOR}
    />
  );
};

export default memo(DefaultPipe);