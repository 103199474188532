import React from "react";
import {STATUSES} from "@core/constants/test";
import styles from "./styles";
import {withStyles} from "tss-react/mui";

const StatusMarks = ({status, classes}) => {
  const img = <img className={classes.imgMark} src="/images/verified-user.png" alt="check mark"/>; // eslint-disable-line

  return (
    <>
      {status === STATUSES.SUBMITTED && <div className={classes.attested}>
        {img}
        Signed
      </div>}
      {status === STATUSES.INSPECTED && <>
        <div className={classes.attested}>
          {img}
          Signed
        </div>
        <div className={classes.attested}>
          {img}
          Attested
        </div>
      </>}
    </>

  );
};

export default withStyles(StatusMarks, styles);
