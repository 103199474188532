import React from "react";
import {prop, groupBy, values, sortBy, trim} from "ramda";
import {Grid, Dialog, DialogContent, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import ConvertHistory from "../ConvertHistory";
import SplitHistory from "../SplitHistory";

const getNotesText = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  return html ? trim(div.innerText) : "";
};

const History = ({header, history}) => {
  return (
    <Grid item xs={6}>
      <Typography component="h5" variant="h5" gutterBottom>
        {header}
      </Typography>
      <ConvertHistory
        properties={history.properties}
        items={history.items}
      />
    </Grid>
  );
};

const ProductInfoHistoryModal = ({classes, opened, onClose, certificate}) => {
  const history = [...certificate.previousProperties, ...certificate.previousItems];
  const sortedHistory = sortBy(prop("dateCreated"))(history).reverse();
  const groupedHistory = values(groupBy(prop("dateCreated"), sortedHistory)).map(([properties, items]) => ({...properties, ...items}));

  const currentHistory = {properties: certificate.properties, items: certificate.items};
  const convertHistory = [currentHistory, ...groupedHistory.filter((history) => history.properties && history.items)];
  const splitHistory = groupedHistory.filter((history) => !history.properties && history.items);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={opened}
      onClose={onClose}
      classes={{
        paperWidthLg: classes.paperWidth
      }}
    >
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h5" variant="h5">
              Current item
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ConvertHistory
              grade={certificate.properties.grade}
              tpUns={certificate.uns}
              properties={certificate.properties}
              certificateType={certificate.certificateType}
              items={certificate.items}
            />
          </Grid>
          {Boolean(convertHistory.length > 1) && (
            <>
              <Grid item xs={12}>
                <Typography component="h5" variant="h5">
                  History
                </Typography>
              </Grid>
              {convertHistory.map((history, index) => {
                if (!convertHistory[index + 1] && index) return null;

                const notes = convertHistory[index + 1] ? convertHistory[index + 1].notes : "";
                const notesText = getNotesText(notes);

                return (
                  <Grid item xs={12}>
                    <Grid container spacing={8}>
                      <Grid item xs={12}>
                        <Grid container spacing={8}>
                          <History
                            history={convertHistory[index + 1]}
                            header="From"
                          />
                          <History
                            history={history}
                            header="To"
                          />
                        </Grid>
                      </Grid>
                      {notesText && (
                        <Grid item xs={12} className={classes.notes}>
                          <Typography component="h6" variant="h6">
                            Notes
                          </Typography>
                          <p dangerouslySetInnerHTML={{__html: notes}} />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </>
          )}
          {Boolean(splitHistory.length) && (
            <Grid item xs={12}>
              <Typography component="h5" variant="h5" gutterBottom>
                Split
              </Typography>
              {splitHistory.map((history) => (
                <SplitHistory
                  productType={certificate.properties.productType}
                  items={history.items}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(ProductInfoHistoryModal, styles);
