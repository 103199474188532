import React, {useMemo, memo, useRef} from "react";
import {Group, Text} from "react-konva";
import {PIPE_CONFIG, QUARANTINED_JOINT_CONFIG, WELD_CONFIG} from "../../constants";
import {STAGE_CONFIG} from "../../../../constants";
import {getTextScale} from "../../../../helpers";
import {equals} from "ramda";
import {observer} from "mobx-react-lite";
import useStores from "../../../../../../../../../../useStores";
import TieInWeld from "../TieInWeld";
import ColoredPipe from "../ColoredPipe";

const Pipe = observer(({id, weld, totalLength, settings, pipeIndex, pipeIndexInChunk, onClick, setOpenWeld, scale, pipe, pipes, stalkNumber, tieInWeld}) => {
  const {CampaignStore} = useStores();

  const pipeRef = useRef();

  const baseMaterialsById = CampaignStore.campaignProductsById;
  const pipeLengthByWeldId = CampaignStore.pipeLengthByWeldId;

  const pipeLength = useMemo(() => {
    return pipeLengthByWeldId[weld._id];
  }, [weld._id]);
  
  const color = useMemo(() => {
    if(baseMaterialsById[pipe]?.quarantine && settings.quarantine) {
      return QUARANTINED_JOINT_CONFIG.TEXT_COLOR;
    }

    return PIPE_CONFIG.TEXT_COLOR;
  }, [baseMaterialsById[pipe]?.type, settings.quarantine]);

  const x = useMemo(() => {
    if(!settings.welds) return PIPE_CONFIG.WIDTH * pipeIndexInChunk;

    return (PIPE_CONFIG.WIDTH + WELD_CONFIG.WIDTH) * pipeIndexInChunk;
  }, [settings.welds, pipeIndexInChunk]);

  const textScale = useMemo(() => {
    return getTextScale(PIPE_CONFIG.TEXT_SCALE_BY, scale, PIPE_CONFIG.TEXT_MAX_SCALE, PIPE_CONFIG.TEXT_MIN_SCALE);
  }, [scale]);

  const textY = useMemo(() => {
    const y = PIPE_CONFIG.TEXT_POSITION / scale;

    return Math.min(PIPE_CONFIG.TEXT_POSITION_MIN, y);
  }, [scale]);
  
  const onMouseEnter = () => {
    pipeRef.current.setAttr("data", {
      pipe: baseMaterialsById[pipe]?.pipeNumber,
      joint: id,
      length: pipeLength,
      totalLength,
      type: baseMaterialsById[pipe]?.type,
    });
  };

  return (
    <Group
      x={x}
      ref={(element) => {
        if(pipes.current[pipeIndex] && element) {
          pipes.current[pipeIndex].destroy();
        }

        pipes.current[pipeIndex] = element;
      }}
      onMouseEnter={onMouseEnter}
    >
      <ColoredPipe
        pipeRef={pipeRef}
        id={id}
        onClick={onClick}
        pipe={baseMaterialsById[pipe] || {}}
        settings={settings}
      />
      {/* <Anode */}
      {/*   type={pipe?.anode} */}
      {/*   anodeNumber={pipe?.anodeNumber} */}
      {/*   settings={settings} */}
      {/* /> */}
      <Text
        perfectDrawEnabled={false}
        y={textY}
        align="center"
        width={PIPE_CONFIG.WIDTH / textScale}
        fontSize={PIPE_CONFIG.FONT_SIZE}
        text={id}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        fill={color}
        scale={{x: textScale, y: textScale}}
      />
      <TieInWeld
        tieInWeld={tieInWeld}
        stalkNumber={stalkNumber}
        scale={scale}
        settings={settings}
        setOpenWeld={setOpenWeld}
      />
    </Group>
  );
});

export default memo(Pipe, (prevProps, nextProps) => equals(prevProps, nextProps));