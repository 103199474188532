import React, {useState} from "react";
import DefiningHeatsAndLotIds from "../DefiningHeatsAndLotIds";
import {isEmpty} from "ramda";
import CertificatesForm from "../CertificatesForm";

const SimpleForm = ({onSubmit, formRef}) => {
  const [certificates, setCertificates] = useState([]);

  return (
    isEmpty(certificates) ? (
      <DefiningHeatsAndLotIds
        formRef={formRef}
        setCertificates={setCertificates}
      />
    ) : (
      <CertificatesForm
        formRef={formRef}
        onSubmit={onSubmit}
        certificates={certificates}
      />
    ));
};

export default SimpleForm;