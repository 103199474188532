import {STATUSES} from "@core/constants/test";
import CertificateBCService from "../../Blockchain/certificates-service";
import CertificateDBService from "Blockchain/certificates-service";
import utils from "Blockchain/utils";
import referenceService from "@core/api/reference-service";
import {SigningStore, NotificationStore} from "@core/components/Modal/stores";

export default {

  getSplitCreateNewTestTX(certificates, witness){
    return certificates.map((certificate) => {
      const splitTest = certificate.tests[certificate.tests.length - 1 ];
      const dataHash = utils.computeHash(splitTest.properties).hash;
      const transactionObject = CertificateDBService.createNewTestTX(certificate._id, splitTest, dataHash, certificate.owner._id || certificate.owner);

      return {
        transactionObject: transactionObject,
        payload: {
          testId: splitTest._id,
          type: "test",
          action: "assign",
          historyData: {
            lab: witness.name,
          },
          laboratory: witness,
          status: STATUSES.ASSIGNED,
        },
      };
    });
  },

  getAddInspectingCompanies(tests){
    return tests
      .filter((test) => test.witnesses.length !== 0)
      .map((test) => {
        const inspectingCompanies = test.witnesses.map((el) => el.company._id);
        const transactionObject = CertificateBCService.addInspectingCompanies(test._id, inspectingCompanies);

        return {
          transactionObject: transactionObject,
          payload: {
            testId: test._id,
            type: "test",
            action: "inspect",
            historyData: {
              witnesses: test.witnesses.map((W) => W.company.name).join(", "),
            },
            inspectingCompanies,
            status: STATUSES.INSPECTING,
          }
        };
      });
  },

  showQR(transactions, creator, config={}){
    referenceService.create({
      creator,
      transactions,
    }).then((reference) => {
      SigningStore.openSigner({id: reference._id}, config);
    })
      .catch((error) => {
        console.warn(error);
        NotificationStore.showError("Something went wrong!");
      });
  }

};
