import React from "react";
import {Grid} from "@mui/material";
import MenuLink from "../MenuLink";

const MenuSubItems = ({items = []}) => {
  return (
    <Grid item xs={12} container spacing={2} direction="column" sx={{minWidth: 200}}>
      {items.map((item, index) => (
        <Grid item key={index}>
          <MenuLink
            menuItem={item}
            exactMatch
            displayTitle
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default MenuSubItems;