import React, {useCallback, useMemo} from "react";
import {isNil} from "ramda";
import {MenuItem, Input, Select} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import {STATE, ALL_STALKS} from "../../../../constants";

const Toolbar = ({classes, state, container, isFullScreen, settings, setSettings, stalks}) => {
  const stalksNumbers = useMemo(() => stalks.map((stalk) => stalk[0].stalkNumber), [stalks.length]);

  const onStalkNumber = useCallback((e) => {
    const value = e.target.value;
    setSettings({
      ...settings,
      stalkNumber: value === ALL_STALKS ? null : value,
      baseMaterials: value === ALL_STALKS,
      quarantine: value === ALL_STALKS,
    });
  }, [settings]);

  return (
    <div
      className={classes.container}
    >
      <Select
        disabled={state === STATE.AS_BUILT}
        MenuProps={{
          container: isFullScreen ? container.current : document.body,
        }}
        value={!isNil(settings.stalkNumber) ? settings.stalkNumber : ALL_STALKS}
        onChange={onStalkNumber}
        input={<Input disableUnderline classes={{input: classes.selectOutlined}} />}
        classes={{select: classes.select}}
      >
        {stalksNumbers.map((stalkNumber, index) => (
          <MenuItem key={index} value={stalkNumber}>Stalk {stalkNumber}</MenuItem>
        ))}
        <MenuItem value={ALL_STALKS}>{ALL_STALKS}</MenuItem>
      </Select>
    </div>
  );
};

export default withStyles(Toolbar, styles);