export default () => ({
  specimenToolbar: {
    position: "absolute",
    top: 15,
    left: -22,
    zIndex: 1000,
  },
  visible: {
    visibility: "visible",
  },
  hidden: {
    visibility: "hidden",
  }
});
