export default (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    height: 48,
    cursor: "pointer",
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    "& svg": {
      marginRight: theme.spacing(1),
    }
  },
  popoverContent: {
    padding: theme.spacing(2),
    width: 300,
  }
});