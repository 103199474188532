import React, {useMemo, memo, useRef} from "react";
import {Text} from "react-konva";
import {SEQUENCE_NUMBER_EVERY} from "../Stalk/constants";
import {STAGE_CONFIG} from "../../constants";
import {getTextScale} from "../../helpers";

const SequenceNumber = ({stalkLength, scale, pipeCoordinates, index, pipeConfig}) => {
  const text = useRef();

  const sequenceNumber = useMemo(() => index + 1, [index]);
  const isInSequence = useMemo(() => !(sequenceNumber % SEQUENCE_NUMBER_EVERY), [index]);
  const isFirst = useMemo(() => !index, [index]);
  const isLast = useMemo(() => index === stalkLength - 1, [index, stalkLength]);

  const textScale = useMemo(() => {
    return getTextScale(pipeConfig.TEXT_SCALE_BY, scale, pipeConfig.TEXT_MAX_SCALE, pipeConfig.TEXT_MIN_SCALE);
  }, [scale]);
  
  const y = useMemo(() => {
    const y = pipeConfig.TEXT_Y / scale;

    return Math.max(Math.min(pipeConfig.TEXT_Y_MIN, y), pipeConfig.TEXT_Y_MAX);
  }, [scale]);
  
  const x = useMemo(() => {
    const middle = pipeCoordinates.x + pipeConfig.WIDTH / 2;
    const width = text.current ? text.current.width() : sequenceNumber.toString().length * pipeConfig.FONT_SIZE / 2;
    const halfWidth = width * textScale / 2;
 
    return middle - halfWidth;
  }, [textScale, pipeCoordinates.x, text.current]);
  
  if(!isFirst && !isLast && !isInSequence) return null;

  return (
    <Text
      ref={text}
      listening={false}
      y={y}
      x={x}
      fontSize={pipeConfig.FONT_SIZE}
      text={sequenceNumber}
      fontFamily={STAGE_CONFIG.FONT_FAMILY}
      fill={pipeConfig.COLOR}
      scale={{x: textScale, y: textScale}}
    />
  );
};

export default memo(SequenceNumber);