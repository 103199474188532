export default (theme) => ({
  header: {
    paddingTop: 22,
  },
  tableCell: {
    color: "#222222",
    fontWeight: theme.typography.fontWeightMedium,
  },
  content: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
});