import React, {useState} from "react";
import {pick} from "ramda";
import {observer} from "mobx-react-lite";
import ProductTypeModal from "@core/components/ProductTypeModal";
import {MuiAutocomplete} from "@core/components/Form";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const PRODUCT_FIELDS = ["internalName", "name", "properties", "_id"];
const ProductTypeAutocomplete = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MuiAutocomplete
        formatOptions={(data) => data.items.map((item) => ({label: item.internalName, value: pick(PRODUCT_FIELDS, item)}))}
        onCreateNew={() => setOpen(true)}
        url={ROUTES.PRODUCT_TYPE[ACTIONS.ALL]}
        {...props}
      />
      <ProductTypeModal
        open={open}
        onProductTypeCreated={(product) => props.onChange(pick(PRODUCT_FIELDS, product))}
        close={() => setOpen(false)}
      />
    </>
  );
};

export default observer(ProductTypeAutocomplete);