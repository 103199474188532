import React from "react";
import {Link} from "react-router-dom";
import {Toolbar} from "@mui/material";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
// import Notifications from "@core/components/Notifications";
import styles from "./styles";

const Header = (props) => {
  const {classes} = props;

  return (
    <div className={classes.header}>
      <div className={classes.headerLeftCol}>
        <a href="" className={classes.logo}><img src="/images/logo-symbol-white.svg" alt=""/> </a>
      </div>
      <div className={classNames(classes.actionButton, classes.headerRightCol)}>
        <Toolbar variant="dense" align="right" disableGutters>
          {/* <Notifications /> */}
          <Link to={"/account"}>
            <img width="24" height="20" src="/images/profile.svg" alt="profile"/>
          </Link>
          <Link to={"/logout"}>
            <img width="24" height="20" src="/images/logout.svg" alt="logout"/>
          </Link>
        </Toolbar>
      </div>
    </div>
  );
};

export default withStyles(Header, styles);
