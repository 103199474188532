import {Box} from "@mui/material";
import {values} from "ramda";
import React, {useMemo} from "react";
import AcceptanceTable from "./components/AcceptanceTable";
import {PO_ITEMS} from "@core/constants/poItems";

const ChemicalComposition = ({qcpConfig}) => {
  const poItems = useMemo(() => values(PO_ITEMS).filter((poItem) => {
    return qcpConfig.elements.some((el) => (el.getMax && el.getMax({poItem})) || (el.getMin && el.getMin({poItem})));
  }), []);

  if(poItems.length) {
    return poItems.map((poItem) => (
      <Box key={poItem} sx={{marginTop: 1}}>
        <h3>QCP Item {poItem}</h3>
        <AcceptanceTable
          poItem={poItem}
          elements={qcpConfig.elements}
        />
      </Box>
    ));
  }

  return (
    <Box sx={{marginTop: 1}}>
      <h3>Local Requirements</h3>
      <AcceptanceTable
        elements={qcpConfig.elements}
      />
    </Box>
  );
};

export default ChemicalComposition;