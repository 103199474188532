export default () => ({
  gridRoot: {
    marginTop: 10,
    marginBottom: 10
  },

  mt20: {
    marginTop: "20px !important",
  },

  roundButtonGrid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end"
  },

  roundButton: {
    width: 50,
    height: 50,
    marginLeft: -25,
    marginBottom: -4
  },

  fieldSizeWidth: {
    width: "120%"
  },

  dimensionsSpan: {
    position: "absolute",
    left: 114,
    bottom: 13,
    color: "rgba(0, 0, 0, 0.54)",
  },

  unit: {
    marginLeft: 10,
  },

  saveButton: {
    marginLeft: "auto",
  }
});

