export default (theme) => ({
  action: {
    display: "flex",
    justifyContent: "flex-end"
  },
  textBold:{
    fontSize: 16,
    color: "#6d6d6d",
    marginBottom: 5,
  },
  statusTextBold: {
    fontSize: 16,
    color: "#6d6d6d",
    marginBottom: 15,
  },
  text: {
    fontSize: 15,
    color: "#6d6d6d",
    lineHeight: "18px"
  },
  table: {
    border: "1px solid #e0e0e0",
    marginTop: 45,
  },
  tableCell: {
    textAlign: "center",
    padding: 20,
    border: "1px solid #e0e0e0",
    width: 215,
  },

  markingText: {
    fontSize: 15,
    lineHeight: "28px",
    color: "#6d6d6d",
  },

  icon: {
    marginRight: 6,
  },
  containerExpanded: {
    padding: 16,
    "& > div": {
      fontSize: 15,
      color: "#6d6d6d",
    },
    overflow: "hidden"
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 6,
    marginBottom: 20,
    "&:hover > svg": {
      opacity: 1,
    },
    cursor: "pointer",
  },
  iconView: {
    marginLeft: 12,
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#6d6d6d",
      "&:hover > svg": {
        opacity: 1,
      },
      "& > svg": {
        color: "#0277bd",
        opacity: 0.5,
      },
      "&:hover ": {
        color: "#0277bd",
      }
    },

  },
  iconRemove: {
    marginLeft: 12,
    "& > svg": {
      color: "#d02d52",
      opacity: 0.5,
    },
    "&:hover ": {
      color: "#d02d52",
    }
  },
  logo: {
    "& img":{
      margin: "25px 0",
      maxHeight: 75
    }
  },
  inputsSection: {
    marginTop: 20
  },
  input: {
    width: "60%"
  },
  green: {
    color: theme.palette.success.main
  },
  preliminary:{
    color: theme.palette.text.main
  },
  orange: {
    color: "#d4a237"
  },
  saveButton: {
    position: "absolute",
    bottom: 0,
  }
});