export default (theme) => ({
  message: {
    marginBottom: 10,
  },
  date: {
    fontSize: 12,
  },
  container: {
    marginTop: 20,
  },
  feedback: {
    marginBottom: 10,
  },
  resolveButton: {
    marginBottom: 8,
    backgroundColor: theme.palette.success.main,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#388e3c"
    }
  },
  imgMark: {
    height: 15,
    marginRight: 5,
    marginLeft: 5,
    paddingTop: 3,
  },
  closed: {
    color: "#0277BD",
    fontSize: 14,
  },
  typeContainer: {
    display: "flex",
  },
});