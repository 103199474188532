export default (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.deactivate.main,
    "& h3": {
      marginRight: theme.spacing(),
    }
  }
});
