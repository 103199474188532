export default (theme) => ({
  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  formControl: {
    display: "block",
    width: "100%"
  },
  gridRoot: {
    marginTop: 20,
    marginBottom: 20
  },
  divider: {
    marginBottom: 20
  },
  bootstrapInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "none",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },

  bootstrapDisabled: {
    backgroundColor: "rgba(0, 0, 0, 0.12)"
  },

  bootstrapError: {
    "& input": {
      borderColor: "#f44336",
      borderRadius: 4
    },
  },

  roundButtonGrid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end"
  },

  roundButton: {
    width: 50,
    height: 50,
    marginLeft: -25,
    marginBottom: -4
  },

  button: {
    width: "100%"
  },
  marginRight25: {
    marginRight: 25,
  },
  width120: {
    width: 120,
  },

  width80: {
    width: 80,
  },

  width60: {
    width: 60,
  },

  additionalLabel: {
    margin: "12px 20px;",
    color: "#616161",
    fontSize: "0.875rem"
  },

  measureLabel: {
    position: "absolute",
    right: -45,
    bottom: 11,
    color: "#616161",
    fontSize: "0.875rem"
  },

  bootstrapFormLabel: {
    fontSize: 18,
    width: "100%"
  },

  bootstrapSelect: {
    width: 195,
  },

  uploadResults: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    "& > input": {
      display: "none",
    }
  },

  saveButton: {
    marginLeft: "auto",
  }
});

