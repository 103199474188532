import React from "react";
import {Typography, Table, TableHead, TableRow, TableCell, TableBody, Button} from "@mui/material";
import {groupBy, prop, values} from "ramda";
import testConfig from "@core/configs/test";
import {Link} from "react-router-dom";
import CollapsibleSection from "../../../../../singleMaterialComponents/CollapsibleSection";

const PendingActions = ({selectedGroup}) => {
  const testsGrouped = groupBy(prop("group"), values(testConfig));
  
  return (
    <>
      <Typography variant="h6" marginBottom={1}>
        Pending actions / Needs attention
      </Typography>
      <CollapsibleSection
        title={(
          <Typography>
            {`Tests pending approval (${testsGrouped[selectedGroup].length})`}
          </Typography>
        )}
      >
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testsGrouped[selectedGroup].map((test) => (
              <TableRow>
                <TableCell>Approve {test.title}</TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={"/certificates/"}
                  >
                        Approve
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CollapsibleSection>
      <CollapsibleSection
        title={(
          <Typography>
            {`Non-conforming tests (${testsGrouped[selectedGroup].length})`}
          </Typography>
        )}
      >
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testsGrouped[selectedGroup].map((test) => (
              <TableRow>
                <TableCell>{test.title}</TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={"/certificates/"}
                  >
                        View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CollapsibleSection>
    </>
  );
};

export default PendingActions;