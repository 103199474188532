import React, {memo} from "react";
import {flatten} from "ramda";
import {observer} from "mobx-react-lite";
import Pipe from "../AnimatedPipe";
import {PIPE_WITH_COATING_CONFIG} from "../../constants";
import useStores from "../../../../../../../../../../useStores";

const CoatedPipe = observer(({pipe, ...props}) => {
  const {CampaignStore} = useStores();

  const coatingTypesByPipeNumber = CampaignStore.coatingTypesByPipeNumber;
  const longestCoatingCombination = CampaignStore.longestCoatingCombination;

  const coatingTypes = coatingTypesByPipeNumber[pipe.pipeNumber] || [];

  const startIndex = longestCoatingCombination.indexOf(coatingTypes[0]);
  const endIndex = longestCoatingCombination.indexOf(coatingTypes[coatingTypes.length - 1]);

  const coatingColors = PIPE_WITH_COATING_CONFIG.BACKGROUND_COLORS.slice(startIndex, endIndex + 1);

  return (
    <Pipe
      {...props}
      fillLinearGradientStartPoint={{x: 10, y: 0}}
      fillLinearGradientEndPoint={{x: 10, y: props.pipeConfig.HEIGHT}}
      fillLinearGradientColorStops={
        flatten(coatingColors.map((color) => [100 / coatingColors.length / 100, color]))
      }
    />
  );
});

export default memo(CoatedPipe);