import {mode} from "@core/helpers";
import {Tooltip, Chip, Box} from "@mui/material";
import {flatten} from "ramda";
import React from "react";

const DimensionsTags = ({certificates}) => {
  const dimensions = flatten(certificates.map((certificate) => {
    const properties = certificate.properties.productType.properties;

    const dimensions = certificate.items.map((item) => properties.map((property) => {
      return `${property.label}: ${item[property.name]}${property.measurements ? property.measurements.toLowerCase() : ""}`;
    }).join("x"));

    return dimensions;
  }));
  const mostCommonDimension = mode(dimensions);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {mostCommonDimension.split("x").map((dimension) => {
        if(!dimension) return null;

        const [label, rest] = dimension.split(":");
        const labelAcronym = label.match(/\b\w/g).join("").toUpperCase();

        return (
          <Tooltip title={label}>
            <Chip
              sx={{cursor: "pointer", marginRight: 1}}
              label={`${labelAcronym}: ${rest}`}
              size="small"
            />
          </Tooltip>
        );}
      )}
    </Box>
  );
};

export default DimensionsTags;