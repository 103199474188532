import React from "react";
import {Checkbox, FormControl, FormControlLabel, Grid, RadioGroup} from "@mui/material";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import styles from "../styles";
import {Field} from "formik";

const Modules = ({classes, isNew, modulesArr = [], disabled}) => {
  return (
    <Grid container className={classes.mb25} spacing={3}>
      <Grid item xs={3}>
        <h4 className={classNames(classes.label, classes.asterisk)}>Modules</h4>
        <Field
          name="modules"
        >
          {({form, meta}) => {
            const {error, touched, value} = meta;
            const {setFieldTouched, setFieldValue} = form;

            const modules = isNew ? modulesArr.filter((module) => module.name !== "SUPER_ADMIN") : value;

            return (
              <FormControl
                variant="standard"
                name="modules"
                component="fieldset"
                error={Boolean(error) && touched}>
                <RadioGroup
                  aria-label="modules"
                  className={classes.group}
                >
                  {modules.map((mod) => mod.name && (
                    <FormControlLabel
                      disabled={disabled}
                      key={mod._id}
                      value={mod._id}
                      control={(
                        <Checkbox
                          disabled={disabled}
                          color="primary"
                          checked={!isNew || value.includes(mod._id)}
                          onChange={(fieldName, e) => {
                            if (e) setFieldValue("modules", [fieldName.target.value]);
                            else setFieldValue("modules", modules.filter((x) => x !== fieldName.target.value));

                            setFieldTouched("modules", true, false);
                          }}
                          value={mod._id}
                        />
                      )}
                      checked={!isNew}
                      label={mod.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            );
          }}
        </Field>
      </Grid>
    </Grid>
  );
};

export default withStyles(Modules, styles);