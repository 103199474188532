import jStat from "jstat";

export const getMean = (elements) => {
  const number = elements.length;
  const sum = elements.reduce((acc, el) => acc + Number(el), 0) || 0;

  return sum / number || 0;
};

export const getDeviation = (elements) => {
  const number = elements.length;
  const mean = getMean(elements);

  const deviation = Math.sqrt(elements.reduce((acc, el) => acc + Math.pow(el - mean, 2), 0) / (number - 1));

  return deviation || 0;
};

export const getCi = (elements, deviation) => {
  const number = elements.length;
  const tScore = jStat.studentt.inv(0.975, number - 1);

  const ci = tScore * deviation / Math.sqrt(number);

  return ci || 0;
};

export const getAccuracy = (elements, deviation, mean) => {
  const ci = getCi(elements, deviation);
  const accuracy = ci / mean * 100;

  return accuracy || 0;
};