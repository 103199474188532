import React, {useCallback, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {CUSTOM_TEST_TYPES, TYPES} from "@core/constants/test";
import {Box, TableCell, TableRow} from "@mui/material";
import {indexBy, prop, uniq, uniqBy} from "ramda";
import {getMaxActivityValue, getWeldIsRepaired} from "../../../../helpers";
import useStores from "../../../../../../../../useStores";
import {TITLE_BY_COLUMN} from "../../constants";
import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";

const findLongest = (array) => {
  const lengths = array.map((value) => {
    const number = value || 0;
    const [, decimals = ""] = number.toString().split(".");

    return decimals.length;
  });

  return Math.max(...lengths);
};

const NdtDataAnalysisRow = ({stalks, setWelds, setTitle}) => {
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;

  const {welds = [], tests = []} = campaign;
  
  const stalkNumbers = useMemo(() => stalks.map((stalk) => stalk[0].stalkNumber), [welds.length]);

  const weldsByWeldNumber = useMemo(() => indexBy(prop("weldNumber"), welds), [welds.length]);

  const asBuildRecordTests = useMemo(() => {
    return tests.filter((test) =>
      test.type === TYPES.CUSTOM &&
      test.displayName === CUSTOM_TEST_TYPES.AS_BUILD_RECORD &&
      test.properties.activities.find((activity) => activity.description === "Stalk Number").results.values.some(({value}) => stalkNumbers.includes(value))
    );
  }, [stalkNumbers, tests]);

  const ilineWeldAdvisorTests = useMemo(() => tests.filter((test) =>
    test.type === TYPES.CUSTOM &&
    test.displayName === CUSTOM_TEST_TYPES.AUTOMATED_ULTRASONIC_TESTING
  ), [tests]);

  const statedLength = useMemo(() => {
    if(!asBuildRecordTests.length) return null;

    const totalStatedValues = asBuildRecordTests.map((test) => getMaxActivityValue(test.properties.activities, "Total Stated"));

    const sum = totalStatedValues.reduce((acc, value) => acc + Number(value), 0);

    const longest = findLongest(totalStatedValues);

    return sum.toFixed(longest);
  }, [asBuildRecordTests.length]);

  const totalActual = useMemo(() => {
    if(!asBuildRecordTests.length) return null;

    const totalStatedValues = asBuildRecordTests.map((test) => getMaxActivityValue(test.properties.activities, "Total Actual"));

    const sum = totalStatedValues.reduce((acc, value) => acc + Number(value), 0);

    const longest = findLongest(totalStatedValues);

    return sum.toFixed(longest);
  }, [asBuildRecordTests.length]);

  const joints = useMemo(() =>
    uniq(welds.reduce((acc, weld) => acc.concat([weld.jointNumberA, weld.jointNumberB]), []))
  , [welds.length]);

  const repairedWelds = useMemo(() => welds.filter((weld) => getWeldIsRepaired(weld.weldNumber)), [welds.length]);

  const indications = useMemo(() => {
    return ilineWeldAdvisorTests.reduce((acc, test) => {
      const indications = test.properties.activities.filter((activity) => activity.description === "Indications");

      return acc.concat(indications);
    }, []);
  }, [ilineWeldAdvisorTests]);

  const indicationWelds = useMemo(() => {
    const specimens = indications.reduce((acc, indication) => {
      if(indication.results.indicationCount) acc.push(indication.specimen);

      return acc;
    }, []);

    const welds = specimens.map((specimen) => weldsByWeldNumber[specimen] || {weldNumber: specimen, _id: specimen});

    return uniqBy(prop("weldNumber"), welds);
  }, [indications, weldsByWeldNumber]);

  const defectWelds = useMemo(() => {
    const specimens = indications.reduce((acc, indication) => {
      const defects = indication.results.values.some(({value}) => value.some(({IsDefect}) => IsDefect));

      if(defects) acc.push(indication.specimen);

      return acc;
    }, []);

    const welds = specimens.map((specimen) => weldsByWeldNumber[specimen] || {weldNumber: specimen, _id: specimen});

    return uniqBy(prop("weldNumber"), welds);
  }, [indications, weldsByWeldNumber]);

  const onClick = useCallback((title, welds) => {
    setWelds(welds);
    setTitle(title);
  }, []);

  return (
    <TableRow
      sx={{borderTop: "2px solid #9f9f9f"}}
    >
      <TableCell>
        <Box sx={{fontWeight: (theme) => theme.typography.fontWeightMedium, display: "inline"}}>Total</Box>: {stalkNumbers.length} Stalks
      </TableCell>
      <TableCell>{statedLength ? `${statedLength}m` : "-"}</TableCell>
      <TableCell>{totalActual ? `${totalActual}m` : "-"}</TableCell>
      <TableCell>{joints.length}</TableCell>
      <TableCell>{welds.length}</TableCell>
      <TableCell>
        {repairedWelds.length ? (
          <DrawerButtonTrigger
            onClick={() => onClick(TITLE_BY_COLUMN.REPAIRED_WELDS, repairedWelds)}
            text={repairedWelds.length}
          />
        ) : repairedWelds.length}
      </TableCell>
      <TableCell>
        {defectWelds.length ? (
          <DrawerButtonTrigger
            onClick={() => onClick(TITLE_BY_COLUMN.INDICATIONS, defectWelds)}
            text={defectWelds.length}
          />
        ) : defectWelds.length}
      </TableCell>
      <TableCell>
        {indicationWelds.length ? (
          <DrawerButtonTrigger
            onClick={() => onClick(TITLE_BY_COLUMN.NDT_DEFECTS, indicationWelds)}
            text={indicationWelds.length}
          />
        ) : indicationWelds.length}
      </TableCell>
    </TableRow>
  );
};

export default observer(NdtDataAnalysisRow);