import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import classNames from "classnames";
import {createTheme, ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import {CssBaseline, Divider, Grid, Tabs, Tab, Skeleton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import themeSettings from "../../../theme-settings";
import useStores from "../../../useStores";
import styles from "./styles";
import {flatten} from "ramda";

const PLATFORM_HEADER_HEIGHT = 60;

const defaultTheme = createTheme();
const theme = createTheme({
  ...themeSettings,
  breakpoints: {values: {...defaultTheme?.breakpoints.values, lg: 1201, xl: 1601}}
});

const Header = ({
  classes,
  tabs,
  actions,
  children,
  onTab,
  isLoaded = true,
  tab,
  withDivider = true,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const {MenuStore} = useStores();
  const isMenuCollapsed = MenuStore.collapsed;

  const scrollToActiveTab = useCallback((activeTab) => {
    const targetElement = document.getElementById(activeTab);

    if(!targetElement) return;

    const targetOffset = targetElement.offsetTop;
    const topOffset = targetOffset - (2 * PLATFORM_HEADER_HEIGHT);
    window.scrollTo({top: topOffset, behavior: "smooth"});
  }, []);

  const onChangeActiveTab = useCallback((activeTab) => {
    setActiveTab(activeTab);
    const handler = onTab || scrollToActiveTab;
    handler(activeTab);
  }, []);

  const checkIsSticky = useCallback(() => {
    setIsSticky(window.pageYOffset);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkIsSticky);

    return () => {
      window.removeEventListener("scroll", checkIsSticky);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          className={classNames(
            classes.container, {
              [classes.stickyHeader]: isSticky,
              [classes.fullWidthHeader]: isMenuCollapsed,
            }
          )}
        >
          <Grid container className={classes.tabs} justifyContent="space-between">
            {isLoaded ? (
              <Grid item>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={tab || activeTab}
                  onChange={(_, value) => onChangeActiveTab(value)}
                  children={flatten(tabs.map((tab, index) => [
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />,
                    (index !== tabs.length - 1 && withDivider) ? (
                      <Tab
                        icon={(
                          <Divider
                            orientation="vertical"
                            className={classes.divider}
                          />
                        )}
                      />
                    ) : null
                  ]))}
                />
              </Grid>
            ) : (
              <Grid item xs>
                <Skeleton width={400} />
              </Grid>
            )}
            <Grid item>
              {actions}
            </Grid>
          </Grid>
          {children}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default withStyles(observer(Header), styles);
