import React from "react";
import {Table, TableBody, TableCell, TableRow, TableHead, Box} from "@mui/material";
import {ZONES} from "@core/constants/test";
import Formula from "../Formula";
import ElementsResults from "../ElementsResults";

const ElementsTable = ({test, updateTest, editable}) => {
  const {elements = [], zone, specimenId, secondSpecimenId} = test.properties;

  const secondValueRow = secondSpecimenId || elements.some((el) => el.secondValue);

  return (
    <Box
      sx={{overflow: "auto"}}
    >
      <Table
        className="styled-table"
        sx={{whiteSpace: "nowrap"}}
      >
        <TableHead>
          <TableRow>
            <TableCell rowSpan={3}>
              Spec. ID
            </TableCell>
            {zone === ZONES.BASE_METAL && <TableCell />}
            {elements.map((el) => (
              <TableCell>
                {el.formula ? (
                  <Formula
                    element={el}
                  />
                ) : el.bm}
              </TableCell>
            ))}
          </TableRow>
          {zone === ZONES.BASE_METAL && (
            <>
              <TableRow>
                <TableCell>
                  Min
                </TableCell>
                {elements.map((el) => (
                  <TableCell>
                    {Number(el.min) ? el.min : "-"}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>
                  Max
                </TableCell>
                {elements.map((el) => (
                  <TableCell>
                    {Number(el.max) ? el.max : "-"}
                  </TableCell>
                ))}
              </TableRow>
            </>
          )}
        </TableHead>
        <TableBody>
          <TableRow>
            <ElementsResults
              editable={editable}
              specimenId={specimenId}
              zone={zone}
              elements={elements}
              propertyName="value"
              updateTest={updateTest}
            />
          </TableRow>
          {secondValueRow && (
            <TableRow>
              <ElementsResults
                editable={editable}
                specimenId={secondSpecimenId}
                zone={zone}
                elements={elements}
                propertyName="secondValue"
                updateTest={updateTest}
              />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ElementsTable;
