import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import TableSkeleton from "@core/components/TableSkeleton";
import styles from "./styles";
import {Grid, Button, Table, TableBody, TableCell, TableHead, TableRow, MenuItem, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ActionCell from "@core/components/ActionCell";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import AddTestConditioning from "../../Certificates/Certificate/components/AddTestConditioning";
import useStores from "../../useStores";
import ExpandableTableRow from "./components/ExpandableTableRow";

const ConditionsPresetsList = observer(({classes}) => {
  const {CertificateStore, ConditionStore} = useStores();

  const [addTestConditioningOpened, setAddTestConditioningOpened] = useState(false);
  const [isDeleteConfirmOpened, setIsDeleteConfirmOpened] = useState(null);
  const [conditionToEdit, setConditionToEdit] = useState(null);

  const deleteCondition = () => {
    ConditionStore.deleteConditionPreset(isDeleteConfirmOpened)
      .then(() => {
        setIsDeleteConfirmOpened(null);
        ConditionStore.getPresetsByCompany();
      });
  };

  useEffect(() => {
    ConditionStore.getPresetsByCompany();
  }, []);

  const certificate = CertificateStore.certificate.data;
  const {conditionsPresets} = ConditionStore;

  return (
    <div className="padded-container">
      <Grid container spacing={5} marginBottom={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Conditions Presets
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { setAddTestConditioningOpened(true); }}
          >
            Create new
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {conditionsPresets.isLoaded ? (
              <TableBody>
                {conditionsPresets.data.length ? conditionsPresets.data.map((condition) => (
                  <ExpandableTableRow key={condition._id} condition={condition}>
                    <TableCell>{condition.name}</TableCell>
                    <TableCell>
                      <ActionCell>
                        <MenuItem
                          onClick={() => {
                            setConditionToEdit(condition);
                            setAddTestConditioningOpened(true);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          className={classes.deleteButton}
                          onClick={() => setIsDeleteConfirmOpened(condition._id)}
                        >
                            Delete
                        </MenuItem>
                      </ActionCell>
                    </TableCell>
                  </ExpandableTableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={4}>No presets found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableSkeleton columns={3}/>
              </TableBody>
            )}
          </Table>
        </Grid>
      </Grid>

      <Confirmation
        open={isDeleteConfirmOpened}
        onCancel={() => setIsDeleteConfirmOpened(null)}
        onConfirm={() => deleteCondition()}
        alertText={"Are you sure you want to delete the condition?"}
      />

      <AddTestConditioning
        prevCondition={conditionToEdit}
        certificate={certificate}
        open={addTestConditioningOpened}
        isPreset={true}
        setConditionToEdit={setConditionToEdit}
        onClose={() => {
          setConditionToEdit(null);
          setAddTestConditioningOpened(false);
        }}
      />
    </div >
  );
});

export default withStyles(ConditionsPresetsList, styles);
