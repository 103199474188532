import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import React from "react";

function CollapsibleSection({title, children}) {
  return (
    <Accordion
      sx={{
        marginBottom: 2,
        "&&": {borderRadius: 1},
        "&:before": {
          display: "none"
        },
        border: "1px solid #efefef",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default CollapsibleSection;