import React, {useCallback, useMemo, useState} from "react";
import {compose} from "ramda";
import {inject, observer} from "mobx-react";
import {DialogContent, Modal} from "@mui/material";
import CompanyInfo from "@core/components/CompanyInfo";
import {DialogOpenContext} from "@core/components/TimelineBlock/contexts";

function CompanyInfoDialogProvider({children, CompanyStore}) {
  const [company, setCompany] = useState(null);
  const [companyInfoOpen, setCompanyInfoOpen] = useState(false);

  const handleOpen = useCallback((id) => (e) => {
    e.stopPropagation();

    CompanyStore.getCompanyById(id).then(() => {
      setCompanyInfoOpen(true);
      setCompany(CompanyStore.company.data);
    });
  }, [CompanyStore]); // TODO to see if can change to nested

  const handleClose = useCallback(() => {
    setCompanyInfoOpen(false);
    setCompany(null);
  }, []);

  const value = useMemo(() => ({handleOpen, handleClose}), [handleOpen, handleClose]);

  return (
    <DialogOpenContext.Provider value={value}>
      {children}
      {company && companyInfoOpen &&
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={companyInfoOpen}
          onClose={handleClose}
        >
          <DialogContent>
            <CompanyInfo company={company} handleClose={handleClose} />
          </DialogContent>
        </Modal>
      }
    </DialogOpenContext.Provider>
  );
}

export default compose(
  inject("CompanyStore"),
  observer
)(CompanyInfoDialogProvider);