import React from "react";
import {Badge, Button, Tooltip} from "@mui/material";
import {GoChecklist} from "react-icons/go";

const SignAllTestsButton = ({availableToSign}) => {
  return (
    <Tooltip title="Approve multiple tests">
      <Badge color="error" badgeContent={availableToSign.length} overlap="circular">
        <Button
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: "100%"
          }}
          variant="containedGray"
          disabled={!availableToSign.length}
        >
          <GoChecklist size={24} />
        </Button>
      </Badge>
    </Tooltip>
  );
};

export default SignAllTestsButton;