import React from "react";
import {Chip} from "@mui/material";
import {COLUMNS, INSPECTION_RESULTS} from "../../constants";

const InspectionResult = ({test}) => {
  const inspectionResult = test[COLUMNS.INSPECTION_RESULT];

  const INSPECTION_THEME_BY_INSPECTION_RESULT = {
    [INSPECTION_RESULTS.ACCEPTED]: {
      backgroundColor: (theme) => theme.palette.success.backgroundColor,
      color: (theme) => theme.palette.success.text
    },
    [INSPECTION_RESULTS.UNACCEPTED]: {
      color: (theme) => theme.palette.error.main,
      backgroundColor: (theme) => theme.palette.error.backgroundColor,
    },
    [INSPECTION_RESULTS.UNSET]: {
      color: (theme) => theme.palette.warning.text,
      backgroundColor: (theme) => theme.palette.warning.backgroundColor
    },
    NO_WITNESS: {
      color: (theme) => theme.palette.deactivate.text,
      backgroundColor: (theme) => theme.palette.deactivate.backgroundColor
    },
  };

  return (
    <Chip
      size="small"
      label={inspectionResult || "N/A"}
      sx={inspectionResult ? INSPECTION_THEME_BY_INSPECTION_RESULT[inspectionResult] : INSPECTION_THEME_BY_INSPECTION_RESULT.NO_WITNESS}
    />
  );
};

export default InspectionResult;