import React, {useEffect, useMemo, useRef, useState} from "react";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import {Box, Chip, MenuItem, Select as MuiSelect} from "@mui/material";
import {Input} from "@core/components/Form";
import ListItemsWrapper from "./components/ListItemsWrapper";
import {SelectContext} from "./context";
import {MdAddCircleOutline} from "react-icons/md";

function MultipleSelect({
  elements,
  value,
  onChange,
  disabled,
  label,
  required,
  error,
  errorMessage,
  classes
}) {
  const [open, setOpen] = useState(false);
  const providerValue = useMemo(() => ({setOpen}), [setOpen]);
  const selectedValues = useRef([]);

  const deleteItem = (event, clickedValue) => {
    const newArray = [...selectedValues.current];
    const indexOfElement = selectedValues.current.indexOf(clickedValue);
    newArray.splice(indexOfElement, 1);
    onChange(newArray);

    event.stopPropagation();
  };

  useEffect(() => {
    selectedValues.current = value;
  }, [value]);

  const onDeleteItem = (event, value) => {
    if (disabled) {
      return;
    }

    deleteItem(event, value);
  };

  return (
    <Box>
      <div className={classes.container}>
        <SelectContext.Provider value={providerValue}>
          <MuiSelect
            multiple
            value={value}
            onChange={(event) => onChange(event.target.value)}
            classes={{select: classes.select}}
            open={open}
            IconComponent={value?.length ? null : undefined}
            input={
              <Input
                disabled={disabled}
                label={label}
                required={required}
                error={error}
                errorMessage={errorMessage}
                onClick={() => !open && !disabled && setOpen(true)}/>
            }
            renderValue={(selected) => (
              <Box sx={{display: "flex", gap: 0.5}}>
                {selected.map((value) => (
                  <Chip
                    size="small"
                    key={value}
                    label={value}
                    deleteIcon={
                      disabled ? undefined :
                        <MdAddCircleOutline
                          size={20}
                          className={classes.chipDeleteIcon}
                        />
                    }
                    onDelete={(event) => onDeleteItem(event, value)}/>
                ))}
              </Box>
            )}
            MenuProps={{MenuListProps: {component: ListItemsWrapper}}}
          >
            {elements.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </MuiSelect>
        </SelectContext.Provider>
      </div>
    </Box>
  );
}

export default React.memo(withStyles(MultipleSelect, styles));