import {action, computed, makeObservable, observable} from "mobx";
import axios from "axios";
import {omit, indexBy, prop} from "ramda";
import MODULES from "@core/constants/modules";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class UserStore {
  constructor() {
    makeObservable(this, {
      users: observable,
      user: observable,
      logs: observable,
      setUser: action,
      getAll: action,
      getUserLogs: action,
      create: action,
      isProducer: action,
      isBasic: action,
      linkAccount: action,
      unlinkAccount: action,
      features: computed,
    });
  }
  users = {
    data: [],
    isLoaded: false,
  };

  user = {
    data: {},
    isLoaded: false,
  };

  logs = {
    data: [],
    isLoaded: false
  };

  setUser(user) {
    this.user = {
      data: user,
      isLoaded: true
    };
  }

  async getAll() {
    this.users.isLoaded = false;

    const res = await axios.get(ROUTES.USER[ACTIONS.ALL],{
      withCredentials: true
    });

    this.users = {
      data: res.data,
      isLoaded: true
    };
  }

  async getUserLogs(userId) {
    const res = await axios.get(ROUTES.LOG[ACTIONS.ALL], {
      params: {userId},
      withCredentials: true,
    });

    this.logs = {
      data: res.data.items,
      isLoaded: true
    };
  }

  async create(data) {
    this.user.isLoaded = false;

    const res = await axios.post(ROUTES.USER[ACTIONS.CREATE], data, {
      withCredentials: true,
    }).catch((e) => e);

    this.user.data = res.data;
    this.user.isLoaded = true;
  }

  isProducer() {
    if (!this.user.isLoaded) {
      return false;
    }

    return [MODULES.PRODUCER, MODULES.END_OWNER].includes(this.user.data.company.modules[0].name);
  }

  isBasic() {
    if (!this.user.isLoaded) {
      return false;
    }

    return this.user.data.company.modules[0].name === MODULES.BASIC;
  }
  
  linkAccount(provider) {
    axios.post(ROUTES.AUTH[ACTIONS.CREATE](provider))
      .then(({data}) => {
        window.location.href = data.consentScreenUrl;
      });
  }
  
  unlinkAccount(provider) {
    return axios.delete(ROUTES.AUTH[ACTIONS.REMOVE](provider))
      .then(() => {
        const updatedUser = {...this.user.data};
        updatedUser.linkedAccounts =  omit([provider], updatedUser.linkedAccounts);
        this.setUser(updatedUser);

        return updatedUser;
      });
  }

  get features() {
    return indexBy(prop("name"), this.user.data.company.features);
  }
}

export default new UserStore();
