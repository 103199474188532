import React from "react";
import {
  Grid,
  DialogContent,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExaminationConditions from "@core/components/ExaminationConditions";
import EditFormHydrostatic from "@core/components/TestForms/HydrostaticTest";
import File from "@core/components/File";
import styles from "./styles";

const HydrostaticTest = ({
  classes,
  test,
  addDataOpen,
  dataDialogClose,
  updateTest,
  formRef
}) => {
  const {properties} = test;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table className={"styled-table"} >
            <TableHead>
              <TableRow>
                {properties.testingFluid && <TableCell>Testing Fluid</TableCell>}
                {properties.pressure && <TableCell> Pressure</TableCell>}
                {properties.minTime && <TableCell>Minimum Holding Time</TableCell>}
                {properties.quantityInspected && <TableCell>Quantity Inspected</TableCell>}
                {properties.pieceIdentification && <TableCell>Piece Identification</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {properties.testingFluid && <TableCell>{properties.testingFluid}</TableCell>}
                {properties.pressure && <TableCell>{properties.pressure} {properties.pressureUnit}</TableCell>}
                {properties.minTime && <TableCell>{properties.minTime} {properties.minHoldingTimeUnits}</TableCell>}
                {properties.quantityInspected && <TableCell>{properties.quantityInspected}</TableCell>}
                {properties.pieceIdentification && <TableCell>{properties.pieceIdentification}</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item container spacing={5} alignContent="space-between">
          {properties.files && (
            <Grid item xs={12} spacing={5} container>
              {properties.files.map((image) =>
                <Grid item xs={4} container alignItems="center" key={image}>
                  <File file={image} preview />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <ExaminationConditions
          test={test}
          file={test.properties.file}
        />

        {properties.notes && (
          <Grid item container spacing={5}>
            <Grid item xs={12}>
              <p
                className={classes.value}
                dangerouslySetInnerHTML={{__html: properties.notes.split("\n").join("</br>")}}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent className={classes.dialogContent}>
          <EditFormHydrostatic
            formRef={formRef}
            test={test}
            isProducerTest={true}
            saveTest={updateTest}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(HydrostaticTest, styles);
