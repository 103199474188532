import React from "react";
import {Table, TableBody, TableRow, TableCell, TableHead} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import {omit} from "ramda";
import testsConfig from "@core/configs/test";
import {STATUSES, TEST_TYPES, TYPES} from "@core/constants/test";
import modules from "@core/constants/modules";
import TestTypesCell from "./TestTypesCell";
import QcpList from "./QcpList";
import useStores from "../../../useStores";
import styles from "./styles";

const getAllowedTestTypes = (module) => {
  const allTypes = Object.values(testsConfig);

  if (module === modules.LAB_INTERNAL) {
    return allTypes.filter((config) => config.responsible.includes(module));
  }

  return allTypes;
};

const TestsListTable = observer(({certificates, types, setTypes, qcps = [], setQcps, classes}) => {
  const {UserStore} = useStores();

  const [module] = UserStore.user.data.company.modules;

  const testTypes = getAllowedTestTypes(module.name);
  const sortedTestTypes = testTypes.sort((a, b) => a.type > b.type ? 1 : -1);

  const selectType = (type) => {
    if(qcps.length) return;

    if (types[type]) {
      setTypes(omit([type], types));
    } else {
      setTypes({...types, [type]: 1});
    }
  };

  const addTestOfType = (type) => {
    const newTypes = omit([type], types);
    newTypes[type] = types[type] + 1;

    setTypes(newTypes);
  };

  const removeTestOfType = (type) => {
    const amount = types[type];
    const newTypes = omit([type], types);

    if(amount - 1) newTypes[type] = amount - 1;

    setTypes(newTypes);
  };

  const canAddIrn = () => {
    if (certificates.length > 1) return true;

    const [certificate] = certificates;

    return certificate && certificate.tests.every((test) => test.status === STATUSES.APPROVED);
  };

  const tests = sortedTestTypes.filter(({testType}) => testType === TEST_TYPES.TEST);
  const declarations = sortedTestTypes.filter(({testType, type}) => {
    if (type === TYPES.INSPECTION_RELEASE_NOTE) return canAddIrn();

    return testType === TEST_TYPES.DECLARATION;
  });

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Tests</TableCell>
          <TableCell>Declarations</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.testsList}>
          <TestTypesCell
            disabled={qcps && qcps.length}
            tests={tests}
            selectType={selectType}
            selectedTypes={types}
            addTestOfType={addTestOfType}
            removeTestOfType={removeTestOfType}
          />
          <TestTypesCell
            disabled={qcps && qcps.length}
            isRightCell
            tests={declarations}
            selectType={selectType}
            selectedTypes={types}
            addTestOfType={addTestOfType}
            removeTestOfType={removeTestOfType}
          >
            <QcpList
              setTypes={setTypes}
              setQcps={setQcps}
              qcps={qcps}
            />
          </TestTypesCell>
        </TableRow>
      </TableBody>
    </Table>
  );
});

export default withStyles(TestsListTable, styles);
