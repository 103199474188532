import React from "react";
import classNames from "classnames";
import CompanyInfoDialogProvider from "@core/components/TimelineBlock/providers/CompanyInfoDialogProvider";
import styles from "./styles";
import {withStyles} from "tss-react/mui";

function TimelineSidebarContent({isFirst, isLast, children, classes}) {
  return (
    <>
      <div className={classNames(
        [classes.timeLineBlock],
        {
          [classes.lineAfter]: isFirst && !isLast,
          [classes.lineBefore]: isLast && !isFirst,
          [classes.lineBeforeAfter]: !isFirst && !isLast
        }
      )}>
        <CompanyInfoDialogProvider>
          {children}
          {!isLast && <div className={classes.timelineBottom}>
            <div className={classes.timelineCircle}/>
          </div>}
        </CompanyInfoDialogProvider>
      </div>
    </>
  );
}

export default withStyles(TimelineSidebarContent, styles);