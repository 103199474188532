import React from "react";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {ENTITIES} from "../../../../../../constants";

const ArrowButton = ({item, onClick, children}) => {
  if(!item) return null;

  return (
    <Grid item>
      <Tooltip
        disableInteractive={!item._id}
        title={item.pipeNumber || item.weldNumber}
      >
        <IconButton
          disabled={!item._id}
          onClick={() => onClick(item, item.pipeNumber ? ENTITIES.PIPE : ENTITIES.WELD)}
          color="primary"
          size="small"
        >
          {children}
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default ArrowButton;