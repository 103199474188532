import DimensionsTags from "@core/components/DimensionsTags";
import EditableCell from "@core/components/EditableCell";
import TestsDrawer from "@core/components/TestsDrawer";
import TestSummaryTable from "@core/components/TestSummaryTable";
import {COLUMNS} from "@core/components/TestSummaryTable/constants";
import {TEST_RESULTS} from "@core/constants/testResults";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Box, Chip, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {indexBy, isNil, prop} from "ramda";
import React, {useMemo, useState} from "react";
import themeSettings from "../../../../../../../theme-settings";
import useStores from "../../../../../../../useStores";

const THEME_BY_CERTIFICATE_STATUS = {
  [TEST_RESULTS.NOT_ACCEPTABLE]: themeSettings.palette.warning,
  [TEST_RESULTS.ACCEPTABLE]: themeSettings.palette.success,
};

const getCertificateStatus = (certificate) => {
  return certificate.tests.some((test) => test.properties.result === TEST_RESULTS.NOT_ACCEPTABLE) ?
    TEST_RESULTS.NOT_ACCEPTABLE :
    TEST_RESULTS.ACCEPTABLE;
};

const SUMMARY_COLUMNS = [
  COLUMNS.ACTIVITY_NUMBER,
  COLUMNS.TITLE,
  COLUMNS.WITNESS_EDITABLE,
  COLUMNS.COMPANY,
  COLUMNS.STATUS,
  COLUMNS.RESULT,
  COLUMNS.INSPECTION_RESULT,
];

const Certificate = ({certificate, updateCertificate, updateTest, displayLotId}) => {
  const [open, setOpen] = useState(false);
  const [openTestIndex, setOpenTestIndex] = useState(null);
  const [filteredTestIds, setFilteredTestIds] = useState([]);

  const {UserStore} = useStores();

  const company = UserStore.user.data.company;

  const certificateStatus = useMemo(() => getCertificateStatus(certificate), [certificate.tests]);

  const formattedTests = useMemo(() => certificate.tests.map((test, index) => ({
    ...test,
    _id: index,
    company: {
      name: company.name,
    },
  })), [certificate.tests]);

  const testsById = useMemo(() => indexBy(prop("_id"), formattedTests), [formattedTests]);
  const filteredTests = filteredTestIds.length ? filteredTestIds.map((id) => testsById[id]) : formattedTests;

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setOpen( !open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{company.name}</TableCell>
        <TableCell>{company.name}</TableCell>
        <TableCell>
          <EditableCell
            editable
            value={certificate.lineItem}
            setValue={(value) => updateCertificate({lineItem: value})}
          />
        </TableCell>
        <TableCell>{certificate.properties.productType.name}</TableCell>
        <TableCell>{certificate.heat}</TableCell>
        {displayLotId && <TableCell>{certificate.properties.lotId || "-"}</TableCell>}
        <TableCell>
          {certificate.items.reduce((quantity, item) => Number(item.quantity) + quantity, 0)}
        </TableCell>
        <TableCell>
          <Chip
            label={certificateStatus}
            sx={{
              backgroundColor: THEME_BY_CERTIFICATE_STATUS[certificateStatus].backgroundColor,
              color: THEME_BY_CERTIFICATE_STATUS[certificateStatus].text
            }}
            size="small"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{padding: 0}}
          sx={!open ? {borderBottom: "none"} : {}}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 2}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table className="styled-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product Location</TableCell>
                        <TableCell>Heat</TableCell>
                        <TableCell>Material</TableCell>
                        <TableCell>Grade</TableCell>
                        <TableCell>Nominal Dimensions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{company.mainLocation.street}, {company.mainLocation.house}, {company.mainLocation.postalCode}, {company.mainLocation.city}</TableCell>
                        <TableCell>{certificate.heat}</TableCell>
                        <TableCell>{certificate.properties.norm}</TableCell>
                        <TableCell>{certificate.properties.grade}</TableCell>
                        <TableCell>
                          <DimensionsTags
                            certificates={[certificate]}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom component="div">
                    Related Tests
                  </Typography>
                  <TestSummaryTable
                    onTestClick={([test]) => setOpenTestIndex(test._id)}
                    setFilteredTestIds={setFilteredTestIds}
                    tests={filteredTests}
                    withPlots={false}
                    columns={SUMMARY_COLUMNS}
                    updateTests={(changes, [testId]) => updateTest(changes, testId)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TestsDrawer
        open={!isNil(openTestIndex)}
        tests={[certificate.tests[openTestIndex]]}
        close={() => setOpenTestIndex(null)}
        updateTest={(changes) => updateTest(changes, openTestIndex)}
      />
    </>
  );
};

export default observer(Certificate);