import React from "react";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import useStores from "../../../useStores";

const ProjectId = ({certificate}) => {
  const {UserStore} = useStores();

  const user = UserStore.user.data;
  const isOwner = certificate.projectId.owner === user.company._id;
  
  if(!isOwner) return certificate.projectId.name;
  
  return (
    <Button
      component={Link}
      to={`/projects/${certificate.projectId._id}`}
      target="_blank"
    >
      {certificate.projectId.name}
    </Button>
  );
};

export default observer(ProjectId);