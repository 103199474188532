import TableSkeleton from "@core/components/TableSkeleton";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";
import Certificate from "./components/Certificate";

const Certificates = ({certificates, isLoading, updateCertificate}) => {
  const updateTest = (changes, testIndex, certificateIndex) => {
    const certificate = certificates[certificateIndex];
    const newTests = certificate.tests.map((test, index) => testIndex === index ? {...test, ...changes} : test);
    updateCertificate({tests: newTests}, certificateIndex);
  };

  const displayLotId = certificates.some((certificate) => certificate.properties.lotId);

  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Owner</TableCell>
          <TableCell>Manufacturer</TableCell>
          <TableCell>PO Item No.</TableCell>
          <TableCell>Product Type</TableCell>
          <TableCell>Heat</TableCell>
          {displayLotId && <TableCell>Lot Id</TableCell>}
          <TableCell>Quantity</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading ? (
          certificates.length ? (
            certificates.map((certificate, index) => (
              <Certificate
                key={index}
                certificate={certificate}
                updateCertificate={(changes) => updateCertificate(changes, index)}
                updateTest={(changes, testIndex) => updateTest(changes, testIndex, index)}
                displayLotId={displayLotId}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                  No data.
              </TableCell>
            </TableRow>
          )
        ) : (
          <TableSkeleton columns={displayLotId ? 9 : 8} />
        )}
      </TableBody>
    </Table>
  );
};

export default Certificates;