import {TEST_RESULTS} from "@core/constants/testResults";
import React from "react";
import * as yup from "yup";
import {FieldArray, Formik} from "formik";
import {omit} from "ramda";
import {Button, Divider, Grid, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {isAcceptable} from "@core/helpers/tests";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import {MultipleSelect} from "@core/components/Form";
import ClientField from "../../../../Tests/Test/components/ClientField";
import IdentificationOfInspectedItems from "../../IdentificationOfInspectedItems";
import {ACCEPTANCE_CRITERIA, TEST_STANDARDS, EXAMINED_SURFACES} from "./data";
import styles from "./styles";
import MuiSelect from "@core/components/MuiSelect";
import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";

const ELEMENT = {
  notchesLocation: "",
  width: "",
  length: "",
  depth: "",
  depthUnit: "mm",
};

const ElectromagneticInduction = ({classes, user, test, closeNewTest, isProducerTest, client, saveTest, inspectedCertificates, formRef}) => {
  const validationSchema = yup.object().shape({
    client: !isProducerTest ? yup.string().required("This field is required!") : yup.string(),
    lab: !isProducerTest ? yup.string().required("This field is required!") : yup.string(),
    testStandard: yup.string().required("This field is required!"),
    acceptance: yup.string().required("This field is required!"),
    surfaceCondition: yup.string(),
    examinedSurface: yup.array().required("This field is required!"),
    elements: yup.array().of(yup.object().shape({
      notchesLocation: yup.string().required("This field is required!"),
      width: yup.number().min(0, "Should be > 0").required("This field is required!"),
      length: yup.number().min(0, "Should be > 0").required("This field is required!"),
      depth: yup.number().min(0, "Should be > 0").required("This field is required!"),
      depthUnit: yup.string().required("This field is required!"),
    })).required(),
    result: yup.string().required("This field is required!"),
    notes: yup.string(),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
  });

  const initialValues = {
    client: !isProducerTest ? (test?.properties.client || client.name || "") : "",
    lab: !isProducerTest ? (test?.properties.lab || user.company.name || "") : "",
    testStandard: test?.properties.testStandard || "",
    acceptance: test?.properties.acceptance || "",
    surfaceCondition: test?.properties.surfaceCondition || "Machined",
    examinedSurface: test?.properties.examinedSurface || [],
    elements: test?.properties.elements || [ELEMENT],
    result: test?.properties.result || "",
    notes: test?.properties.notes || "",
    witnesses: test?.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || []
  };

  const onSubmit = (values) => {
    if (isProducerTest) saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    else saveTest(values);

    if (!closeNewTest) return;

    closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(props) => {
        const acceptable = isAcceptable(props.values.result);

        return (
          <>
            {isProducerTest && (
              <Grid item container spacing={5} marginBottom={4}>
                <Grid item>
                  <h1>Electromagnetic induction test</h1>
                </Grid>
              </Grid>
            )}
            {!isProducerTest && (
              <Grid item container spacing={5} marginBottom={2}>
                <Grid item xs={3}>
                  <ClientField isFromProducer={!!client.name} />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    name="lab"
                    label="Laboratory"
                    required
                  />
                </Grid>
              </Grid>
            )}
            <Grid item container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 4 : 3}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={TEST_STANDARDS}
                />
              </Grid>
              <Grid item xs={isProducerTest ? 4 : 3}>
                <MuiSelect
                  required
                  label="Acceptance criteria"
                  name="acceptance"
                  defaultOptions={ACCEPTANCE_CRITERIA}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <TextField
                  name="surfaceCondition"
                  label="Surface condition"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <MultipleSelect
                  value={props.values.examinedSurface}
                  label='Examined surface'
                  elements={EXAMINED_SURFACES}
                  onChange={(values) => props.setFieldValue("examinedSurface", values)}
                  required
                />
              </Grid>
            </Grid>
            <IdentificationOfInspectedItems
              isProducerTest={isProducerTest}
              inspectedCertificates={inspectedCertificates}
              test={test}
            />
            <Grid item container spacing={5} marginBottom={2}>
              <Grid item xs={12}>
                <h3>Calibration</h3>
              </Grid>
            </Grid>
            <FieldArray name="elements">
              {({remove, push}) => props.values.elements.map((element, index) => (
                <>
                  <Grid item container spacing={3} marginBottom={2}>
                    <Grid item container xs={isProducerTest ? 8 : 6}>
                      <TextField
                        name={`elements.${index}.notchesLocation`}
                        label="Notches location"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <h3>Notches dimensions</h3>
                    </Grid>
                    <Grid item container>
                      <Grid item container spacing={5} alignItems="flex-end">
                        <Grid item xs={isProducerTest ? 4 : 3}>
                          <TextField
                            name={`elements.${index}.width`}
                            label="Width"
                            required
                            type="number"
                            endAdornment="MM"
                          />
                        </Grid>
                        <Grid item xs={isProducerTest ? 4 : 3}>
                          <TextField
                            name={`elements.${index}.length`}
                            label="Length"
                            required
                            type="number"
                            endAdornment="MM"
                          />
                        </Grid>
                        <Grid container spacing={3} item xs={isProducerTest ? 4 : 3} alignItems="flex-end">
                          <Grid item xs={8}>
                            <TextField
                              name={`elements.${index}.depth`}
                              label="Depth"
                              required
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <SelectField
                              name={`elements.${index}.depthUnit`}
                              required
                            >
                              <MenuItem key="mm" value="mm">MM</MenuItem>
                              <MenuItem key="%" value="%">%</MenuItem>
                            </SelectField>
                          </Grid>
                        </Grid>
                        {props.values.elements.length > 1 && (
                          <Grid item>
                            <Button
                              variant="contained"
                              size="large"
                              color="secondary"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </Button>
                          </Grid>
                        )}
                        {props.values.elements.length - 1 === index && (
                          <Grid item>
                            <Button
                              variant="contained"
                              size="large"
                              color="primary"
                              onClick={() => push(ELEMENT)}
                            >
                              Add
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} marginBottom={2}>
                    <Grid item xs={12}>
                      <Divider classes={{root: classes.divider}} />
                    </Grid>
                  </Grid>
                </>
              ))}
            </FieldArray>
            <Grid item container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6} marginBottom={2}>
                <SelectField
                  name="result"
                  label="Result"
                  required
                >
                  <MenuItem value={TEST_RESULTS.NO_RECORDABLE_INDICATIONS}>{TEST_RESULTS.NO_RECORDABLE_INDICATIONS}</MenuItem>
                  <MenuItem value={TEST_RESULTS.ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.ACCEPTABLE_INDICATIONS}</MenuItem>
                  <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE}>{TEST_RESULTS.NOT_ACCEPTABLE}</MenuItem>
                </SelectField>
              </Grid>
            </Grid>
            <Grid item container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <TextField
                  rows={4}
                  multiline
                  name="notes"
                  label="Notes"
                />
              </Grid>
            </Grid>
            {isProducerTest ? (
              <ProducerTestFooter />
            ) : (
              <LabTestFooter
                onSubmit={onSubmit}
                result={acceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default withStyles(ElectromagneticInduction, styles);
