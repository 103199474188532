import React from "react";
import {Button} from "@mui/material";

export const getColumns = (setQcpToView, setQcpDrawerVisible) => [{
  isSorted: true,
  dataIndex: "name",
  title: "QCP Name",
}, {
  isSorted: true,
  dataIndex: "revision",
  title: "QCP Revision",
}, {
  isSorted: true,
  dataIndex: "qcpItem",
  title: "QCP Item",
}, {
  dataIndex: "action",
  permanent: true,
  title: "Action",
  render: (qcp) => (
    <Button
      onClick={() => {
        setQcpToView(`${qcp.name} Rev. ${qcp.revision}`);
        setQcpDrawerVisible(true);
      }}
    >
      View
    </Button>
  )
}];