import baseStore from "@core/helpers/baseStore";
import {TableStore} from "@core/stores";
import axios from "axios";
import {action, computed, makeObservable, observable} from "mobx";
import {isEmpty, map, mergeRight} from "ramda";
import {CERTIFICATE_STATUSES_BY_VIEW, CERTIFICATE_VIEWS} from "../Overview/data";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class CertificateStore extends baseStore {
  constructor(props) {
    super(props);

    makeObservable(this, {
      certificates: observable,
      selectedCertificates: computed,
      certificate: observable,
      product: observable,
      products: observable,
      orders: observable,
      order: observable,
      feedback: observable,
      splitCertificateData: observable,
      isForceSignCertificate: observable,
      resetCertificates: action,
      resetOrders: action,
      resetOrder: action,
      addTest: action,
      addCondition: action,
      setCertificates: action,
      updateCertificate: action,
      updateTests: action,
      updateTestTransactions: action,
      getCertificateById: action,
      getCertificates: action,
      getArchivedCertificate: action,
      deleteCertificates: action,
      getOrderById: action,
      convertProductType: action,
      convertMultipleProductType: action,
      splitCertificate: action,
      getCertificateByTestId: action,
      editCertificate: action,
      deleteTest: action,
      deleteCondition: action,
      getFeedback: action,
      sendFeedback: action,
      updateFeedback: action,
      resolveIssue: action,
      getProducts: action,
      getCertificateProductById: action,
      openForceSignCertificate: action,
      closeForceSignCertificate: action,
      addCertificateToOutgoingOrder:action,
      removeCertificateFromOutgoingOrder: action,
      updateHash: action,
    });
  }

  certificates = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.certificates.fetch, ...queryParams};
      const {offset, limit, search, sort, view} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value: search},
        statuses: CERTIFICATE_STATUSES_BY_VIEW[view],
        statusesStrict: view === CERTIFICATE_VIEWS.COMPLETE,
        select: "heat properties orderNumber internalWorkOrder internalItemNumber lineItem projectId deliveryCondition tests items transactions transfers tags",
      };

      this.certificates.setFetch(newQueryParams);

      return axios.get(
        ROUTES.CERTIFICATE[ACTIONS.ALL],
        {params}
      );
    }
  });

  get selectedCertificates() {
    return this.certificates.data.filter((c) => this.certificates.selected.includes(c._id)) || [];
  }

  certificate = {
    data: {},
    isLoaded: false
  };

  product = {};

  products = {
    data: [],
    isLoading: false,
  };

  orders = {
    data: [],
    offset: 0,
    limit: 10,
    total: 0,
    isLoaded: false
  };

  order = {
    data: {},
    isLoaded: false
  };

  feedback = {
    data: [],
    isLoaded: false,
  };

  splitCertificateData = [];

  isForceSignCertificate = false;

  resetCertificates() {
    this.certificates = {
      data: [],
      offset: 0,
      limit: 10,
      total: 0,
      isLoaded: false
    };
  }

  resetOrders() {
    this.orders = {
      data: [],
      offset: 0,
      limit: 10,
      total: 0,
      isLoaded: false
    };
  }

  resetOrder() {
    this.order = {
      data: {},
      isLoaded: false
    };
  }

  async addTest(tests, id) {
    const res = await axios.patch(ROUTES.CERTIFICATE[ACTIONS.UPDATE](id), {tests}, {
      withCredentials: true,
    });

    this.certificate = {
      data: res.data,
      isLoaded: true
    };
  }

  async addCondition(conditionId, id) {
    const res = await axios.patch(ROUTES.CERTIFICATE[ACTIONS.UPDATE](id), {conditions: [conditionId]}, {
      withCredentials: true,
      successMessage: "Successfully created"
    });

    this.certificate = {
      data: res.data,
      isLoaded: true
    };
  }

  setCertificates(newCertificates) {
    this.certificates.data = newCertificates;
  }

  updateCertificate(newCertificate) {
    this.certificate.data = {...this.certificate.data, ...newCertificate};
  }

  updateTests(newTest) {
    const newTests = map(
      (X) => X._id === newTest._id ?
        mergeRight(X, newTest) :
        X
    )(this.certificate.data.tests);

    this.certificate.data = {...this.certificate.data, tests: newTests};
  }
  updateTestTransactions(testId, transaction) {
    const newTests = map(
      (X) => X._id === testId ?
        mergeRight(X, {transactions: [...X.transactions, transaction]}) :
        X
    )(this.certificate.data.tests);

    this.certificate.data = {...this.certificate.data, tests: newTests};
  }
  
  async getCertificateById(id) {
    const res = await axios.get(ROUTES.CERTIFICATE[ACTIONS.SINGLE](id), {
      withCredentials: true
    });

    this.certificate = {
      data: res.data,
      isLoaded: true,
    };
  }
  
  async getCertificates(params) {
    this.certificates.isLoaded = false;

    const res = await axios.get(ROUTES.CERTIFICATE[ACTIONS.ALL], params);

    this.certificates = {
      data: res.data.items,
      offset: res.data.offset,
      limit: res.data.limit,
      total: res.data.total,
      isLoaded: true
    };
  }
  
  async getArchivedCertificate(id) {
    const response = await axios.get(ROUTES.ARCHIVE[ACTIONS.SINGLE](id), {
      withCredentials: true
    });

    this.certificate.data = response.data.entity;

    return response;
  }
  
  async deleteCertificates(ids, deleteTests) {
    this.certificate.isLoaded = false;

    await axios.delete(ROUTES.CERTIFICATE[ACTIONS.REMOVE], {
      params: {deleteTests},
      withCredentials: true,
      data: {
        certificates: ids,
      },
      successMessage: "Successfully deleted"
    });
  }

  async getOrderById(id) {
    const res = await axios.get(ROUTES.TRANSFER[ACTIONS.SINGLE](id), {
      withCredentials: true
    });

    this.order = {
      data: res.data,
      isLoaded: true
    };
  }
  
  async convertProductType(certificate, data) {
    const res = await axios.patch(ROUTES.CERTIFICATE[ACTIONS.CONVERT](certificate._id), {
      newProperties: data.properties,
      oldProperties: certificate.properties,
      newItems: data.items,
      oldItems: certificate.items,
      certificateType: data.certificateType,
      markingNotes: data.notes,
      markingText: data.markingText,
      markingFiles: data.markingFiles,
      deliveryCondition: data.deliveryCondition,
    }, {
      withCredentials: true,
      successMessage: "Successfully converted"
    });

    this.certificate.isLoaded = true;
    this.certificate.data = res.data;
  }
  
  async convertMultipleProductType(data) {
    await axios.patch(ROUTES.CERTIFICATE[ACTIONS.CONVERT_MULTIPLE], data, {
      withCredentials: true,
      successMessage: "Successfully converted"
    });
  }
  
  async splitCertificate(certificateId, data, multiple) {
    this.certificates.isLoaded = false;
    const res = await axios.patch(ROUTES.CERTIFICATE[ACTIONS.SPLIT](certificateId), data, {
      ...{...(multiple ? {params: {multiple}} : {})},
    });
    this.certificates.isLoaded = true;
    this.splitCertificateData = res.data;
  }
  
  async getCertificateByTestId(testId) {
    this.certificate.isLoaded = false;
    const res = await axios.get(ROUTES.CERTIFICATE[ACTIONS.SINGLE_BY_TEST_ID](testId), {
      withCredentials: true
    });
    this.certificate.isLoaded = true;
    this.certificate.data = res.data;
  }
  
  async editCertificate(data, id) {
    const response = await axios.patch(ROUTES.CERTIFICATE[ACTIONS.UPDATE](id), data);

    this.certificate.data = response.data;
  }

  async updateCertificateWithOrder(id, data) {
    const response = await axios.patch(ROUTES.CERTIFICATE[ACTIONS.UPDATE_WITH_ORDER](id), data);

    this.certificate.data = response.data;
  }
  
  async deleteTest(testId, certificateId) {
    await axios.delete(ROUTES.CERTIFICATE[ACTIONS.REMOVE_TEST](certificateId, testId), {
      withCredentials: true,
      successMessage: "The test has been removed"
    });
  }
  
  async deleteCondition(conditionId, certificateId) {
    await axios.delete(ROUTES.CERTIFICATE[ACTIONS.REMOVE_CONDITION](certificateId, conditionId), {
      withCredentials: true,
      successMessage: "The condition has been removed"
    });
  }

  async getFeedback(certificateId, lineItem) {
    const response = await axios.get(ROUTES.PORTAL_ORDER[ACTIONS.SINGLE](certificateId, lineItem));

    this.feedback.data = response.data;
  }
  
  async sendFeedback(certificateId, lineItem, data) {
    const response = await axios.post(ROUTES.PORTAL_ORDER[ACTIONS.CREATE](certificateId, lineItem), data);

    this.feedback.data = response.data.lineItems[lineItem].feedback;
  }
  
  async updateFeedback(certificateId, lineItem, feedbackNumber, data) {
    const response = await axios.patch(ROUTES.PORTAL_ORDER[ACTIONS.UPDATE](certificateId, lineItem, feedbackNumber), data);

    this.feedback.data = response.data.lineItems[lineItem].feedback || [];
  }
  
  async resolveIssue(certificateId, lineItem, feedbackNumber) {
    const response = await axios.patch(ROUTES.PORTAL_ORDER[ACTIONS.UPDATE](certificateId, lineItem, feedbackNumber), {isClosed: true});

    this.feedback.data = response.data.lineItems[lineItem].feedback;
  }
  
  async getProducts() {
    this.products.isLoading = true;

    const response = await axios.get(ROUTES.PRODUCT_TYPE[ACTIONS.ALL]);

    this.products = {
      data: response.data.items,
      isLoading: false,
    };
  }
  
  async getCertificateProductById(productId) {
    const response = await axios.get(ROUTES.PRODUCT_TYPE[ACTIONS.SINGLE](productId));

    this.product = response.data;
  }

  openForceSignCertificate() {
    this.isForceSignCertificate = true;
  }

  closeForceSignCertificate() {
    this.isForceSignCertificate = false;
  }
  
  async addCertificateToOutgoingOrder(transferId, certificates) {
    await axios.post(ROUTES.DRAFT_TRANSFER[ACTIONS.UPDATE](transferId),{certificates});
  }

  async removeCertificateFromOutgoingOrder(transferId, certificates) {
    await axios.delete(ROUTES.DRAFT_TRANSFER[ACTIONS.REMOVE](transferId), {
      data: {certificates},
    });
  }
  
  async updateHash(id, certHash, data) {
    await axios.patch(ROUTES.TRANSACTION_HASH[ACTIONS.UPDATE](id, "certificates"), {certHash, stringified: JSON.stringify(data)});
  }
}

export default new CertificateStore();
