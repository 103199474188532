import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {difference, map, prop} from "ramda";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {STATUSES, TYPES} from "@core/constants/test";
import useStores from "../../../../useStores";
import styles from "./styles";
import modules from "@core/constants/modules";

const InspectionReleaseNote = observer(({classes, test}) => {
  const {CertificateStore, TestStore, UserStore} = useStores();

  const [module] = UserStore.user.data.company.modules;

  useEffect(() => {
    if(![modules.PRODUCER, modules.END_OWNER].includes(module.name)) return;

    const allCertificateTests = CertificateStore.certificate.data.tests;

    if(!allCertificateTests) return;

    const tests = allCertificateTests.filter((test) => test.type !== TYPES.INSPECTION_RELEASE_NOTE && test.status === STATUSES.APPROVED);
    const testIds = map(prop("_id"), tests);

    const irnTestIds = test.properties.testIds || [];

    const needsUpdate = Boolean(difference(testIds, irnTestIds).length);
    const canBeUpdated = test.status === STATUSES.FILLED;

    if (!needsUpdate || !canBeUpdated) return;

    const properties = {...test.properties, testIds};

    TestStore.update({properties}, test._id);
  }, []);

  return (
    <Grid container spacing={3}>
      {test.properties.note && (
        <Grid item xs={12} className={classes.note}>
          <p
            dangerouslySetInnerHTML={{__html: test.properties.note.split("\n").join("</br>")}}
          />
        </Grid>
      )}
    </Grid>
  );
});

export default withStyles(InspectionReleaseNote, styles);