import themeSettings from "../../../../../../../../theme-settings";
import {MARGIN} from "../Stalk/constants";

export const PIPE_CONFIG = {
  WIDTH: 110,
  HEIGHT: 55,
  RADIUS: 5,
  BACKGROUND_COLOR: "#3E7F8F",
  FONT_SIZE: 13,
  TEXT_SCALE_BY: 0.8,
  TEXT_Y: -10,
  TEXT_Y_MIN: -12,
  TEXT_Y_MAX: -25,
  TEXT_MAX_SCALE: 2.2,
  TEXT_MIN_SCALE: 0.75,
  COLOR: themeSettings.palette.text.primary,
  HIT_STROKE_WIDTH: MARGIN / 2,
};

export const WELD_CONFIG = {
  WIDTH: 33,
  HEIGHT: 55,
  RADIUS: 5,
  BACKGROUND_COLOR: "#4a5d6b",
  HIT_STROKE_WIDTH: MARGIN / 2,
};