export default (theme) => ({
  page: {
    padding: theme.spacing(4),
  },
  pageHeadline: {
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  pageTable: {
    width: "100%",
    marginTop: 30,
    overflowX: "auto",
    "& tr": {
      cursor: "pointer"
    }
  },
});
