export default (theme) => ({
  container: {
    padding: theme.spacing(2),
    minWidth: 250,
  },
  detailedLabel: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    position: "relative",
    zIndex: 3,
    padding: theme.spacing(),
    backgroundColor: "#fff",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#fff"
    }
  }
});