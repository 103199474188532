function isExperimentalDesignEnabled() {
  try {
    const experimentalDesign = JSON.parse(localStorage.getItem("experimentalDesign"));

    return !!experimentalDesign;
  } catch (error) {
    return false;
  }
}

export default isExperimentalDesignEnabled;