export default (theme) => ({
  error: {
    color: theme.palette.error.main
  },
  success: {
    color: theme.palette.success.main
  },
  info: {
    color: theme.palette.primary.main,
  },
  boldBorder: {
    borderBottom: "2px solid #9f9f9f"
  },
  cell: {
    borderLeft: "1px solid #e0e0e0",
  },
  avgCell: {
    borderLeft: "2px solid #9f9f9f"
  }
});

