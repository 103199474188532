import React, {useEffect, useRef, useState} from "react";
import {
  Button,
  Dialog, DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import ConfirmationOnModalClose from "@core/components/ConfirmationOnModalClose";
import {FieldArray, Formik} from "formik";
import {isEmpty} from "ramda";
import * as yup from "yup";
import useStores from "../../../../useStores";

const FORM_TYPES = {
  MANUAL: "MANUAL",
  UPLOAD: "UPLOAD"
};

const initialValues = {
  name: "",
  statements: [""]
};

const PresetForm = ({close, open, preset, usedNames}) => {
  const {StatementPresetsStore, NotificationStore} = useStores();

  const [statements, setStatements] = useState([]);
  const [formType, setFormType] = useState(FORM_TYPES.MANUAL);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const formRef = useRef();

  useEffect(() => {
    setStatements(preset.statements);
  }, [preset._id]);

  const validationSchema = yup.object().shape({
    name: yup.string().required("The field is required!"),
    statements: yup.array().of(yup.string().required("The field is required!")).min(1)
  });

  const onClose = () => {
    setFormType(FORM_TYPES.MANUAL);
    close();
  };

  const uploadStatements = async (file, name) => {
    if (preset._id) {
      await StatementPresetsStore.appendFromFile({file, name});
      onClose();
    } else {
      await StatementPresetsStore.uploadPreset({file, name});
      onClose();
    }
  };

  const createStatements = async (values) => {
    if (usedNames.includes(values.name)) {
      NotificationStore.showError(`Preset with name ${values.name} already exists!`);

      return;
    }

    await StatementPresetsStore.createPreset(values);
    onClose();
  };

  const updateStatements = async (values) => {
    await StatementPresetsStore.updatePreset(preset._id, values.statements);
    onClose();
  };

  const onSave = async () => {
    if(formRef.current) {
      const values = formRef.current.values;

      if (preset._id) await updateStatements(values);
      else await createStatements(values);
    }

    setConfirmationOpen(false);
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => {
          if (isEmpty(formRef.current.touched)) onClose();
          else setConfirmationOpen(true);
        }}
      >
        <DialogTitle>{preset._id ? "Edit statement preset" : "Add statement preset"}</DialogTitle>
        <DialogContent>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={preset._id ? preset : initialValues}
            validationSchema={validationSchema}
            onSubmit={preset._id ? updateStatements : createStatements}
          >
            {(props) => {
              return (
                <Grid item container spacing={3} alignItems="flex-end">
                  <Grid item xs={4}>
                    <TextField
                      disabled={!!preset._id}
                      required
                      label='Preset name'
                      name='name'
                    />
                  </Grid>
                  <Grid item xs>
                    <RadioGroup
                      row
                      value={formType}
                      onChange={({target}) => setFormType(target.value)}
                    >
                      <FormControlLabel
                        value={FORM_TYPES.MANUAL}
                        control={<Radio color="primary" />}
                        label="Add statements manually"
                      />
                      <FormControlLabel
                        value={FORM_TYPES.UPLOAD}
                        control={<Radio color="primary" />}
                        label="Upload statements"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="h5" variant="h5">
                      Statements
                    </Typography>
                  </Grid>
                  <Grid item container spacing={3} alignItems="flex-end">
                    {(formType === FORM_TYPES.MANUAL || preset._id) && (
                      <FieldArray name="statements">
                        {({remove}) => props.values.statements.map((property, index) => (
                          <>
                            <Grid item xs={10}>
                              <TextField
                                required
                                label={`Statement ${index + 1}`}
                                name={`statements.${index}`}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              {(props.values.statements.length > 1) && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    remove(index);
                                    setStatements(statements.filter((_, idx) => idx !== index));
                                  }}
                                >
                                  Remove
                                </Button>
                              )}
                            </Grid>
                          </>
                        ))}
                      </FieldArray>
                    )}
                  </Grid>
                  {formType === FORM_TYPES.UPLOAD && (
                    <Grid item>
                      <Tooltip title="Use a .txt file, where each line is a separate sentence" placement="top">
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={!props.values.name}
                            component="label"
                            onChange={async (event) => {
                              const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
                              await uploadStatements(file, props.values.name);
                            }}
                          >
                            Upload
                            <input
                              accept="text/plain"
                              disabled={!props.values.name}
                              type="file"
                              hidden
                            />
                          </Button>
                        </div>
                      </Tooltip>
                    </Grid>
                  )}
                  {formType === FORM_TYPES.MANUAL && (
                    <>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            props.setFieldValue("statements", [...props.values.statements, ""]);
                          }}
                        >
                          Add
                        </Button>
                      </Grid>
                      <Grid item container justifyContent="flex-end">
                        <Grid item>
                          <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            disabled={!props.isValid}
                            onClick={props.handleSubmit}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <ConfirmationOnModalClose
        confirmationOpen={confirmationOpen}
        formRef={formRef}
        setConfirmationOpen={setConfirmationOpen}
        onClose={onClose}
        onSave={onSave}
      />
    </>
  );
};

export default PresetForm;