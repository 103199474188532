export default (theme) => ({
  satisfactory : {
    color: "#4caf50",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },
  unsatisfactory: {
    color: "#d02d52",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },
  uploader: {
    marginTop: 20,
    width: "100%",
  },
  uploaderLabel: {
    position: "absolute",
    top: -43,
  },
  requirementMax: {
    marginBottom: 12,
    color: "#575757",
    marginLeft: 4,
  }
});
