import React, {useCallback, useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {map, prop} from "ramda";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Button, Typography,
} from "@mui/material";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import {Input} from "@core/components/Form";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import ColumnsSelect from "@core/components/ColumnsSelect";
import ProductForm from "./components/ProductForm";
import {columns as getColumns} from "./columns";
import useStores from "../useStores";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const ProductList = observer(({match}) => {
  const [productToDeleteId, setProductToDeleteId] = useState(null);
  const [createNewFormOpen, setCreateNewFormOpen] = useState(false);
  const savedSelectedColumns = JSON.parse(localStorage.getItem(match.path));

  const columns = getColumns(setProductToDeleteId);

  const [selectedColumns, setSelectedColumns] = useState(savedSelectedColumns || map(prop("dataIndex"), columns));

  const {ProductStore} = useStores();
  const {status, data: products, total, fetch} = ProductStore.products;

  useEffect(() => {
    return () => ProductStore.products.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => ProductStore,
    dataKey: STORE_DATA.products,
    matchPath: match.path,
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    ProductStore.products.load({limit, offset: 0});
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    ProductStore.products.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  return (
    <div className="padded-container">
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Products
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateNewFormOpen(true)}
          >
            Create new
          </Button>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs>
            <Input
              name="search"
              endAdornment={
                <InputAdornment position="start">
                  {status.loading && <CircularProgress size={20} />}
                </InputAdornment>
              }
              placeholder={"Start typing to search"}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item>
            <ColumnsSelect
              columns={columns}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table
            selectedColumns={selectedColumns}
            items={products}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => ProductStore.products.load({sort})}
            saveSortToQueryParams
            total={total}
            noDataText="No available products."
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={products}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              onOffsetChange={(offset) => ProductStore.products.load({offset})}
              onLimitChange={handleRowsPerPageChange}
            />
          </Table>
        </Grid>
      </Grid>
      <Confirmation
        open={productToDeleteId}
        onCancel={() => setProductToDeleteId(null)}
        onConfirm={() => {
          ProductStore.deleteById(productToDeleteId);
          setProductToDeleteId(null);
        }}
        alertText="Are you sure you want to remove the product?"
      />
      <ProductForm
        open={createNewFormOpen}
        close={() => setCreateNewFormOpen(false)}
      />
    </div>
  );
});

export default ProductList;
