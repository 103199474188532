export const ELEMENT = {
  etchingStandard: "",
  etchingSolution: "",
  position: "",
  orientation: "",
  specimenId: "",
  magnification: "",
  electrolytic: false,
  poresRange: "",
  porosityLevel: "",
  files: [],
  result: ""
};

export const TEST_STANDARDS = [
  "ISO 4505-1978",
  "ISO 4499-4:2016",
];

export const ACCEPTANCE_CRITERIA = {
  "A94609US-01-ITP": {
    company: ["Tremblay Inc."],
  },
};

export const PORES_RANGES = [
  "0 to 10",
  "10 to 25",
  "25 to 75",
  "75 to 125",
  "over 125"
];