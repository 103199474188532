export default (theme) => ({
  p40: {
    padding: theme.spacing(4),
  },

  mb25: {
    marginBottom: 25
  },

  activateAdnSpinner: {
    position: "relative",
    display: "flex"
  },

  spinner: {
    position: "absolute",
    right: -60,
    top: -15
  },

  addLocation: {
    height: "100%",
    "& div": {
      height: "100%",

      "& button": {
        flex: 1,
      }
    },

    minHeight: 230,
  },

  label: {
    fontSize: 14.5,
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: 10,
  },
  removeBlock: {
    marginTop: 40,
    "& div": {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  companyForm: {
    "& .padded-container": {
      padding: 0,
    }
  },
});

