import React, {useEffect} from "react";
import {Grid, MenuItem} from "@mui/material";
import {observer} from "mobx-react-lite";
import {map, prop} from "ramda";
import useStores from "../../../../useStores";
import SelectField from "@core/components/FormikSelect";
import ComparisonForm from "../ComparisonForm";
import {TEST_RESULTS} from "@core/constants/testResults";
import {OPERATORS} from "@core/constants/specifications";
import testConfig from "@core/configs/test";

const ALLOWED_VALUES = [TEST_RESULTS.ACCEPTABLE, TEST_RESULTS.NOT_ACCEPTABLE];

const TestResults = observer(({index, item, children, values}) => {
  const {SpecificationStore} = useStores();

  const usedFields = map(prop("type"), values.config.filter(({entity}) => item.entity === entity));

  useEffect(() => {
    const {data, isLoading} = SpecificationStore.testTypes;

    if (data.length || isLoading) return;

    SpecificationStore.getTestTypes();
  }, []);

  return (
    <>
      <Grid item xs={2}>
        <SelectField
          name={`config.${index}.type`}
          label='Test type'
          required
        >
          {SpecificationStore.testTypes.data.map(({name}) => {
            if (usedFields.includes(name) && item.type !== name) return;

            return (
              <MenuItem value={name}>{testConfig[name].title}</MenuItem>
            );
          })}
        </SelectField>
      </Grid>
      <ComparisonForm
        allowedValues={ALLOWED_VALUES}
        allowedOperators={[OPERATORS.CONTAINS, OPERATORS.EQUAL]}
        name={`config.${index}`}
        item={item}
      />
      {children}
    </>
  );
});

export default TestResults;