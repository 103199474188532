
export default (theme) => ({
  root: {
    position: "fixed",
    bottom: 100,
    right: -5,
    zIndex: 1000,
    color: "#F5F5F5",
    height:70,
    backgroundColor: "transparent",
    padding: "60px 0 60px 20px",
    borderRadius: "4px 0 0 4px",

    "& svg":{
      verticalAlign: "middle",
    },
    "&:hover":{
      backgroundColor: "transparent",
    }

  },
  rotateArrow:{
    transform: "rotateY(180deg)"
  },
  mainTest:{
    borderRadius: "4px 0 0 4px",
    backgroundColor: theme.palette.primary.main,
    height:70,
    display: "flex",
    alignItems: "center",

    "& svg":{
      margin:"auto 0"
    },

    "&:hover":{
      backgroundColor: "#006699"
    }
  },

  signature:{
    display: "inline-block",
    width:0,
    transition: "width 0.5s",
  },
  signatureHover:{
    display: "inline-block",
    width: 28 ,
    transition: "width 0.5s",
    marginRight:5
  }
});
