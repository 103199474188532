import {generateID} from "@core/helpers";
import {STRESS_AT_BREAK_ROW_DEFAULT} from "@core/constants/transverseRuptureStrength";

export const TEST_STANDARDS = ["ASTM B406-96 (2021)"];

export const TEST_METHODS = ["KMT B406 TRS (3-pt bend).msm"];

export const STRESS_AT_BREAK_DEFAULTS = [];

for (let i = 0; i < 10; i++) {
  STRESS_AT_BREAK_DEFAULTS.push({...STRESS_AT_BREAK_ROW_DEFAULT, uniqueId: generateID()});
}