import React, {useEffect, useMemo, memo, useCallback, useRef} from "react";
import {Group} from "react-konva";
import {observer} from "mobx-react-lite";
import qs from "qs";
import {useHistory, useLocation} from "react-router";
import {last, isNil, splitEvery, toLower} from "ramda";
import useStores from "../../../../../../../../../../useStores";
import Weld from "../Weld";
import Pipe from "../Pipe";
import WeldLine from "../WeldLine";
import Label from "../../../Label";
import {PIPE_CONFIG, WELD_CONFIG, STALK_NUMBER_WIDTH} from "../../constants";
import {PAGE_TABS} from "../../../../../../constants";
import {ENTITIES} from "../../../../../../../../constants";
import {PAGE_PADDING, SPACING_BETWEEN_STALKS, STAGE_CONFIG, STALK_NUMBER_CONFIG} from "../../../../constants";
import {getSortedStalkEntities} from "../../../../helpers";

const PIPES_IN_ROW = 25;

const Stalk = observer(({settings, scale, stalkIndex, setOpenEntityIndex, setEntitiesToView, stalk, stalks, tieInWelds, label}) => {
  const {CampaignStore} = useStores();

  const pipes = useRef({});
  const welds = useRef({});

  const history = useHistory();
  const location = useLocation();

  const stalkNumber = useMemo(() => !isNil(settings.stalkNumber) ? settings.stalkNumber : stalk[0].stalkNumber, [settings.stalkNumber, stalkIndex]);

  const stalkWithUniqWelds = useMemo(() => stalk.reduce((acc, weld) => {
    acc[weld.position] = weld;

    return acc;
  }, []), [stalkNumber]);

  const chunks = useMemo(() => splitEvery(PIPES_IN_ROW, stalkWithUniqWelds), [stalkNumber]);

  const stalkLength = useMemo(() => last(stalk).position + 1, [stalkNumber]);

  const tieInWeldsByPipeId = useMemo(() => {
    return tieInWelds.reduce((acc, weld) => {
      acc[weld.pipeA] = weld;
      acc[weld.pipeB] = weld;

      return acc;
    }, {});
  }, [tieInWelds.length]);
  
  const tieInWeldsNumber = useMemo(() => tieInWelds.map((weld) => weld.weldNumber), [tieInWelds.length]);

  const rowYBase = useMemo(() => {
    if(settings.welds) return PIPE_CONFIG.HEIGHT + STAGE_CONFIG.PADDING;

    return PIPE_CONFIG.HEIGHT + SPACING_BETWEEN_STALKS;
  }, [settings.welds]);

  const height = useMemo(() => rowYBase * chunks.length, [rowYBase, chunks.length]);

  const storedY = stalks.current[stalkIndex - 1];

  const y = useMemo(() => storedY || 0, [storedY]);

  const nextY = useMemo(() => y + height, [y, height]);
  stalks.current[stalkIndex] = nextY;

  const stalkNumberY = useMemo(() => {
    return PIPE_CONFIG.HEIGHT / 2 - STALK_NUMBER_CONFIG.FONT_SIZE / 2;
  }, [chunks.length]);

  const labelWidth = useMemo(() => STALK_NUMBER_WIDTH + PAGE_PADDING, []);

  const sortedStalkEntities = useMemo(() => getSortedStalkEntities(stalk), [stalk]);

  const {pipe, weld} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const totalLength = useMemo(() => {
    const lastWeld = last(sortedStalkEntities.welds);

    return CampaignStore.pipeLengthByWeldId[lastWeld._id];
  }, [stalkNumber]);

  const setPipeData = useCallback(() => {
    const data = sortedStalkEntities.pipes.find(({pipeNumber}) => pipeNumber === pipe);

    if(!data) return;

    setOpenEntityIndex(data.position);
    setEntitiesToView(sortedStalkEntities);
  }, [pipe]);

  const setWeldData = useCallback(() => {
    const index = sortedStalkEntities.welds.findIndex(({weldNumber}) => weldNumber === weld);

    if(index === -1) return;

    setOpenEntityIndex(index);
    setEntitiesToView(sortedStalkEntities);
  }, [weld]);

  const setTieInWeldData = useCallback(() => {
    const tieInWeld = tieInWelds.find(({weldNumber}) => weldNumber === weld);

    if(!tieInWeld) return;

    setOpenEntityIndex(0);
    setEntitiesToView({welds: [tieInWeld], pipes: []});
  }, [weld]);

  useEffect(() => {
    const {stalk} = qs.parse(location.search, {ignoreQueryPrefix: true});

    if(stalk !== stalkNumber) return;
    
    if(pipe) setPipeData();
    else if(tieInWeldsNumber.includes(weld)) setTieInWeldData();
    else setWeldData();
  }, [pipe, weld]);

  const setOpenPipe = useCallback((id) => {
    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        tab: toLower(PAGE_TABS.PIPELINE),
        stalk: stalkNumber,
        [ENTITIES.PIPE]: id,
      }),
    });
  }, [stalkNumber]);

  const setOpenWeld = useCallback((id) => {
    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        tab: toLower(PAGE_TABS.PIPELINE),
        stalk: stalkNumber,
        [ENTITIES.WELD]: id
      }),
    });
  }, [stalkNumber]);

  return (
    <Group x={0} y={y}>
      {chunks.map((elements, chunkIndex) => (
        <Group key={stalkNumber + chunkIndex}>
          {!chunkIndex && (
            <Label
              label={`${label}: ${stalkNumber}`}
              y={rowYBase * chunkIndex + stalkNumberY}
            />
          )}
          {elements.map((element) => {
            const weldIndex = element.position;
            const weldIndexInChunkIndex = weldIndex % PIPES_IN_ROW;

            const chunkIndex = Math.floor(weldIndex / PIPES_IN_ROW);

            const isLastInChunk = weldIndexInChunkIndex === elements.length - 1;
            const isLastInStalk = weldIndex === stalkLength - 1;

            return (
              <Group
                x={labelWidth}
                y={rowYBase * chunkIndex}
                key={element._id}
              >
                <Pipe
                  id={element.jointNumberA}
                  pipe={element.pipeA}
                  pipeIndexInChunk={weldIndexInChunkIndex}
                  pipeIndex={weldIndex}
                  settings={settings}
                  onClick={() => setOpenPipe(element.jointNumberA)}
                  scale={scale}
                  pipes={pipes}
                  weld={element}
                  stalkNumber={stalkNumber}
                  totalLength={totalLength}
                  tieInWeld={tieInWeldsByPipeId[element.pipeA]}
                  setOpenWeld={setOpenWeld}
                />
                {(isLastInChunk && !isLastInStalk) ? (
                  <WeldLine
                    scale={scale}
                    settings={settings}
                    id={element.weldNumber}
                    onClick={() => setOpenWeld(element.weldNumber)}
                    x={(PIPE_CONFIG.WIDTH + WELD_CONFIG.WIDTH) * (elements.length - 1) + PIPE_CONFIG.WIDTH}
                  />
                ) : (
                  <Weld
                    onClick={() => setOpenWeld(element.weldNumber)}
                    id={element.weldNumber}
                    x={(PIPE_CONFIG.WIDTH + WELD_CONFIG.WIDTH) * weldIndexInChunkIndex + PIPE_CONFIG.WIDTH}
                    settings={settings}
                    scale={scale}
                    welds={welds}
                    index={weldIndex}
                  />
                )}
                {(!isLastInChunk || chunkIndex === chunks.length - 1) && (
                  <Pipe
                    id={element.jointNumberB}
                    pipe={element.pipeB}
                    weldId={element.weldNumber}
                    pipeIndexInChunk={weldIndexInChunkIndex + 1}
                    pipeIndex={weldIndex + 1}
                    settings={settings}
                    onClick={() => setOpenPipe(element.jointNumberB)}
                    scale={scale}
                    pipes={pipes}
                    weld={element}
                    stalkNumber={stalkNumber}
                    totalLength={totalLength}
                    tieInWeld={tieInWeldsByPipeId[element.pipeB]}
                    setOpenWeld={setOpenWeld}
                  />
                )}
              </Group>
            );
          })}
        </Group>
      ))}
    </Group>
  );
});

export default memo(Stalk);