import {deepOrange, deepPurple, green, blue} from "@mui/material/colors";

export default (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  disabledLink: {
    cursor: "default",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  avatarAndName: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "start",
    textTransform: "none",
    padding: 0,
    "& span": {
      display: "flex"
    },
    "& div": {
      width: 20,
      height: 20,
      minWidth: 20,
      fontSize: "0.7rem",
    }
  },
  avatar: {
    marginRight: 10,
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500],
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500],
  },
  greenAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: green[500],
  },
  blueAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor:  blue[500],
  },
  TextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    marginLeft: 20,
    marginRight: "20px",
  },
  colsmall: {
    width: "20px",
    paddingRight: "10px"
  },
  notActivated: {
    backgroundColor: "#ffe6e6"
  },
  disabled: {
    backgroundColor: "#c8d0d5"
  },
  loaderContainer: {
    padding: 3,
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
});
