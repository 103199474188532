import {useEffect, useState} from "react";
import axios from "axios";
import {indexBy, prop} from "ramda";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const cache = {
  data: [],
  isLoading: false,
};

export const useFetchedCertificateNorms = (norm) => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    cache.isLoading = true;

    if (cache.data.length) {
      setData(cache.data);
      cache.isLoading = false;

      return;
    }

    const response = await axios.get(ROUTES.TEST_NORM[ACTIONS.ALL_BY_QUERY]("certificate"));

    cache.data = response.data;
    setData(cache.data);
    cache.isLoading = false;
  };

  useEffect(() => {
    if(cache.isLoading) return;

    fetchData();
  }, []);

  const norms = norm ? data.filter((n) => n.Norm === norm) : data;
  const normsByMaterial = indexBy(prop("Material"), norms) || {};

  return {norms, normsByMaterial};
};
