import {TEST_UNITS} from "@core/constants/test";

export const LOCATIONS = {
  body: "Body",
  leftEnd: "End A",
  rightEnd: "End B",
};

export const MEASUREMENTS_CONFIG = {
  externalDiameter: {
    title: "External diameter",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
  },
  internalDiameter: {
    title: "Internal diameter",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
    multiple: true,
  },
  thickness: {
    title: "Thickness",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
  },
  outOfRoundness: {
    title: "External diameter out of roundness",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
    multiple: true,
  },
  outOfRoundnessInternal: {
    title: "Internal diameter out of roundness",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
  },
  straightness: {
    title: "Straightness",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
  },
  eccentricity: {
    title: "Eccentricity",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["leftEnd", "rightEnd"],
  },
  squareness: {
    title: "Squareness",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["leftEnd", "rightEnd"],
  },
  weldFLash: {
    title: "Weld flash height (inside)",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
  },
  weldFlashOutside: {
    title: "Weld flash height (outside)",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
  },
  length: {
    title: "Length",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
  },
  weight: {
    title: "Weight",
    units: {
      [TEST_UNITS.METRIC]: "kg",
      [TEST_UNITS.IMPERIAL]: "lbs",
    },
  },
  bevelAngle: {
    title: "Bevel angle",
    units: {
      [TEST_UNITS.METRIC]: "°",
      [TEST_UNITS.IMPERIAL]: "°",
    },
  },
  bevelRootFace: {
    title: "Bevel root face",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
  },
  depthOfGroove: {
    title: "Depth of groove",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
  },
  radialOffset: {
    title: "Radial offset",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
  },
  linearWeight: {
    title: "Linear weight",
    units: {
      [TEST_UNITS.METRIC]: "kg/m",
      [TEST_UNITS.IMPERIAL]: "lbs/in",
    },
  },
  peaking: {
    title: "Peaking",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["leftEnd", "rightEnd"],
  },
  geometricDeviation: {
    title: "Geometric deviation",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
  },
  heightOfWeldBead: {
    title: "Height of weld bead",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
  },
  misalignmentOfWeldBead: {
    title: "Misalignment of weld bead",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
  },
  thicknessOfLiner: {
    title: "Wall thickness of liner pipe",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
    multiple: true,
  },
  thicknessOfWeldOverlay: {
    title: "Wall thickness of weld overlay",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
    multiple: true,
  },
  totalThickness: {
    title: "Total wall thickness",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
    multiple: true,
  },
  carbonSteelThickness: {
    title: "Carbon steel wall thickness",
    units: {
      [TEST_UNITS.METRIC]: "mm",
      [TEST_UNITS.IMPERIAL]: "in",
    },
    locations: ["body", "leftEnd", "rightEnd"],
    multiple: true,
  },
};