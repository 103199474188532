import {TABS} from "../../constants";
import DashboardPage from "../DashboardPage";
import BaseMaterialsPage from "../BaseMaterialsPage";
import TestsAndAnalysesPage from "../TestsAndAnalysesPage";
import TraceabilityPage from "../TraceabilityPage";

export const COMPONENTS_BY_TAB = {
  [TABS.DASHBOARD]: DashboardPage,
  [TABS.BASE_MATERIALS]: BaseMaterialsPage,
  [TABS.TESTS]: TestsAndAnalysesPage,
  [TABS.TRACEABILITY]: TraceabilityPage
};