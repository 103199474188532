import React from "react";
import {Box, Typography} from "@mui/material";
import TestResultTag from "@core/components/TestResultTag";

const TestFormResult = ({isValid, result}) => {
  if(!isValid) {
    return (
      <Box sx={{color: (theme) => theme.palette.warning.text}}>
        <Typography variant="h6">
          Please complete mandatory fields or correct invalid data.
        </Typography>
      </Box>
    );
  }
  
  if(result) {
    return (
      <TestResultTag
        result={result}
      />
    ); 
  }
  
  return null;
};

export default TestFormResult;