import {Box} from "@mui/material";
import React from "react";
import {keys} from "ramda";
import AcceptancesContent from "./components/AcceptancesContent";

const ImpactTest = ({qcpConfig}) => {
  const data = qcpConfig.temperature.data;

  if(data.poItem) {
    return keys(data.poItem).map((poItem) => (
      <Box key={poItem} sx={{marginTop: 1}}>
        <h3>QCP Item {poItem}</h3>
        <AcceptancesContent
          config={data.poItem[poItem]}
        />
      </Box>
    ));
  }

  if(data.dependantValues) {
    return keys(data.dependantValues).map((height) => (
      <Box key={height} sx={{marginTop: 1}}>
        <h3>Specimen {height}</h3>
        <AcceptancesContent
          config={data.dependantValues[height]}
        />
      </Box>
    ));
  }

  return (
    <AcceptancesContent
      config={data}
    />
  );
};

export default ImpactTest;