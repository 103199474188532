import React from "react";
import {Dialog, DialogContent, DialogActions, DialogTitle, Button} from "@mui/material";
import useStores from "../../../useStores";
import {observer} from "mobx-react-lite";
import {CertificateService} from "@core/services";

const SignCertificateModal = observer(({certificates}) => {
  const {CertificateStore} = useStores();

  return (
    <Dialog
      open={CertificateStore.isForceSignCertificate}
      onClose={() => CertificateStore.closeForceSignCertificate()}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogContent>
        <DialogTitle>{
          certificates.length > 1 ?
            "Some of selected certificates are not signed yet! Please sign them to perform further actions" :
            "The certificate is not signed yet! Please sign it to perform further actions"
        }</DialogTitle>
        <DialogActions>
          <Button
            size={"large"}
            color={"primary"}
            variant={"contained"}
            onClick={() => CertificateStore.closeForceSignCertificate()}
          >
            Cancel
          </Button>
          <Button
            size={"large"}
            color={"primary"}
            variant={"contained"}
            onClick={() => CertificateService.sign(certificates)}
          >
            Sign
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
});

export default SignCertificateModal;
