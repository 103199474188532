import {OutlinedInput, Grid, Tooltip} from "@mui/material";
import classNames from "classnames";
import React from "react";
import ErrorIcon from "@mui/icons-material/Error";

const EditableCell = ({classes, editable,disabled, value, required,id,placeholder,error, touched, onChange,onBlur}) => {
  if(!editable) {
    return (
      <Grid container justifyContent="space-between">
        <Grid item>{value}</Grid>
        {error && (
          <Tooltip title={error}>
            <ErrorIcon
              sx={{cursor: "pointer"}}
              color="error"
            />
          </Tooltip>
        )}
      </Grid>
    );
  }

  return (
    <OutlinedInput
      id={id}
      classes={{
        input: classes.input,
        root: classNames({
          [classes.inputRoot]: true,
          [classes.requiredInput]: required,
        })
      }}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      margin="none"
      value={value}
      error={error && touched}
      onChange={onChange}
      onBlur={onBlur}
      name={id}
    />
  );
};

export default EditableCell;