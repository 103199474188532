import axios from "axios";
import retry from "async-retry";
import {AuthService} from "@core/services";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const TOKEN_EXPIRED_CODE = 401;

const ERROR_MESSAGE_URL = "/error";

const accessToken = localStorage.getItem("accessToken");

if (accessToken) axios.defaults.headers.common["X-Token-Jwt"] = accessToken;

let refreshing;

axios.interceptors.request.use(async (config) => {
  const expiresIn = localStorage.getItem("expiresIn");
  const nowDate = parseInt(new Date().getTime() / 1000, 10);

  if (expiresIn && nowDate + 4 /* margin in seconds*/ > expiresIn) {
    await retriableRefresh(config);
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (err) => {
    const status = err.response ? err.response.status : null;
    const config = err.response ? err.response.config : {};

    const {refreshToken} = getTokens();

    if (status === TOKEN_EXPIRED_CODE && refreshToken) {
      await retriableRefresh(config);

      if (config) {
        if (+(config.headers["X-Attempt"] || 0) === 5) {
          if(window.location.pathname !== ERROR_MESSAGE_URL) {
            window.location.replace(
              `${ERROR_MESSAGE_URL}?message=` + encodeURI("Sorry, you are not authorized to perform this action")
            );
          }

          removeTokens();

          return err.response;
        }

        config.headers["X-Attempt"] = (config.headers["X-Attempt"] || 0) + 1;
      }

      return axios.request(config);
    }

    return Promise.reject(err);
  });

const getTokens = () => ({
  expiresIn: localStorage.getItem("expiresIn"),
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken")
});

const setTokens = (accessToken, refreshToken, expiresIn) => {
  localStorage.setItem("expiresIn", expiresIn);
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

const removeTokens = () => {
  localStorage.removeItem("expiresIn");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

const retriableRefresh = (config) => retry(
  async (bail, nAttempt) => {
    if (refreshing) {
      // retry
      throw new Error("Token is refreshing");
    }

    if (!refreshing && nAttempt > 1) {
      // already refreshed
      const {accessToken} = getTokens();
      config.headers["X-Token-Jwt"] = accessToken;
      axios.defaults.headers.common["X-Token-Jwt"] = accessToken;

      return;
    }

    try {
      const tokens = await refreshToken();
      // eslint-disable-next-line no-param-reassign
      config.headers["X-Token-Jwt"] = tokens.accessToken;
      axios.defaults.headers.common["X-Token-Jwt"] = tokens.accessToken;
    } catch (err) {
      AuthService.logout();

      return window.location.replace("/login");
    }
  },
  {
    retries: 5
  }
);

const refreshToken = () => {
  // eslint-disable-next-line no-shadow
  const accessToken = localStorage.getItem("accessToken");
  // eslint-disable-next-line no-shadow
  const refreshToken = localStorage.getItem("refreshToken");

  refreshing = refreshToken;

  return fetch(ROUTES.ACCOUNT[ACTIONS.REFRESH_TOKEN], {
    method: "POST",
    credentials: "include",
    headers: {
      "Accept": "application/json",
      "X-Token-Jwt": accessToken,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({refreshToken})
  })
    .then((response) => response.status === 200 ? response.json() : Promise.reject())
    .then((json) => {
      // eslint-disable-next-line no-shadow
      const {accessToken, refreshToken, expiresIn} = json;
      setTokens(accessToken, refreshToken, expiresIn);

      return Promise.resolve({accessToken, refreshToken, expiresIn});
    })
    .catch((error) => {
      return Promise.reject(new Error(error));
    })
    .finally(() => refreshing = null);
};

