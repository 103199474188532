import {useEffect} from "react";
import axios from "axios";
import {prop, indexBy} from "ramda";
import {formatOptions} from "@core/components/NormAutocomplete";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const useFetchTestNorms = (testName, norm, setMaterialData) => {
  const loadOptions = async () => {
    const response = await axios.get(ROUTES.TEST_NORM[ACTIONS.ALL_BY_QUERY](testName));

    const preloadNorm = formatOptions(response.data).find((nrm) => nrm.label === norm)?.value;
    const preloadGrades = preloadNorm && indexBy(prop("Material"), preloadNorm.value);

    setMaterialData(preloadGrades || {});
  };

  useEffect(() => {
    if(!norm) return;

    loadOptions();
  }, []);
};

export default useFetchTestNorms;