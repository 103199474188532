import React from "react";
import {Grid, Button, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Input, Select} from "@core/components/Form";
import {Formik} from "formik";
import {path} from "ramda";
import * as yup from "yup";
import ProducerTestFooter from "../../ProducerTestFooter";
import {
  acceptances, filmTypes, iqiTypes, norms,
  positions, sources, xRayApparatuses
} from "../../TestForms/RadioGraphic/data";
import transferValues from "../../TestForms/RadioGraphic/uploadHelper";
import styles from "./styles";

const RadioGraphicReport = ({classes, test, ...props}) => {

  const predefinedNorm = norms.find((el) => el.value === path(["properties", "norm"], test)) || {value: ""};
  const predefinedAcceptance = acceptances.find((el) => el.value === path(["properties", "acceptance"], test)) || {value: ""};
  const predefinedSource = sources.find((el) => el.value === path(["properties", "source"], test)) || {value: ""};
  const predefinedPosition = positions.find((el) => el.value === path(["properties", "position"], test)) || {value: ""};
  const predefinedIqiType = iqiTypes.find((el) => el.value === path(["properties", "iqiType"], test)) || {value: ""};
  const predefinedFilmType = filmTypes.find((el) => el.value === path(["properties", "filmType"], test)) || {value: ""};
  const predefinedXRayApparatus = xRayApparatuses.find((el) => el.value === path(["properties", "xRayApparatus"], test)) || {value: ""};

  const validationSchema = yup.object().shape({
    workStage: yup.string().required("Work Stage is required!"),
    norm: yup.object().required("Norm is required"),
    acceptance: yup.object().required("Acceptance criteria is required"),
    radioscopic: yup.string().required("Work Stage is required!"),
    source: yup.object().required("Source is required"),
    screen: yup.string().required("Screen is required!"),
    thicknessSourceSide: yup.number().positive("Should be > 0").typeError("TSS should be a number").required("Thickness Source Side is required!"),
    thicknessFilmSide: yup.number().positive("Should be > 0").typeError("TFS should be a number").required("Thickness Film Side is required!"),
    sourceDistance: yup.number().positive("Should be > 0").typeError("MA should be a number").required("Source Distance is required!"),
    position: yup.object().required("Position is required"),
    density: yup.string().required("Density is required!"),
    sourceSize: yup.number("Should be > 0").positive().typeError("MA should be a number").required("Source Size is required!"),
    iqiType: yup.object().required("I.Q.I. is required"),
    filmType: yup.object().required("Film type is required"),
    xRayApparatus: yup.object().required("X-ray is required"),
    tps: yup.string().required("TPS is required!"),
    kv: yup.string().required("KV is required!"),
    ma: yup.number().typeError("MA should be a number").required("MA is required!"),
    expTime: yup.number().positive("Invalid time").typeError("MA should be a number").required("Time is required"),
    description: yup.string(),
    salesOrder: yup.string(),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
  });

  const initialValues = {
    norm: predefinedNorm,
    acceptance: predefinedAcceptance,
    workStage: test.properties.workStage || "",
    radioscopic: test.properties.radioscopic || "",
    source: predefinedSource,
    screen: test.properties.screen || "",
    thicknessSourceSide: test.properties.thicknessSourceSide || "",
    thicknessFilmSide: test.properties.thicknessFilmSide || "",
    sourceDistance: test.properties.sourceDistance || "",
    position: predefinedPosition,
    density: test.properties.density || "",
    sourceSize: test.properties.sourceSize || "",
    iqiType: predefinedIqiType,
    filmType: predefinedFilmType,
    xRayApparatus: predefinedXRayApparatus,
    tps: test.properties.tps || "",
    kv: test.properties.kv || "",
    ma: test.properties.ma || "",
    expTime: test.properties.expTime || "",
    sections: test.properties.sections || {},
    description: test.properties.description || "",
    salesOrderNumber: test.properties.salesOrderNumber || "",
    witnesses: test.witnesses.map((witness) => witness.company),
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || []
  };

  const onSubmit = (values, actions) => {
    const data = {
      norm: values.norm.value,
      acceptance: values.acceptance.value,
      workStage: values.workStage,
      radioscopic: values.radioscopic,
      source: values.source.value,
      screen: values.screen,
      thicknessSourceSide: values.thicknessSourceSide,
      thicknessFilmSide: values.thicknessFilmSide,
      sourceDistance: values.sourceDistance,
      position: values.position.value,
      density: values.density,
      sourceSize: values.sourceSize,
      iqiType: values.iqiType.value,
      filmType: values.filmType.value,
      xRayApparatus: values.xRayApparatus.value,
      tps: values.tps,
      kv: values.kv,
      ma: values.ma,
      expTime: values.expTime,
      sections: values.sections,
      salesOrderNumber: values.salesOrderNumber,
      description: values.description,
      inspectionDate: values.inspectionDate,
      inspectorJobNumber: values.inspectorJobNumber,
      tags: values.tags,
    };

    props.saveTest(data, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    if (props.closeNewTest) {
      props.closeNewTest();
    }
  };

  return (
    <div>
      <Formik
        innerRef={props.formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        dirty
        onSubmit={onSubmit}

        render={(props) => {
          const {
            values: {
              salesOrderNumber, norm, acceptance, workStage, radioscopic, source, screen, thicknessSourceSide,
              thicknessFilmSide, sourceDistance, position, density, sourceSize, iqiType, filmType, xRayApparatus,
              tps, kv, ma, expTime, description,
            },
            touched,
            errors,
            handleChange,
            setFieldTouched,
            setFieldValue
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          return (
            <>
              <Grid container spacing={5} justifyContent="space-between" marginBottom={4}>
                <Grid item xs={6}>
                  <h1>Radiographic Report</h1>
                </Grid>
                <Grid item xs={3} className={classes.uploadResults}>
                  <input
                    accept=".json"
                    id={"raised-button-file"}
                    type="file"
                    onChange={(e) => transferValues(e, setFieldValue, setFieldTouched)}
                  />
                  <label htmlFor={"raised-button-file"}>
                    <Button variant={"contained"} size={"medium"} component='span' color="primary">
                      Upload Results
                    </Button>
                  </label>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Input
                    name="salesOrderNumber"
                    label='Sales order №'
                    value={salesOrderNumber}
                    error={Boolean(errors.salesOrderNumber) && touched.salesOrderNumber}
                    errorMessage={errors.salesOrderNumber}
                    onChange={(event) => change("salesOrderNumber", event)}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={3}>
                  <Select
                    value={norm}
                    name='norm'
                    label='Norm'
                    required
                    error={Boolean(errors.norm) && touched.norm}
                    errorMessage={errors.norm}
                    onChange={(event) => change("norm", event)}
                  >
                    {norms.map((E, idx) => <MenuItem key={idx} value={E}>{E.value}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={acceptance}
                    name='acceptance'
                    label='Acceptance'
                    required
                    error={Boolean(errors.acceptance) && touched.acceptance}
                    errorMessage={errors.acceptance}
                    onChange={(event) => change("acceptance", event)}
                  >
                    {acceptances.map((E, idx) => <MenuItem key={idx} value={E}>{E.value}</MenuItem>)}
                  </Select>
                </Grid>
              </Grid>
              <Grid container className={classes.gridRoot} spacing={2}>
                <h2>Test conditions</h2>
              </Grid>
              <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={3}>
                  <Input
                    label='Work Stage'
                    name='workStage'
                    value={workStage}
                    required
                    error={Boolean(errors.workStage) && touched.workStage}
                    errorMessage={errors.workStage}
                    onChange={(e) => change("workStage", e)} />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label='Radioscopic Tec.'
                    name='radioscopic'
                    value={radioscopic}
                    required
                    error={Boolean(errors.radioscopic) && touched.radioscopic}
                    errorMessage={errors.radioscopic}
                    onChange={(e) => change("radioscopic", e)} />
                </Grid>
              </Grid>
              <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={3}>
                  <Select
                    value={source}
                    name='source'
                    label='Source'
                    required
                    error={Boolean(errors.source) && touched.source}
                    errorMessage={errors.acceptance}
                    onChange={(event) => change("source", event)}
                  >
                    {sources.map((E, idx) => <MenuItem key={idx} value={E}>{E.value}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label='Screen'
                    name='screen'
                    value={screen}
                    required
                    error={Boolean(errors.screen) && touched.screen}
                    errorMessage={errors.screen}
                    onChange={(e) => change("screen", e)} />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label='Thickness source side'
                    name='thicknessSourceSide'
                    value={thicknessSourceSide}
                    type='number'
                    required
                    error={Boolean(errors.thicknessSourceSide) && touched.thicknessSourceSide}
                    errorMessage={errors.thicknessSourceSide}
                    endAdornment='MM'
                    onChange={(e) => change("thicknessSourceSide", e)} />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label='Thickness film side'
                    name='thicknessFilmSide'
                    value={thicknessFilmSide}
                    type='number'
                    required
                    error={Boolean(errors.thicknessFilmSide) && touched.thicknessFilmSide}
                    errorMessage={errors.thicknessFilmSide}
                    endAdornment='MM'
                    onChange={(e) => change("thicknessFilmSide", e)} />
                </Grid>
              </Grid>
              <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={3}>
                  <Select
                    value={position}
                    name='position'
                    label='Position'
                    required
                    error={Boolean(errors.position) && touched.position}
                    errorMessage={errors.position}
                    onChange={(event) => change("position", event)}
                  >
                    {positions.map((E, idx) => <MenuItem key={idx} value={E}>{E.value}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label='Density'
                    name='density'
                    value={density}
                    required
                    error={Boolean(errors.density) && touched.density}
                    errorMessage={errors.density}
                    onChange={(e) => change("density", e)} />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label='Source distance'
                    name='sourceDistance'
                    value={sourceDistance}
                    type='number'
                    required
                    error={Boolean(errors.sourceDistance) && touched.sourceDistance}
                    errorMessage={errors.sourceDistance}
                    endAdornment='MM'
                    onChange={(e) => change("sourceDistance", e)} />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label='Source Size'
                    name='sourceSize'
                    value={sourceSize}
                    type='number'
                    required
                    error={Boolean(errors.sourceSize) && touched.sourceSize}
                    errorMessage={errors.sourceSize}
                    endAdornment='MM'
                    onChange={(e) => change("sourceSize", e)} />
                </Grid>
              </Grid>
              <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={2}>
                  <Select
                    value={iqiType}
                    name='iqiType'
                    label='I.Q.I. Type'
                    required
                    error={Boolean(errors.iqiType) && touched.iqiType}
                    errorMessage={errors.iqiType}
                    onChange={(event) => change("iqiType", event)}
                  >
                    {iqiTypes.map((E, idx) => <MenuItem key={idx} value={E}>{E.value}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Select
                    value={filmType}
                    name='filmType'
                    label='Film Type'
                    required
                    error={Boolean(errors.filmType) && touched.filmType}
                    errorMessage={errors.filmType}
                    onChange={(event) => change("filmType", event)}
                  >
                    {filmTypes.map((E, idx) => <MenuItem key={idx} value={E}>{E.value}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Select
                    value={xRayApparatus}
                    name='xRayApparatus'
                    label='X-ray Apparatus'
                    required
                    error={Boolean(errors.xRayApparatus) && touched.xRayApparatus}
                    errorMessage={errors.xRayApparatus}
                    onChange={(event) => change("xRayApparatus", event)}
                  >
                    {xRayApparatuses.map((E, idx) => <MenuItem key={idx} value={E}>{E.value}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={8}>
                  <Input
                    name="description"
                    label="Description"
                    value={description}
                    multiline
                    rows={6}
                    error={Boolean(errors.description) && touched.description}
                    errorMessage={errors.description}
                    onChange={(e) => change("description", e)}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.gridRoot} spacing={2}>
                <h2>Test results</h2>
              </Grid>
              <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={2}>
                  <Select
                    value={tps}
                    name='tps'
                    label='TPS'
                    error={Boolean(errors.tps) && touched.tps}
                    required
                    onChange={(event) => change("tps", event)}
                  >
                    {["TT"].map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Input
                    label='KV'
                    name='kv'
                    value={kv}
                    error={Boolean(errors.kv) && touched.kv}
                    errorMessage={errors.kv}
                    required
                    onChange={(e) => change("kv", e)} />
                </Grid>
                <Grid item xs={2}>
                  <Select
                    value={ma}
                    name='ma'
                    label='MA'
                    required
                    error={Boolean(errors.ma) && touched.ma}
                    onChange={(event) => change("ma", event)}
                  >
                    {[2, 4, 5, 8].map((E, idx) => <MenuItem key={idx} value={E}>{E}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Input
                    label='Exp. Time'
                    name='expTime'
                    required
                    type='number'
                    value={expTime}
                    error={Boolean(errors.expTime) && touched.expTime}
                    errorMessage={errors.expTime}
                    endAdornment='Sec'
                    onChange={(e) => change("expTime", e)} />
                </Grid>
              </Grid>
              <ProducerTestFooter />
            </>
          );
        }}
      />
    </div>
  );
};

export default withStyles(RadioGraphicReport, styles);
