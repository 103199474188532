import React, {memo, useMemo} from "react";
import {Text} from "react-konva";
import {STALK_NUMBER_CONFIG, STAGE_CONFIG} from "../../constants";

const Label = ({label, y, width}) => {
  const labelUppercased = useMemo(() => label.toUpperCase(), [label]);

  return (
    <Text
      listening={false}
      perfectDrawEnabled={false}
      x={0}
      y={y}
      fontSize={STALK_NUMBER_CONFIG.FONT_SIZE}
      text={labelUppercased}
      fontFamily={STAGE_CONFIG.FONT_FAMILY}
      fill={STALK_NUMBER_CONFIG.TEXT_COLOR}
      fontStyle="bold"
      align="right"
      width={width}
    />
  );
};

export default memo(Label);