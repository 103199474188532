import {withStyles} from "tss-react/mui";
import Autocomplete, {autocompleteClasses} from "@mui/material/Autocomplete";

export const StyledAutocomplete = withStyles(Autocomplete, () => ({
  root: {
    [`&.${autocompleteClasses.hasPopupIcon} .${autocompleteClasses.inputRoot}, .${autocompleteClasses.hasClearIcon}.${autocompleteClasses.root} .${autocompleteClasses.inputRoot}`]: {
      paddingRight: 0
    },
    [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon} .${autocompleteClasses.inputRoot}`]: {
      paddingRight: 0
    }
  },
  endAdornment: {
    marginRight: 5,
  }
}));
