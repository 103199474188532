import React from "react";
import {Grid, IconButton, TableCell, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import CheckIcon from "@mui/icons-material/Check";
import {MdEditNote} from "react-icons/md";
import {IoMdCloseCircleOutline} from "react-icons/io";
import EditableCell from "../EditableCell";
import {isEmpty} from "ramda";

export default withStyles(
  (props) => {
    const {values, index, actions, editable, classes, disabled} = props;
    const {city, street, house, postalCode, phone, country, plantDescription} = values;

    const errors = props.errors[index] || {};
    const touched = props.touched[index] || {};

    return (
      <TableRow>
        <TableCell>
          <EditableCell
            disabled={disabled}
            placeholder="Country"
            id={country.path}
            value={country.value}
            editable={editable}
            classes={classes}
            required = {true}
            error={errors.country}
            touched={touched.country}
            onChange={(e) => actions.change(country.path, e)}
            onBlur={() => actions.blur(country.path, "country")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            disabled={disabled}
            placeholder={"City"}
            id={city.path}
            value={city.value}
            editable={editable}
            classes={classes}
            required = {true}
            error={errors.city}
            touched={touched.city}
            onChange={(e) => actions.change(city.path, e)}
            onBlur={() => actions.blur(city.path, "city")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            id={street.path}
            disabled={disabled}
            placeholder={"Street"}
            classes={classes}
            value={street.value}
            required = {true}
            editable={editable}
            error={errors.street}
            touched={touched.street}
            onChange={(e) => actions.change(street.path, e)}
            onBlur={() => actions.blur(street.path, "street")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            id={house.path}
            disabled={disabled}
            placeholder={"house №"}
            classes={classes}
            value={house.value}
            required = {true}
            editable={editable}
            error={errors.house}
            touched={touched.house}
            onChange={(e) => actions.change(house.path, e)}
            onBlur={() => actions.blur(house.path, "house")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            id={postalCode.path}
            disabled={disabled}
            placeholder={"Postal Code"}
            classes={classes}
            value={postalCode.value}
            editable={editable}
            error={errors.postalCode}
            touched={touched.postalCode}
            onChange={(e) => actions.change(postalCode.path, e)}
            onBlur={() => actions.blur(postalCode.path, "postalCode")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            id={phone.path}
            disabled={disabled}
            placeholder={"Phone"}
            classes={classes}
            value={phone.value}
            required = {false}
            editable={editable}
            error={errors.phone}
            touched={touched.phone}
            onChange={(e) => actions.change(phone.path, e)}
            onBlur={() => actions.blur(phone.path, "phone")}
          />
        </TableCell>
        <TableCell>
          <EditableCell
            disabled={disabled}
            placeholder="Plant description"
            id={plantDescription.path}
            value={plantDescription.value}
            editable={editable}
            classes={classes}
            error={errors.plantDescription}
            touched={touched.plantDescription}
            onChange={(e) => actions.change(plantDescription.path, e)}
            onBlur={() => actions.blur(plantDescription.path, "plantDescription")}
          />
        </TableCell>
        <TableCell width="130" className={classes.actionCell}>
          <Grid container justifyContent='start' spacing={1}>
            <Grid item>
              <IconButton
                onClick={() => actions.save()}
                disabled={(editable && !isEmpty(errors)) || disabled}
                size="large">
                {editable?(<CheckIcon/>):(<MdEditNote/>)}
              </IconButton>
            </Grid>
            {actions.remove &&<Grid item>
              <IconButton
                className={classes.removeButton}
                onClick={() => actions.remove()}
                size="large">
                <IoMdCloseCircleOutline />
              </IconButton>
            </Grid>}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }, styles);
