import {head, last, uniq, flatten} from "ramda";
import moment from "moment";
import {CUSTOM_TEST_TYPES, TYPES} from "@core/constants/test";
import {CAMPAIGN_SPECIMEN_LABELS} from "@core/constants/campaign";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {sortStrings} from "@core/helpers";
import {ORDERS} from "@core/constants/sort";

const TestTitleNotes = ({groupedTests}) => {
  const [test] = groupedTests;

  if(test.type !== TYPES.CUSTOM) return null;

  if(test.displayName === CUSTOM_TEST_TYPES.AS_BUILD_RECORD) {
    const specimens = groupedTests.map((test) => test.properties.activities.find((activity) => activity.description === "Stalk Number") || {});

    const stalks = sortStrings(ORDERS.ASC, flatten(specimens.map((specimen) => specimen.results ? specimen.results.values.map(({value}) => value) : [])));

    return `(Stalk ${stalks.join(", ")})`;
  }

  if(test.specimenLabel === CAMPAIGN_SPECIMEN_LABELS.WELD) {
    const specimens = flatten(groupedTests.map((test) => test.properties.activities.map((activity) => activity.specimen)));
    const orderedSpecimens = sortStrings(ORDERS.ASC, specimens);

    const min = head(orderedSpecimens);
    const max = last(orderedSpecimens);

    return `(${uniq([min, max]).join("/")})`;
  } else {
    const dates = flatten(groupedTests.map((test) => test.properties.activities.map((activity) => moment(activity.date).format(DATE_FORMAT))));
    const orderedDates = sortStrings(ORDERS.ASC, dates);

    const min = head(orderedDates);
    const max = last(orderedDates);

    return `(${uniq([min, max]).join("/")})`;
  }
};

export default TestTitleNotes;