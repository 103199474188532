import {keyframes} from "tss-react";
export default (theme) => ({
  value: {
    opacity: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1em",
    position: "absolute",
    top: theme.spacing(-1),
    fontWeight: theme.typography.fontWeightMedium,
    left: 0,
    width: "100%",
    height: "100%",
    animation: `${keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `} 2.0s forwards`,
    animationDelay: ".7s",
    textAlign: "center",
    fontSize: ".75em",
  }
});