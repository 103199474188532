export default () => ({
  inspectionDocumentLink: {
    textDecoration: "none",
    color: "inherit"
  },
  inspectionDocumentTitle: {
    paddingRight: "10px"
  },
  table: {
    marginTop: 10,
    background: "#fff"
  },
  tags: {
    "& div [role=combobox]": {
      padding: 1,
    }
  }
});
