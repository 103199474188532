import File from "@core/components/File";
import React from "react";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

const PorosityTest = ({test}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h3>Test Information:</h3>
      </Grid>
      <Grid item xs={12}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Laboratory</TableCell>
              <TableCell>Material specification</TableCell>
              <TableCell>Grade / UNS</TableCell>
              <TableCell>Test Standard</TableCell>
              <TableCell>Acceptance Criteria</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{test.company.name || test.properties.client}</TableCell>
              <TableCell>{test.assignee.name || test.properties.lab}</TableCell>
              <TableCell>{test.norm}</TableCell>
              <TableCell>{test.grade}</TableCell>
              <TableCell>{test.properties.testStandard || "-"}</TableCell>
              <TableCell>{test.properties.acceptance}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <h3>Test Result:</h3>
      </Grid>
      <Grid item xs={12}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell padding="none" width="70">Spec. ID</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Orientation</TableCell>
              <TableCell>Etching Standard</TableCell>
              <TableCell>Etching Solution</TableCell>
              <TableCell>Magnification</TableCell>
              <TableCell>Pores range</TableCell>
              <TableCell>Porosity level</TableCell>
              {test.properties.elements.some((element) => element.files?.length) && (
                <TableCell padding="none" width="70">File(s)</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {test.properties.elements.map((element) => (
              <TableRow key={element.specimenId}>
                <TableCell padding="none" width="70">{element.specimenId}</TableCell>
                <TableCell padding="none">{element.position || "-"}</TableCell>
                <TableCell padding="none">{element.orientation || "-"}</TableCell>
                <TableCell padding="none">{element.etchingStandard || "-"}</TableCell>
                <TableCell padding="none">
                  {element.etchingSolution ? (
                    `${element.etchingSolution}${element.electrolytic && " (Electrolytic)"}`
                  ) : (
                    element.electrolytic ? "Electrolytic" : "-"
                  )}
                </TableCell>
                <TableCell padding="none">{element.magnification}</TableCell>
                <TableCell>{element.poresRange || "-"}</TableCell>
                <TableCell>{element.porosityLevel}</TableCell>
                {!!element.files?.length && (
                  <TableCell padding="none" width="70">
                    <Grid container xs={12} spacing={5}>
                      {element.files.map((image) =>
                        <Grid item xs={4} container alignItems="center" key={image}>
                          <File file={image} />
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default PorosityTest;