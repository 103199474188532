import jStat from "jstat";
import {COLORS, PARAM_TYPES_BY_TEST_TYPE} from "./сonstants";

const DEFAULT_CORRECTION = 1;
export const getScale = (dataset, showAcceptanceCriteria, minmax) => {
  const min = jStat.min(dataset);
  const max = jStat.max(dataset);
  const stdev = jStat.stdev(dataset) || DEFAULT_CORRECTION;
  const {minY: acceptanceMin, maxY: acceptanceMax} = minmax ?? {};

  // Previous logic for calculating the scale correction
  // const correctionMin = min - min * 0.1;
  // const correctionMax = max + max * 0.1;

  const correctionMin = min - stdev;
  const correctionMax = max + stdev;

  let scaleMin = correctionMin;
  let scaleMax = correctionMax;

  if(acceptanceMin && showAcceptanceCriteria && correctionMin > acceptanceMin) {
    scaleMin = acceptanceMin;
  }

  if(acceptanceMax && showAcceptanceCriteria && correctionMax < acceptanceMax) {
    scaleMax = acceptanceMax;
  }

  return {min: scaleMin, max: scaleMax};
};

const getAcceptanceLineConfig = (value, scaleID) => {
  if(!value || value < 0) return undefined;

  return {
    scaleID,
    type: "line",
    value: value,
    borderColor: COLORS.red.backgroundColor,
    borderWidth: 2,
    label: {
      backgroundColor: COLORS.red.backgroundColor,
      content: value,
      display: true
    },
  };
};

export const getPlugins = (showAcceptanceCriteria = false, {x = {}, y = {}}) => {
  const plugins = {
    autocolors: false,
  };

  const acceptanceCriteriaPlugin = {
    annotation: {
      annotations: {
        maxX: getAcceptanceLineConfig(x.max, "x"),
        minX: getAcceptanceLineConfig(x.min, "x"),
        maxY: getAcceptanceLineConfig(y.max, "y"),
        minY: getAcceptanceLineConfig(y.min, "y"),
      }
    }
  };

  return showAcceptanceCriteria ? {...plugins, ...acceptanceCriteriaPlugin} : plugins;
};

export const getLabel = (units, testType, paramType, testConfig) => {
  const unitsText = units ? ` [${units}]` : "";
  const testParams = PARAM_TYPES_BY_TEST_TYPE[testType] || {};
  const yAxisParamsLabel = testParams[paramType] || `${testConfig.title} value`;

  return yAxisParamsLabel + unitsText;
};