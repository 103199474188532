import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const getTempUnit = (unit = "celsius") => `\u00b0${unit === "celsius" ? "C" : "F"}`;

const HeatTreatment = ({qcpConfig}) => {
  return (
    <>
      <h3>Local Requirements</h3>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Temperature</TableCell>
            <TableCell>Cooling medium</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{qcpConfig.properties.type === "Quench and Temper" ? "Quench" : qcpConfig.properties.type}</TableCell>
            <TableCell>{qcpConfig.properties.temp} {getTempUnit(qcpConfig.properties.tempUnits)}</TableCell>
            <TableCell>{qcpConfig.properties.coolingMedium}</TableCell>
          </TableRow>
          {qcpConfig.properties.type === "Quench and Temper" && (
            <TableRow>
              <TableCell>Temper</TableCell>
              <TableCell>{qcpConfig.properties.secondTemp} {getTempUnit(qcpConfig.properties.secondTempUnits)}</TableCell>
              <TableCell>{qcpConfig.properties.secondCoolingMedium}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default HeatTreatment;