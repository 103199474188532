export default () => ({
  outputContainer: {
    minWidth: "900px",
    height: "800px",
    border: "1px solid #575757",
    background: "white",
    overflow: "scroll",
  },
  outputContainer__textArea: {
    height: "100%",
    width: "100%",
    resize: "none",
    border: "none"
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center"
  },
});