import React, {memo} from "react";
import {Document, Page} from "react-pdf";

const PdfRenderer = ({className, scale, rotate, pages, instersectionObserver, pdfString, onDocumentLoadSuccess, numPages}) => (
  <Document
    noData={null}
    error={null}
    loading="Loading..."
    file={pdfString ? `data:application/pdf;base64,${pdfString}` : null}
    options={{workerSrc: "/pdf.worker.js"}}
    onLoadSuccess={onDocumentLoadSuccess}
  >
    {Array.from(new Array(numPages), (el, index) => (
      <Page
        className={className}
        inputRef={(page) => {
          if(!page) return;

          pages.current[index + 1] = page;
          page.page = index + 1;

          instersectionObserver.current.observe(page);
        }}
        key={`page_${index + 1}`}
        noData={null}
        error={null}
        loading={null}
        pageNumber={index + 1}
        rotate={rotate}
        scale={scale}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    ))}
  </Document>
);

export default memo(PdfRenderer);