import React, {memo, useMemo, useRef} from "react";
import {observer} from "mobx-react-lite";
import {Group} from "react-konva";
import Label from "../../../Label";
import Pipes from "./components/Pipes";
import {STALK_NUMBER_CONFIG} from "../../../../constants";
import {STALK_NUMBER_WIDTH} from "../../../Stalk/constants";
import {PIPE_CONFIG} from "../../constants";
import useStores from "../../../../../../../../../../useStores";

const PIPES_IN_ROW = 100;

const UnweldedPipesList = observer(({unweldedPipes, numberOfPipesInRow, animateShape, scale, setOpenEntityIndex, setEntitiesToView, settings, storedY = 0}) => {
  const {CampaignStore} = useStores();
  const pipesRef = useRef({});

  const campaign = CampaignStore.campaign;

  const splitBy = useMemo(() => {
    return campaign.welds.length && isFinite(numberOfPipesInRow) ? numberOfPipesInRow : PIPES_IN_ROW;
  }, [numberOfPipesInRow, campaign.welds.length]);

  return unweldedPipes.map(({label, pipes, component: Component}, index) => {
    const y = pipesRef.current[index - 1] || 0;

    return (
      <Group x={0} y={storedY + y}>
        <Label
          width={STALK_NUMBER_WIDTH}
          label={label}
          y={(PIPE_CONFIG.HEIGHT - STALK_NUMBER_CONFIG.FONT_SIZE) / 2}
        />
        <Pipes
          Component={Component}
          scale={scale}
          pipes={pipes}
          animateShape={animateShape}
          splitBy={splitBy}
          setOpenEntityIndex={setOpenEntityIndex}
          setEntitiesToView={setEntitiesToView}
          settings={settings}
          index={index}
          pipesRef={pipesRef}
        />
      </Group>
    );
  });
});

export default memo(UnweldedPipesList);