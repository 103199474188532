import React, {useEffect, useRef} from "react";
import {Chart as ChartJS} from "chart.js";
import {COLORS} from "@core/components/Chart/сonstants";
import {usePlotOptions} from "../../plotContext";
import {getLabel, getPlugins, getScale} from "../../services";

const TITLE = "Scatter plot";

const ScatterPlot = ({testType, plotData, testConfig, isFullScreen}) => {
  const canvas = useRef(null);
  const plot = useRef(null);

  const {options: plotOptions, paramType} = usePlotOptions();
  const {minY: min = 0, maxY: max = 0} = plotData.minmax ?? {};

  const yAxisLabel = getLabel(plotData.units, testType, paramType, testConfig);

  const dataset = plotData.plot.datasets;
  const yValues = dataset.map((points) => points.y);
  const {min: minAxisY , max: maxAxisY} = getScale(yValues, plotOptions.showAcceptanceCriteria, plotData.minmax);

  useEffect(() => {
    const additionalPlugins = {
      title: {
        display: true,
        text: TITLE,
      },
      legend: false,
      tooltip: {
        callbacks: {
          label: (context) => context.parsed.y
        }
      }
    };
    const plugins = {
      ...additionalPlugins,
      ...getPlugins(plotOptions.showAcceptanceCriteria, {y: {min, max}})
    };
    const chartConfig = {
      type: "scatter",
      data: {datasets: [{
        data: dataset,
        backgroundColor: COLORS.blue.backgroundColor,
      }]},
      options: {
        responsive: true,
        maintainAspectRatio: true,
        animation: false,
        plugins,
        scales: {
          y: {
            min: minAxisY,
            max: maxAxisY,
            title: {
              display: true,
              text: yAxisLabel,
            },
          },
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false
            }
          }
        }
      },
    };
    plot.current = new ChartJS(canvas.current, chartConfig);

    return () => {
      plot.current.destroy();
    };
  }, [plotData, plotOptions.showAcceptanceCriteria, min, max, isFullScreen]);

  return (
    <canvas ref={canvas} />
  );
};

export default ScatterPlot;
