import AcceptanceRow from "@core/components/QcpSideBar/components/TensileTest/components/AcceptanceRow";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const AcceptanceOnElements = ({elements}) => {
  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>Orientation</TableCell>
          <TableCell>Position</TableCell>
          <TableCell>Zone</TableCell>
          <TableCell />
          <TableCell>Yield Strength [MPa]</TableCell>
          <TableCell>Tensile Strength [MPa]</TableCell>
          <TableCell>Elongation [%]</TableCell>
          <TableCell>Uniform Elongation [%]</TableCell>
          <TableCell>Yield/Tensile Ratio</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {elements.map((element, index) => (
          <React.Fragment key={index}>
            <TableRow>
              <TableCell rowSpan={3}>{element.orientation}</TableCell>
              <TableCell rowSpan={3}>{element.position}</TableCell>
              <TableCell rowSpan={3}>{element.zone}</TableCell>
            </TableRow>
            <AcceptanceRow
              {...element.requirements}
            />
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

export default AcceptanceOnElements;