import React, {useEffect, useState, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {
  Grid,
  TableHead,
  MenuItem,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@mui/material";
import {IoMdCloseCircleOutline} from "react-icons/io";
import {FieldArray, useFormikContext} from "formik";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import {getLocationAddress} from "@core/helpers";
import useStores from "../../../../../../useStores";
import {MuiAutocomplete} from "@core/components/Form";
import NormAutocomplete from "@core/components/NormAutocomplete";
import ProductTypesAutocomplete from "@core/components/ProductTypeAutocomplete";
import ProjectIdAutocomplete from "@core/components/ProjectIdAutocomplete";
import {keys} from "ramda";
import CreateCustomerOrderModal from "@core/components/CreateCustomerOrderModal";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import {MdAdd} from "react-icons/md";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const CommonFieldsForm = ({isSimpleForm}) => {
  const {UserStore} = useStores();
  const {setFieldValue, values} = useFormikContext();

  const [grades, setGrades] = useState([]);
  const [addCustomerOrderOpen, setAddCustomerOrderOpen] = useState(false);
  const [productProperties, setProductProperties] = useState([]);

  const {mainLocation, locations} = UserStore.user.data.company;

  const properties = useMemo(() => productProperties.reduce((acc, property) => ({
    ...acc,
    [property.name]: ""
  }), {quantity: ""}), [values.properties.productType]);

  useEffect(() => {
    setFieldValue("items", [properties]);
  }, [values.properties.productType]);

  const grade = useMemo(() => grades.find((grade) => grade.Material === values.properties.grade) || {}, [values.properties.grade]);
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Table className="styled-table">
          <TableBody>
            {!isSimpleForm && (
              <>
                <TableRow>
                  <TableCell width="50%">Number of Heats</TableCell>
                  <TableCell width="50%">
                    <TextField
                      required
                      type="number"
                      name='heats'
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%">Lot ID(s) per Heat</TableCell>
                  <TableCell width="50%">
                    <TextField
                      required
                      type="number"
                      name='lots'
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%">Heat</TableCell>
                  <TableCell width="50%">
                    <TextField
                      name='heat'
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCell width="50%">Location</TableCell>
              <TableCell width="50%">
                <SelectField
                  required
                  name='location'
                >
                  <MenuItem key={mainLocation._id} value={mainLocation._id}>
                    {getLocationAddress(mainLocation)}
                  </MenuItem>
                  {locations.map((location) => (
                    <MenuItem key={location._id} value={location._id}>
                      {getLocationAddress(location)}
                    </MenuItem>
                  ))}
                </SelectField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="50%">Certificate Type</TableCell>
              <TableCell width="50%">
                <SelectField
                  name="certificateType"
                  required
                >
                  {["3.1", "3.2"].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </SelectField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="50%">PO Number</TableCell>
              <TableCell width="50%">
                <MuiAutocomplete
                  url={`${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open&type=sender`}
                  inputProps={{
                    name: "transferId",
                  }}
                  formatOptions={(data) => data.items.map((item) => ({label: item.orderNumber, value: item._id}))}
                  onCreateNew={() => setAddCustomerOrderOpen(true)}
                />
                <CreateCustomerOrderModal
                  onTransferCreated={(transferId) => setFieldValue("transferId", transferId)}
                  open={addCustomerOrderOpen}
                  setOpen={setAddCustomerOrderOpen}
                  interfaceType={INTERFACE_TYPE.SENDER}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="50%">Material Specification</TableCell>
              <TableCell width="50%">
                <NormAutocomplete
                  name="properties.norm"
                  testType="certificate"
                  onChange={({norm, value: grades}) => {
                    setFieldValue("properties.norm", norm);
                    setFieldValue("properties.grade", "");
                    setGrades(grades || []);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="50%">Grade</TableCell>
              <TableCell width="50%">
                <SelectField
                  required
                  disabled={!values.properties.norm}
                  name="properties.grade"
                  placeholder={!values.properties.norm && "Please select a Material Specification first."}
                >
                  {grades.map((value) => (
                    <MenuItem key={value.Material} value={value.Material}>
                      {value.Material}
                    </MenuItem>
                  ))}
                </SelectField>
              </TableCell>
            </TableRow>
            {(values.properties.grade && grade.DeliveryConditions) && (
              <TableRow>
                <TableCell width="50%">Delivery condition</TableCell>
                <TableCell width="50%">
                  <SelectField
                    name="deliveryCondition"
                  >
                    {keys(grade.DeliveryConditions).map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </SelectField>
                </TableCell>
              </TableRow>
            )}
            {!isSimpleForm && (
              <>
                <TableRow>
                  <TableCell width="50%">Work Order</TableCell>
                  <TableCell width="50%">
                    <TextField
                      name='internalWorkOrder'
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%">Work Order Item</TableCell>
                  <TableCell width="50%">
                    <TextField
                      name='internalItemNumber'
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCell width="50%">Product Type</TableCell>
              <TableCell width="50%">
                <ProductTypesAutocomplete
                  inputProps={{
                    name: "properties.productType",
                    required: true
                  }}
                  onChange={(value) => {
                    setFieldValue("properties.productType", value);
                    setProductProperties(value.properties || []);
                  }}
                />
              </TableCell>
            </TableRow>
            {values.transferId && (
              <TableRow>
                <TableCell width="50%">Project ID</TableCell>
                <TableCell width="50%">
                  <ProjectIdAutocomplete
                    name="projectId"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
      {values.properties.productType && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      {values.properties.productType && (
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <h3>Items:</h3>
          </Grid>
          <Grid item xs={12}>
            <Table className="styled-table">
              <TableHead>
                <TableCell>Quantity</TableCell>
                {productProperties.map((property) => (
                  <TableCell key={property.label}>
                    {property.label} {property.measurements ? `[${property.measurements}]` : null}
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableHead>
              <TableBody>
                <FieldArray name="items">
                  {({remove}) => values.items.map((item, index) => (
                    <TableRow>
                      <TableCell>
                        <TextField
                          required
                          type="number"
                          name={`items.${index}.quantity`}
                        />
                      </TableCell>
                      {productProperties.map((property) => (
                        <TableCell key={property.name}>
                          <TextField
                            required
                            name={`items.${index}.${property.name}`}
                          />
                        </TableCell>
                      ))}
                      <TableCell>
                        <IconButton onClick={() => remove(index)}>
                          <IoMdCloseCircleOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </FieldArray>
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setFieldValue("items", [...values.items, properties]);
                      }}
                    >
                      <MdAdd size={80} />Add new
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default observer(CommonFieldsForm);