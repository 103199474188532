import React, {useEffect, useCallback, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {observer} from "mobx-react-lite";
import qs from "qs";
import {Grid, Paper, Button, CircularProgress, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {map, prop, tail, values, isEmpty} from "ramda";
import TableNavigation from "@core/components/TableNavigation";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import ColumnsSelect from "@core/components/ColumnsSelect";
import CreateCustomerOrderModal from "@core/components/CreateCustomerOrderModal";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import styles from "./styles";
import useStores from "../../../useStores";
import {Input} from "@core/components/Form";
import InputAdornment from "@mui/material/InputAdornment";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {getColumns, ORDER_TABS, ORDER_STATUSES_QUERY, DEFAULT_VIEW} from "./data";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import useSetInitialViewQueryParam from "@core/hooks/useSetInitialViewQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const LINK_TO_ORDER_BY_INTERFACE_TYPE = {
  [INTERFACE_TYPE.SENDER]: "/transfers",
  [INTERFACE_TYPE.RECEIVER]: "/orders",
};

const TransfersList = observer(({classes, interfaceType}) => {
  const {TransferStore} = useStores();
  const location = useLocation();
  const history = useHistory();
  
  const {status, data: transfers, total, fetch} = TransferStore.transfers;
  const [addOrderOpen, setAddOrderOpen] = useState(false);
  const [transferToDelete, setTransferToDelete] = useState(null);
  const [transferToToggle, setTransferToToggle] = useState({});

  const currentPathname = location.pathname.replace(/\/$/, "");
  const view = qs.parse(tail(location.search)).view || ORDER_TABS.ALL;
  const columns = getColumns(interfaceType, view, setTransferToToggle, setTransferToDelete);
  const savedSelectedColumns = JSON.parse(localStorage.getItem(currentPathname));
  const [selectedColumns, setSelectedColumns] = useState(savedSelectedColumns || map(prop("dataIndex"), columns));

  useEffect(() => {
    return () => TransferStore.transfers.reset();
  }, []);

  useSetInitialViewQueryParam(DEFAULT_VIEW);

  useFetchTableDataByQueryParam({
    getStore: () => TransferStore,
    dataKey: STORE_DATA.transfers,
    defaultParams: {...ORDER_STATUSES_QUERY[view], type: interfaceType},
    matchPath: currentPathname,
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(currentPathname, limit);
    TransferStore.transfers.load({limit, offset: 0});
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    TransferStore.transfers.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  return (
    <div className={classes.page}>
      <Grid container className={classes.pageHeadline} spacing={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            {interfaceType === INTERFACE_TYPE.RECEIVER ? "Orders to Supplier" : "Customer Orders"}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddOrderOpen(true)}
          >
              Create new
          </Button>
          <CreateCustomerOrderModal
            interfaceType={interfaceType}
            open={addOrderOpen}
            setOpen={setAddOrderOpen}
            onTransferCreated={() => history.push(`${LINK_TO_ORDER_BY_INTERFACE_TYPE[interfaceType]}?status=${ORDER_TABS.ALL}`)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Input
            name="search"
            endAdornment={
              <InputAdornment position="start">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Search Orders"}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item>
          <ColumnsSelect
            columns={columns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Grid>
        <Paper className={classes.pageTable}>
          <TableNavigation
            views={values(ORDER_TABS)}
            currentView={view}
            link={currentPathname}
            query="view"
          />
          <Table
            selectedColumns={selectedColumns}
            items={transfers}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => TransferStore.transfers.load({sort})}
            saveSortToQueryParams
            total={total}
            onRowClick={(transfer) => history.push(`${currentPathname}/${transfer._id}`)}
          >
            {status.loaded && transfers.length > 0 && (
              <TableFooter
                saveTablePageToQueryParams
                isLoaded={status.loaded}
                items={transfers}
                total={total}
                limit={fetch.limit}
                offset={fetch.offset}
                onOffsetChange={(offset) => TransferStore.transfers.load({offset})}
                onLimitChange={handleRowsPerPageChange}
              />
            )}
          </Table>
        </Paper>
      </Grid>

      <Confirmation
        open={transferToDelete}
        onCancel={() => setTransferToDelete(null)}
        onConfirm={async () => {
          await TransferStore.removeTransfer(transferToDelete);
          setTransferToDelete(null);
        }}
        alertText="Are you sure you want to delete this order?"
      />
      <Confirmation
        open={!isEmpty(transferToToggle)}
        onCancel={() => setTransferToToggle({})}
        onConfirm={async () => {
          if(transferToToggle.isDraft) await TransferStore.approveDraftTransfer(transferToToggle._id);
          else await TransferStore.reopenTransfer(transferToToggle._id);

          setTransferToToggle({});
        }}
        alertText={transferToToggle.isDraft ?
          "Are you sure you want to close this order?" :
          "Are you sure you want to open this order?"
        }
      />
    </div>
  );
});

export default withStyles(TransfersList, styles);
