import {ROUTES} from "@core/api/routes";
import {Input, Select} from "@core/components/Form";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {ACTIONS} from "@core/constants/api";
import {ZONES, STATUSES, HARDNESS_UNITS} from "@core/constants/test";
import {TEST_RESULTS} from "@core/constants/testResults";
import {getConfigFromCondition, getPoItemNumber, splitByIntoChunks, splitIntoChunks} from "@core/helpers";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import {Formik, getIn} from "formik";
import {inject, observer} from "mobx-react";
import * as R from "ramda";
import {compose, flatten, isEmpty, map, prop, uniq, values} from "ramda";
import React, {Component, useEffect} from "react";
import {withStyles} from "tss-react/mui";
import * as yup from "yup";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestFooter from "../../LabTestFooter";
import {exceptionsConfig} from "./data";
import Picker from "./picker";
import styles from "./styles.js";

const locations = [
  "1/4 Thickness",
  "1/2 Thickness",
  "3/4 Thickness",
  "2 mm from OD",
  "2 mm from ID",
  "Through thickness",
];

const hardnessLocations = [
  "Linear sample",
  "Circular sample"
];

const zones = [
  "MW",
  "OD",
  "ID"
];

const CIRCULAR_SHAPE_MIN_AMOUNT = 16;

function validateElementValue(value) {
  const max = this.parent.max;
  const min = this.parent.min || 0;

  const isElementAcceptable = (!max || value <= max) && (value >= min);

  const elementsHistory = this.from[1].value.elementsHistory;
  const position = this.parent.position;
  const elementHistory = elementsHistory.find((elementHistory) => elementHistory.position.split(", ").includes(position.toString())) || {};

  const avgMin = elementHistory.avgMin || this.from[1].value.avgMin || 0;
  const avgMax = elementHistory.avgMax || this.from[1].value.avgMax;
  const variation = this.from[1].value.variation;
  const elements = this.from[1].value.elements;

  const positions = elementHistory.position ? elementHistory.position.split(", ") : [];
  const quadrant = elements.filter((element) => positions.includes(element.position.toString()));
  const quadrantValues = quadrant.map((element) => Number(element.value) || 0);

  const sum = quadrantValues.reduce((acc, el) => acc + el, 0);
  const avgValue = sum / quadrant.length;
  const quadrantMax = Math.max(...quadrantValues);
  const quadrantMin = Math.min(...quadrantValues);
  const hardnessVariation = quadrantMax - quadrantMin;

  const isQuadrantAcceptable = !quadrant.length || ((!avgMax || Number(avgValue) <= avgMax) && (avgValue > Number(avgMin)));

  const isVariationAcceptable = !variation || hardnessVariation <= variation;

  return isElementAcceptable && isQuadrantAcceptable && isVariationAcceptable;
}

class HardnessTest extends Component {

  constructor(props) {

    super(props);

    const quadrants = map(prop("metal"), props.test.properties.elementsHistory || []);
    const zones = quadrants.map((name) => {
      const [, zone] = name.split("-");

      return zone && zone.trim();
    });

    this.state = {
      loaded: true,
      positionsPickerOpen: false,
      historyPicker: false,
      value: "",
      metals: [{label: ZONES.BASE_METAL, value: "bm"}, {label: ZONES.HAZ, value: "haz"}, {label: ZONES.WELD_METAL, value: "wm"}],
      grades: [],
      min: 0,
      max: "",
      zones: uniq(zones)
    };

  }

  async componentDidMount() {
    const response = await axios.get(ROUTES.TEST_NORM[ACTIONS.ALL_BY_QUERY](this.props.test.type));

    this.setState({grades: response.data});
  }

  handleClose = () => {
    this.setState({positionsPickerOpen: false, historyPicker: false});
  };

  getResult = ({elements, variation, acceptance, elementsHistory, ...rest}) => {
    const isElementsAcceptable = elements.every((el) => {
      const lte = !el.max || R.lte(parseFloat(el.value), el.max);

      return lte && R.gte(parseFloat(el.value), el.min || 0);
    });

    const config = exceptionsConfig[acceptance];

    const splitBy = acceptance ? config.TestZones.map(({values}) => values): [4];

    const isQuadrantsAcceptable = splitByIntoChunks(splitBy, elements).every((chunk, index) => {
      const sum = chunk.reduce((acc, el) => acc + Number(el.value), 0);
      const avgValue = sum / 4;

      const avgMax = elementsHistory[index]?.avgMax || rest.avgMax;
      const avgMin = elementsHistory[index]?.avgMin || rest.avgMin;

      const isAverageAcceptable = avgMax ?
        (avgValue >= Number(avgMin)) && (avgValue <= Number(avgMax)) :
        avgValue >= Number(avgMin);

      return isAverageAcceptable;
    });

    const isVariationAcceptable = splitIntoChunks(4, elements).every((chunk) => {
      const quadrantValues = chunk.map((element) => Number(element.value || 0) || 0);
      const quadrantMax = Math.max(...quadrantValues);
      const quadrantMin = Math.min(...quadrantValues);
      const hardnessVariation = quadrantMax - quadrantMin;

      return !variation || hardnessVariation <= variation;
    });

    const isAcceptable = isElementsAcceptable && isQuadrantsAcceptable && isVariationAcceptable;

    return isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
  };

  getIsStartOfQuadrant = (el, elementsHistory) => {
    const position = el.position.toString();

    const elementHistory = this.getElementHistory(el, elementsHistory);

    if (!elementHistory) return false;

    return R.head(elementHistory.position.split(", ")) === position;
  };

  getShowGap = (el, elementsHistory, tableData, idx) => {
    const position = el.position.toString();

    const elementHistory = this.getElementHistory(el, elementsHistory);

    if (!elementHistory) return false;

    const isEndOfQuadrant = R.last(elementHistory.position.split(", ")) === position;

    return isEndOfQuadrant && idx !== tableData.length - 1;
  };

  getElementHistory = (el, elementsHistory) => {
    const position = el.position.toString();

    return elementsHistory.find((eh) => {
      const positions = eh.position.split(", ");

      return positions.includes(position);
    });
  };

  getElements = (max, min, count = 3, elementsHistory = [], elements) => {
    return R.times((P) => {
      const position = P + 1;

      const elementHistory = elementsHistory.find((e) => {
        const positions = e.position.split(", ");

        return positions.includes(position.toString());
      }) || {};

      const maxValue = elementHistory.differentMax ? elementHistory.differentMax : max;
      const minValue = elementHistory.differentMin ? elementHistory.differentMin : min;

      const value = elements && elements[P] && elements[P].value;

      return {
        id: P,
        position,
        value: value || "",
        checked: false,
        active: false,
        metal: "",
        max: maxValue,
        min: minValue
      };
    }, count);
  };

  render() {
    const {classes, test, saveTest, NotificationStore, certificate, user, client, formRef} = this.props;

    const validationSchema = yup.object().shape({
      client: yup.string().required(),
      lab: yup.string().required(),
      hardnessTest: yup.string().required(),

      norm: yup.string().required(),
      grade: yup.mixed().required(),

      amount: yup.number().typeError("Should be a number").min(0, "Should be \u2265 0").max(1000, "Should be \u2266 1000"),

      min: this.state.min ? yup.number().min(this.state.min, `Should be \u2265 ${this.state.min}`) : yup.number(),
      max: this.state.max ? yup.number().max(this.state.max, `Should be \u2264 ${this.state.max}`) : yup.number(),

      metal: yup.object().shape({
        value: yup.string()
      }),

      location: yup.string(),
      stage: yup.string(),
      diameter: yup.string(),
      positions: yup.string(),
      wallThickness: yup.number(),
      differentMax: this.state.max ? yup.number().max(this.state.max, `Should be \u2264 ${this.state.max}`) : yup.number(),
      differentMin: this.state.max ? yup.number().min(this.state.min, `Should be \u2265 ${this.state.min}`) : yup.number(),
      avgMax: yup.string(),
      avgMin: yup.string(),
      variation: yup.number(),
      elements: yup.array().of(yup.object().shape({
        value: yup.number().test("value", validateElementValue)
      })),
      acceptance: yup.string(),
      specimenId: yup.string()
    });

    const defaultMax = "", defaultMin = "", defaultAmount = 3;
    const predefinedMax = test.properties.max ? test.properties.max : defaultMax;
    const predefinedMin = test.properties.min ? test.properties.min : defaultMin;
    const predefinedAmount = test.properties.elements ? test.properties.elements.length : defaultAmount;

    const predefinedHistory = () => {
      if (!test.properties.elements) {
        return [];
      }

      const metaled = test.properties.elements.filter((el) => el.metal);

      return metaled.reduce((acc, curr) => {
        const found = acc.find((H) => H.metal === curr.metal);

        if (found) {
          found.position += `, ${curr.position}`;

          return acc;
        } else {
          return [...acc, {
            metal: curr.metal,
            position: curr.position.toString(),
            differentMax: curr.max,
            differentMin: curr.min
          }];
        }
      }, []);
    };

    const predefinedElements = test.properties.elements || this.getElements(predefinedMax, predefinedMin, predefinedAmount);

    const filteredAcceptances= Object.keys(exceptionsConfig).reduce(function(r, e) {
      if (!exceptionsConfig[e].hasOwnProperty("company") || exceptionsConfig[e].company.includes(user.company.name)) r[e] = exceptionsConfig[e];

      return r;
    }, {});

    const initialValues = {
      client: test.properties.client || client.name || "",
      lab: test.properties.lab || user.company.name || "",
      hardnessTest: test.properties.hardnessTest || "",
      norm: test.properties?.norm || test.norm || certificate?.properties?.norm || "",
      amount: predefinedAmount,
      grade: test.properties.grade || test.grade || certificate?.properties?.grade || "",
      elements: predefinedElements,
      max: test.properties.max || !STATUSES.FILLED ? predefinedMax : "",
      min: test.properties.min || !STATUSES.FILLED ? predefinedMin : "",
      stage: test.properties.stage || "",
      diameter: test.properties.diameter || "",
      wallThickness: test.properties.wallThickness || "",
      metal: {},
      differentMax: test.properties.max || predefinedMax,
      differentMin: test.properties.min || predefinedMin,
      position: "",
      location: test.properties.location || "",
      hardnessLocation: test.properties.hardnessLocation || "Linear sample",
      elementsHistory: test.properties.elementsHistory || predefinedHistory(),
      notes: test.properties.notes || "",
      acceptance: test.properties.acceptance || "",
      avgMax: test.properties.avgMax || "",
      avgMin: test.properties.avgMin || "",
      variation: test.properties.variation || "",
      specimenId: test.properties.specimenId || "",
      result: test.properties.result || ""
    };

    const onSubmit = (values) => {
      saveTest({
        ...values,
        grade: values.grade.value || test.grade,
        result: this.getResult(values)
      });
    };

    return (
      <Formik
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        render={(props) => {
          const {
            values: {
              lab,
              hardnessTest,
              norm,
              amount,
              elements,
              grade,
              specimenId,
              max,
              min,
              metal,
              differentMin,
              differentMax,
              position,
              stage,
              diameter,
              location,
              wallThickness,
              hardnessLocation,
              notes,
              elementsHistory,
              acceptance
            },
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          } = props;
          const countColumns = 8;
          const tables = Array(Math.ceil(elements.length / countColumns)).fill();
          const isAllChecked = R.all((E) => E.checked, elements);

          useEffect(() => {
            if (R.isEmpty(this.state.grades)) return;

            const newAcceptance = this.state.grades.find((acc) => acc.Material === grade) || {};
            setFieldValue("grade", {...newAcceptance, value: grade});

            if(!test.properties.grade) onAcceptance(newAcceptance);
          }, [this.state.grades]);

          useEffect(() => {
            if(hardnessTest === "HV0.5") setFieldValue("notes", "Micro hardness test");
            else setFieldValue("notes", notes.replace("Micro hardness test", ""));
          }, [hardnessTest]);

          const changeMinAndMax = (min, max) => {
            const newMin = Number(min) || 0;
            const newMax = Number(max) || "";

            const newElements = elements.map((item) => {
              return {...item, min: newMin, max: newMax};
            });

            setFieldValue("elements", newElements);
            setFieldValue("max", newMax);
            setFieldValue("min", newMin);
            this.setState({min: newMin, max: newMax});
          };

          const getTestTypeWithAcceptance = (acceptance) => {
            const config = exceptionsConfig[acceptance];

            const poItemNumber = getPoItemNumber(certificate.lineItem);

            return config.TestType.POItem ? config.TestType.POItem[poItemNumber] || {} : config.TestType;
          };
          
          const getTestType = ({diameter, location}) => {
            if(acceptance) return getTestTypeWithAcceptance(acceptance);

            if(diameter && grade.Diameter && grade.Diameter[diameter]) {
              return grade.Diameter[diameter].TestType;
            }

            if(location && grade.Location && grade.Location[location]) {
              return grade.Location[location].TestType;
            }

            return grade.TestType;
          };

          const changeVariation = (wallThickness, hardnessTest) => {
            const {TestType: testType} = getConfigFromCondition(grade.WallThickness, wallThickness) || {};
            const {Variation: variation} = testType && testType[hardnessTest] ? testType[hardnessTest] : {};

            setFieldValue("variation", variation || "");
          };

          const changeExceptionValues = ({hardnessTest = "", diameter, location}) => {
            if (!grade.value) return;

            const testType = getTestType({diameter, location});
            const {Dmax: _max, Dmin: _min, AvgMax: avgMax} = testType && testType[hardnessTest] ? testType[hardnessTest] : {};

            changeMinAndMax(_min || defaultMin, _max || defaultMax);
            setFieldValue("avgMax", avgMax);

            changeVariation(wallThickness, hardnessTest);
          };

          const onAcceptance = (value) => {
            let {Dmax: max, Dmin: min} = value;
            const {Amount: amount, Stage: stage} = value;

            setFieldValue("amount", amount);
            setFieldValue("stage", stage || "");

            if (!value.Diameter) setFieldValue("diameter", "");

            if (value.TestType) {
              const defaultHardnessType = R.keys(value.TestType)[0];
              setFieldValue("hardnessTest", defaultHardnessType);
              const {Dmax: _max, Dmin: _min} = value.TestType[defaultHardnessType];
              max = _max;
              min = _min;
            }

            changeMinAndMax(min, max);
            const newElements = this.getElements(max, min, amount, elementsHistory);
            setFieldValue("elements", newElements);
          };

          const change = (name, e) => {
            const value = e.target.value;

            if (name === "norm") {
              setFieldValue("elements", predefinedElements);
              setFieldValue("max", predefinedMax);
              setFieldValue("min", predefinedMin);
              setFieldValue("grade", "");
              setFieldValue("diameter", "");
            }

            if (this.state.grades.length === 1 && name === "norm") {
              const [grade] = this.state.grades;
              setFieldValue("grade", grade);
              onAcceptance(grade);
            }

            if (name === "norm" && value.amount) {
              setFieldValue("amount", value.amount);
              const newElements = this.getElements(max, min, value.amount);
              setFieldValue("elements", newElements);
            }

            if (name === "amount" && Number.isInteger(+value) && +value > 0) {
              const newElements = this.getElements(max, min, value, elementsHistory);
              setFieldValue("elements", newElements);
            }

            if (name === "max" && Number.isInteger(+value)) {
              const newElements = elements.map((item) => {
                const history = elementsHistory.find((eh) => eh.position.includes(item.position)) || {};

                if (history.differentMax) {
                  return item;
                }

                const max = e.target.value;

                return {...item, max};
              });

              setFieldValue("elements", newElements);
            }

            if (name === "min" && Number.isInteger(+value)) {
              const newElements = elements.map((item) => {
                const history = elementsHistory.find((eh) => eh.position.includes(item.position)) || {};

                if (history.differentMin) {
                  return item;
                }

                const min = e.target.value;

                return {...item, min};
              });

              setFieldValue("elements", newElements);
            }

            if (name === "grade") {
              onAcceptance(e.target.value);
            }

            if (this.state.grades.length === 1 && name === "norm") {
              const [grade] = this.state.grades;
              onAcceptance(grade);
            }

            setFieldValue(name, value, true);
            setFieldTouched(name, true, false);
          };

          const pickPosition = ({position}) => {
            const freshElements = elements.map((el) => el.position === position ?
              {...el, active: !el.active} :
              el);
            setFieldValue("elements", freshElements);
          };

          const pickHistoryPosition = ({position}, pickerElements) => {
            const freshElements = pickerElements
              .map((el) => el.position === position ?
                {...el, active: !el.active} :
                el);
            setFieldValue("elements", freshElements);
          };

          const changeElement = () => {
            const freshElements = elements.map((E) => E.active ?
              ({
                ...E,
                metal: metal.value + getMetalCount(metal.value),
                max: differentMax ? differentMax : E.max,
                min: differentMin ? differentMin : E.min,
                checked: true,
                active: false
              }) :
              E
            );
            setFieldValue("elements", freshElements);
          };

          const changeHistoryElement = (index) => {
            const elData = elementsHistory[index];
            const positions = elData.position.split(", ").map((el) => +el);
            const modifiedElements = elements
              .map((el) => {
                return positions.includes(el.position) ?
                  {
                    ...el,
                    active: false,
                    checked: true,
                    metal: elData.metal,
                    max: +elData.differentMax,
                    min: +elData.differentMin
                  } :
                  el;
              })
              .map((el) => !el.active && !el.checked ? {...el, max: defaultMax, metal: ""} : el);

            setFieldValue("elements", modifiedElements);
          };

          const changePosition = ({position, active}) => {
            const positionsValue = elements
              .map((E) => E.position === position ? {...E, active: !active} : E)
              .filter((E) => E.active && (E.metal === metal.value || R.isEmpty(E.metal)))
              .map((E) => E.position)
              .join(", ");
            setFieldValue("position", positionsValue);
            setFieldTouched("position", false, false);
          };

          const changeHistoryPosition = (index, {position}) => {
            if (elementsHistory[index].position.includes(position)) {
              const positionArr = elementsHistory[index].position
                .split(", ")
                .filter((pos) => pos !== position.toString())
                .sort((a, b) => +a - +b);

              if (positionArr.length === 0) {
                NotificationStore.showInfo("Last position can't be deleted. You may remove the whole element instead");
              } else {
                setFieldValue(`elementsHistory.${index}.position`, positionArr.join(", "));
              }
            } else {

              const value = elementsHistory[index].position.split(", ");

              if (value.length === 1 && value[0] === "") {
                value[0] = position.toString();
              } else value.push(position.toString());

              value.sort((a, b) => +a - +b);
              setFieldValue(`elementsHistory.${index}.position`, value.join(", "));
            }
          };

          const onLoadRequirements = (event) => {
            if (!event.target.value) {
              setFieldValue("acceptance", "");

              setFieldValue("avgMin", "");
              setFieldValue("avgMax", "");

              onAcceptance(grade);

              setFieldValue("elementsHistory", []);

              return;
            }

            setFieldValue("acceptance", event.target.value);
            const config = exceptionsConfig[event.target.value];

            setFieldValue("amount", config.Amount);

            const testTypeConfig = getTestTypeWithAcceptance(event.target.value);

            const testTypes = R.keys(testTypeConfig);
            const defaultTestType = testTypes[0];
            setFieldValue("hardnessTest", defaultTestType);

            const {Dmin: min, Dmax: max, AvgMin: avgMin = "", AvgMax: avgMax = ""} = testTypeConfig[defaultTestType] || {};
            const exceptionedElements = this.getElements(max, min, config.Amount, elementsHistory, elements);

            const groupedElements = values(config.TestZones.reduce((groupsByType, zone) => {
              const startIndex = flatten(values(groupsByType)).length;
              const endIndex = startIndex + zone.values;
              const group = exceptionedElements.slice(startIndex, endIndex);

              const oldGroup = groupsByType[zone.name] || [];
              groupsByType[zone.name] = oldGroup.concat(group);
              
              return groupsByType;
            }, {}));

            const newElementsHistory = groupedElements.map((chunk, index) => {
              const {Dmax: max, Dmin: min, AvgMax: avgMax, AvgMin: avgMin} = config.TestZones[index][defaultTestType] || {};

              return {
                metal: config.TestZones[index].name,
                position: chunk.map((element) => element.position).join(", "),
                differentMax: max || "",
                differentMin: min || "",
                avgMax: avgMax || "",
                avgMin: avgMin || "",
              };
            });

            const newElements = groupedElements.map((chunk, index) => {
              const {Dmax: max, Dmin: min} = config.TestZones[index][defaultTestType] || {};

              return chunk.map((element) => ({
                ...element,
                metal: `bm${index + 1}`,
                min: min || element.min,
                max: max || element.max,
              }));
            });

            setFieldValue("elementsHistory", newElementsHistory);

            setFieldValue("avgMin", avgMin);
            setFieldValue("avgMax", avgMax);
            setFieldValue("max", max);
            setFieldValue("min", min);

            setFieldValue("hardnessLocation", "Circular sample");

            setFieldValue("elements", flatten(newElements), true);
            this.setState({zones: zones});
          };

          const onCircularSample = () => {
            const exceptionedElements = this.getElements(max, min, CIRCULAR_SHAPE_MIN_AMOUNT, elementsHistory, elements);

            const groupedElements = splitIntoChunks(4, exceptionedElements);

            const newElementsHistory = groupedElements.map((chunk, index) => {
              return {
                metal: `Q${index + 1}`,
                position: chunk.map((element) => element.position).join(", "),
                differentMax: "",
                differentMin: ""
              };
            });

            const newElements = flatten(groupedElements.map((chunk, index) => {
              return chunk.map((element) => ({...element, metal: `bm${index + 1}`, checked: true}));
            }));

            setFieldValue("amount", newElements.length);
            setFieldValue("elements", newElements);
            setFieldValue("elementsHistory", newElementsHistory);
          };

          const onLinearSample = () => {
            const newElements = this.getElements(max, min, defaultAmount, []);
            setFieldValue("elements", flatten(newElements));
            setFieldValue("amount", defaultAmount);
            setFieldValue("elementsHistory", []);
            setFieldValue("acceptance", "");
          };

          const onHardnessLocation = (event) => {
            if (event.target.value === "Circular sample") onCircularSample();
            else onLinearSample();

            this.setState({zones: []});
            change("hardnessLocation", event);
          };

          const onZone = (zone) => {
            let zones = [];

            if (this.state.zones.includes(zone)) zones = this.state.zones.filter((z) => z !== zone);
            else zones = this.state.zones.concat(zone);

            const number = zones.length * CIRCULAR_SHAPE_MIN_AMOUNT;

            const exceptionedElements = this.getElements(max, min, number, elementsHistory, elements);

            const groupedElements = splitIntoChunks(CIRCULAR_SHAPE_MIN_AMOUNT, exceptionedElements);

            const newElementsHistory = groupedElements.reduce((history, quadrantElements, quadrantIndex) => {
              const zoneElements = splitIntoChunks(4, quadrantElements);

              zoneElements.forEach((elements, zoneIndex) => (
                history.push({
                  metal: `Q${zoneIndex + 1} - ${zones[quadrantIndex]}`,
                  position: elements.map((element) => element.position).join(", "),
                  differentMax: "",
                  differentMin: ""
                })
              ));

              return history;
            }, []);

            const newElements = flatten(groupedElements.map((chunk, index) => {
              return chunk.map((element) => ({...element, metal: `bm${index + 1}`, checked: true}));
            }));

            setFieldValue("amount", newElements.length);
            setFieldValue("elements", newElements);
            setFieldValue("elementsHistory", newElementsHistory);
            setFieldValue("location", "");

            this.setState({zones});
          };

          const getPositionArrByHistoryIndex = (historyIndex) => {
            return elementsHistory[historyIndex].position
              .split(", ")
              .filter((pos) => pos !== position.toString())
              .sort((a, b) => +a - +b);
          };

          const changeHistoryDifferentMax = (historyIndex, e) => {
            const differentMax = e.target.value;
            const positionArr = getPositionArrByHistoryIndex(historyIndex);
            const changedElements = elements.map((el) => positionArr.includes(el.position.toString()) ? {
              ...el,
              max: differentMax || max
            } : el);
            setFieldValue(`elementsHistory.${historyIndex}.differentMax`, differentMax);
            setFieldValue("elements", changedElements);
          };

          const changeHistoryDifferentMin = (historyIndex, e) => {
            const differentMin = e.target.value;
            const positionArr = getPositionArrByHistoryIndex(historyIndex);
            const changedElements = elements.map((el) => positionArr.includes(el.position.toString()) ? {
              ...el,
              min: differentMin || min
            } : el);
            setFieldValue(`elementsHistory.${historyIndex}.differentMin`, differentMin);
            setFieldValue("elements", changedElements);
          };

          const changeHistoryTestZone = (historyIndex, e) => {
            setFieldValue(`elementsHistory.${historyIndex}.metal`, e.target.value);
          };

          const pushHistory = () => {
            setFieldValue("elementsHistory", R.prepend({
              metal: getMetalName(metal.value),
              position,
              differentMax,
              differentMin
            }, elementsHistory));
          };

          const removeHistory = (index) => {
            const valuesToReset = elementsHistory[index].position
              .split(", ")
              .map((el) => +el);
            const uniqueElements = elements.map((el) => {
              if (valuesToReset.includes(el.position)) {
                return {...el, max, min, active: false, checked: false, metal: ""};
              } else return el;
            });
            setFieldValue("elements", uniqueElements);
            setFieldValue("elementsHistory", elementsHistory.filter((I, idx) => idx !== index));
          };

          const resetElement = () => {
            setFieldValue("position", "");
            setFieldValue("metal", "");
            setFieldValue("differentMax", "");
            setFieldValue("differentMin", "");
          };

          const getMetalCount = (metalShortcut) => {
            const existingCounts = elements
              .filter((el) => el.metal.includes(metalShortcut))
              .map((el) => +el.metal.split(/([0-9]+)/)[1])
              .sort((a, b) => a - b);

            return existingCounts.reduce((acc, curr) => {
              if (curr === acc) acc++;

              return acc;
            }, 1);
          };

          const getMetalName = (metal) => {
            const count = getMetalCount(metal);
            switch (metal) {
            case "bm":
              return "Base " + count;
            case "wm":
              return "Weld " + count;
            case "haz":
              return "HAZ " + count;
            default:
              return "";
            }
          };

          return (
            <Grid container spacing={4}>
              <Grid item container spacing={5} alignItems="flex-end">
                <Grid item xs={3}>
                  <ClientField isFromProducer={!!client.name} />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    disabled
                    label='Laboratory'
                    name='lab'
                    required
                    value={lab}
                    error={Boolean(errors.lab) && touched.lab}
                    errorMessage={errors.lab}
                    onChange={(e) => change("lab", e)}
                  />
                </Grid>
              </Grid>

              <Grid item container  spacing={5}>
                {certificate._id ? (
                  <>
                    <Grid item xs={3}>
                      <Input
                        value={norm}
                        label="Material Specification"
                        name="norm"
                        required
                        disabled
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Input
                        value={grade.value || grade}
                        label="Grade / UNS"
                        name="grade"
                        required
                        disabled
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={3}>
                      <NormAutocomplete
                        label="Material specification"
                        name="norm"
                        testType={test.type}
                        onChange={(newNorm) => {
                          if (norm === newNorm.norm) return;

                          const event = {target: {value: newNorm.norm || ""}};
                          change("norm", event);
                        }}
                      />
                    </Grid>

                    {norm && (
                      <Grid item xs={3}>
                        <Select
                          value={grade}
                          name='grade'
                          label='Grade / UNS'
                          required
                          error={Boolean(errors.grade) && touched.grade}
                          onChange={(event) => change("grade", event)}
                          renderValue={({value}) => value}
                        >
                          {this.state.grades.map((grade) => {
                            if (grade.Norm !== norm) return null;

                            return (
                              <MenuItem
                                key={grade.Material}
                                value={{...grade, value: grade.Material}}
                              >
                                {grade.Material}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    )}
                  </>
                )}

                {grade && grade.Diameter && (
                  <Grid item xs={3}>
                    <Select
                      value={diameter}
                      name='diameter'
                      label='Diameter'
                      required
                      error={Boolean(errors.diameter) && touched.diameter}
                      onChange={(event) => {
                        change("diameter", event);
                        changeExceptionValues({hardnessTest, diameter: event.target.value, location});
                      }}
                    >
                      {R.keys(grade.Diameter).map((diameter) => (
                        <MenuItem key={diameter} value={diameter}>
                          {diameter}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
              </Grid>
              <Grid item container  spacing={5}>
                <Grid item xs={3}>
                  <Select
                    value={hardnessTest}
                    name='hardnessTest'
                    label='Hardness Type'
                    required
                    error={Boolean(errors.hardnessTest) && touched.hardnessTest}
                    onChange={(event) => {
                      change("hardnessTest", event);
                      changeExceptionValues({hardnessTest: event.target.value, diameter, location});
                    }}
                  >
                    {HARDNESS_UNITS.map(((value) => (
                      <MenuItem key={value} value={value}>{value}</MenuItem>)
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Input
                    type="number"
                    label='No. of Indentations'
                    name='amount'
                    value={amount}
                    error={Boolean(errors.amount) && touched.amount}
                    errorMessage={errors.amount}
                    onChange={(e) => change("amount", e)}
                  />
                </Grid>
              </Grid>
              <Grid item container  spacing={5} alignItems="flex-end">
                <Grid item xs={3}>
                  <Select
                    value={hardnessLocation}
                    name='hardnessLocation'
                    label='Sample shape'
                    onChange={onHardnessLocation}
                  >
                    {hardnessLocations.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label='Specimen ID'
                    name='specimenId'
                    value={specimenId}
                    error={Boolean(errors.specimenId) && touched.specimenId}
                    errorMessage={errors.specimenId}
                    onChange={(e) => change("specimenId", e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={location}
                    name='location'
                    label='Location'
                    disabled={!isEmpty(this.state.zones)}
                    onChange={(event) => {
                      change("location", event);
                      changeExceptionValues({hardnessTest, location: event.target.value, diameter});
                    }}
                  >
                    {locations.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                    <MenuItem value={undefined}>N/A</MenuItem>
                  </Select>
                </Grid>
                {hardnessLocation === "Circular sample" && (
                  <Grid item xs={3}>
                    <FormGroup row>
                      {zones.map((zone) => (
                        <FormControlLabel
                          disabled={acceptance || (location && location !== "N/A")}
                          control={(
                            <Checkbox
                              color="primary"
                              onChange={() => onZone(zone)}
                              checked={this.state.zones.includes(zone)}
                            />
                          )}
                          label={zone}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                )}
              </Grid>
              <Grid item container spacing={5}>
                {grade && grade.Stage && (
                  <Grid item xs={6}>
                    <Select
                      value={stage}
                      name='stage'
                      label='Stage'
                      required
                      error={Boolean(errors.stage) && touched.stage}
                      onChange={(event) => {
                        change("stage", event);
                        setFieldValue("grade", {...grade, ...grade.Stage[event.target.value]});
                      }}
                    >
                      {R.is(Object, grade.Stage) && (
                        R.keys(grade.Stage).map((stage) => (
                          <MenuItem key={stage} value={stage}>
                            {stage}
                          </MenuItem>
                        )))}
                      {R.is(String, grade.Stage) && (
                        <MenuItem key={stage} value={stage}>
                          {stage}
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                )}
                {grade && grade.WallThickness && (
                  <Grid item xs={3}>
                    <Input
                      type="number"
                      label='Wall thickness'
                      name='wallThickness'
                      value={wallThickness}
                      error={Boolean(errors.wallThickness) && touched.wallThickness}
                      errorMessage={errors.wallThickness}
                      onChange={(e) => {
                        change("wallThickness", e);
                        changeVariation(e.target.value, hardnessTest);
                      }}
                      endAdornment="MM"
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <h2>Global grade criteria</h2>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={2}>
                  <Input
                    disabled={acceptance}
                    label='Min'
                    name='min'
                    value={min}
                    error={Boolean(errors.min) && touched.min}
                    errorMessage={errors.min}
                    onChange={(e) => change("min", e)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    disabled={acceptance}
                    label='Max'
                    name='max'
                    value={max}
                    error={Boolean(errors.max) && touched.max}
                    errorMessage={errors.max}
                    onChange={(e) => change("max", e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={acceptance}
                    name='acceptance'
                    label='Load requirements'
                    onChange={onLoadRequirements}
                  >
                    <MenuItem key="N/A" value={undefined}>N/A</MenuItem>
                    {Object.keys(filteredAcceptances).map((name) => (
                      <MenuItem key={name} value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h2>Local grade criteria</h2>
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={2}>
                  <Select
                    disabled={acceptance}
                    value={metal}
                    name='metal'
                    label='Test zone'
                    error={Boolean(errors.metal) && touched.metal}
                    onChange={(event) => change("metal", event)}
                  >
                    {this.state.metals.map((E, idx) => <MenuItem key={idx} value={E}>{E.label}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Input
                    label='Positions'
                    name='position'
                    value={position}
                    placeholder={R.isEmpty(metal) ? "Select test zone first" : ""}
                    disabled={R.isEmpty(metal) || acceptance}
                    id={"positionInput"}
                    error={Boolean(errors.position) && touched.position}
                    errorMessage={errors.position}
                    onClick={() => !R.isEmpty(metal) && this.setState({positionsPickerOpen: true})}
                  />
                  <Dialog
                    open={this.state.positionsPickerOpen}
                    onClose={this.handleClose}
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                  >
                    <DialogTitle id="confirmation-dialog-title" style={{textAlign: "center", paddingBottom: 0}}>Choose
                            positions</DialogTitle>
                    <DialogContent style={{padding: "15px 25px 0px 25px"}}>
                      <FormControl>
                        <Picker elements={elements} pickPosition={(P) => {
                          changePosition(P);
                          pickPosition(P);
                        }} />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary">
                              OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={2} className={classes.differentMaxWidth}>
                  <Input
                    disabled={acceptance}
                    label='Different min'
                    name='differentMin'
                    value={differentMin}
                    type={"number"}
                    error={Boolean(errors.differentMin) && touched.differentMin}
                    errorMessage={errors.differentMin}
                    onChange={(e) => change("differentMin", e)}
                  />
                </Grid>
                <Grid item xs={2} className={classes.differentMaxWidth}>
                  <Input
                    disabled={acceptance}
                    label='Different max'
                    name='differentMax'
                    value={differentMax}
                    type={"number"}
                    error={Boolean(errors.differentMax) && touched.differentMax}
                    errorMessage={errors.differentMax}
                    onChange={(e) => change("differentMax", e)}
                  />
                </Grid>
                <Grid item xs={1} className={classes.roundButtonGrid}>
                  <Tooltip title="Add metal" placement="top">
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.btnAdd}
                        onClick={() => {
                          changeElement();
                          pushHistory();
                          resetElement();
                        }}

                        disabled={
                          Boolean(errors.differentMax) ||
                                Boolean(errors.differentMin) ||
                                !metal.value ||
                                !norm ||
                                this.state.formDisable ||
                                isAllChecked ||
                                R.isEmpty(position) ||
                                acceptance
                        }
                      >
                              Add
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
              {elementsHistory.map((history, idx) => {
                const historyPath = `elementsHistory[${idx}]`;
                const positions = history.position.split(", ").map((el) => +el);
                const pickerElements = elements.map((el) => {
                  return positions.includes(el.position) ? {...el, checked: false, active: true} : el;
                });

                const quadrant = elements.filter((element) => positions.includes(element.position));
                const sum = quadrant.reduce((acc, el) => acc + Number(el.value || 0), 0);
                const avgValue = sum / quadrant.length;

                return (
                  <Grid item container  spacing={5} key={idx}>
                    <Grid item xs={3}>
                      <Input
                        disabled={acceptance}
                        label='Metal'
                        name={`${historyPath}.metal`}
                        value={history.metal}
                        error={Boolean(getIn(errors, `${historyPath}.metal`)) && getIn(touched, `${historyPath}.metal`)}
                        errorMessage={errors[`${historyPath}.metal`]}
                        onChange={(e) => changeHistoryTestZone(idx, e)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        disabled={acceptance}
                        label='Positions'
                        name={`${historyPath}.position`}
                        value={history.position}
                        error={Boolean(getIn(errors, `${historyPath}.position`)) && getIn(touched, `${historyPath}.position`)}
                        errorMessage={errors[`${historyPath}.position`]}
                        onClick={() => {
                          if (acceptance) return;

                          this.setState({historyPicker: idx});
                        }}
                      />
                      <Dialog
                        open={this.state.historyPicker === idx}
                        onClose={() => {
                        }}
                        maxWidth="xs"
                        aria-labelledby="confirmation-dialog-title"
                      >
                        <DialogTitle id="confirmation-dialog-title"
                          style={{textAlign: "center", paddingBottom: 0}}>Choose
                                positions</DialogTitle>
                        <DialogContent style={{padding: "15px 25px 0px 25px"}}>
                          <FormControl>
                            <Picker elements={pickerElements} pickPosition={(P) => {
                              changeHistoryPosition(idx, P);
                              pickHistoryPosition(P, pickerElements);
                            }} />
                          </FormControl>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => {
                            this.handleClose();
                            changeHistoryElement(idx);
                          }} color="primary">
                                  OK
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    <Grid item xs={1} className={classes.differentMaxWidth}>
                      <Input
                        disabled={acceptance}
                        label='Different min'
                        name={`${historyPath}.differentMin`}
                        value={history.differentMin}
                        type={"number"}
                        error={Boolean(errors.differentMin) && touched.differentMin}
                        errorMessage={errors.differentMin}
                        onChange={(e) => changeHistoryDifferentMin(idx, e)}
                      />
                    </Grid>
                    <Grid item xs={1} className={classes.differentMaxWidth}>
                      <Input
                        disabled={acceptance}
                        label='Different max'
                        name={`${historyPath}.differentMax`}
                        value={history.differentMax}
                        type={"number"}
                        error={Boolean(errors.differentMax) && touched.differentMax}
                        errorMessage={errors.differentMax}
                        onChange={(e) => changeHistoryDifferentMax(idx, e)}
                      />
                    </Grid>
                    <Grid item xs={1} className={classes.roundButtonGrid}>
                      <Tooltip title="Remove element" placement="top">
                        <div>
                          <Button
                            disabled={acceptance}
                            variant="contained"
                            color="secondary"
                            className={classes.btnAdd}
                            onClick={() => {
                              removeHistory(idx);
                            }}
                          >
                                  Remove
                          </Button>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2} container alignItems="flex-end">
                      <h3>Average hardness: {avgValue.toFixed(2)}</h3>
                    </Grid>
                  </Grid>
                );
              })}

              <Grid item xs={12}>
                {tables.map((nil, tableIndex) => {
                  const from = tableIndex * countColumns;
                  const to = tableIndex * countColumns + countColumns;

                  const elementsSortedByQuadrants = elementsHistory.reduce((acc, history) => {
                    const positions = history.position.split(", ");
                    const quadrantElements = elements.filter((element) => positions.includes(element.position.toString()));
                    acc.push(...quadrantElements);

                    return acc;
                  }, []);

                  const positionsWithQuadrant = elementsSortedByQuadrants.map((el) => el.position);
                  const elementsWithoutQuadrant = elements.filter((el) => !positionsWithQuadrant.includes(el.position));

                  const data = elementsSortedByQuadrants.concat(elementsWithoutQuadrant);
                  const tableData = data.slice(from, to);

                  return (<React.Fragment key={tableIndex}>
                    <Table style={{width: ((tableData.length + 1) / (countColumns + 1)) * 100 + "%"}}
                      className={classes.table}>
                      <TableBody className={classes.tbody}>
                        <TableRow className={classes.tr}>
                          <TableCell className={classes.td}>Pos</TableCell>
                          {tableData.map((el, idx) => {
                            const hasError = Boolean(getIn(errors, `elements.${el.position - 1}.value`));

                            const showGap = this.getShowGap(el, elementsHistory, tableData, idx);
                            const isStartOfQuadrant = this.getIsStartOfQuadrant(el, elementsHistory);
                            const elementHistory = this.getElementHistory(el, elementsHistory) || {};

                            return (
                              <>
                                <TableCell
                                  className={classNames({
                                    [classes.td]: true,
                                    [classes.error]: hasError
                                  })}
                                  key={idx}
                                >
                                  {isStartOfQuadrant && (
                                    <div className={classes.metalHeader}>
                                      {elementHistory.metal}
                                    </div>
                                  )}
                                  {el.position}
                                </TableCell>
                                {showGap && (
                                  <TableCell
                                    padding="none"
                                    width={10}
                                    className={classes.gap}
                                  />
                                )}
                              </>
                            );
                          })}
                        </TableRow>
                        {(!!min || elementsHistory.some((eh) => eh.differentMin || eh.avgMin)) && (
                          <TableRow className={classes.tr}>
                            <TableCell className={classes.td}>Min</TableCell>
                            {tableData.map((el, idx) => {
                              const hasError = Boolean(getIn(errors, `elements.${el.position - 1}.value`));

                              const elementHistory = this.getElementHistory(el, elementsHistory) || {};

                              const showGap = this.getShowGap(el, elementsHistory, tableData, idx);

                              const min = el.min || null;
                              const avgMin = elementHistory.avgMin ? `Avg. Max: ${elementHistory.avgMin}` : null;

                              return (
                                <>
                                  <TableCell
                                    className={classNames({
                                      [classes.td]: true,
                                      [classes.error]: hasError
                                    })}
                                    key={idx}
                                  >
                                    {min || avgMin}
                                  </TableCell>
                                  {showGap && (
                                    <TableCell
                                      padding="none"
                                      width={10}
                                      className={classes.gap}
                                    />
                                  )}
                                </>
                              );
                            })}
                          </TableRow>
                        )}
                        <TableRow className={classes.tr}>
                          <TableCell className={`${classes.td} ${classes.bold}`}>Value</TableCell>
                          {tableData.map((el, idx) => {
                            const name = `elements.${el.position - 1}.value`;
                            const hasError = Boolean(getIn(errors, name));

                            const showGap = this.getShowGap(el, elementsHistory, tableData, idx);

                            return (
                              <>
                                <TableCell
                                  className={classNames({
                                    [classes.td]: true,
                                    [classes.error]: hasError
                                  })}
                                  key={idx}
                                >
                                  <FormControl className={classes.margin}>
                                    <Input
                                      value={elements[el.position - 1].value}
                                      name={name}
                                      onChange={(e) => change(name, e)}
                                      classes={{
                                        root: classes.bootstrapRoot
                                      }}
                                      placeholder="0"
                                      error={hasError}
                                    />
                                  </FormControl>
                                </TableCell>
                                {showGap && (
                                  <TableCell
                                    padding="none"
                                    width={10}
                                    className={classes.gap}
                                  />
                                )}
                              </>
                            );
                          })}
                        </TableRow>
                        {(!!max || elementsHistory.some((eh) => eh.differentMax || eh.avgMax)) && (
                          <TableRow className={classes.tr}>
                            <TableCell className={classes.td}>Max</TableCell>
                            {tableData.map((el, idx) => {
                              const hasError = Boolean(getIn(errors, `elements.${el.position - 1}.value`));

                              const elementHistory = this.getElementHistory(el, elementsHistory) || {};

                              const showGap = this.getShowGap(el, elementsHistory, tableData, idx);

                              const max = el.max || null;
                              const avgMax = elementHistory.avgMax ? `Avg. Max: ${elementHistory.avgMax}` : null;

                              return (
                                <>
                                  <TableCell
                                    className={classNames({
                                      [classes.td]: true,
                                      [classes.error]: hasError
                                    })}
                                    key={idx}
                                  >
                                    {max || avgMax}
                                  </TableCell>
                                  {showGap && (
                                    <TableCell
                                      padding="none"
                                      width={10}
                                      className={classes.gap}
                                    />
                                  )}
                                </>
                              );
                            })}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </React.Fragment>);
                })}
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={4}>
                  <Input
                    value={notes}
                    name="notes"
                    label="Notes"
                    multiline
                    rows={5}
                    onChange={(e) => change("notes", e)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TestFooter
                  onSubmit={onSubmit}
                  result={this.getResult(props.values)}
                />
              </Grid>
            </Grid>
          );
        }}
      >
      </Formik>
    );
  }
}

export default compose(
  inject("TestStore", "SigningStore", "NotificationStore", "UserStore"),
)(withStyles(observer(HardnessTest), styles));
