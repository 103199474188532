import React, {useMemo, memo} from "react";
import {observer} from "mobx-react-lite";
import {isNil, partition, values} from "ramda";
import useStores from "../../../../../../../../useStores";
import {STALKS_LABEL} from "../../constants";

const AsFabricated = observer(({Component, settings, stalks, tieInWeldsByStalkNumber, ...props}) => {
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;

  const visibleStalks = useMemo(() => {
    if(!isNil(settings.stalkNumber)) return [stalks.find((stalk) => stalk[0].stalkNumber === settings.stalkNumber)];

    return values(stalks);
  }, [settings.stalkNumber, campaign.welds.length]);
  
  const weldedPipes = useMemo(() => {
    const weldedPipes = campaign.welds.reduce((weldedPipes, weld) => weldedPipes.concat([weld.pipeA, weld.pipeB]), []);

    return weldedPipes;
  }, [campaign.welds]);
  
  const baseMaterials = useMemo(() => {
    const baseMaterials = campaign.products.filter((product) => !weldedPipes.includes(product._id));

    return baseMaterials;
  }, [campaign.products, weldedPipes]);
  
  const [quarantinePipes, notWeldedPipes] = useMemo(() => {
    return partition((product) => product.quarantine, baseMaterials);
  }, [baseMaterials]);

  return (
    <Component
      label={STALKS_LABEL}
      stalks={visibleStalks}
      settings={settings}
      baseMaterials={notWeldedPipes}
      quarantinePipes={quarantinePipes}
      tieInWeldsByStalkNumber={tieInWeldsByStalkNumber}
      {...props}
    />
  );
});

export default memo(AsFabricated);