import {Box, ToggleButton, ToggleButtonGroup, Tooltip, useTheme} from "@mui/material";
import {keys} from "ramda";
import React from "react";
import {TABS, TABS_TITLES, TABS_TOOLTIPS} from "../../../../constants";

const ENTER_DELAY = 1500;

const Navigation = ({activeTab, setActiveTab}) => {
  const theme = useTheme();

  return (
    <ToggleButtonGroup
      variant="header"
      value={activeTab}
      exclusive
      onChange={(_, value) => setActiveTab((oldValue) => value || oldValue)}
    >
      {keys(TABS).map((tab) => (
        <ToggleButton
          key={tab}
          value={tab}
        >
          <Tooltip title={TABS_TOOLTIPS[tab]} enterDelay={ENTER_DELAY} enterTouchDelay={ENTER_DELAY} enterNextDelay={ENTER_DELAY}>
            <Box
              sx={{
                color: activeTab === tab ? theme.palette.primary.main : "inherit"
              }}
            >
              {TABS_TITLES[tab]}
            </Box>
          </Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default Navigation;