import React, {useCallback, useMemo} from "react";
import {splitEvery} from "ramda";
import {Grid, Box, Table, TableHead, TableRow, TableCell, TableBody, useTheme} from "@mui/material";
import ResizableDrawer from "@core/components/ResizableDrawer";
import DrawerHeader from "@core/components/DrawerHeader";
import ResultCell from "../ResultCell";
import {TEST_RESULTS} from "@core/constants/testResults";
import SplitDiagonallyTableHeaderCell from "@core/components/SplitDiagonallyTableHeaderCell";

const SPLIT_BY = 2;

const DetailedSpecimen = ({open, close, element, disabled, onSave, activity, test}) => {
  const theme = useTheme();

  const numberOfResultsInRow = useMemo(() => Math.ceil(element?.results.values.length / SPLIT_BY), [open]);
  const halfResults = splitEvery(numberOfResultsInRow, element?.results.values || []);

  const saveResult = useCallback(({values, result}, index) => {
    element.results.values[index] = values[0];
    const testResult = result === TEST_RESULTS.ACCEPTABLE ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
    onSave({values: element.results.values, result: testResult});
  }, [element?.results.values]);

  const specimen = useMemo(() => element?.specimen + (element?.specimenSuffix || ""), [open]);

  return (
    <ResizableDrawer
      open={open}
      close={close}
    >
      <DrawerHeader
        close={close}
        text={`Specimen ID: ${specimen}`}
      />
      <Box sx={{padding: theme.spacing(2.5)}}>
        <Grid container spacing={2}>
          {halfResults.map((results, index) => {
            const position = index * numberOfResultsInRow;

            return (
              <Grid item xs={12}>
                <Table className="styled-table">
                  <TableHead>
                    <TableRow>
                      <SplitDiagonallyTableHeaderCell
                        width="15%"
                        topHeader="Pos"
                        bottomHeader={test.specimenLabel || "Spec ID"}
                      />
                      {results.map((value, index) => (
                        <TableCell key={value.position || index}>{value.position || position + index + 1}</TableCell>
                      ))}
                      <TableCell>Acceptance Criteria</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{specimen}</TableCell>
                      {results.map((value, index) => (
                        <ResultCell
                          key={value.position || index}
                          element={{results: {values: [value]}}}
                          disabled={disabled}
                          onSave={(result) => saveResult(result, position + index)}
                          activity={activity}
                        />
                      ))}
                      <TableCell>{element?.acceptanceCriteria ? element.acceptanceCriteria.map((ac) => ac.label).join(" ,") : "-"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </ResizableDrawer>
  );
};

export default DetailedSpecimen;