import React from "react";
import RouterLink from "@core/components/RouterLink";
import {prop, uniqBy} from "ramda";
import {Button} from "@mui/material";
import importedColumns from "@core/columns/test";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import {STATUSES} from "@core/constants/test";

const WITNESS_TABS = {
  TO_BE_ATTESTED: "to be attested",
  WAITING_FOR_LAB_ACTION: "awaiting lab action",
  ATTESTED: "attested",
  APPROVED_BY_QA: "approved by QA"
};

const WITNESS_TAB_DEFAULT = WITNESS_TABS.TO_BE_ATTESTED;

const NAVIGATION_CONFIG = {
  [WITNESS_TABS.ATTESTED]: [{
    title: "Inspection passed",
    value: WITNESS_STATUSES.ACCEPTED,
    defaultChecked: true,
  }, {
    title: "Inspection not passed",
    value: WITNESS_STATUSES.NOT_ACCEPTED,
    defaultChecked: true,
  }]
};

const WITNESS_STATUSES_BY_TAB = {
  [WITNESS_TABS.TO_BE_ATTESTED]: [STATUSES.INSPECTING],
  [WITNESS_TABS.ATTESTED]: [STATUSES.INSPECTED],
  [WITNESS_TABS.WAITING_FOR_LAB_ACTION]: [STATUSES.ASSIGNED, STATUSES.FILLED],
  [WITNESS_TABS.APPROVED_BY_QA]: [STATUSES.APPROVED]
};

const getColumns = (tab, companyId) => {
  const columns = [
    {
      dataIndex: "inspectionStatus",
      title: "Inspection status",
      render: (test) => {
        if (![STATUSES.APPROVED, STATUSES.INSPECTED].includes(test.status)) return "-";

        const witness = test.witnesses.find((witness) => witness.company._id === companyId);

        if (witness) return witness.status === WITNESS_STATUSES.ACCEPTED ? "Inspection passed" : "Inspection not passed";

        return "-";
      }
    },
    ...importedColumns
  ];

  if (tab !== WITNESS_TABS.WAITING_FOR_LAB_ACTION) columns.unshift({
    dataIndex: "action",
    title: "Action",
    align: "center",
    permanent: true,
    render: (test) => (
      <>
        <Button
          color="primary"
          component={RouterLink}
          to={`/witness/${test._id}`}
        >
          View
        </Button>
        {test.certificateId && (
          <Button
            color="primary"
            component={RouterLink}
            to={`/witness/${test._id}/certificate`}
          >
              View certificate
          </Button>
        )}
      </>
    )
  });

  return uniqBy(prop("dataIndex"), columns);
};

export {WITNESS_STATUSES_BY_TAB, WITNESS_TABS, WITNESS_TAB_DEFAULT, NAVIGATION_CONFIG, getColumns};
