import React from "react";
import {Table, TableBody, TableRow, TableCell, Checkbox, IconButton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const TestTypesCell = ({disabled, tests, selectType, selectedTypes, isRightCell, addTestOfType, removeTestOfType, children, classes}) => (
  <TableCell padding="none" className={classes.rootCell}>
    <Table size="small">
      <TableBody>
        {tests.map((value, index) =>
          <TableRow
            onClick={() => selectType(value.type)}
            key={`${index}${value.title}`}
            hover
          >
            <TableCell
              width="10%"
              className={isRightCell ? classes.rightCell : classes.leftCell}
              classes={{root: classes.checkbox}}
            >
              <Checkbox
                disabled={disabled}
                color="primary"
                checked={Boolean(selectedTypes[value.type])}
              />
              {selectedTypes[value.type] && (
                <>
                  <IconButton
                    className={classes.plusMinusHandler}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeTestOfType(value.type);
                    }}
                    size="large">
                    <RemoveIcon/>
                  </IconButton>
                  {selectedTypes[value.type]}
                  <IconButton
                    className={classes.plusMinusHandler}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      addTestOfType(value.type);
                    }}
                    size="large">
                    <AddIcon/>
                  </IconButton>
                </>
              )}
            </TableCell>
            <TableCell
              width="90%"
              className={isRightCell ? classes.rightCell : classes.leftCell}
              classes={{root: classes.testName}}
            >
              {value.title}
            </TableCell>
          </TableRow>
        )}
        {children}
      </TableBody>
    </Table>
  </TableCell>
);

export default withStyles(TestTypesCell, styles);