export default () => ({
  title: {
    marginTop: "40px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  },
  row: {
    paddingBottom: 10,
    paddingTop: 10,
  }
});