import {makeObservable, observable} from "mobx";
import axios from "axios";
import {TableStore as BaseTableStore} from "@core/stores";
import modules from "@core/constants/modules";
import {STATUSES} from "@core/constants/test";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {INTERFACE_TYPE, ORDER_STATUS} from "@core/constants/transfer";

const DEFAULT_FETCH = {
  search: "",
  limit: 3,
  offset: 0,
  sort: {orderBy: "_id", order: "desc"}
};

class TableStore {
  constructor() {
    makeObservable(this, {
      statistic: observable,
      certificates: observable,
      tests: observable,
      orders: observable,
    });
  }
  statistic = {
    data: {},
    isLoaded: false,
  };

  certificates = new BaseTableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.certificates.fetch, ...queryParams};
      const {offset, limit, sort} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        select: "_id heat properties",
        populate: JSON.stringify([
          {path: "properties.productType", select: "name"}
        ]),
        statuses: [STATUSES.APPROVED],
        statusesStrict: true,
      };

      this.certificates.setFetch(newQueryParams);

      return axios.get(
        ROUTES.CERTIFICATE[ACTIONS.ALL],
        {params}
      );
    },
    fetch: DEFAULT_FETCH
  });

  tests = new BaseTableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.tests.fetch, ...queryParams};
      const {offset, limit, sort, statuses} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        statuses,
      };

      this.tests.setFetch(newQueryParams);

      return axios.get(
        ROUTES.TEST[ACTIONS.ALL],
        {params}
      );
    },
    fetch: DEFAULT_FETCH
  });

  orders = new BaseTableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.orders.fetch, ...queryParams};
      const {offset, limit, sort, module} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        status: ORDER_STATUS.OPEN,
        type: module === modules.END_OWNER ? INTERFACE_TYPE.RECEIVER : INTERFACE_TYPE.SENDER,
      };

      this.orders.setFetch(newQueryParams);

      return axios.get(
        ROUTES.TRANSFER[ACTIONS.ALL],
        {params}
      );
    },
    fetch: DEFAULT_FETCH,
  });

  campaigns = new BaseTableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.campaigns.fetch, ...queryParams};
      const {offset, limit, sort, access} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        access,
      };

      this.campaigns.setFetch(newQueryParams);

      return axios.get(ROUTES.CAMPAIGN[ACTIONS.ALL], {params});
    }
  });
}

export {TableStore};
