import CollapsibleTable from "../../../../CollapsibleTable";
import React from "react";

const ErodingParticle = ({editable}) => {
  return (
    <CollapsibleTable
      editable={editable}
      title="Eroding Particle"
      optionalFields={[{
        name: "sizeDistribution",
        label: "Size Distribution",
      }, {
        name: "shape",
        label: "Shape",
      }, {
        name: "composition",
        label: "Composition",
      }, {
        name: "purity",
        label: "Purity",
      }, {
        name: "source",
        label: "Source",
      }, {
        name: "manufacturingMethod",
        label: "Manufacturing Method",
      }]}
    />
  );
};

export default ErodingParticle;