import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react";
import useStores from "../../../../../useStores";
import {FEATURES} from "@core/constants/features";
import {TITLES} from "@core/components/AppWrapper/constants";
import ConfirmationOnUrlChange from "@core/components/ConfirmationOnUrlChange";
import NotActivatedMessage from "../NotActivatedMessage";
import ZendeskButton from "../ZendeskButton";
import Notification from "@core/components/Modal";
import Content from "./components/Content";

const AppWrapper = ({children}) => {
  const {UserStore} = useStores();

  const user = useMemo(() => UserStore.user.data, [UserStore.user.data]);
  const zendeskFeatureEnabled = useMemo(() => !!UserStore.features[FEATURES.ZENDESK], [UserStore.features]);
  const [module] = user.company.modules;

  useEffect(()=>{
    document.title = "SteelTrace - " + TITLES[module.name];
  },[]);

  return (
    <>
      <ConfirmationOnUrlChange>
        <Content>
          {children}
        </Content>
      </ConfirmationOnUrlChange>
      <NotActivatedMessage />
      <Notification />
      <ZendeskButton
        isZendeskFeatureEnabled={zendeskFeatureEnabled}
      />
    </>
  );
};

export default observer(AppWrapper);