import React from "react";
import {FieldArray, useFormikContext} from "formik";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import AddRowButton from "@core/components/DynamicTestResultsTable/AddRowButton";
import TestResult from "../TestResult";
import {TEST_RESULT} from "../../constants";

const TestResults = ({editable}) => {

  const {values} = useFormikContext();

  return (
    <FieldArray name="testResults">
      {({push, remove}) => {

        return (
          <>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Specimen ID</TableCell>
                  {values.hardnessMeasurementUnit && <TableCell>Hardness</TableCell>}
                  <TableCell>Density</TableCell>
                  <TableCell>Mass Loss</TableCell>
                  <TableCell>Volume Loss</TableCell>
                  <TableCell>
                    Erosion Value
                    {values.acceptanceCriteria && (
                      <span>&nbsp;&#8805;&nbsp;{values.acceptanceCriteria}&nbsp;mm^3/kg</span>
                    )}
                  </TableCell>
                  {editable && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.testResults.map((item, index) => {
                  return (
                    <TestResult
                      key={index}
                      item={item}
                      remove={remove}
                      index={index}
                      editable={editable}
                    />
                  );
                })}
              </TableBody>
            </Table>

            {editable && <AddRowButton push={push} rowCellsValue={TEST_RESULT}/>}
          </>
        );
      }}
    </FieldArray>
  );
};

export default TestResults;