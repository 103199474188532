export default (theme) => ({
  container: {
    display: "flex",
  },
  buttonsContainer: {
    zIndex: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button": {
      padding: theme.spacing(),
      backgroundColor: "#fff",
      borderRadius: 4,
      marginBottom: theme.spacing(),
      "&:hover": {
        backgroundColor: "#fff"
      }
    },
    "& button:last-child": {
      marginBottom: 0
    }
  },
});