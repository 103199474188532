import React from "react";
import {observer} from "mobx-react-lite";
import {STATUSES} from "@core/constants/test";
import {Button} from "@mui/material";
import useStores from "../../../../../../useStores";

const SignAllButton = () => {
  const {CertificateStore} = useStores();

  const certificate = CertificateStore.certificate.data;

  const onOpen = () => {
    if (certificate.transactions.length) return;

    CertificateStore.openForceSignCertificate();
  };

  return (
    <Button
      onClick={onOpen}
      variant={"contained"}
      size={"medium"} 
      color={"primary"}
      disabled={!certificate.tests ||
        certificate.tests.length < 1 ||
        certificate.tests.every((test) => test.status === STATUSES.APPROVED)
      }
    >
      Approve all tests
    </Button>
  );
};

export default observer(SignAllButton);