import {Divider, Box, Typography} from "@mui/material";
import React from "react";

const ParsedTable = ({children, title}) => {
  return (
    <>
      <Divider />
      <Box sx={{padding: 2, paddingBottom: 1}}>
        <Typography variant="h6" marginBottom>{title}</Typography>
        {children}
      </Box>
    </>
  );
};

export default ParsedTable;