import React, {useState} from "react";
import {Grid, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Input} from "@core/components/Form";
import styles from "./styles";

const AddTestDataForm = ({classes, setFieldValue, tests}) => {
  const [itemHeat, setItemHeat] = useState("");
  const [lotId, setLotId] = useState("");

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5" className={classes.title}>
          Default
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Input
            label="Heat"
            value={itemHeat}
            onChange={(event) => {
              setItemHeat(event.target.value);
              const testsWithItemHeat = tests.map((test) => ({...test, item_heat: event.target.value}));
              setFieldValue("tests", testsWithItemHeat);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            label="Lot Id"
            value={lotId}
            onChange={(event) => {
              setLotId(event.target.value);
              const testsWithLotId = tests.map((test) => ({...test, lotId: event.target.value}));
              setFieldValue("tests", testsWithLotId);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(AddTestDataForm, styles);
