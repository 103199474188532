import {getIsWithinRequirements} from "@core/helpers";
import {TestResult} from "./index";

export class ChemicalCompositionResult extends TestResult {
  elements = [];

  constructor(elements) {
    super();
    this.elements = elements;
    this.buildResult();
  }

  buildResult() {
    this.isAcceptable = this.elements.every((element) => {
      return getIsWithinRequirements(element.value, element.min, element.max);
    });
  }
}