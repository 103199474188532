export default (theme) => ({
  transferInfo: {
    marginTop: 20,
    padding: "15px 30px",
    marginBottom: 20,
    backgroundColor: "#e0e0e0",
    "& h6": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "& b": {
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
});