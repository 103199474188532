import {TableStore} from "@core/stores";
import axios from "axios";
import {action, makeObservable, observable} from "mobx";
import {isEmpty} from "ramda";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class ProductTypesStore {
  constructor() {
    makeObservable(this, {
      productTypes: observable,
      removeProduct: action,
      updateProduct: action,
    });
  }
  productTypes = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.productTypes.fetch, ...queryParams};
      const {offset, limit, search, sort} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value: search},
      };

      this.productTypes.setFetch(newQueryParams);

      return axios.get(
        ROUTES.PRODUCT_TYPE[ACTIONS.ALL],
        {params}
      );
    }
  });

  async removeProduct(id) {
    await axios.delete(ROUTES.PRODUCT_TYPE[ACTIONS.REMOVE](id));

    this.productTypes.data = this.productTypes.data.filter((product) => product._id !== id);
  }

  async updateProduct(data, id) {
    const response = await axios.patch(ROUTES.PRODUCT_TYPE[ACTIONS.UPDATE](id), data);

    this.productTypes.data = this.productTypes.data.map((product) => product._id === id ? response.data : product);

    return response.data;
  }
}

export default new ProductTypesStore();
