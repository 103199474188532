import {getIsWithinRequirements, getMean} from "@core/helpers";
import {TestResult} from "./index";

export class ImpactResult extends TestResult {
  elements = [];
  requirements = {};
  
  constructor(elements, requirements) {
    super();
    this.elements = elements;
    this.requirements = requirements;
    this.buildResult();
  }
  
  checkSingle(values = [], single) {
    return !single || values.every((value) => getIsWithinRequirements(value, single));
  }
  
  checkAverage(values = [], average) {
    const mean = getMean(values);

    return !average || getIsWithinRequirements(mean, average);
  }
  
  checkJoule = (values = [], orientation) => {
    const {single, average} = this.requirements[orientation] || {};

    return this.checkSingle(values, single) && this.checkAverage(values, average);
  };

  checkShearArea = (values = [], orientation) => {
    const {singleShearArea, averageShearArea} = this.requirements[orientation] || {};

    return this.checkSingle(values, singleShearArea) && this.checkAverage(values, averageShearArea);
  };
  checkLateralExpansion = (values = [], orientation) => {
    const {singleLateralExpansion, averageLateralExpansion} = this.requirements[orientation] || {};

    return this.checkSingle(values, singleLateralExpansion) && this.checkAverage(values, averageLateralExpansion);
  };
  
  buildResult() {
    this.isAcceptable = this.elements.every((element) => {
      const energyJouleAcceptable = this.checkJoule([element.energyJoule1, element.energyJoule2, element.energyJoule3], element.orientation);
      
      const shearAreaAcceptable = this.checkShearArea([element.shearArea1, element.shearArea2, element.shearArea3], element.orientation);
      
      const lateralExpansionAcceptable = this.checkLateralExpansion([element.lateralExpansion1, element.lateralExpansion2, element.lateralExpansion3], element.orientation);

      return energyJouleAcceptable && shearAreaAcceptable && lateralExpansionAcceptable;
    });
  }
}