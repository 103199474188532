import React, {useCallback, memo, useMemo, useRef} from "react";
import {Rect} from "react-konva";
import {
  REPAIRED_WELD_CONFIG,
  TIE_IN_WELD_CONFIG,
} from "../../constants";
import {getIsTieInWeld, getWeldIsRepaired} from "../../../../../../helpers";
import Defect from "../Defect";

const AnimatedWeld = ({tooltipData, settings, animateShape, weldNumber, index, welds, pipes, pipeCoordinates, weldCoordinates, setOpenWeld, pipeConfig, weldConfig, defects}) => {
  const weld = useRef();

  const maxScaleWeldY = useMemo(() => (pipeConfig.HEIGHT - weldConfig.HEIGHT * weldConfig.MAX_SCALE) / 2, []);
  const midScalePipeY = useMemo(() => -(pipeConfig.HEIGHT * pipeConfig.MEDIUM_SCALE - pipeConfig.HEIGHT) / 2, []);

  const backgroundColor = useMemo(() => {
    const isRepaired = getWeldIsRepaired(weldNumber);

    if(isRepaired && settings.highlightRepairedWelds) return REPAIRED_WELD_CONFIG.BACKGROUND_COLOR;

    const isTieInWeld = getIsTieInWeld(weldNumber);

    if(isTieInWeld && settings.tieInWelds) return TIE_IN_WELD_CONFIG.BACKGROUND_COLOR;

    return weldConfig.BACKGROUND_COLOR;
  }, [settings.tieInWelds, settings.highlightRepairedWelds, weldNumber]);

  const onMouseEnter = useCallback((e) => {
    document.body.style.cursor = "pointer";

    weld.current.setAttr("data", tooltipData);

    e.cancelBubble = true;

    if(!animateShape) return;

    const currentWeld = welds.current[index];
    const prevPipe = pipes.current[index];
    const nextPipe = pipes.current[index + 1];

    animateShape(currentWeld, {
      scaleX: weldConfig.MAX_SCALE,
      scaleY: weldConfig.MAX_SCALE,
      y: maxScaleWeldY,
      offsetX: 2.5,
    });

    animateShape(prevPipe, {
      scaleX: pipeConfig.MEDIUM_SCALE,
      scaleY: pipeConfig.MEDIUM_SCALE,
      y: midScalePipeY,
      offsetX: 2,
    });

    animateShape(nextPipe, {
      scaleX: pipeConfig.MEDIUM_SCALE,
      scaleY: pipeConfig.MEDIUM_SCALE,
      y: midScalePipeY,
      offsetX: 2,
    });
  }, [index]);

  const onMouseLeave = useCallback((e) => {
    document.body.style.cursor = "default";

    e.cancelBubble = true;

    if(!animateShape) return;

    const weld = welds.current[index];
    const prevPipe = pipes.current[index];
    const nextPipe = pipes.current[index + 1];

    animateShape(weld, {
      y: weldCoordinates.y,
      scaleX: 1,
      scaleY: 1,
      offsetX: 0,
    });

    animateShape(prevPipe, {
      y: pipeCoordinates.y,
      scaleX: 1,
      scaleY: 1,
      offsetX: 0,
    });

    animateShape(nextPipe, {
      y: pipeCoordinates.y,
      scaleX: 1,
      scaleY: 1,
      offsetX: 0,
    });
  }, [index]);

  return (
    <>
      <Rect
        id={weldNumber}
        perfectDrawEnabled={false}
        ref={(element) => {
          welds.current[index] = element;
          weld.current = element;
        }}
        fill={backgroundColor}
        y={weldCoordinates.y}
        x={weldCoordinates.x}
        width={weldConfig.WIDTH}
        height={weldConfig.HEIGHT}
        cornerRadius={weldConfig.RADIUS}
        shadowForStrokeEnabled={false}
        hitStrokeWidth={weldConfig.HIT_STROKE_WIDTH}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => setOpenWeld(weldNumber)}
      />
      {settings.highlightCoatingDefects && (
        <Defect
          settings={settings}
          defects={defects}
          config={weldConfig}
          coordinates={weldCoordinates}
        />
      )}
    </>
  );
};

export default memo(AnimatedWeld);