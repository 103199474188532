export default (theme) => ({
  attestSectionContainer: {
    padding: "20px 0 20px !important",
    textAlign: "center",
    backgroundColor: "rgba(206, 206, 206, 0.31)",
    borderRadius: 5
  },

  attestTitle: {
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 17,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 18
  },

  approve: {
    backgroundColor: "#4AAF4D",
    color: "#ffffff",

    "&:hover": {
      backgroundColor: "#4ea24e",
    }
  },

  decline: {
    backgroundColor: "#e91e63",
    color: "#ffffff",

    "&:hover": {
      backgroundColor: "#d61e5d",
    }
  }
});

