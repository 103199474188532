import React from "react";
import {Button, Grid} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import {FieldArray, useFormikContext} from "formik";
import FormGroupListItemLayout from "../../FormGroupListItemLayout";

function EndProducts({getEndProduct}) {
  const {values, isSubmitting} = useFormikContext();
  
  return (
    <FieldArray name="properties.product.nominalSizes">
      {({remove, push}) => values.properties.product.nominalSizes.map((product, index) => (
        <>
          <FormGroupListItemLayout index={index} sx={{rowGap: 3, maxWidth: "100%"}}>
            <Grid item flex={1}>
              <TextField
                disabled={values.properties.product.qcp || isSubmitting}
                required
                type="number"
                label='Quantity'
                name={`properties.product.nominalSizes.${index}.quantity`}
              />
            </Grid>
            {values.properties.product.productType.properties.map((property) => (
              <Grid key={property.name} item flex={1}>
                <TextField
                  disabled={values.properties.product.qcp || isSubmitting}
                  required
                  label={property.label}
                  name={`properties.product.nominalSizes.${index}.${property.name}`}
                  endAdornment={property.measurements}
                />
              </Grid>
            ))}
            {/* {values.qcp && ( */}
            {/*   <Grid item flex={1}> */}
            {/*     <TextField */}
            {/*       disabled={isSubmitting} */}
            {/*       label='ITP / QCP - Item' */}
            {/*       name={`properties.product.nominalSizes.${index}.qcpItem`} */}
            {/*     /> */}
            {/*   </Grid> */}
            {/* )} */}
            <Grid item justifySelf="flex-end">
              <Button
                disabled={values.properties.product.nominalSizes.length === 1 || values.qcp || isSubmitting}
                onClick={() => remove(index)}
                variant="contained"
                color="secondary"
              >
                Remove
              </Button>
            </Grid>
          </FormGroupListItemLayout>
          {values.properties.product.nominalSizes.length - 1 === index && (
            <Grid item xs={12} marginTop={3}>
              <Button
                disabled={values.properties.product.qcp || isSubmitting}
                onClick={() => {
                  const endProduct = getEndProduct(values.properties.product.productType.properties);
                  push(endProduct);
                }}
                variant="contained"
              >
                Add product
              </Button>
            </Grid>
          )}
        </>
      ))}
    </FieldArray>
  );
}

export default EndProducts;