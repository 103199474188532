import React, {memo} from "react";
import {Group, Line} from "react-konva";
import {PAGE_PADDING} from "../../constants";

const CurvedLine = ({points, fill, strokeWidth, stroke}) => {
  return (
    <Group listening={false}>
      <Line
        listening={false}
        points={points}
        sceneFunc={(context, shape) => {
          const points = shape.points();

          context.beginPath();
          context.moveTo(points[0],points[1]);

          if (points.length === 4) {
            context.lineTo(points[2],points[3]);
          } else {
            let n = 0;

            while (n < points.length-4) {
              const deltaX1 = (points[n+2] - points[n+0]);
              const deltaY1 = (points[n+3] - points[n+1]);
              const br1 = Math.min(PAGE_PADDING / 2, Math.max(Math.abs(deltaX1/2),Math.abs(deltaY1/2)));

              const deltaX2 = (points[n+2+2] - points[n+0+2]);
              const deltaY2 = (points[n+3+2] - points[n+1+2]);
              const br2 = Math.min(PAGE_PADDING / 2,Math.max(Math.abs(deltaX2/2),Math.abs(deltaY2/2)));

              const br = Math.min(br1,br2);

              const oneX = points[n+0] + (Math.abs(deltaX1) - br)*Math.sign(deltaX1);
              const oneY = points[n+1] + (Math.abs(deltaY1) - br)*Math.sign(deltaY1);

              context.lineTo(oneX, oneY);
              n+=2;
              const twoX = points[n+0] + (br)*Math.sign(deltaX2);
              const twoY = points[n+1] + (br)*Math.sign(deltaY2);

              context.quadraticCurveTo(points[n+0], points[n+1],twoX, twoY);
            }
            context.lineTo(points[points.length-2],points[points.length-1]);
          }

          context.strokeShape(shape);
        }}
        fill={fill}
        strokeWidth={strokeWidth}
        stroke={stroke}
        shadowForStrokeEnabled={false}
        hitStrokeWidth={0}
      />
    </Group>
  );
};

export default memo(CurvedLine);