import React, {useMemo, memo} from "react";
import {observer} from "mobx-react-lite";
import {Group} from "react-konva";
import DefaultPipe from "../DefaultPipe";
import {PIPE_TYPES} from "../../../../constants";
import QuarantinedPipe from "../QuarantinedPipe";
import DoubleRedBandPipe from "../DoubleRedBandPipe";
import SingleRedBandLine from "../SingleRedBandPipe";
import CoatedPipe from "../CoatedPipe";
import TypePipe from "../TypePipe";
import BlackBandedAnodePipe from "../BlackBandedAnodePipe";
import TransitionPipe from "../TransitionPipe";
import useStores from "../../../../../../../../../../useStores";
import SequenceNumber from "../../../SequenceNumber";

const PIPE_COMPONENT_BY_PIPE_TYPE = {
  [PIPE_TYPES.DOUBLE_RED_BAND]: DoubleRedBandPipe,
  [PIPE_TYPES.SINGLE_RED_BAND]: SingleRedBandLine,
  [PIPE_TYPES.BLACK_BANDED_ANODE_JOINT]: BlackBandedAnodePipe,
  [PIPE_TYPES.TRANSITION_JOINT]: TransitionPipe,
  [PIPE_TYPES.QUARANTINED]: QuarantinedPipe,
  [PIPE_TYPES.COATED]: CoatedPipe,
  [PIPE_TYPES.WITH_TYPE]: TypePipe,
};

const Pipe = observer(({scale, totalLength, stalkLength, pipeLength, index, settings, pipes, welds, animateShape, setOpenPipe, pipeConfig, weldConfig, id, jointNumber, pipeCoordinates, weldCoordinates, defects}) => {
  const {CampaignStore} = useStores();

  const baseMaterialsById = CampaignStore.campaignProductsById;
  const coatingTypesByPipeNumber = CampaignStore.coatingTypesByPipeNumber;

  const lastPipePosition = useMemo(() => stalkLength + 1, [stalkLength]);

  const pipe = useMemo(() => baseMaterialsById[id] || {}, [id]);

  const PipeComponent = useMemo(() => {
    if(pipe.quarantine && settings.quarantine) {
      return PIPE_COMPONENT_BY_PIPE_TYPE[PIPE_TYPES.QUARANTINED];
    }

    if(coatingTypesByPipeNumber[pipe.pipeNumber] && settings.coatingType) {
      return PIPE_COMPONENT_BY_PIPE_TYPE[PIPE_TYPES.COATED];
    }

    if(pipe.type && settings.type) {
      return PIPE_COMPONENT_BY_PIPE_TYPE[PIPE_TYPES.WITH_TYPE];
    }

    // if(settings.anodes) return PIPE_COMPONENT_BY_PIPE_TYPE[pipe.anode];

    return DefaultPipe;
  }, [pipe.type, settings.anodes, settings.quarantine, settings.coatingType, settings.type]);

  return (
    <Group>
      <SequenceNumber
        stalkLength={lastPipePosition}
        scale={scale}
        pipeCoordinates={pipeCoordinates}
        index={index}
        pipeConfig={pipeConfig}
      />
      <PipeComponent
        index={index}
        pipes={pipes}
        welds={welds}
        animateShape={animateShape}
        settings={settings}
        id={jointNumber}
        tooltipData={{
          joint: jointNumber,
          pipe: pipe.pipeNumber,
          type: pipe.type,
          length: pipeLength,
          totalLength,
        }}
        pipeCoordinates={pipeCoordinates}
        weldCoordinates={weldCoordinates}
        setOpenPipe={setOpenPipe}
        pipe={pipe}
        pipeConfig={pipeConfig}
        weldConfig={weldConfig}
        defects={defects}
      />
    </Group>
  );
});

export default memo(Pipe);