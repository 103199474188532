import {TEST_RESULTS} from "@core/constants/testResults";
import {Parser} from "hot-formula-parser";
import {isNil} from "ramda";

const parseToNumber = (number = 0) => {
  const string = number.toString();

  return parseFloat(string.replace("/,/g", "."));
};

/**
 * @name isValueInLimitRange
 * @param {number | string} value - the value of chemical element
 * @param {number | string} min - lowest acceptable limit
 * @param {number | string} max - highest acceptable limit
 * @param {number | string} maxError - highest agreed acceptable limit
 * @param {string} zone - zone value of chemical composition test
 * @returns {boolean}
 * @summary - does value fit in the limit range
 */
const isValueInLimitRange = ({min, max, maxError, required = true}, zone, value) => {
  const minimum = parseToNumber(min) || 0;
  const maximum = maxError ? parseToNumber(maxError) : parseToNumber(max);

  if (zone === "Weld" || (!minimum && !maximum) || (!value && !required)) return true;

  if (!maximum) return value >= minimum;

  return value >= minimum && value <= maximum;
};

/**
 * @name calculatePren
 * @param {array} elements  - array of values from chemical from
 * @param {string} formula - custom formula to calculate pren
 * @param {string} field - value property name
 * @returns {number}
 * @summary - calculates PREN field using (Cr+ 3.3*Mo + 16*N) formula
 */
const calculateElementValue = (elements, formula, field) => {
  const elementNames = elements.filter((el) => el.bm !== "Pren").map((el) => el.bm);
  const parser = new Parser;
  elementNames.forEach((name) => {
    parser.setVariable(name, elements.find((el) => el.bm === name)[field]);
  });

  const result = parser.parse(formula).result;

  if (!result) return 0;

  return parseFloat(result.toFixed(2));
};

const getIsValueAcceptable = (elements, field) => {
  return elements.every(({required = true, ...el}) => {
    const max = el.maxError ? Number(el.maxError) : Number(el.max);
    const isBiggerThanMax = !!max && el[field] > max;

    const isLessThanMin = el[field] < el.min;

    const isEmpty = isNil(el[field]) || el[field] === "";

    return (!isBiggerThanMax && !isLessThanMin) || (isEmpty && !required);
  });
};

/**
 * @name getResult
 * @param {array} elements  - array of values from chemical from
 * @param {boolean} additionalAnalysis - has additional analysis
 * @returns {string} - Acceptable || Not Acceptable
 * @summary - returns result word for chemical composition depending on its values
 */
const getResult = (elements, additionalAnalysis) => {
  const isValueAcceptable = getIsValueAcceptable(elements, "value");
  const isSecondValueAcceptable = !additionalAnalysis || getIsValueAcceptable(elements, "secondValue");
  const result = isValueAcceptable && isSecondValueAcceptable;

  return result ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
};

function validateElementMax(value) {
  const {grade, acceptance} = this.from[1].value;
  const config = grade?.elements?.find((e) => e.bm === this.parent.bm) || {};

  const max = acceptance ? Infinity : config.maxError || config.max;

  return !isNil(max) && value > max ? this.createError({message: `Should be \u2264 ${max}`, path: this.path}) : true;
}

function validateElementMin(value) {
  const {grade} = this.from[1].value;
  const config = grade?.elements?.find((e) => e.bm === this.parent.bm) || {};

  return !isNil(config.min) && value < config.min ? this.createError({message: `Should be \u2265 ${config.min}`, path: this.path}) : true;
}

export {getResult, calculateElementValue, isValueInLimitRange, validateElementMax, validateElementMin};
