export default (theme) => ({
  elements: {
    marginBottom: 15,
    borderBottom: "1px solid rgba(224, 224, 224, 1)"
  },

  row: {
    marginBottom: 10
  },

  field: {
    fontWeight: theme.typography.fontWeightMedium,
    color: "#616161"
  },

  label: {
    color: "#616161"
  },

  greenField: {
    color: "#4caf50",
    fontWeight: theme.typography.fontWeightMedium,
  },

  redField: {
    color: "#f44336",
    fontWeight: theme.typography.fontWeightMedium,
  }
});
