import {MEASUREMENTS_CONFIG} from "@core/components/TestForms/DimensionalTest/data";
import {PO_ITEMS} from "@core/constants/poItems";
import {values, filter, isEmpty} from "ramda";
import React, {useMemo} from "react";
import {useTheme, Box} from "@mui/material";
import AcceptanceTable from "./components/AcceptanceTable";
import GlobalAcceptance from "./components/GlobalAcceptance";

const getIsDependantOnPoItem = (localConfig, poItem) => {
  const {nominalFormula, tolerancePlusFormula, toleranceMinusFormula} = localConfig || {};
  
  const nominalValue = nominalFormula && nominalFormula.bind(localConfig)({}, poItem);
  const tolerancePlusValue = tolerancePlusFormula && tolerancePlusFormula.bind(localConfig)({}, poItem);
  const toleranceMinusValue = toleranceMinusFormula && toleranceMinusFormula.bind(localConfig)({}, poItem);
  
  return nominalValue || tolerancePlusValue || toleranceMinusValue;
};

const DimensionalTest = ({qcpConfig, qcp}) => {
  const theme = useTheme();
  const poItems = useMemo(() => values(PO_ITEMS).filter((poItem) => {
    return Object.keys(qcpConfig.data).some((measurement) => {
      if(MEASUREMENTS_CONFIG[measurement].locations) {
        return MEASUREMENTS_CONFIG[measurement].locations.some((location) => {
          const name = location === "body" ? location : "ends";

          return getIsDependantOnPoItem(qcpConfig.data[measurement][name], poItem);
        });
      }

      return getIsDependantOnPoItem(qcpConfig.data[measurement], poItem);
    });
  }), []);

  const globalConfig = filter((config) => config.globalCriteria, qcpConfig.data);

  return (
    <>
      {!isEmpty(globalConfig) && (
        <>
          <h3>Global Requirements</h3>
          <GlobalAcceptance
            globalConfig={globalConfig}
            qcp={qcp}
          />
        </>
      )}
      {poItems.length ? (
        poItems.map((poItem) => (
          <Box key={poItem} sx={{marginTop: theme.spacing()}}>
            <h3>QCP Item {poItem}</h3>
            <AcceptanceTable
              poItem={poItem}
              config={qcpConfig}
            />
          </Box>
        ))
      ) : (
        <>
          <h3>Local Requirements</h3>
          <AcceptanceTable
            config={qcpConfig}
          />
        </>
      )}
    </>
  );
};

export default DimensionalTest;