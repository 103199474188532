import React, {useRef, useState} from "react";
import {QRCodeCanvas} from "qrcode.react";
import {host} from "Config";
import {Popover} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const GeneratedQR = ({classes, certificate}) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef();

  return (
    <>
      <img
        className={classes.smallQr}
        ref={anchorEl}
        src="/images/qr_code.svg"
        onClick={() => setOpen(true)}
      />
      &nbsp;
      <Popover
        anchorEl={anchorEl.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
      >
        <QRCodeCanvas
          value={`${host}/certificates/${certificate._id}`}
          imageSettings={{
            src: "/images/logo-symbol-only-black.png",
            height: 60,
            width: 60,
          }}
          size={170}
        />
      </Popover>
    </>
  );
};

export default withStyles(GeneratedQR, styles);