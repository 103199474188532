import TableNavigation from "@core/components/TableNavigation";
import testConfig from "@core/configs/test";
import {TYPES} from "@core/constants/test";
import {TEST_RESULTS} from "@core/constants/testResults";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Tooltip, Box, Table, TableCell, TableRow, TableHead, TableBody, Chip} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {omit, toPairs, concat, mergeWith, groupBy, keys, values, indexBy, prop, flatten} from "ramda";
import {observer} from "mobx-react-lite";
import useStores from "../../../../../../useStores";
import {getRangeValue, getAverage} from "./services";
import {getIsWithinRequirements, getDecimals} from "@core/helpers";

const HANDLERS_BY_TEST_TYPE = {
  [TYPES.TENSILE]: {
    yieldStrengthRange: (elements) => getRangeValue(elements, "rp"),
    tensileStrengthRange: (elements) => getRangeValue(elements, "rm"),
  },
  [TYPES.HARDNESS]: {
    average: (elements) => getAverage(elements, "value"),
  },
};

const GlobalTestStatistics = ({certificates}) => {
  const [qcpItem, setQcpItem] = useState(null);

  const {QcpStore} = useStores();

  useEffect(() => {
    QcpStore.qcps.load({limit: 100, offset: 0});
  }, []);

  const qcps = QcpStore.qcps.data;

  const qcpsGrouppedByName = useMemo(() => groupBy((qcp) => qcp.name + qcp.qcpItem, qcps), [qcps.length]);

  const qcpsById = useMemo(() => indexBy(prop("_id"), qcps), [qcps.length]);

  const testsByQcpId = useMemo(() => {
    return certificates.reduce((acc, certificate) => {
      const testsGrouppedByQcpId = groupBy((test) => {
        const qcpName = test.properties.acceptance;
        const qcpItem = test.properties.acceptanceItem || certificates.lineItem;

        const [qcp] = qcpsGrouppedByName[qcpName + qcpItem] || [];

        return qcp?._id;
      }, certificate.tests);

      return mergeWith(concat, acc, omit([undefined], testsGrouppedByQcpId));
    }, {});
  }, [certificates.length, qcps.length]);

  const usedQcpsIds = keys(testsByQcpId) || [];

  const qcpsByQcpItem = useMemo(() => {
    const qcps = usedQcpsIds.map((id) => qcpsById[id]);

    return groupBy((qcp) => qcp.qcpItem, qcps);
  }, [usedQcpsIds.length]);

  const qcpItems = keys(qcpsByQcpItem);

  const qcpsInView = qcpsByQcpItem[qcpItem] || [];

  const displayTable = useMemo(() => {
    return usedQcpsIds.some((id) => qcpsById[id].tests.filter((test) => test.globalAcceptanceCriteria).length);
  }, [usedQcpsIds.length]);

  const getTestsResult = useCallback((acceptanceCriteria, tests, type) => {
    const {formula} = acceptanceCriteria.value;

    const elements = flatten(tests.map((test) => test.properties.elements));

    const handler = formula ? eval(formula) : HANDLERS_BY_TEST_TYPE[type][acceptanceCriteria.name];

    return handler(elements);
  }, [certificates.length]);

  const getTestsAcceptable = useCallback((acceptanceCriteria, tests, type) => {
    const {max, min} = acceptanceCriteria.value;

    const testsResult = getTestsResult(acceptanceCriteria, tests, type);

    return getIsWithinRequirements(testsResult, min, max);
  }, [certificates.length]);

  useEffect(() => {
    if(qcpItem) return;

    setQcpItem(qcpItems[0]);
  }, [qcpItems]);

  if(!displayTable) return null;

  return (
    <>
      <Box
        sx={{
          margin: (theme) => `${theme.spacing(3)} 0 ${theme.spacing()} 0`
        }}
      >
        <h3>
          Global Test Statistics
        </h3>
      </Box>
      <TableNavigation
        titles={qcpItems.reduce((acc, qcpItem) => ({...acc, [qcpItem]: `QCP Item ${qcpItem}`}), {})}
        views={qcpItems}
        currentView={qcpItem}
        onClick={setQcpItem}
      />
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell>QCP/ITP</TableCell>
            <TableCell>Test Type</TableCell>
            <TableCell>Test Quantity</TableCell>
            <TableCell>Acceptance Criteria</TableCell>
            <TableCell>Numerical Result</TableCell>
            <TableCell>Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {qcpsInView.map((qcp) => {
            const tests = testsByQcpId[qcp._id] || [];
            const testGroupedByType = groupBy(prop("type"), tests);

            const acceptanceCriteriaTests = keys(testGroupedByType).reduce((acc, type) => {
              const test = qcp.tests.find((t) => t.type === type) || {};

              if(test.globalAcceptanceCriteria) acc[type] = test.globalAcceptanceCriteria;

              return acc;
            }, {});

            const testsNumber = flatten(values(acceptanceCriteriaTests)).length;

            return toPairs(acceptanceCriteriaTests).map(([type, acceptanceCriteria], testTypeIndex) => {
              return acceptanceCriteria.map((acc, acceptanceCriteriaIndex) => {

                const result = getTestsResult(acc, testGroupedByType[type], type);
                const isAcceptable = getTestsAcceptable(acc, testGroupedByType[type], type);

                const {max = 0, min = 0} = acc.value || {};

                const decimalNumber = Math.max(getDecimals(max), getDecimals(min));

                return (
                  <TableRow key={acc.name + qcp._id + acceptanceCriteriaIndex}>
                    {!acceptanceCriteriaIndex && !testTypeIndex && (
                      <TableCell
                        rowSpan={testsNumber}
                      >
                        {qcp.name}
                      </TableCell>
                    )}
                    {!acceptanceCriteriaIndex && (
                      <>
                        <TableCell
                          rowSpan={acceptanceCriteria.length}
                        >
                          {testConfig[type].title}
                        </TableCell>
                        <TableCell
                          rowSpan={acceptanceCriteria.length}
                        >
                          {testGroupedByType[type].length}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      {acc.formulaLabel ? (
                        <Tooltip title={acc.formulaLabel}>
                          <Box sx={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            {acc.label}
                            &nbsp;
                            <HelpOutlineIcon
                              fontSize="small"
                            />
                          </Box>
                        </Tooltip>
                      ) : acc.label}
                    </TableCell>
                    <TableCell>
                      <Box sx={{color: (theme) => isAcceptable ? theme.palette.success.text : theme.palette.error.text}}>
                        {`${result.toFixed(decimalNumber)} ${acc.value.unit || ""}`}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip
                        size="small"
                        label={isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE}
                        sx={{
                          backgroundColor: (theme) => isAcceptable ? theme.palette.success.backgroundColor : theme.palette.error.backgroundColor,
                          color: (theme) => isAcceptable ? theme.palette.success.text : theme.palette.error.text,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              });
            });
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default observer(GlobalTestStatistics);