import * as React from "react";
import {styled} from "@mui/material/styles";
import {IconButton} from "@mui/material";

const ExpandMore = styled((props) => {
  return <IconButton {...props} size="small" />;
})(({theme, expand}) => ({
  width: "fit-content",
  padding: 0,
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandMore;