export default (theme) => ({
  infoCell: {
    fontWeight: theme.typography.fontWeightMedium,
    color: "#616161",
  },
  table: {
    whiteSpace: "nowrap",
  },
});

