export default () => ({
  datePicker: {
    "& .rmdp-arrow": {
      borderTopColor: "#0088d1",
      borderRightColor: "#0088d1",
      borderBottomColor: "#0088d1",
      borderLeftColor: "#0088d1",
    },
    "& .rmdp-week-day": {
      color: "#0088d1",
    },
    "& .rmdp-day.rmdp-today span": {
      backgroundColor: "#0088d1",
    },
    "& .rmdp-range": {
      backgroundColor: "#0088d1",
    },
    "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
      backgroundColor: "#0088d1",
    },
  },
  pickerInput: {
    outline: "none",
    borderRadius: 4,
    border: "1px solid #c4c4c4",
    color: "#616161",
    padding: 5,
    minWidth: 160,
  }
});