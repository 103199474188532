import React from "react";
import {MenuItem, Grid, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import tests from "@core/configs/test";
import SelectField from "@core/components/FormikSelect";
import styles from "./styles";
import HtmlEditor from "@core/components/HtmlEditor";

const Form = ({classes, onSubmit, validationSchema, initialValues}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({values, setFieldValue, handleSubmit, isValid}) => (
        <Grid container spacing={3} className={classes.container}>
          {initialValues.hasOwnProperty("type") && (
            <Grid item xs={3}>
              <SelectField
                name="type"
                label='Test, declaration or general'
                required
              >
                <MenuItem value="Heat">Heat</MenuItem>
                <MenuItem value="Norm">Norm</MenuItem>
                <MenuItem value="Lot ID">Lot ID</MenuItem>
                <MenuItem value="TP UNS">TP UNS</MenuItem>
                <MenuItem value="Grade">Grade</MenuItem>
                {Object.values(tests).map((t) => (
                  <MenuItem value={t.title}>{t.title}</MenuItem>
                ))}
              </SelectField>
            </Grid>
          )}
          <Grid item xs={12}>
            <HtmlEditor
              value={values.message}
              onChange={(contentState) => setFieldValue("message", contentState)}
            />
          </Grid>
          <Grid item xs={1} container alignItems="flex-end">
            <Button
              disabled={!isValid}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default withStyles(Form, styles);