export default () => ({
  arrow: {
    marginRight: 2,
  },
  testLink: {
    "&:hover" : {
      cursor: "pointer"
    }
  },
  graphIcon: {
    padding: 0,
    marginLeft: 10,
  },
});
