import React, {useEffect} from "react";
import {observer} from "mobx-react";
import Certificate from "@core/components/DisabledCertifcate";
import useStores from "../../useStores";

const OutgoingCertificate = ({match}) => {
  const {CertificateStore} = useStores();

  const {data: certificate, isLoaded} = CertificateStore.certificate;

  useEffect(() => {
    CertificateStore.getCertificateByTestId(match.params.id);
  }, []);

  return (
    <Certificate
      certificate={certificate}
      isLoaded={isLoaded}
    />
  );
};

export default observer(OutgoingCertificate);
