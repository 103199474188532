
export default () => ({
  infoMessage: {
    display: "flex",
    fontSize: 16,
    justifyContent: "center",
  },

  info: {
    backgroundColor: "#1976d2",
  },

  success: {
    backgroundColor: "#4caf50"
  },

  error: {
    backgroundColor: "#d02d52"
  },

  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  text: {
    paddingLeft: 20
  }
});
