import React, {useEffect} from "react";
import {Grid, Box, useTheme} from "@mui/material";
import SubNavigation from "../../../components/SubNavigation";
import GeneralInfo from "./components/GeneralInfo";
import Navigation from "./components/Navigation";
import FilterButton from "./components/FilterButton";
import {SUB_TABS_BY_TAB} from "../../constants";

const Header = ({activeTab, activeSubTab, showFilters, setActiveTab, setActiveSubTab, toggleFilters}) => {
  const theme = useTheme();

  useEffect(() => {
    setActiveSubTab(SUB_TABS_BY_TAB[activeTab] ? SUB_TABS_BY_TAB[activeTab][0] : null);
  }, [activeTab]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: theme.spacing(2.5),
          paddingBottom: 0,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GeneralInfo />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Navigation
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Grid>
              <Grid item>
                <FilterButton
                  showFilters={showFilters}
                  toggleFilters={toggleFilters}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {!!SUB_TABS_BY_TAB[activeTab] && (
        <SubNavigation
          activeSubTab={activeSubTab}
          setActiveSubTab={setActiveSubTab}
          subTabs={SUB_TABS_BY_TAB[activeTab]}
        />
      )}
    </>
  );
};

export default Header;