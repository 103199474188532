import React, {useState} from "react";
import {Grid, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {isEmpty} from "ramda";
import {Input} from "@core/components/Form";
import Properties from "@core/components/Properties";
import {capitalizeFirstLetter} from "@core/helpers";
import Certificate from "../Certificate";
import styles from "./styles";

const SplitByQuantity = ({classes, items, productType, newItems, setNewItems, groupItems, setGroupItems}) => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={8}>
        {items.map((item, index) => {
          const [quantity, setQuantity] = useState("");

          const splitted = newItems.reduce((acc, i) => {
            if (i._id !== item._id) return acc;

            return acc + Number(i.quantity);
          }, 0);

          const remaining = Number(item.quantity) - splitted;

          return (
            <div className={classes.item}>
              <div className={classes.productType}>
                <Typography variant="h6">
                  {`Item ${index + 1}: ${capitalizeFirstLetter(productType.name)}`}
                </Typography>
              </div>
              <Grid container spacing={1} wrap="nowrap" className={classes.propertiesList}>
                <Grid item xs={2}>
                  {`Q: ${item.quantity}`}
                </Grid>
                <Properties
                  item={item}
                  productType={productType}
                />
              </Grid>
              <Grid container spacing={5}>
                <Grid xs={4} item>
                  <Input
                    disabled={!remaining}
                    inputProps={{min: 1, max: remaining}}
                    type="number"
                    label="Quantity"
                    name="quantity"
                    value={quantity}
                    onChange={({target}) => setQuantity(target.value)}
                  />
                </Grid>
                <Grid xs={2} item container alignItems="flex-end">
                  <Button
                    disabled={!remaining || quantity >= remaining || !Number(quantity) || quantity < 0}
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      if (!Number(quantity)) return;

                      if (quantity > remaining) return;

                      setNewItems([...newItems, {_id: item._id, quantity}]);
                      setQuantity("");
                    }}
                  >
                    Split
                  </Button>
                </Grid>
                <Grid xs={6} item container alignItems="flex-end">
                  <span className={classes.remainingQuantity}>{`Remaining: ${remaining}`}</span>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </Grid>
      {!isEmpty(newItems) && (
        <Grid item xs={4} container spacing={1} direction="column">
          <Grid item container wrap="nowrap" direction="column" spacing={2} className={classes.certificatesContainer}>
            {!groupItems && newItems.map((item, index) => (
              <Grid item xs={12}>
                <Certificate
                  newItems={newItems}
                  setNewItems={setNewItems}
                  productType={capitalizeFirstLetter(productType.name)}
                  indexInNewItems={index}
                />
              </Grid>
            ))}
            {groupItems && (
              <Grid item xs={12}>
                <Certificate
                  newItems={newItems}
                  setNewItems={setNewItems}
                  productType={capitalizeFirstLetter(productType.name)}
                  groupItems
                />
              </Grid>
            )}
          </Grid>
          {groupItems && (
            <>
              <Grid item>
                <Input
                  label="Lot ID"
                  value={newItems[0].lotId}
                  onChange={(e) => {
                    const items = newItems.map((i) => ({...i, lotId: e.target.value}));
                    setNewItems(items);
                  }}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Notes"
                  value={newItems[0].notes}
                  onChange={(e) => {
                    const items = newItems.map((i) => ({...i, notes: e.target.value}));
                    setNewItems(items);
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <FormControlLabel
              classes={{root: classes.checkbox}}
              control={
                <Checkbox
                  checked={groupItems}
                  onChange={(e) => setGroupItems(e.target.checked)}
                  color="primary"
                />
              }
              label="Group items into one certificate"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(SplitByQuantity, styles);