import React, {useRef} from "react";
import {Group, Rect, Line, Text} from "react-konva";
import {STAGE_CONFIG} from "../../../../constants";
import {TOOLTIP_PROGRESS_BAR_CONFIG} from "../../../Stalk/constants";

const PROGRESS_TEXT_X_MIN = 0;

const getProgressTextX = (progressX, textRef, contentWidth) => {
  const textWidth = textRef?.width();
  
  const textMiddleX = progressX - textWidth / 2;
  
  if(textMiddleX < PROGRESS_TEXT_X_MIN) return PROGRESS_TEXT_X_MIN;

  if(contentWidth - textMiddleX < textWidth) return contentWidth - textWidth;
  
  return textMiddleX;
};

const LengthProgressBar = ({data, config}) => {
  const text = useRef();

  if(!data.length || ! data.totalLength) return null;

  const contentWidth = config.WIDTH - 2 * config.PADDING;

  const progressX = contentWidth * data.length / data.totalLength;
 
  const progressTextX = getProgressTextX(progressX, text.current, contentWidth);

  return (
    <Group
      y={config.HEIGHT - config.PADDING - TOOLTIP_PROGRESS_BAR_CONFIG.HEIGHT}
    >
      <Rect
        fill={TOOLTIP_PROGRESS_BAR_CONFIG.BACKGROUND_COLOR}
        width={contentWidth}
        height={TOOLTIP_PROGRESS_BAR_CONFIG.HEIGHT}
        cornerRadius={TOOLTIP_PROGRESS_BAR_CONFIG.RADIUS}
      />
      <Text
        ref={text}
        y={-config.MARGIN - config.FONT_SIZE}
        x={progressTextX}
        text={`${data.length}m`}
        fill={config.TEXT_COLOR}
        fontSize={config.GENERAL_TEXT_FONT_SIZE}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        fontStyle="bold"
      />
      <Line
        stroke={TOOLTIP_PROGRESS_BAR_CONFIG.STROKE_COLOR}
        strokeWidth={TOOLTIP_PROGRESS_BAR_CONFIG.STROKE_WIDTH}
        points={[progressX, -TOOLTIP_PROGRESS_BAR_CONFIG.LINE_HEIGHT, progressX, TOOLTIP_PROGRESS_BAR_CONFIG.HEIGHT + TOOLTIP_PROGRESS_BAR_CONFIG.LINE_HEIGHT]}
        fill={TOOLTIP_PROGRESS_BAR_CONFIG.STROKE_COLOR}
      />
    </Group>
  );
};

export default LengthProgressBar;