module.exports = {
  api: process.env.REACT_APP_API || "http://localhost:5000",
  host: process.env.REACT_APP_HOST || "http://localhost:4000",
  blockscout: process.env.REACT_APP_BLOCKSCOUT_URL || "http://localhost:4000",
  portal: process.env.REACT_APP_PORTAL_API || "http://localhost:1337",
  zendeskEnabled: JSON.parse(process.env.ZENDESK),
  sentryEnabled: JSON.parse(process.env.SENTRY),
  sentryDsn: process.env.SENTRY_DSN,
  environment: process.env.ENVIRONMENT || "development",
  version: process.env.VERSION || "0.0.0",
};