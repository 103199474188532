import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import moment from "moment";

export const columns = [{
  dataIndex: "period",
  title: "Period",
}, {
  isSorted: true,
  dataIndex: "activeUsers",
  title: "Monthly Active Users",
}, {
  isSorted: true,
  dataIndex: "activeCompanies",
  title: "Monthly Active Companies",
}, {
  isSorted: true,
  dataIndex: "newProducts",
  title: "Monthly Certified Products",
}, {
  isSorted: true,
  dataIndex: "newCampaigns",
  title: "Monthly New Campaigns",
}, {
  isSorted: true,
  dataIndex: "newUsers",
  title: "Monthly New Users",
}, {
  isSorted: true,
  dataIndex: "date_created",
  title: "Date Created",
  render: (metrics) => moment(metrics.date_created).format(DATE_TIME_FORMAT)
}];