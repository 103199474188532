export default (theme) => ({
  content: {
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
  },
  title: {
    margin: `${theme.spacing(3)} 0 ${theme.spacing()} 0`
  },
  progressBar: {
    marginTop: theme.spacing(2),
  }
});