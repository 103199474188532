import React, {useEffect, useState} from "react";
import {Dialog, Button, Typography, Grid, DialogContent, MenuItem, DialogTitle} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {Formik, FieldArray} from "formik";
import styles from "./styles";
import * as yup from "yup";
import {keys, omit, map, indexBy, prop, values as getValues} from "ramda";
import draftToHtml from "draftjs-to-html";
import {EditorState, convertToRaw} from "draft-js";
import HtmlEditor from "@core/components/HtmlEditor";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import {FilesUploader} from "@core/components/Uploaders";
import ProductTypesAutocomplete from "@core/components/ProductTypeAutocomplete";
import {getProductTypeFields} from "@core/helpers/getProductTypeFields";
import ConvertHistory from "../../../components/Summary/ConvertHistory";
import useStores from "../../../../useStores";
import WS from "@core/api/socketConnection";
import {CertificateService} from "@core/services";

const validationSchema = yup.object().shape({
  deliveryCondition: yup.string().nullable(true),
  certificateType: yup.string().required("The field is required!"),
  properties: yup.object().shape({
    grade: yup.string().required("The field is required!"),
    productType: yup.object().required("Product type is required!"),
    norm: yup.string().required("Material specification is required"),
    lotId: yup.string().required("Lot ID is required!")
  }),
  items: yup.array().of(yup.lazy((item) =>
    yup.object(
      map(() => yup.string().required("This field is required"), item)
    ))).required(),
  markingText: yup.string().nullable(),
  markingFiles: yup.array().of(yup.string()),
  notes: yup.object()
});

const NOTES = EditorState.createEmpty();

const Convert = ({
  classes,
  convertOpened,
  onClose,
  certificate,
  product
}) => {
  const {CertificateStore, NotificationStore, SigningStore} = useStores();

  const productProperties = (product && product.properties) || [];
  const [properties, setProperties] = useState(productProperties);
  const [values, setValues] = useState({});
  const [convertAllowed, setConvertAllowed] = useState(false);

  const convert = async () => {
    await CertificateStore.convertProductType(certificate, {
      ...values,
      notes: convertToRaw(values.notes.getCurrentContent()).blocks[0].text ? draftToHtml(convertToRaw(values.notes.getCurrentContent())) : ""
    });
    await CertificateStore.getCertificateProductById(values.properties.productType._id);
    onClose();

    setConvertAllowed(false);
    NotificationStore.showSuccess("Product has been converted");
  };

  useEffect(() => {
    WS.listen("transaction:certificate:convert", (data) => {
      SigningStore.closeSigner();

      if (data.status !== "ACCEPTED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      setConvertAllowed(true);
    });
  }, []);

  useEffect(() => {
    if (convertAllowed) convert();
  }, [convertAllowed]);

  const onSubmit = async (values) => {
    setValues(values);
    await CertificateService.convert([values]);
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={convertOpened}
      onClose={onClose}
    >
      <DialogTitle>Convert product</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h5" variant="h5">
              Current item
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ConvertHistory
              grade={certificate.properties.grade}
              uns={certificate.uns}
              certificateType={certificate.certificateType}
              items={certificate.items}
              properties={certificate.properties}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h5" variant="h5">
              Convert to
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                ...certificate,
                notes: NOTES
              }}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {(props) => {
                const {
                  values,
                  isValid,
                  handleSubmit,
                  setFieldValue
                } = props;

                const {norms: allNorms} = useFetchedCertificateNorms();

                const norms = allNorms.filter((n) => n.Norm === values.properties.norm);
                const normsByMaterial = indexBy(prop("Material"), norms) || {};

                const normChange = ({norm, value}) => {
                  setFieldValue("properties.norm", norm);

                  if (!value) {
                    setFieldValue("properties.grade", "");

                    return;
                  }

                  if (value.length === 1) {
                    const [grade] = value;
                    materialGradeChange(grade);

                    return;
                  }

                  setFieldValue("properties.grade", "");
                };

                const materialGradeChange = (value) => {
                  value && setFieldValue("properties.grade", value.Material);
                };

                return (
                  <Grid container spacing={2}>
                    <Grid item container spacing={2}>
                      <Grid item xs={3}>
                        <NormAutocomplete
                          label="Material Specification"
                          name="properties.norm"
                          testType="certificate"
                          onChange={normChange}
                          required
                        />
                      </Grid>

                      {values.properties.norm && (
                        <Grid item xs={3}>
                          <SelectField
                            value={values.properties.grade}
                            label="Grade / UNS"
                            name="properties.grade"
                            onChange={(value) => materialGradeChange(normsByMaterial[value])}
                            required
                          >
                            {getValues(normsByMaterial).map((item) => (
                              <MenuItem key={item.Material} value={item.Material}>{item.Material}</MenuItem>
                            ))}
                          </SelectField>
                        </Grid>
                      )}

                      {normsByMaterial[props.values.properties.grade]?.DeliveryConditions && (
                        <Grid item xs={3}>
                          <SelectField
                            label="Delivery condition"
                            name="deliveryCondition"
                          >
                            {keys(normsByMaterial[props.values.properties.grade].DeliveryConditions).map((item) => (
                              <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))}
                          </SelectField>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item xs={2}>
                        <TextField
                          label='Lot ID'
                          name='properties.lotId'
                          required
                          className={classes.internalId}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <SelectField
                          name="certificateType"
                          label='Certificate type'
                          required
                        >
                          {["3.1", "3.2"].map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item xs={2}>
                        <ProductTypesAutocomplete
                          inputProps={{
                            label: "Product type",
                            name: "properties.productType.internalName",
                            required: true
                          }}
                          onChange={(value) => {
                            if (value.name === values.properties.productType.name) return;

                            if (!value) {
                              setFieldValue("properties.productType", "");
                              setFieldValue("items", []);
                            } else {
                              setProperties(value.properties);
                              setFieldValue("properties.productType", value);
                              setFieldValue("items", [{quantity: "", ...getProductTypeFields(value.properties)}]);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>

                    <FieldArray name="items">
                      {({remove}) => values.items.map((item, index) => (
                        <Grid item container spacing={2}>
                          <Grid item xs={2}>
                            <TextField
                              name={`items.${index}.quantity`}
                              label="Quantity"
                              required
                            />
                          </Grid>

                          {keys(omit(["quantity", "_id"], item)).map((name) => {
                            const property = properties && properties.find((p) => p.name === name);

                            if (!property) return null;

                            return (
                              <Grid item xs={2}>
                                <TextField
                                  label={property.label}
                                  name={`items.${index}.${name}`}
                                  required
                                  endAdornment={property.measurements}
                                />
                              </Grid>
                            );
                          })}

                          {values.items.length > 1 && (
                            <Grid item xs={1} container alignItems="flex-end">
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </FieldArray>

                    <Grid item container spacing={5}>
                      <Grid item>
                        <Button
                          className={classes.addButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const items = values.items.concat(getProductTypeFields(properties));
                            setFieldValue("items", items);
                          }}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid item xs={10}>
                      <TextField
                        multiline
                        rows={3}
                        label='Marking'
                        name='markingText'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FilesUploader
                        name="markingFiles"
                        files={values.markingFiles}
                        onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                        changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                      />
                    </Grid>

                    <Grid item>
                      <HtmlEditor
                        label="Notes"
                        value={values.notes}
                        onChange={(contentState) => setFieldValue("notes", contentState)}
                      />
                    </Grid>

                    <Grid item container spacing={5} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          size={"large"}
                          variant={"contained"}
                          color={"primary"}
                          onClick={handleSubmit}
                          disabled={!isValid}
                        >
                          Convert
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(Convert, styles);
