import React from "react";
import {
  Grid,
  DialogContent,
  Divider,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExaminationConditions from "@core/components/ExaminationConditions";
import TestFormElectromagneticInduction from "@core/components/TestForms/ElectromagneticInduction";
import styles from "./styles";

const ElectromagneticInduction = ({classes, test, addDataOpen, dataDialogClose, updateTest, formRef}) => {
  return (
    <>
      {(test.properties.client && test.properties.lab) && (
        <Grid container alignContent="space-between" spacing={5}>
          <Grid item xs={6}>
            <h4 className={classes.name}>Client</h4>
            <p className={classes.value}>{test.properties.client}</p>
          </Grid>

          <Grid item xs={6}>
            <h4 className={classes.name}>Laboratory</h4>
            <p className={classes.value}>{test.properties.lab}</p>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>

        <Grid item xs={12} alignContent="space-between">
          <Grid container={12} spacing={1}>
            <Grid item xs={12}>
              <h3>Test parameters:</h3>
            </Grid>
            <Grid item xs={12}>
              <Table className={"styled-table"}>
                <TableHead>
                  <TableRow>
                    {test.properties.testStandard && <TableCell>Test standard</TableCell>}
                    {test.properties.acceptance && <TableCell>Acceptance criteria</TableCell>}
                    {test.properties.surfaceCondition &&<TableCell>Surface condition</TableCell>}
                    {test.properties.examinedSurface && <TableCell>Examined surface</TableCell>}
                    {test.properties.pieceIdentification && <TableCell>Piece Identification</TableCell>}
                    {test.properties.quantityInspected && <TableCell>Quantity Inspected</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {test.properties.testStandard && <TableCell>{test.properties.testStandard}</TableCell>}
                  {test.properties.acceptance && <TableCell>{test.properties.acceptance}</TableCell>}
                  {test.properties.surfaceCondition && <TableCell>{test.properties.surfaceCondition}</TableCell>}
                  {test.properties.examinedSurface && <TableCell>{test.properties.examinedSurface}</TableCell>}
                  {test.properties.pieceIdentification && <TableCell>{test.properties.pieceIdentification}</TableCell>}
                  {test.properties.quantityInspected && <TableCell>{test.properties.quantityInspected}</TableCell>}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ExaminationConditions
            test={test}
            file={test.properties.file}
          />
        </Grid>
        <Grid item xs={12} alignContent="space-between">
          <Grid container={12} spacing={1}>
            <Grid item xs={12}>
              <h3>Calibration:</h3>
            </Grid>
            <Grid item xs={12}>
              <Table className={"styled-table"}>
                <TableHead>
                  <TableRow>
                    <TableCell>Notches location</TableCell>
                    <TableCell>Notches dimensions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {test.properties.elements?.map((element) => (
                    <>
                      <TableRow key={element.id}>
                        {element.notchesLocation && <TableCell>{element.notchesLocation}</TableCell>}
                        {element.width && <TableCell>{element.width}MM x {element.length}MM x {element.depth}{element.depthUnit ? element.depthUnit.toUpperCase() : "MM"}</TableCell>}
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      {test.properties.notes && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <p
              className={classes.values}
              dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
            />
          </Grid>
        </Grid>
      )}

      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <TestFormElectromagneticInduction
            formRef={formRef}
            test={test}
            isProducerTest={true}
            saveTest={updateTest}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(ElectromagneticInduction, styles);
