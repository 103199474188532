import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import {Button} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {ConfirmationOnUrlChangeContext} from "./context";
import {SHOULD_BLOCK_DEFAULT} from "@core/constants/shouldBlockDefault";

function ConfirmationOnUrlChangeNew({children}) {
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState("Are you sure you want to log out?");
  const [leaveText, setLeaveText] = useState("Log Out");
  const [stayText] = useState("Cancel");
  const history = useHistory();
  const location = useLocation();
  const [shouldBlock, setShouldBlock] = useState(() => SHOULD_BLOCK_DEFAULT);
  const isLogout = useCallback((nextPagePathname) => nextPagePathname.includes("/logout"), []);
  const unblock = useRef(() => {});
  const nextLocation = useRef({});
  
  useEffect(() => {
    unblock.current = history.block(({pathname, search}) => {
      nextLocation.current = {pathname, search};
      let willBlock;
      
      if (isLogout(pathname)) {
        willBlock = true;
        setAlertText("Are you sure you want to log out?");
        setLeaveText("Log Out");
      } else if (shouldBlock()) {
        willBlock = true;
        setAlertText("Your content has not been saved yet! Are you sure you want to leave this page?");
        setLeaveText("Leave");
      }
      
      if (willBlock) {
        setOpen(true);
        
        return false;
      }
      
      return true;
    });
    
    return () => {
      unblock.current();
    };
  }, [shouldBlock, location.pathname]);
  
  const onStay = () => {
    setOpen(false);
  };
  
  const onLeave = () => {
    setOpen(false);
    unblock.current();
    history.push({...nextLocation.current});
  };
  
  const providerValue = useMemo(() => ({setShouldBlock}), []);
  
  return (
    <>
      <ConfirmationOnUrlChangeContext.Provider value={providerValue}>
        {children}
      </ConfirmationOnUrlChangeContext.Provider>
      <Confirmation
        open={open}
        alertText={alertText}
      >
        <Button onClick={onLeave} color="secondary">
          {leaveText}
        </Button>
        <Button onClick={onStay} variant="contained" color="primary">
          {stayText}
        </Button>
      </Confirmation>
    </>
  );
}

export default ConfirmationOnUrlChangeNew;