export const TEST_STANDARDS = [
  "API 5L Table E.7 (46 edition)",
  "ASTM E570 (2020)",
  "ASTM E309 (2016)",
  "ISO 10893-3 (2011)",
  "ISO 10893-2 (2011)"
];

export const ACCEPTANCE_CRITERIA = [
  "API 5L Table E.8 (46 edition)"
];

export const EXAMINED_SURFACES = [
  "100% of surface",
  "Bevel ends",
  "External surface",
  "Internal surface",
  "Welds"
];