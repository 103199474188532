import React, {useRef, useState} from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {Popover, MenuList, IconButton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const ActionColumn = ({classes, children}) => {
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  return <>
    <IconButton
      ref={anchorEl}
      classes={{root: classes.icon}}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
      size="large">
      <MoreHorizIcon />
    </IconButton>
    <Popover
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      open={open}
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      anchorEl={anchorEl.current}
    >
      <MenuList
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
      >
        {children}
      </MenuList>
    </Popover>
  </>;
};

export default withStyles(ActionColumn, styles);