export default (theme) => ({
  attestSectionContainer: {
    border: "2px dashed #adadad",
    backgroundColor: "rgba(255, 206, 31, 0.085)"
  },

  buttons: {
    display: "flex",
    justifyContent: "center"
  },

  formula: {
    display: "flex",
    alignItems: "flex-end",

    "& > div": {
      color: "#909090",
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: 15,
    }
  },

  table: {
    marginBottom: 35,

    "& td": {
      padding: 0,
      textAlign: "center",
      border: "1px solid #e6e6e6"
    },
    "& td:last-child": {
      padding: 0,
    },
    "& th": {
      padding: 0,
      textAlign: "center"
    },
    "& th:last-child": {
      padding: 0,
      width: 56,
    },
    "& input": {
      padding: "13px 5px",
    }
  },

  cellSubtitle: {
    display: "flex",
    marginTop: 10,

    "& span": {
      flex: 1
    }
  },
  tableExceptions: {
    "& tr": {
      height: 35,
    },

    "& td": {
      padding: "5px 20px 0 20px",
      border: "none"
    }
  },
  tooltipCell: {
    color: "#bf8210",
    cursor: "pointer"
  },
  tableCellContainer: {
    display: "flex",
    "& div": {
      marginRight: 10
    },
    "& div:last-child": {
      marginRight: 0
    }
  },
  noBorderRight: {
    borderRight: "none!important"
  },
  noBorderLeft: {
    borderLeft: "none!important"
  },
  lateralTh: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      color: "#2196f3"
    }
  },
  hiddenCell: {
    display: "none"
  },
  satisfactory : {
    color: "#4caf50",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },

  unsatisfactory: {
    color: "#d02d52",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },

  item: {
    "& td": {
      padding: 4,
    }
  },

  addButton: {
    margin: 5,
  }
});

