import React from "react";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {openFileInNewTab} from "@core/helpers/openFileInNewTab";
import styles from "./styles";

const InstrumentsList = ({classes, instruments}) => {
  if(!instruments?.length) return null;

  return (
    <Grid item xs={12} className={classes.instruments}>
      <h4>Instruments used:</h4>
      <p>
        {instruments.map((instrument, index) => (
          <>
            {!!index && ", "}
            {(instrument.files?.length) ? (
              <a
                className={classes.equipmentFile}
                onClick={() => openFileInNewTab(instrument.files[0])}
                target="_blank"
              >
                {instrument.equipment}
              </a>
            ) : instrument.equipment}
          </>
        ))}
      </p>
    </Grid>
  );
};

export default withStyles(InstrumentsList, styles);