export default (theme) => ({
  paper: {
    width: 1100,
    padding: 40,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#575757",
  },
  productInfoTable: {
    padding: "0 20px"
  },
  sectionName: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18,
  },
  sectionValue: {
    fontSize: 16,
  },
  markingImage: {
    "& .preloadImg": {
      width: 100,
      height: 100,
      padding: "17px",
      fontSize: "17px",
    },
    "& .avatar": {
      width: 100,
      height: 100,
      margin: "23px 0 15px 0",
    }
  }
});
