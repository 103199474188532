import {deepOrange, deepPurple, blue, green} from "@mui/material/colors";

export default () => ({
  actionSelect: {
    margin: "10px 0"
  },

  notActivated: {
    backgroundColor: "#ffe6e6"
  },

  thCheckbox: {
    padding: "4px 0 4px 24px",
    width: 30
  },

  thPadding: {
    padding: "4px 0 4px 24px",
  },

  userPadding: {
    padding: 0
  },

  roleWidth: {
    width: 200
  },

  paddingForCheckbox: {
    padding: "0 24px"
  },

  logoAndName: {
    display: "flex",
    alignItems: "center"
  },

  avatar : {
    margin: "10px 10px 10px 0 !important"
  },

  userName: {
    height: 30
  },

  visible: {
    visibility: "visible"
  },

  hidden: {
    visibility: "hidden"
  },

  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500],
  },

  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500],
  },

  greenAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: green[500],
  },

  blueAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor:  blue[500],
  }
});

