export default () => ({
  btnAddCell: {
    textAlign: "center"
  },

  btnAdd: {
    margin: "0 auto",
    width: "50%"
  },

  gridRoot: {
    marginTop: 20,
    marginBottom: 20
  },

  width100: {
    width: "100%"
  },

  saveButton: {
    marginLeft: "auto",
  }
});

