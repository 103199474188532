import React from "react";
import PasswordChangeForm from "@core/components/PasswordChangeForm";
import useStores from "../../../../useStores";

const PasswordChange = ({userId, open, onClose}) => {
  const {ExternalUsersStore} = useStores();

  const changePassword = async (userId, password) => {
    await ExternalUsersStore.changePassword(userId, password);
  };

  return (
    <PasswordChangeForm
      userId={userId}
      open={open}
      changePassword={changePassword}
      onClose={onClose}
    />
  );
};

export default PasswordChange;
