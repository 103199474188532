import React, {useEffect} from "react";
import {Formik} from "formik";
import {observer} from "mobx-react";
import {Button, Grid, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import * as yup from "yup";
import {omit} from "ramda";
import draftToHtml from "draftjs-to-html";
import {convertToRaw, EditorState} from "draft-js";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import {TestService} from "@core/services";
import {FilesUploader} from "@core/components/Uploaders";
import SelectField from "@core/components/FormikSelect";
import HtmlEditor from "@core/components/HtmlEditor";
import styles from "./styles";
import useStores from "../../../useStores";
import WS from "@core/api/socketConnection";
import {STATUSES} from "@core/constants/test";

const validationSchema = yup.object().shape({
  notes: yup.object().test("notes", "This field is required!", function(value) {
    return this.parent.status !== WITNESS_STATUSES.NOT_ACCEPTED || value.getCurrentContent().getPlainText("\u0001");
  }),
  status: yup.number().min(WITNESS_STATUSES.ACCEPTED).required(),
  witness: yup.string().required("This field is required!"),
  files: yup.array().of(yup.string())
});

const initialValues = {
  notes: EditorState.createEmpty(),
  status: WITNESS_STATUSES.UNSET,
  witness: "",
  files: []
};

const WitnessAttest = ({classes, test, children, onClose}) => {
  const {TestStore, SigningStore, NotificationStore} = useStores();

  const onSubmit = async (values) => {
    const data = omit(["notes", "witness"], values);

    if(values.notes.getCurrentContent().getPlainText("\u0001")) {
      data.notes = draftToHtml(convertToRaw(values.notes.getCurrentContent()));
    }

    await TestStore.witnessReview(test._id, data, values.witness);

    const witnesses = test.witnesses.map((witness) => {
      if (witness.company._id !== values.witness) return witness;

      return ({...witness, status: values.status});
    });
    const updatedTest = {...test, witnesses};
    await TestService.attestTests([updatedTest], values.witness);
  };

  useEffect(() => {
    WS.listen("transaction:test:witness", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      TestStore.changeTest({status: STATUSES.INSPECTED});
      NotificationStore.showSuccess("Successfully witnessed!");

      if (onClose) onClose();
    });
  }, []);

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(props) => {

        useEffect(() => {
          if (!test.witnesses.length || test.witnesses.length > 1) return;

          const [witness] = test.witnesses;
          props.setFieldValue("witness", witness.company._id);
        }, []);

        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                className={classes.notAcceptedButton}
                size="large"
                variant="contained"
                color={props.values.status === WITNESS_STATUSES.NOT_ACCEPTED ? "primary" : "inherit"}
                onClick={() => props.setFieldValue("status", WITNESS_STATUSES.NOT_ACCEPTED)}
              >
                Not accepted
              </Button>
              <Button
                className={classes.acceptedButton}
                size="large"
                variant="contained"
                color={props.values.status === WITNESS_STATUSES.ACCEPTED ? "primary" : "inherit"}
                onClick={() => props.setFieldValue("status", WITNESS_STATUSES.ACCEPTED)}
              >
                Accepted
              </Button>
            </Grid>
            {test.witnesses.length > 1 && (
              <Grid item xs={4}>
                <SelectField
                  name='witness'
                  label='Witness'
                  required
                >
                  {test.witnesses.map((witness) => (
                    <MenuItem key={witness.company._id} value={witness.company._id}>
                      {witness.company.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={classes.editor}>
                <HtmlEditor
                  label="Notes"
                  value={props.values.notes}
                  onChange={(contentState) => props.setFieldValue("notes", contentState)}
                  error={props.errors.notes}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <FilesUploader
                name="files"
                files={props.values.files}
                onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
              />
            </Grid>
            <Grid item container justifyContent="flex-end" spacing={3}>
              {children}
              <Grid item>
                <Button
                  fullWidth
                  disabled={!props.isValid}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={props.handleSubmit}
                >
                  Sign
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default observer(withStyles(WitnessAttest, styles));