import React from "react";
import {withStyles} from "tss-react/mui";

import styles from "./styles";

const EntityNotFound = ({entity, classes}) => (
  <div className={classes.wrapper}>
    {entity} not found!
  </div>
);

export default withStyles(EntityNotFound, styles);