import {PO_ITEMS} from "@core/constants/poItems";

export const EXCEPTIONS = {
  "ITP-BPM-22.20 Rev.6": {
    company: ["Berg Pipe", "Tremblay Inc."],
    temperature: {
      value: "0",
      notes: "Specimen location: Pipe body (90° from the longitudinal seam)",
      data: {
        singleShearArea: 70,
        averageShearArea: 85
      }
    }
  },
  get "ITP-BPM-22.20 Rev.6 MPQT"() {
    return this["ITP-BPM-22.20 Rev.6"];
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_1]: 3,
        [PO_ITEMS.PO_ITEM_2]: -14,
      },
      data: {}
    }
  },
  "QCP 5982 Rev. 4 (Transition curve)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      transitionCurve: true,
      temperaturesByPoItem: {
        [PO_ITEMS.PO_ITEM_1]: [{
          value: 13,
        }, {
          value: 3,
        }, {
          value: -7,
        }, {
          value: -17,
        }, {
          value: -27,
        }, {
          value: -37,
        }],
        [PO_ITEMS.PO_ITEM_2]: [{
          value: 6,
        }, {
          value: -4,
        }, {
          value: -14,
        }, {
          value: -24,
        }, {
          value: -34,
        }, {
          value: -44,
        }],
      },
      data: {}
    }
  },
};

export const WIDTH = 76.2;
export const NOTCH_TYPES = ["Pressed", "Chevron"];
export const FRACTURE_TYPES = ["Normal", "Inverse"];
export const TEST_STANDARDS = ["API RP 5L3 - 2014 (Add. 2020, Err. 1 2021)"];
export const ELEMENT = {
  specimen: "",
  location: "",
  position: "",
  orientation: "",
  fractureType: "",
  temperature: "",
  shearArea: "",
};