import ActionCell from "@core/components/ActionCell";
import {Input} from "@core/components/Form";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import TableSkeleton from "@core/components/TableSkeleton";
import {Button, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {map, prop} from "ramda";
import React, {useEffect, useState} from "react";
import {withStyles} from "tss-react/mui";
import useStores from "../../useStores";
import PresetForm from "./components/PresetForm";
import styles from "./styles";

const StatementsPresetsList = observer(({classes}) => {
  const {StatementPresetsStore} = useStores();

  const [statementToDelete, setStatementToDelete] = useState(null);
  const [presetFormOpen, setPresetFormOpen] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState({});
  const [presets, setPresets] = useState([]);

  const {isLoaded} = StatementPresetsStore;

  useEffect(() => {
    StatementPresetsStore.getPresets();
  }, []);

  useEffect(() => {
    setPresets(StatementPresetsStore.presets);
  }, [StatementPresetsStore.presets]);

  const onSearch = (event) => {
    const value = event.target.value;

    let filtered;

    if(!value) filtered = StatementPresetsStore.presets;
    else filtered = StatementPresetsStore.presets.filter((preset) => preset.name.toLowerCase().includes(value.toLowerCase()));

    setPresets(filtered);
  };

  return (
    <div className="padded-container">
      <Grid container spacing={5} marginBottom={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Statement Presets
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedPreset({});
              setPresetFormOpen(true);
            }}
          >
            Create new
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={12}>
          <Input
            className={classes.input}
            name='search'
            placeholder={"Search presets by name"}
            onChange={onSearch}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} marginBottom={2}>
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Statements</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {isLoaded ? (
              <TableBody>
                {presets.length ? presets.map((preset) => (
                  <TableRow key={preset._id}>
                    <TableCell>{preset.name}</TableCell>
                    <TableCell>{preset.statements.length}</TableCell>
                    <TableCell>
                      <ActionCell>
                        <MenuItem
                          onClick={() => {
                            setSelectedPreset({
                              ...preset,
                              statements: map(prop("value"), preset.statements),
                            });
                            setPresetFormOpen(true);
                          }}
                        >
                            Edit
                        </MenuItem>
                        <MenuItem
                          className={classes.deleteButton}
                          onClick={() => setStatementToDelete(preset._id)}
                        >
                              Delete
                        </MenuItem>
                      </ActionCell>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={4}>No presets found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableBody>
                  <TableSkeleton columns={3}/>
                </TableBody>
              </TableBody>
            )}
          </Table>
        </Grid>
      </Grid>
      <PresetForm
        usedNames={map(prop("name"), presets)}
        preset={selectedPreset}
        open={presetFormOpen}
        close={() => setPresetFormOpen(false)}
      />
      <Confirmation
        open={statementToDelete}
        onCancel={() => setStatementToDelete(null)}
        onConfirm={async () => {
          await StatementPresetsStore.removePreset(statementToDelete);
          setStatementToDelete(null);
        }}
        alertText="Are you sure you want to delete this statement?"
      />
    </div>
  );
});

export default withStyles(StatementsPresetsList, styles);
