import React from "react";
import {Button, Grid} from "@mui/material";
import {generateID} from "@core/helpers";

const AddRowButton = ({disabled, push, rowCellsValue}) => (
  <Grid container marginTop={3}>
    <Grid item marginLeft="auto" alignSelf="flex-end">
      <Button variant="outlined" size="small" disabled={disabled} onClick={() => push({...rowCellsValue, uniqueId: generateID()})}>
        Add Row
      </Button>
    </Grid>
  </Grid>
);

export default AddRowButton;