import React from "react";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const TestParameters = ({classes, test}) => {
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <h3>Test parameters</h3>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Etching standard</h4>
        <p className={classes.value}>{test.properties.etchingStandard}</p>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Etching solution</h4>
        <p className={classes.value}>{test.properties.etchingSolution}{test.properties.electrolytic && " (Electrolytic)"}</p>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Specimen ID</h4>
        <p className={classes.value}>{test.properties.specimenId}</p>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Position</h4>
        <p className={classes.value}>{test.properties.position || "-"}</p>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Orientation</h4>
        <p className={classes.value}>{test.properties.orientation || "-"}</p>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Magnification</h4>
        <p className={classes.value}>{test.properties.magnification}</p>
      </Grid>
    </Grid>
  );
};

export default withStyles(TestParameters, styles);