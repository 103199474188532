export default (theme) => ({
  block: {
    display: "block",
    height: theme.spacing(),
    float: "left",
    position: "relative",
    overflow: "hidden",
    opacity: 1,
    transition: "opacity, .3s ease",
    cursor: "pointer",
    "&:hover": {
      opacity: ".65"
    }
  },
});