import {MEASUREMENTS_CONFIG} from "@core/components/TestForms/DimensionalTest/data";
import {CUSTOM_EXCEPTIONS} from "@core/components/TestForms/DimensionalTest/exceptions";
import {isValueAcceptable} from "@core/components/TestForms/DimensionalTest/services";
import {partition} from "ramda";

export const sortElements = (elements = [], columns, poItem, units, acceptanceCriteria, acceptance) => {
  const [unacceptableElements, acceptableElements] = partition((element) => {
    return columns.some((column) => {
      const standardUnits = units[column] || MEASUREMENTS_CONFIG[column].units[units];
      const acceptanceConfig = acceptanceCriteria && acceptanceCriteria[column] || {};

      if (!MEASUREMENTS_CONFIG[column].locations) {
        const value = element[column];
        const exceptionsFormulas = CUSTOM_EXCEPTIONS[acceptance]?.data[column] || {};

        return !isValueAcceptable({
          element,
          value,
          standardUnits,
          ...acceptanceConfig,
          ...exceptionsFormulas,
          poItem,
          acceptanceCriteria,
        });
      }

      return MEASUREMENTS_CONFIG[column].locations.some((location) => {
        const name = location === "body" ? "body" : "ends";

        const locationAcceptanceConfig = acceptanceConfig[name] || {};
        const measurementException = CUSTOM_EXCEPTIONS[acceptance]?.data[column] || {};
        const locationExceptionsFormulas = measurementException[name] || {};
        const value = element[column] && element[column][location];
        const valueMin = element[column] && element[column][`${location}Min`];
        const valueMax = element[column] && element[column][`${location}Max`];

        return !isValueAcceptable({
          element,
          value,
          valueMin,
          valueMax,
          location,
          standardUnits,
          ...locationAcceptanceConfig,
          ...locationExceptionsFormulas,
          poItem,
          acceptanceCriteria
        });
      });
    });
  }, elements);

  return unacceptableElements.concat(acceptableElements);
};