import React from "react";
import {Button, MenuItem, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import {Input, Select} from "@core/components/Form";
import * as yup from "yup";
import styles from "./styles";
import {generateID} from "@core/helpers";
import {norms, acceptances, sources, positions, iqiTypes, filmTypes, xRayApparatuses} from "./data";
import transferValues from "./uploadHelper";
import {isEmpty} from "ramda";
import RadioGraphicTestTable from "../../TestViews/RadioGraphic/table";
import TestFooter from "../../LabTestFooter";

const RadioGraphicReport = ({classes, test, saveTest, formRef}) => {

  const validationSchema = yup.object().shape({
    workStage: yup.string().required("Field is required"),
    norm: yup.object().required("Field is required"),
    acceptance: yup.object().required("Field is required"),
    radioscopic: yup.string().required("Field is required"),
    source: yup.object().required("Field is required"),
    screen: yup.string().required("Field is required"),
    thicknessSourceSide: yup.number().typeError("Should be a number").positive("Should be > 0").required("Field is required"),
    thicknessFilmSide: yup.number().typeError("Should be a number").positive("Should be > 0").required("Field is required"),
    sourceDistance: yup.number().typeError("Should be a number").positive("Should be > 0").required("Field is required"),
    position: yup.object().required("Field is required"),
    density: yup.string().required("Field is required"),
    sourceSize: yup.number().typeError("Should be a number").positive("Should be > 0").required("Field is required"),
    iqiType: yup.object().required("Field is required"),
    filmType: yup.object().required("Field is required"),
    xRayApparatus: yup.object().required("Field is required"),
    tps: yup.string().required("Field is required"),
    kv: yup.number().typeError("Should be a number").required("Field is required"),
    ma: yup.number().typeError("Should be a number").required("Field is required"),
    expTime: yup.number().typeError("Should be a number").positive("Invalid time").required("Field is required"),
    description: yup.string(),
    salesOrder: yup.string(),
  });

  const norm = norms.find((el) => el.value === test.properties.norm || {value: ""});
  const acceptance = acceptances.find((el) => el.value === test.properties.acceptance || {value: ""});
  const source = sources.find((el) => el.value === test.properties.source || {value: ""});
  const position = positions.find((el) => el.value === test.properties.position || {value: ""});
  const iqiType = iqiTypes.find((el) => el.value === test.properties.iqiType || {value: ""});
  const filmType = filmTypes.find((el) => el.value === test.properties.filmType || {value: ""});
  const xRayApparatus = xRayApparatuses.find((el) => el.value === test.properties.xRayApparatus || {value: ""});

  const initialValues = {
    norm,
    acceptance,
    workStage: test.properties.workStage || "",
    radioscopic: test.properties.radioscopic || "",
    source,
    screen: test.properties.screen || "",
    thicknessSourceSide: test.properties.thicknessSourceSide || "",
    thicknessFilmSide: test.properties.thicknessFilmSide || "",
    sourceDistance: test.properties.sourceDistance || "",
    position,
    density: test.properties.density || "",
    sourceSize: test.properties.sourceSize || "",
    iqiType,
    filmType,
    xRayApparatus,
    tps: test.properties.tps || "",
    kv: test.properties.kv || "",
    ma: test.properties.ma || "",
    expTime: test.properties.expTime || "",
    sections: test.properties.sections || {},
    description: test.properties.description || "",
    salesOrderNumber: test.properties.salesOrderNumber || "",
  };

  const onSubmit = (values) => {
    saveTest({
      norm: values.norm.value,
      acceptance: values.acceptance.value,
      workStage: values.workStage,
      radioscopic: values.radioscopic,
      source: values.source.value,
      screen: values.screen,
      thicknessSourceSide: values.thicknessSourceSide,
      thicknessFilmSide: values.thicknessFilmSide,
      sourceDistance: values.sourceDistance,
      position: values.position.value,
      density: values.density,
      sourceSize: values.sourceSize,
      iqiType: values.iqiType.value,
      filmType: values.filmType.value,
      xRayApparatus: values.xRayApparatus.value,
      tps: values.tps,
      kv: values.kv,
      ma: values.ma,
      expTime: values.expTime,
      sections: values.sections,
      salesOrderNumber: values.salesOrderNumber,
      description: values.description,
    });
  };

  return (
    <div>
      <Formik
        onSubmit={onSubmit}
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        render={(props) => {
          const {
            values: {
              salesOrderNumber, norm, acceptance, workStage, radioscopic, source, screen, thicknessSourceSide,
              thicknessFilmSide, sourceDistance, position, density, sourceSize, iqiType, filmType, xRayApparatus,
              tps, kv, ma, expTime, description, sections
            },
            errors,
            touched,
            handleChange,
            setFieldTouched,
            setFieldValue
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          return (<>
            <Grid container spacing={5}>
              <Grid item xs={2} className={classes.uploadResults}>
                <input
                  accept=".json"
                  id={"raised-button-file"}
                  type="file"
                  onChange={(e) => transferValues(e, setFieldValue, setFieldTouched)}
                />
                <label htmlFor={"raised-button-file"}>
                  <Button variant={"contained"} size={"medium"} component='span' color="primary">
                    Upload Results
                  </Button>
                </label>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <Input
                  name="salesOrderNumber"
                  label='Sales order №'
                  value={salesOrderNumber}
                  error={Boolean(errors.salesOrderNumber) && touched.salesOrderNumber}
                  errorMessage={errors.salesOrderNumber}
                  onChange={(event) => change("salesOrderNumber", event)}
                  onBlur={() => setFieldValue("salesOrderNumber", salesOrderNumber.trim())}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={2}>
              <Grid item xs={3}>
                <Select
                  name='norm'
                  label="Norm"
                  value={norm}
                  required
                  error={Boolean(errors.norm) && touched.norm}
                  errorMessage={errors.norm}
                  onChange={(event) => change("norm", event)}
                >
                  {norms.map((E) => <MenuItem key={generateID()} value={E}>{E.value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Select
                  label="Acceptance criteria"
                  name='acceptance'
                  value={acceptance}
                  required
                  error={Boolean(errors.acceptance) && touched.acceptance}
                  errorMessage={errors.acceptance}
                  onChange={(event) => change("acceptance", event)}
                >
                  {acceptances.map((E) => <MenuItem key={generateID()} value={E}>{E.value}</MenuItem>)}
                </Select>
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={2}>
              <h2>Test conditions</h2>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={2}>
              <Grid item xs={3}>
                <Input
                  name="workStage"
                  label='Work Stage'
                  value={workStage}
                  required
                  error={Boolean(errors.workStage) && touched.workStage}
                  errorMessage={errors.workStage}
                  onChange={(event) => change("workStage", event)}
                  onBlur={() => setFieldValue("workStage", workStage.trim())}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name="radioscopic"
                  label='Radioscopic Tec.'
                  value={radioscopic}
                  required
                  error={Boolean(errors.radioscopic) && touched.radioscopic}
                  errorMessage={errors.radioscopic}
                  onChange={(event) => change("radioscopic", event)}
                  onBlur={() => setFieldValue("radioscopic", radioscopic.trim())}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={2}>
              <Grid item xs={3}>
                <Select
                  name='source'
                  label="Source"
                  value={source}
                  required
                  error={Boolean(errors.source) && touched.source}
                  errorMessage={errors.source}
                  onChange={(e) => change("source", e)}
                >
                  {sources.map((E) => <MenuItem key={generateID()} value={E}>{E.value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Input
                  name="screen"
                  label="Screen"
                  value={screen}
                  required
                  error={Boolean(errors.screen) && touched.screen}
                  errorMessage={errors.screen}
                  onChange={(e) => change("screen", e)}
                  onBlur={() => setFieldValue("screen", screen.trim())}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  name="thicknessSourceSide"
                  label="Thickness source side"
                  value={thicknessSourceSide}
                  required
                  error={Boolean(errors.thicknessSourceSide) && touched.thicknessSourceSide}
                  errorMessage={errors.thicknessSourceSide}
                  onChange={(e) => change("thicknessSourceSide", e)}
                  onBlur={() => setFieldValue("thicknessSourceSide", thicknessSourceSide.trim())}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  name="thicknessFilmSide"
                  label="Thickness film side"
                  value={thicknessFilmSide}
                  required
                  error={Boolean(errors.thicknessFilmSide) && touched.thicknessFilmSide}
                  errorMessage={errors.thicknessFilmSide}
                  onChange={(e) => change("thicknessFilmSide", e)}
                  onBlur={() => setFieldValue("thicknessFilmSide", thicknessFilmSide.trim())}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={2}>
              <Grid item xs={3}>
                <Select
                  name='position'
                  label="Position"
                  value={position}
                  required
                  error={Boolean(errors.position) && touched.position}
                  errorMessage={errors.position}
                  onChange={(event) => change("position", event)}
                >
                  {positions.map((E) => <MenuItem key={generateID()} value={E}>{E.value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Input
                  name="density"
                  label="Density"
                  value={density}
                  required
                  error={Boolean(errors.density) && touched.density}
                  errorMessage={errors.density}
                  onChange={(event) => change("density", event)}
                  onBlur={() => setFieldValue("density", density.trim())}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  name="sourceDistance"
                  label="Source distance"
                  value={sourceDistance}
                  required
                  error={Boolean(errors.sourceDistance) && touched.sourceDistance}
                  errorMessage={errors.sourceDistance}
                  onChange={(e) => change("sourceDistance", e)}
                  onBlur={() => setFieldValue("sourceDistance", sourceDistance.trim())}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  name="sourceSize"
                  label="Source Size"
                  value={sourceSize}
                  required
                  error={Boolean(errors.sourceSize) && touched.sourceSize}
                  errorMessage={errors.sourceSize}
                  onChange={(e) => change("sourceSize", e)}
                  onBlur={() => setFieldValue("sourceSize", sourceSize.trim())}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={5}>
              <Grid item xs={2}>
                <Select
                  name='iqiType'
                  label="I.Q.I. Type"
                  value={iqiType}
                  required
                  error={Boolean(errors.iqiType) && touched.iqiType}
                  errorMessage={errors.iqiType}
                  onChange={(event) => change("iqiType", event)}
                >
                  {iqiTypes.map((E) => <MenuItem key={generateID()} value={E}>{E.value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Select
                  name='filmType'
                  label="Film Type"
                  value={filmType}
                  required
                  error={Boolean(errors.filmType) && touched.filmType}
                  errorMessage={errors.filmType}
                  onChange={(event) => change("filmType", event)}
                >
                  {filmTypes.map((E) => <MenuItem key={generateID()} value={E}>{E.value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Select
                  name='xRayApparatus'
                  label="X-ray Apparatus"
                  value={xRayApparatus}
                  required
                  error={Boolean(errors.xRayApparatus) && touched.xRayApparatus}
                  errorMessage={errors.xRayApparatus}
                  onChange={(event) => change("xRayApparatus", event)}
                >
                  {xRayApparatuses.map((E) => <MenuItem key={generateID()}
                    value={E}>{E.value}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={8}>
                <Input
                  name="description"
                  label="Description"
                  value={description}
                  multiline
                  rows={6}
                  error={Boolean(errors.description) && touched.description}
                  errorMessage={errors.description}
                  onChange={(e) => change("description", e)}
                  onBlur={() => setFieldValue("description", description.trim())}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={2}>
              <h2>Test results</h2>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={2}>
              <Grid item xs={2}>
                <Select
                  name='tps'
                  label="TPS"
                  value={tps}
                  required
                  error={Boolean(errors.tps) && touched.tps}
                  errorMessage={errors.tps}
                  onChange={(event) => change("tps", event)}
                >
                  {["TT"].map((E) => <MenuItem key={generateID()} value={E}>{E}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={1} style={{marginRight: 60}}>
                <Input
                  name="kv"
                  label="KV"
                  value={kv}
                  required
                  error={Boolean(errors.kv) && touched.kv}
                  errorMessage={errors.kv}
                  onChange={(e) => change("kv", e)}
                  onBlur={() => setFieldValue("kv", kv.trim())}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  label="mA"
                  name='ma'
                  value={ma}
                  required
                  error={Boolean(errors.ma) && touched.ma}
                  errorMessage={errors.ma}
                  onChange={(event) => change("ma", event)}
                >
                  {[2, 4, 5, 8].map((E) => <MenuItem key={generateID()} value={E}>{E}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={1}>
                <Input
                  name="expTime"
                  label="Exp. Time"
                  value={expTime}
                  required
                  error={Boolean(errors.expTime) && touched.expTime}
                  errorMessage={errors.expTime}
                  onChange={(e) => change("expTime", e)}
                  onBlur={() => setFieldValue("expTime", expTime.trim())}
                />
              </Grid>
            </Grid>
            {!isEmpty(sections) && <Grid container spacing={5}>
              <Grid item xs={12}>
                <RadioGraphicTestTable table={sections} />
              </Grid>
            </Grid>}
            <TestFooter
              onSubmit={onSubmit}
            />
          </>);
        }}
      />
    </div>
  );
};

export default withStyles(RadioGraphicReport, styles);
