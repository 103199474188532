import Avatar from "@core/components/Avatar";
import FileComponent from "@core/components/File";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import {isImage} from "@core/helpers";
import {Button, CircularProgress, Grid} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {withStyles} from "tss-react/mui";
import {MAX_FILE_SIZE, BAD_CHARS_REGEX} from "../constants";
import styles from "./styles";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const Uploader = ({
  file,
  classes,
  isDrop,
  preloadText,
  buttonText,
  fileType,
  className,
  buttonClass,
  disabled,
  required,
  onRemove,
  justifyContent,
  isRelatedToInspection,
  handleUploadedFile,
  handleResetPreview,
  message,
  alertText,
  uploadButton,
  removeButton,
  withConfirmation = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [unSuccessMsg, setUnSuccessMsg] = useState("");
  const [over, setOver] = useState();
  const [fileId, setFileId] = useState("");
  const id = Math.random().toString(36).substring(2);
  const inputElement = useRef(null);

  const [newFile, setNewFile] = useState(null);
  const [fileConfirmationIsOpen, setFileConfirmationIsOpen] = useState({isOpen: false, file: null});

  useEffect(() => {
    setFileConfirmationIsOpen({...fileConfirmationIsOpen, isOpen: false});

    handleFileChange(newFile);
  }, [newFile]);

  const isValidFileName = (fileName) => {
    return !BAD_CHARS_REGEX.test(fileName);
  };

  const handleFileChange = async (file) => {
    if (!file) {
      return;
    }

    if (!isValidFileName(file.name)) {
      setSuccess(false);
      setUnSuccessMsg("Invalid file \n file name should not contain : < > : \\ / \\\\ | ? * #");

      typeof handleResetPreview === "function" && handleResetPreview();

      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setSuccess(false);
      setUnSuccessMsg("Uploaded file is too big. \n Max size: 50mb");
      typeof handleResetPreview === "function" && handleResetPreview();

      return;
    }

    const data = new FormData();
    data.append("file", file);

    setLoading(true);
    setSuccess(true);

    const response = await axios.post(ROUTES.UPLOAD[ACTIONS.CREATE], data, {withCredentials: true});

    typeof handleUploadedFile === "function" && handleUploadedFile(response.data);

    setFileId(response.data._id);

    setLoading(false);
  };

  const removeFile = async () => {
    if(!fileId) return;

    setLoading(true);
    await axios.delete(ROUTES.UPLOAD[ACTIONS.REMOVE](fileId));

    setLoading(false);

    onRemove();
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDragEnter = () => {
    setOver(true);
  };

  const onDragLeave = () => {
    setOver(false);
  };

  const resolveFile = (file) => {
    const name = file ? file.split("/")[2] : "";

    if (!isImage(file)) {
      return (
        <div className={classes.pdf}>
          <FileComponent file={file} />
          <p>{name}</p>
        </div>
      );
    }

    return <Avatar user={{avatar: {file}}} isRect />;
  };

  return (
    <div className={className}>
      {!isRelatedToInspection && (
        file ? resolveFile(file) : <span>{message}</span>
      )}

      {
        !file && isDrop && preloadText ?
          <div
            onClick={() => !disabled && inputElement.current.click()}
            onDrop={(event) => setFileConfirmationIsOpen({isOpen: true, file: event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0]})}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDragEnter={onDragEnter}
            className={classNames(["preloadImg", classes.preloadAvatar, over ? classes.enter : classes.leave])}
          >
            {preloadText}
          </div> :
          null
      }
      <input
        ref={inputElement}
        accept={fileType || "image/*"}
        className={classes.input}
        id={id}
        disabled={disabled}
        type="file"
        onClick={(event) => event.target.value = ""}
        onChange={(event) => {
          const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];

          if(withConfirmation) {
            setFileConfirmationIsOpen({isOpen: true, file});
          } else {
            setNewFile(file);
          }
        }}
      />
      {!success && <p className={classes.errorMessage}>{unSuccessMsg.split("\n").map((text, index) => <React.Fragment key={index}>{text}<br/></React.Fragment>)}</p>}
      <Grid container justifyContent={justifyContent} className={!isRelatedToInspection && file && classes.buttonsContainer} spacing={1}>
        <Grid item>
          <label htmlFor={id}>
            {uploadButton || (
              isRelatedToInspection ? (
                <Button
                  fullWidth
                  className={classes.buttonWaiveInspection}
                  size={"large"}
                  component="span"
                >
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  <span>{buttonText || "Upload"}</span>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  component="span"
                  className={classNames(["button", classes.button, buttonClass])}
                  disabled={loading || disabled}
                  color="primary"
                >
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  {file ? <span>Change</span> : <span className={required ? classes.asterisk : null}>{buttonText || "Upload"}</span>}
                </Button>
              )
            )}
          </label>
        </Grid>
        {(onRemove && file) && (
          <Grid item onClick={removeFile}>
            {removeButton || (
              <Button
                variant="contained"
                component="span"
                className={classNames(["button", classes.button, buttonClass])}
                color="secondary"
              >
                  Remove
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      <Confirmation
        open={fileConfirmationIsOpen.isOpen}
        onCancel={() => setFileConfirmationIsOpen({...fileConfirmationIsOpen, isOpen: false})}
        onConfirm={() => setNewFile(fileConfirmationIsOpen.file)}
        alertText={alertText}
      />
    </div>
  );
};

export default withStyles(Uploader, styles);
