import React from "react";
import GreenDensityForm from "./components/GreenDensityForm";
import SinteredDensityForm from "./components/SinteredDensityForm";
import ImpregnatedDensityForm from "./components/ImpregnatedDensityForm";

export const DENSITY_TYPES = {
  GREEN: "Green density",
  SINTERED: "Sintered density",
  IMPREGNATED: "Impregnated density"
};

export const TEST_FORM_BY_DENSITY_TYPE = {
  [DENSITY_TYPES.GREEN]: <GreenDensityForm />,
  [DENSITY_TYPES.SINTERED]: <SinteredDensityForm/>,
  [DENSITY_TYPES.IMPREGNATED]: <ImpregnatedDensityForm />
};