import React from "react";
import {useParams} from "react-router-dom";
import {Divider, Grid, Skeleton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react";
import useStores from "../../../../../../useStores";
import styles from "./styles";
import TestActions from "../TestActions";

const GeneralInfo = observer(({classes}) => {
  const {CampaignStore, UserStore} = useStores();

  const {id: campaignId} = useParams();

  const campaign = CampaignStore.campaign;
  const {manufacturer, creator, purchaseOrder} = campaign;

  const isLoaded = campaignId === campaign._id;

  const company = UserStore.user.data.company;

  return (
    <Grid container justifyContent="space-between">
      <Grid item className={classes.info}>
        <Grid container spacing={3} alignItems="center">
          <Grid item marginRight={3}>
            {isLoaded ? (
              <h3>{creator._id === company._id ? `Manufacturer: ${manufacturer.name}` : `Customer: ${creator.name}`}</h3>
            ) : (
              <Skeleton width={200}/>
            )}
          </Grid>
          <Grid item style={{paddingLeft: 0, paddingRight: 0}}>
            <Divider
              orientation="vertical"
              className={classes.verticalDivider}
            />
          </Grid>
          <Grid item marginRight={3}>
            {isLoaded ? (
              <h3>{`PO: ${purchaseOrder.orderNumber}`}</h3>
            ) : (
              <Skeleton width={200}/>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <TestActions />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default withStyles(GeneralInfo, styles);