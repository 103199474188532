import GlobalAcceptance from "@core/components/QcpSideBar/components/TensileTest/components/GlobalAcceptance";
import {isEmpty} from "ramda";
import React from "react";
import {Box} from "@mui/material";
import AcceptanceOnElements from "../AcceptanceOnElements";
import Acceptance from "../Acceptance";

const AcceptancesContent = ({config, globalConfig, qcp}) => {
  const withGlobalRequirements = !isEmpty(globalConfig);

  return (
    <>
      {withGlobalRequirements && (
        <Box sx={{marginTop: 1, marginBottom: 1}}>
          <h3>Global Requirements</h3>
          <GlobalAcceptance
            globalConfig={globalConfig}
            qcp={qcp}
          />
        </Box>
      )}
      <Box sx={{marginTop: 1}}>
        {withGlobalRequirements && <h3>Local Requirements</h3>}
        {config.elements ? (
          <AcceptanceOnElements
            elements={config.elements}
          />
        ) : (
          <Acceptance
            config={config}
          />
        )}
      </Box>
    </>
  );
};

export default AcceptancesContent;