import {lightBlue, grey, pink, green} from "@mui/material/colors";
import {autocompleteClasses} from "@mui/material/Autocomplete";
import isExperimentalDesignEnabled from "@core/helpers/isExperimentalDesignEnabled";

const experimentalDesign = isExperimentalDesignEnabled();

export default {
  palette: {
    primary: {
      main: lightBlue[700],
      backgroundColor: "#eff8fa",
      hover: "#ebf4fb",
      placeholder: "#bfdbf1",
    },
    secondary: {
      light: pink[300],
      main: pink[500],
      dark: pink[700],
      grey: grey[300], // TODO imho secondary should be switched from pink to grey across all the app
    },
    success: {
      main: green[500],
      text: "#4caf50",
      backgroundColor: "#dcf5d8",
    },
    error: {
      main: "#d02d52",
      text: "#D02D52",
      backgroundColor: "#ffe0e7",
    },
    warning: {
      main: "#d4a237",
      text: "#d4a237",
      backgroundColor: "#ffefd2",
    },
    awaitingData: {
      backgroundColor: "#e9e9e8",
    },
    text: {
      primary: grey[700],
      secondary: "#8c8c8c",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    deactivate: {
      main: "#575757",
      text: "#575757",
      backgroundColor: "#E0E0E0",
    },
    backgroundColor: {
      main: "#F5F5F5",
    }
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: "initial"
        }
      }
    },
    MuiButton: {
      variants: [{
        props: {variant: "containedGray"},
        style: {
          backgroundColor: grey[100],
          color: grey[600],

          "&:hover": {
            backgroundColor: "#D5D5D5"
          }
        }
      }, {
        props: {variant: "secondaryNewDesign"}, // to be removed after we are done with new buttons design
        style: {
          fontSize: "0.82rem",
          fontWeight: 600,
          padding: "4px 8px",
          backgroundColor: "#f5f5f5",
          color: "#616161",
          border: "1px solid #c2c2c2"
        }
      }],
      styleOverrides: {
        contained: {
          color: "#838383",
        },
        containedSecondary: {
          color: "#fff"
        },
        containedPrimary: {
          color: "#fff",
          backgroundColor: lightBlue[700],
        },
        root: {
          textTransform: "capitalize",
        }
      }
    },
    MuiToggleButtonGroup: {
      variants: [{
        props: {variant: "canvas"},
        style: {
          "& button": {
            padding: "6px 16px",
            border: "none",
            textTransform: "capitalize",
            backgroundColor: "#fff",
            color: grey[600],

            "&:hover": {
              backgroundColor: "#fff",
            },

            "&.Mui-selected": {
              backgroundColor: grey[600],
              color: "#fff",

              "&:hover": {
                backgroundColor: grey[500],
              }
            },

            "&.Mui-disabled": {
              border: "none",
            },
          }
        },
      }, {
        props: {variant: "header"},
        style: {
          "& button": {
            textTransform: "none",
            padding: "6px 16px 6px 16px",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 0,
            "&.Mui-selected": {
              borderLeft: "1px solid #e0e0e0!important",
            },
            "&.Mui-selected, &.Mui-selected:hover": {
              backgroundColor: "#F5F5F5",
            }
          }
        }
      }],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          padding: 0,
          marginRight: 10,
          minWidth: "unset"
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 1300
        },
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          "& > div": {
            boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
          }
        },
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        ".globalColor": {
          backgroundColor: "#61ffbb"
        },
        ".padded-container": {
          padding: 32
        },
        ".container": {
          alignItems: "stretch",
          display: "flex",
          minHeight: "100vh",
        },
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        ".styled-table": {
          borderCollapse: "unset!important",
          border: "1px solid #e0e0e0",
          borderRadius: 4,
          "& tbody tr": {
            maxHeight: 46,
          },
          "& thead tr": {
            height: 37,
          },
          "& thead tr:first-child": {
            "& > th:first-child": {
              borderTopLeftRadius: 4,
            },
            "& > th:last-child": {
              borderTopRightRadius: 4,
            }
          },
          "& tbody td ": {
            padding: 10,
            borderRight: "1px solid #e0e0e0",
          },
          "& .vertical-header-col": {
            textAlign: "right",
            fontSize: "0.88rem",
            color: "#616161",
            backgroundColor: "#fafafa",
            fontWeight: 500
          },
          "& th": {
            borderRight: "1px solid #e0e0e0",
            padding: 10,
            textAlign: "left",
            fontSize: "0.88rem",
            color: "#616161",
            backgroundColor: "#fafafa",
          },
          "& th:has(svg)": {
            padding: "8px 10px",
          },
          "& tr": {
            backgroundColor: "#fff",
            "& > td:last-child, th:last-child": {
              borderRight: "none",
            }
          },
          "& tr:last-child": {
            "& > td:first-child": {
              borderBottomLeftRadius: 4,
            },
            "& > td:last-child": {
              borderBottomRightRadius: 4,
            },
            "& > td": {
              borderBottom: "none",
            },
          },
          "& th:last-child, td:last-child": {
            paddingRight: 10,
          },
          "& td > a, td > span, th > span, ul": {
            padding: 0,
            lineHeight: "inherit",
            verticalAlign: "middle",
          },
          "& button": {
            padding: "0 2px",
            lineHeight: "inherit",
          },
          "& svg, img": {
            maxWidth: 20,
            maxHeight: 20,
          },
          [experimentalDesign ? "&& input, div[role=button], div[role=combobox]" : "& input, div[role=button]"]: {
            fontSize: "0.8125rem",
            padding: "2.25px 0 2.25px 6px",
          },
          "& td:has(input, div[role=button])": {
            padding: "5.75px 10px"
          }
        },
        body: {
          margin: 0,
          padding: 0,
          backgroundColor: "#F5F5F5",
          lineHeight: "initial",
          fontFamily: "Inter",
          fontWeight: 400,
          overflowX: "hidden",
        },

        "h1, h2, h3": {
          color: "#575757",
          fontWeight: 400,
        },

        ".acceptable": {
          backgroundColor: "#d9ffd1",
        },
        ".notAcceptable": {
          backgroundColor: "#f6dade",
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: "auto", // fit same design as previously with MUI3
          ...(experimentalDesign ? {
            "&&": {
              padding: "8px 26px 8px 12px"
            }
          } : {}
          )
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: "inline", // fit same design as previously with MUI3
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          [`& .${autocompleteClasses.option}.${autocompleteClasses.focused}`]: {
            backgroundColor: "rgba(2, 136, 209, 0.12)"
          },
        },
      }
    }
  },

  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],

  typography: {
    body1: {
      color: grey[700]
    },
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemibold: 600,
    fontWeightBold: 700,
    fontFamily: "Inter"
  },
};
