import React, {useState} from "react";
import {map, prop} from "ramda";
import {Grid, MenuItem, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import SelectField from "@core/components/FormikSelect";
import ItemsForm from "../Items";
import ComparisonForm from "../ComparisonForm";
import {CERTIFICATE_FIELDS} from "../../../constants";
import styles from "./styles";

const Certificate = ({classes, index, item, setFieldValue, remove, children, values}) => {
  const [isEditOpen, setEditOpen] = useState(false);

  const usedFields = map(prop("field"), values.config.filter(({entity}) => item.entity === entity));

  return (
    <>
      <Grid item xs={2}>
        <SelectField
          name={`config.${index}.field`}
          label='Field'
          required
          disabled={!item.entity}
        >
          {CERTIFICATE_FIELDS.map((field) => {
            if(usedFields.includes(field.name) && item.field !== field.name) return;

            return (
              <MenuItem value={field.name}>{field.friendlyName}</MenuItem>
            );
          })}
        </SelectField>
      </Grid>
      {item.field === "items" ? (
        <>
          <Grid item xs={1} container alignItems="flex-end">
            <Button
              fullWidth
              onClick={() => setEditOpen(true)}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Edit
            </Button>
          </Grid>
          <ItemsForm
            removeItems={() => remove(index)}
            onClose={() => setEditOpen(false)}
            isOpen={isEditOpen}
            setFieldValue={setFieldValue}
            name={`config.${index}`}
            items={item}
          />
        </>) : (
        <ComparisonForm
          name={`config.${index}`}
          item={item}
        />
      )}
      {children}
    </>
  );
};

export default withStyles(Certificate, styles);
