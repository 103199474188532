import React, {useEffect, useState} from "react";
import axios from "axios";
import {keys} from "ramda";
import {Button, Grid, MenuItem, FormControlLabel, Checkbox, useTheme} from "@mui/material";
import {AiOutlineFullscreen, AiOutlineFullscreenExit} from "react-icons/ai";
import {Select} from "@core/components/Form";
import HistogramSettings from "../HistogramSettings";
import usePlotFilter from "../../hooks/usePlotFilter";
import {openFullScreen, closeFullScreen} from "@core/helpers";
import {usePlotOptions} from "../../plotContext";
import {PLOT_TYPES, PARAM_TYPES_BY_TEST_TYPE} from "../../сonstants";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const ADVANCED_SETTINGS_BY_PLOT_TYPE = {
  [PLOT_TYPES.HISTOGRAM]: HistogramSettings,
};

const PlotSettings = ({testId, testType, plotType, plotData, setPlotDataLoading, plot, isFullScreen}) => {
  const testParams = PARAM_TYPES_BY_TEST_TYPE[testType] || {};
  const paramTypes = keys(testParams);

  const theme = useTheme();

  const {...plotOptions} = usePlotOptions();
  const {toggleShowAcceptanceCriteria, toggleSplitByZones, onNewParamType} = usePlotFilter();
  const {paramType, splitByZones = true} = plotOptions;
  const {showAcceptanceCriteria = false} = plotOptions.options;
  const {minY: min, maxY: max} = plotData.minmax ?? {};

  const [availableParamTypes, setAvailableParamTypes] = useState([]);
  const [plotWithZones, setPlotWithZones] = useState(false);

  const setTestParams = async () => {
    setPlotDataLoading(true);

    const response = await axios.get(ROUTES.TEST_CHART[ACTIONS.TYPES], {params: {testId, type: plotType}});

    const paramsValues = response.data;

    setAvailableParamTypes(paramsValues);

    setPlotDataLoading(false);

    if(!paramsValues.length) return;

    onNewParamType(paramsValues[0]);
  };

  useEffect(() => {
    if(!testId || !plotType || !paramTypes.length) return;

    setTestParams();
  }, [plotType]);

  useEffect(() => {
    if(!plotData.plot || !splitByZones) return;
    
    const datasets = plotData.plot.datasets;
    setPlotWithZones(datasets.length > 1);
  }, [plotData.plot]);

  const onParamTypeChange = (event) => {
    const value = event.target.value;
    onNewParamType(value);
  };

  const toggleFullScreen = () => {
    if(!isFullScreen) openFullScreen(plot.current);
    else closeFullScreen();
  };

  const AdvancedSettingsComponent = ADVANCED_SETTINGS_BY_PLOT_TYPE[plotType] || null;

  return (
    <Grid
      container
      direction="row"
      spacing={3}
      sx={{
        alignItems: {xs: "stretch", md: "flex-end"},
      }}
    >
      {(availableParamTypes.length > 1) && (
        <Grid item>
          <Select
            menuProps={{
              container: isFullScreen ? plot.current : document.body,
            }}
            label="Value"
            value={paramType}
            onChange={onParamTypeChange}
          >
            {availableParamTypes.map((param) => (
              <MenuItem key={param} value={param}>
                {testParams[param] || param}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      {(plotWithZones && [PLOT_TYPES.BOX_PLOT, PLOT_TYPES.VIOLIN].includes(plotType)) && (
        <Grid item>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={splitByZones}
                onClick={toggleSplitByZones}
              />
            )}
            label="Split by zones"
          />
        </Grid>
      )}
      {!!(min || max) && (
        <Grid item>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={showAcceptanceCriteria}
                onClick={toggleShowAcceptanceCriteria}
              />
            )}
            label="Show acceptance criteria"
          />
        </Grid>
      )}
      {AdvancedSettingsComponent && (
        <Grid item>
          <AdvancedSettingsComponent
            isFullScreen={isFullScreen}
            plot={plot}
          />
        </Grid>
      )}
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          height: 48,
          cursor: "pointer",
          color: theme.palette.text.primary,
          fontSize: "0.875rem",
          "& svg": {
            marginRight: theme.spacing(1),
          }
        }}
        onClick={toggleFullScreen}
      >
        {isFullScreen ? <AiOutlineFullscreenExit size={20} /> : <AiOutlineFullscreen size={20} />}
        Toggle fullscreen
      </Button>
    </Grid>
  );
};

export default PlotSettings;