export const ICONISTS_ICONS = {
  filter: "filter",
  filterFilled: "filterFilled",
  archive: "archive",
  wallet: "wallet",
  building: "building",
  buildings: "buildings",
  clipboard: "clipboard",
  dotGrid: "dotGrid",
  fileText: "fileText",
  folderPaper: "folderPaper",
  group: "group",
  logout: "logout",
  pageTextSearch: "pageTextSearch",
  paperPlane: "paperPlane",
  people: "people",
  playground: "playground",
  preferences: "preferences",
  pumpjack: "pumpjack",
  settingsGear: "settingsGear",
  settingsSliderThree: "settingsSliderThree",
  signature: "signature",
  sketchbook: "sketchbook",
  squareChecklistMagnifyingGlass: "squareChecklistMagnifyingGlass",
  squareChecklist: "squareChecklist",
  stocks: "stocks",
  thread: "thread",
  toolbox: "toolbox",
  target: "target",
  chevronBottom: "chevronBottom",
  chevronLargeTop: "chevronLargeTop",
  chevronLargeDown: "chevronLargeDown",
  around: "around",
  shapesPlusXSquareCircle: "shapesPlusXSquareCircle",
  pageText: "pageText",
  focusRenew: "focusRenew",
  magnifyingGlassPlus: "magnifyingGlassPlus",
  magnifyingGlassMinus: "magnifyingGlassMinus",
  focusZoomIn: "focusZoomIn",
  focusZoomOut: "focusZoomOut"
};