import React from "react";
import {Button, Grid, IconButton, Tooltip} from "@mui/material";
import {isEmpty} from "ramda";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import EditIcon from "@mui/icons-material/Edit";
import {MdSwapHoriz} from "react-icons/md/";
import TransformIcon from "@mui/icons-material/Transform";
import SpecificationChecker from "@core/components/SpecificationChecker";

const CertificateActions = ({
  certificate,
  openTransfer,
  onAddToOutgoingOrder,
  onSplit,
  onEdit,
  onConvert,
}) => {
  return (
    <Grid container wrap="nowrap" alignItems="center" data-html2canvas-ignore="true">
      <Grid item>
        <SpecificationChecker
          certificate={certificate}
        />
      </Grid>
      {(!certificate.createdByPortal || !isEmpty(certificate.transactions)) && (
        <Grid container item justifyContent="flex-end" spacing={3} alignItems="center">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                {!certificate.orderNumber && (
                  <Tooltip title="Add to a customer order" placement="bottom">
                    <IconButton onClick={onAddToOutgoingOrder} color="primary" size="large">
                      <MdSwapHoriz />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Split product" placement="bottom-start">
                  <IconButton onClick={onSplit} color="primary" size="large">
                    <CallSplitIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Edit main certificate data" placement="bottom">
                  <IconButton onClick={onEdit} color="primary" size="large">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Convert product" placement="bottom-start">
                  <IconButton onClick={onConvert} color="primary" size="large">
                    <TransformIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item> */}
          {/*   <Button */}
          {/*     disabled={user.role !== roles.ADMIN} */}
          {/*     variant="contained" */}
          {/*     size={"large"} */}
          {/*     className={classes.fullHeight} */}
          {/*     color="primary" */}
          {/*     onClick={() => { */}
          {/*       if (certificate.viewer) unpublishCertificate(); */}
          {/*       else openPublishToPortal(); */}
          {/*     }} */}
          {/*   > */}
          {/*     {certificate.viewer ? "Unpublish from portal" : "Publish to portal"} */}
          {/*   </Button> */}
          {/* </Grid> */}
          {/* <Grid item> */}
          {/*   <ExportToPdf */}
          {/*     elementId={certificate._id} */}
          {/*     name={`certificate-${certificate._id}`} */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={openTransfer}
            >
              Transfer
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CertificateActions;
