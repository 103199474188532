import React from "react";
import {Button} from "@mui/material";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import {getColumns as getTransferColumns} from "@core/columns/transfer";

const ORDER_TABS = {
  ALL: "all",
  OPEN: "open",
  COMPLETED: "closed"
};

const ORDER_STATUSES_QUERY = {
  [ORDER_TABS.ALL]: {status: null},
  [ORDER_TABS.OPEN]: {status: ORDER_TABS.OPEN},
  [ORDER_TABS.COMPLETED]: {status: ORDER_TABS.COMPLETED},
};

const DEFAULT_VIEW = ORDER_TABS.ALL;

const getColumns = (interfaceType, view, setTransferToToggle, setTransferToDelete) => {
  const columns = getTransferColumns(interfaceType);

  if(interfaceType === INTERFACE_TYPE.SENDER) {
    columns.push({
      dataIndex: "action",
      title: "Action",
      permanent: true,
      render: (transfer) => (
        <>
          {transfer.isDraft ? (
            <Button
              color="primary"
              onClick={(e) => {
                e.stopPropagation();

                setTransferToToggle(transfer);
              }}
            >
                  Close
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={(e) => {
                e.stopPropagation();

                setTransferToToggle(transfer);
              }}
            >
                  Open
            </Button>
          )}
          <Button
            onClick={(e) => {
              e.stopPropagation();

              setTransferToDelete(transfer._id);
            }}
          >
                Remove
          </Button>
        </>
      )
    });
  }
  
  return columns;
};

export {getColumns, ORDER_TABS, DEFAULT_VIEW, ORDER_STATUSES_QUERY};
