export default (theme) => ({
  root: {
    width: "100%",
    padding: "20px",
  },
  userComponent:{
    padding: theme.spacing(4),
  },
  section:{
    marginBottom: theme.spacing(4)
  },
  avatarUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 0,

    "& .avatar": {
      width: 150,
      height: 150,
      marginLeft: 0
    }
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  selectField: {
    marginBottom: theme.spacing(2)
  },

  activationTooltip: {
    opacity: 0.54,
    color: theme.palette.error.main,
  },
  action: {
    textTransform: "capitalize"
  },
  buttonContainer: {
    width: 130,
  }
});