import React from "react";
import {Checkbox, TableCell} from "@mui/material";
import {withStyles} from "tss-react/mui";
import AddRemoveNewTestType from "@core/components/AddRemoveNewTestType";
import {STATUSES} from "@core/constants/test";
import {generateID} from "@core/helpers";
import {observer} from "mobx-react";
import useStores from "../../../../../useStores";
import styles from "./styles";

const TypeSelection = observer(({classes, title, disabled, selected, type, setSelected}) => {
  const {UserStore} = useStores();

  const companyId = UserStore.user.data.company._id;
  const numberSelected = selected.filter((test) => test.type === type).length;

  const newTest = {
    assignee: "",
    witnesses: [],
    type,
    company: companyId,
    status: STATUSES.EMPTY,
    properties: {
      tags: []
    },
    _id: generateID(),
  };

  const selectType = (e) => {
    if(disabled) return;
    
    e.preventDefault();
    e.stopPropagation();
    
    if (numberSelected) {
      setSelected(selected.filter((test) => test.type !== type));
    } else {
      setSelected([...selected, newTest]);
    }
  };
  
  return (
    <>
      <TableCell
        padding="none"
        onClick={selectType}
        width="10%"
      >
        <div className={classes.container}>
          <Checkbox
            disabled={disabled}
            color="primary"
            checked={!!numberSelected}
            classes={{
              root: classes.checkbox
            }}
          />
          {!!numberSelected && (
            <AddRemoveNewTestType
              selected={selected}
              type={type}
              setSelected={setSelected}
              numberSelected={numberSelected}
            />
          )}
        </div>
      </TableCell>
      <TableCell width="40%" onClick={selectType}>{title}</TableCell>
    </>
  );
});

export default withStyles(TypeSelection, styles);