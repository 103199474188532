import {getIsWithinRequirements} from "@core/helpers";
import React, {Component} from "react";
import {head, sort, groupBy, prop, map, keys, flatten, isEmpty} from "ramda";
import {Table, TableHead, TableBody, TableCell, TableRow, Tooltip, Grid, Button} from "@mui/material";
import {MdAdd} from "react-icons/md";
import {withStyles} from "tss-react/mui";
import {orientations} from "@core/components/TestForms/ImpactTest/data";
import {getElementRequirements} from "@core/components/TestForms/ImpactTest/services";
import EditableCell from "@core/components/EditableCell";
import EditableDropdownCell from "@core/components/EditableDropdownCell";
import TestResultTableCell from "@core/components/TestResultTableCell";
import {ORIENTATIONS, ZONES} from "@core/constants/test";
import {TEST_RESULTS} from "@core/constants/testResults";
import {ImpactResult} from "@core/services/testResult/impact";
import styles from "./styles";
import classNames from "classnames";

const POSITION_SHORT_FORM = {
  "1/4 t": "1/4T",
  "1/2 t": "MW",
  "3/4 t": "3/4T",
  "2 mm from ID": "ID",
  "2 mm from OD": "OD"
};

const POSITIONS_ORDER = ["2 mm from OD", "1/2 t", "2 mm from ID", "1/4 t", "3/4 t"];

const sortByOrientation = (elements) => sort((a, b) => {
  return orientations.indexOf(a) - orientations.indexOf(b);
}, elements);

const sortByPosition = (elements) => sort((a, b) => {
  const indexOfA = POSITIONS_ORDER.findIndex((p) => a.position?.includes(p));
  const indexOfB = POSITIONS_ORDER.findIndex((p) => b.position?.includes(p));

  return indexOfA - indexOfB;
}, elements);

const sortElements = (elements) => {
  const withIndex = elements.map((element, index) => ({...element, index}));
  const elementsGroupedByOrientation = groupBy(prop("orientation"), withIndex);
  const sortedByPosition = map((elements) => sortByPosition(elements), elementsGroupedByOrientation);
  const sortedOrientations = sortByOrientation(keys(elementsGroupedByOrientation));

  return flatten(sortedOrientations.map((orientation) => sortedByPosition[orientation]));
};

class ImpactTestTable extends Component {

  getAverageEnergy = (element) => {
    const t1 = element.energyJoule1 || 0;
    const t2 = element.energyJoule2 || 0;
    const t3 = element.energyJoule3 || 0;

    return ((parseFloat(t1) + parseFloat(t2) + parseFloat(t3)) / 3).toFixed(1);
  };

  getAverageExpansion = (element) => {
    const t1 = element.lateralExpansion1 || 0;
    const t2 = element.lateralExpansion2 || 0;
    const t3 = element.lateralExpansion3 || 0;

    return ((parseFloat(t1) + parseFloat(t2) + parseFloat(t3)) / 3).toFixed(1);
  };

  getAverageShearArea = (element) => {
    const t1 = element.shearArea1 || 0;
    const t2 = element.shearArea2 || 0;
    const t3 = element.shearArea3 || 0;

    return ((parseFloat(t1) + parseFloat(t2) + parseFloat(t3)) / 3).toFixed(1);
  };

  get isLateralExpansion() {
    return this.props.test.properties.elements.reduce((acc, curr) => {
      const elementRequirements = getElementRequirements(curr, this.props.test.properties.requirements, this.props.test.properties.acceptance, curr.index);

      const LEexist = !!elementRequirements.singleLateralExpansion || !!elementRequirements.averageLateralExpansion;

      if (LEexist) acc = true;

      return acc;
    }, false);
  }

  get isShearArea() {
    return this.props.test.properties.elements.reduce((acc, curr) => {
      const elementRequirements = getElementRequirements(curr, this.props.test.properties.requirements, this.props.test.properties.acceptance, curr.index);

      const SAexist = !!elementRequirements.singleShearArea || !!elementRequirements.averageShearArea;

      if (SAexist) acc = true;

      return acc;
    }, false);
  }

  updateTest = (changes) => {
    const {test, setTest} = this.props;

    const properties = {...test.properties, ...changes};
    setTest({...test, properties});
  };
  
  updateElement = (changes, index) => {
    const {test} = this.props;
    const elements = test.properties.elements.map((element, idx) => idx === index ? {...element, ...changes} : element);

    const impactResult = new ImpactResult(elements, test.properties.requirements);
    const result = impactResult.getResult();

    this.updateTest({elements, result});
  };
  
  addElement = () => {
    const {test} = this.props;
    const [{temperature}] = test.properties.elements;

    const elements = test.properties.elements.concat({temperature});

    this.updateTest({elements, result: TEST_RESULTS.NOT_ACCEPTABLE});
  };

  render() {
    const {editable, test, classes} = this.props;

    const {requirements, elements, temperature, acceptance} = test.properties;

    const showNotchPosition = elements.some((element) => ["HAZ", "Weld"].includes(element.location));
    const showPosition = elements.some((element) => element.position);

    const sorted = sortElements(elements);

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table className={classNames("styled-table", classes.table,classes.tableCenter)}>
            <TableHead>
              <TableRow>
                <TableCell>Spec. ID</TableCell>
                <TableCell>Location</TableCell>
                {showNotchPosition && <TableCell>Notch position</TableCell>}
                {showPosition && <TableCell>Position</TableCell>}
                <TableCell>Orientation</TableCell>
                <TableCell>Test temp ({"\u00b0"}C)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sorted.map((element, index) => {
                const exceptionTemperature = requirements && requirements[element.orientation] && requirements[element.orientation]?.temperature;

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <EditableCell
                        disabled={!editable}
                        value={element.laboratory}
                        editable={editable}
                        setValue={(value) => this.updateElement({laboratory: value}, element.index)}
                      />
                    </TableCell>
                    <TableCell>
                      <EditableDropdownCell
                        value={element.location}
                        onChange={(e) => this.updateElement({location: e.target.value}, element.index)}
                        editable={editable}
                        options={[ZONES.BASE_METAL, ZONES.WELD_METAL, ZONES.HAZ]}
                      />
                    </TableCell>
                    {showNotchPosition && <TableCell>{element.notchPosition || "-"}</TableCell>}
                    {showPosition && <TableCell>{element.position}</TableCell>}
                    <TableCell>
                      <EditableDropdownCell
                        value={element.orientation}
                        onChange={(e) => this.updateElement({orientation: e.target.value}, element.index)}
                        editable={editable}
                        options={ORIENTATIONS}
                      />
                    </TableCell>
                    <TableCell>
                      {exceptionTemperature || element.temperature || temperature || "No data"}
                    </TableCell>
                  </TableRow>
                );
              })}
              {editable && (
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={this.addElement}
                    >
                      <MdAdd size={80} />Add new
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Table className={classNames("styled-table", classes.table,classes.tableCenter)}>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>Spec. ID</TableCell>
                <TableCell colSpan={3}>Absorbed Energy [J]</TableCell>
                <TableCell rowSpan={2}>Energy average [J]</TableCell>
                {this.isLateralExpansion && (
                  <>
                    <TableCell colSpan={3}>Lateral expansion [mm]</TableCell>
                    <TableCell rowSpan={2}>Lateral exp. average [mm]</TableCell>
                  </>
                )}
                {this.isShearArea && (
                  <>
                    <TableCell colSpan={3}>Shear Area [%]</TableCell>
                    <TableCell rowSpan={2}>Shear area average [%]</TableCell>
                  </>
                )}
              </TableRow>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>2</TableCell>
                <TableCell>3</TableCell>

                {this.isLateralExpansion && (
                  <>
                    <TableCell>1</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>3</TableCell>
                  </>
                )}
                {this.isShearArea && (
                  <>
                    <TableCell>1</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>3</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sorted.map((element, index) => {
                const elementRequirements = getElementRequirements(element, requirements, acceptance, element.index);

                const LEexist = !!elementRequirements.singleLateralExpansion || !!elementRequirements.averageLateralExpansion;
                const SAexist = !!elementRequirements.singleShearArea || !!elementRequirements.averageShearArea;

                return (
                  <>
                    <TableRow key={index}>
                      <TableCell rowSpan={!isEmpty(elementRequirements) ? 2 : 1}>
                        {element.laboratory}<br />
                        {element.orientation && showPosition && (
                        `${POSITION_SHORT_FORM[element.position]} / ${head(element.orientation).toUpperCase()}`
                        )}
                        {element.orientation && !showPosition && (
                        `${element.location} / ${head(element.orientation).toUpperCase()}`
                        )}
                        {element.temperature ? element.temperature : null}
                      </TableCell>

                      <TableCell>
                        <TestResultTableCell
                          value={element.energyJoule1}
                          min={elementRequirements.single}
                        >
                          <EditableCell
                            error={!getIsWithinRequirements(element.energyJoule1, elementRequirements.single)}
                            type="number"
                            disabled={!editable}
                            value={element.energyJoule1}
                            editable={editable}
                            setValue={(value) => this.updateElement({energyJoule1: value}, element.index)}
                          />
                        </TestResultTableCell>
                      </TableCell>
                      <TableCell>
                        <TestResultTableCell
                          value={element.energyJoule2}
                          min={elementRequirements.single}
                        >
                          <EditableCell
                            error={!getIsWithinRequirements(element.energyJoule2, elementRequirements.single)}
                            type="number"
                            disabled={!editable}
                            value={element.energyJoule2}
                            editable={editable}
                            setValue={(value) => this.updateElement({energyJoule2: value}, element.index)}
                          />
                        </TestResultTableCell>
                      </TableCell>
                      <TableCell>
                        <TestResultTableCell
                          value={element.energyJoule3}
                          min={elementRequirements.single}
                        >
                          <EditableCell
                            error={!getIsWithinRequirements(element.energyJoule3, elementRequirements.single)}
                            type="number"
                            disabled={!editable}
                            value={element.energyJoule3}
                            editable={editable}
                            setValue={(value) => this.updateElement({energyJoule3: value}, element.index)}
                          />
                        </TestResultTableCell>
                      </TableCell>
                      <TableCell>
                        <TestResultTableCell
                          value={this.getAverageEnergy(element)}
                          min={elementRequirements.average}
                        />
                      </TableCell>
                      {LEexist && (
                        <>
                          <TableCell>
                            <TestResultTableCell
                              value={element.lateralExpansion1}
                              min={elementRequirements.singleLateralExpansion}
                            >
                              <EditableCell
                                error={!getIsWithinRequirements(element.lateralExpansion1, elementRequirements.singleLateralExpansion)}
                                type="number"
                                disabled={!editable}
                                value={element.lateralExpansion1}
                                editable={editable}
                                setValue={(value) => this.updateElement({lateralExpansion1: value}, element.index)}
                              />
                            </TestResultTableCell>
                          </TableCell>
                          <TableCell>
                            <TestResultTableCell
                              value={element.lateralExpansion2}
                              min={elementRequirements.singleLateralExpansion}
                            >
                              <EditableCell
                                error={!getIsWithinRequirements(element.lateralExpansion2, elementRequirements.singleLateralExpansion)}
                                type="number"
                                disabled={!editable}
                                value={element.lateralExpansion2}
                                editable={editable}
                                setValue={(value) => this.updateElement({lateralExpansion2: value}, element.index)}
                              />
                            </TestResultTableCell>
                          </TableCell>
                          <TableCell>
                            <TestResultTableCell
                              value={element.lateralExpansion3}
                              min={elementRequirements.singleLateralExpansion}
                            >
                              <EditableCell
                                error={!getIsWithinRequirements(element.lateralExpansion3, elementRequirements.singleLateralExpansion)}
                                type="number"
                                disabled={!editable}
                                value={element.lateralExpansion3}
                                editable={editable}
                                setValue={(value) => this.updateElement({lateralExpansion3: value}, element.index)}
                              />
                            </TestResultTableCell>
                          </TableCell>
                          <TableCell>
                            <TestResultTableCell
                              value={this.getAverageExpansion(element)}
                              min={elementRequirements.averageLateralExpansion}
                            />
                          </TableCell>
                        </>
                      )}
                      {SAexist && (
                        <>
                          <TableCell>
                            <TestResultTableCell
                              value={element.shearArea1}
                              min={elementRequirements.singleShearArea}
                            >
                              <EditableCell
                                error={!getIsWithinRequirements(element.shearArea1, elementRequirements.singleShearArea)}
                                type="number"
                                disabled={!editable}
                                value={element.shearArea1}
                                editable={editable}
                                setValue={(value) => this.updateElement({shearArea1: value}, element.index)}
                              />
                            </TestResultTableCell>
                          </TableCell>
                          <TableCell>
                            <TestResultTableCell
                              value={element.shearArea2}
                              min={elementRequirements.singleShearArea}
                            >
                              <EditableCell
                                error={!getIsWithinRequirements(element.shearArea2, elementRequirements.singleShearArea)}
                                type="number"
                                disabled={!editable}
                                value={element.shearArea2}
                                editable={editable}
                                setValue={(value) => this.updateElement({shearArea2: value}, element.index)}
                              />
                            </TestResultTableCell>
                          </TableCell>
                          <TableCell>
                            <TestResultTableCell
                              value={element.shearArea3}
                              min={elementRequirements.singleShearArea}
                            >
                              <EditableCell
                                error={!getIsWithinRequirements(element.shearArea3, elementRequirements.singleShearArea)}
                                type="number"
                                disabled={!editable}
                                value={element.shearArea3}
                                editable={editable}
                                setValue={(value) => this.updateElement({shearArea3: value}, element.index)}
                              />
                            </TestResultTableCell>
                          </TableCell>
                          <TableCell>
                            <TestResultTableCell
                              value={this.getAverageShearArea(element)}
                              min={elementRequirements.averageShearArea}
                            >
                              {!elementRequirements.averageShearArea ?
                                <Tooltip title={"Value for information only."}>
                                  <span>{this.getAverageShearArea(element)}</span>
                                </Tooltip> :
                                <span>{this.getAverageShearArea(element)}</span>
                              }
                            </TestResultTableCell>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                    {!isEmpty(elementRequirements) && (
                      <TableRow>
                        <TableCell colspan={3}>
                          {elementRequirements.single && `\u2265 ${elementRequirements.single}`}
                        </TableCell>
                        <TableCell>
                          {elementRequirements.average && `\u2265 ${elementRequirements.average}`}
                        </TableCell>
                        {this.isLateralExpansion && (
                          <>
                            <TableCell colspan={3}>
                              {elementRequirements.singleLateralExpansion && `\u2265 ${elementRequirements.singleLateralExpansion}`}
                            </TableCell>
                            <TableCell>
                              {elementRequirements.averageLateralExpansion && `\u2265 ${elementRequirements.averageLateralExpansion}`}
                            </TableCell>
                          </>
                        )}
                        {this.isShearArea && (
                          <>
                            <TableCell colspan={3}>
                              {elementRequirements.singleShearArea && `\u2265 ${elementRequirements.singleShearArea}`}
                            </TableCell>
                            <TableCell>
                              {elementRequirements.averageShearArea && `\u2265 ${elementRequirements.averageShearArea}` || "-"}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </Grid>

      </Grid>
    );
  }
}

export default withStyles(ImpactTestTable, styles);
