import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import {STATUSES} from "@core/constants/test";
import {Box, Grid, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import useStores from "../../../../../useStores";
import StatisticsSection from "../../../StatisticsSection";
import DashboardTable from "../Table";
import {columns} from "./columns";

const DashboardLab = observer(({userCompanyId}) => {
  const {StatisticStore} = useStores();

  useEffect(() => {
    StatisticStore.getCompanyStatistics(userCompanyId);
  }, []);

  const statistic = StatisticStore.statistic;

  return (
    <Box sx={{padding: 4}}>
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StatisticsSection
            isLoading={!statistic.isLoaded}
            data={[{
              number: statistic.data.openTests,
              description: "Open tests",
              icon: ICONISTS_ICONS.clipboard
            }, {
              number: statistic.data.readyTests,
              description: "Tests ready for signing",
              icon: ICONISTS_ICONS.clipboard
            }, {
              number: statistic.data.labTests,
              description: "Tests created",
              icon: ICONISTS_ICONS.clipboard
            }, {
              number: statistic.data.attestationTests,
              description: "Tests with attestation",
              icon: ICONISTS_ICONS.clipboard
            }]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <DashboardTable
                title="Latest test requests"
                noFoundText="No tests found."
                type="tests"
                columns={columns}
                params={{statuses: [STATUSES.ASSIGNED]}}
                onRowClick={(test) => window.open(`/tests/${test._id}`, "_self")}
              />
            </Grid>
            <Grid item>
              <DashboardTable
                title="Waiting customer approval"
                noFoundText="No tests found."
                type="tests"
                columns={columns}
                params={{statuses: [STATUSES.INSPECTED, STATUSES.SUBMITTED]}}
                onRowClick={(test) => window.open(`/tests/${test._id}`, "_self")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <DashboardTable
            title="Waiting for signing"
            noFoundText="No tests found."
            type="tests"
            columns={columns}
            params={{statuses: [STATUSES.FILLED]}}
            onRowClick={(test) => window.open(`/tests/${test._id}`, "_self")}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default DashboardLab;
