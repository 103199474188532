import {action, observable, computed, makeObservable} from "mobx";

class MenuStore {

  constructor() {
    const menuState = localStorage.getItem("is-menu-collapsed");
    const isCollapsed = menuState === "true";
    this.collapsed = isCollapsed;
    makeObservable(this, {
      collapsed: observable,
      toggleCollapsed: action,
      changeCollapsed: action,
      menuWidth: computed,
    });
  }

  collapsed = false;
  
  toggleCollapsed() {
    const menuState = localStorage.getItem("is-menu-collapsed");
    const isCollapsed = menuState === "true";

    this.changeCollapsed(!isCollapsed);
  }

  changeCollapsed(value) {
    localStorage.setItem("is-menu-collapsed", value);
    this.collapsed = value;
  }
  
  get menuWidth() {
    return this.collapsed ? 60 : 260;
  }
}

export default new MenuStore();