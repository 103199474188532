import {TYPES} from "@core/constants/test";
import {getTestName, capitalizeFirstLetter} from "@core/helpers";
import moment from "moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";

const REQUIREMENT_REQ_EXP = /\(([^)]+)\)/;

const IMPACT_REQUIREMENTS_DESCRIPTIONS = {
  "After reeling": "After reeling simulation",
  "After SPWHT": "After SPWHT",
};

const TITLE_BY_TEST_NAME = {
  [TYPES.CHEMICAL]: (testName, test) => {
    if(!test.properties?.analysis) return testName;

    return `${testName} - ${capitalizeFirstLetter(test.properties.analysis)}`;
  },
  [TYPES.IMPACT]: (testName, test) => {
    const [, requirementType] = REQUIREMENT_REQ_EXP.exec(test.properties?.acceptance) || [];
    const requirementName = IMPACT_REQUIREMENTS_DESCRIPTIONS[requirementType];

    if (requirementType && requirementName) return `${testName} - ${requirementName}`;

    if (test.properties?.impactTestCurve) return `${testName} - Transition Curve`;

    return testName;
  },
  [TYPES.OTHER]: (testName, test, groupTests) => {
    const isIndividualOtherTest = test.properties.elements?.length === 1;

    if(isIndividualOtherTest && groupTests) return test.properties.elements[0].test;

    return testName;
  },
  [TYPES.CUSTOM_REPORT]: (testName, test, groupTests, testVersions) => {
    if(testVersions.length <= 1) return testName;

    return `${testName} - ${moment(test.date_created).format(DATE_FORMAT)}`;
  },
};

const TestReportTitle = ({test, groupTests, testVersions}) => {
  const titleHandler = TITLE_BY_TEST_NAME[test.type];
  const testName = getTestName(test);

  if(titleHandler) return titleHandler(testName, test, groupTests, testVersions);

  return testName;
};

TestReportTitle.defaultProps = {
  groupTests: false,
  testVersions: []
};

export default TestReportTitle;