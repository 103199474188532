import React, {useEffect, useMemo} from "react";
import TextField from "@core/components/FormikTextField";
import {Grid} from "@mui/material";
import {useFormikContext} from "formik";
import {getDensity} from "../../helpers";

const NonPorousTestForm = () => {
  const {values, setFieldValue} = useFormikContext();

  const c = useMemo(() => {
    if(values.massOfItemAndSupportInWater && values.massOfSupportInWater) {
      return Number(values.massOfItemAndSupportInWater) + Number(values.massOfSupportInWater);
    }

    return Number(values.massOfItemInWater);
  }, [values.massOfItemAndSupportInWater, values.massOfSupportInWater, values.massOfItemInWater]);

  const density = useMemo(() => {
    return getDensity(values.massOfItemInAir, values.densityOfWater, Number(values.massOfItemInAir - c));
  }, [c, values.massOfItemInAir, values.densityOfWater]);

  useEffect(() => {
    setFieldValue("density", density);
  }, [density]);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={3}>
        <TextField
          type="number"
          name="massOfItemInAir"
          label="Mass of item in air"
          endAdornment="g"
          required
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          type="number"
          name="massOfItemAndSupportInWater"
          label="Mass of item +support in water"
          endAdornment="g"
          disabled={values.massOfItemInWater}
          onChange={() => setFieldValue("massOfItemInWater", "")}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          type="number"
          name="massOfSupportInWater"
          label="Mass of support in water"
          endAdornment="g"
          disabled={values.massOfItemInWater}
          onChange={() => setFieldValue("massOfItemInWater", "")}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          type="number"
          name="massOfItemInWater"
          label="Mass of item in water (support tared)"
          endAdornment="g"
          onChange={() => {
            setFieldValue("massOfItemAndSupportInWater", "");
            setFieldValue("massOfSupportInWater", "");
          }}
          disabled={values.massOfItemAndSupportInWater && values.massOfSupportInWater}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          type="number"
          name="densityOfWater"
          label="Density of water"
          endAdornment="g/m^3"
          required
        />
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              type="number"
              name="density"
              label="Density"
              endAdornment="g/m^3"
              disabled
              required
            />
          </Grid>
          {values.acceptanceCriteria && (
            <Grid item sx={{paddingBottom: 1.5}} alignSelf="flex-end">
              {`>= ${values.acceptanceCriteria}`}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NonPorousTestForm;