import React from "react";
import {Tooltip, Box} from "@mui/material";

const Formula = ({element}) => {
  return (
    <Tooltip title={element.formula}>
      <Box sx={{cursor: "pointer"}}>
        {element.label || element.bm}
      </Box>
    </Tooltip>
  );
};

export default Formula;