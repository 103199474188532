import {useEffect} from "react";
import qs from "qs";
import {useHistory, useLocation} from "react-router";

function useSetInitialViewQueryParam(defaultView, runInEffect = true, canClear = false) {
  const history = useHistory();
  const location = useLocation();

  const setInitialViewQueryParam = () => {
    const initialQueryParams = qs.parse(location.search.slice(1));

    if (!initialQueryParams.view) {
      history.replace({
        pathname: location.pathname,
        search: qs.stringify({...initialQueryParams, view: defaultView})
      });
    }
  };

  const clearInitialQueryParam = canClear ? () => {
    const currentQueryParams = qs.parse(location.search.slice(1));
    delete currentQueryParams.view;

    history.replace({
      pathname: location.pathname,
      search: qs.stringify(currentQueryParams)
    });
  } : () => {};

  useEffect(() => {
    if (runInEffect) {
      setInitialViewQueryParam();
    }
  }, []);

  return {setInitialViewQueryParam, clearInitialQueryParam};
}

export default useSetInitialViewQueryParam;