import React, {useRef, useState} from "react";
import {isEmpty, omit} from "ramda";
import {Dialog, DialogContent} from "@mui/material";
import testConfig from "@core/configs/test";
import ConfirmationOnModalClose from "@core/components/ConfirmationOnModalClose";
import useStores from "../../../../../useStores";

const AddDataModal = ({open, onClose, addData, certificates, test}) => {
  const {UserStore} = useStores();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const formRef = useRef();

  if(!testConfig[test.type].addDataForm) return;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (isEmpty(formRef.current.touched)) onClose();
          else setConfirmationOpen(true);
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {React.cloneElement(testConfig[test.type].addDataForm, {
            formRef,
            saveTest: addData,
            inspectedCertificates: certificates,
            isProducerTest: true,
            user: UserStore.user.data,
            test: {
              witnesses: test.witnesses.map((witness) => ({company: witness})),
              properties: {
                tags: test.properties.tags
              },
            },
          })}
        </DialogContent>
      </Dialog>
      <ConfirmationOnModalClose
        confirmationOpen={confirmationOpen}
        formRef={formRef}
        setConfirmationOpen={setConfirmationOpen}
        onClose={onClose}
        onSave={() => {
          const values = formRef.current.values;
          addData(omit(["witnesses"], values), values.witnesses);
        }}
      />
    </>
  );
};

export default AddDataModal;
