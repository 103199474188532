import React from "react";
import {Divider, Grid, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {clone, indexOf} from "ramda";
import {Uploader} from "@core/components/Uploaders/";
import AddValue from "../AddValue";
import styles from "./styles";

const Item = ({classes, item, newItems, setNewItems, productType, index, indexInNewItems, groupItems}) => (
  <>
    <Divider />
    <Grid container className={classes.newItem}>
      <Grid item xs={2} container alignItems="center" justifyContent="center">
        <HighlightOffIcon
          className={classes.closeIcon}
          onClick={() => {
            const indexToRemove = indexOf(item, newItems);
            const copy = clone(newItems);
            copy.splice(indexToRemove, 1);
            setNewItems(copy);
          }}
        />
      </Grid>
      <Grid item xs={6} container alignItems="center">
        <div>
          <div>
            <span className={classes.property}>Item {index + 1}:</span>
            &nbsp;
            {productType}
          </div>
          <div className={classes.quantity}>
            <span className={classes.property}>Quantity:</span>
            &nbsp;
            {item.quantity}
          </div>
          {!groupItems && (
            <>
              <AddValue
                value={item.lotId}
                label="Lot ID"
                buttonName="Add Lot ID"
                propertyName="lotId"
                newItems={newItems}
                indexInNewItems={indexInNewItems}
                setNewItems={setNewItems}
              />
              <AddValue
                value={item.notes}
                label="Notes"
                buttonName="Add notes"
                propertyName="notes"
                newItems={newItems}
                indexInNewItems={indexInNewItems}
                setNewItems={setNewItems}
              />
            </>
          )}
        </div>
      </Grid>
      <Grid xs={4} item container alignItems="center" justifyContent="flex-end">
        <Uploader
          className={classes.uploader}
          file={item.split_image}
          handleUploadedFile={(e) => {
            const items = newItems.map((i, idx) => {
              if (idx !== indexInNewItems) return i;

              return {...i, split_image: e.file.dir + e.file.name};
            });
            setNewItems(items);
          }}
          handleResetPreview={() => {
            const items = newItems.map((i, idx) => {
              if (idx !== indexInNewItems) return i;

              delete i.split_image;

              return i;
            });
            setNewItems(items);
          }}
        />
      </Grid>
    </Grid>
  </>
);

const Certificate = ({classes, newItems, setNewItems, productType, indexInNewItems, groupItems}) => {
  return (
    <div className={classes.newCertificate}>
      <Typography className={classes.newCertificateHeader} component="h6" variant="h6">
        New certificate
      </Typography>
      {groupItems && newItems.map((item, index) => (
        <Item
          item={item}
          classes={classes}
          newItems={newItems}
          setNewItems={setNewItems}
          productType={productType}
          index={index}
          indexInNewItems={index}
          groupItems
        />
      ))}
      {!groupItems && newItems.slice(indexInNewItems, indexInNewItems + 1).map((item, index) => (
        <Item
          item={item}
          classes={classes}
          newItems={newItems}
          setNewItems={setNewItems}
          productType={productType}
          index={index}
          indexInNewItems={indexInNewItems}
        />
      ))}
    </div>
  );
};

export default withStyles(Certificate, styles);