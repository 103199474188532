import React, {memo} from "react";
import Pipe from "../AnimatedPipe";
import {SINGLE_RED_BAND_PIPE_CONFIG} from "../../constants";

const SingleRedBandPipe = ({...props}) => {
  return (
    <Pipe
      {...props}
      fill={SINGLE_RED_BAND_PIPE_CONFIG.BACKGROUND_COLOR}
    />
  );
};

export default memo(SingleRedBandPipe);