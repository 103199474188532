export default (theme) => ({
  header: {
    marginBottom: theme.spacing(),
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginLeft: 5,
      cursor: "pointer",
    }
  },
});
