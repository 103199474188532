import {TableCell, TableRow} from "@mui/material";
import React from "react";
import Field from "../Field";

const FieldRow = ({field, required, editable}) => {
  const {label, ...rest} = field;

  return (
    <TableRow>
      <TableCell width="50%">{label}</TableCell>
      <TableCell width="50%">
        <Field
          editable={editable}
          required={required}
          errorMessage={null}
          {...rest}
        />
      </TableCell>
    </TableRow>
  );
};

export default FieldRow;