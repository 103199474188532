import React from "react";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
const Text = ({classes, activeItem, withPercentage, allNumber}) => {
  return (
    <span className={classes.value}>
      {withPercentage ? (
        `${activeItem.title}: ${Math.round(activeItem.number / allNumber * 100)}%`
      ) : activeItem.title}
    </span>
  );
};

export default withStyles(Text, styles);