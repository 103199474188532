export default (theme) => ({
  qcpHeader: {
    border: "1px solid #e5e5e5",
    borderLeft: "none",
    color: "#575757",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    padding: 16.5,
  },
  rightCell: {
    border: "1px solid #e5e5e5",
    borderLeft: "none",
  },
  checkbox: {
    padding: "4px 10px",
  },
  testName: {
    
  }
});