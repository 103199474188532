import * as R from "ramda";

export const splitIntoChunks = R.curry(function group(n, list) {
  return R.isEmpty(list) ? [] : R.prepend(R.take(n, list), group(n, R.drop(n, list)));
});

export const splitByIntoChunks = (ns, xs) =>
  ns.reduce(({xs, found}, n) => ({
    xs: xs.slice(n),
    found: [...found, xs.slice(0, n)]
  }), {xs, found: []}).found;