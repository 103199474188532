import React, {useEffect, useState} from "react";
import {Field, Form} from "formik";
import {Select} from "@core/components/Form";
import {Uploader} from "@core/components/Uploaders";
import TextField from "@core/components/FormikTextField";
import {Button, Grid, IconButton, InputAdornment, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {MdVisibility, MdVisibilityOff} from "react-icons/md/";
import rolesConst from "@core/constants/roles";
import modules from "@core/constants/modules";
import styles from "./styles";

const UserForm = ({handleSubmit, values, touched, isValid, allowRoleSelection, classes, header, allowSubmit, formik}) => {

  useEffect(() => {
    const isSubform = Boolean(formik);

    if (!isSubform) return;

    const {setFieldValue, name, setFieldTouched} = formik;
    setFieldTouched(name, touched[name]);
    setFieldValue(name, values, true);
  }, [values]);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [avatarPreview, setPreview] = useState(null);

  return (
    <div className="content padded-container">
      <h1 className={classes.mb25}>{header}</h1>
      <Form>
        <Grid container className={classes.mb30}>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid container spacing={4}>
                  <Grid container item>
                    <TextField
                      name="username"
                      label="Username"
                      required
                    />
                  </Grid>
                  <Grid container item>
                    <TextField
                      name="password"
                      label="Password"
                      required
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={() => setShowPassword(!showPassword)} size="large">
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid container item>
                    <TextField
                      name="passwordConfirmation"
                      label="Password confirmation"
                      required
                      type={showPasswordConfirmation ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                            size="large">
                            {showPasswordConfirmation ? <MdVisibility /> : <MdVisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="avatar"
                >
                  {({field, form}) => {
                    return (
                      <Uploader
                        justifyContent="center"
                        isDrop
                        className={classes.avatarUpload}
                        preloadText="drop avatar file here"
                        file={avatarPreview}
                        handleUploadedFile={({_id, file}) => {
                          form.setFieldValue(field.name, _id);
                          setPreview(file.dir + file.name);
                        }}
                        handleResetPreview={() => {
                          form.setFieldValue("avatar", "");
                          setPreview(null);
                        }}
                      />
                    );
                  }}
                </Field>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid container spacing={4}>
                  <Grid container item>
                    <TextField
                      name="firstname"
                      label="First name"
                      required
                    />
                  </Grid>
                  <Grid container item>
                    <TextField
                      name="lastname"
                      label="Last name"
                      required
                    />
                  </Grid>
                  <Grid container item>
                    <TextField
                      name="email"
                      label="Email"
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {allowRoleSelection && (
                  <Field
                    name="role"
                  >
                    {({field, form}) => (
                      <Select
                        required
                        label="Role"
                        value={field.value}
                        className={classes.selectField}
                        onChange={({target}) => form.setFieldValue("role", target.value)}
                      >
                        {Object.values(rolesConst).filter((el) => el !== modules.SUPER_ADMIN)
                          .map((el, index) => (
                            <MenuItem key={index} value={el}>
                              {el}
                            </MenuItem>)
                          )}
                      </Select>
                    )}
                  </Field>)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
      {allowSubmit && (
        <Button
          className={classes.mt40}
          variant="contained"
          color="primary"
          size="large"
          disabled={!isValid}
          onClick={handleSubmit}
        >
          Save
        </Button>
      )}
    </div>
  );
};

UserForm.defaultProps = {
  header: "Create New User",
  allowRoleSelection: true,
  allowSubmit: true
};

export default withStyles(UserForm, styles);