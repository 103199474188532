import {action, makeObservable} from "mobx";

class BaseStore {
  constructor() {
    makeObservable(this, {
      clear: action,
    });
  }

  /**
   * clear
   * @param store {string} store name - store that will be cleared
   */
  clear(store) {
    store.forEach((S) => {
      this[S].isLoaded = false;
    });
  }
}

export default BaseStore;
