import React, {useState} from "react";
import {Formik} from "formik";
import {
  Grid,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import TextField from "@core/components/FormikTextField";
import * as yup from "yup";
import {MdVisibility, MdVisibilityOff} from "react-icons/md/";
import {ROLES, ROLES_TITLES} from "@core/constants/externalUsersRoles";
import {validatePassword} from "@core/helpers";
import PasswordChange from "../../ExternalUsers/components/PasswordChange";
import styles from "./styles";

const initialValues = {
  firstname: "",
  lastname: "",
  company: "",
  email: "",
  password: "",
  username: "",
  labels: []
};

const ExternalUserForm = ({classes, onSubmit, externalUser}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordFormOpen, setPasswordFormOpen] = useState(false);

  const validationSchema = yup.object().shape({
    username: yup.string().required("The field is required!"),
    firstname: yup.string().required("The field is required!"),
    lastname: yup.string().required("The field is required!"),
    company: yup.string().required("The field is required!"),
    email: yup.string().email("Email has to be of type name@email.com"),
    ...{
      ...(!externalUser ? {
        password: yup.string()
          .required("The field is required!")
          .test("password", validatePassword),
      } : {})
    },
    labels: yup.array().min(1, "Role is required!")
  });

  const changeRoles = (event, {values, setFieldValue}) => {
    const {name, checked} = event.target;

    if (checked) setFieldValue("labels", values.labels.concat(name));
    else setFieldValue("labels", values.labels.filter((label) => label !== name));
  };

  return (
    <div className={classes.container}>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={externalUser || initialValues}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {isValid, handleSubmit} = props;

          return <>
            <Grid container spacing={4}>
              <Grid item container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    label='Username'
                    name='username'
                    required
                    inputProps={{
                      autocomplete: "new-password"
                    }}
                  />
                </Grid>
                {!externalUser && (
                  <Grid item xs={3}>
                    <TextField
                      label='Password'
                      name='password'
                      required
                      type={showPassword ? "text" : "password"}
                      inputProps={{
                        autocomplete: "new-password"
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={() => setShowPassword(!showPassword)} size="large">
                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    label='First name'
                    name='firstname'
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label='Last name'
                    name='lastname'
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    label='Company'
                    name='company'
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label='Email'
                    name='email'
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={4}>
                <Grid item xs={3}>
                  <FormControl
                    variant="standard"
                    component="fieldset"
                    required
                    error={Boolean(props.errors.labels) && props.touched.labels}>

                    <FormLabel component="legend" classes={{asterisk: classes.asterisk}}>Role</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={props.values.labels.includes(ROLES.SUPPLIER)}
                            onChange={(e) => changeRoles(e, props)}
                            name={ROLES.SUPPLIER}
                            color="primary"
                          />
                        )}
                        label={ROLES_TITLES.SUPPLIER}
                      />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={props.values.labels.includes(ROLES.CUSTOMER)}
                            onChange={(e) => changeRoles(e, props)}
                            name={ROLES.CUSTOMER}
                            color="primary"
                          />
                        )}
                        label={ROLES_TITLES.CUSTOMER}
                      />
                    </FormGroup>
                    {(Boolean(props.errors.labels) && props.touched.labels) && (
                      <FormHelperText>{props.errors.labels}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={3} container alignItems="center" spacing={4}>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={!isValid}
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                  {externalUser && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setPasswordFormOpen(true)}
                      >
                        Change password
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {externalUser && (
              <PasswordChange
                userId={externalUser._id}
                open={passwordFormOpen}
                onClose={() => setPasswordFormOpen(false)}
              />
            )}
          </>;
        }}
      </Formik>
    </div>
  );
};

export default withStyles(ExternalUserForm, styles);
