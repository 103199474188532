import React from "react";
import {MenuItem, FormControl, InputLabel, Select as MaterialSelect, InputBase, FormHelperText} from "@mui/material";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import styles from "./styles";

const Select = (props) => {
  const {
    classes,
    name,
    value,
    label,
    error,
    errorMessage,
    onChange,
    disabled,
    variant,
    shrinkLabel,
    multiple,
    className,
    required,
    placeholder,
    onBlur,
    defaultValue,
    renderValue,
    endAdornment,
    menuProps,
  } = props;

  return (
    <div className={classes.selectRoot}>
      <FormControl variant="standard" className={classNames(className, classes.control)}>
        {label ? <InputLabel shrink={shrinkLabel} htmlFor={`select-${name}`} className={classNames(classes.label, required && label ? classes.asteriskLabel : null)}>{label}</InputLabel> : null}
        <MaterialSelect
          defaultValue={defaultValue}
          variant={variant}
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          error={error}
          multiple={multiple}
          className={classNames({
            [classes.error]: error,
            [classes.disabled]: disabled,
          })}
          onBlur={onBlur}
          renderValue={renderValue}
          input={<InputBase
            name={name}
            id={`select-${name}`}
            classes={{
              root: classNames(classes.root, {[classes.rootMargin]: !!label}),
              input: classNames(label ? classes.input : classes.inputNoLabel, !label && required ? classes.asteriskField : null ),
              error: classes.error,
            }} />}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            ...menuProps,
          }}
          displayEmpty
        >
          <MenuItem disabled value="" className={classes.placeholder}>
            {placeholder}
          </MenuItem>
          {props.children}
        </MaterialSelect>
        {error && <FormHelperText className={classes.helperTest}>{errorMessage}</FormHelperText>}
      </FormControl>
      {endAdornment && <span className={classes.selectAdornment}>{endAdornment}</span>}
    </div>
  );
};

Select.defaultProps = {
  variant: "filled",
  shrinkLabel: true
};

export default withStyles(Select, styles);