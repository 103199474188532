import {TEST_RESULTS} from "@core/constants/testResults";
import {isAcceptable} from "@core/helpers";
import React, {useState} from "react";
import {Button, MenuItem, FormControl, Grid} from "@mui/material";
import {keys} from "ramda";
import {withStyles} from "tss-react/mui";
import {Input, Select} from "@core/components/Form";
import {FieldArray, Formik, getIn} from "formik";
import * as yup from "yup";
import styles from "./styles";
import {positions, acceptances, testStandards, EXCEPTIONS, ELEMENT} from "./data";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestFooter from "../../LabTestFooter";
import MuiSelect from "@core/components/MuiSelect";

const isNotDefective = (elements) => elements.every((item) => item.result && isAcceptable(item.result));

const BendTest = ({classes, test, saveTest, user, client, formRef}) => {
  const [confirmAction, setConfirmAction] = useState({open: false, testId: 0});
  const cancelConfirm = () => setConfirmAction({...confirmAction, open: false});

  const validationSchema = yup.object().shape({
    testStandard: yup.string().required(),
    acceptance: yup.string().required(),
    elements: yup.array().of(yup.object().shape({
      width: yup.number().typeError("must be a number").required("Width is required"),
      height: yup.number().typeError("must be a number").required("Height is required"),
      jig: yup.number().typeError("must be a number").required("Jig is required"),
      rollsDistance: yup.number().typeError("must be a number").required("Roll distance is required"),
      angle: yup.number().typeError("must be a number").required("Angle is required"),
      specimenId: yup.string().required("Specimen is required"),
      result: yup.string().required("Result is required"),
    })),
    client: yup.string().required("is required"),
    lab: yup.string().required("is required"),
  });
  
  const initialValues = {
    testStandard: test.properties.testStandard || "",
    acceptance: test.properties.acceptance || "",
    elements: test.properties.elements || [ELEMENT],
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name,
    notes: test.properties.notes || "",
  };

  const onSubmit = (values) => {
    const isAcceptable = isNotDefective(values.elements);
    saveTest({
      testStandard: values.testStandard,
      acceptance: values.acceptance,
      lab: values.lab,
      client: values.client,
      elements: values.elements,
      notes: values.notes,
      result: isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE
    });
  };
  
  const exceptions = keys(EXCEPTIONS).filter((exception) => !exception.company || EXCEPTIONS[exception].company.includes(user.company.name));

  return (
    <div>
      <Formik
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values: {acceptance, lab, elements, notes},
            errors,
            handleChange,
            touched,
            setFieldTouched,
            setFieldValue
          } = props;

          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          const removeTest = (testId, remove) => {
            remove(testId);
            setConfirmAction({open: false, testId: 0});
          };

          const isAcceptable = isNotDefective(elements);

          return <>
            <Grid container className={classes.gridRoot} spacing={2}>
              <Grid item xs={2}>
                <ClientField isFromProducer={!!client.name} />
              </Grid>
              <Grid item xs={2}>
                <FormControl variant="standard">
                  <Input
                    disabled
                    label={"Laboratory"}
                    name='lab'
                    value={lab}
                    error={Boolean(errors.lab) && touched.lab}
                    errorMessage={errors.lab}
                    onChange={(e) => change("lab", e)}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.gridRoot} spacing={2}>
              <Grid item xs={2}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={testStandards}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl variant="standard" className={classes.formControl}>
                  <Select
                    required
                    label='Load requirements'
                    name='acceptance'
                    value={acceptance}
                    onChange={(event) => {
                      change("acceptance", event);
                      
                      const value = event.target.value;

                      if(!value) {
                        setFieldValue("elements", [ELEMENT]);
                        setFieldValue("notes", "");
                      } else {
                        if(!EXCEPTIONS[value]) return;

                        const {elements = [ELEMENT], notes = ""} = EXCEPTIONS[value].requirements;

                        setFieldValue("elements", elements);
                        setFieldValue("notes", notes);
                      }
                    }}
                  >
                    <MenuItem key="N/A" value={undefined}>N/A</MenuItem>
                    {exceptions.map((exception) => (
                      <MenuItem key={exception} value={exception}>{exception}</MenuItem>
                    ))}
                    {acceptances.map((E) => <MenuItem key={E.value} value={E.value}>{E.value}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <FieldArray
              name="elements"
            >
              {({remove, push}) => {
                return <>
                  {elements.map((element, idx) => <div className={classes.sectionWrapper} key={idx}>
                    <Grid container spacing={5}>
                      <Grid item xs={1}>
                        <h3 className={classes.sectionTitle}>Test {idx + 1}</h3>
                      </Grid>
                      {elements.length > 1 && <Grid item xs={1}>
                        <Button
                          variant="contained"
                          size={"medium"}
                          color="secondary"
                          onClick={() => setConfirmAction({open: true, id: idx})}
                        >
                          Remove
                        </Button>
                      </Grid>}
                    </Grid>
                    <Grid container className={classes.gridRoot} spacing={2}>
                      <Grid item xs={2}>
                        <FormControl variant="standard">
                          <Input
                            label={"Specimen Id"}
                            name={`elements.${idx}.specimenId`}
                            value={element.specimenId}
                            error={Boolean(getIn(errors, `elements.${idx}.specimenId`)) && getIn(touched, `elements.${idx}.specimenId`)}
                            errorMessage={getIn(errors, `elements.${idx}.specimenId`)}
                            onChange={(e) => change(`elements.${idx}.specimenId`, e)}
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl variant="standard" className={classes.formControl}>
                          <Select
                            name={`elements.${idx}.position`}
                            label='Position'
                            required
                            value={element.position}
                            onChange={(event) => change(`elements.${idx}.position`, event)}
                          >
                            {positions.map((E) => <MenuItem key={E.value} value={E.value}>{E.value}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridRoot} spacing={2} alignItems={"center"}>
                      <Grid item xs={2}>
                        <FormControl variant="standard" className={classes.formControl}>
                          <Input
                            label={"Specimen Dimensions"}
                            name={`elements.${idx}.width`}
                            placeholder={"0"}
                            value={element.width}
                            error={Boolean(getIn(errors, `elements.${idx}.width`)) && getIn(touched, `elements.${idx}.width`)}
                            errorMessage={getIn(errors, `elements.${idx}.width`)}
                            endAdornment={"MM"}
                            type={"number"}
                            onChange={(e) => change(`elements.${idx}.width`, e)}
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} className={classes.height}>
                        <span className={classes.additionalLabel}>X</span>
                        <FormControl variant="standard">
                          <Input
                            name={`elements.${idx}.height`}
                            value={element.height}
                            placeholder={"0"}
                            type={"number"}
                            error={Boolean(getIn(errors, `elements.${idx}.height`)) && getIn(touched, `elements.${idx}.height`)}
                            errorMessage={getIn(errors, `elements.${idx}.height`)}
                            endAdornment={"MM"}
                            onChange={(e) => change(`elements.${idx}.height`, e)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl variant="standard" className={classes.formControl}>
                          <Input
                            label='Plunger radius'
                            name={`elements.${idx}.jig`}
                            value={element.jig}
                            placeholder={"0"}
                            type={"number"}
                            error={Boolean(getIn(errors, `elements.${idx}.jig`)) && getIn(touched, `elements.${idx}.jig`)}
                            errorMessage={getIn(errors, `elements.${idx}.jig`)}
                            endAdornment={"MM"}
                            onChange={(e) => change(`elements.${idx}.jig`, e)}
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl variant="standard" className={classes.formControl}>
                          <Input
                            label='Rolls Distance'
                            name={`elements.${idx}.rollsDistance`}
                            value={element.rollsDistance}
                            placeholder={"0"}
                            type={"number"}
                            error={Boolean(getIn(errors, `elements.${idx}.rollsDistance`)) && getIn(touched, `elements.${idx}.rollsDistance`)}
                            errorMessage={getIn(errors, `elements.${idx}.rollsDistance`)}
                            endAdornment={"MM"}
                            onChange={(e) => change(`elements.${idx}.rollsDistance`, e)}
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <FormControl variant="standard" className={classes.formControl}>
                          <Input
                            label='Bend Angle'
                            name={`elements.${idx}.angle`}
                            value={element.angle}
                            placeholder={"0"}
                            type={"number"}
                            error={Boolean(getIn(errors, `elements.${idx}.angle`)) && getIn(touched, `elements.${idx}.angle`)}
                            errorMessage={getIn(errors, `elements.${idx}.angle`)}
                            onChange={(e) => change(`elements.${idx}.angle`, e)}
                            required
                            endAdornment="°"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridRoot} spacing={2}>
                      <Grid item xs={2}>
                        <FormControl variant="standard" className={classes.formControl}>
                          <Select
                            label='Test result'
                            name={`elements.${idx}.result`}
                            required
                            value={element.result}
                            onChange={(event) => change(`elements.${idx}.result`, event)}
                          >
                            <MenuItem value={TEST_RESULTS.NO_INDICATION}>{TEST_RESULTS.NO_INDICATION}</MenuItem>
                            <MenuItem value={TEST_RESULTS.ACCEPTABLE_INDICATIONS}>{TEST_RESULTS.ACCEPTABLE_INDICATIONS}</MenuItem>
                            <MenuItem value={TEST_RESULTS.NOT_ACCEPTABLE_DEFECTS}>{TEST_RESULTS.NOT_ACCEPTABLE_DEFECTS}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  )}
                  <Grid container>
                    <Grid item xs={1} className={classes.formButton}>
                      <Button
                        variant="contained"
                        size={"large"}
                        fullWidth
                        color="primary"
                        disabled={false}
                        onClick={() => push({})}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  <Confirmation
                    open={confirmAction.open}
                    onCancel={cancelConfirm}
                    onConfirm={() => removeTest(confirmAction.testId, remove)}
                    alertText={"Are you sure you want to delete the test?"}
                  />
                </>;
              }}
            </FieldArray>
            <Grid container className={classes.gridRoot} spacing={2}>
              <Grid item xs={4}>
                <FormControl variant="standard" className={classes.formControl}>
                  <Input
                    label='Notes'
                    name='notes'
                    value={notes}
                    error={Boolean(errors.notes) && touched.notes}
                    errorMessage={errors.notes}
                    onChange={(e) => change("notes", e)}
                    multiline
                    rows={5}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <TestFooter
              onSubmit={onSubmit}
              result={isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE}
            />
          </>;
        }}
      </Formik>
    </div>
  );
};

export default withStyles(BendTest, styles);
