import {ROUTES} from "@core/api/routes";
import AddDataDrawer from "@core/components/AddDataDrawer";
import {ACTIONS} from "@core/constants/api";
import {FEATURES} from "@core/constants/features";
import {STATUSES, TYPES} from "@core/constants/test";
import {base64ToBlob, downloadBlob} from "@core/helpers";
import axios from "axios";
import React, {useMemo, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router";
import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  CircularProgress,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {MdAddCircleOutline} from "react-icons/md";
import {RiFileTransferLine} from "react-icons/ri";
import {CgImport} from "react-icons/cg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import ImportData from "../ImportData";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import styles from "./styles";
import useStores from "../../../../useStores";

const Actions = observer(({classes, interfaceType, setShowTransferModal, setShowAddCertificates, setOrderCertificates}) => {
  const [moreActionsOpen, setMoreActionsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isConfirmationToToggleOpen, setConfirmationToToggleOpen] = useState(false);
  const [isConfirmationToDeleteOpened, setIsConfirmationToDeleteOpened] = useState(false);
  const [addDataOpen, setAddDataOpen] = useState(false);
  const anchorEl = useRef(null);

  const {TransferStore, UserStore} = useStores();
  const history = useHistory();

  const {isDraft, _id: transferId, certificates = []} = TransferStore.transfer;

  const canImportData = UserStore.features[FEATURES.ORDER_IMPORT];
  
  const canExport = useMemo(() => {
    return !!certificates.length && certificates.some((certificate) =>
      certificate.tests.some((test) => test.type === TYPES.DIMENSIONAL && ![STATUSES.ASSIGNED, STATUSES.EMPTY].includes(test.status))
    );
  }, [certificates]);

  const downloadOrder = async () => {
    setIsExporting(true);
    const response = await axios.post(ROUTES.TRANSFER[ACTIONS.DOWNLOAD](transferId), {responseType: "blob"});
    const filenameIndex = response.headers["content-disposition"].indexOf("=") + 1;
    const filename = response.headers["content-disposition"].substring(filenameIndex);
    downloadBlob(base64ToBlob(response.data), filename, response.headers["content-type"]);
    setIsExporting(false);
  };

  const importData = async ({certificates}) => {
    await axios.post(ROUTES.CERTIFICATE[ACTIONS.IMPORT], {certificates, transferId});

    await TransferStore.getTransfer(transferId);
    setOrderCertificates(TransferStore.transfer.certificates);
  };

  return <>
    <Grid container spacing={3} justifyContent="flex-end" alignItems="center">
      {(isDraft && interfaceType === INTERFACE_TYPE.SENDER) && (
        <>
          <Grid item>
            <Button
              variant="containedGray"
              onClick={() => setShowAddCertificates(true)}>
              <MdAddCircleOutline size={20} />&nbsp;<span>Add certificates</span>
            </Button>
          </Grid>
          {canImportData && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddDataOpen(true)}
              >
                <CgImport size={20} />&nbsp;<span>Import data</span>
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowTransferModal(true)}
            >
              <RiFileTransferLine size={20} />&nbsp;<span>Transfer</span>
            </Button>
          </Grid>
        </>
      )}
      {interfaceType === INTERFACE_TYPE.SENDER && (
        <Grid item>
          <IconButton
            classes={{
              root: classes.moreActions
            }}
            ref={anchorEl}
            size="small"
            onClick={() => setMoreActionsOpen(true)}
          >
            <MoreVertIcon />
          </IconButton>
        </Grid>
      )}
      {(interfaceType === INTERFACE_TYPE.RECEIVER && canExport) && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadOrder}
            disabled={isExporting}
          >
            Export Order to Excel {isExporting && <CircularProgress size={20} />}
          </Button>
        </Grid>
      )}
    </Grid>
    <Popper open={moreActionsOpen} anchorEl={anchorEl.current} transition disablePortal>
      {({TransitionProps, placement}) => (
        <Grow
          {...TransitionProps}
          id="menu-list-grow"
          style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom"}}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => setMoreActionsOpen(false)}>
              <MenuList>
                {canExport && (
                  <MenuItem
                    onClick={downloadOrder}
                    disabled={isExporting}
                  >
                    Export Order to Excel {isExporting && <CircularProgress size={20} />}
                  </MenuItem>
                )}
                <MenuItem onClick={() => setConfirmationToToggleOpen(true)}>
                  {isDraft ? "Close Order" : "Open Order"}
                </MenuItem>
                {interfaceType === INTERFACE_TYPE.SENDER && (
                  <MenuItem
                    className={classes.deleteOrder}
                    onClick={() => setIsConfirmationToDeleteOpened(true)}
                  >
                    Delete Order
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
    <AddDataDrawer
      open={addDataOpen}
      close={() => setAddDataOpen(false)}
      ImportData={ImportData}
      importData={importData}
      title="Import Data"
    />
    <Confirmation
      open={isConfirmationToDeleteOpened}
      alertText="Are you sure you want to delete this order?"
      onCancel={() => setIsConfirmationToDeleteOpened(false)}
      onConfirm={async () => {
        await TransferStore.removeTransfer(transferId);
        history.push("/transfers");
      }}
    />
    <Confirmation
      open={isConfirmationToToggleOpen}
      alertText={isDraft ?
        "Are you sure you want to close this order?" :
        "Are you sure you want to open this order?"
      }
      onCancel={() => setConfirmationToToggleOpen(false)}
      onConfirm={async () => {
        if(isDraft) await TransferStore.approveDraftTransfer(transferId);
        else await TransferStore.reopenTransfer(transferId);

        document.location.reload();
      }}
    />
  </>;
});

export default withStyles(Actions, styles);