import {CORROSION_RATE_K_BY_UNIT, WEIGHT_UNIT} from "./data";
import {round} from "@core/helpers/round";
import {TEST_RESULTS} from "@core/constants/testResults";

export function getMassLoss(initialMass, finalMass) {
  const result = initialMass - finalMass;

  return round(result, 6);
}

export const getExaminedArea = (w, l) => (2 * w * l / 100).toFixed(2);

export const getPitsPerCmSquared = (pitsSideA, pitsSideB, examinedArea) => {
  return (((pitsSideA + pitsSideB) / examinedArea).toFixed(2)) || 0;
};

export const getCorrosionRate = (massLoss, corrosionRateUnit, examinedArea, testingTime, materialDensity) => {
  if(!corrosionRateUnit) return "";

  const k = CORROSION_RATE_K_BY_UNIT[corrosionRateUnit](materialDensity);

  return (k * massLoss / (examinedArea * testingTime * materialDensity)).toFixed(2);
};

export const getIsAcceptable = (values) => {
  const {corrosionRate, massLoss: weightLoss, maxPits, pitsPerCmSquared} = values;
  const {value: maxCorrosionRate} = values.maxCorrosionRate;
  const {value: maxWeightLoss, unit: maxWeightLossUnit} = values.maxWeightLoss;

  const corrosionRateAcceptable = !maxCorrosionRate || Number(corrosionRate) <= Number(maxCorrosionRate);
  const weightLossAcceptable = !maxWeightLoss || (maxWeightLossUnit && Number(weightLoss) <= Number(WEIGHT_UNIT[maxWeightLossUnit](maxWeightLoss)));
  const maxPitsAcceptable = !maxPits || Number(pitsPerCmSquared) <= Number(maxPits);

  return (corrosionRateAcceptable && maxPitsAcceptable && weightLossAcceptable) ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
};
