export default () => ({
  dragger: {
    width: "5px",
    cursor: "ew-resize",
    padding: 0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 2000,
    backgroundColor: "#f4f7f9"
  },
});