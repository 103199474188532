import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {QRCodeCanvas} from "qrcode.react";
import {observer} from "mobx-react";

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  dialogTitle: {
    textAlign: "center"
  }
});

class Signing extends React.Component {
  render() {
    const {store, classes} = this.props;
    const QRValue = JSON.stringify(store.qrData);
    const {fullScreen} = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          disableEscapeKeyDown={store.configure.blocked}
          open={store.open}
          onClose={() => store.closeSigner()}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
            {store.configure.title}
          </DialogTitle>
          <DialogContent>
            {
              store.state === "signed" ?
                <Grid container>
                  <Grid item justifyContent='center'>Signed</Grid>
                </Grid> :

                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <QRCodeCanvas
                      value={QRValue}
                      imageSettings={{
                        src: "/images/logo-symbol-only-black.png",
                        height: 50,
                        width: 50,
                      }}
                      size={250}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DialogContentText id="alert-dialog-description">
                      {store.configure.description || (
                        <Grid container spacing={3} direction={"column"}>
                          <Grid item xs={12}>
                            This QR code should be signed by {store.configure.createdFor}.
                          </Grid>
                          <Grid item xs={12}>
                            By scanning this QR code with the SteelTrace app you can sign this document. It will be
                            stored on the blockchain, cryptographically linked to you and irrefutable in time.
                          </Grid>
                        </Grid>)}
                    </DialogContentText>
                  </Grid>
                </Grid>
            }
          </DialogContent>
          <DialogActions>
            {!store.configure.blocked && <Button onClick={() => store.closeSigner()} color="primary">
              Cancel
            </Button>}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(observer(Signing), styles);
