import React, {useState} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ProductTypesAutocomplete from "@core/components/ProductTypeAutocomplete";
import TextField from "@core/components/FormikTextField";
import useStores from "../../../../useStores";
import styles from "./styles";
import {ROLES} from "@core/constants/externalUsersRoles";
import {MuiAutocomplete} from "@core/components/Form";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const initialValues = {
  uns: "",
  norm: "",
  grade: "",
  viewer: "",
  productType: ""
};

const validationSchema = yup.object().shape({
  viewer: yup.string().required("The field is required!"),
  norm: yup.string().required("The field is required!"),
  productType: yup.object().required("The field is required!"),
  grade: yup.string().when("uns", {
    is: (uns) => !uns,
    then: yup.string().required("The field is required!"),
    otherwise: yup.string()
  }),
  uns: yup.string().when("grade", {
    is: (grade) => !grade,
    then: yup.string().required("The field is required!"),
    otherwise: yup.string()
  })
}, [["uns", "grade"]]);

const PublishToPortal = ({open, onClose, testId, classes}) => {
  const {NotificationStore, TestStore} = useStores();

  const [unit, setUnit] = useState("grade");

  const onSubmit = async (values) => {
    await TestStore.publishTest(values, testId);
    NotificationStore.showSuccess("Successfully published to portal!");
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Publish to portal</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Grid container spacing={3}>
              <Grid xs={6} item>
                <MuiAutocomplete
                  url={`${ROUTES.EXTERNAL_USER[ACTIONS.ALL]}?label=${ROLES.CUSTOMER}`}
                  inputProps={{
                    label: "Customer",
                    name: "viewer",
                    required: true
                  }}
                  formatOptions={(data) => data.map((item) => ({
                    label: `${item.lastname} - ${item.company}`,
                    value: item._id}))}
                />
              </Grid>
              <Grid xs={6} item>
                <ProductTypesAutocomplete
                  inputProps={{
                    label: "Product type",
                    name: "productType",
                    required: true
                  }}
                  onChange={({name}) => {
                    props.setFieldValue("productType", name);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label='Norm'
                  name='norm'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label={unit === "grade" ? "Grade" : "TP UNS"}
                  name={unit}
                />
              </Grid>
              <Grid item xs={6} container alignItems="flex-end">
                <RadioGroup
                  className={classes.unitSelect}
                  value={unit}
                  onChange={({target}) => {
                    props.setFieldValue(target.value, props.values[unit]);
                    setUnit(target.value);
                  }}
                >
                  <FormControlLabel value="grade" control={<Radio color="primary" />} label="Grade" />
                  <FormControlLabel value="uns" control={<Radio color="primary" />} label="TP UNS" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <Button
                  disabled={!props.isValid}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={props.handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(PublishToPortal, styles);
