import React from "react";
import {observer} from "mobx-react-lite";
import {OPERATORS} from "@core/constants/specifications";
import ComparisonForm from "../ComparisonForm";

const Statements = observer(({index, item, children}) => {
  return (
    <>
      <ComparisonForm
        allowedOperators={[OPERATORS.CONTAINS, OPERATORS.EQUAL]}
        name={`config.${index}`}
        item={item}
      />
      {children}
    </>
  );
});

export default Statements;