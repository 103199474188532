import {Typography} from "@mui/material";
import React, {useState, useEffect, useRef, useCallback, useContext} from "react";
import EditPersonalInfo from "./components/EditPersonalInfo";
import userService from "@core/api/user-service";
import Loader from "@core/components/Loader";
import {withStyles} from "tss-react/mui";
import {equals} from "ramda";
import {observer} from "mobx-react-lite";
import useStores from "../useStores";
import styles from "./styles";
import {ConfirmationOnUrlChangeContext} from "@core/components/ConfirmationOnUrlChange/context";
import {SHOULD_BLOCK_DEFAULT} from "@core/constants/shouldBlockDefault";

const Account = (props) => {
  const [user, setUser] = useState(null);
  const {UserStore} = useStores();
  const {classes} = props;
  const {setShouldBlock} = useContext(ConfirmationOnUrlChangeContext);

  const formRef = useRef();

  useEffect(() => {
    userService.getUserInfo().then((user) => {
      setUser(user);
      UserStore.setUser(user);
    });
  }, []);
  
  const shouldBlock = useCallback(() => !equals(formRef.current?.values, formRef.current?.initialValues), []);
  
  useEffect(() => {
    setShouldBlock(() => shouldBlock);
    
    return () => {
      setShouldBlock(() => SHOULD_BLOCK_DEFAULT);
    };
  }, []);

  if (!user) {
    return "";
  }

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        Account
      </Typography>
      {user ? (
        <EditPersonalInfo
          user={user}
          formRef={formRef}
        />
      ) : <Loader />}
    </div>
  );
};

export default withStyles(observer(Account), styles);
