import React from "react";
import {isEmpty} from "ramda";
import {Box, IconButton, Link, Tooltip} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {withStyles} from "tss-react/mui";
import TestReportTitle from "@core/components/TestReportTitle";
import Tags from "@core/components/Tags";
import ConfidentialLabel from "@core/components/ConfidentialLabel";
import {GoGraph} from "react-icons/go";
import {NOT_PLOTTABLE_TEST_STATUSES, STATUSES} from "@core/constants/test";
import testsConfig from "@core/configs/test";
import styles from "./styles";

const Title = ({classes, getClickDisabled, collapseTests, openTests, isCollapsible, bunchOfSameTests, index, withPlots, test, groupedTests, onTestClick, setTestsToShowAnalytics, TitleNotes}) => {
  const {plots, plotsByDisplayName = {}} = testsConfig[test.type];

  const canHavePlots = plots || (test.displayName && plotsByDisplayName[test.displayName]);
  const showPlots = withPlots && canHavePlots && test._id && !NOT_PLOTTABLE_TEST_STATUSES.includes(test.status) && !isEmpty(test.properties);

  const open = openTests[test._id];
  const displayArrow = isCollapsible && bunchOfSameTests.length > 1 && !index;

  const disabled = getClickDisabled && getClickDisabled(test);

  const TestReportTitleComponent = (
    <TestReportTitle
      test={test}
      groupTests
      testVersions={bunchOfSameTests}
    />
  );

  return (
    <>
      {displayArrow && (
        <IconButton
          className={classes.arrow}
          size="small"
          onClick={() => collapseTests(bunchOfSameTests, openTests[test._id])}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )}
      {(onTestClick && !disabled) ? (
        <Link
          underline="hover"
          onClick={() => onTestClick(groupedTests, test._id)}
          className={classes.testLink}
        >
          {TestReportTitleComponent}
          {TitleNotes && (
            <>
            &nbsp;
              <TitleNotes
                groupedTests={groupedTests}
              />
            </>
          )}
        </Link>
      ) : TestReportTitleComponent}
      {!!test.properties?.tags?.length && (
        <Box sx={{marginLeft: 1, display: "inline"}}>
          <Tags
            tags={test.properties.tags}
          />
        </Box>
      )}
      {(test.confidential && test.status !== STATUSES.APPROVED) && (
        <ConfidentialLabel />
      )}
      {showPlots && (
        <Tooltip title="See analytics">
          <IconButton
            size="small"
            onClick={() => setTestsToShowAnalytics(bunchOfSameTests)}
            classes={{root: classes.graphIcon}}
          >
            <GoGraph size="18" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default withStyles(Title, styles);