export default () => ({
  chip: {
    height: 24,
  },
  chipLabel: {
    maxWidth: 300,
    overflow: "hidden",
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
});