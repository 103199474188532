import React, {useEffect} from "react";
import {FieldArray} from "formik";
import {map, prop} from "ramda";
import {Grid, MenuItem, Button, Dialog, DialogContent} from "@mui/material";
import {withStyles} from "tss-react/mui";
import SelectField from "@core/components/FormikSelect";
import ComparisonForm from "../ComparisonForm";
import styles from "./styles";

const EMPTY_ITEM = {
  field: "",
  operator: "",
  value: "",
  secondValue: ""
};

const ELEMENTS = ["Al", "As", "B", "Bi", "C", "Ca", "Co", "Cr", "Cu", "Fe", "H", "Mn", "Mo", "N", "Nb", "Ni", "O", "P", "Pb", "S", "Sb", "Si", "Sn", "Ta", "Ti", "V", "W", "Zr", "CE_Pcm", "CE_IIW", "CE_CSA"];

const ChemicalCompositionForm = ({classes, name, items, setFieldValue, isOpen, onClose, removeItems}) => {

  const usedElements = map(prop("field"), items.value);

  useEffect(() => {
    if (items.value) return;

    setFieldValue(`${name}.value`, [EMPTY_ITEM]);
  }, []);

  if (!items.value) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth='md'
    >
      <DialogContent>
        <FieldArray
          name={`${name}.value`}
        >
          {(itemHelpers) => (
            <Grid container spacing={3}>
              {items.value.map((item, itemIndex) => (
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <SelectField
                          name={`${name}.value.${itemIndex}.field`}
                          label='Element'
                          required
                        >
                          {ELEMENTS.map((element) => {
                            if(usedElements.includes(element) && item.field !== element) return;

                            return (
                              <MenuItem value={element}>{element}</MenuItem>
                            );
                          })}
                        </SelectField>
                      </Grid>
                      <ComparisonForm
                        xs={3}
                        name={`${name}.value.${itemIndex}`}
                        item={item}
                      />
                      <Grid item xs={1} container alignItems="flex-end">
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            if (items.value.length === 1) removeItems();
                            else itemHelpers.remove(itemIndex);
                          }}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Button
                    onClick={() => itemHelpers.push(EMPTY_ITEM)}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                  >
                    Add element
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </FieldArray>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(ChemicalCompositionForm, styles);
