import {keys, pick} from "ramda";
import React from "react";
import {Box} from "@mui/material";
import AcceptancesContent from "./components/AcceptancesContent";

const TensileTest = ({qcpConfig, qcp}) => {
  const globalCriteria = pick(["yieldStrengthRange", "tensileStrengthRange", "yieldTensileRatios"], qcpConfig);

  if(qcpConfig.poItem) {
    return keys(qcpConfig.poItem).map((poItem) => (
      <Box key={poItem} sx={{marginTop: 1}}>
        <h3>QCP Item {poItem}</h3>
        <AcceptancesContent
          config={qcpConfig.poItem[poItem]}
          globalConfig={globalCriteria}
          qcp={qcp}
        />
      </Box>
    ));
  }

  return (
    <AcceptancesContent
      config={qcpConfig}
      globalConfig={globalCriteria}
      qcp={qcp}
    />
  );
};

export default TensileTest;