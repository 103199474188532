export default (theme) => ({
  logoImage: {
    height: "64px",
    marginBottom: theme.spacing(8)
  },
  message: {
    padding: `0 ${theme.spacing(2)}`,
    marginBottom: theme.spacing(4)
  },
  container: {
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  }
});
