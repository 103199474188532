import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import List from "../../../Companies/Overview";
import StatisticsSection from "@core/components/StatisticsSection";
import styles from "../../styles";
import useStores from "../../../useStores";

const DashboardSuperAdmin = observer(({classes}) => {
  const {StatisticStore} = useStores();

  useEffect(() => {
    StatisticStore.getAdminStatistics();
  }, []);

  const {data, isLoaded} = StatisticStore.statistic;

  return (
    <div className={classes.dashboard}>
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        SteelTrace Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StatisticsSection
            isLoading={!isLoaded}
            data={[{
              number: data.companies || 0,
              description: "Companies registered",
              icon: ICONISTS_ICONS.buildings
            }, {
              number: data.user || 0,
              description: "Users registered",
              icon: ICONISTS_ICONS.group
            }, {
              number: data.users_by_company || 0,
              description: "Users/Company",
              icon: ICONISTS_ICONS.group
            }, {
              number: data.certificates || 0,
              description: "Certificates",
              icon: ICONISTS_ICONS.sketchbook
            }, {
              number: data.transactions || 0,
              description: "Transactions",
              icon: ICONISTS_ICONS.signature
            }, {
              number: data.transfers || 0,
              description: "Changes of ownership",
              icon: ICONISTS_ICONS.paperPlane
            },{
              number: data.transactions_by_company || 0,
              description: "Transactions/Company",
              icon: ICONISTS_ICONS.signature
            }, {
              number: data.transactions_by_user || 0,
              description: "Transactions/User",
              icon: ICONISTS_ICONS.signature
            }]}
          />
        </Grid>
        <Grid item xs={12}>
          <List dashboard/>
        </Grid>
      </Grid>
    </div>
  );
});

export default withStyles(DashboardSuperAdmin, styles);