import React, {useEffect, useState} from "react";
import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react";
import axios from "axios";
import {any, indexBy, prop} from "ramda";
import testsConfig from "@core/configs/test";
import WitnessAttest from "@core/components/WitnessAttest";
import TestSummaryTable from "@core/components/TestSummaryTable";
import TestMarker from "@core/components/TestMarker";
import TestResultTag from "@core/components/TestResultTag";
import Status from "@core/components/Status";
import modules from "@core/constants/modules";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import styles from "./styles";
import useStores from "../../../../../useStores";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const MultipleSigningSubmit = observer((props) => {
  const {classes, handleBack, handleSubmit, certificateByTestId, setCertificateByTestId} = props;

  const {UserStore, TestStore} = useStores();
  const {selected: defaultSelected} = TestStore.tests;

  const [filteredTestIds, setFilteredTestIds] = useState([]);
  const [selected, setSelected] = useState(defaultSelected);

  const [module] = UserStore.user.data.company.modules;
  const companyId = UserStore.user.data.company._id;

  const testsToSign = module.name === modules.WITNESS ?
    selected.filter((test) => any((witness) => witness.status !== WITNESS_STATUSES.UNSET, test.witnesses)) :
    selected;

  const testsById = indexBy(prop("_id"), testsToSign);

  const filteredTests = filteredTestIds.length ? filteredTestIds.map((id) => testsById[id]) : testsToSign;

  const fetchCertificates = async () => {
    const certificates = await Promise.all(selected.map((test) => {
      return axios.get(ROUTES.CERTIFICATE[ACTIONS.SINGLE_BY_TEST_ID](test._id));
    }));

    const certificateByTestId = selected.reduce((acc, test, index) => {
      acc[test._id] = certificates[index].data;

      return acc;
    }, {});

    setCertificateByTestId(certificateByTestId);
  };

  const witnessAll = () => {
    const witnessed = selected.map((test) => ({
      ...test,
      witnesses: test.witnesses.map((witness) => witness.company._id === companyId ? {...witness, status: WITNESS_STATUSES.ACCEPTED} : witness),
    }));

    setSelected(witnessed);
  };

  useEffect(() => {
    fetchCertificates();
  }, []);

  return (
    <>
      {selected.map((test) => {
        const testConfig = testsConfig[test.type];

        const certificate = certificateByTestId[test._id] || {};

        const onWitness = (data) => {
          const witnessedTest = {
            ...test,
            witnesses: test.witnesses.map((witness) => witness.company._id === companyId ? {...witness, ...data} : witness)
          };
          setSelected(selected.map((t) => t._id === test._id ? witnessedTest : t));
        };

        return (
          <div
            key={test._id}
            className={classes.testView}
            style={{borderLeft: `10px solid ${testConfig.color}`}}
          >
            <TestMarker
              test={test}
              disabled
            />
            <div className={classes.testResults}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Status
                    test={test}
                  />
                </Grid>
              </Grid>
              {React.cloneElement(testConfig.certificateView, {test})}
              <Grid container alignItems="center" justifyContent="space-between" className={classes.result}>
                <Grid item container xs={6}>
                  <TestResultTag
                    result={test.properties.result}
                  />
                </Grid>
                {module.name === modules.WITNESS && (
                  <Grid item container xs={6} justifyContent="flex-end">
                    <WitnessAttest
                      onSave={onWitness}
                      test={test}
                      certificate={certificate}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        );
      }
      )}

      <Grid container spacing={2} className={classes.reviewSummary}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography component="h6" variant="h6">
              Review Summary
            </Typography>
          </Grid>
          {module.name === modules.WITNESS && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={witnessAll}
              >
                Accept All
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <TestSummaryTable
            tests={filteredTests}
            setFilteredTestIds={setFilteredTestIds}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button
            variant={"contained"}
            size={"medium"}
            color={"secondary"}
            onClick={handleBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={"contained"}
            size={"medium"}
            color={"primary"}
            onClick={() => handleSubmit(selected)}
          >
            Sign All
          </Button>
        </Grid>
      </Grid>
    </>
  );
});

export default withStyles(MultipleSigningSubmit, styles);
