export default (theme) => ({
  verticalDivider: {
    width: 1,
    height: 20,
  },
  filterDivider: {
    [theme.breakpoints.down(1601)]: {
      display: "none",
    },
  },
  status: {
    fontWeight: theme.typography.fontWeightMedium,
    padding: "4px 10px",
    borderRadius: 4,
    display: "inline",
    whiteSpace: "nowrap",
    fontSize: "0.875rem",
    marginLeft: theme.spacing(),
    textTransform: "uppercase"
  },
  statusOpen: {
    backgroundColor: theme.palette.success.backgroundColor,
    color: theme.palette.success.text,
  },
  statusClosed: {
    backgroundColor: theme.palette.deactivate.backgroundColor,
    color: theme.palette.deactivate.text,
  },
  date: {
    display: "flex",
    alignItems: "center",
    maxHeight: 30,
  },
});
