import Image from "@core/components/Image";
import {Avatar as AvatarM} from "@mui/material";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import styles from "./styles";

const maximumAspectRatio = 1.3;

function Avatar({user, className, colorClassName, isRect, classes, big, path}) {
  const [imageClass, setImageClass] = useState("");
  const shouldResetImageClass = !(user.avatar && user.avatar.file) && !path;

  useEffect(() => {
    setImageClass("");
  }, [shouldResetImageClass]);

  const setImageFitBasedOnRatio = (imageEl) => {
    const width = imageEl.naturalWidth;
    const height = imageEl.naturalHeight;

    const isLandscape = width > height;
    const aspectDifference = isLandscape ? width / height : height / width;
    setImageClass(aspectDifference > maximumAspectRatio ? "img-contain" : "");
  };

  const onImgLoad = ({target:img}) => {
    setImageFitBasedOnRatio(img);
  };

  const getImage = (user, path) => {
    let source;

    if (user) {
      source = user.avatar.file.dir ? user.avatar.file.dir + user.avatar.file.name : user.avatar.file;
    } else {
      source = path;
    }

    return (
      <Image
        src={source}
        onLoad={onImgLoad}
        className={imageClass}
        alt="Unable to display the image"
      />
    );
  };

  if (!user) return (
    <AvatarM className={classNames([classes.avatar, className, classes.unsigned])}/>
  );
  
  return (
    user.avatar && user.avatar.file ?
      <div className={classNames([
        "avatar",
        classes.avatar,
        className,
        isRect ? classes.rectImage : null,
        big ? classes.bigAvatar : "",
        !imageClass ? colorClassName : classes.defaultBackground,
      ])}>
        {getImage(user, null)}
      </div>        :
      <AvatarM className={classNames([
        classes.avatar,
        className,
        !imageClass ? colorClassName : classes.defaultBackground,
        classes.blueAvatar,
        big ? classes.bigAvatar : "",
      ])}>
        {path ?
          <div className={classNames(["avatar", classes.avatar, isRect ? classes.rectImage : null, big ? classes.bigAvatar : ""])}>
            {getImage(null, path)}
          </div> :
          user.firstname && user.lastname ? user.firstname.charAt(0) + user.lastname.charAt(0) : null}
      </AvatarM>
  );
}

export default withStyles(Avatar, styles);
