import React, {useRef, useMemo, memo} from "react";
import {Layer} from "react-konva";
import {STAGE_CONFIG, VIEWS} from "../../constants";
import Stalk from "../Stalk";
import {PIPE_CONFIG, WELD_CONFIG} from "./constants";
import {getNumberOfStalkItemsInRow, getNumberOfStalkItemsToFitIOneRow} from "../../helpers";

const CoatingView = ({settings, stageWidth, stalks, layer, ...props}) => {
  const stalksRef = useRef({});

  const numberOfStalkItemsToFitIOneRow = useMemo(() => {
    return getNumberOfStalkItemsToFitIOneRow(VIEWS.COATING, settings, stageWidth, PIPE_CONFIG, WELD_CONFIG);
  }, [stageWidth, settings.welds]);

  const numberOfStalkItemsInRow = useMemo(() => {
    return getNumberOfStalkItemsInRow(stalks, settings, numberOfStalkItemsToFitIOneRow);
  }, [numberOfStalkItemsToFitIOneRow, settings.splitStalk]);

  return (
    <Layer
      ref={layer}
      style={{background: STAGE_CONFIG.BACKGROUND_COLOR}}
    >
      {stalks.map((stalk, stalkIndex) => (
        <Stalk
          key={stalkIndex}
          stalk={stalk}
          stalkIndex={stalkIndex}
          settings={settings}
          stalks={stalks}
          stalksRef={stalksRef}
          numberOfStalkItemsInRow={numberOfStalkItemsInRow}
          pipeConfig={PIPE_CONFIG}
          weldConfig={WELD_CONFIG}
          {...props}
        />
      ))}
    </Layer>
  );
};

export default memo(CoatingView);