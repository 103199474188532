import React from "react";
import {useFormikContext} from "formik";
import {TableCell, TableRow} from "@mui/material";
import RemoveButtonCell from "@core/components/DynamicTestResultsTable/RemoveButtonCell";
import Field from "../../../../CollapsibleTable/components/Field";
import {getVolumeLoss} from "../../services";

const TestResult = ({index, item, remove, editable}) => {

  const {values, setFieldValue} = useFormikContext();

  return (
    <TableRow>
      <TableCell>
        <Field
          required
          name={`testResults.${index}.specimen`}
          errorMessage={null}
          editable={editable}
        />
      </TableCell>
      {values.hardnessMeasurementUnit && (
        <TableCell>
          <Field
            type="number"
            name={`testResults.${index}.hardness`}
            required
            errorMessage={null}
            endAdornment={values.hardnessMeasurementUnit}
            inputProps={{min: 0}}
            editable={editable}
          />
        </TableCell>
      )}
      <TableCell>
        <Field
          type="number"
          required
          name={`testResults.${index}.density`}
          onChange={(value) => setFieldValue(`testResults.${index}.volumeLoss`, getVolumeLoss(value, item.massLoss))}
          errorMessage={null}
          endAdornment="g/cm^3"
          inputProps={{min: 0}}
          editable={editable}
        />
      </TableCell>
      <TableCell>
        <Field
          type="number"
          required
          name={`testResults.${index}.massLoss`}
          onChange={(value) => setFieldValue(`testResults.${index}.volumeLoss`, getVolumeLoss(item.density, value))}
          errorMessage={null}
          endAdornment="g"
          inputProps={{min: 0}}
          editable={editable}
        />
      </TableCell>
      <TableCell>
        <Field
          type="number"
          required
          disabled
          name={`testResults.${index}.volumeLoss`}
          errorMessage={null}
          endAdornment="mm^3"
          inputProps={{min: 0}}
          editable={editable}
        />
      </TableCell>
      <TableCell>
        <Field
          type="number"
          required
          name={`testResults.${index}.erosionValue`}
          errorMessage={null}
          endAdornment="mm^3/kg"
          inputProps={{min: 0}}
          editable={editable}
        />
      </TableCell>
      {editable && (
        <RemoveButtonCell
          onClick={() => remove(index)}
        />
      )}
    </TableRow>
  );
};

export default TestResult;