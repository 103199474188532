import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const AcceptanceTable = ({config}) => {
  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>Testing fluid</TableCell>
          <TableCell>Pressure</TableCell>
          <TableCell>Minimum holding time</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{config.testingFluid || "-"}</TableCell>
          <TableCell>{config.pressure} {config.pressureUnit}</TableCell>
          <TableCell>{config.minTime} {config.minHoldingTimeUnits}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AcceptanceTable;