import React, {useState, useEffect} from "react";
import RouterLink from "@core/components/RouterLink";
import {observer} from "mobx-react-lite";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import BasicCompUpgrade from "@core/components/Modal/BasicCompUpgrade";
import {
  TableCell,
  Paper,
  Table,
  Grid,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Button,
  Typography
} from "@mui/material";  // eslint-disable-line
import TableSkeleton from "@core/components/TableSkeleton";
import {MdDelete, MdEdit} from "react-icons/md";
import moment from "moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import useStores from "../../useStores";

const SpecificationsList = () => {
  const [specCheckerToDelete, setSpecCheckerToDelete] = useState(null);
  const [isBasicUpgradeOpen, setBasicUpgradeOpen] = useState(false);

  const {SpecificationStore, UserStore} = useStores();

  useEffect(() => {
    SpecificationStore.getAll();
  }, []);

  const {specifications} = SpecificationStore;
  const isBasic = UserStore.isBasic();

  return (
    <div className="content padded-container">
      <Grid container spacing={3} marginBottom={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Specifications
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant={"contained"}
            component={isBasic ? "span" : RouterLink}
            color="primary"
            to="/specifications/create"
            onClick={() => {
              if (!isBasic) return;

              setBasicUpgradeOpen(true);
            }}
          >
            Add specification
          </Button>
        </Grid>
      </Grid>
      <BasicCompUpgrade
        open={isBasicUpgradeOpen}
        onCancel={() => setBasicUpgradeOpen(false)}
      />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Paper>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {specifications.isLoaded && specifications.data.length !== 0 && specifications.data.map((spec) =>
                  <TableRow key={spec.name}>
                    <TableCell>{spec.name}</TableCell>
                    <TableCell>{moment(spec.date_created).format(DATE_FORMAT)}</TableCell>
                    <TableCell>
                      <IconButton
                        component={RouterLink}
                        to={`/specifications/${spec._id}`}
                      >
                        <MdEdit size={20} color={"#2196f3"} />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => setSpecCheckerToDelete(spec._id)} size="large">
                        <MdDelete size={20} color={"#d02d52"} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
                {specifications.isLoaded && specifications.data.length === 0 && (
                  <TableRow>
                    <TableCell>
                      There are no specifications yet.
                    </TableCell>
                  </TableRow>
                )}
                {!specifications.isLoaded && (
                  <TableSkeleton
                    columns={4}
                  />
                )}
              </TableBody>
            </Table>
          </Paper>
          <Confirmation
            open={specCheckerToDelete}
            onCancel={() => setSpecCheckerToDelete(null)}
            onConfirm={async () => {
              await SpecificationStore.delete([specCheckerToDelete]);
              setSpecCheckerToDelete(null);
            }}
            alertText="Are you sure you want to delete it?"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(SpecificationsList);