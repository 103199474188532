export default (theme) => ({
  block: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(),
    "& div [role=combobox]": {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    }
  },
  title: {
    marginBottom: theme.spacing(),
  },
});
