import React, {useRef, useState} from "react";
import {Button, Dialog, DialogContent, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react";
import TestMarker from "@core/components/TestMarker";
import styles from "./styles";
import {omit} from "ramda";
import ConfirmationOnModalClose from "@core/components/ConfirmationOnModalClose";
import testsConfig from "@core/configs/test";
import {STATUSES} from "@core/constants/test";
import useStores from "../../../../../useStores";

const EmptyProducerTest = observer(({
  classes,
  test,
  dataDialogOpen,
  dataDialogClose,
  updateTest,
  deleteTest,
  disabled,
  addDataOpen,
  certificate,
  shareLink
}) => {
  const formRef = useRef();
  const {UserStore} = useStores();

  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);

  const testConfig = testsConfig[test.type];

  const user = UserStore.user.data;

  return (
    <>
      <TestMarker
        test={test}
        deleteTest={deleteTest}
        updateTest={updateTest}
        disabled={disabled}
        shareLink={shareLink}
      />
      <div className={classes.emptyForm}>
        <h2>Awaiting data</h2>
        {(!disabled && testConfig.addDataForm) && (
          <Grid item container justifyContent="flex-end">
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={dataDialogOpen}
            >
              Edit
            </Button>
          </Grid>
        )}
      </div>
      {testConfig.addDataForm && (
        <Dialog
          open={addDataOpen}
          onClose={dataDialogClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            {React.cloneElement(testConfig.addDataForm, {
              formRef,
              test,
              saveTest: (data, {witnesses}) => updateTest(data, {witnesses, status: STATUSES.FILLED}),
              isProducerTest: true,
              user,
              certificate,
            })}
          </DialogContent>
        </Dialog>
      )}
      <ConfirmationOnModalClose
        confirmationOpen={closeConfirmationOpen}
        formRef={formRef}
        setConfirmationOpen={setCloseConfirmationOpen}
        onClose={dataDialogClose}
        onSave={() => {
          const values = formRef.current.values;
          updateTest(omit(["witnesses"], values), {witnesses: values.witnesses});
          setCloseConfirmationOpen(false);
        }}
      />
    </>
  );
});

export default withStyles(EmptyProducerTest, styles);
