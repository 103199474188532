const certificatesABI = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32[]",
        "name": "_testIds",
        "type": "bytes32[]"
      }
    ],
    "name": "addCertificate",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32[]",
        "name": "_inspectingCompanies",
        "type": "bytes32[]"
      }
    ],
    "name": "addInspectingCompanies",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      }
    ],
    "name": "addTestToCertificate",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      }
    ],
    "name": "approveTest",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_assignedLab",
        "type": "bytes32"
      }
    ],
    "name": "assignTest",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      },
      {
        "internalType": "uint8",
        "name": "_status",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      }
    ],
    "name": "attest",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "certificateIds",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "name": "certificates",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "id",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "certificateOwner",
        "type": "bytes32"
      },
      {
        "internalType": "uint256",
        "name": "indexInArray",
        "type": "uint256"
      },
      {
        "internalType": "bytes32",
        "name": "dataHash",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "companiesContract",
    "outputs": [
      {
        "internalType": "contract Companies",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_labId",
        "type": "bytes32"
      }
    ],
    "name": "createTest",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_inspectingComapnyId",
        "type": "bytes32"
      }
    ],
    "name": "getAttestationByInspectingCompanyId",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "inspectingCompanyId",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "attestedBy",
        "type": "address"
      },
      {
        "internalType": "uint8",
        "name": "status",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "dataHash",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      }
    ],
    "name": "getCertificate",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "_id",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_certificateOwner",
        "type": "bytes32"
      },
      {
        "internalType": "uint256",
        "name": "_indexInArray",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_index",
        "type": "uint256"
      }
    ],
    "name": "getCertificateByIndex",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "_id",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_certificateOwner",
        "type": "bytes32"
      },
      {
        "internalType": "uint256",
        "name": "_indexInArray",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      },
      {
        "internalType": "uint256",
        "name": "_index",
        "type": "uint256"
      }
    ],
    "name": "getCertificateForTestByIndex",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "_id",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_certificateOwner",
        "type": "bytes32"
      },
      {
        "internalType": "uint256",
        "name": "_indexInArray",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      }
    ],
    "name": "getInspectingCompanies",
    "outputs": [
      {
        "internalType": "bytes32[]",
        "name": "",
        "type": "bytes32[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getNumberOfCertificates",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      }
    ],
    "name": "getNumberOfCertificatesForTest",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getNumberOfTests",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      }
    ],
    "name": "getNumberOfTestsForCertificate",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      }
    ],
    "name": "getTest",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "_id",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_assignedLab",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "_assignedBy",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_submittedBy",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_approvedBy",
        "type": "address"
      },
      {
        "internalType": "uint8",
        "name": "_status",
        "type": "uint8"
      },
      {
        "internalType": "uint256",
        "name": "_indexInArray",
        "type": "uint256"
      },
      {
        "internalType": "bytes32[]",
        "name": "_inspectingCompanies",
        "type": "bytes32[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_index",
        "type": "uint256"
      }
    ],
    "name": "getTestByIndex",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "_id",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_assignedLab",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "_assignedBy",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_submittedBy",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_approvedBy",
        "type": "address"
      },
      {
        "internalType": "uint8",
        "name": "_status",
        "type": "uint8"
      },
      {
        "internalType": "uint256",
        "name": "_indexInArray",
        "type": "uint256"
      },
      {
        "internalType": "bytes32[]",
        "name": "_inspectingCompanies",
        "type": "bytes32[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      },
      {
        "internalType": "uint256",
        "name": "_index",
        "type": "uint256"
      }
    ],
    "name": "getTestForCertificateByIndex",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "_id",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_assignedLab",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "_assignedBy",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_submittedBy",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_approvedBy",
        "type": "address"
      },
      {
        "internalType": "uint8",
        "name": "_status",
        "type": "uint8"
      },
      {
        "internalType": "uint256",
        "name": "_indexInArray",
        "type": "uint256"
      },
      {
        "internalType": "bytes32[]",
        "name": "_inspectingCompanies",
        "type": "bytes32[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      },
      {
        "internalType": "uint256",
        "name": "_index",
        "type": "uint256"
      }
    ],
    "name": "getTestIDForCertificateByIndex",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "_id",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "companiesAddress",
        "type": "address"
      }
    ],
    "name": "linkToCompanies",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_testId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      }
    ],
    "name": "submitTest",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "testIds",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "name": "tests",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "id",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "dataHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "assignedLab",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "assignedCompany",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "assignedBy",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "submittedBy",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "approvedBy",
        "type": "address"
      },
      {
        "internalType": "uint8",
        "name": "status",
        "type": "uint8"
      },
      {
        "internalType": "uint256",
        "name": "indexInArray",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_newOwnerCompany",
        "type": "bytes32"
      }
    ],
    "name": "transferCertificate",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_certId",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "_dataHash",
        "type": "bytes32"
      }
    ],
    "name": "updateCertificate",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
export default certificatesABI