import React from "react";
import {Grid, Box} from "@mui/material";
import MenuItem from "../MenuItem";

const MenuGroup = ({title, items, displayTitle}) => {
  return (
    <Grid container spacing={2} direction="column">
      {!!(title && displayTitle) && (
        <Grid item>
          <Box
            sx={{
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {title}
          </Box>
        </Grid>
      )}
      {items.map((menuItem) => (
        <Grid item>
          <MenuItem
            displayTitle={displayTitle}
            menuItem={menuItem}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default MenuGroup;