import React, {useState} from "react";
import {
  Button,
  Grid,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogTitle,
  Dialog,
  DialogContent,
  Collapse,
  Divider,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {values, prop, isEmpty, groupBy, flatten} from "ramda";
import * as yup from "yup";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import {FieldArray, Formik, useFormikContext} from "formik";
import styles from "./styles";
import {getActualValue, getMinValue} from "../services";
import {dimensionY, orientations, locations, temperatureUnits} from "../data";

const ExceptionCell = ({value, sampleY}) => {
  if (!value) return null;

  return getActualValue(value, sampleY);
};

const Requirement = ({classes, width, notch, index, values, defaultRequirements = {}, locations, remove, canBeRemoved}) => {
  const [open, setOpen] = useState(false);

  const isDefault = !isEmpty(defaultRequirements) && !index;

  const {errors} = useFormikContext();

  const requirementErrors = errors.requirements ? errors.requirements[index] : {};

  return (
    <>
      <Grid item xs={4}>
        <Grid spacing={1} container alignItems="flex-end">
          <Grid item xs={8}>
            <TextField
              label='Temperature'
              name={`requirements.${index}.temperature`}
              required
              type="number"
              error={requirementErrors && requirementErrors.temperature}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectField
              disabled={isDefault}
              required
              name={`requirements.${index}.unit`}
            >
              <MenuItem key={temperatureUnits.CELSIUS} value={temperatureUnits.CELSIUS}>°C</MenuItem>
              <MenuItem key={temperatureUnits.FAHRENHEIT} value={temperatureUnits.FAHRENHEIT}>°F</MenuItem>
            </SelectField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <SelectField
          name={`requirements.${index}.orientation`}
          disabled={isDefault}
          label='Orientation'
        >
          {orientations.map((orientation) =>
            <MenuItem key={orientation} value={orientation}>{orientation}</MenuItem>
          )}
          <MenuItem key="" value="" />
        </SelectField>
      </Grid>
      <Grid item xs={2}>
        <SelectField
          name={`requirements.${index}.location`}
          label='Location'
        >
          {locations.map((location) =>
            <MenuItem key={location} value={location}>{location}</MenuItem>
          )}
          <MenuItem key="" value="" />
        </SelectField>
      </Grid>
      <Grid item xs={4}>
        {Boolean(canBeRemoved && index) && (
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => remove(index)}
          >
            Remove
          </Button>
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField
          label='Single'
          name={`requirements.${index}.single`}
          required={isDefault && defaultRequirements.single}
          type="number"
          error={requirementErrors && requirementErrors.single}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label='Average'
          name={`requirements.${index}.average`}
          required={isDefault && defaultRequirements.average}
          type="number"
          error={requirementErrors && requirementErrors.average}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required={isDefault && defaultRequirements.singleLateralExpansion}
          label='LE single'
          name={`requirements.${index}.singleLateralExpansion`}
          type="number"
          error={requirementErrors && requirementErrors.singleLateralExpansion}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required={isDefault && defaultRequirements.averageLateralExpansion}
          label='LE average'
          name={`requirements.${index}.averageLateralExpansion`}
          type="number"
          error={requirementErrors && requirementErrors.averageLateralExpansion}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required={isDefault && defaultRequirements.singleShearArea}
          inputProps={{min: 0}}
          endAdornment='%'
          name={`requirements.${index}.singleShearArea`}
          label='Shear area single'
          type="number"
          error={requirementErrors && requirementErrors.singleShearArea}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required={isDefault && defaultRequirements.averageShearArea}
          inputProps={{min: 0}}
          endAdornment='%'
          name={`requirements.${index}.averageShearArea`}
          label='Shear area average'
          type="number"
          error={requirementErrors && requirementErrors.averageShearArea}
        />
      </Grid>
      {(values.orientation || values.location) && (
        <Grid item xs={12} onClick={() => setOpen(!open)}>
          <h3 className={classes.expandButton}>
            Expand table{open ? <ExpandLess /> : <ExpandMore />}
          </h3>
        </Grid>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapse}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none" align="center">Sample X(mm)</TableCell>
              <TableCell padding="none" align="center">Sample Y(mm)</TableCell>
              <TableCell padding="none" align="center">Notch</TableCell>
              <TableCell padding="none" align="center">mm<sup>2</sup></TableCell>
              <TableCell padding="none" align="center">Energy single</TableCell>
              <TableCell padding="none" align="center">Energy average</TableCell>
              {!!values.singleLateralExpansion && (
                <TableCell padding="none" align="center">LE single</TableCell>
              )}
              {!!values.averageLateralExpansion && (
                <TableCell padding="none" align="center">LE average</TableCell>
              )}
              {!!values.singleShearArea && (
                <TableCell padding="none" align="center">Shear area single</TableCell>
              )}
              {!!values.averageShearArea && (
                <TableCell padding="none" align="center">Shear area average</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {dimensionY.map((sampleY) => (
              <TableRow key={sampleY}>
                <TableCell padding="none" align="center">{width}</TableCell>
                <TableCell padding="none" align="center">{sampleY}</TableCell>
                <TableCell padding="none" align="center">{notch}</TableCell>
                <TableCell padding="none" align="center">
                  {Number(((width - notch) * sampleY).toFixed(1))}
                </TableCell>
                <TableCell padding="none" align="center">
                  <ExceptionCell
                    value={values.single}
                    sampleY={sampleY}
                  />
                </TableCell>
                <TableCell padding="none" align="center">
                  <ExceptionCell
                    value={values.average}
                    sampleY={sampleY}
                  />
                </TableCell>
                {!!values.singleLateralExpansion && (
                  <TableCell padding="none" align="center">
                    {values.singleLateralExpansion}
                  </TableCell>
                )}
                {!!values.averageLateralExpansion && (
                  <TableCell padding="none" align="center">
                    {values.averageLateralExpansion}
                  </TableCell>
                )}
                {!!values.singleShearArea && (
                  <TableCell padding="none" align="center">
                    {values.singleShearArea}
                  </TableCell>
                )}
                {!!values.averageShearArea && (
                  <TableCell padding="none" align="center">
                    {values.averageShearArea}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </>
  );
};

const NewException = ({
  classes,
  width,
  height,
  notch,
  setFieldValue,
  defaultRequirements,
  requirements,
  temperature,
  acceptance
}) => {
  const [open, setOpen] = useState(false);

  const requirement = {
    orientation: "",
    location: "",
    temperature: "",
    unit: "",
    single: "",
    average: "",
    singleLateralExpansion: "",
    averageLateralExpansion: "",
    singleShearArea: "",
    averageShearArea: ""
  };

  const currentRequirements = flatten(values(requirements)).map((requirement) => ({
    ...requirement,
    unit: requirement.unit || "celsius",
    temperature: requirement.temperature || temperature,
    single: (height && requirement.temperature && !acceptance) ? getMinValue(requirement.single, height) : requirement.single,
    average: (height && requirement.temperature &&! acceptance) ? getMinValue(requirement.average, height) : requirement.average
  }));

  const initialValues = {
    requirements: currentRequirements.length ? currentRequirements : [requirement]
  };

  const getValidationSchemaByOrientation = (orientation) => {
    return yup.object().shape({
      orientation: yup.string().when("location", {
        is: (location) => !location,
        then: yup.string().required("Field is required")
      }),
      location: yup.string().when("orientation", {
        is: (orientation) => !orientation,
        then: yup.string().required("Field is required")
      }),
      temperature: (defaultRequirements[orientation] && !isEmpty(defaultRequirements[orientation]) && temperature) ?
        yup.number().max(temperature, `Should be \u2264 ${temperature}`).required("Field is required") :
        yup.number(),
      unit: yup.string().required("Field is required"),
      single: (defaultRequirements[orientation] && defaultRequirements[orientation].single) ?
        yup.number().min(defaultRequirements[orientation].single, `Should be \u2265 ${defaultRequirements[orientation].single}`).required("Field is required") :
        yup.mixed(),
      average: (defaultRequirements[orientation] && defaultRequirements[orientation].average) ?
        yup.number().min(defaultRequirements[orientation].average, `Should be \u2265 ${defaultRequirements[orientation].average}`).required("Field is required") :
        yup.mixed(),
      singleLateralExpansion: (defaultRequirements[orientation] && defaultRequirements[orientation].singleLateralExpansion) ?
        yup.number().min(defaultRequirements[orientation].singleLateralExpansion, `Should be \u2265 ${defaultRequirements[orientation].singleLateralExpansion}`).required("Field is required") :
        yup.mixed(),
      averageLateralExpansion: (defaultRequirements[orientation] && defaultRequirements[orientation].averageLateralExpansion) ?
        yup.number().min(defaultRequirements[orientation].averageLateralExpansion, `Should be \u2265 ${defaultRequirements[orientation].averageLateralExpansion}`).required("Field is required") :
        yup.mixed(),
      singleShearArea: (defaultRequirements[orientation] && defaultRequirements[orientation].singleShearArea) ?
        yup.number().min(defaultRequirements[orientation].singleShearArea, `Should be \u2265 ${defaultRequirements[orientation].singleShearArea}`).required("Field is required") :
        yup.mixed(),
      averageShearArea: (defaultRequirements[orientation] && defaultRequirements[orientation].averageShearArea) ?
        yup.number().min(defaultRequirements[orientation].averageShearArea, `Should be \u2265 ${defaultRequirements[orientation].averageShearArea}`).required("Field is required") :
        yup.mixed()
    }, [["orientation", "location"]]);
  };

  const validationSchema = yup.object().shape({
    requirements: yup.array().of(yup.lazy((requirement) => getValidationSchemaByOrientation(requirement.orientation).required()))
  });

  return (
    <>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Set exception
      </Button>
      <Dialog
        classes={{
          paperWidthMd: classes.paperWidthMd
        }}
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          Set custom requirements
        </DialogTitle>
        <DialogContent>
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const withAppliedHeight = values.requirements.map((requirement) => ({
                ...requirement,
                average: height ? getActualValue(requirement.average, height) : requirement.average,
                single: height ? getActualValue(requirement.single, height) : requirement.single
              }));
              const configByOrientation = groupBy(prop("orientation"), withAppliedHeight);
              setFieldValue("requirements", configByOrientation);

              setOpen(false);
            }}
            children={({handleSubmit, isValid, values}) => {
              return (
                <>
                  <Grid container spacing={3} alignItems="flex-end">
                    <FieldArray name="requirements">
                      {({push, remove}) => values.requirements.map((config, index) => {

                        const requirementsWithSameOrientation = values.requirements.filter((requirement) => requirement.orientation === config.orientation);
                        const notUsedLocations = locations.filter((location) => {
                          return !requirementsWithSameOrientation.find((config) => config.location === location) || location === config.location;
                        });
                        
                        return (
                          <>
                            <Requirement
                              classes={classes}
                              width={width}
                              notch={notch}
                              index={index}
                              values={config}
                              defaultRequirements={defaultRequirements[config.orientation]}
                              locations={notUsedLocations}
                              remove={remove}
                              canBeRemoved={values.requirements.length > 1}
                            />
                            {index !== values.requirements.length - 1 && (
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            )}
                            {index === values.requirements.length - 1 && (
                              <Grid item container>
                                <Grid item>
                                  <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => push(requirement)}
                                  >
                                    Add
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        );
                      })}
                    </FieldArray>
                    <Grid item container justifyContent="flex-end">
                      <Grid item>
                        <Button
                          disabled={!isValid}
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(NewException, styles);