export default (theme) => ({
  root: {
    width: "100%",
  },
  bgColor : {
    backgroundColor: "#f5f5f5"
  },
  button: {
    marginLeft: 40,
    marginBottom: 40,
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  p40: {
    padding: 40
  },

  mb25: {
    marginBottom: 25
  },
  finalData: {
    paddingLeft: 40,
    marginBottom: 25,
  },
  avatarFinal: {
    marginLeft: 20
  },
  stepButtons: {
    display: "flex",
    marginTop: 20,
    "& div": {
      marginRight: 20
    }
  },
});

