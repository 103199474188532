export default () => ({
  link: {
    "&:hover" : {
      cursor: "pointer"
    }
  },
  removeIcon: {
    "& svg": {
      fontSize: 18,
    },
    marginLeft: 10,
    padding: 0,
  },
});
