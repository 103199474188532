import React, {useEffect} from "react";
import classNames from "classnames";
import {keys, indexBy, prop, omit} from "ramda";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import styles from "./styles";
import useStores from "../../../../useStores";

const ConvertHistory = observer(({classes, certificateType, tpUns, grade, items, properties}) => {
  const {CertificateStore} = useStores();

  useEffect(() => {
    const {isLoading, data} = CertificateStore.products;

    if (isLoading || data.length) return;

    CertificateStore.getProducts();
  }, []);

  const product = CertificateStore.products.data.find((p) => p._id === properties.productType._id) || {};
  const productProperties = product.properties || [];
  const propertyByKey = indexBy(prop("name"), productProperties);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={certificateType ? 3 : 4} className={classes.box}>
        <div>Current item</div>
        <div>{properties.productType.name}</div>
      </Grid>
      <Grid item xs={certificateType ? 3 : 4} className={classes.box}>
        <div>Material</div>
        <div>{properties.norm}<br />{tpUns || grade}</div>
      </Grid>
      {certificateType && (
        <Grid item xs={3} className={classes.box}>
          <div>Certificate type</div>
          <div>{certificateType}</div>
        </Grid>
      )}
      <Grid item xs={certificateType ? 3 : 4} className={classes.box}>
        <div>Lot ID</div>
        <div>{properties.lotId}</div>
      </Grid>
      {items && (
        <Grid item xs={12} className={classNames(classes.box, classes.items)}>
          <div>Items</div>
          <div>
            {items.map((item) => (
              <p className={classes.item}>
                <span>Quantity: {item.quantity}</span>
                {keys(omit(["quantity", "_id"], item)).map((prop) => {
                  if (!propertyByKey[prop]) return null;

                  return (
                    <>
                      &nbsp;/&nbsp;
                      <span>{propertyByKey[prop].label}: {`${item[prop]}${propertyByKey[prop].measurements || ""}`}</span>
                    </>
                  );
                })}
              </p>
            ))}
          </div>
        </Grid>
      )}
    </Grid>
  );
});

export default withStyles(ConvertHistory, styles);
