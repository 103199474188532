import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const AcceptanceOnLocation = ({config}) => {
  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>Location</TableCell>
          <TableCell />
          <TableCell>Absorbed Energy (Single) [J]</TableCell>
          <TableCell>Absorbed Energy (Average) [J]</TableCell>
          <TableCell>Shear Area (Single) [%]</TableCell>
          <TableCell>Shear Area (Average) [%]</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {config.locationDependantValues.map((data) => (
          <>
            <TableRow>
              <TableCell rowSpan={2}>{data.location}</TableCell>
              <TableCell>Min</TableCell>
              <TableCell>{data.single}</TableCell>
              <TableCell>{data.average}</TableCell>
              <TableCell>{data.singleShearArea}</TableCell>
              <TableCell>{data.averageShearArea || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Max</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
  );
};

export default AcceptanceOnLocation;