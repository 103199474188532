import React, {useEffect} from "react";
import moment from "moment";
import {drop, head} from "ramda";
import {observer} from "mobx-react-lite";
import {Grid, Typography, Divider, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import MuiExpansionPanel from "@mui/material/Accordion";
import MuiExpansionPanelSummary from "@mui/material/AccordionSummary";
import MuiExpansionPanelDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {TestService} from "@core/services";
import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import NewMessage from "../NewMessage";
import useStores from "../../../../useStores";
import styles from "./styles";

const Accordion = withStyles(MuiExpansionPanel, {
  root: {
    "&:before": {
      display: "none"
    }
  },
  expanded: {
    margin: "auto"
  }
});

const AccordionSummary = withStyles((props) => <MuiExpansionPanelSummary {...props} />, {
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    display: "block",
    "&$expanded": {
      margin: "12px 0"
    },
    "& span": {
      fontSize: 18
    }
  },
  expanded: {}
});

AccordionSummary.muiName = "AccordionSummary";

const AccordionDetails = withStyles(MuiExpansionPanelDetails, () => ({
  root: {
    display: "block",
    padding: "10px 20px"
  }
}));

const FeedbackList = observer(({classes, lineItem, certificateId, approvable, onClose, lineItemStatus}) => {
  const {CertificateStore, UserStore} = useStores();

  useEffect(() => {
    CertificateStore.getFeedback(certificateId, lineItem);
  }, []);

  const approveIssue = async (feedbackIndex) => {
    await CertificateStore.resolveIssue(certificateId, lineItem, feedbackIndex);

    if(!approvable || !CertificateStore.feedback.data.every((f) => f.isClosed)) return;

    const certificate = CertificateStore.certificate.data;
    await TestService.approve(certificate.tests, certificate, UserStore.user.data._id);
    onClose();
  };

  return (
    <div className={classes.container}>
      {CertificateStore.feedback.data.map((f, feedbackIndex) => {
        const firstMessage = head(f.messages);
        const rest = drop(1, f.messages);

        return (
          <div className={classes.feedback}>
            <Grid container justifyContent="space-between">
              <Grid item className={classes.typeContainer}>
                <Typography
                  component="h5"
                  variant="h6"
                >
                  {f.type}
                </Typography>
                {f.isClosed && (
                  <div>
                    <img className={classes.imgMark} src="/images/verified-user.png" alt="check mark" />
                    <span className={classes.closed}>Resolved</span>
                  </div>
                )}
              </Grid>
              <Grid item>
                {!f.isClosed && (
                  <Button
                    className={classes.resolveButton}
                    variant="contained"
                    size="small"
                    onClick={() => approveIssue(feedbackIndex)}
                  >
                    Resolve
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider />
            <Accordion>
              <AccordionSummary
                expandIcon={rest.length ? <ExpandMoreIcon /> : null}
              >
                <Typography
                  component="span"
                  variant="body1"
                  color="textPrimary"
                  gutterBottom
                >
                  <b>{firstMessage.username}</b> <span
                    className={classes.date}>{moment(firstMessage.date).format(DATE_TIME_FORMAT)}</span>
                </Typography>
                <div dangerouslySetInnerHTML={{__html: firstMessage.message}} />
              </AccordionSummary>
              {rest.length && (
                <AccordionDetails>
                  {rest.map((m, index) => (
                    <>
                      <Grid item xs className={classes.message}>
                        <Typography
                          component="span"
                          variant="body1"
                          color="textPrimary"
                          gutterBottom
                        >
                          <b>{m.username}</b> <span
                            className={classes.date}>{moment(m.date).format(DATE_TIME_FORMAT)}</span>
                        </Typography>
                        <div dangerouslySetInnerHTML={{__html: m.message}} />
                      </Grid>
                      {index === rest.length - 1 && (
                        <NewMessage
                          lineItemStatus={lineItemStatus}
                          certificateId={certificateId}
                          lineItem={lineItem}
                          index={feedbackIndex}
                        />
                      )}
                    </>
                  ))}
                </AccordionDetails>
              )}
            </Accordion>
          </div>
        );
      })}
    </div>
  );
});

export default withStyles(FeedbackList, styles);