export default () => ({
  button: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: "15px !important",
  },

  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#cccccc",
    margin: "10px 0 20px 0"
  },
});