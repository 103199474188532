import themeSettings from "../../../../../../../../theme-settings";
import {MARGIN} from "../Stalk/constants";

export const PIPE_CONFIG = {
  WIDTH: 8,
  HEIGHT: 50,
  RADIUS: 5,
  MAX_SCALE: 1.5,
  MEDIUM_SCALE: 1.1,
  BACKGROUND_COLOR: "#3E7F8F",
  FONT_SIZE: 13,
  TEXT_SCALE_BY: 0.8,
  TEXT_Y: -10,
  TEXT_Y_MIN: -12,
  TEXT_Y_MAX: -25,
  TEXT_MAX_SCALE: 2.2,
  TEXT_MIN_SCALE: 0.75,
  COLOR: themeSettings.palette.text.primary,
  HIT_STROKE_WIDTH: MARGIN / 2,
};

export const WELD_CONFIG = {
  WIDTH: 7,
  HEIGHT: 40,
  RADIUS: 5,
  MAX_SCALE: 1.7,
  MEDIUM_SCALE: 1.25,
  BACKGROUND_COLOR: "#4a5d6b",
  HIT_STROKE_WIDTH: MARGIN / 2,
};

export const LINE_WELD_CONFIG = {
  POINTER_HEIGHT: 10,
  POINTER_WIDTH: 10,
  STROKE_WIDTH: 3,
  FONT_SIZE: 13,
  BACKGROUND_WIDTH: 100,
  RECTANGLE_PADDING: 6,
};

export const ANIMATION_DURATION = 0.07;