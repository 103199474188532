import React, {useEffect, useState} from "react";
import modules from "@core/constants/modules";
import DashboardProducer from "./components/ProducerDashboard";
import DashboardLab from "./components/LabDashboard";
import DashboardWitness from "./components/WitnessDashboard";
import BasicDashboard from "./components/BasicDashboard";
import DashboardEndOwner from "./components/EndOwnerDashboard";
import {observer} from "mobx-react-lite";
import useStores  from "../../../useStores";
import Loader from "@core/components/Loader";

const DashboardCompany = observer((props) => {
  const [storeCompanyId, setId] = useState("");
  const [storeCompanyModule, setModule] = useState("");
  const {CompanyStore} = useStores();

  const getCompany = async () => {
    await CompanyStore.getCompanyById(props.match.params.id);
  };

  useEffect(() => {
    if(!props.module && !props.userCompanyId){
      getCompany().then(() => {
        setId(CompanyStore.company.data._id);
        setModule(CompanyStore.company.data.modules[0].name);
      });
    }
  },[]);

  const userCompanyId = props.userCompanyId || storeCompanyId;
  const  module =  props.module || storeCompanyModule;
  const getDashboard = {
    [modules.END_OWNER] : <DashboardEndOwner userCompanyId={userCompanyId}/>,
    [modules.PRODUCER] : <DashboardProducer userCompanyId={userCompanyId}/>,
    [modules.BASIC] : <BasicDashboard userCompanyId={userCompanyId}/>,
    [modules.LAB_INTERNAL] : <DashboardLab userCompanyId={userCompanyId}/>,
    [modules.WITNESS]: <DashboardWitness userCompanyId={userCompanyId}/>
  };

  if(!module && !userCompanyId){
    return <Loader/>;
  }

  return getDashboard[module];

});

export default DashboardCompany;
