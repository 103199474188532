import {getTestName} from "@core/helpers";
import moment from "moment/moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";

export const columns = [{
  title: "Test",
  render: (test) => getTestName(test)
}, {
  title: "Requested by",
  render: (test) => test.company && test.company.name,
}, {
  title: "Requested on",
  render: (test) => moment(test.date_created).format(DATE_FORMAT)
}, {
  title: "Inspector Job Number",
  render: (test) => test?.properties?.inspectorJobNumber || "-"
}, {
  title: "Heat - Lot",
  render: (test) => `${test?.item_heat} - ${test?.lotId}`
}];