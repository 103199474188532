import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import useStores from "../../../../../../useStores";
import {Link, TableCell} from "@mui/material";
import modules from "@core/constants/modules";

const LINK_TO_ORDER_BY_MODULE = {
  [modules.PRODUCER]: "/transfers",
  [modules.END_OWNER]: "/orders",
};

const ProductionDataCell = ({products}) => {
  const {CampaignStore, UserStore} = useStores();

  const [module] = UserStore.user.data.company.modules;

  const productionTransfers = CampaignStore.transfers;

  const heats = useMemo(() => products.map((product) => product.heat), []);

  const productionData = useMemo(() => productionTransfers.filter((transfer) => transfer.certificates.some((certificate) => heats.includes(certificate.heat))), [productionTransfers]);

  return (
    <TableCell>
      {productionData.length ? productionData.map((transfer, index) => (
        <>
          <Link
            underline="hover"
            key={transfer.orderNumber}
            target="_blank"
            href={`${LINK_TO_ORDER_BY_MODULE[module.name]}/${transfer._id}`}
          >
            {transfer.orderNumber}
          </Link>
          {index + 1 !== productionData.length && ", "}
        </>
      )) : "-"}
    </TableCell>
  );
};

export default observer(ProductionDataCell);