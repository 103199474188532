import React from "react";
import {Box, useTheme} from "@mui/material";
import TestSummaryTable from "@core/components/TestSummaryTable";
import BlockHeader from "../../../BlockHeader";

const AssemblyTestReports = ({setFilteredTestIds, onTestClick}) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{marginBottom: theme.spacing(3.5)}}>
        <BlockHeader>Test Summary</BlockHeader>
        <TestSummaryTable
          setFilteredTestIds={setFilteredTestIds}
          onTestClick={onTestClick}
          tests={[]}
        />
      </Box>
    </>
  );
};

export default AssemblyTestReports;