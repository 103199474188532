export default () => ({
  notFoundImage: {
    height: "100%",
  },
  container: {
    height: "100%",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
  }
});