import React, {memo} from "react";
import {init} from "ramda";
import LineWeld from "../LineWeld";
import AnimatedWeld from "../AnimatedWeld";

const Weld = ({scale, chunk, settings, animateShape, totalLength, pipeLength, weldNumber, index, welds, pipes, pipeCoordinates, weldCoordinates, isLast, isLastInChunk, setOpenWeld, pipeConfig, weldConfig, defects}) => {
  if(!settings.welds) return null;

  if(isLastInChunk && !isLast && settings.splitStalk) {
    return (
      <LineWeld
        setOpenWeld={setOpenWeld}
        stalkChunks={[init(chunk)]}
        settings={settings}
        weldConfig={weldConfig}
        pipeConfig={pipeConfig}
        scale={scale}
        weldNumber={weldNumber}
        totalLength={totalLength}
        pipeLength={pipeLength}
        defects={defects}
      />
    );
  }

  return (
    <AnimatedWeld
      tooltipData={{
        weld: weldNumber,
        length: pipeLength,
        totalLength,
      }}
      settings={settings}
      animateShape={animateShape}
      weldNumber={weldNumber}
      index={index}
      welds={welds}
      pipes={pipes}
      pipeCoordinates={pipeCoordinates}
      weldCoordinates={weldCoordinates}
      setOpenWeld={setOpenWeld}
      pipeConfig={pipeConfig}
      weldConfig={weldConfig}
      defects={defects}
    />
  );
};

export default memo(Weld);