import React, {useMemo, memo} from "react";
import {Group, Rect, Text} from "react-konva";
import {
  PIPE_CONFIG,
  WELD_CONFIG,
  REPAIRED_WELD_CONFIG,
  AS_BUILD_TIE_IN_WELD_CONFIG
} from "../../constants";
import {STAGE_CONFIG} from "../../../../constants";
import {getTextScale} from "../../../../helpers";
import {getIsTieInWeld, getWeldIsRepaired} from "../../../../../../helpers";
import {equals} from "ramda";

const Weld = ({id, x, settings, scale, onClick, welds, index}) => {
  const y = useMemo(() => (PIPE_CONFIG.HEIGHT - WELD_CONFIG.HEIGHT) / 2, []);

  const isRepaired = useMemo(() => getWeldIsRepaired(id), [id]);

  const isTieInWeld = useMemo(() => getIsTieInWeld(id), [id]);

  const backgroundColor = useMemo(() => {
    if(isRepaired && settings.highlightRepairedWelds) return REPAIRED_WELD_CONFIG.COLOR;

    if(isTieInWeld && settings.tieInWelds) return AS_BUILD_TIE_IN_WELD_CONFIG.COLOR;

    return WELD_CONFIG.COLOR;
  }, [isRepaired, isTieInWeld, settings.highlightRepairedWelds, settings.tieInWelds]);

  const textColor = useMemo(() => {
    if(isRepaired && settings.highlightRepairedWelds) return REPAIRED_WELD_CONFIG.TEXT_COLOR;

    if(isTieInWeld && settings.tieInWelds) return AS_BUILD_TIE_IN_WELD_CONFIG.TEXT_COLOR;

    return WELD_CONFIG.TEXT_COLOR;
  }, [isRepaired, isTieInWeld, settings.highlightRepairedWelds, settings.tieInWelds]);

  const textScale = useMemo(() => {
    return getTextScale(PIPE_CONFIG.TEXT_SCALE_BY, scale, PIPE_CONFIG.TEXT_MAX_SCALE, PIPE_CONFIG.TEXT_MIN_SCALE);
  }, [scale]);

  if(!settings.welds) return null;

  return (
    <Group
      y={y}
      x={x}
      ref={(element) => {
        if(welds.current[index] && element) {
          welds.current[index].destroy();
        }

        welds.current[index] = element;
      }}
    >
      <Rect
        perfectDrawEnabled={false}
        width={WELD_CONFIG.WIDTH}
        height={WELD_CONFIG.HEIGHT}
        fill={backgroundColor}
        stroke={WELD_CONFIG.BORDER_COLOR}
        strokeWidth={WELD_CONFIG.BORDER_WIDTH}
        shadowForStrokeEnabled={false}
        hitStrokeWidth={0}
        onClick={onClick}
        onMouseEnter={() => document.body.style.cursor = "pointer"}
        onMouseLeave={() => document.body.style.cursor = "default"}
      />
      <Text
        x={-PIPE_CONFIG.WIDTH / 2}
        align="center"
        width={PIPE_CONFIG.WIDTH / textScale}
        perfectDrawEnabled={false}
        y={PIPE_CONFIG.HEIGHT - y + WELD_CONFIG.TEXT_POSITION}
        fontSize={WELD_CONFIG.FONT_SIZE}
        text={id}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        fill={textColor}
        scale={{x: textScale, y: textScale}}
      />
    </Group>
  );
};

export default memo(Weld, (prevProps, nextProps) => equals(prevProps, nextProps));