import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const ElectromagneticInduction = ({qcpConfig}) => {
  const [element = {}] = qcpConfig.properties.elements || [];

  return (
    <>
      <h3>Local Requirements</h3>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell>Notches location</TableCell>
            <TableCell>Notches dimensions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{element.notchesLocation}</TableCell>
            <TableCell>{element.width}MM x {element.length}MM x {element.depth}%</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default ElectromagneticInduction;