import {formControlClasses} from "@mui/material";

export default () => ({
  container: {
    [`& .${formControlClasses}`]: {
      width: "auto"
    }
  },
  select: {
    "&&": {
      overflowX: "auto",
    }
  },
  chipDeleteIcon: {
    transform: "rotate(45deg)",
    color: "#616161",

    "&&&:hover": {
      color: "#d6595b",
      cursor: "pointer",
    }
  }
});