export default (theme) => ({
  editorWrapper: {
    paddingTop: 25,
  },
  editorToolbar: {
    borderRadius: 4,
    margin: 0,
    borderBottom: "unset",
  },
  editorTextArea: {
    padding: "0 20px",
    minHeight: 250,
    border: "1px solid #ced4da",
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  editorLabel: {
    transform: "unset",
  },
  error: {
    borderColor: theme.palette.error.main
  },
  errorContainer: {
    position: "relative",
  },
  errorMessage: {
    position: "absolute",
    top: 0,
    left: 0,
    marginTop: theme.spacing(0.5),
    color: theme.palette.error.main
  }
});
