import {TYPES} from "@core/constants/test";
import React, {useCallback} from "react";
import {MuiAutocomplete} from "@core/components/Form";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const EtchantAutocomplete = ({etchingStandard, name, setFieldValue, required}) => {
  const formatOptions = useCallback(
    (data) => data.map((obj) => ({label: obj.EtchantName, value: obj}))
    , []);

  const filterOptions = useCallback(
    (options, state) => {

      return options.filter(({value}) => {
        const belongsToSelectedNorm = value?.Norm === etchingStandard;
        const searched = value?.EtchantName?.toLowerCase()?.includes(state?.inputValue?.toLowerCase());

        return belongsToSelectedNorm && searched;
      });
    }
    , [etchingStandard]);

  return (
    <MuiAutocomplete
      disabled={!etchingStandard}
      inputProps={{
        label: "Etching solution",
        name,
        required,
      }}
      url={ROUTES.TEST_NORM[ACTIONS.ALL_BY_QUERY](TYPES.MICROGRAPHIC)}
      onChange={(obj) => setFieldValue(name, obj.EtchantName)}
      formatOptions={formatOptions}
      filterOptions={filterOptions}
    />
  );
};

export default EtchantAutocomplete;