import React from "react";
import {head, tail} from "ramda";
import {Link} from "react-router-dom";
import {Box, Typography, Grid, Avatar, Paper, Button} from "@mui/material";
import IconistsIcon from "@core/components/IconistsIcon";

const Statistics = ({number = "", description, link, icon}) => {
  return (
    <Paper
      sx={{
        padding: 2,
        height: "100%"
      }}
    >
      <Grid
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                bgcolor: (theme) => theme.palette.primary.placeholder,
              }}
            >
              <IconistsIcon width="24px" icon={icon} color="#2496DB"/>
            </Avatar>
            {link && (
              <Button
                variant="secondaryNewDesign"
                size="small"
                component={Link}
                to={link}
              >
                View
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              width: 40,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                fontWeight: (theme) => theme.typography.fontWeightBold,
                width: "fit-content",
                "& span": {
                  left: "100%",
                  position: "absolute",
                }
              }}
            >
              {head(number)}
              <span>{tail(number)}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            fontSize: "0.82rem",
            fontWeight: (theme) => theme.typography.fontWeightSemibold,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <span>{description}</span>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Statistics;