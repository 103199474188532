import React from "react";
import Acceptance from "../Acceptance";

const AcceptanceTable = ({elements, poItem}) => {
  const isHeatAndProduct = elements.some((el) => el.analysis);
  
  if(isHeatAndProduct) {
    return (
      <>
        <h3>Heat analysis requirements</h3>
        <Acceptance
          elements={elements}
          analysis="heat"
          poItem={poItem}
        />
        <h3>Product analysis requirements</h3>
        <Acceptance
          elements={elements}
          analysis="product"
          poItem={poItem}
        />
      </>
    );
  }

  return (
    <Acceptance
      elements={elements}
      poItem={poItem}
    />
  );
};

export default AcceptanceTable;