export const TRANSACTION_TYPES = {
  TEST: "test",
  CERTIFICATE: "certificate",
  CAMPAIGN: "campaign",
  USER: "user"
};

export const TRANSACTION_ACTIONS_BY_TYPE = {
  [TRANSACTION_TYPES.TEST]: {
    ASSIGN: "assign",
    INSPECT: "inspect",
    APPROVE: "approve",
    WITNESS_REQUEST: "witnessRequest",
    SUBMIT: "submit",
    WITNESS: "witness"
  },
  [TRANSACTION_TYPES.CERTIFICATE]: {
    CREATE: "create",
  },
};