import React, {useState, useEffect} from "react";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  IconButton,
  Grid,
  Tooltip, Typography,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import EditModal from "./components/EditModal";
import {MdEdit} from "react-icons/md";
import styles from "./styles";
import {observer} from "mobx-react-lite";
import useStores from "../useStores";
import testsConfig from "@core/configs/test";
import Roles from "@core/constants/roles";
import File from "@core/components/File";
import TableFooter from "@core/components/TableFooter";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import TableSkeleton from "@core/components/TableSkeleton";
import {DATE_FORMAT} from "@core/constants/dateFormats";

const Instruments = observer((props) => {
  const {classes} = props;
  const [instruments, setInstruments] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalValues, setModalValues] = useState({});
  const [isConfirmationOpened, setConfirmationOpened] = useState(false);
  const {InstrumentStore, UserStore} = useStores();
  const isAdmin = UserStore.user.data.role === Roles.ADMIN;

  useEffect(() => {
    const {limit, offset} = InstrumentStore.instruments;
    InstrumentStore.findAll({offset, limit}).then(() => {
      updateInstruments();
    });
  },[]);

  const updateInstruments = () => {
    const listWithChecked = InstrumentStore.instruments.data.map((el) => ({...el, checked: false}));
    setInstruments(listWithChecked);
  };

  const onChangeSingle = (id) => {
    const changed = instruments.map((instrument) => {
      if(instrument._id === id){
        instrument.checked = !instrument.checked;
      }

      return instrument;
    });
    setInstruments(changed);
  };

  const onChangeAll = (event, checked) => {
    const changed = instruments.map((instrument) => ({...instrument, checked}));
    setInstruments(changed);
  };

  const checkedInstruments = instruments.filter((instrument) => instrument.checked);

  const opedEditModal = (el) => {
    setModalValues(el);
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalValues({});
    setModalOpened(false);
  };

  const deleteInstrument = () => {
    InstrumentStore.delete(checkedInstruments.map((instrument) => instrument._id))
      .finally(() => {
        setConfirmationOpened(false);
        updateInstruments();
      });
  };

  const handleChangePage = (offset) => {
    const {limit} = InstrumentStore.instruments;

    InstrumentStore.findAll({offset, limit}).then(() => {
      updateInstruments();
    });
  };

  const handleChangeRowsPerPage = (limit) => {
    const {offset} = InstrumentStore.instruments;

    InstrumentStore.findAll({offset, limit}).then(() => {
      updateInstruments();
    });
  };

  const {limit, total, offset, isLoaded} = InstrumentStore.instruments;

  return <>
    <div className={classes.mainPadding}>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Instruments
          </Typography>
        </Grid>
        {isAdmin && <Grid item>
          <Button color={"primary"} variant={"contained"} onClick={() => opedEditModal({})}>add New</Button>
        </Grid>}
        <Grid item xs={2}>
          {checkedInstruments.length !== 0 &&
          <Button
            onClick={() => setConfirmationOpened(true)}
            color={"secondary"}
            size={"large"}
            variant={"contained"}
          >
            Remove
          </Button>}
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  {isAdmin && <TableCell className={classes.colsmall}><Checkbox color="primary" onChange={onChangeAll}/></TableCell>}
                  <TableCell className={classes.thUser}>Equipment name</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>Serial number</TableCell>
                  <TableCell>Calibration expiration date</TableCell>
                  <TableCell>Default for tests</TableCell>
                  <TableCell>Calibration reports</TableCell>
                  {isAdmin && <TableCell>Edit</TableCell>}
                </TableRow>
              </TableHead>
              {isLoaded ? (
                <TableBody>
                  {instruments.length > 0 ? instruments.map((instrument, index) => {
                    const calibrationExpirationDatePassed = instrument.calibrationExpDate && moment(instrument.calibrationExpDate).isBefore(new Date());

                    return (
                      <TableRow key={index} className={classes.notActivated}>
                        {isAdmin && <TableCell className={classes.colsmall}>
                          <Checkbox
                            color="primary"
                            value={instrument.equipment}
                            checked={instrument.checked}
                            onChange={() => onChangeSingle(instrument._id)}
                          />
                        </TableCell>}
                        <TableCell className={classes.colsmall}>{instrument.equipment}</TableCell>
                        <TableCell>{instrument.trademark}</TableCell>
                        <TableCell>{instrument.serialNumber}</TableCell>
                        <TableCell className={calibrationExpirationDatePassed ? classes.error : null}>
                          <Tooltip title={calibrationExpirationDatePassed ? "Calibration expiration date has passed" : ""}>
                            <span>
                              {instrument.calibrationExpDate ? moment(instrument.calibrationExpDate).format(DATE_FORMAT) : "-"}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{instrument.defaultForTests.map((type) => testsConfig[type].title).join(", ")}</TableCell>
                        <TableCell>
                          <Grid container spacing={1} wrap="nowrap">
                            {instrument.files?.map((file) => (
                              <Grid item><File file={file} /></Grid>
                            ))}
                          </Grid>
                        </TableCell>
                        {isAdmin && <TableCell><IconButton
                          onClick={() => opedEditModal({id: instrument._id, ...instrument})}
                          size="large"><MdEdit size={20}/></IconButton></TableCell>}
                      </TableRow>
                    );
                  }) : (
                    <TableRow>
                      <TableCell colSpan={7}>No instruments found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ): (
                <TableBody>
                  <TableSkeleton columns={8} />
                </TableBody>
              )}
              <TableFooter
                isLoaded={isLoaded}
                items={instruments}
                total={total}
                limit={limit}
                offset={offset}
                onOffsetChange={handleChangePage}
                onLimitChange={handleChangeRowsPerPage}
              />
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </div>
    {modalOpened && <EditModal
      opened={modalOpened}
      onClose={closeModal}
      initialValues={modalValues}
      instruments={instruments}
      updateInstruments={updateInstruments}
    />}
    <Confirmation
      open={isConfirmationOpened}
      alertText={`Are you sure you want to delete ${checkedInstruments.length === 1 ? "an instrument" : `${checkedInstruments.length} instruments` } ?`}
      onCancel={() => setConfirmationOpened(false)}
      onConfirm={deleteInstrument}
    />
  </>;
});

export default withStyles(Instruments, styles);
