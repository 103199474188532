import React, {useState, useEffect} from "react";
import {Redirect} from "react-router-dom";
import userService from "@core/api/user-service";
import {AuthService} from "@core/services";

const Logout = (props) => {
  const [isLogOut, setLogOut] = useState(false);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    userService.clearCache();
    userService.logout()
      .then(() => {
        props.logout();
        setLogOut(true);

        AuthService.logout();

        setRedirect(<Redirect to='/login'/>);
      });
  },[]);

  return isLogOut && redirect;
};

export default Logout;
