import React from "react";
import {isEmpty} from "ramda";
import {Button} from "@mui/material";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";

const ConfirmationOnModal = ({confirmationOpen, formRef, setConfirmationOpen, onClose, onSave}) => {
  return (
    <Confirmation
      open={confirmationOpen}
      alertText="You are moving without saving data. Are you sure about this?"
    >
      <Button
        onClick={() => {
          setConfirmationOpen(false);
          onClose();
        }}
        color="secondary"
      >
        Close without saving
      </Button>
      {isEmpty(formRef?.current?.errors) ? (
        <Button onClick={onSave} variant="contained" color="primary">
          Save data
        </Button>
      ) : (
        <Button onClick={() => setConfirmationOpen(false)} variant="contained" color="primary">
          Stay
        </Button>
      )}
    </Confirmation>
  );
};

export default ConfirmationOnModal;