import React from "react";
import styles from "./styles";
import useStores from "../../../../useStores";
import AddDataFormHeatTreatment from "../../../../core/components/TestAddDataForms/HeatTreatment";
import AddDataFormTreatment from "../../../../core/components/TestAddDataForms/TreatmentTest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {TableCell, TableRow, DialogContent, Dialog, IconButton, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ActionCell from "@core/components/ActionCell";

const ExpandableTableRow = ({children, condition, classes,}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [treatmentToEdit, setTreatmentToEdit] = React.useState(null);
  const {TestStore, ConditionStore} = useStores();

  const saveTest = async (data) => {
    await TestStore.update({
      properties: data
    }, treatmentToEdit._id);

    setTreatmentToEdit(null);
    await ConditionStore.getPresetsByCompany();
  };

  const removeTreatment = async (treatmentId) => {
    await ConditionStore.deleteTest(treatmentId);
    await ConditionStore.getPresetsByCompany();
  };

  return <>
    {
      treatmentToEdit && (
        <Dialog
          open={treatmentToEdit}
          onClose={() => setTreatmentToEdit(null)}
          fullWidth
          maxWidth='md'
        >
          <DialogContent>
            {treatmentToEdit.type === "heat_treatment" ? (
              <AddDataFormHeatTreatment
                test={treatmentToEdit}
                saveTest={saveTest}
              />
            ) : (
              <AddDataFormTreatment
                test={treatmentToEdit}
                saveTest={saveTest}
              />
            )}
          </DialogContent>
        </Dialog>
      )
    }

    <TableRow>
      <TableCell padding="checkbox">
        <IconButton onClick={() => setIsExpanded(!isExpanded)} size="large">
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      {children}
    </TableRow>
    {isExpanded && (
      <>
        {condition.treatments.map((item, index) => {
          const treatmentName = item.type === "treatment_test" ? item.properties.elements[0].treatment : item.properties?.type;

          return (
            <TableRow key={treatmentName + index}>
              <TableCell padding="checkbox" />
              <TableCell>{treatmentName}</TableCell>
              <TableCell>
                <ActionCell>
                  <MenuItem
                    onClick={() => setTreatmentToEdit(item)}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    className={classes.deleteButton}
                    onClick={() => removeTreatment(item._id)}
                  >
                    Delete
                  </MenuItem>
                </ActionCell>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    )}
  </>;
};

export default withStyles(ExpandableTableRow, styles);
