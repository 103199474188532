export const columns = [{
  title: "Certificate ID",
  render: (certificate) => certificate._id
}, {
  title: "Heat",
  render: (certificate) => certificate.heat
}, {
  title: "Material grade",
  render: (certificate) => certificate.properties.grade
}, {
  title: "Product type",
  render: (certificate) => certificate.properties.productType.name
}];