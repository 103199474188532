export default (theme) => ({
  select: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    }
  },
  selectOutlined: {
    padding: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  }
});
