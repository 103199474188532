export default {
  login({expiresIn, accessToken, refreshToken}) {
    localStorage.setItem("expiresIn", expiresIn);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  },
  logout() {
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  },
  getTokens() {
    return {
      expiresIn: localStorage.getItem("expiresIn"),
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken")
    };
  },
};