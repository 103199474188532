import TestResultTableCell from "@core/components/TestResultTableCell";
import React, {useMemo, useState} from "react";
import classNames from "classnames";
import {Collapse, Grid, Table, TableBody, TableCell, Tooltip, TableHead, TableRow} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {withStyles} from "tss-react/mui";
import ImpactCurve from "@core/components/ImpactCurve";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {getMean, openFileInNewTab} from "@core/helpers";
import styles from "./styles";

const DropWeightTear = ({classes, test}) => {
  const [curveExpanded, setExpanded] = useState(false);

  const shearAreaAverage = useMemo(() => test.properties.elements ? 
    getMean(test.properties.elements.map((element) => Number(element.shearArea))) : null
  , []);

  return (
    <Grid container alignContent="space-between" spacing={1}>
      <Grid item container alignContent="space-between" spacing={5}>
        {(test.company?.name || test.properties.client) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Client</h4>
            <p className={classes.value}>{test.company?.name || test.properties.client}</p>
          </Grid>
        )}
        {(test.assignee?.name || test.properties.lab) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Laboratory</h4>
            <p className={classes.value}>{test.assignee?.name || test.properties.lab}</p>
          </Grid>
        )}
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        <Grid item xs={3}>
          <h4 className={classes.name}>Material specification</h4>
          <p className={classes.value}>{test.properties.norm}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Grade / UNS</h4>
          <p className={classes.value}>{test.properties.grade}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Test standard</h4>
          <p className={classes.value}>{test.properties.testStandard}</p>
        </Grid>
        {test.properties.acceptance && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Custom requirements</h4>
            <p className={classes.value}>{test.properties.acceptance}</p>
          </Grid>
        )}
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        <Grid item xs={3}>
          <h4 className={classes.name}>Specimen dimensions</h4>
          <p className={classes.value}>{`${test.properties.width} x ${test.properties.thickness} x 305 mm`}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Notch Type</h4>
          <p className={classes.value}>{test.properties.notchType}</p>
        </Grid>
        {test.properties.finalProductThickness && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Final product thickness</h4>
            <p className={classes.value}>{test.properties.finalProductThickness} mm</p>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={5}>
        {test.properties.elements && (
          <Grid item xs={12}>
            <Table className={classNames("styled-table", classes.table)}>
              <TableHead>
                <TableRow>
                  <TableCell>Spec. ID</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Orientation</TableCell>
                  <TableCell>Test temp [°C]</TableCell>
                  <TableCell>Fracture type</TableCell>
                  <TableCell>Shear area [%]</TableCell>
                  <TableCell>
                    <div className={classes.shearAreaAverage}>
                      Shear area average [%]&nbsp;
                      <Tooltip title="Shear area average across all test specimens">
                        <InfoIcon className={classes.info}/>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
                {(test.properties.requirements.singleShearArea || test.properties.requirements.averageShearArea) && (
                  <TableRow>
                    <TableCell>
                      Min
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      {test.properties.requirements.singleShearArea ? (
                        <>
                          &ge;
                          {test.properties.requirements.singleShearArea}
                        </>
                      ) : "-"}
                    </TableCell>
                    <TableCell>
                      {test.properties.requirements.averageShearArea ? (
                        <>
                          &ge;
                          {test.properties.requirements.averageShearArea}
                        </>
                      ) : "-"}
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {test.properties.elements?.map((element) => {
                  return (
                    <TableRow>
                      <TableCell>{element.specimen}</TableCell>
                      <TableCell>{element.location}</TableCell>
                      <TableCell>{element.position || "-"}</TableCell>
                      <TableCell>{element.orientation || "-"}</TableCell>
                      <TableCell>{element.temperature}</TableCell>
                      <TableCell>{element.fractureType}</TableCell>
                      <TableCell>
                        <TestResultTableCell
                          value={element.shearArea}
                          min={test.properties.requirements.singleShearArea}
                        />
                      </TableCell>
                      <TableCell>
                        <TestResultTableCell
                          value={shearAreaAverage}
                          min={test.properties.requirements.averageShearArea}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>

      {(test.properties.transitionCurve && test.properties.elements) && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <h3
              onClick={() => setExpanded(!curveExpanded)}
              className={classes.advancedSettingsButton}
            >
              See transition curve{curveExpanded ? <ExpandLess /> : <ExpandMore />}
            </h3>
          </Grid>
        </Grid>
      )}

      {(test.properties.transitionCurve && test.properties.elements) && (
        <Collapse in={curveExpanded} timeout="auto" unmountOnExit>
          <div>
            <ImpactCurve
              elements={test.properties.elements.map((element) => ({
                ...element,
                values: [element.shearArea]
              }))}
              valuesLabel="Total shear area [%]"
            />
          </div>
        </Collapse>
      )}

      {test.properties.file && (
        <Grid item container spacing={5}>
          <Grid item xs={12}>
            <a
              className={classes.link}
              onClick={() => openFileInNewTab(test.properties.file)}
              target={"_blank"}
            >
              Fracture images
            </a>
          </Grid>
        </Grid>
      )}

      {test.properties.notes && (
        <Grid item container spacing={5}>
          <Grid item xs={12}>
            <p
              className={classes.notes}
              dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(DropWeightTear, styles);
