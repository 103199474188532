export default (theme) => ({
  value: {
    color: "#616161",
    fontWeight: theme.typography.fontWeightMedium,
  },
  table: {
    margin: "20px 0",
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#cccccc",
    margin: "20px 0"
  },
  requirements: {
    margin: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCell: {
    borderBottom: "none",
  },
  notes: {
    marginTop: 30,
    fontWeight: theme.typography.fontWeightMedium,
    color: "#616161",
  },
  files: {
    marginTop: 20,
  },
});
