import React, {useState, useEffect} from "react";
import {Input} from "@core/components/Form";
import {BigFontButton, NoCapitalizeButton} from "./customButtons";
import {
  Button,
  InputAdornment,
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Parser} from "hot-formula-parser";
import {pick, equals} from "ramda";
import styles from "./styles";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";

/**
 * @name FormulaBuilder
 * @param props.elements {array} - array of chemical elements from the table
 * @param props.formula {string} - a formula to edit
 * @param props.onSubmit {function} - action executed by clicking "Set"
 * @param props.close {function} - closing modal function
 * @returns {*}
 * @constructor
 */
const FormulaBuilder = (props) => {

  const [formulaValue, setFormulaValue] = useState(props.formula || "");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [result, setResult] = useState({result: ""});
  const [numericFormula, setNumericFormula] = useState("");
  const [formulaChecked, setFormulaChecked] = useState(false);
  const [chemicalLabel, setChemicalLabel] = useState(props.chemical.label || props.chemical.bm);
  const [chemicalMin, setChemicalMin] = useState(props.chemical.min);
  const [chemicalMax, setChemicalMax] = useState(props.chemical.max);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {elements, classes} = props;
  const elementNames = elements.filter((el) => el.bm !== "Pren").map((el) => el.bm);
  const mathValues = ["+", "-", "*", "/", "()", ".", ","];

  const existingLabels = elements.filter((el) => el.bm !== props.chemical.bm).map((el) => el.label || el.bm);
  const labelIsUnique = !existingLabels.includes(chemicalLabel);

  useEffect(() => {
    if (props.formula) {
      setFormulaValue(props.formula);
      compute();
    }
  }, []);

  useEffect(() => {
    compute();
  }, [formulaValue]);

  const parser = new Parser();

  // Creating custom Parser variables
  elementNames.forEach((name) => {
    parser.setVariable(name, elements.find((el) => el.bm === name).value || 0);
  });

  const handleInput = (e) => {
    setFormulaValue(e.target.value);
    setFormulaChecked(false);
  };

  const compute = () => {
    const res = parser.parse(formulaValue);

    // parsing the formula string and receiving unique chemical elements used there
    const wordsInFormula = [...formulaValue.matchAll(/[A-Za-z]+/g)].map((el) => el[0]);
    const elementsInFormula = [...new Set(wordsInFormula.filter((w) => elementNames.includes(w)))];

    // replacing element names with its values from chemical test
    const numericFormula = elementsInFormula.reduce((acc, curr) => {
      const regexp = `\\b${curr}\\b`;

      return acc.replace(new RegExp(regexp, "g"), elements.find((E) => E.bm === curr).value || 0);
    }, formulaValue);

    setNumericFormula(numericFormula);

    if (res.result) setResult({...res, result: Number(parseFloat(res.result).toFixed(2))});
    else setResult(res);

    setFormulaChecked(true);
  };

  const insertFormula = (value) => {
    const domEl = document.getElementById("formula");
    const isSelected = domEl.selectionStart !== domEl.selectionEnd;
    const start = isSelected ? domEl.selectionStart : cursorPosition;
    const end = isSelected ? domEl.selectionEnd : cursorPosition;
    const output = [formulaValue.slice(0, start), value, formulaValue.slice(end)].join("");
    setCursorPosition(start + value.length);
    setFormulaValue(output);
    setFormulaChecked(false);
  };

  const set = () => {
    props.onSubmit({
      formula: formulaValue,
      bm: chemicalLabel,
      label: chemicalLabel,
      min: chemicalMin,
      max: chemicalMax
    });
    props.close();
  };

  const onCancelExisting = () => {
    const chemical = pick(["formula", "bm", "min", "max"], props.chemical);
    const newChemical = {formula: formulaValue, bm: chemicalLabel, min: chemicalMin, max: chemicalMax};
    const hasChanges = !equals(chemical, newChemical);

    if (hasChanges) setShowConfirmation(true);
    else props.close();
  };

  const onCancelNew = () => {
    const hasChanges = chemicalLabel || chemicalMax || chemicalMin;

    if (hasChanges) setShowConfirmation(true);
    else props.close();
  };

  const onCancel = () => props.formula ? onCancelExisting() : onCancelNew();

  return (
    <Dialog
      open
      onClose={onCancel}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>Formula builder</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Input
              label="Label"
              value={chemicalLabel}
              onChange={(e) => setChemicalLabel(e.target.value)}
              error={!labelIsUnique}
              errorMessage="Label has to be unique"
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type="number"
              label="Min"
              value={chemicalMin}
              onChange={(e) => setChemicalMin(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type="number"
              label="Max"
              value={chemicalMax}
              onChange={(e) => setChemicalMax(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems={"center"}>
          <Grid item xs={6}>
            <Input
              label="Formula"
              value={formulaValue}
              onChange={(e) => handleInput(e)}
              name='formula'
              onKeyUp={(e) => setCursorPosition(e.target.selectionStart)}
              onClick={(e) => setCursorPosition(e.target.selectionStart)}
              endAdornment={<InputAdornment position="end">{result.error || result.result}</InputAdornment>}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.result}>
              {numericFormula} = {result.error || result.result}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.elementsButtons}>
          {elementNames.map((el) => (
            <NoCapitalizeButton
              key={el}
              color={"primary"}
              variant={"outlined"}
              size={"medium"}
              onClick={() => insertFormula(el)}
            >
              {el}
            </NoCapitalizeButton>
          ))}
        </div>
        <Grid container spacing={5}>
          <Grid item xs={12} className={classes.mathButtons}>
            {mathValues.map((math, id) =>
              <BigFontButton
                key={`${id}${math}`}
                color={"primary"}
                variant={"outlined"}
                size={"medium"}
                onClick={() => insertFormula(math)}>
                {math}
              </BigFontButton>
            )}
            <BigFontButton
              color={"primary"}
              variant={"outlined"}
              size={"medium"}
              onClick={() => insertFormula("SUM()")}
            >
              Σ
            </BigFontButton>
            <BigFontButton
              color={"primary"}
              variant={"outlined"}
              size={"medium"}
              onClick={() => insertFormula("SQRT()")}
            >
              √
            </BigFontButton>
            <button className={classes.customButton} onClick={() => insertFormula("POWER(x, 2)")}>X<sup>2</sup></button>
            <button className={classes.customButton} onClick={() => insertFormula("POWER(x, y)")}>X<sup>y</sup></button>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={2}>
            <Button
              fullWidth
              variant={"contained"}
              color={"primary"}
              size={"large"}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant={"contained"}
              color={"primary"}
              size={"large"}
              onClick={set}
              disabled={
                !formulaChecked ||
                result.result === null ||
                result.error ||
                !labelIsUnique ||
                !chemicalLabel
              }
            >
              Set
            </Button>
          </Grid>
        </Grid>
        <Confirmation
          open={showConfirmation}
          alertText="You are moving without saving data. Are you sure about this?"
          onCancel={() => setShowConfirmation(false)}
          onConfirm={() => {
            setShowConfirmation(false);
            props.close();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(FormulaBuilder, styles);