import React from "react";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {splitEvery} from "ramda";
import classNames from "classnames";
import TestParameters from "./TestParameters";
import styles from "./styles";

const ELEMENTS_IN_COLUMN = 10;

const ManualCount = ({classes, test}) => {
  const columns = splitEvery(ELEMENTS_IN_COLUMN, test.properties.elements);
  const columnsNumber = Math.max(Math.floor(12 / columns.length), 2);
  const columnWidth = Math.min(columnsNumber, 4);

  return (
    <>
      <TestParameters test={test} />
      <Grid item xs={12} container spacing={1}>
        {columns.map((bunch, bunchIndex) => (
          <Grid item xs={columnWidth} key={bunchIndex}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="none" width="70" align="center" className={classNames(classes.value, classes.tableColumn)}>
                    Field
                  </TableCell>
                  <TableCell padding="none" align="center"className={classNames(classes.value, classes.tableColumn)}>
                    Points falling in ferrite phase
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bunch.map((element, elementIndex) => {
                  const actualIndex = bunchIndex * ELEMENTS_IN_COLUMN + (elementIndex + 1);

                  return (
                    <TableRow className={classes.tableRow} key={bunchIndex + elementIndex}>
                      <TableCell className={classes.tableColumn} padding="none" align="center">{actualIndex}</TableCell>
                      <TableCell className={classes.tableColumn} padding="none" align="center">{element}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Mean ferrite</h4>
        <p className={classes.value}>{test.properties.meanFerrite}%</p>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Standard deviation</h4>
        <p className={classes.value}>{test.properties.deviation}%</p>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>CI 95%</h4>
        <p className={classes.value}>{test.properties.ci}%</p>
      </Grid>
      <Grid item xs={3}>
        <h4 className={classes.name}>Relative accuracy</h4>
        <p className={classes.value}>{test.properties.accuracy}%</p>
      </Grid>
    </>
  );
};

export default withStyles(ManualCount, styles);