import {EXT_NAMES_BY_FEATURE, FEATURES} from "@core/constants/features";
import {Button, Grid} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {TbFileUpload} from "react-icons/tb";
import useStores from "../../../../../useStores";

const ImportForm = ({children, file, isLoading, onNewFile}) => {
  const {UserStore} = useStores();
  
  const canParseCsv = UserStore.features[FEATURES.PARSE_CSV];
  const canParseXls = UserStore.features[FEATURES.PARSE_XLS];
  const canParseZip = UserStore.features[FEATURES.PARSE_ZIP];

  const csvExtNames = canParseCsv ? EXT_NAMES_BY_FEATURE[FEATURES.PARSE_CSV] : [];
  const xlsExtNames = canParseXls ? EXT_NAMES_BY_FEATURE[FEATURES.PARSE_XLS] : [];
  const zipExtNames = canParseZip ? EXT_NAMES_BY_FEATURE[FEATURES.PARSE_ZIP] : [];

  const acceptedExtNames = [...csvExtNames, ...xlsExtNames, ...zipExtNames].join(",");
  
  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item sx={{marginBottom: 1}}>File:</Grid>
      <Grid item sx={{marginBottom: 1}}>{file ? file.name : "Select file"}</Grid>
      <Grid item>
        <label htmlFor="contained-button-file">
          <input
            id="contained-button-file"
            style={{display: "none"}}
            accept={acceptedExtNames}
            multiple type="file"
            onChange={onNewFile}
          />
          <Button
            color='primary'
            variant="contained"
            component="span"
            disabled={isLoading}
          >
            <TbFileUpload size={19} />&nbsp;
            <span>{file ? "Change file" : "Add file"}</span>
          </Button>
        </label>
      </Grid>
      {children}
    </Grid>
  );
};

export default observer(ImportForm);