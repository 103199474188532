import React from "react";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ByFerriteScope from "./ByFerriteScope";
import ManualCount from "./ManualCount";
import AutomaticCount from "./AutomaticCount";
import styles from "./styles";

const COMPONENT_BY_TYPE = {
  "by Ferritescope": ByFerriteScope,
  "Manual count": ManualCount,
  "Automatic count": AutomaticCount,
};

const FerriteContent = ({classes, test, ...props}) => {
  const Component = COMPONENT_BY_TYPE[test.properties.type];

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <h4 className={classes.name}>Ferrite test type</h4>
        <p className={classes.value}>{test.properties.type}</p>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.name}>Test standard</h4>
        <p className={classes.value}>{test.properties.norm}</p>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <h3>Acceptance criteria</h3>
        </Grid>
        <Grid item xs={4}>
          <h4 className={classes.name}>Specification</h4>
          <p className={classes.value}>{test.properties.acceptance}</p>
        </Grid>
        <Grid item xs={4} container spacing={3}>
          {!!test.properties.min && (
            <Grid item xs={4}>
              <h4 className={classes.name}>Min</h4>
              <p className={classes.value}>{test.properties.min}{test.properties.uom}</p>
            </Grid>
          )}
          {!!test.properties.max && (
            <Grid item xs={4}>
              <h4 className={classes.name}>Max</h4>
              <p className={classes.value}>{test.properties.max}{test.properties.uom}</p>
            </Grid>
          )}
        </Grid>
        {test.properties.maxRelativeAccuracy && (
          <Grid item xs={4}>
            <h4 className={classes.name}>Max relative accuracy</h4>
            <p className={classes.value}>{test.properties.maxRelativeAccuracy}%</p>
          </Grid>
        )}
      </Grid>
      {Component && (
        <Component
          test={test}
          {...props}
        />
      )}
      {test.properties.notes && (
        <Grid item xs={12}>
          <p
            className={classes.value}
            dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(FerriteContent, styles);
