import React from "react";
import {Provider} from "mobx-react";
import {createRoot} from "react-dom/client";
import "@core/middleware/tokenRefresh";
import "@core/middleware/transformResponse";
import "@core/middleware/sentryInit";
import App from "./App";
import * as stores from "./configure-store";

const appRoot = document.getElementById("root");

const app = createRoot(appRoot);
app.render(
  <Provider {...stores}>
    <App/>
  </Provider>
);
// registerServiceWorker();
