import React from "react";
import qs from "qs";
import {pick, tail} from "ramda";
import {getColumns} from "./data";
import {TEST_STATUSES, TABS} from "../../../components/TestsList/data";
import TestList from "../../../components/TestsList";

const ProducerTestList = () => {
  const currentTab = qs.parse(tail(location.search)).view || TABS.ACTIVE;

  const columns = getColumns(currentTab);

  return (
    <TestList
      testStatuses={pick([TABS.ACTIVE, TABS.BIN], TEST_STATUSES)}
      currentTab={currentTab}
      columns={columns}
    />
  );
};

export default ProducerTestList;
