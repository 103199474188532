export default () => ({
  title: {
    marginBottom: 12,
  },
  container: {
    background: "#f7f7f7",
    border: "1px solid #ced4da",
    borderRadius: 4,
    padding: 15,
  }
});