export default (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  witnesses: {
    marginTop: 2
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column"
  },
  dateLabel: {
    width: "100%",
    position: "relative",
    fontSize: "1.2vw",
    whiteSpace: "nowrap",
    color: "#00000085",
  },
  dateField: {
    display: "flex",
    width: "100%",
    overflow: "auto",
    minHeight: 50,
    padding: "5px 12px",
    marginTop: 5,
    border: "1px solid #ced4da",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  confidential: {
    display: "flex",
    alignItems: "center"
  }
});