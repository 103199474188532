export default (theme) => ({
  content: {
    overflowY: "scroll",
    overflowX: "hidden",
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginLeft: theme.spacing(),
  },
});
