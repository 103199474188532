import React, {useState} from "react";
import {head, drop} from "ramda";
import {List, ListItem, Collapse} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {OPERATOR_DESCRIPTION} from "@core/constants/specifications";
import styles from "./styles";

const Element = ({element}) => {
  if(element.operator) return <div>{element.field || element.bm} {OPERATOR_DESCRIPTION[element.operator].toLowerCase()} {element.value}</div>;

  return <div>{element.field || element.bm}:&nbsp;{element.value}</div>;
};

const ChemicalElements = ({classes, elements, elementsToShow}) => {
  const [open, setOpen] = useState(false);

  const allowedElements = elementsToShow ? elements.filter((el) => elementsToShow.includes(el.bm)) : elements;

  const first = head(allowedElements) || {};
  const rest = drop(1, allowedElements) || [];

  return (
    <List>
      <ListItem
        classes={{
          root: classes.listItem
        }}
        disableGutters
        dense={true}
        onClick={() => setOpen(!open)}
      >
        <Element
          element={first}
        />
        {rest.length ? (open ? <ExpandLess /> : <ExpandMore />) : null}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {rest.map((element) => (
          <ListItem
            classes={{
              root: classes.listItem
            }}
            disableGutters
            dense={true}
          >
            <Element
              element={element}
              key={element.value}
            />
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
};

export default withStyles(ChemicalElements, styles);
