import React, {useState, useEffect} from "react";
import * as yup from "yup";
import useFetchTestNorms from "@core/hooks/useFetchTestNorms";
import {omit, prop, indexBy, values, keys, isEmpty, pick, times, isNil} from "ramda";
import {Formik, FieldArray} from "formik";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  Grid,
  MenuItem,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {TYPES, STATUSES, POSITIONS, ORIENTATIONS} from "@core/constants/test";
import {Input} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {FilesUploader} from "@core/components/Uploaders";
import {
  SPECIMEN_TYPES,
  ELONGATION_BY_NORM,
  DIMENSIONS_BY_SPECIMEN_TYPE,
  ELONGATION_SPECIFIERS,
  TEST_STANDARDS,
  ISO_TEST_STANDARDS,
  STRENGTH_SPECIFIERS,
  ZONE,
  BREAK_POSITION,
  MIN_ELEVATED_TEMPERATURE,
  EXCEPTIONS,
  ELEMENT,
  REQUIREMENTS,
  GLOBAL_REQUIREMENTS
} from "./data";
import {
  getResult,
  getIsAcceptable,
  formatNumber,
  getCustomExceptionAllowed,
  getLimitFromCondition,
  checkRp,
  checkA,
  checkUniformElongation,
  checkYeildTensileRatio,
  checkReductionOfArea,
  checkRm,
} from "./services";
import ClientField from "../../../../Tests/Test/components/ClientField";
import CustomException from "./CustomException";
import TestFooter from "../../LabTestFooter";
import styles from "./styles";
import MuiSelect from "@core/components/MuiSelect";
import classNames from "classnames";
import {getPoItemNumber} from "@core/helpers";
import EditableCell from "@core/components/EditableCell";

const TensileTest = ({certificate, test, saveTest, classes, user, client, formRef}) => {
  const [data, setData] = useState({});
  const [retestIndexesByTestIndex, setRetestIndexByIndex] = useState({});

  const poItemNumber = getPoItemNumber(certificate.lineItem);

  const filteredAcceptances = keys(EXCEPTIONS).filter((exception) => {
    const config = EXCEPTIONS[exception];
    const isRequired = !config.getIsRequired || config.getIsRequired({poItem: poItemNumber});
    
    return (!config.company || config.company.includes(user.company.name)) && isRequired;
  });

  const initialValues = {
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name || "",
    norm: test.properties.norm || test.norm || certificate?.properties?.norm || "",
    grade: test.properties.grade || certificate?.properties?.grade || "",
    specimen: test.properties.specimen,
    testStandard: test.properties.testStandard || "",
    result: test.properties.result || "",
    acceptance: test.properties.acceptance || "",
    elements: test.properties.elements || [ELEMENT],
    notes: test.properties.notes || "",
    files: test.properties.files || [],
    requirements: test.properties.requirements || GLOBAL_REQUIREMENTS,
  };

  const validationSchema = yup.object().shape({
    client: yup.string().required("Client field is required"),
    lab: yup.string().required("Laboratory field is required"),
    norm: yup.string().required("Acceptance criteria is required!"),
    ...{
      ...(values(data).some((grade) => grade.Material) ? {
        grade: yup.string().required("Limit is required!")
      } : {})
    },
    specimen: yup.string().required("Specimen is required!"),
    testStandard: yup.string().required("Test norms is required!"),
    notes: yup.string(),
    acceptance: yup.string(),
    requirements: yup.object().shape({
      yieldStrengthRange: yup.number(),
      tensileStrengthRange: yup.number(),
    }),
    elements: yup.array().of(yup.object().shape({
      position: yup.string(),
      specimenId: yup.string().required("Specimen ID is required!"),
      fracturAppearance: yup.string().when("zone", {
        is: "Weld Metal",
        then: yup.string().required("Fracture appearance is required!")
      }),
      dimensionX: yup.number().positive("Should be > 0").typeError("Should be a number!").required("Dimension - X is required!"),
      dimensionY: yup.number().positive("Should be > 0").typeError("Should be a number!").test("dimensionY", "Dimension - Y is required", function (value) {
        const {from} = this;
        const specimen = from[1].value.specimen;

        return ![SPECIMEN_TYPES.RECTANGULAR, SPECIMEN_TYPES.STRIP].includes(specimen) || !!value;
      }),
      heatTreatedThickness: yup.number().positive("Should be > 0").typeError("Should be a number!"),
      boltDiameter: yup.string(),
      gaugeLength: yup.string(),
      temperature: yup.number(),
      breakPosition: yup.string(),
      zone: yup.string().required("Zone is required"),
      orientation: yup.string(),
      rp: yup.string().when(["requirements", "temperature", "zone"], {
        is: (requirements, temperature, zone) => {
          const isBase = zone === "Base Metal";
          const isHotTensileTest = Number(temperature) >= MIN_ELEVATED_TEMPERATURE;
          const hasToBeFilled = requirements && (requirements.yeldMin || requirements.yeldMax);

          return !isHotTensileTest && hasToBeFilled && isBase;
        },
        then: yup.string().required("Field is required"),
        otherwise: yup.string()
      }),
      rm: yup.string().test("rm", "Field is required", function (value) {
        const [element, parent] = this.from;

        const requirements = element.value.requirements || {};
        const temperature = element.value.requirements;
        const acceptance = parent.value.acceptance;

        if(acceptance) return value;

        const isHotTensileTest = Number(temperature) >= MIN_ELEVATED_TEMPERATURE;

        return !isHotTensileTest || ((requirements.tensMin || requirements.tensMax) && value);
      }),
      a: yup.string().test("a", "Field is required", function (value) {
        const [element, parent] = this.from;

        const requirements = element.value.requirements || {};
        const temperature = element.value.requirements;
        const acceptance = parent.value.acceptance;

        if(acceptance) return isNil(requirements.elongation) || value;

        const isHotTensileTest = Number(temperature) >= MIN_ELEVATED_TEMPERATURE;

        return !isHotTensileTest || (requirements.elongation && value);
      }),
      reductionOfArea: yup.string().when(["requirements", "temperature"], {
        is: (requirements, temperature) => {
          const isHotTensileTest = Number(temperature) >= MIN_ELEVATED_TEMPERATURE;
          const hasToBeFilled = requirements && requirements.reductionOfArea;

          return !isHotTensileTest && hasToBeFilled;
        },
        then: yup.string().required("Field is required"),
        otherwise: yup.string()
      }),
      uniformElongation: yup.string().when(["requirements", "temperature"], {
        is: (requirements, temperature) => {
          const isHotTensileTest = Number(temperature) >= MIN_ELEVATED_TEMPERATURE;
          const hasToBeFilled = requirements && requirements.uniformElongationMin;

          return !isHotTensileTest && hasToBeFilled;
        },
        then: yup.string().required("Field is required"),
        otherwise: yup.string()
      }),
    }))
  });

  useFetchTestNorms(TYPES.TENSILE, initialValues.norm, setData);

  const onSubmit = (values) => {
    const properties = {
      ...values,
      result: getResult(getIsAcceptable(values.elements, values.acceptance, values.requirements, poItemNumber))
    };

    const type = values.elements.some((element) => Number(element.temperature) >= MIN_ELEVATED_TEMPERATURE) ? TYPES.HOT_TENSILE : TYPES.TENSILE;

    saveTest(properties, type);
  };

  return (
    <Formik
      onSubmit={(values) => onSubmit(values)}
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      render={(props) => {
        const {
          values: {
            norm,
            grade,
            specimen,
            elements,
            files,
            acceptance,
            testStandard,
            requirements
          },
          setFieldValue
        } = props;

        useEffect(() => {
          const unfilled = [STATUSES.EMPTY, STATUSES.ASSIGNED].includes(test.status);

          if (isEmpty(data) || !grade || !unfilled) return;

          onLimitChange(data[grade]);
        }, [data]);

        const changeElements = (changes) => {
          const newElements = elements.map((el) => ({...el, ...changes}));
          setFieldValue("elements", newElements);
        };

        const clearElementsRequirements = () => {
          const newElements = elements.map((el) => ({...el, requirements: REQUIREMENTS}));
          setFieldValue("elements", newElements);
        };

        const onLimitChange = (value) => {
          if (!value) return;

          if(acceptance) return;

          setFieldValue("grade", value.Material);

          const requirements = {
            yeldMin: Number(value.YELDmin),
            yeldMax: Number(value.YELDmax),
            tensMin: Number(value.TENSmin),
            tensMax: Number(value.TENSmax),
            elongation: Number(value.ELONGATION) || "",
            reductionOfArea: Number(value.ReductionOfArea) || "",
            yeildTensileRatio: Number(value.YieldTensileRatio) || "",
            uniformElongationMin: Number(value.UniformElongationMin) || "",
          };
          changeElements({requirements});

          if (value.DefaultSpecimen) {
            setFieldValue("specimen", value.DefaultSpecimen);
          }
        };

        const onAcceptanceCriteriaChange = ({norm, value}) => {
          setFieldValue("norm", norm);

          if (!value) {
            setData({});
            setFieldValue("grade", "");
            clearElementsRequirements();

            return;
          }

          const gradeDataByMaterial = indexBy(prop("Material"), value);
          setData(gradeDataByMaterial);

          if (norm === norm) return;

          if (value.length === 1) {
            const [grade] = value;
            onLimitChange(grade);

            return;
          }

          setFieldValue("grade", "");
          clearElementsRequirements();
        };
        
        const checkRetests = (index, el, remove, push) => {
          if(el.rm > el.requirements.triggerRetestTensMax || el.rp < el.requirements.triggerRetestYeldMin) {
            addRetests(el, index, push);
          }
          else removeRetest(index, remove);
        };
        
        const removeRetest = (index, remove) => {
          const indexesToRemove = retestIndexesByTestIndex[index];

          if(!indexesToRemove) return;

          indexesToRemove.reverse().forEach((index) => remove(index));

          setRetestIndexByIndex(omit([index], retestIndexesByTestIndex));
        };
        
        const addRetests = (el, index, push) => {
          if(retestIndexesByTestIndex[index]) return;

          const {retestNumber, ...requirements} = el.requirements.retest;
          const retests = times((index) => ({...ELEMENT, requirements, specimenId: `${el.specimenId}-RETEST-${index + 1}`}), retestNumber);
          const retestIndexes = retests.map((_, index) => index + elements.length);

          retests.forEach((test) => push(test));

          setRetestIndexByIndex({...retestIndexesByTestIndex, [index]: retestIndexes});
        };
        
        const getElongationFunction = (acceptance, index = 0) => {
          if(acceptance) {
            const config = EXCEPTIONS[acceptance].poItem ? EXCEPTIONS[acceptance].poItem[poItemNumber] : EXCEPTIONS[acceptance];

            return config.elements ? config.elements[index]?.requirements.getElongation : config.getElongation;
          }
          
          return ELONGATION_BY_NORM[norm];
        };

        const materialData = data[grade] || {};

        return (
          <>
            <Grid container spacing={5}>
              <Grid item xs={12} container spacing={5} alignItems="flex-end">
                <Grid item xs={2}>
                  <ClientField isFromProducer={!!client.name} />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    disabled
                    name="lab"
                    label="Laboratory"
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <MuiSelect
                    required
                    label="Test standard"
                    name="testStandard"
                    defaultOptions={TEST_STANDARDS}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container spacing={5} alignItems="flex-end">
                {certificate._id ? (
                  <>
                    <Grid item xs={2}>
                      <Input
                        value={norm}
                        label="Material Specification"
                        name="norm"
                        required
                        disabled
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Input
                        value={grade}
                        label="Grade / UNS"
                        name="grade"
                        required
                        disabled
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={2}>
                      <NormAutocomplete
                        label="Material Specification"
                        name="norm"
                        testType={TYPES.TENSILE}
                        onChange={onAcceptanceCriteriaChange}
                      />
                    </Grid>

                    {norm && values(data).some((norm) => norm.Material) && (
                      <Grid item xs={2}>
                        <SelectField
                          label="Grade / UNS"
                          name="grade"
                          onChange={(value) => onLimitChange(data[value])}
                        >
                          {values(data).map((grade) => (
                            <MenuItem key={grade.Material} value={grade.Material}>{grade.Material}</MenuItem>
                          ))}
                        </SelectField>
                      </Grid>)}
                  </>
                )}

                <Grid item xs={2}>
                  <SelectField
                    key={specimen}
                    disabled={materialData.DefaultSpecimen}
                    required
                    label="Specimen type"
                    name="specimen"
                    onChange={(value) => {
                      if (materialData.InfluencedBy === ELONGATION_SPECIFIERS.SPECIMEN) {
                        const elongation = materialData.ELONGATION[value] || "";
                        const newElements = elements.map((el) => ({
                          ...el,
                          requirements: {...el.requirements, elongation}
                        }));
                        setFieldValue("elements", newElements);
                      }

                      const getElongation = getElongationFunction(acceptance);
                      
                      if (getElongation) {
                        const newElements = elements.map((el) => ({
                          ...el,
                          requirements: {
                            ...el.requirements,
                            elongation: getElongation({
                              tensileMin: el.requirements.tensMin,
                              specimen: value,
                              dimensionX: el.dimensionX,
                              dimensionY: el.dimensionY,
                              elongation: materialData.ELONGATION,
                              heatTreatedThickness: el.heatTreatedThickness,
                              orientation: el.orientation,
                              gaugeLength: el.gaugeLength
                            })
                          }
                        }));
                        setFieldValue("elements", newElements);
                      }
                    }}
                  >
                    {Object.values(SPECIMEN_TYPES).map((el) => (
                      <MenuItem key={el} value={el}>{el}</MenuItem>)
                    )}
                  </SelectField>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={5}>
                <Grid item xs={2}>
                  <SelectField
                    label="Load requirements"
                    name="acceptance"
                    onChange={(value) => {
                      const acceptance = EXCEPTIONS[value];
                      
                      const requirements = {
                        yeldMin: Number(materialData.YELDmin),
                        yeldMax: Number(materialData.YELDmax),
                        tensMin: Number(materialData.TENSmin),
                        tensMax: Number(materialData.TENSmax),
                        elongation: Number(materialData.ELONGATION) || "",
                        reductionOfArea: Number(materialData.ReductionOfArea) || "",
                        yeildTensileRatio: Number(materialData.YieldTensileRatio) || ""
                      };

                      if (!value) {
                        changeElements({requirements});
                        setFieldValue("notes", "");
                        setFieldValue("requirements", GLOBAL_REQUIREMENTS);

                        return;
                      }

                      const acceptanceRequirements = acceptance.poItem && poItemNumber ?
                        acceptance.poItem[poItemNumber] || {} :
                        acceptance;

                      const globalRequirements = {
                        yieldStrengthRange: acceptanceRequirements.yieldStrengthRange,
                        tensileStrengthRange: acceptanceRequirements.tensileStrengthRange,
                      };

                      setFieldValue("requirements", globalRequirements);
                      setFieldValue("specimen", acceptanceRequirements.specimen || specimen || "");

                      if(acceptanceRequirements.elements) {
                        const elements = acceptanceRequirements.elements.map((element, index) => {
                          const getElongation = getElongationFunction(value, index);

                          if(getElongation) {
                            element.requirements.elongation = getElongation({
                              tensileMin: acceptanceRequirements.tensMin,
                              specimen,
                              dimensionX: element.dimensionX,
                              dimensionY: element.dimensionY,
                            });
                          }

                          return element;
                        }); 
                        setFieldValue("elements", elements);

                        return;
                      }

                      const newElements = elements.map((el) => {
                        const elementRequirements = {
                          ...pick([
                            "yeldMin",
                            "yeldMax",
                            "tensMin",
                            "tensMax",
                            "reductionOfArea",
                            "elongation",
                            "yeildTensileRatio",
                            "uniformElongationMin"
                          ], acceptanceRequirements),
                        };

                        if(acceptanceRequirements.getElongation) {
                          elementRequirements.elongation = acceptanceRequirements.getElongation({
                            tensileMin: acceptanceRequirements.tensMin,
                            specimen,
                            dimensionX: el.dimensionX,
                            dimensionY: el.dimensionY,
                          });
                        }

                        return {
                          ...el,
                          temperature: acceptanceRequirements.temperature,
                          requirements: elementRequirements
                        };
                      });

                      setFieldValue("elements", newElements);
                      setFieldValue("notes", acceptance.notes || "");
                    }}
                  >
                    <MenuItem key="N/A" value={undefined}>N/A</MenuItem>
                    {filteredAcceptances.map((name) => (
                      <MenuItem key={name} value={name}>{name}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={5}>
                <Grid item xs={4}>
                  <TextField
                    name="notes"
                    label="Notes"
                    multiline
                    rows={5}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={5}>
                <Grid item>
                  <FilesUploader
                    name="files"
                    files={files}
                    onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                    changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={5}>
                <FieldArray name="elements">
                  {({remove, push}) => {
                    const prefilledDiameter = !ISO_TEST_STANDARDS.includes(testStandard) && (materialData.Diameter || EXCEPTIONS[acceptance]?.diameter);

                    return (
                      <>
                        {elements.map((el, index) => {
                          const elementPath = `elements[${index}]`;
                          const customExceptionsAllowed = getCustomExceptionAllowed(materialData, grade, specimen, el);
                          const isHotTensileTest = Number(el.temperature) >= MIN_ELEVATED_TEMPERATURE;

                          return (
                            <React.Fragment key={elementPath}>

                              <Grid item xs={12}>
                                <div className={classes.divider} />
                              </Grid>

                              <Grid item xs={12} container spacing={5} alignItems="flex-end">
                                <Grid item xs={2}>
                                  <SelectField
                                    label="Position"
                                    name={`elements.${index}.position`}
                                  >
                                    {POSITIONS.map((el, index) => (
                                      <MenuItem key={index} value={el}>{el}</MenuItem>)
                                    )}
                                    <MenuItem value={undefined}>N/A</MenuItem>
                                  </SelectField>
                                </Grid>
                                <Grid item xs={2}>
                                  <SelectField
                                    label="Orientation"
                                    name={`elements.${index}.orientation`}
                                    onChange={(value) => {
                                      if(acceptance) return;

                                      if (materialData.InfluencedBy === ELONGATION_SPECIFIERS.ORIENTATION) {
                                        const elongation = materialData.ELONGATION[value] || "";
                                        const reductionOfArea = materialData.ReductionOfArea[value] || "";
                                        const newElements = elements.map((el) => ({
                                          ...el,
                                          orientation: value,
                                          requirements: {...el.requirements, elongation, reductionOfArea}
                                        }));
                                        setFieldValue("elements", newElements);
                                      }

                                      if (materialData.InfluencedBy === ELONGATION_SPECIFIERS.HEAT_TREATED_THICKNESS) {
                                        if (!el.gaugeLength || !el.heatTreatedThickness) return;

                                        const gaugeType = Number(el.gaugeLength) !== 80 ? "Proportional" : "L80";
                                        const elongation = getLimitFromCondition(materialData.ELONGATION[gaugeType][value], el.heatTreatedThickness);
                                        const requirements = {...el.requirements, elongation};
                                        setFieldValue(`elements.${index}.requirements`, requirements);
                                      }

                                      const getElongation = getElongationFunction(acceptance, index);

                                      if (getElongation) {
                                        const elongation = getElongation({
                                          tensileMin: el.requirements.tensMin,
                                          specimen,
                                          dimensionX: el.dimensionX,
                                          dimensionY: el.dimensionY,
                                          elongation: materialData.ELONGATION,
                                          heatTreatedThickness: el.heatTreatedThickness,
                                          orientation: value,
                                          gaugeLength: el.gaugeLength
                                        });
                                        setFieldValue(`elements.${index}.requirements`, {
                                          ...el.requirements,
                                          elongation
                                        });
                                      }
                                    }}
                                  >
                                    {ORIENTATIONS.map((orient, index) => (
                                      <MenuItem key={index} value={orient}>{orient}</MenuItem>
                                    ))}
                                    <MenuItem value={undefined}>N/A</MenuItem>
                                  </SelectField>
                                </Grid>

                                <Grid item xs={2}>
                                  <TextField
                                    label='Specimen ID'
                                    name={`elements.${index}.specimenId`}
                                    required
                                  />
                                </Grid>
                                
                                {customExceptionsAllowed && (
                                  <Grid item>
                                    <CustomException
                                      isHotTensileTest={isHotTensileTest}
                                      requirements={el.requirements}
                                      onChange={(changes) => {
                                        const customRequirements = {...el.requirements, ...changes};
                                        setFieldValue(`elements.${index}.requirements`, customRequirements);
                                      }}
                                    />
                                  </Grid>
                                )}
                                {(elements.length > 1) && (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>

                              <Grid item xs={12} container spacing={5}>
                                <Grid item xs={2}>
                                  <SelectField
                                    label="Sampling Zone"
                                    name={`elements.${index}.zone`}
                                    required
                                  >
                                    {ZONE.map((zone, index) => (
                                      <MenuItem key={`${zone}${index}`} value={zone}>{zone}</MenuItem>
                                    ))}
                                  </SelectField>
                                </Grid>
                                {el.zone === "Weld Metal" && <>
                                  <Grid item xs={2}>
                                    <SelectField
                                      label="Fracture Appearance"
                                      name={`elements.${index}.fracturAppearance`}
                                      required
                                    >
                                      <MenuItem value="Ductile">Ductile</MenuItem>)
                                    </SelectField>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <SelectField
                                      label="Break position"
                                      name={`elements.${index}.breakPosition`}
                                    >
                                      {BREAK_POSITION.map((value, index) => (
                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                      ))}
                                    </SelectField>
                                  </Grid>
                                </>}
                                <Grid item xs={2}>
                                  <TextField
                                    label={(
                                      <Tooltip
                                        placement="top-start"
                                        title={(
                                          <span>For ASTM A182: Maximum section thickness at the time of heat treatment<br />
                                          For EN 10025-2: Final product thickness
                                          </span>
                                        )}
                                      >
                                        <span className={classes.helpIcon}>Thickness&nbsp;<HelpOutlineIcon
                                          fontSize="small" />
                                        </span>
                                      </Tooltip>
                                    )}
                                    name={`elements.${index}.heatTreatedThickness`}
                                    placeholder='0'
                                    type={"number"}
                                    endAdornment='MM'
                                    onChange={(value) => {
                                      if(acceptance) return;

                                      if (materialData.InfluencedBy === STRENGTH_SPECIFIERS.HEAT_TREATED_THICKNESS) {
                                        let elongation = el.requirements.elongation;

                                        if (el.orientation && el.gaugeLength) {
                                          const gaugeType = Number(el.gaugeLength) !== 80 ? "Proportional" : "L80";
                                          elongation = getLimitFromCondition(materialData.ELONGATION[gaugeType][el.orientation], value);
                                        }

                                        setFieldValue(`elements.${index}.requirements`, {
                                          ...el.requirements,
                                          yeldMin: getLimitFromCondition(materialData.YELDmin, value) || el.requirements.yeldMin,
                                          yeldMax: getLimitFromCondition(materialData.YELDmax, value) || el.requirements.yeldMax,
                                          tensMax: getLimitFromCondition(materialData.TENSmax, value) || el.requirements.tensMax,
                                          tensMin: getLimitFromCondition(materialData.TENSmin, value) || el.requirements.tensMin,
                                          elongation
                                        });
                                      }

                                      const getElongation = getElongationFunction(acceptance, index);

                                      if (getElongation) {
                                        const elongation = getElongation({
                                          tensileMin: el.requirements.tensMin,
                                          specimen,
                                          dimensionX: el.dimensionX,
                                          dimensionY: el.dimensionY,
                                          elongation: materialData.ELONGATION,
                                          heatTreatedThickness: value,
                                          orientation: el.orientation,
                                          gaugeLength: el.gaugeLength
                                        });
                                        setFieldValue(`elements.${index}.requirements`, {
                                          ...el.requirements,
                                          elongation
                                        });
                                      }
                                    }}
                                  />
                                </Grid>
                                {materialData.BoltDiameter && (
                                  <Grid item xs={2}>
                                    <SelectField
                                      required
                                      label="Bolt diameter"
                                      name={`elements.${index}.boltDiameter`}
                                      onChange={(value) => {
                                        if(acceptance) return;

                                        const diameterData = materialData.BoltDiameter[value];
                                        const requirements = {
                                          yeldMin: Number(diameterData.YELDmin),
                                          yeldMax: Number(diameterData.YELDmax),
                                          tensMin: Number(diameterData.TENSmin),
                                          tensMax: Number(diameterData.TENSmax),
                                          elongation: Number(diameterData.ELONGATION) || "",
                                          reductionOfArea: Number(diameterData.ReductionOfArea) || ""
                                        };
                                        setFieldValue(`elements.${index}.requirements`, requirements);
                                      }}
                                    >
                                      {keys(materialData.BoltDiameter).map((value) => (
                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                      ))}
                                    </SelectField>
                                  </Grid>
                                )}
                              </Grid>

                              <Grid item xs={12} container spacing={5}>
                                {specimen && (
                                  <React.Fragment>
                                    <Grid item xs={2}>
                                      {(prefilledDiameter && specimen === SPECIMEN_TYPES.ROUND) && (
                                        <SelectField
                                          endAdornment='MM'
                                          required
                                          label={DIMENSIONS_BY_SPECIMEN_TYPE[specimen]?.x || "Dimension X"}
                                          name={`elements.${index}.dimensionX`}
                                          onChange={(value) => {
                                            const getElongation = getElongationFunction(acceptance, index);

                                            if (!getElongation) return;

                                            const elongation = getElongation({
                                              tensileMin: el.requirements.tensMin,
                                              specimen,
                                              dimensionX: value,
                                              dimensionY: el.dimensionY,
                                              elongation: materialData.ELONGATION,
                                              heatTreatedThickness: el.heatTreatedThickness,
                                              orientation: el.orientation,
                                              gaugeLength: el.gaugeLength
                                            });
                                            setFieldValue(`elements.${index}.requirements`, {
                                              ...el.requirements,
                                              elongation
                                            });
                                          }}
                                        >
                                          {prefilledDiameter.map((value) => (
                                            <MenuItem key={value} value={value}>{value}</MenuItem>
                                          ))}
                                        </SelectField>
                                      )}
                                      {(materialData.Width && specimen !== SPECIMEN_TYPES.ROUND) && (
                                        <SelectField
                                          endAdornment='MM'
                                          required
                                          label={DIMENSIONS_BY_SPECIMEN_TYPE[specimen]?.x || "Dimension X"}
                                          name={`elements.${index}.dimensionX`}
                                          onChange={(value) => {
                                            if(materialData.InfluencedBy !== ELONGATION_SPECIFIERS.SPECIMEN_AREA) return;

                                            const specimenArea = Number(elements[index].dimensionY) * Number(value);

                                            setFieldValue(`elements.${index}.requirements`, {
                                              ...el.requirements,
                                              elongation: getLimitFromCondition(materialData.ELONGATION, specimenArea)
                                            });
                                          }}
                                        >
                                          {materialData.Width.map((value) => (
                                            <MenuItem key={value} value={value}>{value}</MenuItem>
                                          ))}
                                        </SelectField>
                                      )}
                                      {((specimen !== SPECIMEN_TYPES.ROUND && !materialData.Width) ||
                                      (specimen === SPECIMEN_TYPES.ROUND && !prefilledDiameter)) && (
                                        <TextField
                                          label={DIMENSIONS_BY_SPECIMEN_TYPE[specimen]?.x || "Dimension X"}
                                          name={`elements.${index}.dimensionX`}
                                          placeholder='0'
                                          type={"number"}
                                          required
                                          endAdornment='MM'
                                          onChange={(value, touched) => {
                                            const getElongation = getElongationFunction(acceptance, index);

                                            if (!getElongation || !touched) return;

                                            const elongation = getElongation({
                                              tensileMin: el.requirements.tensMin,
                                              specimen,
                                              dimensionX: value,
                                              dimensionY: el.dimensionY,
                                              elongation: materialData.ELONGATION,
                                              heatTreatedThickness: el.heatTreatedThickness,
                                              orientation: el.orientation,
                                              gaugeLength: el.gaugeLength
                                            });

                                            setFieldValue(`elements.${index}.requirements`, {
                                              ...el.requirements,
                                              elongation
                                            });
                                          }}
                                        />
                                      )}
                                    </Grid>
                                    {specimen !== SPECIMEN_TYPES.ROUND && (
                                      <Grid item xs={2}>
                                        <TextField
                                          label={DIMENSIONS_BY_SPECIMEN_TYPE[specimen]?.y || "Dimension Y"}
                                          name={`elements.${index}.dimensionY`}
                                          placeholder='0'
                                          type={"number"}
                                          required
                                          endAdornment='MM'
                                          onChange={(value, touched) => {
                                            const getElongation = getElongationFunction(acceptance, index);

                                            if (!touched) return;

                                            if(getElongation) {
                                              const elongation = getElongation({
                                                tensileMin: el.requirements.tensMin,
                                                specimen,
                                                dimensionX: el.dimensionX,
                                                dimensionY: value,
                                                elongation: materialData.ELONGATION,
                                                heatTreatedThickness: el.heatTreatedThickness,
                                                orientation: el.orientation,
                                                gaugeLength: el.gaugeLength
                                              });
                                              setFieldValue(`elements.${index}.requirements`, {
                                                ...el.requirements,
                                                elongation
                                              });
                                            }

                                            if(materialData.InfluencedBy === ELONGATION_SPECIFIERS.SPECIMEN_AREA) {
                                              const specimenArea = Number(elements[index].dimensionX) * Number(value);

                                              setFieldValue(`elements.${index}.requirements`, {
                                                ...el.requirements,
                                                elongation: getLimitFromCondition(materialData.ELONGATION, specimenArea)
                                              });
                                            }
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </React.Fragment>
                                )}
                                <Grid item xs={2}>
                                  {materialData.InfluencedBy === ELONGATION_SPECIFIERS.GAUGE_LENGTH ? (
                                    <SelectField
                                      label="Gauge length"
                                      name={`elements.${index}.gaugeLength`}
                                      onChange={(value) => {
                                        if(acceptance) return;

                                        const requirements = {
                                          ...el.requirements,
                                          elongation: Number(materialData.ELONGATION[value])
                                        };
                                        setFieldValue(`elements.${index}.requirements`, requirements);
                                      }}
                                      endAdornment='MM'
                                    >
                                      {keys(materialData.ELONGATION).map((value) => (
                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                      ))}
                                    </SelectField>
                                  ) : (
                                    <TextField
                                      label="Gauge length"
                                      name={`elements.${index}.gaugeLength`}
                                      onChange={(value) => {
                                        const getElongation = getElongationFunction(acceptance, index);

                                        if(acceptance) return;

                                        if (getElongation) {
                                          const elongation = getElongation({
                                            tensileMin: el.requirements.tensMin,
                                            specimen,
                                            dimensionX: el.dimensionX,
                                            dimensionY: el.dimensionY,
                                            elongation: materialData.ELONGATION,
                                            heatTreatedThickness: el.heatTreatedThickness,
                                            orientation: el.orientation,
                                            gaugeLength: value,
                                          });
                                          setFieldValue(`elements.${index}.requirements`, {
                                            ...el.requirements,
                                            elongation
                                          });
                                        }

                                        if (materialData.InfluencedBy !== ELONGATION_SPECIFIERS.HEAT_TREATED_THICKNESS) return;

                                        if (!el.orientation || !el.heatTreatedThickness) return;

                                        const gaugeType = Number(value) !== 80 ? "Proportional" : "L80";
                                        const elongation = getLimitFromCondition(materialData.ELONGATION[gaugeType][el.orientation], el.heatTreatedThickness);
                                        const requirements = {...el.requirements, elongation};
                                        setFieldValue(`elements.${index}.requirements`, requirements);
                                      }}
                                      placeholder='0'
                                      type={"number"}
                                      endAdornment='MM'
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    label="Test Temperature"
                                    name={`elements.${index}.temperature`}
                                    placeholder='0'
                                    type={"number"}
                                    endAdornment='°C'
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Table className={classNames("styled-table", classes.table)}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Requirements</TableCell>
                                      <TableCell>Yield Strength [MPa]</TableCell>
                                      <TableCell>Tensile Strength [MPa]</TableCell>
                                      <TableCell>Elongation [%]</TableCell>
                                      <TableCell>Reduction of area [%]</TableCell>
                                      {(!!parseFloat(el.requirements.uniformElongationMin) || el.uniformElongation) &&
                                          (<TableCell>Uniform elongation [%]</TableCell>)}

                                      {(!!parseFloat(el.requirements.yeildTensileRatio) || acceptance) &&
                                          (<TableCell>Yield/Tensile ratio</TableCell>)}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>Min</TableCell>
                                      <TableCell>{el.requirements.yeldMin || "-"}</TableCell>
                                      <TableCell>{el.requirements.tensMin || "-"}</TableCell>
                                      <TableCell>{el.requirements.elongation || "-"}</TableCell>
                                      <TableCell>{el.requirements.reductionOfArea || "-"}</TableCell>
                                      {(!!parseFloat(el.requirements.uniformElongationMin) || el.uniformElongation) &&
                                          (<TableCell>{el.requirements.uniformElongationMin}</TableCell>)}

                                      {(!!parseFloat(el.requirements.yeildTensileRatio) || acceptance) &&
                                          (<TableCell>-</TableCell>)}
                                    </TableRow>

                                    <TableRow>
                                      <TableCell></TableCell>
                                      <TableCell>
                                        <EditableCell
                                          editable
                                          value={el.rp}
                                          required={(!isHotTensileTest && el.zone === "Base Metal" && (el.requirements.yeldMin ||
                                            el.requirements.yeldMax))}
                                          setValue={(value) => {
                                            setFieldValue(`elements.${index}.rp`, value);

                                            if(!el.requirements?.retest || !value) return;

                                            checkRetests(index, {...el, rp: value}, remove, push);
                                          }}
                                          type="number"
                                          error={!checkRp(el.requirements, el.rp)}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <EditableCell
                                          editable
                                          value={el.rm}
                                          required={((!isHotTensileTest && el.requirements.tensMin || el.requirements.tensMax) ||
                                            acceptance)}
                                          setValue={(value) => {
                                            setFieldValue(`elements.${index}.rm`, value);

                                            if(!el.requirements.retest || !value) return;

                                            checkRetests(index, {...el, rm: value}, remove, push);
                                          }}
                                          type="number"
                                          error={!checkRm(el.requirements, el.rm)}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <EditableCell
                                          editable
                                          value={el.a}
                                          required={((!isHotTensileTest && !isNil(el.requirements.elongation)))}
                                          setValue={(value) => setFieldValue(`elements.${index}.a`, value)}
                                          type="number"
                                          error={!checkA(el.requirements, el.a)}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <EditableCell
                                          editable
                                          value={el.reductionOfArea}
                                          required={!isHotTensileTest && el.requirements.reductionOfArea}
                                          setValue={(value) => setFieldValue(`elements.${index}.reductionOfArea`, value)}
                                          type="number"
                                          error={!checkReductionOfArea(el.requirements, el.reductionOfArea)}
                                        />
                                      </TableCell>
                                      {(!!parseFloat(el.requirements.uniformElongationMin) || el.uniformElongation) &&
                                          (<TableCell>
                                            <EditableCell
                                              editable
                                              value={el.uniformElongation}
                                              required={!isHotTensileTest && el.requirements.uniformElongationMin}
                                              setValue={(value) => setFieldValue(`elements.${index}.uniformElongation`, value)}
                                              type="number"
                                              error={!checkUniformElongation(el.requirements, el.uniformElongation)}
                                            />
                                          </TableCell>)}
                                      {(!!parseFloat(el.requirements.yeildTensileRatio) || acceptance) &&
                                          (<TableCell>
                                            <EditableCell
                                              value={el.rp && el.rm && formatNumber(el.rp / el.rm)}
                                              type="number"
                                              error={!checkYeildTensileRatio(poItemNumber, el.requirements, el.rp, el.rm)}
                                            />
                                          </TableCell>)}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Max</TableCell>
                                      <TableCell>{el.requirements.yeldMax || "-"}</TableCell>
                                      <TableCell>{el.requirements.tensMax || "-"}</TableCell>
                                      <TableCell>-</TableCell>
                                      <TableCell>-</TableCell>
                                      {(!!parseFloat(el.requirements.uniformElongationMin) || el.uniformElongation) &&
                                          (<TableCell>-</TableCell>)}

                                      {(!!parseFloat(el.requirements.yeildTensileRatio) || acceptance) &&
                                          (<TableCell>{el.requirements.yeildTensileRatio || "-"}</TableCell>)}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Grid>
                            </React.Fragment>
                          );
                        })}
                        <Grid item xs={12} alignContent="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              const element = ELEMENT;

                              if (acceptance) {
                                element.temperature = EXCEPTIONS[acceptance].temperature;
                                element.requirements = omit(["temperature"], EXCEPTIONS[acceptance]);
                              } else {
                                element.requirements = {
                                  yeldMin: Number(materialData.YELDmin),
                                  yeldMax: Number(materialData.YELDmax),
                                  tensMin: Number(materialData.TENSmin),
                                  tensMax: Number(materialData.TENSmax),
                                  elongation: Number(materialData.ELONGATION) || "",
                                  reductionOfArea: Number(materialData.ReductionOfArea) || "",
                                  yeildTensileRatio: Number(materialData.YieldTensileRatio) || ""
                                };
                              }

                              push(element);
                            }}
                          >
                            Add
                          </Button>
                        </Grid>
                      </>
                    );
                  }}
                </FieldArray>
              </Grid>
            </Grid>
            <TestFooter
              onSubmit={onSubmit}
              result={getResult(getIsAcceptable(elements, acceptance, requirements, poItemNumber))}
            />
          </>
        );
      }}
    />
  );
};

export default withStyles(TensileTest, styles);
