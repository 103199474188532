import {observable, action, makeObservable} from "mobx";
import {generateID} from "../../../helpers";

class Notification {
  constructor() {
    makeObservable(this, {
      notifications: observable,
      showError: action,
      showSuccess: action,
      showInfo: action,
      removeNotification: action,
    });
  }
  notifications = {};

  /**
   * @name showErrorNotification
   * @param message {string} message to show in a popup
   * @param button {object} describes an form/icon to prepend in notifications
   * @param callback {function} an action to execute after notification
   */
  showError(message, {button, callback}={}) {
    this.notifications[generateID()] = {type: "error", message, button, callback};
  }

  /**
   * @name showSuccessNotification
   * @param message {string} message to show in a popup
   * @param button {object} describes an form/icon to prepend in notifications
   * @param callback {function} an action to execute after notification
   */
  showSuccess(message, {button, callback}={}){
    this.notifications[generateID()] = {type: "success", message, button, callback};
  }

  /**
   * @name showInfoNotification
   * @param message {string} message to show in a popup
   * @param button {object} describes an form/icon to prepend in notifications
   * @param callback {function} an action to execute after notification
   */
  showInfo(message, {button, callback}={}){
    this.notifications[generateID()] = {type: "info", message, button, callback};
  }

  removeNotification(id) {
    delete this.notifications[id];
  }
}

export default new Notification();
