import {values} from "ramda";
import {CERTIFICATE_VIEWS} from "./CertificatesList/data";

export const PAGE_TABS = {
  ORDER_STATUS: "ORDER_STATUS",
  ORDER_SUMMARY: "ORDER_SUMMARY",
  TEST_SUMMARY: "TEST_SUMMARY",
  CERTIFICATES: "CERTIFICATES",
  CAMPAIGNS: "CAMPAIGNS"
};

export const DEFAULT_VIEW_TAB = values(CERTIFICATE_VIEWS)[0];