export default () => ({
  paper: {
    width: 600,
    padding: 40,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#575757",
    borderRadius: 20,
  },
  heading: {
    fontSize: 32,
    textAlign: "center",
    color: "#575757",
  },
  separationLine: {
    borderBottom: "2px solid #575757",
    width: "100%",
    padding: 0
  },
  submitContainer: {
    position: "relative",
  },
  loader: {
    position: "absolute",
    right: -50,
    top: 0
  }

});
