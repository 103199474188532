import React, {useState} from "react";
import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";
import {MEASUREMENTS_CONFIG} from "@core/components/TestForms/DimensionalTest/data";
import GlobalAcceptanceOnWeight from "./components/GlobalAcceptanceOnWeight";
import GlobalAcceptanceOnLength from "./components/GlobalAcceptanceOnLength";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {keys} from "ramda";

const PLAYGROUND_COMPONENT_BY_PROPERTY = {
  weight: GlobalAcceptanceOnWeight,
  length: GlobalAcceptanceOnLength
};

const GlobalAcceptance = ({globalConfig, qcp}) => {
  const [property, setProperty] = useState(null);

  const PlaygroundComponent = PLAYGROUND_COMPONENT_BY_PROPERTY[property];
  
  return (
    <>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            {keys(globalConfig).map((property) => (
              <TableCell key={property}>
                {MEASUREMENTS_CONFIG[property].title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {keys(globalConfig).map((property) => (
              <TableCell key={property}>
                <DrawerButtonTrigger
                  onClick={() => setProperty(property)}
                  text={globalConfig[property].globalCriteria?.label}
                  label="Try it out"
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      {property && (
        <PlaygroundComponent
          close={() => setProperty(null)}
          qcp={qcp}
          requirement={globalConfig[property] || {}}
        />
      )}
    </>
  );
};

export default GlobalAcceptance;