export default (theme) => ({
  dashboard: {
    padding: theme.spacing(4),
  },
  innerBlock: {
    padding: 25,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,

    "& > div": {
      fontSize: 60,
    },

    "& a": {
      minWidth: 200,
      marginTop: 30
    }
  },
  statistics: {
    marginBottom: 18,
  },
  statisticsHeadline: {
    padding: 25,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    "& a": {
      minWidth: 200,
      marginTop: 30,
    }
  },
  statisticNumber: {
    fontSize: 60,
  },
  dashboardTableTitle: {
    padding: "10px 24px",
    fontSize: 22,
  },
  table: {
    "& thead tr": {
      height: 40
    },
    "& thead tr th": {
      borderTop: "1px solid #e0e0e0",
      borderBottom: "1px solid #eeeeee"
    },
    "& tbody tr": {
      height: 50,
    },
    "& tbody tr td": {
      borderBottom: "none",
    },
  },
  inspectingTest: {
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
});