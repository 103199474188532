import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Box} from "@mui/material";
import MenuItem from "../MenuItem";
import MenuList from "../MenuList";
import Footer from "../Footer";
import useStores from "../../../../../../../../../useStores";
import {COLLAPSED_MENU_WIDTH, EXPANDED_MENU_WIDTH, SETTINGS_ITEM} from "../../../../constants";

const Content = ({expanded, onMouseOver, onMouseLeave}) => {
  const {UserStore} = useStores();

  const [module] = UserStore.user.data.company.modules;

  const canSeeSettings = useMemo(() => {
    return SETTINGS_ITEM.belongsToModules.includes(module.name);
  }, [UserStore.user.data._id, module.name]);

  return (
    <Box
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        overflowY: "auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontWeight: (theme) => theme.typography.fontWeightSemibold,
        fontSize: "0.82rem",
        width: expanded ? EXPANDED_MENU_WIDTH : COLLAPSED_MENU_WIDTH,
        borderRight: (theme) => `1px solid ${theme.palette.secondary.grey}`
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: expanded ? 3 : 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MenuList
          displayTitle={expanded}
        />
        {canSeeSettings && (
          <Box
            sx={{marginTop: 2}}
          >
            <MenuItem
              displayTitle={expanded}
              menuItem={SETTINGS_ITEM}
            />
          </Box>
        )}
      </Box>
      <Footer
        displayTitle={expanded}
      />
    </Box>
  );
};

export default observer(Content);