export default (theme) => ({
  materials: {
    "& h1": {
      marginBottom: 25,
    },

    "& h4": {
      paddingTop: 15,
      marginBottom: 0,
      color: "#616161",
    },

    "& span": {
      fontWeight: theme.typography.fontWeightMedium,
      color: "#616161",
      paddingTop: 5,
      display: "block",
    },
  },
  notes: {
    marginTop: 10,
    fontWeight: theme.typography.fontWeightMedium,
    color: "#616161",
  },
});

