import {action, makeObservable, observable} from "mobx";
import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class InstrumentStore {
  constructor() {
    makeObservable(this, {
      instruments: observable,
      instrument: observable,
      findAll: action,
      create: action,
      update: action,
      delete: action,
    });
  }
  instruments = {
    data: [],
    offset: 0,
    limit: 10,
    total: 0,
    isLoaded: false,
  };

  instrument = {
    data: {},
    isLoaded: false,
  };

  async findAll(params) {
    this.instruments.isLoaded = false;

    const res = await axios.get(ROUTES.INSTRUMENT[ACTIONS.ALL], {
      withCredentials: true
    });

    this.instruments = {
      data: res.data.items,
      limit: params.limit,
      offset: params.offset,
      total: res.data.total,
      isLoaded: true,
    };
  }

  async create(data) {
    if (!data.calibrationExpDate) {
      delete data.calibrationExpDate;
    }

    const res = await axios.post(ROUTES.INSTRUMENT[ACTIONS.CREATE], data, {
      withCredentials: true,
      successMessage: "Successfully created",
    });

    this.instruments = {
      ...this.instruments,
      isLoaded: true,
      data: [res.data, ...this.instruments.data],
      total: this.instruments.total + 1,
    };

  }

  async update(data, instrumentId) {
    if (!data.calibrationExpDate) {
      delete data.calibrationExpDate;
    }

    const res = await axios.patch(ROUTES.INSTRUMENT[ACTIONS.UPDATE](instrumentId), data);

    const updatedInstruments = this.instruments.data.map((el) => {
      if (el._id === res.data._id) return res.data;

      return el;
    });

    this.instruments = {
      ...this.instruments,
      isLoaded: true,
      data: updatedInstruments
    };
  }

  async delete(ids) {
    this.instrument.isLoaded = false;
    await axios.delete(ROUTES.INSTRUMENT[ACTIONS.REMOVE], {
      params: {ids},
      withCredentials: true,
      successMessage: "Instrument removed",
    });

    const updatedInstruments = this.instruments.data.filter((el) => !ids.includes(el._id));
    this.instruments = {
      isLoaded: true,
      data: updatedInstruments
    };
  }
}

export default new InstrumentStore();
