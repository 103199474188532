import classNames from "classnames";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./styles";
import useStores from "../../../../../useStores";

const TimelineSideBar = observer(({children, classes}) => {
  const {TimelineStore}  = useStores();
  
  const sideBarRef = useRef();
  const [transitionEnd, setTransitionEnd] = useState(true);
  const timelineOpen = TimelineStore.open;

  const onSidebarTransitionRun = useCallback(() => {
    setTransitionEnd(false);
  }, []);

  useEffect(() => {
    const instance = sideBarRef.current;
    instance.addEventListener("transitionrun", onSidebarTransitionRun, false);

    return () => {
      instance.removeEventListener("transitionrun", onSidebarTransitionRun, false);
    };
  }, []);

  return (
    <div
      ref={sideBarRef}
      onTransitionEnd={() => {
        if (!timelineOpen) {
          setTransitionEnd(true);
        }
      }}
      className={classNames({
        [classes.sideBar]: true,
        [classes.sideBarOpen]: timelineOpen,
        [classes.sideBarClosedTransitionEnd]: transitionEnd && !timelineOpen
      })}
    >
      {children}
    </div>
  );
});

export default withStyles(TimelineSideBar, styles);