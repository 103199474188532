import React from "react";
import {Formik, FieldArray} from "formik";
import {Table, TableHead, TableRow, TableBody, TableCell, IconButton, Grid, Button} from "@mui/material";
import EditableCell from "@core/components/EditableCell";
import {IoMdCloseCircleOutline} from "react-icons/io";
import * as yup from "yup";
import {MdAdd} from "react-icons/md";

const CERTIFICATE = {
  heat: "",
  internalWorkOrder: "",
  internalItemNumber: "",
  properties: {
    lotId: "",
  },
};

const validationSchema = yup.object().shape({
  certificates: yup.array().of(yup.object().shape({
    heat: yup.string().required("This field is required!"),
    internalWorkOrder: yup.string(),
    internalItemNumber: yup.string(),
    properties: yup.object().shape({
      lotId: yup.string().when("productType", {
        is: (productType) => {
          return productType?.name && (productType.name.toLowerCase() === "heat");
        },
        then: yup.string(),
        otherwise: yup.string().required("This field is required!"),
      }),
    })
  })).required(),
});

const DefiningHeatsAndLotIds = ({setCertificates, formRef}) => {
  return (
    <Formik
      innerRef={formRef}
      validateOnMount
      initialValues={{certificates: [CERTIFICATE]}}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={({certificates}) => setCertificates(certificates)}
    >
      {(props) => (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Heat</TableCell>
                  <TableCell>Lot ID</TableCell>
                  <TableCell>Work Order</TableCell>
                  <TableCell>Work Order Item</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <FieldArray name="certificates">
                  {({remove}) => props.values.certificates.map((certificate, index) => {
                    const errors = props.errors.certificates && props.errors.certificates[index] ? props.errors.certificates[index] : {};
                    const touched = props.touched.certificates && props.touched.certificates[index] ? props.touched.certificates[index] : {};

                    return (
                      <TableRow hover key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <EditableCell
                            editable
                            value={certificate.heat}
                            required
                            setValue={(value) => {
                              props.setFieldValue(`certificates.${index}.heat`, value);
                              props.setFieldTouched(`certificates.${index}.heat`, true);
                            }}
                            error={errors.heat && touched.heat}
                            errorMessage={errors.heat}
                          />
                        </TableCell>
                        <TableCell>
                          <EditableCell
                            editable
                            value={certificate.properties.lotId}
                            required
                            setValue={(value) => {
                              props.setFieldValue(`certificates.${index}.properties.lotId`, value);
                              props.setFieldTouched(`certificates.${index}.properties.lotId`, true);
                            }}
                            error={errors.properties?.lotId && touched.properties?.lotId}
                            errorMessage={errors.properties?.lotId}
                          />
                        </TableCell>
                        <TableCell>
                          <EditableCell
                            editable
                            value={certificate.internalWorkOrder}
                            setValue={(value) => {
                              props.setFieldValue(`certificates.${index}.internalWorkOrder`, value);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <EditableCell
                            editable
                            value={certificate.internalItemNumber}
                            setValue={(value) => {
                              props.setFieldValue(`certificates.${index}.internalItemNumber`, value);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => remove(index)}
                          >
                            <IoMdCloseCircleOutline />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </FieldArray>
                <TableRow colSpan={5}>
                  <TableCell>
                    <Button
                      onClick={() => props.setFieldValue("certificates", [...props.values.certificates, CERTIFICATE])}
                    >
                      <MdAdd size={80} />Add new
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={!props.isValid}
                onClick={props.handleSubmit}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default DefiningHeatsAndLotIds;