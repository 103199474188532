import React from "react";
import DrawerHeader from "@core/components/DrawerHeader";
import ResizableDrawer from "@core/components/ResizableDrawer";
import {Box, TableBody, Table, TableHead, TableRow, TableCell, useTheme} from "@mui/material";

const Indication = ({open, close, indication}) => {
  const theme = useTheme();

  const results = indication ? indication?.results.values.reduce((acc, {value}) => acc.concat(value), []) : [];

  return (
    <ResizableDrawer
      open={open}
      close={close}
      keepMounted
    >
      <DrawerHeader
        close={close}
        text={`Weld ID: ${indication?.specimen}`}
      />
      <Box
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>#</TableCell>
              <TableCell rowSpan={2}>Weld ID</TableCell>
              <TableCell rowSpan={2}>Type</TableCell>
              <TableCell rowSpan={2}>Is Defect</TableCell>
              <TableCell rowSpan={2}>Weld Side</TableCell>
              <TableCell colSpan={4}>Measurement</TableCell>
              <TableCell rowSpan={2}>Zones</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell>Length</TableCell>
              <TableCell>Depth</TableCell>
              <TableCell>Height</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{indication.specimen}</TableCell>
                <TableCell>{result.IndicationType}</TableCell>
                <TableCell>{result.IsDefect ? "Yes" : "No"}</TableCell>
                <TableCell>{result.WeldSide}</TableCell>
                <TableCell>{result.Measurement.Start + result.Measurement.Units}</TableCell>
                <TableCell>{result.Measurement.Length + result.Measurement.Units}</TableCell>
                <TableCell>{result.Measurement.Depth + result.Measurement.Units}</TableCell>
                <TableCell>{result.Measurement.Height + result.Measurement.Units}</TableCell>
                <TableCell>{result.Zones}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </ResizableDrawer>
        
  );
};

export default Indication;
    