import React, {useCallback, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {CUSTOM_TEST_TYPES, TYPES} from "@core/constants/test";
import {TableCell, TableRow} from "@mui/material";
import {indexBy, prop, uniq, uniqBy} from "ramda";
import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";
import {getMaxActivityValue, getWeldIsRepaired} from "../../../../helpers";
import useStores from "../../../../../../../../useStores";
import {TITLE_BY_COLUMN} from "../../constants";

const NdtDataAnalysisRow = ({stalkNumber, stalk, setWelds, setTitle}) => {
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;
  const asBuildRecordTestByStalkNumber = CampaignStore.asBuildRecordTestByStalkNumber;

  const weldsByWeldNumber = useMemo(() => indexBy(prop("weldNumber"), campaign.welds), [campaign.welds]);
  
  const asBuildRecordTest = useMemo(() => asBuildRecordTestByStalkNumber[stalkNumber], [stalkNumber, campaign.tests]);

  const ilineWeldAdvisorTests = useMemo(() => campaign.tests.filter((test) =>
    test.type === TYPES.CUSTOM &&
    test.displayName === CUSTOM_TEST_TYPES.AUTOMATED_ULTRASONIC_TESTING
  ), [stalkNumber, campaign.tests]);

  const statedLength = useMemo(() => {
    if(!asBuildRecordTest) return null;

    return getMaxActivityValue(asBuildRecordTest.properties.activities, "Total Stated");
  }, [asBuildRecordTest]);

  const totalActual = useMemo(() => {
    if(!asBuildRecordTest) return null;

    return getMaxActivityValue(asBuildRecordTest.properties.activities, "Total Actual");
  }, [asBuildRecordTest]);

  const joints = useMemo(() =>
    uniq(stalk.reduce((acc, weld) => acc.concat([weld.jointNumberA, weld.jointNumberB]), []))
  , [stalkNumber]);

  const welds = useMemo(() => stalk.map((weld) => weld.weldNumber), [stalkNumber]);

  const repairedWelds = useMemo(() => stalk.filter((weld) => getWeldIsRepaired(weld.weldNumber)), [stalkNumber]);

  const indications = useMemo(() => {
    return ilineWeldAdvisorTests.reduce((acc, test) => {
      const indications = test.properties.activities.filter((activity) => activity.description === "Indications" && welds.includes(activity.specimen));

      return acc.concat(indications);
    }, []);
  }, [ilineWeldAdvisorTests, welds]);

  const indicationWelds = useMemo(() => {
    const specimens = indications.reduce((acc, indication) => {
      if(indication.results.indicationCount) acc.push(indication.specimen);

      return acc;
    }, []);

    const welds = specimens.map((specimen) => weldsByWeldNumber[specimen] || {weldNumber: specimen, _id: specimen});

    return uniqBy(prop("weldNumber"), welds);
  }, [indications, weldsByWeldNumber]);

  const defectWelds = useMemo(() => {
    const specimens = indications.reduce((acc, indication) => {
      const defects = indication.results.values.some(({value}) => value.some(({IsDefect}) => IsDefect));

      if(defects) acc.push(indication.specimen);

      return acc;
    }, []);

    const welds = specimens.map((specimen) => weldsByWeldNumber[specimen] || {weldNumber: specimen, _id: specimen});

    return uniqBy(prop("weldNumber"), welds);
  }, [indications, weldsByWeldNumber]);

  const onClick = useCallback((title, welds) => {
    setWelds(welds);
    setTitle(`Stalk ${stalkNumber}: ${title}`);
  }, [stalkNumber]);

  return (
    <TableRow>
      <TableCell>{stalkNumber}</TableCell>
      <TableCell>{statedLength ? `${statedLength}m` : "-"}</TableCell>
      <TableCell>{totalActual ? `${totalActual}m` : "-"}</TableCell>
      <TableCell>{joints.length}</TableCell>
      <TableCell>{stalk.length}</TableCell>
      <TableCell>
        {repairedWelds.length ? (
          <DrawerButtonTrigger
            onClick={() => onClick(TITLE_BY_COLUMN.REPAIRED_WELDS, repairedWelds)}
            text={repairedWelds.length}
          />
        ) : repairedWelds.length}
      </TableCell>
      <TableCell>
        {defectWelds.length ? (
          <DrawerButtonTrigger
            onClick={() => onClick(TITLE_BY_COLUMN.INDICATIONS, defectWelds)}
            text={defectWelds.length}
          />
        ) : defectWelds.length}
      </TableCell>
      <TableCell>
        {indicationWelds.length ? (
          <DrawerButtonTrigger
            onClick={() => onClick(TITLE_BY_COLUMN.NDT_DEFECTS, indicationWelds)}
            text={indicationWelds.length}
          />
        ) : indicationWelds.length}
      </TableCell>
    </TableRow>
  );
};

export default observer(NdtDataAnalysisRow);