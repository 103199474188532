import React, {useRef, useState, useMemo} from "react";
import {map, prop, uniq, flatten} from "ramda";
import {
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  TableCell,
} from "@mui/material";
import {sortStrings} from "@core/helpers";
import {withStyles} from "tss-react/mui";
import {FilterList, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {ORDERS} from "@core/constants/sort";
import styles from "./styles";

const NA = "";

const FilterableSortableHeaderCell = ({classes, children, setOrder, order, setOrderBy, orderBy, filter, setFilter, id, elements = [], cellsWithNAValue = [], ...props}) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const anchorEl = useRef();

  const values = useMemo(() => uniq(flatten(map(prop(id), elements).filter((option) => !!option))), [elements]);
  const availableOptions = props.options || values;
  const options = cellsWithNAValue.includes(id) ? [NA, ...availableOptions] : availableOptions;
  const sortedOptions = sortStrings(ORDERS.ASC, options);

  const selectedOptions = filter[id] || [];

  const onOptionClick = (option) => {
    let values = [];
    
    if(selectedOptions.includes(option)) {
      values = selectedOptions.filter((op) => op !== option);
    } else {
      values = [...selectedOptions, option];
    }
    
    setFilter({...filter, [id]: values});
  };

  return (
    <TableCell>
      <div className={classes.titleContainer} ref={anchorEl}>
        <IconButton
          color={orderBy === id && order === ORDERS.ASC ? "primary" : "default"}
          className={classes.upArrow}
          onClick={() => {
            setOrder(ORDERS.ASC);
            setOrderBy(id);
          }}
        >
          <KeyboardArrowUp />
        </IconButton>
        <IconButton
          color={orderBy === id && order === ORDERS.DESC ? "primary" : "default"}
          className={classes.downArrow}
          onClick={() => {
            setOrder(ORDERS.DESC);
            setOrderBy(id);
          }}
        >
          <KeyboardArrowDown />
        </IconButton>
        <span className={classes.title}>{children}</span>
        {!!options.length && (
          <IconButton
            color={selectedOptions.length ? "primary" : "default"}
            onClick={() => setFilterOpen(true)}
          >
            <FilterList className={classes.filterIcon} />
          </IconButton>
        )}
      </div>
      <Popover
        anchorEl={anchorEl.current}
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.filterPopup}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {children}
            </Grid>
            <Grid item>
              <FilterList className={classes.filterIcon} />
            </Grid>
            <Grid item xs={12}>
              <List
                component="div"
                disablePadding
              >
                {sortedOptions.map((option) => (
                  <ListItem
                    button
                    key={option}
                    onClick={() => onOptionClick(option)}
                    classes={{root: classes.listItemCheckbox}}
                  >
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      value={option}
                      color="primary"
                    />
                    <ListItemText
                      primary={option || "N/A"}
                      classes={{root: classes.listItemTitle}}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </TableCell>
  );
};

export default withStyles(FilterableSortableHeaderCell, styles);