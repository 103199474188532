import React from "react";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const Line = ({classes, allNumber, item,  setActiveItem}) => {
  const widthInPercents = item.number / allNumber * 100;

  return (
    <span
      className={classes.block}
      style={{
        width: `${widthInPercents}%`,
        backgroundColor: item.color
      }}
      onMouseOver={() => setActiveItem(item)}
      onMouseLeave={() => setActiveItem(null)}
    />
  );
};

export default withStyles(Line, styles);