export default () => ({
  table: {
    "& th, td": {
      fontSize: 14,
      color: "#575757",
      whiteSpace: "nowrap",
    },
    "& th": {
      border: "1px solid #e5e5e5",
      borderBottom: "none"
    },
    tableLayout: "fixed",
  },
  testsList: {
    "& td": {
      verticalAlign: "unset",
    }
  }
});