export default () => ({
  container: {
    zIndex: 1000,
    overflowX: "hidden",
    overflowY: "auto",
  },
  content: {
    padding: "0 20px 10px 20px",
    overflowY: "scroll",
    overflowX: "hidden",
    "& h3": {
      margin: "20px 0 10px 0",
    },
  },
});