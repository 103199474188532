import React from "react";
import {SUB_NAVIGATION_BY_TAB} from "../../constants";
import CoatingDefects from "./components/CoatingDefects";
import FieldJointCoating from "./components/FieldJointCoating";

const COMPONENT_BY_TAB = {
  [SUB_NAVIGATION_BY_TAB.COATING.COATING_DEFECTS]: CoatingDefects,
  [SUB_NAVIGATION_BY_TAB.COATING.FIELD_JOINT_COATING]: FieldJointCoating,
};

const Coating = ({activeSubTab, tests, setFilteredTestIds, onTestClick}) => {

  const Component = COMPONENT_BY_TAB[activeSubTab];

  if(!activeSubTab) return null;

  return (
    <Component
      tests={tests}
      setFilteredTestIds={setFilteredTestIds}
      onTestClick={onTestClick}
    />
  );
};

export default Coating;