import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import Form from "../CreateNew";
import useStores from "../../useStores";

const SpecificationEdit = ({history, match}) => {
  const {SpecificationStore} = useStores();

  useEffect(() => {
    SpecificationStore.getAll();
  }, []);

  const specification = SpecificationStore.specifications.data.find(({_id}) => _id === match.params.id);

  return (
    <Form
      history={history}
      specification={specification}
    />
  );
};

export default observer(SpecificationEdit);