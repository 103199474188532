import React, {Component} from "react";
import {Grid, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

class CorrosionTest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: props.test.properties.table
    };
  }

  handleChange = (test) => {
    const {value} = test.target;
    const {table} = this.props.test.properties;

    const filteredData = value === "all" ? table : table.filter((D) => D.type === value);
    this.setState({value,  tableData: filteredData});
  };

  render() {
    const {test, classes} = this.props;

    return (<>
      <Grid container>
        <Grid item xs={8} container>
          <div className={classes.additional}>
            <div className={classes.item}>
              <h4 className={classes.name}>Simple ID:</h4>
              <span className={classes.value}>{test._id}</span>
            </div>
            <div className={classes.item}>
              <h4 className={classes.name}>Test norms:</h4>
              <span className={classes.value}>{test.properties.norm}</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Table>
            <TableBody>
              {(test.company?.name || test.properties.client) && (
                <TableRow>
                  <TableCell>Client:</TableCell>
                  <TableCell>{test.company?.name || test.properties.client}</TableCell>
                </TableRow>
              )}
              {(test.assignee?.name || test.properties.laboratory) && (
                <TableRow>
                  <TableCell>Lab:</TableCell>
                  <TableCell>{test.assignee.name || test.properties.laboratory}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Dimensions</TableCell>
                <TableCell>{`${test.properties.diameter || 0} X ${test.properties.weight || 0} X ${test.properties.size || 0} MM`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Exposed surface:</TableCell>
                <TableCell>{test.properties.exposedSurface}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Simple position</TableCell>
                <TableCell>{test.properties.position}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Specimen preparation</TableCell>
                <TableCell>{test.properties.preparation}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Test reactant</TableCell>
                <TableCell>{test.properties.testReactant}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Test temperature</TableCell>
                <TableCell>{test.properties.testTemperature} +- 1% °C</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Acceptance tolerance</TableCell>
                <TableCell>{test.properties.acceptanceTolerance}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Testing time</TableCell>
                <TableCell>{test.properties.testingTime} h</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Initial weight</TableCell>
                <TableCell>{test.properties.initialWeight} g</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Final weight</TableCell>
                <TableCell>{test.properties.finalWeight} g</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Corrosion Rate</TableCell>
                <TableCell>{test.properties.corrosionRate || test.properties.corrosionSpeed || " - "} g/m<sup>2</sup></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mass loss #1</TableCell>
                <TableCell>{test.properties.massLoss || test.properties.massLoss1 || " - "} g</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Observation #1</TableCell>
                <TableCell>{test.properties.observation || test.properties.observation1 || " - "}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

      </Grid>
    </>);
  }
}

export default withStyles(CorrosionTest, styles);
