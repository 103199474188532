import React, {useState} from "react";
import {withStyles} from "tss-react/mui";
import {Input} from "@core/components/Form";
import Playground from "@core/components/QcpSideBar/components/Playground";
import testsConfig from "@core/configs/test";
import {TYPES} from "@core/constants/test";
import {Grid} from "@mui/material";
import styles from "./styles";

const GlobalAcceptanceOnWeight = ({classes, qcp, requirement, close}) => {
  const [nominalValue, setNominalValue] = useState("");
  
  return (
    <Playground
      header={`${qcp} : ${testsConfig[TYPES.DIMENSIONAL].title}`}
      requirement={requirement.globalCriteria.label}
      element={{
        value: "Weight",
      }}
      open
      onClose={close}
      isValid={eval(requirement.globalCriteria.value)}
      nominal={nominalValue}
    >
      <Grid container>
        <Grid item xs={6}>
          <Input
            type="number"
            className={classes.input}
            label="Nominal Weight"
            value={nominalValue}
            onChange={(event) => setNominalValue(event.target.value)}
            required
            endAdornment="kg"
          />
        </Grid>
      </Grid>
    </Playground>
  );
};

export default withStyles(GlobalAcceptanceOnWeight, styles);