import React, {useState} from "react";
import {Box, Divider} from "@mui/material";
import ExpandMore from "@core/components/ExpandMore";
import Account from "./components/Account";
import CollapsibleSection from "./components/CollapsibleSection";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

const Footer = ({displayTitle}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      onMouseOver={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <Divider />
      <Box
        sx={{
          padding: displayTitle ? 2 : 1,
          paddingTop: 0.5,
          paddingBottom: 1.5,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <ExpandMore
          expand={expanded}
        >
          <IconistsIcon width="24px" icon={ICONISTS_ICONS.chevronLargeTop} color="#AFAFAF"/>
        </ExpandMore>
        {displayTitle && (
          <CollapsibleSection
            expanded={expanded}
          />
        )}
        <Account
          displayTitle={displayTitle}
        />
      </Box>
    </Box>
  );
};

export default Footer;