import React, {useState, useMemo, useCallback, useEffect} from "react";
import {Button, Table, TableBody, TableCell, TableHead, TableRow, useTheme} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {isNil, groupBy, partition} from "ramda";
import classNames from "classnames";
import moment from "moment";
import TableSkeleton from "@core/components/TableSkeleton";
import DrawerHeader from "@core/components/DrawerHeader";
import ResizableDrawer from "@core/components/ResizableDrawer";
import SortableCell from "../../../../SortableCell";
import DetailedSpecimen from "../DetailedSpecimen";
import ResultCell from "../ResultCell";
import {ORDER_BY} from "../../constants";
import {ORDERS} from "@core/constants/sort";
import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import {capitalizeFirstLetter, getMean, sort} from "@core/helpers";
import styles from "./styles";
import {TEST_RESULTS} from "@core/constants/testResults";
import TableFooter from "@core/components/TableFooter";

const MIN_RESULTS_FOR_COMPACT_VIEW = 5;
const DetailedActivity = ({classes, open,  close, activity, activities, disabled, updateTest, test}) => {
  const [orderBy, setOrderBy] = useState(ORDER_BY.SPECIMEN);
  const [order, setOrder] = useState(ORDERS.ASC);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [selectedSpecimen, setSelectedSpecimen] = useState(null);
  const [specimens, setSpecimens] = useState([]);

  const theme = useTheme();

  const withAcceptance = useMemo(() => specimens.some((element) => element.acceptanceCriteria), [specimens]);

  const specimensPaginated = useMemo(() => {
    const [notAcceptable, rest] = partition((element) => element.results.result === TEST_RESULTS.NOT_ACCEPTABLE, specimens);
    const ordered = notAcceptable.concat(rest);

    return ordered.slice(offset, offset + limit);
  }, [specimens, offset, limit]);

  useEffect(() => {
    if(!activity) return;

    const groupedByActivity = groupBy((element) => element.activity || element.description, activities);

    const specimens = groupedByActivity[activity];
    setSpecimens(sort(order, orderBy, specimens));
  }, [activity]);
  
  useEffect(() => {
    if(open) return;

    setSpecimens([]);
  }, [open]);

  useEffect(() => {
    setSpecimens(sort(order, orderBy, specimens));
  }, [order, orderBy]);

  const [element = {}] = specimens;

  const isCompactView = useMemo(() => {
    if(!activity) return [];

    return specimens.some((activity) => activity.results.values.length >= MIN_RESULTS_FOR_COMPACT_VIEW && isNil(activity.results.indicationCount));
  }, [specimens]);

  const onSpecimen = useCallback((element) => {
    if(!isCompactView) return;

    setSelectedSpecimen(element);
  }, [isCompactView]);

  const onClose = useCallback(() => {
    close();
    setOrderBy(ORDER_BY.SPECIMEN);
    setOrder(ORDERS.ASC);
    setOffset(0);
  });

  return (
    <>
      <ResizableDrawer
        open={open}
        close={onClose}
        keepMounted
      >
        <DrawerHeader
          close={onClose}
          text={element?.activity ? `${activity}: ${element?.description}` : element?.description}
        />
        <div className={classes.content}>
          {element?.parameters && (
            <>
              <h3>Parameters</h3>
              <Table className={classNames("styled-table", classes.parametersTable)}>
                <TableHead>
                  <TableRow>
                    {Object.keys(element.parameters).map((parameter) => (
                      <TableCell key={parameter}>{capitalizeFirstLetter(parameter)}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(element.parameters).map((value) => (
                    <TableCell key={value}>{value}</TableCell>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <SortableCell
                  id={ORDER_BY.SPECIMEN}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  order={order}
                  orderBy={orderBy}
                >
                  {test.specimenLabel || "Specimen ID"}
                </SortableCell>
                {isCompactView ? (
                  <>
                    <TableCell>Results</TableCell>
                    <TableCell>Min</TableCell>
                    <TableCell>Max</TableCell>
                    <TableCell>Range</TableCell>
                    <TableCell>Average</TableCell>
                  </>
                ) : (
                  <TableCell>Result</TableCell>
                )}
                {withAcceptance && (
                  <TableCell>Acceptance criteria</TableCell>
                )}
                <SortableCell
                  id={ORDER_BY.DATE}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  order={order}
                  orderBy={orderBy}
                >
                Date
                </SortableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {specimensPaginated.map((element, index) => {
                const values = element.results.values.map(({value}) => value);
                const min = parseFloat(Math.min(...values).toFixed(3));
                const max = parseFloat(Math.max(...values).toFixed(3));
                const range = parseFloat((max - min).toFixed(3));
                const average = parseFloat(getMean(values).toFixed(3));

                const isNotANumber = isNaN(min) || isNaN(max);

                const isMinValid = !element.acceptanceCriteria || element.acceptanceCriteria.every(({value}) => !value?.min || min >= value.min);
                const isMaxValid = !element.acceptanceCriteria || element.acceptanceCriteria.every(({value}) => !value?.max || max <= value.max);

                const date = moment(element.date);

                return(
                  <TableRow key={element.specimen + index}>
                    <TableCell>{element.specimen + (element.specimenSuffix || "")}</TableCell>
                    {isCompactView ? (
                      <>
                        <TableCell>
                          <Button
                            onClick={() => onSpecimen(element)}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell
                          sx={{color: isMinValid && !isNotANumber ? theme.palette.primary.main : theme.palette.error.main}}
                        >
                          {isNotANumber ? "-" : min}
                        </TableCell>
                        <TableCell
                          sx={{color: isMaxValid && !isNotANumber ? theme.palette.primary.main : theme.palette.error.main}}
                        >
                          {isNotANumber ? "-" : max}
                        </TableCell>
                        <TableCell
                          sx={{color: !isNotANumber ? theme.palette.primary.main : theme.palette.error.main}}
                        >
                          {isNotANumber ? "-" : range}
                        </TableCell>
                        <TableCell
                          sx={{color: !isNotANumber ? theme.palette.primary.main : theme.palette.error.main}}
                        >
                          {isNotANumber ? "-" : average}
                        </TableCell>
                      </>
                    ) : (
                      <ResultCell
                        element={element}
                        disabled={disabled || !isNil(element.results.indicationCount)}
                        onSave={(results) => updateTest(results, element.id)}
                        activity={activities[element.id]}
                      />
                    )}
                    {withAcceptance && (
                      <TableCell>
                        {element.acceptanceCriteria ? element.acceptanceCriteria.map((ac) => ac.label).join(" ,") : "-"}
                      </TableCell>
                    )}
                    <TableCell>{date.isValid() ? date.format(DATE_TIME_FORMAT) : "-"}</TableCell>
                  </TableRow>
                );
              })}
              {!specimens.length && (
                <TableSkeleton columns={isCompactView ? 8 : 4} />
              )}
            </TableBody>
            <TableFooter
              isLoaded
              items={specimensPaginated}
              total={specimens.length}
              limit={limit}
              offset={offset}
              onOffsetChange={setOffset}
              onLimitChange={setLimit}
            />
          </Table>
        </div>
      </ResizableDrawer>
      <DetailedSpecimen
        open={!!selectedSpecimen}
        close={() => setSelectedSpecimen(null)}
        element={selectedSpecimen}
        disabled={disabled}
        onSave={(results) => updateTest(results, selectedSpecimen.id)}
        activity={activities[element.id]}
        test={test}
      />
    </>
  );
};

export default withStyles(DetailedActivity, styles);