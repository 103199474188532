import React, {useEffect, useMemo, useRef, useState} from "react";
import {Formik} from "formik";
import {observer} from "mobx-react";
import {Button, Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import * as yup from "yup";
import draftToHtml from "draftjs-to-html";
import {omit} from "ramda";
import {stateFromHTML} from "draft-js-import-html";
import {useParams} from "react-router-dom";
import {convertToRaw, EditorState} from "draft-js";
import WS from "@core/api/socketConnection";
import {STATUSES} from "@core/constants/test";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import {TestService} from "@core/services";
import {FilesUploader} from "@core/components/Uploaders";
import HtmlEditor from "@core/components/HtmlEditor";
import styles from "./styles";
import useStores from "../../../useStores";

const validationSchema = yup.object().shape({
  notes: yup.object().test("notes", "This field is required!", function (value) {
    return this.parent.status !== WITNESS_STATUSES.NOT_ACCEPTED || value.getCurrentContent().getPlainText("\u0001");
  }),
  status: yup.number().min(WITNESS_STATUSES.ACCEPTED).required(),
  files: yup.array().of(yup.string())
});

const WitnessAttest = ({classes, test, allowToSign, onSave}) => {
  const [open, setOpen] = useState(false);
  const {TestStore, UserStore, NotificationStore, SigningStore} = useStores();

  const companyId = UserStore.user.data.company._id;
  const witness = test.witnesses.find((witness) => witness.company._id === companyId) || {};

  const {id: testId} = useParams();
  const submittedData = useRef();
  const [triggerReinitialize, setTriggerReinitialize] = useState({});

  useEffect(() => {
    if(!testId) return;

    WS.listen("transaction:test:witness", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      TestStore.getTestById(testId);

      NotificationStore.showSuccess("Successfully witnessed!");
    });

  }, []);

  const onSubmit = async (values) => {
    const data = omit(["notes"], values);

    if(values.notes.getCurrentContent().getPlainText("\u0001")) {
      data.notes = draftToHtml(convertToRaw(values.notes.getCurrentContent()));
    }

    await TestStore.witnessReview(test._id, data, witness.company._id);
    NotificationStore.showInfo("Successfully saved!");

    if(onSave) onSave(data);

    setOpen(false);
    submittedData.current = data;
    setTriggerReinitialize({});
  };

  const onSign = async (values) => {
    await onSubmit(values);
    const witnesses = test.witnesses.map((witness) => witness.company._id === companyId ? {...witness, status: values.status} : witness);
    const testToAttest = {...test, witnesses};
    await TestService.attestTests([testToAttest], companyId);
    setOpen(false);
  };

  const initialValues = useMemo(() => {
    if (submittedData.current) {
      const formData = {
        ...submittedData.current,
        notes: EditorState.createWithContent(stateFromHTML(submittedData.current.notes || ""))
      };
      submittedData.current = undefined;

      return formData;
    }

    return {
      notes: witness.notes ? EditorState.createWithContent(stateFromHTML(witness.notes)) : EditorState.createEmpty(),
      status: witness.status || WITNESS_STATUSES.UNSET,
      files: witness.files || []
    };
  }, [witness.notes, witness.status, witness.files, triggerReinitialize]);

  const canBeEdited = test.status === STATUSES.INSPECTING ||
    test.status === STATUSES.INSPECTED && witness.status === WITNESS_STATUSES.NOT_ACCEPTED;

  if (!canBeEdited) return null;

  return (
    <>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        {test.status === STATUSES.INSPECTED ? "Update review" : "Review"}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Test attestation</DialogTitle>
        <DialogContent>
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(props) => (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button
                    className={classes.notAcceptedButton}
                    size="large"
                    variant="contained"
                    color={props.values.status === WITNESS_STATUSES.NOT_ACCEPTED ? "primary" : "inherit"}
                    onClick={() => props.setFieldValue("status", WITNESS_STATUSES.NOT_ACCEPTED)}
                  >
                    Not accepted
                  </Button>
                  <Button
                    className={classes.acceptedButton}
                    size="large"
                    variant="contained"
                    color={props.values.status === WITNESS_STATUSES.ACCEPTED ? "primary" : "inherit"}
                    onClick={() => props.setFieldValue("status", WITNESS_STATUSES.ACCEPTED)}
                  >
                    Accepted
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <HtmlEditor
                    label="Notes"
                    value={props.values.notes}
                    onChange={(contentState) => props.setFieldValue("notes", contentState)}
                    error={props.errors.notes}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FilesUploader
                    name="files"
                    files={props.values.files}
                    onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                    changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                  />
                </Grid>
                <Grid item container justifyContent="flex-end" spacing={3}>
                  <Grid item>
                    <Button
                      fullWidth
                      disabled={!props.isValid}
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={props.handleSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                  {allowToSign ? (
                    <Grid item>
                      <Button
                        fullWidth
                        disabled={!props.isValid}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => onSign(props.values)}
                      >
                        Sign
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={() => setOpen(false)}
                      >
                        Close
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default observer(withStyles(WitnessAttest, styles));