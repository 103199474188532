export default (theme) => ({
  content: {
    padding: `0 ${theme.spacing(4)}`,
  },
  openButton: {
    display: "flex",
    cursor: "pointer",
    userSelect: "none",
  },
  title: {
    margin: `${theme.spacing(3)} 0 ${theme.spacing()} 0`
  },
  summaryTable: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }
});