import TestResultTableCell from "@core/components/TestResultTableCell";
import {getIsWithinRequirements} from "@core/helpers";
import React from "react";
import {adjust} from "ramda";
import {TableCell} from "@mui/material";
import EditableCell from "@core/components/EditableCell";
import {calculateElementValue} from "@core/components/TestForms/ChemicalComposition/services";
import {ChemicalCompositionResult} from "@core/services/testResult/chemicalComposition";
import {ZONES} from "@core/constants/test";

const ElementsResults = ({editable, specimenId, zone, elements, propertyName, updateTest}) => {
  const getRecalculatedElements = (elements) => {
    return elements.map((element) => {
      if(!element.formula) return element;

      return {...element, [propertyName]: calculateElementValue(elements, element.formula, propertyName)};
    });
  };
  
  const updateElement = (value, index) => {
    const newElements = adjust(index, (element) => ({...element, [propertyName]: value}), elements);
    
    const recalculatedElements = getRecalculatedElements(newElements);

    const chemicalCompositionResult = new ChemicalCompositionResult(recalculatedElements);
    const result = chemicalCompositionResult.getResult();

    updateTest({elements: recalculatedElements, result});
  };

  return (
    <>
      <TableCell
        colSpan={zone === ZONES.BASE_METAL ? 2 : 1}
      >
        <EditableCell
          disabled={!editable}
          value={specimenId}
          editable={editable}
          setValue={(value) => updateTest({specimenId: value})}
        />
      </TableCell>
      {elements.map((el, index) => {
        const value = el[propertyName];
        const min = zone === ZONES.BASE_METAL ? el.min : null;
        const max = zone === ZONES.BASE_METAL ? el.max : null;

        return (
          <TableCell>
            <TestResultTableCell
              value={value}
              max={max}
              min={min}
            >
              <EditableCell
                error={!getIsWithinRequirements(value, min, max)}
                disabled={!editable || el.formula}
                type="number"
                value={value}
                editable={editable}
                setValue={(value) => updateElement(value, index)}
              />
            </TestResultTableCell>
          </TableCell>
        );
      })}
    </>
  );
};

export default ElementsResults;