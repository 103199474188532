import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {flatten, times, gte, lte} from "ramda";
import classNames from "classnames";
import {getIsWithinRequirements, getMean} from "@core/helpers";
import EditableCell from "@core/components/EditableCell";
import TestResultTableCell from "@core/components/TestResultTableCell";
import styles from "./styles";

const QuadrantTable = ({classes, min, max, avgMin, avgMax, quadrants, sortByQuadrant, value, productType, editable, updateElement}) => {
  const positionsLength = quadrants.map((zones) => zones.map((zone) => zone.elements.length));
  const longestHistoryLength = Math.max(...flatten(positionsLength));
  const positions = times((index) => index + 1, longestHistoryLength);

  const zones = flatten(quadrants);
  const elements = flatten(zones.map((zone) => zone.elements));
  const values = elements.map((element) => Number(element.value));

  return (
    <Table  className={classNames("styled-table", classes.table)}>
      <TableHead>
        <TableRow>
          <TableCell>
            Pos
          </TableCell>
          {positions.map((index) => (
            <TableCell>
              {index}
            </TableCell>
          ))}
          <TableCell className={classNames(classes.avgCell)}>
            AVG
          </TableCell>
          <TableCell >
            Lowest
          </TableCell>
          <TableCell >
            Highest
          </TableCell>
          {value === "all" && (
            <TableCell rowSpan={3}>
              {productType.includes("ERW") ? "Zone" : "Quadrant"}
            </TableCell>
          )}
        </TableRow>
        {(Boolean(min) || Boolean(avgMin)) && (
          <TableRow className={classes.boldBorder}>
            <TableCell>
              Req min
            </TableCell>
            {positions.map(() => (
              <TableCell >
                {min || "-"}
              </TableCell>
            ))}
            <TableCell className={classNames(classes.avgCell)}>
              {avgMin || "-"}
            </TableCell>
            <TableCell >
              -
            </TableCell>
            <TableCell >
              -
            </TableCell>
          </TableRow>
        )}
        {(Boolean(max) || Boolean(avgMax)) && (
          <TableRow className={classes.boldBorder}>
            <TableCell>
              Req max
            </TableCell>
            {positions.map(() => (
              <TableCell >
                {max || "-"}
              </TableCell>
            ))}
            <TableCell className={classNames(classes.avgCell)}>
              {avgMax || "-"}
            </TableCell>
            <TableCell >
              -
            </TableCell>
            <TableCell >
              -
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {quadrants.map((zones) => zones.map((zone, zoneIndex) => {
          const values = zone.elements.map((element) => Number(element.value));
          const mean = getMean(values);
          const hasMeanRequirement = avgMin || avgMax; // Check if the test has a requirement on the mean
          const meanAcceptable = gte(mean, avgMin || 0) && (!avgMax || lte(mean, avgMax));

          return (
            <TableRow className={zoneIndex === zones.length - 1 && classes.boldBorder}>
              {(sortByQuadrant || value !== "all") ? (
                <TableCell>
                  {zone.zoneName}
                </TableCell>
              ) : (
                !zoneIndex && (
                  <TableCell rowSpan={zones.length}>
                    {zone.zoneName}
                  </TableCell>
                )
              )}
              {zone.elements.map((element) => {
                return (
                  <TableCell>
                    <TestResultTableCell
                      value={element.value}
                      max={element.max}
                      min={element.min}
                    >
                      <EditableCell
                        error={!getIsWithinRequirements(element.value, element.min, element.max)}
                        type="number"
                        value={element.value}
                        editable={editable}
                        setValue={(value) => updateElement(value, element.id)}
                      />
                    </TestResultTableCell>
                  </TableCell>
                );
              })}
              {values.length < longestHistoryLength && (
                times((index) => index + 1, longestHistoryLength - values.length).map(() => (
                  <TableCell className={classNames(classes.cell, classes.success)}>-</TableCell>
                ))
              )}
              <TableCell className={classNames(classes.cell, hasMeanRequirement ? (meanAcceptable ? classes.success : classes.error) : classes.info, classes.avgCell)}>
                {hasMeanRequirement ? <span>{mean.toFixed(2)}</span> :
                  <Tooltip title={"Value for information only."}>
                    <span>{mean.toFixed(2)}</span>
                  </Tooltip>
                }
              </TableCell>
              <TableCell className={classNames(classes.cell, classes.info)}>
                <Tooltip title={"Value for information only."}>
                  <span>{Math.min(...values)}</span>
                </Tooltip>
              </TableCell>
              <TableCell className={classNames(classes.cell, classes.info)}>
                <Tooltip title={"Value for information only."}>
                  <span>{Math.max(...values)}</span>
                </Tooltip>
              </TableCell>
              {value === "all" && (
                sortByQuadrant ? (
                  !zoneIndex && (
                    <TableCell rowSpan={zones.length} >
                      {zone.quadrantName}
                    </TableCell>
                  )
                ) : (
                  <TableCell >
                    {zone.quadrantName}
                  </TableCell>
                )
              )}
            </TableRow>
          );
        }))}
        {value !== "all" && (
          <TableRow className={classes.boldBorder}>
            <TableCell padding="none" colSpan={longestHistoryLength + 1} />
            <TableCell className={classNames(classes.avgCell)}>
              {value}
            </TableCell>
            <TableCell className={classNames(classes.cell, classes.info)}>
              {Math.min(...values)}
            </TableCell>
            <TableCell className={classNames(classes.cell, classes.info)}>
              {Math.max(...values)}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default withStyles(QuadrantTable, styles);