import React, {useCallback, useEffect, useState} from "react";
import {map, prop} from "ramda";
import {withRouter} from "react-router";
import {observer} from "mobx-react-lite";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment
} from "@mui/material";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {Input} from "@core/components/Form";
import ColumnsSelect from "@core/components/ColumnsSelect";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import {getColumns} from "@core/columns/certificate";
import BatchTransferTable from "./Table";
import useStores from "../../../../useStores";
import {getRowsPerPage, setRowsPerPage} from "@core/helpers";
import useSwitchTableView from "@core/hooks/useSwitchTableView";
import {DEFAULT_VIEW_TAB} from "@core/components/Transfer/constants";
import {STORE_DATA} from "@core/constants/storeDataKeys";

const BatchTransfer = observer(({match, open, close, setOrderCertificates}) => {
  const {normsByMaterial} = useFetchedCertificateNorms();
  const columns = getColumns(normsByMaterial);
  const savedSelectedColumns = JSON.parse(localStorage.getItem(match.path));
  const [selectedColumns, setSelectedColumns] = useState(savedSelectedColumns || map(prop("dataIndex"), columns));

  const {OutgoingOrderStore, TransferStore} = useStores();
  const {status, data, total, fetch, selected} = OutgoingOrderStore.certificates;
  const {selectedCertificates} = OutgoingOrderStore;

  const getOrderCertificateIds = () => {
    const orderCertificates = TransferStore.transfer.certificates || [];

    return orderCertificates.map(prop("_id"));
  };

  useEffect(() => {
    return () => OutgoingOrderStore.certificates.reset();
  }, []);

  const {lastTableView} = useSwitchTableView({
    getStore: () => OutgoingOrderStore,
    dataKey: STORE_DATA.certificates,
    matchPath: match.path,
    defaultParams: {orderCertificatesIds: getOrderCertificateIds()},
    runEffect: open
  });

  useEffect(() => {
    if(open) {
      return;
    }

    const limit = getRowsPerPage(match.path);
    lastTableView.current = DEFAULT_VIEW_TAB;
    OutgoingOrderStore.certificates.setSelected([]);
    OutgoingOrderStore.certificates.load({
      orderCertificatesIds: getOrderCertificateIds(),
      search: "", offset: 0, limit
    });
  }, [open]);

  const handleChangeRowsPerPage = (limit) => {
    setRowsPerPage(match.path, limit);
    OutgoingOrderStore.certificates.load({limit, offset: 0});
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    OutgoingOrderStore.certificates.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback((event) => {
    searchAPIDebounced(event.target.value);
  }, []);

  const onSave = () => {
    const orderCertificates = TransferStore.transfer.certificates || [];
    setOrderCertificates([...orderCertificates, ...selectedCertificates]);
    close();
  };

  const onClose = () => {
    close();
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Batch transfer</DialogTitle>
      <DialogContent>
        <Grid container spacing={5}>
          <Grid container justifyContent="space-between" item>
            <Grid item xs>
              <Input
                name='search'
                endAdornment={
                  <InputAdornment position="start" className="input-loader">
                    {status.loading && <CircularProgress size={20} />}
                  </InputAdornment>
                }
                placeholder={"Search Certificates"}
                onChange={handleSearch}
              />
            </Grid>

            <Grid item>
              <ColumnsSelect
                columns={columns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <BatchTransferTable
              disabled={!TransferStore.transfer.isDraft}
              certificates={data}
              total={total}
              offset={fetch.offset}
              limit={fetch.limit}
              handleChangePage={(offset) => OutgoingOrderStore.certificates.load({offset})}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              selectCertificates={(ids) => {
                OutgoingOrderStore.certificates.setSelected(ids);
              }}
              selected={selected}
              sort={fetch.sort}
              setSort={(sort) => OutgoingOrderStore.certificates.load({sort})}
              selectedColumns={selectedColumns}
              columns={columns}
              isLoaded={status.loaded}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={5} justifyContent="flex-end">
              <Grid item>
                <Button
                  disabled={!selected.length}
                  variant="contained"
                  color="primary"
                  onClick={onSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

export default withRouter(BatchTransfer);
