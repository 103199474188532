export default () => ({
  btnAddCell: {
    textAlign: "center"
  },

  btnAdd: {
    margin: "0 auto",
    width: "50%"
  },

  gridRoot: {
    marginTop: 20,
    marginBottom: 20
  },

  formControl: {
    display: "block",
    width: "100%"
  },

  tableCell: {
    paddingRight: "0px !important"
  },

  saveButton: {
    marginLeft: "auto",
  }
});

