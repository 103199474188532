import React, {useEffect} from "react";
import {Input} from "@core/components/Form";
import {useField} from "formik";

const TextField = ({label, onChange, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const {error, touched} = meta;
  const {setTouched, setValue} = helpers;

  useEffect(() => {
    if (!onChange || !field.value) return;

    onChange(field.value, touched);
  }, [field.value]);

  return (
    <Input
      label={label}
      value={field.value}
      error={props.error || (Boolean(error) && touched)}
      errorMessage={error}
      onChange={({target}) => {
        if (!touched) setTouched(true);

        setValue(target.value);
      }}
      {...props}
    />
  );
};

export default TextField;