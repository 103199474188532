import React, {useEffect} from "react";
import TestParameters from "./TestParameters";
import {FieldArray, useFormikContext} from "formik";
import {splitEvery} from "ramda";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {getMean, getDeviation, getCi, getAccuracy} from "@core/helpers";
import TextField from "@core/components/FormikTextField";

const ELEMENTS_IN_COLUMN = 10;

const AutomaticCount = ({firstUpdate, test}) => {
  const {values, setFieldValue} = useFormikContext();

  const columns = splitEvery(ELEMENTS_IN_COLUMN, values.elements);
  const columnsNumber = Math.max(Math.floor(12 / columns.length), 2);
  const columnWidth = Math.min(columnsNumber, 4);

  const elementsString = values.elements.join("");

  useEffect(() => {
    const totalAreaAnalyzed = values.elements.length * Number(values.singleFieldArea);
    const meanFerrite = getMean(values.elements);
    const meanPercents = values.singleFieldArea ? meanFerrite / Number(values.singleFieldArea) * 100 || 0 : 0;
    const deviation = getDeviation(values.elements);

    const deviationFerrite = values.singleFieldArea ? deviation / Number(values.singleFieldArea) * 100 || 0 : 0;
    const ci = getCi(values.elements, deviationFerrite);
    const accuracy = getAccuracy(values.elements, deviationFerrite, meanPercents);

    setFieldValue("totalAreaAnalyzed", totalAreaAnalyzed.toFixed(3));
    setFieldValue("meanFerrite", meanPercents.toFixed(3));
    setFieldValue("meanFerriteMicron", meanFerrite.toFixed(3));
    setFieldValue("deviationFerrite", deviationFerrite.toFixed(3));
    setFieldValue("deviation", deviation.toFixed(3));
    setFieldValue("ci", ci.toFixed(3));
    setFieldValue("accuracy", accuracy.toFixed(3));
  }, [elementsString, values.singleFieldArea]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TestParameters
          firstUpdate={firstUpdate}
          test={test}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={9} container>
            {columns.map((bunch, bunchIndex) => (
              <Grid item xs={columnWidth}>
                <Table className="styled-table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none" width="70">Field</TableCell>
                      <TableCell padding="none">Area [µm<sup>2</sup>]</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="elements">
                      {() => bunch.map((element, elementIndex) => {
                        const actualIndex = bunchIndex * ELEMENTS_IN_COLUMN + (elementIndex + 1);

                        return (
                          <TableRow key={bunchIndex + elementIndex}>
                            <TableCell padding="none">{actualIndex}</TableCell>
                            <TableCell
                              padding="normal"
                            >
                              <TextField
                                inputProps={{min: 0}}
                                type="number"
                                name={`elements.${actualIndex - 1}`}
                                required
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </FieldArray>
                  </TableBody>
                </Table>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={9}>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell padding="none">Total area analyzed [µm<sup>2</sup>]</TableCell>
                  <TableCell padding="none">Mean ferrite [%]</TableCell>
                  <TableCell padding="none">Mean ferrite [µm<sup>2</sup>]</TableCell>
                  <TableCell padding="none">Standard deviation [%]</TableCell>
                  <TableCell padding="none">Standard deviation [µm<sup>2</sup>]</TableCell>
                  <TableCell padding="none">CI 95%</TableCell>
                  <TableCell padding="none">Relative accuracy [%]</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell padding="none">{values.totalAreaAnalyzed}</TableCell>
                  <TableCell padding="none">{values.meanFerrite}</TableCell>
                  <TableCell padding="none">{values.meanFerriteMicron}</TableCell>
                  <TableCell padding="none">{values.deviationFerrite}</TableCell>
                  <TableCell padding="none">{values.deviation}</TableCell>
                  <TableCell padding="none">{values.ci}</TableCell>
                  <TableCell padding="none">{values.accuracy}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AutomaticCount;