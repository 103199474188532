import CertificateService from "Blockchain/certificates-service";
import referenceService from "@core/api/reference-service";
import {SigningStore, NotificationStore} from "@core/components/Modal/stores";
import UserStore from "../../Admin/stores/user";
import utils from "../../Blockchain/utils";
import CertificateDBService from "../../Blockchain/certificates-service";
import {STATUSES, TYPES} from "@core/constants/test";

export default {
  split(certificates, originalCertificateId, callback) {
    const transactions = [];

    certificates.forEach((certificate) => {
      const dataHash = utils.computeHash(certificate).hash;
      const transactionObject = CertificateService.addCertificateTX(certificate, dataHash, []);

      transactions.push({
        transactionObject: transactionObject,
        payload: {
          certificateId: certificate._id,
          originalCertificateId,
          type: "certificate",
          action: "split"
        }
      });

      const splitTests = certificate.tests.filter((test) => test.type === TYPES.SPLIT);
      splitTests.forEach((test) => {
        const dataHash = utils.computeHash(test.properties).hash;
        const transactionObject = CertificateDBService.createNewTestTX(certificate._id, test, dataHash, test.assignee._id);

        transactions.push({
          transactionObject: transactionObject,
          payload: {
            testId: test._id,
            type: "test",
            action: "approve",
            status: STATUSES.APPROVED
          }
        });
      });
    });

    referenceService.create({
      creator: UserStore.user.data._id,
      transactions
    }).then((reference) => {
      SigningStore.openSigner({id: reference._id}, {title: "Certificate split", createdFor: "the certificate owner"}, callback);
    })
      .catch(() => NotificationStore.showError("Something went wrong"));

  },

  sign(certificates) {
    const transactions = certificates.map((certificate) => {
      const dataHash = utils.computeHash(certificate).hash;
      const transactionObject = CertificateService.addCertificateTX(certificate, dataHash, []);

      return {
        transactionObject,
        payload: {
          certificateId: certificate._id,
          type: "certificate",
          action: "sign"
        }
      };
    });

    referenceService.create({
      creator: UserStore.user.data._id,
      transactions,
      date_created: new Date
    })
      .then((reference) => SigningStore.openSigner(
        {id: reference._id},
        {title: "Certificate(s) sign", createdFor: "the certificate owner"}
      ))
      .catch(() => NotificationStore.showError("Something went wrong!"));
  },

  convert(certificates) {
    const transactions = certificates.map((certificate) => {
      const dataHash = utils.computeHash(certificate).hash;
      const transactionObject = CertificateService.updateCertificateTX(certificate, dataHash);

      return {
        transactionObject,
        payload: {
          certificateId: certificate._id,
          type: "certificate",
          action: "convert"
        }
      };
    });

    referenceService.create({
      creator: UserStore.user.data._id,
      transactions,
    })
      .then((reference) => SigningStore.openSigner(
        {id: reference._id},
        {title: "Certificates convert", createdFor: "the certificate owner"}
      ))
      .catch(() => NotificationStore.showError("Something went wrong!"));
  }
};
