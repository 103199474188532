import modules from "@core/constants/modules";
import {Formik} from "formik";
import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo, useRef, useState} from "react";
import useStores from "../../useStores";
import DefiningStartingMaterialForm from "./components/DefiningStartingMaterialForm";
import UptimeLikeFormLayout from "@core/components/UptimeLikeFormLayout";
import {initialValuesDefault, initialValuesEdit, validationSchema} from "./constants";
import CampaignDetailsFields from "./components/CampaignDetailsFields";
import DefineEndProduct from "./components/DefineEndProduct";
import SubmitForm from "./components/SubmitForm";
import {useParams} from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import {Grid} from "@mui/material";

// simulates async API call and retrieves values
const fakeApiRequestAsync = () => new Promise((resolve) => {
  setTimeout(() => {
    resolve(JSON.parse(JSON.stringify(initialValuesEdit)));
  }, 3000);
});

const SingleMaterialCampaignForm = () => {
  const [initialValues, setInitialValues] = useState(initialValuesDefault);
  
  const {id} = useParams();
  const editState = useMemo(() => !!id, [id]);
  const [isLoading, setIsLoading] = useState(!!editState);
  const saveAsDraft = useRef(false);

  const {TransferStore} = useStores();

  useEffect(() => {
    TransferStore.getTransfersReceivers([modules.PRODUCER, modules.END_OWNER]);
    
    if (editState) {
      const setValuesAsync = async () => {
        const data = await fakeApiRequestAsync({id});
        setInitialValues(data);
        setIsLoading(false);
      };
      setValuesAsync();
    }
  }, []);
  
  const onSubmit = (values) => {
    console.log("the onsubmit in the parent.");
    console.log("values: ", values);

    if (saveAsDraft.current) {
      console.log("is in save as draft!");
    } else {
      console.log("is just submitting");
    }
    
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 5000);
    });
  };

  return (
    <>
      {
        isLoading ?
          <Grid container justifyContent="center" marginTop={7}>
            <CircularProgress size={48}/>
          </Grid> :
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(props) => {
              console.log(props.values);

              return (
                <UptimeLikeFormLayout
                  title={"Create campaign"}
                  formRows={
                    [
                      {
                        sectionDescription: {
                          title: "Campaign details",
                          text: "A campaign follows the manufacturing of one QCP/ITP item. Connecting a campaign to a PO will enable the customer to track progress."
                        },
                        sectionForm: <CampaignDetailsFields/>
                      },
                      {
                        sectionDescription: {
                          title: "Define starting material situation",
                          text: "Choose if you are creating new material or if you have existing base materials (e.g. plate) that you will convert into a new product (e.g. pipe). To provide a progress estimate, you can estimate to amount to heats you intent to produce."
                        },
                        sectionForm: <DefiningStartingMaterialForm/>
                      },
                      {
                        sectionDescription: {
                          title: "Define end product",
                          text: "Define to which material specification(s) and grade(s) the end product will manufactured including nominal sizes. Choosing 3.2 certification will require all underlying tests to be signed by a third party inspector."
                        },
                        sectionForm: <DefineEndProduct />
                      },
                    ]
                  }
                  formFooter={<SubmitForm setSaveAsDraft={(value) => saveAsDraft.current = value}/>}
                >
                </UptimeLikeFormLayout>
              );
            }}
          </Formik>
      }
    </>
  );
};

export default observer(SingleMaterialCampaignForm);