import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Checkbox,
} from "@mui/material";
import {RiFileTransferLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import {Formik} from "formik";
import ProjectIdAutocomplete from "@core/components/ProjectIdAutocomplete";
import TextField from "@core/components/FormikTextField";
import useStores from "../../../../useStores";

const PreselectedListModal = observer(({certificates, open, onClose, onSave, setCertificatesToTransfer}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(certificates.map((certificate) => certificate._id));
  }, [open]);

  const {TransferStore} = useStores();

  const initialValues = certificates.reduce((acc, certificate) => {
    acc[certificate._id] = {
      lineItem: certificate.lineItem || "",
      projectId: certificate.projectId ? certificate.projectId._id : ""
    };

    return acc;
  }, {});

  const onSubmit = (values) => {
    const updatedCertificates = selected.map((certificateId) => ({
      id: certificateId,
      lineItem: values[certificateId].lineItem,
      projectId: values[certificateId].projectId
    }));

    onSave(values, selected);
    setCertificatesToTransfer(updatedCertificates);
  };

  const onCheck = (certificateId) => {
    let certificatesToTransfer = [];

    if(selected.includes(certificateId)) {
      certificatesToTransfer = selected.filter((id) => id !== certificateId);
    } else {
      certificatesToTransfer = [...selected, certificateId];
    }

    setSelected(certificatesToTransfer);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>Transfer</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({handleSubmit}) => (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Table className="styled-table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" />
                      <TableCell>Heat</TableCell>
                      <TableCell>Norm</TableCell>
                      <TableCell>Material</TableCell>
                      <TableCell>Certificate ID</TableCell>
                      <TableCell width="20%">PO Item</TableCell>
                      <TableCell width="20%">Project Id</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {certificates.length ? certificates.map((certificate) => (
                      <TableRow key={certificate._id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selected.includes(certificate._id)}
                            onClick={() => onCheck(certificate._id)}
                          />
                        </TableCell>
                        <TableCell>{certificate.heat}</TableCell>
                        <TableCell>{certificate.properties.norm}</TableCell>
                        <TableCell>{certificate.properties.grade}</TableCell>
                        <TableCell>{certificate._id}</TableCell>
                        <TableCell width="20%">
                          <TextField
                            name={`${certificate._id}.lineItem`}
                          />
                        </TableCell>
                        <TableCell width="20%">
                          <ProjectIdAutocomplete
                            name={`${certificate._id}.projectId`}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            size="small"
                            target="_blank"
                            component={Link}
                            to={`/certificates/${certificate._id}`}
                          >
                              View
                          </Button>
                        </TableCell>
                      </TableRow>
                    )) : (
                      <TableRow>
                        <TableCell colSpan={9}>No certificates selected.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
              <Grid container item justifyContent="flex-end">
                <Button
                  disabled={!selected.length}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  <RiFileTransferLine size={20} />&nbsp;
                  <span>{TransferStore.transfer.receiver ? "Sign and send" : "Send"}</span>
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
});

export default PreselectedListModal;