import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Box, Grid, Divider} from "@mui/material";
import LegendWrapper from "../../../Footer/components/LegendWrapper";
import {
  QUARANTINE_PIPE_CONFIG,
  REPAIRED_WELD_CONFIG,
  TIE_IN_WELD_CONFIG,
} from "../../../Stalk/constants";
import {PIPE_CONFIG, WELD_CONFIG} from "../../constants";
import {STATE, PIPE_WITH_TYPE_CONFIG} from "../../../../constants";
import useStores from "../../../../../../../../../../useStores";

const Legend = ({settings, state, isWrapped}) => {
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;
  const baseMaterialsById = CampaignStore.campaignProductsById;

  const baseMaterialsTypes = useMemo(() => {
    return CampaignStore.baseMaterialsTypes.slice(0, PIPE_WITH_TYPE_CONFIG.BACKGROUND_COLORS.length);
  }, [CampaignStore.baseMaterialsTypes.length]);
  
  const isEveryPipeWithType = useMemo(() => {
    return campaign.welds.every((weld) => baseMaterialsById[weld.pipeA]?.type &&  baseMaterialsById[weld.pipeB]?.type);
  }, [campaign.welds.length, campaign.products.length]);

  return (
    <LegendWrapper
      isWrapped={isWrapped}
    >
      {!isEveryPipeWithType && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: PIPE_CONFIG.BACKGROUND_COLOR,
                  borderRadius: PIPE_CONFIG.RADIUS,
                  height: PIPE_CONFIG.WIDTH,
                  width: PIPE_CONFIG.HEIGHT,
                }}
              />
            </Grid>
            <Grid item>
              Pipe
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {(state === STATE.AS_FABRICATED && settings.quarantine) && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: QUARANTINE_PIPE_CONFIG.BACKGROUND_COLOR,
                  borderRadius: PIPE_CONFIG.RADIUS,
                  height: PIPE_CONFIG.WIDTH,
                  width: PIPE_CONFIG.HEIGHT,
                }}
              />
            </Grid>
            <Grid item>
                Quarantined pipe
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {Boolean(settings.type && baseMaterialsTypes.length) && (
        baseMaterialsTypes.map((type, index) => (
          <Grid item key={type}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: PIPE_WITH_TYPE_CONFIG.BACKGROUND_COLORS[index],
                    borderRadius: PIPE_CONFIG.RADIUS,
                    height: PIPE_CONFIG.WIDTH,
                    width: PIPE_CONFIG.HEIGHT,
                  }}
                />
              </Grid>
              <Grid item>
                {type}
              </Grid>
              <Grid item>
                <Divider orientation="vertical" sx={{height: 20}} />
              </Grid>
            </Grid>
          </Grid>
        ))
      )}
      {settings.welds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: WELD_CONFIG.BACKGROUND_COLOR,
                  borderRadius: WELD_CONFIG.RADIUS,
                  height: WELD_CONFIG.WIDTH,
                  width: WELD_CONFIG.HEIGHT,
                }}
              />
            </Grid>
            <Grid item>
              Weld
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {settings.highlightRepairedWelds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: REPAIRED_WELD_CONFIG.BACKGROUND_COLOR,
                  borderRadius: WELD_CONFIG.RADIUS,
                  height: WELD_CONFIG.WIDTH,
                  width: WELD_CONFIG.HEIGHT,
                }}
              />
            </Grid>
            <Grid item>
              Repaired weld
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{height: 20}} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {settings.tieInWelds && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  backgroundColor: TIE_IN_WELD_CONFIG.BACKGROUND_COLOR,
                  borderRadius: WELD_CONFIG.RADIUS,
                  height: WELD_CONFIG.WIDTH,
                  width: WELD_CONFIG.HEIGHT,
                }}
              />
            </Grid>
            <Grid item>
              Tie-in weld
            </Grid>
          </Grid>
        </Grid>
      )}
    </LegendWrapper>
  );
};

export default observer(Legend);