import React, {useState} from "react";
import {Formik, FieldArray} from "formik";
import * as yup from "yup";
import {keys, times} from "ramda";
import {observer} from "mobx-react-lite";
import {Grid, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Loader from "@core/components/Loader";
import {STATUSES} from "@core/constants/test";
import Test from "../NewTestForm";
import AddTestDataForm from "../AddTestDataForm";
import useStores from "../../../../../../useStores";
import styles from "./styles";

const validationSchema = yup.object().shape({
  tests: yup.array().of(yup.object().shape({
    lotId: yup.string(),
    item_heat: yup.string()
  }))
});

const getInitialValues = (types) => ({
  tests: types.map((type) => ({
    lotId: "",
    item_heat: "",
    type,
  }))
});

const NewTests = ({classes, onSuccess, onClose, types}) => {
  const {TestStore, UserStore} = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true);

    const data = keys(types).reduce((tests, type) => {
      const {item_heat, lotId} = values.tests.find((item) => item.type === type);

      const test = {
        type,
        item_heat,
        lotId,
        company: UserStore.user.data.company._id,
        properties: {},
        assignee: UserStore.user.data.company._id,
        status: STATUSES.EMPTY
      };

      const testsToCreate = times(() => test, types[type]);
      tests.push(...testsToCreate);

      return tests;
    }, []);

    await TestStore.create(data);

    setIsLoading(false);
    onClose();
    onSuccess();
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={getInitialValues(keys(types), UserStore.user.data.company._id)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({values, setFieldValue, handleSubmit, isValid}) => (
        <Grid container>
          {values.tests.length > 1 && (
            <AddTestDataForm
              setFieldValue={setFieldValue}
              tests={values.tests}
            />
          )}

          <Grid item xs={12} className={values.tests.length > 1 && classes.container}>
            <FieldArray name="tests">
              <>
                {values.tests.map((test, index) => (
                  <Test
                    types={types}
                    key={index + test.type}
                    index={index}
                    test={test}
                  />
                ))}
              </>
            </FieldArray>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{paddingTop: 15}}
          >
            <Button
              disabled={!isValid || isLoading}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Create Tests
              {isLoading ? <div style={{marginLeft: "20px"}}><Loader size={20} noMargin={true} /></div> : null}
            </Button>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default observer(withStyles(NewTests, styles));
