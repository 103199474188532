import React from "react";
import {Formik} from "formik";
import * as yup from "yup";
import {Dialog, DialogTitle, DialogContent, Button, Grid} from "@mui/material";
import {MuiAutocomplete} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import useStores from "../../../../useStores";
import {ROLES} from "@core/constants/externalUsersRoles";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const initialValues = {
  orderNumber: "",
  lineItem: "",
  viewer: "",
};

const validationSchema = yup.object().shape({
  viewer: yup.string().required("The field is required!"),
  orderNumber: yup.string().required("The field is required!"),
  lineItem: yup.number().required("The field is required!"),
});

const PublishToPortal = ({open, onClose, certificateId}) => {
  const {NotificationStore, CertificateStore} = useStores();

  const onSubmit = async (values) => {
    await CertificateStore.publishCertificate(values, certificateId);
    NotificationStore.showSuccess("Successfully published to portal!");
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Publish to portal</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Grid container spacing={3}>
              <Grid xs={12} item>
                <MuiAutocomplete
                  url={`${ROUTES.EXTERNAL_USER[ACTIONS.ALL]}?label=${ROLES.CUSTOMER}`}
                  inputProps={{
                    label: "Customer",
                    name: "viewer",
                    required: true,
                  }}
                  formatOptions={(data) => data.map((item) => ({
                    label: `${item.lastname} - ${item.company}`,
                    value: item._id})
                  )}/>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label='PO Number'
                  name='orderNumber'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  required
                  label='PO item'
                  name='lineItem'
                />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <Button
                  disabled={!props.isValid}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={props.handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PublishToPortal;