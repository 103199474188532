import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {partition} from "ramda";
import TestResults from "./components/TestResults";
import {TEST_RESULTS} from "@core/constants/testResults";
import MetadataTable from "./components/MetadataTable";
import {FILTERS} from "@core/constants/filters";
import useStores from "../../../../useStores";

const CustomTest = ({test, disabled, updateTest}) => {
  const {FilterStore} = useStores();
  
  const formattedActivities = useMemo(() => test.properties.activities.map((activity, index) => ({...activity, id: index})), [test.properties.activities]);
  
  const specimens = FilterStore.filters[FILTERS.SPECIMEN_ID] || [];
  const welds = FilterStore.filters[FILTERS.WELD_ID] || [];
  
  const specimensString = specimens.join();
  const weldsString = welds.join();
  
  const filteredActivities = useMemo(() => {
    const filtered = formattedActivities.filter((activity) => {
      const isInSpecimensArr = !specimens.length || specimens.includes(activity.specimen);
      const isInWeldsArr = !welds.length || welds.includes(activity.specimen);

      return isInSpecimensArr && isInWeldsArr;
    });
    
    if(filtered.length) return filtered;

    return formattedActivities;
  }, [formattedActivities, specimensString, weldsString]);

  const [results, metadata] = useMemo(() => partition((activity) => !activity.isMetadata, filteredActivities), [filteredActivities]);
  
  const changeTest = async (results, activityIndex) => {
    const activities = test.properties.activities.map((activity, index) => activityIndex === index ? {...activity, results} : activity);
    const properties = {
      activities,
      result: activities.every((activity) => activity.results.result === TEST_RESULTS.ACCEPTABLE) ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE,
    };

    await updateTest(properties);
  };

  return (
    <Grid container>
      {!!metadata.length && (
        <Grid item xs={12}>
          <MetadataTable
            activities={metadata}
            test={test}
          />
        </Grid>
      )}
      {!!results.length && (
        <Grid item xs={12}>
          <TestResults
            disabled={disabled}
            activities={results}
            test={test}
            updateTest={changeTest}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default observer(CustomTest);