import React, {useEffect, useState, useCallback} from "react";
import {Drawer} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {ResizableDrawerContext} from "./context";
import styles from "./styles";

const DEFAULT_DRAWER_WIDTH_COEFFICIENT = 0.75;

const getDrawerWidth = () => window.innerWidth * DEFAULT_DRAWER_WIDTH_COEFFICIENT;

const MIN_DRAWER_WIDTH = 500;

const MAX_DRAWER_WIDTH_COEFFICIENT = 0.9;
const MAX_DRAWER_WIDTH = window.innerWidth * MAX_DRAWER_WIDTH_COEFFICIENT;

const ResizableDrawer = ({classes, className, minWidth = MIN_DRAWER_WIDTH, paperClasses, keepMounted, open, close, children, ...props}) => {
  const [drawerWidth, setDrawerWidth] = useState(getDrawerWidth());

  const handleMouseDown =  useCallback(() => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  }, []);

  const handleMouseUp =  useCallback(() => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  }, []);

  const handleResize =  useCallback(() => {
    setDrawerWidth(getDrawerWidth());
  }, []);

  const handleMouseMove = useCallback((e) => {
    const newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);

    if (newWidth > minWidth && newWidth < MAX_DRAWER_WIDTH) {
      setDrawerWidth(newWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <Drawer
      className={className}
      PaperProps={{
        style: {width: drawerWidth},
        classes: paperClasses,
      }}
      ModalProps={{
        keepMounted,
      }}
      anchor="right"
      open={open}
      onClose={close}
      {...props}
    >
      <div
        onMouseDown={handleMouseDown}
        className={classes.dragger}
      />
      <ResizableDrawerContext.Provider value={drawerWidth}>
        {children}
      </ResizableDrawerContext.Provider>
    </Drawer>
  );
};

export default withStyles(ResizableDrawer, styles);