import React from "react";
import {useHistory} from "react-router";
import {observer} from "mobx-react-lite";
import {MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ActionCell from "@core/components/ActionCell";
import styles from "./styles";
import useStores from "../../../../useStores";

const Actions = observer(({classes, onDelete, transfer}) => {
  const {UserStore, ProjectStore} = useStores();

  const history = useHistory();

  return (
    <ActionCell>
      <MenuItem
        onClick={() => {
          history.push(`/orders/${transfer._id}`);
        }}
      >
        View
      </MenuItem>
      {ProjectStore.project.data.createdBy._id === UserStore.user.data._id && (
        <MenuItem
          className={classes.deleteButton}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          Unlink
        </MenuItem>
      )}
    </ActionCell>
  );
});

export default withStyles(Actions, styles);