import React, {useEffect} from "react";
import {Grid, MenuItem} from "@mui/material";
import {keys} from "ramda";
import TextField from "@core/components/FormikTextField";
import {useFormikContext} from "formik";
import * as yup from "yup";
import {getCorrosionRate, getExaminedArea, getIsAcceptable, getMassLoss, getPitsPerCmSquared} from "../../services";
import SelectField from "@core/components/FormikSelect";
import {commonValidationSchema, CORROSION_RATE_K_BY_UNIT, WEIGHT_UNIT} from "../../data";

export const validationSchema = yup.object().shape({
  ...commonValidationSchema,
  testReactant: yup.string().required("This field is required"),
  materialDensity: yup.number().positive("Should be > 0").required("This field is required"),
  initialWeight: yup.number().positive("Should be > 0").required("This field is required"),
  finalWeight: yup.number().positive("Should be > 0").required("This field is required"),
  massLoss: yup.number().min(0, "Should be >= 0").required("This field is required"),
  examinedArea: yup.number().positive("Should be > 0").required("This field is required"),
  maxPits: yup.number().min(0, "Should be >= 0"),
  maxCorrosionRate: yup.object().shape({
    value: yup.number().positive("Should be > 0").test("value", "This field is required", function (value) {
      const maxWeightLoss = this.from[1].value.maxWeightLoss.value;

      return !!maxWeightLoss || value;
    }),
    unit: yup.string().when("value", {
      is: (value) => !!value,
      then: yup.string().required("This field is required")
    }),
  }),
  maxWeightLoss: yup.object().shape({
    value: yup.number().positive("Should be > 0").test("value", "This field is required", function (value) {
      const maxCorrosionRate = this.from[1].value.maxCorrosionRate.value;

      return !!maxCorrosionRate || value;
    }),
    unit: yup.string().when("value", {
      is: (value) => !!value,
      then: yup.string().required("This field is required")
    }),
  }),
  numberOfPits: yup.object().shape({
    sideA: yup.number().min(0, "Should be >= 0"),
    sideB: yup.number().min(0, "Should be >= 0"),
  }),
  pitsPerCmSquared: yup.number(),
  pitsDepth: yup.object().shape({
    avg: yup.number().min(0, "Should be >= 0"),
    max: yup.number().min(0, "Should be >= 0"),
  }),
  corrosionRate: yup.number(),
});

const AutomaticWithPitsForm = () => {
  const {values, setFieldValue} = useFormikContext();

  useEffect(() => {
    setFieldValue("massLoss", getMassLoss(values.initialWeight, values.finalWeight));
  }, [values.finalWeight, values.initialWeight]);

  useEffect(() => {
    setFieldValue("examinedArea", getExaminedArea(values.weight, values.size));
  }, [values.weight, values.size]);

  useEffect(() => {
    setFieldValue("pitsPerCmSquared", getPitsPerCmSquared(values.numberOfPits.sideA, values.numberOfPits.sideB, values.examinedArea));
  }, [values.numberOfPits.sideA, values.numberOfPits.sideB, values.examinedArea]);

  useEffect(() => {
    setFieldValue("corrosionRate", getCorrosionRate(values.massLoss, values.maxCorrosionRate.unit, values.examinedArea, values.testingTime, values.materialDensity));
  }, [values.massLoss, values.maxCorrosionRate.unit, values.examinedArea, values.testingTime, values.materialDensity]);

  useEffect(() => {
    setFieldValue("result", getIsAcceptable(values));
  }, [values.maxCorrosionRate.value, values.corrosionRate, values.maxWeightLoss.value, values.maxWeightLoss.unit, values.massLoss, values.maxPits, values.pitsPerCmSquared]);

  return (
    <>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <TextField
            disabled
            required
            name="testReactant"
            label="Test solution"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            name="materialDensity"
            label="Material density"
            type="number"
            endAdornment={<span>G/CM<sup>2</sup></span>}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={3}>
          <TextField
            name="initialWeight"
            label="Initial weight"
            type="number"
            endAdornment="G"
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name="finalWeight"
            label="Final weight"
            type="number"
            endAdornment="G"
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled
            name="massLoss"
            label="Weight loss"
            type="number"
            endAdornment="G"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled
            name="examinedArea"
            label="Examined area"
            type="number"
            endAdornment={<span>CM<sup>2</sup></span>}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3>Acceptance criteria</h3>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={3}>
          <TextField
            name="maxPits"
            label="Max pits"
            type="number"
            inputProps={{min: 0}}
          />
        </Grid>
        <Grid xs={3} item container spacing={3} alignItems="flex-end">
          <Grid item xs={6}>
            <TextField
              name="maxCorrosionRate.value"
              label="Max corrosion rate"
              type="number"
              inputProps={{min: 0}}
              required={!values.maxWeightLoss.value}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              name="maxCorrosionRate.unit"
              required={values.maxCorrosionRate.value}
            >
              {keys(CORROSION_RATE_K_BY_UNIT).map((unit) => (
                <MenuItem key={unit} value={unit}>{unit}</MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Grid>
        <Grid xs={3} item container spacing={3} alignItems="flex-end">
          <Grid item xs={6}>
            <TextField
              name="maxWeightLoss.value"
              label="Max weight loss"
              type="number"
              inputProps={{min: 0}}
              required={!values.maxCorrosionRate.value}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              name="maxWeightLoss.unit"
              required={values.maxWeightLoss.value}
            >
              {keys(WEIGHT_UNIT).map((unit) => (
                <MenuItem key={unit} value={unit}>{unit}</MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3>Test results</h3>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={3}>
          <TextField
            name="numberOfPits.sideA"
            label="Number of pits (Side A)"
            type="number"
            inputProps={{min: 0}}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name="numberOfPits.sideB"
            label="Number of pits (Side B)"
            type="number"
            inputProps={{min: 0}}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled
            name="pitsPerCmSquared"
            label="Pits per CM^2"
            type="number"
            inputProps={{min: 0}}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={3}>
          <TextField
            name="pitsDepth.avg"
            label="Average pits depth"
            type="number"
            inputProps={{min: 0}}
            endAdornment="micron"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name="pitsDepth.max"
            label="Maximum pits depth"
            type="number"
            inputProps={{min: 0}}
            endAdornment="micron"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled
            name="corrosionRate"
            label="Corrosion rate"
            type="number"
            endAdornment={values.maxCorrosionRate.unit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AutomaticWithPitsForm;