import React from "react";
import WitnessAttestForm from "@core/components/WitnessAttestForm";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";

const WitnessAttest = ({test, onClose}) => {
  if(!test) return null;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={test}
      onClose={onClose}
    >
      <DialogTitle>Test attestation</DialogTitle>
      <DialogContent>
        <WitnessAttestForm
          test={test}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default WitnessAttest;