import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const GlobalAcceptance = ({averageShearAreaAllSpecimens}) => {
  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Average Shear Area [%]</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Min</TableCell>
          <TableCell>{averageShearAreaAllSpecimens}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Max</TableCell>
          <TableCell>-</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default GlobalAcceptance;