import {green} from "@mui/material/colors";

export default (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
  input: {
    display: "none",
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonWaiveInspection: {
    color: "#616161",
    paddingLeft: "0px",
    marginTop: "0px",
    textTransform: "none",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#ebebeb00",
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  img: {
    maxWidth: "100%"
  },
  preloadAvatar: {
    width: 180,
    height: 180,
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "24px",
    border: "1px dashed #888",
    padding: "40px",
    color: "#888",
    margin: "15px auto",
    cursor: "pointer"
  },
  avatar: {
    width: 140,
    height: 140,
  },
  enter: {
    backgroundColor: "rgb(200,200,200)",
    color: "rgb(64,64,64)"
  },
  asterisk: {
    position: "relative",
    "&:after": {
      position: "absolute",
      content: "' *'",
      right: -11,
      color: "#ff0000"
    }
  },
  pdf: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& img": {
      height: 50,
    },
    "& p": {
      marginLeft: 10,
      wordBreak: "break-word"
    }
  },
  errorMessage: {
    color: "#f44336",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "start",
    marginTop: "3px",
  },
  buttonsContainer: {
    marginTop: 10,
  },
});
