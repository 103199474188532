
export default (theme) => ({
  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  formControl: {
    display: "block",
    width: "100%"
  },
  image: {
    position: "absolute",
    right: 0,
    top: 0,
    display: "flex",
    justifyContent: "flex-end",
    "& img": {
      maxWidth: "85%"
    }
  },
  divider: {
    marginBottom: 20
  },
  bootstrapInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "none",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },

  roundButtonGrid: {
    display: "flex",
    justifyContent: "flex-center",
    alignItems: "flex-end",
  },

  btnAdd: {
    padding: "7px 15px",
    marginBottom: 2.5
  },

  width180: {
    width: 180,
  },

  width120: {
    width: 120,
  },

  marginRight25: {
    marginRight: 25,
  },

  bootstrapFormLabel: {
    fontSize: 18,
    width: "100%"
  },
  bootstrapSelect: {
    width: 195,
  },
  bootstrapSelectSmall: {
    width: 170,
  },
  bootstrapMenuItem: {
    backgroundColor: theme.palette.common.white,
  },

  table: {
    marginBottom: 40,
    overflowX: "hidden"
  },

  tbody: {
    borderColor: "#c9c9c9",
  },

  tr: {
    maxWidth: "100%",
  },

  td: {
    position: "relative",
    border: "1px solid #c9c9c9",
    fontSize: 16,
    textAlign: "center",
    padding: "20px 10px",
    minWidth: 140,
  },

  gap: {
    borderBottom: "none",
  },

  metalHeader: {
    top: -25,
    left: 0,
    position: "absolute",
  },

  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },

  value: {
    textAlign: "center"
  },

  differentMaxWidth: {
    width: "10%",
    maxWidth: "10%",
    flexBasis: "10%"
  },
  satisfactory : {
    color: "#4caf50",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },
  unsatisfactory: {
    color: "#d02d52",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },
  error: {
    backgroundColor: "#f8e0e9",
  },
});

