export default (theme) => ({
  marginBottom: {
    marginBottom: "32px"
  },

  table: {
    "& td:first-of-type": {
      width: "80%"
    }
  },

  name: {
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },

  value: {
    color: "#616161",
    fontWeight: theme.typography.fontWeightMedium,
  },

  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#cccccc",
    margin: "20px 0"
  },
});

