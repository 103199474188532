export default () => {
  return ({
    pipesCell: {
      minWidth: 150,
    },
    actionCell: {
      padding: 0,
      "& svg": {
        cursor: "pointer",
        fontSize: 20,
      }
    },
    uploadButton: {
      "& span": {
        width: "inherit",
      },
      "& svg": {
        filter: "invert(32%) sepia(13%) saturate(9%) hue-rotate(0deg) brightness(94%) contrast(83%)",
      },
      marginLeft: 5,
      minWidth: 20,
      padding: 12,
      borderRadius: "50%",
    },
    fileCell: {
      "& div": {
        display: "flex",
        alignItems: "center",
        "& div:last-child": {
          maxWidth: 70,
          flexWrap: "nowrap",
          marginBottom: 1,
          "& div": {
            paddingRight: 0,
          }
        },
        "& p": {
          marginRight: 10,
        },
        "& div": {
          marginTop: 0,
        }
      },
      "& img": {
        width: 20,
        height: 20,
      },
      "& svg": {
        fontSize: 20,
      }
    },
    removeButton: {
      marginLeft: 5,
    }
  });
};
