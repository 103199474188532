import {getLocationAddress} from "@core/helpers";
import React from "react";
import Actions from "./components/Actions";

export const columns = (setProductToDeleteId) => [{
  isSorted: "true",
  dataIndex: "creator",
  title: "Creator",
  render: (product) => product.creator.name,
},{
  isSorted: "true",
  dataIndex: "owner",
  title: "Owner",
  render: (product) => product.owner.name,
},{
  isSorted: "true",
  dataIndex: "status",
  title: "Manufacturing status",
},{
  dataIndex: "properties.productType",
  isSorted: "true",
  title: "Product type",
  render: (product) =>  product.properties.productType.name,
}, {
  dataIndex: "properties.quantity",
  isSorted: "true",
  title: "Quantity",
}, {
  dataIndex: "properties.norm",
  isSorted: "true",
  title: "Material specification",
  render: (product) => product.properties.norm || "-",
}, {
  dataIndex: "properties.grade",
  isSorted: "true",
  title: "Grade",
  render: (product) => product.properties.grade || "-",
}, {
  dataIndex: "properties.location",
  isSorted: "true",
  title: "Location",
  render: (product) => product.properties.location ? getLocationAddress(product.properties.location) : "-",
}, {
  dataIndex: "properties.notes",
  isSorted: "true",
  title: "Notes",
  render: (product) => product.properties.notes || "-",
}, {
  dataIndex: "action",
  permanent: true,
  title: "Action",
  render: (product) => (
    <Actions
      product={product}
      onDelete={() => setProductToDeleteId(product._id)}
    />
  )
}];