export default () => ({
  user: {
    color: "#fff",
    background: "#1d1d1d",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
      background: "#3a3a3a",
    },

    "& span": {
      whiteSpace: "nowrap",
    },
  },
  avatar: {
    margin: "10px 20px 10px 10px",
    color: "#fff",
  },
  roundedImage: {
    width: 40,
    height: 40,
    borderRadius: "100%",
    overflow: "hidden",
    marginRight: 10,

    "& img": {
      maxHeight: "100%",
      display: "inline-block",
    },
  },
  company: {
    fontSize: 10,
  }
});