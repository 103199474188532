import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import qs from "qs";
import {Button, Grid} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {pick, tail} from "ramda";
import {FEATURES} from "@core/constants/features";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import {TEST_STATUSES, TABS} from "../../../components/TestsList/data";
import {getColumns} from "./data";
import TestList from "../../../components/TestsList";
import AddTests from "./components/AddTestsForm";
import useStores from "../../../../useStores";

const LabTestList = () => {
  const [testToDelete, setTestToDelete] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const {TestStore, UserStore} = useStores();

  const {tests: {fetch: {limit}}} = TestStore;

  const currentTab = qs.parse(tail(location.search)).view || TABS.OPENED;

  const canParseCsv = UserStore.features[FEATURES.PARSE_CSV];
  const canParseXls = UserStore.features[FEATURES.PARSE_XLS];

  const deleteTest = async (id) => {
    setTestToDelete(null);
    await TestStore.delete(id);
  };

  const redirectToOpened = () => {
    if (currentTab !== TABS.OPENED) history.push(`/tests?status=${TABS.OPENED}`);
    else TestStore.tests.load({limit, offset: 0, statuses: TEST_STATUSES[TABS.OPENED]});
  };

  return (
    <>
      <TestList
        testStatuses={pick([
          TABS.OPENED,
          TABS.UNSIGNED,
          TABS.SUBMITTED,
          TABS.CLOSED,
          TABS.BIN
        ], TEST_STATUSES)}
        currentTab={currentTab}
        columns={getColumns(setTestToDelete)}
      >
        <Grid item>
          <Button
            color={"primary"}
            size={"medium"}
            variant={"contained"}
            onClick={() => history.push("/tests/multiSigning")}
          >
            Multiple sign
          </Button>
        </Grid>
        <Grid item>
          <AddTests onSuccess={redirectToOpened} />
        </Grid>
        {(canParseCsv || canParseXls) && (
          <Grid item>
            <Button
              color={"primary"}
              size={"medium"}
              variant={"contained"}
              onClick={() => history.push("/tests/importFromJSON")}
            >
              Import tests from JSON
            </Button>
          </Grid>
        )}
      </TestList>
      <Confirmation
        open={testToDelete}
        onCancel={() => setTestToDelete(null)}
        onConfirm={() => deleteTest(testToDelete)}
        alertText="Are you sure you want to remove the test?"
      />
    </>
  );
};

export default observer(LabTestList);
