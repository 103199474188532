import React from "react";
import {TableBody, TableHead, Table, TableRow, TableCell} from "@mui/material";

const CorrosionTest = ({qcpConfig}) => {
  return (
    <>
      <h3>Local Requirements</h3>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell>Test Standard</TableCell>
            <TableCell>Method</TableCell>
            {qcpConfig.properties.maxCorrosionRate && <TableCell>Max Corrosion rate</TableCell>}
            {qcpConfig.properties.maxWeightLoss && <TableCell>Max Weight loss</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{qcpConfig.properties.testStandard}</TableCell>
            <TableCell>{qcpConfig.properties.method}</TableCell>
            {qcpConfig.properties.maxCorrosionRate && <TableCell>{qcpConfig.properties.maxCorrosionRate.value} {qcpConfig.properties.maxCorrosionRate.unit}</TableCell>}
            {qcpConfig.properties.maxWeightLoss && <TableCell>{qcpConfig.properties.maxWeightLoss.value} {qcpConfig.properties.maxWeightLoss.unit}</TableCell>}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default CorrosionTest;