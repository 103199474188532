import React from "react";
import {Grid, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ProjectId from "@core/components/ProjectId";
import ApprovePortalCertificate from "../ApprovePortalCertificate";
import styles from "./styles";

const TransactionsInfo = ({user, certificate, classes}) => {
  if(!certificate.orderNumber) return null;

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.transferInfo}>
      <Grid item xs={10} container spacing={2} alignItems="center">
        <Grid item>
          <Typography component="h6" variant="h6">
            PO:&nbsp;<b>{certificate.orderNumber}</b>
          </Typography>
        </Grid>
        {certificate.lineItem && (
          <Grid item>
            <Typography component="h6" variant="h6">
              PO Item No:&nbsp;<b> {certificate.lineItem}</b>
            </Typography>
          </Grid>
        )}
        {certificate.projectId && (
          <Grid item>
            <h3>
              <Typography component="h6" variant="h6">
                Project ID:&nbsp;
                <b>
                  <ProjectId
                    certificate={certificate}
                  />
                </b>
              </Typography>
            </h3>
          </Grid>
        )}
      </Grid>
      {certificate.viewer && (
        <Grid item xs={2} container justifyContent="flex-end">
          <ApprovePortalCertificate
            user={user}
            certificate={certificate}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(TransactionsInfo, styles);