import React, {useState} from "react";
import {observer} from "mobx-react";
import {Formik} from "formik";
import * as yup from "yup";
import {Dialog, DialogTitle, DialogContent, Button, Grid, MenuItem} from "@mui/material";
import {MuiAutocomplete} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import ProjectIdAutocomplete from "@core/components/ProjectIdAutocomplete";
import SelectField from "@core/components/FormikSelect";
import {omitEmptyFields} from "@core/helpers";
import useStores from "../../../../useStores";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const initialValues = {
  transferId: "",
  projectId: "",
  lineItem: "",
  customerId: "",
  orderNumber: "",
};

const AddToOutgoingOrder = ({open, onClose}) => {
  const {CertificateStore, TransferStore, UserStore, NotificationStore, ProjectStore} = useStores();

  const [addNewOrder, setAddNewOrder] = useState(false);

  const activeProducers = TransferStore.producers.filter((prod) => prod.active);

  const validationSchema = yup.object().shape({
    transferId: !addNewOrder ? yup.string().required("The field is required!") : yup.string(),
    projectId: yup.string(),
    lineItem: yup.string(),
    customerId: addNewOrder ? yup.string().required("The field is required!") : yup.string(),
    orderNumber: addNewOrder ? yup.string().required("The field is required!") : yup.string(),
  });

  const addToOrder = async ({transferId, projectId, lineItem}) => {
    const certificateId = CertificateStore.certificate.data._id;

    const certificate = omitEmptyFields({
      _id: certificateId,
      projectId,
      lineItem
    });
    await CertificateStore.addCertificateToOutgoingOrder(transferId, [certificate]);

    if(projectId) {
      await ProjectStore.linkOrder(projectId, transferId);
    }

    await CertificateStore.getCertificateById(certificateId);

    onClose();
    NotificationStore.showSuccess("Successfully added to customer order");
  };

  const createOrder = async (values) => {
    await TransferStore.createTransfer({
      receiver: values.customerId,
      sender: UserStore.user.data.company._id,
      orderNumber: values.orderNumber,
    });

    await addToOrder({transferId: TransferStore.transfer._id, projectId: values.projectId, lineItem: values.lineItem});
    setAddNewOrder(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        onClose();
        setAddNewOrder(false);
      }}
    >
      <DialogTitle>Add certificate to a customer order</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={addNewOrder ? createOrder : addToOrder}
        >
          {(props) => (
            <Grid container spacing={3}>
              {!addNewOrder && (
                <Grid xs={12} item>
                  <MuiAutocomplete
                    url={`${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open&type=sender`}
                    inputProps={{
                      label: "Customer order",
                      name: "transferId",
                      required: true
                    }}
                    formatOptions={(data) => data.items.map((item) => ({label: item.orderNumber, value: item._id}))}
                    onCreateNew={() => setAddNewOrder(true)}/>
                </Grid>
              )}
              {addNewOrder && (
                <>
                  <Grid item xs={6}>
                    <SelectField
                      name='customerId'
                      label='Customer'
                      required
                    >
                      {activeProducers.map((producer) => (
                        <MenuItem key={producer._id} value={producer._id}>
                          {producer.name}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label='PO Number'
                      name='orderNumber'
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <ProjectIdAutocomplete
                  name='projectId'
                  label="Project ID"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='PO item'
                  name='lineItem'
                />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <Button
                  disabled={!props.isValid}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={props.handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default observer(AddToOutgoingOrder);