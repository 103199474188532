import {Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import React from "react";

const Acceptance = ({analysis, elements, poItem}) => (
  <Table className="styled-table">
    <TableHead>
      <TableRow>
        <TableCell />
        {elements.map((element) => (
          <Tooltip title={element.formula || ""}>
            <TableCell>{element.bm}</TableCell>
          </Tooltip>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>Min</TableCell>
        {elements.map((element) => {
          if(element.analysis && analysis) {
            return (
              <TableCell>{element.analysis[analysis].min || "-"}</TableCell>
            );
          }

          const calculatedMin = element.getMin ? element.getMin({poItem}) : null;

          return (
            <TableCell>{calculatedMin || element.min || "-"}</TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell>Max</TableCell>
        {elements.map((element) => {
          if(element.analysis && analysis) {
            return (
              <TableCell>{element.analysis[analysis].max || "-"}</TableCell>
            );
          }

          const calculatedMax = element.getMax ? element.getMax({poItem}) : null;

          return (
            <TableCell>{calculatedMax || element.max || "-"}</TableCell>
          );
        })}
      </TableRow>
    </TableBody>
  </Table>
);

export default Acceptance;