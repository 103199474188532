import {getTestName} from "@core/helpers";
import moment from "moment/moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";

export const testColumns = [{
  title: "Test",
  render: (test) => getTestName(test)
}, {
  title: "Requested on",
  render: (test) => moment(test.date_created).format(DATE_FORMAT)
}];

export const certificateColumns = [{
  title: "Certificate ID",
  render: (certificate) => certificate._id
}, {
  title: "Heat",
  render: (certificate) => certificate.heat
}];

export const orderColumns =  [{
  title: "PO Number",
  render: (transfer) => transfer.orderNumber,
}, {
  title:  "Receiver",
  render: (transfer) => transfer.receiver.name,
}, {
  title: "Date of issue",
  render: (transfer) => moment(transfer.date_created).format(DATE_FORMAT),
}, {
  title: "Certificates",
  render: (transfer) => transfer.certificates.length,
}];