import React from "react";
import {Button, TableRow, TableCell} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const Treatments = ({
  treatments,
  isPrefilled,
  editTreatment,
  removeTreatment,
  classes,
}) => (
  <>
    {treatments.map((item, index) => {
      const treatmentName = item.type === "treatment_test" ? item.properties.elements[0].treatment : item.properties?.type;

      return (
        <TableRow key={treatmentName + index}>
          <TableCell className={classes.tableCell}>{treatmentName}</TableCell>

          <TableCell align="center">
            <Button
              color='primary'
              onClick={() => editTreatment(index, isPrefilled)}
            >
                Edit
            </Button>

            <Button
              onClick={() => removeTreatment(index, isPrefilled)}
            >
                Remove
            </Button>
          </TableCell>
        </TableRow>
      );
    })}
  </>
);

export default withStyles(Treatments, styles);
