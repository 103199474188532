import React, {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {indexBy, prop, uniq} from "ramda";
import qs from "qs";
import Coating from "./components/Coating";
import Welding from "./components/Welding";
import {FILTERS} from "@core/constants/filters";
import {VIEWS} from "./constants";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {TYPES, TEST_ID_PARAM} from "@core/constants/test";
import {capitalizeFirstLetter} from "@core/helpers";
import useStores from "../../useStores";
import {getAsBuiltViewTests, getGlobalViewTests, filterTests} from "./helpers";

const COMPONENT_BY_CAMPAIGN_TYPE = {
  [CAMPAIGN_TYPES.COATING]: Coating,
  [CAMPAIGN_TYPES.WELDING]: Welding,
};

const CampaignDetails = observer(({match, location, history}) => {
  const {CampaignStore, FilterStore} = useStores();
  
  const [selectedTestIds, setSelectedTestIds] = useState([]);
  
  const {id: campaignId, type: savedCampaignType} = match.params;

  const campaign = CampaignStore.campaign;
  const campaignTests = campaign.tests || [];
  const campaignProductsById = CampaignStore.campaignProductsById;

  const campaignType = useMemo(() => campaign.type || capitalizeFirstLetter(savedCampaignType), [campaign._id]);
  const testsById = useMemo(() => indexBy(prop("_id"), campaignTests), [campaignTests]);

  const {view = VIEWS.REPORT, ...search} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const [dateA, dateB] = FilterStore.filters[FILTERS.DATE_CREATED] || [];
  const specimens = FilterStore.filters[FILTERS.SPECIMEN_ID] || [];
  const batchNumbers = FilterStore.filters[FILTERS.BATCH_NUMBER] || [];
  const welds = FilterStore.filters[FILTERS.WELD_ID] || [];

  const specimensList = specimens.join();
  const batchNumbersList = batchNumbers.join();
  const weldsList = welds.join();

  const weldedPipes = useMemo(() => {
    if(!campaign.welds) return [];

    return campaign.welds.reduce((pipes, weld) => {
      if(campaignProductsById[weld.pipeA]) {
        pipes.push(campaignProductsById[weld.pipeA].pipeNumber);
      }

      if(campaignProductsById[weld.pipeB]) {
        pipes.push(campaignProductsById[weld.pipeB].pipeNumber);
      }

      return pipes;
    }, []);
  }, [campaign.welds]);
  
  const selectedTests = useMemo(() => selectedTestIds.length ?
    selectedTestIds.reduce((acc, id) => testsById[id] ? acc.concat(testsById[id]) : acc, []) :
    campaignTests, [selectedTestIds, testsById]);
  
  const filteredTests = useMemo(() => {
    return filterTests(FilterStore.filters, selectedTests, campaign.rawMaterials);
  }, [selectedTests, dateA, dateB, specimensList, weldsList, batchNumbersList, campaignTests]);

  const testsForView = useMemo(() => {
    switch (view) {
    case VIEWS.GLOBAL:
      return getGlobalViewTests(filteredTests);
    case VIEWS.AS_BUILT:
      return getAsBuiltViewTests(filteredTests, weldedPipes);
    default:
      return filteredTests;
    }
  }, [view, filteredTests, weldedPipes]);

  useEffect(() => {
    CampaignStore.getCampaignById(campaignId);

    return () => {
      FilterStore.setFilters({});
      CampaignStore.removeCampaign();
    };
  }, []);
  
  useEffect(() => {
    if(!campaign._id) return;

    const heats = uniq(campaign.products.map((product) => product.heat));

    if(!heats.length) return;

    CampaignStore.getRelatedTransfers(heats);  
  }, [campaign._id]);

  const onTestClick = useCallback(([test]) => {
    const id = view === VIEWS.GLOBAL && test.type === TYPES.CUSTOM ? test.displayName : test._id;

    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        ...search,
        [TEST_ID_PARAM]: id
      })
    });
  }, [location.search]);
  
  const Component = campaignType ? COMPONENT_BY_CAMPAIGN_TYPE[campaignType] : COMPONENT_BY_CAMPAIGN_TYPE[CAMPAIGN_TYPES.COATING];

  return (
    <Component
      tests={testsForView}
      setFilteredTestIds={setSelectedTestIds}
      onTestClick={onTestClick}
    />
  );
});

export default CampaignDetails;
