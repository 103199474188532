import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const Acceptance = ({config}) => {
  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Absorbed Energy (Single) [J]</TableCell>
          <TableCell>Absorbed Energy (Average) [J]</TableCell>
          <TableCell>Shear Area (Single) [%]</TableCell>
          <TableCell>Shear Area (Average) [%]</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Min</TableCell>
          <TableCell>{config.single}</TableCell>
          <TableCell>{config.average}</TableCell>
          <TableCell>{config.singleShearArea}</TableCell>
          <TableCell>{config.averageShearArea || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Max</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Acceptance;