import React, {memo} from "react";
import Pipe from "../../../Stalk/components/AnimatedPipe";
import {BASE_MATERIAL_PIPE_CONFIG} from "../../../Stalk/constants";

const BaseMaterialPipe = ({...props}) => {
  return (
    <Pipe
      {...props}
      fill={BASE_MATERIAL_PIPE_CONFIG.BACKGROUND_COLOR}
    />
  );
};

export default memo(BaseMaterialPipe);