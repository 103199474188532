import React from "react";
import {Dialog, DialogContent, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import FerriteForm from "@core/components/TestForms/FerriteContent";
import ExaminationConditions from "@core/components/ExaminationConditions";

const ByFerriteScope = ({classes, test, addDataOpen, dataDialogClose, updateTest, formRef}) => (
  <>
    <Grid item xs={6}>
      <h4 className={classes.name}>Piece Identification:</h4>
      <p className={classes.value}>{test.properties.pieceIdentification}</p>
    </Grid>

    <ExaminationConditions
      test={test}
      file={test.properties.file}
    />

    <Grid item xs={12} container>
      {test.properties.elements.map((testInputs, elementIndex) => (
        <>
          <Grid item xs={12}>
            <h3>{test.properties.examinationConditions && test.properties.examinationConditions[elementIndex]?.value}</h3>
          </Grid>
          {testInputs.map((testInput, testInputIndex) => (
            <Grid key={testInputIndex} item xs={3}>
              <h4 className={classes.name}>Test result {testInputIndex + 1}</h4>
              <p className={classes.value}>{testInput}</p>
            </Grid>
          ))}
        </>
      ))}
    </Grid>
    <Dialog
      open={addDataOpen}
      onClose={dataDialogClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <FerriteForm
          isProducerTest
          formRef={formRef}
          saveTest={updateTest}
          test={test}
        />
      </DialogContent>
    </Dialog>
  </>
);

export default withStyles(ByFerriteScope, styles);