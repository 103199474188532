import React from "react";
import {Chip} from "@mui/material";

const Tags = ({tags}) => {
  return tags.map((tag, index) => (
    <Chip
      color="primary"
      size="small"
      label={tag}
      sx={{
        backgroundColor: (theme) => theme.palette.warning.text,
        marginLeft: index ? 1 : null,
      }}
    />
  ));
};

export default Tags;