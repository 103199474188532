import React from "react";
import {Chart} from "react-chartjs-2";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const InfoPlot = ({classes, text}) => {
  const plugins = [
    {
      afterDraw: (chart) => {
        const ctx = chart.ctx;
        const width = chart.width;
        const height = chart.height;
        ctx.save();
        ctx.fillStyle = "#f6f6f6";
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      },
    },
  ];

  return (
    <div className={classes.container}>
      <p className={classes.text}>{text}</p>
      <Chart
        type="line"
        data={{labels: [], datasets: []}}
        plugins={plugins}
        options={{maintainAspectRatio: false}}
      />
    </div>
  );
};

export default withStyles(InfoPlot, styles);
