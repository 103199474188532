import React, {memo, useMemo} from "react";
import Pipe from "../AnimatedPipe";
import {PIPE_WITH_TYPE_CONFIG} from "../../../../constants";
import useStores from "../../../../../../../../../../useStores";
import {observer} from "mobx-react-lite";

const TypePipe = observer(({pipe, ...props}) => {
  const {CampaignStore} = useStores();

  const baseMaterialsTypes = CampaignStore.baseMaterialsTypes;

  const color = useMemo(() => {
    const index = baseMaterialsTypes.findIndex((t) => t === pipe.type);

    return PIPE_WITH_TYPE_CONFIG.BACKGROUND_COLORS[index];
  }, [baseMaterialsTypes, pipe.type]);

  return (
    <Pipe
      {...props}
      fill={color || props.pipeConfig.BACKGROUND_COLOR}
    />
  );
});

export default memo(TypePipe);