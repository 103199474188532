import React, {Component} from "react";
import {Snackbar, SnackbarContent} from "@mui/material";
import {withStyles} from "tss-react/mui";
import InfoIcon from "@mui/icons-material/Info";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import IconButton from "@mui/material/IconButton";
import {inject, observer} from "mobx-react";
import classNames from "classnames";

import styles from "./styles";
import {compose} from "ramda";

class Notification extends Component {
  render() {
    const {classes, notification, notificationID} = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open
        autoHideDuration={5000}
        onClose={() => this.props.NotificationStore.removeNotification(notificationID)}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          className={classNames(classes.infoMessage, classes[notification.type])}
          message={
            <span id="client-snackbar" className={classes.message}>
              {notification.type === "info" && <InfoIcon style={{marginRight: 5}} />}
              {notification.type === "success" && <DoneIcon style={{marginRight: 5}} />}
              {notification.type === "error" && <ErrorIcon style={{marginRight: 5}} />}

              <span className={classes.text}>{notification.message}</span>
              {notification.button && <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.actions}
                onClick={notification.callback}
                size="large">
                {notification.button ? <notification.button.icon /> : <span>{notification.button.text}</span>}
              </IconButton>}
            </span>
          }
        />
      </Snackbar>
    );
  }
}

export default compose(
  inject("NotificationStore"),
)(withStyles(observer(Notification), styles));