import InfoIcon from "@mui/icons-material/InfoOutlined";
import React, {useCallback} from "react";
import {Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import {Uploader} from "@core/components/Uploaders";
import {MEASUREMENTS_CONFIG, LOCATIONS} from "../../data";
import {Formik, useFormikContext} from "formik";

const SINGLE_VALUE_REGEX = /[^0-9.]+/g;

const EditPopup = ({onClose, index, measurements}) => {
  const {values, setFieldValue} = useFormikContext();

  const element = values.elements[index];

  const onSubmit = useCallback((values) => {
    setFieldValue(`elements.${index}`, values);

    onClose();
  }, [index]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogTitle>Pipe #{index + 1}</DialogTitle>
      <Formik
        initialValues={element}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(props) => {

          const cleanUpSingleValue = (value) => {
            const numbers = value.replace(SINGLE_VALUE_REGEX, "").split(".");

            return numbers.slice(0, 2).join(".") + numbers.slice(2);
          };
          
          const cleanUpMultipleValues = (value) => {
            const numbers = value.split(",");

            return numbers.map((number) => cleanUpSingleValue(number)).join(",");
          };

          const onChange = (value, measurement, name) => {
            const handler = MEASUREMENTS_CONFIG[measurement].multiple ? cleanUpMultipleValues : cleanUpSingleValue;

            props.setFieldValue(name, handler(value));
          };

          return (
            <DialogContent>
              <Grid container spacing={3}>
                {measurements.map((measurement) => (
                  <Grid item container xs={12} spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sx={{display: "flex", alignItems: "center"}}
                    >
                      <h3>{MEASUREMENTS_CONFIG[measurement].title}</h3>
                      {MEASUREMENTS_CONFIG[measurement].multiple && (
                        <Tooltip title="Enter multiple values separated with comma">
                          <InfoIcon
                            sx={{cursor: "pointer", marginLeft: 1}}
                            fontSize="small"
                            color="info"
                          />
                        </Tooltip>
                      )}
                    </Grid>
                    {MEASUREMENTS_CONFIG[measurement].locations ?
                      MEASUREMENTS_CONFIG[measurement].locations.map((location) => (
                        <Grid item xs={4}>
                          <TextField
                            label={LOCATIONS[location]}
                            name={`${measurement}.${location}`}
                            endAdornment={values.units[measurement]}
                            onChange={(value) => onChange(value, measurement, `${measurement}.${location}`)}
                          />
                        </Grid>
                      )) : (
                        <Grid item xs={4}>
                          <TextField
                            inputProps={{min: 1}}
                            name={measurement}
                            endAdornment={values.units[measurement]}
                            onChange={(value) => onChange(value, measurement, measurement)}
                          />
                        </Grid>
                      )}
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Uploader
                    fileType="image/*, application/pdf, .csv"
                    file={props.values.file}
                    buttonText="Choose file"
                    handleUploadedFile={(file) => {
                      props.setFieldValue("file", file.file.dir + file.file.name);
                    }}
                    onRemove={() => props.setFieldValue("file", "")}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    onClick={props.handleSubmit}
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditPopup;