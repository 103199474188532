import React from "react";
import moment from "moment";
import {
  Button,
  Grid,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Dialog,
  DialogContent, DialogActions
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import File from "@core/components/File";
import Image from "@core/components/Image";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import styles from "./styles";
import classNames from "classnames";

const CompanyInfo = ({company, classes, handleClose}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.companyInfo}>
        <Grid container spacing={5}>
          {company.logo && <Grid item xs={4} className={classes.imageContainer}>
            <Image src={company.logo} alt="company logo" className={classes.companyLogo}/>
          </Grid>}
          <Grid container item xs={8} spacing={2}>
            <Grid item>
              <h3 className={classes.infoH1}>{company.name}</h3>
            </Grid>
            <Grid item className={classes.address}>
              <span>Address:</span>
              <span className={classes.field}>{company.mainLocation.street} {company.mainLocation.house}<br/>
                {company.mainLocation.postalCode} {company.mainLocation.city} {company.mainLocation.country}</span>
              <p>
                <span>Phone:</span>
                <span className={classes.field}>{company.phone}</span>
              </p>
              {company.fax && <p>
                <span>Fax:</span>
                <span className={classes.field}>{company.fax}</span>
              </p>}
              <p>
                <span>Mail:</span>
                <span className={classes.field}>{company.email}</span>
              </p>
              {company.website && <p>
                <span>Website:</span>
                <span className={classes.field}>{company.website}</span>
              </p>}
            </Grid>
          </Grid>

          {company.partners.length > 0 && <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <h3>Partners:</h3>
            </Grid>
            <Grid container item xs={12} >
              {company.partners.map((partner) => (
                <Grid item>
                  <a href={partner.url} className={classes.partnersAvatar}>
                    <Image src={partner.image} alt="partner logo"/>
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>}

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <h3>Company certification:</h3>
            </Grid>
            <Grid item xs={12} className={classes.approvals}>
              {company.approvals.length === 0 ?
                <p>No company certification yet</p> :
                <Table className={classNames("styled-table")}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Issuing date</TableCell>
                      <TableCell>Expiration Date</TableCell>
                      <TableCell>File</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody classes={{root: classes.approvalsTable}}>
                    {company.approvals.map((approval) => <TableRow>
                      <TableCell>{approval.description ? approval.description : "-"}</TableCell>
                      <TableCell>{(approval.from) ? moment(approval.from).format(DATE_FORMAT): "-"}</TableCell>
                      <TableCell>{(approval.to) ? moment(approval.to).format(DATE_FORMAT) : "-"}</TableCell>
                      <TableCell>
                        <File
                          file={approval.link}
                        />
                      </TableCell>
                    </TableRow>
                    )}
                  </TableBody>
                </Table>
              }
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <a href="" className={classes.download}>*/}
        {/*  /!* <SvgIcon color="primary">*!/*/}
        {/*  /!*  <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>*!/*/}
        {/*  /!*  <path d="M0 0h24v24H0z" fill="none"/>*!/*/}
        {/*  /!* </SvgIcon>*!/*/}
        {/*  /!* <span>Download Cert</span>*!/*/}
        {/* </a>*/}
        <Button size="large" variant="contained" color="primary" onClick={handleClose} autoFocus>
            Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(CompanyInfo, styles);
