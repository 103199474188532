import qs from "qs";
import React, {useEffect, useMemo, useState} from "react";
import {flatten, indexBy, prop} from "ramda";
import {observer} from "mobx-react-lite";
import {withStyles} from "tss-react/mui";
import {Grid} from "@mui/material";
import TestSummaryTable from "@core/components/TestSummaryTable";
import styles from "./styles";
import {PAGE_TABS} from "../constants";
import {STATUSES, TEST_ID_PARAM} from "@core/constants/test";
import {useLocation, useParams} from "react-router";
import useStores from "../../../../useStores";

const TestStatusSummary = observer(({classes, certificates, campaigns, setTestsToView}) => {
  const [filteredTestIds, setFilteredTestIds] = useState([]);

  const {TransferStore} = useStores();
  
  const {id: transferId} = useParams();
  const location = useLocation();

  const {[TEST_ID_PARAM]: testId} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const transfer = TransferStore.transfer;

  const certificateTests = flatten(certificates.map((certificate) => certificate.tests));
  const campaignTests = flatten(campaigns.map((campaign) => campaign.tests));

  const tests = certificateTests.concat(campaignTests);
  const testsById = useMemo(() => indexBy(prop("_id"), tests), [tests]);

  const filteredTests = filteredTestIds.length ? filteredTestIds.map((id) => testsById[id]) : tests;

  const isLoaded = transfer._id === transferId && transfer.certificates.length === certificates.length;

  useEffect(() => {
    if(!testId || !isLoaded) return;

    const test = tests.find((t) => t._id === testId);

    setTestsToView(test ? [test] : []);
  }, [isLoaded]);

  return (
    <div className={classes.content}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <h3
            id={PAGE_TABS.TEST_SUMMARY}
            className={classes.title}
          >
            Test Summary
          </h3>
        </Grid>
      </Grid>
      <TestSummaryTable
        isLoaded={isLoaded}
        onTestClick={setTestsToView}
        tests={filteredTests}
        withGrouping
        setFilteredTestIds={setFilteredTestIds}
        getClickDisabled={(test) => [STATUSES.EMPTY, STATUSES.ASSIGNED].includes(test.status)}
      />
    </div>
  );
});

export default withStyles(TestStatusSummary, styles);