import React, {useCallback, useRef, useState} from "react";
import {equals, keys} from "ramda";
import {Button, Tab, Tabs} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ConfirmationOnModalClose from "@core/components/ConfirmationOnModalClose";
import ResizableDrawer from "@core/components/ResizableDrawer";
import DrawerHeader from "@core/components/DrawerHeader";
import AdvancedForm from "./components/CertificatesForm";
import SimpleForm from "./components/SimpleForm";
import styles from "./styles";
import axios from "axios";
import {observer} from "mobx-react";
import useStores from "../../../../useStores";
import {omitEmptyFields} from "@core/helpers";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const TYPES = {
  SIMPLE: "Simple",
  ADVANCED: "Advanced"
};

const COMPONENT_BY_ACTION = {
  [TYPES.SIMPLE]: SimpleForm,
  [TYPES.ADVANCED]: AdvancedForm,
};
const BulkCreateCertificates = observer(({classes}) => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(TYPES.SIMPLE);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  
  const {NotificationStore, CertificateStore} = useStores();

  const formRef = useRef();

  const onSubmit = useCallback(async ({certificates, items}) => {
    
    const data = certificates.map((certificate) => (
      omitEmptyFields({
        ...certificate,
        items,
        properties: {...certificate.properties, productType: certificate.properties.productType._id}
      })
    ));
    
    await axios.post(ROUTES.CERTIFICATE[ACTIONS.CREATE_BULK], data);

    await CertificateStore.certificates.load();

    NotificationStore.showInfo("Successfully created!");

    setOpen(false);
  }, []);

  const onClose = useCallback(() => {
    if (equals(formRef.current.values, formRef.current.initialValues)) setOpen(false);
    else setConfirmationOpen(true);
  }, []);

  const Component = COMPONENT_BY_ACTION[action];

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Bulk create
      </Button>
      <ResizableDrawer
        open={open}
        close={onClose}
        minWidth={850}
      >
        <DrawerHeader
          close={onClose}
          text="Create Certificate(s)"
        />
        <div className={classes.tabs}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={action}
            onChange={(e, value) => setAction(value)}
          >
            {keys(TYPES).map((tab) => (
              <Tab
                key={tab}
                label={TYPES[tab]}
                value={TYPES[tab]}
              />
            ))}
          </Tabs>
        </div>
        <div className={classes.content}>
          <Component
            onSubmit={onSubmit}
            formRef={formRef}
          />
        </div>
      </ResizableDrawer>
      <ConfirmationOnModalClose
        confirmationOpen={confirmationOpen}
        formRef={formRef}
        setConfirmationOpen={setConfirmationOpen}
        onClose={() => setOpen(false)}
        onSave={() => onSubmit(formRef.current.values.certificates)}
      />
    </>
  );
});

export default withStyles(BulkCreateCertificates, styles);