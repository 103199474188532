import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {groupBy, concat, keys, prop, mergeDeepWith, flatten} from "ramda";
import {getDefectReports} from "../../../../../../../../helpers";
import useStores from "../../../../../../../../../../useStores";
import {useParams} from "react-router";
import {Table, TableHead, TableRow, TableCell, TableBody, Box} from "@mui/material";
import TableSkeleton from "@core/components/TableSkeleton";

const StatisticByDefectType = ({totalDefects}) => {
  const {CampaignStore} = useStores();

  const {id: campaignId} = useParams();

  const campaign = CampaignStore.campaign;

  const isLoaded = campaignId === campaign._id;

  const {tests = [], welds = []} = campaign;

  const weldNumbers = useMemo(() => welds.map((weld) => weld.weldNumber), [welds]);

  const defectReports = useMemo(() => getDefectReports(tests), [tests]);
  
  const defectsByType = useMemo(() => {
    return defectReports.reduce((defectsByType, test) =>  {
      const defectTypeCellName = flatten(test.properties.data
        .map((data) => keys(data)))
        .find((name) => name.includes("Crack Type"));
      
      const filtered = test.properties.data.filter((data) => weldNumbers.includes(data["Weld Number"]));
      
      const testDefectsByType = groupBy(prop(defectTypeCellName), filtered);

      return mergeDeepWith(concat, defectsByType, testDefectsByType);
    }, {});
  }, [defectReports, weldNumbers]);

  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>Crack Type</TableCell>
          <TableCell>No. of Cracks</TableCell>
          <TableCell>% of Cracks</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoaded ? (
          <>
            {keys(defectsByType).map((type) => (
              <TableRow>
                <TableCell>{type}</TableCell>
                <TableCell>{defectsByType[type].length}</TableCell>
                <TableCell>{(defectsByType[type].length * 100 / totalDefects.length).toFixed(2)} %</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Box sx={{fontWeight: (theme) => theme.typography.fontWeightMedium, display: "inline"}}>Total</Box>: {keys(defectsByType).length} Types
              </TableCell>
              <TableCell colSpan={2}>{totalDefects.length}</TableCell>
            </TableRow>
          </>
        ) : (
          <TableSkeleton
            columns={3}
          />
        )}
      </TableBody>
    </Table>
  );
};

export default observer(StatisticByDefectType);