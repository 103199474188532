import React, {useState} from "react";
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";

const CertificateDeleteConfirmationModal = ({open, close, onConfirm}) => {
  const [deleteTests, setDeleteTests] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={close}
    >
      <DialogTitle id="alert-dialog-title">
        Please choose appropriate option and confirm your action
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          row
          value={deleteTests}
          onChange={() => setDeleteTests(!deleteTests)}
        >
          <FormControlLabel value={true} control={<Radio color="primary" />} label="Delete all tests associated with this certificate" />
          <FormControlLabel value={false} control={<Radio color="primary" />} label="Keep the tests available" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => onConfirm(deleteTests)} color="primary" variant="contained" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CertificateDeleteConfirmationModal;