import React from "react";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import File from "@core/components/File";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const SulphideStressCorrosionCracking = ({classes, test}) => {
  return (
    <Grid container spacing={1}>
      <Grid item container alignContent="space-between" spacing={5}>
        {(test.company?.name || test.properties.client) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Client</h4>
            <p className={classes.value}>{test.company?.name || test.properties.client}</p>
          </Grid>
        )}
        {(test.assignee?.name || test.properties.lab) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Laboratory</h4>
            <p className={classes.value}>{test.assignee?.name || test.properties.lab}</p>
          </Grid>
        )}
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        <Grid item xs={3}>
          <h4 className={classes.name}>Test standard</h4>
          <p className={classes.value}>{test.properties.testStandard}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Acceptance criteria</h4>
          <p className={classes.value}>{test.properties.acceptance}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Stress method</h4>
          <p className={classes.value}>{test.properties.stressMethod}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Sample ID</h4>
          <p className={classes.value}>{test.properties.sampleID}</p>
        </Grid>
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        <Grid item xs={3}>
          <h4 className={classes.name}>Test solution</h4>
          <p className={classes.value}>{test.properties.solution}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Test duration</h4>
          <p className={classes.value}>{test.properties.duration} hrs</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Test temperature</h4>
          <p className={classes.value}>{`${test.properties.temperature} +/- ${test.properties.temperatureTolerance} °C`}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Test pressure</h4>
          <p className={classes.value}>{test.properties.pressure} hrs</p>
        </Grid>
      </Grid>
      <Grid item container spacing={5}>
        <Grid item xs={12}>
          <h3>Solution pH</h3>
        </Grid>
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        <Grid item xs={3}>
          <h4 className={classes.name}>Gas mixture</h4>
          <p className={classes.value}>{test.properties.gasMixture}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>
            Initial&nbsp;
            <Tooltip title="Before H2S introduction">
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </h4>
          <p className={classes.value}>{test.properties.solutionPH.before}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>After H2S introduction</h4>
          <p className={classes.value}>{test.properties.solutionPH.after} ppm</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Final</h4>
          <p className={classes.value}>{test.properties.solutionPH.final} ppm</p>
        </Grid>
      </Grid>
      <Grid item container spacing={5}>
        <Grid item xs={12}>
          <h3> H2S concentration</h3>
        </Grid>
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        <Grid item xs={3}>
          <h4 className={classes.name}>Initial</h4>
          <p className={classes.value}>{test.properties.h2sConcentration.initial} ppm</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Final</h4>
          <p className={classes.value}>{test.properties.h2sConcentration.final} ppm</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Yield strength type</h4>
          <p className={classes.value}>{test.properties.yeildStrengthType}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Yield strength value</h4>
          <p className={classes.value}>{test.properties.yeildStrengthValue} Mpa</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Yield strength percentage</h4>
          <p className={classes.value}>{test.properties.yeildStrengthPercentage} %</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Applied stress</h4>
          <p className={classes.value}>{test.properties.appliedLoad} Mpa</p>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell padding="none">Spec. ID</TableCell>
              <TableCell padding="none">Position</TableCell>
              <TableCell padding="none">Orientation</TableCell>
              <TableCell padding="none">Specimen Length [mm]</TableCell>
              <TableCell padding="none">Specimen Width [mm]</TableCell>
              <TableCell padding="none">Specimen Thickness [mm]</TableCell>
              {test.properties.elements.some((e) => e.controlSample) && (
                <TableCell width="100" padding="none">Control Sample</TableCell>
              )}
              {test.properties.elements.some((e) => e.magnification) && (
                <TableCell padding="none">Magnification</TableCell>
              )}
              <TableCell padding="none">Evaluation of cracks</TableCell>
              {test.properties.elements.some((e) => e.files?.length) && (
                <TableCell padding="none">File(s)</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {test.properties.elements.map((element) => (
              <TableRow>
                <TableCell padding="none">{element.specimenId}</TableCell>
                <TableCell padding="none">{element.position}</TableCell>
                <TableCell padding="none">{element.orientation}</TableCell>
                <TableCell padding="none">{element.specimenLength}</TableCell>
                <TableCell padding="none">{element.specimenWidth}</TableCell>
                <TableCell padding="none">{element.specimenThickness}</TableCell>
                {test.properties.elements.some((e) => e.controlSample) && (
                  <TableCell width="100" padding="none">{element.controlSample.toString()}</TableCell>
                )}
                {test.properties.elements.some((e) => e.magnification) && (
                  <TableCell padding="none">{element.magnification || "-"}</TableCell>
                )}
                <TableCell padding="none">{element.evaluationOfCracks}</TableCell>
                {test.properties.elements.some((e) => e.files?.length) && (
                  <TableCell padding="none">
                    {element.files?.length && (
                      <Grid container xs={12} spacing={5}>
                        {element.files.map((image) =>
                          <Grid item xs={4} container alignItems="center" key={image}>
                            <File file={image} />
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      {test.properties.notes && (
        <Grid item xs={12}>
          <p
            className={classes.notes}
            dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(SulphideStressCorrosionCracking, styles);
