import React, {useCallback, useState} from "react";
import {observer} from "mobx-react-lite";
import {Grid, Dialog, DialogContent, DialogTitle, MenuItem, Button} from "@mui/material";
import * as yup from "yup";
import {Formik} from "formik";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import {omitEmptyFields} from "@core/helpers";
import useStores from "../../../../../../useStores";

const PRODUCT = {
  productType: "",
  manufacturer: "",
  description: "",
  heat: "",
  quarantine: false,
  quarantineReason: "",
  pipeNumber: ""
};

const validationSchema = yup.object().shape({
  productType: yup.string(),
  manufacturer: yup.string().required("This field is required!"),
  description: yup.string(),
  heat: yup.string().required("This field is required!"),
  quarantine: yup.bool(),
  quarantineReason: yup.string(),
  pipeNumber: yup.string().required("This field is required!"),
});

const AddBaseMaterial = ({open, close}) => {
  const {CampaignStore, NotificationStore} = useStores();

  const [isLoading, setIsLoading] = useState(false);

  const campaign = CampaignStore.campaign;

  const createProduct = useCallback(async (product) => {
    setIsLoading(true);

    await CampaignStore.updateCampaignById(campaign._id, {products: omitEmptyFields(product)});

    setIsLoading(false);
    close();
    NotificationStore.showSuccess("Successfully created!");
  }, [campaign.products]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Add base material
      </DialogTitle>
      <DialogContent>
        <Formik
          validateOnMount
          initialValues={PRODUCT}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={createProduct}
        >
          {(props) => (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  required
                  label='Pipe ID'
                  name='pipeNumber'
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  required
                  label='Manufacturer'
                  name='manufacturer'
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  required
                  label='Heat'
                  name='heat'
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label='Product type'
                  name='productType'
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label='Description'
                  name='description'
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  name='quarantine'
                  label="Quarantine"
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </SelectField>
              </Grid>
              {props.values.quarantine && (
                <Grid item xs={3}>
                  <TextField
                    label='Quarantine reason'
                    name='quarantineReason'
                  />
                </Grid>
              )}
              <Grid item container justifyContent="flex-end">
                <Button
                  disabled={!props.isValid || isLoading}
                  color='primary'
                  variant="contained"
                  onClick={props.handleSubmit}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default observer(AddBaseMaterial);