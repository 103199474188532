import {INTERFACE_TYPE} from "@core/constants/transfer";

const LINK_TO_CERTIFICATE_BY_INTERFACE_TYPE = {
  [INTERFACE_TYPE.SENDER]: (_, certificateId) => `/certificates/${certificateId}`,
  [INTERFACE_TYPE.RECEIVER]: (transferId, certificateId) => `/transfers/${transferId}/certificate/${certificateId}`
};

const LINK_TO_TRANSFERRED_CERTIFICATE_BY_INTERFACE_TYPE = {
  [INTERFACE_TYPE.SENDER]: (_, certificateId) => `/certificates/archive/${certificateId}`,
  [INTERFACE_TYPE.RECEIVER]: (_, certificateId) => `/certificates/${certificateId}`
};

export const getLinkToCertificate = (interfaceType = INTERFACE_TYPE.SENDER, isTransferred, transferId, certificateId) => {
  const handlerToGetCertificateLink = isTransferred ? LINK_TO_TRANSFERRED_CERTIFICATE_BY_INTERFACE_TYPE[interfaceType] : LINK_TO_CERTIFICATE_BY_INTERFACE_TYPE[interfaceType];

  return handlerToGetCertificateLink(transferId, certificateId);
};