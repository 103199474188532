export default (theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
    color: "#616161",
  },
  table : {
    "& th": {
      color: "#575757",
      fontSize: 14,
    },
    "& td": {
      textTransform: "capitalize"
    },
    "& td:nth-child(5)": {
      maxWidth: 600
    }
  },
  thRoot: {
    "&:focus": {
      color: theme.palette.primary.main,
    }
  },
  thActive: {
    color: theme.palette.primary.main,
  },
  withAvatar: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      marginRight: 10,
    },
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    }
  },
  status200: {
    color: "#4caf50",
  },
  statusNot200: {
    color: "#d02d52"
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
  }
});