export default (theme) => ({
  codeContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 30,
    "& span": {
      lineHeight: "1.3",
    }
  },
  code: {
    marginRight: 5,
  },
  title: {
    marginBottom: 10,
  },
  authCode: {
    marginTop: 20,
  },
  actions: {
    marginTop: 20,
  },
  codeBox: {
    border: "1px solid #eff1f2",
    padding: 10,
  },
  issuerLabel: {
    display: "inline-flex",
    alignItems: "center",
  },
  issuer: {
    marginBottom: 30,
  },
  link: {
    color: theme.palette.primary.main,
  }
});