import baseStore from "@core/helpers/baseStore";
import {TableStore} from "@core/stores";
import axios from "axios";
import {action, computed, makeObservable, observable} from "mobx";
import {isEmpty} from "ramda";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class ArchiveStore extends baseStore {

  constructor(props) {
    super(props);
    makeObservable(this, {
      archives: observable,
      selectedArchives: computed,
      resetArchives: action,
    });
  }

  archives = new TableStore({
    loader: async (queryParams) => {
      const newQueryParams = {...this.archives.fetch, ...queryParams};
      const {offset, limit, search, sort} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value:search},
        select: "-stringified"
      };

      const response = await axios.get(
        ROUTES.ARCHIVE[ACTIONS.ALL],
        {params}
      );

      this.archives.setFetch(newQueryParams);

      return response;
    }
  });

  get selectedArchives() {
    return this.archives.data.filter((c) => this.archives.selected.includes(c._id)) || [];
  }

  resetArchives() {
    this.archives = {
      data: [],
      offset: 0,
      limit: 10,
      total: 0,
      isLoaded: false
    };
  }
}

export default new ArchiveStore();
