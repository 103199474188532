import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import useStores from "../../../../useStores";
import Loader from "@core/components/Loader";
import styles from "./styles";

const BasicCompUpgrade = observer((props) => {
  const [step, setStep] = useState("confirm");
  const {UserStore} = useStores();
  const {classes} = props;

  useEffect(() => {
    if (UserStore.user.data.company.upgradeRequested) setStep("requested");
  }, []);

  const cancelAndReset = () => {
    if(step !== "confirm" || step !== "loading") setStep("requested");
    else setStep("confirm");

    props.onCancel();
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={cancelAndReset}
      aria-labelledby="basicUpgrade"
    >
      <DialogTitle id="basicUpgrade">You can upgrade your account to be able to perform that action</DialogTitle>
      <DialogContent>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            {step === "confirm" &&
            <DialogContentText id="alert-dialog-description">
              It will allow you to operate without any restrictions
            </DialogContentText>}
            {step === "loading" &&
            <>
              <DialogContentText id="alert-dialog-description">
                It will allow you to operate without any restrictions
              </DialogContentText>
              <Loader/>
            </>}
            {step === "finished" &&
            <DialogContentText id="alert-dialog-description">
              Thanks! Your request has been requested! We also have sent you an email with more details.
            </DialogContentText>
            }
            {step === "requested" &&
            <DialogContentText id="alert-dialog-description">
              Your company has been already requested for an upgrade.
              We are currently processing your application.
              Please check your email for more information.
            </DialogContentText>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        {["finished", "requested"].includes(step) ?
          <Button onClick={cancelAndReset} color="secondary">
            close
          </Button> :
          <>
            <Button onClick={cancelAndReset} color="secondary" disabled={step === "loading"}>
              No, thanks
            </Button>
            {/* <Button onClick={sendUpgradeRequest} variant="contained" color="primary" disabled={step === "loading"}> */}
            {/*   Upgrade */}
            {/* </Button> */}
          </>}
      </DialogActions>
    </Dialog>
  );
});

export default withStyles(BasicCompUpgrade, styles);
