import React, {useState} from "react";
import {Collapse, Grid, Box} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SpecimensList from "../SpecimensList";

const MetadataTable = ({activities, test}) => {
  const [collapsed, setIsCollapsed] = useState(true);
  
  return (
    <Grid container spacing={2}>
      <Grid item>
        <h3>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center"
            }}
            onClick={() => setIsCollapsed(!collapsed)}
          >
          Test Information{collapsed ? <ExpandMore /> : <ExpandLess />}
          </Box>
        </h3>
      </Grid>
      <Grid item xs={12}>
        <Collapse
          in={!collapsed}
          timeout="auto"
        >
          <SpecimensList
            test={test}
            activities={activities}
            disabled
          />
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default MetadataTable;