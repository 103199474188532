export default () => ({
  emptyForm: {
    padding: 20,
    paddingTop: 90,
    minHeight: 250,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    borderBottom: "1px solid #e0e0e0",
    "& h2": {
      width: "100%",
      textAlign: "center",
    }
  }
});

