export default () => ({
  paperWidth: {
    maxWidth: 1100,
  },
  notes: {
    "& h6": {
      padding: "20px 10px",
      color: "#616161",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderBottom: 0,
    },
    "& p:first-child": {
      color: "#0000008a",
      padding: "20px 10px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: 0,
    }
  }
});