import {useCallback, useEffect, useRef, useState} from "react";

function useDetachedExecutionAfterSubmit() {
  const [canNavigateAway, setCanNavigateAway] = useState(false);
  const navigateAwayActionCallback = useRef(() => {});
  
  const navigateAway = useCallback((callback) => {
    setCanNavigateAway(true);
    navigateAwayActionCallback.current = callback;
  }, []);
  
  useEffect(() => {
    if (canNavigateAway) {
      navigateAwayActionCallback.current();
    }
  }, [canNavigateAway]);
  
  return {navigateAway};
}

export default useDetachedExecutionAfterSubmit;