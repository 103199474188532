import { ethers } from 'ethers';
import crypto from 'crypto';

const STATUSES = {
  ASSIGNED: "ASSIGNED",
  SUBMITTED: "SUBMITTED",
  APPROVED: "APPROVED",
};

const utils = {
  stringToBytes32: (str) => {
    return ethers.utils.formatBytes32String(str);
  },
  bytes32ToString(bytes32) {
    return ethers.utils.parseBytes32String(bytes32);
  },
  parseStatus(status) {
    switch (status) {
    case 1: return STATUSES.ASSIGNED;
    case 2: return STATUSES.SUBMITTED;
    case 3: return STATUSES.APPROVED;
    default:
      throw new Error("Invalid status");
    }
  },
  /**
   * @param {*} data
   * @summary The function can have any type of data as input, stringifies it using JSON.stringify and computes the hash of that data
   * @returns {object} Returns an object containing the Hash of the data and the string on which the hash function was applied
   */
  computeHash(data) {
    const newHash = crypto.createHash('sha256');
    const stringifiedData = JSON.stringify(data);

    const hexResult = newHash.update(stringifiedData).digest().toString('hex');
    return { hash: `0x${hexResult}`, hashedString: stringifiedData };
  }
};

export default utils;