import React, {useMemo, useState} from "react";
import {observer} from "mobx-react";
import {keys} from "ramda";
import {Checkbox, Link, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import QcpSideBar from "@core/components/QcpSideBar";
import {QCPS} from "@core/constants/qcps";
import useStores from "../../../../../useStores";
import styles from "./styles";

const QcpSelectionTable = observer(({classes, selectedQcp, onSelectQcp}) => {
  const {UserStore} = useStores();

  const [qcpToView, setQcpToView] = useState(null);
  
  const companyName = UserStore.user.data.company.name;
  const companyQcps = useMemo(() => keys(QCPS).filter((qcp) => QCPS[qcp].companies.includes(companyName)), []);

  const onQcpNameClick = (event, qcp) => {
    event.stopPropagation();
    setQcpToView(qcp);
  };

  return (
    <>
      <Table className="styled-table">
        <TableBody>
          {companyQcps.map((qcp) => (
            <TableRow
              hover
              key={qcp}
              onClick={() => onSelectQcp(qcp)}
            >
              <TableCell padding="none" width="10%">
                <Checkbox
                  color="primary"
                  checked={selectedQcp === qcp}
                />
              </TableCell>
              <TableCell>
                <Link
                  underline="hover"
                  className={classes.link}
                  onClick={(event) => onQcpNameClick(event, qcp)}
                >
                  {qcp}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <QcpSideBar
        open={!!qcpToView}
        close={() => setQcpToView(null)}
        qcp={qcpToView}
      />
    </>
  );
});

export default withStyles(QcpSelectionTable, styles);