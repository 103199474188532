export function isElementOnScreen(element, stage) {
  const rect = element.getClientRect();
  const stageWidth = stage.width();
  const stageHeight = stage.height();

  return (
    rect.x >= 0 &&
    rect.y >= 0 &&
    rect.x + rect.width <= stageWidth &&
    rect.y + rect.height <= stageHeight
  );
}