import React, {useRef} from "react";
import {isEmpty, isNil, reverse} from "ramda";
import {observer} from "mobx-react-lite";
import {Grid, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import TimelineToggle from "@core/components/TimelineToggle";
import EntityNotFound from "@core/components/EntityNotFound";
import Loader from "@core/components/Loader";
import Test from "@core/components/Test";
import SpecificationChecker from "@core/components/SpecificationChecker";
import Summary from "Certificates/components/Summary";
import styles from "./styles";
import modules from "@core/constants/modules";
import useStores from "../../../useStores";

const Certificate = observer(({classes, certificate, isLoaded}) => {
  const testsRefs = useRef({});
  const testsContainer = useRef();

  const {UserStore} = useStores();

  const [module] = UserStore.user.data.company.modules;

  if(!isLoaded) return <Loader/>;

  if(isEmpty(certificate)) {
    return <EntityNotFound entity='Certificate'/>;
  }

  return (
    <>
      <div className={classes.main} id={certificate._id}>
        <div aria-label="pdf-page-1">
          <Grid container justifyContent="space-between" className={classes.topSection} data-html2canvas-ignore="true">
            <Grid item>
              <Typography variant="h4" fontSize="1.8rem">
                Certificate {certificate._id}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                {/* <Grid item> */}
                {/*   <ExportToPdf */}
                {/*     elementId={certificate._id} */}
                {/*     name={`certificate-${certificate._id}`} */}
                {/*   /> */}
                {/* </Grid> */}
                {module.name !== modules.WITNESS && (
                  <Grid item>
                    <SpecificationChecker
                      certificate={certificate}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Summary
            certificate={certificate}
            onTestClick={(_, testId) => {
              if(!testsRefs.current[testId]) return;

              testsRefs.current[testId].scrollIntoView({behavior: "smooth", block: "start"});
            }}
          />
        </div>
        <div
          className={classes.tests}
          ref={testsContainer}
        >
          {certificate.tests && reverse(certificate.tests).map((test, idx) => {

            if ((isEmpty(test.properties) || isNil(test.properties))) {
              return null;
            }

            const isFirst = !idx;
            const isLast = idx === certificate.tests.length - 1;

            return (
              <div
                id={test._id}
                key={test.type + idx}
                ref={(element) => testsRefs.current[test._id] = element}
                className={classes.test}
                aria-label={`pdf-page-${2 + idx}`}
              >
                <Test
                  certificate={certificate}
                  test={test}
                  isLast={isLast}
                  isFirst={isFirst}
                  disabled
                />
              </div>
            );
          })}
        </div>
      </div>
      <TimelineToggle
        refTest={testsContainer}
      />
    </>
  );
});

export default withStyles(Certificate, styles);
