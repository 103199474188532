import {ORDERS} from "@core/constants/sort";
import {sort} from "@core/helpers/sort";
import {prop, uniqBy} from "ramda";

export const getQcpVersions = (qcps, selectedQcp = "") => {
  const uniqQcps = uniqBy(prop("name"), qcps);
  const qcpVersions = qcps.filter((qcp) => qcp.name === selectedQcp);
  const qcpItems = sort(ORDERS.ASC, "qcpItem", qcpVersions);

  return {
    qcps: uniqQcps,
    qcpItems: qcpItems,
  };
};