import React, {useCallback, useState, useMemo, useEffect} from "react";
import axios from "axios";
import {Formik, FieldArray} from "formik";
import {observer} from "mobx-react-lite";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Input,
  Button,
  IconButton,
  Grid,
  Paper,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {IoMdCloseCircleOutline} from "react-icons/io";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import EditableCell from "@core/components/EditableCell";
import roles from "@core/constants/roles";
import styles from "./styles";
import * as yup from "yup";
import UserService from "@core/api/user-service";
import useStores from "../../useStores";
import {MdAdd} from "react-icons/md";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const USER = {
  email: "",
  username: "",
  role: roles.ADMIN,
};

const ASYNC_VALIDATION_TIMEOUT_IN_MS = 500;

const validateUniqueUsername = async (username) => {
  const response = await axios.post(ROUTES.USER[ACTIONS.VALIDATE], {username});

  return response.data;
};

const validationSchema = yup.object().shape({
  users: yup.array().of(yup.object().shape({
    email: yup.string()
      .email("Email has to be of correct type")
      .required("This field is required!"),
    username: yup.string()
      .test(
        "globalUnique",
        "This username is already registered",
        function (username) {
          if(!username) return false;

          return validateUniqueUsername(username);
        })
      .test(
        "localUnique",
        "Username has to be unique",
        function (username) {
          if(!username) return false;

          const {users} = this.from[1].value;
          const matches = users.filter((user) => user.username && user.username.toLowerCase() === username.toLowerCase());

          return matches.length <= 1;
        }
      )
      .required("This field is required!"),
    role: yup.string().required("This field is required!"),
  })).required(),
});

const InviteUsers = observer(({classes}) => {
  const {NotificationStore} = useStores();
  
  const [users, setUsers] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  
  const inviteUsers = useCallback(async () => {
    await UserService.inviteUsers(users);
    NotificationStore.showSuccess(`${users.length} users successfully received your invitation!`);
    setConfirmationOpen(false);
    window.open("/admin/users", "_self");
  }, [users]);

  const onSubmit = useCallback(({users}) => {
    setUsers(users);
    setConfirmationOpen(true);
  }, []);
  
  return (
    <div className="padded-container">
      <h2 className={classes.header}>Invite user(s) to your company</h2>
      <Formik
        validateOnBlur={false}
        validateOnMount
        validateOnChange={false}
        initialValues={{users: []}}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(props) => {
          const debouncedValidate = useMemo(
            () =>  AwesomeDebouncePromise(props.validateForm, ASYNC_VALIDATION_TIMEOUT_IN_MS),
            [props.validateForm],
          );

          useEffect(
            () => {
              debouncedValidate(props.values);
            },
            [props.values, debouncedValidate],
          );

          return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper>
                  <Table className="styled-table">
                    <TableHead>
                      <TableRow>
                        <TableCell width="32%">Email</TableCell>
                        <TableCell width="32%">Username</TableCell>
                        <TableCell width="32%">Role</TableCell>
                        <TableCell width="4%">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray name="users">
                        {({remove}) => props.values.users.map((user, index) => {
                          const errors = props.errors.users && props.errors.users[index] ? props.errors.users[index] : {};
                          const touched = props.touched.users && props.touched.users[index] ? props.touched.users[index] : {};

                          return (
                            <TableRow hover key={index}>
                              <TableCell>
                                <EditableCell
                                  editable
                                  value={user.email}
                                  required
                                  setValue={(value) => {
                                    props.setFieldValue(`users.${index}.email`, value);
                                    props.setFieldTouched(`users.${index}.email`, true);
                                  }}
                                  error={errors.email && touched.email}
                                  errorMessage={errors.email}
                                />
                              </TableCell>
                              <TableCell>
                                <EditableCell
                                  editable
                                  value={user.username}
                                  required
                                  setValue={(value) => {
                                    props.setFieldValue(`users.${index}.username`, value);
                                    props.setFieldTouched(`users.${index}.username`, true);
                                  }}
                                  error={errors.username && touched.username}
                                  errorMessage={errors.username}
                                />
                              </TableCell>
                              <TableCell>
                                <Select
                                  required
                                  classes={{select: classes.select}}
                                  fullWidth
                                  value={user.role}
                                  onChange={(event) => props.setFieldValue(`users.${index}.role`, event.target.value)}
                                  input={<Input />}
                                >
                                  <MenuItem value={roles.ADMIN} key={roles.ADMIN}>{roles.ADMIN}</MenuItem>
                                  <MenuItem value={roles.SIGNER} key={roles.SIGNER}>{roles.SIGNER}</MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell>
                                <IconButton onClick={() => remove(index)} size="large">
                                  <IoMdCloseCircleOutline />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </FieldArray>
                      <TableRow colSpan={4}>
                        <TableCell>
                          <Button
                            onClick={() => props.setFieldValue("users", [...props.values.users, USER])}
                          >
                            <MdAdd size={80} />Add new
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    color='primary'
                    variant="contained"
                    onClick={props.handleSubmit}
                    disabled={!props.isValid}
                  >
                    Invite
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
      <Confirmation
        open={confirmationOpen}
        alertText={`You are about to invite ${users.length} user(s).`}
        onCancel={() => setConfirmationOpen(false)}
        onConfirm={inviteUsers}
      />
    </div>
  );
});

export default withStyles(InviteUsers, styles);