import React from "react";
import Playground from "@core/components/QcpSideBar/components/Playground";
import testsConfig from "@core/configs/test";
import {TYPES} from "@core/constants/test";

const GlobalAcceptanceOnLength = ({qcp, requirement, close}) => {
  return (
    <Playground
      header={`${qcp} : ${testsConfig[TYPES.DIMENSIONAL].title}`}
      requirement={requirement.globalCriteria.label}
      element={{
        value: "Length",
      }}
      open
      onClose={close}
      isValid={eval(requirement.globalCriteria.value)}
    />
  );
};

export default GlobalAcceptanceOnLength;