import React, {useEffect, useMemo, useState} from "react";
import LoginContainer from "../components/LoginContainer";
import {Box, Button, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useLocation} from "react-router";
import qs from "qs";
import {QRCodeCanvas} from "qrcode.react";

function MobilePasscodeRecovery() {
  const location = useLocation();
  const [token, setToken] = useState("");
  const mobileAppUrl = useMemo(() => `steeltracesigner://passcode-reset?token=${token}`, [token]);

  useEffect(() => {
    const queryParams = qs.parse(location.search.slice(1));

    if (queryParams.token) {
      setToken(queryParams.token);
    }
  }, []);

  const theme = useTheme();
  const isMinWidthLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isMinWidthSm = useMediaQuery(theme.breakpoints.down("sm"));

  const getButton = () => (
    <Button
      variant="contained"
      color="primary"
      href={mobileAppUrl}>
      Reset the passcode
    </Button>
  );

  const getQrCode = () => (
    <Box marginTop={3}>
      <QRCodeCanvas
        value={mobileAppUrl}
        imageSettings={{
          src: "/images/logo-symbol-only-black.png",
          height: 50,
          width: 50,
        }}
        size={250}
      />
    </Box>
  );

  return (
    <>
      {
        isMinWidthLg ?
          <div>
            <Box
              component="img"
              src="/images/logo-symbol-blue.svg"
              alt="Steeltrace logo"
              sx={{
                marginY: 4,
                width: 308,
                height: "auto",
                display: "block",
                marginX: "auto"
              }}
            />
            <Typography align="center" variant="h5">Reset signer app passcode</Typography>
            <Typography
              variant="body1"
              marginX={2}
              marginY={4}
              textAlign={!isMinWidthSm ? "center" : "left"}
            >
              The passcode should be reset from a device which you requested it from. Press the button if you are on that device or scan the QR code from device you requested the reset.
            </Typography>
            <Grid container alignItems="center" flexDirection="column">
              <Grid item>
                {getButton()}
              </Grid>
              <Grid item>{getQrCode()}</Grid>
            </Grid>
          </div> :
          <LoginContainer logoAndContentSx={{marginTop: 10}}>
            <Typography variant="h5">Reset signer app passcode</Typography>
            <Typography align="left" marginY={3}>
              The passcode should be reset from a device which you requested it from. Press the button if you are on that device or scan the QR code from device you requested the reset.
            </Typography>
            {getButton()}
            {getQrCode()}
          </LoginContainer>
      }
    </>
  );
}

export default MobilePasscodeRecovery;