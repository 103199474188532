import React from "react";
import {Dialog, DialogContent, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import NotesTestForm from "@core/components/TestAddDataForms/NotesTest";
import styles from "./styles";

const NotesTest = ({
  classes,
  test,
  formRef,
  ...props
}) => (
  <>
    <Grid container alignContent="space-between" spacing={5} className={classes.marginBottom}>
      <Grid item xs={12}>
        <h4 className={classes.name}>Description:</h4>
        <p
          className={classes.value}
          dangerouslySetInnerHTML={{__html: test.properties.description.split("\n").join("</br>")}}
        />
      </Grid>
    </Grid>

    <Dialog
      open={props.addDataOpen}
      onClose={props.dataDialogClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent className={classes.dialogContent}>
        <NotesTestForm
          formRef={formRef}
          saveTest={props.updateTest}
          test={test}
        />
      </DialogContent>
    </Dialog>
  </>
);

export default withStyles(NotesTest, styles);
