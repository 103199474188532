import React from "react";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {capitalizeFirstLetter} from "@core/helpers";
import EditableCell from "@core/components/EditableCell";
import ElementsTable from "./components/ElementsTable";
import styles from "./styles";
import classNames from "classnames";

const ANALYSIS_TYPES_NAME = {
  heat: "Heat analysis",
  product: "Product analysis",
  "weld joint": "Weld joint chemical analysis",
  "weld overlay": "Weld overlay chemical analysis",
};

const ChemicalComposition = ({setTest, addDataOpen: editable, classes, ...props}) => {
  const test = editable ? props.testUpdates : props.test;

  const updateTestProperties = (changes) => {
    const properties = {...test.properties, ...changes};
    setTest({...test, properties});
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Test Information:</h3>
        </Grid>
        <Grid item xs={12}>
          <Table className={classNames("styled-table", classes.table)}>
            <TableHead>
              <TableRow>
                {(test.company?.name || test.properties.client) && (
                  <TableCell>Client</TableCell>
                )}
                {(test.assignee?.name || test.properties.lab) && (
                  <TableCell>Laboratory</TableCell>
                )}
                <TableCell>Test standard</TableCell>
                {test.properties.analysis && <TableCell>Type of analysis</TableCell>}
                {test.properties.position && <TableCell>Position</TableCell>}
                {test.properties.distance &&<TableCell>Distance from {test.properties.position}</TableCell>}
                <TableCell>Material specification</TableCell>
                <TableCell>Grade / UNS</TableCell>
                {test.properties.measurement && <TableCell>Thickness</TableCell>}
                {test.properties.acceptance && <TableCell>QCP/ITP</TableCell>}
                {test.properties.acceptanceItem && <TableCell>QCP Item</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {(test.company?.name || test.properties.client) && (
                  <TableCell>{test.company?.name || test.properties.client}</TableCell>
                )}
                {(test.assignee?.name || test.properties.lab) && (
                  <TableCell>{test.assignee?.name || test.properties.lab}</TableCell>
                )}
                <TableCell>
                  <EditableCell
                    disabled={!editable}
                    value={test.properties.testStandard}
                    editable={editable}
                    setValue={(value) => updateTestProperties({testStandard: value})}
                  />
                </TableCell>
                {test.properties.analysis && <TableCell> {ANALYSIS_TYPES_NAME[test.properties.analysis] || capitalizeFirstLetter(test.properties.analysis)}</TableCell>}
                {test.properties.position && <TableCell>{test.properties.distance ? `Distance from ${test.properties.position}` : test.properties.position}</TableCell>}
                {test.properties.distance &&<TableCell>{test.properties.distance} mm</TableCell>}
                <TableCell>{test.norm}</TableCell>
                <TableCell>{test.grade}</TableCell>
                {test.properties.measurement && <TableCell>{test.properties.measurement} mm</TableCell>}
                {test.properties.acceptance && <TableCell>{test.properties.acceptance}</TableCell>}
                {test.properties.acceptanceItem && <TableCell>{test.properties.acceptanceItem}</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <h3>Test Result:</h3>
        </Grid>
        <Grid item xs={12}>
          <ElementsTable
            editable={editable}
            test={test}
            updateTest={(changes) => updateTestProperties(changes)}
          />
        </Grid>
        <Grid item xs={12}>
          {test.properties.notes && (
            <div
              className={classes.infoCell}
              dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(ChemicalComposition, styles);
