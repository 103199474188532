import React from "react";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {HardnessResult} from "@core/services/testResult/hardness";
import QuadrantView from "./QuadrantView";
import PositionView from "./PositionView";
import styles from "./styles";
import classNames from "classnames";

const HardnessTest = ({setTest, addDataOpen: editable, classes, ...props}) => {
  const test = editable ? props.testUpdates : props.test;

  const showQuadrantView = test.properties.hardnessLocation === "Circular sample" && test.properties.elementsHistory.length;
  
  const updateElement = (value, index) => {
    const newElements = test.properties.elements.map((element) => element.id === index ? {...element, value} : element);

    const hardnessTestResult = new HardnessResult(test.properties.avgMin, test.properties.avgMax, newElements);
    const result = hardnessTestResult.getResult();

    const properties = {...test.properties, elements: newElements, result};
    setTest({...test, properties});
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          Test Information:
        </Grid>
        <Grid item xs={12}>
          <Table className={classNames("styled-table", classes.table)}>
            <TableHead>
              <TableRow>
                {(test.company?.name || test.properties.client) && (
                  <TableCell>Client</TableCell>
                )}
                {(test.assignee?.name || test.properties.lab) && (
                  <TableCell>Laboratory</TableCell>
                )}
                <TableCell>Material specification</TableCell>
                <TableCell>Grade / UNS</TableCell>
                <TableCell>Hardness type</TableCell>
                {test.properties.specimenId && ( <TableCell>Specimen ID</TableCell> )}
                {test.properties.location && (<TableCell>Location</TableCell>)}
                {test.properties.acceptance && <TableCell>QCP/ITP</TableCell>}
                {test.properties.acceptanceItem && <TableCell>QCP Item</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {(test.company?.name || test.properties.client) && (
                  <TableCell>{test.company?.name || test.properties.client}</TableCell>
                )}
                {(test.assignee?.name || test.properties.lab) && (
                  <TableCell>{test.assignee?.name || test.properties.lab}</TableCell>
                )}
                <TableCell>{test.norm}</TableCell>
                <TableCell>{test.grade}</TableCell>
                <TableCell>{test.properties.hardnessTest || "-"}</TableCell>
                {test.properties.specimenId &&<TableCell>{test.properties.specimenId}</TableCell>}
                {test.properties.location && (<TableCell>{test.properties.location}</TableCell>)}
                {test.properties.acceptance && <TableCell>{test.properties.acceptance}</TableCell>}
                {test.properties.acceptanceItem && <TableCell>{test.properties.acceptanceItem}</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          Test Result:
        </Grid>
        <Grid item xs={12}>
          {showQuadrantView ? (
            <QuadrantView
              test={test}
              editable={editable}
              updateElement={updateElement}
            />
          ) : (
            <PositionView
              test={test}
              editable={editable}
              updateElement={updateElement}
            />
          )}
        </Grid>
        {test.properties.notes && (
          <Grid item xs={12}>
            <div className={classes.notes}>
              <p
                className={classes.value}
                dangerouslySetInnerHTML={{__html: test.properties.notes?.split("\n").join("</br>")}}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withStyles(HardnessTest, styles);
