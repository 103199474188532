import {green} from "@mui/material/colors";
export default (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
  input: {
    display: "none",
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  preloadAvatar: {
    width: 180,
    height: 180,
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "24px",
    border: "1px dashed #888",
    padding: "40px",
    color: "#888",
    margin: "15px auto"
  },
  enter: {
    backgroundColor: "rgb(200,200,200)",
    color: "rgb(64,64,64)"
  },
  errorMessage: {
    color: "#f44336",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    marginTop: "3px",
  },
  cropperContainer: {
    position: "relative",
    minWidth: "800px",
    minHeight: "500px"
  },
  dialogButtonsContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: 0,
    marginRight: 0,
  },
  noCropContent: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& img": {
      maxWidth: "800px",
      height: "auto",
      objectFit: "contain"
    }
  },
  dialogCheckBoxWrap: {
    marginRight: "16px",
    "& label": {
      cursor: "pointer"
    }
  },
  dialogCancel: {
    marginRight: 0,
    marginLeft: "16px"
  },
  buttonsContainer: {
    marginTop: 10,
  },
  converterImage: {
    display: "none",
  },
  converterCanvas: {
    display: "none"
  }
});