import React, {useMemo} from "react";
import {uniq, values, groupBy, prop, indexBy} from "ramda";
import {Box, Table, TableBody, TableHead, TableCell, TableRow} from "@mui/material";
import {sort} from "@core/helpers";
import {ORDERS} from "@core/constants/sort";
import ResultCell from "@core/components/TestViews/CustomTest/components/ResultCell";

const SpecimensList = ({activities, test, disabled, updateTest}) => {
  const descriptions = useMemo(() => uniq(activities.map((activity) => activity.description)), [activities]);
  const ordered = sort(ORDERS.ASC, "specimen", activities);
  const groupedBySpecimen = values(groupBy(prop("specimen"), ordered));

  return (
    <Box sx={{overflowX: "scroll"}}>
      <Table
        className="styled-table"
        sx={{whiteSpace: "nowrap"}}
      >
        <TableHead>
          <TableRow>
            <TableCell>{test.specimenLabel}</TableCell>
            {descriptions.map((description) => (
              <TableCell key={description}>{description}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedBySpecimen.map((specimenActivities) => {
            const groupedByDescription = indexBy(prop("description"), specimenActivities);

            return (
              <TableRow>
                <TableCell>{specimenActivities[0].specimen}</TableCell>
                {descriptions.map((description) => {
                  const element = groupedByDescription[description] || {};

                  return (
                    <React.Fragment key={specimenActivities[0].specimen + description}>
                      <ResultCell
                        element={element}
                        disabled={disabled}
                        onSave={(results) => updateTest(results, element.id)}
                        activity={activities[element.id]}
                      />
                    </React.Fragment>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SpecimensList;