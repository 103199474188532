import {DATE_FORMAT} from "@core/constants/dateFormats";
import {Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import React from "react";

const TRANSFER_ACTIONS = ["transfer", "batch"];

const OwnersHistoryModal = ({
  certificate,
  opened,
  onClose
}) => {
  const transferTransactions = certificate.transactions.filter(({action}) => TRANSFER_ACTIONS.includes(action));

  return (
    <Dialog
      open={opened}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Owners history</DialogTitle>
      <DialogContent>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Previous owners</TableCell>
              <TableCell>Product type</TableCell>
              <TableCell>Owner from - to</TableCell>
              <TableCell>PO Number</TableCell>
              <TableCell>PO item</TableCell>
              <TableCell>Project ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificate.transfers.map((transfer, index) => {
              const transaction = transferTransactions[index];

              return (
                <TableRow key={index}>
                  <TableCell>{transfer.sender && transfer.sender.name}</TableCell>
                  <TableCell>{certificate.properties.productType.name}</TableCell>
                  <TableCell>{moment(certificate.date_created).format(DATE_FORMAT)}/{moment(transfer.date_created).format(DATE_FORMAT)}</TableCell>
                  {transaction && (
                    <>
                      <TableCell>{transaction.historyData.orderNumber}</TableCell>
                      <TableCell>{transaction.historyData.lineItem}</TableCell>
                      <TableCell>{transaction.historyData.projectId?.name}</TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default OwnersHistoryModal;
