import React, {useState} from "react";
import Header from "./components/Header";
import Content from "./components/Content";
import {SUB_TABS_BY_TAB, TABS} from "./constants";

const SingleMaterialCampaign = () => {
  const [activeTab, setActiveTab] = useState(TABS.DASHBOARD);
  const [activeSubTab, setActiveSubTab] = useState(SUB_TABS_BY_TAB[activeTab] ? SUB_TABS_BY_TAB[activeTab][0] : null);
  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <Header
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        activeSubTab={activeSubTab}
        setActiveSubTab={setActiveSubTab}
        showFilters={showFilters}
        toggleFilters={() => setShowFilters((prevValue) => !prevValue)}
      />
      <Content
        showFilters={showFilters}
        activeTab={activeTab}
        activeSubTab={activeSubTab}
      />
    </>
  );
};

export default SingleMaterialCampaign;