import React, {Component} from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogContent,
  Dialog,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import TreatmentTestForm from "@core/components/TestAddDataForms/TreatmentTest";
import {inject, observer} from "mobx-react";
import styles from "./styles";
import {generateID} from "@core/helpers";
import {compose} from "ramda";

class TreatmentTest extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {test, classes} = this.props;

    return (
      <>
        <Grid item xs={10} className="transversal">
          <Grid container alignContent="space-between">
            <Grid item xs={12} style={{marginBottom: "30px"}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell size='small'>Treatment</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {test.properties.elements.map((element) => (
                    <TableRow key={generateID()}>
                      <TableCell>{element.treatment}</TableCell>
                      <TableCell>{element.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={this.props.addDataOpen}
          onClose={this.props.dataDialogClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent className={classes.dialogContent}>
            <TreatmentTestForm
              formRef={this.props.formRef}
              saveTest={this.props.updateTest}
              test={test}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }

}

export default compose(
  inject("TestStore", "SigningStore", "CertificateStore")
)(withStyles(observer(TreatmentTest), styles));
