import React, {memo, useRef} from "react";
import {Layer} from "react-konva";
import {values} from "ramda";
import Stalk from "./components/Stalk";
import {STAGE_CONFIG} from "../../constants";

const DetailedView = ({stalks, settings, stageWidth, scale, layer, tieInWeldsByStalkNumber, label,  ...props}) => {
  const stalksRef = useRef({});

  return (
    <Layer
      ref={layer}
      style={{background: STAGE_CONFIG.BACKGROUND_COLOR}}
    >
      {stalks.map((stalk, stalkIndex) => (
        <Stalk
          key={stalkIndex}
          stalk={stalk}
          stalkIndex={stalkIndex}
          settings={settings}
          stalks={stalksRef}
          stageWidth={stageWidth}
          scale={scale}
          tieInWelds={values(tieInWeldsByStalkNumber)}
          label={label}
          {...props}
        />
      ))}
    </Layer>
  );
};

export default memo(DetailedView);