import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";
import Playground from "@core/components/QcpSideBar/components/Playground";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React, {useState} from "react";
import testsConfig from "@core/configs/test";
import {TYPES} from "@core/constants/test";

const GlobalAcceptance = ({globalConfig, qcp}) => {
  const [playgroundOpen, setPlaygroundOpen] = useState(false);

  const {yieldStrengthRange, tensileStrengthRange, yieldTensileRatios} = globalConfig;

  return (
    <>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Yield Strength Range [MPa]</TableCell>
            <TableCell>Tensile Strength Range [MPa]</TableCell>
            {yieldTensileRatios && (
              <TableCell>Yield/Tensile Ratio</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Min</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            {yieldTensileRatios && (
              <TableCell>-</TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell>Max</TableCell>
            <TableCell>{yieldStrengthRange || "-"}</TableCell>
            <TableCell>{tensileStrengthRange || "-"}</TableCell>
            {yieldTensileRatios && (
              <TableCell>
                <DrawerButtonTrigger
                  onClick={() => setPlaygroundOpen(true)}
                  text={yieldTensileRatios.label || "-"}
                  label="Try it out"
                />
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
      <Playground
        header={`${qcp} : ${testsConfig[TYPES.TENSILE].title}`}
        requirement={yieldTensileRatios?.label}
        resultCellHeader="Yield/Tensile ratio"
        getResultCellValue={(element) => {
          return element.rp && element.rm ? (element.rp / element.rm).toFixed(2) : null;
        }}
        element={{
          rp: "Yield Strength",
          rm: "Tensile Strength",
        }}
        open={playgroundOpen}
        onClose={() => setPlaygroundOpen(false)}
        isValid={eval(yieldTensileRatios?.value)}
      />
    </>
  );
};

export default GlobalAcceptance;