import React from "react";
import RouterLink from "@core/components/RouterLink";
import importedColumns from "@core/columns/test";
import {Button} from "@mui/material";

const getColumns = (setTestToDelete) => ([{
  dataIndex: "action",
  permanent: true,
  title: "Action",
  align: "center",
  render: (test) => (
    <>
      <Button
        component={RouterLink}
        to={`/tests/${test._id}`}
      >
        View
      </Button>
      {(!test.company || test.assignee._id === test.company._id) && (
        <Button
          onClick={() => setTestToDelete(test._id)}
        >
          Remove
        </Button>
      )}
    </>
  )
}, ...importedColumns]);

export {getColumns};
