import React, {Component} from "react";
import Loader from "@core/components/Loader";
import DashboardCompany from "@core/components/DashboardCompany";
import DashboardSuperAdmin from "./components/DashboardSuperAdmin";
import modules from "@core/constants/modules";
import userService from "@core/api/user-service";

class  Dashboard extends Component {
  state = {
    user: {},
    loaded: false
  };

  componentDidMount() {
    userService.getUserFromCache().then((user) =>{
      this.setState({loaded: true, user});
    });
  }

  render() {
    const {user, loaded} = this.state;

    return(
      <div>
        {loaded ?
          <div>
            {user.company.modules[0].name === modules.SUPER_ADMIN ?
              <DashboardSuperAdmin/> :
              <DashboardCompany userCompanyId={user.company._id} module={user.company.modules[0].name}/>}
          </div> :
          <Loader/>}
      </div>
    );
  }
}

export default Dashboard ;
