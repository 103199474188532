export default (theme) => ({
  content: {
    padding: theme.spacing(2.5),
  },
  parametersTable: {
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(1.5),
  },
  link: {
    cursor: "pointer"
  }
});
