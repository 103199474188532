import {acceptances, filmTypes, iqiTypes, norms, positions, sources, xRayApparatuses} from "./data";

const correspondingFormNames = {
  "Sales Order No.": "salesOrderNumber",
  "Work Phase": "workStage",
  "Tecnica Radiografica": "radioscopic",
  "Apply. Doc.": "norm",
  "Accett. Dif.": "acceptance",
  "Source": "source",
  "Source Dim.": "sourceSize",
  "Dist sorg - Pell": "sourceDistance",
  "Screens": "screen",
  "Source Side": "thicknessSourceSide",
  "Film Side": "thicknessFilmSide",
  "Position": "position",
  "Density": "density",
  "Film Type": "filmType",
  "Description": "description",
  "Penetrametro Type": "iqiType",
  "Sections": "sections"
};

const selectorsConfig = {
  norm: {name: "norm", value: norms},
  acceptance: {name: "acceptance", value: acceptances},
  source: {name: "source", value: sources},
  position: {name: "position", value: positions},
  iqiType: {name: "iqiType", value: iqiTypes},
  filmType: {name: "filmType", value: filmTypes},
  xRayApparatus: {name: "xRayApparatus", value: xRayApparatuses},
};

const getFieldsCollection = (event) => {
  const parsedObj = JSON.parse(event.target.result);

  return Object.keys(parsedObj)
    .filter((el) => correspondingFormNames[el])
    .map((key) => ({[correspondingFormNames[key]]: parsedObj[key]}));
};

const resolvedValue = (key, value) => {
  if(selectorsConfig[key]){
    const valueFromConfig = selectorsConfig[key].value.find((el) => el.value === value);

    if(valueFromConfig){
      return valueFromConfig;
    } else {
      selectorsConfig[key].value.push({value: value});

      return selectorsConfig[key].value.find((el) => el.value === value);
    }
  }

  return value;
};

const transferValues = (event, setFieldValue, setFieldTouched) => {
  const reader = new FileReader();

  if(event.target.files[0]){reader.readAsText(event.target.files[0]);}

  reader.onload = (event) => {
    const data = getFieldsCollection(event);
    data.forEach((field) => {
      const key = Object.keys(field)[0];
      const value = field[key];
      setFieldValue(key, resolvedValue(key, value));
      setFieldTouched(key, true, false);
    });
  };
};

export default transferValues;