import {TEST_GROUP_ORDER} from "@core/constants/test";

export const TABS = {
  DASHBOARD: "DASHBOARD",
  TESTS: "TESTS",
  CONCESSION_REQUESTS: "CONCESSION_REQUESTS",
  BASE_MATERIALS: "BASE_MATERIALS",
  TRACEABILITY: "TRACEABILITY",
  UPLOADS: "UPLOADS"
};

export const TABS_TITLES = {
  [TABS.DASHBOARD]: "Dashboard",
  [TABS.TESTS]: "Test(s) & Analyses",
  [TABS.CONCESSION_REQUESTS]: "Concession Requests",
  [TABS.BASE_MATERIALS]: "Base Materials",
  [TABS.TRACEABILITY]: "Traceability",
  [TABS.UPLOADS]: "File(s)"
};

export const TABS_TOOLTIPS = {
  [TABS.DASHBOARD]: "Dashboard",
  [TABS.TESTS]: "Test(s) & Analyses",
  [TABS.CONCESSION_REQUESTS]: "Concession Requests",
  [TABS.BASE_MATERIALS]: "Base Materials",
  [TABS.TRACEABILITY]: "Traceability",
  [TABS.UPLOADS]: "File(s)"
};

export const SUB_TABS_BY_TAB = {
  [TABS.TESTS]: TEST_GROUP_ORDER, // potentially filter those that are empty (no tests belonging to this group in campaign)
};