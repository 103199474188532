
export const DIRECTIONS = {
  x: "x",
  y: "y",
  z: "z"
};

export const FIELD_NAMES = {
  testStandard: "testStandard",
  method: "method",
  acceptanceCriteria: "acceptanceCriteria",
};

export const DISPLAY_NAME_BY_FIELD = {
  [FIELD_NAMES.testStandard]: "Test standard",
  [FIELD_NAMES.method]: "Test method",
  [FIELD_NAMES.acceptanceCriteria]: "Acceptance criteria",
};

export const EMPTY_CELL = "EMPTY_CELL";

export const STRESS_AT_BREAK_FIELDS = {
  [EMPTY_CELL]: EMPTY_CELL,
  specimenId: "specimenId",
  [DIRECTIONS.x]: DIRECTIONS.x,
  [DIRECTIONS.y]: DIRECTIONS.y,
  [DIRECTIONS.z]: DIRECTIONS.z,
};

export const STRESS_AT_BREAK_COLUMN_NAMES = {
  [STRESS_AT_BREAK_FIELDS.specimenId]: "Specimen ID",
  [STRESS_AT_BREAK_FIELDS.x]: "X direction",
  [STRESS_AT_BREAK_FIELDS.y]: "Y direction",
  [STRESS_AT_BREAK_FIELDS.z]: "Z direction",
};

export const STRESS_AT_BREAK_ROW_DEFAULT = {
  [STRESS_AT_BREAK_FIELDS.specimenId]: "",
  [STRESS_AT_BREAK_FIELDS.x]: 0,
  [STRESS_AT_BREAK_FIELDS.y]: 0,
  [STRESS_AT_BREAK_FIELDS.z]: 0
};