import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import useStores from "../useStores";
import {columns} from "./columns";

const Metrics = ({match}) => {
  const {MetricsStore} = useStores();
  const {status, data: metrics, total, fetch} = MetricsStore.metrics;

  useEffect(() => {
    return () => MetricsStore.metrics.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => MetricsStore,
    dataKey: STORE_DATA.metrics,
    matchPath: match.path,
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    MetricsStore.metrics.load({limit, offset: 0});
  };
  
  return (
    <div className="padded-container">
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Metrics
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table
            items={metrics}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => MetricsStore.metrics.load({sort})}
            saveSortToQueryParams
            total={total}
            noDataText="No data."
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={metrics}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              onOffsetChange={(offset) => MetricsStore.metrics.load({offset})}
              onLimitChange={handleRowsPerPageChange}
            />
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(Metrics);