import {ROUTES} from "@core/api/routes";
import DrawerHeader from "@core/components/DrawerHeader";
import EditableCell from "@core/components/EditableCell";
import {Input} from "@core/components/Form";
import {ACTIONS} from "@core/constants/api";
import {TEST_RESULTS} from "@core/constants/testResults";
import axios from "axios";
import React, {useState} from "react";
import {
  Chip,
  Grid,
  Table,
  TableBody,
  Box,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Collapse,
  Card,
  CardHeader,
  Divider,
  CardContent,
  useTheme,
  Typography,
  CircularProgress
} from "@mui/material";
import {FieldArray, Formik} from "formik";
import ResizableDrawer from "@core/components/ResizableDrawer";
import {keys} from "ramda";
import {IoMdCloseCircleOutline} from "react-icons/io";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {TbBrandOpenai} from "react-icons/tb";
import {MdAdd} from "react-icons/md";
import * as yup from "yup";

const Playground = ({children, open, onClose, isValid, element, getResultCellValue, resultCellHeader, requirement, header, nominal}) => {
  const [openCode, setOpenCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [codeExplanation, setCodeExplanation] = useState("");

  const theme = useTheme();

  const properties = keys(element);

  const codeRows = isValid.toString().replace("{", "{\n").replaceAll(";", ";\n").split("\n");

  const getCodeExplanation = async () => {
    setIsLoading(true);

    const {data} = await axios.post(ROUTES.OPEN_AI[ACTIONS.SINGLE], {
      prompt: isValid.toString()
    });

    setCodeExplanation(data);

    if(!openCode) setOpenCode(true);

    setIsLoading(false);
  };

  const initialValues = {
    elements: [{}]
  };

  const validationSchema = yup.object().shape({
    elements: yup.array().of(yup.object().shape({
      ...properties.reduce((acc, property) => {
        acc[property] = yup.number().required("This field is required!");

        return acc;
      }, {}),
    })),
  });

  return (
    <ResizableDrawer
      open={open}
      close={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(props) => {
          const result = isValid(props.values.elements, nominal) ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

          return (
            <>
              <DrawerHeader
                close={onClose}
                text={header}
              />
              <Box sx={{margin: 2}}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Global requirement:
                    </Typography>
                    <Box sx={{marginTop: 3}}>{requirement}</Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {children}
                  </Grid>
                  <Grid item xs={12}>
                    <Table className="styled-table">
                      <TableHead>
                        <TableRow>
                          <TableCell width="4%">#</TableCell>
                          {properties.map((property) => (
                            <TableCell key={property}>{element[property]}</TableCell>
                          ))}
                          {resultCellHeader && <TableCell>{resultCellHeader}</TableCell>}
                          <TableCell width="4%">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray name="elements">
                          {({remove}) => props.values.elements.map((element, index) => {
                            const errors = props.errors.users && props.errors.users[index] ? props.errors.users[index] : {};
                            const touched = props.touched.users && props.touched.users[index] ? props.touched.users[index] : {};

                            return (
                              <TableRow hover key={index}>
                                <TableCell width="4%">{index + 1}</TableCell>
                                {properties.map((property) => (
                                  <TableCell key={property + index}>
                                    <EditableCell
                                      type="number"
                                      editable
                                      value={element[property]}
                                      required
                                      setValue={(value) => {
                                        props.setFieldValue(`elements.${index}.${property}`, value);
                                        props.setFieldTouched(`elements.${index}.${property}`, true);
                                      }}
                                      error={errors[property] && touched[property]}
                                      errorMessage={errors[property]}
                                    />
                                  </TableCell>
                                ))}
                                {resultCellHeader && (
                                  <TableCell>
                                    {getResultCellValue(element)}
                                  </TableCell>
                                )}
                                <TableCell>
                                  <IconButton onClick={() => remove(index)} size="large">
                                    <IoMdCloseCircleOutline />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </FieldArray>
                        <TableRow>
                          <TableCell colSpan={properties.length + 3}>
                            <Grid container justifyContent="space-between" alignItems="center">
                              <Grid item>
                                <Button
                                  onClick={() => props.setFieldValue("elements", [...props.values.elements, {}])}
                                >
                                  <MdAdd size={80} />Add new
                                </Button>
                              </Grid>
                              <Grid item>
                                Result:&nbsp;
                                <Chip
                                  size="small"
                                  label={result}
                                  sx={{
                                    backgroundColor: result === TEST_RESULTS.ACCEPTABLE ? theme.palette.success.backgroundColor : theme.palette.error.backgroundColor,
                                    color: result === TEST_RESULTS.ACCEPTABLE ? theme.palette.success.text : theme.palette.error.text,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader
                        sx={{padding: 1}}
                        disableTypography
                        action={
                          <IconButton onClick={() => setOpenCode(!openCode)}>
                            {openCode ? <ExpandLessIcon />: <ExpandMoreIcon />}
                          </IconButton>
                        }
                        title={(
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              "& button": {
                                marginLeft: 1,
                              }
                            }}
                          >
                            Validation function code
                            <Button
                              variant="contained"
                              size="small"
                              onClick={getCodeExplanation}
                              disabled={isLoading}
                            >
                              Explain this code to me {isLoading && <CircularProgress size={20} />}
                            </Button>
                          </Box>
                        )}
                      />
                      <Collapse in={openCode} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                "& pre": {
                                  padding: 1,
                                  borderRadius: 1,
                                  backgroundColor: theme.palette.backgroundColor.main,
                                },
                                "& code": {
                                  fontFamily: "Inter",
                                  fontSize: "1rem"
                                }
                              }}
                            >
                              <pre>
                                <code>
                                  {codeRows.map((row, index) => {
                                    if(!index || index === codeRows.length - 1) return row;

                                    return (
                                      <Box sx={{marginLeft: 3, marginBottom: 0.5}}>
                                        {row}
                                      </Box>
                                    );
                                  })}
                                </code>
                              </pre>
                            </Grid>
                            {codeExplanation && (
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <Box sx={{display: "flex", alignItems: "center"}}>
                                    <TbBrandOpenai />&nbsp;ChatGPT generated response:
                                  </Box>
                                </Typography>
                                <Box sx={{marginTop: 3}}>
                                  <Input
                                    value={codeExplanation}
                                    multiline
                                  />
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        }}
      </Formik>
    </ResizableDrawer>
  );
};

Playground.defaultProps = {
  isValid: () => {}
};

export default Playground;