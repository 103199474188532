import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {getDefectReports} from "../../../../helpers";
import useStores from "../../../../../../useStores";
import CustomReport from "@core/components/TestViews/CustomReport";
import {Box, Divider, Grid, useTheme} from "@mui/material";

const DefectLog = ({welds, getDefects}) => {
  const {CampaignStore} = useStores();

  const theme = useTheme();

  const campaign = CampaignStore.campaign;

  const defectReports = useMemo(() => getDefectReports(campaign.tests), [campaign.tests]);

  const data = useMemo(() => getDefects(defectReports, welds), [defectReports, welds]);

  if(!data.length) return null;

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{marginBottom: theme.spacing()}}>
          <h3>Coating defect(s)</h3>
        </Box>
        <CustomReport
          test={{properties: {data}}}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};

export default observer(DefectLog);