import React from "react";
import {useLocation, useParams} from "react-router-dom";
import {values} from "ramda";
import TableFooter from "@core/components/TableFooter";
import TableNavigation from "@core/components/TableNavigation";
import Table from "@core/components/Table";
import {CERTIFICATE_VIEWS, VIEWS_TITLES} from "../data";

const BatchTransferTable = ({
  columns,
  certificates,
  limit,
  offset,
  total,
  isLoaded,
  selectCertificates,
  selected,
  handleChangePage,
  handleChangeRowsPerPage,
  disabled,
  sort,
  setSort,
  selectedColumns
}) => {
  const {id: transferId} = useParams();
  const location = useLocation();

  const view = new URLSearchParams(location.search).get("view") || CERTIFICATE_VIEWS.READY_FOR_TRANSFER;

  const onSelect = (ids) => {
    selectCertificates(ids);
  };

  return (
    <>
      <TableNavigation
        titles={VIEWS_TITLES}
        views={values(CERTIFICATE_VIEWS)}
        currentView={view}
        link={`/transfers/${transferId}`}
        query="view"
      />
      <Table
        setSelectedItems={onSelect}
        selectedItems={selected}
        selectedColumns={selectedColumns}
        allowRowSelection={!disabled}
        items={certificates}
        columns={columns}
        isLoaded={isLoaded}
        sort={sort}
        setSort={setSort}
        total={total}
      >
        <TableFooter
          isLoaded={isLoaded}
          items={certificates}
          total={total}
          limit={limit}
          offset={offset}
          selectedItems={selected}
          onOffsetChange={handleChangePage}
          onLimitChange={handleChangeRowsPerPage}
        />
      </Table>
    </>
  );
};

export default BatchTransferTable;
