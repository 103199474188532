import {TableStore} from "@core/stores";
import axios from "axios";
import {makeObservable, observable} from "mobx";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {isEmpty} from "ramda";

class QcpStore {
  constructor() {
    makeObservable(this, {
      qcps: observable,
    });
  }

  qcps = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.qcps.fetch, ...queryParams};
      const {offset, limit, search, sort} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value: search},
      };

      this.qcps.setFetch(newQueryParams);

      return axios.get(
        ROUTES.QCP[ACTIONS.ALL],
        {params}
      );
    }
  });
}

export default new QcpStore();
