export default (theme) => ({
  green: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.backgroundColor
  },
  red: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.backgroundColor
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.backgroundColor
  },
  tag: {
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightMedium,
    padding: "4px 10px",
    borderRadius: 4,
    display: "inline",
    whiteSpace: "nowrap",
  },
});

