import {TableCell, TableRow} from "@mui/material";
import React from "react";

const AcceptanceRow = ({yeldMin, tensMin, elongation, yeldMax, tensMax, yeildTensileRatio, uniformElongationMin}) => (
  <>
    <TableRow>
      <TableCell>Min</TableCell>
      <TableCell>{yeldMin || "-"}</TableCell>
      <TableCell>{tensMin || "-"}</TableCell>
      <TableCell>{elongation || "-"}</TableCell>
      <TableCell>{uniformElongationMin || "-"}</TableCell>
      <TableCell>-</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Max</TableCell>
      <TableCell>{yeldMax || "-"}</TableCell>
      <TableCell>{tensMax || "-"}</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>{yeildTensileRatio || "-"}</TableCell>
    </TableRow>
  </>
);

export default AcceptanceRow;