import React, {useMemo, useCallback, useState} from "react";
import {observer} from "mobx-react-lite";
import {
  Button,
  IconButton,
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  OutlinedInput,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import {uniq, uniqBy, prop} from "ramda";
import {IoMdCloseCircleOutline} from "react-icons/io";
import useStores from "../../../../../../../../useStores";
import TableFooter from "@core/components/TableFooter";
import {getFileData} from "@core/helpers";
import axios from "axios";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import {BAD_CHARS_REGEX, MAX_FILE_SIZE} from "@core/components/Uploaders/constants";
import CircularProgress from "@mui/material/CircularProgress";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const NewUpload = ({open, close}) => {
  const [files, setFiles] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [uploadToDeleteId, setUploadToDeleteId] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const {NotificationStore, CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;

  const filesPaginated = useMemo(() => files.slice(offset, offset + limit), [files, offset, limit]);
  
  const removeFile = useCallback(() => {
    setFiles(files.filter((upload) => upload._id !== uploadToDeleteId));
    axios.delete(ROUTES.UPLOAD[ACTIONS.REMOVE](uploadToDeleteId));
    setUploadToDeleteId(null);
  }, [files, uploadToDeleteId]);
  
  const uploadFile = useCallback(async (file) => {
    if (BAD_CHARS_REGEX.test(file.name)) {
      NotificationStore.showError("Invalid file \n file name should not contain : < > : \\ / \\\\ | ? * #");

      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      NotificationStore.showError("Uploaded file is too big. \n Max size: 50mb");
      
      return;
    }

    const data = new FormData();
    data.append("file", file);

    const response = await axios.post(ROUTES.UPLOAD[ACTIONS.CREATE], data, {withCredentials: true});

    return response.data;
  }, [files]);
  
  const uploadFiles = useCallback(async (newFiles) => {
    const promises = Array.from(newFiles).map((file) => uploadFile(file));
    const uploadedFiles = await Promise.all(promises);
    setFiles(uniqBy(prop("_id"), [...uploadedFiles, ...files]));
  }, [files]);
  
  const addFiles = useCallback((e) => {
    e.preventDefault();
    
    const newFiles = e.target.files;

    if (newFiles && newFiles.length) {
      uploadFiles(e.target.files);
    }
  }, [files]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const newFiles = e.dataTransfer.files;

    if (newFiles && newFiles.length) {
      uploadFiles(newFiles);
    }
  }, [files]);

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);
  
  const changeUpload = useCallback((id, changes) => {
    setFiles(files.map((upload) => upload._id === id ? ({...upload, ...changes}) : upload));
  }, [files]);
  
  const saveFiles = useCallback(async () => {
    setLoading(true);

    const filesToBeUpdated = files.filter((upload) => upload.label);
    
    if(filesToBeUpdated.length) {
      const promises = filesToBeUpdated.map((upload) => {
        return axios.patch(ROUTES.UPLOAD[ACTIONS.UPDATE](upload._id), {label: upload.label});
      });
      await Promise.all(promises);
    }
    
    const fileIds = files.map((upload) => upload._id);
    const oldFileIds = campaign.attachments ? campaign.attachments.map((upload) => upload._id) : [];

    await CampaignStore.updateCampaignById(campaign._id, {attachments: uniq(oldFileIds.concat(fileIds))});
    onClose();
    setLoading(false);
  }, [campaign, files]);
  
  const onClose = useCallback(() => {
    close();
    setFiles([]);
  }, []);

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Upload files</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  "& .preloadImg": {
                    width: "100%",
                    padding: "80px 40px",
                    borderRadius: 1,
                  }
                }}
              >
                <Box
                  sx={{
                    "& input": {
                      display: "none",
                    }
                  }}
                >
                  <form
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      type="file"
                      id="input-file-upload"
                      multiple
                      onChange={addFiles}
                    />
                    <label
                      id="label-file-upload"
                      htmlFor="input-file-upload"
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: 200,
                          width: "100%",
                          textAlign: "center",
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderWidth: 1,
                          borderRadius: "0.5rem",
                          borderStyle: "dashed",
                          borderColor: "#888888",
                          color: "#888888",
                          fontSize: "24px"
                        }}
                      >
                        Drag & drop or choose file to upload
                      </Box>
                    </label>
                    {dragActive && (
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          borderRadius: "1rem",
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      />
                    )}
                  </form>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <h3>Files to be uploaded:</h3>
            </Grid>
            <Grid item xs={12}>
              <Table className="styled-table">
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>File Extension</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filesPaginated.map((upload) => {
                    const {name, extension} = getFileData(upload.file.name);

                    return (
                      <TableRow key={upload._id}>
                        <TableCell>{name}{extension}</TableCell>
                        <TableCell>{extension}</TableCell>
                        <TableCell>
                          <OutlinedInput
                            sx={{width: "100%"}}
                            margin="none"
                            value={upload.label}
                            onChange={(e) => changeUpload(upload._id, {label: e.target.value})}
                            placeholder="File description"
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Remove">
                            <IconButton
                              onClick={() => setUploadToDeleteId(upload._id)}
                            >
                              <IoMdCloseCircleOutline size={20} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {!files.length && (
                    <TableRow>
                      <TableCell colSpan={4}>No data</TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter
                  isLoaded
                  items={filesPaginated}
                  total={files.length}
                  limit={limit}
                  offset={offset}
                  onOffsetChange={setOffset}
                  onLimitChange={setLimit}
                />
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={onClose}
                    color="secondary"
                  >
                      Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!files.length || loading}
                    onClick={saveFiles}
                    color="primary"
                    variant="contained"
                  >
                    {loading && <CircularProgress size={24} />}
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Confirmation
        open={!!uploadToDeleteId}
        onCancel={() => setUploadToDeleteId(null)}
        onConfirm={removeFile}
        alertText="Are you sure you want to remove the file?"
      />
    </>
  );
};

export default observer(NewUpload);