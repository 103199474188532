export const TEST_STANDARDS = [
  "ASTM A370 (2021)",
  "ASTM A999 (2018)",
  "ISO 8492 (2013)"
];

export const WELD_POSITIONS = [
  "0°",
  "90°",
  "180°",
  "270°"
];

export const DEFORMATION_PER_UNIT = [
  0.07,
  0.08,
  0.09
];

export const SPECIMEN_INPUT_LENGTH_REQUIREMENTS_BY_TEST_STANDARD = {
  "ASTM A370": {
    min: 63.5
  },
  "ASTM A999": {
    min: 60
  },
  "ISO 8492": {
    min: 10,
    max: 100
  }
};
