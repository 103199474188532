import {STATUSES} from "@core/constants/test";

const TABS = {
  ACTIVE: "active",
  BIN: "bin",
  OPENED: "opened",
  UNSIGNED: "unsigned",
  SUBMITTED: "submitted",
  CLOSED: "closed",
};

const TEST_STATUSES = {
  [TABS.OPENED]: [STATUSES.EMPTY, STATUSES.ASSIGNED],
  [TABS.UNSIGNED]: [STATUSES.FILLED],
  [TABS.SUBMITTED]: [STATUSES.INSPECTING, STATUSES.SUBMITTED],
  [TABS.CLOSED]: [STATUSES.APPROVED, STATUSES.INSPECTED],
  [TABS.BIN]: [STATUSES.DELETED],
  [TABS.ACTIVE]: [STATUSES.ASSIGNED, STATUSES.FILLED, STATUSES.INSPECTING, STATUSES.SUBMITTED, STATUSES.APPROVED, STATUSES.INSPECTED, STATUSES.EMPTY],
};

export {TEST_STATUSES, TABS};