import React, {useEffect, useState} from "react";
import {Grid, MenuItem, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import SelectField from "@core/components/FormikSelect";
import {indexBy, prop} from "ramda";
import {observer} from "mobx-react-lite";
import testConfig from "@core/configs/test";
import useStores from "../../../../useStores";
import ComparisonForm from "../ComparisonForm";
import ChemicalCompositionForm from "../ChemicalCompositionForm";
import styles from "./styles";

const Tests = observer(({classes, index, item, setFieldValue, remove, children}) => {
  const {SpecificationStore} = useStores();

  const [isEditOpen, setEditOpen] = useState(false);

  useEffect(() => {
    const {data, isLoading} = SpecificationStore.testTypes;

    if(data.length || isLoading) return;

    SpecificationStore.getTestTypes();
  }, []);

  const {data} = SpecificationStore.testTypes;

  const fieldsByTestType = indexBy(prop("name"), data);
  const selectedProperty = fieldsByTestType[item.type]?.properties?.find((prop) => prop.name === item.field);

  if (!data.length) return null;

  return (
    <>
      <Grid item xs={2}>
        <SelectField
          name={`config.${index}.type`}
          label='Test type'
          required
        >
          {data.map(({name}) => (
            <MenuItem value={name}>{testConfig[name].title}</MenuItem>
          ))}
        </SelectField>
      </Grid>
      <Grid item xs={2}>
        <SelectField
          name={`config.${index}.field`}
          label='Field'
          required
          disabled={!item.type}
        >
          {item.type && fieldsByTestType[item.type].properties.map((field) => (
            <MenuItem value={field.name}>{field.friendlyName}</MenuItem>
          ))}
        </SelectField>
      </Grid>
      {item.field === "elements" && item.type === "chemical_composition" ? (
        <>
          <Grid item xs={1} container alignItems="flex-end">
            <Button
              fullWidth
              onClick={() => setEditOpen(true)}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Edit
            </Button>
          </Grid>
          <ChemicalCompositionForm
            removeItems={() => remove(index)}
            onClose={() => setEditOpen(false)}
            isOpen={isEditOpen}
            setFieldValue={setFieldValue}
            name={`config.${index}`}
            items={item}
          />
        </>
      ) : (
        <ComparisonForm
          name={`config.${index}`}
          item={item}
          allowedValues={selectedProperty?.options}
        />
      )}
      {children}
    </>
  );
});

export default withStyles(Tests, styles);
