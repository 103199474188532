import React, {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react";
import {Formik} from "formik";
import * as yup from "yup";
import {Dialog, DialogTitle, DialogContent, Button, Grid, MenuItem} from "@mui/material";
import {MuiAutocomplete} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import useStores from "../../../../useStores";
import {useParams} from "react-router";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const initialValues = {
  transferId: "",
  orderNumber: "",
  sender: "",
};

const AddToOutgoingOrder = () => {
  const {ProjectStore, TransferStore, UserStore, NotificationStore} = useStores();
  
  const [addNewOrder, setAddNewOrder] = useState(false);
  const [addOrderOpen, setAddOrderOpen] = useState(false);
  
  const {id: projectId} = useParams();

  const transfers = ProjectStore.project.data.transfers || [];
  
  const projectTransferIds = useMemo(() => transfers.map((transfer) => transfer._id), [projectId, transfers.length]);
  
  const activeProducers = useMemo(() => TransferStore.producers.filter((prod) => prod.active), [TransferStore.producers.length]);

  useEffect(() => {
    TransferStore.getProducers();
  }, []);

  const validationSchema = yup.object().shape({
    transferId: !addNewOrder ? yup.string().required("The field is required!") : yup.string(),
    orderNumber: addNewOrder ? yup.string().required("The field is required!") : yup.string(),
    sender: addNewOrder ? yup.string().required("The field is required!") : yup.string(),
  });

  const onClose = useCallback(() => {
    setAddOrderOpen(false);
    setAddNewOrder(false);
  }, []);

  const addToProject = async (data) => {
    if(data.transferId) await ProjectStore.linkOrder(projectId, data.transferId);
    else await ProjectStore.createAndLinkOrder(projectId, data);

    onClose();
    NotificationStore.showSuccess("Successfully added to project!");
  };

  const createOrder = async (values) => {
    const data = {
      receiver: UserStore.user.data.company._id,
      sender: values.sender,
      orderNumber: values.orderNumber,
    };

    await addToProject(data);
    setAddNewOrder(false);
  };

  const filterOptions = useCallback((options, state) => {
    return options.filter(({label, value}) => {
      const belongToProject = projectTransferIds.includes(value);
      const searched = label.toLowerCase().includes(state?.inputValue?.toLowerCase());

      return !belongToProject && searched;
    });
  }, [projectId, transfers.length]);
  
  return <>
    <Button
      variant="contained"
      color="primary"
      onClick={() => setAddOrderOpen(true)}
    >
    Add order
    </Button>
    <Dialog
      fullWidth
      maxWidth="sm"
      open={addOrderOpen}
      onClose={onClose}
    >
      <DialogTitle>Add order</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={addNewOrder ? createOrder : addToProject}
        >
          {(props) => (
            <Grid container spacing={3}>
              {!addNewOrder && (
                <Grid xs={12} item>
                  <MuiAutocomplete
                    url={`${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open`}
                    inputProps={{
                      label: "Order",
                      name: "transferId",
                      required: true
                    }}
                    formatOptions={(data) => data.items.map((item) => ({label: item.orderNumber, value: item._id}))}
                    onCreateNew={() => setAddNewOrder(true)}
                    filterOptions={filterOptions}
                  />
                </Grid>
              )}
              {addNewOrder && (
                <>
                  <Grid item xs={12}>
                    <SelectField
                      name='sender'
                      label='Customer'
                      required
                    >
                      {activeProducers.map((producer) => (
                        <MenuItem key={producer._id} value={producer._id}>
                          {producer.name}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      label='PO Number'
                      name='orderNumber'
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} container justifyContent="flex-end">
                <Button
                  disabled={!props.isValid}
                  variant="contained"
                  color="primary"
                  onClick={props.handleSubmit}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  </>;
};

export default observer(AddToOutgoingOrder);