import referenceService from "@core/api/reference-service";
import WS from "@core/api/socketConnection";
import userService from "@core/api/user-service";
import EntityNotFound from "@core/components/EntityNotFound";
import Loader from "@core/components/Loader";
import BasicCompUpgrade from "@core/components/Modal/BasicCompUpgrade";
import ScrollToTop from "@core/components/ScrollToTop";
import Test from "@core/components/Test";
import TimelineToggle from "@core/components/TimelineToggle";
import {STATUSES, TYPES} from "@core/constants/test";
import {omitEmptyFields} from "@core/helpers";
import {isTestCompleted} from "@core/helpers/tests";
import {TestService} from "@core/services";
import classNames from "classnames";
import {inject, observer} from "mobx-react";
import {compose, isEmpty, partition} from "ramda";
import React, {Component, createRef} from "react";
import {withStyles} from "tss-react/mui";
import CertificateService from "../../Blockchain/certificates-service";
import SignCertificateModal from "../components/SignCertificateModal";
import Summary from "../components/Summary";
import UtilsBar from "../components/UtilsBar";
import AddToOutgoingOrder from "./components/AddToOutgoingOrder";
import CertificateActions from "./components/CertificateActions";
import Convert from "./components/Convert";
import Edit from "./components/Edit";
import PublishToPortal from "./components/PublishToPortal";
import Split from "./components/Split";
import TestsActions from "./components/TestsActions";
import TransactionsInfo from "./components/TransactionsInfo";
import Transfer from "./components/Transfer";
import styles from "./styles";

class Details extends Component {
  constructor(props) {
    super(props);

    this.testContainerRef = createRef();
    this.state = {
      user: {},
      error: null,
      isLoaded: false,
      transferOpen: false,
      newTestOpened: false,
      customer: null,
      convertOpened: false,
      splitOpened: false,
      editOpened: false,
      isBasicUpgradeOpen: false,
      publishToPortalOpen: false,
      addToOutgoingOrderOpen: false,
      conditionTestsRefs: [],
    };
    this.testsRefs = [];
  }

  openTransfer = () => {
    const certificate = this.props.CertificateStore.certificate.data;

    if (certificate.transactions.length === 0) {
      this.props.CertificateStore.openForceSignCertificate();
    } else if (certificate.tests.length === 0) {
      this.props.NotificationStore.showInfo("A certificate should have at least one test");
    } else {
      if (certificate.tests.every((T) => T.status === STATUSES.APPROVED || T.type === "split_certificate")) {
        this.setState({
          transferOpen: true
        });
      } else {
        this.props.NotificationStore.showInfo("All tests should be approved to be able to transfer");
      }

    }
  };

  togglePublishToPortal = () => {
    const {publishToPortalOpen} = this.state;
    const {NotificationStore} = this.props;

    const allowed = this.isEveryCertificateTestApproved();

    if (!publishToPortalOpen && !allowed) {
      NotificationStore.showInfo("All tests should be approved");

      return;
    }

    this.setState({publishToPortalOpen: !publishToPortalOpen});
  };

  // Handles the cancellation of the signing form
  cancelTransferOperation = () => {
    this.setState({
      transferOpen: false,
      transferTransaction: null
    });
  };

  scanTransferOperation = (values) => {
    const {user} = this.state;
    const certificate = this.props.CertificateStore.certificate.data;
    const transactionObject = CertificateService.transferCertificate(certificate._id, values.customer._id);

    this.setState({transferData: values});

    referenceService.create({
      creator: user._id,
      transactions: [
        {
          transactionObject: transactionObject,
          payload: {
            certificateId: certificate._id,
            type: "certificate",
            action: "transfer",
            historyData: {
              receiver: values.customer.name,
              orderNumber: values.orderNumber,
              lineItem: values.lineItem,
              projectId: values.projectId,
            }
          }
        }
      ],
      date_created: new Date
    }).then((reference) => {
      this.setState({customer: values.customer});
      this.props.SigningStore.openSigner({id: reference._id}, {
        title: "Certificate transfer",
        createdFor: "the certificate owner"
      });
    })
      .catch(() => this.props.NotificationStore.showError("Something went wrong"));
  };

  closeNewTest = () => {
    this.setState({newTestOpened: false});
  };

  addNewTest = () => {
    if (this.props.UserStore.isBasic()) {
      this.openBasicUpdate();

      return;
    }

    this.setState({newTestOpened: true});
  };

  onTestClick = (_, id) => {
    this.testsRefs[id].scrollIntoView({behavior: "smooth", block: "start"});
  };

  isEveryCertificateTestApproved = () => {
    const {CertificateStore} = this.props;

    const certificate = CertificateStore.certificate.data;

    return certificate.tests.filter((test) => test.type !== "split_certificate").every((test) => test.status === STATUSES.APPROVED);
  };

  onSplit = () => {
    const {CertificateStore, UserStore, NotificationStore} = this.props;
    const certificate = CertificateStore.certificate.data;

    if (UserStore.isBasic()) {
      this.openBasicUpdate();

      return;
    }

    if (certificate.transactions.length === 0) {
      CertificateStore.openForceSignCertificate();

      return;
    }

    const allowed = this.isEveryCertificateTestApproved();

    if (allowed) this.setState({splitOpened: true});
    else NotificationStore.showInfo("All tests should be approved, to be able to split");
  };

  onEdit = () => {
    const {UserStore} = this.props;

    if (UserStore.isBasic()) {
      this.openBasicUpdate();

      return;
    }

    this.setState({editOpened: true});
  };

  onConvert = () => {
    const {CertificateStore, UserStore} = this.props;
    const certificate = CertificateStore.certificate.data;

    if (UserStore.isBasic()) {
      this.openBasicUpdate();

      return;
    }

    if (certificate.transactions.length === 0) {
      CertificateStore.openForceSignCertificate();

      return;
    }

    this.setState({convertOpened: true});
  };

  onAddToOutgoingOrder = () => {
    const {CertificateStore, NotificationStore} = this.props;
    const certificate = CertificateStore.certificate.data;

    const testsCompleted = certificate.tests.every((test) => isTestCompleted(test));

    if (!testsCompleted) NotificationStore.showInfo("All tests should be completed");
    else this.setState({addToOutgoingOrderOpen: true});
  };

  unpublish = () => {
    const {CertificateStore, NotificationStore} = this.props;
    CertificateStore.unpublishCertificate(CertificateStore.certificate.data._id);
    NotificationStore.showSuccess("Successfully unpublished!");
  };

  handleDelete = async (deleteTests) => {
    const {history, CertificateStore} = this.props;
    const certificate = {...CertificateStore.certificate};

    await CertificateStore.deleteCertificates([`${certificate.data._id}`], deleteTests);
    history.push("/certificates");
  };

  closeConvert = () => {
    this.setState({convertOpened: false});
  };

  closeSplit = () => {
    this.setState({splitOpened: false});
  };

  openBasicUpdate = () => {
    this.setState({isBasicUpgradeOpen: true});
  };

  createTestsRefs = (ref, id) => {
    this.testsRefs[id] = ref;
  };
  onTestUpdate = async (changes, testId) => {
    const {CertificateStore, TestStore} = this.props;
    const certificate = CertificateStore.certificate.data;
    await TestStore.update(changes, testId);
    const tests = certificate.tests.map((test) => test._id === testId ? TestStore.test.data : test);
    CertificateStore.updateCertificate({tests});
  };

  deleteTest = async (testId) => {
    const {CertificateStore} = this.props;
    const certificate = CertificateStore.certificate.data;

    await CertificateStore.deleteTest(testId, certificate._id);
    const tests = certificate.tests.filter((test) => test._id !== testId);
    CertificateStore.updateCertificate({tests});
  };

  // Moved render into content due to async loading issues

  content() {
    const {
      transferOpen, transferReference, transferTransaction, redirect, convertOpened,
      splitOpened, isBasicUpgradeOpen, publishToPortalOpen,
      editOpened, addToOutgoingOrderOpen
    } = this.state;
    const {TransferStore, CertificateStore, UserStore, classes} = this.props;
    const certificate = CertificateStore.certificate;

    const tests = certificate.data.tests || [];

    const [splitTests, restTests] = partition((test) => test.type === TYPES.SPLIT, tests);
    const filteredTestWithSplitTest = [...restTests, ...splitTests];

    return (
      <div className="content" id={certificate.data._id}>
        {redirect}
        <UtilsBar deleteCertificate={this.handleDelete} isProducer={UserStore.isProducer()} />
        <div className={classes.main}>
          <div aria-label="pdf-page-1">
            <CertificateActions
              certificate={certificate.data}
              unpublishCertificate={this.unpublish}
              openPublishToPortal={this.togglePublishToPortal}
              openTransfer={this.openTransfer}
              onAddToOutgoingOrder={this.onAddToOutgoingOrder}
              onSplit={this.onSplit}
              onEdit={this.onEdit}
              onConvert={this.onConvert}
              user={this.state.user}
            />
            <TransactionsInfo
              user={this.state.user}
              certificate={certificate.data}
            />

            <Summary
              certificate={certificate.data}
              onTestClick={this.onTestClick}
            />

            {!certificate.data.viewer && (
              <TestsActions
                newTestOpened={this.state.newTestOpened}
                addNewTest={this.addNewTest}
                user={this.state.user}
                isBasic={UserStore.isBasic()}
                openBasicUpdate={this.openBasicUpdate}
                certificate={certificate.data}
              />
            )}
          </div>
          {/* <Conditions certificate={certificate} createTestsRefs={this.createTestsRefs} /> */}
          {filteredTestWithSplitTest.length > 0 && <div ref={this.testContainerRef}>
            {filteredTestWithSplitTest.map((test, idx) => {
              if (!test._id) return null;

              return (
                <div
                  id={test._id}
                  key={test.type + idx}
                  ref={(ref) => this.createTestsRefs(ref, test._id)}
                  className={classNames(idx === 0 ? classes.testFirst : classes.test)}
                  aria-label={`pdf-page-${2 + idx}`}
                >
                  <Test
                    certificate={certificate.data}
                    test={test}
                    isLast={certificate.data.transfers.length === 0 && idx === tests.length - 1}
                    isFirst={idx === 0}
                    isProducer={UserStore.isProducer()}
                    onTestUpdate={this.onTestUpdate}
                    approveTest={(test) => TestService.approve([test], certificate.data, UserStore.user.data._id)}
                    assignInspector={() => TestService.assignInspector([test], {[test._id]: certificate.data})}
                    deleteTest={this.deleteTest}
                    shareLink
                  />
                </div>
              );
            })}
          </div>}
          <Transfer
            transferOpen={transferOpen}
            transferClose={this.cancelTransferOperation}
            openTransfer={this.openTransfer}
            certificate={certificate.data}
            transferReference={transferReference}
            companies={TransferStore.producers}
            transferTransaction={transferTransaction}
            handleSend={this.scanTransferOperation}
          />

          {!isEmpty(CertificateStore.product) && (
            <Convert
              convertOpened={convertOpened}
              onClose={this.closeConvert}
              certificate={certificate.data}
              product={CertificateStore.product}
            />
          )}

          <Split
            open={splitOpened}
            onClose={this.closeSplit}
            history={this.props.history}
          />

          <Edit
            certificate={certificate.data}
            open={editOpened}
            onClose={() => this.setState({editOpened: false})}
          />

          <PublishToPortal
            certificateId={certificate.data._id}
            open={publishToPortalOpen}
            onClose={this.togglePublishToPortal}
          />

          <AddToOutgoingOrder
            open={addToOutgoingOrderOpen}
            onClose={() => this.setState({addToOutgoingOrderOpen: false})}
          />

          <SignCertificateModal certificates={[certificate.data]} />

          <BasicCompUpgrade
            open={isBasicUpgradeOpen}
            onCancel={() => this.setState({isBasicUpgradeOpen: false})}
          />

          <ScrollToTop />

          <TimelineToggle
            refTest={this.testContainerRef}
          />
        </div>
      </div>
    );
  }

  render() {
    const {CertificateStore, TransferStore} = this.props;

    if (!CertificateStore.certificate.isLoaded || !TransferStore.isLoaded) {
      return <Loader />;
    }

    if (isEmpty(CertificateStore.certificate)) {
      return <EntityNotFound entity='Certificate' />;
    }

    return this.content();
  }

  componentWillUnmount() {
    WS.remove("transaction:certificate:sign");
    WS.remove("transaction:certificate:transfer");
    WS.remove("transaction:test:assign");
    WS.remove("transaction:test:witness");
    WS.remove("transaction:test:approve");
    this.props.CertificateStore.clear(["certificate"]);
  }

  componentDidMount() {
    const {SigningStore, CertificateStore, TransferStore, NotificationStore, history} = this.props;

    userService.getUserFromCache().then((user) => {
      this.setState({user});
    });

    CertificateStore.getCertificateById(this.props.match.params.id)
      .then((redirectLink) => {

        if (redirectLink) {
          history.push(redirectLink);

          return;
        }

        const {hash} = this.props.location;

        if (!hash) {
          return;
        }

        const id = hash.substr(1);

        if (!this.testsRefs[id]) {
          return;
        }

        this.testsRefs[id].scrollIntoView({behavior: "smooth", block: "start"});
      });

    TransferStore.getProducers();

    WS.listen("transaction:certificate:sign", (data) => {
      if (data.status === "DECLINED") {
        NotificationStore.showError("Signing failed!");

        return;
      }

      if (data.status === "ACCEPTED") {
        NotificationStore.showSuccess("Successfully signed!");
        CertificateStore.closeForceSignCertificate();
        SigningStore.closeSigner();
        CertificateStore.updateCertificate({transactions: [data]});
      }
    });

    WS.listen("transaction:test:approve", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINE") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      CertificateStore.getCertificateById(this.props.match.params.id);

      NotificationStore.showSuccess("Successfully approved!");
    });

    WS.listen("transaction:certificate:transfer", (data) => {
      const {CertificateStore, TransferStore, NotificationStore} = this.props;
      const {customer, transferData} = this.state;
      const certificateId = CertificateStore.certificate.data._id;

      if (data.status === "DECLINED") {
        NotificationStore.showError("Transfer failed!");

        return;
      }

      if (data.status === "ACCEPTED") {
        const certificate = omitEmptyFields({
          id: certificateId,
          projectId: transferData.projectId,
          lineItem: transferData.lineItem
        });
        const data = {
          transferId: transferData.transferId,
          orderNumber: transferData.orderNumber,
          receiver: customer._id,
          certificates: [certificate],
        };

        TransferStore.transferCertificates(data)
          .then(() => {
            NotificationStore.showSuccess("Successfully transferred!");
            this.props.SigningStore.closeSigner();
            this.setState({transferOpen: false});
            this.props.history.push(`/certificates/archive/${certificateId}`);
          })
          .catch((error) => NotificationStore.showError(`${error}`)
          );
      }
    });

    WS.listen("transaction:test:assign", async (res) => {
      SigningStore.closeSigner();
      this.closeNewTest();

      if (res.status === "DECLINED") {
        const action = () => {
          switch (res.data.status) {
          case STATUSES.APPROVED:
            return "approving";
          case STATUSES.ASSIGNED:
            return "assigning";
          }
        };
        NotificationStore.showError(`Test ${action()} failed!`);

        return;
      }

      await CertificateStore.getCertificateById(this.props.match.params.id);

      switch (res.data.status) {
      case STATUSES.APPROVED:
        NotificationStore.showSuccess("Successfully approved!");
        break;
      case STATUSES.ASSIGNED:
        NotificationStore.showSuccess("Successfully assigned!");
      }
    });

    WS.listen("transaction:test:witness", async (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      NotificationStore.showSuccess("Successfully witnessed!");
      await CertificateStore.getCertificateById(this.props.match.params.id);
    });

    WS.listen("transaction:test:witnessRequest", async (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      await CertificateStore.getCertificateById(this.props.match.params.id);
      NotificationStore.showSuccess("Successfully assigned!");
    });
  }

  componentWillReceiveProps(nextProps, nextContext) { // eslint-disable-line
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.CertificateStore.getCertificateById(nextProps.match.params.id);
    }
  }
}

export default compose(
  inject("CertificateStore", "TransferStore", "NotificationStore", "SigningStore", "UserStore", "TestStore"),
)(withStyles(observer(Details), styles));
