export default (theme) => ({
  fullScreenContainer: {
    backgroundColor: "#fff",
    padding: theme.spacing(4),
    paddingBottom: 0,
  },
  openButton: {
    display: "flex",
    cursor: "pointer",
    userSelect: "none",
  },
  commonPlotContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    backgroundColor: "#fff",
  },
  plotContainer: {
    height: 400,
    maxHeight: 400,
  },
  fullScreenPlotContainer: {
    height: "calc(100vh - 115px)",
    maxHeight: "calc(100vh - 115px)",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  logo: {
    position: "absolute",
    top: 20,
    right: 20,
    width: 200,
  }
});
