import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Grid, Paper, InputAdornment, CircularProgress, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import qs from "qs";
import {observer} from "mobx-react";
import {withRouter} from "react-router";
import {keys, map, prop, tail, mergeAll, uniqBy} from "ramda";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import Table from "@core/components/Table";
import {Input} from "@core/components/Form";
import ColumnsSelect from "@core/components/ColumnsSelect";
import TableNavigation from "@core/components/TableNavigation";
import TableFooter from "@core/components/TableFooter";
import MODULES from "@core/constants/modules";
import useStores from "../../../../../useStores";
import styles from "./styles";
import {
  columns,
  NAVIGATION_CONFIG,
  TABS,
  TEST_STATUSES_BY_MODULE,
  LINK_BY_MODULE,
  WITNESS_REVIEW_STATUSES_BY_TAB
} from "./data";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import useSetInitialViewQueryParam from "@core/hooks/useSetInitialViewQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const MultipleSigningChoose = observer(({classes, location, match, handleStep}) => {
  const {TestStore, UserStore} = useStores();
  const {status, data: tests, total, fetch, selected} = TestStore.tests;
  const [module] = UserStore.user.data.company.modules;

  const [filtration, setFiltration] = useState({});

  const savedSelectedColumns = JSON.parse(localStorage.getItem(match.path));
  const [selectedColumns, setSelectedColumns] = useState(savedSelectedColumns || map(prop("dataIndex"), columns));

  const testStatuses = TEST_STATUSES_BY_MODULE[module.name];
  const currentStatus = qs.parse(tail(location.search)).view || TABS.AVAILABLE_TO_SIGN;
  const statuses = testStatuses[currentStatus];
  const hideUnsignedCertificates = currentStatus === TABS.AVAILABLE_TO_SIGN && !filtration.showUnsignedCertificates;
  const witnessStatuses = module.name === MODULES.WITNESS ? WITNESS_REVIEW_STATUSES_BY_TAB[currentStatus] : [];
  const selectedTests = useRef();

  const {certificateIds} = qs.parse(tail(location.search));
  
  const selectedIds = selected.map((test) => test._id);

  useEffect(() => {
    return () => TestStore.tests.reset({shouldMerge: false, selected: selectedTests.current});
  }, []);

  const initialView = useMemo(() => keys(testStatuses)[0], [testStatuses]);
  useSetInitialViewQueryParam(initialView);

  useFetchTableDataByQueryParam({
    getStore: () => TestStore,
    dataKey: STORE_DATA.tests,
    matchPath: match.path,
    defaultParams: {
      statuses,
      witnessStatuses,
      certificateIds,
      hideUnsignedCertificates
    }
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    TestStore.tests.load({limit, offset: 0});
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    TestStore.tests.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  const onFilterTableView = (selectedOptions) => {
    const filtrationValues = mergeAll(selectedOptions);
    setFiltration(filtrationValues);
    TestStore.tests.load({hideUnsignedCertificates: !filtrationValues.showUnsignedCertificates});
  };

  const onSelect = useCallback(
    (ids) => {
      const oldSelected = selected.filter((test) => ids.includes(test._id));
      const newSelected = tests.filter((test) => ids.includes(test._id));

      const uniqSelected = uniqBy(prop("_id"), [...oldSelected, ...newSelected]);

      TestStore.tests.setSelected(uniqSelected);
    },
    [tests, selected]
  );

  const onSubmit = () => {
    selectedTests.current = TestStore.tests.selected;
    handleStep("Sign Selected Tests");
  };

  return (
    <Grid container spacing={5}>
      <Grid container item justifyContent="space-between">
        <Grid item xs>
          <Input
            name='search'
            endAdornment={
              <InputAdornment position="start">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Search Tests"}
            onChange={handleSearch}
          />
        </Grid>

        <Grid item>
          <ColumnsSelect
            columns={columns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.pageTable}>
          <TableNavigation
            views={keys(testStatuses)}
            currentView={currentStatus}
            link={LINK_BY_MODULE[module.name]}
            query="view"
            onFilter={onFilterTableView}
            options={NAVIGATION_CONFIG[module.name]}
          />
          <Table
            setSelectedItems={onSelect}
            selectedItems={selectedIds}
            selectedColumns={selectedColumns}
            allowRowSelection={currentStatus !== TABS.NOT_AVAILABLE_TO_SIGN}
            items={tests}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => TestStore.tests.load({sort})}
            saveSortToQueryParams
            total={total}
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={tests}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              selectedItems={selected}
              onOffsetChange={(offset) => TestStore.tests.load({offset})}
              onLimitChange={handleRowsPerPageChange}
            />
          </Table>
        </Paper>
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            disabled={!selected.length}
            onClick={onSubmit}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default withRouter(withStyles(MultipleSigningChoose, styles));
