export default (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    marginTop: theme.spacing(2),
  },
  text: {
    position: "absolute",
    width: "100%",
    fontSize: "1.5rem",
    top: "50%",
    textAlign: "center",
    color: "#a5a5a5",
  }
});
