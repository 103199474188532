import React, {useState, useEffect, useMemo} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {groupBy, partition, values} from "ramda";
import AnalyticsSlider from "@core/components/AnalyticsSlider";
import TableSkeleton from "@core/components/TableSkeleton";
import FilterableSortableHeaderCell from "@core/components/FilterableSortableHeaderCell";
import {getActivityNumber, getTestName, getTestResult, sort, filterElements, sortTestsByType, generateID, getTestVersions} from "@core/helpers";
import {getDefaultColumns} from "./services";
import {STATUSES, STATUSES_DESCRIPTION, TYPES} from "@core/constants/test";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import {
  CELLS_WITH_NA_VALUE,
  COLUMNS,
  COLUMNS_CONFIG,
  INSPECTION_RESULT_BY_WITNESS_STATUS,
  INSPECTION_RESULTS,
} from "./constants";

const groupTests = (tests, groupByProps, withGrouping) => {
  return groupBy((test) => {
    if(!withGrouping) return test.retestHash || test._id || test.displayName;

    if(groupByProps) {
      return groupByProps.map((prop) => test[prop]).join("");
    }

    return test.type +
      test.confidential +
      test.displayName +
      test.properties?.analysis +
      test.productType?._id +
      test.status +
      test.properties?.tags?.sort().join("") +
      test[COLUMNS.WITNESS].join("") +
      test[COLUMNS.COMPANY] +
      test[COLUMNS.LAB] +
      test[COLUMNS.RESULT] +
      test[COLUMNS.INSPECTION_RESULT] +
      test[COLUMNS.ACTIVITY_NUMBER] +
      test[COLUMNS.QCP_ITEM];
  }, tests);
};

const formatTests = (tests, groupByProps, withGrouping) => {
  const formattedTests = tests.map((test) => {
    const witnessed = test.witnesses.length;
    const witnessWithResult = test.witnesses.find((witness) => witness.status !== WITNESS_STATUSES.UNSET) || {};
    const inspectionResult = INSPECTION_RESULT_BY_WITNESS_STATUS[witnessWithResult.status] || INSPECTION_RESULTS.UNSET;

    const witnessCompanies = test.witnesses.map((witness) => witness.company?.name || witness.name);

    return {
      ...test,
      [COLUMNS.TITLE]: getTestName(test),
      [COLUMNS.HEAT]: test.item_heat || "",
      [COLUMNS.PRODUCT_TYPE]: test.productType ? test.productType.name : "",
      [COLUMNS.QCP_ITEM]: test.properties.acceptanceItem || "",
      [COLUMNS.WITNESS]: witnessCompanies,
      [COLUMNS.COMPANY]: test.company?.name || "",
      [COLUMNS.LAB]: test.assignee?.name || "",
      [COLUMNS.STATUS]: STATUSES_DESCRIPTION[test.status],
      [COLUMNS.RESULT]: getTestResult(test).text,
      [COLUMNS.INSPECTION_RESULT]: witnessed ? inspectionResult : "",
      [COLUMNS.ACTIVITY_NUMBER]: getActivityNumber(test) || "",
    };
  });
  
  const groupedTests = groupTests(formattedTests, groupByProps, withGrouping);
  const groupedTestsIds = values(groupedTests).map((tests) => tests.map((test) => test._id));
  
  return formattedTests.map((test) => {
    const groupedTests = groupedTestsIds.find((tests) => tests.includes(test._id));

    return {
      ...test,
      [COLUMNS.QUANTITY]: groupedTests.length.toString(),
    };
  });
};

const sortTests = (formattedTests, order, orderBy) => {
  const [newTests, filledTests] = partition((test) => test.status === STATUSES.EMPTY, formattedTests);
  const [testsWithTags, restFilledTests] = partition((test) => test.properties?.tags?.length, filledTests);

  const defaultSorted = [
    ...sortTestsByType(newTests),
    ...sortTestsByType(testsWithTags),
    ...sortTestsByType(restFilledTests),
  ];

  return order && orderBy ? sort(order, orderBy, formattedTests) : defaultSorted;
};

const TestSummaryTable = ({
  setFilteredTestIds,
  withGrouping,
  tests,
  collapsible,
  children,
  groupByProps,
  isLoaded = true,
  ...props
}) => {
  const [allTests, setAllTests] = useState([]);
  const [testsToShowAnalytics, setTestsToShowAnalytics] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filter, setFilter] = useState({});
  const [openTests, setOpenTests] = useState({});

  const columns = props.columns || getDefaultColumns(withGrouping, allTests);
  
  const formattedAllTests = useMemo(() => {
    const testsWithoutSplitTests = allTests.filter((test) => test.type !== TYPES.SPLIT);

    return formatTests(testsWithoutSplitTests, groupByProps, withGrouping);
  }, [allTests.length]);
  
  const formattedTests = useMemo(() => {
    const [testsWithRetest, restTests] = partition((test) => !!test.retest?.length, tests);

    const retests = testsWithRetest.reduce((acc, test) => {
      const retestHash = generateID();
      getTestVersions(test).forEach((t) => acc.push({...t, retestHash}));

      return acc;
    }, []);

    return formatTests(restTests.concat(retests), groupByProps, withGrouping);
  }, [tests]);

  useEffect(() => {
    if(allTests.length || !tests.length) return;

    setAllTests(tests);
  }, [tests]);

  useEffect(() => {
    if(!props.allTests) return;

    setAllTests(props.allTests);
  }, [props.allTests]);

  useEffect(() => {
    if(!setFilteredTestIds) return;

    const filtered = filterElements(filter, formattedAllTests);
    const sorted = sortTests(filtered, order, orderBy);
    const newTestsIds = sorted.map((test) => test._id);

    setFilteredTestIds(newTestsIds);
  }, [filter, orderBy, order]);

  const collapseTests = (tests, open) => {
    const data = tests.reduce((acc, test) => {
      return {...acc, [test._id]: !open};
    }, openTests);

    setOpenTests(data);
  };

  const groupedTests = groupTests(formattedTests, groupByProps, withGrouping);

  const groupedTestsList = values(groupedTests);

  const isCollapsible = collapsible && groupedTestsList.some((bunch) => bunch.length > 1);

  const isWitnessed = allTests.some((test) => test.witnesses.length);

  const filledColumns = columns.filter((column) => COLUMNS_CONFIG[column].permanent || formattedAllTests.some((test) => test[column].length));
  const filteredColumns = !isWitnessed ? filledColumns.filter((column) => column !== COLUMNS.INSPECTION_RESULT) : filledColumns;

  return (
    <>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            {filteredColumns.map((column) =>
              (COLUMNS_CONFIG[column].isSorted && setFilteredTestIds) ? (
                <FilterableSortableHeaderCell
                  key={column}
                  id={column}
                  setOrder={setOrder}
                  order={order}
                  setOrderBy={setOrderBy}
                  orderBy={orderBy}
                  filter={filter}
                  setFilter={setFilter}
                  elements={formattedAllTests}
                  cellsWithNAValue={CELLS_WITH_NA_VALUE}
                >
                  {COLUMNS_CONFIG[column].title}
                </FilterableSortableHeaderCell>
              ) : (
                <TableCell key={column}>
                  {COLUMNS_CONFIG[column].title}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            <>
              {groupedTestsList.map((bunchOfSameTests) => bunchOfSameTests.map((test, index) => {
                if (index && !openTests[test._id]) return null;

                const groupedTests = !index && !openTests[test._id] ? bunchOfSameTests : [test];

                return (
                  <TableRow
                    hover
                    key={test._id}
                  >
                    {filteredColumns.map((column) => {
                      const params = {
                        collapseTests,
                        openTests,
                        isCollapsible,
                        test,
                        groupedTests,
                        bunchOfSameTests,
                        setTestsToShowAnalytics,
                        index,
                        tests,
                        ...props
                      };

                      return (
                        <TableCell
                          key={column + test._id}
                          width={COLUMNS_CONFIG[column].width}
                        >
                          {COLUMNS_CONFIG[column].render(params)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }))}
              {children || (
                !groupedTestsList.length && (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      No data.
                    </TableCell>
                  </TableRow>
                )
              )}
            </>
          ) : (
            <TableSkeleton columns={filteredColumns.length} />
          )}
        </TableBody>
      </Table>
      <AnalyticsSlider
        open={testsToShowAnalytics && testsToShowAnalytics.length > 0}
        close={() => setTestsToShowAnalytics([])}
        tests={testsToShowAnalytics}
      />
    </>
  );
};

TestSummaryTable.defaultProps = {
  withGrouping: false,
  collapsible: false,
  withPlots: true,
};

export default TestSummaryTable;
