export default (theme) => ({
  signUp: {
    padding: 20,
    bottom: 0,
    width: "100%",
    left: 0,
    position: "absolute",
  },
  privacyPolicy: {
    display: "block",
    marginTop: theme.spacing(1.5),
  },
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  submit: {
    marginTop: 30,
    background: theme.palette.primary.main,
  },
  recoverPassword: {
    marginTop: 15,
  },
  textButton: {
    "&:hover": {
      backgroundColor: "unset",
    }
  },
  signInWithSocialAccountSection: {
    padding: `${theme.spacing(2)} 0`
  },
  socialIcon: {
    width: theme.spacing(1.75),
    lineHeight: theme.spacing(1.75),
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  }
});
