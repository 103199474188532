import React, {useState, useMemo, useEffect, useCallback} from "react";
import {observer} from "mobx-react-lite";
import {isNil} from "ramda";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell, Button,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import TableSkeleton from "@core/components/TableSkeleton";
import FilterableSortableHeaderCell from "@core/components/FilterableSortableHeaderCell";
import TableFooter from "@core/components/TableFooter";
import RawMaterialForm from "./components/RawMaterialForm";
import modules from "@core/constants/modules";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import styles from "./styles";
import useStores from "../../../useStores";
import {formatRawMaterials} from "./helpers";
import {MdAdd} from "react-icons/md";
import {filterElements, sort} from "@core/helpers";

const RAW_MATERIAL = {
  batchNumber: "",
  type: "",
  manufacturer: "",
  tradeName: "",
  pipes: "",
  file: {},
};

const RawMaterialsTable = observer(({classes, deleteRawMaterial, savedRawMaterials = [], isLoaded = true, disabled, ...props}) => {
  const {UserStore, CampaignStore} = useStores();

  const [module] = UserStore.user.data.company.modules;

  const campaign = CampaignStore.campaign;

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filter, setFilter] = useState({});

  const [rawMaterials, setRawMaterials] = useState([]);
  const [materialToDelete, setMaterialToDelete] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);

  useEffect(() => {
    setRawMaterials(formatRawMaterials(savedRawMaterials));
  }, [savedRawMaterials.length]);

  const updateFilter = useCallback((filter) => {
    setFilter(filter);
    setOffset(0);
  }, []);

  const filtered = useMemo(() => filterElements(filter, rawMaterials), [filter, rawMaterials]);
  const sorted = useMemo(() => sort(order, orderBy, filtered), [filtered, order, orderBy, filter]);
  const rawMaterialsPaginated = useMemo(() => sorted.slice(offset, offset + limit), [sorted, offset, limit]);

  return (
    <Paper className={classes.paper}>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <FilterableSortableHeaderCell
              id="batchNumber"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={rawMaterials}
            >
              Batch number
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="type"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={rawMaterials}
            >
              Type
            </FilterableSortableHeaderCell>
            {(savedRawMaterials.some((material) => material.manufacturer) || !isNil(editableIndex)) && (
              <FilterableSortableHeaderCell
                id="manufacturer"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={rawMaterials}
              >
                Manufacturer
              </FilterableSortableHeaderCell>
            )}
            {(savedRawMaterials.some((material) => material.tradeName) || !isNil(editableIndex)) && (
              <FilterableSortableHeaderCell
                id="tradeName"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={rawMaterials}
              >
              Trade name
              </FilterableSortableHeaderCell>
            )}
            <TableCell>{campaign.type === CAMPAIGN_TYPES.WELDING ? "Welds" : "Pipes"}</TableCell>
            <TableCell width="250">File</TableCell>
            {!disabled && <TableCell width="130">Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            <>
              {rawMaterialsPaginated.map((material, index) => (
                <RawMaterialForm
                  key={material._id || index}
                  setEditableIndex={setEditableIndex}
                  setRawMaterials={setRawMaterials}
                  setMaterialToDelete={setMaterialToDelete}
                  index={index}
                  material={material}
                  editableIndex={editableIndex}
                  rawMaterials={rawMaterials}
                  savedRawMaterials={savedRawMaterials}
                  disabled={disabled}
                  {...props}
                />
              ))}
              {!rawMaterials.length && (
                <TableRow>
                  <TableCell colSpan={7}>
                    No data
                  </TableCell>
                </TableRow>
              )}
              {(module.name === modules.PRODUCER && !disabled) && (
                <TableRow colSpan={7}>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setRawMaterials([RAW_MATERIAL, ...rawMaterials]);
                        setEditableIndex(rawMaterials.length);
                      }}
                    >
                      <MdAdd size={80} />Add new
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableSkeleton columns={5} />
          )}
        </TableBody>
        <TableFooter
          isLoaded={isLoaded}
          items={rawMaterialsPaginated}
          total={sorted.length}
          limit={limit}
          offset={offset}
          onOffsetChange={setOffset}
          onLimitChange={setLimit}
        />
      </Table>
      <Confirmation
        open={!!materialToDelete}
        onCancel={() => setMaterialToDelete(null)}
        onConfirm={async () => {
          await deleteRawMaterial(materialToDelete);
          setMaterialToDelete(null);
        }}
        alertText="Are you sure you want to remove the raw material?"
      />
    </Paper>
  );
});

export default withStyles(RawMaterialsTable, styles);