import React from "react";
import {Avatar} from "@mui/material";
import IconistsIcon from "@core/components/IconistsIcon";
import LightTooltip from "@core/components/LightTooltip";
import Image from "@core/components/Image";
import CompanyDetails from "../CompanyDetails";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import MODULES from "@core/constants/modules";
import themeSettings from "../../../../../theme-settings";

const AVATAR_COLORS_BY_MODULE = {
  [MODULES.PRODUCER]: themeSettings.palette.warning,
  [MODULES.BASIC]: themeSettings.palette.warning,
  [MODULES.WITNESS]: themeSettings.palette.error,
  [MODULES.LAB_INTERNAL]: {
    ...themeSettings.palette.primary,
    text: themeSettings.palette.primary.main,
  },
  [MODULES.END_OWNER]: themeSettings.palette.deactivate,
};

const CompanyAvatar = ({company}) => {
  const [module] = company.modules;

  return (
    <LightTooltip
      placement="bottom"
      title={(
        <CompanyDetails
          company={company}
        />
      )}
      arrow
    >
      <Avatar
        sx={{
          width: 30,
          height: 30,
          cursor: "pointer",
          border: `1px solid ${AVATAR_COLORS_BY_MODULE[module.name].main}!important`,
          bgcolor: (theme) => !company.logo ?
            AVATAR_COLORS_BY_MODULE[module.name].backgroundColor :
            theme.palette.common.white,
          "& img": {
            maxWidth: "100%",
            maxHeight: "100%"
          }
        }}
      >
        {company.logo ? (
          <Image
            src={company.logo}
            alt={company.name}
          />
        ) : (
          <IconistsIcon
            width="18px"
            color={AVATAR_COLORS_BY_MODULE[module.name].text}
            icon={ICONISTS_ICONS.building}
          />
        )}
      </Avatar>
    </LightTooltip>
  );
};

export default CompanyAvatar;