import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Button} from "@mui/material";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import referenceService from "@core/api/reference-service";
import WS from "@core/api/socketConnection";
import UserService from "@core/api/user-service";

import useStores from "../../../../useStores";
import CompanyBCService from "../../../../Blockchain/companies-service";
const ResetSigningKey = () => {
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const {NotificationStore, UserStore, SigningStore} = useStores();

  const user = UserStore.user.data;

  useEffect(() => {
    WS.listen("transaction:user:key_reset", async () => {
      SigningStore.closeSigner();
      await UserService.resetBCAddress();
      NotificationStore.showSuccess("Your signing key has been reset!");
    });
  }, []);

  const resetKey = useCallback(async () => {
    setConfirmationOpen(false);

    const transactionObject = CompanyBCService.changeUserPermissionsTX(user.company._id, user.bc_address, 0);
    const reference = await referenceService.create({
      creator: user._id,
      transactions: [
        {
          transactionObject: transactionObject,
          payload: {
            type: "user",
            action: "key_reset",
            historyData: {
              editedUser: user.username
            },
            userId: user._id
          }
        },
      ]
    });

    SigningStore.openSigner({id: reference._id}, {
      title: "Reset signing key",
      description: "Scan this QR code in order to get your signing key reset.",
    });
  }, []);

  if(!user.bc_address) return null;

  return (
    <>
      <Button
        margin="normal"
        variant="contained"
        color="secondary"
        onClick={() => setConfirmationOpen(true)}
      >
        Reset signing key
      </Button>
      <Confirmation
        open={isConfirmationOpen}
        alertText="Are you sure you want to reset your signing key?"
        onCancel={() => setConfirmationOpen(false)}
        onConfirm={resetKey}
      />
    </>
  );
};

export default observer(ResetSigningKey);
