import React from "react";
import {Select} from "@core/components/Form";
import {MenuItem} from "@mui/material";

const EditableDropdownCell = ({editable, value, onChange, options}) => {
  if(!editable) return value || "-";

  return (
    <Select
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>{option}</MenuItem>
      ))}
    </Select>
  );
};

export default EditableDropdownCell;