export function getBase64ImageCircularCropped(canvas, image, x, y, width, height) {
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, x, y, width, height, 0, 0, width, height);
  const imageInBase64 = canvas.toDataURL("image/png");

  return imageInBase64;
}

export function dataUrlToFile(dataUrl, fileName) {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }

  const extension = mime.split("/")[1];

  return new File([u8arr], fileName + "." + extension, {type:mime});
}

export function eventPreventDefault(event) {
  event.preventDefault();
}