import axios from "axios";
import {ACTIONS} from "@core/constants/api";
import {ROUTES} from "@core/api/routes";

export default {
  update:  (data, userId) => {
    return new Promise((resolve, reject) => {
      axios.put(ROUTES.USER[ACTIONS.UPDATE](userId), data, {
        withCredentials: true,
        responseType: "json",
      }).then( (response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
  },
  changePassword: (data) => {
    return axios.patch(ROUTES.ACCOUNT[ACTIONS.CHANGE_PASSWORD], data, {
      withCredentials: true,
      responseType: "json",
    });
  },
};
