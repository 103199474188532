import React, {useState} from "react";
import {Grid, Button, Typography, Table, TableBody, TableRow, TableCell, TableHead} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import useStores from "../../useStores";
import styles from "./styles";

const ImportFromJSON = observer(({classes}) => {
  const {TestStore} = useStores();
  const [testsData, setTestsData] = useState("");
  const [importingLogs, setImportingLogs] = useState({shouldCheckJson: false});

  const checkTestsData = async () => {
    const logs = await TestStore.importJsonData({testsData, shouldCheckJson: true});
    setImportingLogs({...logs, shouldCheckJson: true});
  };

  const submitTestsData = async () => {
    const logs = await TestStore.importJsonData({testsData, shouldCheckJson: false});
    setImportingLogs({...logs, shouldCheckJson: false});
  };

  const copyToBuffer = () => navigator.clipboard.writeText(JSON.stringify(importingLogs.testsWithoutEmptyTemplates, null, 2));

  return (
    <div className="padded-container">
      <Grid container spacing={3}>
        <Grid item xs={23}>
          <h1>Import Tests From JSON</h1>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom>
            Paste the json text in the box:
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <div className={classes.inputContainer}>
            <textarea
              className={classes.outputContainer__textArea}
              name="JSON data"
              resize="none"
              onChange={(event) => setTestsData(event.target.value)}
              value={testsData}
            />
          </div>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={3}>
            <Button
              fullWidth
              size={"large"}
              variant={"contained"}
              component="span"
              onClick={checkTestsData}
              disabled={!testsData}
            >
              Check Test Data
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              size={"large"}
              variant={"contained"}
              component="span"
              onClick={submitTestsData}
              disabled={!testsData}
            >
              Import Test Data
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {importingLogs.logsOfImportedTests && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container alignContent="space-between">
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Material Certificate ID</TableCell>
                    <TableCell className={classes.tableHead}>Heat</TableCell>
                    <TableCell className={classes.tableHead}>Lot ID</TableCell>
                    <TableCell className={classes.tableHead}>{importingLogs.shouldCheckJson ? "Tests to import" : "Successfully imported"}</TableCell>
                    <TableCell className={classes.tableHead}>Tests already imported</TableCell>
                    <TableCell className={classes.tableHead}>Not enough empty tests</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {importingLogs.logsOfImportedTests.filter((log) => log.certificateId).map((item, index) => (
                    <TableRow key={`${item.certificateId}${index}`} className={classes.tableRow}>
                      <TableCell className={classes.certificateId}>{item.certificateId}</TableCell>
                      <TableCell>{item.heat}</TableCell>
                      <TableCell>{item.lotId}</TableCell>
                      <TableCell className={classes.success}>{item.successfullyImportedTests}</TableCell>
                      <TableCell className={classes.fail}>{item.unSuccessfullyImportedTests.alreadyImported}</TableCell>
                      <TableCell className={classes.fail}>{item.unSuccessfullyImportedTests.withoutEmptyTemplates}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                Tests without matching certificate(s) are shown in the table below. <br/>
                Ask the manufacturer to add the missing certificate(s) and request again the test(s).<br/>
                Afterwards, you can either upload the same file once again, or just copy the field below and import it.
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignContent="space-between">
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Heat</TableCell>
                    <TableCell className={classes.tableHead}>Lot ID</TableCell>
                    <TableCell className={classes.tableHead}>Number of tests without matching certificate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {importingLogs.logsOfImportedTests.filter((log) => !log.certificateId).map((item, index) => (
                    <TableRow key={`${item.heat}${index}`} className={classes.tableRow}>
                      <TableCell>{item.heat}</TableCell>
                      <TableCell>{item.lotId}</TableCell>
                      <TableCell className={classes.fail}>{item.noMatchingCertificate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                Remainder of tests that could not be imported:
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={9} className={classes.buttonContainer}>
              <div className={classes.outputContainer}>
                <textarea
                  className={classes.outputContainer__textArea}
                  name="converted File"
                  resize="none"
                  disabled
                  value={JSON.stringify(importingLogs.testsWithoutEmptyTemplates, null, 2)}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                size={"large"}
                variant={"contained"}
                component="span"
                onClick={copyToBuffer}
                disabled={!importingLogs.testsWithoutEmptyTemplates}
              >
                Copy To Buffer
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
});

export default withStyles(ImportFromJSON, styles);
