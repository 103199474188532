import React, {memo, useEffect, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {splitEvery} from "ramda";
import PipesRows from "../PipesRows";
import qs from "qs";
import {useLocation} from "react-router";
import {PIPE_CONFIG} from "../../../../constants";
import {SPACING_BETWEEN_STALKS} from "../../../../../../constants";

const ROW_Y_BASE= PIPE_CONFIG.HEIGHT + SPACING_BETWEEN_STALKS;

const Pipes = observer(({Component, scale, pipes, animateShape, splitBy, setOpenEntityIndex, setEntitiesToView, pipesRef, index, ...props}) => {
  const location = useLocation();

  const chunks = useMemo(() => {
    const chunks = splitEvery(splitBy, pipes);

    return chunks;
  }, [splitBy, pipes.length]);

  const storedY = pipesRef.current[index - 1];

  const y = useMemo(() => storedY || 0, [storedY]);

  const nextY = useMemo(() => y + ROW_Y_BASE * chunks.length, [y, chunks.length]);

  pipesRef.current[index] = nextY;
  
  const {pipe, stalk} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const settings = useMemo(() => ({...props.settings, welds: false}), [props.settings]);

  useEffect(() => {
    if(stalk || !pipe) return;

    const index = pipes.findIndex(({pipeNumber}) => pipeNumber === pipe);

    if(index === -1) return;

    setOpenEntityIndex(index);
    setEntitiesToView({pipes});
  }, [pipe]);

  if(!pipes.length) return null;

  return (
    <PipesRows
      PipeComponent={Component}
      chunks={chunks}
      splitBy={splitBy}
      pipes={pipes}
      scale={scale}
      settings={settings}
      animateShape={animateShape}
    />
  );
});

export default memo(Pipes);