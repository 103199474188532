import React, {useState} from "react";
import {TableCell, Grid, IconButton} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {IoMdCloseCircleOutline} from "react-icons/io";
import styles from "./styles";
import {acceptancesConfig} from "../data";

const HeaderCell = ({classes, title, className, width, el, grade, acceptance, onRemove}) => {
  const [visible, setVisible] = useState(false);

  const config = grade.elements.find((e) => e.bm === el.bm);
  const exception = acceptance && acceptancesConfig[acceptance] ? acceptancesConfig[acceptance].elements.find((exc) => exc.bm === el.bm) : null;

  return (
    <TableCell
      className={className}
      width={width}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {title}
        </Grid>
        {(!config && !exception) && (
          <Grid item>
            <IconButton
              className={visible ? classes.visible : classes.hidden}
            >
              <IoMdCloseCircleOutline
                onClick={onRemove}
              />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};

export default withStyles(HeaderCell, styles);