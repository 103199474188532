import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

const OtherTest = ({qcpConfig}) => (
  <>
    <h3>Local Requirements</h3>
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>Test</TableCell>
          <TableCell>Requirements</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {qcpConfig.properties.elements.map((element) => (
          <TableRow>
            <TableCell>{element.test}</TableCell>
            <TableCell>{element.notes || "-"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>
);

export default OtherTest;