import {CAMPAIGN_ACCESS} from "@core/constants/campaign";
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import useStores from "../../../../../useStores";
import {Grid, Box, Typography} from "@mui/material";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import StatisticsSection from "@core/components/StatisticsSection";
import DashboardTable from "@core/components/DashboardCompany/components/Table";
import modules from "@core/constants/modules";
import {ORDER_STATUS} from "@core/constants/transfer";
import {orderColumns, campaignColumns} from "./columns";

const EndOwnerDashboard = ({userCompanyId}) => {
  const {StatisticStore} = useStores();

  useEffect(() => {
    StatisticStore.getCompanyStatistics(userCompanyId);
  }, []);

  const statistic = StatisticStore.statistic;

  return (
    <Box
      sx={{
        padding: 4
      }}
    >
      <Grid container spacing={2}>
        <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
          Dashboard
        </Typography>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StatisticsSection
                isLoading={!statistic.isLoaded}
                data={[{
                  number: statistic.data.openCampaigns || 0,
                  description: "Open campaigns",
                  link: "/campaigns",
                  icon: ICONISTS_ICONS.target,
                }, {
                  number: statistic.data.totalCampaigns || 0,
                  description: "Total campaigns",
                  link: "/campaigns",
                  icon: ICONISTS_ICONS.target,
                }, {
                  number: statistic.data.openOrdersToSupplier || 0,
                  description: "Open orders",
                  link: `/orders?view=${ORDER_STATUS.OPEN}`,
                  icon: ICONISTS_ICONS.folderPaper,
                }, {
                  number: statistic.data.totalOrdersToSupplier || 0,
                  description: "Total orders",
                  link: "/orders",
                  icon: ICONISTS_ICONS.folderPaper,
                }, {
                  number: statistic.data.activeUsers || 0,
                  description: "Active users",
                  link: "/admin/users",
                  icon: ICONISTS_ICONS.group,
                },{
                  number: statistic.data.specifications || 0,
                  description: "Specifications",
                  link: "/specifications",
                  icon: ICONISTS_ICONS.settingsSliderThree,
                }]}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardTable
                title="Campaigns"
                noFoundText="No campaigns found."
                type="campaigns"
                columns={campaignColumns}
                params={{access: CAMPAIGN_ACCESS.CREATOR}}
                onRowClick={(campaign) => window.open(`/campaigns/${campaign.type.toLowerCase()}/${campaign._id}`, "_self")}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardTable
                title="Open orders"
                noFoundText="No orders found."
                type="orders"
                columns={orderColumns}
                params={{module: modules.END_OWNER}}
                onRowClick={(transfer) => window.open(`orders/${transfer._id}`, "_self")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(EndOwnerDashboard);