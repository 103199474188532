import React from "react";
import * as yup from "yup";
import draftToHtml from "draftjs-to-html";
import {observer} from "mobx-react-lite";
import {Dialog, Typography, DialogContent} from "@mui/material";
import {EditorState, convertToRaw} from "draft-js";
import useStores from "../../../useStores";
import Form from "./Form";
import FeedbackList from "./FeedbackList";
import {STATUSES} from "@core/constants/order";

const validationSchema = yup.object().shape({
  message: yup.object().required("This field is required!"),
  type: yup.string().required("This field is required!"),
});

const SupplierFeedbackBox = ({open, onClose, certificateId, lineItem, approvable, lineItemStatus}) => {
  const {CertificateStore} = useStores();

  const onSubmit = async (values, {resetForm}) => {
    const data = {
      messages: [draftToHtml(convertToRaw(values.message.getCurrentContent()))],
      type: values.type,
    };

    await CertificateStore.sendFeedback(certificateId, lineItem, data);
    resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <Typography component="h5" variant="h5" gutterBottom>
          Feedback for the Supplier
        </Typography>
        {!approvable && lineItemStatus !== STATUSES.ACCEPTED && (
          <Form
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={{
              message: EditorState.createEmpty(),
              type: ""
            }}
          />
        )}
        <FeedbackList
          lineItemStatus={lineItemStatus}
          onClose={onClose}
          approvable={approvable}
          certificateId={certificateId}
          lineItem={lineItem}
        />
      </DialogContent>
    </Dialog>
  );
};

export default observer(SupplierFeedbackBox);
