import React, {useCallback, useMemo} from "react";
import {Button, Fade, Dialog, DialogTitle, DialogContent, Grid, TextField as DateField, InputLabel} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import {omit} from "ramda";
import {FilesUploader} from "@core/components/Uploaders";
import {Input, MultipleSelect} from "@core/components/Form";
import useStores from "../../../useStores";
import * as yup from "yup";
import testsConfig from "@core/configs/test";
import {observer} from "mobx-react-lite";
import moment from "moment";
import {TYPES} from "@core/constants/test";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import styles from "./styles";
import FadeContent from "@core/components/FadeContent";

const Instruments = observer((props) => {
  const {classes} = props;
  const {InstrumentStore} = useStores();
  const date = props.initialValues.calibrationExpDate;
  const validationSchema = yup.object().shape({
    equipment: yup.string().required(),
    trademark: yup.string().required(),
    serialNumber: yup.string().required(),
    calibrationExpDate: yup.string()
      .required()
      .test("calibrationExpDate", "Should be later than today", (value = "") => {
        const dateValue = new Date(formatDate(value).toString()).getTime();
        const today = new Date().getTime();
        
        return  dateValue >= today;
      }),
  });

  const initialValues = {
    equipment: props.initialValues.equipment || "",
    trademark: props.initialValues.trademark || "",
    serialNumber: props.initialValues.serialNumber || "",
    calibrationExpDate: date ? moment(date).format(DATE_FORMAT) : "",
    defaultForTests: props.initialValues.defaultForTests || [],
    files: props.initialValues.files || [],
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }

    const split = date.split("-");
    const y = split[0];
    const m = split[1];
    const d = split[2];

    return moment([m, d, y].join("/"));
  };

  const types = useMemo(() => Object.values(testsConfig)
    .filter((config) => ![TYPES.SUPPLEMENT, TYPES.HEATTREATMENT, TYPES.SPLIT, TYPES.NOTES, TYPES.OTHER, TYPES.TREATMENT, TYPES.SUPPLIER, TYPES.STATEMENT, TYPES.INSPECTION_RELEASE_NOTE]
      .includes(config.type))
    .sort((a, b) => {
      if (a.type > b.type) return 1;

      if (b.type > a.type) return -1;

      return 0;
    }), []);

  const closeAndUpdate = () => {
    props.onClose();
    props.updateInstruments();
  };

  const updateInstrument = (values, id) => {
    InstrumentStore.update(values, id)
      .then(() => closeAndUpdate());
  };

  const createInstrument = (values) => {
    InstrumentStore.create(values)
      .then(() => closeAndUpdate());
  };

  return (
    <Dialog
      open={props.opened}
      aria-label={"Convert product"}
      onClose={props.onClose}
      maxWidth={"sm"}
      fullWidth
    >
      <Fade in={props.opened} timeout={300}>
        <FadeContent>
          <DialogTitle>
            {props.initialValues._id ? "Edit current instrument" : "Add a new instrument"}
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount
              onSubmit={(values) => {
                const id = props.initialValues._id;
                const isEdit = !!id;
                const data = omit(["calibrationExpDate"], values);
                data.calibrationExpDate = formatDate(values.calibrationExpDate);

                if (isEdit) updateInstrument(data, id);
                else createInstrument(data);
              }}

            >
              {(props) => {
                const {
                  values: {equipment, trademark, serialNumber, calibrationExpDate, defaultForTests, files},
                  errors, isValid, handleSubmit, handleChange, setFieldValue, touched, setFieldTouched
                } = props;

                const typeTitles = useMemo(() => types.map((test) => test.title), []);

                const defaultTitles = useMemo(() => {
                  const def = defaultForTests.map((type) => types.find((test) => test.type === type));

                  return def.length === 0 ? def : def.map((test) => test.title);
                }, [defaultForTests]);

                const change = (e) => {
                  e?.persist && e.persist();
                  handleChange(e);
                  setFieldTouched(e.target.name, true, false);
                };

                const changeDefaultForTest = useCallback((values) => {
                  const defaultTypes = values.map((title) => types.find((test) => test.title === title).type);
                  setFieldValue("defaultForTests", defaultTypes);
                  setFieldTouched("defaultForTests", true, false);
                }, [setFieldTouched, setFieldValue]);

                return (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Input
                          required
                          label={"Equipment name"}
                          name={"equipment"}
                          value={equipment}
                          onChange={change}
                          error={Boolean(errors.equipment) && touched.equipment}
                          errorMessage={errors.equipment}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          required
                          label={"Manufacturer"}
                          name={"trademark"}
                          value={trademark}
                          onChange={change}
                          error={Boolean(errors.trademark) && touched.trademark}
                          errorMessage={errors.trademark}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          required
                          label={"Serial number"}
                          name={"serialNumber"}
                          value={serialNumber}
                          onChange={change}
                          error={Boolean(errors.serialNumber) && touched.serialNumber}
                          errorMessage={errors.serialNumber}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.dateContainer}>
                        <InputLabel htmlFor="calibrationExpDate">
                          Calibration expiration date
                        </InputLabel>
                        <DateField
                          className={classes.dateField}
                          type="date"
                          name={"calibrationExpDate"}
                          value={calibrationExpDate}
                          onChange={change}
                          error={Boolean(errors.calibrationExpDate)}
                          helperText={errors.calibrationExpDate}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MultipleSelect
                          label={"Default for tests"}
                          value={defaultTitles}
                          elements={typeTitles}
                          onChange={changeDefaultForTest}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FilesUploader
                          label="Calibration reports"
                          name="files"
                          files={files}
                          onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                          changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={5} justifyContent="flex-end">
                      <Grid item xs="auto">
                        <Button
                          onClick={handleSubmit}
                          color={"primary"}
                          fullWidth
                          size={"large"}
                          variant={"contained"}
                          disabled={!isValid}
                        >
                          save
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </DialogContent>
        </FadeContent>
      </Fade>
    </Dialog>
  );
});

export default withStyles(Instruments, styles);