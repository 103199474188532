export default (theme) => ({
  menu: {
    flexDirection: "row",
    backgroundColor: "#fafafa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottom: "1px solid #e1e1e1",
    "& a": {
      textDecoration: "none"
    },
    "& button": {
      fontSize: "0.8125rem",
      borderRadius: 0,
      padding: "5px 30px",
      color: "#616161",
      borderBottom: "2px solid transparent",
    },
    "& .active": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    }
  },
});