export default (theme) => ({
  additional: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  item: {
    marginBottom: 20
  },
  name: {
    paddingTop: 10,
    marginBottom: 5,
    color: "#616161",
  },
  value: {
    fontWeight: theme.typography.fontWeightMedium,
    color: "#616161",
  }
});