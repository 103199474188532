import React, {Fragment} from "react";
import {Grid, DialogContent, Dialog} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ExaminationConditions from "@core/components/ExaminationConditions";
import EditFormUltrasonic from "@core/components/TestForms/UltrasonicTest";
import {values, filter, isEmpty} from "ramda";
import styles from "./styles";
import useStores from "../../../../useStores";

const UltrasonicTest = ({
  classes,
  test,
  addDataOpen,
  dataDialogClose,
  updateTest,
  formRef
}) => {
  const {UserStore} = useStores();
  const {properties} = test;

  return (
    <>
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item xs={6}>
          <h4 className={classes.name}>Test Standard</h4>
          <p className={classes.value}>{properties.testStandard}</p>
        </Grid>

        <Grid item xs={6}>
          <h4 className={classes.name}>Acceptance criteria</h4>
          <p className={classes.value}>{properties.acceptance}</p>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item xs={6}>
          <h4 className={classes.name}>Surface Condition</h4>
          <p className={classes.value}> {properties.surfacePreparation}</p>
        </Grid>

        {properties.surfaceRoughness && (
          <Grid item xs={6}>
            <h4 className={classes.name}>Surface Roughness [μm]</h4>
            <p className={classes.value}>{properties.surfaceRoughness}</p>
          </Grid>
        )}

        <Grid item xs={6}>
          <h4 className={classes.name}>Quantity Inspected</h4>
          <p className={classes.value}>{properties.quantityInspected}</p>
        </Grid>

        <Grid item xs={6}>
          <h4 className={classes.name}>Piece Identification</h4>
          <p className={classes.value}>{properties.pieceIdentification}</p>
        </Grid>
      </Grid>

      <Grid container marginBottom={2}>
        <ExaminationConditions
          test={test}
          file={test.properties.file}
        />
      </Grid>

      {(properties.examination?.speed || properties.examination?.gel || properties.examination?.overlap) && (
        <>
          <Grid container spacing={3}>
            <Grid item>
              <h3>Examination conditions</h3>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" spacing={3}>
            {properties.examination.speed && (
              <Grid item xs={4}>
                <h4 className={classes.name}>Scanning speed [mm/s]</h4>
                <p className={classes.value}>{properties.examination.speed}</p>
              </Grid>
            )}

            {properties.examination.gel && (
              <Grid item xs={4}>
                <h4 className={classes.name}>Couplant Gel</h4>
                <p className={classes.value}>{properties.examination.gel}</p>
              </Grid>
            )}

            {properties.examination.overlap && (
              <Grid item xs={4}>
                <h4 className={classes.name}>Scanning overlap minimum [%]</h4>
                <p className={classes.value}>{properties.examination.overlap}</p>
              </Grid>
            )}
          </Grid>
        </>
      )}

      {properties.elements?.map((item, index) => (
        <Fragment key={index}>
          {!!filter((value) => !isEmpty(value), values(item.technique)).length && (
            <>
              <Grid container spacing={3}>
                <Grid item>
                  <h3>Examination technique</h3>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" spacing={3}>
                {item.technique.technique && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Technique</h4>
                    <p className={classes.value}>{item.technique.technique}</p>
                  </Grid>
                )}

                {item.technique.sensitivity && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Sensitivity</h4>
                    <p className={classes.value}>{item.technique.sensitivity}</p>
                  </Grid>
                )}

                {!!item.technique.volume.length && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Scanned volume</h4>
                    <p className={classes.value}>{item.technique.volume.join(", ")}</p>
                  </Grid>
                )}
              </Grid>

            </>
          )}
          {!!filter((value) => value, values(item.probes)).length && (
            <>
              <Grid container spacing={3}>
                <Grid item>
                  <h3>Probes and Calibration</h3>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" spacing={3}>
                {item.probes.waves && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Waves</h4>
                    <p className={classes.value}>{item.probes.waves}</p>
                  </Grid>
                )}

                {item.probes.angle && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Angle [°]</h4>
                    <p className={classes.value}>{item.probes.angle}</p>
                  </Grid>
                )}

                {item.probes.model && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Brand and model</h4>
                    <p className={classes.value}>{item.probes.model}</p>
                  </Grid>
                )}

                {item.probes.size && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Size [mm]</h4>
                    <p className={classes.value}>{item.probes.size}</p>
                  </Grid>
                )}

                {item.probes.frequency && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Frequency [MHz]</h4>
                    <p className={classes.value}>{item.probes.frequency}</p>
                  </Grid>
                )}

                {item.probes.calibration && (
                  <Grid item xs={4}>
                    <h4 className={classes.name}>Calibration</h4>
                    <p className={classes.value}>{item.probes.calibration}</p>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Fragment>
      ))}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.divider} />
        </Grid>
      </Grid>

      {properties.notes && (
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={12}>
            <p
              className={classes.value}
              dangerouslySetInnerHTML={{__html: properties.notes.split("\n").join("</br>")}}
            />
          </Grid>
        </Grid>
      )}
      <Dialog

        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <EditFormUltrasonic
            formRef={formRef}
            test={test}
            isProducerTest={true}
            saveTest={updateTest}
            user={UserStore.user.data}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(UltrasonicTest, styles);
