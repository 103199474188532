import React from "react";
import {Grid} from "@mui/material";

function FormGroupListItemLayout({children, index, sx = {}}) {
  return (
    <Grid item xs={12} container padding={2} marginTop={index === 0 ? undefined : 3}
      alignItems="flex-end" justifyContent="space-between" columnGap={3}
      sx={{backgroundColor: "#dceef8", borderRadius: 1, ...sx}}
    >
      {children}
    </Grid>
  );
}

export default FormGroupListItemLayout;