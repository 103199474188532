import React, {useEffect, useState} from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel, Typography,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import moment from "moment";
import axios from "axios";
import Avatar from "@core/components/Avatar";
import Loader from "@core/components/Loader";
import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import styles from "./styles";

const Logs = (props) => {

  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState(("date_created"));
  const [order, setOrder] = useState("desc");
  const [limit, setLimit] = useState(10);
  const [pagesCount, setPagesCount] = useState(0);
  const [page, setPage] = useState(0);

  const actionNames = {
    create: "Create",
    delete: "Delete",
    bulkDelete: "Delete",
    login: "Log in",
    logout: "Log out",
    createBalk: "Create"
  };

  const rows = [
    {label: "User", path: "user.lastname", numeric: false},
    {label: "Company", path: "company.name", numeric: false},
    {label: "Action", path: "type", numeric: false},
    {label: "Status", path: "status", numeric: true},
    {label: "Data", path: "_id", numeric: false},
    {label: "Date", path: "date_created", numeric: false},
  ];

  useEffect(() => {
    getLogs();
  }, [limit, page]);

  useEffect(() => {
    getLogsCount();
  }, []);

  const getLogsCount = async () => {
    const pagesMax = await axios.get(ROUTES.LOG[ACTIONS.ALL], {
      withCredentials: true,
    });
    setPagesCount(pagesMax.data.total);
  };

  const getLogs = async () => {
    const res = await axios.get(ROUTES.LOG[ACTIONS.ALL], {
      params: {offset: limit * page, limit},
      withCredentials: true,
    });
    setLogs(res.data.items);
    setIsLoading(false);
  };

  const getAdditionalData = (log) => {
    if (log && log.additional) {
      return Object.entries(log.additional)
        .reduce((acc, curr, index) => {
          return index === 0 ? `${curr[0]}: ${curr[1]}` : `${acc}, ${curr[0]}: ${curr[1]}`;
        }, "").split(",").join(", ");
    } else {
      return " - ";
    }
  };

  const handleRequestSort = (event, property) => {
    const _orderBy = property;
    let _order = "desc";

    if (orderBy === property && order === "desc") {
      _order = "asc";
    }

    setOrder(_order);
    setOrderBy(_orderBy);
  };

  const deepValue = (obj, path) => {
    for (let i = 0, newPath = path.split("."), len = newPath.length; i < len; i++) {
      obj = obj[newPath[i]];
    }

    return obj;
  };

  const desc = (a, b, orderBy) => {
    if (deepValue(b, orderBy) < deepValue(a, orderBy)) {
      return -1;
    }

    if (deepValue(b, orderBy) > deepValue(a, orderBy)) {
      return 1;
    }

    return 0;
  };

  const sortArr = (order, orderBy) => {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  };

  const sortedTable = (array, sortArr) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = sortArr(a[0], b[0]);

      if (order !== 0) return order;

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  const getStatusColor = (status) => status.toString()[0] === "2" ? classes.status200 : classes.statusNot200;

  const {classes} = props;

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        Logs info
      </Typography>
      <Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {rows.map((row) =>
                <TableCell
                  key={row.label}
                  sortDirection={orderBy === row.path ? order : false}>
                  {row.label !== "Data" ? <TableSortLabel
                    active={orderBy === row.path}
                    direction={order}
                    onClick={(e) => handleRequestSort(e, row.path)}
                    classes={{active: classes.thActive, root: classes.thRoot}}
                  >
                    {row.label}
                  </TableSortLabel> :
                    row.label
                  }
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {!isLoading ? (
            <TableBody>
              {logs.length !== 0 && sortedTable(logs, sortArr(order, orderBy))
                .map((log) =>
                  <TableRow key={log._id} hover>
                    <TableCell>
                      <div className={classes.withAvatar}
                        onClick={() => props.history.push(`/users/${log.user._id}`)}>
                        {log.user ?
                          <>
                            <Avatar className={classes.avatarFinal} user={log.user} path={log.user.avatar ? log.user.avatar.file.name : ""}/>
                            <div data-sentry-mask>{log.user.lastname} {log.user.firstname}</div>
                          </> :
                          " - "}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.withAvatar} onClick={() => props.history.push(`/companies/${log.company._id}`)}>
                        {log.company ?
                          <>
                            <Avatar className={classes.avatarFinal} user={log.company} path={log.company.logo}/>
                            {log.company.name}
                          </> :
                          " - "}
                      </div>
                    </TableCell>
                    <TableCell>{log.type} {actionNames[log.action] || log.action}</TableCell>
                    <TableCell className={log.status ? getStatusColor(log.status) : ""}>{log.status}</TableCell>
                    <TableCell>{getAdditionalData(log)}</TableCell>
                    <TableCell>{moment(log.date_created).format(DATE_TIME_FORMAT)}</TableCell>
                  </TableRow>
                )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <div className={classes.loaderContainer}>
                    <Loader size={30}/>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!isLoading && logs.length > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                  count={pagesCount}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={(e, page) => setPage(page)}
                  onRowsPerPageChange={(e) => setLimit(e.target.value)}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Paper>
    </div>
  );
};

export default withStyles(Logs, styles);
