import React, {useState, useEffect} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {Button, TextField, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import CircularProgress from "@mui/material/CircularProgress";
import LoginPage from "../components/LoginContainer";
import LoginTextField from "@core/components/LoginTextField";
import PasswordField from "@core/components/LoginPasswordField";
import userService from "@core/api/user-service";
import {parseJwt, validatePassword} from "@core/helpers";
import styles from "./styles";
import useStores from "../../useStores";
import {AuthService} from "@core/services";

const SendEmailForm = ({classes}) => {
  const [username, setUsername] = useState("");
  const [isEmailSent, setEmailSent] = useState(false);

  const onSubmit = () => {
    if (!username) return;

    userService.getRecoveryToken({username});
    setEmailSent(true);
  };

  if (isEmailSent) {
    return (
      <>
        <Typography component="h1" variant="h6" className={classes.title}>
          <b>Check your inbox</b>
        </Typography>
        <Typography className={classes.description} align="left">
          If a matching username was found an email was sent to allow you to reset your password.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/login"
        >
          Back to login
        </Button>
      </>
    );
  }

  return (
    <>
      <Typography component="h1" variant="h6" className={classes.title}>
        <b>Having trouble signing in?</b>
      </Typography>
      <Typography className={classes.description} align="left">
        Please enter your username to get started.
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Username"
        onChange={(e) => setUsername(e.target.value)}
        value={username}
        autoFocus
        InputLabelProps={{
          shrink: true
        }}
        onKeyDown={(e) => {
          if (e.key !== "Enter") return;

          onSubmit();
        }}
      />
      <Button
        className={classes.submit}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={onSubmit}
      >
        Continue
      </Button>
    </>
  );
};

const NewPasswordForm = ({classes, token, id}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {NotificationStore} = useStores();

  const [loading, setLoading] = useState(false);

  const {username} = parseJwt(token);

  const initialValues = {
    password: "",
    confirmPassword: ""
  };

  useEffect(() => {
    AuthService.logout();
  }, []);

  const validationSchema = yup.object().shape({
    password: yup.string()
      .required("New password is required!")
      .test("password", validatePassword),
    confirmPassword: yup.string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords don't match")
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const data = {password: values.password, token};
    await userService.setNewPassword(data, id);
    setLoading(false);
    NotificationStore.showSuccess("Password has been successfully changed!");
    window.open("/login", "_self");
  };

  return (
    <>
      <Typography component="h1" variant="h6" className={classes.title}>
        <b>Reset password</b>
      </Typography>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {handleSubmit, handleChange, errors, touched} = props;

          return (
            <>
              <LoginTextField
                name="username"
                label="Username"
                value={username}
                disabled
              />
              <PasswordField
                showPassword={showPassword}
                handleChange={handleChange}
                setShowPassword={setShowPassword}
                value={props.values.password}
                error={errors.password}
                touched={touched.password}
                name="password"
                label="Password"
                autoFocus
                onSubmit={onSubmit}
              />
              <PasswordField
                showPassword={showConfirmPassword}
                handleChange={handleChange}
                setShowPassword={setShowConfirmPassword}
                value={props.values.confirmPassword}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
                name="confirmPassword"
                label="Confirm password"
                onSubmit={onSubmit}
              />
              <Button
                className={classes.submit}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
              >
                {loading && <CircularProgress classes={{colorPrimary: classes.loader}} size={24} />}&nbsp;
                <span>Confirm</span>
              </Button>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const PasswordRecovery = ({classes}) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const {token, id} = params;

  return (
    <LoginPage>
      {token ? (
        <NewPasswordForm
          id={id}
          token={token}
          classes={classes}
        />
      ) : (
        <SendEmailForm
          classes={classes}
        />
      )}
    </LoginPage>
  );
};

export default withStyles(PasswordRecovery, styles);
