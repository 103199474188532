import * as yup from "yup";
import {Button, Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {Formik} from "formik";
import TextField from "@core/components/FormikTextField";
import React from "react";
import {withRouter} from "react-router";

const initialValues = {
  name: ""
};

const validationSchema = yup.object().shape({
  name: yup.string().required("The field is required!"),
});

const CreateProject = ({open, setOpen, createProject, history}) => (
  <Dialog
    maxWidth="sm"
    fullWidth
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle>Create project</DialogTitle>
    <DialogContent>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await createProject(values).then((data)=>{
            history.push(`/projects/${data._id}`);
          }).catch(
            setOpen(false)
          );
        }}
      >
        {(props) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                label='Name'
                name='name'
              />
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Button
                disabled={!props.isValid}
                onClick={props.handleSubmit}
                color="primary"
                variant="contained"
              >
                Create
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </DialogContent>
  </Dialog>
);

export default withRouter(CreateProject);