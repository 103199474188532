import React, {useState} from "react";
import MenuSubItems from "../MenuSubItems";
import LightTooltip from "@core/components/LightTooltip";
import MenuLink from "../MenuLink";

const MenuItem = ({menuItem, displayTitle}) => {
  const [open, setOpen] = useState(false);

  if(!menuItem.items || !menuItem.items.length || !displayTitle) {
    return (
      <MenuLink
        displayTitle={displayTitle}
        exactMatch={menuItem.exact}
        menuItem={menuItem}
      />
    );
  }

  return (
    <LightTooltip
      enterDelay={100}
      leaveDelay={100}
      open={open}
      onOpen={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
      onClose={(e) => {
        e.stopPropagation();

        if(!e._targetInst) return;

        setOpen(false);
      }}
      placement="right-start"
      title={(
        <MenuSubItems
          items={menuItem.items}
        />
      )}
      arrow
    >
      <div>
        <MenuLink
          displayTitle={displayTitle}
          exactMatch={menuItem.exact}
          menuItem={menuItem}
        />
      </div>
    </LightTooltip>
  );
};

export default MenuItem;