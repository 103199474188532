export default (theme) => ({
  menu: {
    marginTop: 0,
    borderBottom: "1px solid #454545",
    borderTop: "1px solid #454545",
    position: "relative",
    zIndex: 2,
    backgroundColor: "#252525",
    maxHeight: "calc(100% - 120px)",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      height: 7,
      width: 5,
      cursor: "pointer",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#525252",
      borderRadius: 10,
    },

    "& a": {
      color: "#9e9e9e",
      textDecoration: "none",
      display: "flex",
      padding: 20,
      fontSize: 14,
      width: "100%",
      whiteSpace: "nowrap"
    },

    "& a.active": {
      color: "#fff",
    },

    "& a:hover": {
      backgroundColor: "#222222",
      color: "#fff"
    },

    "& span": {
      display: "flex",
      alignItems: "center",
    }
  },
  list: {
    listStyle: "none",
  },
  innerList: {
    listStyle: "none",
    backgroundColor: "#222222",
    display: "none",
  },
  activeListItem: {
    "& ul": {
      display: "block"
    }
  },
  openListItem: {
    backgroundColor: "#171717",
    borderTop: "1px solid #454545",

    "& ul": {
      borderBottom: "1px solid #454545",
      backgroundColor: "#171717",
    }
  },
  menuIcon: {
    fontSize: 22,
    marginRight: 30,
  },
  menuArrow: {
    marginLeft: "auto",
    fontSize: 18,
  },
  rotatedMenuArrow: {
    transform: "rotate(90deg)"
  },
  tooltip: {
    fontSize: 13,
  },
  proLabel: {
    margin: "-8px 0 0 5px",
    color: theme.palette.primary.main
  },
  popoverContent: {
    padding: 0,
    width: 260,
    margin: "0!important",
  },
  tooltipPlacementBottom: {
    margin: "-10px 0",
  }
});