export default (theme) => ({
  tag: {
    padding: "4px 10px",
    borderRadius: 4,
    display: "inline",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeightMedium,
  },
  enabled: {
    backgroundColor: theme.palette.success.backgroundColor,
    color: theme.palette.success.text
  },
  disabled: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.backgroundColor,
  },
  invited: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.backgroundColor,
  }
});