import {COLUMN_MIN_WIDTH} from "./data";
export default (theme) => ({
  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  formControl: {
    display: "block",
    width: "100%"
  },
  gridRoot: {
    marginTop: 20,
    marginBottom: 20
  },
  divider: {
    marginBottom: 20
  },
  bootstrapInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "none",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  helpIcon: {
    display: "inline-flex",
    alignItems: "center",
  },
  bootstrapDisabled: {
    backgroundColor: "rgba(0, 0, 0, 0.12)"
  },
  bootstrapError: {
    "& input": {
      borderColor: "#f44336",
      borderRadius: 4
    },
  },
  roundButtonGrid: {
    display: "flex",
    alignItems: "flex-end"
  },
  btnAdd: {
    padding: "7px 15px",
    marginBottom: 2.5
  },
  button: {
    width: "100%"
  },
  marginRight25: {
    marginRight: 25,
  },
  width120: {
    width: 120,
  },
  bootstrapFormLabel: {
    fontSize: 18,
    width: "100%"
  },
  bootstrapSelect: {
    width: 195,
  },
  bootstrapSelectSmall: {
    width: 170,
  },
  bootstrapMenuItem: {
    backgroundColor: theme.palette.common.white,
  },
  table: {
    marginBottom: 25,
    overflowX: "hidden",
    "& td": {
      minWidth: COLUMN_MIN_WIDTH,
      maxWidth: 145,
      position: "relative",
    }
  },
  error: {
    backgroundColor: "#f8e0e9",
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  value: {
    textAlign: "center"
  },
  elementActions: {
    fontSize: 14,
    padding: "5px 15px",
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.54)"
  },
  prenFormula: {
    color: "#909090",
    fontWeight: theme.typography.fontWeightMedium,
    margin: "0 20px 12px 0px;",
  },
  formulaSection: {
    display: "flex",
    alignItems: "flex-end",
  },
  editFormulaTooltip: {
    fontSize: 14
  },
  outOfLimitRange: {
    color: theme.palette.error.main,
  },
  satisfactory: {
    color: "#4caf50",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },
  unsatisfactory: {
    color: "#d02d52",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24
  },
  tableInput: {
    padding: "6px 0 7px 6px",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none"
    }
  }
});
