import React from "react";

const UltrasonicTest = ({qcpConfig}) => {

  return (
    <>
      <h3>Local Requirements</h3>
      <p
        dangerouslySetInnerHTML={{__html: qcpConfig.properties.notes?.split("\n").join("</br>")}}
      />
    </>
  );
};

export default UltrasonicTest;