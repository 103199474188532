import React from "react";
import Actions from "./components/Actions";
import {getColumns as getTransferColumns} from "@core/columns/transfer";
import {INTERFACE_TYPE} from "@core/constants/transfer";

const getColumns = (setOrderToDelete) => [
  ...getTransferColumns(INTERFACE_TYPE.RECEIVER, false),
  {
    dataIndex: "action",
    permanent: true,
    title: "Action",
    render: (transfer) => (
      <Actions
        transfer={transfer}
        onDelete={() => setOrderToDelete(transfer._id)}
      />
    )
  },
];

export {getColumns};
