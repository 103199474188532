export default (theme) => ({
  yellow: {
    backgroundColor: theme.palette.warning.main,
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
  green: {
    backgroundColor: theme.palette.success.main,
  },
  blue: {
    backgroundColor: theme.palette.primary.main,
  },
  default: {
    backgroundColor: theme.palette.text.main,
  }
});

