export default (theme) => ({
  leftCell: {
    border: "1px solid #e5e5e5",
  },
  rightCell: {
    border: "1px solid #e5e5e5",
    borderLeft: "none",
  },
  rootCell: {
    width: "50%",
    maxWidth: "50%",
    cursor: "pointer",
    border: "none",
  },
  testName: {
    "&:last-child": {
      paddingRight: 0,
    }
  },
  checkbox: {
    padding: "4px 10px",
  },
  plusMinusHandler: {
    padding: 0,
    margin: `0 ${theme.spacing()}`
  }
});