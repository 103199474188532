import React, {Component} from "react";
import {observer} from "mobx-react";
import Notification from "./Notification";

class List extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {store} = this.props;

    return (
      <>
        {Object.entries(store.notifications).map((N, idx)=> {
          return <Notification
            key={idx}
            notificationID={N[0]}
            notification={N[1]}
          />;})}
      </>
    );
  }
}

export default observer(List);