import themeSettings from "../../../../../../../../theme-settings";
import {PAGE_PADDING} from "../../constants";

export const PIPE_CONFIG = {
  WIDTH: 85,
  HEIGHT: 35,
  COLOR: "#fff",
  BORDER_COLOR: "#000000",
  BORDER_WIDTH: 2,
  TEXT_COLOR: themeSettings.palette.text.primary,
  FONT_SIZE: 15,
  TEXT_POSITION: -8,
  TEXT_POSITION_MIN: -12,
  TEXT_SCALE_BY: 0.5,
  TEXT_MAX_SCALE: 2.2,
  TEXT_MIN_SCALE: 0.75,
};

export const WELD_CONFIG = {
  WIDTH: 8,
  HEIGHT: 13,
  COLOR: "#fff",
  BORDER_COLOR: "#000000",
  BORDER_WIDTH: 1,
  TEXT_COLOR: themeSettings.palette.text.primary,
  FONT_SIZE: 16,
  TEXT_POSITION: 2,
};

export const TIE_IN_WELD_CONFIG = {
  HEIGHT: 16,
  WIDTH: 26,
  BORDER_WIDTH: 1,
  BORDER_COLOR: "#000000",
  FONT_SIZE: 15,
  TEXT_COLOR: themeSettings.palette.text.primary,
  LINE_HEIGHT: 2 * PAGE_PADDING,
};

export const AS_BUILD_TIE_IN_WELD_CONFIG = {
  COLOR: themeSettings.palette.primary.main,
  TEXT_COLOR: themeSettings.palette.primary.main,
};

export const REPAIRED_WELD_CONFIG = {
  COLOR: "#ffd12c",
  TEXT_COLOR: "#d7a60e",
};

export const WELD_LINE_CONFIG = {
  COLOR: "#000000",
  TEXT_COLOR: themeSettings.palette.text.primary,
  FONT_SIZE: 14,
  TEXT_POSITION: 30,
  TEXT_POSITION_MIN: 20,
};

export const QUARANTINED_JOINT_CONFIG = {
  TEXT_COLOR: "#ff0000"
};

export const SINGLE_RED_BAND_CONFIG = {
  WIDTH: 4,
  COLOR: "#ff0000"
};

export const DOUBLE_RED_BAND_CONFIG = {
  WIDTH: 4,
  COLOR: "#ff0000"
};

export const BLACK_BANDED_ANODE_JOINT_CONFIG = {
  WIDTH: 4,
  COLOR: "#000000"
};

export const TRANSITION_JOINT_CONFIG = {
  WIDTH: 4,
  COLOR: "#002060"
};

export const ANODE_NUMBER_CONFIG = {
  RADIUS: 15,
  TEXT_COLOR: "#000000",
  FONT_SIZE: 11,
  BORDER_COLOR: "#000000",
  BORDER_WIDTH: 2,
  MARGIN: 17
};

export const STALK_NUMBER_WIDTH = 140;