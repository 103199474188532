import React from "react";
import {observer} from "mobx-react-lite";
import PageHeader from "@core/components/PageHeader";
import Actions from "../Actions";
import HeaderBottomLine from "../HeaderBottomLine";
import {PAGE_TABS} from "../constants";
import useStores from "../../../../useStores";

const TABS = [{
  label: "Order Status",
  value: PAGE_TABS.ORDER_STATUS,
}, {
  label: "Order Summary",
  value: PAGE_TABS.ORDER_SUMMARY,
}, {
  label: "Test Summary",
  value: PAGE_TABS.TEST_SUMMARY,
}];

const Header = ({
  classes,
  interfaceType,
  setShowTransferModal,
  setShowAddCertificates,
  setOrderCertificates,
}) => {

  const {TransferStore} = useStores();
  const transfer = TransferStore.transfer;
  
  return (
    <PageHeader
      withDivider={false}
      tabs={[
        ...TABS,
        ...(transfer.certificates?.length ? [{
          label: "Certificates",
          value: PAGE_TABS.CERTIFICATES,
        }] : []),
        ...(transfer.campaigns?.length ? [{
          label: "Campaigns",
          value: PAGE_TABS.CAMPAIGNS,
        }] : []),
      ]}
      actions={(
        <Actions
          classes={classes}
          interfaceType={interfaceType}
          setShowTransferModal={setShowTransferModal}
          setShowAddCertificates={setShowAddCertificates}
          setOrderCertificates={setOrderCertificates}
        />
      )}
      children={(
        <HeaderBottomLine
          classes={classes}
          interfaceType={interfaceType}
        />
      )}
    />
  );
};

export default observer(Header);
