export default (theme) => ({
  title: {
    marginBottom: 20,
  },
  submit: {
    marginTop: 30,
    background: theme.palette.primary.main,
  },
  loader: {
    color: "#ffffff",
  },
});