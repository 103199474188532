export default (theme) => ({
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    "& img": {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      objectFit: "cover",
      "&.img-contain": {
        objectFit: "contain"
      }
    }
  },

  defaultBackground: {
    backgroundColor: theme.palette.common.white
  },

  rectImage: {
    borderRadius: "0%",
  },

  bigAvatar: {
    width: 55,
    height: 55
  }
});

