import React from "react";
import {Image, Layer} from "react-konva";

const ImageLayer = ({layer, imageLayer, layerImage}) => {
  const layerDimensions = layer.current?._id ? layer.current.getClientRect({skipTransform: true}) : {};
  
  return (
    <Layer
      ref={imageLayer}
      x={layerDimensions.x}
      y={layerDimensions.y}
      visible={false}
    >
      <Image
        height={layerDimensions.height}
        width={layerDimensions.width}
        image={layerImage}
      />
    </Layer>
  );
};

export default ImageLayer;