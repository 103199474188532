import {generateID} from "@core/helpers";

export const COLUMN_NAMES = ["Heat #", "Heat Lot", "Work Order", "Work Order Item", "PO Item"];
export const COLUMN_NAMES_EDIT = ["Heat #", "Heat Lot", "Work Order", "Work Order Item", "PO Item", "Action"];

export const TABLE_FIELDS = {
  HEAT: "heat",
  HEAT_LOT: "heatLot",
  WORK_ORDER: "workOrder",
  WORK_ORDER_ITEM: "WorkOrderItem",
  PO_ITEM: "poItem"
};

export const TRACEABILITY_TABLE_DEFAULT_VALUES = [
  {
    [TABLE_FIELDS.HEAT]: "91223",
    [TABLE_FIELDS.HEAT_LOT]: "12",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    uniqueId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "91223",
    [TABLE_FIELDS.HEAT_LOT]: "12",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    uniqueId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "91223",
    [TABLE_FIELDS.HEAT_LOT]: "12",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    uniqueId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "91223",
    [TABLE_FIELDS.HEAT_LOT]: "12",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    uniqueId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "91223",
    [TABLE_FIELDS.HEAT_LOT]: "12",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    uniqueId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "91223",
    [TABLE_FIELDS.HEAT_LOT]: "12",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    uniqueId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "91223",
    [TABLE_FIELDS.HEAT_LOT]: "12",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    uniqueId: generateID()
  },
];

export const TABLE_FIELDS_NAMES = Object.values(TABLE_FIELDS);

export const ROW_VALUES_TO_ADD = TABLE_FIELDS_NAMES.reduce((acc, value) => ({...acc, [value]: ""}), {});