import React from "react";
import {Formik} from "formik";
import {withRouter} from "react-router-dom";
import Form from "./components/Form";
import {validationSchema} from "@core/validationSchemas/user";
import {inject, observer} from "mobx-react";
import userService from "@core/api/user-service.js";
import modules from "@core/constants/modules";
import {compose} from "ramda";

const initialValues = {
  username: "",
  firstname: "",
  lastname: "",
  password: "",
  passwordConfirmation: "",
  role: "",
  email: "",
  avatar: null,
  avatarPreview: ""
};

class UserForm extends React.Component {

  handleSubmit = async (values) => {
    const {history, NotificationStore, UserStore} = this.props;

    try {
      const user = await userService.addNew(values);
      NotificationStore.showSuccess("Successfully added!");

      const [module] = UserStore.user.data.company.modules;
      const isSuperAdminView = module.name === modules.SUPER_ADMIN;

      const redirectLink = isSuperAdminView ? `/users/${user._id}` : `/admin/users/${user._id}`;

      history.push(redirectLink);
    } catch (error) {
      NotificationStore.showError("User is not available");
    }
  };

  render() {
    const {user, ...formProps} = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={user || initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        children={(props) => (
          <Form {...props} {...formProps} />
        )}
      />
    );
  }
}

export default compose(
  inject("NotificationStore", "UserStore", "CompanyStore"),
  withRouter,
  observer
)(UserForm);
