import React, {useRef, useState} from "react";
import {Grid, Button, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {isEmpty, omit} from "ramda";
import TestResultTag from "@core/components/TestResultTag";
import TestMarker from "@core/components/TestMarker";
import InspectorNotes from "@core/components/InspectorNotes";
import InstrumentsList from "@core/components/InstrumentsList";
import Status from "@core/components/Status";

import testsConfig from "@core/configs/test";
import {STATUSES, TYPES} from "@core/constants/test";

import {copyToClipboard} from "@core/helpers/copyToClipboard";
import useStores from "../../../../../useStores";

import WitnessAttest from "../WitnessAttest";

import styles from "./styles";

import ConfirmationOnModalClose from "@core/components/ConfirmationOnModalClose";
import TestVersionSelector from "../TestVersionSelector";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";

const TestView = ({
  classes,
  disabled,
  hideSign,
  certificate,
  productId,
  testVersions,
  testVersionIndex,
  setTestVersionId,
  shareLink,
  addDataOpen = false,
  dataDialogOpen = () => { },
  dataDialogClose = () => { },
  updateTest = () => { },
  deleteTest = () => {},
  approveTest = () => { },
  assignInspector = () => { },
  test,
}) => {
  const [testUpdates, setTestUpdates] = useState(test);

  const {NotificationStore} = useStores();

  const [testToAttest, setTestToAttest] = useState(null);
  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);
  const formRef = useRef();

  const testConfig = testsConfig[test.type];

  const showUnsignedByInspector = (test) => {
    if (test.type === TYPES.SPLIT) {
      return false;
    }

    const isInspecting = test.status === STATUSES.INSPECTING;
    const isInspected = test.status === STATUSES.INSPECTED;

    const isWitnessed = !!test.witnesses.length;
    const isNotAccepted = isWitnessed && test.witnesses.find((witness) => witness.status === WITNESS_STATUSES.NOT_ACCEPTED);

    return isInspecting || (isInspected && isNotAccepted);
  };

  const showEditButton = (test) => {
    return test.status === STATUSES.FILLED && !test.assignee && (testConfig.addDataForm || test.properties.acceptance);
  };

  const showSignButton = () => {
    const submittedByLab = test.status === STATUSES.SUBMITTED;
    const filledByProducer = (test.status === STATUSES.FILLED && !test.assignee && (!test.witnesses.length || test.inspectionWaivedDocument)) || (test.status === STATUSES.FILLED && test.type === "split_certificate");
    const isInspected = test.status === STATUSES.INSPECTED && test.assignee;

    return isInspected || submittedByLab || filledByProducer;
  };

  const showAskEndorsement = () => {
    const isProducerTestWithWitness = test.status === STATUSES.FILLED && !!test.witnesses.length && !test.assignee && !test.inspectionWaivedDocument;

    return isProducerTestWithWitness;
  };

  const closeConfirmation = () => {
    if (isEmpty(formRef.current.touched)) dataDialogClose();
    else setCloseConfirmationOpen(true);
  };

  const onDataDialogClose = () => {
    if(testConfig.addDataForm) closeConfirmation();
    else {
      updateTest(testUpdates.properties);
      NotificationStore.showSuccess("Test updated successfully");
    }
  };

  return (
    <>
      <TestMarker
        test={test}
        disabled={disabled || !test._id}
        testVersions={testVersions}
        deleteTest={deleteTest}
        updateTest={updateTest}
        shareLink={shareLink}
      />
      <div className={classes.testResults}>
        <Grid container spacing={3} alignContent="center">
          {test._id && (
            <Grid item xs>
              <h3>
                <Tooltip title="Click to copy">
                  <span
                    className={classes.testId}
                    onClick={() => copyToClipboard(test._id)}
                  >
                         Test ID: {test._id.substring(0,3) + "..." +test._id.slice(-3) }
                  </span>
                </Tooltip>
              </h3>
            </Grid>
          )}
          {test.status && (
            <Grid item xs container justifyContent="flex-end">
              <Status test={test} />
            </Grid>
          )}
          <TestVersionSelector
            testVersions={testVersions}
            testVersionIndex={testVersionIndex}
            setTestVersionId={setTestVersionId}
          />
          <InstrumentsList instruments={test.instruments} />
          <Grid item xs={12}>
            {React.cloneElement(testConfig.certificateView, {
              formRef,
              test,
              dataDialogOpen: dataDialogOpen,
              dataDialogClose: onDataDialogClose,
              addDataOpen,
              updateTest: updateTest,
              certificate,
              disabled,
              productId,
              testUpdates,
              setTest: setTestUpdates,
            })}
          </Grid>
          <InspectorNotes
            test={test}
            inspectorNotesStyles={classes.inspectorNotes}
          />
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item xs={4}>
              <TestResultTag
                result={test.properties.result}
              />
            </Grid>
            {(!disabled && !hideSign) && (
              <Grid item xs={8} container spacing={2} justifyContent="flex-end" alignItems="center">
                {showEditButton(test) && (
                  <Grid item>
                    <Button
                      size="large"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={addDataOpen ? onDataDialogClose : dataDialogOpen}
                    >
                      {addDataOpen ? "Save" : "Edit"}
                    </Button>
                  </Grid>
                )}
                {showAskEndorsement(test) && (
                  <Grid item>
                    <Button
                      size="large"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={assignInspector}
                    >
                        Ask endorsement
                    </Button>
                  </Grid>
                )}
                {showSignButton(test) && (
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => approveTest(test)}
                    >
                        Sign
                    </Button>
                  </Grid>
                )}
                {showUnsignedByInspector(test) && (
                  <Grid item>
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={() => setTestToAttest(test)}
                    >
                      {test.status === STATUSES.INSPECTED ? "Update inspector review" : "Inspector sign"}
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      <WitnessAttest
        test={testToAttest}
        onClose={() => setTestToAttest(null)}
      />
      <ConfirmationOnModalClose
        confirmationOpen={closeConfirmationOpen}
        formRef={formRef}
        setConfirmationOpen={setCloseConfirmationOpen}
        onClose={dataDialogClose}
        onSave={() => {
          const values = formRef.current.values;
          updateTest(omit(["witnesses"], values), {witnesses: values.witnesses});
          setCloseConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default withStyles(TestView, styles);
