import React from "react";
import {Grid, TableCell, Table, TableBody, TableRow, TableHead} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {portal} from "Config";
import File from "@core/components/File";
import styles from "./styles";

const SupplierCertificates = ({classes, test}) => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align={"center"}>Creator</TableCell>
              <TableCell align={"center"}>Type</TableCell>
              <TableCell align={"center"}>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {test.properties.elements.map((element) =>
              <TableRow>
                <TableCell align={"center"}>{element.creator}</TableCell>
                <TableCell align={"center"}>{element.certificateType}</TableCell>
                <TableCell align={"center"}>
                  {element.file ? (
                    <File
                      base={portal}
                      file={element.file.preview_path}
                    />
                  ) : "No file uploaded"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(SupplierCertificates, styles);
