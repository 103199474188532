import React, {useRef, useState} from "react";
import File from "@core/components/File";
import {Uploader} from "@core/components/Uploaders";
import EditableCell from "@core/components/EditableCell";
import {Button, ClickAwayListener, IconButton, TableCell, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import CheckIcon from "@mui/icons-material/Check";
import {Formik} from "formik";
import {equals, isNil, omit, trim} from "ramda";
import {GrDocumentUpload} from "react-icons/gr";
import {IoMdCloseCircleOutline} from "react-icons/io";
import {MdEditNote} from "react-icons/md";
import * as yup from "yup";
import styles from "./styles";
import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";
import PipesDrawer from "../PipesDrawer";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {observer} from "mobx-react-lite";
import useStores from "../../../../../useStores";
import {formatRawMaterials} from "../../helpers";

const validationSchema = yup.object().shape({
  batchNumber: yup.string().required(),
  type: yup.string().required(),
  manufacturer: yup.string(),
  tradeName: yup.string(),
  pipes: yup.string().required().min(1),
  file: yup.object(),
});

const RawMaterialForm = observer(({
  classes,
  rawMaterials,
  savedRawMaterials,
  setEditableIndex,
  setRawMaterials,
  setMaterialToDelete,
  updateRawMaterial,
  createRawMaterial,
  onRowClick = () => {},
  index,
  material,
  editableIndex,
  disabled
}) => {
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;

  const [openMaterial, setMaterial] = useState(null);

  const formRef = useRef();

  const onSubmit = async (values) => {
    const data = omit(["pipes"], values);

    data.pipes = values.pipes.split(" ");

    if(values._id) {
      await updateRawMaterial(values._id, data);
    } else {
      await createRawMaterial(data);
    }

    setRawMaterials(formatRawMaterials(savedRawMaterials));
  };

  const onClickAway = (formRef, index) => {
    if (!equals(formRef.current.values, formRef.current.initialValues) ||
      editableIndex !== index) return;

    setEditableIndex(null);
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        validateOnMount
        initialValues={material}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(props) => {
          const editable = editableIndex === index;

          const pipes = trim(props.values.pipes).length ? props.values.pipes.split(" ") : "";

          return (
            <ClickAwayListener
              onClickAway={() => onClickAway(formRef, index)}
            >
              <TableRow
                hover
                onClick={() => onRowClick(index, editableIndex, material)}
              >
                <TableCell>
                  <EditableCell
                    value={props.values.batchNumber}
                    editable={editable}
                    setValue={(value) => props.setFieldValue("batchNumber", value)}
                    required
                  />
                </TableCell>
                <TableCell>
                  <EditableCell
                    value={props.values.type}
                    editable={editable}
                    setValue={(value) => props.setFieldValue("type", value)}
                    required
                  />
                </TableCell>
                {(savedRawMaterials.some((material) => material.manufacturer) || !isNil(editableIndex)) && (
                  <TableCell>
                    <EditableCell
                      value={props.values.manufacturer}
                      editable={editable}
                      setValue={(value) => props.setFieldValue("manufacturer", value)}
                    />
                  </TableCell>
                )}
                {(savedRawMaterials.some((material) => material.tradeName) || !isNil(editableIndex)) && (
                  <TableCell>
                    <EditableCell
                      value={props.values.tradeName}
                      editable={editable}
                      setValue={(value) => props.setFieldValue("tradeName", value)}
                    />
                  </TableCell>
                )}
                <TableCell className={classes.pipesCell}>
                  {editable ? (
                    <EditableCell
                      value={props.values.pipes}
                      editable={editable}
                      setValue={(value) => props.setFieldValue("pipes", value)}
                      required
                    />
                  ) : (
                    pipes.length ? (
                      <DrawerButtonTrigger
                        onClick={(e) => {
                          e.stopPropagation();
                          setMaterial(props.values);
                        }}
                        text={`${pipes.length} ${campaign.type === CAMPAIGN_TYPES.WELDING ? "Weld(s)" : "Pipe(s)"}`}
                      />
                    ) : "-"
                  )}
                </TableCell>
                <TableCell width="250" className={classes.fileCell}>
                  {editable ? (
                    <Uploader
                      withConfirmation={false}
                      fileType="image/*, application/pdf, .csv"
                      file={(props.values.file?.dir && props.values.file?.name) ?
                        props.values.file?.dir + props.values.file?.name :
                        ""}
                      handleUploadedFile={({file, _id}) => props.setFieldValue("file", {...file, _id})}
                      onRemove={() => props.setFieldValue("file", {})}
                      uploadButton={(
                        <Button
                          className={classes.uploadButton}
                          component="span"
                        >
                          <GrDocumentUpload size="20px" />
                        </Button>
                      )}
                      removeButton={(
                        <IconButton
                          className={classes.removeButton}
                          onClick={() => props.setFieldValue("file", {})}
                          size="large">
                          <IoMdCloseCircleOutline />
                        </IconButton>
                      )}
                    />
                  ) : (
                    (props.values.file?.dir && props.values.file?.name) ? (
                      <File
                        preview
                        file={props.values.file.dir + props.values.file.name}
                      />) :
                      "-"
                  )}
                </TableCell>
                {!disabled && (
                  <TableCell width="130" className={classes.actionCell}>
                    {editable ? (
                      <IconButton
                        disabled={!props.isValid}
                        onClick={async (e) => {
                          e.stopPropagation();

                          await props.handleSubmit();
                          setEditableIndex(null);
                        }}
                        size="large">
                        <CheckIcon/>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();

                          setEditableIndex(index);
                        }}
                        size="large">
                        <MdEditNote  />
                      </IconButton>
                    )}
                    <IconButton
                      className={classes.removeButton}
                      onClick={(e) => {
                        e.stopPropagation();

                        if(!material._id) {
                          setRawMaterials(rawMaterials.filter((_, idx) => idx !== index));
                        } else {
                          setMaterialToDelete(material._id);
                        }
                      }}
                      size="large"
                    >
                      <IoMdCloseCircleOutline />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            </ClickAwayListener>
          );
        }}
      </Formik>
      <PipesDrawer
        open={openMaterial}
        close={() => setMaterial(null)}
        material={openMaterial}
      />
    </>
  );
});

export default withStyles(RawMaterialForm, styles);