import permissionsConst from "../constants/permissions";

/**
 * getBCPermissions
 * @param module {string} - module name as 'PRODUCER' etc.
 * @param role {string} - role name as 'ADMIN' etc.
 * @returns {number} - permissions
 */
const getBCPermissions = (module, role) => {
  return permissionsConst[module][role];
};

export {getBCPermissions};
