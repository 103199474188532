export default () => ({
  block: {
    padding: "20px 20px 10px 20px",
  },
  title: {
    marginBottom: 10,
  },
  divider: {
    marginTop: 10,
  }
});
