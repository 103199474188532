import Test from "@core/components/Test";
import React from "react";
import {Box} from "@mui/material";

const Tests = ({testsRefs, tests, deleteTest, updateTest}) => {
  return tests.map((test, idx) => (
    <Box
      sx={{marginBottom: 2}}
      key={test._id}
      ref={(element) => testsRefs.current[test._id] = element}
    >
      <Test
        test={test}
        isLast={idx === tests.length - 1}
        isFirst={!idx}
        deleteTest={deleteTest}
        onTestUpdate={updateTest}
        hideSign
        timeline={false}
      />
    </Box>
  ));
};

export default Tests;