import {TEST_RESULTS} from "@core/constants/testResults";
import {getMean} from "@core/helpers";
import React, {useEffect} from "react";
import {Button, Divider, Grid, Checkbox, FormControlLabel} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {FieldArray, Formik} from "formik";
import {times, keys} from "ramda";
import * as yup from "yup";
import TextField from "@core/components/FormikTextField";
import {FilesUploader} from "@core/components/Uploaders";
import ClientField from "../../../../Tests/Test/components/ClientField";
import CustomException from "./CustomException";
import TestFooter from "../../LabTestFooter";
import {
  TEST_STANDARDS,
  ACCEPTANCE_CRITERIA
} from "./data";
import styles from "./styles";
import MuiSelect from "@core/components/MuiSelect";

const validationSchema = yup.object().shape({
  client: yup.string().required("This field is required!"),
  lab: yup.string().required("This field is required!"),
  testStandard: yup.string().required("This field is required!"),
  acceptance: yup.string().required("This field is required!"),
  sampleID: yup.string().required("This field is required!"),
  solution: yup.string().required("This field is required!"),
  duration: yup.string().required("This field is required!"),
  temperature: yup.string().required("This field is required!"),
  temperatureTolerance: yup.string().required("This field is required!"),
  pressure: yup.string().required("This field is required!"),
  gasMixture: yup.string(),
  solutionPH: yup.object().shape({
    before: yup.string().required("This field is required!"),
    after: yup.string().required("This field is required!"),
    final: yup.string().required("This field is required!")
  }),
  h2sConcentration: yup.object().shape({
    initial: yup.string().required("This field is required!"),
    final: yup.string().required("This field is required!")
  }),
  requirements: yup.object().shape({
    crackLengthRatioMax: yup.string(),
    crackThicknessRatioMax: yup.string(),
    crackSensitivityRatioMax: yup.string(),
    averageCrackLengthRatioMax: yup.string(),
    averageCrackThicknessRatioMax: yup.string(),
    averageCrackSensitivityRatioMax: yup.string(),
  }),
  elements: yup.array().of(yup.object().shape({
    position: yup.string(),
    orientation: yup.string().required("This field is required!"),
    specimenId: yup.string().required("This field is required!"),
    specimenLength: yup.string().required("This field is required!"),
    specimenWidth: yup.string().required("This field is required!"),
    specimenThickness: yup.string().required("This field is required!"),
    controlSample: yup.boolean(),
    requirements: yup.object().shape({
      crackLengthRatioMax: yup.string(),
      crackThicknessRatioMax: yup.string(),
      crackSensitivityRatioMax: yup.string()
    }),
    crackLengthRatio: yup.string().when("requirements", {
      is: (requirements) => requirements.crackLengthRatioMax,
      then: yup.string().required("This field is required!")
    }),
    crackThicknessRatio: yup.string().when("requirements", {
      is: (requirements) => requirements.crackThicknessRatioMax,
      then: yup.string().required("This field is required!")
    }),
    crackSensitivityRatio: yup.string().when("requirements", {
      is: (requirements) => requirements.crackSensitivityRatioMax,
      then: yup.string().required("This field is required!")
    })
  })),
  notes: yup.string()
});

const REQUIREMENTS = {
  crackLengthRatioMax: "",
  crackThicknessRatioMax: "",
  crackSensitivityRatioMax: ""
};

const ELEMENT = {
  position: "",
  orientation: "",
  specimenId: "",
  specimenLength: "",
  specimenWidth: "",
  specimenThickness: "",
  controlSample: false,
  crackLengthRatio: "",
  crackThicknessRatio: "",
  crackSensitivityRatio: "",
  files: [],
  requirements: REQUIREMENTS
};

const getAveragePropertyValue = (elements, property) => {
  const length = elements.filter((element) => !element.controlSample).length;

  const sum = elements.reduce((acc, element) => {
    if (element.controlSample) return acc;
    else return acc += Number(element[property]);
  }, 0);

  if (length === 0) return "-";
  else return (sum / length).toFixed(2);
};

const isTestAcceptable = ({elements, requirements}) => {
  const {averageCrackLengthRatioMax, averageCrackThicknessRatioMax, averageCrackSensitivityRatioMax} = requirements;

  const averageCrackLengthAcceptable = !averageCrackLengthRatioMax || getMean(elements.map((element) => element.crackLengthRatio)) <= Number(averageCrackLengthRatioMax);
  const averageCrackThicknessRatioAcceptable = !averageCrackThicknessRatioMax || getMean(elements.map((element) => element.crackThicknessRatio)) <= Number(averageCrackThicknessRatioMax);
  const averageCrackSensitivityRatioAcceptable = !averageCrackSensitivityRatioMax || getMean(elements.map((element) => element.crackSensitivityRatio)) <= Number(averageCrackSensitivityRatioMax);

  const averageValuesAcceptable = averageCrackLengthAcceptable && averageCrackThicknessRatioAcceptable && averageCrackSensitivityRatioAcceptable;

  const singleValuesAcceptable =  elements.every((element) => {
    if (element.controlSample) return true;

    const {crackLengthRatioMax, crackThicknessRatioMax, crackSensitivityRatioMax} = element.requirements;

    const crackLengthRatioAcceptable = !crackLengthRatioMax || Number(element.crackLengthRatio) <= Number(crackLengthRatioMax);
    const crackThicknessRatioAcceptable = !crackThicknessRatioMax || Number(element.crackThicknessRatio) <= Number(crackThicknessRatioMax);
    const crackSensitivityRatioAcceptable = !crackSensitivityRatioMax || Number(element.crackSensitivityRatio) <= Number(crackSensitivityRatioMax);

    return crackLengthRatioAcceptable && crackThicknessRatioAcceptable && crackSensitivityRatioAcceptable;
  });

  return singleValuesAcceptable && averageValuesAcceptable;
};

const getResult = (isAcceptable) => {
  if (isAcceptable) return TEST_RESULTS.ACCEPTABLE;

  return TEST_RESULTS.NOT_ACCEPTABLE;
};

const HydrogenInducedCracking = ({
  test,
  user,
  saveTest,
  client,
  formRef,
  classes
}) => {
  const initialValues = {
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name || "",
    testStandard: "",
    acceptance: "",
    sampleID: "",
    solution: "",
    duration: "",
    temperature: "",
    temperatureTolerance: "",
    pressure: "",
    gasMixture: "",
    solutionPH: {
      before: "",
      after: "",
      final: ""
    },
    h2sConcentration: {
      initial: "",
      final: ""
    },
    requirements: {
      ...REQUIREMENTS,
      averageCrackLengthRatioMax: "",
      averageCrackThicknessRatioMax: "",
      averageCrackSensitivityRatioMax: "",
    },
    elements: [ELEMENT],
    notes: "",
    result: ""
  };

  const filteredAcceptances = Object.keys(ACCEPTANCE_CRITERIA).reduce(function(r, e) {
    if (!ACCEPTANCE_CRITERIA[e].hasOwnProperty("company") || ACCEPTANCE_CRITERIA[e].company.includes(user.company.name)) r[e] = ACCEPTANCE_CRITERIA[e];

    return r;
  }, {});

  const onSubmit = (values) => {
    const isAcceptable = isTestAcceptable(values);
    const result = getResult(isAcceptable);
    saveTest({...values, result});
  };

  return (
    <Formik
      onSubmit={onSubmit}
      innerRef={formRef}
      initialValues={{...initialValues, ...test.properties}}
      validationSchema={validationSchema}
      enableReinitialize
      render={(props) => {
        const {crackLengthRatioMax, crackThicknessRatioMax, crackSensitivityRatioMax} = props.values.requirements;

        useEffect(() => {
          const elements = props.values.elements.map((element) => ({
            ...element,
            requirements: props.values.requirements
          }));

          props.setFieldValue("elements", elements);
        }, [crackLengthRatioMax, crackThicknessRatioMax, crackSensitivityRatioMax]);
        useEffect(() => {
          if (!props.touched.acceptance) return;

          const values = ACCEPTANCE_CRITERIA[props.values.acceptance] || {};
          const {solution, duration, elementsQuantity} = values;
          const requirements = values.requirements || REQUIREMENTS;

          const {crackLengthRatioMax, crackThicknessRatioMax, crackSensitivityRatioMax} = requirements;

          const element = {...ELEMENT, requirements: {crackLengthRatioMax, crackThicknessRatioMax, crackSensitivityRatioMax}};
          const newElements = times(() => element, elementsQuantity || 1);

          props.setValues(() => ({
            ...props.values,
            requirements,
            solution: solution || "",
            duration: duration || "",
            elements: newElements
          }));
        }, [props.values.acceptance]);

        const isAcceptable = props.isValid && isTestAcceptable(props.values);
        const result = getResult(isAcceptable);

        return (
          <Grid container spacing={2}>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <ClientField isFromProducer={!!client.name} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  name="lab"
                  label="Laboratory"
                  required
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <MuiSelect
                  required
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={TEST_STANDARDS}
                />
              </Grid>
              <Grid item xs={6}>
                <MuiSelect
                  required
                  label="Acceptance criteria"
                  name="acceptance"
                  defaultOptions={keys(filteredAcceptances)}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  name="sampleID"
                  label="Sample ID"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="solution"
                  label="Test solution"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="duration"
                  label="Test duration"
                  required
                  endAdornment="hrs"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="temperature"
                  label="Test temperature"
                  required
                  endAdornment="°C"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  startAdornment="+/-"
                  name="temperatureTolerance"
                  label="Tolerance"
                  required
                  endAdornment="°C"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="pressure"
                  label="Test pressure"
                  required
                  endAdornment="bar"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="gasMixture"
                  label="Gas Mixture"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item>
                <h3>Solution pH</h3>
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="solutionPH.before"
                  label="Before H2S introduction"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="solutionPH.after"
                  label="After H2S introduction"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="solutionPH.final"
                  label="Final"
                  required
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item>
                <h3>H2S concentration</h3>
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="h2sConcentration.initial"
                  label="Initial"
                  required
                  endAdornment="ppm"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="h2sConcentration.final"
                  label="Final"
                  required
                  endAdornment="ppm"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item>
                <h3>Global acceptance criteria</h3>
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.crackLengthRatioMax"
                  label="Crack length ratio max"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.crackThicknessRatioMax"
                  label="Crack thickness ratio max"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.crackSensitivityRatioMax"
                  label="Crack sensitivity ratio max"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={5}>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.averageCrackLengthRatioMax"
                  label="Average crack length ratio max"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.averageCrackThicknessRatioMax"
                  label="Average crack thickness ratio max"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="requirements.averageCrackSensitivityRatioMax"
                  label="Average crack sensitivity ratio max"
                />
              </Grid>
            </Grid>
            <FieldArray name="elements">
              {({remove, push}) => props.values.elements.map((element, index) => (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenId`}
                        label="Specimen ID"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.position`}
                        label="Position"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.orientation`}
                        label="Orientation"
                        required
                      />
                    </Grid>
                    {props.values.elements.length > 1 && (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    )}
                    {props.values.elements.length - 1 === index && (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          onClick={() => push({...ELEMENT, requirements: props.values.requirements})}
                        >
                          Add
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenLength`}
                        label="Specimen Length"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenWidth`}
                        label="Specimen Width"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name={`elements.${index}.specimenThickness`}
                        label="Specimen Thickness"
                        endAdornment="MM"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        style={{marginTop: "25px"}}
                        control={(
                          <Checkbox
                            checked={element.controlSample}
                            onClick={(event) => props.setFieldValue(`elements[${index}].controlSample`, event.target.checked)}
                            name={`elements.${index}.controlSample`}
                            color="primary"
                          />
                        )}
                        label="Control Sample"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5}>
                    <Grid item>
                      <h3>Test results</h3>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={3} container alignItems="flex-end">
                      <Grid item xs>
                        <TextField
                          type="number"
                          name={`elements.${index}.crackLengthRatio`}
                          label="Crack Length Ratio (CLR)"
                          required={element.requirements.crackLengthRatioMax}
                          endAdornment="%"
                        />
                      </Grid>
                      {element.requirements.crackLengthRatioMax && (
                        <Grid xs={2} className={classes.requirementMax} item container justifyContent="center">
                          {"\u2264"}&nbsp;&nbsp;{element.requirements.crackLengthRatioMax}%
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={3} container alignItems="flex-end">
                      <Grid item xs>
                        <TextField
                          type="number"
                          name={`elements.${index}.crackThicknessRatio`}
                          label="Crack Thickness Ratio (CTR)"
                          required={element.requirements.crackThicknessRatioMax}
                          endAdornment="%"
                        />
                      </Grid>
                      {element.requirements.crackThicknessRatioMax && (
                        <Grid xs={2} className={classes.requirementMax} item container justifyContent="center">
                          {"\u2264"}&nbsp;&nbsp;{element.requirements.crackThicknessRatioMax}%
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={3} container alignItems="flex-end">
                      <Grid item xs>
                        <TextField
                          type="number"
                          name={`elements.${index}.crackSensitivityRatio`}
                          label="Crack Sensitivity Ratio (CSR)"
                          required={element.requirements.crackSensitivityRatioMax}
                          endAdornment="%"
                        />
                      </Grid>
                      {element.requirements.crackSensitivityRatioMax && (
                        <Grid xs={2} className={classes.requirementMax} item container justifyContent="center">
                          {"\u2264"}&nbsp;&nbsp;{element.requirements.crackSensitivityRatioMax}%
                        </Grid>
                      )}
                    </Grid>
                    <Grid item>
                      <CustomException
                        requirements={element.requirements}
                        onChange={(changes) => {
                          const customRequirements = {...element.requirements, ...changes};
                          props.setFieldValue(`elements.${index}.requirements`, customRequirements);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={5} alignItems="flex-end">
                    <Grid item xs={12}>
                      <FilesUploader
                        name={`elements.${index}.files`}
                        files={element.files || []}
                        onNewFile={(file, push) => push(file.file.dir + file.file.name)}
                        changeFile={(index, file, replace) => replace(index, file.file.dir + file.file.name)}
                      />
                    </Grid>
                  </Grid>
                  {props.values.elements.length > 1 && props.values.elements.length - 1 === index && (
                    <Grid item container spacing={5}>
                      <Grid item>
                        <h3>Average of CLR: {getAveragePropertyValue(props.values.elements, "crackLengthRatio")}</h3>
                      </Grid>
                      <Grid item>
                        <h3>Average of CTR: {getAveragePropertyValue(props.values.elements, "crackThicknessRatio")}</h3>
                      </Grid>
                      <Grid item>
                        <h3>Average of
                          CSR: {getAveragePropertyValue(props.values.elements, "crackSensitivityRatio")}</h3>
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
            </FieldArray>
            <Grid item container spacing={5}>
              <Grid item xs={6}>
                <TextField
                  rows={4}
                  multiline
                  name="notes"
                  label="Additional remarks"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TestFooter
                onSubmit={onSubmit}
                result={result}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default withStyles(HydrogenInducedCracking, styles);
