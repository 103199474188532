import {Button} from "@mui/material";

import {withStyles} from "tss-react/mui";

const BigFontButton = withStyles(Button, () => ({
  root: {
    fontSize: 24,
  }
}));
const NoCapitalizeButton = withStyles(Button, () => ({
  root: {
    textTransform: "none",
  }
}));

export {BigFontButton, NoCapitalizeButton};