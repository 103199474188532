import {Button, Grid} from "@mui/material";
import axios from "axios";
import React from "react";
import {IoMdDownload} from "react-icons/io";
import {Link} from "react-router-dom";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const BaseProductsUpload = ({classes, required, products, selectedProducts, setProducts, setSelectedProducts}) => {
  const parseProductsCsvFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(ROUTES.BASE_PRODUCT[ACTIONS.CREATE], formData);

    return response.data.items;
  };
    
  return (
    <Grid container spacing={3}>
      <Grid item>
        <label htmlFor="contained-button-file" className={required ? classes.asterisk : null}>
          <input
            id="contained-button-file"
            className={classes.fileUploader}
            accept=".csv,.xls,.xlsx"
            multiple type="file"
            onChange={async (event) => {
              const file = event.target.files[0];

              if (!file) return;

              const parsedProducts = await parseProductsCsvFile(file);
              setProducts(products.concat(parsedProducts));
              setSelectedProducts(selectedProducts.concat(parsedProducts));
            }}
          />
          <Button
            color='primary'
            variant="contained"
            component="span"
          >
            Upload base material list
          </Button>
        </label>
      </Grid>
      <Grid item>
        <Button
          component={Link}
          to="/parseFileExamples/base-products.csv"
          target="_blank"
          download
          variant="containedGray"
        >
          <span className={classes.downloadButton}>
            <IoMdDownload />&nbsp;Download csv template
          </span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(BaseProductsUpload, styles);