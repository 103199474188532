import React from "react";
import {observer} from "mobx-react";
import {Grid} from "@mui/material";
import WitnessAttestForm from "@core/components/WitnessAttestForm";
import useStores from "../../../../useStores";

const WitnessAttest = () => {
  const {TestStore} = useStores();

  // const user = UserStore.user.data;
  const test = TestStore.test.data;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h3>Witness / Inspector section</h3>
      </Grid>
      <Grid item xs={8}>
        <WitnessAttestForm
          test={test}
        >
          {/* Remove Publish to Portal btn until portal is back*/}
          {/* <Grid item>*/}
          {/*  <Button*/}
          {/*    fullWidth*/}
          {/*    disabled={user.role !== roles.ADMIN}*/}
          {/*    variant="contained"*/}
          {/*    size="large"*/}
          {/*    color="primary"*/}
          {/*    onClick={() => setIsPublishToPortalOpen(true)}*/}
          {/*  >*/}
          {/*    Publish to portal*/}
          {/*  </Button>*/}
          {/* </Grid>*/}
        </WitnessAttestForm>
      </Grid>
    </Grid>
  );
};

export default observer(WitnessAttest);