import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import {MenuItem} from "@mui/material";
import {useFormikContext} from "formik";
import {path} from "ramda";
import React from "react";

const Field = ({editable, options = [], ...field}) => {
  const {values} = useFormikContext();

  const value = path(field.name.split("."), values);

  if(!editable) {
    return value ? (
      <span
        dangerouslySetInnerHTML={{__html: `${value} ${field.endAdornment || ""}`}}
      />
    ) : "-";
  }

  if (options.length) {
    return (
      <SelectField
        {...field}
      >
        {!field.required && <MenuItem value={undefined}>N/A</MenuItem>}
        {options.map((value) => (
          <MenuItem key={value} value={value}>
            <span
              dangerouslySetInnerHTML={{__html: value}}
            />
          </MenuItem>
        ))}
      </SelectField>
    );
  }

  return (
    <TextField
      {...field}
    />
  );
};

export default Field;