import {action, makeObservable, observable} from "mobx";
import axios from "axios";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class AccountStore {
  constructor() {
    makeObservable(this, {
      account: observable,
      register: action,
    });
  }
  account = {
    data: {},
    isLoaded: false
  };

  async register(data) {
    const res = await axios.post(ROUTES.USER[ACTIONS.REGISTER], data, {
      withCredentials: true,
      successMessage: "The user has been successfully created"
    });

    this.account = {
      data: res.data,
      isLoaded: true
    };
  }

}

export default new AccountStore();
