import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import {Typography, Button, InputAdornment, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import LoginTextField from "@core/components/LoginTextField";
import PasswordField from "@core/components/LoginPasswordField";
import LoginPage from "../components/LoginContainer";
import UserService from "@core/api/user-service";
import {validatePassword, parseJwt} from "@core/helpers";
import styles from "./styles";
import {observer} from "mobx-react";
import useStores from "../../useStores";
import {AuthService} from "@core/services";

const validationSchema = yup.object().shape({
  password: yup.string().required("Password is a required field").test("password", validatePassword),
  confirmPassword: yup.string().required("Please confirm your password").oneOf([yup.ref("password"), null], "Passwords don't match"),
  firstname: yup.string().required("First name is a required field"),
  lastname: yup.string().required("Last name is a required field"),
});

const USER = {
  password: "",
  confirmPassword: "",
  firstname: "",
  lastname: "",
};

const ResetPassword = observer(({classes}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const {NotificationStore} = useStores();

  useEffect(() => {
    AuthService.logout();
    checkIfTokenValid();
  }, []);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const {token, id: userId} = params;
  const {username} = parseJwt(token);

  const checkIfTokenValid = async() => {
    const isValid = await UserService.validateInvitationToken(userId, token);

    if(isValid) return;

    window.location.replace(
      `/error?message=${encodeURI("This invite link has expired. A new link was just sent to your email.")}`
    );
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    await UserService.createFromInvite(values, userId, token);
    NotificationStore.showSuccess("Registration successfully completed!");
    setIsLoading(false);
    window.open("/login", "_self");
  };

  return (
    <LoginPage>
      <Typography component="h1" variant="h6" className={classes.title}>
        <b>Registration form</b>
      </Typography>
      <Formik
        onSubmit={onSubmit}
        initialValues={USER}
        validationSchema={validationSchema}
      >
        {(props) => {
          return (
            <>
              <LoginTextField
                name="username"
                label="Username"
                value={username}
                disabled
                endAdornment={(
                  <InputAdornment>
                    <Tooltip title="This username was set by the person who invited you but can be changed after the registration through your account settings.">
                      <InfoIcon />
                    </Tooltip>
                  </InputAdornment>
                )}
              />
              <LoginTextField
                name="firstname"
                label="First name"
                handleChange={props.handleChange}
                value={props.values.firstname}
                error={props.errors.firstname}
                touched={props.touched.firstname}
                errorMessage={props.errors.firstname}
              />
              <LoginTextField
                name="lastname"
                label="Last name"
                handleChange={props.handleChange}
                value={props.values.lastname}
                error={props.errors.lastname}
                touched={props.touched.lastname}
                errorMessage={props.errors.lastname}
              />
              <PasswordField
                showPassword={showPassword}
                handleChange={props.handleChange}
                setShowPassword={setShowPassword}
                value={props.values.password}
                error={props.errors.password}
                touched={props.touched.password}
                name="password"
                label="Password"
              />
              <PasswordField
                showPassword={showConfirmPassword}
                handleChange={props.handleChange}
                setShowPassword={setShowConfirmPassword}
                value={props.values.confirmPassword}
                error={props.errors.confirmPassword}
                touched={props.touched.confirmPassword}
                name="confirmPassword"
                label="Confirm password"
              />
              <Button
                className={classes.submit}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={props.handleSubmit}
              >
                {loading && <CircularProgress classes={{colorPrimary: classes.loader}} size={24} />}&nbsp;
                <span>Register</span>
              </Button>
            </>
          );
        }}
      </Formik>
    </LoginPage>
  );
});

export default withStyles(ResetPassword, styles);