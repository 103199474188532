import TestsDrawer from "@core/components/TestsDrawer";
import {groupBy} from "ramda";
import React, {useState} from "react";
import {Typography} from "@mui/material";
import TestSummaryTable from "@core/components/TestSummaryTable";
import testConfig from "@core/configs/test";

const TESTS = [
  {
    "_id": "65bcfa77e5466e27af07b9df",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "chemical_composition",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "elements": [
        {
          "id": 1,
          "bm": "C",
          "value": 0.11,
          "max": 0.12
        },
        {
          "id": 2,
          "bm": "Mn",
          "value": 1.4,
          "max": 1.7
        },
        {
          "id": 3,
          "bm": "Si",
          "value": 0.17,
          "max": 0.45
        },
        {
          "id": 7,
          "bm": "Nb",
          "value": 0.01,
          "max": 0.05
        },
        {
          "id": 8,
          "bm": "Ti",
          "value": 0.01,
          "max": 0.04
        },
        {
          "id": 13,
          "bm": "Al",
          "value": 0.03,
          "max": 0.06
        },
        {
          "id": 14,
          "bm": "N",
          "value": 0.003,
          "max": 0.012
        },
        {
          "id": 16,
          "bm": "CE_Pcm",
          "value": 0.2,
          "max": 0.2
        },
        {
          "id": 17,
          "bm": "Nb+V+Ti",
          "value": 0.02,
          "max": 0.15
        },
        {
          "id": 18,
          "bm": "Al/N",
          "value": 10,
          "min": 2
        },
        {
          "id": 19,
          "bm": "CE_IIW",
          "value": 0.34,
          "max": 0.42
        }
      ],
      "zone": "Base metal",
      "specimenId": 1,
      "analysis": "Product",
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "testStandard": "ASTM E415",
      "result": "Acceptable",
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.881Z",
    "__v": 0
  },
  {
    "_id": "65bcfa77e5466e27af07b9e3",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "chemical_composition",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "elements": [
        {
          "id": 1,
          "bm": "C",
          "value": 0.09,
          "max": 0.12
        },
        {
          "id": 2,
          "bm": "Mn",
          "value": 1.4,
          "max": 1.8
        },
        {
          "id": 3,
          "bm": "Si",
          "value": 0.19,
          "max": 0.45
        },
        {
          "id": 4,
          "bm": "P",
          "value": 0.01,
          "max": 0.02
        },
        {
          "id": 7,
          "bm": "Nb",
          "value": 0.01,
          "max": 0.05
        },
        {
          "id": 8,
          "bm": "Ti",
          "value": 0.02,
          "max": 0.04
        },
        {
          "id": 13,
          "bm": "Al",
          "value": 0.02,
          "max": 0.06
        },
        {
          "id": 14,
          "bm": "N",
          "value": 0.003,
          "max": 0.012
        },
        {
          "id": 15,
          "bm": "B",
          "value": 0.0001,
          "max": 0.0005
        },
        {
          "id": 16,
          "bm": "CE_Pcm",
          "value": 0.2,
          "max": 0.2
        },
        {
          "id": 17,
          "bm": "Nb+V+Ti",
          "value": 0.03,
          "max": 0.15
        },
        {
          "id": 18,
          "bm": "Al/N",
          "value": 7,
          "min": 2
        },
        {
          "id": 19,
          "bm": "CE_IIW",
          "value": 0.32,
          "max": 0.42
        }
      ],
      "zone": "Base metal",
      "specimenId": 2,
      "analysis": "Product",
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "testStandard": "ASTM E415",
      "result": "Acceptable",
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.886Z",
    "__v": 0
  },
  {
    "_id": "65bcfa77e5466e27af07b9eb",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "impact_test",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "elements": [
        {
          "location": "Weld",
          "notchPosition": "0.5 mm from weld CL",
          "energyJoule1": 103,
          "energyJoule2": 100,
          "energyJoule3": 105,
          "laboratory": "1",
          "temperature": -10
        },
        {
          "location": "HAZ",
          "notchPosition": "fusion line",
          "energyJoule1": 216,
          "energyJoule2": 215,
          "energyJoule3": 218,
          "laboratory": "2",
          "temperature": -10
        },
        {
          "location": "HAZ",
          "notchPosition": "2 mm from fusion line",
          "energyJoule1": 225,
          "energyJoule2": 228,
          "energyJoule3": 221,
          "laboratory": "3",
          "temperature": -10
        },
        {
          "location": "HAZ",
          "notchPosition": "5 mm from fusion line",
          "energyJoule1": 228,
          "energyJoule2": 219,
          "energyJoule3": 232,
          "laboratory": "4",
          "temperature": -10
        },
        {
          "location": "Base",
          "orientation": "transverse",
          "energyJoule1": 235,
          "energyJoule2": 232,
          "energyJoule3": 230,
          "shearArea1": 100,
          "shearArea2": 100,
          "shearArea3": 100,
          "laboratory": "5",
          "temperature": -10
        }
      ],
      "temperature": -10,
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "result": "Acceptable",
      "testStandard": "ISO 148-1",
      "height": 10,
      "notch": "KV",
      "radius": "2 mm",
      "requirements": {
        "Base": {
          "location": "Base",
          "single": 30,
          "average": 40,
          "singleShearArea": 75,
          "averageShearArea": 85
        },
        "Weld": {
          "location": "Weld",
          "single": 30,
          "average": 40
        },
        "HAZ": {
          "location": "HAZ",
          "single": 30,
          "average": 40
        }
      },
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.893Z",
    "__v": 0
  },
  {
    "_id": "65bcfa77e5466e27af07b9ef",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "hardness_test",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "elements": [
        {
          "id": 0,
          "position": 1,
          "value": 177,
          "metal": "ID",
          "max": 270
        },
        {
          "id": 1,
          "position": 2,
          "value": 180,
          "metal": "ID",
          "max": 270
        },
        {
          "id": 2,
          "position": 3,
          "value": 179,
          "metal": "ID",
          "max": 270
        },
        {
          "id": 3,
          "position": 4,
          "value": 182,
          "metal": "ID",
          "max": 270
        },
        {
          "id": 4,
          "position": 5,
          "value": 185,
          "metal": "ID",
          "max": 270
        },
        {
          "id": 5,
          "position": 6,
          "value": 185,
          "metal": "ID",
          "max": 270
        },
        {
          "id": 6,
          "position": 7,
          "value": 209,
          "metal": "Root",
          "max": 270
        },
        {
          "id": 7,
          "position": 8,
          "value": 213,
          "metal": "Root",
          "max": 270
        },
        {
          "id": 8,
          "position": 9,
          "value": 213,
          "metal": "Cap",
          "max": 270
        },
        {
          "id": 9,
          "position": 10,
          "value": 217,
          "metal": "Cap",
          "max": 270
        },
        {
          "id": 10,
          "position": 11,
          "value": 170,
          "metal": "Root",
          "max": 270
        },
        {
          "id": 11,
          "position": 12,
          "value": 205,
          "metal": "Root",
          "max": 270
        },
        {
          "id": 12,
          "position": 13,
          "value": 180,
          "metal": "Cap",
          "max": 270
        },
        {
          "id": 13,
          "position": 14,
          "value": 211,
          "metal": "Cap",
          "max": 270
        }
      ],
      "elementsHistory": [
        {
          "position": "1, 2",
          "metal": "Base Metal - ID"
        },
        {
          "position": "3, 4",
          "metal": "Base Metal - MW"
        },
        {
          "position": "5, 6",
          "metal": "Base Metal - OD"
        },
        {
          "position": "7, 8",
          "metal": "Weld - Root"
        },
        {
          "position": "9, 10",
          "metal": "Weld - Cap"
        },
        {
          "position": "11, 12",
          "metal": "HAZ - Root"
        },
        {
          "position": "13, 14",
          "metal": "HAZ - Cap"
        }
      ],
      "specimenId": 1,
      "amount": 14,
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "hardnessTest": "HV10",
      "max": 270,
      "result": "Partially Unacceptable",
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.896Z",
    "__v": 0
  },
  {
    "_id": "65bcfa77e5466e27af07b9f3",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "tensile_test",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "elements": [
        {
          "rm": 541,
          "temperature": 22,
          "specimenId": 1,
          "zone": "Weld Metal",
          "orientation": "Transversal",
          "requirements": {
            "tensMin": 460
          }
        }
      ],
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "result": "Acceptable",
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.899Z",
    "__v": 0
  },
  {
    "_id": "65bcfa77e5466e27af07b9fb",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "hydrostatic_test",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "pieceIdentification": "Single piece",
      "examinationConditions": {
        "0": {
          "value": "C126814"
        },
        "1": {
          "value": "C126864"
        },
        "2": {
          "value": "C126909"
        },
        "3": {
          "value": "C126913"
        },
        "4": {
          "value": "C126917"
        },
        "5": {
          "value": "C126996"
        },
        "6": {
          "value": "C127016"
        },
        "7": {
          "value": "C127020"
        },
        "8": {
          "value": "C127049"
        },
        "9": {
          "value": "C127053"
        },
        "10": {
          "value": "C127057"
        },
        "11": {
          "value": "C127069"
        },
        "12": {
          "value": "C127074"
        },
        "13": {
          "value": "C127077"
        },
        "14": {
          "value": "C127152"
        },
        "15": {
          "value": "C127171"
        },
        "16": {
          "value": "C127272"
        },
        "17": {
          "value": "C127336"
        },
        "18": {
          "value": "C127340"
        },
        "19": {
          "value": "C127344"
        },
        "20": {
          "value": "C127414"
        },
        "21": {
          "value": "C127415"
        },
        "22": {
          "value": "C127423"
        },
        "23": {
          "value": "C127406"
        },
        "24": {
          "value": "C127465"
        },
        "25": {
          "value": "C127467"
        },
        "26": {
          "value": "C127469"
        },
        "27": {
          "value": "C127470"
        },
        "28": {
          "value": "C127471"
        },
        "29": {
          "value": "C127473"
        },
        "30": {
          "value": "C127474"
        },
        "31": {
          "value": "C127475"
        },
        "32": {
          "value": "C127477"
        }
      },
      "specimenId": "",
      "pressure": 118,
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "minTime": 10,
      "minHoldingTimeUnits": "sec",
      "pressureUnit": "bar",
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [
      {
        "_id": "65bcfa7ce5466e27af07c64d",
        "company": {
          "_id": "646331321e8e4700277d3679",
          "name": "Keebler, Keebler and Rath",
          "phone": "(752) 480-4011",
          "fax": "305-844-8680",
          "email": "Carey77@hotmail.com",
          "website": "exhausted-buckwheat.name",
          "mainLocation": {
            "_id": "646331321e8e4700277d367a",
            "country": "Spain",
            "city": "West Ollie",
            "street": "Rosenbaum Mountains",
            "house": "b",
            "postalCode": "49440-0788"
          },
          "partners": [],
          "approvals": []
        },
        "status": 0,
        "notes": "",
        "files": []
      }
    ],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.905Z",
    "__v": 0
  },
  {
    "_id": "65bcfa77e5466e27af07ba01",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "dimensional_test",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "elements": [
        {
          "length": 18.21,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.2,
            "rightEndMax": 13.1
          },
          "internalDiameter": {
            "leftEndMin": 659.4,
            "rightEndMin": 658.9,
            "leftEndMax": 660.4,
            "rightEndMax": 661.2
          },
          "outOfRoundness": {
            "leftEnd": 1,
            "rightEnd": 2.3
          },
          "externalDiameter": {
            "bodyMax": 686
          },
          "productId": "J15116814"
        },
        {
          "length": 18.16,
          "straightness": 10,
          "thickness": {
            "leftEndMin": 13.2,
            "rightEndMin": 13.2,
            "leftEndMax": 13.3,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 658.5,
            "rightEndMin": 659,
            "leftEndMax": 660.9,
            "rightEndMax": 661.2
          },
          "outOfRoundness": {
            "leftEnd": 2.5,
            "rightEnd": 2.2,
            "bodyMax": 1.8
          },
          "externalDiameter": {
            "bodyMax": 686.1
          },
          "productId": "J15116864"
        },
        {
          "length": 18.21,
          "straightness": 11,
          "thickness": {
            "leftEndMin": 12.8,
            "rightEndMin": 12.9,
            "leftEndMax": 13.1,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 658.5,
            "rightEndMin": 659.2,
            "leftEndMax": 661.1,
            "rightEndMax": 660.7
          },
          "outOfRoundness": {
            "leftEnd": 2.6,
            "rightEnd": 1.6
          },
          "externalDiameter": {
            "bodyMax": 686.3
          },
          "productId": "J15116909"
        },
        {
          "length": 17.52,
          "straightness": 10,
          "thickness": {
            "leftEndMin": 13.2,
            "rightEndMin": 13.1,
            "leftEndMax": 13.3,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 659.1,
            "rightEndMin": 658,
            "leftEndMax": 660.4,
            "rightEndMax": 661.3
          },
          "outOfRoundness": {
            "leftEnd": 1.3,
            "rightEnd": 3.3
          },
          "externalDiameter": {
            "bodyMax": 686.4
          },
          "productId": "J15116913"
        },
        {
          "length": 18.2,
          "straightness": 10,
          "thickness": {
            "leftEndMin": 13.2,
            "rightEndMin": 13.2,
            "leftEndMax": 13.2,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 657.9,
            "rightEndMin": 658.4,
            "leftEndMax": 661.7,
            "rightEndMax": 661.7
          },
          "outOfRoundness": {
            "leftEnd": 3.8,
            "rightEnd": 3.3
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15116917"
        },
        {
          "length": 17.54,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.2,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 659,
            "rightEndMin": 658.4,
            "leftEndMax": 660.4,
            "rightEndMax": 660.9
          },
          "outOfRoundness": {
            "leftEnd": 1.4,
            "rightEnd": 2.5
          },
          "externalDiameter": {
            "bodyMax": 686.4
          },
          "productId": "J15116996"
        },
        {
          "length": 18.2,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.3,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 659.2,
            "rightEndMin": 658.6,
            "leftEndMax": 660.8,
            "rightEndMax": 660.7
          },
          "outOfRoundness": {
            "leftEnd": 1.6,
            "rightEnd": 2.2
          },
          "externalDiameter": {
            "bodyMax": 686.3
          },
          "productId": "J15117016"
        },
        {
          "length": 18.07,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.2,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 659.6,
            "rightEndMin": 658.2,
            "leftEndMax": 660.1,
            "rightEndMax": 661.4
          },
          "outOfRoundness": {
            "leftEnd": 0.6,
            "rightEnd": 3.1
          },
          "externalDiameter": {
            "bodyMax": 686
          },
          "productId": "J15117020"
        },
        {
          "length": 18.2,
          "straightness": 12,
          "thickness": {
            "leftEndMin": 12.9,
            "rightEndMin": 12.9,
            "leftEndMax": 13.1,
            "rightEndMax": 13.1
          },
          "internalDiameter": {
            "leftEndMin": 659.3,
            "rightEndMin": 658.9,
            "leftEndMax": 660.8,
            "rightEndMax": 660.7
          },
          "outOfRoundness": {
            "leftEnd": 1.6,
            "rightEnd": 1.7
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117049"
        },
        {
          "length": 18.21,
          "straightness": 12,
          "thickness": {
            "leftEndMin": 12.9,
            "rightEndMin": 12.9,
            "leftEndMax": 13.2,
            "rightEndMax": 13.1
          },
          "internalDiameter": {
            "leftEndMin": 658.9,
            "rightEndMin": 658.4,
            "leftEndMax": 661.2,
            "rightEndMax": 661.7
          },
          "outOfRoundness": {
            "leftEnd": 2.3,
            "rightEnd": 3.3
          },
          "externalDiameter": {
            "bodyMax": 686.4
          },
          "productId": "J15117053"
        },
        {
          "length": 18.18,
          "straightness": 12,
          "thickness": {
            "leftEndMin": 12.9,
            "rightEndMin": 12.9,
            "leftEndMax": 13.2,
            "rightEndMax": 13.1
          },
          "internalDiameter": {
            "leftEndMin": 659,
            "rightEndMin": 658.6,
            "leftEndMax": 660.9,
            "rightEndMax": 661.8
          },
          "outOfRoundness": {
            "leftEnd": 1.9,
            "rightEnd": 3.2,
            "bodyMax": 3.3
          },
          "externalDiameter": {
            "bodyMax": 686.3
          },
          "productId": "J15117057"
        },
        {
          "length": 18.22,
          "straightness": 12,
          "thickness": {
            "leftEndMin": 12.9,
            "rightEndMin": 12.9,
            "leftEndMax": 13.2,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 658.2,
            "rightEndMin": 658.9,
            "leftEndMax": 661.3,
            "rightEndMax": 660.9
          },
          "outOfRoundness": {
            "leftEnd": 3.1,
            "rightEnd": 1.9
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117069"
        },
        {
          "length": 18.22,
          "straightness": 12,
          "thickness": {
            "leftEndMin": 12.9,
            "rightEndMin": 12.9,
            "leftEndMax": 13.1,
            "rightEndMax": 13.1
          },
          "internalDiameter": {
            "leftEndMin": 658.3,
            "rightEndMin": 659.4,
            "leftEndMax": 661.1,
            "rightEndMax": 660.6
          },
          "outOfRoundness": {
            "leftEnd": 2.8,
            "rightEnd": 1.2
          },
          "externalDiameter": {
            "bodyMax": 686.1
          },
          "productId": "J15117074"
        },
        {
          "length": 18.2,
          "straightness": 11,
          "thickness": {
            "leftEndMin": 13.2,
            "rightEndMin": 13.2,
            "leftEndMax": 13.2,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 658.2,
            "rightEndMin": 658.5,
            "leftEndMax": 661.4,
            "rightEndMax": 661
          },
          "outOfRoundness": {
            "leftEnd": 3.2,
            "rightEnd": 2.5,
            "bodyMax": 1.7
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117077"
        },
        {
          "length": 17.48,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.4,
            "leftEndMax": 13.2,
            "rightEndMax": 13.5
          },
          "internalDiameter": {
            "leftEndMin": 658.4,
            "rightEndMin": 658.3,
            "leftEndMax": 661,
            "rightEndMax": 661
          },
          "outOfRoundness": {
            "leftEnd": 2.6,
            "rightEnd": 2.7
          },
          "externalDiameter": {
            "bodyMax": 686.4
          },
          "productId": "J15117152"
        },
        {
          "length": 18.18,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.3,
            "leftEndMax": 13.1,
            "rightEndMax": 13.4
          },
          "internalDiameter": {
            "leftEndMin": 659.2,
            "rightEndMin": 658.7,
            "leftEndMax": 660.8,
            "rightEndMax": 661.1
          },
          "outOfRoundness": {
            "leftEnd": 1.6,
            "rightEnd": 2.4
          },
          "externalDiameter": {
            "bodyMax": 686.1
          },
          "productId": "J15117171"
        },
        {
          "length": 17.97,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.2,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 659.3,
            "rightEndMin": 658.8,
            "leftEndMax": 660.4,
            "rightEndMax": 660.4
          },
          "outOfRoundness": {
            "leftEnd": 1.1,
            "rightEnd": 1.6
          },
          "externalDiameter": {
            "bodyMax": 686.3
          },
          "productId": "J15117272"
        },
        {
          "length": 17.99,
          "straightness": 11,
          "thickness": {
            "leftEndMin": 13.2,
            "rightEndMin": 13.2,
            "leftEndMax": 13.3,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 659.3,
            "rightEndMin": 658.6,
            "leftEndMax": 660.2,
            "rightEndMax": 660.6
          },
          "outOfRoundness": {
            "leftEnd": 0.9,
            "rightEnd": 2
          },
          "externalDiameter": {
            "bodyMax": 686
          },
          "productId": "J15117336"
        },
        {
          "length": 18.11,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.2,
            "rightEndMin": 13.1,
            "leftEndMax": 13.2,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 659.1,
            "rightEndMin": 658.9,
            "leftEndMax": 661.3,
            "rightEndMax": 660.8
          },
          "outOfRoundness": {
            "leftEnd": 2.2,
            "rightEnd": 2
          },
          "externalDiameter": {
            "bodyMax": 686.1
          },
          "productId": "J15117340"
        },
        {
          "length": 18.19,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.3,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 659,
            "rightEndMin": 658.5,
            "leftEndMax": 661,
            "rightEndMax": 661.1
          },
          "outOfRoundness": {
            "leftEnd": 2.1,
            "rightEnd": 2.5
          },
          "externalDiameter": {
            "bodyMax": 685.9
          },
          "productId": "J15117344"
        },
        {
          "length": 18,
          "straightness": 11,
          "thickness": {
            "leftEndMin": 12.9,
            "rightEndMin": 12.9,
            "leftEndMax": 13.2,
            "rightEndMax": 13.1
          },
          "internalDiameter": {
            "leftEndMin": 659.2,
            "rightEndMin": 658.4,
            "leftEndMax": 660.6,
            "rightEndMax": 660.8
          },
          "outOfRoundness": {
            "leftEnd": 1.4,
            "rightEnd": 2.5
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117414"
        },
        {
          "length": 18.19,
          "straightness": 15,
          "thickness": {
            "leftEndMin": 13.2,
            "rightEndMin": 13.3,
            "leftEndMax": 13.2,
            "rightEndMax": 13.4
          },
          "internalDiameter": {
            "leftEndMin": 659.6,
            "rightEndMin": 659.4,
            "leftEndMax": 661,
            "rightEndMax": 660
          },
          "outOfRoundness": {
            "leftEnd": 1.4,
            "rightEnd": 0.6,
            "bodyMax": 2.4
          },
          "externalDiameter": {
            "bodyMax": 686.2999877929688
          },
          "productId": "J15117415"
        },
        {
          "length": 18.19,
          "straightness": 16,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.2,
            "leftEndMax": 13.2,
            "rightEndMax": 13.4
          },
          "internalDiameter": {
            "leftEndMin": 659.1,
            "rightEndMin": 658.6,
            "leftEndMax": 660.4,
            "rightEndMax": 660.8
          },
          "outOfRoundness": {
            "leftEnd": 1.3,
            "rightEnd": 2.2,
            "bodyMax": 2.7
          },
          "externalDiameter": {
            "bodyMax": 686.2999877929688
          },
          "productId": "J15117423"
        },
        {
          "length": 17.25,
          "straightness": 12,
          "thickness": {
            "leftEndMin": 13.2,
            "rightEndMin": 13.2,
            "leftEndMax": 13.2,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 659.4,
            "rightEndMin": 658.4,
            "leftEndMax": 660.7,
            "rightEndMax": 661.1
          },
          "outOfRoundness": {
            "leftEnd": 1.3,
            "rightEnd": 2.8
          },
          "externalDiameter": {
            "bodyMax": 686.4
          },
          "productId": "J15117406"
        },
        {
          "length": 18.2,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.3,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 659.1,
            "rightEndMin": 658.9,
            "leftEndMax": 660.2,
            "rightEndMax": 661.3
          },
          "outOfRoundness": {
            "leftEnd": 1.1,
            "rightEnd": 2.4
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117465"
        },
        {
          "length": 18.2,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.2,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 657.7,
            "rightEndMin": 658.4,
            "leftEndMax": 661.7,
            "rightEndMax": 661.1
          },
          "outOfRoundness": {
            "leftEnd": 4,
            "rightEnd": 2.7
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117467"
        },
        {
          "length": 18.21,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.3,
            "rightEndMax": 13.2
          },
          "internalDiameter": {
            "leftEndMin": 659.1,
            "rightEndMin": 659,
            "leftEndMax": 660.3,
            "rightEndMax": 661
          },
          "outOfRoundness": {
            "leftEnd": 1.3,
            "rightEnd": 2.1
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117469"
        },
        {
          "length": 18.16,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13,
            "rightEndMin": 13.1,
            "leftEndMax": 13.2,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 658.6,
            "rightEndMin": 658.9,
            "leftEndMax": 660.7,
            "rightEndMax": 660.9
          },
          "outOfRoundness": {
            "leftEnd": 2.1,
            "rightEnd": 2
          },
          "externalDiameter": {
            "bodyMax": 686.4
          },
          "productId": "J15117470"
        },
        {
          "length": 18.21,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13,
            "leftEndMax": 13.3,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 658.7,
            "rightEndMin": 658.7,
            "leftEndMax": 660.8,
            "rightEndMax": 660.6
          },
          "outOfRoundness": {
            "leftEnd": 2.2,
            "rightEnd": 1.8
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117471"
        },
        {
          "length": 18.21,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.3,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 658.6,
            "rightEndMin": 659.2,
            "leftEndMax": 660.8,
            "rightEndMax": 661
          },
          "outOfRoundness": {
            "leftEnd": 2.2,
            "rightEnd": 1.7
          },
          "externalDiameter": {
            "bodyMax": 686.2
          },
          "productId": "J15117473"
        },
        {
          "length": 18.2,
          "straightness": 13,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.1,
            "leftEndMax": 13.3,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 659.3,
            "rightEndMin": 659.4,
            "leftEndMax": 660.1,
            "rightEndMax": 660.3
          },
          "outOfRoundness": {
            "leftEnd": 0.8,
            "rightEnd": 0.9,
            "bodyMax": 2.7
          },
          "externalDiameter": {
            "bodyMax": 686.4000244140625
          },
          "productId": "J15117474"
        },
        {
          "length": 18.2,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13,
            "leftEndMax": 13.2,
            "rightEndMax": 13.3
          },
          "internalDiameter": {
            "leftEndMin": 658.6,
            "rightEndMin": 658.7,
            "leftEndMax": 661.1,
            "rightEndMax": 661.2
          },
          "outOfRoundness": {
            "leftEnd": 2.5,
            "rightEnd": 2.5
          },
          "externalDiameter": {
            "bodyMax": 685.9
          },
          "productId": "J15117475"
        },
        {
          "length": 17.97,
          "straightness": 0,
          "thickness": {
            "leftEndMin": 13.1,
            "rightEndMin": 13.3,
            "leftEndMax": 13.2,
            "rightEndMax": 13.4
          },
          "internalDiameter": {
            "leftEndMin": 659.2,
            "rightEndMin": 658,
            "leftEndMax": 660.7,
            "rightEndMax": 661.7
          },
          "outOfRoundness": {
            "leftEnd": 1.5,
            "rightEnd": 3.8
          },
          "externalDiameter": {
            "bodyMax": 686
          },
          "productId": "J15117477"
        }
      ],
      "units": {
        "length": "mm",
        "straightness": "mm",
        "thickness": "mm",
        "internalDiameter": "mm",
        "outOfRoundness": "mm",
        "externalDiameter": "mm"
      },
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "result": "Not Acceptable",
      "acceptanceCriteria": {
        "externalDiameter": {
          "body": {
            "nominal": 686,
            "toleranceMinus": 2,
            "tolerancePlus": 2
          }
        },
        "internalDiameter": {
          "ends": {
            "nominal": 660,
            "toleranceMinus": 1.5,
            "tolerancePlus": 1.5
          }
        },
        "thickness": {
          "ends": {
            "nominal": 13,
            "toleranceMinus": 0.5,
            "tolerancePlus": 1
          }
        },
        "geometricDeviation": {
          "nominal": 0,
          "tolerancePlus": 3.2
        },
        "outOfRoundness": {
          "body": {
            "nominal": 0,
            "tolerancePlus": 6.8
          },
          "ends": {
            "nominal": 0,
            "tolerancePlus": 5.1
          }
        },
        "length": {
          "nominal": 17.22,
          "tolerancePlus": 1
        },
        "straightness": {
          "body": {
            "reqsHeader": "Max 0.15% on length",
            "nominal": 0,
            "tolerancePlusFormula": "(element = {}) => 0.15 * Number(element.length) / 100",
            "tolerancePlus": 0.15,
            "toleranceUnits": "%"
          },
          "ends": {
            "nominal": 0,
            "tolerancePlus": 2.9
          }
        },
        "squareness": {
          "body": {
            "nominal": 0,
            "tolerancePlus": 1.6
          },
          "ends": {
            "nominal": 0,
            "tolerancePlus": 1.6
          }
        },
        "radialOffset": {
          "nominal": 0,
          "tolerancePlus": 1.3
        },
        "heightOfWeldBead": {
          "body": {
            "nominal": 0,
            "tolerancePlus": 3.5
          },
          "ends": {
            "nominal": 0,
            "tolerancePlus": 3.5
          }
        },
        "misalignmentOfWeldBead": {
          "nominal": 0,
          "tolerancePlus": 3
        }
      },
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [
      {
        "_id": "65bcfa7ce5466e27af07c655",
        "company": {
          "_id": "646331321e8e4700277d3679",
          "name": "Keebler, Keebler and Rath",
          "phone": "(752) 480-4011",
          "fax": "305-844-8680",
          "email": "Carey77@hotmail.com",
          "website": "exhausted-buckwheat.name",
          "mainLocation": {
            "_id": "646331321e8e4700277d367a",
            "country": "Spain",
            "city": "West Ollie",
            "street": "Rosenbaum Mountains",
            "house": "b",
            "postalCode": "49440-0788"
          },
          "partners": [],
          "approvals": []
        },
        "status": 0,
        "notes": "",
        "files": []
      }
    ],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.912Z",
    "__v": 0
  },
  {
    "_id": "65bcfa77e5466e27af07b9e7",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "chemical_composition",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "elements": [
        {
          "id": 1,
          "bm": "C",
          "value": 0.09,
          "max": 0.12
        },
        {
          "id": 2,
          "bm": "Mn",
          "value": 1.4,
          "max": 1.8
        },
        {
          "id": 3,
          "bm": "Si",
          "value": 0.19,
          "max": 0.45
        },
        {
          "id": 4,
          "bm": "P",
          "value": 0.01,
          "max": 0.02
        },
        {
          "id": 7,
          "bm": "Nb",
          "value": 0.01,
          "max": 0.05
        },
        {
          "id": 8,
          "bm": "Ti",
          "value": 0.02,
          "max": 0.04
        },
        {
          "id": 13,
          "bm": "Al",
          "value": 0.02,
          "max": 0.06
        },
        {
          "id": 14,
          "bm": "N",
          "value": 0.003,
          "max": 0.012
        },
        {
          "id": 15,
          "bm": "B",
          "value": 0.0001,
          "max": 0.0005
        },
        {
          "id": 16,
          "bm": "CE_Pcm",
          "value": 0.2,
          "max": 0.2
        },
        {
          "id": 17,
          "bm": "Nb+V+Ti",
          "value": 0.03,
          "max": 0.15
        },
        {
          "id": 18,
          "bm": "Al/N",
          "value": 7,
          "min": 2
        },
        {
          "id": 19,
          "bm": "CE_IIW",
          "value": 0.32,
          "max": 0.42
        }
      ],
      "zone": "Base metal",
      "specimenId": 2,
      "analysis": "Product",
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "testStandard": "ASTM E415",
      "result": "Acceptable",
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.889Z",
    "__v": 0
  },
  {
    "_id": "65bcfa77e5466e27af07b9f7",
    "company": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44",
      "logo": "",
      "phone": "(522) 205-0757",
      "fax": "817-755-7804",
      "email": "Dion.Schinner61@gmail.com",
      "website": "concerned-injury.biz",
      "mainLocation": {
        "_id": "646331321e8e4700277d3616",
        "country": "Saudi Arabia",
        "city": "Robertaboro",
        "street": "Schmitt Key",
        "house": "c",
        "postalCode": "43871-5294"
      },
      "partners": [
        {
          "url": "",
          "image": "/646331321e8e4700277d3615/1701774493875_Screenshot_2023-12-05_at_12.36.13.png",
          "name": "part",
          "_id": "65771d22b4868d5e59e7f829"
        },
        {
          "url": "",
          "image": "",
          "name": "21212",
          "_id": "65771d22b4868d5e59e7f82a"
        }
      ],
      "approvals": [
        {
          "standard": "standard",
          "issuingBody": "body",
          "description": "desc",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1687851137552_1687600871982_prova.pdf",
          "_id": "65771d22b4868d5e59e7f82b"
        },
        {
          "standard": "d",
          "issuingBody": "d",
          "description": "d",
          "from": null,
          "to": null,
          "link": "/646331321e8e4700277d3615/1701777625968_17A3A198-6EC4-4363-BD97-41349259CF2E.pdf",
          "_id": "65771d22b4868d5e59e7f82c"
        }
      ]
    },
    "status": "FILLED",
    "type": "tensile_test",
    "grade": "X52MO (L360MO)",
    "item_heat": "G3235",
    "norm": "API 5L PSL2 (2018)",
    "properties": {
      "grade": "X52MO (L360MO)",
      "norm": "API 5L PSL2 (2018)",
      "elements": [
        {
          "rm": 541,
          "temperature": 22,
          "specimenId": 1,
          "zone": "Weld Metal",
          "orientation": "Transversal",
          "requirements": {
            "tensMin": 460
          }
        }
      ],
      "acceptance": "CPW-ITP-24-23-SAWL Rev. 3",
      "acceptanceItem": "10",
      "result": "Acceptable",
      "client": "Satterfield, Hessel and Bechtelar44"
    },
    "retest": [],
    "witnesses": [],
    "productType": {
      "_id": "65b76fdee7c2fb2d0e8a636b",
      "name": "Sleeves",
      "internalName": "Sleeves"
    },
    "instruments": [],
    "transactions": [],
    "date_created": "2024-02-02T14:21:43.902Z",
    "__v": 0
  }
];

const TestsOverview = ({selectedGroup}) => {
  const [openTests, setOpenTests] = useState(null);

  const groupedTests = groupBy((test) => testConfig[test.type].group, TESTS);

  return (
    <>
      <Typography variant="h6" marginBottom={1}>
        {selectedGroup} Overview
      </Typography>
      <TestSummaryTable
        tests={groupedTests[selectedGroup] || []}
        onTestClick={setOpenTests}
      />
      <TestsDrawer
        open={!!openTests}
        tests={openTests || []}
        close={() => setOpenTests(null)}
      />
    </>
  );
};

export default TestsOverview;