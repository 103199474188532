import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import AddTests from "../../../components/AddTests";
import styles from "./styles";
import {isEmpty} from "ramda";
import {TestService} from "@core/services";
import MultipleSigningModal from "@core/components/MultipleSigningModal";
import AddExistingTest from "../AddExistingTest";
import {TEST_WITH_MANDATORY_WITNESS} from "@core/constants/test";
import useStores from "../../../../useStores";
import SignAllButton from "./components/SignAllButton";

const TestsActions = observer(({
  classes,
  certificate,
  isBasic,
  openBasicUpdate,
}) => {
  const {TestStore, CertificateStore} = useStores();
  
  // const [addTestConditioningOpened, setAddTestConditioningOpened] = useState(false);
  const [addExistingTestOpened, setAddExistingTestOpened] = useState(false);
  
  const onNewTestClick = (handler) => {
    if (isBasic) {
      openBasicUpdate();

      return;
    }

    handler(true);
  };

  const createTests = async (testsToCreate, testDataByType) => {
    const [response] = await TestStore.createMultipleTestsForMultipleCertificates([certificate._id], testsToCreate);
    await CertificateStore.updateCertificate({...certificate, tests: [...certificate.tests, ...response.tests]});
    await TestService.assignTestsToMultipleCertificates([response], testDataByType);
  };

  const createTestWithData = async (data, inspectionData = {}, allowToSign) => {
    const testToCreate = {
      ...data,
      productType: certificate.properties.productType._id,
      item_heat: certificate.heat,
      lotId: certificate.properties.lotId || "",
      internalWorkOrder: certificate.internalWorkOrder,
      internalItemNumber: certificate.internalItemNumber,
      norm: certificate.properties.norm,
      grade: certificate.properties.grade,
      inspectionDate: inspectionData.inspectionDate,
      inspectorJobNumber: inspectionData.inspectorJobNumber,
    };
    const [test] = await TestStore.create([testToCreate]);
    await CertificateStore.addTest([test._id], certificate._id);

    if(allowToSign) await TestService.assignInspector([test], {[test._id]: certificate});
  };

  const testsWithRequiredWitnesses = certificate.certificateType === "3.2" ? TEST_WITH_MANDATORY_WITNESS : [];

  if (certificate.createdByPortal && isEmpty(certificate.transactions)) return null;

  return (
    <>
      <div className={classes.buttonsContainer} data-html2canvas-ignore="true">
        {/* <Button */}
        {/*   variant={"contained"} */}
        {/*   size={"medium"} */}
        {/*   color={"primary"} */}
        {/*   onClick={() => onNewTestClick(setAddTestConditioningOpened)} */}
        {/* > */}
        {/*   Add test conditioning */}
        {/* </Button> */}
        {!isBasic && (
          <MultipleSigningModal
            disabled={!certificate.transactions.length}
            tests={certificate.tests}
            link={`/certificates/${certificate._id}`}
            entity={certificate}
            updateTests={(test, changes) => CertificateStore.updateTests({...test, ...changes})}
            triggerButton={SignAllButton}
          />
        )}
        <AddTests
          certificates={[certificate]}
          createTests={createTests}
          createTestWithData={createTestWithData}
          testsWithRequiredWitnesses={testsWithRequiredWitnesses}
        />
        <Button
          variant={"contained"}
          size={"medium"}
          color={"primary"}
          onClick={() => onNewTestClick(setAddExistingTestOpened)}
        >
          Add existing test
          {isBasic && <sup className={classes.proLabel}>Pro</sup>}
        </Button>
      </div>
      {/* <AddTestConditioning */}
      {/*   certificate={certificate} */}
      {/*   open={addTestConditioningOpened} */}
      {/*   onClose={() => setAddTestConditioningOpened(false)} */}
      {/* /> */}
      <AddExistingTest
        certificate={certificate}
        open={addExistingTestOpened}
        onClose={() => setAddExistingTestOpened(false)}
      />
    </>
  );
});

export default withStyles(TestsActions, styles);
