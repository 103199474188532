import React, {useEffect} from "react";
import {omit, keys} from "ramda";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import useStores from "../../../useStores";

const Properties = ({item, productType}) => {
  useEffect(() => {
    CertificateStore.getCertificateProductById(productType?._id);
  }, []);

  const {CertificateStore} = useStores();

  const {product} = CertificateStore;
  const productProperties = (product && product.properties) || [];

  return keys(omit(["quantity", "_id"], item)).map((prop) => {
    const property = productProperties.find((p) => p.name === prop);

    if (!property) return null;

    const {label, measurements} = property;

    return (
      <Grid key={prop} item xs={3}>
        {`${label}: ${item[prop]} ${measurements ? measurements.toLowerCase() : ""}`}
      </Grid>
    );
  });
};

export default observer(Properties);
