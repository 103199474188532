import React from "react";
import {Button} from "@mui/material";
import {prop, uniqBy} from "ramda";
import {getColumns} from "@core/columns/certificate";
import RouterLink from "@core/components/RouterLink";

export default (normsByMaterial) => {
  return uniqBy(prop("dataIndex"),[
    {
      dataIndex: "action",
      permanent: true,
      title: "Action",
      align: "center",
      render: (certificate) => (
        <Button
          color="primary"
          component={RouterLink}
          to={`/certificates/archive/${certificate._id}`}
        >
          View
        </Button>
      )
    },
    {
      dataIndex: "company.name",
      title: "Owner",
    },
    {
      isSorted: "true",
      dataIndex: "orderNumber",
      title: "PO Number",
      render: (certificate) => certificate.orderNumber || "-",
    },
    ...getColumns(normsByMaterial),
  ]);
};
