import QcpSideBar from "@core/components/QcpSideBar";
import ActionsGroupButton from "../ActionsGroupButton";
import React, {useCallback, useState} from "react";
import {Badge, Typography, Grid, Divider, Link} from "@mui/material";

const campaign = {
  name: "Ballymore Riser",
  certificationType: "3.1",
  purchaseOrder: {
    _id: "646341d3c04db40027a1e0fd",
    orderNumber: "3123-2023-057",
    receiver: {
      name: "Sauer LLC",
      _id: "656f140f229c06e6dbf26aa9"
    }
  },
  qcp: "QCP 8262 Rev. 6 MPQT/FDPT",
};

const linkStyles = {
  cursor: "pointer",
  color: "inherit",
  textDecoration: "underline",
  textDecorationColor: "#AAA2A2"
};

const GeneralInfo = () => {
  const [qcpOpen, setQcpOpen] = useState(false);

  const toggleQcp = useCallback(() => {
    setQcpOpen(!qcpOpen);
  }, [qcpOpen]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Badge badgeContent={campaign.certificationType} color="primary">
                <Typography variant="h4" sx={{fontSize: "2rem"}}>{campaign.name} Campaign</Typography>
              </Badge>
            </Grid>
            <Grid item>
              <ActionsGroupButton />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              Customer:&nbsp;
              <Link sx={linkStyles}>
                {campaign.purchaseOrder.receiver.name}
              </Link>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              PO:&nbsp;
              <Link
                sx={linkStyles}
                href={`/transfers/${campaign.purchaseOrder._id}`}
                target="_blank"
              >
                {campaign.purchaseOrder.orderNumber}
              </Link>
            </Grid>
            {campaign.qcp && (
              <>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  QCP/ITP:&nbsp;
                  <Link
                    sx={linkStyles}
                    onClick={toggleQcp}
                  >
                    {campaign.qcp}
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <QcpSideBar
        open={qcpOpen}
        close={toggleQcp}
        qcp={campaign.qcp}
      />
    </>
  );
};

export default GeneralInfo;