export default (theme) => ({
  marginBottom: {
    marginBottom: "32px"
  },

  values: {
    fontWeight: theme.typography.fontWeightMedium,
    color: "#616161"
  },

  name: {
    paddingTop: "10px",
    color: "#616161",
    marginBottom: "5px",
  },

  value: {
    color: "#616161",
    fontWeight: theme.typography.fontWeightMedium,
  },

  cellGreen: {
    color: "#4caf50",
  },

  borderTable: {
    "& td, & th": {
      textAlign: "center",
      padding: "4px 24px",
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& b": {
      fontWeight: theme.typography.fontWeightMedium
    }
  }
});

