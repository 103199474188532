import File from "@core/components/File";
import {STATUSES} from "@core/constants/test";
import {TestService, TransactionService} from "@core/services";
import {Button, Grid, Link, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import classNames from "classnames";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import AttestSection from "../../../../Tests/Test/components/AttestSection";
import useStores from "../../../../useStores";
import styles from "./styles";
import {ACTIONS} from "@core/constants/api";
import {ROUTES} from "@core/api/routes";

const Split = observer(({classes, test, ...props}) => {
  const {CertificateStore, UserStore} = useStores();

  const [certificates, setCertificates] = useState([]);

  const certificate = props.certificate || CertificateStore.certificate.data;
  const parent = certificates.find((certificate) => certificate._id === test.properties.parentCertificate) || {};
  const children = certificates.filter((certificate) => certificate._id !== test.properties.parentCertificate);
  const parentQuantity = parent.items?.reduce((acc, item) => acc + Number(item.quantity), 0);
  const childrenQuantity = children.reduce((acc, child) => {
    return acc + child.items.reduce((acc, item) => acc + Number(item.quantity), 0);
  }, 0);

  useEffect(() => {
    axios.get(ROUTES.CERTIFICATE[ACTIONS.ALL_BY_QUERY](test._id))
      .then(({data}) => setCertificates(data));
  }, []);

  const witnessSplit = (status, test) => {
    TestService.attestSplit([status], [test]);
  };

  const approveSplit = () => {
    TestService.approve([test], certificate, UserStore.user.data._id);
  };

  const assignWitness = () => {
    const testsTransactions = TransactionService.getSplitCreateNewTestTX([certificate], test.witnesses);
    const witnessTransactions = TransactionService.getAddInspectingCompanies([test]);
    const transactions = testsTransactions.concat(witnessTransactions);
    TransactionService.showQR(transactions, UserStore.user.data._id, {
      title: "Add inspectors to witness the certificate split",
      createdFor: "the certificate owner"
    });
  };

  const continueSignProcess = () => {
    if (certificate.transactions.length === 0) {
      CertificateStore.openForceSignCertificate();
    } else {
      if (test.witnesses.length === 0) {
        approveSplit();
      } else {
        assignWitness();
      }
    }

  };
  const hasAttachment = certificates.some((certificate) => certificate.items.some((item) => item.split_image));

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Certificate ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Heat</TableCell>
                <TableCell>Lot ID</TableCell>
                <TableCell>Quantity</TableCell>
                {hasAttachment && <TableCell>Marking</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                style={hasAttachment ? {height: 60}: {}}
                className={classNames({
                  [classes.active]: test.properties.parentCertificate === certificate._id,
                })}
              >
                <TableCell>
                  <Link
                    target="_blank"
                    href={`/certificates/${test.properties.parentCertificate}`}
                    underline="hover">
                    {test.properties.parentCertificate}
                  </Link>
                </TableCell>
                <TableCell>Parent</TableCell>
                <TableCell>{parent.heat || "-"}</TableCell>
                <TableCell>{parent.properties?.lotId || "-"}</TableCell>
                <TableCell>
                  <div className={classes.quantity}>
                    {parentQuantity || "-"}
                    &nbsp;
                    <Tooltip title={`Original certificate quantity: ${parentQuantity + childrenQuantity}`}>
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  </div>
                </TableCell>
                {hasAttachment && (
                  <TableCell>
                    {parent.items && parent.items[0].split_image && (
                      <File file={parent.items[0].split_image} />
                    )}
                  </TableCell>
                )}
              </TableRow>
              {children.map((child) => (
                <TableRow
                  key={child._id}
                  style={hasAttachment ? {height: 60}: {}}
                  className={classNames({
                    [classes.active]: child._id === certificate._id,
                  })}
                >
                  <TableCell>
                    <Link target="_blank" href={`/certificates/${child._id}`} underline="hover">
                      {child._id}
                    </Link>
                  </TableCell>
                  <TableCell>Child</TableCell>
                  <TableCell>{child.heat}</TableCell>
                  <TableCell>{child.properties.lotId || "-"}</TableCell>
                  <TableCell>{child.items.reduce((acc, item) => acc + Number(item.quantity), 0)}</TableCell>
                  {hasAttachment && (
                    <TableCell>
                      {child.items[0].split_image && (
                        <File file={child.items[0].split_image} />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      {/* view 1 */}
      {test.status === STATUSES.EMPTY && <div>
        <Grid spacing={5} container justifyContent={"flex-end"}>
          <Grid item xs={12}>
            <Button onClick={continueSignProcess} variant={"contained"} color={"primary"}>sign</Button>
          </Grid>
        </Grid>
      </div>}

      {/* view 2 */}
      {test.status === STATUSES.INSPECTING && <Grid container spacing={5} justifyContent={"center"}>
        <Grid item xs={4}>
          <AttestSection test={test} witnessTest={witnessSplit} />
        </Grid>
      </Grid>
      }
    </>
  );
});

export default withRouter(withStyles(Split, styles));
