import {TEST_RESULTS} from "@core/constants/testResults";
import React, {useRef} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import {omit, values} from "ramda";
import {Grid, MenuItem} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import ByFerriteScope from "./ByFerriteScope";
import AutomaticCount from "./AutomaticCount";
import ManualCount from "./ManualCount";
import ClientField from "../../../../Tests/Test/components/ClientField";
import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import {FERRITE_TEST_TYPES, TEST_STANDARDS_BY_TEST_TYPE, ferriteScopeSchema, manualCountSchema, automaticCountSchema} from "./data";

const isByFerriteScopeAcceptable = ({elements, min, max}) => {
  const minimum = min ? Number(min) : 0;
  const maximum = Number(max);

  return elements.every((tests) => Array.isArray(tests) && tests.every((value) => {
    const number = Number(value);

    return number >= minimum && (!maximum || number <= maximum);
  }));
};

const isCountAcceptable = ({meanFerrite, ci, min, max}) => {
  const isAcceptableAgainstMin = Number(meanFerrite) - Number(ci) >= Number(min);
  const isAcceptableAgainstMax = Number(meanFerrite) + Number(ci) <= Number(max);

  return isAcceptableAgainstMax && isAcceptableAgainstMin;
};

const ACCEPTANCE_FUNCTION_BY_TYPE = {
  [FERRITE_TEST_TYPES.BY_FERRITE_SCOPE]: isByFerriteScopeAcceptable,
  [FERRITE_TEST_TYPES.AUTOMATIC_COUNT]: isCountAcceptable,
  [FERRITE_TEST_TYPES.MANUAL_COUNT]: isCountAcceptable,
};

const FORM_BY_TEST_TYPE = {
  [FERRITE_TEST_TYPES.BY_FERRITE_SCOPE]: ByFerriteScope,
  [FERRITE_TEST_TYPES.AUTOMATIC_COUNT]: AutomaticCount,
  [FERRITE_TEST_TYPES.MANUAL_COUNT]: ManualCount,
};

const VALIDATION_SCHEMA_BY_TEST_TYPE = {
  [FERRITE_TEST_TYPES.BY_FERRITE_SCOPE]: ferriteScopeSchema,
  [FERRITE_TEST_TYPES.AUTOMATIC_COUNT]: automaticCountSchema,
  [FERRITE_TEST_TYPES.MANUAL_COUNT]: manualCountSchema,
};

const FerriteContent = ({test, saveTest, user, client, inspectedCertificates, isProducerTest, formRef, closeNewTest}) => {
  const initialValues = {
    client: !isProducerTest ? (test?.properties.client || client.name || "") : "",
    lab: !isProducerTest ? (test?.properties.lab || user.company.name || "") : "",
    type: isProducerTest ? FERRITE_TEST_TYPES.BY_FERRITE_SCOPE : "",
    norm: "",
    acceptance: "",
    min: "",
    max: "",
    uom: "",
    maxRelativeAccuracy: "",
    etchingStandard: "",
    etchingSolution: "",
    electrolytic: false,
    position: "",
    orientation: "",
    specimenId: "",
    magnification: "",
    pointsPerField: "",
    singleFieldArea: "",
    totalAreaAnalyzed: "",
    elements: [],
    meanFerrite: "",
    meanFerriteMicron: "",
    deviation: "",
    ci: "",
    accuracy: "",
    notes: "",
    witnesses: test.witnesses.map((witness) => witness.company) || [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: []
  };

  const getValidationSchema = () => yup.lazy((values) => {
    const typeDependantValidationSchema = VALIDATION_SCHEMA_BY_TEST_TYPE[values.type];

    return yup.object().shape({
      client: !isProducerTest ? yup.string().required("This field is required!") : yup.string(),
      lab: !isProducerTest ? yup.string().required("This field is required!") : yup.string(),
      type: yup.string().required("This field is required!"),
      norm: yup.string().required("This field is required!"),
      acceptance: yup.string().required("This field is required!"),
      uom: yup.string().when(["min", "max"], {
        is: (min, max) => min || max,
        then: yup.string().required("This field is required!"),
      }),
      notes: yup.string(),
      inspectionDate: yup.string(),
      inspectorJobNumber: yup.string(),
      tags: yup.array().of(yup.string()),
      ...typeDependantValidationSchema
    });
  });

  const onSubmit = (values) => {
    if (isProducerTest) saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    else saveTest(values);

    if (!closeNewTest) return;

    closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{...initialValues, ...test?.properties}}
      validationSchema={getValidationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(props) => {
        const firstUpdate = useRef(true);

        const Component = FORM_BY_TEST_TYPE[props.values.type];

        const getIsAcceptable = ACCEPTANCE_FUNCTION_BY_TYPE[props.values.type];
        const isAcceptable = getIsAcceptable && getIsAcceptable(props.values) && props.isValid;
        const result = isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

        return (
          <>
            {isProducerTest && (
              <Grid item container spacing={5} marginBottom={4}>
                <Grid item>
                  <h1>Ferrite Content Measurement</h1>
                </Grid>
              </Grid>
            )}
            {!isProducerTest && (
              <Grid container spacing={5} marginBottom={2}>
                <Grid item xs={3}>
                  <ClientField
                    isFromProducer={!!client.name}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    name="lab"
                    label="Laboratory"
                    required
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={5} marginBottom={2}>
              {!isProducerTest && (
                <Grid item xs={3}>
                  <SelectField
                    name="type"
                    label="Choose ferrite test type"
                    required
                    onChange={(value) => {
                      props.setFieldValue("norm", TEST_STANDARDS_BY_TEST_TYPE[value]);
                    }}
                  >
                    {values(FERRITE_TEST_TYPES).map((type) => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              )}
              <Grid item xs={isProducerTest ? 4 : 3}>
                <TextField
                  name="norm"
                  label="Test standard"
                  required={TEST_STANDARDS_BY_TEST_TYPE[props.values.type]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={12}>
                <h3>Acceptance criteria</h3>
              </Grid>
            </Grid>
            <Grid container spacing={5} marginBottom={2}>
              <Grid item xs={isProducerTest ? 4 : 3}>
                <TextField
                  name="acceptance"
                  label="Specification"
                  required
                />
              </Grid>
              <Grid item xs={isProducerTest ? 4 : 3} container spacing={1} justifyContent="space-between">
                <Grid item xs={4}>
                  <TextField
                    type="number"
                    name="min"
                    label="Min"
                    required={[FERRITE_TEST_TYPES.MANUAL_COUNT, FERRITE_TEST_TYPES.AUTOMATIC_COUNT].includes(props.values.type)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="number"
                    name="max"
                    label="Max"
                    required={[FERRITE_TEST_TYPES.MANUAL_COUNT, FERRITE_TEST_TYPES.AUTOMATIC_COUNT].includes(props.values.type)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectField
                    name="uom"
                    label="UoM"
                    required={props.values.min || props.values.max}
                  >
                    <MenuItem key="%" value="%">%</MenuItem>
                    <MenuItem key="FN" value="FN">FN</MenuItem>
                  </SelectField>
                </Grid>
              </Grid>
              {[FERRITE_TEST_TYPES.MANUAL_COUNT, FERRITE_TEST_TYPES.AUTOMATIC_COUNT].includes(props.values.type) && (
                <Grid item xs={3}>
                  <TextField
                    name="maxRelativeAccuracy"
                    label="Max relative accuracy"
                    endAdornment="%"
                  />
                </Grid>
              )}
            </Grid>
            {Component && (
              <Component
                test={test}
                isProducerTest={isProducerTest}
                inspectedCertificates={inspectedCertificates}
                firstUpdate={firstUpdate}
              />
            )}
            <Grid container marginTop={1}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <TextField
                  rows={4}
                  multiline
                  name="notes"
                  label="Additional remarks"
                />
              </Grid>
            </Grid>
            {isProducerTest ? (
              <ProducerTestFooter />
            ) : (
              <LabTestFooter
                onSubmit={() => onSubmit({...props.values, result})}
                result={result}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default FerriteContent;
