import Footer from "@core/components/TestForms/ErosionTest/components/Footer";
import {TEST_RESULTS} from "@core/constants/testResults";
import {getIsWithinRequirements} from "@core/helpers";
import React from "react";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import ErodingParticle from "@core/components/TestForms/ErosionTest/components/ErodingParticle";
import Specimen from "@core/components/TestForms/ErosionTest/components/Specimen";
import TestConditions from "@core/components/TestForms/ErosionTest/components/TestConditions";
import {Grid, MenuItem, Typography} from "@mui/material";
import {omit} from "ramda";
import * as yup from "yup";
import ClientField from "../../../../Tests/Test/components/ClientField";
import Dimensions from "./components/Dimensions";
import TestResults from "./components/TestResults";
import {
  TEST_CONDITIONS,
  TEST_STANDARDS,
  DEFAULT_TEST_RESULTS
} from "./constants";
import {Formik} from "formik";
import MuiSelect from "@core/components/MuiSelect";

const ErosionTest = ({test, closeNewTest, saveTest, formRef, client: owner, user, isProducerTest, editable = true}) => {
  const {witnesses, inspectorJobNumber, inspectionDate, properties} = test ?? {};

  const {client,
    laboratory,
    testStandard,
    testResults,
    pressure,
    impingementAngle,
    testConditions,
    acceptanceCriteria,
    thickness,
    weight,
    length,
    surface,
    heatTreatment,
    processingTreatment,
    preparation,
    cleaning,
    initialSurfaceRoughness,
    hardnessMeasurementUnit,
    sizeDistribution,
    shape,
    composition,
    purity,
    source,
    manufacturingMethod,
    averageParticleVelocity,
    methodOfVelocityDetermination,
    particleFlow,
    particleFlux,
    temperatureOfSpecimen,
    temperatureOfParticles,
    temperatureOfCarrierGas,
    testDuration,
    methodOfDeterminingSteadyStateErosionCondition,
    carrierGasComposition,
    measurementMethod,
    methodOfDeterminingTheMassOfAbrasiveUsed,
    tags,
  } = properties ?? {};

  const validationSchema = yup.object().shape({
    client: isProducerTest ? yup.string() : yup.string().required("This field is required"),
    laboratory: isProducerTest ? yup.string() : yup.string().required("This field is required"),
    testStandard: yup.string().required("This field is required"),
    testConditions: yup.string().required("This field is required"),
    thickness: yup.number().min(0).required(),
    weight: yup.number().min(0).required(),
    length: yup.number().min(0).required(),
    surface: yup.number().min(0).required(),
    heatTreatment: yup.string(),
    processingTreatment: yup.string(),
    preparation: yup.string().required(),
    cleaning: yup.string().required(),
    initialSurfaceRoughness: yup.number().min(0).required(),
    acceptanceCriteria: yup.number().min(0),
    hardnessMeasurementUnit: yup.string(),
    sizeDistribution: yup.string(),
    shape: yup.string(),
    composition: yup.string(),
    purity: yup.string(),
    source: yup.string(),
    manufacturingMethod: yup.string(),
    averageParticleVelocity: yup.number().min(0),
    methodOfVelocityDetermination: yup.string(),
    particleFlow: yup.string(),
    particleFlux: yup.string(),
    impingementAngle: yup.number().min(0).required(),
    temperatureOfSpecimen: yup.number().min(0),
    temperatureOfParticles: yup.number().min(0),
    temperatureOfCarrierGas: yup.number().min(0),
    testDuration: yup.number().min(0).required(),
    methodOfDeterminingSteadyStateErosionCondition: yup.string(),
    carrierGasComposition: yup.string(),
    pressure: yup.number().min(0),
    measurementMethod: yup.string(),
    methodOfDeterminingTheMassOfAbrasiveUsed: yup.string(),
    testResults: yup.array().of(yup.object().shape({
      specimen: yup.string().required(),
      hardness: yup.number().min(0).test(
        "hardness",
        "This field is required",
        function (value) {
          const [, parent] = this.from;

          return value || !parent.value.hardnessMeasurementUnit;
        }),
      density: yup.number().min(0).required(),
      massLoss: yup.number().min(0).required(),
      volumeLoss: yup.number().min(0).required(),
      erosionValue: yup.number().min(0).required(),
    })).required(),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    tags: yup.array().of(yup.string()),
  });

  const initialValues = {
    client: !isProducerTest ? client ?? owner.name ?? "" : "",
    laboratory: !isProducerTest ? laboratory ?? user.company.name ?? "" : "",
    testStandard: testStandard ?? TEST_STANDARDS[0],
    testConditions: testConditions ?? "",
    acceptanceCriteria: acceptanceCriteria ?? undefined,
    thickness: thickness ?? undefined,
    weight: weight ?? undefined,
    length: length ?? undefined,
    surface: surface ?? undefined,
    heatTreatment: heatTreatment ?? "",
    processingTreatment: processingTreatment ?? "",
    preparation: preparation ?? "",
    cleaning: cleaning ?? "",
    initialSurfaceRoughness: initialSurfaceRoughness ?? undefined,
    hardnessMeasurementUnit: hardnessMeasurementUnit ?? "",
    sizeDistribution: sizeDistribution ?? "",
    shape: shape ?? "",
    composition: composition ?? "",
    purity: purity ?? "",
    source: source ?? "",
    manufacturingMethod: manufacturingMethod ?? "",
    averageParticleVelocity: averageParticleVelocity ?? undefined,
    methodOfVelocityDetermination: methodOfVelocityDetermination ?? "",
    particleFlow: particleFlow ?? "",
    particleFlux: particleFlux ?? "",
    impingementAngle: impingementAngle ?? undefined,
    temperatureOfSpecimen: temperatureOfSpecimen ?? undefined,
    temperatureOfParticles: temperatureOfParticles ?? undefined,
    temperatureOfCarrierGas: temperatureOfCarrierGas ?? undefined,
    testDuration: testDuration ?? undefined,
    methodOfDeterminingSteadyStateErosionCondition: methodOfDeterminingSteadyStateErosionCondition ?? "",
    carrierGasComposition: carrierGasComposition ?? "",
    pressure: pressure ?? undefined,
    measurementMethod: measurementMethod ?? "",
    methodOfDeterminingTheMassOfAbrasiveUsed: methodOfDeterminingTheMassOfAbrasiveUsed ?? "",
    testResults: testResults ?? DEFAULT_TEST_RESULTS,
    witnesses: witnesses.map((witness) => witness.company) ?? [],
    inspectionDate: inspectionDate ?? "",
    inspectorJobNumber: inspectorJobNumber ?? "",
    tags: tags ?? []
  };

  const getResult = ({acceptanceCriteria, testResults}) => {
    const isAccepted = testResults.every((value) => getIsWithinRequirements(value.erosionValue, acceptanceCriteria));

    return isAccepted ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
  };

  const onSubmit = (values, actions) => {
    values.result = getResult(values);

    const formData = omit(["witnesses"], values);

    saveTest(formData, {witnesses: values.witnesses});
    actions.setSubmitting(false);

    closeNewTest && closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({values}) => {
        return (
          <>
            <Grid container spacing={3} sx={{maxWidth: "1200px"}}>
              {editable && (
                <Grid item container spacing={3}>
                  {!isProducerTest && (
                    <>
                      <Grid item xs={3}>
                        <ClientField
                          isFromProducer={!!owner.name}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          disabled
                          name="laboratory"
                          label="Laboratory"
                          required
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={3}>
                    <MuiSelect
                      required
                      name="testStandard"
                      defaultOptions={TEST_STANDARDS}
                      label="Test standard"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectField
                      name="testConditions"
                      label='Test conditions'
                      required
                    >
                      {TEST_CONDITIONS.map((value) => (
                        <MenuItem key={value} value={value}>{value}</MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" marginBottom={1}>Test Parameters</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Specimen
                          editable={editable}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TestConditions
                          editable={editable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Dimensions
                          editable={editable}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ErodingParticle
                          editable={editable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" marginBottom={1}>Test Results</Typography>
                <TestResults
                  editable={editable}
                />
              </Grid>
            </Grid>
            <Footer
              editable={editable}
              isProducerTest={isProducerTest}
              saveTest={saveTest}
              result={getResult(values)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ErosionTest;