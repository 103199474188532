import React, {useMemo, useState} from "react";
import {Box, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, useTheme} from "@mui/material";
import File from "@core/components/File";
import TableFooter from "@core/components/TableFooter";

const RawMaterialsTable = ({rawMaterials = [], id, label, type}) => {
  const theme = useTheme();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const idRawMaterials = useMemo(() => {
    const idRawMaterials = rawMaterials.filter((material) => material.pipes.includes(id));

    if(idRawMaterials.length) return idRawMaterials;

    return rawMaterials;
  }, [rawMaterials, id]);

  const rawMaterialsPaginated = useMemo(() => idRawMaterials.slice(offset, offset + limit), [idRawMaterials, offset, limit]);

  if(!idRawMaterials.length) return null;

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{marginBottom: theme.spacing()}}>
          <h3>{label}</h3>
        </Box>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>{type}</TableCell>
              <TableCell>Batch Number</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Trade Name</TableCell>
              <TableCell>File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rawMaterialsPaginated.map((rawMaterial) => (
              <TableRow>
                <TableCell>{id}</TableCell>
                <TableCell>{rawMaterial.batchNumber}</TableCell>
                <TableCell>{rawMaterial.type}</TableCell>
                <TableCell>{rawMaterial.tradeName}</TableCell>
                <TableCell>
                  {rawMaterial.file?.dir && rawMaterial.file?.name ? (
                    <File
                      preview
                      file={rawMaterial.file.dir + rawMaterial.file.name}
                    />
                  ) : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter
            isLoaded
            items={rawMaterialsPaginated}
            total={rawMaterials.length}
            limit={limit}
            offset={offset}
            onOffsetChange={setOffset}
            onLimitChange={setLimit}
          />
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};

export default RawMaterialsTable;