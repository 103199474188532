import React from "react";
import {AvatarGroup} from "@mui/material";
import CompanyAvatar from "./components/CompanyAvatar";

const CompanyLogos = ({companies}) => {
  return (
    <AvatarGroup
      sx={{width: "fit-content"}}
    >
      {companies.map((company) => {
        if(!company) return null;

        return (
          <CompanyAvatar
            company={company}
          />
        );
      })}
    </AvatarGroup>
  );
};

export default CompanyLogos;