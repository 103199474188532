export default (theme) => ({
  notAcceptable: {
    color: theme.palette.error.main,
  },
  acceptable: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  clickableRow: {
    cursor: "pointer"
  },
});
