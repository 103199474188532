import React, {useEffect, useMemo} from "react";
import TestFooter from "@core/components/LabTestFooter";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import {Grid, MenuItem} from "@mui/material";
import MuiSelect from "@core/components/MuiSelect";
import {Formik} from "formik";
import * as yup from "yup";
import {TEST_FORM_BY_TEST_STANDARD, TEST_STANDARDS} from "./constants";
import {TEST_RESULTS} from "@core/constants/testResults";

const validationSchema = yup.object().shape({
  norm: yup.string().required("This field is required!"),
  grade: yup.string().required("This field is required!"),
  testStandard: yup.string().required("This field is required!"),
  weighingLiquid: yup.string().required("This field is required!"),
  waterTemperature: yup.number().min(0).required("This field is required!"),
  wireDiameter: yup.number().min(0),
  acceptanceCriteria: yup.number().min(0),
  result: yup.string().required("This field is required!"),
  notes: yup.string(),

  massOfItemInAir: yup.number().min(0).required("This field is required!"),
  densityOfWater: yup.number().min(0).required("This field is required!"),
  density: yup.number().min(0).required("This field is required!"),

  // ASTM B311
  massOfSupportInWater: yup.number().min(0),
  massOfItemInWater: yup.number().min(0),
  massOfItemAndSupportInWater: yup.number().min(0),

  // ASTM B962
  densityType: yup.string().when("testStandard", {
    is: "ASTM B962-23",
    then: (schema) => schema.required("This field is required"),
  }),

  oilViscosity: yup.number().min(0),
  partImpregnated: yup.bool(),
  impregnationMethod: yup.string().when("partImpregnated", {
    is: true,
    then: (schema) => schema.required("This field is required"),
  }),
  massOfOilImpregnatedPart: yup.number().min(0),
  massOfOilImpregnatedSupportPart: yup.number().min(0),
  massOfOilImpregnatedSupportInWater: yup.number().min(0),
  massOfOilImpregnatedPartInWater: yup.number().min(0),
});

const DensityTest = ({test, saveTest, formRef, certificate}) => {

  const {norms} = useFetchedCertificateNorms();

  const {
    norm,
    grade,
    testStandard,
    weighingLiquid,
    waterTemperature,
    wireDiameter,
    acceptanceCriteria,
    massOfItemInAir,
    massOfItemAndSupportInWater,
    massOfSupportInWater,
    massOfItemInWater,
    densityOfWater,
    density,
    densityType,
    oilViscosity,
    partImpregnated,
    impregnationMethod,
    massOfOilImpregnatedPart,
    massOfOilImpregnatedSupportPart,
    massOfOilImpregnatedSupportInWater,
    massOfOilImpregnatedPartInWater,
    result,
    notes,
  } = test?.properties ?? {};

  const initialValues = {
    norm: norm ?? test.norm ?? certificate?.properties?.norm ?? "",
    grade: grade ?? test.grade ?? certificate?.properties?.grade ?? "",
    testStandard: testStandard ?? "",
    weighingLiquid: weighingLiquid ?? "",
    waterTemperature: waterTemperature ?? "",
    wireDiameter: wireDiameter ?? "",
    acceptanceCriteria: acceptanceCriteria ?? "",
    result: result ?? "",
    notes: notes ?? "",

    massOfItemInAir: massOfItemInAir ?? "",
    densityOfWater: densityOfWater ?? "",
    density: density ?? "",

    // ASTM B311
    massOfSupportInWater: massOfSupportInWater ?? "",
    massOfItemInWater: massOfItemInWater ?? "",
    massOfItemAndSupportInWater: massOfItemAndSupportInWater ?? "",

    // ASTM B962
    densityType: densityType ?? "",
    oilViscosity: oilViscosity ?? "",
    partImpregnated: partImpregnated ?? "",
    impregnationMethod: impregnationMethod ?? "",
    massOfOilImpregnatedPart: massOfOilImpregnatedPart ?? "",
    massOfOilImpregnatedSupportPart: massOfOilImpregnatedSupportPart ?? "",
    massOfOilImpregnatedSupportInWater: massOfOilImpregnatedSupportInWater ?? "",
    massOfOilImpregnatedPartInWater: massOfOilImpregnatedPartInWater ?? ""
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={saveTest}
      validationSchema={validationSchema}
    >
      {({setFieldValue, values}) => {
        const grades = useMemo(() => norms.filter((norm) => norm.Norm === values.norm), [values.norm, norms.length]);

        useEffect(() => {
          const isAccepted = !values.acceptanceCriteria || Number(values.density) >= Number(values.acceptanceCriteria);

          const result = isAccepted ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

          setFieldValue("result", result);
        }, [values.acceptanceCriteria, values.density]);

        return (
          <Grid container spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={3}>
                <NormAutocomplete
                  disabled={test.norm ?? certificate?.properties?.norm}
                  label="Material specification"
                  name="norm"
                  testType="certificate"
                  onChange={({norm}) => setFieldValue("norm", norm)}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  disabled={test.grade ?? certificate?.properties?.grade}
                  name="grade"
                  label="Grade / UNS"
                  required
                >
                  {grades.map((grade) => (
                    <MenuItem key={grade.Material} value={grade.Material}>{grade.Material}</MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={3}>
                <MuiSelect
                  required
                  name="testStandard"
                  defaultOptions={TEST_STANDARDS}
                  label="Test standard"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  name="weighingLiquid"
                  label="Weighing Liquid"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="waterTemperature"
                  label="Water temperature"
                  endAdornment="°C"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="wireDiameter"
                  label="Wire diameter"
                  endAdornment="mm"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  name="acceptanceCriteria"
                  label="Acceptance criteria"
                  endAdornment="g/cm^3"
                />
              </Grid>
            </Grid>
            {TEST_FORM_BY_TEST_STANDARD[values.testStandard]}
            <Grid item xs={6}>
              <TextField
                rows={4}
                multiline
                name="notes"
                label="Notes"
              />
            </Grid>
            <Grid item xs={12}>
              <TestFooter
                onSubmit={saveTest}
                result={values.result}
              />
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default DensityTest;