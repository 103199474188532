import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {isNil, trim} from "ramda";
import classNames from "classnames";
import {IconButton, OutlinedInput, TableCell, Tooltip} from "@mui/material";
import {BiEdit} from "react-icons/bi";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import CheckIcon from "@mui/icons-material/Check";
import {TEST_RESULTS} from "@core/constants/testResults";
import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";
import Indication from "../Indication";
import {IoMdDownload} from "react-icons/io";
import {downloadFile, getFileData} from "@core/helpers";
import useStores from "../../../../../../useStores";

const ENTER_KEY_CODE = 13;

const FILE_NAME_ACTIVITY = "FileName";

const ResultCell = observer(({classes, onSave, disabled, activity, element}) => {
  const {CampaignStore} = useStores();

  const {indicationCount, values: results = []} = element.results || {};

  const [editable, setIsEditable] = useState(false);
  const [value, setValue] = useState(results.map((res) => trim(res.value?.toString())).join(", "));
  const [openIndication, setOpenIndication] = useState(null);

  const campaign = CampaignStore.campaign;

  const fileToDownload = element.description === FILE_NAME_ACTIVITY && campaign.attachments?.find((upload) => {
    const {name, extension} = getFileData(upload.file.name);
    const fileName = name + extension;

    return results.some(({value}) => value === fileName);
  });

  const onSaveChanges = () => {
    const values = value.replace(" ", "").split(",");
    const defaultConfig = activity.results.values[0];

    const resultValues = values.map((value, index) => {
      const acceptanceCriteria = activity.results.values[index]?.acceptanceCriteria || [];

      const isAcceptable = !acceptanceCriteria.length || acceptanceCriteria.every((ac) => {
        const {value: range} = activity.acceptanceCriteria.find((tAc) => tAc.name === ac) || {};

        return !range || ((!range.min || value >= range.min) && (!range.max || value <= range.max));
      });

      const valueConfig = activity.results.values[index];
      const config = valueConfig || defaultConfig;

      return {
        ...config,
        result: isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE,
        value,
      };
    });

    const testAcceptable = resultValues.every((value) => value.result === TEST_RESULTS.ACCEPTABLE);

    const results = {
      result: testAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE,
      values: resultValues,
    };

    onSave(results);

    setIsEditable(false);
  };

  const onKeyPress = (e) => {
    if(e.keyCode !== ENTER_KEY_CODE || !value) return;

    onSaveChanges();
  };
  
  const download = () => {
    if(!fileToDownload) return null;
    
    downloadFile(fileToDownload.file);
  };

  if(!element.results) {
    return <TableCell>-</TableCell>;
  }
    
  if(!isNil(indicationCount)) {
    return (
      <TableCell>
        {indicationCount ? (
          <DrawerButtonTrigger
            onClick={() => setOpenIndication(element)}
            text={indicationCount}
          />
        ) : indicationCount}
        <Indication
          open={!!openIndication}
          close={() => setOpenIndication(null)}
          indication={openIndication}
        />
      </TableCell>
    );
  }

  return (
    <TableCell>
      <div className={classes.resultContainer}>
        {editable ? (
          <>
            <OutlinedInput
              classes={{input: classes.input, root: classes.inputRoot}}
              margin="none"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={onKeyPress}
            />
            {value && (
              <Tooltip title="Save changes">
                <IconButton
                  classes={{root: classes.icon}}
                  onClick={onSaveChanges}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            <div
              onClick={download}
            >
              {results.map((result, index) => {
                const isLast = index + 1 === results.length;

                const isAcceptable = result.result === TEST_RESULTS.ACCEPTABLE;

                return (
                  <span key={index}>
                    <span
                      className={classNames({
                        [classes.acceptable]: !element.isMetadata && isAcceptable,
                        [classes.notAcceptable]: !element.isMetadata && !isAcceptable,
                        [classes.link]: !!fileToDownload,
                      })}
                    >
                      {result.value}
                    </span>
                    {isLast ? (<span>{result.unit}</span>) : (<span>,&nbsp;</span>)}
                  </span>
                );
              })}
            </div>
            {(!disabled && !fileToDownload) && (
              <Tooltip title="Edit">
                <IconButton
                  classes={{root: classes.icon}}
                  onClick={() => setIsEditable(true)}
                >
                  <BiEdit />
                </IconButton>
              </Tooltip>
            )}
            {fileToDownload && (
              <Tooltip title="Download">
                <IconButton onClick={download}>
                  <IoMdDownload />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </TableCell>
  );
});

export default withStyles(ResultCell, styles);