import React from "react";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const FlatteningTest = ({classes, test}) => {
  return (
    <Grid container spacing={1}>
      <Grid item container alignContent="space-between" spacing={5}>
        {(test.company?.name || test.properties.client) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Client</h4>
            <p className={classes.value}>{test.company?.name || test.properties.client}</p>
          </Grid>
        )}
        {(test.assignee?.name || test.properties.lab) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Laboratory</h4>
            <p className={classes.value}>{test.assignee?.name || test.properties.lab}</p>
          </Grid>
        )}
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        <Grid item xs={3}>
          <h4 className={classes.name}>Test standard</h4>
          <p className={classes.value}>{test.properties.testStandard} {test.properties.reverseFlattening && "(Reverse flattening)"}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Acceptance criteria</h4>
          <p className={classes.value}>{test.properties.acceptance}</p>
        </Grid>
        <Grid item xs={3}>
          <h4 className={classes.name}>Outside diameter</h4>
          <p className={classes.value}>{test.properties.outsideDiameter} mm</p>
        </Grid>
        {test.properties.internalDiameter && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Internal diameter</h4>
            <p className={classes.value}>{test.properties.internalDiameter} mm</p>
          </Grid>
        )}
        <Grid item xs={3}>
          <h4 className={classes.name}>Thickness</h4>
          <p className={classes.value}>{test.properties.thickness} mm</p>
        </Grid>
        {test.properties.deformationPerUnitLength && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Deformation per unit length</h4>
            <p className={classes.value}>{test.properties.deformationPerUnitLength}</p>
          </Grid>
        )}
        {test.properties.firstStep && (
          <Grid item xs={3}>
            <h4 className={classes.name}>First step</h4>
            <p className={classes.value}>{test.properties.firstStep} mm</p>
          </Grid>
        )}
        {test.properties.secondStep && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Second step</h4>
            <p className={classes.value}>{test.properties.secondStep}</p>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell padding="none">Spec. ID</TableCell>
              <TableCell padding="none">Specimen Length [mm]</TableCell>
              <TableCell padding="none">Weld position</TableCell>
              <TableCell padding="none">Final distance of plates [mm]</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {test.properties.elements.map((element) => (
              <TableRow>
                <TableCell padding="none">{element.specimenId}</TableCell>
                <TableCell padding="none">{element.specimenLength}</TableCell>
                <TableCell padding="none">{element.weldPosition}</TableCell>
                <TableCell padding="none">{element.finalDistanceOfPlates}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(FlatteningTest, styles);
