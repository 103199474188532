import React, {useState, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useTheme} from "@mui/material/styles";
import {Box, Table, TableBody, TableHead, TableRow, TableCell} from "@mui/material";
import ResizableDrawer from "@core/components/ResizableDrawer";
import File from "@core/components/File";
import {CAMPAIGN_SPECIMEN_LABELS, CAMPAIGN_TYPES} from "@core/constants/campaign";
import TableFooter from "@core/components/TableFooter";
import DrawerHeader from "@core/components/DrawerHeader";
import useStores from "../../../../../useStores";
import SortableCell from "@core/components/SortableCell";
import {ORDERS} from "@core/constants/sort";
import {sortStrings} from "@core/helpers";

const PipesDrawer = ({open, close, material}) => {
  const {CampaignStore} = useStores();
  const theme = useTheme();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState(ORDERS.ASC);

  const campaign = CampaignStore.campaign;

  const pipes = useMemo(() => material ? material.pipes.split(" ") : [], [material]);
  const sorted = useMemo(() => sortStrings(order, pipes), [pipes, order]);
  const pipesPaginated = useMemo(() => sorted.slice(offset, offset + limit), [sorted, offset, limit]);

  return (
    <ResizableDrawer
      open={open}
      close={close}
      keepMounted={false}
    >
      <DrawerHeader
        close={close}
        text={`Batch number: ${material?.batchNumber}`}
      />
      <Box
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <SortableCell
                id="specimen"
                setOrder={setOrder}
                order={order}
                orderBy="specimen"
              >
                {campaign.type === CAMPAIGN_TYPES.WELDING ? CAMPAIGN_SPECIMEN_LABELS.WELD : CAMPAIGN_SPECIMEN_LABELS.PIPE}
              </SortableCell>
              <TableCell>Batch Number</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Trade Name</TableCell>
              <TableCell>File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pipesPaginated.map((pipe) => (
              <TableRow>
                <TableCell>{pipe}</TableCell>
                <TableCell>{material.batchNumber}</TableCell>
                <TableCell>{material.type}</TableCell>
                <TableCell>{material.tradeName}</TableCell>
                <TableCell>
                  {material.file.dir && material.file.name ? (
                    <File
                      preview
                      file={material.file.dir + material.file.name}
                    />
                  ) : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter
            isLoaded
            items={pipesPaginated}
            total={pipes.length}
            limit={limit}
            offset={offset}
            onOffsetChange={setOffset}
            onLimitChange={setLimit}
          />
        </Table>
      </Box>
    </ResizableDrawer>
  );
};

export default observer(PipesDrawer);