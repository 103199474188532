import React, {useEffect} from "react";
import {NavLink, Route} from "react-router-dom";
import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {keys, intersection} from "ramda";
import {Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import roles from "../../constants/roles";
import {MdKeyboardArrowRight} from "react-icons/md/";
import menuData from "./MenuData";
import styles from "./styles";
import modules from "@core/constants/modules";
import useStores from "../../../useStores";
import {MenuStore} from "../../../configure-store";

const Menu = observer(({isCollapsed, classes}) => {
  const {UserStore} = useStores();
  const user = UserStore.user.data;
  const enabledFeatures = keys(UserStore.features);

  useEffect(() => {
    const handleWindowResize = () => {
      const width = window.innerWidth;

      if (width < 1200){
        MenuStore.changeCollapsed(true);
      }else{
        MenuStore.changeCollapsed(false);
      }
    };
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const getContent = () => {
    const userModule = user.company.modules[0].name;
    const userRole = user.role;
    const isProLabel = (label) => userModule === modules.BASIC && label === "Specifications";

    const enabledMenuData = menuData.filter((menuItem) => !menuItem.requiredFeatures || intersection(menuItem.requiredFeatures, enabledFeatures).length);

    const appMenu = enabledMenuData.map((menuItem, index) => {
      if (menuItem.belongsToModules.includes(userModule) || menuItem.belongsToModules[0] === "All") {
        let exact = true;
        const escapedPathParent = menuItem.linkTo && menuItem.linkTo.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");

        const children = menuItem.children
          .filter((subItem) =>
            subItem.belongsToModules.includes(userModule) &&
            (!subItem.belongsToRole || subItem.belongsToRole.includes(userRole) || subItem.belongsToRole[0] === "All") &&
            (!subItem.requiredFeatures || intersection(subItem.requiredFeatures, enabledFeatures).length))
          .map((subItem, idx) => {
            return (
              <li key={`sub${idx}`}>
                <NavLink exact={subItem.exact} to={subItem.linkTo}>
                  <span className={classes.menuIcon}> {subItem.icon} </span>
                  <span>{subItem.title}</span>
                  <span className={classes.menuArrow}><MdKeyboardArrowRight /></span>
                </NavLink>
              </li>
            );
          });

        if (children) {
          exact = false;
        }

        if (menuItem.title === "Dashboard") {
          exact = true;
        }

        const content = <Route key={index}
          path={escapedPathParent}
          exact={exact}
          children={({
            match
          }) => {
            const btnClass = classNames({
              btn: true,
              [classes.openListItem]: children && match !== null,
              [classes.activeListItem]: match !== null,
            });

            return (
              <Tooltip
                disableFocusListener
                classes={{
                  tooltip: classes.popoverContent,
                }}
                disableHoverListener={!isCollapsed || !children.length}
                title={(
                  <nav className={classes.menu}>
                    <ul className={classNames(classes.list, classes.openListItem)}>
                      {children}
                    </ul>
                  </nav>
                )}
                placement="right-start"
              >
                <li key={index} className={btnClass}>
                  <Tooltip
                    disableHoverListener={!isCollapsed}
                    classes={{tooltip: classes.tooltip, tooltipPlacementBottom: classes.tooltipPlacementBottom}}
                    title={isCollapsed ? menuItem.title : ""}
                    placement="bottom-end"
                  >
                    <NavLink
                      exact={exact}
                      to={menuItem.linkTo}
                    >
                      <span className={classes.menuIcon}> {menuItem.icon} </span>
                      <span>{menuItem.title}{isProLabel(menuItem.title) && <sup className={classes.proLabel}>Pro</sup>}</span>
                      <span className={classNames(classes.menuArrow, {
                        [classes.rotatedMenuArrow]: children && match !== null
                      })}>
                        <MdKeyboardArrowRight />
                      </span>
                    </NavLink>
                  </Tooltip>
                  <ul className={classes.innerList}>
                    {children}
                  </ul>
                </li>
              </Tooltip>
            );
          }} />;

        if (menuItem.title === "Admin") {
          if (userRole === roles.ADMIN || userRole === roles.SUPER_ADMIN) {
            return content;
          }

          return null;
        }

        return content;
      }
    });

    return (
      <nav className={classes.menu}>
        <ul className={classes.list}>
          {appMenu}
        </ul>
      </nav>
    );
  };

  return getContent();
});

export default withStyles(Menu, styles);
