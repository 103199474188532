import React from "react";
import {withStyles} from "tss-react/mui";

const Picker = (props) => {
  const countColumns = 6;
  const rows = Array(Math.ceil(props.elements.length / countColumns)).fill();

  return (
    <table className={props.classes.picker}>
      <tbody>
        {rows.map((nil, idx) => {
          const from = idx * countColumns;
          const to = idx * countColumns + countColumns;

          const tableData = props.elements.slice(from, to);

          return (
            <tr key={idx}>
              {tableData.map((T) => <td
                className={`
                ${props.classes.cell}
                ${T.checked && props.classes.cellChecked}
                ${T.active && props.classes.cellActive}
              `}

                key={T.position}

                onClick={() => {
                  if(T.checked)
                    return;

                  props.pickPosition(T);
                }}
              >{T.position}</td>) }
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const styles = (theme) => ({
  picker: {
    backgroundColor: "#fff",
    borderCollapse: "collapse",
  },

  cell: {
    width: 38,
    height: 38,
    color: "#575757",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
    cursor: "pointer",
    emptyCells: "hide",
    border: "1px solid #ccc",

    "&:hover": {
      backgroundColor: "rgba(35, 150, 243, 0.25)",
      borderColor: "rgba(35, 150, 243, 0.50)",
    }
  },
  cellActive: {
    backgroundColor: "#2396f399",
    borderColor: "rgba(35, 150, 243, 0.50)",
  },

  cellChecked: {
    background: "rgba(0, 0, 0, 0.12)",
    border: "1px solid #ccc",
    color: "rgba(0, 0, 0, 0.26)",
    boxShadow: "none",
    cursor: "default"
  }
});

export default withStyles(Picker, styles);