export default (theme) => ({
  value: {
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginLeft: 5,
      fontSize: 16,
      cursor: "pointer",
    },
    "& span": {
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  button: {
    padding: 0,
    paddingRight: 4,
    paddingLeft: 4,
    marginTop: 5,
    display: "block",
  }
});