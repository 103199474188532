import React, {useEffect, useMemo} from "react";
import {times, omit, mergeDeepRight} from "ramda";
import {Table, TableHead, TableBody, TableRow, TableCell, Button} from "@mui/material";
import {FieldArray, useFormikContext} from "formik";
import Certificate from "../Certificate";
import {MdAdd} from "react-icons/md";

const SPECIFIC_FIELDS = {
  heat: "",
  properties: {
    lotId: "",
  },
  lineItem: ""
};

const CertificatesList = () => {
  const {values, setFieldValue} = useFormikContext();

  const commonFields = omit(["certificates", "heats", "lots"], values);
  const fieldsToUpdate =  omit(["items"], commonFields);

  useEffect(() => {
    const currentNumber = values.certificates.length;
    const newNumber = Number(values.heats * values.lots) || 0;

    const missingCertificates = newNumber > currentNumber ?
      times(() => mergeDeepRight(commonFields, SPECIFIC_FIELDS), newNumber - currentNumber) :
      [];
    const newCertificates = values.certificates.concat(missingCertificates);

    setFieldValue("certificates", newCertificates.slice(0, newNumber));
  }, [values.heats, values.lots]);

  useEffect(() => {
    const newCertificates = values.certificates.map((certificate) => mergeDeepRight(certificate, fieldsToUpdate));
    setFieldValue("certificates", newCertificates);
  }, Object.values(fieldsToUpdate));

  const {items = []} = commonFields;

  const quantity = useMemo(() => items.reduce((quantity, item) => Number(item.quantity) + quantity, 0), [items]);
  
  const withOrder = useMemo(() => values.certificates.some((certificate) => certificate.transferId), [values.certificates]);

  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell width="10%">#</TableCell>
          <TableCell width="15%">Heat</TableCell>
          <TableCell width="15%">Lot ID</TableCell>
          <TableCell width="15%">Work Order</TableCell>
          <TableCell width="15%">Work Order Item</TableCell>
          <TableCell width="15%">PO Number</TableCell>
          {withOrder && (
            <TableCell width="15%">PO Item</TableCell>
          )}
          <TableCell>Est Quantity</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.certificates.length ? (
          <>
            <FieldArray name="certificates">
              {({remove}) => values.certificates.map((_, index) => (
                <Certificate
                  key={index}
                  index={index}
                  quantity={quantity}
                  withOrder={withOrder}
                  remove={remove}
                />
              ))}
            </FieldArray>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => setFieldValue("certificates", [...values.certificates, mergeDeepRight(commonFields, SPECIFIC_FIELDS)])}
                >
                  <MdAdd size={80} />Add new
                </Button>
              </TableCell>
            </TableRow>
          </>
        ) : (
          <TableRow>
            <TableCell colSpan={11}>No data.</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default CertificatesList;