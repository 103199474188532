import React from "react";
import {observer} from "mobx-react-lite";
import {any, propEq} from "ramda";
import {MultipleSelect} from "@core/components/Form";
import modulesConstants from "@core/constants/modules";
import useStores from "../../../../../useStores";

const AddWitnesses = ({setSelectedWitnesses, selectedWitnesses}) => {

  const {CompanyStore} = useStores();

  const witnessCompanies = CompanyStore.meta.filter((company) => any(propEq(modulesConstants.WITNESS, "name"), company.modules));

  const handleSelectWitnesses = (witnesses) => {
    setSelectedWitnesses(witnesses);
  };

  return (
    <MultipleSelect
      label='Witnesses (Optional)'
      value={selectedWitnesses.map((c) => c.name)}
      elements={witnessCompanies.map((c) => c.name)}
      onChange={(values) => {
        const witnesses = witnessCompanies.filter((c) => values.includes(c.name));
        handleSelectWitnesses(witnesses);
      }}
    />
  );
};

export default observer(AddWitnesses);
