import {Button, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import React from "react";
import styles from "./styles";

const AttestSection = (props) => {
  const {test, witnessTest, classes} = props;

  return (
    <Grid container
      className={classes.attestSectionContainer}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item xs={12}>
        <h4 className={classes.attestTitle}>ATTEST SECTION</h4>
      </Grid>
      <Grid item xs={4}>
        <Button variant="contained" className={classes.approve} onClick={() => witnessTest(1, [test])}>Approve</Button>
      </Grid>
      <Grid item xs={4}>
        <Button variant="contained" className={classes.decline} onClick={() => witnessTest(2, [test])}>Decline</Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(AttestSection, styles);