import React from "react";
import {Box, TableCell, useTheme} from "@mui/material";

const ValueCell = ({children, isAcceptable}) => {
  const theme = useTheme();

  return (
    <TableCell
      align="center"
      padding="none"
    >
      <Box sx={{color: isAcceptable ? theme.palette.success.main : theme.palette.error.main}}>
        {children}
      </Box>
    </TableCell>
  );
};

export default ValueCell;