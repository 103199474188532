import React from "react";
import {Typography} from "@mui/material";
import ExternalUserForm from "../components/Form";
import ExternalUsersTable from "./components/ExternalUsersTable";
import useStores from "../../useStores";

const ExternalUsers = () => {
  const {ExternalUsersStore, NotificationStore} = useStores();

  const createExternalUser = async (values, {resetForm}) => {
    await ExternalUsersStore.createExternalUser(values);
    resetForm();
    NotificationStore.showSuccess("Successfully added!");
  };

  return (
    <div className="content padded-container">
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        External Users
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        gutterBottom
      >
        Add
      </Typography>
      <ExternalUserForm
        onSubmit={createExternalUser}
      />
      <ExternalUsersTable />
    </div>
  );
};

export default ExternalUsers;