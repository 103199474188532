import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import useStores from "../../../../../../useStores";
import {observer} from "mobx-react";
import {FILTERS} from "@core/constants/filters";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

const FilterButton = ({toggleFilters}) => {
  const {FilterStore} = useStores();
  const {filters} = FilterStore;
  const [filtersSelected, setFiltersSelected] = useState(false);
  const dependencies = Object.values(FILTERS).map((filterGroup) => filters[filterGroup]);

  useEffect(() => {
    const hasFilters = dependencies.some((item) => item !== undefined);
    setFiltersSelected(hasFilters);
  }, [...dependencies]);

  return (
    <Button
      onClick={toggleFilters}
      size="small"
      sx={{
        color: (theme) => !filtersSelected ? theme.palette.text.primary : undefined
      }}
    >
      <IconistsIcon
        icon={filtersSelected ? ICONISTS_ICONS.filterFilled : ICONISTS_ICONS.filter}
      />
      &nbsp;
      Filter
    </Button>
  );
};

export default observer(FilterButton);