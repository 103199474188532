import * as yup from "yup";
import {map} from "ramda";

export const initialValues = {
  heat: "",
  certificateType: "",
  markingFiles: [],
  markingText: "",
  markingNotes: "",
  properties: {
    productType: "",
    norm: "",
    lotId: "",
    grade: "",
  },
  items: [],
  internalItemNumber: "",
  internalWorkOrder: "",
  location: "",
  transferId: "",
  projectId: "",
  lineItem: "",
  deliveryCondition: "",
  tags: [],
};

export const KAR85_SPECIFICATION = "KAR85-AM-K";

export const validationSchema = yup.object().shape({
  heat: yup.string().required("Heat is required!"),
  certificateType: yup.string().required("Certificate type is required!"),
  markingFiles: yup.array().of(yup.string()),
  markingText: yup.string(),
  markingNotes: yup.string(),
  properties: yup.object().shape({
    productType: yup.object().required("Product type is required!"),
    norm: yup.string().required("Material specification is required"),
    lotId: yup.string().when("productType", {
      is: (productType) => {
        return productType?.name && (productType.name.toLowerCase() === "heat");
      },
      then: yup.string(),
      otherwise: yup.string().required("Lot ID is required!"),
    }),
    grade: yup.string().required("Grade / UNS is required!"),
  }),
  internalItemNumber: yup.string(),
  internalWorkOrder: yup.string(),
  transferId: yup.string(),
  projectId: yup.string(),
  lineItem: yup.string(),
  deliveryCondition: yup.string().nullable(true),
  items: yup.array().of(yup.lazy((item) =>
    yup.object().shape({
      ...map(() => yup.string().required("Property is required"), item),
      quantity: yup.number().min(1, "Should be \u2265 1").required("Property is required"),
    }))).required(),
  tags: yup.array().of(yup.string()),
});