import React, {useEffect, useState} from "react";
import axios from "axios";
import VerificationForm from "../components/VerificationForm";
import LoginPage from "../components/LoginContainer";
import useQuery from "../../core/hooks/useQuery";

const ProviderLoginHandler = () => {
  const query = useQuery();
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.defaults.headers.common["X-Token-Jwt"] = query.get("accessToken");
    setUser(JSON.parse(atob(query.get("user"))));
  }, [query]);

  return(
    <>
      {user ? (
        <LoginPage>
          <VerificationForm user={user} />
        </LoginPage>) :
        <div />}
    </>
  );
};

export default ProviderLoginHandler;
