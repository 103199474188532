import React from "react";
import {Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {AiOutlineWarning} from "react-icons/ai";
import ErrorIcon from "@mui/icons-material/Error";
import {Input} from "@core/components/Form";
import styles from "./styles";

const MaxMinInput = ({classes, className, value, error, warning, warningMessage, errorMessage, ...props}) => {

  return (
    <div className={classes.container}>
      <Input
        key={value}
        classes={{input: className}}
        type="number"
        value={value}
        warning={warning}
        error={error}
        {...props}
      />
      {!!(warningMessage && value && warning) && (
        <Tooltip title={warningMessage}>
          <span>
            <AiOutlineWarning className={classes.warningIcon} />
          </span>
        </Tooltip>
      )}
      {!!(errorMessage && value && error) && (
        <Tooltip title={errorMessage}>
          <span>
            <ErrorIcon className={classes.errorIcon} />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default withStyles(MaxMinInput, styles);