export default (theme) => ({
  main: {
    padding: theme.spacing(4),
    overflowX: "hidden"
  },
  topSection: {
    padding: "30px 0 0"
  },
  test: {
    marginTop: theme.spacing(6),
  },
  testFirst: {
    marginTop: theme.spacing(2)
  }
});