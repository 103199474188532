import React, {useEffect, useState} from "react";
import {Link} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import {withStyles} from "tss-react/mui";
import {api} from "Config";
import {base64ToBlob, isImage} from "@core/helpers";
import {ICONS} from "@core/constants/iconByFileExtension";
import styles from "./styles";
import ImageWithZoom from "@core/components/ImageWithZoom";

const File = ({base, file, preview, linkName, classes}) => {
  const [blob, setBlob] = useState(null);

  const extension = file ? file.split(".").pop() : "";
  const name = file ? file.split("/")[2] : "";

  const src = ICONS[extension] || "/images/files/file.svg";

  useEffect(() => {
    axios.get(`${base}/uploads/download${file}`).then((res) => setBlob(base64ToBlob(res.data)));
  }, [file]);

  const openInNewTab = (e) => {
    e.stopPropagation();

    const fileURL = URL.createObjectURL(blob);
    const fileWindow = window.open();
    fileWindow.location.href = fileURL;
  };

  if (preview && isImage(file)) {
    return (
      <ImageWithZoom
        src={file}
        alt="download file"
      />
    );
  }

  if(linkName) {
    return (
      <Link
        underline="hover"
        onClick={openInNewTab}
      >
        {linkName}
      </Link>
    );
  }
  
  return (
    <a
      onClick={openInNewTab}
      target="_blank"
      className={classNames(classes.icon, {[classes.container]: preview})}
    >
      <img height="40" src={src} alt="Download file" />
      {preview && <p>{name}</p>}
    </a>
  );
};

File.defaultProps = {
  base: api
};

export default withStyles(File, styles);
