import themeSettings from "../../../../../../../../theme-settings";

export const TIE_IN_WELD_CONFIG = {
  BACKGROUND_COLOR: themeSettings.palette.primary.main,
};

export const LINE_WELD_CONFIG = {
  POINTER_HEIGHT: 10,
  POINTER_WIDTH: 10,
  STROKE_WIDTH: 3,
  FONT_SIZE: 13,
  BACKGROUND_WIDTH: 100,
  RECTANGLE_PADDING: 6,
};

export const PIPE_WITH_COATING_CONFIG = {
  BACKGROUND_COLORS: ["#41C08C", "#008A59"]
};

export const DEFECT_CONFIG = {
  BACKGROUND_COLOR: "#e8833a",
  WIDTH: 10,
  HEIGHT: 55,
  RADIUS: 5,
};

export const MARGIN = 7;

export const SINGLE_RED_BAND_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#d3465c"
};

export const DOUBLE_RED_BAND_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#d3465c"
};

export const BLACK_BANDED_ANODE_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#000000"
};

export const TRANSITION_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#016fbb"
};

export const QUARANTINE_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#f93442"
};

export const BASE_MATERIAL_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#1cae9f",
};

export const REPAIRED_WELD_CONFIG = {
  BACKGROUND_COLOR: "#ffd12c",
};

export const SEQUENCE_NUMBER_EVERY = 10;

const TOOLTIP_CONFIG = {
  FONT_SIZE: 12.5,
  GENERAL_TEXT_FONT_SIZE: 10,
  PADDING: 10,
  MARGIN: 5,
  WIDTH: 200,
  TEXT_COLOR: themeSettings.palette.text.primary,
  LIGHT_TEXT_COLOR: "#8c8c8c",
  BACKGROUND_COLOR: "#fff",
  SCALE_MIN: 0.6,
  RADIUS: 2,
  STROKE_COLOR: themeSettings.palette.deactivate.backgroundColor,
  STROKE_WIDTH: 1,
  POINTER_RADIUS: 10,
};

export const WELD_TOOLTIP_CONFIG = {
  ...TOOLTIP_CONFIG,
  HEIGHT: 100,
  HEIGHT_WITHOUT_AS_BUILT_LENGTH: 85,
};

export const PIPE_TOOLTIP_CONFIG = {
  ...TOOLTIP_CONFIG,
  HEIGHT: 150,
  HEIGHT_WITHOUT_AS_BUILT_LENGTH: 85,
};

export const TOOLTIP_PROGRESS_BAR_CONFIG = {
  HEIGHT: 5,
  RADIUS: 3,
  BACKGROUND_COLOR: "#5db7d6",
  STROKE_COLOR: themeSettings.palette.text.primary,
  STROKE_WIDTH: 1.5,
  LINE_HEIGHT: 2.5,
};

export const STALK_NUMBER_WIDTH = 250;