import React, {useEffect} from "react";
import {FieldArray, Formik} from "formik";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {COLUMN_NAMES_EDIT, ROW_VALUES_TO_ADD, TABLE_FIELDS_NAMES} from "../../constants";
import {Input} from "@core/components/Form";
import {debounce} from "@core/helpers";
import RemoveButtonCell from "@core/components/DynamicTestResultsTable/RemoveButtonCell";
import AddRowButton from "@core/components/DynamicTestResultsTable/AddRowButton";

const EditableTable = ({tableData, setIsSubmitting, setHandleFormikSubmit, onSubmit}) => {

  const initialValues = {
    traceabilityTable: tableData
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
      {({handleSubmit, values, isSubmitting, handleChange}) => {

        useEffect(() => {
          setHandleFormikSubmit(() => handleSubmit);
        }, [handleSubmit]);

        useEffect(() => {
          setIsSubmitting(isSubmitting);
        }, [isSubmitting]);

        return (
          <FieldArray name="traceabilityTable">
            {({push, remove}) => {
              return (
                <>
                  <Table className="styled-table">
                    <TableHead>
                      <TableRow>
                        {COLUMN_NAMES_EDIT.map((columnName) => (
                          <TableCell key={columnName}>{columnName}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {values.traceabilityTable.map(({uniqueId, ...item}, rowIndex) => (
                        <TableRow key={uniqueId}>
                          {TABLE_FIELDS_NAMES.map((field) => (
                            <TableCell key={field}>
                              <Input
                                defaultValue={item[field]}
                                disabled={isSubmitting}
                                name={`traceabilityTable.${rowIndex}.${field}`}
                                onChange={debounce(handleChange)}/>
                            </TableCell>
                          ))}
                          <RemoveButtonCell disabled={isSubmitting} onClick={() => remove(rowIndex)}/>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <AddRowButton disabled={isSubmitting} push={push} rowCellsValue={ROW_VALUES_TO_ADD}/>
                </>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

export default EditableTable;