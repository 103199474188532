export const TEST_STANDARDS = [
  "ASTM E340 (2015)",
  "ASTM E381 (20120)",
  "ASME Section IX (2021)"
];

export const ELEMENT = {
  etchingStandard: "",
  etchingSolution: "",
  specimenId: "",
  magnification: "",
  electrolytic: false,
  files: [],
  result: ""
};

export const ACCEPTANCE_CRITERIA = {
  "ITP 011/23/124277 Rev. 05": {
    company: ["Tremblay Inc."],
    elements: [{
      ...ELEMENT,
      magnification: 5,
    },
    {
      ...ELEMENT,
      magnification: 10,
    }],
  },
  get "ITP 011/23/124277 Rev. 05 MPQT"() {
    return this["ITP 011/23/124277 Rev. 05"];
  },
  "CPW-ITP-24-23-SAWL Rev. 3": {
    company: ["Tremblay Inc."],
    elements: [{
      ...ELEMENT,
      magnification: 2.5,
    }],
  },
  "CPW-ITP-24-23-SAWL Rev. 3 MPQT": {
    company: ["Tremblay Inc."],
    elements: [{
      ...ELEMENT,
      magnification: 2.5,
    },
    {
      ...ELEMENT,
      magnification: 2.5,
    }],
  }
};